import type { Integration } from "schemas/generated/integration";

import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import { BsBarChartLine } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { areAccessScopesEqual, serializedScopes } from "schemas/shopifyScopes";

const ShopifyIntegrationCard = ({
  number,
  integration,
}: {
  number: number;
  integration: Integration;
}) => {
  const {
    id,
    workspaceId,
    shopifyIntegrationConfig: { shopifyUrl, name, accessScopes, webPixelId },
  } = integration;
  const isWebPixelInstalled = webPixelId !== null;
  const areAccessScopesForAnalytics = areAccessScopesEqual(
    accessScopes,
    serializedScopes.Normal,
  );
  const showAnalyticsConnection =
    !isWebPixelInstalled || !areAccessScopesForAnalytics;
  const { openModal } = useModal();

  function handleRemove() {
    openModal({
      type: "uninstallShopifyIntegrationModal",
      props: { workspaceId, integrationId: id },
    });
  }

  return (
    <div className="border rounded-lg p-4 mb-3 w-[600px] flex flex-row gap-1">
      <div className="flex-shrink-0">
        <div className="bg-slate-800 text-blue-50 text-sm font-bold w-[30px] h-[30px] rounded-full flex items-center justify-center mr-4 flex-shrink-0">
          {number}
        </div>
      </div>
      <div className="flex-grow flex flex-col gap-1">
        <p className="text-base font-semibold">
          {name ?? shopifyUrl.replace(".myshopify.com", "")}
        </p>
        <p className="text-sm">{shopifyUrl}</p>
        {showAnalyticsConnection ? (
          <EnableAnalyticsCallout {...integration} />
        ) : null}
      </div>
      <div className="flex-shrink-0">
        <Button variant="secondary" onClick={() => handleRemove()}>
          Remove
        </Button>
      </div>
    </div>
  );
};

function EnableAnalyticsCallout({
  workspaceId,
  shopifyIntegrationConfig,
}: Integration) {
  const shopifyUrl = shopifyIntegrationConfig.shopifyUrl;
  const { data: oauthLink } = trpc.analytics.generateOAuthLink.useQuery({
    workspaceId,
    shopifyUrl,
  });
  const { mutateAsync: enableAnalytics } = trpc.analytics.enable.useMutation();
  const shouldUseOauth =
    shopifyIntegrationConfig.accessScopes !== serializedScopes.Normal;
  const navigate = useNavigate();
  const handleEnable = async () => {
    await enableAnalytics({ workspaceId });
    navigate(`/workspace/${workspaceId}/analytics/overview`);
  };
  const analytics = useLogAnalytics();

  return (
    <div className="p-2 bg-blue-50 rounded-lg flex-grow gap-4 flex flex-row">
      <BsBarChartLine className="flex-shrink-0" />
      <div className="flex flex-col gap-2">
        <p className="text-xs font-medium">Analytics</p>
        <p className="text-xs">
          Get valuable insights by connecting your store
        </p>
        <div className="flex items-center content-center">
          {!shouldUseOauth ? (
            <Button
              variant="primary"
              onClick={() => void handleEnable()}
              size="sm"
            >
              Connect
            </Button>
          ) : null}
          {shouldUseOauth && oauthLink ? (
            <Button
              variant="primary"
              to={oauthLink}
              size="sm"
              onClick={() => {
                analytics("analytics.connect", {
                  tab: "integrations_tab",
                });
              }}
            >
              Connect
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ShopifyIntegrationCard;
