import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { AiOutlineFontSize } from "react-icons/ai";
import { CSS_LENGTH_TYPES_WITH_COMPUTED } from "replo-runtime/shared/utils/units";

import ModifierLabel from "../../extras/ModifierLabel";
import { LengthInputSelector } from "../../modifiers/LengthInputModifier";

interface FontSizeSelectorProps {
  size: string | ReploMixedStyleValue | null;
  onChange: (size: string) => void;
  onPreviewChange?: (size: string) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  placeholder?: string;
}

const FontSizeSelector: React.FC<FontSizeSelectorProps> = ({
  size,
  onChange,
  onPreviewChange,
  onDragStart,
  onDragEnd,
  placeholder,
}) => {
  return (
    <LengthInputSelector
      label={<ModifierLabel label="Size" />}
      field="style.fontSize"
      value={size}
      dragTrigger="label"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      allowsNegativeValue={false}
      anchorValue="16px"
      resetValue={null}
      placeholder={placeholder ?? "Font Size"}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      startEnhancer={
        <Tooltip inheritCursor content="Font Size" triggerAsChild>
          <span tabIndex={0}>
            <AiOutlineFontSize />
          </span>
        </Tooltip>
      }
      menuOptions={fontSizeMenuItems}
      onPreviewChange={onPreviewChange}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onChange={onChange}
      previewProperty="fontSize"
    />
  );
};

const fontSizeMenuItems = [
  "Reset",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "32px",
].map((v) => ({
  label: v,
  value: v === "Reset" ? null : v,
}));

export default FontSizeSelector;
