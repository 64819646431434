import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import Separator from "@editor/components/common/designSystem/Separator";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const renameFormSchema = z.object({
  assetName: z
    .string()
    .min(1, "Asset name is required")
    .max(255, "Asset name is too long"),
});

type RenameFormData = z.infer<typeof renameFormSchema>;

const AssetCardRenameForm: React.FC<{
  originalAssetName: string;
  onRenameAsset: (assetName: string) => void;
}> = ({ originalAssetName, onRenameAsset }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RenameFormData>({
    resolver: zodResolver(renameFormSchema),
    defaultValues: {
      assetName: originalAssetName,
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void handleSubmit((data) => {
      onRenameAsset(data.assetName);
    })();
  };

  return (
    <>
      <Separator className="" />
      <form onSubmit={onSubmit} className="flex flex-col gap-2">
        <span className="typ-label-small">Name</span>
        <Input
          {...register("assetName")}
          autoFocus
          onBlur={(e) => {
            if (e.target.value.trim()) {
              onRenameAsset(e.target.value);
            }
          }}
        />
        {errors.assetName && (
          <span className="typ-label-small text-danger">
            {errors.assetName.message}
          </span>
        )}
      </form>
    </>
  );
};

export default AssetCardRenameForm;
