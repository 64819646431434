import type { ComponentTemplate } from "@editor/types/component-template";

import * as React from "react";

import ComponentPreview from "@editor/components/ComponentPreview";
import ComponentTemplateDragOverlay from "@editor/components/ComponentTemplateDragOverlay";
import { getTemplateName } from "@editor/components/editor/componentTemplates";

import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import twMerge from "@replo/design-system/utils/twMerge";

export const ComponentTemplateListSkeleton = ({
  hideTitle,
  count = 6,
}: {
  hideTitle?: boolean;
  count?: number;
}) => {
  return (
    <div className={twMerge("flex flex-col gap-3", hideTitle ? "" : "p-3")}>
      <div className="flex flex-col gap-2 mb-4">
        {!hideTitle && (
          <div className="flex items-center gap-2">
            <Skeleton className="h-5 w-32" />
          </div>
        )}
        <ul className="grid grid-cols-2 gap-3 py-2">
          {new Array(count).fill(0).map((_, itemIndex) => (
            <li key={itemIndex}>
              <div className="aspect-[1/1] w-full">
                <Skeleton className="h-full w-full rounded" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const ComponentTemplateListItem: React.FC<{
  componentTemplate: ComponentTemplate;
}> = ({ componentTemplate }) => {
  const regex = /\/([^/]+)\./;
  const alt = componentTemplate.preview?.match(regex);

  return (
    <div className="relative aspect-[1/1] w-full cursor-pointer rounded border-0.5 bg-white">
      <div
        className="grid h-full w-full place-items-center overflow-hidden"
        data-testid="component-template-draggable"
      >
        <ComponentPreview
          type="img"
          alt={alt?.[1] ?? "Component"}
          src={`/images/templates/${componentTemplate.preview}`}
        />
        {componentTemplate.template && (
          <ComponentTemplateDragOverlay
            dragType="newComponent"
            template={componentTemplate}
            identifier={componentTemplate.id}
            scope="left-bar"
          />
        )}
        <div className="absolute bottom-3 text-muted flex items-center h-7 text-xs text-center">
          {getTemplateName(componentTemplate) as string}
        </div>
      </div>
    </div>
  );
};
