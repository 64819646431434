import type { VariantWithState } from "replo-runtime/shared/types";
import type { AllComponentRenderData } from "replo-runtime/store/components";
import type { Context } from "replo-runtime/store/ReploVariable";
import type { Component, ReploComponentType } from "schemas/component";

import {
  RuntimeHooksContext,
  useRuntimeContext,
} from "replo-runtime/shared/runtime-context";
import {
  generateStyleId,
  getComponentRuleName,
} from "replo-runtime/shared/styles";
import { getRenderData } from "replo-runtime/store/components";

/**
 * Note (Chance, 2023-05-12): This function is not truly type safe as it
 * performs an arbitrary string operation. We accept the component type, so we
 * should be able to do a runtime check if we can construct a scheme or even an
 * object that maps components to its class keys. Be careful with usage in the
 * mean time and make sure you only call this function if you definitely know
 * the component type.
 *
 * @returns A map of `styleElements` (the key for each DOM element rendered by a
 * component, eg. 'root', 'button', etc.) to its classname.
 *
 * Note (Chance, 2023-05-18) This return type basically says that if a
 * component's render data has a `styleElements` key, the return type will
 * extract the type from its value in the render data object. For example,
 * because `AllComponentRenderData['container']` has a `styleElements` key, and
 * the type of `AllComponentRenderData['container']['styleElements']` is `{
 * root: { ... } }`, `useComponentClassNames('container', ...args)` will
 * return an object with the type `{ root: string }`. If a component's render
 * data doesn't define a `styleElements` key, the return type will be an
 * inaccessable object, eg `{ [key: never]: never }`
 */
export function useComponentClassNames<T extends ReploComponentType>(
  componentType: T,
  component: Component,
  context: Context,
): undefined | StyleElementRecord<T, string> {
  const editorOverrideVariantId = useRuntimeContext(
    RuntimeHooksContext,
  ).useEditorOverrideActiveVariantId(component.id);

  // Get all the variants for this component (NOT any from ancestors)
  const variants: VariantWithState[] =
    context.ancestorWithVariantsId === component.id
      ? context.variants ?? []
      : [];

  // Next, figure out which variant is active, if any
  let activeVariant: VariantWithState | undefined = undefined;
  if (editorOverrideVariantId) {
    // If the user has specifically selected a variant in the editor, then
    // treat that variant as active
    activeVariant = variants.find((v) => v.id == editorOverrideVariantId);
  }
  if (!activeVariant) {
    // Otherwise, the active variant is the one whose conditions are true (in
    // ReploComponent, we already mapped this to isActive = true on this
    // VariantWithState object)
    activeVariant = variants.find((variant) => variant.isActive);
  }
  if (activeVariant?.isDefault) {
    // If the active variant is the default variant, then act like there's no
    // active variant, since we don't want to include any variant id in the
    // className which is going to get returned from this function (we just want
    // the "normal") class name
    activeVariant = undefined;
  }
  const styleElementNames = Object.keys(
    getRenderData(componentType)?.styleElements ?? {
      root: {},
    },
  );
  return Object.fromEntries(
    styleElementNames.map((styleElementName) => {
      return [
        styleElementName,
        [
          generateStyleId(
            getComponentRuleName(component.id, {
              styleElementName,
            }),
          ),
          // If there is an active variant we combine the root className with
          // the variant className
          activeVariant
            ? generateStyleId(
                getComponentRuleName(component.id, {
                  styleElementName,
                  variantId: activeVariant.id,
                }),
              )
            : false,
        ]
          .filter(Boolean)
          .join(" "),
      ];
    }),
  ) as StyleElementRecord<T, string>;
}

type StyleElementRecord<
  T extends ReploComponentType,
  V,
> = AllComponentRenderData[T] extends {
  styleElements: any;
}
  ? Record<keyof AllComponentRenderData[T]["styleElements"], V>
  : Record<"root", V>;
