import * as React from "react";

import isEqual from "lodash-es/isEqual";

/**
 * Hook which functions like useState, except that the value which is passed in
 * always overrides the state variable if it's different. The value returned
 * from this hook is guaranteed to be the same as the overrideValue, as long
 * as a local state update hasn't processed since the last render with a different
 * overrideValue.
 *
 * If an onChange is provided, it will be called when the returned setter is called.
 *
 * Useful for things like modifiers, where we want to have local state so that the
 * UI updates quickly, but we want the state to automatically update when a new prop
 * comes in. Especially useful if the callback for the value we're controlling is debounced.
 *
 * dependencyKey is for when we need to pass custom dependencies for the useEffect,
 * for example, in case we need to override the value but it is the same as before.
 *
 * E.g.:
 *
 * ```
 * const CustomSlider = (props: { value: number, onChange: (value: number): void }) => {
 *   const [stateValue, setStateValue] = useOverridableState(
 *      props.value,
 *      debounce(props.onChange, 300)
 *   )
 *   return <Input value={value} onChange={setStateValue} ... />
 * }
 * ```
 */
export function useOverridableState<T>(
  overrideValue: T,
  onChange?: (value: T) => void,
  dependencyKey?: string,
): [T, (value: T) => void] {
  const [stateValue, setStateValue] = React.useState(overrideValue);
  const latestValueRef = React.useRef(stateValue);

  // biome-ignore lint/correctness/useExhaustiveDependencies: using more dependencies than necessary: dependencyKey
  React.useEffect(() => {
    setStateValue((stateValue) => {
      if (
        !isEqual(stateValue, overrideValue) &&
        isEqual(stateValue, latestValueRef.current)
      ) {
        latestValueRef.current = overrideValue;
        return overrideValue;
      }
      return stateValue;
    });
  }, [overrideValue, dependencyKey]);

  const callback = React.useMemo(() => {
    const callback = (value: T) => {
      setStateValue(value);
      latestValueRef.current = value;
      onChange?.(value);
    };
    return callback;
  }, [onChange]);
  return [stateValue, callback];
}
