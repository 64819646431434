import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { ComponentActionType } from "@editor/types/component-action-type";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { DropTarget as AlchemyDropTarget } from "@editor/types/drop-target";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import * as React from "react";

import useHandleReplaceComponentWithDesignLibraryReferences from "@editor/hooks/designLibrary/useHandleReplaceComponentWithDesignLibraryReferences";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useStoreAndDraftElementProducts } from "@editor/hooks/useStoreProducts";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectComponentDataMapping,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectGetAttribute,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { getEditorComponentNode } from "@editor/utils/component";
import getUpdatedImageComponentTemplate from "@editor/utils/getUpadatedImageComponentTemplate";
import useDragAndDrop from "@providers/DragAndDropProvider";
import { finalizeDropComponentActions } from "@utils/drop";

import { selectActiveCanvas, selectCanvasDeltaXY } from "./canvas-reducer";

export function useDropHandler({
  onDrop: onDropProp,
  componentIdToDrag,
  componentNodeToDrag,
}: {
  onDrop: () => void;
  componentIdToDrag: string | null;
  componentNodeToDrag: HTMLElement | null;
}) {
  const applyComponentAction = useApplyComponentAction();
  const setDraftElement = useSetDraftElement();
  const store = useEditorStore();
  const { onSuccessFinishDrop, onDrop: onDropRef } = useDragAndDrop();
  // Note (Fran, 2022-12-06): We need to grab all the store and draft element
  // products, because sometimes the cache of the fetch products are only a few
  // of them. And for drop component templates with specific products, we will
  // not replace them correctly.
  const { products } = useStoreAndDraftElementProducts();
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);

  const { deltaY } = useEditorSelector(selectCanvasDeltaXY);
  const errorToast = useErrorToast();
  const { handleReplaceComponentWithDesignLibraryReferences } =
    useHandleReplaceComponentWithDesignLibraryReferences();
  const onDrop = React.useCallback(
    (
      target: AlchemyDropTarget,
      template: ComponentTemplate | null | undefined,
      previousActions: UseApplyComponentActionType[],
      isSavedComponent?: boolean,
    ) => {
      onDropProp();
      // NOTE (Reinaldo): If we are trying to drop an existing component into itself, don't do it.
      if (!template && target.componentId === componentIdToDrag) {
        return;
      }

      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(
          store.getState(),
        );

      if (!draftElement) {
        return;
      }

      if (!componentNodeToDrag) {
        if (target.error) {
          errorToast(
            "Failed Dropping Component",
            target.error,
            "error.component.drop",
            {
              error: target.error,
            },
          );
        }
        if (previousActions?.length > 0) {
          applyComponentAction({
            type: "applyCompositeAction",
            value: previousActions,
          });
        }
        return;
      }

      const updatedImageTemplate = getUpdatedImageComponentTemplate(
        template,
        deltaY,
      );

      const componentDataMapping = selectComponentDataMapping(store.getState());

      const result = finalizeDropComponentActions(
        target,
        updatedImageTemplate ?? template ?? null,
        draftElement!,
        componentIdToDrag!,
        selectGetAttribute(store.getState()),
        componentDataMapping,
        {
          products,
          currencyCode: activeCurrency,
          language: activeLanguage,
          moneyFormat,
          templateProduct,
          isEditor: true,
          isShopifyProductsLoading: false,
        },
        isSavedComponent,
        handleReplaceComponentWithDesignLibraryReferences,
      );

      if (result.result === "error") {
        errorToast(
          "Dragging not possible",
          result.message ?? "Unknown error",
          "error.component.drop",
          {
            error: result.message ?? "Unknown error",
          },
        );
        return;
      }
      const componentActions = (previousActions ?? []).concat(result.actions);
      if (componentActions.length > 0) {
        applyComponentAction({
          type: "applyCompositeAction",
          value: componentActions,
        });

        if (result.addedComponent) {
          // NOTE (Fran 2024-04-24): When we drop a new component, we must set the draft repeated index
          // to the new component's repeated index. This is because when we drop a new text component,
          // we are enabling content editing, and we need to sync the repeated index and the component id.
          // We don't have the new component's repeated index at this moment, so we need to infer it.
          // We can infer it by checking the ancestors types in the componentDataMapping.
          let draftRepeatedIndex = undefined;
          const canvas = selectActiveCanvas(store.getState());
          if (result.needsNewContainer) {
            // NOTE (Fran 2024-04-26): if the dropped component needs a new container, we need to find the
            // repeated index of the new container, because at this moment of the process we will not find
            // the new component parent in the html to infer the repeated index.
            // So we need to find the new container component in the componentDataMapping and infer the repeated index
            // from the parent component.
            const newContainerAction = result.actions.find(
              (action) =>
                action.type === "addComponentToComponent" &&
                action.value.newComponent.type === "container",
            ) as
              | Extract<
                  ComponentActionType,
                  { type: "addComponentToComponent" }
                >
              | undefined;
            const newContainerId = newContainerAction?.value.newComponent.id;
            if (newContainerId) {
              draftRepeatedIndex = inferRepeatedIndexPathFromParentComponent({
                canvas,
                parentComponentId: result.parentId,
                needsNewContainer: true,
              });
            }
          } else {
            draftRepeatedIndex = inferRepeatedIndexPathFromParentComponent({
              canvas,
              parentComponentId: result.parentId,
              needsNewContainer: false,
            });
          }

          setDraftElement({
            componentIds: [result.addedComponent.id],
            repeatedIndex: draftRepeatedIndex ?? undefined,
          });

          onSuccessFinishDrop?.(result.addedComponent, result.parentId);
        }
      }
    },
    [
      store,
      applyComponentAction,
      onSuccessFinishDrop,
      setDraftElement,
      componentIdToDrag,
      componentNodeToDrag,
      deltaY,
      products,
      activeCurrency,
      activeLanguage,
      moneyFormat,
      onDropProp,
      templateProduct,
      errorToast,
      handleReplaceComponentWithDesignLibraryReferences,
    ],
  );

  React.useEffect(() => {
    if (onDropRef) {
      onDropRef.current = onDrop;
    }
  }, [onDrop, onDropRef]);

  return { onDrop };
}

/**
 * This function is in charge of inferring the repeated index of a new component
 * that is being dropped. It will infer the repeated index using the parent of
 * the new component repeated index. We will get it from the html node.
 *
 * This function is needed because when we execute the actions resulting from
 * the drag and drop process we will not find the new component in the html to
 * get the repeated index. If we don't set the repeated index of the new
 * component it will be unsync with the component id. This unsync state will
 * result in issues like the content editing not working.
 */
function inferRepeatedIndexPathFromParentComponent(options: {
  canvas: EditorCanvas;
  parentComponentId: string | null;
  needsNewContainer: boolean;
}): string | undefined {
  const { canvas, parentComponentId, needsNewContainer } = options;
  if (!parentComponentId) {
    return undefined;
  }

  const parentNode = getEditorComponentNode({
    canvas,
    componentId: parentComponentId,
  });
  const repeatedIndex = parentNode?.dataset.reploRepeatedIndex;
  if (repeatedIndex) {
    return needsNewContainer ? `${repeatedIndex}.0.0` : `${repeatedIndex}.0`;
  }

  return undefined;
}
