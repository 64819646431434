import type { ComboboxContentProps } from "@replo/design-system/components/combobox/components/ComboboxContent";
import type { ComboboxOption } from "./ComboboxContext";
import type { ComboboxPopoverProps } from "./components/ComboboxPopover";

import React from "react";

import { Command } from "@replo/design-system/components/shadcn/core/command";
import { Popover } from "@replo/design-system/components/shadcn/core/popover";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import twMerge from "../../utils/twMerge";
import { ComboboxContext } from "./ComboboxContext";
import { ComboboxContent } from "./components/ComboboxContent";
import { ComboboxFooter } from "./components/ComboboxFooter";
import { ComboboxInput } from "./components/ComboboxInput";
import { ComboboxPopover } from "./components/ComboboxPopover";
import { ComboboxSelectionButton } from "./components/ComboboxSelectionButton";
import { ComboboxTrigger } from "./components/ComboboxTrigger";

type SingleSelectProps = {
  isMultiselect?: never;
  value?: string;
  onChange?: (value: string) => void;
};
type MultiSelectProps = {
  isMultiselect: true;
  value?: string[];
  onChange?: (value: string[]) => void;
};

type ComboboxRootProps = {
  options: ComboboxOption[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  input?: string;
  onInputChange?: (value: string) => void;
  syncInputWithSelectedValue?: boolean;
  layoutClassName?: string;
} & (SingleSelectProps | MultiSelectProps);

const ComboboxRoot: React.FC<React.PropsWithChildren<ComboboxRootProps>> = ({
  children,
  options,
  isMultiselect = false,
  value: controlledValue,
  onChange: onControlledChange,
  open: controlledOpen,
  onOpenChange: onControlledOpenChange,
  input: inputValue,
  onInputChange: onControlledInputChange,
  syncInputWithSelectedValue = true,
  layoutClassName,
}) => {
  const [open, setOpen] = useControllableState(
    controlledOpen,
    false,
    onControlledOpenChange,
  );

  const [value, setValue] = useControllableState<string | string[]>(
    controlledValue,
    isMultiselect ? [] : "",
    onControlledChange as
      | ((value: string | string[] | null) => void)
      | undefined,
  );
  const [input, setInput] = useControllableState(
    inputValue,
    "",
    onControlledInputChange,
  );

  /**
   * NOTE (Max, 2025-01-10): We use & expose this flag through
   * the ComboboxContext for its consumers to know whether the
   * trigger is an input or a select.
   *
   * For example, <OptionsList> (use in <ComboboxContent>) needs to access
   * this flag to know whether to filter the options based on the input value.
   */
  const [isUsingTriggerInput, setIsUsingTriggerInput] = React.useState(false);

  const triggerInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    // NOTE (Max, 2025-01-03): Ensures that when the optionsList popover is opened,
    // the trigger input remains focused
    if (isOpen) {
      setTimeout(() => {
        triggerInputRef.current?.focus();
      }, 0);
    }
  };

  const handleValueChange = (newValue: string | string[]) => {
    setValue(newValue);

    /**
     * NOTE (Max, 2025-01-04): Ensures that the actual selected value & the triggerInput value
     * are in sync.
     *
     * setTimeout() prevents the input to be updated before the
     * optionsList is closed, which would lead to a UI flicker, as the optionsList's
     * items get would be updated (as they get filtered by the new input value).
     */
    if (
      isUsingTriggerInput &&
      syncInputWithSelectedValue &&
      !isMultiselect &&
      typeof newValue === "string"
    ) {
      setTimeout(() => {
        setInput(newValue);
      }, 100);
    }
  };

  return (
    <ComboboxContext.Provider
      value={{
        open,
        isMultiselect,
        setOpen,
        value,
        setValue: handleValueChange,
        input,
        setInput,
        options,
        triggerInputRef,
        isUsingTriggerInput,
        setIsUsingTriggerInput,
        syncInputWithSelectedValue,
      }}
    >
      <Popover open={open} onOpenChange={handleOpenChange}>
        <Command
          className={twMerge("overflow-hidden flex flex-col", layoutClassName)}
          shouldFilter={false}
        >
          {children}
        </Command>
      </Popover>
    </ComboboxContext.Provider>
  );
};

export type ComboboxProps = ComboboxRootProps &
  Pick<
    ComboboxPopoverProps,
    "side" | "align" | "sideOffset" | "unsafe_className"
  > &
  Pick<
    ComboboxContentProps,
    | "title"
    | "areOptionsSearchable"
    | "inputPlaceholder"
    | "emptySearchMessage"
    | "size"
    | "footer"
    | "loader"
  > & { trigger: React.ReactNode };

/**
 * A component which allows searching/filtering through a list of options.
 *
 * ## Default Comboboxes
 * Use the <Combobox /> component for the default configuration of a combobox.
 * Renders a trigger (which generally you'll want to pass as a
 * Combobox.SelectionButton) and a popover with options that appears when you
 * click the trigger. Optionally, a footer can be passed which will show up
 * after the options inside the popover, and you can pass areOptionsSearchable
 * to display a search bar in the popover.
 *
 * ## Composed Comboboxes
 * Use the composed components if you want a custom combobox - for example, if
 * you want a custom styled input, you want the input outside of the popover, you
 * want the input itself to trigger the popover, you want a custom footer, you don't
 * want the default popover behavior, you want to add in custom content inside the
 * popover, etc.
 *
 * Generally the setup for this looks like:
 * <Combobox.Root>
 *   <Combobox.Trigger>
 *     <Combobox.SelectionButton /> // Can be any other trigger - anything inside this will open the popover
 *   </Combobox.Trigger>
 *   <Combobox.Popover> // Can provide custom prop overrides to popover
 *     <Combobox.Content // Can be any custom content, custom div to add more padding, etc
 *      footer={<Combobox.Footer />} // Can provide custom footer or omit
 *     />
 *   </Combobox.Popover>
 * </Combobox.Root>
 *
 * ## Multiselect Comboboxes
 * Use isMultiselect  to create a multiselect combobox. If multiselect is provided,
 * the `value` prop must be an array, and the `onChange` prop will receive an array
 * which represents the new list of selected values any time the user checks or
 * unchecks an option.
 */
export const Combobox = (props: ComboboxProps) => {
  return (
    <ComboboxRoot {...props}>
      <ComboboxTrigger>{props.trigger}</ComboboxTrigger>
      <ComboboxPopover
        sideOffset={props.sideOffset}
        side={props.side}
        align={props.align}
      >
        <ComboboxContent
          title={props.title}
          areOptionsSearchable={props.areOptionsSearchable}
          inputPlaceholder={props.inputPlaceholder}
          emptySearchMessage={props.emptySearchMessage}
          footer={props.footer}
          loader={props.loader}
        />
      </ComboboxPopover>
    </ComboboxRoot>
  );
};

Combobox.Root = ComboboxRoot;
Combobox.Input = ComboboxInput;
Combobox.Content = ComboboxContent;
Combobox.Footer = ComboboxFooter;
Combobox.Popover = ComboboxPopover;
Combobox.Trigger = ComboboxTrigger;
Combobox.SelectionButton = ComboboxSelectionButton;
