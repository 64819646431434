import * as React from "react";

import Popover from "@replo/design-system/components/popover/Popover";

import AssetsLibrary from "./AssetsLibrary";

const AssetLibraryPopover: React.FC<{
  anchor: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onChangeSource?: (assetUrl: string) => void;
  assetTypes?: ("image" | "video")[];
}> = ({ anchor, isOpen, setIsOpen, onChangeSource, assetTypes }) => {
  return (
    <Popover.Root isOpen={isOpen} onOpenChange={setIsOpen}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside={false}
        title="Assets"
        // NOTE (Gabe 2025-03-05): For some reason this min-h-0 is necessary to
        // ensure the InfiniteSroll trigger the next page fetch.
        className="flex flex-col w-64 h-[428px] min-h-0 pb-0"
        sideOffset={16}
      >
        <AssetsLibrary
          assetTypes={assetTypes}
          onAssetSelected={(asset) => {
            onChangeSource?.(asset.url);
            setIsOpen(false);
          }}
          shouldAllowActions={false}
        />
      </Popover.Content>
      <Popover.Anchor asChild>{anchor}</Popover.Anchor>
    </Popover.Root>
  );
};

export default AssetLibraryPopover;
