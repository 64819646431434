import type { EditorPropsRecord } from "replo-runtime/shared/utils/editorProps";
import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "../../components";

import containerConfig from "replo-runtime/store/components/Container/config";

import {
  getDefaultStyleRulesIfEmpty,
  mapAndConvertComponentStylePropsToStyle,
} from "../../../shared/utils/breakpoints";
import { convertToLegacyProps } from "../../../shared/utils/props";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _tooltipContentComponent: {
      type: "component",
      name: "Tooltip Content",
      description: "The content to show in the tooltip.",
      defaultValue: null,
    },
    _showArrow: {
      type: "boolean",
      name: "Show Arrow",
      description:
        "If set, the tooltip will show an arrow pointing to the trigger.",
      defaultValue: true,
    },
    _triggeringAction: {
      type: "selectable",
      name: "Opening Interaction",
      description: "What visitor interaction makes the tooltip appear?",
      defaultValue: "onHover",
      selectableValues: {
        type: "options",
        options: [
          { label: "Hover", value: "onHover" },
          { label: "Click", value: "onClick" },
        ],
      },
    },
    _arrowSize: {
      type: "pixels",
      name: "Arrow Size",
      description: "The size of the tooltip arrow",
      defaultValue: "20px",
    },
    _side: {
      type: "selectable",
      name: "Side",
      description: "The side of the trigger the tooltip will appear on.",
      defaultValue: "top",
      selectableValues: {
        type: "options",
        options: [
          { label: "Top", value: "top" },
          { label: "Right", value: "right" },
          { label: "Bottom", value: "bottom" },
          { label: "Left", value: "left" },
        ],
      },
    },
    _sideOffset: {
      type: "pixels",
      name: "Side Offset",
      description: "The distance from the trigger edge to the tooltip content",
      defaultValue: "0px",
    },
    _alignment: {
      type: "selectable",
      name: "Alignment",
      description: "The alignment of the content relative to the side.",
      defaultValue: "center",
      selectableValues: {
        type: "options",
        options: [
          { label: "Start", value: "start" },
          { label: "Center", value: "center" },
          { label: "End", value: "end" },
        ],
      },
    },
    _alignOffset: {
      type: "pixelsIncludingNegativeValues",
      name: "Align Offset",
      description:
        "The offset from the trigger's center to the tooltip content's center.",
      defaultValue: "0px",
    },
    _hoverDelayTime: {
      type: "integer",
      name: "Hover Delay Time",
      description:
        "The amount of time in milliseconds to wait before showing and hiding the tooltip content.",
      defaultValue: 0,
      min: 0,
    },
  };
}

function getEditorProps(): EditorPropsRecord {
  return {
    isOpen: {
      type: "collapsibility",
      title: "Is open",
      description: "If set, the editor will show the tooltip content",
      defaultValue: false,
    },
  };
}

const styleElements = {
  root: {
    overrideStyles({ component, styleProps }) {
      return getDefaultStyleRulesIfEmpty(component, styleProps, {
        defaultMinHeight: 60,
      });
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    editorProps: getEditorProps(),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: false,
    styleElements,
    ancestorDisallowList: [
      {
        ancestorTypes: ["marquee"],
        message: "Tooltips cannot be nested inside tickers.",
      },
      {
        ancestorTypes: ["tooltip"],
        message: "Tooltip cannot be nested inside another tooltip.",
      },
      {
        ancestorTypes: ["modal"],
        message: "Popup cannot be nested inside a tooltip.",
      },
    ],
  },
  children: {
    tooltipContent: {
      renderData: {
        ...containerConfig.renderData,
        customProps: undefined,
        styleElements: {
          root: {
            overrideStyles({ component, styleProps }) {
              const defaultStyles = getDefaultStyleRulesIfEmpty(
                component,
                styleProps,
                { defaultMinHeight: 60 },
              );

              return mapAndConvertComponentStylePropsToStyle(
                styleProps,
                (styles) => {
                  return {
                    ...defaultStyles,
                    ...styles,
                    // NOTE (Sebas, 2024-07-02): We need to remove any border styles from the tooltip content
                    // to avoid conflicts with the tooltip wrapper styles. This is because the library wrapper
                    // is responsible for rendering the tooltip arrow and border styles.
                    // All these styles will be applied to the content wrapper.
                    borderBottomColor: null,
                    borderBottomStyle: null,
                    borderBottomWidth: null,
                    borderLeftColor: null,
                    borderLeftStyle: null,
                    borderLeftWidth: null,
                    borderRightColor: null,
                    borderRightStyle: null,
                    borderRightWidth: null,
                    borderTopColor: null,
                    borderTopStyle: null,
                    borderTopWidth: null,
                  };
                },
              );
            },
          },
          contentWrapper: {
            overrideStyles({ styleProps }) {
              return mapAndConvertComponentStylePropsToStyle(
                styleProps,
                (styles) => {
                  return {
                    backgroundColor: styles.backgroundColor,
                    borderBottomColor: styles.borderBottomColor,
                    borderBottomStyle: styles.borderBottomStyle,
                    borderBottomWidth: styles.borderBottomWidth,
                    borderLeftColor: styles.borderLeftColor,
                    borderLeftStyle: styles.borderLeftStyle,
                    borderLeftWidth: styles.borderLeftWidth,
                    borderRightColor: styles.borderRightColor,
                    borderRightStyle: styles.borderRightStyle,
                    borderRightWidth: styles.borderRightWidth,
                    borderTopColor: styles.borderTopColor,
                    borderTopStyle: styles.borderTopStyle,
                    borderTopWidth: styles.borderTopWidth,
                    borderTopLeftRadius: styles.borderTopLeftRadius,
                    borderTopRightRadius: styles.borderTopRightRadius,
                    borderBottomLeftRadius: styles.borderBottomLeftRadius,
                    borderBottomRightRadius: styles.borderBottomRightRadius,
                  };
                },
              );
            },
          },
          arrow: {
            overrideStyles({ styleProps }) {
              return mapAndConvertComponentStylePropsToStyle(
                styleProps,
                (styles) => ({
                  fill: `${styles.backgroundColor ?? "black"} !important`,
                }),
              );
            },
          },
        },
        ancestorAllow: {
          ancestorTypes: ["tooltip"],
          message:
            "Tooltip Content can only be nested at the root of a tooltip",
          directChildOnly: true,
        },
      },
    },
  },
} satisfies ComponentConfig;

export default config;
