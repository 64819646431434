import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type {
  ReorderableTreeDropTarget,
  ReorderableTreeNode,
} from "@editor/types/tree";
import type { DropTargetMonitor } from "react-dnd";
import type { ComponentData } from "replo-runtime/shared/Component";
import type { Position } from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import Input from "@common/designSystem/Input";
import Scrollable from "@common/designSystem/Scrollable";
import { TreeModalSelector } from "@editor/components/editor/TreeModalSelector";
import TreeRowWidthBadge from "@editor/components/TreeRowWidthBadge";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useContextMenuItems from "@editor/hooks/useContextMenuItems";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { setCandidateNode } from "@editor/reducers/candidate-reducer";
import {
  selectAncestorComponentIds,
  selectComponent,
  selectComponentData,
  selectComponentDataMapping,
  selectComponentHasChildrenInTree,
  selectComponentMapping,
  selectDescendantComponentIds,
  selectDoesExistDraftElement,
  selectDraftComponentId,
  selectDraftComponentIds,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftElementType,
  selectGetAttribute,
  selectIsDraftComponent,
  selectTreeComponentIssue,
} from "@editor/reducers/core-reducer";
import {
  selectAncestorFlexDirection,
  selectAreAnyComponentModals,
  selectComponentTreeInfo,
  selectDoesNodeHaveChildren,
  selectExplicitExpandedNodeIds,
  selectIdToTreeNodeData,
  selectIsAncestorHidden,
  selectIsAncestorSelected,
  selectIsComponentHidden,
  selectIsExpandedTreeNode,
  selectIsLoadingTreePane,
  selectLastVisibleTreeNodeId,
  selectRenderedTreeNodeIds,
  setExplicitExpandedNodeIds,
} from "@editor/reducers/tree-reducer";
import {
  selectAllowHorizontalScroll,
  selectIsRenamingTreeNode,
  setIsRenamingTreeNode,
} from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { DropEdge } from "@editor/types/tree";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import {
  getComponentAlignment,
  getComponentPositioning,
  getComponentWidthProperties,
  getDropEdge,
  getIconAndMessage,
  getIconColorClassName,
  getSelectedComponentIds,
  isArrayUnOrderlyEqual,
} from "@editor/utils/tree-utils";
import {
  canComponentAcceptArbitraryChildren,
  canMoveComponentToParent,
  getEditorComponentNode,
  getParentComponentFromMapping,
} from "@utils/component";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import { Menu } from "@replo/design-system/components/menu/Menu";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { BsExclamationCircleFill, BsMask } from "react-icons/bs";
import { MdArrowDropDown, MdRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { shallowEqual } from "react-redux";
import useMeasure from "react-use-measure";
import { editorCanvasToMediaSize } from "replo-runtime/shared/utils/breakpoints";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { isCloseTo } from "replo-utils/lib/math";

import { elementTypeToEditorData } from "../editor/element";

const reorderableTreeNodeIdPrefix = "reorderable-tree-node";
const treeNodeGap = 20;
const treeNodePadding = 4;
const dropIndicatorHeight = 2;

export const ComponentTreePane: React.FC = () => {
  const errorToast = useErrorToast();
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const setDraftElement = useSetDraftElement();

  const elementType = useEditorSelector(selectDraftElementType);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const isLoadingTreePane = useEditorSelector(selectIsLoadingTreePane);
  const renderedTreeNodeIds = useEditorSelector(
    selectRenderedTreeNodeIds,
    shallowEqual,
  );
  const draftElementExists = useEditorSelector(selectDoesExistDraftElement);
  const dispatch = useEditorDispatch();
  const dropIndicatorRef = React.useRef<HTMLDivElement>(null);

  const isDraggingRef = React.useRef<boolean>(false);
  const expandCurrentNodeTimeoutRef = React.useRef<ReturnType<
    typeof setTimeout
  > | null>(null);
  const parentIdToShowHover = React.useRef<string | null>(null);

  const idToTreeNodeLabel = React.useRef<Record<string, HTMLDivElement>>({});
  const onMountTreeRowNodeLabel = React.useCallback(
    (node: HTMLDivElement, id: string) => {
      idToTreeNodeLabel.current[id] = node;
    },
    [],
  );

  const idToTreeHtmlNode = React.useRef<Record<string, HTMLLIElement>>({});
  const onMountTreeRowNode = React.useCallback(
    (node: HTMLLIElement, id: string) => {
      idToTreeHtmlNode.current[id] = node;
    },
    [],
  );

  const setParentIdToShowHover = (dropParentId: string | null) => {
    if (parentIdToShowHover.current) {
      idToTreeHtmlNode.current[parentIdToShowHover.current]?.style.setProperty(
        "border-color",
        "transparent",
      );
    }

    parentIdToShowHover.current = dropParentId;

    if (parentIdToShowHover.current) {
      idToTreeHtmlNode.current[parentIdToShowHover.current]?.style.setProperty(
        "border-color",
        "blue",
      );
    }
  };

  const onChangeDropPreview = React.useCallback(
    (
      dropPreviewId: string | null,
      dropParentId: string | null,
      isAllowedDrop: boolean,
    ) => {
      if (!dropPreviewId || !dropParentId) {
        dropIndicatorRef.current?.style.setProperty("display", "none");
        return;
      }

      const dropParentNode = idToTreeNodeLabel.current[dropParentId];
      const previewNode = idToTreeNodeLabel.current[dropPreviewId];
      if (dropParentNode && previewNode) {
        const parentNodeBounds = dropParentNode.getBoundingClientRect();
        const { bottom } = previewNode.getBoundingClientRect();
        const left = parentNodeBounds.left + treeNodeGap;
        const width = parentNodeBounds.width - treeNodeGap;

        const calculatedTop = bottom + treeNodePadding - dropIndicatorHeight;
        dropIndicatorRef.current?.style.setProperty("left", `${left}px`);
        dropIndicatorRef.current?.style.setProperty("width", `${width}px`);
        dropIndicatorRef.current?.style.setProperty(
          "top",
          `${calculatedTop}px`,
        );
        dropIndicatorRef.current?.style.setProperty("display", "block");
        dropIndicatorRef.current?.style.setProperty(
          "background-color",
          isAllowedDrop ? "black" : "red",
        );
      }
    },
    [],
  );

  const onExpandOrCollapseTreeNode = React.useCallback(
    (
      selectedNodeId: string,
      expand: boolean,
      options: { expandAllDescendants: boolean },
    ) => {
      const explicitExpandedNodeIds = selectExplicitExpandedNodeIds(
        store.getState(),
      );
      if (expand) {
        const newExpandedNodes = new Set(
          [
            explicitExpandedNodeIds,
            selectedNodeId,
            options.expandAllDescendants
              ? selectDescendantComponentIds(store.getState(), selectedNodeId)
              : [],
          ].flat(),
        );

        dispatch(setExplicitExpandedNodeIds([...newExpandedNodes]));
      } else {
        const descendantComponentIds = selectDescendantComponentIds(
          store.getState(),
          selectedNodeId,
        );
        dispatch(
          setExplicitExpandedNodeIds([
            ...new Set(
              explicitExpandedNodeIds.filter(
                (key) => !descendantComponentIds.includes(key),
              ),
            ),
          ]),
        );
      }
    },
    [store, dispatch],
  );

  const onChangeExpansion = React.useCallback(
    (
      nodeId: string,
      isExpanded: boolean,
      options: { expandAllDescendants: boolean },
    ) => {
      onExpandOrCollapseTreeNode(nodeId, isExpanded, options);
    },
    [onExpandOrCollapseTreeNode],
  );

  const clearOrSetTimerForExpansion = React.useCallback(
    (previewId: string, dropEdge: DropEdge) => {
      const hasChildren = selectComponentHasChildrenInTree(
        store.getState(),
        previewId,
      );
      const explicitExpandedNodeIds = selectExplicitExpandedNodeIds(
        store.getState(),
      );
      if (
        dropEdge === DropEdge.middle &&
        !expandCurrentNodeTimeoutRef.current &&
        !explicitExpandedNodeIds.includes(previewId) &&
        // NOTE: Ovishek (2022-03-21) this check is needed to prevent expanding empty containers
        hasChildren
      ) {
        expandCurrentNodeTimeoutRef.current = setTimeout(() => {
          onChangeExpansion(previewId, true, {
            expandAllDescendants: false,
          });
        }, 1000);
      } else if (dropEdge !== DropEdge.middle) {
        if (expandCurrentNodeTimeoutRef.current) {
          clearTimeout(expandCurrentNodeTimeoutRef.current);
        }
        expandCurrentNodeTimeoutRef.current = null;
      }
    },
    [store, onChangeExpansion],
  );

  const isDroppable = React.useCallback(
    (nodeId: string) => {
      const componentTreeInfo = selectComponentTreeInfo(store.getState());
      const component = componentTreeInfo?.[nodeId]?.component;
      if (!component) {
        return false;
      }

      if (!component?.children || component?.children?.length === 0) {
        return canComponentAcceptArbitraryChildren(component, {
          movementSource: "componentTree",
        });
      }
      return true;
    },
    [store],
  );

  const getDropTargetAndPreviewId = React.useCallback(
    (clientX: number, hoveredNodeId: string, side: DropEdge) => {
      const { idToNodeData, idToParentNode } = selectIdToTreeNodeData(
        store.getState(),
      );
      const hoveredNode = idToNodeData[hoveredNodeId]!.node;
      const hoveredParentNode = idToParentNode[hoveredNodeId]!;
      if (!hoveredParentNode) {
        return {
          previewId: hoveredNode.id,
          dropParentId: hoveredNode.id,
          dropPosition: 0,
        };
      }
      const index = hoveredParentNode.children.findIndex(
        (node) => node.id === hoveredNode.id,
      );
      let previewId: string | null = null;
      let dropParentId: string | null = null;
      let dropPosition = 0;

      const explicitExpandedNodeIds = selectExplicitExpandedNodeIds(
        store.getState(),
      );

      if (side === DropEdge.beforeCurrentNode) {
        if (index === 0) {
          previewId = hoveredParentNode.id;
          dropParentId = hoveredParentNode.id;
          dropPosition = 0;
        } else {
          let leftNotExpandedChild: ReorderableTreeNode =
            hoveredParentNode.children[index - 1]!;
          dropPosition = index;

          while (explicitExpandedNodeIds.includes(leftNotExpandedChild.id)) {
            const length = leftNotExpandedChild.children.length;
            if (!length) {
              break;
            }
            leftNotExpandedChild = leftNotExpandedChild.children[length - 1]!;
            dropPosition = length;
          }

          previewId = leftNotExpandedChild.id;
          // Note (Ovishek, 2022-06-16): we all know that leftNotExpandedChild is always someones child,
          // so it's safe to assert that, its parent exists!
          dropParentId = idToParentNode[leftNotExpandedChild.id]!.id;
        }
      } else {
        const shouldGoInside =
          (isDroppable(hoveredNode.id) && side === DropEdge.middle) ||
          (explicitExpandedNodeIds.includes(hoveredNode.id) &&
            hoveredNode.children.length > 0 &&
            side === DropEdge.afterCurrentNode);

        previewId = hoveredNode.id;
        dropParentId = shouldGoInside ? hoveredNode.id : hoveredParentNode.id;
        dropPosition = shouldGoInside ? 0 : index + 1;
      }

      if (
        dropPosition &&
        dropPosition === idToNodeData[dropParentId]!.node.children.length
      ) {
        let currentId =
          idToNodeData[dropParentId]!.node.children[dropPosition - 1]!.id;

        while (idToParentNode[currentId]) {
          const parent = idToParentNode[currentId];
          if (!parent) {
            break;
          }
          const isCurrentLastChild =
            parent.children[parent.children.length - 1]!.id === currentId;

          const currentDistanceLeft =
            idToTreeNodeLabel.current[currentId]!.getBoundingClientRect()
              .left || 0;

          if (
            clientX <= currentDistanceLeft &&
            isCurrentLastChild &&
            !explicitExpandedNodeIds.includes(previewId) &&
            idToParentNode[parent.id]
          ) {
            currentId = parent.id;
            dropParentId = idToParentNode[parent.id]!.id;
            dropPosition =
              idToParentNode[parent.id]!.children.findIndex(
                (node) => node.id === parent.id,
              ) + 1;
          } else {
            break;
          }
        }
      }

      return {
        previewId,
        dropParentId,
        dropPosition,
      };
    },
    [store, isDroppable],
  );

  const allowDrop = React.useCallback(
    ({
      newParentNodeId,
    }: ReorderableTreeDropTarget): {
      canMove: boolean;
      message: string | null;
    } => {
      const storeState = store.getState();
      const componentMapping = selectComponentMapping(storeState);
      const draftComponentIds = selectDraftComponentIds(storeState);
      const newPossibleParent = getFromRecordOrNull(
        componentMapping,
        newParentNodeId,
      )?.component;

      if (!newPossibleParent) {
        return { canMove: false, message: "Parent not found" };
      }

      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);

      let errorMessage: string | null = null;
      const result = [...draftComponentIds].every((componentId: string) => {
        const draggedComponent = getFromRecordOrNull(
          componentMapping,
          componentId,
        )?.component;
        if (!draggedComponent || !draftElement) {
          return false;
        }

        const result = canMoveComponentToParent(
          draftElement,
          draggedComponent,
          newPossibleParent,
          selectGetAttribute(storeState),
          "componentTree",
          selectComponentDataMapping(storeState),
        );
        if (!result.canMove && !errorMessage) {
          errorMessage = result.message;
        }
        return result.canMove;
      });

      return {
        canMove: result,
        message: errorMessage,
      };
    },
    [store],
  );

  const onDragStart = React.useCallback(
    (nodeId: string) => {
      const draftComponentIds = selectDraftComponentIds(store.getState());
      if (!draftComponentIds.includes(nodeId)) {
        setDraftElement({
          componentIds: [nodeId],
        });
      }
    },
    [store, setDraftElement],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Biome is wrong about this
  const onDragHover = React.useCallback(
    (clientX: number, hoveredNodeId: string, side: DropEdge) => {
      // Note: Ovishek (2022-03-19):
      // previewId: means the node id we will show the dropIndicator for
      // we introduced it b/c for example, if we hover over upper 50% of a text component, it should show drop indicator
      // on the top of it, which basically means we need to find the top node's id and get the html element
      // to figure out the position of it, it's being used for visual, isn't being used in any functionalities/logics.
      //
      // dropParentId: means the parent node id we are trying to dropping into
      //
      // dropPosition: means the position we are trying to drop into based on the siblings position

      const { previewId, dropParentId, dropPosition } =
        getDropTargetAndPreviewId(clientX, hoveredNodeId, side);

      const dropTarget = {
        newParentNodeId: dropParentId,
        positionWithinChildren: dropPosition,
      };

      clearOrSetTimerForExpansion(previewId, side);

      onChangeDropPreview(
        previewId,
        dropParentId,
        allowDrop(dropTarget).canMove,
      );
      setParentIdToShowHover(dropParentId);
      isDraggingRef.current = true;
    },
    [
      getDropTargetAndPreviewId,
      clearOrSetTimerForExpansion,
      allowDrop,
      onChangeDropPreview,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Biome is wrong about this
  const onDrop = React.useCallback(
    (clientX: number, hoveredNodeId: string, side: DropEdge) => {
      const storeState = store.getState();
      const draftComponentIds = selectDraftComponentIds(storeState);
      const { idToNodeData } = selectIdToTreeNodeData(storeState);

      const { dropParentId, dropPosition } = getDropTargetAndPreviewId(
        clientX,
        hoveredNodeId,
        side,
      );

      const dropTarget = {
        newParentNodeId: dropParentId,
        positionWithinChildren: dropPosition,
      };

      const componentMapping = selectComponentMapping(storeState);

      const [firstSelectedId] = draftComponentIds;
      const draggedComponent = getFromRecordOrNull(
        componentMapping,
        firstSelectedId ?? null,
      )?.component;

      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);

      const doesAllowDrop = allowDrop(dropTarget);
      if (!draggedComponent || !draftElement || !doesAllowDrop.canMove) {
        if (doesAllowDrop.message) {
          errorToast(
            "Dragging not possible",
            doesAllowDrop.message,
            "error.component.drop",
            {
              error: doesAllowDrop.message,
            },
          );
        }
        return;
      }

      const newPossibleParent = getFromRecordOrNull(
        componentMapping,
        dropTarget.newParentNodeId,
      )?.component;

      if (!newPossibleParent) {
        return;
      }

      const result = canMoveComponentToParent(
        draftElement,
        draggedComponent,
        newPossibleParent,
        selectGetAttribute(storeState),
        "componentTree",
        selectComponentDataMapping(storeState),
      );
      if (!result.canMove) {
        errorToast(
          "Dragging not possible",
          result.message,
          "error.component.drop",
          {
            error: result.message,
          },
        );
      } else if (newPossibleParent) {
        applyComponentAction({
          type: "moveMultipleComponentsToParent",
          componentIds: [...draftComponentIds],
          value: {
            parentComponentId: newPossibleParent.id,
            positionWithinSiblings: dropTarget.positionWithinChildren,
          },
          source: "componentTree",
        });
      }

      const explicitExpandedNodeIds = selectExplicitExpandedNodeIds(storeState);
      const dropParentNode = idToNodeData[dropParentId]!.node;
      if (!explicitExpandedNodeIds.includes(dropParentId)) {
        onChangeExpansion(dropParentNode.id, true, {
          expandAllDescendants: false,
        });
      }
      setParentIdToShowHover(null);
    },
    [
      store,
      getDropTargetAndPreviewId,
      allowDrop,
      applyComponentAction,
      onChangeExpansion,
      errorToast,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Biome is wrong about this
  const onDragEnd = React.useCallback(() => {
    onChangeDropPreview(null, null, true);
    isDraggingRef.current = false;
    setParentIdToShowHover(null);
  }, [onChangeDropPreview]);

  const currentCandidateNodeRef = React.useRef<HTMLElement | null>(null);
  const onHoverTreeNode = React.useCallback(
    (nodeId: string) => {
      const componentId =
        currentCandidateNodeRef.current?.getAttribute("data-rid");

      if (componentId !== nodeId) {
        const activeCanvas = selectActiveCanvas(store.getState());
        const newCandidateNode = getEditorComponentNode({
          canvas: activeCanvas,
          componentId: nodeId,
        });
        currentCandidateNodeRef.current = newCandidateNode;

        if (newCandidateNode) {
          dispatch(
            setCandidateNode({
              candidateNode: newCandidateNode,
              candidateCanvas: activeCanvas,
              componentNodeToDrag: newCandidateNode,
              componentIdToDrag: nodeId,
            }),
          );
        }
      }
    },
    [dispatch, store],
  );

  const contextMenuItems = useContextMenuItems("componentTree", () =>
    dispatch(setIsRenamingTreeNode(true)),
  );

  const onChangeSelection = React.useCallback(
    (e: React.MouseEvent, recentlySelectedId: string) => {
      const draftComponentIds = selectDraftComponentIds(store.getState());
      const componentDataMapping = selectComponentDataMapping(store.getState());
      const newDraftComponentIds = getSelectedComponentIds(
        recentlySelectedId,
        draftComponentIds,
        componentDataMapping,
        {
          event: e,
          source: "componentTree",
        },
      );

      if (
        !newDraftComponentIds ||
        isArrayUnOrderlyEqual(newDraftComponentIds, draftComponentIds)
      ) {
        return;
      }

      setDraftElement({
        componentIds: newDraftComponentIds,
      });
    },
    [store, setDraftElement],
  );

  React.useEffect(() => {
    if (draftComponentId) {
      // Scroll in component tree to show selected node
      // Note(Noah): If we don't do this setTimeout, nodes which are not expanded
      // will not be able to be scrolled to. I think this is due to antd animating
      // the expansion. I'm not sure if there's a way to get around this, but
      // the setTimeout works for now
      setTimeout(() => {
        const nodeToScrollTo = document.querySelector(
          `#${reorderableTreeNodeIdPrefix}-${draftComponentId}`,
        );

        if (nodeToScrollTo) {
          nodeToScrollTo.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "nearest",
          });
        }
      }, 200);
    }
    dispatch(setIsRenamingTreeNode(false));
  }, [dispatch, draftComponentId]);

  const expandNodesForSelectedComponent = React.useCallback(
    (selectedNodeId: string) => {
      const componentMapping = selectComponentMapping(store.getState());
      const explicitExpandedNodeIds = selectExplicitExpandedNodeIds(
        store.getState(),
      );
      const parent = getParentComponentFromMapping(
        componentMapping,
        selectedNodeId,
      );
      const nodeIdToExpand = parent?.id ?? selectedNodeId;

      const ancestorComponentIds = selectAncestorComponentIds(
        store.getState(),
        nodeIdToExpand,
      );
      const newExpandedNodeIdsSet = new Set(
        [explicitExpandedNodeIds, nodeIdToExpand, ancestorComponentIds].flat(),
      );

      if (
        !isArrayUnOrderlyEqual(explicitExpandedNodeIds, [
          ...newExpandedNodeIdsSet,
        ])
      ) {
        dispatch(setExplicitExpandedNodeIds([...newExpandedNodeIdsSet]));
      }
    },
    [store, dispatch],
  );

  // Note (Noah, 2025-01-08): When the draftComponentId changes (e.g. via a click
  // in the canvas) we want to expand nodes such that the new selected component
  // becomes visible.
  React.useEffect(() => {
    if (draftComponentId) {
      expandNodesForSelectedComponent(draftComponentId);
    }
  }, [draftComponentId, expandNodesForSelectedComponent]);

  if (isLoadingTreePane && draftElementExists) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner size={30} variant="primary" />
      </div>
    );
  }

  return (
    <Scrollable
      className="no-scrollbar w-full grow overflow-scroll"
      shouldShowScrollbar={false}
    >
      <Menu
        menuType="context"
        items={contextMenuItems}
        customWidth={280}
        trigger={
          <ul
            className="group flex flex-col"
            onMouseLeave={() => setParentIdToShowHover(null)}
            data-testid="tree"
          >
            <div
              ref={dropIndicatorRef}
              style={{
                background: "black",
                order: -1,
                height: dropIndicatorHeight,
              }}
              className="z-max pointer-events-none fixed"
            />
            {renderedTreeNodeIds.map((id) => {
              return (
                <TreeNode
                  onChangeSelection={onChangeSelection}
                  onChangeExpansion={onChangeExpansion}
                  onDragStart={onDragStart}
                  onMountTreeRowNode={onMountTreeRowNode}
                  onMountTreeRowNodeLabel={onMountTreeRowNodeLabel}
                  onHoverTreeNode={onHoverTreeNode}
                  key={id}
                  nodeId={id}
                  onDragHover={onDragHover}
                  onDrop={onDrop}
                  onDragEnd={onDragEnd}
                  isDroppable={isDroppable}
                  elementType={elementType}
                />
              );
            })}
            <DroppableEndTreeNode onDragHover={onDragHover} onDrop={onDrop} />
          </ul>
        }
      />
    </Scrollable>
  );
};

type TreeProps = {
  onChangeSelection(e: React.MouseEvent, recentlySelectedId: string): void;
  onChangeExpansion(
    nodeId: string,
    isExpanded: boolean,
    options: { expandAllDescendants: boolean },
  ): void;
  onDragStart(nodeId: string): void;
  onDragHover(clientX: number, hoveredNodeId: string, side: DropEdge): void;
  onDrop(clientX: number, hoveredNodeId: string, side: DropEdge): void;
  onDragEnd: () => void;
  onHoverTreeNode?(nodeId: string): void;
  onMountTreeRowNodeLabel(node: HTMLDivElement | null, id: string): void;
  onMountTreeRowNode(node: HTMLLIElement | null, id: string): void;
  isDroppable(nodeId: string): boolean;
  nodeId: string;
  elementType: ReploElementType;
};

const TreeNode: React.FC<TreeProps> = React.memo(function _TreeNode(props) {
  const {
    nodeId,
    onDrop,
    onDragEnd,
    onDragStart,
    onDragHover,
    onMountTreeRowNode,
    onMountTreeRowNodeLabel,
    onHoverTreeNode,
    onChangeSelection,
    onChangeExpansion,
    isDroppable,
    elementType,
  } = props;

  const component = useEditorSelector((state) => {
    return selectComponent(state, nodeId);
  });
  const componentData = useEditorSelector((state) => {
    return selectComponentData(state, nodeId);
  });
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isSymbolRef = component?.type === "symbolRef";

  const depth = componentData?.depth ?? 0;
  const order = componentData?.order ?? 0;

  const isExpanded = useEditorSelector((state) =>
    selectIsExpandedTreeNode(state, nodeId),
  );
  const isPageRoot = depth === 0;
  const nodeLabel = (() => {
    if (isPageRoot) {
      return elementTypeToEditorData[elementType].singularDisplayName;
    }
    return componentData?.label ?? "Component";
  })();

  const [isHovered, setIsHovered] = React.useState(false);
  const [treeRowMeasureRef, { height: treeRowHeight }] = useMeasure();

  const treeRowWrapperRef = React.useRef<HTMLDivElement>(null);
  const isRenaming = useEditorSelector(selectIsRenamingTreeNode);
  const isAncestorHidden = useEditorSelector((state) =>
    selectIsAncestorHidden(state, nodeId),
  );
  const isAncestorSelected = useEditorSelector((state) =>
    selectIsAncestorSelected(state, nodeId),
  );

  const onTreeRowClickHandler = (e: React.MouseEvent) => {
    onChangeSelection?.(e, nodeId);
  };

  const getDropEdgeFromMonitor = (monitor: DropTargetMonitor) => {
    const { y } = monitor.getClientOffset() || {};
    const { top: currRowTop = y } =
      treeRowWrapperRef.current?.getBoundingClientRect() || {};

    return getDropEdge(
      currRowTop ?? 0,
      y ?? 0,
      treeRowHeight,
      isDroppable(nodeId),
      isExpanded,
    );
  };

  const [{ isDragging }, drag, dragPreview] = useDrag(
    {
      type: "box",
      item: { id: nodeId },
      end: () => {
        onDragEnd();
      },
      collect: (monitor) => {
        return { isDragging: monitor.isDragging() };
      },
      canDrag: () => {
        return !isRenaming;
      },
    },
    [nodeId, onDragEnd, isRenaming],
  );

  const isDraggingRef = React.useRef(isDragging);
  React.useEffect(() => {
    const previousIsDragging = isDraggingRef.current;
    isDraggingRef.current = isDragging;
    // NOTE: Ovishek (2022-02-15) this is a hack we are doing b/c react-dnd doesn't support on drag start
    if (!previousIsDragging && isDragging) {
      onDragStart?.(nodeId);
      setIsHovered(false);
    }
  }, [isDragging, nodeId, onDragStart]);

  const previousCoordinates = React.useRef<Position>({ x: 0, y: 0 });

  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(_, monitor) {
        onDrop(
          monitor.getClientOffset()!.x,
          nodeId,
          getDropEdgeFromMonitor(monitor),
        );
      },
      hover(_, monitor) {
        const { y = 0, x = 0 } = monitor.getClientOffset() || {};
        // Note (Ovishek, 2022-07-14) We don't wanna over call onDragHover function so
        if (
          isCloseTo(previousCoordinates.current.x, x, 5) &&
          isCloseTo(previousCoordinates.current.y, y, 5)
        ) {
          return;
        }

        previousCoordinates.current.x = x;
        previousCoordinates.current.y = y;

        const { top: currRowTop = y } =
          treeRowWrapperRef.current?.getBoundingClientRect() || {};
        const dropEdge = getDropEdge(
          currRowTop,
          y,
          treeRowHeight,
          isDroppable(nodeId),
          isExpanded,
        );
        onDragHover(x, nodeId, dropEdge);
      },
    }),
    [
      nodeId,
      treeRowHeight,
      onDragHover,
      isExpanded,
      onDrop,
      getDropEdgeFromMonitor,
      getDropEdge,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    // NOTE: Ovishek (2022-02-15) this call overrides default drag preview with empty image,
    // because we don't wanna show anything when dragging
    // For reference see getEmptyImage on https://react-dnd.github.io/react-dnd/docs/backends/html5
    dragPreview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDraftComponent = useEditorSelector((state) =>
    selectIsDraftComponent(state, nodeId),
  );

  const isCurrentComponentHidden = useEditorSelector((state) =>
    selectIsComponentHidden(state, nodeId),
  );

  const isRowHidden = isCurrentComponentHidden || isAncestorHidden;

  let rowSelectionColor = isSymbolRef ? "bg-purple-200" : "bg-selected";
  if (isRowHidden || isAncestorSelected) {
    if (isSymbolRef) {
      rowSelectionColor = "bg-purple-50";
    } else {
      rowSelectionColor = "bg-blue-50";
    }
  }

  //  Note (Juan, 2024-11-06): Automatically expands the page root node when mounted.
  //  This ensures the root level components are always visible to the user
  //  without requiring manual expansion.
  // Note (Evan, 2024-11-21): We want empty deps here so that we just run this on mount (meaning a
  // user can manually toggle, but it starts in an expanded state).
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to only run on mount
  React.useEffect(() => {
    if (isPageRoot && !isExpanded) {
      onChangeExpansion(nodeId, true, {
        expandAllDescendants: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!component || !componentData) {
    return null;
  }

  return (
    <li
      ref={(node) => onMountTreeRowNode(node, nodeId)}
      style={{ order }}
      className={twMerge(
        "cursor-default border border-solid border-transparent",
        isHovered && "border-blue-700",
        (isDraftComponent || isAncestorSelected) && rowSelectionColor,
      )}
      id={`${reorderableTreeNodeIdPrefix}-${nodeId}`}
      onClick={onTreeRowClickHandler}
      onContextMenu={onTreeRowClickHandler}
      onMouseMove={() => {
        setIsHovered(true);
        onHoverTreeNode?.(nodeId);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div ref={treeRowWrapperRef} className="relative">
        <div ref={drop}>
          <div data-testid="tree-node" ref={drag}>
            <div
              ref={treeRowMeasureRef}
              style={{
                marginLeft: depth * treeNodeGap,
              }}
            >
              <div
                className="flex h-[30px] items-center text-default"
                style={{ padding: treeNodePadding }}
              >
                <div className="w-[20px]">
                  <Chevron
                    nodeId={nodeId}
                    nodeLabel={nodeLabel}
                    onChangeExpansion={onChangeExpansion}
                    isExpanded={isExpanded}
                  />
                </div>
                <div
                  className="flex-1"
                  ref={(node) => onMountTreeRowNodeLabel(node, nodeId)}
                >
                  <ItemLabel
                    nodeId={nodeId}
                    nodeLabel={nodeLabel}
                    isHovered={isHovered}
                    isAncestorHidden={isAncestorHidden}
                    isCurrentComponentHidden={isCurrentComponentHidden}
                    isRowHidden={isRowHidden}
                    isPageRoot={isPageRoot}
                    component={component}
                    activeCanvas={activeCanvas}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
});

const DroppableEndTreeNode: React.FC<{
  onDragHover(clientX: number, hoveredNodeId: string, side: DropEdge): void;
  onDrop(clientX: number, hoveredNodeId: string, side: DropEdge): void;
}> = ({ onDrop, onDragHover }) => {
  const store = useEditorStore();
  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(_, monitor) {
        const lastVisibleTreeNodeId = selectLastVisibleTreeNodeId(
          store.getState(),
        );
        if (lastVisibleTreeNodeId) {
          onDrop(
            monitor.getClientOffset()!.x,
            lastVisibleTreeNodeId,
            DropEdge.afterCurrentNode,
          );
        }
      },
      hover(_, monitor) {
        const lastVisibleTreeNodeId = selectLastVisibleTreeNodeId(
          store.getState(),
        );
        if (lastVisibleTreeNodeId) {
          const { x = 0 } = monitor.getClientOffset() || {};
          onDragHover(x, lastVisibleTreeNodeId, DropEdge.afterCurrentNode);
        }
      },
    }),
    [onDragHover, onDrop],
  );
  return (
    <div
      style={{ order: Number.MAX_SAFE_INTEGER }}
      ref={drop}
      className="h-20 grow"
    />
  );
};

const Chevron: React.FC<{
  nodeId: string;
  nodeLabel: string;
  isExpanded: boolean;
  onChangeExpansion: TreeProps["onChangeExpansion"];
}> = ({ nodeId, nodeLabel, isExpanded, onChangeExpansion }) => {
  const doesNodeHaveChildren = useEditorSelector((state) =>
    selectDoesNodeHaveChildren(state, nodeId),
  );
  if (!doesNodeHaveChildren) {
    return <div></div>;
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onChangeExpansion(nodeId, !isExpanded, {
          expandAllDescendants: e.altKey,
        });
      }}
      className="flex items-center justify-center"
      style={{ height: "100%" }}
      role="button"
    >
      <div
        className="hidden group-hover:block"
        role="button"
        aria-label={
          isExpanded ? `Collapse ${nodeLabel}` : `Expand ${nodeLabel}`
        }
      >
        <MdArrowDropDown
          className="text-muted"
          style={{
            transform: `rotate(${isExpanded ? "0deg" : "-90deg"})`,
            transition: "all 300ms",
          }}
          size={12}
        />
      </div>
    </div>
  );
};

const ItemLabelInput = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    onRename: (newLabel: string) => void;
  }
>(({ label, onRename }, ref) => {
  const [newLabel, setNewLabel] = React.useState(label);

  const onRenameNode = React.useCallback(() => {
    if (newLabel.length > 0) {
      onRename(newLabel);
    }
  }, [newLabel, onRename]);

  return (
    <Input
      ref={ref}
      value={newLabel}
      onBlur={onRenameNode}
      onEnter={onRenameNode}
      onChange={(e) => setNewLabel(e.target.value)}
      autoFocus
    />
  );
});

ItemLabelInput.displayName = "ItemLabelInput";

const ItemLabel: React.FC<{
  nodeId: string;
  nodeLabel: string;
  isHovered: boolean;
  isAncestorHidden: boolean;
  isCurrentComponentHidden: boolean;
  isRowHidden: boolean;
  isPageRoot: boolean;
  component: Component;
  activeCanvas: EditorCanvas;
}> = React.memo(function _ItemLabel({
  nodeId,
  nodeLabel,
  isPageRoot,
  isHovered,
  isAncestorHidden,
  isCurrentComponentHidden,
  isRowHidden,
  component,
  activeCanvas,
}) {
  const treeIssue = useEditorSelector((state) =>
    selectTreeComponentIssue(state, nodeId),
  );

  const iconName = component?.props?.iconName;
  const componentType = component?.type;
  const isSymbolRef = componentType === "symbolRef";
  const direction = component?.props?.direction;
  const componentPosition = getComponentPositioning(
    editorCanvasToMediaSize[activeCanvas].mediaSize,
    component ?? undefined,
  );
  const componentAlignment = getComponentAlignment(
    editorCanvasToMediaSize[activeCanvas].mediaSize,
    component ?? undefined,
  );
  const componentHasActions = Boolean(
    component?.props?.onClick?.length || component?.props?.onHover?.length,
  );
  const componentHasAnimations = Boolean(component?.animations?.length);
  const { width, maxWidth, minWidth } = getComponentWidthProperties(
    editorCanvasToMediaSize[activeCanvas].mediaSize,
    component ?? undefined,
  );

  const dispatch = useEditorDispatch();
  const isRenaming = useEditorSelector(selectIsRenamingTreeNode);
  const allowHorizontalScroll = useEditorSelector(selectAllowHorizontalScroll);
  const modalsInTree = useEditorSelector(selectAreAnyComponentModals);
  const isDraftComponent = useEditorSelector((state) =>
    selectIsDraftComponent(state, nodeId),
  );

  const ancestorFlexDirection = useEditorSelector((state) =>
    selectAncestorFlexDirection(state, nodeId),
  );

  const store = useEditorStore();

  const applyComponentAction = useApplyComponentAction();

  const onRenameNode = React.useCallback(
    (newLabel: string) => {
      applyComponentAction({
        type: "updateComponentName",
        value: newLabel,
        componentId: nodeId,
      });
      dispatch(setIsRenamingTreeNode(false));
    },
    [dispatch, applyComponentAction, nodeId],
  );

  const onChangeComponentVisibility = React.useCallback(() => {
    const storeState = store.getState();
    const id = nodeId;
    const component = selectComponent(storeState, nodeId);
    if (!component) {
      return;
    }

    const getAttribute = selectGetAttribute(storeState);
    const actions: UseApplyComponentActionType[] = [
      "style@sm",
      "style@md",
      "style",
    ].map((breakpoint) => {
      const prevDisplayValue =
        getAttribute(component, `props.${breakpoint}.display`).value ||
        styleAttributeToEditorData["display"].defaultValue;
      const __prevDisplayValue =
        getAttribute(component, `props.${breakpoint}.__display`).value ||
        styleAttributeToEditorData["display"].defaultValue;

      const displayValues: Record<string, string> = {
        display: isCurrentComponentHidden ? __prevDisplayValue : "none",
      };
      if (!isCurrentComponentHidden && prevDisplayValue !== "none") {
        displayValues.__display = prevDisplayValue;
      }
      return {
        componentId: id,
        type: "setProps",
        value: {
          [breakpoint]: {
            ...displayValues,
          },
        },
      };
    });
    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });
  }, [applyComponentAction, store, isCurrentComponentHidden, nodeId]);

  const { icon, message } = getIconAndMessage(
    isPageRoot,
    direction,
    componentType,
    iconName,
    componentPosition,
    componentHasActions,
    nodeLabel,
    componentAlignment,
    ancestorFlexDirection,
  );

  const iconColor = getIconColorClassName(isRowHidden, isSymbolRef);
  const EyeIcon = isCurrentComponentHidden ? RiEyeCloseLine : MdRemoveRedEye;
  const eyeIconTooltipContent = isRowHidden
    ? "Show on All Device Sizes"
    : "Hide on All Device Sizes";

  const labelContents = message ? (
    <Tooltip content={message} triggerAsChild>
      <button
        type="button"
        aria-label={message}
        // NOTE (Chance 2023-11-02): Normally we'd want a tooltip trigger to be
        // tabbable, but in this case it's probably not a great user experience
        // since the parent item is focusable and this tooltip really provides
        // context to the row and not the icon itself. So it is still focusable but
        // excluded from the tab order.
        tabIndex={-1}
      >
        {icon}
      </button>
    </Tooltip>
  ) : (
    icon
  );

  const setInputRef = React.useCallback((input: HTMLInputElement | null) => {
    if (input) {
      input.select();
    }
  }, []);

  if (isRenaming && isDraftComponent) {
    return (
      <div
        className="grid w-full grid-cols-[16px_1fr]"
        onContextMenu={(e) => e.stopPropagation()}
      >
        <div className={twMerge("flex items-center justify-center", iconColor)}>
          {labelContents}
        </div>

        <div className="ml-2">
          <ItemLabelInput
            ref={setInputRef}
            label={nodeLabel}
            onRename={(newLabel: string) => {
              if (newLabel !== nodeLabel) {
                onRenameNode(newLabel);
              } else {
                dispatch(setIsRenamingTreeNode(false));
              }
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="grid w-full"
      style={{
        gridTemplateColumns: isPageRoot ? "16px 1fr 30px" : "16px 1fr 16px",
      }}
    >
      <div className={twMerge("flex items-center justify-center", iconColor)}>
        {labelContents}
      </div>
      <div
        onDoubleClick={() => {
          if (!isPageRoot) {
            dispatch(setIsRenamingTreeNode(true));
          }
        }}
        className={twMerge(
          "ml-2 flex h-[24px] select-none items-center justify-between text-[12px] font-normal leading-[14px] tracking-tight",
          isRowHidden && "text-muted",
          !allowHorizontalScroll && "overflow-hidden",
        )}
      >
        <div className="flex flex-row items-center">
          <span
            className={twMerge(
              "whitespace-nowrap",
              !allowHorizontalScroll && "overflow-hidden text-ellipsis",
            )}
          >
            {nodeLabel}
          </span>
          <TreeComponentPositionEnhancer nodeId={nodeId} />
          <TreeRowWidthBadge
            width={width}
            maxWidth={maxWidth}
            minWidth={minWidth}
            label={nodeLabel}
          />
        </div>

        {componentHasAnimations && !isCurrentComponentHidden && (
          <div className="mr-1 flex items-center justify-center text-violet-400">
            <Tooltip content={`${nodeLabel} has animations`} triggerAsChild>
              <button
                type="button"
                aria-label={`${nodeLabel} has animations`}
                tabIndex={-1}
              >
                <BsMask className="scale-[0.70]" />
              </button>
            </Tooltip>
          </div>
        )}

        {treeIssue && !isCurrentComponentHidden ? (
          <TreeRowIssueEnhancer tooltipMessage={treeIssue.message} />
        ) : null}
      </div>

      {!isPageRoot && (
        <TreeRowEndEnhancer
          onVisibilityChange={onChangeComponentVisibility}
          isVisuallyHidden={!(isCurrentComponentHidden || isHovered)}
        >
          {!isAncestorHidden && (
            <Tooltip content={eyeIconTooltipContent} triggerAsChild>
              <button
                type="button"
                aria-label={eyeIconTooltipContent}
                tabIndex={0}
              >
                <EyeIcon
                  className={twMerge(
                    "text-xs text-muted",
                    isRowHidden ? "text-slate-200" : "",
                  )}
                />
              </button>
            </Tooltip>
          )}
        </TreeRowEndEnhancer>
      )}
      {isPageRoot && modalsInTree && <TreeModalSelector />}
    </div>
  );
});

const TreeRowEndEnhancer: React.FC<
  React.PropsWithChildren<{
    isVisuallyHidden?: boolean;
    onVisibilityChange: () => void;
  }>
> = ({ isVisuallyHidden, onVisibilityChange, children }) => {
  return (
    // NOTE (Chance 2023-11-02): This should be a button since it's clickable.
    // Test to make sure changing it doesn't result in multiple nested buttons
    // before changing.
    <div
      onClick={(e) => {
        e.stopPropagation();
        onVisibilityChange();
      }}
      className={twMerge(
        "h-full cursor-pointer items-center justify-center",
        isVisuallyHidden ? "hidden" : "flex",
      )}
    >
      {children}
    </div>
  );
};

const TreeRowIssueEnhancer: React.FC<{
  tooltipMessage: string;
}> = ({ tooltipMessage }) => {
  return (
    <div className="mr-1 flex items-center justify-center text-muted">
      <Tooltip content={tooltipMessage} triggerAsChild>
        <button type="button" aria-label={tooltipMessage} tabIndex={-1}>
          <BsExclamationCircleFill size={12} />
        </button>
      </Tooltip>
    </div>
  );
};

const TreeComponentPositionEnhancer: React.FC<{ nodeId: string }> = ({
  nodeId,
}) => {
  const componentPosition = useGetComponentPosition(nodeId);
  if (!componentPosition) {
    return null;
  }

  const isIndexGreaterThanAncestorLength =
    componentPosition.index > componentPosition.ancestorLength;

  if (isIndexGreaterThanAncestorLength) {
    return (
      <div className="mx-2">
        <TreeRowIssueEnhancer tooltipMessage="This content will not be displayed" />
      </div>
    );
  }

  return (
    <div className="ml-2 rounded bg-blue-600 text-white text-[10px] px-1 py-0.5 flex items-center justify-center">
      {componentPosition.index}/{componentPosition.ancestorLength}
    </div>
  );
};

function useGetComponentPosition(nodeId: string) {
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const getChildrenDataFromMapping = React.useCallback(
    (parentData?: ComponentData) => {
      if (!parentData) {
        return [];
      }
      return parentData.containedComponentData.filter(
        ([, , level]) => level === 1,
      );
    },
    [],
  );
  const getIndex = React.useCallback(
    (children: ComponentData["containedComponentData"], nodeId: string) =>
      children?.findIndex(([componentId]) => componentId === nodeId) ?? 0,
    [],
  );

  return React.useMemo(() => {
    const componentData = componentDataMapping[nodeId];
    const componentParentData = componentData?.ancestorComponentData.at(-1);
    const [componentParentId, componentParentType] = componentParentData ?? [];
    if (
      componentParentId &&
      (componentParentType === "tabsV2__list" ||
        componentParentType === "tabsV2__panelsContent")
    ) {
      const parentComponentdata = componentDataMapping[componentParentId];
      if (parentComponentdata) {
        const tabBlockData = parentComponentdata?.ancestorComponentData.at(-1);
        const [tabBlockId, tabBlockType] = tabBlockData ?? [];
        if (tabBlockId && tabBlockType === "tabsV2__block") {
          const tabsBlockComponentData = componentDataMapping[tabBlockId];
          const tabListData =
            tabsBlockComponentData?.containedComponentData.find(
              ([, type]) => type === "tabsV2__list",
            );
          if (tabListData) {
            const [tabListId] = tabListData;
            const tabListComponentData = componentDataMapping[tabListId];
            const tabListChildren =
              getChildrenDataFromMapping(tabListComponentData);

            if (
              tabsBlockComponentData &&
              componentParentType === "tabsV2__list"
            ) {
              const tabIndex = getIndex(tabListChildren, nodeId);
              return {
                ancestorLength: tabListChildren?.length ?? 0,
                index: tabIndex + 1,
              };
            } else if (
              tabsBlockComponentData &&
              componentParentType === "tabsV2__panelsContent"
            ) {
              const tabListData =
                tabsBlockComponentData?.containedComponentData.find(
                  ([, type]) => type === "tabsV2__list",
                );
              if (tabListData) {
                const tabContentChildren =
                  getChildrenDataFromMapping(parentComponentdata);
                const index = getIndex(tabContentChildren, nodeId);
                return {
                  // NOTE (Fran 2024-05-23): We should always match the length of the tabs list because is the way
                  // we are going to render in the canvas. If a tab content is missing we will not render anything but
                  // the tab list will always be rendered. If the tab list is missing we will not render anything.
                  ancestorLength: tabListChildren?.length ?? 0,
                  index: index + 1,
                };
              }
            }
          }
        }
      }
    }

    return null;
  }, [componentDataMapping, getChildrenDataFromMapping, getIndex, nodeId]);
}
