import type { DynamicDataStore } from "replo-runtime/shared/runtime-context";
import type {
  ReploShopifyProduct,
  ReploShopifyVariant,
  ShopifySellingPlan,
  VariantWithState,
} from "replo-runtime/shared/types";
import type { RenderChildren } from "replo-runtime/shared/utils/renderComponents";
import type { GlobalWindow } from "replo-runtime/shared/Window";
import type { DateFormatOptions, Formatter } from "schemas/dynamicData";
import type { DesignLibrary } from "schemas/generated/designLibrary";
import type { ReploElementType } from "schemas/generated/element";
import type {
  ProductRefOrDynamic,
  SelectedSellingPlanIdOrOneTimePurchase,
} from "schemas/product";

import { format as formatDate } from "date-fns";
import assign from "lodash-es/assign";
import get from "lodash-es/get";
import has from "lodash-es/has";
import isObject from "lodash-es/isObject";
import uniq from "lodash-es/uniq";
import { formatCurrencyWithShopifyMoneyFormat } from "replo-runtime/shared/liquid";
import {
  getSavedStyleAttributeValue,
  getSavedStyleId,
} from "replo-runtime/shared/savedStyles";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { firstGroupMatchOfExpression } from "replo-runtime/shared/utils/regex";
import { getAlchemyGlobalPaintContext } from "replo-runtime/shared/Window";
import { wrapStringWithLiquidChunks } from "replo-runtime/store/components/ReploLiquid/ReploLiquidChunk";
import {
  applyLiquidFormatting,
  getLiquidString,
} from "replo-runtime/store/utils/liquid";
import isLiquidAllowed from "replo-runtime/utils/isLiquidAllowed";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { formatterSchema } from "schemas/dynamicData";
import z from "zod";

type DropdownValue = {
  type: "dataTable";
  dataTableId: string;
  index: number;
  item: Record<string, any>; // data table row (column name -> value)
}; // When there are other types of dropdowns, add them here in an | type

export const topLevelDynamicDataKeys = [
  "_product",
  "_variant",
  "_variants",
  "_sellingPlans",
  "_currentVariant",
  "_autoSelectVariant",
  "_defaultSelectedVariantId",
  "_optionsValues",
  "_selectedOptionValues",
  "_quantity",
  "_options",
  "_autoEnableHashmark",
  "_accessibilityChecked",
  "_accessibilityRole",
  "_accessibilityHidden",
  "_currentItem",
  "_currentTabItem",
  "_products",
  "_currentSelection",
  "secondsUntilEnd",
  "_currentOption",
  "_temporaryCartItems",
  "_currentTemporaryCartItem",
  "_temporaryCartTotalPrice",
  "_currentOptionValue",
  "minutesUntilEnd",
  "_temporaryCartTotalPriceIncludingDiscounts",
  "hoursUntilEnd",
  "daysUntilEnd",
  "_endTime",
  "_selectedSellingPlan",
  "_currentSellingPlan",
  "_swatches",
  "_templateProduct",
] as const;

export type TopLevelDynamicDataKey = (typeof topLevelDynamicDataKeys)[number];
export const isTopLevelDynamicDataKey = (
  field: string,
): field is TopLevelDynamicDataKey => {
  return (topLevelDynamicDataKeys as readonly string[]).includes(field);
};
/**
 * A context is a random blob of data passed down from components to their children.
 *
 * Components can use context to inter-connect and pass data (for example, Product component
 * passing options to an Option List component), to provide info about the current state (for
 * example, which modal is open, etc) to enable/disable certain actions and state triggers, and
 * more.
 *
 * Basically, this is equivalent to a React Context which is shared across the whole AlchemyElement
 * React tree.
 */
export interface Context {
  state: Partial<{
    productWrapperComponentId?: string;
    product: Partial<{
      product: ReploShopifyProduct | null;
      selectedOptionValues: Record<string, string | null> | null;
      selectedVariant?: ReploShopifyVariant;
      quantity: number;
      selectedSellingPlan: {
        id: number | null;
      };
      sellingPlans: ShopifySellingPlan[];
    }>;
    [propName: string]: any;
  }>;
  elementId: string;
  openModalComponent?: { id: string; repeatedIndex: string | null } | null;
  variants?: VariantWithState[];
  ancestorWithVariantsId?: string;
  selfOrParentHasVariants: boolean;
  repeatedIndexPath: string;
  actionHooks: {
    toggleCollapsible?(): void;
    setSelectedListItem?(): void;
    openModal?(componentId: string, repeatedIndex: string | null): void;
    setCurrentCollectionSelection?: (index: number) => void;
    setDropdownItem?: (index: number) => void;
    increaseProductQuantity?: (quantity: number) => void;
    decreaseProductQuantity?: (quantity: number) => void;
    setProductQuantity?: (quantity: number) => void;
    setActiveOptionValue?: (payload: { label: string; value: string }) => void;
    updateCurrentProduct?: (payload: ProductRefOrDynamic) => void;
    setActiveSellingPlan?: (
      payload: {
        sellingPlanId: SelectedSellingPlanIdOrOneTimePurchase;
      } | null,
    ) => void;
    scrollToSpecificCarouselItem?: (index: number) => void;
    goToItem?: (index: number) => void;
    goToNextItem?: () => void;
    goToPrevItem?: () => void;
    activateTabId?: (tabId: string) => void;
    setDropdownValue?: (componentId: string, value: any) => void;
    setActiveTabIndex?: (activeIndex: number) => void;
    scrollToNextCarouselItem?: () => void;
    scrollToPreviousCarouselItem?: () => void;
    decreaseVariantCountInTemporaryCart?: (
      product: ReploShopifyProduct,
    ) => void;
    removeVariantFromTemporaryCart?: (product: ReploShopifyProduct) => void;
    addVariantToTemporaryCart?: (product: ReploShopifyProduct) => void;
    setActiveVariant?: (variantId: number) => void;
    toggleDropdown?: () => void;
    scrollContainerRight?: (scrollAmount: number) => void;
    scrollContainerLeft?: (scrollAmount: number) => void;
    toggleFullScreen?: () => void;
    toggleMute?: () => void;
    togglePlay?: () => void;
    closeCurrentModal?: () => void;
  };
  dropdownValues: Record<string, DropdownValue>;
  tabs?: any;
  group?: {
    isDynamic: boolean;
    items: RenderChildren;
    isFirstItem: boolean;
    isLastItem: boolean;
    isActiveItem?: boolean;
    total: number;
    currentIndex: number;
  };
  attributes?: Partial<Record<TopLevelDynamicDataKey, any>>;
  attributeKeyToComponentId?: Partial<Record<TopLevelDynamicDataKey, string>>;
  elementType?: ReploElementType;
  isPublishing?: boolean;
  componentOverrides?: Record<string, any>;
  isInsideProductComponent?: boolean;
  isInsideTemplateProductComponent?: boolean;
  isInsideProductImageCarousel?: boolean;
  isInsideSelectionList?: boolean;
  disableLiquid?: boolean;
  componentReferencesTemplateProduct?: boolean;
  useSectionSettings?: boolean;
  store?: DynamicDataStore;
  klaviyoEmbedCode?: string;
  globalWindow: GlobalWindow | null;
  designLibrary?: DesignLibrary;
}

export type ContextKey = keyof Context;

// #region Dynamic Data V2

// NOTE (Matt 2025-03-11): Due to the complex nature with which we're asserting
// the existence of keys of this object, it can be difficult to read here on its face.
// My recommendation is to refer to `FormatterRequirements` and `PathRequirements`
// to understand what key/value paris must be passed along for a given DynamicDataReference
export type DynamicDataResolutionConfig = {
  currencyCode?: string;
  language?: string;
  moneyFormat?: string | null;
  selectedSellingPlan?: ShopifySellingPlan;
};

/**
 * Given a string, finds balanced occurrences of "{{" and "}}" that are not
 * nested. Useful for finding all dynamic data value references in a string,
 * given that there may be nested formatter json in the string.
 *
 * E.g. for "Hello {{ hello| formatters"{"s": {}} }} hello", this will correctly
 * return only "{{ hello| formatters"{"s": {}} }}". As far as I understand this
 * is not possible using regexes alone.
 */
export function findBalancedDoubleBrackets(text: string): string[] {
  const matches: string[] = [];
  const stack: { start: number }[] = [];

  if (!/{{/.test(text)) {
    return [];
  }

  for (let i = 0; i < text.length; i++) {
    if (text[i] === "{") {
      stack.push({ start: i }); // Start a new block
    } else if (text[i] === "}" && stack.length > 0) {
      const { start } = stack.pop()!;
      const possibleMatch = text.slice(start, i + 1);
      if (possibleMatch.startsWith("{{") && possibleMatch.endsWith("}}")) {
        matches.push(possibleMatch);
      }
    }
  }

  return matches;
}

function applyFormatting(
  value: string,
  formatters: Formatter[],
  resolutionConfig?: DynamicDataResolutionConfig,
): string {
  if (!formatters || formatters.length === 0) {
    return value;
  }
  let result = value;
  const isRounded = formatters.some(({ type }) => type === "rounded");
  for (const formatter of formatters) {
    result = exhaustiveSwitch({ ...formatter, result })({
      discount: ({ result }) =>
        applySellingPlanDiscount(result, resolutionConfig).toString(),
      rounded: ({ result }) => Math.round(Number(result)).toString(),
      currency: ({ result }) =>
        applyCurrencyFormatting(result, isRounded, resolutionConfig),
      date: ({ result, dateOptions }) =>
        applyDateFormatting(result, dateOptions),
    });
  }
  return result;
}

function applySellingPlanDiscount(
  value: string,
  resolutionConfig?: DynamicDataResolutionConfig,
) {
  const selectedSellingPlan: ShopifySellingPlan | undefined =
    resolutionConfig?.selectedSellingPlan;
  if (
    isNaN(Number(value)) ||
    !selectedSellingPlan ||
    !selectedSellingPlan.priceAdjustments[0]
  ) {
    return value;
  }
  let price = Number(value);
  // NOTE (Matt 2025-03-07): This data structure is an array but there
  // is only ever one price adjustment. Because Shopify ¯\_(ツ)_/¯
  const firstAdjustment = selectedSellingPlan.priceAdjustments[0];

  price = exhaustiveSwitch({ type: firstAdjustment.value_type, price })({
    percentage: () => price - price * (firstAdjustment.value / 100),
    fixed_amount: () => price - firstAdjustment.value,
    price: () => Number(firstAdjustment.value),
  });

  return price.toFixed(2);
}

function applyCurrencyFormatting(
  value: string,
  isRounded: boolean,
  config?: DynamicDataResolutionConfig,
): string {
  return formatCurrencyWithShopifyMoneyFormat(value, {
    currencyCode: config?.currencyCode ?? "USD",
    language: config?.language ?? "en-US",
    moneyFormat: config?.moneyFormat ?? null,
    showCents: !isRounded,
  });
}

function applyDateFormatting(
  value: string,
  dateOptions: DateFormatOptions,
): string {
  const dateValue = new Date(String(value));
  let format = "";

  if (dateOptions.format === "text") {
    // NOTE (Matt 2025-03-11): Text format (e.g., "January 1, 2023" or "Monday, January 1, 2023")
    const parts = [];

    // Add weekday if requested
    if (dateOptions.includeWeekday) {
      parts.push("EEEE");
    }

    // Add month as full text
    parts.push("MMMM d");

    // Add year if requested
    if (dateOptions.includeYear) {
      parts.push("yyyy");
    }

    format = parts.join(", ");
  } else if (dateOptions.format === "number") {
    // NOTE (Matt 2025-03-11): Number format (e.g., "01/31/2023" or "31-01-2023")
    const separator = dateOptions.separator;

    if (dateOptions.order === "month-first") {
      format = `MM${separator}dd`;
    } else {
      format = `dd${separator}MM`;
    }
    if (dateOptions.includeYear) {
      format += `${separator}yyyy`;
    }
  }
  return formatDate(dateValue, format);
}

// #endregion

export const evaluateVariable = (
  v: string | null,
  context?: Context,
  resolutionConfig?: DynamicDataResolutionConfig,
): unknown => {
  if (v == null) {
    return null;
  }
  // NOTE (Matt 2025-03-04): If v is a string, then it is the old dynamic data format.
  if (typeof v === "string") {
    return compileHandlebars(v, context, resolutionConfig);
  }
};

export const evaluateVariableAsString = (
  v: string | null,
  context?: Context,
  resolutionConfig?: DynamicDataResolutionConfig,
): string | undefined => {
  const evaluatedValue = evaluateVariable(v, context, resolutionConfig);
  if (typeof evaluatedValue === "string") {
    return evaluatedValue;
  }
  return undefined;
};

export const resolveDynamicDataPath = (
  dynamicDataPathString: string,
  formatMatch: string | null,
  context: Context,
  formatters: Formatter[] | null,
  resolutionConfig?: DynamicDataResolutionConfig,
) => {
  if (isDynamicDesignLibraryValue(dynamicDataPathString)) {
    const savedStyleId = getSavedStyleId(dynamicDataPathString);
    const savedStyleData = savedStyleId
      ? context?.designLibrary?.savedStyles[savedStyleId]
      : null;

    if (!savedStyleData) {
      return null;
    }

    return getSavedStyleAttributeValue(savedStyleData, dynamicDataPathString);
  }

  // NOTE (Sebas, 2024-09-17): We need to check if the element type allows liquid
  // because we don't want to convert dynamic data to liquid for elements that
  // don't support it (e.g. blogs).
  const allowsLiquid = isLiquidAllowed({
    elementType: context?.elementType,
    isPublishing: context?.isPublishing,
    disableLiquid: context?.disableLiquid,
  });

  // NOTE (Gabe 2023-07-20): If we're publishing a product, we want to convert
  // the dynamicDataString to a liquid representation so Shopify is able to
  // inject the correct data while serving the page.

  let contextKey = dynamicDataPathString;
  if (formatMatch) {
    contextKey = contextKey.replace(formatMatch, "").trim();
  }

  if (
    allowsLiquid &&
    (context?.isInsideProductComponent || context?.useSectionSettings)
  ) {
    let liquidString = getLiquidString(contextKey, context);
    if (liquidString && formatters) {
      liquidString = applyLiquidFormatting(liquidString, formatters);
    }
    if (liquidString) {
      return wrapStringWithLiquidChunks(liquidString);
    }
  }

  let contextValue: any = get(context, contextKey, null);

  // Note (Evan, 2024-03-13): If we get null, it's possible that the contextKey has dots
  // in it. We can try to parse out the value via a greedy search.
  if (contextValue === null && context) {
    contextValue = resolveDynamicDataPathGreedy(context, contextKey);
  }

  if (contextValue && formatters) {
    contextValue = applyFormatting(contextValue, formatters, resolutionConfig);
  }

  return contextValue;
};

export const replaceAllMatches = (
  target: string,
  context: Context,
  resolutionConfig?: DynamicDataResolutionConfig,
): string | object => {
  let matches = findBalancedDoubleBrackets(target);
  if (!matches) {
    return target;
  }
  let mutatedTarget = target;

  // NOTE (Sebas, 2024-12-16): Remove duplicated matches to avoid trying to replace
  // the same match multiple times.
  matches = uniq(matches);

  for (const match of matches) {
    let formatters: Formatter[] | null = null;
    // Try to parse out format specifiers of type |format to apply a transform
    // to the parsed value (poor man's liquid template system)
    const formatMatch = firstGroupMatchOfExpression(
      /(\|[^|]*)}}/g,
      match,
    )?.trim();
    let hasKnownFormatKey = false;
    if (
      // Note (Noah, 2023-11-24): Explainer for this regex - we want to match
      // the LAST occurrence of a "|" format specifier, something like
      // "{{my.dyna|mic.path|json}}". We want to match the last occurrence since
      // a segment of the dynamic path could have a pipe character in it. So:
      // 1. We start a capturing group
      // 2. We look for a pipe character
      // 3. After that pipe character, we look for an arbitrary number of characters
      //    OTHER than a pipe character, immediately followed by }} (end of the
      //    dynamic data value)
      // This results in "|json" being returned for the example above.
      //
      // Note (Noah, 2025-03-19): Important that this is greedy, because we may have
      // nested json in the string for formatters and we want to make sure we match
      // the outermost }} of the string (which itself is a {{ }} match)
      formatMatch !== null &&
      formatMatch !== undefined
    ) {
      const result = formatMatch.match(/\|\s*([^\s:]+)(?:\s*:\s*(.+))?/) ?? [];
      let [, formatKey = "", formatValue = ""] = result;
      formatKey = formatKey.trim();
      formatValue = formatValue.trim();
      switch (formatKey) {
        case "formatters":
          const formattersJson = formatValue;
          formatters = parseFormatters(formattersJson);
          hasKnownFormatKey = true;
          break;
        default:
          break;
      }
    }

    const dynamicDataPath = extractHandlebarsContent(match);
    const contextValue = resolveDynamicDataPath(
      dynamicDataPath ?? "",
      // Note (Noah, 2023-11-06, USE-540): We only want to parse out the "|
      // format" content if it corresponds to an actual transform. This ensures
      // that we don't accidentally render the wrong swatch name if the swatch
      // contains the "|" character, for example
      hasKnownFormatKey ? formatMatch! : null,
      context,
      formatters,
      resolutionConfig,
    ) as unknown as string;
    if (isObject(contextValue)) {
      return contextValue;
    }

    mutatedTarget = mutatedTarget.replaceAll(
      match,
      contextValue === null ? "" : contextValue,
    );
  }

  return mutatedTarget;
};

const compileHandlebars = (
  target: string,
  context?: Context,
  resolutionConfig?: DynamicDataResolutionConfig,
): string | object => {
  if (!context) {
    return "";
  }

  // TODO (Matt 2024-04-18): The only reason that we need to run this (jank)
  // code where we get the features object from window.alchemy is because of
  // how the current GoPuff integration works. Once we're able to create a
  // better integration for them (in the Integration Hub), then we can remove this.
  const windowStore = getAlchemyGlobalPaintContext()?.features;
  if (windowStore) {
    context.store = assign({}, context.store, windowStore);
  }

  try {
    // NOTE (Gabe 2023-11-09): If in the future we need to be able to replace
    // multiple matches we should update replaceOneMatch to be able to find
    // multiple matches instead of calling it repeatedly. This is because we use
    // this to map dynamic data to liquid and if we do that and then call
    // replaceOneMatch again on the returned string the liquid will get mapped
    // to nothing.
    return replaceAllMatches(target, context, resolutionConfig);
  } catch (error) {
    console.error(`Cannot compile Handlebars: ${target}, ${error}`);
    return "";
  }
};

/**
 * Performs a greedy search in order to attempt to resolve a key that may contain dots.
 * The approach is as follows:
 * - Start with an empty path
 * - Attempt to extend the path with the next segment, then the next 2 segments (treated as one key by joining them with a "."),
 *   then the next 3, etc. If any of these potential paths are present in the context, we add that to the path -
 *   this makes this a greedy algorithm.
 * - Continue until all segments are used, or we reach a point where we are unable to construct a path that is present in context
 *
 * This is not guaranteed to handle all cases, but it's probably good enough to handle dynamic data keys with "." present.
 */
const resolveDynamicDataPathGreedy = (context: Context, key: string) => {
  const segments = key.split(".");
  let path: string[] = [];
  let i = 0;
  while (i < segments.length) {
    let added = false;
    for (let j = i; j < segments.length; j++) {
      // Note (Evan, 2024-03-13): Constructs a prospective path from the current path, plus
      // the (j - i + 1) segments we could add (treated as one key by re-joining them with a ".")
      const prospectivePath = [...path, segments.slice(i, j + 1).join(".")];

      if (has(context, prospectivePath)) {
        path = prospectivePath;
        // Note (Evan, 2024-03-13): Skip to the remaining segments
        i = j + 1;
        added = true;
        break;
      }
    }

    // Note (Evan, 2024-03-13): If no combination of remaining segments was valid, just return null
    if (!added) {
      return null;
    }
  }
  return get(context, path, null);
};

export const extractHandlebarsContent = (dynamicDataString: string) => {
  const match = firstGroupMatchOfExpression(/{{(.*)}}/g, dynamicDataString);
  if (!match) {
    return null;
  }
  return match.trim();
};

export const extractDynamicDataInfo = (dynamicDataString: string) => {
  const matches = findBalancedDoubleBrackets(dynamicDataString);
  return matches.map((match) => {
    let [path, formatters] = extractHandlebarsContent(match)?.split("|") ?? [];
    path = path?.trim();
    formatters = formatters?.replace("formatters:", "").trim();
    return {
      path,
      formatters: formatters ? parseFormatters(formatters) : null,
    };
  });
};

const parseFormatters = (formattersString: string) => {
  try {
    const object = JSON.parse(formattersString);
    const result = z
      .object({
        formatters: z.array(formatterSchema),
      })
      .safeParse(object);
    if (!result.success) {
      return null;
    }
    return result.data.formatters;
  } catch {
    return null;
  }
};
