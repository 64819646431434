import * as React from "react";

import { selectIsPreviewMode } from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";

import { useEffectEvent } from "replo-utils/react/use-effect-event";

import {
  selectCanvasArea,
  selectCanvasInteractionMode,
} from "./canvas-reducer";
import { useCanvasPortalPan } from "./useCanvasPortalPan";

export function useWheelHandler() {
  const store = useEditorStore();
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const handleCanvasPortalPan = useCanvasPortalPan();

  const canvasArea = useEditorSelector(selectCanvasArea);

  // Note (Noah, 2022-01-11): Set handlers for wheel so that when we're content
  // editing (editing text inside the iframe) and the iframe's pointer-events is
  // auto, we still intercept events and scroll/zoom the canvas
  const getWheelHandler = useEffectEvent(() => {
    return (event: WheelEvent) => {
      event.preventDefault();
      event.stopImmediatePropagation();

      const isContentEditing =
        selectCanvasInteractionMode(store.getState()) == "content-editing";

      if (!isContentEditing) {
        handleCanvasPortalPan({
          clientX: event.clientX,
          clientY: event.clientY,
          deltaX: event.deltaX,
          deltaY: event.deltaY,
          metaKey: event.metaKey,
          ctrlKey: event.ctrlKey,
        });
        return;
      }

      handleCanvasPortalPan({
        clientX: event.clientX,
        clientY: event.clientY,
        deltaX: event.deltaX,
        deltaY: event.deltaY,
        metaKey: event.metaKey,
        ctrlKey: event.ctrlKey,
      });
    };
  });

  React.useEffect(() => {
    if (
      // Note (Noah, 2022-01-13): Don't do anything in this listener if we're
      // not editing content inside the DOM, because we might be in preview
      // mode and if so we don't want the pan/zoom to happen
      isPreviewMode ||
      !canvasArea
    ) {
      return;
    }

    const cleanupFunctions: (() => void)[] = [];
    const handleWheel = getWheelHandler();
    canvasArea.addEventListener("wheel", handleWheel, {
      passive: false,
    });
    cleanupFunctions.push(() => {
      canvasArea.removeEventListener("wheel", handleWheel);
    });
    return () => {
      for (const cleanup of cleanupFunctions) {
        cleanup();
      }
    };
  }, [isPreviewMode, getWheelHandler, canvasArea]);
}
