import type {
  ComponentTemplateCategoryId,
  ComponentTemplateIndustryId,
} from "schemas/componentTemplates";
import type { ComponentTemplateScope } from "schemas/generated/componentTemplates";

import React from "react";

import SelectableChip from "@replo/design-system/components/chip/SelectableChip";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import twMerge from "@replo/design-system/utils/twMerge";
import {
  BadgePlus,
  Bookmark,
  CircleHelp,
  LandPlot,
  LayoutList,
  Megaphone,
  Newspaper,
  ShoppingBag,
  Users,
} from "lucide-react";
import { COMPONENT_TEMPLATE_CATEGORIES } from "replo-runtime/shared/componentTemplates";
import {
  componentTemplateIndustryNames,
  industryIdSchema,
} from "schemas/componentTemplates";

import Separator from "../common/designSystem/Separator";

type IndustryOption = {
  label: string;
  value: string;
  isSelectable?: boolean;
};

const industryOptions = [
  { label: "All Industries", value: "", isSelectable: true },
  ...componentTemplateIndustryNames.map((name: string, index: number) => ({
    label: name,
    value: Object.values(industryIdSchema.enum)[index],
    isSelectable: true,
  })),
] as IndustryOption[];

type CategoryOption = {
  id: string;
  label: string;
  icon?: React.ReactNode;
  color?: string;
  alias?: string;
};

const pageCategories = COMPONENT_TEMPLATE_CATEGORIES.filter(
  (category) => category.type === "page",
);

const categoryConfigs = [
  {
    slug: "landing-pages",
    icon: <LandPlot size={14} />,
    color: "text-orange-400",
    alias: "Landing",
  },
  {
    slug: "product-page-templates",
    icon: <ShoppingBag size={14} />,
    color: "text-amber-400",
    alias: "PDP",
  },
  {
    slug: "advertorial",
    icon: <Megaphone size={14} />,
    color: "text-lime-400",
    alias: undefined,
  },
  {
    slug: "listicle",
    icon: <LayoutList size={14} />,
    color: "text-emerald-400",
    alias: undefined,
  },
  {
    slug: "product-drop",
    icon: <BadgePlus size={14} />,
    color: "text-cyan-400",
    alias: undefined,
  },
  {
    slug: "about-us",
    icon: <Users size={14} />,
    color: "text-indigo-400",
    alias: "About Us",
  },
  {
    slug: "blog-posts",
    icon: <Newspaper size={14} />,
    color: "text-purple-400",
    alias: "Blogs",
  },
  {
    slug: "faqs",
    icon: <CircleHelp size={14} />,
    color: "text-pink-400",
    alias: "FAQ",
  },
];

const categoryOptions: CategoryOption[] = [
  {
    id: "",
    label: "All Page Types",
  },
  {
    id: "saved",
    label: "Saved",
    icon: <Bookmark size={14} />,
    color: "text-red-400",
  },
  ...(categoryConfigs
    .map((config) => {
      const category = pageCategories.find((cat) => cat.slug === config.slug);

      if (!category) {
        return null;
      }

      return {
        id: category.id,
        label: category.name,
        icon: config.icon,
        color: config.color,
        alias: config.alias,
      };
    })
    .filter(Boolean) as CategoryOption[]),
];

type TemplateFiltersProps = {
  selectedIndustryIds: ComponentTemplateIndustryId[];
  setSelectedIndustryIds: React.Dispatch<
    React.SetStateAction<ComponentTemplateIndustryId[]>
  >;
  selectedCategoryIds: ComponentTemplateCategoryId[];
  setSelectedCategoryIds: React.Dispatch<
    React.SetStateAction<ComponentTemplateCategoryId[]>
  >;
  templateScope: Omit<ComponentTemplateScope, "left-bar" | "unlisted">;
  setTemplateScope: React.Dispatch<
    React.SetStateAction<Omit<ComponentTemplateScope, "left-bar" | "unlisted">>
  >;
};

const TemplateFilters = ({
  selectedIndustryIds,
  setSelectedIndustryIds,
  selectedCategoryIds,
  setSelectedCategoryIds,
  templateScope,
  setTemplateScope,
}: TemplateFiltersProps) => {
  const industryOptionsCount = industryOptions.length - 1; // Subtract 1 for the "All Industries" option

  const allOrNoIndustriesSelected =
    selectedIndustryIds.length === 0 ||
    selectedIndustryIds.length === industryOptionsCount;

  const comboboxValues = allOrNoIndustriesSelected ? [""] : selectedIndustryIds;

  const isSavedSelected = templateScope === "store";

  const isOptionActive = (optionId: string) => {
    if (optionId === "saved") {
      return isSavedSelected;
    }

    if (optionId === "") {
      return selectedCategoryIds.length === 0 && !isSavedSelected;
    }

    return (
      selectedCategoryIds.includes(optionId as ComponentTemplateCategoryId) &&
      !isSavedSelected
    );
  };

  let selectionTitle = "All Industries";
  if (!allOrNoIndustriesSelected) {
    if (selectedIndustryIds.length === 1) {
      const selectedIndustry = industryOptions.find(
        (option) => option.value === selectedIndustryIds[0],
      );
      selectionTitle = selectedIndustry?.label || "1 Industry";
    } else {
      selectionTitle = `${selectedIndustryIds.length} Industries`;
    }
  }

  return (
    <div className="flex flex-col gap-3 p-4 bg-white border-0.5 border-border rounded-xl flex-shrink-0 h-fit">
      <div className="typ-header-base">Filters</div>
      <Combobox.Root
        options={industryOptions}
        isMultiselect
        value={comboboxValues}
        onChange={(values) => {
          if (values.includes("")) {
            if (values.length > 1 && selectedIndustryIds.length === 0) {
              const specificIndustries = values.filter((v) => v !== "");
              setSelectedIndustryIds(
                specificIndustries as ComponentTemplateIndustryId[],
              );
            } else if (selectedIndustryIds.length > 0) {
              setSelectedIndustryIds([]);
            } else {
              const allIndustryIds = industryOptions
                .slice(1) // Skip the "All Industries" option
                .map((option) => option.value as ComponentTemplateIndustryId);
              setSelectedIndustryIds(allIndustryIds);
            }
          } else {
            setSelectedIndustryIds(values as ComponentTemplateIndustryId[]);
          }
        }}
      >
        <Combobox.Trigger>
          <Combobox.SelectionButton
            title={selectionTitle}
            size="base"
            titleAlignment="start"
          />
        </Combobox.Trigger>
        <Combobox.Popover>
          <Combobox.Content areOptionsSearchable={true} />
        </Combobox.Popover>
      </Combobox.Root>
      <Separator />
      <div className="flex flex-col gap-2">
        {categoryOptions.map((option) => (
          <div key={option.id} className="flex-shrink-0">
            <SelectableChip
              size="base"
              active={isOptionActive(option.id)}
              onClick={() => {
                if (option.id === "saved") {
                  // When Saved is clicked, clear all categories and set scope to store
                  setSelectedCategoryIds([]);
                  setTemplateScope("store");
                } else {
                  // For any other category, set scope to global
                  if (templateScope !== "global") {
                    setTemplateScope("global");
                  }

                  // Then handle normal category selection
                  if (option.id === "") {
                    setSelectedCategoryIds([]);
                  } else {
                    const categoryId = option.id as ComponentTemplateCategoryId;
                    setSelectedCategoryIds((prev) => {
                      if (prev.includes(categoryId)) {
                        return prev.filter((id) => id !== categoryId);
                      }
                      return [...prev, categoryId];
                    });
                  }
                }
              }}
              startEnhancer={
                option.icon ? (
                  <span
                    className={twMerge(
                      (option.id === "" && selectedCategoryIds.length === 0) ||
                        (option.id === "saved" && isSavedSelected) ||
                        (selectedCategoryIds.includes(
                          option.id as ComponentTemplateCategoryId,
                        ) &&
                          option.id !== "saved")
                        ? "text-primary"
                        : option.color || "",
                    )}
                  >
                    {option.icon}
                  </span>
                ) : undefined
              }
            >
              {option.alias || option.label}
            </SelectableChip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplateFilters;
