import type { ComponentTemplate } from "@editor/types/component-template";

export const carouselIndicators: ComponentTemplate = {
  id: "babd295f-98ce-44b6-87d0-0cf4c28b89d8",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Carousel/Carousel Indicators",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/carousel-indicators.svg",
  template: {
    id: "43428615-0325-4518-8665-5c28bf5668ec",
    name: "Indicators 1",
    type: "carouselV3Indicators",
    props: {
      style: {
        top: "auto",
        left: "auto",
        right: "auto",
        bottom: "auto",
        zIndex: 2,
        position: "relative",
        marginTop: "0px",
        marginLeft: "0px",
        paddingTop: "16px",
        paddingBottom: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch",
      },
    },
    children: [
      {
        id: "8133262e-cb4d-401e-9917-74453abb4233",
        name: "Container 14",
        type: "container",
        props: {
          style: {
            width: "10px",
            cursor: "pointer",
            height: "10px",
            __width: "10px",
            display: "flex",
            __height: "10px",
            flexGrow: "unset",
            maxWidth: "100%",
            alignSelf: "auto",
            alignItems: "flex-start",
            marginLeft: "10px",
            flexDirection: "row",
            justifyContent: "flex-start",
            backgroundColor: "#0000004D",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          },
        },
        children: [],
        variants: [
          {
            id: "af77352b-4dfc-4fd2-b34a-57c78a654446",
            name: "default",
            query: null,
          },
          {
            id: "5cb4b75c-f17e-4ba3-a14f-64641cfb5665",
            name: "Current item is active",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "a5eefa18-3bc3-4890-a191-335f2c071f83",
                  field: "state.group.isCurrentItemActive",
                },
              ],
            },
          },
        ],
        variantOverrides: {
          "5cb4b75c-f17e-4ba3-a14f-64641cfb5665": {
            componentOverrides: {
              "8133262e-cb4d-401e-9917-74453abb4233": {
                props: { style: { backgroundColor: "#000000FF" } },
              },
            },
          },
        },
      },
    ],
  },
};
