import React from "react";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import { X } from "lucide-react";

import twMerge from "../../utils/twMerge";
import {
  Dialog,
  DialogClose,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from "../shadcn/core/dialog";

export const ModalContext = React.createContext<{
  open: boolean;
  setOpen: (open: boolean) => void;
} | null>(null);

export type ModalProps = {
  title: string;
  description?: string;
  onOpenChange: (open: boolean) => void;
  isOpen: boolean;
  footer?: React.ReactNode;
  layoutClassName?: string;
  UNSAFE_className?: string;
  closeOnOverlayClick?: boolean;
  size?: "sm" | "base" | "lg" | "custom";
};

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  title,
  description,
  onOpenChange,
  isOpen,
  footer,
  layoutClassName,
  UNSAFE_className,
  size = "base",
  closeOnOverlayClick = true,
}) => {
  return (
    <ModalContext.Provider value={{ open: isOpen, setOpen: onOpenChange }}>
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogOverlay />
        <DialogPrimitive.Content
          className={twMerge(
            "fixed left-[50%] top-[50%] z-50 flex flex-col w-full translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-lg",
            "bg-white shadow-modal w-max-[unset] max-h-[90vh] max-w-[90vw] overflow-y-auto",
            size === "sm" && "w-96",
            size === "base" && "w-[576px]",
            size === "lg" && "w-[768px]",
            size === "custom" && "w-fit",
            layoutClassName,
            UNSAFE_className,
          )}
          onInteractOutside={(e) => {
            if (!closeOnOverlayClick) {
              e.preventDefault();
            }
          }}
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <div className="flex flex-row items-start gap-2">
              <DialogTitle className="typ-header-h2 text-default flex-1">
                {title}
              </DialogTitle>
              <DialogClose className="rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                <X size={24} />
                <span className="sr-only">Close</span>
              </DialogClose>
            </div>
            <DialogDescription className="typ-body-base !mt-0 text-muted">
              {description}
            </DialogDescription>
          </DialogHeader>
          {children}
          {footer && <DialogFooter>{footer}</DialogFooter>}
        </DialogPrimitive.Content>
      </Dialog>
    </ModalContext.Provider>
  );
};

Modal.displayName = "Modal";

export { Modal };
