import type { ComponentTemplate } from "@editor/types/component-template";
import type { ResponsiveToggleAllowedDevices } from "../common/designSystem/ResponsiveToggle";

import * as React from "react";

import { CANVAS_DATA } from "@/features/canvas/canvas-constants";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

interface ComponentTemplateSnapshot {
  componentTemplate: ComponentTemplate;
  previewPlatform: ResponsiveToggleAllowedDevices;
}

export const ComponentTemplateSnapshot: React.FC<ComponentTemplateSnapshot> = ({
  componentTemplate,
  previewPlatform,
}) => {
  // TODO (Yuxin, 2022-06-22) This code is really terrible
  // It's based loosely on this code here https://stackblitz.com/edit/react-cuf5cl?file=src%2FApp.js
  // Tbh I have no idea how this code works
  const step = 1.5;
  const scrollRef = React.useRef<null | HTMLDivElement>(null);
  const isScrollRef = React.useRef<boolean>(false);

  const setMove = (state: boolean) => {
    isScrollRef.current = state;
  };

  const move = () => {
    if (isScrollRef.current && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollTop + step;
      requestAnimationFrame(move);
    }
  };

  const config = {
    desktop: {
      src: componentTemplate.desktopPreviewUrl,
      width: "250px",
    },
    tablet: {
      src: componentTemplate.desktopPreviewUrl,
      width: "250px",
    },
    mobile: {
      src: componentTemplate.mobilePreviewUrl,
      width: "80px",
    },
  }[previewPlatform];

  /* If for some weird reason the snapshot isn't generated, we just show a placeholder */
  const [imgSrc, setImgSrc] = useOverridableState<string | undefined>(
    config.src,
  );
  const onError = () =>
    setImgSrc(
      `https://via.placeholder.com/${CANVAS_DATA[previewPlatform].defaultFrameWidth}x1000`,
    );

  return (
    <div
      onMouseEnter={() => {
        setMove(true);
        move();
      }}
      onMouseLeave={() => {
        setMove(false);
        if (scrollRef.current) {
          scrollRef.current.scrollTop = 0;
        }
      }}
      ref={scrollRef}
      className="relative w-full h-full overflow-y-hidden"
    >
      <img
        src={imgSrc}
        loading="lazy"
        className="mx-auto transition duration-700 ease-in-out"
        alt={`${componentTemplate.name} preview`}
        onError={onError}
      />
    </div>
  );
};
