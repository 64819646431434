import * as React from "react";

import Input from "@common/designSystem/Input";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

import LabeledControl from "../common/designSystem/LabeledControl";

type FormValues = {
  name: string;
};

export const AddWorkspaceModal = () => {
  const { closeModal } = useModal();
  const navigate = useNavigate();

  const { mutate: createWorkspace, isPending: isLoadingCreation } =
    trpc.workspace.create.useMutation({
      onSuccess: (data) => {
        toast({
          header: "Workspace created",
        });
        navigate(`/workspace/${data.workspace.id}/projects`);
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      },
      onSettled: () => {
        closeModal({ type: "addWorkspaceModal" });
      },
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });
  const nameValue = useWatch({ control, name: "name" });
  const hasNameErrors = !isEmpty(errors.name);

  const { user } = useCurrentUser();

  const onSubmit = ({ name }: FormValues) => {
    if (!user) {
      return;
    }
    void createWorkspace({
      name,
      preferredPaymentProcessor:
        user.source === "shopify" ? "shopify" : "stripe",
      eventOrigin: "dropdown",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      closeModal({ type: "addWorkspaceModal" });
    }
  };

  return (
    <form
      onSubmit={(data) => {
        void handleSubmit(onSubmit)(data);
      }}
    >
      <Modal
        isOpen={true}
        onOpenChange={handleOpenChange}
        title="Add Workspace"
        size="base"
        footer={
          <Button
            variant="primary"
            size="base"
            type="submit"
            isLoading={isLoadingCreation}
            disabled={hasNameErrors || !nameValue}
          >
            Add
          </Button>
        }
      >
        <LabeledControl label="Workspace Name" error={errors.name?.message}>
          <Input
            id="workspaceName"
            aria-invalid={hasNameErrors ? "true" : undefined}
            aria-describedby={
              hasNameErrors ? "error-workspace-name" : undefined
            }
            autoComplete="off"
            placeholder="Enter workspace name..."
            {...register("name", {
              required: "Please enter the workspace name.",
            })}
            type="text"
            size="base"
            validityState={hasNameErrors ? "invalid" : "valid"}
            autoFocus
          />
        </LabeledControl>
      </Modal>
    </form>
  );
};
