import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

type LabelProps = {
  size?: "base" | "sm" | "lg";
  layoutClassName?: string;
  UNSAFE_className?: string;
  variant?: "default" | "inline";
  breakWords?: boolean;
  truncate?: boolean;
  pointer?: boolean;
  label?: React.ReactNode;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  (
    {
      size = "base",
      label,
      layoutClassName,
      UNSAFE_className,
      variant = "default",
      breakWords = false,
      truncate = false,
      pointer = false,
      ...props
    },
    ref,
  ) => {
    return (
      <label
        {...props}
        className={twMerge(
          "flex items-center text-default",
          fontClassNames[size][variant],
          breakWords && "break-words",
          pointer && "cursor-pointer",
          layoutClassName,
          UNSAFE_className,
        )}
        ref={ref}
      >
        {/* NOTE (Jackson, 4/3/25) Applying truncate (which handles text overflow) directly to a flex container 
        like the <label> doesn't work reliably because flex layout rules can interfere with the overflow calculation. 
        By moving the truncate styles to a simple inner <span> that just holds the text, we isolate the text handling from the flex layout, 
        which allows the browser to correctly detect overflow within the span's constrained space and apply the ellipsis. */}
        <span className={twMerge("min-w-0", truncate && "truncate")}>
          {label}
        </span>
      </label>
    );
  },
);

const fontClassNames = {
  sm: {
    default: "typ-label-small",
    inline: "typ-body-small",
  },
  base: {
    default: "typ-label-base",
    inline: "typ-body-base",
  },
  lg: {
    default: "text-base",
    inline: "text-base",
  },
};

Label.displayName = "Label";

export default Label;
