import type { Component } from "schemas/component";
import type {
  DesignLibrarySavedStyleMetadata,
  SavedTextStyle,
} from "schemas/generated/designLibrary";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import produce from "immer";
import { getSavedStyleId } from "replo-runtime/shared/savedStyles";
import { allMediaSizeStyles } from "replo-runtime/shared/utils/breakpoints";
import { forEachComponentAndDescendants } from "replo-runtime/shared/utils/component";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { getDesignLibraryDynamicDataExpressions } from "replo-runtime/shared/utils/dynamic-data";

import useShopStyles from "./useGetDesignLibrarySavedStyles";

/**
 * Hook that handles the paste of components with design library references.
 * If the components to paste have dynamic design library references,
 * it will replace them with the static values from the design library or
 * the dynamic values from the current design library if there is some saved style
 * with the same name.
 */
const useHandleReplaceComponentWithDesignLibraryReferences = () => {
  const {
    designLibrary,
    textShopStyles: textSavedStyles,
    colorShopStyles: colorSavedStyles,
  } = useShopStyles();

  const savedStylesFromCurrentDesignLibrary = [
    ...textSavedStyles,
    ...colorSavedStyles,
  ];

  const replaceDynamicDataExpressionWithStaticValue = (
    textProp: string,
    expression: string,
    stringToReplace?: string,
  ) => {
    return textProp.replaceAll(`{{ ${expression} }}`, stringToReplace ?? "p");
  };

  const replaceSavedStyleIdDynamicDataExpression = (
    expression: string,
    designLibraryId: string,
    savedStyleIdFromDesignLibrary: string,
  ) => {
    const styleValueSplitted = expression.split(".");

    // NOTE (Fran 2024-11-28): Update the design library id to the id from the current design library.
    styleValueSplitted.splice(1, 1, designLibraryId);
    // NOTE (Fran 2024-11-28): Update the saved style id to the id of the saved style with the same
    // name from the current design library.
    styleValueSplitted.splice(3, 1, savedStyleIdFromDesignLibrary);

    return styleValueSplitted.join(".");
  };

  const updateComponentsSavedStyleReferences = (
    components: Component[],
    designLibraryMetadata: DesignLibrarySavedStyleMetadata,
  ) => {
    const designLibraryIdFromMetadata = Object.keys(
      designLibraryMetadata.library,
    )[0];
    const designLibrarySavedStylesMetadata =
      designLibraryMetadata?.library[designLibraryIdFromMetadata!]?.styles;

    if (!designLibrarySavedStylesMetadata) {
      return components;
    }

    return produce(components, (draftComponents) => {
      for (const component of draftComponents) {
        forEachComponentAndDescendants(component, (componentOrChild) => {
          const textProp = componentOrChild.props.text;
          if (textProp) {
            const designLibraryDynamicDataExpressions =
              getDesignLibraryDynamicDataExpressions(textProp);

            if (
              designLibraryDynamicDataExpressions &&
              designLibraryDynamicDataExpressions.length > 0
            ) {
              // NOTE (Fran 2025-01-15): We don't support multiple saved styles references inside the text
              // prop, so is safe to get only the first expression.
              // If we support multiple saved styles references inside the text prop, we need to update this logic.
              const expression = designLibraryDynamicDataExpressions[0]
                ? designLibraryDynamicDataExpressions[0]
                    .replace(/{{|}}/g, "")
                    .trim()
                : null;

              if (expression && textProp.includes(expression)) {
                const savedStyleId = getSavedStyleId(expression)!;
                const savedStyleFromMetadata =
                  designLibrarySavedStylesMetadata[savedStyleId];

                if (!savedStyleFromMetadata) {
                  componentOrChild.props.text =
                    replaceDynamicDataExpressionWithStaticValue(
                      textProp,
                      expression,
                    );
                } else {
                  const savedStyleName = savedStyleFromMetadata.name;
                  const savedStyleFromCurrentDesignLibrary =
                    savedStylesFromCurrentDesignLibrary.find(
                      (savedStyle) => savedStyle.name === savedStyleName,
                    );

                  componentOrChild.props.text =
                    replaceDynamicDataExpressionWithStaticValue(
                      textProp,
                      expression,
                      savedStyleFromCurrentDesignLibrary?.type === "text"
                        ? `{{ ${replaceSavedStyleIdDynamicDataExpression(
                            expression,
                            designLibrary!.id,
                            savedStyleFromCurrentDesignLibrary.id,
                          )} }}`
                        : (savedStyleFromMetadata as SavedTextStyle).attributes
                            .htmlTag,
                    );
                }
              }
            }
          }

          allMediaSizeStyles.forEach((mediaSize) => {
            const styleProp = componentOrChild.props[mediaSize];
            if (!styleProp) {
              return;
            }
            const stylesValues = Object.entries(styleProp);
            for (const [styleKey, styleValue] of stylesValues) {
              if (
                !(
                  typeof styleValue === "string" &&
                  isDynamicDesignLibraryValue(styleValue)
                )
              ) {
                continue;
              }
              const savedStyleId = getSavedStyleId(styleValue)!;
              const savedStyleFromMetadata =
                designLibrarySavedStylesMetadata[savedStyleId];

              if (!savedStyleFromMetadata) {
                continue;
              }

              const savedStyleFromMetadataName = savedStyleFromMetadata.name;

              const savedStyleFromCurrentDesignLibrary =
                savedStylesFromCurrentDesignLibrary.find(
                  (savedStyle) =>
                    savedStyle.name === savedStyleFromMetadataName,
                );

              if (!savedStyleFromCurrentDesignLibrary) {
                const savedStyleStaticValue =
                  savedStyleFromMetadata.type === "color"
                    ? savedStyleFromMetadata.attributes.color
                    : savedStyleFromMetadata.attributes[
                        styleKey as keyof SavedStyleTextAttributes
                      ];
                if (!componentOrChild.props[mediaSize]) {
                  continue;
                }

                // NOTE (Fran 2024-11-28): Apply the static value from the design library.
                componentOrChild.props[mediaSize][
                  styleKey as RuntimeStyleAttribute
                ] = savedStyleStaticValue;
              } else {
                if (!componentOrChild.props[mediaSize]) {
                  continue;
                }

                const newSavedStyleDynamicDataValue =
                  replaceSavedStyleIdDynamicDataExpression(
                    styleValue,
                    designLibrary!.id,
                    savedStyleFromCurrentDesignLibrary.id,
                  );

                componentOrChild.props[mediaSize][
                  styleKey as RuntimeStyleAttribute
                ] = newSavedStyleDynamicDataValue;
              }
            }
          });
        });
      }
    });
  };

  return {
    handleReplaceComponentWithDesignLibraryReferences: (
      components: Component[],
      designLibraryMetadata: DesignLibrarySavedStyleMetadata | null,
    ) => {
      const designLibraryIdFromMetadata = designLibraryMetadata
        ? Object.keys(designLibraryMetadata.library)[0]
        : null;

      if (
        designLibraryMetadata &&
        designLibraryIdFromMetadata &&
        designLibraryIdFromMetadata !== designLibrary?.id
      ) {
        return updateComponentsSavedStyleReferences(
          components,
          designLibraryMetadata,
        );
      }
      return components;
    },
  };
};

export default useHandleReplaceComponentWithDesignLibraryReferences;
