type FeatureFlag = {
  value: string;
  description: string;
};

/**
 * IMPORTANT, When adding a new feature flag, please add team, name, and description.
 *
 * Teams are ENG, SUPPORT, PRODUCT
 */
export const featureFlags: FeatureFlag[] = [
  {
    value: "ai-microvac",
    description: "(ENG) Debugging tool for AI responses.",
  },
  {
    value: "carousel-debug",
    description:
      "(SUPPORT) Adds debugging tools for troubleshooting carousel components.",
  },
  {
    value: "dynamic-data-refresh",
    description: "(ENG) Updated dynamic data UI.",
  },
  {
    value: "prevent-theme-header-footer",
    description:
      "(SUPPORT) Prevents theme-defined headers and footers from being automatically applied.",
  },
  {
    value: "ai-claude",
    description: "(ENG) Enabled Claude as the default model.",
  },
  {
    value: "debug-insights",
    description:
      "(ENG/PRODUCT) Debugging tool to view all the insights returned from our AI.",
  },
  {
    value: "data-em-disable-on-images",
    description:
      "(ENG) Disables 'data-em' processing on image elements for improved performance.",
  },
  {
    value: "archived-pages",
    description:
      "(SUPPORT) Enables support for managing and restoring archived pages.",
  },
  {
    value: "analytics-v2-data-model",
    description:
      "(ENG, Ben V.) Uses Ben's new analytics V2 data model for analytics.",
  },
  {
    value: "refactor-publishing",
    description:
      "(Eng, Ben O.) Refactors the publishing logic from RSAA -> TRPC",
  },
  {
    value: "template-library-personalization",
    description:
      "(ENG, Cole) Adds personalization via AI to the template library creation.",
  },
];

export type FeatureName = FeatureFlag["value"] | `announcement_${string}`;
