import type { UninstallShopifyIntegrationModalProps } from "../AppModalTypes";

import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BsInfoCircleFill } from "react-icons/bs";

export const UninstallShopifyIntegrationModal: React.FC<
  UninstallShopifyIntegrationModalProps
> = ({ workspaceId, integrationId }) => {
  const { closeModal } = useModal();

  const { data: shopifyIntegrationData } =
    trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );

  const analytics = useLogAnalytics();

  // NOTE (Matt 2024-08-09): If the workspace has no capacity for another Shopify Integration
  // and the reason for no capacity is that it is a shopifyWorkspace, then we know that this
  // workspace's billing plan is managed through the sole connected shopify store. Removing
  // this shopify integration will cancel the billing plan, so we need to inform the user.
  const isRemovingSoleShopifyIntegrationFromShopifyWorkspace =
    shopifyIntegrationData?.canAddShopifyIntegration?.hasCapacity === false &&
    shopifyIntegrationData.canAddShopifyIntegration.reason ===
      "shopifyWorkspace";

  const { mutate: removeShopifyIntegration, isPending: isLoading } =
    trpc.integration.deleteIntegration.useMutation({
      onSuccess: async () => {
        void trpcUtils.integration.getWorkspaceShopifyIntegrationData.invalidate(
          {
            workspaceId,
          },
        );
        toast({
          header: "Shopify integration uninstalled from workspace",
        });
      },
      onSettled: () => {
        closeModal({ type: "uninstallShopifyIntegrationModal" });
      },
    });

  const handleConfirm = () => {
    analytics("integration.shopify.remove", { workspaceId, integrationId });
    removeShopifyIntegration({ workspaceId, integrationId });
  };

  return (
    <Modal
      isOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          closeModal({ type: "uninstallShopifyIntegrationModal" });
        }
      }}
      title="Uninstall Shopify Integration"
      size="base"
      footer={
        <div className="flex flex-row gap-2">
          <Button
            variant="secondary"
            size="base"
            onClick={() =>
              closeModal({ type: "uninstallShopifyIntegrationModal" })
            }
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            size="base"
            type="submit"
            isLoading={isLoading}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      }
    >
      <p className="typ-body-base text-default">
        Removing this Shopify integration is the same as uninstalling the Replo
        app from your Shopify store.
      </p>
      {isRemovingSoleShopifyIntegrationFromShopifyWorkspace && (
        <p className="text-sm text-default inline">
          The Shopify Store you are about to disconnect is responsible for the
          billing plan for this workspace. Uninstalling this Shopify Integration
          will cancel your current billing plan. If you wish to maintain a
          billing plan for this workspace that is not managed through Shopify,
          reach out to support.{" "}
          <a
            href={docs.billingPlans}
            className="inline-flex cursor-pointer items-center typ-body-base text-primary underline"
            target="_blank"
            rel="noreferrer"
          >
            Learn More <BsInfoCircleFill aria-hidden className="ml-1" />
          </a>
        </p>
      )}
    </Modal>
  );
};
