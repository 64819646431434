import type { AssetType } from "schemas/generated/asset";

import * as React from "react";

import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { trpc, trpcClient } from "@editor/utils/trpc";

import { slugify } from "replo-utils/lib/string";
import { ReploError } from "schemas/errors";

class CloudflareUploadError extends ReploError {}

export const useUploadAsset = () => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const projectId = useCurrentProjectId();
  const { mutateAsync: createAsset } = trpc.asset.create.useMutation({
    onSuccess: () => {
      setIsUploading(false);
    },
    onError: (_, input) => {
      setIsError(true);

      // NOTE (Gabe 2025-02-27): This ensures we delete the asset from
      // cloudflare if we fail to create it in our database.
      void trpcClient.asset.delete.mutate({
        projectId: input.asset.projectId,
        assetId: input.asset.id,
        type: input.asset.type,
      });
    },
    onSettled: () => {
      setIsUploading(false);
    },
  });

  const uploadAsset = React.useCallback(
    async ({
      assetId,
      file,
    }: {
      assetId: string;
      file: File;
    }): Promise<string> => {
      if (!projectId) {
        throw new Error("Project ID is required to upload assets.");
      }
      const assetName = slugify(file.name.split(".")[0] ?? ""); // Remove file extension
      const assetType = getAssetTypeFromFile(file);
      const fileExtension = file.name.split(".").at(-1) ?? "";

      setIsUploading(true);

      const assetUrl = await uploadToCloudflare({
        file,
        // TODO (Gabe 2025-02-26): Not great that we have this assetId=>filename
        // mapping here and elsewhere.
        filename: assetId,
        projectId,
      });

      const now = new Date();
      try {
        const { width, height } = await getMediaDimensions(file);
        await createAsset({
          asset: {
            id: assetId,
            url: assetUrl,
            name: assetName,
            type: assetType,
            sizeBytes: file.size,
            fileExtension,
            width: width ?? null,
            height: height ?? null,
            projectId,
            createdAt: now,
            updatedAt: now,
            altText: null,
            embeddingText: null,
          },
        });
      } catch (error) {
        void trpcClient.asset.delete.mutate({
          projectId,
          assetId: assetId,
          type: assetType,
        });
        throw error;
      }

      return assetUrl;
    },
    [createAsset, projectId],
  );

  return { uploadAsset, isUploading, isError };
};

const uploadToCloudflare = async ({
  file,
  filename: originalFilename,
  projectId,
}: {
  file: Blob;
  projectId: string;
  filename: string;
}): Promise<string> => {
  const filename = slugify(originalFilename);

  const { signedUrl, publicUrl } = await trpcClient.asset.fetchSignedUrl.query({
    projectId,
    filename,
    contentType: file.type,
    contentLength: file.size,
  });

  const uploadResponse = await fetch(signedUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type,
      "Content-Length": file.size.toString(),
    },
  });

  if (!uploadResponse.ok) {
    throw new CloudflareUploadError({
      message: `Failed to upload image ${originalFilename} to Cloudflare`,
      additionalData: { status: uploadResponse.status },
    });
  }

  return publicUrl;
};

function getMediaDimensions(
  file: File,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const fileType = file.type.split("/")[0];

    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      if (fileType === "image") {
        const img = new Image();
        img.addEventListener("load", () => {
          resolve({ width: img.width, height: img.height });
        });
        img.addEventListener("error", reject);
        img.src = event.target?.result as string;
      } else if (fileType === "video") {
        const video = document.createElement("video");
        video.addEventListener("loadedmetadata", () => {
          resolve({ width: video.videoWidth, height: video.videoHeight });
        });
        video.addEventListener("error", reject);
        video.src = event.target?.result as string;
      } else {
        reject(new Error("Unsupported file type"));
      }
    });
    reader.addEventListener("error", reject);
    reader.readAsDataURL(file);
  });
}
// TODO (Gabe 2025-02-26): Add support for fonts.

function getAssetTypeFromFile(
  file: Blob,
): Extract<AssetType, "image" | "video"> {
  const type = file.type.split("/")[0];

  if (type === "image") {
    return "image";
  } else if (type === "video") {
    return "video";
  } else {
    throw new Error("Unsupported file type");
  }
}
