import * as React from "react";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

import twMerge from "../../utils/twMerge";

type BaseEmptyStateProps = {
  title: string;
  description: string;
  size?: "base" | "sm";
  layoutClassName?: string;
  UNSAFE_className?: string;
};

type DefaultEmptyStateProps = BaseEmptyStateProps & {
  icon: React.ReactNode;
  variant?: "primary" | "secondary";
};

type PingingEmptyStateProps = BaseEmptyStateProps & {
  variant?: "pinging";
};

type EmptyStateProps = DefaultEmptyStateProps | PingingEmptyStateProps;

// Note (Cole, 2025-02-18): The rings are hardcoded, so if you want to use this somewhere that the rings need to be different, you'll have to add a new variant.
const EmptyState: React.FC<React.PropsWithChildren<EmptyStateProps>> = ({
  title,
  description,
  children,
  size = "base",
  layoutClassName,
  UNSAFE_className,
  variant = "primary",
  ...props
}) => {
  const renderPrimaryContent = (value: {
    variant: "primary" | "secondary" | "pinging";
    icon?: React.ReactNode;
  }) => (
    <>
      <div
        className={twMerge(
          "border-[.8px] border-dashed border-border rounded-full h-[149px] w-[149px] absolute",
          size === "sm" && "h-0 w-0 hidden",
          size === "base" && "h-[149px] w-[149px]",
        )}
      />
      <div
        className={twMerge(
          "border-[.8px] border-dashed border-border rounded-full h-[218px] w-[218px] absolute",
          size === "sm" && "h-0 w-0 hidden",
          size === "base" && "h-[218px] w-[218px]",
        )}
      />
      <div
        className={twMerge(
          "absolute bottom-[-80px] left-[50%] translate-x-[-50%] z-10",
          variant === "primary" && "bg-white",
          variant === "secondary" && "bg-neutral-soft",
          size === "sm" && "h-0 w-0 hidden",
          size === "base" && "h-[80px] w-[200px]",
        )}
      />
      <div
        className={twMerge(
          "rounded-full bg-info-soft flex items-center justify-center relative z-10 text-primary",
          size === "sm" && "h-12 w-12 text-lg",
          size === "base" && "h-16 w-16 text-2xl",
        )}
      >
        {value.icon}
      </div>
    </>
  );

  const startEnhancer = exhaustiveSwitch(
    { ...props, variant },
    "variant",
  )({
    primary: renderPrimaryContent,
    secondary: renderPrimaryContent,
    pinging: () => {
      return (
        <div className="relative flex m-4 h-10 w-10 duration-1000 justify-center items-center">
          <div className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-graph-secondary-a opacity-75"></div>
          <div className="pl-3 pt-3 rounded-full h-4 w-4 bg-primary z-10"></div>
        </div>
      );
    },
  });

  return (
    <div
      className={twMerge(
        "flex flex-col items-center justify-center py-[15%] gap-2",
        layoutClassName,
        UNSAFE_className,
      )}
    >
      <div className="flex items-center justify-center relative">
        {startEnhancer}
      </div>
      <div
        className={twMerge(
          "flex flex-col items-center justify-center gap-2 relative z-10",
          variant === "primary" && "bg-white",
          variant === "secondary" && "bg-neutral-soft",
          variant === "pinging" && "bg-transparent",
        )}
      >
        <div className="text-center max-w-[300px] flex flex-col gap-1">
          <div
            className={twMerge(
              "text-default break-words",
              size === "sm" && "typ-header-small",
              size === "base" && "typ-header-base",
            )}
          >
            {title}
          </div>
          <div
            className={twMerge(
              "text-placeholder break-words",
              size === "sm" && "typ-body-small",
              size === "base" && "typ-body-base",
            )}
          >
            {description}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default EmptyState;
