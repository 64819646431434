import type {
  FieldErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormWatch,
} from "react-hook-form";
import type { Experiment } from "schemas/generated/experiment";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

type ChangePropertyPayload = {
  key: keyof Experiment;
  value: Experiment[keyof Experiment];
  shouldDirty?: boolean;
};

export type ExperimentEditAction =
  | { type: "addVariation" }
  | { type: "changeVariation"; payload: Variation }
  | { type: "removeVariation"; payload: string }
  | { type: "changeProperty"; payload: ChangePropertyPayload }
  | { type: "setEqualVariationSplit" };

type ExperimentEditContextType = {
  watch: UseFormWatch<Experiment>;
  reset: UseFormReset<Experiment>;
  dispatchExperimentEdit: (action: ExperimentEditAction) => void;
  hasDataChanged: boolean;
  isExperimentValid: boolean;
  handleReactHookFormSubmit: UseFormHandleSubmit<Experiment, undefined>;
  errors: FieldErrors<Experiment>;
  getValues: UseFormGetValues<Experiment>;
};

export const ExperimentEditContext = React.createContext<
  ExperimentEditContextType | undefined
>(undefined);
