import * as React from "react";

import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { trpc } from "@editor/utils/trpc";

import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BillingTiers } from "schemas/billing";

const useAnalyticsStatus = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { data: isEnabled, isLoading: isLoadingEnableCheck } =
    trpc.analytics.isEnabled.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );
  const { data: workspaceSubscription, isLoading: isLoadingSubscription } =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspaceId ?? reactQuerySkipToken,
    );

  const isCurrentlyPaying =
    Boolean(workspaceSubscription) &&
    workspaceSubscription?.name !== BillingTiers.FREE;

  const isLoading = isLoadingEnableCheck || isLoadingSubscription;

  return { isEnabled, isCurrentlyPaying, isLoading };
};

export default useAnalyticsStatus;
