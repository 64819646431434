import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type { ReploShopifyProduct } from "replo-runtime/shared/types";
import type { SelectedSellingPlanIdOrOneTimePurchase } from "schemas/product";

import * as React from "react";

import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { getSellingPlanOptionsFromProduct } from "@editor/utils/sellingPlans";

import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { isOneTimePurchase } from "replo-runtime/store/utils/product";

const REPLO_ONE_TIME_PURCHASE_OPTION_VALUE = "replo:one-time-purchase";

export default function SellingPlanSelector(props: {
  product: ReploShopifyProduct;
  allowAllPlans: boolean;
  selectedSellingPlanId: SelectedSellingPlanIdOrOneTimePurchase | null;
  onChange: (newValue: SelectedSellingPlanIdOrOneTimePurchase | null) => void;
  side?: "left" | "right" | "top" | "bottom";
  sideOffset?: number;
}) {
  const {
    product,
    selectedSellingPlanId,
    onChange,
    allowAllPlans,
    side = "left",
    sideOffset = 16,
  } = props;
  const sellingPlanOptions = getSellingPlanOptionsFromProduct(
    product,
    allowAllPlans,
  );
  const selectedSellingPlan = sellingPlanOptions.find(
    (option) => Number(option.value) === Number(selectedSellingPlanId),
  );
  const isOneTimePurchaseSelected = isOneTimePurchase(
    selectedSellingPlanId ?? null,
  );
  const selectedSellingPlanName = isOneTimePurchaseSelected
    ? "One-time purchase"
    : selectedSellingPlan?.label ?? "First available selling plan";

  // NOTE (Jackson, 2025-04-03): Externlly, sellingPlanId is a number, but
  // with respect to the combobox, it needs to be a string. Additionally,
  // use "no-selection" as a sentinel value to indicate that no selling plan
  // is selected.
  let comboboxValue: string = "no-selection";
  if (isOneTimePurchaseSelected) {
    comboboxValue = REPLO_ONE_TIME_PURCHASE_OPTION_VALUE;
  } else if (selectedSellingPlanId) {
    comboboxValue = String(selectedSellingPlanId);
  }

  // Prepare options for Combobox
  const comboboxOptions: ComboboxOption[] = [
    {
      label: "One-time purchase",
      value: REPLO_ONE_TIME_PURCHASE_OPTION_VALUE,
      isSelectable: true,
    },
    ...sellingPlanOptions,
  ];

  return (
    <Combobox
      side={side}
      sideOffset={sideOffset}
      title="Select Selling Plan"
      options={comboboxOptions}
      value={comboboxValue}
      onChange={(newSelectedValue: string) => {
        if (newSelectedValue === "no-selection") {
          onChange(null);
          return;
        }
        const newValue =
          newSelectedValue === REPLO_ONE_TIME_PURCHASE_OPTION_VALUE
            ? { __reploOneTimePurchase: true }
            : Number(newSelectedValue);
        onChange(newValue as SelectedSellingPlanIdOrOneTimePurchase);
      }}
      trigger={
        <Combobox.SelectionButton
          title={String(selectedSellingPlanName) ?? "Select Selling Plan"}
          placeholder="Select Selling Plan"
          size="sm"
          layoutClassName="w-full"
          titleAlignment="start"
          endEnhancer={
            comboboxValue !== "no-selection" ? (
              <FormFieldXButton
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(null);
                }}
              />
            ) : undefined
          }
        />
      }
    />
  );
}
