import React from "react";

import { Checkbox as ShadcnCheckbox } from "@replo/design-system/components/shadcn/core/checkbox";
import twMerge from "@replo/design-system/utils/twMerge";
import { Check } from "lucide-react";

type CheckboxProps = {
  disabled?: boolean;
  id?: string;
  onChange?: (e: boolean) => void;
  layoutClassName?: string;
  UNSAFE_className?: string;
  checked?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  disabled = false,
  id,
  onChange,
  checked = false,
  layoutClassName,
  UNSAFE_className,
  onKeyDown,
}) => {
  return (
    <ShadcnCheckbox
      id={id}
      className={twMerge(
        "bg-white w-4 h-4 rounded-[4px] border border-border shrink-0 cursor-pointer",
        checked && "bg-primary border-primary",
        disabled && "cursor-not-allowed",
        layoutClassName,
        UNSAFE_className,
      )}
      checked={checked}
      onCheckedChange={onChange}
      onKeyDown={(event) => {
        if (event.key === " ") {
          event.preventDefault();
          onChange?.(!checked);
        }
        onKeyDown?.(event);
      }}
      disabled={disabled}
      icon={
        <Check
          className="text-white w-[10px] h-[10px]"
          strokeWidth={4}
          absoluteStrokeWidth={true}
        />
      }
    />
  );
};

/** @public */
export type { CheckboxProps };
export { Checkbox };
