import type { MoveProjectModalProps } from "../AppModalTypes";

import * as React from "react";

import Selectable from "@common/designSystem/Selectable";
import { useModal } from "@editor/hooks/useModal";
import {
  isShopifyIntegrationCapacityReason,
  mapShopifyIntegrationCapacityReasonToError,
} from "@editor/utils/shopifyIntegrationCapacity";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { Controller, useForm, useWatch } from "react-hook-form";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  newWorkspaceId: string | null;
};

export const MoveProjectModal: React.FC<MoveProjectModalProps> = ({
  projectId,
  oldWorkspaceId,
  shopifyUrl,
}) => {
  const { closeModal } = useModal();
  const { mutate: transferProject, isPending: isLoading } =
    trpc.project.transfer.useMutation({
      onSuccess: ({ newWorkspaceId }) => {
        toast({
          header: "Project transferred",
        });
        if (pathname.includes("/workspace")) {
          navigate(
            generatePath("/workspace/:workspaceId/projects", {
              workspaceId: newWorkspaceId,
            }),
          );
        }
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        void trpcUtils.project.findByUserId.invalidate();
        void trpcUtils.project.listWithStats.invalidate();
      },
      onSettled: () => closeModal({ type: "moveProjectModal" }),
    });

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data } = trpc.workspace.getUserWorkspacesList.useQuery();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      newWorkspaceId: null,
    },
  });
  const newWorkspaceIdValue = useWatch({ control, name: "newWorkspaceId" });

  if (!oldWorkspaceId) {
    return null;
  }

  const filteredWorkspaces = data?.workspaces.filter((workspace) => {
    return (
      workspace.id !== oldWorkspaceId &&
      workspace.workspaceMemberships[0]?.role === "owner"
    );
  });

  const hasWorkspaceIdErrors = !isEmpty(errors.newWorkspaceId);

  const onSubmit = ({ newWorkspaceId }: FormValues) => {
    if (newWorkspaceId && oldWorkspaceId) {
      transferProject({
        projectId,
        newWorkspaceId,
      });
    }
  };

  return (
    <form
      className="flex flex-col gap-6"
      onSubmit={(data) => {
        void handleSubmit(onSubmit)(data);
      }}
    >
      <Modal
        title="Move Project"
        isOpen={true}
        onOpenChange={(open) => {
          if (!open) {
            closeModal({ type: "moveProjectModal" });
          }
        }}
        size="base"
        footer={
          <div className="flex flex-row gap-2">
            <Button
              variant="secondary"
              size="base"
              onClick={() => closeModal({ type: "moveProjectModal" })}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="base"
              type="submit"
              disabled={hasWorkspaceIdErrors || !newWorkspaceIdValue}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <p className="typ-body-base text-default">
            Which workspace do you want to move this to?
          </p>
          <div className="flex flex-col gap-2">
            <label htmlFor="workspaceName" className="sr-only">
              Move project to workspace:
            </label>
            <Controller
              name="newWorkspaceId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Selectable
                  placeholder="Select a workspace"
                  value={value}
                  size="base"
                  options={
                    filteredWorkspaces?.map((workspace) => ({
                      label: workspace.name,
                      value: workspace.id,
                    })) ?? []
                  }
                  onSelect={onChange}
                />
              )}
            />
          </div>
          {errors.newWorkspaceId &&
            isShopifyIntegrationCapacityReason(errors.newWorkspaceId.type) && (
              <div className="text-xs text-danger">
                {mapShopifyIntegrationCapacityReasonToError({
                  reason: errors.newWorkspaceId.type,
                  redirectToBilling: () => {
                    closeModal({ type: "moveProjectModal" });
                    navigate(`/workspace/${newWorkspaceIdValue}/billing`);
                  },
                })}
              </div>
            )}
          {shopifyUrl && (
            <div className="typ-body-small">
              Please note: The Shopify store currently connected to this project
              will not be moved. To move the Shopify integration along with the
              project, disconnect it from{" "}
              <a
                className="text-blue-600"
                href={generatePath(
                  "/workspace/:workspaceId/integrations/shopify",
                  {
                    workspaceId: oldWorkspaceId,
                  },
                )}
              >
                Integration Settings
              </a>{" "}
              then access Replo through Shopify Admin to add it to the new
              workspace.
            </div>
          )}
        </div>
      </Modal>
    </form>
  );
};
