import type { SelectedTab } from "@/features/experiments/tabs/constants/tabOptions";

import * as React from "react";

import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";

import {
  TAB_OPTION_DETAILS,
  TAB_OPTION_RESULTS,
} from "@/features/experiments/tabs/constants/tabOptions";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { Tabs } from "@replo/design-system/components/tabs/Tabs";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { generatePath, Link, useLocation, useParams } from "react-router-dom";
import { BillingTiers } from "schemas/billing";
import { getExperimentStatus } from "schemas/experiment";

export const TabMenu: React.FC = () => {
  const { experiment } = useExperimentDetails();
  const status = experiment ? getExperimentStatus(experiment) : null;
  const workspaceId = useCurrentWorkspaceId();
  const { experimentId } = useParams();
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;
  const location = useLocation();
  const isResultsTab = location.pathname.includes(TAB_OPTION_RESULTS.value);
  const selectedTab = isResultsTab ? TAB_OPTION_RESULTS : TAB_OPTION_DETAILS;
  const isResultsDisabled = status === "draft";

  const detailsPath = generatePath(routes.workspace.experiments.details, {
    workspaceId,
    experimentId,
  });

  const resultsPath = generatePath(routes.workspace.experiments.results, {
    workspaceId,
    experimentId,
  });

  const handleClick = (tab: SelectedTab) => {
    if (tab === TAB_OPTION_DETAILS) {
      logEvent("experiment.detail.select", {
        billingPlanTier: subscriptionTier,
      });
    } else if (tab === TAB_OPTION_RESULTS) {
      logEvent("experiment.results.select", {
        billingPlanTier: subscriptionTier,
      });
    }
  };

  const tabsList = [
    {
      ...TAB_OPTION_DETAILS,
      asChild: true,
      label: (
        <Link
          key={TAB_OPTION_DETAILS.value}
          to={detailsPath}
          onClick={() => handleClick(TAB_OPTION_DETAILS)}
        >
          {TAB_OPTION_DETAILS.label}
        </Link>
      ),
    },
    {
      ...TAB_OPTION_RESULTS,
      asChild: true,
      label: isResultsDisabled ? (
        <Tooltip
          content="Start test to view results"
          delay={300}
          triggerAsChild
        >
          {TAB_OPTION_RESULTS.label}
        </Tooltip>
      ) : (
        <Link
          key={TAB_OPTION_RESULTS.value}
          to={resultsPath}
          onClick={() => handleClick(TAB_OPTION_RESULTS)}
        >
          {TAB_OPTION_RESULTS.label}
        </Link>
      ),
    },
  ];

  return (
    <Tabs
      tabsOptions={tabsList}
      value={selectedTab.value}
      onValueChange={() => {}}
      defaultValue={selectedTab.value}
      size="base"
    />
  );
};
