import type { RightBarTab } from "@editor/reducers/ui-reducer";

import * as React from "react";

import DraftComponentPaneHeader from "@editor/components/DraftComponentPaneHeader";
import { RIGHT_BAR_WIDTH } from "@editor/components/editor/constants";
import { configMenuMap } from "@editor/components/editor/page/element-editor/components/config-menus/configMap";
import TourStepTrigger from "@editor/components/flows/TourStepTrigger";
import {
  RightBarBody,
  RightBarLayout,
} from "@editor/components/layouts/RightBarLayout";
import { useEditorPerformanceContext } from "@editor/contexts/editor-performance.context";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useRightBarVisibility from "@editor/hooks/useRightBarVisibility";
import { analytics, trackError } from "@editor/infra/analytics";
import {
  selectDraftComponentHasVariants,
  selectDraftComponentRightBarTabs,
  selectDraftComponentType,
  selectDraftComponentTypeIsModal,
  selectEditorMode,
  selectNearestAncestorHasVariants,
  selectUpdatesSinceLastRequestFinished,
} from "@editor/reducers/core-reducer";
import {
  selectIsRightBarAnalyticsOpen,
  selectRightBarActiveTab,
  setRightBarActiveTab,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { isDevelopment } from "@editor/utils/env";
import DesignTab from "@editorComponents/DesignTab";
import InteractionsTab from "@editorComponents/InteractionsTab";
import CustomPropModifier from "@editorModifiers/CustomPropModifier";
import VariantModifier from "@editorModifiers/VariantModifier";

import { AnalyticsMenuPane } from "@/features/analytics/AnalyticsMenuPane";
import InlineAlert from "@replo/design-system/components/alert/InlineAlert";
import { toast } from "@replo/design-system/components/alert/Toast";
import { Tabs } from "@replo/design-system/components/tabs/Tabs";
import twMerge from "@replo/design-system/utils/twMerge";
import {
  ErrorBoundary,
  ErrorBoundaryDefaultFallback,
} from "replo-runtime/shared/ErrorBoundary";

const ANALYTICS_RIGHT_BAR_WIDTH = 300;

const RightBar = React.memo(function RightBar() {
  const activeTab = useEditorSelector(selectRightBarActiveTab);
  const editorMode = useEditorSelector(selectEditorMode);

  const isRightBarVisible = useRightBarVisibility();
  const isRightBarAnalyticsOpen = useEditorSelector(
    selectIsRightBarAnalyticsOpen,
  );
  const { rightBarElementRef } = useEditorPerformanceContext();
  const draftComponentType = useEditorSelector(selectDraftComponentType);

  const hasVariants = useEditorSelector(selectDraftComponentHasVariants);
  const hasParentVariants = useEditorSelector(selectNearestAncestorHasVariants);
  const shouldShowVariantModifier =
    activeTab === "design" || hasVariants || hasParentVariants;

  const ConfigMenu =
    draftComponentType && configMenuMap[draftComponentType]?.config
      ? configMenuMap[draftComponentType].config
      : CustomPropModifier;

  const AccessibilityMenu =
    (draftComponentType && configMenuMap[draftComponentType]?.accessibility) ||
    null;
  const tabOptions = useEditorSelector(selectDraftComponentRightBarTabs);
  const dispatch = useEditorDispatch();
  useResetActiveTab();
  const logEvent = useLogAnalytics();
  const handleRenderError = React.useCallback(
    (error: unknown, info: React.ErrorInfo) => {
      if (isDevelopment) {
        console.error("[REPLO] Component rendering error in RightBar", {
          error,
          reactErrorInfo: info,
        });
      }
      toast({
        type: "error",
        header: "Something went wrong",
        message:
          "Please try refreshing the page or reach out to support if the error persists.",
        cta: "Contact Support",
        ctaHref: "mailto:support@replo.app",
      });
      trackError(error);
    },
    [],
  );

  if (!isRightBarVisible) {
    return null;
  }

  const designTabRightBarWidth = RIGHT_BAR_WIDTH;

  const isEditMode = editorMode === EditorMode.edit;
  const rightBarWidth = isRightBarAnalyticsOpen
    ? ANALYTICS_RIGHT_BAR_WIDTH
    : designTabRightBarWidth;

  if (isRightBarAnalyticsOpen) {
    return (
      <AnalyticsMenuPane
        width={rightBarWidth}
        elementRef={rightBarElementRef}
      />
    );
  }

  const tabOptionsFiltered = tabOptions?.filter((tab) => tab.isVisible) || [];

  // https://stackoverflow.com/questions/14962468/how-can-i-combine-flexbox-and-vertical-scroll-in-a-full-height-app
  return (
    <RightBarLayout
      rightBarWidth={rightBarWidth}
      rightBarElementRef={rightBarElementRef}
    >
      <Tabs.Root
        value={activeTab ?? "design"}
        onValueChange={(value) => {
          logEvent("editor.rightbar.tab.select", { tab: value as RightBarTab });
          dispatch(setRightBarActiveTab(value as RightBarTab));
        }}
      >
        {isEditMode && (
          // NOTE (Sebas, 2024-10-24): This is now on the RightBar and not in the
          // DraftComponentPane because it was interfering with the scrollable
          // component and causing weird horizontal and vertical scroll behavior.
          <div className="bg-white px-2 pt-2 z-10 sticky top-0 flex flex-col gap-1">
            <DraftComponentPaneHeader />
            <TourStepTrigger step="step-3">
              <Tabs.List>
                {tabOptionsFiltered?.map((option) => (
                  <Tabs.Trigger
                    key={option.value}
                    value={option.value}
                    size="sm"
                  >
                    {option.label}
                  </Tabs.Trigger>
                ))}
              </Tabs.List>
            </TourStepTrigger>
          </div>
        )}
        <RightBarBody isEditMode={isEditMode}>
          {/* Note (Noah, 2022-08-16, REPL-3607): This banner is here because we're
            suspicious of a bug where we think that user updates which are made under
            slow or volatile network conditions may sometimes result in lost work. This
            could possibly happen if the element update request was VERY slow to respond,
            and a lot of updates to the local element don't actually get sent to the
            backend because there's already a request in progress, then the user refreshes
            the page or something and loses the updates (since they've never been sent to
            the backend). This might not happen, but we're putting this banner here if they've
            made some arbitrary number of updates which would currently be lost if the page
            were to refresh, so that we can see it in full stories and debug. */}
          <UpdatesWarningBanner />
          <ErrorBoundary
            fallback={
              <div className="p-2 text-xs">
                <ErrorBoundaryDefaultFallback />
              </div>
            }
            onError={handleRenderError}
          >
            {draftComponentType && (
              <div
                className={twMerge(
                  "flex flex-col gap-2 p-2 pb-32 text-default max-w-[250px]",
                  !isEditMode && "cursor-not-allowed",
                )}
              >
                {shouldShowVariantModifier && <VariantModifier />}
                <div className={twMerge(!isEditMode && "pointer-events-none")}>
                  <Tabs.Content value="design">
                    <DesignTab />
                  </Tabs.Content>
                  <Tabs.Content value="interactions">
                    <InteractionsTab />
                  </Tabs.Content>
                  <Tabs.Content value="custom">
                    <ConfigMenu />
                  </Tabs.Content>
                  {AccessibilityMenu && (
                    <Tabs.Content value="accessibility">
                      <AccessibilityMenu />
                    </Tabs.Content>
                  )}
                </div>
              </div>
            )}
          </ErrorBoundary>
        </RightBarBody>
      </Tabs.Root>
    </RightBarLayout>
  );
});

const UpdatesWarningBanner: React.FC<{}> = () => {
  const updatesSinceLastRequestFinished = useEditorSelector(
    selectUpdatesSinceLastRequestFinished,
  );

  const showBanner = updatesSinceLastRequestFinished >= 30;

  React.useEffect(() => {
    if (showBanner) {
      analytics.logEvent("editor.pendingUpdatesWarningBannerShown", {});
    }
  }, [showBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <div className="p-2">
      <InlineAlert variant="error" multiline>
        More than {updatesSinceLastRequestFinished} pending unsaved updates.
        This may be a bug - please reach out to support@replo.app
      </InlineAlert>
    </div>
  );
};

/**
 * Hook to reset active tab when the draft component changes
 */
function useResetActiveTab() {
  const isModal = useEditorSelector(selectDraftComponentTypeIsModal);
  const dispatch = useEditorDispatch();
  const tabOptions = useEditorSelector(selectDraftComponentRightBarTabs);
  const activeTab = useEditorSelector(selectRightBarActiveTab);
  const isCurrentTabVisible = tabOptions?.find(
    (tab) => tab.value === activeTab,
  )?.isVisible;

  // // NOTE (Sebas, 2025-01-14): When selecting multiple components we need to
  // // check if the current tab is visible, if not, reset the tab to design to
  // // avoid showing the wrong tab.
  React.useEffect(() => {
    if (!isCurrentTabVisible) {
      dispatch(setRightBarActiveTab(isModal ? "custom" : "design"));
    }
  }, [dispatch, isCurrentTabVisible, isModal]);
}

export default RightBar;
