import type { Emitter } from "mitt";
import type { CarouselContextValue } from "./carousel";
import type {
  AutoPlayPauseEvent,
  AutoPlayPlayEvent,
  AutoPlayState,
  TextDirection,
} from "./lib/types";
import type {
  CarouselTransitionStates,
  FadeTransitionStates,
  GoToOptions,
  ImmediateTransitionStates,
  SlideTransitionStates,
} from "./lib/utils";

import * as React from "react";

import mitt from "mitt";
import { clamp } from "replo-utils/lib/math";
import { warning } from "replo-utils/lib/misc";
import { useConstant } from "replo-utils/react/use-constant";

import { DIRECTION_RTL } from "./lib/constants";
import {
  getSlideTransitionStates,
  getTrackStartPosition,
  shouldAutoPlay,
} from "./lib/utils";

type AutoPlayEventType = AutoPlayPauseEvent | AutoPlayPlayEvent;

type CarouselReducerEvent =
  | { type: "INIT_AUTO_PLAY"; autoPlayInterval: number }
  | {
      type: "INIT_ACTIVE_SLIDE";
      defaultActiveSlideIndex: number;
      slideCount: number;
      textDirection: TextDirection;
    }
  | ({ type: "SYNC_TRACK_START" } & Pick<
      CarouselContextValue,
      | "activeSlidePosition"
      | "autoWidth"
      | "autoPlayInterval"
      | "endBehavior"
      | "orientation"
      | "slideCount"
      | "slidesPerMove"
      | "slidesPerPage"
      | "trackRef"
      | "isFade"
      | "isHydrated"
      | "listSize"
    >)
  | { type: "UPDATE_LIST_SIZE"; size: number }
  | {
      type: "AUTO_PLAY";
      autoPlayInterval: number;
      playType: AutoPlayEventType;
    }
  | ({
      type: "GO_TO_SLIDE";
      index: number;
      opts?: GoToOptions;
    } & Pick<
      CarouselContextValue,
      | "activeSlidePosition"
      | "autoWidth"
      | "carouselId"
      | "endBehavior"
      | "listSize"
      | "onActiveSlideChange"
      | "orientation"
      | "slideCount"
      | "slidesPerMove"
      | "slidesPerPage"
      | "trackRef"
      | "transitionEasing"
      | "transitionSpeed"
      | "isFade"
      | "isImmediateTransition"
      | "isHydrated"
    >)
  | {
      type: "SET_IMMEDIATE_TRANSITION_STATE";
      state: ImmediateTransitionStates["state"];
      autoPlayInterval: number;
    }
  | {
      type: "SET_TRANSITION_START_STATE";
      state: FadeTransitionStates["state"] | SlideTransitionStates["state"];
      autoPlayInterval: number;
    }
  | {
      type: "SET_TRANSITION_STOP_STATE";
      state: Omit<
        FadeTransitionStates["nextState"] | SlideTransitionStates["nextState"],
        "isTransitioning"
      >;
    }
  | { type: "STOP_ANIMATION" };

type InternalCarouselReducerEvent = CarouselReducerEvent & {
  debug: boolean;
  isControlledRef: React.MutableRefObject<boolean>;
  emitter: Emitter<ReducerEmittedEvents>;
};

type ReducerEmittedEvents = {
  CALL_CONTROLLED_STATE_UPDATER: {
    changeResults: CarouselTransitionStates;
  };
  START_SLIDE_TRANSITION: {
    changeResults: CarouselTransitionStates;
  };
  END_SLIDE_TRANSITION: {
    nextState: CarouselState;
    finalState: CarouselState;
  };
};

export type CarouselState = {
  activeSlideIndex: number;
  autoPlayState: AutoPlayState | null;
  isTransitioning: boolean;
  targetSlideIndex: number;
  trackStartPosition: number;
};

const INITIAL_ACTIVE_SLIDE_INDEX = 0;

function resolveActiveSlide(
  activeSlideIndex: number | null | undefined,
  context: { slideCount: number },
) {
  const { slideCount } = context;
  if (
    slideCount === 0 ||
    activeSlideIndex == null ||
    activeSlideIndex < 0 ||
    activeSlideIndex >= slideCount
  ) {
    warnSlideNotFound();
  }
  return clamp(activeSlideIndex ?? 0, 0, Math.max(slideCount - 1, 0));
}

function getInitialCarouselState({
  activeSlideIndex,
}: {
  activeSlideIndex: number;
}): CarouselState {
  return {
    activeSlideIndex,
    targetSlideIndex: activeSlideIndex,
    autoPlayState: null,
    isTransitioning: false,
    trackStartPosition: 0,
  } satisfies CarouselState;
}

function carouselReducer(
  state: CarouselState,
  event: InternalCarouselReducerEvent,
): CarouselState {
  const emitter: Emitter<ReducerEmittedEvents> = event.emitter;
  const debug = event.debug;
  const { current: isControlled } = event.isControlledRef;

  if (debug) {
    // biome-ignore lint/suspicious/noConsoleLog: allow console.log
    console.log(
      `%cCAROUSEL REDUCER EVENT: %c${event.type}`,
      "font-weight:bold;color:crimson;",
      "color:initial;",
      {
        payload: event,
        currentState: state,
      },
    );
  }

  switch (event.type) {
    case "INIT_AUTO_PLAY": {
      const { autoPlayInterval } = event;
      const autoPlayState = shouldAutoPlay({ autoPlayInterval })
        ? "playing"
        : "paused";

      if (autoPlayState !== state.autoPlayState) {
        return {
          ...state,
          autoPlayState,
        };
      }
      return state;
    }

    case "INIT_ACTIVE_SLIDE": {
      if (isControlled || state.activeSlideIndex !== -1) {
        return state;
      }
      const { defaultActiveSlideIndex, slideCount, textDirection } = event;
      const activeSlideIndex = initializeActiveSlideIndex(
        defaultActiveSlideIndex,
        { slideCount, textDirection },
      );
      if (activeSlideIndex === state.activeSlideIndex) {
        return state;
      }
      // NOTE (Chance 2024-05-23): defensive strategy since numbers may not
      // actually be numbers, and the resulting state here would be very wonky
      // (thanks JavaScript!)
      if (Number.isNaN(activeSlideIndex)) {
        warning(
          false,
          `Requested index is not a number. Received: ${activeSlideIndex}`,
        );
        return state;
      }
      return {
        ...state,
        activeSlideIndex,
      };
    }

    case "SYNC_TRACK_START": {
      const {
        activeSlidePosition,
        autoPlayInterval,
        autoWidth,
        endBehavior,
        isFade,
        isHydrated,
        listSize,
        orientation,
        slideCount,
        slidesPerMove,
        slidesPerPage,
        trackRef,
      } = event;

      const activeSlideIndex = state.activeSlideIndex;
      const trackStartPosition = getTrackStartPosition(activeSlideIndex, {
        activeSlidePosition,
        autoWidth,
        orientation,
        endBehavior,
        listSize,
        slideCount,
        slidesPerMove,
        slidesPerPage,
        trackRef,
        isFade,
        isHydrated,
      });
      if (trackStartPosition === state.trackStartPosition) {
        return state;
      }

      let autoPlayState = state.autoPlayState;
      if (autoPlayState === "playing") {
        if (!shouldAutoPlay({ autoPlayInterval })) {
          autoPlayState = "paused";
        }
      }

      const nextState = {
        ...state,
        trackStartPosition,
      };

      return nextState;
    }

    // @ts-expect-error
    case "HANDLE_ADDED_SLIDES":
      return state;
    // @ts-expect-error
    case "HANDLE_REMOVED_SLIDES": {
      // TODO (Chance 2023-11-17):
      //
      // 1. If we added slides to the end of the carousel, nothing changes
      // 2. If we added slides to the beginning of the carousel, increment the
      //    slide index by the number of slides added to prevent the active
      //    slide from changing
      // 3. If we removed slides from the end of the carousel at an index
      //    starting after the active slide, nothing changes
      // 4. If we removed slides from the end of the carousel at an index before
      //    or equal to the active slide, decrement the active slide index by
      //    the number of slides removed to prevent the active slide from
      //    changing
      // 5. If all slides are removed, set the activeSlideIndex and
      //    targetSlideIndex to -1
      // 6. If we're in an animating state, send an event to stop the animation
      //    and update the state immediately
      // 7. Changing the slide count will also change the size of our slide
      //    container, so we shouldn't need to re-position the track. Our resize
      //    handler takes care of that.
      return state;
    }

    case "AUTO_PLAY": {
      const { playType, autoPlayInterval } = event;
      if (!shouldAutoPlay({ autoPlayInterval })) {
        if (state.autoPlayState !== "paused") {
          return {
            ...state,
            autoPlayState: "paused",
          };
        }
        return state;
      }

      let autoPlayState: AutoPlayState | null = null;
      switch (playType) {
        case "update":
          if (state.autoPlayState === "playing") {
            autoPlayState = "playing";
          }
          break;
        case "leave":
          if (
            state.autoPlayState === "playing" ||
            state.autoPlayState === "hovered"
          ) {
            autoPlayState = "playing";
          }
          break;
        case "blur":
          if (
            state.autoPlayState === "playing" ||
            state.autoPlayState === "focused"
          ) {
            autoPlayState = "playing";
          }
          break;
        case "pause":
          autoPlayState = "paused";
          break;
        case "focus":
          if (
            state.autoPlayState === "playing" ||
            state.autoPlayState === "hovered"
          ) {
            autoPlayState = "focused";
          }
          break;
        case "hover":
          if (state.autoPlayState === "playing") {
            autoPlayState = "hovered";
          }
          break;
        default:
          break;
      }

      if (!autoPlayState) {
        return state;
      }

      return {
        ...state,
        autoPlayState,
      };
    }

    case "GO_TO_SLIDE": {
      const {
        index,
        opts,
        //
        activeSlidePosition,
        autoWidth,
        carouselId,
        endBehavior,
        listSize,
        onActiveSlideChange,
        orientation,
        slideCount,
        slidesPerMove,
        slidesPerPage,
        trackRef,
        transitionEasing,
        transitionSpeed,
        isFade,
        isImmediateTransition,
        isHydrated,
      } = event;
      const { activeSlideIndex: currentActiveSlideIndex, isTransitioning } =
        state;

      if (Number.isNaN(index)) {
        warning(false, `Requested index is not a number. Received: ${index}`);
        return state;
      }

      // NOTE (Chance 2023-11-17): This prevents the user from spamming nav
      // buttons or indicators, which can cause the carousel position to get out
      // of sync with the active slide index. Ideally we could set up a queue so
      // that each click is handled in order, but for now we just bail. This is
      // probably fine for short transitions, but blocking interactions for long
      // transitions feels a bit weird.
      if (isTransitioning) {
        return state;
      }

      const context = {
        activeSlideIndex: currentActiveSlideIndex,
        activeSlidePosition,
        autoWidth,
        carouselId,
        endBehavior,
        isFade,
        isImmediateTransition,
        isHydrated,
        isTransitioning,
        listSize,
        onActiveSlideChange,
        orientation,
        slideCount,
        slidesPerMove,
        slidesPerPage,
        trackRef,
        transitionEasing,
        transitionSpeed,
      };

      const transitionStates = getSlideTransitionStates(index, context, opts);
      if (!transitionStates) {
        return state;
      }

      // NOTE (Chance 2023-11-16): This action is a bit weird because it
      // doesn't actually update state. I'm handling it in the reducer
      // because I need run a function given some of the current state
      // before we trigger a slide transition with our emitter. The
      // emitter takes the next state, which will be updated in the
      // component's change handler after we know the transition is
      // necessary.
      if (isControlled) {
        emitter.emit("CALL_CONTROLLED_STATE_UPDATER", {
          changeResults: transitionStates,
        });
      } else {
        emitter.emit("START_SLIDE_TRANSITION", {
          changeResults: transitionStates,
        });
      }
      return state;
    }

    case "SET_IMMEDIATE_TRANSITION_STATE":
    case "SET_TRANSITION_START_STATE":
    case "SET_TRANSITION_STOP_STATE": {
      // NOTE (Chance 2024-03-04): When starting a slide transition we need to
      // reset the auto play timer but only if auto play is enabled. If it's not
      // we want to ensure the auto play state is set to paused and we emit an
      // event to stop the timer.
      let autoPlayState = state.autoPlayState;
      if ("autoPlayInterval" in event) {
        const autoPlayInterval = event.autoPlayInterval;
        if (!shouldAutoPlay({ autoPlayInterval })) {
          if (state.autoPlayState !== "paused") {
            autoPlayState = "paused";
          }
        } else if (
          state.autoPlayState === "paused" ||
          state.autoPlayState === "playing"
        ) {
          autoPlayState = "playing";
        }
      }

      return {
        ...state,
        ...event.state,
        autoPlayState,
      };
    }

    case "STOP_ANIMATION": {
      if (state.isTransitioning) {
        return {
          ...state,
          isTransitioning: false,
        };
      }
      return state;
    }

    default:
      return state;
  }

  // NOTE (Chance 2023-11-17): TS knows we shouldn't get here but return current
  // state just in case so shit doesn't break if someone decides to abuse or
  // ignore types
  // biome-ignore lint/correctness/noUnreachable: leave in in case
  return state;
}

export function useCarouselReducer({
  controlledActiveSlideIndex,
  defaultActiveSlideIndex,
  debugEvents,
}: {
  controlledActiveSlideIndex: number | undefined;
  defaultActiveSlideIndex: number | undefined;
  debugEvents: boolean;
}) {
  const emitter = useConstant(() => mitt<ReducerEmittedEvents>());
  const isControlled = controlledActiveSlideIndex !== undefined;
  const [state, dispatch] = React.useReducer(
    carouselReducer,
    {
      activeSlideIndex: isControlled
        ? controlledActiveSlideIndex
        : defaultActiveSlideIndex ?? INITIAL_ACTIVE_SLIDE_INDEX,
    },
    getInitialCarouselState,
  );

  const isControlledRef = React.useRef(isControlled);
  React.useInsertionEffect(() => {
    const wasControlled = isControlledRef.current;
    warning(
      !(isControlled && !wasControlled),
      "Carousel is switching from uncontrolled to controlled. This will likely result in bugs.",
    );
    warning(
      !(!isControlled && wasControlled),
      "Carousel is switching from controlled to uncontrolled. This will likely result in bugs.",
    );
    isControlledRef.current = isControlled;
  }, [isControlled]);

  const _dispatch: React.Dispatch<CarouselReducerEvent> = React.useCallback(
    (event) => {
      dispatch({ ...event, debug: debugEvents, isControlledRef, emitter });
    },
    [debugEvents, emitter],
  );

  return { state, dispatch: _dispatch, emitter };
}

function warnSlideNotFound() {
  warning(
    false,
    "The active slide ID provided to the carousel cannot be found in slides. Using the first slide as the active slide.",
  );
}

function initializeActiveSlideIndex(
  defaultActiveSlideIndex: number,
  context: Pick<CarouselContextValue, "textDirection" | "slideCount">,
) {
  const { textDirection, slideCount } = context;
  const _activeSlideIndex =
    textDirection === DIRECTION_RTL
      ? slideCount - 1 - defaultActiveSlideIndex
      : defaultActiveSlideIndex;
  return resolveActiveSlide(_activeSlideIndex, { slideCount });
}

export type CarouselDispatcher = React.Dispatch<CarouselReducerEvent>;
export type CarouselEmitter = Emitter<ReducerEmittedEvents>;
export type CarouselEmittedEvent = ReducerEmittedEvents;
