import * as React from "react";

/**
 * Returns whether or not a CSS media query matches.
 *
 * @param queryString A string representing a CSS media query
 */
export function useMatchMedia(
  queryString: string,
  options?: UseMatchMediaOptions,
): boolean;

/**
 * Returns whether or not a CSS media query matches.
 *
 * @param queryString A string representing a CSS media query
 * @param defaultMatchState The default state to return before the media query
 * can be evaluated
 */
export function useMatchMedia(
  queryString: string,
  defaultMatchState: boolean,
  options?: UseMatchMediaOptions,
): boolean;

export function useMatchMedia(
  queryString: string,
  maybeDefaultState?: boolean | UseMatchMediaOptions,
  maybeOptions?: UseMatchMediaOptions,
): boolean {
  let defaultMatchState = false;
  let options: UseMatchMediaOptions = {};
  if (maybeDefaultState && typeof maybeDefaultState === "object") {
    options = maybeDefaultState;
  } else {
    defaultMatchState = maybeDefaultState === true;
    options = maybeOptions || {};
  }

  const { effectHook: useEffect = React.useEffect } = options;

  const [state, setState] = React.useState(defaultMatchState);

  useEffect(() => {
    const mql = window.matchMedia(queryString);
    mql.addEventListener("change", handleChange);
    setState(mql.matches);
    return () => {
      mql.removeEventListener("change", handleChange);
    };
    function handleChange(event: MediaQueryListEvent) {
      setState(event.matches);
    }
  }, [queryString]);

  return state;
}

export interface UseMatchMediaOptions {
  /**
   * Add the listener in either `useEffect` or `useLayoutEffect`. Defaults to
   * `useEffect`.
   */
  effectHook?: (
    effect: React.EffectCallback,
    deps?: React.DependencyList,
  ) => void;
}
