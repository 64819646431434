import type { PageInfo } from "@editor/reducers/api-reducer";
import type { StoreProduct } from "replo-runtime/shared/types";
import type { StoreProductSummary } from "schemas/product";

import * as React from "react";

import { useDebouncedValue } from "@editor/hooks/useDebouncedValue";
import { useLazyGetShopifyProductsQuery } from "@editor/reducers/api-reducer";
import { selectIsShopifyIntegrationEnabled } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import useCurrentProjectId from "@hooks/useCurrentProjectId";

/**
 * Hook to fetch product data for the current store.
 */
type HookProps = {
  pageSize: number;
  nextPage?: string;
  query?: string;
  debounceDelay?: number;
};
export function useInfiniteStoreProducts({
  debounceDelay = 500,
  pageSize,
  query = "",
}: HookProps) {
  const [triggerGetProductsQuery, { data, error, isLoading, isFetching }] =
    useLazyGetShopifyProductsQuery();
  const triggerFetchProductsQuery = async (
    projectId: string,
    nextPage: string | undefined,
    query: string,
  ) => {
    const response = await triggerGetProductsQuery(
      {
        storeId: projectId,
        pageSize,
        nextPage,
        query: query,
      },
      true,
    )
      .unwrap()
      .catch(() => null);
    return response;
  };
  const { fetchNextPage, pageInfo, products } = useLoadInfiniteProducts({
    triggerFetchProductsQuery,
    data: data ?? null,
    isLoading,
    isFetching,
    debounceDelay,
    searchTerm: query,
  });

  return {
    fetchNextPage,
    products,
    pageInfo,
    isLoading,
    isFetching,
    error,
  };
}

type ProductQueryResult<T> = {
  products: T[];
  pageInfo: PageInfo;
} | null;

/**
 * Generic hook to handle the infinite-loading logic that's shared for infinite loading of both
 * store products and store product summaries.
 */
function useLoadInfiniteProducts<T extends StoreProduct | StoreProductSummary>({
  triggerFetchProductsQuery,
  data,
  isLoading,
  isFetching,
  debounceDelay = 500,
  searchTerm,
}: {
  triggerFetchProductsQuery: (
    projectId: string,
    nextPage: string | undefined,
    query: string,
  ) => Promise<ProductQueryResult<T>>;
  data: ProductQueryResult<T>;
  isLoading: boolean;
  isFetching: boolean;
  searchTerm?: string;
  debounceDelay?: number;
}) {
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const nextPageRef = React.useRef<string | null>(null);
  const prevDebouncedSearchTermRef = React.useRef<string | undefined>();
  const currentRequestIndexRef = React.useRef<number>(0);
  const [productList, setProductList] = React.useState<T[]>([]);

  const projectId = useCurrentProjectId();

  const debouncedSearchTerm = useDebouncedValue<string | undefined>(
    searchTerm,
    debounceDelay,
  );

  const fetchProducts = React.useCallback(async () => {
    currentRequestIndexRef.current += 1;
    const thisRequestIndex = currentRequestIndexRef.current;
    if (!projectId || !isShopifyIntegrationEnabled) {
      return;
    }
    const response = await triggerFetchProductsQuery(
      projectId,
      nextPageRef.current ?? undefined,
      debouncedSearchTerm ?? "",
    );

    // NOTE (Evan, 8/31/23) This checks if another request has been initiated after the current one,
    // meaning that this response is outdated and we should ignore it. This avoids a situation where the
    // results of a previous query inadvertantly end up in the products list.
    if (!response || thisRequestIndex !== currentRequestIndexRef.current) {
      return;
    }

    const newProducts = response?.products ?? [];
    setProductList((prevProducts) => [...prevProducts, ...newProducts]);
    nextPageRef.current = response.pageInfo.nextPage ?? null;
  }, [
    projectId,
    debouncedSearchTerm,
    triggerFetchProductsQuery,
    isShopifyIntegrationEnabled,
  ]);

  // NOTE (Evan, 8/28/23) This effect handles the initial fetch and re-fetches the products
  // when the (debounced) query changes
  React.useEffect(() => {
    if (debouncedSearchTerm !== prevDebouncedSearchTermRef.current) {
      nextPageRef.current = null;
      void fetchProducts();
      prevDebouncedSearchTermRef.current = debouncedSearchTerm;
    }
  }, [debouncedSearchTerm, fetchProducts]);

  return {
    fetchNextPage: async () => {
      if (!isFetching && nextPageRef.current && projectId) {
        await fetchProducts();
      }
    },
    products: productList,
    pageInfo: data?.pageInfo,
    isLoading,
    isFetching,
  };
}
