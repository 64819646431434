import * as React from "react";

import {
  PopoverContent,
  PopoverPortal,
} from "@replo/design-system/components/shadcn/core/popover";
import twMerge from "@replo/design-system/utils/twMerge";

export type ComboboxPopoverProps = {
  side?: "top" | "bottom" | "left" | "right";
  align?: "start" | "center" | "end";
  sideOffset?: number;
  alignOffset?: number;
  unsafe_className?: string;
  layoutClassName?: string;
};

export const ComboboxPopover: React.FC<
  React.PropsWithChildren<ComboboxPopoverProps>
> = ({
  children,
  unsafe_className,
  layoutClassName,
  side,
  align,
  sideOffset,
  alignOffset,
}) => {
  return (
    <PopoverPortal>
      <PopoverContent
        className={twMerge(
          "flex flex-col border border-secondary p-0 w-full max-h-[var(--radix-popover-content-available-height)]",
          // Note (Noah, 2025-03-14): In order to support comboboxes inside modals, we need to
          // make sure to set a higher z-index than the modal's popover. However, we only want
          // to do this if we're inside a modal, because having a combobox open _behind_ a modal
          // is also a legit use case. So, we bump the z-index only if we have a ModalContext.
          //
          // See https://github.com/radix-ui/primitives/issues/1317
          // modalContext && "!z-100",
          layoutClassName,
          unsafe_className,
        )}
        style={{
          minWidth: "var(--radix-popper-anchor-width)",
        }}
        side={side}
        align={align}
        sideOffset={sideOffset}
        alignOffset={alignOffset}
      >
        {children}
      </PopoverContent>
    </PopoverPortal>
  );
};
