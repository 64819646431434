import type { DynamicDataAttribute } from "@editor/utils/dynamic-data";
import type { PopoverRootProps } from "@replo/design-system/components/popover/Popover";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { TopLevelDynamicDataKey } from "replo-runtime/store/ReploVariable";
import type { ContextRef } from "schemas/product";

import React from "react";

import { useFetchVariantMetafieldsDefinitions } from "@editor/hooks/useFetchVariantMetafieldsDefinitions";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  selectDraftComponentIds,
  selectIsShopifyIntegrationEnabled,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getDynamicDataTree,
  getDynamicDataTypeIcon,
} from "@editor/utils/dynamic-data";
import { stripHtmlTags } from "@editor/utils/string";
import { useFetchProductMetafieldDefinitions } from "@hooks/useFetchProductMetafieldDefinitions";

import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { LargeMenuItem } from "@replo/design-system/components/menu/LargeMenuItem";
import Popover from "@replo/design-system/components/popover/Popover";
import get from "lodash-es/get";
import mapValues from "lodash-es/mapValues";
import omitBy from "lodash-es/omitBy";
import { ChevronLeft } from "lucide-react";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { filterNulls } from "replo-utils/lib/array";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";

type PopulatedDynamicDataAttribute = DynamicDataAttribute & {
  path: string[];
  value: any;
};

export function useDynamicData({
  targetType,
}: {
  targetType: DynamicDataTargetType;
}) {
  // STEP 1: Get all attributes from all draft components
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const draftComponentsContexts = draftComponentIds.map((componentId) =>
    getCurrentComponentContext(componentId, 0),
  );
  const draftComponentsAttributes = draftComponentsContexts.map(
    (context) => context?.attributes,
  );

  // STEP 2: If we are in multiselect mode, we need to return only attributes that
  // are shared between all draft components
  const sharedAttributes = omitBy(
    mapValues(draftComponentsAttributes[0], (value, key) => {
      if (!value) {
        return null;
      }

      return draftComponentsAttributes
        .slice(1)
        .every((draftComponentAttributes) =>
          Boolean(draftComponentAttributes?.[key as TopLevelDynamicDataKey]),
        )
        ? value
        : null;
    }),
    (value) => value === null,
  );

  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const { productMetafieldsDefinitions = [] } =
    useFetchProductMetafieldDefinitions({
      shouldSkip: !isShopifyIntegrationEnabled,
    });

  const { variantMetafieldsDefinitions = [] } =
    useFetchVariantMetafieldsDefinitions({
      shouldSkip: !isShopifyIntegrationEnabled,
    });

  // STEP 3: Now we need to filter attributes down to the options we actually want to show
  const dynamicDataTree = getDynamicDataTree(
    sharedAttributes,
    targetType,
    productMetafieldsDefinitions,
    variantMetafieldsDefinitions,
  );
  const isMultilayeredTree = dynamicDataTree?.length > 1;
  // STEP 4: Populate the tree with data from sharedAttributes
  const populatedTree = React.useMemo(() => {
    if (!dynamicDataTree) {
      return [];
    }

    return dynamicDataTree.map((category) => {
      // NOTE (Jackson, 2025-03-25): attributeKey is null if we need to select
      // data from the top level of the attributes hierarchy
      const attributeData =
        category.parentKey === null
          ? sharedAttributes
          : sharedAttributes[category.parentKey];

      const dynamicDataValues = category.allowedAttributes
        .map((attribute) => {
          const { key: attrKey, displayName, type, description } = attribute;

          // Special handling for metafields - we don't need to get actual values
          // since they'll be fetched later
          const isMetafield = attrKey.includes("Metafields");
          const value = isMetafield
            ? displayName // Use displayName as a placeholder
            : get(attributeData, attrKey);

          return {
            key: attrKey,
            path: filterNulls([
              category.parentKey,
              ...(attrKey !== null ? attrKey.split(".") : []),
            ]),
            displayName: displayName,
            description: value ? description : "No data available",
            value: value,
            type: type,
          };
        })
        .filter((item) => {
          // Include metafields even if they don't have a value yet
          const isMetafield = item.key.includes("Metafields");
          return (
            isMetafield || (item.value !== undefined && item.value !== null)
          );
        });

      return {
        categoryName: category.categoryName,
        categoryDescription: category.categoryDescription,
        dynamicDataValues,
      };
    });
  }, [dynamicDataTree, sharedAttributes]);

  return {
    populatedTree,
    isMultilayeredTree,
  };
}

type DynamicDataComboboxProps = {
  categoryName: string;
  categoryDescription?: string;
  attributes: PopulatedDynamicDataAttribute[];
  onSelect: (value: string) => void;
  trigger?: React.ReactNode;
  side?: "top" | "bottom" | "left" | "right";
  sideOffset?: number;
  initialPath?: string[];
  shouldShowPreviewImage?: boolean;
  layoutClassName?: string;
  openOnHover?: boolean;
  onMouseEnter?: () => void;
  isHovered?: boolean;
};

function formatDescription(
  item: PopulatedDynamicDataAttribute,
): string | undefined {
  const defaultDescription = item.description ?? item.value;
  if (typeof defaultDescription === "string") {
    return stripHtmlTags(defaultDescription);
  }
}

const DynamicDataCombobox = ({
  categoryName,
  categoryDescription,
  attributes,
  onSelect,
  trigger,
  side = "left",
  sideOffset = 10,
  initialPath,
  shouldShowPreviewImage = false,
  layoutClassName,
  onMouseEnter,
  isHovered,
}: DynamicDataComboboxProps) => {
  const [localOpen, setLocalOpen] = React.useState(false);
  const open = isHovered !== undefined ? isHovered : localOpen;

  const selectedValue = initialPath?.join(".") || "";
  const options = attributes.map((item) => ({
    label:
      shouldShowPreviewImage &&
      !categoryName.toLowerCase().includes("metafield") ? (
        <LargeMenuItem
          variant="product"
          productImage={item.value}
          label={item.displayName}
          description={formatDescription(item)}
          selected={item.path.join(".") === selectedValue}
        />
      ) : (
        <LargeMenuItem
          variant="icon"
          startEnhancer={getDynamicDataTypeIcon(item.type)}
          label={item.displayName}
          description={formatDescription(item)}
          selected={item.path.join(".") === selectedValue}
        />
      ),
    displayValue: item.displayName,
    value: item.path.join("."),
    estimatedSize: 44,
  }));

  const handleValueChange = (value: string) => {
    if (isHovered === undefined) {
      setLocalOpen(false);
    }
    onSelect(value);
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (isHovered === undefined) {
      setLocalOpen(isOpen);
    }
  };

  return (
    <Combobox.Root
      options={options}
      value={selectedValue}
      onChange={handleValueChange}
      open={open}
      onOpenChange={handleOpenChange}
      layoutClassName={layoutClassName}
    >
      <div onMouseEnter={onMouseEnter}>
        <Combobox.Trigger>
          {trigger ?? (
            <LargeMenuItem
              variant="flexible"
              label={categoryName}
              description={categoryDescription}
              startEnhancer={<ChevronLeft size={16} />}
            />
          )}
        </Combobox.Trigger>
      </div>
      <Combobox.Popover
        layoutClassName="w-[225px]"
        side={side}
        align="center"
        sideOffset={sideOffset}
      >
        <Combobox.Content
          title={`Select ${categoryName}`}
          areOptionsSearchable
          inputPlaceholder="Search values..."
          emptySearchMessage="No values found"
        />
      </Combobox.Popover>
    </Combobox.Root>
  );
};

type DynamicDataValueTypeMap = {
  [DynamicDataTargetType.PRODUCT]: ContextRef;
  [DynamicDataTargetType.PRODUCT_VARIANT]: ContextRef;
  [DynamicDataTargetType.TEXT_COLOR]: SolidOrGradient;
  default: string;
};

type DynamicDataChangeHandler<T extends DynamicDataTargetType> = (
  value: T extends keyof DynamicDataValueTypeMap
    ? DynamicDataValueTypeMap[T]
    : DynamicDataValueTypeMap["default"],
) => void;

type DynamicDataSelectorProps<T extends DynamicDataTargetType> = Pick<
  Omit<PopoverRootProps, "shouldClose">,
  "isDefaultOpen" | "shouldIgnoreOutsideInteractions"
> & {
  trigger: React.ReactNode;
  side?: "top" | "bottom" | "left" | "right";
  sideOffset?: number;
  targetType: T;
  onChange: DynamicDataChangeHandler<T>;
  location?: "canvas" | "designPanel";
  initialPath?: string[];
  layoutClassName?: string;
  triggerClassName?: string;
};

/*
 * The DynamicDataSelector is a component that allows users to select a value from a
 * dynamic data tree.
 *
 * The DynamicDataCombobox serves as the component where new values are selected,
 * while the Popover serves as a navigation layer if the tree has two layers.
 */

export const DynamicDataSelector = <T extends DynamicDataTargetType>({
  trigger,
  isDefaultOpen,
  side = "bottom",
  sideOffset = 4,
  targetType,
  initialPath,
  location = "designPanel",
  layoutClassName,
  triggerClassName,
  onChange,
}: DynamicDataSelectorProps<T>) => {
  const { isMultilayeredTree, populatedTree } = useDynamicData({
    targetType,
  });

  const analytics = useLogAnalytics();

  const [isOpen, setIsOpen] = React.useState(false);
  const [hoveredCategoryIndex, setHoveredCategoryIndex] = React.useState<
    number | null
  >(null);

  const handleCategoryHover = useDebouncedCallback(
    (index: number) => {
      setHoveredCategoryIndex(index);
      setIsOpen(true);
    },
    100,
    { leading: true },
  );

  const handleSelectValue = (value: string) => {
    const isAddingNewDynamicDataValue = !initialPath || initialPath.length < 1;
    if (isAddingNewDynamicDataValue) {
      analytics("editor.dynamicData.add", {
        location,
        value,
        targetType,
      });
    } else {
      analytics("editor.dynamicData.replace", {
        location,
        value,
        targetType,
      });
    }

    // Special handling for PRODUCT and PRODUCT_VARIANT target types
    if (
      [
        DynamicDataTargetType.PRODUCT,
        DynamicDataTargetType.PRODUCT_VARIANT,
      ].includes(targetType)
    ) {
      const contextRef: ContextRef = {
        type: "contextRef",
        ref: `attributes.${value}`,
      };
      (onChange as (value: ContextRef) => void)(contextRef);
    } else {
      (onChange as (value: string) => void)(`{{attributes.${value}}}`);
    }

    // Only close the popover when a value is actually selected
    if (value) {
      setIsOpen(false);
    }
  };

  // NOTE (Matt 2025-03-28): If there is no dynamic data found, we return the trigger
  // if the component prop already has a path. This is primarily to deal with the usecase
  // where a user has invalid dynamicData already selected (like a DataTable) and we
  // need them to be able to click the trigger to remove the selection.
  if (populatedTree.length === 0 || populatedTree[0] === undefined) {
    return initialPath && initialPath.length > 0 ? trigger : null;
  }

  return isMultilayeredTree ? (
    <Popover.Root
      isDefaultOpen={isDefaultOpen}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      shouldIgnoreOutsideInteractions={false}
    >
      <Popover.Trigger className={triggerClassName}>{trigger}</Popover.Trigger>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside={false}
        side={side}
        sideOffset={sideOffset}
        align="center"
        title="Choose Data Type"
        hideCloseButton
        className="px-1.5"
      >
        {populatedTree?.map((category, index) => (
          <DynamicDataCombobox
            key={index}
            categoryName={category.categoryName}
            categoryDescription={category.categoryDescription}
            attributes={category.dynamicDataValues}
            onSelect={handleSelectValue}
            initialPath={initialPath}
            shouldShowPreviewImage={targetType === DynamicDataTargetType.IMAGE}
            layoutClassName={layoutClassName}
            openOnHover={true}
            onMouseEnter={() => handleCategoryHover(index)}
            isHovered={hoveredCategoryIndex === index}
          />
        ))}
      </Popover.Content>
    </Popover.Root>
  ) : (
    <DynamicDataCombobox
      categoryName={populatedTree[0].categoryName}
      attributes={populatedTree[0].dynamicDataValues}
      onSelect={handleSelectValue}
      trigger={trigger}
      side={side}
      sideOffset={sideOffset}
      initialPath={initialPath}
      shouldShowPreviewImage={targetType === DynamicDataTargetType.IMAGE}
      layoutClassName={layoutClassName}
    />
  );
};
