import type { MenuItem } from "@replo/design-system/components/menu/Menu";
import type { ReploElement } from "schemas/generated/element";

import * as React from "react";
import { useEffect } from "react";

import Input from "@common/designSystem/Input";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useOpenModal } from "@editor/hooks/useModal";
import { useUnarchiveElement } from "@editor/hooks/useUnarchiveElement";
import {
  selectEditorMode,
  selectSelectedArchivedElementId,
  setEditorMode,
  setSelectedArchivedElementId,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { trpc } from "@editor/utils/trpc";

import IconButton from "@replo/design-system/components/button/IconButton";
import { Menu } from "@replo/design-system/components/menu/Menu";
import Popover from "@replo/design-system/components/popover/Popover";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken } from "@tanstack/react-query";
import { Ellipsis } from "lucide-react";
import {
  BsCart,
  BsCheckLg,
  BsDistributeVertical,
  BsFilter,
  BsSearch,
  BsWindow,
  BsX,
} from "react-icons/bs";
import { RiArticleLine } from "react-icons/ri";

import { formatVersionHistoryDateAndTime } from "../version-history/VersionCard";

function getIcon(type: string) {
  switch (type) {
    case "page":
      return <BsWindow className="text-default" size={16} />;
    case "shopifySection":
      return <BsDistributeVertical className="text-default" size={16} />;
    case "shopifyProductTemplate":
      return <BsCart className="text-default" size={16} />;
    case "shopifyArticle":
      return <RiArticleLine className="text-default" size={16} />;
    default:
      return null;
  }
}

type SortBy = "dateArchived" | "alphabetical" | "dateCreated";
type SortOrder = "desc" | "asc";
type SortConfig = { sortBy: SortBy; sortOrder: SortOrder };

const SORT_OPTIONS = [
  { label: "Date Archived", value: "dateArchived" as const },
  { label: "Alphabetical", value: "alphabetical" as const },
  { label: "Date Created", value: "dateCreated" as const },
];

function sortElements(
  elements: ReploElement[],
  { sortBy, sortOrder }: SortConfig,
) {
  const sortFns: Record<SortBy, (a: ReploElement, b: ReploElement) => number> =
    {
      dateArchived: (a, b) =>
        new Date(a.archivedAt!).getTime() - new Date(b.archivedAt!).getTime(),
      alphabetical: (a, b) => a.name.localeCompare(b.name),
      dateCreated: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    };

  return [...elements].sort((a, b) => {
    const comparison = sortFns[sortBy](a, b);
    return sortOrder === "desc" ? -comparison : comparison;
  });
}

function filterElements(elements: ReploElement[], searchTerm: string) {
  if (!searchTerm) {
    return elements;
  }

  const lowerSearchTerm = searchTerm.toLowerCase();
  return elements.filter(
    (element) =>
      element.name.toLowerCase().includes(lowerSearchTerm) ||
      element.type.toLowerCase().includes(lowerSearchTerm),
  );
}

type ArchivedElementsState = {
  searchTerm: string;
  sortConfig: {
    sortBy: SortBy;
    sortOrder: SortOrder;
  };
  selectedElementId: string | null;
};

type ArchivedElementsAction =
  | { type: "SET_SEARCH_TERM"; payload: string }
  | { type: "SET_SORT_BY"; payload: SortBy }
  | { type: "SET_SORT_ORDER"; payload: SortOrder }
  | { type: "SET_SELECTED_ELEMENT"; payload: string | null };

function archivedElementsReducer(
  state: ArchivedElementsState,
  action: ArchivedElementsAction,
): ArchivedElementsState {
  switch (action.type) {
    case "SET_SEARCH_TERM":
      return { ...state, searchTerm: action.payload };
    case "SET_SORT_BY":
      return {
        ...state,
        sortConfig: { ...state.sortConfig, sortBy: action.payload },
      };
    case "SET_SORT_ORDER":
      return {
        ...state,
        sortConfig: { ...state.sortConfig, sortOrder: action.payload },
      };
    case "SET_SELECTED_ELEMENT":
      return { ...state, selectedElementId: action.payload };
    default:
      return state;
  }
}

export default function ArchivedElementsPane() {
  const dispatch = useEditorDispatch();
  const projectId = useCurrentProjectId();
  const { data: elements = [], isLoading } = trpc.element.listArchived.useQuery(
    projectId ? { projectId } : skipToken,
  );
  const [state, dispatchReducer] = React.useReducer(archivedElementsReducer, {
    searchTerm: "",
    sortConfig: {
      sortBy: "dateArchived",
      sortOrder: "desc",
    },
    selectedElementId: null,
  });
  const editorMode = useEditorSelector(selectEditorMode);
  const openModal = useOpenModal();
  const selectedArchivedElementId = useEditorSelector(
    selectSelectedArchivedElementId,
  );
  const { unarchiveElement } = useUnarchiveElement();

  const filteredAndSortedElements = React.useMemo(() => {
    const filtered = filterElements(elements, state.searchTerm);
    return sortElements(filtered, state.sortConfig);
  }, [elements, state.searchTerm, state.sortConfig]);

  const onElementClick = (elementId: string) => {
    dispatch(setSelectedArchivedElementId(elementId));
    dispatchReducer({ type: "SET_SELECTED_ELEMENT", payload: elementId });
  };

  const handleUnarchive = React.useCallback(
    (element: ReploElement) => {
      unarchiveElement(element, { from: "pane" });
    },
    [unarchiveElement],
  );

  const getMenuItems = React.useCallback(
    (element: ReploElement): MenuItem[] => {
      return [
        {
          type: "leaf",
          id: "unarchive",
          title: "Unarchive",
          variant: "default",
          size: "sm",
          onSelect: () => handleUnarchive(element),
          disabled: false,
        },
        {
          type: "leaf",
          id: "delete",
          title: "Delete Forever",
          variant: "default",
          size: "sm",
          onSelect: () =>
            openModal({
              type: "permanentlyDeleteElementModal",
              props: { element },
            }),
          disabled: false,
        },
      ];
    },
    [handleUnarchive, openModal],
  );

  useEffect(() => {
    if (editorMode !== EditorMode.archived) {
      dispatch(setSelectedArchivedElementId(null));
      dispatchReducer({ type: "SET_SELECTED_ELEMENT", payload: null });
    }
  }, [editorMode, dispatch]);

  useEffect(() => {
    const firstElement = elements[0];
    if (
      editorMode === EditorMode.archived &&
      elements.length > 0 &&
      !state.selectedElementId
    ) {
      if (selectedArchivedElementId) {
        const elementExists = elements.some(
          (e) => e.id === selectedArchivedElementId,
        );
        if (elementExists) {
          dispatchReducer({
            type: "SET_SELECTED_ELEMENT",
            payload: selectedArchivedElementId,
          });
        } else {
          if (firstElement) {
            dispatch(setSelectedArchivedElementId(firstElement.id));
            dispatchReducer({
              type: "SET_SELECTED_ELEMENT",
              payload: firstElement.id,
            });
          }
        }
      } else {
        if (firstElement) {
          dispatch(setSelectedArchivedElementId(firstElement.id));
          dispatchReducer({
            type: "SET_SELECTED_ELEMENT",
            payload: firstElement.id,
          });
        }
      }
    }
  }, [
    elements,
    state.selectedElementId,
    dispatch,
    editorMode,
    selectedArchivedElementId,
  ]);

  return (
    editorMode === EditorMode.archived && (
      <div className="fixed left-0 top-[61px] w-[268px] bg-white h-[calc(100%-60px)] border-r-0.5">
        <div className="flex h-full w-full flex-col relative">
          <div className="fixed flex flex-col z-max bg-white w-[268px] py-3 border-b border-slate-200 px-3">
            <div className="flex justify-between items-center">
              <h2 className="text-sm font-semibold text-default">Archived</h2>
              <IconButton
                variant="tertiary"
                size="sm"
                tooltipText="Close"
                onClick={() => dispatch(setEditorMode(EditorMode.edit))}
                icon={<BsX size={16} />}
              />
            </div>
            <div className="mt-3 flex gap-2">
              <Input
                autoComplete="off"
                placeholder="Search"
                value={state.searchTerm}
                onChange={(e) =>
                  dispatchReducer({
                    type: "SET_SEARCH_TERM",
                    payload: e.target.value,
                  })
                }
                startEnhancer={<BsSearch />}
              />
              <SortMenu
                sortBy={state.sortConfig.sortBy}
                setSortBy={(sortBy) =>
                  dispatchReducer({ type: "SET_SORT_BY", payload: sortBy })
                }
                sortOrder={state.sortConfig.sortOrder}
                setSortOrder={(sortOrder) =>
                  dispatchReducer({
                    type: "SET_SORT_ORDER",
                    payload: sortOrder,
                  })
                }
              />
            </div>
          </div>
          <div className="h-full mt-24 overflow-y-scroll no-scrollbar">
            {isLoading ? (
              <div className="flex h-full items-center justify-center">
                <Spinner variant="primary" size={33} />
              </div>
            ) : (
              <div className="flex flex-col relative pl-3 pr-2 gap-1">
                {filteredAndSortedElements.map((element) => (
                  <div
                    className={twMerge(
                      "flex flex-row h-12 justify-between items-center p-2 cursor-pointer hover:bg-hover group rounded",
                      selectedArchivedElementId === element.id &&
                        "bg-selected hover:bg-selected",
                    )}
                    onClick={() => onElementClick(element.id)}
                    key={element.id}
                  >
                    <div className="flex flex-col items-start gap-px">
                      <div className="flex gap-2 items-center">
                        <div className="w-4 pt-px">{getIcon(element.type)}</div>
                        <p className="text-sm text-default truncate overflow-hidden block w-44">
                          {element.name}
                        </p>
                      </div>
                      <p className="text-muted text-sm pl-6">
                        {element?.archivedAt
                          ? formatVersionHistoryDateAndTime(element.archivedAt)
                          : "N/A"}
                      </p>
                    </div>
                    <Menu
                      items={getMenuItems(element)}
                      menuType="normal"
                      customWidth="auto"
                      side="right"
                      trigger={
                        <IconButton
                          icon={<Ellipsis size={12} className="text-muted" />}
                          variant="link"
                          tooltipText="More"
                        />
                      }
                      disableTriggerFocusOnClose
                    />
                  </div>
                ))}
              </div>
            )}
            {filteredAndSortedElements.length === 0 && !isLoading && (
              <div className="flex h-full items-center justify-center">
                <p className="text-muted text-sm">
                  {elements.length === 0
                    ? "No archived elements found"
                    : `No results found for "${state.searchTerm}"`}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}

const SortMenu: React.FC<{
  sortBy: SortBy;
  setSortBy: (value: SortBy) => void;
  sortOrder: SortOrder;
  setSortOrder: (value: SortOrder) => void;
}> = ({ sortBy, setSortBy, sortOrder, setSortOrder }) => {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <IconButton
          icon={<BsFilter />}
          tooltipText="Filter"
          variant="secondary"
          layoutClassName="h-full"
        />
      </Popover.Trigger>
      <Popover.Content
        className="w-[200px] p-2"
        shouldPreventDefaultOnInteractOutside
        hideCloseButton
      >
        <div className="flex flex-col gap-2 p-1">
          <div className="flex flex-col gap-1">
            <span className="text-xs text-default font-semibold">Sort by</span>
            <div className="flex flex-col gap-1">
              {SORT_OPTIONS.map((option) => (
                <button
                  key={option.value}
                  className={twMerge(
                    "text-sm px-2 py-1 text-left flex items-center justify-between rounded text-default hover:bg-hover",
                    sortBy === option.value && "bg-selected hover:bg-selected",
                  )}
                  onClick={() => setSortBy(option.value)}
                >
                  {option.label}
                  {sortBy === option.value && (
                    <BsCheckLg className="text-default" size={16} />
                  )}
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-xs text-default font-semibold">
              Sort Order
            </span>
            <div className="flex flex-col gap-1">
              <button
                className={twMerge(
                  "text-sm px-2 py-1 text-left flex items-center justify-between rounded text-default hover:bg-hover",
                  sortOrder === "desc" && "bg-selected hover:bg-selected",
                )}
                onClick={() => setSortOrder("desc")}
              >
                {sortBy === "alphabetical" ? "Descending" : "Newest First"}

                {sortOrder === "desc" && (
                  <BsCheckLg className="text-default" size={16} />
                )}
              </button>
              <button
                className={twMerge(
                  "text-sm px-2 py-1 text-left flex items-center justify-between rounded text-default hover:bg-hover",
                  sortOrder === "asc" && "bg-selected hover:bg-selected",
                )}
                onClick={() => setSortOrder("asc")}
              >
                {sortBy === "alphabetical" ? "Ascending" : "Oldest First"}
                {sortOrder === "asc" && (
                  <BsCheckLg className="text-default" size={16} />
                )}
              </button>
            </div>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
