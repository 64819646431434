import type { CodeEditorLanguage } from "@editor/components/modals/CodeEditorModal";

import * as React from "react";

import { useModal } from "@editor/hooks/useModal";

import Button from "@replo/design-system/components/button/Button";

export const CodeEditorCustomPropModifier = (props: {
  value: string | null;
  onChange: (value: string) => void;
  language: CodeEditorLanguage;
}) => {
  const { openModal } = useModal();
  const { language, value, onChange } = props;

  return (
    <Button
      variant="secondary"
      layoutClassName="w-full"
      onClick={() =>
        openModal({
          type: "codeEditorModal",
          props: {
            language,
            value,
            onChange,
          },
        })
      }
    >
      Launch Code Editor
    </Button>
  );
};
