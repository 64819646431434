export type EditorRoute = {
  projectId: string;
  elementId?: string;
  productId?: string;
};

export const routes = {
  signup: "/auth/signup",
  home: {
    root: "/home",
    shareTemplate: "/home/template/:templateId",
  },
  allProjects: "/all-projects",
  editor: {
    project: "/editor/:projectId",
    element: "/editor/:projectId/:elementId",
    settings: "/editor/:projectId/project-settings",
    integrations: "/editor/:projectId/integrations",
  },
  workspace: {
    projects: "/workspace/:workspaceId/projects",
    integrations: {
      list: "/workspace/:workspaceId/integrations",
      shopify: "/workspace/:workspaceId/integrations/shopify",
    },
    billing: "/workspace/:workspaceId/billing",
    experiments: {
      list: "/workspace/:workspaceId/abtesting",
      settings: "/workspace/:workspaceId/abtesting/settings",
      details: "/workspace/:workspaceId/abtesting/:experimentId/details",
      results: "/workspace/:workspaceId/abtesting/:experimentId/results",
      edit: "/workspace/:workspaceId/abtesting/:experimentId/edit",
    },
    members: "/workspace/:workspaceId/members",
  },
  analytics: {
    dashboard: "/workspace/:workspaceId/analytics",
    overview: "/workspace/:workspaceId/analytics/overview",
    pageDetails: "/workspace/:workspaceId/analytics/deep-dive",
    insights: "/workspace/:workspaceId/analytics/insights",
  },
  growthAudits: "/workspace/:workspaceId/growth-audits",
};

export function addReferralToUrl(url: string, referral: string | null) {
  if (!referral) {
    return url;
  }
  return `${url}?referral=${referral}`;
}
