import type { JssStyle, Rule } from "jss";
import type {
  DynamicStyleDataMap,
  RuntimeFeatureFlags,
} from "replo-runtime/shared/types";
import type {
  MediaSizeWithDefault,
  StyleAliasConfig,
} from "replo-runtime/shared/utils/breakpoints";
import type {
  ComponentStyleProps,
  StringableCSSProperties,
} from "replo-runtime/shared/utils/styles";
import type { Nullish } from "replo-utils/lib/types";
import type { Component } from "schemas/component";
import type { ReploState, ReploSymbol } from "schemas/generated/symbol";
import type {
  DimensionStyleProperties,
  RuntimeStyleAttribute,
  RuntimeStyleProperties,
} from "schemas/styleAttribute";

import hash from "@emotion/hash";
import jss from "jss";
import preset from "jss-preset-default";
import isFunction from "lodash-es/isFunction";
import merge from "lodash-es/merge";
import pick from "lodash-es/pick";
import pickBy from "lodash-es/pickBy";
import { AlchemyActionTriggers } from "replo-runtime/shared/enums";
import { resolveSymbolRef } from "replo-runtime/shared/symbol";
import { isGradient } from "replo-runtime/shared/types";
import {
  cleanupAliasedStyles,
  convertComponentStylePropsToStyleRules,
  getAliasedStyles,
  getMediaSizeStyleAttributeValue,
  getStylePropsFromComponent,
  mapComponentStyleProps,
} from "replo-runtime/shared/utils/breakpoints";
import {
  componentHasDefinedHeightV2,
  componentHasDefinedWidthV2,
  getChildren,
} from "replo-runtime/shared/utils/component";
import { getDynamicDataCustomPropertyName } from "replo-runtime/shared/utils/css";
import {
  gradientToCssGradient,
  isCssGradient,
} from "replo-runtime/shared/utils/gradient";
import { getAlchemyEditorWindow } from "replo-runtime/shared/Window";
import { getRenderData } from "replo-runtime/store/components";
import {
  getGridWrapperRelatedStyles,
  getGridWrapperStyles,
} from "replo-runtime/store/utils/grid-wrapper";
import { isCompletelyHiddenComponent } from "replo-runtime/store/utils/isCompletelyHiddenComponent";
import { convertPropValueToSectionSetting } from "replo-runtime/store/utils/section-settings";
import { isEmpty } from "replo-utils/lib/misc";
import {
  isValidHttpUrl,
  normalizeUrlIncludingProtocolRelative,
} from "replo-utils/lib/url";
import { styleAttributeToDefaultStyle } from "schemas/styleAttribute";

import { isDynamicDataValue } from "./utils/dynamic-data";

/**
 * Note (Noah, 2023-12-03): This file contains the main code we use to power
 * Replo's style system - that is, the code that translates the props from Replo
 * component JSON into CSS styles recursively.
 *
 * # Terminology
 *
 * 1. "Style Props": ComponentStyleProps, aka the part of the component's props
 *    object that pertains to styles. E.g. { style: {...}, style@sm: { ...}, ...
 *    }
 * 2. "Style Element": StyleElements, aka the object that each Replo component
 *    defines to provide its default styles. E.g. { root: { ... }, ... }
 * 3. "Style Rules": JssStyle, JSS style rules, aka the object that JSS uses to
 *    generate CSS. The main point of this file is to generate these finalized
 *    style rules, given style props. Style rules are a superset of React.CSSProperties.
 *    E.g.: { color: "red", "@media (min-width: 768px)": { color: "blue" } }
 * 4. "Styles": Usually means "Style Rules", but sometimes in the past has been
 *    used to be "Style Props". Generally should not be used because it's
 *    ambiguous
 * 5. "Style rules mapping": Maps a JSS selector to a set of style rules. E.g.
 *    { ".r-123": { color: "red" } }
 */

export type ComponentStyleRulesMapping = Record<string, JssStyle | undefined>;

export type ComponentIdToStyleRulesMapping = Record<
  Component["id"],
  ComponentStyleRulesMapping
>;

jss
  .setup({
    ...preset(),
    createGenerateId: () => (rule) => generateStyleId(rule.key),
  })
  .use(increaseSpecificity());

/**
 * JSS little plugin that increases the CSS specificity for all the rules. We
 * need to do that in order to make sure that we override styles coming from the
 * parent Shopify theme (and to make sure that mobile styles always override
 * desktop, and that state overrides override defaults, etc). In order to do
 * that, we add a `:not(#\20)`
 * https://twitter.com/subzey/status/829050478721896448
 */
function increaseSpecificity() {
  function onProcessRule(rule: Rule) {
    const parent: any = rule.options.parent;
    if (rule.type !== "style" || (parent && parent.type === "keyframes")) {
      return;
    }

    // Note (Noah, 2023-07-10): Start with two levels of specificity because
    // that's enough to override our standardized CSS reset
    let extraSpecificityLevels = 2;

    // Note (Noah, 2023-07-11): If this is a nested rule, we don't need two
    // extra levels of specificity because the "&" rule will have them added
    // already, and rule nesting adds specificity anyway. However, we still want
    // to add more specificity if the rule is a mobile override, etc
    if (rule.key.includes("&")) {
      extraSpecificityLevels = 0;
    }

    // Note (Noah, 2023-07-11): Rules inside media queries should have higher
    // specificity so that they always override the desktop rules (by default in
    // CSS, media queries don't actually add specificity)
    if (
      parent &&
      parent.type === "conditional" &&
      parent.key.includes("@media")
    ) {
      extraSpecificityLevels += 1;
    }

    // Note (Noah, 2023-07-10): If the rule is a state override, give it higher
    // specificity
    if (parseComponentRuleName(rule.key)?.variantId !== undefined) {
      extraSpecificityLevels += 1;
    }

    // Note (Noah, 2023-07-10): ts-expect-error is here because JSS's type
    // definitions don't include the fact that selectorText is present on
    // the Rule type

    // @ts-expect-error
    rule.selectorText = `${generateSelector(extraSpecificityLevels)}${
      // @ts-expect-error
      rule.selectorText
    }`;
  }

  return { onProcessRule };
}

function generateSelector(repeat = 2) {
  const selector = ":not(#\\20)";
  return new Array(repeat + 1).join(selector);
}

/**
 * Merge together two component style prop objects.
 *
 * Note (Noah, 2023-08-29, REPL-7801): We use this function instead of lodash's
 * merge() because it's way faster for our use case - we don't need to do a deep
 * merge because we know the structure of the inputs (we only need to go one level
 * deep)
 */
function mergeStyleProps(
  ...stylePropsList: ComponentStyleProps[]
): ComponentStyleProps {
  const result: Partial<ComponentStyleProps> = {};

  for (const styleProps of stylePropsList) {
    for (const key in styleProps) {
      if (!result[key as keyof ComponentStyleProps]) {
        result[key as keyof ComponentStyleProps] = {};
      }

      for (const innerKey in styleProps[key as keyof ComponentStyleProps]) {
        result[key as keyof ComponentStyleProps]![
          innerKey as keyof RuntimeStyleProperties
        ] =
          styleProps[key as keyof ComponentStyleProps]![
            innerKey as keyof RuntimeStyleProperties
          ];
      }
    }
  }

  return result;
}

/**
 * Registry of component overrides styles. It's used to store styles coming
 * from parent components that are meant to be applied to children components.
 * It's a map of component id to an array of overrides.
 * Each override is an object with the following properties:
 * - ancestorComponentId: the id of the ancestor component that triggers the overwrite
 * - variant: the variant that triggers the override
 * - props: component props that has all the styles to apply to the child component
 */
type OverridesRegistry = Record<
  string,
  Array<{
    ancestorComponentId: string;
    variant: ReploState;
    componentOverrides: Partial<Component>;
  }>
>;

type ComponentStylesBuildConfig = StyleElementContext & {
  isEditor: boolean;
  symbols?: ReploSymbol[];
};

export type StyleElementContext = {
  featureFlags: RuntimeFeatureFlags;
  useSectionSettings?: boolean;
};

type ParentStyleProps = {
  [key in keyof ComponentStyleProps]?: Pick<
    RuntimeStyleProperties,
    | "__hasHeight"
    | "__hasWidth"
    | "display"
    | "flexDirection"
    | "justifyContent"
    | "alignItems"
  >;
};

export type ComponentContext = {
  isRoot: boolean;
  parentStyleProps: ParentStyleProps | null;
  parentHasVariants?: boolean;
  parentOverrideStyleRules?: StringableCSSProperties | null;
  fullPageOffset: number;
};

type GenerateComponentStylesOptions = {
  buildConfig: ComponentStylesBuildConfig;
  componentContext: ComponentContext;
  overridesRegistry: OverridesRegistry;
  rulesMapping?: ComponentStyleRulesMapping;
};

type ComponentStyleData = {
  styleRulesMapping: ComponentStyleRulesMapping | null;
  resolvedComponent: Component | null;
  childComponentContext: ComponentContext | null;
};

type ComponentStyleDataMapping = Record<string, ComponentStyleData>;

type GenerateComponentStyleDataMappingOptions = Omit<
  GenerateComponentStylesOptions,
  "rulesMapping"
> & {
  styleDataMapping?: ComponentStyleDataMapping;
};

/**
 * Generate component's styles and returns them as a stringified HTML style
 * element.
 */
export function generateComponentStyleSheetElementHTMLString(
  component: Component,
  buildConfig: ComponentStylesBuildConfig,
): string {
  const rules = generateStyleRulesRecursively(component, {
    componentContext: {
      isRoot: true,
      parentHasVariants: (component?.variants?.length ?? 0) > 0,
      parentStyleProps: null,
      parentOverrideStyleRules: null,
      fullPageOffset: 0,
    },
    buildConfig,
    rulesMapping: {},
    overridesRegistry: {},
  });
  const sheet = jss.createStyleSheet(rules);
  const styles = sheet.toString({
    format: buildConfig.isEditor,
    allowEmpty: false,
  });
  jss.removeStyleSheet(sheet);
  return `<style id="replo-element-styles">${styles}</style>`;
}

function generateComponentStyleData(
  unresolvedComponent: Component,
  options: GenerateComponentStylesOptions,
): ComponentStyleData | null {
  const { buildConfig, componentContext, overridesRegistry } = options;

  const { symbols, ...styleElementContext } = buildConfig;

  const resolvedComponent = resolveSymbolRef(
    unresolvedComponent,
    Object.fromEntries(symbols?.map((s) => [s.id, s]) ?? []),
  );

  if (resolvedComponent) {
    const componentStyleElements = getRenderData(resolvedComponent.type)
      ?.styleElements ?? {
      root: {},
    };

    // Register component's children overrides styles in the overrides registry.
    // Those will be used by children at the moment of their styles building.
    for (const variant of resolvedComponent.variants ?? []) {
      if (variant.name === "default") {
        continue;
      }

      const { componentOverrides } =
        resolvedComponent.variantOverrides?.[variant.id] ?? {};
      if (!componentOverrides) {
        continue;
      }

      for (const [componentId, overrides] of Object.entries(
        componentOverrides,
      )) {
        // Only want to register overrides for the resolvedComponent's children
        if (componentId === resolvedComponent.id) {
          continue;
        }

        if (overridesRegistry[componentId]) {
          overridesRegistry[componentId]!.push({
            ancestorComponentId: resolvedComponent.id,
            variant,
            componentOverrides: overrides,
          });
        } else {
          overridesRegistry[componentId] = [
            {
              ancestorComponentId: resolvedComponent.id,
              variant,
              componentOverrides: overrides,
            },
          ];
        }
      }
    }

    const selfIsRoot = componentContext.isRoot || false;

    const selfOrParentHasVariants =
      componentContext.parentHasVariants ||
      (resolvedComponent.variants?.length ?? 0) > 0;

    const childrenOverrideStyles =
      componentStyleElements.root?.childrenOverrideStyles;

    const componentChildrenContext: ComponentContext = {
      isRoot: selfIsRoot,
      parentHasVariants: selfOrParentHasVariants,
      parentStyleProps: null,
      parentOverrideStyleRules: isFunction(childrenOverrideStyles)
        ? childrenOverrideStyles({ component: resolvedComponent })
        : childrenOverrideStyles,
      fullPageOffset: componentContext.fullPageOffset,
    };

    // Note (Noah, 2023-08-19, REPL-8212): Don't render styles for completely
    // hidden components, since they will be rendered as null and never shown
    // on the published page anyway
    if (
      isCompletelyHiddenComponent(resolvedComponent) &&
      !selfOrParentHasVariants
    ) {
      return null;
    }

    const componentStyleElementsRulesMapping: ComponentStyleRulesMapping = {};
    let componentChildrenContextParentStyleProps = null;

    for (const [styleElementName, styleElement] of Object.entries(
      componentStyleElements,
    )) {
      if (
        styleElement.shouldRender &&
        !styleElement.shouldRender(styleElementContext)
      ) {
        continue;
      }

      // Get all styles in the style format for a component and one of its style
      // elements.
      // Evaluate dynamic data on styles from component props, then merge default
      // styles with component prop styles
      const defaultStyleRules = isFunction(styleElement.defaultStyles)
        ? styleElement.defaultStyles({
            component: resolvedComponent,
            context: styleElementContext,
          }) ?? {}
        : styleElement.defaultStyles;
      const styleProps = mergeStyleProps(
        {
          style: defaultStyleRules,
        },
        mapComponentStyleProps(
          getStylePropsFromComponent(resolvedComponent),
          (styles) => styles,
          { ignoreBaseStyles: true },
        ),
      );

      const {
        processedStyleProps,
        processedStylePropsWithNoCleanup,
        processedStylePropsForGridWrapper,
        processedStylePropsForChildren,
      } = processComponentStyleProps(
        styleProps,
        resolvedComponent,
        componentContext,
        buildConfig,
      );

      // Calculate overridden styles
      const overrideStyleRules = isFunction(styleElement.overrideStyles)
        ? styleElement.overrideStyles({
            component: resolvedComponent,
            styleProps: processedStylePropsWithNoCleanup,
            context: styleElementContext,
          })
        : styleElement.overrideStyles;
      // Convert to JSS style objects and merge with overridden styles
      const unresolvedComponentStyleRules = merge(
        {},
        convertComponentStylePropsToStyleRules(processedStyleProps),
        overrideStyleRules,
        componentContext.parentOverrideStyleRules,
      );
      const gridWrapperStyleRules =
        processedStylePropsForGridWrapper &&
        convertComponentStylePropsToStyleRules(
          processedStylePropsForGridWrapper,
        );

      if (styleElementName === "root") {
        // 1. Build the base style rules
        const baseStyleSelector = getComponentRuleName(resolvedComponent.id, {
          styleElementName,
        });
        const componentStyleRules = resolveDynamicDataForStyleRules(
          unresolvedComponentStyleRules,
        );

        // Build component's overrides styles that are stored in the overrides
        // registry. Those have been previously registered by parent components.
        const overrideStyleRulesMapping = Object.fromEntries(
          overridesRegistry[resolvedComponent.id]?.map(
            ({ ancestorComponentId, variant, componentOverrides }) => {
              // TODO: Bring :hover back for non editor
              // const selector = isHoverVariant(variant)
              //   ? `${classNameOfAncestorComponent}:hover &`
              //   : ...;

              // Note (Noah, 2023-07-11): Use a class selector (.) with the generated
              // className here instead of a JSS dependency (which would have $)
              // because in the editor these rules may be in two different stylesheets
              // if the override is for a descendant of the component which has the
              // state.
              return [
                `.${generateStyleId(
                  getComponentRuleName(ancestorComponentId, {
                    variantId: variant.id,
                  }),
                )} &`,
                getComponentVariantStyleRules(
                  resolvedComponent,
                  componentOverrides,
                  componentContext,
                  buildConfig,
                ),
              ];
            },
          ) ?? [],
        );

        // TODO: Bring :hover back for non editor
        // const hoverVariantId = resolvedComponent.variants?.find(isHoverVariant)?.id;
        // const hoverComponentOverrides = hoverVariantId
        //     ? resolvedComponent.variantOverrides?.[hoverVariantId]?.componentOverrides
        //     : null;
        // let hoverStyleRules: JssStyle = {};
        // if (hoverComponentOverrides) {
        //   const { [resolvedComponent.id]: rootOverrides } = hoverComponentOverrides;
        //   hoverStyleRules = {
        //     "&:hover": resolveDynamicDataForStyleRules(rootOverrides, resolvedComponent.type),
        //   };

        const baseStyleRulesMapping = {
          [baseStyleSelector]: {
            ...componentStyleRules,
            // ...hoverStyleRules,
            // Note (Noah, 2024-03-31): This is a nested mapping, which is fine, because
            // the keys of this overrideStyleRulesMapping contain "&" which JSS resolves.
            // E.g. { ".r-123": { ".r-456 &": { "color": "red" } } } will correctly generate
            // a rule which applies red color to .r-123's which are contained within .r-456's
            ...overrideStyleRulesMapping,
          },
        };

        // 2. Build the variant style rules to use with JSS. This includes the
        //    variants' own styles and their responsive styles. It uses
        //    jss-plugin-compose to enable classes composition.
        const variantStyleRulesMapping: ComponentStyleRulesMapping = {};
        if (resolvedComponent.variants) {
          Object.assign(
            variantStyleRulesMapping,
            Object.fromEntries(
              resolvedComponent.variants
                .filter(
                  (variant) => variant.name !== "default",
                  // TODO Note (Noah, 2023-06-07): Ideally we'd want to filter out this variant
                  // when it's a hover variant since the :hover pseudo-selector should account for
                  // it, but in the editor we need to manually trigger it via the right bar, so
                  // we need a separate classname for it for now.
                  // && !isHoverVariant(variant)
                )
                .map((variant) => {
                  const { componentOverrides } =
                    resolvedComponent.variantOverrides?.[variant.id] ?? {};

                  if (!componentOverrides) {
                    return [];
                  }

                  const rootOverrides =
                    componentOverrides[resolvedComponent.id];

                  // If there are children overrides, we need this variant selector to
                  // exist so that is can be used in getComponentOverridesStyles.
                  // So the way we do that is by adding a CSS rule that is never used
                  // and acts as a placeholder.
                  const placeholderStyles = !isEmpty(componentOverrides?.length)
                    ? { zoom: 1 }
                    : undefined;

                  return [
                    getComponentRuleName(resolvedComponent.id, {
                      styleElementName,
                      variantId: variant.id,
                    }),
                    rootOverrides
                      ? getComponentVariantStyleRules(
                          resolvedComponent,
                          rootOverrides,
                          componentContext,
                          buildConfig,
                        )
                      : placeholderStyles,
                  ];
                }),
            ),
          );
        }

        // 3. Build the grid wrapper style rules
        const gridWrapperRulesMapping = {
          [getComponentRuleName(resolvedComponent.id, { isGridWrapper: true })]:
            gridWrapperStyleRules,
        };

        Object.assign(componentStyleElementsRulesMapping, {
          ...baseStyleRulesMapping,
          ...variantStyleRulesMapping,
          ...gridWrapperRulesMapping,
        });

        componentChildrenContextParentStyleProps =
          processedStylePropsForChildren;
      } else {
        // TODO (Noah, 2024-04-03, REPL-11211): This code is duplicated heavily
        // from the "root" case, clean it up
        const overrideStyleRulesMapping = Object.fromEntries(
          overridesRegistry[resolvedComponent.id]?.map(
            ({ ancestorComponentId, variant, componentOverrides }) => {
              // TODO: Bring :hover back for non editor
              // const selector = isHoverVariant(variant)
              //   ? `${classNameOfAncestorComponent}:hover &`
              //   : ...;

              const overrideStyleRulesForThisVariant = isFunction(
                styleElement.overrideStyles,
              )
                ? styleElement.overrideStyles({
                    component: resolvedComponent,
                    styleProps: getComponentVariantStyleProps(
                      resolvedComponent,
                      componentOverrides,
                      componentContext,
                      buildConfig,
                    ).processedStylePropsWithNoCleanup,
                    context: styleElementContext,
                  }) ?? {}
                : {};

              // Note (Noah, 2023-07-11): Use a class selector (.) with the generated
              // className here instead of a JSS dependency (which would have $)
              // because in the editor these rules may be in two different stylesheets
              // if the override is for a descendant of the component which has the
              // state.
              return [
                `.${generateStyleId(
                  getComponentRuleName(ancestorComponentId, {
                    variantId: variant.id,
                  }),
                )} &`,
                overrideStyleRulesForThisVariant,
              ];
            },
          ) ?? [],
        );

        componentStyleElementsRulesMapping[
          getComponentRuleName(resolvedComponent.id, {
            styleElementName,
          })
        ] = Object.assign(
          {},
          defaultStyleRules,
          overrideStyleRules,
          overrideStyleRulesMapping,
        );

        const variantStyleRulesMapping: ComponentStyleRulesMapping = {};
        if (resolvedComponent.variants) {
          Object.assign(
            variantStyleRulesMapping,
            Object.fromEntries(
              resolvedComponent.variants
                .filter(
                  (variant) => variant.name !== "default",
                  // TODO Note (Noah, 2023-06-07): Ideally we'd want to filter out this variant
                  // when it's a hover variant since the :hover pseudo-selector should account for
                  // it, but in the editor we need to manually trigger it via the right bar, so
                  // we need a separate classname for it for now.
                  // && !isHoverVariant(variant)
                )
                .map((variant) => {
                  const { componentOverrides } =
                    resolvedComponent.variantOverrides?.[variant.id] ?? {};

                  if (!componentOverrides) {
                    return [];
                  }

                  const rootOverrides =
                    componentOverrides[resolvedComponent.id];

                  const overrideStyleRulesForThisVariant = isFunction(
                    styleElement.overrideStyles,
                  )
                    ? styleElement.overrideStyles({
                        component: resolvedComponent,
                        styleProps: getComponentVariantStyleProps(
                          resolvedComponent,
                          rootOverrides,
                          componentContext,
                          buildConfig,
                        ).processedStylePropsWithNoCleanup,
                        context: styleElementContext,
                      }) ?? {}
                    : {};

                  return [
                    getComponentRuleName(resolvedComponent.id, {
                      styleElementName,
                      variantId: variant.id,
                    }),
                    overrideStyleRulesForThisVariant,
                  ];
                }),
            ),
          );
        }
        Object.assign(
          componentStyleElementsRulesMapping,
          variantStyleRulesMapping,
        );
      }
    }

    return {
      styleRulesMapping: componentStyleElementsRulesMapping,
      resolvedComponent,
      childComponentContext: {
        ...componentChildrenContext,
        parentStyleProps: componentChildrenContextParentStyleProps,
      },
    };
  }

  return null;
}

export function generateStyleRulesRecursively(
  unresolvedComponent: Component,
  options: GenerateComponentStylesOptions,
) {
  const {
    buildConfig,
    overridesRegistry,
    rulesMapping: allRulesMapping = {},
  } = options;

  const { styleRulesMapping, resolvedComponent, childComponentContext } =
    generateComponentStyleData(unresolvedComponent, options) ?? {};

  if (styleRulesMapping) {
    Object.assign(allRulesMapping, styleRulesMapping);
  }

  if (resolvedComponent) {
    for (const childComponent of getChildren(resolvedComponent)) {
      generateStyleRulesRecursively(childComponent, {
        componentContext: {
          ...childComponentContext!,
          isRoot: false,
        },
        buildConfig,
        overridesRegistry,
        rulesMapping: allRulesMapping,
      });
    }
  }

  return allRulesMapping;
}

export function generateComponentStyleDataMapping(
  unresolvedComponent: Component,
  options: GenerateComponentStyleDataMappingOptions,
): ComponentStyleDataMapping {
  const { buildConfig, overridesRegistry, styleDataMapping = {} } = options;

  const componentStyleData = generateComponentStyleData(
    unresolvedComponent,
    options,
  );

  if (componentStyleData) {
    Object.assign(styleDataMapping, {
      [unresolvedComponent.id]: componentStyleData,
    });
  }

  if (componentStyleData?.resolvedComponent) {
    for (const childComponent of getChildren(
      componentStyleData.resolvedComponent,
    )) {
      generateComponentStyleDataMapping(childComponent, {
        componentContext: {
          ...componentStyleData.childComponentContext,
          isRoot: false,
          parentStyleProps:
            componentStyleData.childComponentContext?.parentStyleProps ?? null,
          fullPageOffset:
            componentStyleData.childComponentContext?.fullPageOffset ?? 0,
        },
        buildConfig,
        overridesRegistry,
        styleDataMapping,
      });
    }
  }

  return styleDataMapping;
}

/**
 * Given a key, returns an optimized version that hashes it and adds a prefix.
 */
export function generateStyleId(key: string, prefix = "r") {
  return `${prefix}-${hash(key)}`;
}

function isValidDynamicDataStyleUrl(url: string) {
  // Note (Noah, 2023-08-19, REPL-8250): In some cases, e.g. in the published
  // page where we get dynamic data URLs for variant images from liquid, these
  // urls will be protocol-relative (Shopify renders them that way). We need to
  // handle this case and still wrap things in url() even if they're protocol-relative
  // (which interestingly is not a valid URL according to the js spec)
  return isValidHttpUrl(
    normalizeUrlIncludingProtocolRelative(url)?.toString() ?? "",
  );
}

/**
 * Function that calculates extra styles that have some kind of runtime
 * dependency and therefore cannot be statically calculated at build time.
 * It should mostly be used to set values for CSS custom properties that were
 * used as values for other properties at build time.
 */
export function getRuntimeStyles({
  dynamicStyleDataMap,
  desktopBackgroundImage,
}: {
  dynamicStyleDataMap: DynamicStyleDataMap;
  desktopBackgroundImage?: string | null;
}) {
  const styleProperties: Record<string, string> = {};

  for (const [dynamicKey, { key, value: evaluatedValue }] of Object.entries(
    dynamicStyleDataMap,
  )) {
    const dynamicStyleKey = getDynamicDataCustomPropertyName(dynamicKey);
    if (isGradient(evaluatedValue)) {
      const backgroundImageGradient = gradientToCssGradient(evaluatedValue);
      if (backgroundImageGradient) {
        let backgroundImageValue = backgroundImageGradient;
        if (desktopBackgroundImage) {
          const value = isDynamicDataValue(desktopBackgroundImage)
            ? `var(${getDynamicDataCustomPropertyName(desktopBackgroundImage)})`
            : desktopBackgroundImage;
          backgroundImageValue += `, ${value}`;
        }

        styleProperties[`${dynamicStyleKey}-background-image`] =
          backgroundImageValue;

        const fallbackColor = evaluatedValue.stops[0]?.color;
        if (fallbackColor) {
          styleProperties[`${dynamicStyleKey}-background-color`] =
            fallbackColor;
        }

        if (key === "color") {
          styleProperties[`${dynamicStyleKey}-background-clip`] = "text";
          styleProperties[`${dynamicStyleKey}-webkit-text-fill-color`] =
            "transparent";

          // NOTE (Martin, 2023-09-27): The following line is a hack. Chrome
          // currently has a bug where a custom property assigned to
          // -webkit-background-clip will not be applied so we need to set the
          // "text" value directly for it to work.
          // More info about the bug: https://stackoverflow.com/q/56843922
          styleProperties["-webkit-background-clip"] = "text";
        }
      }
    } else {
      let value = String(evaluatedValue);
      const styleKeysRequiringUrls: RuntimeStyleAttribute[] = [
        "backgroundImage",
      ];
      if (
        styleKeysRequiringUrls.includes(key) ||
        isValidDynamicDataStyleUrl(value)
      ) {
        // Note (Noah, 2023-08-29, REPL-8307, REPL-8142): We need the quotes here
        // because otherwise if the url has parentheses in it (fairly common) then
        // CSS will parse the property value incorrectly which can result in dropped
        // swatch images
        value = `url("${value}")`;
      }

      let dynamicStyleKeySuffix = "";
      if (key === "backgroundColor") {
        dynamicStyleKeySuffix = "-background-color";
      }

      styleProperties[`${dynamicStyleKey}${dynamicStyleKeySuffix}`] = value;
    }
  }

  return styleProperties;
}

function getComponentDimensionStyles(
  mediaStyles: RuntimeStyleProperties,
  mediaSize: MediaSizeWithDefault,
  componentContext: ComponentContext,
): DimensionStyleProperties {
  // Calculate dimensions styles based on current and parent styles.
  const parentFlexDirection =
    getMediaSizeStyleAttributeValue(
      "flexDirection",
      componentContext.parentStyleProps ?? {},
      mediaSize,
    ) ?? styleAttributeToDefaultStyle.flexDirection;

  const parentHasDefinedHeight = Boolean(
    getMediaSizeStyleAttributeValue(
      "__hasHeight",
      componentContext.parentStyleProps ?? {},
      mediaSize,
    ),
  );

  const parentHasDefinedWidth = Boolean(
    getMediaSizeStyleAttributeValue(
      "__hasWidth",
      componentContext.parentStyleProps ?? {},
      mediaSize,
    ),
  );

  return {
    __hasWidth: componentHasDefinedWidthV2(
      parentFlexDirection,
      parentHasDefinedWidth,
      mediaStyles,
      componentContext.isRoot,
    ),
    __hasHeight: componentHasDefinedHeightV2(
      parentFlexDirection,
      parentHasDefinedHeight,
      mediaStyles,
    ),
    __parentHasWidth: parentHasDefinedWidth,
    __parentHasHeight: parentHasDefinedHeight,
    __parentFlexDirection: parentFlexDirection,
    __parentDisplay:
      getMediaSizeStyleAttributeValue(
        "display",
        componentContext.parentStyleProps ?? {},
        mediaSize,
      ) ?? styleAttributeToDefaultStyle.display,
    __parentJustifyContent:
      getMediaSizeStyleAttributeValue(
        "justifyContent",
        componentContext.parentStyleProps ?? {},
        mediaSize,
      ) ?? styleAttributeToDefaultStyle.justifyContent,
    __parentAlignItems:
      getMediaSizeStyleAttributeValue(
        "alignItems",
        componentContext.parentStyleProps ?? {},
        mediaSize,
      ) ?? styleAttributeToDefaultStyle.alignItems,
  };
}

function getComponentExtraProps(
  mediaStyles: RuntimeStyleProperties,
  component: Component,
  componentContext: ComponentContext,
): RuntimeStyleProperties {
  // Calculate extra styles that might be needed based on component props,
  // context and build config.
  const stylesToAdd: RuntimeStyleProperties = {};
  if (
    AlchemyActionTriggers.some((trigger) => !isEmpty(component.props[trigger]))
  ) {
    stylesToAdd.cursor = mediaStyles.cursor ?? "pointer";
  }

  if (
    componentContext.parentHasVariants &&
    (component.variants?.length ?? 0) === 0
  ) {
    stylesToAdd.transition = "inherit";
  }

  return stylesToAdd;
}

/**
 * Takes component props styles and runs them through all the necessary
 * transformations to get the final styles.
 */
function processComponentStyleProps(
  styleProps: ComponentStyleProps,
  component: Component,
  componentContext: ComponentContext,
  buildConfig: ComponentStylesBuildConfig,
) {
  const styleAliasConfig = getComponentStyleAliasConfig(component.type, {
    isEditor: buildConfig.isEditor,
    fullPageOffset: componentContext.fullPageOffset,
  });
  let hasGrid = false;
  const gridWrapperStyleProps: ComponentStyleProps = {};
  const processedStylesWithNoCleanup: ComponentStyleProps = {};
  const processedStylePropsForChildren: ParentStyleProps = {};

  // Merge with dimensions styles
  let processedStyles = mapComponentStyleProps(
    styleProps,
    (styleRules, mediaSize, mediaSizeStyleRules) => {
      return Object.assign(
        {},
        mediaSizeStyleRules,
        getComponentDimensionStyles(styleRules, mediaSize, componentContext),
      );
    },
  );

  // Apply aliases overrides
  processedStyles = mapComponentStyleProps(
    processedStyles,
    (styleRules, mediaSize, mediaSizeStyleRules) => {
      return getAliasedStyles({
        styles: styleRules,
        mediaSizeStyles: mediaSizeStyleRules,
        mediaSize,
        animations: component.animations ?? [],
        config: styleAliasConfig,
      });
    },
  );

  // Apply grid wrapper related overrides, extra component props related styles
  // and get styles for the GridWrapper while mapping through all the media sizes.
  // Return cleaned up styles while also storing the styles without cleanup.
  processedStyles = mapComponentStyleProps(
    processedStyles,
    (styleRules, mediaSize, mediaSizeStyleRules) => {
      if (styleRules.display === "grid") {
        hasGrid = true;
      }

      const currentKey =
        mediaSize === ""
          ? "style"
          : (`style@${mediaSize}` as keyof ComponentStyleProps);

      gridWrapperStyleProps[currentKey] = getGridWrapperStyles(styleRules);
      processedStylesWithNoCleanup[currentKey] = Object.assign(
        {},
        mediaSizeStyleRules,
        getGridWrapperRelatedStyles(styleRules),
        getComponentExtraProps(styleRules, component, componentContext),
      );

      processedStylePropsForChildren[currentKey] = pick(
        processedStylesWithNoCleanup[currentKey],
        [
          "__hasHeight",
          "__hasWidth",
          "display",
          "flexDirection",
          "justifyContent",
          "alignItems",
        ],
      );

      return cleanupAliasedStyles(
        processedStylesWithNoCleanup[currentKey]!,
        styleAliasConfig,
      );
    },
  );

  // NOTE (Matt 2024-03-19): If section settings is enabled, we need
  // to update any applicable styles to utilize the corresponding value.
  // Currently this only applies to backgroundImage. The logic for assigning
  // the correct style value is in `replo-deps-section-settings`, this is
  // just overwriting the styleValue as dynamic data so a CSS variable
  // can be assigned.
  if (buildConfig.useSectionSettings) {
    processedStyles = mapComponentStyleProps(
      processedStyles,
      (styles, mediaSize) => {
        if (
          styles.backgroundImage &&
          !styles.backgroundImage.startsWith("{{") &&
          !isCssGradient(styles.backgroundImage) &&
          styles.backgroundImage !== "none"
        ) {
          // NOTE (Matt 2024-04-29): We only override the backgroundImage with a sectionSetting
          // if the user has set an image for a specific breakpoint intentionally as a prop.
          // The values of `styles` at this point could correspond to a breakpoint where a user
          // did not explicitly set a background image, so we check and change the value of
          // sectionSettingsModifiers accordingly.
          const currentKey =
            mediaSize === ""
              ? "style"
              : (`style@${mediaSize}` as keyof ComponentStyleProps);
          const explicitStyleProps = component.props[currentKey];
          const sectionSettingModifiers = [];
          if (explicitStyleProps?.backgroundImage) {
            sectionSettingModifiers.push(`style${mediaSize}`);
          } else {
            sectionSettingModifiers.push("style");
          }
          const sectionSettingValue = convertPropValueToSectionSetting(
            "backgroundImage",
            styles.backgroundImage,
            component.id,
            sectionSettingModifiers,
          );
          if (sectionSettingValue) {
            styles.backgroundImage = sectionSettingValue;
          }
        }
        return styles;
      },
    );
  }

  return {
    processedStyleProps: processedStyles,
    processedStylePropsWithNoCleanup: processedStylesWithNoCleanup,
    processedStylePropsForChildren,
    // Note (Noah, 2023-08-19, REPL-8258): check whether we actually need to
    // add grid wrapper styles. If we don't, we'll simply return undefined for
    // the gridWrapper styles since that would just be unused CSS which bloats the page.
    processedStylePropsForGridWrapper: hasGrid
      ? gridWrapperStyleProps
      : undefined,
  };
}

function getComponentStyleAliasConfig(
  componentType: Component["type"],
  args: {
    isEditor: boolean;
    fullPageOffset: number;
  },
): StyleAliasConfig {
  const { isEditor, fullPageOffset } = args;
  const editorWindow = getAlchemyEditorWindow();
  return {
    addGapCssVariable: ["carouselV3Slides"].includes(componentType),
    alwaysHideOverflowYIfPossible: ["text"].includes(componentType),
    defaultToPositionRelative:
      getRenderData(componentType)?.canContainChildren ?? false,
    viewportHeight: editorWindow?.innerHeight,
    isEditor,
    fullPageOffset,
  };
}

/**
 * Builds the selector to pass to JSS as the name of the style rule. This
 * function standardizes the names based on the various types of rules we
 * generate (e.g. whether we're generating a rule for a state override, etc) and
 * can be parsed using `parseComponentRuleName`.
 *
 * This selector gets hashed to create the class name (e.g. `r-1yh55pz`) for the
 * component.
 */
export function getComponentRuleName(
  componentId: string,
  {
    styleElementName,
    variantId,
    isGridWrapper,
  }: {
    styleElementName?: string | Nullish;
    variantId?: string | Nullish;
    isGridWrapper?: boolean;
  },
) {
  return JSON.stringify({
    id: componentId,
    styleElementName:
      styleElementName !== "root" ? styleElementName : undefined,
    variantId,
    isGridWrapper,
  });
}

/**
 * Given a standard jss rulename for a Replo style rule, return the set of configs
 * that specifies the rule it represents. Useful for when you have a style rule, and
 * you want to figure out metadata about it (e.g. was it a state override? etc)
 *
 * Inverse of `getComponentRuleName`
 */
function parseComponentRuleName(selector: string) {
  try {
    // Note (Noah, 2024-08-18): This function is called frequently, once for
    // every insert of a style element, so for performance reasons, instead of
    // doing something expensive like parsing with a schema, we cast instead.
    return JSON.parse(selector) as {
      id: string;
      styleElementName?: string;
      variantId?: string;
      isGridWrapper?: boolean;
    };
  } catch {
    return undefined;
  }
}

function getComponentVariantStyleProps(
  component: Component,
  componentOverrides: Partial<Component> | undefined,
  componentContext: ComponentContext,
  buildConfig: ComponentStylesBuildConfig,
) {
  const { processedStyleProps, processedStylePropsWithNoCleanup } =
    processComponentStyleProps(
      mergeStyleProps(
        mapComponentStyleProps(
          getStylePropsFromComponent(component),
          (styles) => styles,
          { ignoreBaseStyles: true },
        ),
        componentOverrides
          ? mapComponentStyleProps(
              getStylePropsFromComponent(componentOverrides as Component),
              (styles) => styles,
              { ignoreBaseStyles: true },
            )
          : {},
      ),
      component,
      componentContext,
      buildConfig,
    );

  return { processedStyleProps, processedStylePropsWithNoCleanup };
}

function getComponentVariantStyleRules(
  component: Component,
  componentOverrides: Partial<Component> | undefined,
  componentContext: ComponentContext,
  buildConfig: ComponentStylesBuildConfig,
) {
  const { processedStyleProps } = getComponentVariantStyleProps(
    component,
    componentOverrides,
    componentContext,
    buildConfig,
  );

  // Note (Martin, 2023-09-26): Process variant styles by getting the
  // final style object and converting dynamic data paths properly.
  return resolveDynamicDataForStyleRules(
    convertComponentStylePropsToStyleRules(processedStyleProps) ?? {},
  );
}

export function resolveDynamicDataForStyleRules(
  style: StringableCSSProperties,
) {
  const baseStyles: StringableCSSProperties = pickBy(
    style,
    (_value, key) => !key.startsWith("@media"),
  );
  const mediaStyles: StringableCSSProperties = pickBy(style, (_value, key) =>
    key.startsWith("@media"),
  );

  return {
    ...resolveDynamicDataForCssRules(baseStyles),
    ...resolveDynamicDataForCssRules(mediaStyles),
  };
}

/**
 * Takes styles from a component and returns styles with resolved values for
 * those that use dynamic data. This function recursively traverses nested
 * style rules.
 */
function resolveDynamicDataForCssRules(
  styles: Record<string, string | number | Record<string, string | number>>,
) {
  let resolvedStyleRules: Record<
    string,
    string | number | Record<string, string | number>
  > = {};
  for (const propertyKey in styles) {
    const propertyValue = styles[propertyKey]!;
    if (propertyValue === null) {
      continue;
    }

    if (typeof propertyValue === "object") {
      // @ts-expect-error
      resolvedStyleRules = {
        ...resolvedStyleRules,
        [propertyKey]: resolveDynamicDataForCssRules(propertyValue),
      };
    } else {
      if (
        typeof propertyValue === "string" &&
        propertyValue.includes("{{") &&
        !propertyValue.includes("{%")
      ) {
        const propertyName = propertyValue
          // replace `url({{value}})` with {{value}}, where {{value}} may be wrapped
          // in single or double quotes
          .replace(/url\((["']?)(.*?)\1\)/g, "$2")
          .replace(/{{\s*(.*?)\s*}}/g, (_, match) => {
            // Process the content inside {{...}}
            return `var(--replo-${match
              .trim() // Remove extra spaces
              // replace `url({{value}})` with {{value}}, where {{value}} may be wrapped
              // in single or double quotes
              // replace whitespace and separating punctuation with dashes
              .replace(/[\s!&,./:;=\\_]+/g, "-")
              // remove any remaining non-alphanumeric characters
              .replace(/[^\w-]+/g, "")
              // trim starting and trailing dashes
              .replace(/^-+/, "")
              .replace(/-+$/, "")
              .toLowerCase()})`;
          });
        resolvedStyleRules[propertyKey] = propertyName;
      } else {
        resolvedStyleRules[propertyKey] = propertyValue;
      }
    }
  }

  return resolvedStyleRules;
}
