import type { SimpleExperiment } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import type {
  AnalyticsReadQuery,
  RangeResult,
} from "schemas/generated/analyticsRead";
import type { Experiment } from "schemas/generated/experiment";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import { BackButton } from "@editor/components/common/BackButton";
import { Loader } from "@editor/components/common/Loader";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { docs } from "@editor/utils/docs";
import { routes } from "@editor/utils/router";

import {
  DEFAULT_FILTERS,
  DEFAULT_QUERY,
  OVERVIEW_TABLE_METRICS,
} from "@/features/analytics/constants";
import useBasicAnalyticsRead from "@/features/analytics/useBasicAnalyticsRead";
import { ExperimentActionsBar } from "@/features/experiments/components/ExperimentActionsBar";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import { ExperimentTabWrapper } from "@/features/experiments/tabs/ExperimentTabWrapper";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import copy from "copy-to-clipboard";
import { endOfDay, startOfDay } from "date-fns";
import { BsDash, BsInfoCircle } from "react-icons/bs";
import {
  LuArrowUpRight,
  LuCopy,
  LuExternalLink,
  LuNetwork,
} from "react-icons/lu";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { convertMsToDays } from "replo-utils/lib/datetime";
import { ConditionOperatorEnum } from "schemas/analyticsRead";
import { BillingTiers } from "schemas/billing";

import { VariationNameStartEnhancer } from "../components/VariationNameStartEnhancer";
import { ExperimentEditableHeader } from "../ExperimentEditableHeader";
import { useExperimentApi } from "../utils";
import { useExperimentEdit } from "./hooks/useExperimentEdit";

type ValueContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const ValuesContainer: React.FC<ValueContainerProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={twMerge(
        "col-span-6 border p-4 rounded-md flex flex-row gap-8",
        className,
      )}
    >
      {children}
    </div>
  );
};

type TitleWithValueProps = {
  title: string;
  value: string | null;
  valueAsChildren?: React.ReactNode;
};

const TitleWithValue: React.FC<TitleWithValueProps> = ({ title, value }) => {
  return (
    <div className="flex flex-col gap-2 text-default">
      <span className="typ-label-small">{title}</span>
      <span className="typ-body-small">
        {value !== null ? value : <BsDash />}
      </span>
    </div>
  );
};

const DEFAULT_QUERY_MAIN_RANGE_ID = crypto.randomUUID();

const useAnalyticsReadForExperiments = (
  experimentId: string,
  startDatetime: number,
  endDatetime: number,
  interval: number,
  workspaceId: string | null,
  variationTargetUrlHosts: string[],
) => {
  /**
   * NOTE (Max, 2024-10-17): We use a basic query where we get:
   * 1) urlHosts from the experiment variation urls
   * 2) mainRange datetimes from the Experiment's createdAt / endedAt dates
   * 3) filters using the experimentId: we're only interested in CH rows that contain
   * the experimentId as a urlParam
   */
  const query: AnalyticsReadQuery = {
    ...DEFAULT_QUERY,
    urlHosts: variationTargetUrlHosts,
    order: "DESC",
    sortMetric: "conversion_rates",
    ranges: {
      mainRange: {
        id: DEFAULT_QUERY_MAIN_RANGE_ID,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        interval,
      },
      compareAtRanges: [],
    },
    filters: {
      ...DEFAULT_FILTERS,
      urlParams: {
        "replo-experiment-id": [
          {
            operator: ConditionOperatorEnum.EQUALS,
            value: [`${experimentId}`],
          },
        ],
      },
    },
  };

  return useBasicAnalyticsRead({
    workspaceId,
    type: "withoutUrlSyncQuery",
    queryConfig: query,
  });
};

const ExperimentResultsTabLinkInfo: React.FC<{
  experiment: Experiment;
}> = ({ experiment }) => {
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;

  const {
    links: { data: links },
  } = useExperimentApi({ workspaceId: experiment.workspaceId });

  const getSelectedLinkUrl = (): string => {
    const link = links?.find(
      (link) =>
        link.id ===
        (experiment.completedAnalyticsLinkId ?? experiment.analyticsLinkId),
    );
    return link?.url ?? "";
  };

  return (
    <div className="flex flex-row py-3 px-4 bg-hover border border-slate-300 rounded-md items-center justify-between">
      <div className="flex flex-row gap-1.5">
        <LuNetwork size={16} />
        <div className="flex flex-col">
          <span className="typ-label-small text-default">
            Direct traffic to your Test Link
          </span>
          <span className="typ-body-small text-placeholder">
            Only traffic directed through this test link will be included in the
            test.
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-2.5">
        <Button
          variant="tertiary"
          size="base"
          endEnhancer={<LuExternalLink size={14} />}
          onClick={() => {
            const url = getSelectedLinkUrl();
            if (url) {
              window.open(url, "_blank");
              logEvent("experiment.link.preview", {
                billingPlanTier: subscriptionTier,
              });
            }
          }}
        >
          <span className="typ-button-base text-default">Preview</span>
        </Button>
        <Button
          variant="primary"
          size="base"
          endEnhancer={<LuCopy size={14} />}
          onClick={() => {
            const url = getSelectedLinkUrl();
            if (url) {
              copy(url);
              successToast(
                "Link Copied",
                "The test link has been copied to your clipboard.",
              );
              logEvent("experiment.link.copy", {
                billingPlanTier: subscriptionTier,
              });
            }
          }}
        >
          <span className="typ-button-base text-white">Copy link</span>
        </Button>
      </div>
    </div>
  );
};

const StatSigInfoText: React.FC = () => {
  return (
    <div className="flex flex-row justify-end items-center gap-1">
      <span className="typ-body-small text-muted">
        Want to see if you've reached stat sig?
      </span>
      <Button
        variant="tertiary"
        size="sm"
        endEnhancer={<LuArrowUpRight size={12} className="text-accent" />}
        to={docs.abTesting.education.statSigCalculator}
        target="_blank"
      >
        <span className="typ-label-small text-accent">Use our calculator</span>
      </Button>
    </div>
  );
};

const ExperimentResultsTabInner: React.FC<{
  simpleExperiment: SimpleExperiment;
  workspaceId: string;
  experiment: Experiment | null;
}> = ({ simpleExperiment, experiment, workspaceId }) => {
  const { dispatchExperimentEdit, handleReactHookFormSubmit } =
    useExperimentEdit();

  const navigate = useNavigate();

  const {
    id,
    createdAt,
    createdAtHuman,
    endedAt,
    endedAtHuman,
    variations,
    name,
  } = simpleExperiment;

  const winner = variations.find(
    (variation) =>
      experiment?.selectedDisplayWinnerVariationId === variation.id,
  );

  const startDatetime = startOfDay(createdAt).valueOf();
  const endDatetime = endOfDay(endedAt ?? Date.now()).valueOf() + 1;
  const interval = endDatetime - startDatetime;
  // NOTE Ben Vogt 2025-03-13: We only need to query for analytics data
  // taking place on the url hosts in the experiment variations target URLs.
  const urlHostsInExperiment = [
    ...new Set(
      simpleExperiment.variations.map(({ target }) => new URL(target!).host),
    ),
  ];

  const durationDays = convertMsToDays(interval);

  const { mainRangeResults, isLoading } = useAnalyticsReadForExperiments(
    id,
    startDatetime,
    endDatetime,
    interval,
    workspaceId ?? null,
    urlHostsInExperiment,
  );

  const totalSessions = mainRangeResults
    ? mainRangeResults.reduce((total, rangeResult) => {
        const sessions = rangeResult.metrics.unique_sessions?.[0] || 0;
        return total + sessions;
      }, 0)
    : 0;

  const experimentResults = variations
    .filter((variation) => variation.target !== null)
    .map((variation) => {
      const targetURL = new URL(variation.target!);
      const urlPath = targetURL.pathname;

      const variationSlug = variation.slug;

      const associatedRangeResult: RangeResult | null =
        mainRangeResults?.find(
          (rangeResult) => rangeResult.urlPath === urlPath,
        ) ?? null;

      const metrics = associatedRangeResult?.metrics ?? {};

      return {
        targetURL,
        variationSlug,
        metrics,
      };
    });

  const getWinnerDisplay = () => {
    if (!endedAt) {
      return null;
    }

    return winner?.slug ?? "No Winner";
  };

  if (!experiment || !workspaceId) {
    return null;
  }

  const experimentsListPath = generatePath(routes.workspace.experiments.list, {
    workspaceId,
  });

  return (
    <ExperimentsLayout
      headerTitleComponent={
        <ExperimentEditableHeader
          title={name}
          experimentId={id}
          dispatchExperimentEdit={dispatchExperimentEdit}
          handleReactHookFormSubmit={handleReactHookFormSubmit}
          isLoadingRequiredData={!workspaceId}
        />
      }
      headerActions={
        <ExperimentActionsBar
          workspaceId={workspaceId}
          experiment={experiment}
        />
      }
      showStatus
      backButton={<BackButton onClick={() => navigate(experimentsListPath)} />}
    >
      <ExperimentTabWrapper>
        <div className="flex flex-col gap-4">
          <ExperimentResultsTabLinkInfo experiment={experiment} />
          <div className="grid grid-cols-12 gap-4">
            <ValuesContainer className="col-span-6">
              <TitleWithValue title="Sessions" value={`${totalSessions}`} />
              <TitleWithValue
                title="Duration"
                value={`${durationDays} day${durationDays !== 1 ? "s" : ""}`}
              />
              <TitleWithValue title="Winner" value={getWinnerDisplay()} />
            </ValuesContainer>

            <ValuesContainer className="col-span-6">
              <TitleWithValue title="Start Date" value={createdAtHuman} />
              <TitleWithValue title="End Date" value={endedAtHuman} />
            </ValuesContainer>
          </div>
          {!isLoading ? (
            <div className="flex flex-col gap-2">
              <Table className="table-fixed border-separate border-spacing-0">
                <TableHeader className="font-medium text-xs text-default">
                  <TableRow>
                    <CustomTableHead
                      label="Variant name"
                      className="border-l rounded-tl w-[20%]"
                    />
                    {OVERVIEW_TABLE_METRICS.map((metric, index) => (
                      <CustomTableHead
                        key={metric.key}
                        label={metric.label}
                        className={twMerge(
                          index === OVERVIEW_TABLE_METRICS.length - 1 &&
                            "border-r border-l-0 rounded-tr",
                        )}
                      />
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {experimentResults?.map((experimentResult, index) => {
                    return (
                      <ExperimentResultsTableRow
                        key={experimentResult.variationSlug}
                        experimentResult={experimentResult}
                        isLastMetric={OVERVIEW_TABLE_METRICS.length - 1}
                        index={index}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <StatSigInfoText />
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </ExperimentTabWrapper>
    </ExperimentsLayout>
  );
};

const ExperimentResultsTab: React.FC = () => {
  const { simpleExperiment, workspaceId, experiment } = useExperimentDetails();

  if (!experiment) {
    return null;
  }

  if (!simpleExperiment || !workspaceId) {
    return <Loader />;
  }
  return (
    <ExperimentResultsTabInner
      simpleExperiment={simpleExperiment}
      workspaceId={workspaceId}
      experiment={experiment}
    />
  );
};

type ExperimentResult = {
  targetURL: URL;
  variationSlug: string;
  metrics: RangeResult["metrics"];
};

type ExperimentResultsTableRowProps = {
  experimentResult: ExperimentResult;
  isLastMetric: number;
  index: number;
};

const ExperimentResultsTableRow: React.FC<ExperimentResultsTableRowProps> = ({
  experimentResult,
  isLastMetric,
  index,
}) => {
  const { targetURL, variationSlug, metrics } = experimentResult;

  return (
    <TableRow className="border-collapse">
      <TableCell className="border-b border-l">
        <div className="grid grid-cols-[auto,1fr] gap-x-2 gap-y-1">
          <div className="flex items-center justify-center">
            <VariationNameStartEnhancer index={index} size="sm" />
          </div>
          <div className="flex items-center">
            <Tooltip triggerAsChild content={targetURL.toString()} delay={800}>
              <span className="typ-label-small">{variationSlug}</span>
            </Tooltip>
          </div>
          <div />
          <div className="flex items-center gap-1 truncate">
            <Tooltip triggerAsChild content={targetURL.toString()} delay={800}>
              <span className="truncate typ-button-small text-muted">
                {targetURL.toString()}
              </span>
            </Tooltip>
            <Link
              to={targetURL.toString()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LuExternalLink size={12} />
            </Link>
          </div>
        </div>
      </TableCell>
      {OVERVIEW_TABLE_METRICS.map((metric, index) => {
        const currentMetricValues = metrics[metric.key] ?? [];
        return (
          <TableCell
            key={metric.key}
            className={twMerge(
              "border-b",
              index === isLastMetric && "border-r border-l-0 rounded-tr",
            )}
          >
            <MetricWithDelta
              name={metric.key}
              value={currentMetricValues[0] ?? null}
              doesCompareValueExist={false}
              wrapperClassName="justify-start flex-grow"
              valueClassName="text-xs"
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

type CustomTableHeadProps = {
  label: string;
  tooltip?: string;
  className?: string;
};

const CustomTableHead = ({
  label,
  tooltip,
  className,
}: CustomTableHeadProps) => {
  return (
    <TableHead
      className={twMerge(
        "overflow-hidden bg-subtle border border-r-0 border-l-0",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <span className="truncate">{label}</span>
        {tooltip && (
          <Tooltip content={tooltip} triggerAsChild side="bottom">
            <span className="flex-shrink-0 pl-2">
              <BsInfoCircle className="text-slate-400" />
            </span>
          </Tooltip>
        )}
      </div>
    </TableHead>
  );
};

export default ExperimentResultsTab;
