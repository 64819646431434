import * as React from "react";

import { Content, Item, Root, Trigger } from "@radix-ui/react-accordion";
import { animated, config, useSpring } from "@react-spring/web";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsChevronDown as ArrowDown } from "react-icons/bs";
import useMeasure from "react-use-measure";

type AccordionElement = {
  id: string;
  label: string;
  description: string | string[];
  primaryColor?: string;
};
type AccordionProps = {
  elements: AccordionElement[];
  primaryColor?: string;
};

const AccordionItem = ({
  id,
  label,
  description,
  openedValue,
  primaryColor = "#fff",
}: AccordionElement & { openedValue: string }) => {
  const [accordionRef, { height }] = useMeasure({
    offsetSize: false,
    scroll: true,
  });
  const accordionStyles = useSpring({
    from: {
      height: openedValue === id ? 0 : height,
      opacity: openedValue === id ? 0 : 1,
    },
    to: {
      height: openedValue === id ? height : 0,
      opacity: openedValue === id ? 1 : 0,
    },
    config: config.stiff,
  });
  const nodes = Array.isArray(description) ? description : [description];

  return (
    <Item value={id} className="border-b" style={{ borderColor: primaryColor }}>
      <Trigger asChild>
        <button
          className="flex w-full items-center justify-between text-left outline-none transition-all"
          style={{ color: primaryColor }}
        >
          <p className="w-5/6 py-3">{label}</p>
          <ArrowDown
            className={twMerge(
              "mx-3 transition-all",
              id === openedValue && "rotate-180",
            )}
            stroke={primaryColor}
          />
        </button>
      </Trigger>
      <Content
        asChild
        forceMount
        className={twMerge("relative", openedValue !== id && "-z-50")}
      >
        <animated.div style={accordionStyles}>
          <div ref={accordionRef} className="pb-8 pr-4 font-light">
            {nodes.map((node) => (
              <p
                className="py-2"
                key={node}
                dangerouslySetInnerHTML={{ __html: node }}
                style={{ color: primaryColor }}
              />
            ))}
          </div>
        </animated.div>
      </Content>
    </Item>
  );
};

const Accordion = ({ elements, primaryColor }: AccordionProps) => {
  const [openedValue, setOpenedValue] = React.useState("");
  return (
    <Root
      type="single"
      collapsible
      onValueChange={(value) => setOpenedValue(value)}
      value={openedValue}
    >
      {elements.map(({ id, label, description }) => (
        <AccordionItem
          key={id}
          id={id}
          label={label}
          description={description}
          openedValue={openedValue}
          primaryColor={primaryColor}
        />
      ))}
    </Root>
  );
};

export { Accordion };
