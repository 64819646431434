import { z } from "zod";

export const referralCodeSchema = z
  .object({
    id: z.string(),
    code: z.string(),
    createdAt: z.coerce.date(),
    archivedAt: z.coerce.date().nullish(),
    workspaceId: z.string(),
    revenueSharePercentage: z.number(),
  })
  .describe("ReferralCode");
