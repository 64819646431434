import type { MenuItem } from "@replo/design-system/components/menu/Menu";
import type { ReploElementMetadata } from "schemas/generated/element";

import * as React from "react";

import LogoBadge from "@common/LogoBadge";
import { ElementUpdateLoadingIndicator } from "@components/header/ElementUpdateLoadingIndicator";
import PublishedStatus from "@components/header/PublishedStatus";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import PageControlButtons from "@editor/components/header/PageControlButtons";
import {
  getMenuItemsForElementItems,
  useFolders,
  usePageModificationHandlers,
} from "@editor/components/left-bar/ElementsPane";
import { useElementName } from "@editor/hooks/element/useElementName";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useGetHeaderLogoRedirection from "@editor/hooks/useGetHeaderLogoRedirection";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";
import { useSelectedArchivedElement } from "@editor/hooks/useSelectedArchivedElement";
import {
  selectDraftElementId,
  selectEditorMode,
  selectLoadableProject,
  setEditorMode,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { docs } from "@editor/utils/docs";
import { canRenameElement } from "@editor/utils/element";
import { trpc } from "@editor/utils/trpc";

import { HEADER_HEIGHT } from "@/features/canvas/canvas-constants";
import InlineAlert from "@replo/design-system/components/alert/InlineAlert";
import { toast } from "@replo/design-system/components/alert/Toast";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Menu, MenuTrigger } from "@replo/design-system/components/menu/Menu";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken } from "@tanstack/react-query";
import { ChevronDown } from "lucide-react";
import { Link } from "react-router-dom";
import { z } from "zod";

import Input from "../common/designSystem/Input";
import { formatVersionHistoryDateAndTime } from "../version-history/VersionCard";

const SHOW_PAGE_CONTROLS: Record<EditorMode, boolean> = {
  [EditorMode.edit]: true,
  [EditorMode.preview]: true,
  [EditorMode.aiGeneration]: true,
  [EditorMode.versioning]: false,
  [EditorMode.archived]: false,
};

const shouldShowPageControls = (mode: EditorMode) => {
  return SHOW_PAGE_CONTROLS[mode];
};

const SHOW_PAGE_TITLE: Record<EditorMode, boolean> = {
  [EditorMode.edit]: true,
  [EditorMode.preview]: true,
  [EditorMode.aiGeneration]: true,
  [EditorMode.versioning]: true,
  [EditorMode.archived]: true,
};

const shouldShowPageTitle = (mode: EditorMode) => {
  return SHOW_PAGE_TITLE[mode];
};

const Header: React.FC<{
  showElementSpecificContent: boolean;
}> = React.memo(function Header({ showElementSpecificContent }) {
  const editorMode = useEditorSelector(selectEditorMode);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const { storeName } = useGetStoreNameAndUrl();
  const redirectTo = useGetHeaderLogoRedirection();
  const {
    isLoading: isSubscriptionDetailsLoading,
    isFetching: isSubscriptionFetching,
  } = useSubscriptionInfo();
  const { isLoading: isProjectLoading, project } = useEditorSelector(
    selectLoadableProject,
  );
  const archivedElement = useSelectedArchivedElement();

  const { data: elementMetadata } =
    trpc.element.getElementMetadataById.useQuery(draftElementId ?? skipToken);

  const { data, isFetched } = trpc.workspace.getSimpleWorkspaceById.useQuery(
    !isProjectLoading && project?.ownerWorkspaceId
      ? { id: project.ownerWorkspaceId }
      : skipToken,
  );

  const currentWorkspaceName = isFetched ? data?.workspace.name : undefined;

  const isLoading =
    isProjectLoading || isSubscriptionDetailsLoading || isSubscriptionFetching;

  return (
    <header className="relative flex flex-col border-b border-slate-200">
      <div
        className={`flex h-[${HEADER_HEIGHT}px] w-full justify-between overflow-hidden bg-white px-2`}
      >
        <div className="flex items-center gap-2">
          <div className="flex gap-2">
            <div className="flex cursor-pointer items-center text-slate-200 transition-all hover:text-muted">
              <Tooltip
                triggerAsChild
                key="logo"
                content="Back to Replo Dashboard"
              >
                <Link to={redirectTo} className="flex flex-row items-center">
                  <span className="sr-only">Back to Replo Dashboard</span>
                  <LogoBadge
                    aria-hidden
                    className="cursor-pointer text-black h-8 w-8"
                  />
                </Link>
              </Tooltip>
            </div>
            <div className="flex flex-col text-xs min-w-0">
              <div className="overflow-hidden text-ellipsis whitespace-nowrap typ-header-base">
                {storeName}
              </div>
              <div
                className={twMerge(
                  "flex items-center gap-2",
                  isLoading && "invisible",
                )}
              >
                <div className="typ-body-small text-slate-500">
                  {currentWorkspaceName}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showElementSpecificContent && (
          <>
            {shouldShowPageTitle(editorMode) && (
              <div className="flex items-center space-x-2">
                {editorMode !== EditorMode.archived && elementMetadata && (
                  <TitleAndCenterMenu
                    pageTitle={elementMetadata?.name}
                    elementMetadata={elementMetadata}
                  />
                )}
                {editorMode === EditorMode.archived && (
                  <TitleAndCenterMenu
                    pageTitle={
                      archivedElement?.name ?? "No Archived Element Selected"
                    }
                    archivedAt={archivedElement?.archivedAt}
                    elementMetadata={elementMetadata}
                  />
                )}
              </div>
            )}
            <div className="flex items-center space-x-2">
              <ElementUpdateLoadingIndicator />
              {shouldShowPageControls(editorMode) && <PageControlButtons />}
            </div>
          </>
        )}
      </div>
      <DebugModeBanner />
    </header>
  );
});

const DebugModeBanner: React.FC = () => {
  const [usePublisherStagingUrl] = useLocalStorageState(
    "replo.debug.usePublisherStagingUrl",
    false,
    { schema: z.boolean() },
  );
  return usePublisherStagingUrl ? (
    <InlineAlert variant="warning" centerAlign>
      [Replo debug] Using publisher staging URL!
    </InlineAlert>
  ) : null;
};

const PageLink = () => {
  const { path, shopifyUrl, urlIsDisabled } = usePublishedInfo();

  return path ? (
    <div
      className={twMerge(
        "max-w-[280px] overflow-hidden text-ellipsis whitespace-nowrap text-center text-xs text-muted",
        !urlIsDisabled && "hover:text-blue-600",
      )}
    >
      <a
        href={shopifyUrl}
        target="_blank"
        rel="noreferrer"
        className={twMerge(
          urlIsDisabled && "pointer-events-none cursor-default",
        )}
      >
        {path}
      </a>
    </div>
  ) : null;
};

const TitleAndCenterMenu: React.FC<{
  pageTitle: string;
  archivedAt?: string;
  elementMetadata?: ReploElementMetadata;
}> = ({ pageTitle, archivedAt, elementMetadata }) => {
  const dispatch = useEditorDispatch();
  const [isOpen, setOpen] = React.useState(false);

  const {
    elementName,
    setElementName,
    isEditingName,
    setIsEditingName,
    handleUpdateElementName,
    handleKeyDown,
    isInvalidElementName,
  } = useElementName({
    element: elementMetadata!,
    initialName: pageTitle,
  });

  const { folders: allFolders } = useFolders();
  const currentFolder =
    allFolders.find((folder) => folder.id === elementMetadata?.folderId) ??
    null;

  const {
    handleDeletion,
    handleDuplication,
    handleEditPage,
    handleMoveToFolder,
    handleSupportDuplication,
    handleArchive,
  } = usePageModificationHandlers();
  const isDebugMode = useIsDebugMode();

  const menuItems: MenuItem[] = [
    ...(elementMetadata
      ? getMenuItemsForElementItems({
          element: elementMetadata,
          currentFolder,
          allFolders,
          handleDeletion,
          handleDuplication,
          handleSupportDuplication,
          handleEditPage,
          handleMoveToFolder,
          isDebugMode,
          handleArchive,
        })
      : []),
    {
      type: "leaf",
      id: "history",
      title: "View Version History",
      variant: "default",
      size: "sm",
      onSelect: () => {
        dispatch(setEditorMode(EditorMode.versioning));
      },
    },
  ];

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [buttonDimensions, setButtonDimensions] = React.useState<{
    width?: number;
    height?: number;
  }>({});

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to update the button dimensions when the page title changes
  React.useEffect(() => {
    const updateDimensions = () => {
      if (buttonRef.current) {
        // NOTE (Kurt, 2024-12-09): We need to dynamically calculate the dimensions of the trigger button
        // so that when we switch to the editable input, it maintains the exact same width/height.
        // This prevents any layout shifts and maintains visual consistency during the transition.
        // The button ref gives us access to the rendered button's actual dimensions which we can then
        // apply to the input container to match perfectly.
        setButtonDimensions({
          width: buttonRef.current.offsetWidth,
          height: buttonRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [elementName, isEditingName]);

  const editorMode = useEditorSelector(selectEditorMode);

  if (!elementMetadata) {
    return null;
  }

  return (
    <div
      className="absolute flex h-[60px] flex-col items-center justify-center"
      style={{ left: "50%", transform: "translateX(-50%)" }}
    >
      <div className="relative flex flex-row group">
        <Menu
          items={menuItems}
          isOpen={isOpen}
          onRequestClose={() => setOpen(false)}
          contentClassNames="mt-2.5"
          trigger={
            <MenuTrigger asChild>
              <div className="flex flex-row hover:bg-slate-50 rounded">
                {isEditingName && editorMode !== EditorMode.archived ? (
                  <div
                    style={{
                      width: buttonDimensions.width,
                      height: buttonDimensions.height,
                      maxWidth: buttonDimensions.width,
                      maxHeight: buttonDimensions.height,
                    }}
                  >
                    <Input
                      autoFocus
                      size="sm"
                      value={elementName}
                      onChange={(e) => setElementName(e.target.value)}
                      onKeyDown={handleKeyDown}
                      onBlur={() => {
                        void handleUpdateElementName(elementName);
                      }}
                      validityState={isInvalidElementName ? "invalid" : "valid"}
                    />
                  </div>
                ) : (
                  <button
                    ref={buttonRef}
                    className="px-1.5 py-0.5 max-w-[280px] rounded-r-none overflow-hidden text-ellipsis whitespace-nowrap text-center text-sm leading-5 font-semibold"
                    onClick={() => {
                      if (canRenameElement(elementMetadata)) {
                        setIsEditingName(true);
                      } else {
                        toast({
                          header: "Cannot rename section",
                          message:
                            "This section cannot be renamed because it has been published.",
                          type: "warning",
                          cta: "Learn More",
                          ctaOnClick: () => {
                            window.open(
                              docs.warnings.cannotRenameSection,
                              "_blank",
                            );
                          },
                        });
                      }
                    }}
                  >
                    <div className="flex items-center gap-2">
                      {editorMode !== EditorMode.archived && (
                        <PublishedStatus />
                      )}
                      <div
                        data-testid="element-page-title"
                        className="truncate"
                      >
                        {elementName}
                      </div>
                    </div>
                  </button>
                )}
                <div className="w-px h-full bg-slate-200 opacity-0 group-hover:opacity-100 transition-opacity" />
                {editorMode !== EditorMode.archived && (
                  <IconButton
                    variant="tertiary"
                    size="sm"
                    icon={<ChevronDown className="text-subtle" size={16} />}
                    onClick={() => setOpen(true)}
                    data-testid="element-page-title-dropdown-button"
                  />
                )}
              </div>
            </MenuTrigger>
          }
        />
      </div>
      {editorMode === EditorMode.archived ? (
        <p className="text-xs text-subtle">
          {archivedAt &&
            `Archived ${formatVersionHistoryDateAndTime(archivedAt)}`}
        </p>
      ) : (
        <PageLink />
      )}
    </div>
  );
};

export default Header;
