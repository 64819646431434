import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { GradientStop } from "schemas/styleAttribute";

import * as React from "react";

import { BADGE_TRIGGER_OFFSET } from "@editor/components/editor/constants";
import FontFamilyControl from "@editor/components/editor/page/element-editor/components/modifiers/FontFamilyControl";
import { useRichTextComponent } from "@editor/components/RichTextComponentContext";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectAncestorTextColor,
  selectColor,
  selectColorGradientStops,
  selectColorGradientTilt,
  selectFontFamily,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { isAllTextColored } from "@editor/utils/rte";
import ModifierGroup from "@editorExtras/ModifierGroup";
import ModifierLabel from "@editorExtras/ModifierLabel";
import DynamicColorModifier from "@editorModifiers/DynamicColorModifier";

import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { coerceNumberToString, isNotNullish } from "replo-utils/lib/misc";

const FontAndColorModifier: React.FC<
  React.PropsWithChildren<{
    allowsGradientSelection: boolean;
    allowsFontSelection: boolean;
  }>
> = ({ allowsGradientSelection, allowsFontSelection }) => {
  // NOTE (Fran 2024-04-15):  Given the text color inheritance, we need to show if the component
  // has a color or if any ancestor text color will apply.
  const ancestorTextColor = useEditorSelector(selectAncestorTextColor);
  const color = useEditorSelector(selectColor);
  const colorGradientTilt = useEditorSelector(selectColorGradientTilt);
  const colorGradientStops = useEditorSelector(
    selectColorGradientStops,
  ) as GradientStop[];
  const fontFamily = useEditorSelector(selectFontFamily) ?? null;

  const applyComponentAction = useApplyComponentAction();

  // if there is a instance of the tipTapEditor being used by the rich text
  // control we must use the same one and queue our changes
  const { tipTapEditor, queueAction } = useRichTextComponent();

  const changeColor = (newValue: SolidOrGradient) => {
    const gradientOrSolid = getGradientOrSolidValue(
      newValue,
      allowsGradientSelection,
    );
    const newStyleValue =
      gradientOrSolid && typeof gradientOrSolid === "object"
        ? {
            color: "alchemy:gradient",
            __alchemyGradient__color__tilt: gradientOrSolid?.tilt,
            __alchemyGradient__color__stops: gradientOrSolid?.stops,
            // NOTE (Fran 2024-11-27): Remove gradient data from design library when setting gradient color
            __reploGradient__color__design_library: null,
          }
        : {
            color: gradientOrSolid,
            // NOTE (Fran 2024-11-27): Remove gradient data when setting solid color
            __alchemyGradient__color__tilt: null,
            __alchemyGradient__color__stops: null,
            __reploGradient__color__design_library: null,
          };
    const action: UseApplyComponentActionType = {
      type: "setStyles",
      value: newStyleValue,
    };
    // If all text in the editor already has a color then we've got to unset it
    // in order for the changes to have an effect.
    if (tipTapEditor && isAllTextColored(tipTapEditor)) {
      queueAction(action);
      tipTapEditor?.chain().selectAll().unsetColor().run();
    } else {
      applyComponentAction(action);
    }
  };

  const colorModifierValue = color ?? ancestorTextColor;

  return (
    <ModifierGroup
      title="Text"
      isDefaultOpen={
        isNotNullish(colorModifierValue) || isNotNullish(fontFamily)
      }
    >
      <div className="flex flex-col gap-2">
        {allowsFontSelection && <FontFamilyControl />}
        <div className="flex items-center w-full">
          <ModifierLabel label="Color" />
          <DynamicColorModifier
            popoverSideOffset={BADGE_TRIGGER_OFFSET}
            previewProperty="color"
            popoverTitle="Text Color"
            gradientSelectionType={allowsGradientSelection ? "color" : null}
            gradientData={{
              tilt: !isMixedStyleValue(colorGradientTilt)
                ? coerceNumberToString(colorGradientTilt) ?? "90deg"
                : "90deg",
              stops: colorGradientStops ?? [
                {
                  id: "c7795a8c-4e13-4011-889b-64adb0e11e41",
                  color: "#DF9393",
                  location: "0%",
                },
              ],
            }}
            field="style.color"
            value={colorModifierValue ?? undefined}
            onChange={changeColor}
            onRemove={() => {
              // NOTE (Fran 2024-05-09): If the color is not set we will show the inherited color from any
              // ancestor, so in this case, we should set the color to transparent. If the color is set, we
              // should remove it and show the inherited color.
              const onRemoveNewColor = color ? null : "#00000000";
              changeColor({
                type: "solid",
                color: onRemoveNewColor,
              });
            }}
            showSavedStyles
          />
        </div>
      </div>
    </ModifierGroup>
  );
};

const getGradientOrSolidValue = (
  newValue: SolidOrGradient,
  allowsGradientSelection: boolean,
) => {
  let gradientOrSolid = null;

  if (allowsGradientSelection) {
    if (newValue.type === "solid") {
      gradientOrSolid = newValue.color;
    } else {
      gradientOrSolid = newValue.gradient;
    }
  } else if (typeof newValue === "string") {
    gradientOrSolid = newValue;
  }

  return gradientOrSolid;
};

export default FontAndColorModifier;
