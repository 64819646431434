import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { ComponentConfig } from "../../components";

const styleElements = {
  root: {
    defaultStyles: {
      display: "flex",
      flexDirection: "column",
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
