import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { selectIsPreviewMode } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import twMerge from "@replo/design-system/utils/twMerge";
import { Resizable } from "react-resizable";
import { clamp } from "replo-utils/lib/math";
import { useRequiredContext } from "replo-utils/react/context";

import { CANVAS_DATA } from "./canvas-constants";
import { CanvasContext } from "./canvas-context";
import {
  selectCanvasDeltaXY,
  selectCanvasScale,
  setCanvasFrameWidth,
  setCanvasInteractionMode,
  setPreviewWidth,
} from "./canvas-reducer";
import { useSetDeltaXY } from "./useSetDeltaXY";

type Orientation = "left" | "right";
const DRAG_HANDLE_WIDTH = 5;

export function CanvasResizer() {
  return (
    <>
      {["left", "right"].map((orientation) => {
        return (
          <CanvasSingleResizer
            key={orientation}
            orientation={orientation as Orientation}
          />
        );
      })}
    </>
  );
}

const CanvasSingleResizer: React.FC<{
  orientation: Orientation;
}> = ({ orientation }) => {
  const { canvas, frameWidth, canvasHeight, canvasLeftOffset } =
    useRequiredContext(CanvasContext);

  const [isDragging, setIsDragging] = React.useState(false);
  const canvasWidthOnResizeStart = React.useRef<number>(frameWidth);

  const { deltaX } = useEditorSelector(selectCanvasDeltaXY);
  const canvasScale = useEditorSelector(selectCanvasScale);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const dispatch = useEditorDispatch();
  const setDraftElement = useSetDraftElement();
  const setDeltaXY = useSetDeltaXY();
  const logAnalytics = useLogAnalytics();

  const isLeft = orientation === "left";

  function handleResizeStart() {
    setIsDragging(true);
    dispatch(setCanvasInteractionMode("resizing"));
    if (!isPreviewMode) {
      setDraftElement({ componentIds: [] });
      canvasWidthOnResizeStart.current = frameWidth;
    }
  }

  function handleResizeStop(newWidth: number) {
    setIsDragging(false);
    dispatch(setCanvasInteractionMode("edit"));
    dispatch(
      isPreviewMode
        ? setPreviewWidth(newWidth)
        : setCanvasFrameWidth({
            canvas,
            width: newWidth,
          }),
    );
    if (!isPreviewMode) {
      logAnalytics("canvas.frame.resize", {
        method: "drag",
        previousSize: canvasWidthOnResizeStart.current,
        size: newWidth,
      });
    }
  }

  function handleResize(newWidth: number) {
    const minWidth = CANVAS_DATA[isPreviewMode ? "mobile" : canvas].range[0];
    const maxWidth = CANVAS_DATA[isPreviewMode ? "desktop" : canvas].range[1];
    const width = clamp(newWidth, minWidth, maxWidth);
    const newDeltaX = deltaX - ((width - frameWidth) / 2) * canvasScale;

    dispatch(
      isPreviewMode
        ? setPreviewWidth(width)
        : setCanvasFrameWidth({
            canvas,
            width,
          }),
    );

    setDeltaXY({ deltaX: newDeltaX });
  }

  function getNewWidth(width: number) {
    return Math.round(isLeft ? frameWidth - (width - frameWidth) : width);
  }
  return (
    <Resizable
      width={frameWidth}
      height={0}
      axis="x"
      handle={
        <div
          style={{
            position: "absolute",
            width: DRAG_HANDLE_WIDTH,
            left: isLeft ? -DRAG_HANDLE_WIDTH : frameWidth,
            top: 0,
            height: canvasHeight,
            cursor: "ew-resize",
            marginLeft: `-${canvasLeftOffset}px`,
          }}
          className={twMerge(
            "hover:bg-blue-600",
            isDragging && "bg-blue-600",
            !isDragging && "bg-transparent",
          )}
        />
      }
      onResize={(_e, { size }) => {
        handleResize(getNewWidth(size.width));
      }}
      onResizeStart={handleResizeStart}
      onResizeStop={(_e, { size }) => {
        handleResizeStop(getNewWidth(size.width));
      }}
    >
      <div />
    </Resizable>
  );
};
