import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { AlchemyActionType } from "../../../shared/enums";
import type { ComponentConfig } from "../../components";

const styleElements = {
  root: {},
} as const satisfies StyleElements;

const panelsStyleElements = {
  root: {
    childrenOverrideStyles: {
      flexShrink: 0,
    },
  },
} as const satisfies StyleElements;

const indicatorStyleElements = {
  root: {
    childrenOverrideStyles: {
      flexShrink: 0,
    },
  },
} as const satisfies StyleElements;

const actionTypes = [
  "scrollToNextCarouselItem",
  "scrollToPreviousCarouselItem",
  "scrollToSpecificCarouselItem",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    customProps: [
      {
        id: "_autoCenter",
        type: "boolean",
        name: "Auto center carousel items",
        defaultValue: false,
        description:
          "If set, the carousel items will animate to the center when moved.",
      },
      {
        id: "_dragToScroll",
        type: "boolean",
        name: "Drag with mouse",
        defaultValue: false,
        description:
          "If enabled, the carousel's content can be dragged with the mouse.",
      },
      {
        id: "_autoNextInterval",
        type: "seconds",
        name: "Auto scroll to next element",
        defaultValue: "0s",
        description:
          "If enabled, the carousel will automatically scroll to the next element after the given amount of time",
      },
      {
        id: "_enableScroll",
        type: "boolean",
        name: "Enable scroll",
        defaultValue: true,
        description:
          "If enabled, the carousel's content can be scrolled using the mouse or trackpad.",
      },
    ],
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: true,
    styleElements,
    ancestorDisallowList: [
      {
        ancestorTypes: ["marquee"],
        message: "Carousels cannot be nested inside tickers.",
      },
    ],
    variantTriggers: ["state.carouselV2.isAtStart", "state.carouselV2.isAtEnd"],
    actionTypes,
  },
  children: {
    panels: {
      renderData: {
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => false,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        canContainChildren: true,
        styleElements: panelsStyleElements,
      },
    },
    indicator: {
      renderData: {
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => false,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        canContainChildren: true,
        styleElements: indicatorStyleElements,
        variantTriggers: ["state.carouselV2.isCurrentIndicatorItem"],
      },
    },
  },
} satisfies ComponentConfig;

export default config;
