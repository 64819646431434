import React from "react";

import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import {
  usePageFontOptions,
  useShopifyFontOptions,
} from "@editor/hooks/useFontFamilyOptions";
import { selectIsShopifyIntegrationEnabled } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { filterOutPageFonts, GOOGLE_FONT_OPTIONS } from "@editor/utils/font";

import { FONT_ACCEPT_FILE_EXTENSIONS } from "@/features/assets/constants";
import ShopifyFileDropzone from "@/features/assets/old/ShopifyFileDropzone";
import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { MenuItem } from "@replo/design-system/components/menu/MenuItem";
import intersectionBy from "lodash-es/intersectionBy";
import { ChevronDown } from "lucide-react";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import ControlGroup from "../../extras/ControlGroup";
import { normalizeFontFamily } from "../../modifiers/utils";
import { fontStartCase } from "./utils";

interface FontFamilySelectorProps {
  fontFamily: string | null | undefined;
  onSelect: (fontValue: string | null) => void;
  placeholder: string;
}

const FontFamilySelector: React.FC<FontFamilySelectorProps> = ({
  fontFamily,
  onSelect,
  placeholder,
}) => {
  const normalizedFontFamily = normalizeFontFamily(
    !isMixedStyleValue(fontFamily ?? null) ? fontFamily ?? null : null,
  )
    ?.replaceAll("_", " ")
    .replaceAll("-", " ");

  const {
    refetch,
    nameToDisplayName,
    fontOptions: shopifyFontOptions,
  } = useShopifyFontOptions();
  const [fontPreviewErrors, setFontPreviewErrors] = React.useState<
    Record<string, boolean>
  >({});

  const pageFontOptions = usePageFontOptions();

  // NOTE (Gabe 2024-05-15): Only show the fonts that are actually available
  // (via Shopify or Google fonts).
  const filteredPageFonts = intersectionBy(
    pageFontOptions,
    [...shopifyFontOptions, ...GOOGLE_FONT_OPTIONS],
    (font) => font.value,
  );

  const onFontUploadComplete = async (url: string) => {
    const newFonts = await refetch();
    const fontName = newFonts?.find((font) =>
      // NOTE (Evan, 7/13/23) We replace spaces with underscores here because
      // we're matching against the Shopify files system, which does the same.
      url.includes(font.name.replace(/ /g, "_")),
    )?.name;
    if (fontName) {
      onSelect(fontName);
    }
  };

  // Note (Evan, 2024-08-07): Attempt to retrieve a display name from
  // nameToDisplayName, checking first for the fontFamily, then the
  // fontFamily after removing any comma-joined fallbacks
  let displayName: string | undefined = undefined;
  if (fontFamily && !isMixedStyleValue(fontFamily)) {
    displayName =
      nameToDisplayName[fontFamily] ??
      nameToDisplayName[fontFamily.split(",")[0] ?? ""];
  }

  const fontDisplayName = displayName ?? normalizedFontFamily;

  const fontOptions = [
    // First get all page fonts
    ...filteredPageFonts.map((font) =>
      createFontOption(
        font,
        nameToDisplayName,
        "From Page",
        fontDisplayName ?? "",
      ),
    ),

    // Note (Evan, 6/2/23) Filter out page fonts from shopify/google fonts so that we don't have any duplicates
    ...filterOutPageFonts(shopifyFontOptions, filteredPageFonts).map((font) =>
      createFontOption(
        font,
        nameToDisplayName,
        "From Theme",
        fontDisplayName ?? "",
      ),
    ),
    ...filterOutPageFonts(GOOGLE_FONT_OPTIONS, filteredPageFonts).map(
      (font) => ({
        ...font,
        displayValue:
          getDisplayName(font.value, nameToDisplayName) ?? font.label,
        groupTitle: "Google Fonts",
        label: !fontPreviewErrors[font.value ?? ""] ? (
          <MenuItem
            variant="default"
            size="sm"
            selected={
              typeof fontFamily === "string"
                ? fontFamily?.includes(font.value ?? "")
                : false
            }
          >
            <img
              src={`/images/font-previews/${font.value?.replace(/\s+/g, "_")}.webp`}
              alt={getDisplayName(font.value, nameToDisplayName) ?? font.label}
              width={182}
              height={16}
              loading="lazy"
              onError={() =>
                setFontPreviewErrors((prev) => ({
                  ...prev,
                  [font.value ?? ""]: true,
                }))
              }
            />
          </MenuItem>
        ) : (
          font.label
        ),
      }),
    ),
  ];

  return (
    <ControlGroup label="Font">
      <div className="w-full overflow-hidden">
        <Combobox.Root
          options={fontOptions.filter((opt) => opt.value !== null)}
          value={
            fontFamily && !isMixedStyleValue(fontFamily) ? fontDisplayName : ""
          }
          onChange={onSelect}
        >
          <Combobox.Trigger>
            <Combobox.SelectionButton
              layoutClassName="w-full"
              title={fontDisplayName ?? placeholder}
              isPlaceholder={!fontDisplayName}
              size="sm"
              endEnhancer={
                fontFamily ? (
                  <div className="ml-auto">
                    <FormFieldXButton
                      onClick={() => {
                        onSelect("");
                      }}
                    />
                  </div>
                ) : (
                  <ChevronDown size={12} className="text-muted" />
                )
              }
            />
          </Combobox.Trigger>
          <Combobox.Popover
            sideOffset={8}
            side="right"
            align="center"
            layoutClassName="w-[220px] h-[416px]"
          >
            <Combobox.Content
              title="Fonts"
              areOptionsSearchable
              inputPlaceholder="Search fonts"
              emptySearchMessage="No matches found. Try uploading your own font!"
              footer={
                <Combobox.Footer size="sm">
                  <UploadCustomFont
                    onUploadComplete={(data) => {
                      void onFontUploadComplete(data);
                    }}
                  />
                </Combobox.Footer>
              }
            />
          </Combobox.Popover>
        </Combobox.Root>
      </div>
    </ControlGroup>
  );
};

const UploadCustomFont: React.FC<{
  onUploadComplete(url: string): void;
}> = ({ onUploadComplete }) => {
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const onUpload = (url: string) => {
    successToast("Font Uploaded", "");
    onUploadComplete(url);
  };

  return (
    <>
      {isShopifyIntegrationEnabled ? (
        <div id="text-style-modifier-font-upload">
          <ShopifyFileDropzone
            acceptDropAssetType={FONT_ACCEPT_FILE_EXTENSIONS}
            onUploadComplete={onUpload}
            projectId={projectId}
            sourceType="files"
            inputSize="small"
            uploadText="Upload Custom Fonts"
            className="border-none pt-0"
          />
        </div>
      ) : (
        <Button
          variant="tertiary"
          style={{ minWidth: 0 }}
          disabled
          tooltipText="Connect to Shopify to add custom fonts"
        >
          Upload Custom Fonts
        </Button>
      )}
    </>
  );
};

const getDisplayName = (
  fontValue: string | null,
  nameToDisplayName: Record<string, string | undefined>,
) => {
  if (!fontValue) {
    return undefined;
  }
  return nameToDisplayName[fontValue];
};

const normalizeFontLabel = (
  label: string,
  displayName: string | undefined,
): string => {
  let normalizedLabel = label;

  if (displayName) {
    normalizedLabel = normalizeFontFamily(displayName) ?? label;
  } else {
    normalizedLabel = normalizeFontFamily(label) ?? label;
  }

  return fontStartCase(normalizedLabel);
};

const createFontOption = (
  font: { value: string | null; label: string },
  nameToDisplayName: Record<string, string | undefined>,
  groupTitle?: string,
  selectedFontFamily?: string | null,
) => {
  const displayName = getDisplayName(font.value, nameToDisplayName);
  const normalizedLabel = normalizeFontLabel(font.label, displayName);
  const fontLabel = (
    <MenuItem
      variant="default"
      size="sm"
      style={{
        fontFamily: `"${font.value}", "Inter", sans-serif`,
        fontSize: "13px",
        lineHeight: "20px",
      }}
      selected={selectedFontFamily === font.value}
    >
      {normalizedLabel}
    </MenuItem>
  );

  return {
    value: font.value ?? "",
    displayValue: normalizedLabel,
    label: fontLabel,
    groupTitle,
  };
};

export default FontFamilySelector;
