import type { View } from "@editor/components/left-bar/insert-pane/InsertPane";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { MenuItem } from "@replo/design-system/components/menu/Menu";

import * as React from "react";

import ComponentTemplateDragOverlay from "@editor/components/ComponentTemplateDragOverlay";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";

import { getConfig } from "@/config";
import { successToast } from "@replo/design-system/components/alert/Toast";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Menu } from "@replo/design-system/components/menu/Menu";
import twMerge from "@replo/design-system/utils/twMerge";
import { Link, MoreHorizontal, Trash } from "lucide-react";

export const SavedTemplateListItem = ({
  componentTemplate,
  selectedView,
}: {
  componentTemplate: ComponentTemplate;
  selectedView: View;
}) => {
  const modal = useModal();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const logEvent = useLogAnalytics();

  const onCopyUrl = () => {
    const url = getConfig("dashboardUrl") ?? "https://dashboard.replo.app/";
    const baseUrl = `${url}/home/template/${componentTemplate.id}`;
    void navigator.clipboard.writeText(baseUrl);
    logEvent("editor.componentTemplate.share", {
      shareUrl: baseUrl,
      componentTemplateId: componentTemplate?.id,
      componentTemplateName: componentTemplate?.name,
      from: "componentTemplateDetails",
    });
    successToast(
      "Template Link Copied",
      "The template link has been copied to your clipboard.",
    );
  };

  const menuItems: MenuItem[] = [
    ...(selectedView !== "components"
      ? [
          {
            id: "shareTemplate",
            type: "leaf" as const,
            title: "Share",
            variant: "default" as const,
            size: "sm" as const,
            startEnhancer: <Link size={12} />,
            onSelect: () => onCopyUrl(),
          },
        ]
      : []),
    {
      id: "deleteTemplate",
      type: "leaf" as const,
      title: "Delete",
      variant: "default" as const,
      size: "sm" as const,
      startEnhancer: <Trash size={12} />,
      onSelect: () => {
        modal.openModal({
          type: "deleteComponentTemplateConfirmationModal",
          props: {
            template: componentTemplate,
          },
        });
      },
    },
  ];

  return (
    <li key={componentTemplate.id}>
      <div
        className={twMerge(
          "gap-1 p-1.5 border-0.5 border-border rounded-md",
          selectedView === "components" ? "h-full flex flex-col" : "",
        )}
      >
        <div className="relative w-full cursor-pointer rounded bg-hover aspect-[16/9]">
          <div
            className="flex h-full w-full place-items-center rounded border-0.5"
            data-testid="component-template-draggable"
          >
            <div className="w-full h-full flex items-center justify-center">
              <div className="relative w-full h-full cursor-pointer overflow-y-hidden flex items-center justify-center">
                <img
                  src={componentTemplate.desktopPreviewUrl}
                  loading="lazy"
                  className="mx-auto my-auto max-h-full max-w-full object-contain transition duration-700 ease-in-out"
                  alt={`${componentTemplate.name} preview`}
                />
              </div>
            </div>
            <ComponentTemplateDragOverlay
              dragType={
                selectedView === "components" ? "newComponent" : "section"
              }
              template={componentTemplate}
              identifier={componentTemplate.id}
              scope="left-bar"
            />
          </div>
        </div>

        <div className="flex items-center justify-between gap-1 relative min-h-6">
          <span className="block typ-label-small truncate">
            {componentTemplate.name}
          </span>
          <Menu
            isOpen={isMenuOpen}
            onRequestOpen={() => setIsMenuOpen(true)}
            onRequestClose={() => setIsMenuOpen(false)}
            align="start"
            size="sm"
            side="right"
            sideOffset={4}
            disableTriggerFocusOnClose
            items={menuItems}
            contentClassNames="w-40"
            trigger={
              <IconButton
                variant="tertiary"
                size="sm"
                layoutClassName="h-4 w-4"
                icon={<MoreHorizontal size={12} />}
                aria-hidden
              />
            }
          />
        </div>
      </div>
    </li>
  );
};
