import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

interface ComponentPreviewProps {
  type: "img";
  src: string;
  alt?: string;
  className?: string;
}

const ComponentPreview = (props: ComponentPreviewProps) => {
  return (
    <img
      // Note (Sebas, 2023-11-30): We add a query param to the src to prevent
      // the browser from using a previous version of the images (cached)
      // format: YYYY-MM-DD
      src={`${props.src}?date=2024-07-16`}
      alt={props.alt}
      className={twMerge(
        "h-full w-full object-cover object-center",
        props.className,
      )}
      style={{ pointerEvents: "none" }}
    />
  );
};

export default ComponentPreview;
