import type { ComponentTemplate } from "@editor/types/component-template";

import { activeTabVariantActionTransform } from "@editor/components/editor/templates/transforms";

export const radioButton: ComponentTemplate = {
  id: "ba18a1dd-3a4f-4043-9c36-81eb7d0441f3",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Component/Radio Button",
  leftBarCategory: { main: "interactive", subCategory: "toggles" },
  preview: "component/radio-button.svg",
  transforms: [activeTabVariantActionTransform],
  tags: ["option", "radio", "button"],
  template: {
    id: "53efb05f-bb5f-4899-bb8a-8e5a386da5e5",
    name: "Radio Button",
    type: "toggleContainer",
    props: {
      style: {
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        borderTopColor: "#000000",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        justifyContent: "center",
        borderLeftColor: "#000000",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderRightColor: "#000000",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderBottomColor: "#000000",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderTopLeftRadius: "50%",
        borderTopRightRadius: "50%",
        borderBottomLeftRadius: "50%",
        borderBottomRightRadius: "50%",
        alignSelf: "stretch",
        __animateVariantTransitions: true,
      },
      _accessibilityRole: "radio",
      _accessibilityChecked: false,
    },
    children: [
      {
        id: "77f1df40-6528-4e90-a579-c5e8663e1987",
        name: "Radio Button Indicator",
        type: "toggleIndicator",
        props: {
          style: {
            width: "18px",
            height: "18px",
            display: "flex",
            opacity: "0%",
            backgroundColor: "#000000FF",
            alignItems: "flex-start",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            borderBottomRightRadius: "50%",
          },
        },
        children: [],
      },
    ],
    variants: [
      {
        id: "fb8bf4a3-4f7c-42e3-90c3-2633ddfae014",
        name: "default",
        query: null,
        componentOverrides: null,
      },
      {
        id: "83973ebe-ab32-424e-99d2-03ad9d13ead8",
        name: "Active",
        query: null,
        componentOverrides: null,
      },
    ],
    variantOverrides: {
      "83973ebe-ab32-424e-99d2-03ad9d13ead8": {
        componentOverrides: {
          "77f1df40-6528-4e90-a579-c5e8663e1987": {
            props: { style: { opacity: "100%" } },
          },
          "53efb05f-bb5f-4899-bb8a-8e5a386da5e5": {
            props: {
              _accessibilityChecked: true,
            },
          },
        },
      },
    },
  },
};
