import type { ComponentTemplate } from "@editor/types/component-template";
import type {
  ComponentTemplateCategoryId,
  ComponentTemplateIndustryId,
} from "schemas/componentTemplates";
import type { ComponentTemplateScope } from "schemas/generated/componentTemplates";
import type { ResponsiveToggleAllowedDevices } from "../common/designSystem/ResponsiveToggle";

import React, { useEffect, useRef, useState } from "react";

import { DebouncedInput } from "@editor/components/common/designSystem/Input";

import IconButton from "@replo/design-system/components/button/IconButton";
import { ArrowUp, Search } from "lucide-react";

import PreviewPlatformToggleGroup from "./PreviewPlatformToggleGroup";
import TemplatesGrid from "./TemplatesGrid";

interface SearchTemplatesProps {
  selectedIndustryIds: ComponentTemplateIndustryId[];
  selectedCategoryIds: ComponentTemplateCategoryId[];
  setSubpageTemplate: (template: ComponentTemplate | null) => void;
  previewPlatform: ResponsiveToggleAllowedDevices;
  setPreviewPlatform: (platform: ResponsiveToggleAllowedDevices) => void;
  templateScope: Omit<ComponentTemplateScope, "left-bar" | "unlisted">;
}

const SearchTemplates: React.FC<SearchTemplatesProps> = ({
  selectedIndustryIds,
  selectedCategoryIds,
  setSubpageTemplate,
  previewPlatform,
  setPreviewPlatform,
  templateScope,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const gridRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!gridRef.current) {
        return;
      }

      setShowScrollButton(gridRef.current.scrollTop > 300);
    };

    const currentRef = gridRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToTop = () => {
    if (gridRef.current) {
      gridRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="flex flex-col gap-6 w-full relative">
      <div className="flex gap-3">
        <div className="w-full">
          <DebouncedInput
            placeholder="Search for a template"
            value={searchTerm}
            onValueChange={setSearchTerm}
            startEnhancer={<Search className="w-4 h-4" />}
            autoComplete="off"
            size="base"
          />
        </div>
        <PreviewPlatformToggleGroup
          previewPlatform={previewPlatform}
          setPreviewPlatform={setPreviewPlatform}
        />
      </div>

      <TemplatesGrid
        searchTerm={searchTerm}
        selectedIndustryIds={selectedIndustryIds}
        selectedCategoryIds={selectedCategoryIds}
        setSubpageTemplate={setSubpageTemplate}
        previewPlatform={previewPlatform}
        gridRef={gridRef}
        templateScope={templateScope}
      />

      {showScrollButton && (
        <IconButton
          variant="secondary"
          onClick={scrollToTop}
          layoutClassName="fixed bottom-10 right-10"
          aria-label="Scroll to top"
          size="lg"
          icon={<ArrowUp size={20} />}
        />
      )}
    </div>
  );
};

export default SearchTemplates;
