import type {
  ReploAnnouncement,
  ReploAnnouncementTag,
} from "schemas/generated/announcement";

import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { extractTextFromFirstParagraph } from "@/features/announcements/utils/announcement";
import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import { Tag } from "@replo/design-system/components/tag/Tag";
import { ArrowLeft, ArrowUpRight, X } from "lucide-react";

const AnnouncementCard: React.FC<{
  announcement: ReploAnnouncement;
  onAnnouncementClick: (announcement: ReploAnnouncement) => void;
}> = ({ announcement, onAnnouncementClick }) => {
  return (
    <div
      className="p-2 rounded hover:bg-neutral-soft cursor-pointer"
      onClick={() => onAnnouncementClick(announcement)}
    >
      <div className="flex gap-2">
        {announcement.imageUrl && (
          <div className="h-[52px] w-[52px] flex-shrink-0 bg-neutral-soft rounded">
            <img
              src={announcement.imageUrl}
              alt=""
              className="h-full w-full rounded object-cover"
              loading="lazy"
            />
          </div>
        )}
        <div className="flex flex-col gap-1 overflow-hidden">
          <h3 className="typ-header-small truncate overflow-hidden">
            {announcement.title}
          </h3>
          <p className="typ-body-small text-muted line-clamp-2">
            {extractTextFromFirstParagraph(announcement.description)}
          </p>
        </div>
      </div>
    </div>
  );
};

const AnnouncementCardSkeleton: React.FC = () => {
  return (
    <div className="p-2 rounded">
      <div className="flex gap-2">
        <div className="h-[52px] w-[52px] flex-shrink-0 rounded">
          <Skeleton className="h-full w-full rounded" />
        </div>
        <div className="flex flex-col gap-1 overflow-hidden flex-grow">
          <Skeleton className="h-5 w-3/4 rounded" />
          <Skeleton className="h-4 w-full rounded" />
          <Skeleton className="h-4 w-1/2 rounded" />
        </div>
      </div>
    </div>
  );
};

const AnnouncementTag: React.FC<{ tag: ReploAnnouncementTag }> = ({ tag }) => {
  const getVariant = () => {
    switch (tag) {
      case "New":
        return "ai";
      case "Update":
        return "success";
      case "Insights":
      case "A/B Testing":
        return "beta";
      default:
        return "neutral";
    }
  };

  return (
    <Tag size="base" variant={getVariant()}>
      {tag}
    </Tag>
  );
};

const FullAnnouncementView: React.FC<{
  announcement: ReploAnnouncement;
  onBack: () => void;
  onClose: () => void;
}> = ({ announcement, onBack, onClose }) => {
  const logEvent = useLogAnalytics();
  const handleAnnouncementClick = () => {
    if (announcement && announcement.ctaLink) {
      window.open(announcement.ctaLink, "_blank");
      logEvent("announcements.clicked", {
        announcementId: announcement.id,
        announcementWebflowId: announcement.webflowId,
        source: "AnnouncementsMenu",
      });
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between gap-2 bg-white sticky top-0 z-10 px-4 py-3 border-b border-neutral-soft shadow-menu">
        <IconButton
          icon={<ArrowLeft size={12} />}
          onClick={onBack}
          variant="tertiary"
          size="sm"
        />

        <h3 className="typ-header-base truncate overflow-hidden">
          {announcement.title}
        </h3>

        <IconButton
          icon={<X size={12} />}
          onClick={onClose}
          variant="tertiary"
          size="sm"
        />
      </div>

      <div className="flex flex-col gap-4 p-4 overflow-y-auto overflow-x-hidden no-scrollbar h-[280px]">
        {announcement.imageUrl && (
          <div className="w-full bg-neutral-soft rounded">
            <img
              src={announcement.imageUrl}
              alt="Announcement image"
              className="h-full w-full rounded object-cover"
              loading="lazy"
            />
          </div>
        )}

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <h2 className="typ-header-base">{announcement.title}</h2>
            <div className="flex gap-2">
              {announcement.tags.map((tag) => (
                <AnnouncementTag key={tag} tag={tag} />
              ))}
            </div>
          </div>

          <div
            className="typ-body-base [&_a]:text-primary break-words"
            dangerouslySetInnerHTML={{ __html: announcement.description }}
          />

          {announcement.ctaText && announcement.ctaLink && (
            <div className="flex">
              <Button
                variant="primary"
                size="sm"
                onClick={handleAnnouncementClick}
              >
                {announcement.ctaText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AnnouncementsMenuContent: React.FC<{
  announcements: ReploAnnouncement[];
  isLoading: boolean;
  handleClose: () => void;
  selectedAnnouncement: ReploAnnouncement | null;
  setSelectedAnnouncement: (announcement: ReploAnnouncement | null) => void;
}> = ({
  announcements,
  isLoading,
  handleClose,
  selectedAnnouncement,
  setSelectedAnnouncement,
}) => {
  if (selectedAnnouncement) {
    return (
      <FullAnnouncementView
        announcement={selectedAnnouncement}
        onBack={() => setSelectedAnnouncement(null)}
        onClose={handleClose}
      />
    );
  }

  return (
    <div className="flex flex-col h-full mt-4 gap-1">
      <div className="flex flex-col gap-2">
        {isLoading ? (
          <>
            {new Array(3).fill(0).map((_, index) => (
              <AnnouncementCardSkeleton key={index} />
            ))}
          </>
        ) : (
          announcements.map((announcement) => (
            <AnnouncementCard
              key={announcement.id}
              announcement={announcement}
              onAnnouncementClick={() => setSelectedAnnouncement(announcement)}
            />
          ))
        )}
      </div>

      <div className="flex justify-end">
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            window.open("https://www.replo.app/changelog", "_blank");
          }}
          endEnhancer={<ArrowUpRight size={12} />}
          UNSAFE_className="focus:outline-none"
        >
          See all
        </Button>
      </div>
    </div>
  );
};

export default AnnouncementsMenuContent;
