import React from "react";

import { MenuItem } from "@replo/design-system/components/menu/MenuItem";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

export type AnalyticsUrlParamsFilterTypes =
  | "utm_campaign"
  | "utm_content"
  | "utm_medium"
  | "utm_source"
  | "utm_term";

const UrlFilterParamMenuItem = (props: {
  value: string;
  label: string;
  onClick?: (value: string) => void;
}) => {
  return (
    <MenuItem
      variant="default"
      size="sm"
      selected={false}
      onClick={() => props.onClick?.(props.value)}
    >
      {props.label}
    </MenuItem>
  );
};

export const createUrlParamsFilterOptions = (
  onClick?: (value: string) => void,
) => [
  {
    label: (
      <UrlFilterParamMenuItem
        label="UTM Campaign"
        value="utm_campaign"
        onClick={onClick}
      />
    ),
    displayValue: "UTM Campaign",
    value: "utm_campaign",
  },
  {
    label: (
      <UrlFilterParamMenuItem
        label="UTM Content"
        value="utm_content"
        onClick={onClick}
      />
    ),
    displayValue: "UTM Content",
    value: "utm_content",
  },
  {
    label: (
      <UrlFilterParamMenuItem
        label="UTM Medium"
        value="utm_medium"
        onClick={onClick}
      />
    ),
    displayValue: "UTM Medium",
    value: "utm_medium",
  },
  {
    label: (
      <UrlFilterParamMenuItem
        label="UTM Source"
        value="utm_source"
        onClick={onClick}
      />
    ),
    displayValue: "UTM Source",
    value: "utm_source",
  },
  {
    label: (
      <UrlFilterParamMenuItem
        label="UTM Term"
        value="utm_term"
        onClick={onClick}
      />
    ),
    displayValue: "UTM Term",
    value: "utm_term",
  },
];

export const OPERATOR_OPTIONS = [
  {
    label: "Equals",
    displayValue: "Equals",
    value: ConditionOperatorEnum.EQUALS,
  },
  {
    label: "Doesn't Equal",
    displayValue: "Doesn't Equal",
    value: ConditionOperatorEnum.DOES_NOT_EQUAL,
  },
  {
    label: "Contains",
    displayValue: "Contains",
    value: ConditionOperatorEnum.CONTAINS,
  },
  {
    label: "Doesn't Contain",
    displayValue: "Doesn't Contain",
    value: ConditionOperatorEnum.DOES_NOT_CONTAIN,
  },
];
