import type { AggregatedResult } from "schemas/generated/analyticsRead";

export type AggregationMetric = "median" | "average";

// NOTE (Max, 2025-03-07): 2025-02-01
export const BENCHMARK_START_DATETIME = 1_738_368_000_000;

// NOTE (Max, 2025-03-07): 2025-02-28
export const BENCHMARK_END_DATETIME = 1_740_873_600_000;

export type Industry =
  | "all"
  | "health"
  | "beautyAndFitness"
  | "foodAndDrink"
  | "apparel"
  | "homeAndGarden";

export const CHART_COLORS = {
  labelInside: "#1E293B",
  labelOutside: "#1E293B",
  allReploPages: "#E2E8F0",
  yourPages: "#E76968",
  aboveThreshold: "#34D399",
  belowThreshold: "#F5D982",
};

export const HARDCODED_CONVERSION_RATES_FOR_ALL_REPLO_PAGES: Record<
  Industry,
  Record<AggregationMetric, Record<AggregatedResult["group"], number>>
> = {
  all: {
    median: {
      "/pages": 1.37,
      "/products": 3.44,
      "/collections": 2.08,
      "/blogs": 0,
      all: 2.12,
      homepage: 2.86,
    },
    average: {
      "/pages": 1.63,
      "/products": 3.17,
      "/collections": 3.34,
      "/blogs": 0,
      all: 2.97,
      homepage: 4.02,
    },
  },
  health: {
    median: {
      "/pages": 0.52,
      "/products": 2.99,
      "/collections": 8.6,
      "/blogs": 0,
      all: 2.51,
      homepage: 7.87,
    },
    average: {
      "/pages": 1.08,
      "/products": 3.38,
      "/collections": 8.33,
      "/blogs": 0,
      all: 3.41,
      homepage: 7.35,
    },
  },
  beautyAndFitness: {
    median: {
      "/pages": 2.09,
      "/products": 3.27,
      "/collections": 4.95,
      "/blogs": 0,
      all: 3.42,
      homepage: 8.16,
    },
    average: {
      "/pages": 2.51,
      "/products": 3.27,
      "/collections": 4.95,
      "/blogs": 0,
      all: 4.26,
      homepage: 8.04,
    },
  },
  foodAndDrink: {
    median: {
      "/pages": 3.15,
      "/products": 3.07,
      "/collections": 6.22,
      "/blogs": 0,
      all: 3.87,
      homepage: 4.74,
    },
    average: {
      "/pages": 3.16,
      "/products": 6.32,
      "/collections": 7.79,
      "/blogs": 0,
      all: 4.69,
      homepage: 6.52,
    },
  },
  apparel: {
    median: {
      "/pages": 1.24,
      "/products": 1.21,
      "/collections": 1.4,
      "/blogs": 0,
      all: 1.36,
      homepage: 4.2,
    },
    average: {
      "/pages": 1.15,
      "/products": 1.24,
      "/collections": 1.66,
      "/blogs": 0,
      all: 1.78,
      homepage: 3.97,
    },
  },
  homeAndGarden: {
    median: {
      "/pages": 0.64,
      "/products": 1.04,
      "/collections": 0.61,
      "/blogs": 0,
      all: 1.01,
      homepage: 1.88,
    },
    average: {
      "/pages": 0.64,
      "/products": 2.02,
      "/collections": 0.46,
      "/blogs": 0,
      all: 1.55,
      homepage: 2.17,
    },
  },
};
