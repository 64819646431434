import type { Component } from "replo-runtime";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { ContextRef } from "schemas/product";

import isObject from "lodash-es/isObject";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import {
  doesComponentHaveConnectedDynamicDataProps,
  doesComponentSupportDynamicDataProps,
  getPrimaryDynamicDataProp,
} from "replo-runtime/shared/utils/dynamic-data";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import useApplyComponentAction from "./useApplyComponentAction";

const useDynamicDataQuickConnect = (component: Component) => {
  const applyComponentAction = useApplyComponentAction();
  const supportsDynamicData = doesComponentSupportDynamicDataProps(component);
  const isDynamicDataConnected =
    doesComponentHaveConnectedDynamicDataProps(component);
  const dynamicDataProp = getPrimaryDynamicDataProp(component);
  const handleChangePropValue = (
    newPropValue: string | ContextRef | SolidOrGradient,
  ) => {
    if (!dynamicDataProp) {
      return null;
    }
    const { propKey, targetType } = dynamicDataProp;
    exhaustiveSwitch({ type: targetType })({
      [DynamicDataTargetType.TEXT]: () => {
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: newPropValue,
          },
        });
      },
      [DynamicDataTargetType.PRODUCT]: () => {
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: newPropValue,
          },
        });
      },
      [DynamicDataTargetType.PRODUCT_VARIANT]: () => {
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: newPropValue,
          },
        });
      },
      [DynamicDataTargetType.SELLING_PLAN]: () => {
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: newPropValue,
          },
        });
      },
      [DynamicDataTargetType.TEXT_COLOR]: () => {
        applyComponentAction({
          type: "setStyles",
          value: {
            color: "red",
          },
        });
      },
      [DynamicDataTargetType.URL]: () => {
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: newPropValue,
          },
        });
      },
      [DynamicDataTargetType.ANY_LIST]: () => {
        const transformedValue = {
          type: "inline",
          valueType: "dynamic",
          dynamicPath: newPropValue,
        };
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: transformedValue,
          },
        });
      },
      [DynamicDataTargetType.IMAGE_LIST]: () => {
        const transformedValue = {
          type: "inline",
          valueType: "dynamic",
          dynamicPath: newPropValue,
        };
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: transformedValue,
          },
        });
      },
      [DynamicDataTargetType.INTEGER]: () => {
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: newPropValue,
          },
        });
      },
      [DynamicDataTargetType.QUANTITY]: () => {
        applyComponentAction({
          type: "setProps",
          value: {
            [propKey]: newPropValue,
          },
        });
      },
      [DynamicDataTargetType.IMAGE]: () => {
        applyComponentAction({
          type: "setStyles",
          value: {
            [propKey]: newPropValue,
          },
        });
      },
    });
  };
  const extractedPath =
    dynamicDataProp &&
    isObject(dynamicDataProp.propValue) &&
    "dynamicPath" in dynamicDataProp.propValue
      ? dynamicDataProp.propValue.dynamicPath
      : dynamicDataProp?.propValue;
  return {
    supportsDynamicData,
    isDynamicDataConnected,
    dynamicDataProp: dynamicDataProp && {
      ...dynamicDataProp,
      propValue: extractedPath,
    },
    handleChangePropValue,
  };
};

export default useDynamicDataQuickConnect;
