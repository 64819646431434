import type { LineChartData } from "@replo/design-system/components/shadcn/LineChart";
import type { ChartInterval, SelectedTimePeriod } from "replo-utils/analytics";
import type {
  AnalyticsReadQuery,
  MetricName,
  RangeResult,
} from "schemas/generated/analyticsRead";

import { TZDate } from "@date-fns/tz";
import { getRangesFromTimePeriod } from "replo-utils/analytics";
import { convertDaysToMs } from "replo-utils/lib/datetime";

const HOUR_OPTION = {
  value: "hour",
  label: "hour",
};
const DAY_OPTION = { value: "day", label: "day" };

// NOTE (Kurt, 2024-09-19): This function is used to get the line chart data from the analytics read query.
// We take the data from the analytics read query and format it into the line chart data format that is then
// consumed by the LineChart component which ultimately passes to the shadcn chart component.
export const getLineChartDataFromAnalyticsRead = (
  pageData: RangeResult,
  compareAtData: RangeResult,
  query: AnalyticsReadQuery,
  metricName: MetricName,
) => {
  const data: LineChartData = [];
  const dataLength = pageData.metrics[metricName]?.length ?? 0;

  for (let i = 0; i < dataLength; i++) {
    const date =
      query.ranges.mainRange?.startDatetime +
      i * (query.ranges.mainRange?.interval ?? 0);

    data.push({
      x: date,
      [`thisPeriod`]: pageData.metrics[metricName]?.[i] ?? 0,
      [`previousPeriod`]: compareAtData.metrics[metricName]?.[i] ?? 0,
    });
  }

  return data;
};

export function getChartIntervalOptions(
  selectedTimePeriod: SelectedTimePeriod,
  timeZone: string,
) {
  const { updatedRanges } = getRangesFromTimePeriod({
    selectedTimePeriod,
    timeZone,
  });
  const { startDatetime, endDatetime } = updatedRanges.mainRange;
  const start = new Date(startDatetime);
  const end = new Date(endDatetime);

  const rangeInDays = Math.ceil(
    (end.getTime() - start.getTime()) / convertDaysToMs(1),
  );

  let intervalOptions: { label: string; value: string }[] = [];

  if (rangeInDays <= 1) {
    intervalOptions = [HOUR_OPTION];
  } else if (rangeInDays <= 31) {
    intervalOptions = [HOUR_OPTION, DAY_OPTION];
  } else {
    intervalOptions = [DAY_OPTION];
  }

  return { rangeInDays, intervalOptions };
}

/**
 * Determines how many data points should be displayed with dashed lines in a chart.
 *
 * This function identifies future data points (those with timestamps after the current time)
 * and returns the count of those points. This allows us to visually distinguish between
 * historical data (solid lines) and future projections (dashed lines).
 *
 * If all data points are in the past or present, returns 0 (no dashed ticks).
 * Special case: If interval is day and the last datapoint is today (and today is not over),
 * returns 1 to show today's datapoint with a dashed line.
 *
 * @param data - The chart data points
 * @returns The number of data points from current time to the end that should be displayed with dashed lines
 *
 * @author Kurt
 */
export function getCurrentPeriodDashedTicks(
  data: LineChartData,
  chartInterval: ChartInterval,
  timeZone: string,
): number {
  // If no data, return 0
  if (!data || data.length === 0) {
    return 0;
  }

  // Get the last datapoint
  const lastDatapoint = data[data.length - 1];

  if (!lastDatapoint) {
    return 0;
  }

  const currentTimestamp = Date.now();

  // Get the timestamp of the last datapoint (assuming x is a timestamp in milliseconds)
  const lastTimestampMs = Number(lastDatapoint.x);

  if (chartInterval === "day") {
    const lastTimeStampDate = new TZDate(lastTimestampMs, timeZone);
    const today = new TZDate(Date.now(), timeZone);
    if (lastTimeStampDate.getDate() === today.getDate()) {
      return 1;
    }
  }

  // If the last datapoint is in the past or present, no dashed ticks
  if (lastTimestampMs <= currentTimestamp) {
    return 0;
  }

  // Find the datapoint closest to current time
  let currentTimeIndex = -1;
  for (let i = 0; i < data.length; i++) {
    const point = data[i];
    if (!point || point.x === undefined) {
      continue;
    }

    const pointTimeMs = Number(point.x);

    if (pointTimeMs > currentTimestamp) {
      currentTimeIndex = i;
      break;
    }
  }

  if (currentTimeIndex === -1) {
    return 0;
  }

  // Count ticks from the datapoint after current time to the end
  return data.length - currentTimeIndex;
}
