import { referralCodeSchema } from "schemas/referralCode";
import { shopifyIntegrationSchema } from "schemas/shopifyIntegration";
import { z } from "zod";

export const workspaceRoleSchema = z
  .union([z.literal("owner"), z.literal("member"), z.literal("viewer")])
  .describe("WorkspaceRole");

export const workspacePartnershipStatusTierSchema = z
  .union([
    z.literal("affiliate"),
    z.literal("silver"),
    z.literal("gold"),
    z.literal("platinum"),
  ])
  .describe("WorkspacePartnershipStatusTier");

const workspaceMembershipSchema = z.object({
  id: z.string().uuid(),
  role: workspaceRoleSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  archivedAt: z.coerce.date().nullish(),
  workspaceId: z.string().uuid(),
  userId: z.string().uuid(),
  user: z.object({
    id: z.string(),
    email: z.string().email(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
  }),
});

export type CurrencyCode =
  | "AED"
  | "AUD"
  | "CAD"
  | "CHF"
  | "DKK"
  | "EUR"
  | "GBP"
  | "NZD"
  | "QAR"
  | "SAR"
  | "SGD"
  | "BRL"
  | "MXN"
  | "NOK"
  | "RON"
  | "JPY"
  | "PLN"
  | "INR"
  | "HUF"
  | "SEK"
  | "ILS"
  | "CZK"
  | "HKD"
  | "ISK"
  | "PHP"
  | "RSD"
  | "BGN"
  | "IDR"
  | "THB"
  | "TWD"
  | "USD";

export const CURRENCIES: Record<CurrencyCode, { name: string }> = {
  AED: { name: "UAE Dirham" },
  AUD: { name: "Australian Dollar" },
  BGN: { name: "Bulgarian Lev" },
  BRL: { name: "Brazilian Real" },
  CAD: { name: "Canadian Dollar" },
  CHF: { name: "Swiss Franc" },
  CZK: { name: "Czech Koruna" },
  DKK: { name: "Danish Krone" },
  EUR: { name: "Euro" },
  GBP: { name: "British Pound" },
  HKD: { name: "Hong Kong Dollar" },
  HUF: { name: "Hungarian Forint" },
  IDR: { name: "Indonesian Rupiah" },
  ILS: { name: "Israeli New Shekel" },
  INR: { name: "Indian Rupee" },
  ISK: { name: "Icelandic Króna" },
  JPY: { name: "Japanese Yen" },
  MXN: { name: "Mexican Peso" },
  NOK: { name: "Norwegian Krone" },
  NZD: { name: "New Zealand Dollar" },
  PHP: { name: "Philippine Peso" },
  PLN: { name: "Polish Złoty" },
  QAR: { name: "Qatari Riyal" },
  RON: { name: "Romanian Leu" },
  RSD: { name: "Serbian Dinar" },
  SAR: { name: "Saudi Riyal" },
  SEK: { name: "Swedish Krona" },
  SGD: { name: "Singapore Dollar" },
  THB: { name: "Thai Baht" },
  TWD: { name: "New Taiwan Dollar" },
  USD: { name: "United States Dollar" },
} as const;

const currencySchema = z
  .enum(Object.keys(CURRENCIES) as [CurrencyCode, ...CurrencyCode[]])
  .describe("Currency");

export const workspaceSchema = z
  .object({
    id: z.string().uuid(),
    name: z.string(),
    domain: z.string(),
    partnershipAgreementSignedVersion: z.string().nullish(),
    partnershipAgreementSignedAt: z.coerce.date().nullish(),
    createdAt: z.coerce.date(),
    archivedAt: z.coerce.date().nullish(),
    partnershipAgreementSignedById: z.string().nullish(),
    kind: z.string().nullish(),
    paypalPayoutEmail: z.string().email().nullish(),
    overridePartnershipStatusTier:
      workspacePartnershipStatusTierSchema.nullish(),
    partnershipStatusTier: workspacePartnershipStatusTierSchema.nullish(),
    preferredPaymentProcessor: z.string().nullish(),
    isLegacyStripeWorkspace: z.boolean().nullish(),
    workspaceMemberships: z.array(workspaceMembershipSchema),
    timeZone: z.string().nullish(),
    currency: currencySchema.nullish(),
    referralCodes: z
      .array(referralCodeSchema.pick({ id: true, code: true }))
      .describe("ignoreImport:true"),
    isOnboarded: z.boolean(),
    hasShopifyIntegration: z.boolean(),
    hasShopifyActiveSubscription: z.boolean(),
    integrations: z
      .array(
        z.object({
          id: z.string(),
          shopifyIntegrationConfig: shopifyIntegrationSchema.nullish(),
          projectAssignmentCount: z.number().nullish(),
        }),
      )
      .nullish(),
    referralCode: referralCodeSchema
      .pick({ id: true, code: true })
      .nullish()
      .describe("ignoreImport:true"),
  })
  .describe("Workspace");

// NOTE (Kurt, 2024-12-09): Simplified version of the workspace schema containing only safe
// fields for unauthenticated consumption from the backend.
export const simpleWorkspaceSchema = workspaceSchema
  .pick({
    id: true,
    name: true,
  })
  .describe("SimpleWorkspace");
