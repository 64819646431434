import * as React from "react";

import Separator from "@editor/components/common/designSystem/Separator";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import Button from "@replo/design-system/components/button/Button";
import { MenuItem } from "@replo/design-system/components/menu/MenuItem";
import Popover from "@replo/design-system/components/popover/Popover";
import { ChevronDown } from "lucide-react";
import z from "zod";

export type Field =
  | "project.name"
  | "project.lastEditedAt"
  | "project.workspace"
  | "project.createdAt";
export type Direction = "asc" | "desc";

export type OrderState = {
  field: Field;
  direction: Direction;
};

const LOCAL_STORAGE_SORT_KEY = "replo-projects-sort";

const DEFAULT_ORDER: OrderState = {
  field: "project.lastEditedAt",
  direction: "desc",
};

const orderStateSchema = z.object({
  field: z.enum([
    "project.name",
    "project.lastEditedAt",
    "project.workspace",
    "project.createdAt",
  ]),
  direction: z.enum(["asc", "desc"]),
});

const isOrderState = (value: unknown): value is OrderState => {
  return orderStateSchema.safeParse(value).success;
};

type SortDropdownProps = {
  order: OrderState;
  onOrderChange: (order: OrderState) => void;
  showWorkspaceSort?: boolean;
};

const sortByOptions = [
  { label: "Project Name", value: "project.name" },
  { label: "Last Edited", value: "project.lastEditedAt" },
  { label: "Created Date", value: "project.createdAt" },
];

const sortOrderOptions = [
  { label: "A to Z", altLabel: "Oldest First", value: "asc" },
  { label: "Z to A", altLabel: "Newest First", value: "desc" },
];

// ToDo (Ben O., 01-23-2025): Replace this with selectable once design system implements this
const SortDropdown: React.FC<SortDropdownProps> = ({
  order: externalOrder,
  onOrderChange,
  showWorkspaceSort = false,
}) => {
  const [order, setOrder] = useLocalStorageState<OrderState>(
    LOCAL_STORAGE_SORT_KEY,
    externalOrder ?? DEFAULT_ORDER,
    { validate: isOrderState },
  );

  React.useEffect(() => {
    onOrderChange(order);
  }, [order, onOrderChange]);

  const [isOpen, setIsOpen] = React.useState(false);
  const logAnalytics = useLogAnalytics();
  const selectableRef = React.useRef<HTMLDivElement>(null);
  const [selectableWidth, setSelectableWidth] = React.useState<number>(0);

  React.useEffect(() => {
    if (selectableRef.current) {
      setSelectableWidth(selectableRef.current.offsetWidth);
    }
  }, []);

  const getValueLabel = () => {
    const field = order.field;
    const direction = order.direction;
    let label = "Sort by";

    if (field === "project.name") {
      label = `Alphabetical${direction === "desc" ? " (Z-A)" : " (A-Z)"}`;
    } else if (field === "project.lastEditedAt") {
      label = `Last edited (${direction === "desc" ? "Newest" : "Oldest"} first)`;
    } else if (field === "project.workspace") {
      label = `Workspace${direction === "desc" ? " (Z-A)" : " (A-Z)"}`;
    } else if (field === "project.createdAt") {
      label = `Created (${direction === "desc" ? "Newest" : "Oldest"} first)`;
    } else if (field === "project.shopifyUrl") {
      label = `Shopify URL${direction === "desc" ? " (Z-A)" : " (A-Z)"}`;
    }

    return label;
  };

  const handleSortChange = (field: Field, direction: Direction) => {
    logAnalytics("dashboard.projectView.sort", {
      sortBy: field,
      order: direction,
    });
    setOrder({ field, direction });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Enter":
      case " ":
        e.preventDefault();
        setIsOpen((prev) => !prev);
        break;
      case "Escape":
        e.preventDefault();
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <Popover.Root isOpen={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger asChild>
        <div
          ref={selectableRef}
          role="combobox"
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-label="Sort options"
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <Button
            variant="secondary"
            size="base"
            endEnhancer={<ChevronDown size={12} />}
            UNSAFE_className="typ-body-base"
          >
            {getValueLabel()}
          </Button>
        </div>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        side="bottom"
        className="p-2"
        style={{ width: selectableWidth }}
        shouldPreventDefaultOnInteractOutside={false}
        hideCloseButton
      >
        <div className="flex flex-col gap-2 text-default typ-body-small">
          <div className="flex flex-col gap-1">
            <h3 className="typ-header-small px-2">Sort By</h3>
            {sortByOptions.map((option) => (
              <MenuItem
                key={option.value}
                variant="default"
                size="sm"
                selected={order.field === option.value}
                onClick={() =>
                  handleSortChange(option.value as Field, order.direction)
                }
              >
                {option.label}
              </MenuItem>
            ))}
            {showWorkspaceSort && (
              <MenuItem
                variant="default"
                size="sm"
                selected={order.field === "project.workspace"}
                onClick={() =>
                  handleSortChange(
                    "project.workspace" as Field,
                    order.direction,
                  )
                }
              >
                Workspace
              </MenuItem>
            )}
          </div>
          <Separator />
          <div className="flex flex-col gap-1">
            <h3 className="typ-header-small px-2">Sort Order</h3>
            {sortOrderOptions.map((option) => (
              <MenuItem
                key={option.value}
                variant="default"
                size="sm"
                selected={order.direction === option.value}
                onClick={() =>
                  handleSortChange(order.field, option.value as Direction)
                }
              >
                {order.field === "project.name" ||
                order.field === "project.workspace"
                  ? option.label
                  : option.altLabel}
              </MenuItem>
            ))}
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default SortDropdown;
