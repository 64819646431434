import { MAX_IMAGE_SIZE_BYTES, MAX_VIDEO_SIZE_BYTES } from "../constants";

export const sizeValidator = (file: File) => {
  const isImage = file.type.startsWith("image/");
  const isVideo = file.type.startsWith("video/");

  if (isImage && file.size > MAX_IMAGE_SIZE_BYTES) {
    return {
      code: "replo-file-too-large",
      message: `Image files must be less than ${MAX_IMAGE_SIZE_BYTES / (1024 * 1024)}MB.`,
    };
  }

  if (isVideo && file.size > MAX_VIDEO_SIZE_BYTES) {
    return {
      code: "replo-file-too-large",
      message: `Video files must be less than ${MAX_VIDEO_SIZE_BYTES / (1024 * 1024)}MB.`,
    };
  }

  return null;
};
