import type { DialogProps } from "@radix-ui/react-dialog";

import * as React from "react";

import {
  Dialog,
  DialogContent,
} from "@replo/design-system/components/shadcn/core/dialog";
import twMerge from "@replo/design-system/utils/twMerge";
import { Command as CommandPrimitive } from "cmdk";

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    filter={(value, search) => (value?.includes(search) ? 1 : 0)}
    ref={ref}
    className={className}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;

interface CommandDialogProps extends DialogProps {}

const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent className="overflow-hidden p-0 shadow-lg">
        <Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  );
};

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    startEnhancer?: React.ReactNode;
    endEnhancer?: React.ReactNode;
    variant?: "sm" | "base";
    outlineOnActive?: boolean;
  }
>(
  (
    {
      className,
      startEnhancer,
      endEnhancer,
      variant = "sm",
      outlineOnActive = true,
      ...props
    },
    ref,
  ) => (
    <div className="flex items-center">
      <div
        className={twMerge(
          "flex items-center w-full rounded bg-slate-100 gap-2",
          outlineOnActive &&
            " outline-1 outline-offset-0 outline-transparent focus-within:outline focus-within:outline-blue-600",
        )}
      >
        {startEnhancer && (
          <div className="ml-2 text-slate-400">{startEnhancer}</div>
        )}
        <CommandPrimitive.Input
          ref={ref}
          className={twMerge(
            "flex w-full bg-slate-100 text-sm placeholder:text-slate-500 rounded outline-none disabled:cursor-not-allowed disabled:opacity-50",
            variant === "sm" ? "text-xs h-6" : "text-sm h-8",
            className,
          )}
          {...props}
        />
        {endEnhancer && <div className="mr-2">{endEnhancer}</div>}
      </div>
    </div>
  ),
);

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={twMerge(
      // Note (Noah, 2024-11-03, REPL-14468): vh value here is arbitrary,
      // but 56vh makes the dropdown show overflow for items on a Macbook Pro
      // 13", which is nice to indicate that there are more items
      "max-h-[56vh] overflow-y-auto overflow-x-hidden grow",
      className,
    )}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="p-3 text-xs text-slate-800"
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={twMerge(
      "overflow-hidden py-2 [&_[cmdk-group-items]]:flex [&_[cmdk-group-items]]:flex-col [&_[cmdk-group-items]]:gap-[1px] [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium",
      className,
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={twMerge("-mx-1 h-px bg-slate-200", className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    variant?: "sm" | "base";
  }
>(({ className, variant = "sm", ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={twMerge(
      "relative flex cursor-default select-none items-center rounded-sm  outline-none data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50 truncate",
      variant === "sm" ? "text-xs px-2 py-1.5" : "text-sm px-3 py-2",
      className,
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={twMerge("ml-auto text-xs tracking-widest", className)}
      {...props}
    />
  );
};
CommandShortcut.displayName = "CommandShortcut";

/** @public */
export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
};
