import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { CgSpinner } from "react-icons/cg";

export type SpinnerVariants = "primary" | "secondary" | "danger" | "white";

type SpinnerProps = {
  variant: SpinnerVariants;
  size?: number;
  layoutClassName?: string;
  UNSAFE_className?: string;
  testId?: string;
  "aria-hidden"?: boolean;
  "aria-label"?: string;
  "aria-labelledby"?: string;
  role?: string;
};

const Spinner = ({
  variant,
  size = 16,
  "aria-hidden": ariaHidden,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
  role,
  testId,
  layoutClassName,
  UNSAFE_className,
}: SpinnerProps) => {
  const cn = twMerge(
    "animate-spin",
    variant === "primary" && "text-primary",
    variant === "secondary" && "text-default",
    variant === "white" && "text-white",
    variant === "danger" && "text-danger",
    layoutClassName,
    UNSAFE_className,
  );
  return (
    <CgSpinner
      size={size}
      className={cn}
      data-testid={testId}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      role={role}
    />
  );
};

export { Spinner };
