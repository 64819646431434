import type { CredentialResponse } from "@react-oauth/google";

import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import Separator from "@common/designSystem/Separator";
import {
  ErrorMessage as GlobalErrorMessage,
  prefilledErrorMapping,
  Screen,
  useNextUrlWithPendingAppInstallationId,
} from "@components/account/common";
import GoogleAuth from "@components/account/GoogleAuth";
import { storeToken } from "@editor/reducers/utils/store-token";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import get from "lodash-es/get";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";
import { z } from "zod";

const emailSchema = z.string().email();

type LoginFormValues = {
  email: string;
  password: string;
};

const LoginForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loginErrorMessage, setLoginErrorMessage] = React.useState<
    string | null
  >(get(prefilledErrorMapping, searchParams.get("error") ?? ""));

  const navigate = useNavigate();
  const { nextUrl, nextSearchParams } =
    useNextUrlWithPendingAppInstallationId();

  const handleLogin = (token: string) => {
    storeToken(token);
    window.location.replace(
      `${nextUrl ?? "/home"}${nextSearchParams ? `?${nextSearchParams}` : ""}`,
    );
  };

  const { mutate: loginApi, isPending: isLoadingLogin } =
    trpc.auth.login.useMutation({
      onSuccess: ({ token }) => handleLogin(token),
      onError: ({ message }) => setLoginErrorMessage(message),
    });

  const { mutate: loginWithGoogleApi } = trpc.auth.loginWithGoogle.useMutation({
    onSuccess: ({ token }) => handleLogin(token),
    onError: ({ message }) => setLoginErrorMessage(message),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      email: searchParams.get("email") ?? "",
      password: "",
    },
    mode: "onBlur",
  });

  const watchAllFields = useWatch({ control });

  const handleGoogleCredentials = async (
    credentialResponse: CredentialResponse,
  ) => {
    const { credential, clientId } = credentialResponse;
    if (credential && clientId) {
      loginWithGoogleApi({
        credential,
        clientId,
      });
    }
  };

  const onSubmit = async ({ email, password }: LoginFormValues) => {
    loginApi({
      email,
      rawPassword: password,
    });
  };

  const emailError = !isEmpty(watchAllFields.email)
    ? errors?.email?.message
    : undefined;
  const passwordError = !isEmpty(watchAllFields.password)
    ? errors?.password?.message
    : undefined;

  return (
    <form
      className="no-scrollbar"
      onSubmit={(data) => {
        void handleSubmit(onSubmit)(data);
      }}
    >
      <GlobalErrorMessage errorMessage={loginErrorMessage} />
      <div className="flex flex-col gap-4">
        <LabeledControl
          label="Email"
          className="text-default font-medium"
          size="base"
          id="email"
          error={emailError}
        >
          <Input
            id="email"
            aria-invalid={emailError ? "true" : undefined}
            aria-describedby={emailError ? "error-email" : undefined}
            validityState={emailError ? "invalid" : undefined}
            autoComplete="off"
            placeholder="Your Work Email"
            {...register("email", {
              required: "Please enter a valid email address.",
              validate: (value) => {
                const parsedEmail = emailSchema.safeParse(value);
                return (
                  parsedEmail.success || "Please enter a valid email address"
                );
              },
              // NOTE (Fran 2024-02-27): We should clear the errors when the user is typing
              onChange: () => clearErrors("email"),
            })}
            type="email"
            size="base"
          />
        </LabeledControl>
        <LabeledControl
          label="Password"
          id="password"
          labelActionText="Forgot Password?"
          labelAction={() => navigate(Screen.INIT_RESET_PASSWORD)}
          className="text-default font-medium"
          size="base"
          error={passwordError}
        >
          <Input
            id="password"
            autoComplete="off"
            placeholder="••••••••••••"
            aria-invalid={passwordError ? "true" : undefined}
            aria-describedby={passwordError ? "error-password" : undefined}
            validityState={passwordError ? "invalid" : undefined}
            {...register("password", {
              required: "Please enter a valid password",
              // NOTE (Fran 2024-02-27): We should clear the errors when the user is typing
              onChange: () => clearErrors("password"),
            })}
            type="password"
            size="base"
          />
        </LabeledControl>
      </div>
      <div className="mt-10 overflow-hidden flex flex-col gap-y-4">
        <Button
          layoutClassName="w-full"
          variant="primary"
          size="base"
          type="submit"
          isLoading={isLoadingLogin}
        >
          Login
        </Button>
        <Separator />
        <GoogleAuth
          width={370}
          handleGoogleCredentials={handleGoogleCredentials}
        />
      </div>
    </form>
  );
};

export default LoginForm;
