import * as React from "react";

import {
  TableCell,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";

export const TableLoadingRows: React.FC<{ rows: number; columns: number }> = ({
  rows,
  columns,
}) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, index) => (
        <TableRow key={index}>
          {Array.from({ length: columns }).map((_, index) => (
            <TableCell key={index}>
              <Skeleton className="h-7" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
