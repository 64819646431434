import { z } from "zod";

const SelectedTabSchema = z.union([
  z.object({
    label: z.literal("Details"),
    value: z.literal("details"),
  }),
  z.object({
    label: z.literal("Results"),
    value: z.literal("results"),
  }),
]);

export type SelectedTab = z.infer<typeof SelectedTabSchema>;

export const TAB_OPTION_DETAILS: SelectedTab = {
  label: "Details",
  value: "details",
};

export const TAB_OPTION_RESULTS: SelectedTab = {
  label: "Results",
  value: "results",
};
