import type { ComponentTemplate } from "@editor/types/component-template";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { useCreateElement } from "@editor/hooks/element/useCreateElement";
import { trpcUtils } from "@editor/utils/trpc";
import { useLogAnalytics } from "@hooks/useLogAnalytics";
import { useModal } from "@hooks/useModal";

interface OnSubmitParams {
  template: ComponentTemplate;
  elementType: ReploElementType;
  projectId?: string;
}

const useOnSubmitTemplateAction = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { handleCreateElement } = useCreateElement();
  const modal = useModal();
  const getTemplateById = trpcUtils.componentTemplates.get.fetch;

  const analytics = useLogAnalytics();

  const onSubmit = async ({
    template,
    elementType,
    projectId,
  }: OnSubmitParams) => {
    setIsLoading(true);
    const getTemplateByIdParams: {
      templateId: string;
      storeId?: string | null;
    } = {
      templateId: template.id,
    };

    const templateFromService = await getTemplateById(getTemplateByIdParams);
    if (!templateFromService) {
      setIsLoading(false);
      return;
    }

    analytics("editor.componentTemplate.used", {
      componentTemplateId: template.id,
      collectionId: template.collectionId,
      categoryId: template.categoryId,
      componentTemplateName: template.name,
      componentTemplateType: template.type,
      componentScope: template.scope,
      source: "marketplace",
    });
    modal.closeModal({});

    await handleCreateElement({
      type: elementType,
      initialTemplate: templateFromService,
      projectId,
    });

    setIsLoading(false);
  };

  return { onSubmit, isLoadingSubmitTemplate: isLoading };
};

export default useOnSubmitTemplateAction;
