import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const FERA_PRODUCT_RATING_WIDGET_LIQUID_SOURCE =
  '<div data-fera-container="product_reviews_summary" data-product-id="{{ product.id }}"></div>';

function FeraProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: FERA_PRODUCT_RATING_WIDGET_LIQUID_SOURCE,
      context,
    },
  );

  return (
    <SharedShopifyLiquid
      componentId={component.id}
      componentAttributes={componentAttributes}
      component={component}
      liquidSource={liquidSourceWithProductAssignment}
      title="Fera Product Rating"
      placeholderType="reviewStars"
      isLiquidSupported
      repeatedIndexPath={context.repeatedIndexPath}
    />
  );
}

export default FeraProductRatingWidget;
