import type { ExperimentCreateLocation } from "@editor/infra/analytics";
import type { Experiment } from "schemas/generated/experiment";

import { useSubscriptionTier } from "@editor/hooks/subscription";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";
import { handleTRPCClientError, trpc, trpcUtils } from "@editor/utils/trpc";

import { TRPC_ERROR_CODES_BY_KEY } from "@trpc/server/unstable-core-do-not-import";
import { generatePath, useNavigate } from "react-router-dom";

type CreateExperimentProps = {
  location: ExperimentCreateLocation;
  partialExperiment?: Partial<Experiment>;
  onExperimentCreateSuccess?: () => void;
};

export const useExperimentCreate = (workspaceId: string) => {
  const logEvent = useLogAnalytics();
  const subscriptionTier = useSubscriptionTier();
  const navigate = useNavigate();
  const errorToast = useErrorToast();

  const { mutateAsync: createDefault, isPending: isCreatingExperiment } =
    trpc.experiment.createDefault.useMutation({
      meta: { reploIgnoreDefaultErrorHandling: true },
      onSuccess: async () => {
        await trpcUtils.experiment.list.invalidate();
      },
      onError: (error) => {
        if (error.shape?.code === TRPC_ERROR_CODES_BY_KEY.FORBIDDEN) {
          errorToast(
            "You don't have permission to create a test in this workspace.",
            "Error creating test",
            "error.experiment.create",
            {
              error: "No admin access to workspace",
              workspaceId,
            },
          );
        } else {
          handleTRPCClientError(error);
        }
      },
    });

  const createExperiment = async ({
    location,
    partialExperiment,
    onExperimentCreateSuccess,
  }: CreateExperimentProps) => {
    const createdExperiment = await createDefault({
      workspaceId: workspaceId,
      experiment: {
        ...partialExperiment,
      },
    });
    logEvent("experiment.create", {
      billingPlanTier: subscriptionTier,
      location,
    });
    const path = generatePath(routes.workspace.experiments.details, {
      workspaceId,
      experimentId: createdExperiment.id,
    });
    navigate(path, {
      state: { newExperiment: true },
    });
    onExperimentCreateSuccess?.();
  };

  return {
    createExperiment,
    isCreatingExperiment,
  };
};
