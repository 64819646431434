import type {
  AggregationMetric,
  Industry,
} from "@/features/analytics/insights/charts";
import type { AnalyticsUrlParamsFilterTypes } from "@/features/analytics/moreFilters/constants";
import type { BrandDetailsTab } from "@editor/components/designLibrary/BrandDetailsContext";
import type { StyleCategory } from "@editor/components/designLibrary/useDraftStyles";
import type { MenuTriggerSource } from "@editor/providers/AIStreamingProvider";
import type { ImportWarning } from "@editor/reducers/figma-to-replo-reducer";
import type { OpenModalPayload } from "@editor/reducers/modals-reducer";
import type { LeftBarTab, RightBarTab } from "@editor/reducers/ui-reducer";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { CoreState } from "@editor/types/core-state";
import type { ReploClipboardSingle } from "@editor/utils/copyPaste";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import type { AlchemyActionTrigger } from "replo-runtime/shared/enums";
import type { User } from "replo-runtime/shared/types";
import type {
  ChartInterval,
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "replo-utils/analytics";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Action } from "schemas/actions";
import type { SupportedAIModel } from "schemas/ai";
import type { AlchemyAnimationType } from "schemas/animations";
import type { Component } from "schemas/component";
import type { Formatter } from "schemas/dynamicData";
import type { AITriggeringFeature } from "schemas/generated/ai";
import type {
  ConditionOperatorEnum,
  MetricName,
} from "schemas/generated/analyticsRead";
import type {
  BillingTier,
  SubscriptionDetails,
} from "schemas/generated/billing";
import type { ComponentTemplateCollection } from "schemas/generated/componentTemplates";
import type { ReploElement, ReploElementType } from "schemas/generated/element";
import type { Experiment } from "schemas/generated/experiment";
import type {
  FlowStepData,
  FlowStepResultsDataType,
} from "schemas/generated/flow";
import type { ReploProject } from "schemas/generated/project";
import type {
  SavedStyleColorType,
  SavedStyleTextAttributes,
} from "schemas/generated/savedStyles";
import type { WorkspaceRole } from "schemas/generated/workspace";
import type { ModifierOptionalProperty, ModifierType } from "schemas/modifiers";

import {
  selectDraftElementFromCoreState,
  selectDraftElementVersionFromCoreState,
  selectUpdatesSinceLastRequestFinishedFromCoreState,
} from "@editor/reducers/core-reducer";
import { extractDomainFromEmail } from "@editor/utils/email";
import { getProjectName, getStoreData } from "@editor/utils/project-utils";

import { getConfig } from "@/config";
import { getWebInstrumentations, initializeFaro } from "@grafana/faro-react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { isUnitTest } from "replo-runtime/shared/env";
import { ANALYTICS_GROUP_NAMES } from "replo-utils/lib/misc/analytics";
import {
  errorUserFacingDetailsSchema,
  extractErrorTrackingEventDetails,
  shouldReportErrorToSentry,
} from "schemas/errors";

let segmentAnalytics: AnalyticsBrowser | null = null;

export const initializeErrorTracking = () => {
  Sentry.init({
    dsn: "https://2f4d0ccb0334467d8b95ffe173042c23@o1267142.ingest.sentry.io/6453263",
    // Disable tracing and profiling since we don't use them currently
    tracesSampleRate: 0,
    profilesSampleRate: 0,
    release: window.SENTRY_RELEASE?.id,
    beforeSend: (event, hint) => {
      const error = hint.originalException;

      // NOTE (Gabe 2024-09-05): If there are user facing details then we
      // shouldn't send this error to Sentry.
      if (extractUserFacingDetailsFromUnknownError(error)) {
        return null;
      }

      if (error instanceof Error && !shouldReportErrorToSentry(error)) {
        return null;
      }

      if (event.message?.match(/resizeobserver loop limit exceeded/i)) {
        // Note (Noah, 2022-07-25): This event doesn't actually indicate a
        // problem, so we filter it out
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        return null;
      }
      // Note (Noah, 2022-08-25, REPL-3764): There are several shopify apps which try to mess
      // with the history when they're loaded by mirror into the Canvas srcdoc iframe, and because
      // of cross domain security restrictions, they get an error. This doesn't affect anything,
      // so we filter it from our error logs
      if (
        event.message?.match(/A history state object with URL 'about:srcdoc'/)
      ) {
        return null;
      }

      const details = extractErrorTrackingEventDetails(
        error,
        event.transaction,
      );

      return {
        ...event,
        fingerprint: details ? details.fingerprint : ["{{ default }}"],
      };
    },
  });
};

export const trackError = (
  error: unknown,
  extrasFromCaller?: Record<string, any>,
) => {
  const shouldExcludeErrorDueToUnitTesting = isUnitTest();
  const shouldExcludeErrorDueToAnalyticsConfig = !shouldLogAnalytics();
  const shouldExcludeErrorDueToStandardTracking =
    error instanceof Error && !shouldReportErrorToSentry(error);
  if (
    shouldExcludeErrorDueToUnitTesting ||
    shouldExcludeErrorDueToAnalyticsConfig ||
    shouldExcludeErrorDueToStandardTracking
  ) {
    // Bail out if we shouldn't track anything to Sentry
    return;
  }

  const trackingDetails = extractErrorTrackingEventDetails(error, undefined);
  Sentry.captureException(error, {
    captureContext: {
      fingerprint: trackingDetails?.fingerprint ?? ["{{ default }}"],
      extra: {
        url: window.location.href,
        ...extrasFromCaller,
        ...trackingDetails?.extra,
      },
    },
  });
};

export const initializePosthogIfNeeded = () => {
  if (!shouldLogAnalytics()) {
    return;
  }

  // Note (Noah, 2023-01-18): Add posthog here to help debug issues with feature
  // flags, etc in the editor. Note that we can't use getAlchemyEditorWindow because
  // by the time this runs, window.alchemyEditor might not have been initialized yet
  if (typeof window !== "undefined") {
    window.reploPosthog = posthog;
  }

  if (posthog.config.token === "") {
    posthog.init("phc_dRETsj85vNkKrvQQszEFRBibH5kUF9l7rbjACuKPanZ", {
      api_host: "https://d99bbtllqsaif.cloudfront.net",
      autocapture: false,
      rageclick: false,
      capture_pageview: true,
      capture_pageleave: false,
      disable_session_recording: true, // Disable initially
      persistence: "localStorage+cookie",
      loaded: function (posthog) {
        posthog.config.disable_session_recording = !shouldLogAnalytics();
      },
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    });
  }
};

export const initializeAnalytics = () => {
  if (!shouldLogAnalytics()) {
    return;
  }
  initializePosthogIfNeeded();
  segmentAnalytics = AnalyticsBrowser.load({
    writeKey: "FZO6ACG1KkVRwPZXvGPToYQTVhjm5WOH",
  });
};

export const initUserBasedAnalytics = (user: User) => {
  if (!shouldLogAnalytics()) {
    return;
  }

  Sentry.setUser({ email: user.email });

  void segmentAnalytics?.identify(user.email, {
    displayName: user.name,
    email: user.email,
    verified: true,
  });

  posthog.identify(user.email, {
    displayName: user.name,
    email: user.email,
  });

  const uniqueDomain = extractDomainFromEmail(user.email);
  if (uniqueDomain) {
    void segmentAnalytics?.group(`domain:${uniqueDomain}`, {
      type: "domain",
      name: uniqueDomain,
    });
    void posthog?.group(ANALYTICS_GROUP_NAMES.domain, uniqueDomain, {
      type: "domain",
      name: uniqueDomain,
    });
  }
};

export const shouldLogAnalytics = () => {
  return getConfig("shouldLogAnalytics", false) && !isUnitTest();
};

export const initProjectBasedAnalytics = (
  project: ReploProject,
  subscriptionInfo: SubscriptionDetails | undefined,
) => {
  if (!shouldLogAnalytics()) {
    return false;
  }
  const activeSubscriptionTier = subscriptionInfo?.name ?? "free";
  const shopifyUrl = getStoreData(project)?.shopifyUrl;

  const commonProperties = {
    name: getProjectName(project),
    shopifyShopUrl: shopifyUrl,
    tags: [`org_${activeSubscriptionTier}`],
    tenancy: project.tenancy,
    referrerCode: project.referrerCode?.code,
    billingPlanMonthlyAmount: subscriptionInfo?.monthlyAmount ?? 0,
    billingPlanStartedAt: subscriptionInfo?.startedAt,
    paymentProcessor: subscriptionInfo?.paymentProcessor,
  };

  if (shopifyUrl) {
    void segmentAnalytics?.group(`store:${shopifyUrl}`, {
      type: "store",
      ...commonProperties,
    });
  }

  void posthog?.group(ANALYTICS_GROUP_NAMES.project, project.id, {
    plan: activeSubscriptionTier,
    ...commonProperties,
  });
  if (project.ownerWorkspaceId) {
    void posthog?.group(
      ANALYTICS_GROUP_NAMES.workspace,
      project.ownerWorkspaceId,
      {
        id: project.ownerWorkspaceId,
        name: project.ownerWorkspace?.name,
      },
    );
  }

  return true;
};

export const initWorkspaceBasedAnalytics = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  if (!shouldLogAnalytics()) {
    return;
  }
  void posthog?.group(ANALYTICS_GROUP_NAMES.workspace, id, {
    type: ANALYTICS_GROUP_NAMES.workspace,
    id,
    name,
  });
};

export function initializePerformanceMonitoring() {
  if (!shouldLogAnalytics()) {
    return;
  }

  initializeFaro({
    url: "https://faro-collector-prod-us-west-0.grafana.net/collect/6a644c89636725ec3c696b0392415764",
    app: {
      name: "editor",
      version: "1.0.0",
      environment: "production",
    },
    instrumentations: [
      ...getWebInstrumentations({
        enablePerformanceInstrumentation: false,
        captureConsole: false,
      }),
    ],
  });
}

export type GlobalEventProperties = {
  userEmail?: string;
  storeUrl?: string | null;
  workspaceId?: string | null;
  projectId?: string | null;
  elementId?: string | null;
  elementName?: string | null;
  elementType?: string | null;
  elementVersion?: number | null;
  unsavedUpdates?: number;
  elementSectionSettingsEnabled?: boolean;
};

export type ExperimentCreateLocation = "experiment_list_tab" | "editor_header";

export type EducationalContentTitle =
  | "Get Started"
  | "Explore Top Tests"
  | "Why A/B Testing Works"
  | "Frequently Asked Questions";

// NOTE (Cole, 2025-03-25): Desired event format is SCREEN.AREA.THING.ACTION
export type AnalyticsEvent = (
  | {
      type: "component.add";
      params: GlobalEventProperties & {
        componentType: Component["type"];
        method: "duplicate" | "paste" | "panel";
        frame: EditorCanvas;
        numberOfComponentsSelected: number;
      };
      description: "Tracked when a user adds a new component to an element";
    }
  | {
      type: "component.delete";
      params: GlobalEventProperties & {
        componentType: Component["type"];
        frame: EditorCanvas;
        numberOfComponentsSelected: number;
      };
      description: "Tracked when a user deletes a component on an element";
    }
  | {
      type: "component.drop";
      params: GlobalEventProperties & {
        componentType: Component["type"];
        frame: EditorCanvas;
        numberOfComponentsSelected: number;
      };
      description: "Tracked when a user drops a component after dragging";
    }
  | {
      type: "component.group";
      params: GlobalEventProperties & {
        frame: EditorCanvas;
        numberOfComponentsSelected: number;
      };
      description: "Tracked when a user groups selected components into a container";
    }
  | {
      type: "component.hide";
      params: GlobalEventProperties & {
        componentType: Component["type"];
        frame: EditorCanvas;
        numberOfComponentsSelected: number;
      };
      description: "Tracked when a user hides a specific component";
    }
  | {
      type: "component.show";
      params: GlobalEventProperties & {
        componentType: Component["type"];
        frame: EditorCanvas;
        numberOfComponentsSelected: number;
      };
      description: "Tracked when a user shows a specific component";
    }
  | {
      type: "component.turninto";
      params: GlobalEventProperties & {
        turnedInto: Component["type"];
        previousComponentType: Component["type"];
        frame: EditorCanvas;
        numberOfComponentsSelected: number;
      };
      description: "Tracked when a user turns a component into another type";
    }
  | {
      type: "component.save";
      params: GlobalEventProperties & {
        componentType: Component["type"];
        frame: EditorCanvas;
      };
      description: "Tracked when a user creates a saved component";
    }
  | {
      type: "element.archive";
      params: {
        elementId: string;
        projectId: string;
        elementType: ReploElement["type"];
        from: "button" | "modal";
      };
      description: "Tracked when the user archives an element";
    }
  | {
      type: "openModal";
      params: OpenModalPayload;
      description: "Tracked when a modal opens";
    }
  | {
      type: "header.viewPage";
      params: {};
      description: "Tracked when the user presses the View Live Page button in the header";
    }
  | {
      type: "header.backToDashboard";
      params: {};
      description: "Tracked when the user pressess the button in the header to return to the dashboard";
    }
  // Project Events
  | {
      type: "project.create";
      params: {
        withShopify: boolean;
        createdFrom: "allProjects" | "orgPage" | "reverseShopifyConnect";
      };
      description: "Tracked when a user clicks the Create Project button from different locations";
    }
  | {
      type: "shopify.connect";
      params: {
        source:
          | "sections"
          | "noShopifyErrorModal"
          | "projectSettings"
          | "newProject"
          | "publish"
          | "preview"
          | "assets"
          | "productPicker"
          | "templateDefault"
          | "billingModal"
          | "integrationHub"
          | "onboarding"
          | "shopStyles";
      };
      description: "Tracked when users click on Add Shopify Integration button";
    }
  | {
      type: "project.shopify.associate";
      params: {};
      description: "Tracked when users click on the Connect Project button to connect project to Shopify";
    }
  | {
      type: "project.edit";
      params: {
        type: "rename";
      };
      description: "Tracked when users edit project name and click save";
    }
  | {
      type: "project.delete";
      params: {
        projectId: string;
        projectName?: string | null;
        from: "details" | "list";
      };
      description: "Tracked when users deletes project";
    }
  | {
      type: "dashboard.logout";
      params: {};
      description: "Tracked when the user presses the logout button on the dashboard";
    }
  | {
      type: "dashboard.projectView.toggle";
      params: {
        viewMode: "gallery" | "list";
      };
      description: "Tracked when the user toggles the project view mode";
    }
  | {
      type: "dashboard.projectView.sort";
      params: {
        sortBy:
          | "project.name"
          | "project.lastEditedAt"
          | "project.workspace"
          | "project.createdAt";
        order: "asc" | "desc";
      };
      description: "Tracked when the user sorts the projects";
    }
  | {
      type: "dashboard.project.click";
      params: {
        storeId: string;
        name: string | undefined;
        storeUrl: string | undefined;
        from: "allProjects" | "orgProjects";
      };
      description: "Tracked when the user clicks a project in the project dashboard";
    }
  | {
      type: "element.new";
      params: {
        type: ReploElement["type"];
        operation: "create" | "duplicate";
        creationMethod:
          | "blank"
          | "template"
          | "ai"
          | "duplicate"
          | "exportToSection";
      };
      description: "Tracked when the user creates a new element (either by creating or duplicating one)";
    }
  | {
      type: "element.update";
      params: { type: ReploElement["type"]; elementId: string };
      description: "Tracked when the user presses the button in page settings to update settings about an element";
    }
  | {
      type: "editor.element.update.homepage";
      params: { isHomepage: boolean };
      description: "Tracked when the user toggles the homepage setting of a page";
    }
  | {
      type: "editor.dynamicData.add";
      params: {
        location: "canvas" | "designPanel";
        targetType: DynamicDataTargetType;
        value: string;
      };
      description: "Tracked when the user sets a new dynamic data prop value";
    }
  | {
      type: "editor.dynamicData.replace";
      params: {
        location: "canvas" | "designPanel";
        targetType: DynamicDataTargetType;
        value: string;
      };
      description: "Tracked when the user replaces an existing dynamic data prop value";
    }
  | {
      type: "editor.dynamicData.format";
      params: {
        location: "canvas" | "designPanel";
        formatters: Formatter[];
        type: "currency" | "date";
      };
      description: "Tracked when the user replaces an existing dynamic data prop value";
    }
  | {
      type: "billing.downgrade.survey";
      params: {
        previousPlan: string;
        currentPlan: string;
        reasons: string[];
        feedback: string;
      };
    }
  | {
      type: "header.publish";
      params: {
        buttonLocation: "preview" | "header";
      };
      description: "Tracked when the user publishes from either the Preview Popover or the header";
    }
  | {
      type: "preview.requested";
      params: {};
      description: "Tracked when the user opens the Preview Popover";
    }
  | {
      type: "preview.copied";
      params: {};
      description: "Tracked when the user copies the preview url from the Preview Popover";
    }
  | {
      type: "experiment.view.detail";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Emitted when the user views the details of an experiment..";
    }
  | {
      type: "experiment.created";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Emitted when the user creates a new experiment.";
    }
  | {
      type: "experiment.activated";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Emitted when the user activates a new experiment.";
    }
  | {
      type: "experiment.completed";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Emitted when the user completes an experiment";
    }
  | {
      type: "experiment.deleted";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Emitted when the user deletes an experiment.";
    }
  | {
      type: "experiment.educationalContent.clicked";
      params: {
        from: "allExperimentsTable" | "detailsTab" | "resultsTab";
        contentTitle: EducationalContentTitle;
      };
      description: "Tracked when the user clicks on educational content (image/video) which links out to external docs.";
    }
  | {
      type: "user.help.requested";
      params: {
        billingPlan: BillingTier | "Free" | "Unknown";
        from: "button";
      };
      description: "Tracked when the user requests help from support chat or 'Get Help' button";
    }
  | {
      type: "user.expert.requested";
      params: {
        from: "website" | "app";
      };
      description: "Tracked when the user requests help from support chat or 'Get Help' button";
    }
  | {
      type: "support.chat.open";
      params: {
        source: string;
        userName: string;
        userEmail: string;
      };
      description: "Tracked when the user opens the support chat";
    }
  | {
      type: "announcements.dismissed";
      params: {
        announcementId: string;
        announcementWebflowId: string;
        source:
          | "AnnouncementsMenu"
          | "AnnouncementMessage"
          | "AnnouncementBanner";
      };
      description: "Tracked when the user dismisses an announcement";
    }
  | {
      type: "announcements.clicked";
      params: {
        announcementId: string;
        announcementWebflowId: string;
        source:
          | "AnnouncementsMenu"
          | "AnnouncementMessage"
          | "AnnouncementBanner";
      };
      description: "Tracked when the user clicks an announcement cta";
    }
  | {
      type: "announcements.menu.opened";
      params: {};
      description: "Tracked when the user opens the announcements menu";
    }
  | {
      type: "store.designSystem.created";
      params: {};
      description: "Tracked when the user creates a design system from shopify.";
    }
  | {
      type: "store.designSystem.updated";
      params: {
        from: "shopify" | "replo";
      };
      description: "Tracked when the user updates a design system from shopify. The user can update from shopify or from replo.";
    }
  | {
      type: "editor.componentTemplate.used";
      params: {
        componentTemplateId: ComponentTemplate["id"];
        collectionId: ComponentTemplate["collectionId"];
        categoryId: ComponentTemplate["categoryId"];
        componentTemplateName: ComponentTemplate["name"];
        componentTemplateType: ComponentTemplate["type"];
        source: "insert-pane" | "marketplace" | "onboarding";
        componentScope: ComponentTemplate["scope"];
        isAIPersonalized?: boolean;
      };
      description: "Tracked when a user uses a component template.";
    }
  | {
      type: "editor.marketplace.browse";
      params: {
        from: "newPage" | "shortcut" | "leftBar";
      };
      description: "Tracked when the user open the marketplace.";
    }
  | {
      type: "editor.marketplace.details";
      params:
        | {
            type: "componentTemplate";
            componentTemplateId?: ComponentTemplate["id"];
            componentTemplateName?: ComponentTemplate["name"];
          }
        | {
            type: "collection";
            collectionId?: ComponentTemplateCollection["id"];
            collectionName?: ComponentTemplateCollection["name"];
          };
      description: "Tracked when the user open the template or collection details.";
    }
  | {
      type: "editor.componentTemplate.share";
      params: {
        shareUrl: string;
        componentTemplateId: ComponentTemplate["id"];
        componentTemplateName: ComponentTemplate["name"];
        from: "componentTemplateDetails" | "componentTemplateCard";
      };
      description: "Tracked when the user copy the link to share the component template.";
    }
  | {
      type: "editor.componentTemplate.save";
      params: {
        scope: ComponentTemplate["scope"];
        type: ComponentTemplate["type"];
        name: ComponentTemplate["name"];
      };
      description: "Tracked when the user save a new component template.";
    }
  | {
      type: "editor.rightbar.tab.select";
      params: {
        tab: RightBarTab;
      };
      description: "Tracked when the user selects a tab in the design panel";
    }
  | {
      type: "editor.rightbar.interaction.add";
      params: {
        type: AlchemyActionTrigger | "animation";
        subType?: Action["type"] | AlchemyAnimationType;
      };
      description: "Tracked when the user adds an interaction to a component";
    }
  | {
      type: "editor.folder.add";
      description: "Tracked when the user adds a page folder";
    }
  | {
      type: "editor.history.restore";
      description: "Tracked when the user restores a previous version of a page";
    }
  | {
      type: "dashboard.nav.click";
      params: {
        tab:
          | "projects"
          | "members"
          | "billing"
          | "analytics"
          | "experiments"
          | "analytics_overview"
          | "analytics_list"
          | "analytics_deep_dive"
          | "analytics_insights"
          | "affiliates_program"
          | "growth_audits"
          | "integrations"
          | "settings"
          | "all_projects"
          | "your_profile";
        workspaceId: string | null;
        userId: string | null;
      };
      description: "Tracked when the user clicks on a nav item in the dashboard.";
    }
  | {
      type: "integration.shopify.add.initiate";
      params: {};
      description: "Tracked when the user clicks on an Add Shopify Integration button from the integration hub.";
    }
  | {
      type: "integration.shopify.remove";
      params: {
        workspaceId: string | null;
        integrationId: string | null;
      };
      description: "Tracked when the user clicks on an Remove Shopify Integration button from the integration hub.";
    }
  | {
      type: "integration.select";
      params: {
        integration_name: string;
      };
      description: "Tracked when the user clicks on an integration from the integration hub.";
    }
  | {
      type: "workspace.delete";
      params: {
        workspaceId: string;
        workspaceName: string;
      };
      description: "Tracked when the user deletes a workspace.";
    }
  | {
      type: "workspace.members.add";
      params: {
        workspaceId: string;
        numberOfInvites: number;
        emails: string[];
        permissionLevel: WorkspaceRole;
      };
      description: "Tracked when the user sends an invitation to a workspace.";
    }
  | {
      type: "workspace.members.permission.update";
      params: {
        memberEmail: string;
        newPermissionLevel: WorkspaceRole;
        workspaceId: string;
      };
      description: "Tracked when the user updates the permissions of another member of the workspace.";
    }
  | {
      type: "workspace.invite.resend";
      params: {
        workspaceId: string;
        resendInviteTo: string;
      };
      description: "Tracked when the user resends an invitation to an workspace.";
    }
  | {
      type: "dashboard.workspace.switch";
      params: {
        toWorkspaceName: string;
        toWorkspaceId: string;
      };
      description: "Tracked when the user switch between workspaces in the dasboard.";
    }
  | {
      type: "workspace.member.removed";
      params: {
        workspaceId: string;
        memberRemoved: string;
        removedBy?: string;
      };
      description: "Tracked when a user removes another user from an workspace.";
    }
  | {
      type: "workspace.setting.updated";
      params: {
        workspaceId: string;
        newWorkspaceName?: string;
        newWorkspaceTimeZone?: string;
      };
      description: "Tracked when a user updates the workspace name or timezone.";
    }
  | {
      type: "workspace.referralCode.deleted";
      params: {
        workspaceId: string;
        code: string;
        email: string;
      };
      description: "Tracked when a user deletes a referral code.";
    }
  | {
      type: "user.setting.updated";
      params: {
        infoUpdated: "name" | "password";
      };
      description: "Tracked when a user updates their profile details (name, password, etc).";
    }
  // Flow events
  | {
      type: "onboarding.flow.begin";
      params: {};
      description: "Tracked when a user submits the first step of the onboarding flow.";
    }
  | {
      type: "onboarding.flow.abandoned";
      params: {
        lastStepCompleted: string;
        stepsData: Partial<FlowStepResultsDataType>;
        heardFrom?: string[];
        whatToDo?: string[];
      };
      description: "Tracked when a user reloads or closes the current flow page.";
    }
  | {
      type: "onboarding.flow.completed";
      params: {
        stepsData: FlowStepResultsDataType;
        whatToDo: string[];
      };
      description: "Tracked when a user submits the last step of the onboarding flow.";
    }
  | {
      type: "onboarding.flow.step.completed";
      params: {
        userId: string;
        stepId: string;
        stepType: string;
      } & Partial<FlowStepData>;
      description: "Tracked when a user submits any step in a flow.";
    }
  | {
      type: "editor-tour.flow.next";
      params: {
        currentStep: string;
      };
      description: "Tracked when a user submits each step of the editor tour flow.";
    }
  | {
      type: "editor-tour.flow.skip";
      params: {
        skipOnStepId: string;
      };
      description: "Tracked when a user skips the editor flow.";
    }
  | {
      type: "editor-tour.flow.completed";
      params: {};
      description: "Tracked when a user submits the last step of the editor tour flow.";
    }
  | {
      type: "editor.pendingUpdatesWarningBannerShown";
      params: {};
      description: "Tracked when the user sees the pending updates warning banner.";
    }
  // Referrals Events
  | {
      type: "referralCode.terms";
      params: {
        workspaceId: string;
        email: string;
      };
      description: "Tracked when a user sings the terms to create the first referral code.";
    }
  | {
      type: "referralCode.created";
      params: {
        workspaceId: string;
        code: string;
        email: string;
      };
      description: "Tracked when a user creates a referral code.";
    }
  | {
      type: "referralCode.copied";
      params: {
        workspaceId: string;
        code: string;
        referralCodeId: string;
        email: string;
      };
      description: "Tracked when a user copies a referral code.";
    }
  | {
      type: "referralCode.updated";
      params: {
        workspaceId: string;
        code: string;
        referralCodeId: string;
        email: string;
      };
      description: "Tracked when a user edits a referral code.";
    }
  | {
      type: "referralCode.lead";
      params: {
        code: string;
        referredUserEmail: string;
      };
      description: "Tracked when a user signs up with a referral code.";
    }
  | {
      type: "referralCode.redeemed";
      params: {
        workspaceId: string;
        code: string;
        referralCodeId: string;
        email: string;
      };
      description: "Tracked when a user subscribe to replo using a referral code.";
    }
  | {
      type: "ai.menu.triggered";
      params: {
        source: MenuTriggerSource;
      };
      description: "Tracked when a user opens the AI menu";
    }
  | {
      type: "ai.action.open";
      params: {
        source: string;
      };
      description: "Tracked when a user opens the AI action panel";
    }
  | {
      type: "ai.action.close";
      params: {
        source: string;
      };
      description: "Tracked when a user closes the AI action panel";
    }
  | {
      type: "ai.action.submitted";
      params: {
        prompt: string;
        triggeringFeature?: string;
      };
      description: "Tracked when a user submits an AI action";
    }
  | {
      type: "ai.message.submitted";
      params: {
        message: string;
      };
      description: "Tracked when a user submits any message to the model";
    }
  | {
      type: "ai.action.aborted";
      description: "Tracked when a user cancels during AI generation";
    }
  // Note (Evan, 2024-06-18): Not including more properties, since it could
  // potentially be a lot of data - we can cross-reference with the
  // corresponding ai.action.generation event from publisher
  // NOTE (Yuxin, 2025-03-15): We only send events for build-assistant
  | {
      type: "ai.action.approved";
      params: {
        streamingUpdateId: string;
        model?: SupportedAIModel;
        triggeringFeature?: string;
      };
      description: "Tracked when a user approves changes made by AI";
    }
  | {
      type: "ai.action.rejected";
      params: {
        streamingUpdateId: string;
        model?: SupportedAIModel;
        triggeringFeature?: string;
      };
      description: "Tracked when a user rejects changes made by AI";
    }
  | {
      type: "ai.action.feedback.positive";
      params: {
        streamingUpdateId?: string;
        feedback?: string;
        triggeringFeature?: AITriggeringFeature;
      };
      description: "User submitted positive feedback on AI changes";
    }
  | {
      type: "ai.action.feedback.negative";
      params: {
        streamingUpdateId?: string;
        feedback?: string;
        triggeringFeature?: AITriggeringFeature;
      };
      description: "User submitted negative feedback on AI changes";
    }
  | {
      type: "canvas.frame.toggle";
      params: {
        action: "show" | "hide";
        frame: EditorCanvas;
      };
      description: "Tracked when a user toggles which canvas frames are shown in multi-canvas";
    }
  | {
      type: "canvas.frame.resize";
      params: {
        method: "drag" | "input" | "presetsDropdown";
        previousSize: number;
        size: number;
        device?: string;
        mode?: "edit" | "preview";
      };
      description: "Tracked when a canvas frame is resized (either through dragging in the canvas, typing px into the input, or selecting a preset device from the dropdown.";
    }
  | {
      type: "canvas.preview";
      params:
        | {
            source: "toolbar";
          }
        | { source: "frameHeader"; frame: EditorCanvas };
      description: "Tracked when the preview button is clicked, either from the editor bottom-bar or from the header for a specific canvas";
    }
  | {
      type: "canvas.selectActiveFrame";
      params: {
        frame: EditorCanvas;
        mode: "edit" | "preview";
      };
      description: "Tracked when the user selects which canvas frame they're viewing via the bottom bar";
    }
  | {
      type: "editor.paste.figma";
      params: {
        figmaId?: string | null;
        warnings: ImportWarning[];
        // Note (Evan, 2024-08-08): This will become a union once
        // we add more entrypoints (REPL-12632)
        entrypoint: "shortcut";
      };
    }
  | {
      type: "editor.paste.figma.confirm";
      params: {};
    }
  | {
      type: "editor.paste.figma.cancel";
      params: {};
    }
  // #region (Kurt, 2024-11-12): Analytics events
  | {
      type: "analytics.connect";
      params: {
        tab: "integrations_tab" | "analytics_tab" | "experiment_details_tab";
      };
      description: "Triggered when a user connects their store via the integrations tab, the analytics tab, or the experiment details tab.";
    }
  | {
      type: "analytics.record.click";
      params: {
        from: "analytics_overview" | "analytics_insights";
      };
      description: "Triggered when a user clicks on a record in the analytics list to view more details (deep dive).";
    }
  | {
      type: "analytics.time.filter";
      params: {
        range: RelativeTimeFrame | "custom";
        location: "analytics_overview" | "analytics_deep_dive";
      };
      description: "Triggered when a user adjusts the time filter in either the deep dive or list view.";
    }
  | {
      type: "analytics.filter";
      params: {
        type: AnalyticsUrlParamsFilterTypes;
        filterDetail: ConditionOperatorEnum;
        location: "analytics_overview" | "analytics_deep_dive";
      };
      description: "Triggered when a user applies a filter to narrow down the data in the deep dive or list view.";
    }
  | {
      type: "analytics.compare";
      params: {
        compareDetail: ComparisonTimeFrame | "custom";
        location: "analytics_overview" | "analytics_deep_dive";
      };
      description: "Triggered when a user adjusts the comparison setting in the deep dive or list view, such as comparing to the previous period.";
    }
  | {
      type: "analytics.search";
      description: "Triggered when a user uses the search function in the table to find specific data.";
    }
  | {
      type: "analytics.table.sort";
      params: {
        sortBy: MetricName;
      };
      description: "Triggered when a user sorts the table by any of the available metrics, such as sessions, conversions, or revenue.";
    }
  | {
      type: "analytics.url.open";
      params: {
        source: "analytics_overview" | "analytics_insights";
      };
      description: "Triggered when a user clicks a URL to open that page in a new tab.";
    }
  | {
      type: "analytics.definitions.open";
      description: "Triggered when a user clicks on the metric definitions link to view the definitions for key metrics.";
    }
  | {
      type: "analytics.report.switch";
      params: {
        switchTo: "entry_page" | "sessions";
        location: "analytics_overview" | "analytics_deep_dive";
      };
      description: "Triggered when a user switches between different report types (e.g., entry page or session reports) in either the deep dive or list view.";
    }
  | {
      type: "analytics.chart.update";
      params: {
        time: ChartInterval;
      };
      description: "Triggered when a user updates the time segmentation dropdown for charts, selecting either hour or week.";
    }
  | {
      type: "analytics.editor.menu.open";
      description: "Tracked when a user opens the AnalyticsMenuPane from the left bar by clicking on the element's analytics icon.";
    }
  | {
      type: "analytics.editor.menu.deepDive.click";
      description: "Tracked when a user opens the Deep Dive page from the editor's AnalyticsMenuPane.";
    }
  // #region Command Menu Events
  | {
      type: "editor.cmdk.open";
      params: {};
      description: "Tracked when a user opens the command menu (cmd/ctrl+k).";
    }
  | {
      type: "editor.cmdk.close";
      params: {
        interaction: "cmd-k" | "modal";
      };
      description: "Tracked when a user closes the command menu either via cmd/ctrl+k, or via modal controls (escape/clicking outside)";
    }
  | {
      type: "editor.cmdk.select";
      params: {
        label: string;
      };
      description: "Tracked when a user selects a command from the command menu.";
    }
  | {
      type: "editor.cmdk.back";
      params: {
        interaction: "esc" | "backspace";
      };
      description: "Tracked when a user navigates back in the command menu via escape key or backspace.";
    }
  // #endregion
  // #region (Max, 2025-03-04): Analytics Insights Events
  | {
      type: "analytics.insights.filters.aggregationMetric.change";
      params: {
        workspaceId: string | undefined;
        newAggregationMetric: AggregationMetric;
      };
      description: "Tracked when a user changes the aggregation metric in the Analytics Insights page (e.g. from 'average' to 'median').";
    }
  | {
      type: "analytics.insights.card.apply.click";
      params: {
        workspaceId: string | undefined;
        insightId: string;
        elementId: string;
        projectId: string;
      };
      description: "Tracked when a user clicks the apply button on a Insights card from the Analytics Insights page.";
    }
  | {
      type: "analytics.insights.card.thumbs.click";
      params: {
        workspaceId: string | undefined;
        insightId: string;
        thumbsType: "up" | "down";
      };
      description: "Tracked when a user clicks the thumbs up or down on a Insights card from the Analytics Insights page.";
    }
  | {
      type: "analytics.insights.filters.industry.change";
      params: {
        workspaceId: string | undefined;
        newIndustry: Industry;
      };
      description: "Tracked when a user changes the industry in the Analytics Insights page (e.g. from 'health' to 'beauty & fitness').";
    }
  | {
      type: "analytics.insights.feedbackLink.click";
      params: {
        workspaceId: string | undefined;
      };
      description: "Tracked when a user clicks the feedback link in the Analytics Insights page.";
    }
  | {
      type: "analytics.insights.improvementSuggestions.click";
      params: {
        workspaceId: string | undefined;
      };
      description: "Tracked when a user clicks the improvement suggestions link in the Analytics Insights page.";
    }
  | {
      type: "analytics.insights.atRiskTable.actions.click";
      params: {
        workspaceId: string | undefined;
        action: "hidePage";
        urlPath: string;
      };
      description: "Tracked when a user clicks the improvement suggestions link in the Analytics Insights page.";
    }
  // #endregion
  // #region (Kurt, 2024-11-12): Experiments Refresh Events
  | {
      type: "experiment.create";
      params: {
        location: ExperimentCreateLocation;
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user selects the 'Create New Experiment' button in the list view (not on the create experiment page if they do not save the experiment).";
    }
  | {
      type: "experiment.launch";
      params: {
        billingPlanTier: BillingTier;
        variantCount: number;
        description?: string;
      };
      description: "Triggered when a user launches an experiment, recording if a new or existing link was used, the number of variants, and an optional description.";
    }
  | {
      type: "experiment.completed";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user ends an experiment or selects a winner.";
    }
  | {
      type: "experiment.upgrade.click";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user clicks the 'Upgrade' button in the billing modal opened from experiments.";
    }
  | {
      type: "experiment.link.create";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user clicks 'Create Link' within the new experiment view.";
    }
  | {
      type: "experiment.detail.select";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user toggles to view experiment details.";
    }
  | {
      type: "experiment.results.select";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user toggles to view experiment results.";
    }
  | {
      type: "experiment.link.preview";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user previews the test link for an experiment.";
    }
  | {
      type: "experiment.link.copy";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user copies the test link for an experiment.";
    }
  | {
      type: "experiment.groupName.add";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user adds a custom group name to an experiment.";
    }
  | {
      type: "experiment.domain.add";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user adds a custom sub-domain to an experiment.";
    }
  | {
      type: "experiment.settings.update";
      params: {
        billingPlanTier: BillingTier;
      };
      description: "Triggered when a user updates any experiment settings.";
    }
  | {
      type: "editor.panel.toggle";
      params: {
        isOpened: boolean;
        toggle: LeftBarTab;
        openedWithShortcut: boolean;
      };
      description: "Triggered when a user toggles between panels (pages, tree, components), indicating whether the panel is opened or closed and if it was opened with a shortcut.";
    }
  | {
      type: "editor.panel.resize";
      params: {
        previousSize: number;
        newSize: number;
        activePanel: LeftBarTab | null;
      };
      description: "Triggered when a user resizes the editor panel, capturing both the previous size and the new size.";
    }
  | {
      type: "element.search";
      description: "Triggered when a user searches within the pages panel.";
      params: {
        searchString: string;
      };
    }
  | {
      type: "element.select";
      description: "Triggered when a user selects a page.";
      params: {
        elementId: string;
        elementType: ReploElementType;
      };
    }
  | {
      type: "components.search";
      description: "Triggered when a user searches within the components panel.";
      params: {
        searchString: string;
      };
    }
  | {
      type: "design.modifier.add";
      params: {
        type: ModifierType;
        propertyType: ModifierOptionalProperty | "minMaxWidthHeight";
      };
      description: "Triggered when a user adds one of the hidden modifiers in the right panel.";
    }
  // #region (Fran, 2024-12-12): Design Library Events
  | {
      type: "library.style.add";
      params: {
        type: "text" | "color";
        tag: SavedStyleColorType | SavedStyleTextAttributes["htmlTag"];
      };
      description: "Triggered when a user adds a style to the design library.";
    }
  | {
      type: "library.style.import";
      params: {
        importMethod: "url" | "shopify" | "replo" | null;
      };
      description: "Triggered when a user imports a style from another source.";
    }
  | {
      type: "library.style.apply";
      params: {
        modifier: string;
        type: "text" | "color";
      };
      description: "Triggered when a user applies a style from the design library to a component.";
    }
  | {
      type: "library.style.delete";
      params: {
        type: "text" | "color";
        tag: SavedStyleColorType | SavedStyleTextAttributes["htmlTag"];
      };
      description: "Triggered when a user deletes a style from the design library.";
    }
  | {
      type: "library.style.duplicate";
      params: {
        type: "text" | "color";
        tag: SavedStyleColorType | SavedStyleTextAttributes["htmlTag"];
      };
      description: "Triggered when a user duplicates a style from the design library.";
    }
  | {
      type: "library.style.import.confirm";
      params: {};
      description: "Triggered when a user confirms the import of saved styles.";
    }
  | {
      type: "library.style.import.reject";
      params: {};
      description: "Triggered when a user rejects the import of saved styles.";
    }
  | {
      type: "brandDetails.tabSelected";
      params: {
        tab: BrandDetailsTab;
      };
      description: "Triggered when a user selects the shop styles tab.";
    }
  | {
      type: "brandDetails.pullFromUrl";
      params: {
        url: string;
      };
      description: "Triggered when a user pulls brand details from a URL.";
    }
  | {
      type: "shopDetails.styles.save";
      params: {};
      description: "Tracked when a user saves shop details styles";
    }
  | {
      type: "shopDetails.styles.cancel";
      params: {};
      description: "Tracked when a user cancels shop details styles change";
    }
  | {
      type: "shopDetails.context.save";
      params: {};
      description: "Tracked when a user saves shop details context";
    }
  | {
      type: "shopDetails.context.cancel";
      params: {};
      description: "Tracked when a user cancels shop details context change";
    }
  | {
      type: "shopDetails.presetStyle.click";
      params: {
        category: StyleCategory;
      };
      description: "Tracked when a user clicks a preset saved style";
    }
  | {
      type: "asset.upload.success";
      params: {
        projectId: string;
        assetId: string;
        contentType: string;
        publicUrl: string;
      };
      description: "Triggered when a user uploads an asset successfully.";
    }
  | {
      type: "asset.upload.failed";
      params: {
        projectId: string;
        error: unknown;
      };
      description: "Triggered when a user uploads an asset but fails.";
    }
  | {
      type: "asset.updated";
      params: {
        projectId: string;
        assetId: string;
      };
      description: "Triggered when a user updates an asset.";
    }
  | {
      type: "asset.deleted";
      params: {
        projectId: string;
        assetId: string;
      };
      description: "Triggered when a user deletes an asset.";
    }
  // #region (Jackson, 2025-01-07): Client error events
  | {
      type: "error.input.string.exceeds_max_length";
      params: {
        value: string;
        stringLength: number;
        maxLength: number;
      };
      description: "Triggered when a user inputs a string that is too long into an input field.";
    }
  | {
      type: "error.project.not_found";
      params: {
        projectError: any;
      };
      description: "Triggered when a user attempts to load a store that does not exist.";
    }
  | {
      type: "error.billing.update.failed";
      params: {};
      description: "Triggered when a user attempts to update their billing plan but fails.";
    }
  | {
      type: "error.partnershipAgreement.create.failed";
      params: {};
      description: "Triggered when a user attempts to create a partnership agreement but fails.";
    }
  | {
      type: "error.savedStyle.duplicate";
      params: {};
      description: "Triggered when a user attempts to duplicate a saved style but fails.";
    }
  | {
      type: "error.ai.generatePrimaryStyles";
      params: {};
      description: "Triggered when a user attempts to generate primary styles but fails.";
    }
  | {
      type: "error.project.membership.blocked";
      params: {
        blockedConfigTitle: string;
        blockedConfigSubtitle: string;
      };
      description: "Triggered when a user attempts to add a collaborator to a project but the email is blocked.";
    }
  | {
      type: "error.element.delete";
      params: {
        error: string;
      };
      description: "Triggered when a user attempts to delete an element but fails.";
    }
  | {
      type: "error.figma.font.upload";
      params: {
        fontFamily: string;
      };
      description: "Triggered when a user attempts to upload a font via figma import but fails.";
    }
  | {
      type: "error.font.upload";
      params: {
        error: FetchBaseQueryError | SerializedError;
      };
      description: "Triggered when a user attempts to upload a font but fails.";
    }
  | {
      type: "error.page.preview.not_found";
      params: {
        error: string;
      };
      description: "Triggered when a user attempts to preview a page but fails.";
    }
  | {
      type: "error.page.preview.not_found.no_404";
      params: {
        error: string;
      };
      description: "Triggered when a user attempts to preview a page but fails and the error is not a 404.";
    }
  | {
      type: "error.publish.unavailable";
      params: {
        publishNotAvailableReasonTitle: string;
        publishNotAvailableReasonMessage: string;
      };
      description: "Triggered when a user attempts to publish a page but fails.";
    }
  | {
      type: "error.component.delete";
      params: {
        error: string;
        componentId: string;
      };
      description: "Triggered when a user attempts to delete a component but fails.";
    }
  | {
      type: "error.component.copy";
      params: {
        error: string;
        componentIds: string[];
        clipboard: DataTransfer | undefined;
      };
      description: "Triggered when a user attempts to copy a component but fails.";
    }
  | {
      type: "error.component.group";
      params: {
        error: string;
        componentIds: string[];
      };
      description: "Triggered when a user attempts to group components but fails.";
    }
  | {
      type: "error.component.replace";
      params: {
        error: string;
        componentId: string;
        componentJson: Object;
      };
      description: "Triggered when a user attempts to replace a component but fails.";
    }
  | {
      type: "error.component.paste.invalid";
      params: {
        componentId: string;
        clipboard: ReploClipboardSingle | undefined;
      };
      description: "Triggered when a user attempts to paste a single Shopify App Block component outside of a Section.";
    }
  | {
      type: "error.component.duplicate";
      params: {
        error: string;
        componentId: string;
      };
      description: "Triggered when a user attempts to duplicate a component but fails.";
    }
  | {
      type: "error.component.copy.styles";
      params: {
        componentId: string;
      };
      description: "Triggered when a user attempts to copy styles from a component but fails.";
    }
  | {
      type: "error.asset.upload.shopify_not_connected";
      params: {};
      description: "Triggered when a user attempts to upload an asset but fails because the Shopify store is not connected.";
    }
  | {
      type: "error.asset.upload.too_many_files";
      params: {};
      description: "Triggered when a user attempts to upload more than MAX_FILE_UPLOAD_COUNT files at a time.";
    }
  | {
      type: "error.asset.upload.invalid_file_type";
      params: {};
      description: "Triggered when a user attempts to upload an asset but fails because of an invalid file type.";
    }
  | {
      type: "error.asset.upload.unknown";
      params: {};
      description: "Triggered when a user attempts to upload an asset but fails because of an unknown error.";
    }
  | {
      type: "error.saved_style.delete";
      params: {};
      description: "Triggered when a user attempts to delete a saved style but fails.";
    }
  | {
      type: "error.saved_style.duplicate";
      params: {};
      description: "Triggered when a user attempts to duplicate a saved style but fails.";
    }
  | {
      type: "error.saved_style.save";
      params: {};
      description: "Triggered when a user attempts to save changes to their shop styles.";
    }
  | {
      type: "error.element.update";
      params: {
        elementDetail: string;
      };
      description: "Triggered when a user attempts to update an element but fails.";
    }
  | {
      type: "error.element.create";
      params: {
        elementDetail: string;
      };
      description: "Triggered when a user attempts to create an element but fails.";
    }
  | {
      type: "error.element.duplicate";
      params: {
        elementDetail: string;
      };
      description: "Triggered when a user attempts to duplicate an element but fails.";
    }
  | {
      type: "element.update.unmatchedSaveVersion";
      params: {
        payload: string;
      };
      description: "Triggered when a user attempts to update an element but receives 'Someone else made a change.'.";
    }
  | {
      type: "error.clipboard.read";
      params: {
        clipboardReadPermission: PermissionStatus;
      };
      description: "Triggered when a user attempts to read from the clipboard but fails.";
    }
  | {
      type: "error.clipboard.write";
      params: {
        permissions: Permissions;
      };
      description: "Triggered when a user attempts to write to the clipboard but fails.";
    }
  | {
      type: "error.file.upload";
      params: {
        error: string;
      };
      description: "Triggered when a user attempts to upload a file through a FileDropZonebut fails.";
    }
  | {
      type: "error.component.drop";
      params: {
        error: string;
      };
      description: "Triggered when a user attempts to drop a component but fails.";
    }
  | {
      type: "error.component.drop";
      params: {
        error: string;
      };
      description: "Triggered when a user attempts to drag a component but fails.";
    }
  | {
      type: "error.experiment.create";
      params: {
        error: string;
        workspaceId: string | undefined;
        experiments: Experiment[] | undefined;
      };
      description: "Triggered when a user attempts to create an experiment but fails.";
    }
  | {
      type: "error.experiment.complete";
      params: {
        winnerId: string | undefined;
        displaySelectionId: string | undefined;
      };
      description: "Triggered when a user attempts to complete an experiment but fails.";
    }
  | {
      type: "error.workspace.update";
      params: {
        formDataParsed: Object;
      };
      description: "Triggered when a user attempts to update a workspace but fails.";
    }
  | {
      type: "error.workspace.access_denied";
      params: {
        user: User | undefined;
        isFetching: boolean;
        isSuccess: boolean;
        isMemberOfCurrentWorkspace: boolean;
      };
      description: "Triggered when a user attempts to access a workspace but fails.";
    }
  | {
      type: "error.iframe.load";
      params: {
        error: string;
      };
      description: "Triggered when the canvas iframe fails to load after 10 seconds.";
    }
) & {
  params: GlobalEventProperties;
};

export type AnalyticsEventParamsOf<EventName extends AnalyticsEvent["type"]> =
  Extract<AnalyticsEvent, { type: EventName }>["params"] &
    GlobalEventProperties;

export const analytics = {
  logEvent: <EventName extends AnalyticsEvent["type"]>(
    eventName: EventName,
    eventProperties: AnalyticsEventParamsOf<EventName>,
  ) => {
    Sentry.addBreadcrumb({
      category: "analyticsEvent",
      message: eventName,
      level: "info",
      data: eventProperties,
    });

    if (shouldLogAnalytics()) {
      void segmentAnalytics?.track(
        eventName,
        eventProperties,
        eventProperties.storeUrl
          ? { context: { groupId: `store:${eventProperties.storeUrl}` } }
          : undefined,
      );
      void posthog?.capture(eventName, eventProperties);
    } else if (!isUnitTest()) {
      // biome-ignore lint/suspicious/noConsoleLog: no console log
      console.log("Event:", eventName, eventProperties);
    }
  },
};

export const getGlobalEventProperties = (
  state: CoreState,
  project: ReploProject | null,
  workspaceId: string | null,
): GlobalEventProperties => {
  const draftElement = selectDraftElementFromCoreState(state);
  const draftElementVersion = selectDraftElementVersionFromCoreState(state);

  return {
    storeUrl: getStoreData(project)?.shopifyUrl ?? undefined,
    workspaceId: workspaceId ?? null,
    projectId: project?.id ?? null,
    elementId: state.elements.draftElementId ?? undefined,
    elementName: draftElement?.name,
    elementType: draftElement?.type,
    elementVersion: draftElementVersion,
    unsavedUpdates: selectUpdatesSinceLastRequestFinishedFromCoreState(state),
  };
};

function extractUserFacingDetailsFromUnknownError(error: unknown) {
  if (
    typeof error === "object" &&
    error !== null &&
    "error" in error &&
    typeof error.error === "object" &&
    error.error !== null &&
    "data" in error.error
  ) {
    const userFacingDetails = errorUserFacingDetailsSchema.safeParse(
      error.error.data,
    );
    if (userFacingDetails.success) {
      return userFacingDetails.data;
    }
  }
}
