import type { AnyAction } from "@reduxjs/toolkit";
import type { ErrorDetails } from "schemas/errors";
import type { ErrorUserFacingDetails } from "schemas/generated/errors";

import { breadcrumb } from "@editor/infra/nonfatal";
import { docs } from "@editor/utils/docs";

import { createSlice } from "@reduxjs/toolkit";
import toast from "@replo/design-system/components/alert/Toast";
import { TRPC_ERROR_CODES_BY_KEY } from "@trpc/server/unstable-core-do-not-import";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import {
  CUSTOM_ERROR_KEY_TO_PROPS,
  DEFAULT_ERROR_MESSAGE_CONFIG,
  errorUserFacingDetailsSchema,
} from "schemas/errors";

const errorUserFacingDetailsToErrorDetails = (
  errorUserFacingDetails: ErrorUserFacingDetails,
):
  | {
      errorDetails: ErrorDetails;
      type: "fullPageModal" | "toast";
      errorKey?: string;
      endpointName?: string;
    }
  | {
      type: "shopifyStorePassword";
      errorKey: "passwordRequired" | "wrongPassword";
    }
  | { type: "none"; errorKey?: string; endpointName?: string }
  | null => {
  if (errorUserFacingDetails.type === "uncommon_none") {
    return null;
  }
  if (errorUserFacingDetails.type === "shopifyStorePassword") {
    return {
      type: "shopifyStorePassword",
      errorKey: errorUserFacingDetails.key as
        | "passwordRequired"
        | "wrongPassword",
    };
  }
  const errorDetails: ErrorDetails = {
    header: errorUserFacingDetails.message,
    message: errorUserFacingDetails.detail ?? [],
    isRecoverable: errorUserFacingDetails.isRecoverable ?? false,
    isFullPage: errorUserFacingDetails.type === "fullPageModal",
  };

  if (errorUserFacingDetails.callToAction) {
    if (typeof errorUserFacingDetails.callToAction === "object") {
      errorDetails.callToAction = {
        type: "custom",
        callToActionType: errorUserFacingDetails.callToAction,
      };
    } else {
      errorDetails.callToAction = exhaustiveSwitch({
        type: errorUserFacingDetails.callToAction,
      })({
        installApp: {
          type: "installApp",
        },
        reload: {
          type: "callback",
          name: "Refresh",
          onClick: () => window.location.reload(),
        },
        closeModal: {
          type: "closeModal",
          name: "Close",
        },
        navigateToProjectEditor: {
          type: "custom",
          callToActionType: errorUserFacingDetails.callToAction,
        },
        goToHomepage: {
          type: "callback",
          name: "Go To Home",
          onClick: () => window.location.replace("/home"),
        },
      });
    }
  }
  return {
    type:
      errorUserFacingDetails.type === "fullPageModal"
        ? "fullPageModal"
        : "toast",
    errorDetails,
    errorKey: errorUserFacingDetails.key,
  };
};

export const getErrorDetails = (
  config:
    | {
        type: "rsaaOrRtkQueryAction";
        action: AnyAction;
      }
    | {
        type: "trpcError";
        userFacingDetails?: ErrorUserFacingDetails;
        code?: number;
      },
):
  | {
      errorDetails: ErrorDetails;
      type: "fullPageModal" | "toast";
      errorKey?: string;
      endpointName?: string;
    }
  | {
      type: "shopifyStorePassword";
      errorKey: "passwordRequired" | "wrongPassword";
    }
  | { type: "none"; errorKey?: string; endpointName?: string }
  | null => {
  if (
    config.type === "rsaaOrRtkQueryAction" &&
    !Boolean(config.action.type?.endsWith("_ERROR")) &&
    !Boolean(config.action.type?.endsWith("/rejected"))
  ) {
    return null;
  }

  // Note (Evan, 2024-04-26): Don't throw a toast when AI requests are aborted
  // (this can be user-triggered)
  if (
    config.type === "rsaaOrRtkQueryAction" &&
    config.action.meta?.aborted === true &&
    (config.action.meta?.arg?.endpointName === "aiAltText" ||
      config.action.meta?.arg?.endpointName === "aiTextV2" ||
      config.action.meta?.arg?.endpointName === "aiMobileResponsive" ||
      config.action.meta?.arg?.endpointName === "aiTemplate")
  ) {
    return null;
  }

  breadcrumb("Network request returned failure/rejected", "error", {
    type:
      config.type === "rsaaOrRtkQueryAction"
        ? config.action.type
        : config.userFacingDetails?.key,
  });

  if (config.type === "trpcError") {
    let errorUserFacingDetails = config.userFacingDetails;
    if (
      !errorUserFacingDetails &&
      config.code === TRPC_ERROR_CODES_BY_KEY.TOO_MANY_REQUESTS
    ) {
      errorUserFacingDetails = {
        type: "toast",
        key: "unknown",
        message: "Too many requests",
        detail:
          "You've made too many requests in a short time period. Please try again later, or reach out to support@replo.app for help.",
      };
    }

    if (!errorUserFacingDetails) {
      errorUserFacingDetails = {
        type: "toast",
        key: "unknown",
        message: "Unknown error",
        detail:
          "An unknown error has occurred. If this error persists, please reach out to support@replo.app for help.",
      };
    }
    return errorUserFacingDetailsToErrorDetails(errorUserFacingDetails);
  }

  // Note (Evan, 2024-01-19): If the error contains userFacingDetails, just use those
  const errorUserFacingDetails = errorUserFacingDetailsSchema.safeParse(
    config.action?.payload?.data ?? config.action?.payload?.response,
  );
  if (errorUserFacingDetails.success) {
    return errorUserFacingDetailsToErrorDetails(errorUserFacingDetails.data);
  }

  const errorKey =
    config.action?.payload?.response?.key ?? config.action?.payload?.data?.key;
  const messageFromServer =
    config.action?.payload?.response?.message ??
    config.action?.payload?.data?.message;

  // Note (Evan, 2024-01-19): Otherwise, look in the custom error key map
  let errorDetails: ErrorDetails | null = null;
  if (errorKey) {
    errorDetails = CUSTOM_ERROR_KEY_TO_PROPS[
      errorKey as keyof typeof CUSTOM_ERROR_KEY_TO_PROPS
    ]?.({
      type: errorKey,
      serverMessage: messageFromServer,
      endpoint: config.action.meta?.arg?.endpointName,
      // Note (Noah, 2024-05-30, USE-994): Because of the cast above, we have to
      // re-cast to the nullable type. I tried a few different configurations and
      // this seems to be the least hacky way to have typescript represent the
      // actual type
    }) as ErrorDetails | null;
  }

  if (!errorDetails) {
    errorDetails = DEFAULT_ERROR_MESSAGE_CONFIG;
  }

  if (errorDetails.isFullPage) {
    return {
      type: "fullPageModal",
      errorDetails,
      errorKey,
      endpointName: config.action.meta?.arg?.endpointName,
    };
  }

  // Note (Evan, 2024-01-19): Suppress the toast in a few specific scenarios:
  const suppressToast = Boolean(
    // billing plan errors are already displayed in the billing plan modal
    errorKey?.startsWith("billingPlan") ||
      // "path already in use" errors are already displayed in the page data modal
      errorKey === "pathAlreadyInUse" ||
      // RTKQ throws a "ConditionError" to bail out of queries that are already in progress,
      // this is internal to RTKQ so we don't want to alert the user
      // (see https://github.com/reduxjs/redux-toolkit/issues/2501)
      config.action.meta?.condition,
  );

  if (!suppressToast) {
    return {
      type: "toast",
      errorDetails,
      errorKey,
      endpointName: config.action.meta?.arg?.endpointName,
    };
  }
  return {
    type: "none",
    errorKey,
    endpointName: config.action.meta?.arg?.endpointName,
  };
};

/**
 * This can be used to extract usage data from a billing plan exception if it
 * exists.
 */
export const getBillingPlanErrorUsage = (
  action: AnyAction,
):
  | {
      maximum: number;
      current: number;
    }
  | undefined => {
  const usage = action.payload?.response?.usage ?? action.payload?.data?.usage;
  return usage
    ? {
        maximum: usage.maximum ?? Number.POSITIVE_INFINITY,
        current: usage.current,
      }
    : undefined;
};

const getToastCtaForErrorDetails = (errorDetails: ErrorDetails) => {
  if (
    errorDetails.callToAction?.type === "custom" &&
    typeof errorDetails.callToAction.callToActionType === "object" &&
    errorDetails.callToAction.callToActionType.type === "navigateToLink"
  ) {
    const { link } = errorDetails.callToAction.callToActionType;
    return exhaustiveSwitch(link)({
      "errors.chunkingError": () => ({
        cta: "Learn more",
        ctaHref: docs.errors.chunkingError,
      }),
      "errors.wrongJsonExtension": () => ({
        cta: "Learn more",
        ctaHref: docs.errors.wrongJsonExtension,
      }),
      "errors.indexBackupError": () => ({
        cta: "Learn more",
        ctaHref: docs.errors.indexBackupError,
      }),
      customLink: (data) => ({
        cta: "Learn more",
        ctaHref: data.url,
      }),
    });
  }
  return null;
};

/**
 * A slice for any errors and exceptions
 */
const errorSlice = createSlice({
  name: "error",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* If you define a next, automatically redirect after an API call is successful */
      .addMatcher(
        (action: AnyAction) => {
          return (
            Boolean(action.type?.endsWith("_ERROR")) ||
            Boolean(action.type?.endsWith("/rejected"))
          );
        },
        (_state, action) => {
          const details = getErrorDetails({
            type: "rsaaOrRtkQueryAction",
            action,
          });

          if (details && details.type === "toast") {
            const cta = getToastCtaForErrorDetails(details.errorDetails);
            if (cta) {
              toast({
                type: "error",
                ...details.errorDetails,
                ...cta,
              });
            } else {
              toast({
                type: "error",
                ...details.errorDetails,
              });
            }
          }
        },
      );
  },
});

const { reducer } = errorSlice;

export default reducer;
