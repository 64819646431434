import type { View } from "@editor/components/left-bar/insert-pane/InsertPane";
import type { CategoryCard } from "@editor/components/left-bar/insert-pane/TemplateCategories";

import * as React from "react";

import { selectLeftBarWidth } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import twMerge from "@replo/design-system/utils/twMerge";
import { ChevronRight } from "lucide-react";

import TemplateList from "./TemplateList";

interface TemplateCategoryMenuCardProps {
  selectedView: View;
  category: CategoryCard;
  openedMenu: string;
  hideMenuContent: boolean;
  overflowClassname: string;
  elementRef: React.RefObject<HTMLDivElement>;
}

const TemplateCategoryMenuCard: React.FC<TemplateCategoryMenuCardProps> = ({
  selectedView,
  category,
  openedMenu,
  hideMenuContent,
  overflowClassname,
  elementRef,
}) => {
  const leftBarWidth = useEditorSelector(selectLeftBarWidth);

  return (
    <NavigationMenu.Item
      key={category.value}
      value={category.value}
      className="list-none"
    >
      <NavigationMenu.Trigger
        className={twMerge(
          "text-xs font-semibold flex w-full justify-between p-2 items-center rounded",
          openedMenu === category.value ? "bg-hover" : "",
        )}
        onClick={(e) => {
          if (openedMenu === category.value) {
            e.preventDefault();
          }
        }}
      >
        <div className="flex items-center gap-2">
          <div className="bg-subtle border-[0.5px] rounded p-2">
            <category.icon size={18} className="text-muted" />
          </div>
          <div className="flex flex-col text-start">
            <span>{category.name}</span>
            {category.description && (
              <span className="font-normal text-slate-600">
                {category.description}
              </span>
            )}
          </div>
        </div>
        <ChevronRight size={16} className="text-placeholder" />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content
        id="insert-pane-template-list-wrapper"
        style={{
          left: leftBarWidth,
          top: 0,
        }}
        ref={elementRef}
        className={twMerge(
          "absolute w-64 bg-white h-full border-t border-r border-secondary styled-scrollbar shadow-lg",
          openedMenu !== category.value || hideMenuContent ? "invisible" : "",
          overflowClassname,
        )}
        onPointerLeave={(e) => {
          // Note (Ben O. 2025-03-03): This is here to prevent the opened saved menu from closing when we right click to delete saved templates.
          if (openedMenu === "saved") {
            e.preventDefault();
          }
        }}
      >
        <TemplateList selectedView={selectedView} category={category} />
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
};

export default TemplateCategoryMenuCard;
