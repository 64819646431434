import type { ComponentTemplate } from "@editor/types/component-template";

export const beforeAfterSlider: ComponentTemplate = {
  id: "cd13478c-e1cd-4cfe-8680-5cb4b7cc6a29",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Basic/Before/After Slider",
  preview: "component/beforeAfterSlider.png",
  leftBarCategory: { main: "interactive", subCategory: "beforeAfterSlider" },
  template: {
    id: "e8602cb7-85fd-4834-9759-27d7c865b2ba",
    name: "Before & After Slider",
    type: "beforeAfterSlider",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
      },
      _sliderComponent: {
        id: "1558e58c-abc2-4d41-a25b-74c80a52fa48",
        name: "Slider Control",
        type: "beforeAfterSliderThumb",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            alignItems: "center",
            justifyContent: "center",
            __height: "100%",
            __width: "60px",
            flexGrow: 1,
            height: "100%",
          },
        },
        children: [
          {
            id: "45f61546-68ef-4b27-bc93-b6381134a8ec",
            type: "container",
            name: "Divider",
            props: {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "100%",
                width: "2px",
                __width: "2px",
                height: "100%",
                zIndex: 1,
                position: "absolute",
                top: "50%",
                left: "50%",
                __transform: {
                  translateY: "-50%",
                  translateX: "-50%",
                  translateZ: "0px",
                  scaleX: "100%",
                  scaleY: "100%",
                  scaleZ: "100%",
                  rotateX: "0deg",
                  rotateY: "0deg",
                  rotateZ: "0deg",
                  skewX: "0deg",
                  skewY: "0deg",
                },
                alignSelf: "stretch",
                backgroundColor: "#000000FF",
              },
            },
            children: [],
          },
          {
            id: "50bdbd48-1a53-4c79-aee0-640ee8e201a7",
            type: "container",
            props: {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "100%",
                width: "60px",
                borderTopWidth: "2px",
                borderRightWidth: "2px",
                borderBottomWidth: "2px",
                borderLeftWidth: "2px",
                borderTopColor: "#000",
                borderRightColor: "#000",
                borderBottomColor: "#000",
                borderLeftColor: "#000",
                borderTopStyle: "solid",
                borderRightStyle: "solid",
                borderBottomStyle: "solid",
                borderLeftStyle: "solid",
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
                height: "60px",
                __height: "60px",
                color: "#000000FF",
              },
            },
            children: [
              {
                id: "64a26e3f-91d0-4132-b2c3-1b7addd4cb02",
                type: "icon",
                props: {
                  style: {
                    width: "28px",
                    height: "28px",
                    __width: "28px",
                    __height: "28px",
                  },
                  iconName: "arrow-left",
                },
              },
              {
                id: "24c89576-04aa-46d7-84cc-296bb8bf102a",
                type: "icon",
                props: {
                  style: {
                    width: "28px",
                    height: "28px",
                    __width: "28px",
                    __height: "28px",
                  },
                  iconName: "arrow-right",
                },
              },
            ],
            name: "Thumb",
          },
        ],
      },
      _beforeComponent: {
        id: "c50ffe81-7b96-49b5-a421-679ca64bf351",
        name: "Before Content",
        type: "beforeAfterSliderBeforeContent",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          },
        },
        children: [
          {
            id: "577d8e19-224f-46af-b456-e0892add5453",
            name: "Image 1",
            type: "image",
            props: {
              src: "",
              style: {
                objectFit: "cover",
                flexGrow: 1,
              },
              loading: "eager",
            },
          },
        ],
      },
      _afterComponent: {
        id: "a114c4aa-dc83-4c88-9d7f-1419592c49de",
        name: "After Content",
        type: "beforeAfterSliderAfterContent",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          },
        },
        children: [
          {
            id: "c33550dc-7d61-48b9-b2ce-2195137010bc",
            type: "image",
            name: "Image 2",
            props: {
              src: "",
              style: {
                objectFit: "cover",
                flexGrow: 1,
              },
              loading: "eager",
            },
          },
        ],
      },
    },
  },
};
