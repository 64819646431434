import { z } from "zod";

export const PENDING_SHOPIFY_INTEGRATION_COOKIE =
  "replo_pending_shopify_integration";

export const pendingShopifyIntegrationCookieSchema = z.discriminatedUnion(
  "type",
  [
    z.object({
      type: z.literal("onboarding"),
    }),
    z.object({
      type: z.literal("sections"),
      workspaceId: z.string(),
      projectId: z.string(),
    }),
    z.object({
      type: z.literal("noShopifyErrorModal"),
      projectId: z.string().nullish(),
      workspaceId: z.string().nullish(),
    }),
    z.object({
      type: z.literal("projectSettings"),
      workspaceId: z.string(),
      projectId: z.string(),
    }),
    z.object({
      type: z.literal("newProject"),
      workspaceId: z.string(),
      projectId: z.string(),
    }),
    z.object({
      type: z.literal("publish"),
      workspaceId: z.string(),
      projectId: z.string(),
    }),
    z.object({
      type: z.literal("shopStyles"),
      workspaceId: z.string(),
      projectId: z.string(),
    }),
    z.object({
      type: z.literal("preview"),
      workspaceId: z.string(),
      projectId: z.string(),
    }),
    z.object({
      type: z.literal("assets"),
      workspaceId: z.string(),
      projectId: z.string(),
    }),
    z.object({
      type: z.literal("productPicker"),
      workspaceId: z.string(),
      projectId: z.string(),
    }),
    z.object({
      type: z.literal("integrationHub"),
      workspaceId: z.string(),
    }),
    z.object({
      type: z.literal("billingModal"),
      workspaceId: z.string(),
      projectId: z.string().nullish(),
    }),
  ],
);

export type PendingShopifyIntegrationCookie = z.infer<
  typeof pendingShopifyIntegrationCookieSchema
>;
