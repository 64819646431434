import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const JunipProductRating: React.FC<RenderComponentProps> = (props) => {
  const liquidSource = `
    <span
      class="junip-product-summary"
      data-product-id="{{ product.id }}"
      data-product-rating-count="{{ product.metafields.junip.rating_count }}"
      data-product-rating-average="{{ product.metafields.junip.rating_average }}">
    </span>
  `;

  const product = useProductFromProps(props.component.props, props.context);

  const liquid = wrapProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context: props.context,
  });

  return (
    <SharedShopifyLiquid
      componentAttributes={props.componentAttributes}
      component={props.component}
      componentId={props.component.id}
      liquidSource={liquid}
      title="Junip Product Rating"
      placeholderType="reviewStars"
      repeatedIndexPath={props.context.repeatedIndexPath}
      isLiquidSupported
    />
  );
};

export default JunipProductRating;
