// NOTE (Jackson, 2025-02-05): prefer vanilla approach over lodash here b/c
// startCase adds a space before each capital letter (ex: "ABeeZee" -> "A Bee Zee"),
// which we don't want, same with words()
export const fontStartCase = (str: string) => {
  return str
    .replaceAll("_", " ")
    .replaceAll("-", " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
