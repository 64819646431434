import type { ComponentTemplate } from "@editor/types/component-template";

import { getCanBeAddedFunction } from "@editor/utils/template";

const price: ComponentTemplate = {
  id: "db38b605-99ad-4dc7-93a4-68a29d9c6565",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Product Price",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "product/price.svg",
  template: {
    id: "5a03a31e-4571-41fa-a0ae-9bc2f9e501eb",
    type: "text",
    props: {
      text: "{{attributes._variant.displayPrice}}",
      style: { fontSize: "20px", lineHeight: "24px" },
    },
  },
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Prices can only be added inside Product boxes.",
  ),
};

export default price;
