// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import * as React from "react";

import { BenefitsImagesLayout } from "@editor/components/dashboard/partner/partner-program-v2-lp-components/BenefitsImagesLayout";
import { Hero } from "@editor/components/dashboard/partner/partner-program-v2-lp-components/Hero";
import { LogoCloud } from "@editor/components/dashboard/partner/partner-program-v2-lp-components/LogoCloud";
import PartnerProgramTiers from "@editor/components/dashboard/partner/partner-program-v2-lp-components/PartnerProgramTiers";

import { Accordion } from "@replo/design-system/components/accordion";
import Button from "@replo/design-system/components/button/Button";

const IMAGES = [
  {
    src: "/images/dashboard/partner/images/replo-cookies.png",
    alt: "Replo Cookies",
    title: "Earn Up to $3K for every customer",
    description:
      "Receive up to $3K for every user you refer when you refer customers with your own personalized affiliate link",
  },
  {
    src: "/images/dashboard/partner/images/replo-content.png",
    alt: "Replo Masterclass",
    title: "Create Content Together",
    description:
      "Let’s join forces to co-create content across our newsletter, podcast, blog, social channels, and Slack community",
  },
  {
    src: "/images/dashboard/partner/images/replo-meetup.png",
    alt: "Replo Meetup",
    title: "Coordinate Meetups and Events",
    description:
      "Gain exclusive access to Replo Meetups and Events - an invaluable perk designed to spark connections, collaborations, and growth",
  },

  {
    src: "/images/dashboard/partner/images/product-roadmap.png",
    alt: "Replo Roadmap",
    title: "Product Roadmap and Strategy Review",
    description:
      "Participate in monthly Roadmap and Strategy Reviews as you help shape the trajectory of Replo from the ground up ",
  },
  {
    src: "/images/dashboard/partner/images/replo-support.png",
    alt: "Replo Support Team",
    title: "Dedicated Support",
    description:
      "Receive dedicated support, gain priority consideration for opportunities, and align strategies for mutual success",
  },
  {
    src: "/images/dashboard/partner/images/partner-directory.png",
    alt: "Replo Directory",
    title: "Partner Directory",
    description:
      "Our Partner Directory serves as a showcase for your applications, SaaS solutions, and expertise within the Shopify Ecosystem",
  },
];
const CONTENT = [
  {
    title: "Weekly Newsletter Features",
    description:
      "Get promoted in our weekly newsletter and reach an audience of tens of thousands of brands, agencies, and tech partners ",
  },
  {
    title: "Design To Convert Podcast",
    description:
      "Join us on 'Design To Convert', and showcase your expertise, knowledge and experiences to an audience eager to learn",
  },
  {
    title: "Guides / Case Studies",
    description:
      "Get featured in our guides and case studies and showcase your real-world experiences and success stories",
  },

  {
    title: "Virtual & IRL Events ",
    description:
      "Feature as a guest in our virtual and in-real-life events and engage with the broader industry to share your insights",
  },
  {
    title: "Social and Community",
    description:
      "Stand out in our social media channels and Slack community and get featured as an industry expert and DTC thought leader",
  },
  {
    title: "Blog Posts",
    description:
      "Get featured in our blog posts and establish yourself as an authoritative voice in the broader ecommerce industry",
  },
];
const BENEFITS_ON_CLICK = [
  {
    title: "Slack Community",
    description:
      "Join our Slack community to collaborate, share insights, and find solutions amongst industry experts and innovators.",
    src: "/images/dashboard/partner/images/slack-community-image.png",
    alt: "Slack Community Image",
    href: "/community",
  },
  {
    src: "/images/dashboard/partner/images/partner-directory-image.png",
    alt: "Partner Directory Image",
    title: "Partner Directory",
    description:
      "Join our Partner Directory and get discovered by thousands of brands looking to connect with your unique skillsets.",
    href: "https://airtable.com/shrGZqxcM2QMXpRof",
  },
  {
    src: "/images/dashboard/partner/images/crossbeam.png",
    alt: "Crossbeam Image",
    title: "Crossbeam",
    description:
      "Meet us on Crossbeam - the platform where data-driven insights merge with collaboration, allowing us to optimize our partnership.",
    href: "https://app.crossbeam.com/register?public_invite_code=ulkksc",
  },
  {
    src: "/images/dashboard/partner/images/marketplace-image.png",
    alt: "Marketplace Image",
    title: "Marketplace Dashboard",
    description:
      "List your services and templates on Replo’s Marketplace and tap into a vibrant ecosystem. Monetize your expertise by catering to a global audience.",
    href: "https://airtable.com/shrGZqxcM2QMXpRof",
  },
  {
    src: "/images/dashboard/partner/images/partnership-integrations.png",
    alt: "Partnership Integrations",
    title: "Partnership Integrations",
    description:
      "We’re on a mission to integrate the most groundbreaking Shopify-focused tools and services into our ecosystem. We’re forging a network of innovation across Shopify.",
    href: "https://airtable.com/shrGZqxcM2QMXpRof",
  },
  {
    src: "/images/dashboard/partner/images/partnership-badges.png",
    alt: "Partnership Badges",
    title: "Partnership Badges",
    description:
      "Our Partnership Badges are emblems that signify your commitment to the Shopify Ecosystem. By earning a badge, you stand shoulder to shoulder with the best.",
    href: "https://airtable.com/shrGZqxcM2QMXpRof",
  },
];
const faq = [
  {
    id: "1f2ae81a-eb74-11ed-817a-f2472ab43fa2",
    label: "What is the Replo Partner Program?",
    description:
      "The Replo Partner Program is a meticulously crafted initiative that invites individuals and businesses to form synergistic alliances with Replo. As a valued member of this program, you have the opportunity to earn an impressive 50% commission for an entire year when new customers start their Replo journey using your personalized affiliate link for their first subscription. But that's not all! Being a Replo Partner also grants you access to a wealth of exclusive perks and benefits, specifically designed to propel you to new heights of success and fulfillment in the ecommerce domain.",
  },
  {
    id: "1f2ae888-eb74-11ed-817a-f2472ab43fa2",
    label: "How much can I earn as a Replo Partner?",
    description: [
      "For every user you refer through your personalized affiliate link, you have the potential to earn up to $3,000. The commission structure is tier-based and calculated based on the number of active referrals in a paid tier within the preceding 30-day period. This structure ensures that the more successful referrals you have, and the higher the subscription tiers they choose, the more you can earn",
    ],
  },
  {
    id: "1f2ae892-eb74-11ed-817a-f2472ab43fa2",
    label: "What is the Replo Partner Directory?",
    description:
      "The Replo Partner Directory showcases the multifaceted applications, cutting-edge SaaS solutions, and unrivaled expertise of Replo Partners within the vibrant Shopify Ecosystem. Located on Replo's Marketing website, this Directory enjoys the limelight as it is regularly featured across our vibrant Community, insightful Newsletter, and dynamic Social Channels. This exposure is invaluable for partners, as the Directory serves as a beacon, guiding thousands of brands on a quest to discover and collaborate with experts who possess the unique skill sets and services they seek to thrive in ecommerce.",
  },
  {
    id: "1f2ae8a6-eb74-11ed-817a-f2472ab43fa2",
    label: "How can I collaborate with Replo in content creation?",
    description:
      "As a Replo Partner, you have the unique opportunity to join forces with Replo in co-creating impactful content that spans a diverse range of platforms, such as newsletters, podcasts, blogs, social media channels, and the interactive Slack community. Moreover, you can showcase your expertise and share your success stories by being featured in Replo's comprehensive guides and insightful case studies. Additionally, you can immerse yourself in engaging discussions and network with industry leaders through Replo’s virtual and live events. By applying to become a partner and progressing through the Partner tiers, you'll unlock a variety of content creation opportunities that not only amplify your voice but also position you as a thought leader in the ecosystem.",
  },
  {
    id: "1f2ae8a7-eb74-11ed-817a-f2472ab43fa2",
    label: "What are Replo Meetups and Events, and how can they benefit me?",
    description:
      "Replo Meetups and Events are exclusive, vibrant gatherings organized for our community, where Replo Partners have the unique opportunity to host events and showcase their products or services. With a dual focus on nurturing relationships and fostering collaborations, these events are pivotal in bringing together the Replo community and the broader Shopify DTC ecosystem. Through a mix of weekly digital events covering topics like CRO, SEO, Advertising, and in-person meetups in cities like Los Angeles, Miami, Toronto, and London, Replo Partners gain invaluable networking opportunities, cutting-edge industry insights, and a platform to showcase their expertise and offerings to like-minded professionals and potential collaborators.",
  },
  {
    id: "1f2ae8b0-eb74-11ed-817a-f2472ab43fa2",
    label: "What is the monthly Roadmap and Strategy Review?",
    description:
      "As a valued Replo Partner, you are cordially invited to participate in our Monthly Roadmap and Strategy Reviews. These sessions serve as a conduit for your active engagement in shaping Replo’s evolution and charting its future course. We believe that our community and partners are the foundation upon which our platform thrives. In these sessions, you can share insights, provide feedback, and contribute to the vision. We work together to meticulously craft tools and resources tailored to each tier, empowering you to reach new heights of success in the ecommerce arena.",
  },
  {
    id: "1f2ae91e-eb74-11ed-817a-f2472ab43fa2",
    label:
      "How can I use Replo’s social media channels and Slack community to my advantage?",
    description:
      "Engaging with Replo’s social media channels and vibrant Slack community presents a golden opportunity to establish yourself as an industry expert. Share your insights, expertise, and best practices that can create an impact in the DTC landscape. By actively participating, you can become a recognized DTC thought leader, gaining credibility among peers and aspiring entrepreneurs. Several of our partners have embraced the spirit of giving back by making regular contributions to our community, using our Slack community as a platform to educate, inspire, and empower others through the exchange of knowledge and experience. We encourage you to do the same!",
  },
  {
    id: "1f2ae91f-eb74-11ed-817a-f2472ab43fa2",
    label:
      "How can I leverage the Replo Marketplace through the Replo Partner Program?",
    description:
      "As a Replo Partner, you can harness the potential of the Replo Marketplace by creating and selling your own bespoke templates. The Marketplace is a bustling hub where creators, designers, and developers converge to offer high-quality, conversion-focused templates for ecommerce teams. By contributing your expertly designed templates, you can tap into a global audience, monetize your expertise, and establish yourself as a go-to resource within the Shopify ecosystem. This not only elevates your brand but also creates a revenue stream while contributing to the community’s growth and success.",
  },
  {
    id: "1f2ae920-eb74-11ed-817a-f2472ab43fa2",
    label:
      "What are Partnership Badges and how can they enhance my visibility?",
    description:
      "Partnership Badges are prestigious emblems awarded to Replo Partners in recognition of their innovation and steadfast commitment to the Shopify Ecosystem. Securing a badge elevates you among the industry's elite, substantially boosting your visibility and cementing your credibility. These badges can be prominently displayed on your website, acting as a testament to your distinguished status as a Certified Replo Partner, and underlining your dedication to excellence and innovation.",
  },
  {
    id: "1f2ae921-eb75-11ed-817a-f2472ab43fa2",
    label: "How do I apply to become a Replo Partner?",
    description:
      "To apply for the Replo Partner Program, simply submit an application through the designated page. Upon submission, you will initially be welcomed into the program as an Affiliate. As you generate referrals and meet the criteria for the Silver tier, a Replo representative will reach out to you with the exciting news of your elevation to the Replo Partner Program. But that's just the beginning! As you progress through the tiers, Replo will provide you with an enriched suite of tools and resources tailored to each successive tier, ensuring that you are well-equipped to thrive and make a significant impact in the ecommerce landscape.",
  },
];
export const PartnerProgramLPContent = () => {
  return (
    <div className="w-full bg-white">
      <Hero />
      <LogoCloud />
      <BenefitsImagesLayout
        content={IMAGES}
        title="Start Your Empowering Journey with the Replo Partner Program Today"
      />
      <PartnerProgramTiers />
      <BenefitsImagesLayout
        content={CONTENT}
        title="Accelerating Joint Innovation and Building Co-Marketing Success Together"
      />
      <BenefitsImagesLayout
        content={BENEFITS_ON_CLICK}
        title="Unlock Synergies and Forge Alliances Across the Shopify Ecosystem"
      />
      <div className="bg-black py-32 dark:bg-black">
        <div className="g:text-center mx-auto flex flex-col items-center justify-center gap-7 px-4 text-white dark:text-slate-50 sm:px-6 lg:px-8">
          <p className="mt-2 text-center text-4xl font-semibold leading-10 md:text-left">
            Apply to Become a Partner Today
          </p>
          <Button
            variant="tertiary"
            size="base"
            to="https://replo.app/calendar/partners"
            target="_blank"
          >
            Partner With Us
          </Button>
        </div>
      </div>
      <div className="md:max-w-custom mx-3 flex flex-col justify-center gap-7 py-20 md:mx-16 md:flex-row">
        <div className="w-full md:w-1/2">
          <p className="text-4xl font-semibold text-black">
            Frequently Asked Questions
          </p>
          <p className="mt-4 text-lg">
            No matter what your question is, the team at Replo is always on hand
            to provide prompt and accurate answers. Email us at
            support@replo.app anytime.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <Accordion elements={faq} primaryColor="#000" />
        </div>
      </div>
    </div>
  );
};
