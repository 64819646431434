import type { Middleware } from "redux";

import { setStorageItem } from "@editor/hooks/useLocalStorage";

import toast from "@replo/design-system/components/alert/Toast";

const LAST_NOTIFIED_OF_UPDATES_KEY = "lastNotifiedOfUpdates";

// TODO (gabe 2023-04-21): move to replo-core/constants.ts once it exists
const GIT_SHA_CUSTOM_HEADER_NAME = "X-Git-Sha";

const compareToCurrentShaAndNotify = (serverSha: string | null | undefined) => {
  const currentSha = window.__COMMIT_HASH__;
  if (serverSha && currentSha && currentSha !== serverSha) {
    const lastNotifiedUpdatesValue = localStorage.getItem(
      LAST_NOTIFIED_OF_UPDATES_KEY,
    );
    const lastNotifiedUpdates = lastNotifiedUpdatesValue
      ? new Date(lastNotifiedUpdatesValue)
      : undefined;

    if (
      !lastNotifiedUpdates ||
      lastNotifiedUpdates.getTime() < Date.now() - 1000 * 60 * 60 * 4
    ) {
      toast({
        header: "A new version of Replo is available",
        message: "Please refresh the page to update",
      });
      setStorageItem(LAST_NOTIFIED_OF_UPDATES_KEY, new Date().toISOString());
    }
  }
};

const updateCheckingMiddleware: Middleware = () => (next) => (action) => {
  if (action.type.endsWith("/fulfilled")) {
    const headers = action.meta?.baseQueryMeta?.response?.headers;
    const serverSha = headers?.get(GIT_SHA_CUSTOM_HEADER_NAME);
    compareToCurrentShaAndNotify(serverSha);
  }
  return next(action);
};

export default updateCheckingMiddleware;
