import * as React from "react";

import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { RiTextSpacing } from "react-icons/ri";
import { CSS_LENGTH_TYPES_WITH_COMPUTED } from "replo-runtime/shared/utils/units";

import ModifierLabel from "../../extras/ModifierLabel";
import { LengthInputSelector } from "../../modifiers/LengthInputModifier";

interface LetterSpacingSelectorProps {
  onChange: (letterSpacing: string) => void;
}

const LetterSpacingSelector: React.FC<LetterSpacingSelectorProps> = ({
  onChange,
}) => {
  const letterSpacingDefaultValue =
    styleAttributeToEditorData.letterSpacing.defaultValue;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Spacing" />}
      startEnhancer={
        <Tooltip inheritCursor content="Letter Spacing" triggerAsChild>
          <span tabIndex={0}>
            <RiTextSpacing />
          </span>
        </Tooltip>
      }
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.letterSpacing"
      resetValue={letterSpacingDefaultValue}
      anchorValue="1px"
      dragTrigger="label"
      onChange={onChange}
      menuOptions={[
        { label: "Reset", value: "" },
        { label: "1px", value: "1px" },
        { label: "2px", value: "2px" },
        { label: "4px", value: "4px" },
      ]}
      previewProperty="letterSpacing"
    />
  );
};

export default LetterSpacingSelector;
