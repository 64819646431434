import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { BoxSizes } from "@editor/types/component-template";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { DraggableData, DraggableEvent } from "react-draggable";
import type {
  Bounds,
  Edges,
  NumericBounds,
  WidthOrHeight,
} from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { BoxSide } from "replo-utils/lib/types";
import type { Component } from "schemas/component";

import * as React from "react";

import ArrowIndicator from "@editor/components/common/ArrowIndicator";
import {
  componentHasDefinedHeight,
  componentHasDefinedWidth,
} from "@editor/components/editor/page/element-editor/components/modifiers/utils";
import { Dimensions } from "@editor/constants/styles";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useDraggingCursor from "@editor/hooks/useDraggingCursor";
import useIsDraggingSpacingIndicators from "@editor/hooks/useIsDraggingSpacingIndicators";
import {
  selectComponentMapping,
  selectDraftComponent,
  selectDraftComponentNodeFromActiveCanvas,
  selectDraftComponentType,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftParentComponent,
  selectedDraftComponentIsRoot,
  selectGetAttribute,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { getAlignSelf, getEditorComponentNode } from "@editor/utils/component";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import Draggable from "react-draggable";
import { getChildren } from "replo-runtime/shared/utils/component";
import { componentTypeToRenderData } from "replo-runtime/store/components";
import { inRange, isCloseTo, parseFloat, round } from "replo-utils/lib/math";
import { capitalizeFirstLetter } from "replo-utils/lib/string";

import {
  convertToUnit,
  getUpdatedDimensionValue,
  isTopLeftSide,
  isVerticalSide,
} from "./canvas-utils";

interface DimensionResizerProps {
  boxSizes: BoxSizes;
  draftComponentId: string | null;
  canvasScale: number;
  draftComponentNode: HTMLElement;
}

export const DimensionResizer = (props: DimensionResizerProps) => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const isPageRootComponent = useEditorSelector(selectedDraftComponentIsRoot);
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();
  const { boxSizes } = props;

  const getProperUnitValue = (
    field: string,
    value: string | number,
    roundDown = false,
  ) => {
    return (
      draftComponent &&
      convertToUnit(
        props.draftComponentNode,
        field,
        parseFloat(value),
        selectGetAttribute(store.getState()),
        draftComponent,
        roundDown,
      )
    );
  };

  const onChange = (
    payload: {
      width: string;
      height: string;
      __width: string;
      __height: string;
    },
    alignSelf: string | null,
    flexGrow: string | number | null,
  ) => {
    const field = payload.width ? "width" : "height";
    const value = payload[field];
    if (value === "100%") {
      alignSelf = "auto";
    }
    const properUnitValue = getProperUnitValue(field, value, true);
    const properUnitPreviousValue = getProperUnitValue(
      field,
      payload[`__${field}`],
      true,
    );

    renderInDOMDirectly([field], properUnitValue);
    const action: UseApplyComponentActionType = {
      type: "setStyles",
      componentId: props.draftComponentId,
      value: {
        [field]:
          alignSelf === "stretch" && field === "width"
            ? "auto"
            : properUnitValue,
        [`__${field}`]: properUnitPreviousValue,
      },
    };

    if (alignSelf) {
      action.value.alignSelf = alignSelf;
    }
    if (flexGrow) {
      action.value.flexGrow = flexGrow;
    }
    if (payload?.height) {
      action.value.height = payload.height;
    }

    applyComponentAction(action);
  };

  const renderInDOMDirectly = (fields: string[], value: string | null) => {
    fields.forEach((field) => {
      props.draftComponentNode.style.setProperty(`--${field}`, value || "0px");
    });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  const onPreviewChange = React.useCallback(
    (orientation: string, v: string) => {
      const valueWithUnit = getProperUnitValue(orientation, v, false)!;

      renderInDOMDirectly([orientation], valueWithUnit);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.draftComponentNode],
  );

  const draggableComponentProps = {
    ...boxSizes,
    onChange,
    onPreviewChange,
    canvasScale: props.canvasScale,
    draftComponentNode: props.draftComponentNode,
    draftComponentId: props.draftComponentId,
  };

  // Note (Fran, 2022-08-30): If the component selected is the page root
  // we need to disallow resizing with the drag handles.
  if (isPageRootComponent) {
    return null;
  }

  return (
    <>
      {(["left", "top", "right", "bottom"] as const).map((orientation) => (
        <SingleDimensionResizer
          {...draggableComponentProps}
          orientation={orientation}
          key={orientation}
        />
      ))}
    </>
  );
};

interface SingleDimensionResizerProps {
  bounds: NumericBounds;
  margins: Edges;
  paddings: Edges;
  borders: Edges;
  orientation: BoxSide;
  canvasScale: number;
  onChange(
    payload: Partial<Edges | Bounds>,
    alignSelf: string | null,
    flexGrow: string | number | null,
  ): void;
  onPreviewChange(spacing: string, v: string): void;
  draftComponentNode: HTMLElement;
  draftComponentId: string | null;
}

const SingleDimensionResizer: React.FC<SingleDimensionResizerProps> = (
  props,
) => {
  const {
    bounds,
    margins,
    paddings,
    borders,
    orientation,
    onChange,
    onPreviewChange,
    draftComponentNode,
    canvasScale,
  } = props;

  const draftComponent = useEditorSelector(selectDraftComponent);
  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const draftParentComponent = useEditorSelector(selectDraftParentComponent);

  const getAttribute = useEditorSelector(selectGetAttribute);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  const modifiedOrientation = React.useMemo(
    () => getModifiedOrientation(orientation),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [draftComponent, draftParentComponent, orientation],
  );
  const initialValue = `${bounds[modifiedOrientation]}px`;

  const {
    currentValue,
    setCurrentValue,
    lastSignedValue,
    setLastSignedValue,
    valueBeforeDrag,
    parentHasDefinedWidth,
    parentHasDefinedHeight,
    parentContentWidth,
    parentContentHeight,
    componentHorizontalSpace,
    componentVerticalSpace,
  } = useDynamicDragValues(initialValue, bounds);

  const [isDragging, setIsDragging] = React.useState(false);
  const [arrowProps, setArrowProps] = React.useState({
    top: "0px",
    left: "0px",
    perpendicularSpace: "0px",
    arrowLength: 0,
  });

  const [componentAlignSelf, setAlignSelf] = React.useState("auto");
  const [newProportionalHeight, setNewProportionalHeight] = React.useState<
    string | null
  >(null);
  const [newProportionalWidth, setNewProportionalWidth] = React.useState<
    string | null
  >(null);

  const { setDraggingCursor } = useDraggingCursor();
  const { setIsDraggingSpacingIndicators } = useIsDraggingSpacingIndicators();

  if (!draftComponent || draftComponentType == null) {
    return null;
  }

  const allowedOrientations =
    componentTypeToRenderData[draftComponentType]
      ?.canvasIndicatorDragDirections ?? [];

  if (!allowedOrientations.includes(modifiedOrientation)) {
    return null;
  }

  const boxSizes = {
    bounds,
    paddings,
    borders,
    margins,
  };

  const isVertical = orientation === "top" || orientation === "bottom";
  const isParentVerticalStack = isComponentVerticalStack(
    draftParentComponent,
    getAttribute,
  );

  const onDragStart = () => {
    setIsDraggingSpacingIndicators(true);

    setIsDragging(true);
    const updatedValue = `${bounds[modifiedOrientation]}px`;
    setLastSignedValue(updatedValue);
    setCurrentValue(parseFloat(updatedValue));
    setArrowProps(
      getMarginArrowIndicatorProps(
        modifiedOrientation,
        boxSizes,
        parseFloat(updatedValue),
      ),
    );

    valueBeforeDrag.current = {
      horizontalAvailableSpace: parentContentWidth - componentHorizontalSpace,
      verticalAvailableSpace: parentContentHeight - componentVerticalSpace,
      value: parseFloat(updatedValue),
      initialHeight: bounds.height,
      initialWidth: bounds.width,
    };

    (["left", "right", "bottom", "top"] as const).forEach((orientation) => {
      const field = `--margin-${orientation}`;
      const marginValue = convertToUnit(
        draftComponentNode,
        `margin${capitalizeFirstLetter(orientation)}`,
        parseFloat(margins[orientation]),
        getAttribute,
        draftComponent,
        false,
      );

      draftComponentNode.style.setProperty(field, marginValue);
    });
    let alignSelf = getAlignSelf(
      draftComponent,
      draftParentComponent,
      getAttribute,
    );
    const width = getAttribute(draftComponent, "style.width", null).value;
    if (
      modifiedOrientation === "width" &&
      (width === "100%" || alignSelf === "stretch") &&
      (modifiedOrientation !== "width" || isParentVerticalStack)
    ) {
      if (isTopLeftSide(orientation)) {
        alignSelf = "flex-end";
      } else {
        alignSelf = "flex-start";
      }
    }

    ["width", "height"].forEach((spacing) => {
      const field = `--${spacing}`;
      const value = convertToUnit(
        draftComponentNode,
        spacing,
        parseFloat(bounds[spacing as WidthOrHeight]),
        getAttribute,
        draftComponent,
        false,
      );

      draftComponentNode.style.setProperty(field, value);
    });

    setAlignSelf(alignSelf);

    draftComponentNode.style.setProperty("--align-self", alignSelf);
    if (modifiedOrientation === "width" && !isParentVerticalStack) {
      draftComponentNode.style.setProperty("--flex-grow", "unset");
      draftComponentNode.style.setProperty("--flex-shrink", "0");
      draftComponentNode.style.setProperty("--flex-basis", "auto");
    }

    if (modifiedOrientation === "width") {
      draftComponentNode.classList.add("indicator-width");
    } else {
      draftComponentNode.classList.add("indicator-height");
    }

    setDraggingCursor(
      getMarginBarCursor(
        orientation,
        modifiedOrientation,
        draftComponent,
        draftParentComponent,
        getAttribute,
        parseFloat(updatedValue),
        isParentVerticalStack,
      ),
    );
  };

  const onDrag = (e: DraggableEvent, data: DraggableData) => {
    if (!isDragging) {
      return;
    }
    const { deltaX, deltaY } = data;

    const { updatedValue, signedValue, updatedBounds } =
      getUpdatedDimensionValue(
        deltaX,
        deltaY,
        orientation,
        modifiedOrientation,
        boxSizes,
        {
          valueBeforeDrag: valueBeforeDrag.current,
          parentHasDefinedWidth,
          parentHasDefinedHeight,
          parentContentWidth,
          parentContentHeight,
          componentHorizontalSpace,
          componentVerticalSpace,
        },
        round(
          parseFloat(lastSignedValue) > 0 ? parseFloat(lastSignedValue) : 0,
          2,
        ),
        isParentVerticalStack,
      );
    setLastSignedValue(signedValue);

    if (e?.shiftKey && updatedBounds?.height >= 0 && updatedBounds?.width > 0) {
      const isModifiedOrientationWidth = modifiedOrientation === "width";
      const height = updatedBounds.height;
      const width = updatedBounds?.width;

      const previousWidth = parseFloat(
        valueBeforeDrag.current.initialWidth as string,
      );

      const previousHeight = parseFloat(
        valueBeforeDrag.current.initialHeight as string,
      );

      let aspectRatio = 0;

      // Note (Fran, 2022-07-06): If height is 0, the aspect ratio will be 0, so
      // we need to set as initial value 1 so we can calculate the new height
      if (previousHeight <= 0 && previousWidth > 0) {
        aspectRatio = 1 / previousWidth;
      } else if (previousWidth <= 0) {
        aspectRatio = previousHeight / 1;
      } else {
        aspectRatio = previousHeight / previousWidth;
      }

      const setPreviewAndNewValue = (previewValue: number) => {
        const previewValueRounded = round(previewValue, 2);
        if (isModifiedOrientationWidth) {
          setNewProportionalHeight(`${previewValueRounded}px`);
          onPreviewChange("height", `${previewValueRounded}px`);
        } else {
          setNewProportionalWidth(`${previewValueRounded}px`);
          onPreviewChange("width", `${previewValueRounded}px`);
        }
      };

      if (Number.isFinite(aspectRatio) && aspectRatio >= 0) {
        const newProportionalValue = isModifiedOrientationWidth
          ? aspectRatio * width
          : height / aspectRatio;

        const parentHasDefinedValue = isModifiedOrientationWidth
          ? parentHasDefinedHeight
          : parentHasDefinedWidth;
        const parentContentValue = isModifiedOrientationWidth
          ? parentContentHeight
          : parentContentWidth;

        // Note (Fran, 2022-06-29): If the parent doesn't have a defined
        // height/width then we don't need to limit its new proportional value.
        if (
          !parentHasDefinedValue ||
          inRangeIncludingStart(newProportionalValue, 0, parentContentValue)
        ) {
          if (isModifiedOrientationWidth) {
            if (
              inRangeIncludingStart(width, 0, parentContentWidth) ||
              width === 0
            ) {
              setPreviewAndNewValue(newProportionalValue);
            } else {
              // Note (Fran, 2022-06-29): If the width reaches the parent width, we
              // need to set the last calculated height for keeping the aspect ratio.
              setPreviewAndNewValue(height);
            }
          } else {
            if (
              inRangeIncludingStart(height, 0, parentContentHeight) ||
              height === 0
            ) {
              setPreviewAndNewValue(newProportionalValue);
            } else {
              // Note (Fran, 2022-06-29): If the height reaches the parent height, we
              // need to set the last calculated width for keeping the aspect ratio.
              setPreviewAndNewValue(width);
            }
          }
        } else {
          // Note (Fran, 2022-06-29): If the height reaches the parent height, we
          // need to set the parent width/height value.
          setPreviewAndNewValue(parentContentValue);
        }
      } else {
        setPreviewAndNewValue(0);
      }
    } else {
      setNewProportionalHeight(null);
      setNewProportionalWidth(null);
    }

    // This section is where we take the possibility that the element is restricted by min/max width/height into account
    let constrainedValue = updatedValue;

    if (modifiedOrientation === "width") {
      const actualWidth = draftComponentNode.getBoundingClientRect().width;
      const proposedWidth = parseFloat(updatedValue);

      if (!isCloseTo(actualWidth, proposedWidth)) {
        constrainedValue = `${actualWidth}px`;
      }
    } else if (modifiedOrientation === "height") {
      const actualHeight = draftComponentNode.getBoundingClientRect().height;
      const proposedHeight = parseFloat(updatedValue);
      if (!isCloseTo(actualHeight, proposedHeight)) {
        constrainedValue = `${actualHeight}px`;
      }
    }

    if (parseFloat(updatedValue) > 0) {
      setCurrentValue(parseFloat(constrainedValue));
      onPreviewChange(modifiedOrientation, String(updatedValue));
      const arrowProps = getMarginArrowIndicatorProps(
        modifiedOrientation,
        {
          ...boxSizes,
          bounds: draftComponentNode.getBoundingClientRect(),
        },
        parseFloat(constrainedValue),
      );
      setArrowProps(arrowProps);
    }
  };

  const onDragStop = () => {
    setIsDraggingSpacingIndicators(false);

    const { updatedValue, alignSelf } = getUpdatedDimensionValue(
      0,
      0,
      orientation,
      modifiedOrientation,
      boxSizes,
      {
        valueBeforeDrag: valueBeforeDrag.current,
        parentHasDefinedWidth,
        parentHasDefinedHeight,
        parentContentWidth,
        parentContentHeight,
        componentHorizontalSpace,
        componentVerticalSpace,
      },
      round(
        parseFloat(lastSignedValue) > 0 ? parseFloat(lastSignedValue) : 0,
        2,
      ),
      isParentVerticalStack,
    );

    setDraggingCursor(null);

    const payload = {
      [modifiedOrientation]: updatedValue,
      [`__${modifiedOrientation}`]: valueBeforeDrag.current.value,
    };

    if (newProportionalHeight && modifiedOrientation === "width") {
      payload.height = newProportionalHeight;
    }

    if (newProportionalWidth && modifiedOrientation === "height") {
      payload.width = newProportionalWidth;
    }

    let modifiedAlignSelf = alignSelf;
    let flexGrow = null;

    if (modifiedOrientation !== "width" || isParentVerticalStack) {
      modifiedAlignSelf = alignSelf || componentAlignSelf;
    } else {
      // unset flexGrow if parent is horizontal stack and changing it's width
      flexGrow = "unset";
    }

    if (!isCloseTo(valueBeforeDrag.current.value, parseFloat(updatedValue))) {
      onChange(payload, modifiedAlignSelf, flexGrow);
    }

    setIsDragging(false);
    if (modifiedOrientation === "width") {
      draftComponentNode.classList.remove("indicator-width");
    } else {
      draftComponentNode.classList.remove("indicator-height");
    }
  };

  let arrowLabel =
    draftComponentNode && draftComponentNode?.parentElement
      ? convertToUnit(
          draftComponentNode,
          modifiedOrientation,
          parseFloat(currentValue),
          getAttribute,
          draftComponent,
          true,
        )
      : parseFloat(currentValue);

  if (typeof arrowLabel === "string" && !arrowLabel.includes("%")) {
    arrowLabel = String(parseFloat(arrowLabel));
  } else {
    arrowLabel = String(arrowLabel);
  }

  const marginBarStyles = getMarginBarStyles(orientation, boxSizes);

  const marginBarCursor = getMarginBarCursor(
    orientation,
    modifiedOrientation,
    draftComponent,
    draftParentComponent,
    getAttribute,
    parseFloat(initialValue),
    isParentVerticalStack,
  );

  const draggablePosition = {
    x: Number(marginBarStyles.left),
    y: Number(marginBarStyles.top),
  };

  return (
    <div>
      {isDragging && (
        <ArrowIndicator
          arrowheadClassName="text-rose-600"
          arrowClassName="bg-rose-600"
          isVertical={isVertical}
          label={arrowLabel}
          {...arrowProps}
        />
      )}
      <Draggable
        axis={!isVertical ? "x" : "y"}
        position={draggablePosition}
        onStart={onDragStart}
        scale={canvasScale}
        onDrag={onDrag}
        onStop={onDragStop}
      >
        <div
          className="absolute rounded-sm border-2 border-rose-600 bg-white"
          style={{
            zIndex: 100,
            cursor: marginBarCursor,
            ...marginBarStyles,
          }}
        />
      </Draggable>
    </div>
  );
};

function useDynamicDragValues(initialValue: number | string, bounds: Bounds) {
  const element = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );
  const draftParentComponent = useEditorSelector(selectDraftParentComponent);
  const getAttribute = useEditorSelector(selectGetAttribute);
  const [currentValue, setCurrentValue] = React.useState(
    parseFloat(initialValue),
  );
  const componentMapping = useEditorSelector(selectComponentMapping);
  const [lastSignedValue, setLastSignedValue] = React.useState(initialValue);
  const canvas = useEditorSelector(selectActiveCanvas);

  const {
    parentHasDefinedWidth,
    parentHasDefinedHeight,
    parentContentWidth,
    parentContentHeight,
    componentHorizontalSpace,
    componentVerticalSpace,
    // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  } = React.useMemo(() => {
    const draftParentComponentNode = getEditorComponentNode({
      canvas,
      componentId: draftParentComponent?.id,
    });

    const { parentContentWidth, parentContentHeight } =
      getParentContentHeightWidth(draftParentComponentNode);

    const parentFlexDirection = draftParentComponent
      ? getAttribute(draftParentComponent, "style.flexDirection").value
      : null;

    const parentHasDefinedWidth = componentHasDefinedWidth(
      draftParentComponent,
      getAttribute,
      componentMapping,
    );
    const parentHasDefinedHeight = componentHasDefinedHeight(
      draftParentComponent,
      getAttribute,
      componentMapping,
    );

    const { componentHorizontalSpace, componentVerticalSpace } =
      getComponentSpace(
        canvas,
        element?.id ?? null,
        draftComponentNode ?? undefined,
        draftParentComponent,
        parentFlexDirection,
      );
    return {
      parentHasDefinedWidth,
      parentHasDefinedHeight,
      parentContentWidth,
      parentContentHeight,
      componentHorizontalSpace: componentHorizontalSpace ?? 0,
      componentVerticalSpace: componentVerticalSpace ?? 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, draftComponentNode, draftParentComponent, bounds, getAttribute]);

  const valueBeforeDrag = React.useRef({
    horizontalAvailableSpace: parentContentWidth - componentHorizontalSpace,
    verticalAvailableSpace: parentContentHeight - componentVerticalSpace,
    value: 0,
    initialWidth: bounds.width,
    initialHeight: bounds.height,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    valueBeforeDrag.current.horizontalAvailableSpace =
      parentContentWidth - componentHorizontalSpace;
    valueBeforeDrag.current.verticalAvailableSpace =
      parentContentHeight - componentVerticalSpace;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, draftComponentNode, draftParentComponent]);

  return {
    valueBeforeDrag,
    parentHasDefinedWidth,
    parentHasDefinedHeight,
    parentContentWidth,
    parentContentHeight,
    componentHorizontalSpace,
    componentVerticalSpace,
    currentValue,
    setCurrentValue,
    lastSignedValue,
    setLastSignedValue,
  };
}

function getModifiedOrientation(orientation: BoxSide): WidthOrHeight {
  return isVerticalSide(orientation) ? "height" : "width";
}

function isComponentVerticalStack(
  component: Component | null,
  getAttribute: GetAttributeFunction,
) {
  if (!component) {
    return true;
  }
  const flexDirection = getAttribute(component, "style.flexDirection", {
    defaultValue: styleAttributeToEditorData.flexDirection.defaultValue,
  }).value;

  return flexDirection === "column";
}

function getMarginArrowIndicatorProps(
  orientation: WidthOrHeight,
  boxSizes: {
    paddings: Edges;
    margins: Edges;
    bounds: NumericBounds;
    borders: Edges;
  },
  value: number,
) {
  return getOuterMarginArrowIndicatorProps(orientation, boxSizes, value);
}

function getMarginBarCursor(
  orientation: BoxSide,
  modifiedOrientation: WidthOrHeight,
  draftComponent: Component,
  parentComponent: Component | null,
  getAttribute: GetAttributeFunction,
  value: number,
  isParentVerticalStack: boolean,
) {
  const defaultCursor = isVerticalSide(orientation) ? "ns-resize" : "ew-resize";
  if (!draftComponent || !parentComponent) {
    return defaultCursor;
  }
  const alignSelf = getAlignSelf(draftComponent, parentComponent, getAttribute);
  const parentWidth =
    getAttribute(parentComponent, "style.width", null).value || "auto";
  const parentHeight =
    getAttribute(parentComponent, "style.height", null).value || "auto";

  if (modifiedOrientation === "width" && Math.abs(value) < 1) {
    return orientation === "left" ? "w-resize" : "e-resize";
  } else if (
    modifiedOrientation === "width" &&
    alignSelf === "stretch" &&
    parentWidth !== "auto" &&
    (modifiedOrientation !== "width" || isParentVerticalStack)
  ) {
    return orientation === "left" ? "e-resize" : "w-resize";
  } else if (modifiedOrientation === "height" && Math.abs(value) < 1) {
    return orientation === "top" ? "n-resize" : "s-resize";
  } else if (
    modifiedOrientation === "height" &&
    alignSelf === "stretch" &&
    parentHeight !== "auto"
  ) {
    return orientation === "top" ? "s-resize" : "n-resize";
  }

  return defaultCursor;
}

function inRangeIncludingStart(number: number, start: number, end: number) {
  return inRange(number, start, end) || number === start;
}

function getParentContentHeightWidth(
  draftParentComponentNode: HTMLElement | null,
) {
  const { parentBounds, parentPaddings, parentBorders } = getParentBoxSize(
    draftParentComponentNode,
  );
  const parentContentWidth =
    parseFloat(parentBounds.width) -
    parseFloat(parentPaddings.left) -
    parseFloat(parentPaddings.right) -
    parseFloat(parentBorders.left) -
    parseFloat(parentBorders.right);

  const parentContentHeight =
    parseFloat(parentBounds.height) -
    parseFloat(parentPaddings.top) -
    parseFloat(parentPaddings.bottom) -
    parseFloat(parentBorders.top) -
    parseFloat(parentBorders.bottom);

  return { parentContentWidth, parentContentHeight };
}

function getMarginBarStylesInner(orientation: BoxSide, boxSizes: BoxSizes) {
  const { paddings, bounds, borders } = boxSizes;
  const { indicatorShortSide, indicatorLongSide } = Dimensions.DragIndicators;
  const contentWidth =
    bounds.width -
    parseFloat(borders.left) -
    parseFloat(borders.right) -
    parseFloat(paddings.left) -
    parseFloat(paddings.right);

  const contentHeight =
    bounds.height -
    parseFloat(borders.top) -
    parseFloat(borders.bottom) -
    parseFloat(paddings.top) -
    parseFloat(paddings.bottom);

  let left: string;
  let top: string;
  let width: string = toFloorPixel(indicatorShortSide);
  let height: string = toFloorPixel(indicatorShortSide);

  switch (orientation) {
    case "left":
      left = `calc(${bounds.left}px - ${indicatorShortSide}px)`;
      top = `calc(${bounds.top}px + ${paddings.top} + ${borders.top} + ${contentHeight / 2}px - ${indicatorLongSide / 2}px)`;
      height = toFloorPixel(indicatorLongSide);
      break;

    case "right":
      left = `calc(${bounds.left}px + ${bounds.width}px)`;
      top = `calc(${bounds.top}px + ${paddings.top} + ${borders.top} + ${contentHeight / 2}px - ${indicatorLongSide / 2}px)`;
      height = toFloorPixel(indicatorLongSide);
      break;

    case "top":
      left = `calc(${bounds.left}px + ${paddings.left} + ${borders.left} + ${contentWidth / 2}px - ${indicatorLongSide / 2}px)`;
      top = `calc(${bounds.top}px - ${indicatorShortSide}px)`;
      width = toFloorPixel(indicatorLongSide);
      break;

    case "bottom":
      left = `calc(${bounds.left}px + ${paddings.left} + ${borders.left} + ${contentWidth / 2}px - ${indicatorLongSide / 2}px)`;
      top = `calc(${bounds.top}px + ${bounds.height}px)`;
      width = toFloorPixel(indicatorLongSide);
      break;

    default:
      throw new Error(`Unknown orientation: ${orientation}`);
  }

  return {
    left,
    top,
    width,
    height,
  };
}

function getMarginBarStyles(orientation: BoxSide, boxSizes: BoxSizes) {
  return getMarginBarStylesInner(orientation, boxSizes);
}

function getComponentSpace(
  canvas: EditorCanvas,
  draftElementId: string | null,
  draftComponentNode: HTMLElement | undefined,
  draftParentComponent: Component | null,
  parentFlexDirection: string,
) {
  if (!draftComponentNode || !draftElementId) {
    return { componentHorizontalSpace: null, componentVerticalSpace: null };
  }
  let componentHorizontalSpace: number =
    getComponentHorizontalSpace(draftComponentNode);
  let componentVerticalSpace: number =
    getComponentVerticalSpace(draftComponentNode);

  const siblingsWithSelf = draftParentComponent
    ? getChildren(draftParentComponent)
    : [];

  const hasParentChildrenArray = Array.isArray(siblingsWithSelf);

  if (hasParentChildrenArray && parentFlexDirection === "row") {
    componentHorizontalSpace =
      siblingsWithSelf.reduce(
        (sum, component) =>
          sum +
          getComponentHorizontalSpace(
            getEditorComponentNode({
              canvas,
              componentId: component.id,
            }),
          ),
        0,
      ) || 0;
  }
  if (hasParentChildrenArray && parentFlexDirection === "column") {
    componentVerticalSpace =
      siblingsWithSelf.reduce(
        (sum: number, component) =>
          sum +
          getComponentVerticalSpace(
            getEditorComponentNode({
              canvas,
              componentId: component.id,
            }),
          ),
        0,
      ) || 0;
  }
  return { componentHorizontalSpace, componentVerticalSpace };
}

// Note: outer margin indicators are those which stay on the outer edge of margin
function getOuterMarginArrowIndicatorProps(
  orientation: WidthOrHeight,
  boxSizes: BoxSizes,
  value: number,
) {
  const { bounds, borders, paddings } = boxSizes;
  const isVertical = isVerticalSide(orientation) || orientation === "height";

  let left: string;
  let top: string;
  const arrowLength: number = Math.max(0, Math.abs(value));

  const verticalPerpendicularSpace = `calc(${bounds.width}px - ${paddings.left} - ${paddings.right} - ${borders.left} - ${borders.right})`;
  const horizontalPerpendicularSpace = `calc(${bounds.height}px - ${paddings.top} - ${paddings.bottom} - ${borders.top} - ${borders.bottom})`;

  const perpendicularSpace = isVertical
    ? verticalPerpendicularSpace
    : horizontalPerpendicularSpace;

  switch (orientation) {
    case "height":
      left = `calc(${bounds.left}px + ${paddings.left} + ${borders.left})`;
      top = `calc(${bounds.top}px)`;
      break;

    case "width":
      left = `calc(${bounds.left}px)`;
      top = `calc(${bounds.top}px + ${paddings.top} + ${borders.top})`;
      break;

    default:
      throw new Error(`Unknown orientation: ${orientation}`);
  }

  return {
    left,
    top,
    perpendicularSpace,
    arrowLength,
  };
}

function toFloorPixel(value: number | string) {
  return `${Math.floor(parseFloat(value))}px`;
}

function getParentBoxSize(draftParentComponentNode: HTMLElement | null) {
  if (!draftParentComponentNode) {
    return {
      parentBounds: {
        width: 0,
        height: 0,
      },
      parentMargins: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      parentBorders: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      parentPaddings: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
    };
  }

  const parentStyle = getComputedStyle(draftParentComponentNode);

  return {
    parentBounds: {
      width: parentStyle.width,
      height: parentStyle.height,
    },
    parentMargins: {
      top: parentStyle.marginTop,
      left: parentStyle.marginLeft,
      bottom: parentStyle.marginBottom,
      right: parentStyle.marginRight,
    },
    parentBorders: {
      top: parentStyle.borderTopWidth,
      left: parentStyle.borderLeftWidth,
      bottom: parentStyle.borderBottomWidth,
      right: parentStyle.borderRightWidth,
    },
    parentPaddings: {
      top: parentStyle.paddingTop,
      left: parentStyle.paddingLeft,
      bottom: parentStyle.paddingBottom,
      right: parentStyle.paddingRight,
    },
  };
}

function getComponentHorizontalSpace(node: HTMLElement | null) {
  if (!node) {
    return 0;
  }
  const bounds = node.getBoundingClientRect();
  const style = getComputedStyle(node);
  return (
    parseFloat(bounds.width) +
    parseFloat(style.marginLeft) +
    parseFloat(style.marginRight)
  );
}

function getComponentVerticalSpace(node: HTMLElement | null) {
  if (!node) {
    return 0;
  }
  const bounds = node.getBoundingClientRect();
  const style = getComputedStyle(node);
  return (
    parseFloat(bounds.height) +
    parseFloat(style.marginTop) +
    parseFloat(style.marginBottom)
  );
}
