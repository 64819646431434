import type { ComponentTemplate } from "@editor/types/component-template";

import * as React from "react";
import { useState } from "react";

import Selectable from "@editor/components/common/designSystem/Selectable";
import useOnSubmitTemplateAction from "@editor/hooks/useOnSubmitTemplateAction";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import SelectableCard from "@replo/design-system/components/card/SelectableCard";
import EmptyState from "@replo/design-system/components/empty-state/EmptyState";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import { skipToken } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import { Building2, FolderPlus } from "lucide-react";
import { useParams } from "react-router-dom";

const ProjectSelection: React.FC<{
  selectedProjectId: string | null;
  setSelectedProjectId: (projectId: string | null) => void;
  workspaceId: string | null;
}> = ({ selectedProjectId, setSelectedProjectId, workspaceId }) => {
  const { data: projectsData, isLoading: isLoadingProjects } =
    trpc.project.listWithStats.useQuery(workspaceId ? { workspaceId } : {});

  if (isLoadingProjects) {
    return (
      <div className="flex flex-col overflow-y-auto max-h-[300px] gap-2 pr-2">
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} className="h-[72px] w-full rounded-lg" />
        ))}
      </div>
    );
  }

  if (!projectsData?.projects || projectsData.projects.length === 0) {
    return (
      <EmptyState
        title="No Projects Found"
        description={
          workspaceId
            ? "This workspace doesn't have any projects."
            : "You don't have any projects to add this template to."
        }
        icon={<FolderPlus />}
        variant="primary"
        size="sm"
      />
    );
  }

  return (
    <div className="flex flex-col overflow-y-auto max-h-[200px] w-full gap-2">
      {projectsData.projects.map((project) => (
        <div
          key={project.id}
          onClick={() => setSelectedProjectId(project.id)}
          className="w-full cursor-pointer"
        >
          <SelectableCard
            size="base"
            title={project.name ?? ""}
            description={`Edited ${formatDistanceToNow(project.lastEditedAt ?? project.createdAt)} ago`}
            layoutClassName="w-full"
            active={selectedProjectId === project.id}
          />
        </div>
      ))}
    </div>
  );
};

const WorkspaceSelector: React.FC<{
  selectedWorkspaceId: string | null;
  onWorkspaceSelect: (workspaceId: string) => void;
  isLoading?: boolean;
}> = ({ selectedWorkspaceId, onWorkspaceSelect, isLoading = false }) => {
  const { data, isPending } = trpc.workspace.getUserWorkspacesList.useQuery();
  const workspaces = React.useMemo(
    () => data?.workspaces ?? [],
    [data?.workspaces],
  );

  const workspaceOptions = React.useMemo(() => {
    return workspaces.map((workspace) => ({
      label: workspace.name,
      value: workspace.id,
    }));
  }, [workspaces]);

  if (isLoading || isPending) {
    return <Skeleton className="h-10 w-full rounded" />;
  }

  if (workspaces.length === 0) {
    return (
      <div className="text-sm text-muted py-2">
        No workspaces available. Please create a workspace first.
      </div>
    );
  }

  return (
    <Selectable
      placeholder="Select a workspace"
      options={workspaceOptions}
      value={selectedWorkspaceId}
      onSelect={onWorkspaceSelect}
      size="base"
      areOptionsSearchable
      triggerStartEnhancer={<Building2 size={16} className="text-muted" />}
      layoutClassName="w-full"
    />
  );
};

const SharedTemplateDetailsModalContent: React.FC<{
  componentTemplate?: ComponentTemplate;
  selectedProjectId: string | null;
  setSelectedProjectId: (projectId: string | null) => void;
  selectedWorkspaceId: string | null;
  setSelectedWorkspaceId: (workspaceId: string) => void;
  isLoading?: boolean;
}> = ({
  componentTemplate,
  selectedProjectId,
  setSelectedProjectId,
  selectedWorkspaceId,
  setSelectedWorkspaceId,
  isLoading = false,
}) => {
  if (isLoading) {
    return (
      <div className="flex w-full h-full gap-5">
        <div className="flex flex-col w-1/2 h-full border-0.5 rounded shadow-lg">
          <div className="relative px-2 pt-3 pb-0 bg-neutral-soft group gap-2 rounded-t">
            <div className="w-full aspect-video overflow-hidden">
              <Skeleton className="w-full h-full rounded" />
            </div>
          </div>
          <div className="bg-white border-t border-0.5 rounded-b p-3">
            <Skeleton className="w-3/4 h-6 rounded" />
          </div>
        </div>
        <div className="flex flex-col w-1/2 h-full gap-4">
          <Skeleton className="h-10 w-full rounded" />
          <div className="flex flex-col overflow-y-auto max-h-[300px] gap-2 pr-2">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="h-[72px] w-full rounded-lg" />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (!componentTemplate) {
    return (
      <EmptyState
        title="Template Not Found"
        description="The template you're looking for could not be found."
        icon={<FolderPlus />}
        variant="primary"
        size="sm"
      />
    );
  }

  return (
    <div className="flex w-full h-full gap-5">
      {/* Left side - Template preview */}
      <div className="flex flex-col w-1/2 h-full border-0.5 rounded shadow-lg">
        <div className="relative px-2 pt-3 pb-0 bg-neutral-soft group gap-2 rounded-t">
          <div className="w-full aspect-video overflow-hidden">
            <img
              src={componentTemplate.desktopPreviewUrl}
              className="w-full object-contain object-top transition-opacity duration-300"
              alt="Template Preview"
            />
          </div>
        </div>

        <div className="bg-white border-t border-0.5 rounded-b p-3 typ-header-small">
          {componentTemplate.name}
        </div>
      </div>

      {/* Right side - workspace and project selection */}
      <div className="flex flex-col w-1/2 h-full gap-2">
        <WorkspaceSelector
          selectedWorkspaceId={selectedWorkspaceId}
          onWorkspaceSelect={setSelectedWorkspaceId}
        />

        <ProjectSelection
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={setSelectedProjectId}
          workspaceId={selectedWorkspaceId}
        />
      </div>
    </div>
  );
};

export function SharedTemplateDetailsModal() {
  const { templateId } = useParams<{ templateId: string }>();
  const [isOpen, setIsOpen] = useState(true);
  const [selectedProjectId, setSelectedProjectId] = React.useState<
    string | null
  >(null);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = React.useState<
    string | null
  >(null);
  const { onSubmit: onSubmitTemplateAction, isLoadingSubmitTemplate } =
    useOnSubmitTemplateAction();

  const { data: componentTemplate, isLoading: isLoadingComponentTemplate } =
    trpc.componentTemplates.get.useQuery(
      templateId
        ? {
            templateId,
          }
        : skipToken,
    );

  const handleWorkspaceSelect = (workspaceId: string) => {
    setSelectedWorkspaceId(workspaceId);
    setSelectedProjectId(null);
  };

  const onSubmit = async () => {
    if (componentTemplate && selectedProjectId) {
      await onSubmitTemplateAction({
        template: componentTemplate,
        elementType: "page",
        projectId: selectedProjectId,
      });
    }
  };

  return (
    <Modal
      title="Where will you use this template?"
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      size="lg"
      footer={
        <div className="flex gap-2">
          <Button
            variant="secondary"
            size="base"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            size="base"
            disabled={!selectedProjectId}
            isLoading={isLoadingSubmitTemplate}
            onClick={() => void onSubmit()}
          >
            Add to Project
          </Button>
        </div>
      }
    >
      <SharedTemplateDetailsModalContent
        componentTemplate={componentTemplate ?? undefined}
        selectedProjectId={selectedProjectId}
        setSelectedProjectId={setSelectedProjectId}
        selectedWorkspaceId={selectedWorkspaceId}
        setSelectedWorkspaceId={handleWorkspaceSelect}
        isLoading={isLoadingComponentTemplate}
      />
    </Modal>
  );
}
