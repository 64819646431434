import type { View } from "@editor/components/left-bar/insert-pane/InsertPane";

import * as React from "react";

import {
  COMPONENT_CATEGORIES,
  SECTION_CATEGORIES,
} from "@editor/components/left-bar/insert-pane/TemplateCategories";
import TemplateCategoryMenuCard from "@editor/components/left-bar/insert-pane/TemplateCategoryMenuCard";
import TemplatesList from "@editor/components/left-bar/insert-pane/TemplateList";
import useFigmaPluginPaste from "@editor/hooks/figmaToReplo/useFigmaPluginPaste";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useDynamicCSSOverflow from "@editor/hooks/useDynamicCSSOverflow";
import { useFindParentForPaste } from "@editor/hooks/useFindParentForPaste";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import twMerge from "@replo/design-system/utils/twMerge";

interface TemplatesMenuProps {
  selectedView: View;
  searchString: string;
}

const FigmaCard = () => {
  const findParentForPaste = useFindParentForPaste();
  const { pasteFromFigmaOrToast } = useFigmaPluginPaste({
    findParentForPaste,
  });

  return (
    <button
      className="text-xs font-semibold flex w-full hover:bg-hover justify-between p-2 items-center rounded"
      onClick={() => void pasteFromFigmaOrToast()}
    >
      <div className="flex items-center gap-2">
        <div className="bg-subtle border-[0.5px] rounded p-2">
          <img
            className="h-[18px] w-[18px]"
            src="/images/figma/figma-logo.svg"
            alt="Figma logo"
          />
        </div>
        Import from Figma
      </div>
    </button>
  );
};

const TemplatesMenu: React.FC<TemplatesMenuProps> = ({
  selectedView,
  searchString,
}) => {
  const { currentDragType } = useCurrentDragType();
  const [openedMenu, setOpenedMenu] = React.useState("");
  // NOTE (Fran 2024-07-09): We must prevent the user from opening different categories when moving
  // the mouse to the right diagonally. This is to prevent the user from accidentally opening a category
  // when trying to go to the menu content to drag a component.
  const [allowOpen, setAllowOpen] = React.useState(true);
  const { elementRef, overflowClassname } = useDynamicCSSOverflow();

  const handleOpenMenu = React.useCallback(
    (value: string) => {
      // NOTE (Fran 2024-07-09): We should open the menu when is allowed to open. This is to prevent the user
      // from accidentally opening a category when trying to go to the menu content to drag a component.
      if (allowOpen) {
        setOpenedMenu(value);
      }
    },
    [allowOpen],
  );

  // NOTE (Sebas, 2024-06-26): We need to hide the component template pane when
  // dragging a new component.
  React.useEffect(() => {
    if (currentDragType) {
      setAllowOpen(false);
    } else {
      setOpenedMenu("");
      setAllowOpen(true);
    }
  }, [currentDragType]);

  // NOTE (Ben O., 2025-03-04): This code prevents users from opening different
  // categories when moving the mouse to the right diagonally while going to
  // the menu content.
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const clearTimeoutRef = React.useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);
  const handleMouseMove = React.useCallback(
    (e: React.MouseEvent) => {
      // Don't allow changes to allowOpen when dragging
      if (currentDragType) {
        return;
      }

      if (e.movementX < 3) {
        clearTimeoutRef();
        setAllowOpen(true);
      } else if (timeoutRef.current === null) {
        setAllowOpen(false);
        timeoutRef.current = setTimeout(() => {
          // Only set allowOpen to true if we're not dragging
          if (!currentDragType) {
            setAllowOpen(true);
          }
          timeoutRef.current = null;
        }, 1000);
      }
    },
    [clearTimeoutRef, currentDragType],
  );

  React.useEffect(() => {
    return clearTimeoutRef;
  }, [clearTimeoutRef]);

  if (searchString) {
    return (
      <div
        className={twMerge(
          "flex flex-col no-scrollbar bg-white pb-24 h-full absolute pr-3",
          overflowClassname,
        )}
        ref={elementRef}
        id="insert-pane-search-wrapper"
      >
        <TemplatesList
          searchString={searchString}
          selectedView={selectedView}
        />
      </div>
    );
  }

  return (
    <>
      <NavigationMenu.Root
        value={openedMenu}
        onValueChange={handleOpenMenu}
        onMouseMove={handleMouseMove}
      >
        <NavigationMenu.List className="flex flex-col mb-8">
          {selectedView === "components"
            ? COMPONENT_CATEGORIES.map((category) => (
                <TemplateCategoryMenuCard
                  key={category.value}
                  selectedView={selectedView}
                  category={category}
                  openedMenu={openedMenu}
                  hideMenuContent={Boolean(currentDragType)}
                  overflowClassname={overflowClassname}
                  elementRef={elementRef}
                />
              ))
            : SECTION_CATEGORIES.map((category) => (
                <TemplateCategoryMenuCard
                  key={category.value}
                  selectedView={selectedView}
                  category={category}
                  openedMenu={openedMenu}
                  hideMenuContent={Boolean(currentDragType)}
                  overflowClassname={overflowClassname}
                  elementRef={elementRef}
                />
              ))}
          {selectedView === "components" && <FigmaCard />}
        </NavigationMenu.List>

        <NavigationMenu.Viewport />
      </NavigationMenu.Root>
    </>
  );
};

export default TemplatesMenu;
