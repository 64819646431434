import type { ComponentTemplate } from "@editor/types/component-template";

import { getCanBeAddedFunction } from "@editor/utils/template";

export const productQuantitySelection: ComponentTemplate = {
  id: "a2836a3a-1df2-48e3-beb5-b9014711ed66",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Basic/Product Quantity Selection",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "selection-list.svg",
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Quantity Selection can only be added to product components.",
  ),
  template: {
    id: "b8700107-4938-4ea8-a8a0-700e689d20f1",
    name: "Quantity Selection",
    type: "selectionList",
    props: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        __width: "50%",
        __flexGap: "15px",
      },
      _accessibilitySelection: true,
    },
    children: [
      {
        id: "7143a81e-2f6e-4fd5-8252-00870b99f7d2",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            backgroundColor: "#FFFFFFFF",
            borderTopColor: "#000000",
            borderRightColor: "#000000",
            borderBottomColor: "#000000",
            borderLeftColor: "#000000",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
          },
          onClick: [
            {
              id: "018fadc0-28d6-40ee-96ff-a0c0da325afa",
              type: "setProductQuantity",
            },
          ],
        },
        children: [
          {
            id: "5dbea3e2-dab2-4c9a-b3df-813d708f9a71",
            type: "text",
            props: {
              text: "<p>1</p>",
              style: {
                flexGrow: 1,
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "2px",
              },
            },
          },
        ],
        variants: [
          {
            id: "28699362-d76a-420a-9b63-4284c031779b",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fde26c5f-3ebe-4607-965b-f0e4aa350826",
                  field: "state.selectionList.isItemSelected",
                },
              ],
            },
            name: "Selected List Item",
            id: "e92e225c-b7bf-4cf1-ab1a-adc64f1a9349",
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "e92e225c-b7bf-4cf1-ab1a-adc64f1a9349": {
            componentOverrides: {
              "7143a81e-2f6e-4fd5-8252-00870b99f7d2": {
                props: {
                  style: {
                    backgroundColor: "#000000FF",
                    borderTopWidth: "0px",
                    borderRightWidth: "0px",
                    borderBottomWidth: "0px",
                    borderLeftWidth: "0px",
                    borderWidth: null,
                    borderTopColor: null,
                    borderRightColor: null,
                    borderBottomColor: null,
                    borderLeftColor: null,
                    borderColor: null,
                    borderTopStyle: "none",
                    borderRightStyle: "none",
                    borderBottomStyle: "none",
                    borderLeftStyle: "none",
                    borderStyle: null,
                    color: "#FFFFFFFF",
                  },
                },
              },
            },
          },
        },
        name: "Quantity 1",
      },
      {
        id: "6c1e1480-2412-4833-9974-e6cd5ad6da10",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            backgroundColor: "#FFFFFFFF",
            borderTopColor: "#000000",
            borderRightColor: "#000000",
            borderBottomColor: "#000000",
            borderLeftColor: "#000000",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
          },
          onClick: [
            {
              id: "6ac21170-f1c9-4ed4-8655-9d17d4b1c267",
              type: "setProductQuantity",
              value: 2,
            },
          ],
        },
        children: [
          {
            id: "32d6b465-70de-4727-8d6d-785fd7c8b1bf",
            type: "text",
            props: {
              text: "<p>2</p>",
              style: {
                flexGrow: 1,
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "2px",
              },
            },
          },
        ],
        variants: [
          {
            id: "327cad61-0895-4543-9c03-ce259f61a851",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fde26c5f-3ebe-4607-965b-f0e4aa350826",
                  field: "state.selectionList.isItemSelected",
                },
              ],
            },
            name: "Selected List Item",
            id: "ce668a28-b07c-495b-9133-6bb7e192d1e2",
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "ce668a28-b07c-495b-9133-6bb7e192d1e2": {
            componentOverrides: {
              "6c1e1480-2412-4833-9974-e6cd5ad6da10": {
                props: {
                  style: {
                    backgroundColor: "#000000FF",
                    borderTopWidth: "0px",
                    borderRightWidth: "0px",
                    borderBottomWidth: "0px",
                    borderLeftWidth: "0px",
                    borderWidth: null,
                    borderTopColor: null,
                    borderRightColor: null,
                    borderBottomColor: null,
                    borderLeftColor: null,
                    borderColor: null,
                    borderTopStyle: "none",
                    borderRightStyle: "none",
                    borderBottomStyle: "none",
                    borderLeftStyle: "none",
                    borderStyle: null,
                    color: "#FFFFFFFF",
                  },
                },
              },
            },
          },
        },
        name: "Quantity 2",
      },
      {
        id: "20ab70d3-2a02-4863-8e81-ce7943158c35",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            backgroundColor: "#FFFFFFFF",
            borderTopColor: "#000000",
            borderRightColor: "#000000",
            borderBottomColor: "#000000",
            borderLeftColor: "#000000",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
          },
          onClick: [
            {
              id: "582ebf83-2862-4ae2-82ac-b26bbb5368ac",
              type: "setProductQuantity",
              value: 5,
            },
          ],
        },
        children: [
          {
            id: "efd40b8a-6908-4204-b5ea-56991d8a1158",
            type: "text",
            props: {
              text: "<p>5</p>",
              style: {
                flexGrow: 1,
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "2px",
              },
            },
          },
        ],
        variants: [
          {
            id: "33c67c3c-9417-429b-9dd4-db8b2de6aea6",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fde26c5f-3ebe-4607-965b-f0e4aa350826",
                  field: "state.selectionList.isItemSelected",
                },
              ],
            },
            name: "Selected List Item",
            id: "1add502a-74ba-4867-aabc-421eec6e9a63",
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "1add502a-74ba-4867-aabc-421eec6e9a63": {
            componentOverrides: {
              "20ab70d3-2a02-4863-8e81-ce7943158c35": {
                props: {
                  style: {
                    backgroundColor: "#000000FF",
                    borderTopWidth: "0px",
                    borderRightWidth: "0px",
                    borderBottomWidth: "0px",
                    borderLeftWidth: "0px",
                    borderWidth: null,
                    borderTopColor: null,
                    borderRightColor: null,
                    borderBottomColor: null,
                    borderLeftColor: null,
                    borderColor: null,
                    borderTopStyle: "none",
                    borderRightStyle: "none",
                    borderBottomStyle: "none",
                    borderLeftStyle: "none",
                    borderStyle: null,
                    color: "#FFFFFFFF",
                  },
                },
              },
            },
          },
        },
        name: "Quantity 5",
      },
    ],
  },
};
