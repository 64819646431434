import { addBreadcrumb } from "@sentry/react";

type BreadcrumbCategory = "general" | "error";

export const breadcrumb = (
  message: string,
  category: BreadcrumbCategory = "general",
  data?: Record<string, any>,
) => {
  addBreadcrumb({ category, level: "info", message, data });
};
