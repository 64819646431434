import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";

/**
 * Simple way to get create a default slug that is probably unique,
 * and reasonable to the user.
 */
export function positiveIntToCapitalLetter(i: number): string {
  return String.fromCharCode(96 + Math.min(Math.max(i, 1), 26));
}

export function generateExperimentVariation({
  slug,
  target,
  allocationPercent = 50,
}: {
  slug: string;
  target: string | null;
  allocationPercent?: number;
}): Variation {
  return {
    id: uuid(),
    slug,
    target,
    allocationPercent,
  };
}

export const NO_LINK_TEXT = "(no link)";

/**
 * Provides all experiment API interactions as a single hook, allowing us to more easily refetch
 * the list and achieve "cache-like" behavior across uses of the query while avoiding indirection.
 *
 * TODO (Max, 2024-10-23, REPL-14267): Move this to @editor/utils/experiments.ts when cleaning up (
 * and delete other functions in this file).
 *
 * @author Ben 2024-04-26
 */
export function useExperimentApi({
  workspaceId,
}: {
  workspaceId: string | undefined;
}) {
  const trpcUtils = trpc.useUtils();
  const list = trpc.experiment.list.useQuery(
    workspaceId
      ? {
          workspaceId,
        }
      : skipToken,
  );
  const links = trpc.analyticsLink.list.useQuery(
    workspaceId
      ? {
          workspaceId,
        }
      : skipToken,
  );
  const onSuccess = async () => {
    void trpcUtils.experiment.list.invalidate({ workspaceId });
    void trpcUtils.analyticsLink.list.invalidate({ workspaceId });
  };
  const create = trpc.experiment.create.useMutation({
    onSuccess,
  });
  const update = trpc.experiment.update.useMutation({
    onSuccess,
  });
  const remove = trpc.experiment.remove.useMutation({
    onSuccess,
  });
  const complete = trpc.experiment.complete.useMutation({
    onSuccess,
  });
  return { list, links, update, remove, create, complete };
}
