import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { useInterval } from "replo-utils/react/use-interval";

import useScriptCallbackEvent from "../../../shared/hooks/useScriptCallbackEvent";
import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";

const RechargeSubscriptionWidget: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const isWidgetLoaded = React.useRef(false);
  const product = useProductFromProps(component.props, context);
  const productId = product?.id;
  const shouldHavePlaceHolder = isEditorApp && !globalWindow?.ReChargeWidget;

  /**
   * Remove all HTML from this container which might have been previously added
   * by recharge. This is necessary since recharge doesn't provide a way to
   * "re-create" the widget, so if we call createWidget twice, it will just add
   * a second widget. When we need to update the widget, like when products
   * change in the editor, we use this to remove all pre-existing html and let
   * recharge fill it in again.
   */
  const removeExistingRechargeHtml = React.useCallback(() => {
    globalWindow?.document
      .querySelector(`[data-rid='${component.id}'] .rc_container_wrapper`)
      ?.remove();
  }, [globalWindow, component.id]);

  const createRechargeWidgetIfNeeded = React.useCallback(() => {
    if (!product?.id || isWidgetLoaded.current) {
      return;
    }

    removeExistingRechargeHtml();

    globalWindow?.ReChargeWidget?.createWidget?.({
      productId: Number.parseInt(String(product.id), 10),
      injectionParent: `[data-rid='${component.id}']`,
      selectors: {
        /**
         * Note (Noah, 2023-06-15): In order for recharge to correctly update when
         * variants are changed in Replo, we have to tell it what inputs to look for.
         * This selector corresponds to the hidden input we create below, which
         * changes any time the selected variant changes.
         */
        variants: [
          `[data-rid="${component.id}"] [name="replo-recharge-widget-variant-id"]`,
        ],
        globalEventTriggers: "variant:changed",
      },
    });

    isWidgetLoaded.current = true;
  }, [globalWindow, product?.id, component.id, removeExistingRechargeHtml]);

  // Reset the widget if the product changes
  const productIdRef = React.useRef(productId);
  React.useEffect(() => {
    const previousProductId = productIdRef.current;
    productIdRef.current = productId;
    if (productId && productId !== previousProductId) {
      removeExistingRechargeHtml();
      isWidgetLoaded.current = false;
    }
  }, [productId, removeExistingRechargeHtml]);

  useScriptCallbackEvent(
    {
      run: createRechargeWidgetIfNeeded,
      isReady: () => isEditorApp || globalWindow?.ReChargeWidget?.createWidget,
      identifier: "recharge",
      componentId: component.id,
      scriptSourceRegex: /static\.rechargecdn\.com/,
    },
    [globalWindow, createRechargeWidgetIfNeeded, component.id, isEditorApp],
  );

  useRechargeFallback(
    !isWidgetLoaded.current && !isEditorApp,
    createRechargeWidgetIfNeeded,
  );

  /**
   * Note (Noah, 2023-06-15, USE-230): This is a little hacky, but in order to
   * get recharge to update the widget when the selected variant (which might be
   * set via Replo means, e.g. with a variant list) changes, we need to hook
   * into one of Recharge's update mechanisms. There's no documentation on this
   * that I could find on Recharge's docs, but if you read the Recharge source
   * code, they listen for all sorts of "standard" events for various themes.
   *
   * One of those is the "variant:changed" event, so we can dispatch that event
   * ourselves to notify recharge that the variant has been updated, every time
   * the variant changes.
   *
   * Note this works in concert with the `selectors.variants` which we pass to
   * createRechargeWidget above - when recharge receives the "variant:changed"
   * event, it will query the variants selector (which in our case is a hidden
   * input) to determine the new variant. Or at least that seems to be how it
   * works.
   *
   * Also, apparently the `selectors.variantTriggers` option is supposed to be able
   * to listen for changes to the variant input in order to be able to detect changes
   * with this event, but I tried that in a couple different configurations and
   * I couldn't get it to work. Might be a slightly better way to do this if we
   * could get a better understanding of Recharge's API.
   */
  const selectedVariantId = context.state.product?.selectedVariant?.id;
  React.useEffect(() => {
    const event = new CustomEvent("variant:changed", {
      detail: { variant: { id: selectedVariantId } },
      bubbles: true,
    });
    const selector = `[data-rid="${component.id}"]`;
    const node = globalWindow?.document.querySelector(selector);
    node?.dispatchEvent(event);
  }, [component.id, globalWindow, selectedVariantId]);

  return !isEditorApp ? (
    <div {...componentAttributes}>
      <input
        type="hidden"
        name="replo-recharge-widget-variant-id"
        data-productid={product?.id}
        value={selectedVariantId ?? undefined}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: String(component.props._css ?? ""),
        }}
      />
      {shouldHavePlaceHolder && (
        <RenderComponentPlaceholder title="The Recharge subscription widget should appear here. Please ensure you have Recharge installed, or reach out to support@replo.app" />
      )}
    </div>
  ) : (
    <div
      {...componentAttributes}
      className={twMerge(componentAttributes.className, "w-full")}
    >
      <RenderComponentPlaceholder
        type="productOptions"
        title="Recharge Subscription Widget"
      />
    </div>
  );
};

/**
 * This hook takes care of starting up the Recharge widget in the weird
 * edge case where their script is loaded yet it does not trigger the load
 * eventlistener callback that we set on AlchemyElement.
 */
function useRechargeFallback(shouldKeepPolling = true, callback: () => void) {
  const [pollingInterval, setPollingInterval] = React.useState<number | null>(
    500,
  );
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  useInterval(() => {
    if (shouldKeepPolling) {
      if (globalWindow?.ReChargeWidget) {
        callback();
        setPollingInterval(null);
      }
    } else {
      setPollingInterval(null);
    }
  }, pollingInterval);
}

export default RechargeSubscriptionWidget;
