import type { EditorRootState } from "@editor/store";
import type { CoreState } from "@editor/types/core-state";
import type {
  GetAttributeDefaults,
  GetAttributeDependencies,
  GetAttributeFilters,
  GetAttributeFunction,
  ResolvedValue,
} from "@editor/types/get-attribute-function";
import type {
  ComponentDataMapping,
  ComponentMapping,
} from "replo-runtime/shared/Component";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";
import type { ReploState, ReploSymbol } from "schemas/generated/symbol";

import {
  selectComponentDataMapping,
  selectComponentIdToDraftVariantId,
  selectComponentMapping,
  selectDraftComponentId,
  selectSymbolsMapping,
} from "@editor/reducers/core-reducer";
import { emptyIfNullish } from "@utils/array";
import { findComponentByIdInComponent } from "@utils/component";
import { paramsStringToMapping } from "@utils/url";

import cloneDeep from "lodash-es/cloneDeep";
import get from "lodash-es/get";
import last from "lodash-es/last";
import merge from "lodash-es/merge";
import replace from "lodash-es/replace";
import memoize from "mem";
import {
  applySymbolOverrides,
  applyVariantOverrides,
  getSymbol,
  resolveVariants,
} from "replo-runtime/shared/symbol";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import {
  builtInSymbolDirectory,
  getBuiltInSymbol,
  hasBuiltInSymbol,
} from "replo-runtime/store/builtInSymbolDirectory";
import { filterNulls } from "replo-utils/lib/array";
import { mergeReplacingArrays } from "replo-utils/lib/misc";

export const VALUE_TO_ALIGNMENT_OPTION: Record<string, string> = {
  start: "flex-start",
  center: "center",
  end: "flex-end",
  "flex-start": "flex-start",
  "flex-end": "flex-end",
  "space-between": "space-between",
};

/**
 * Returns a function which can be called to get various information about a
 * component, including defaults, fallbacks for canvas sizes, the current state
 * in the editor, etc.
 *
 * The returned function takes arguments:
 * - component: The component to query for info on
 * - attribute: The type of thing to query, e.g. "style.flexDirection"
 * - defaults: Default info to return if not found
 * - filters: Config for filtering out certain parts of the component data
 *   model, e.g. if you want to only look at a specific state value
 *
 * Supported attributes:
 * - "props.XYZ": Returns a literal get() of the component's props. Does not do
 *   any dynamic resolution on canvas sizes
 * - "style.XYZ": Returns the current style value from props.styles (or
 *   styles@sm if mobile, etc: takes care of canvas/state/symbol overrides)
 */
const getAttributeRetriever = (
  componentDataMapping: ComponentDataMapping,
  componentMapping: ComponentMapping,
  draftSymbolInstanceId: string | null,
  draftComponentId: string | null,
  symbolMapping: Record<string, ReploSymbol>,
  componentIdToDraftVariantId: Record<string, string | undefined>,
  canvas: EditorCanvas,
): GetAttributeFunction => {
  const __getAttribute = (
    component: Component | null,
    attribute: string,
    defaults?: GetAttributeDefaults | null,
    filters?: GetAttributeFilters | null,
  ): ResolvedValue => {
    if (attribute.startsWith("style.")) {
      return getStyleAttribute(
        componentDataMapping,
        componentMapping,
        component?.id ?? null,
        draftSymbolInstanceId,
        draftComponentId,
        symbolMapping,
        componentIdToDraftVariantId,
        canvas,
        attribute,
        defaults ?? {},
        filters ?? null,
      );
    } else if (attribute.startsWith("props.")) {
      return getProp(
        componentDataMapping,
        componentMapping,
        component?.id ?? null,
        draftSymbolInstanceId,
        draftComponentId,
        symbolMapping,
        componentIdToDraftVariantId,
        attribute,
        defaults ?? {},
        filters ?? null,
      );
    }
    return { value: null };
  };

  return __getAttribute;
};

function getAttributeRetrieverFromCoreState(
  state: CoreState,
  activeCanvas: EditorCanvas,
) {
  const rootState: EditorRootState = { core: state } as EditorRootState;
  return getAttributeRetriever(
    selectComponentDataMapping(rootState),
    selectComponentMapping(rootState),
    state.elements.draftSymbolInstanceId,
    selectDraftComponentId(rootState),
    selectSymbolsMapping(rootState),
    selectComponentIdToDraftVariantId(rootState),
    activeCanvas,
  );
}

export { getAttributeRetrieverFromCoreState as getAttributeRetriever };

/**
 * This is a function which can be memoized - the return value is a getAttribute
 * function which can be called to get a style attribute of a Replo component.
 * Note: the OUTER function is the one intended for memoization.
 *
 * Memoization is important for this function since it does a fair amount of
 * work (calling resolveComponent, which resolves symbol values, canvas
 * overrides, etc) and it's called many, many times throughout the editor.
 */
const memoizableGetAttributeRetriever = (
  component: Component | null,
  dependencies: GetAttributeDependencies,
  // Note (Noah, 2022-03-16): These parameters are not actually used in
  // the function body, but they need to be part of the memoization key so
  // we need to define them here. These parameters need to be part of the
  // memoization key because we need to use a new getAttribute when the
  // element changes, but we don't want to serialize the whole dependencies.
  // element as part of the key.
  _elementObjectId: number | null,
) => {
  // Note (Noah, 2022-02-26): Want to see if getAttribute isn't getting
  // memoized effectively somewhere? Log here with the component id and
  // make sure you're not seeing duplicate ids

  const { component: resolvedComponent, mergedStyles } = resolveComponent(
    dependencies.componentDataMapping,
    dependencies.componentMapping,
    component?.id ?? null,
    dependencies.draftSymbolInstanceId,
    dependencies.draftComponentId,
    dependencies.symbolMapping,
    dependencies.componentIdToDraftVariantId,
    dependencies.activeCanvas,
    null,
  );

  return (attribute: string, defaults?: GetAttributeDefaults | null) => {
    if (!component) {
      return { value: defaults?.defaultValue };
    }
    const isStyleAttribute = attribute.startsWith("style.");
    if (isStyleAttribute) {
      attribute = `props.${attribute}`;
    }
    return {
      value:
        get(
          isStyleAttribute
            ? { props: { style: mergedStyles } }
            : resolvedComponent,
          attribute,
          defaults?.defaultValue,
        ) ?? null,
    };
  };
};

/**
 * Return a memoized version of getAttributeRetriever for a specific component. This
 * will pre-process the component on first call and generate a function which can be
 * called to get that component's attributes, then re-use that generated function on
 * subsequent calls.
 */
export const memoizedGetAttributeRetriever = memoize(
  memoizableGetAttributeRetriever,
  {
    cacheKey: (args) => {
      const [component, dependencies, elementObjectId] = args;

      // Note (Noah, 2022-02-28): You might think we'd actually want to hash this
      // object, but JSON.stringify is significantly faster than a hash function
      // at least, it was faster than the sha1 implementation from object-hash I
      // tried, by a factor of 100x (~0.1ms for sha1 on my 2021 M1 MBP, ~0.001ms for
      // JSON.stringify). At the time of writing, using a hash slowed down the editor
      // to the point where animations would stutter, but JSON.stringify had smooth
      // animations. It's important for this to be fast since it's run on every access
      // to the memoized getAttribute.
      return JSON.stringify({
        componentId: component?.id,
        mediaSize: dependencies.activeCanvas,
        componentIdToDraftVariantId: dependencies.componentIdToDraftVariantId,
        draftSymbolInstanceId: dependencies.draftSymbolInstanceId,
        // Note (Noah, 2022-03-07, REPL-1122): A unique identifier of the object
        // in-memory is necessary here because we want the function to cache-miss
        // when the element changes after it has been updated by an applyComponentAction.
        // If we didn't have this, we would apply an update locally and thus create a
        // new element, but there wouldn't be a cache miss on this memoization until
        // the saveVersion was updated after a successful request to the server,
        // resulting in an "old value" being shown for a short time. With this object
        // id we're guaranteed to be accessing the "newest" version of the local draft
        // element with this memoized getAttribute function.
        elementObjectId,
      });
    },
    // Note (Noah, 2022-02-24): Avoid huge memory bloat by expiring the cache at
    // least every 1 minute (this still gets us perf wins but we just periodically
    // might get a cache miss which is only like 1ms per component)
    maxAge: 1000 * 60 * 1,
  },
);

const resolveComponentForSymbolsAndVariants = (
  componentDataMapping: ComponentDataMapping,
  componentMapping: ComponentMapping,
  componentId: string | null,
  draftSymbolInstanceId: string | null,
  draftComponentId: string | null,
  symbolMapping: Record<string, ReploSymbol>,
  componentIdToDraftVariantId: Record<string, string | undefined>,
  specificVariantIdIfRequested: string | null,
): Component | null => {
  if (!componentId) {
    return null;
  }
  const component = componentMapping[componentId]?.component;
  if (!component) {
    return null;
  }

  // Find any parent that is a symbol
  const symbolAncestor = draftSymbolInstanceId
    ? componentMapping[draftSymbolInstanceId]?.component
    : null;

  const ancestorOrSelfWithVariantsId =
    componentDataMapping[componentId]?.ancestorOrSelfWithVariantsId;
  const ancestorWithVariants = getFromRecordOrNull(
    componentMapping,
    ancestorOrSelfWithVariantsId,
  )?.component;

  const needsOverrides = symbolAncestor || ancestorWithVariants;
  if (!needsOverrides) {
    return component;
  }
  const resolvedComponent = cloneDeep(component);

  if (symbolAncestor) {
    const { symbolChildId } =
      draftComponentId === symbolAncestor.id
        ? { symbolChildId: symbolAncestor.id }
        : paramsStringToMapping(draftComponentId ?? "");
    let resolvedComponentFromSymbol =
      componentMapping[symbolChildId]?.component ?? null;
    const {
      component: resolvedSymbolAncestor,
      variantAndSymbolOverrides: otherOverrides,
    } = applySymbolOverrides(
      symbolAncestor,
      symbolMapping[symbolAncestor.symbolId!] ?? null,
      specificVariantIdIfRequested ??
        getFromRecordOrNull(componentIdToDraftVariantId, symbolAncestor.id) ??
        null,
      null,
    );

    // Note (Noah, 2021-09-22): If the symbol child does not equal the component
    // we're looking for, we have to child it in the symbol. Note that the component
    // we're looking for might not even be in the symbol at all, since we could
    // be looking for a component which is not part of the tree of the draftSymbolInstance.
    // In this case, we'll try to look for it and return null, which is fine because
    // that will result in no symbol override being set.
    if (
      !resolvedComponentFromSymbol ||
      symbolChildId !== resolvedComponent?.id
    ) {
      resolvedComponentFromSymbol = findComponentByIdInComponent(
        resolvedSymbolAncestor,
        resolvedComponent.id,
      );
    }
    resolvedComponentFromSymbol = resolvedComponentFromSymbol
      ? merge(
          {},
          resolvedComponentFromSymbol.id === resolvedSymbolAncestor?.id
            ? resolvedSymbolAncestor
            : resolvedComponentFromSymbol,
          otherOverrides[resolvedComponentFromSymbol.id],
        )
      : null;
    mergeReplacingArrays(resolvedComponent, resolvedComponentFromSymbol ?? {});
  }

  if (ancestorWithVariants && !symbolAncestor) {
    const { variantAndSymbolOverrides: ancestorWithVariantsOverrides } =
      applySymbolOverrides(
        ancestorWithVariants,
        null,
        specificVariantIdIfRequested ??
          getFromRecordOrNull(
            componentIdToDraftVariantId,
            ancestorWithVariants.id,
          ) ??
          null,
        null,
      );

    if (ancestorWithVariantsOverrides[component.id]) {
      mergeReplacingArrays(
        resolvedComponent,
        ancestorWithVariantsOverrides[component.id]!,
      );
    }
  }

  return resolvedComponent;
};

const resolveComponent = (
  componentDataMapping: ComponentDataMapping,
  componentMapping: ComponentMapping,
  componentId: string | null,
  draftSymbolInstanceId: string | null,
  draftComponentId: string | null,
  symbolMapping: Record<string, ReploSymbol>,
  componentIdToDraftVariantId: Record<string, string | undefined>,
  canvas: EditorCanvas,
  specificVariantIdIfRequested: string | null,
): { component?: Component; mergedStyles?: Record<string, any> } => {
  const resolvedComponent = resolveComponentForSymbolsAndVariants(
    componentDataMapping,
    componentMapping,
    componentId,
    draftSymbolInstanceId,
    draftComponentId,
    symbolMapping,
    componentIdToDraftVariantId,
    specificVariantIdIfRequested,
  );

  if (!resolvedComponent) {
    return {};
  }

  const aliasesInOrderToCheck = [];
  if (canvas === "mobile") {
    // Note (Noah, 2022-03-16): Order actually matters for these, since we're iteratively
    // merging them in to create the final object - sm styles have to be after md styles
    // in order to correctly override them.
    aliasesInOrderToCheck.push("md", "sm");
  } else if (canvas === "tablet") {
    aliasesInOrderToCheck.push("md");
  }

  // Try dependent resolutions and use the first one that has an overriding value
  // by calculating all of them and filtering out nulls
  const resolutions = aliasesInOrderToCheck.map((alias) => {
    return get(resolvedComponent, `props.style@${alias}`, {});
  });

  const mergedStyles = merge(
    {},
    resolvedComponent.props?.style ?? {},
    ...resolutions,
  );
  return {
    component: { props: resolvedComponent.props ?? {} } as Component,
    mergedStyles,
  };
};

const getStyleAttribute = (
  componentDataMapping: ComponentDataMapping,
  componentMapping: ComponentMapping,
  componentId: string | null,
  draftSymbolInstanceId: string | null,
  draftComponentId: string | null,
  symbolMapping: Record<string, ReploSymbol>,
  componentIdToDraftVariantId: Record<string, string | undefined>,
  canvas: EditorCanvas,
  attribute: string,
  defaults: GetAttributeDefaults,
  filters: GetAttributeFilters | null,
): ResolvedValue => {
  const resolvedValue = getResolvedValue(
    componentDataMapping,
    componentMapping,
    componentId,
    draftSymbolInstanceId,
    draftComponentId,
    symbolMapping,
    componentIdToDraftVariantId,
    replace(attribute, "style.", `props.style.`),
    replace(attribute, "style.", `props.style.`),
    filters?.variantId ?? null,
    defaults,
  );

  const aliasesInOrderToCheck = [];
  if (canvas === "mobile") {
    aliasesInOrderToCheck.push("sm", "md");
  } else if (canvas === "tablet") {
    aliasesInOrderToCheck.push("md");
  }

  // Try dependent resolutions and use the first one that has an overriding value
  // by calculating all of them and filtering out nulls
  const resolutions = filterNulls(
    aliasesInOrderToCheck.map((alias) => {
      const valueForAlias = getResolvedValue(
        componentDataMapping,
        componentMapping,
        componentId,
        draftSymbolInstanceId,
        draftComponentId,
        symbolMapping,
        componentIdToDraftVariantId,
        replace(attribute, "style.", `props.style@${alias}`),
        replace(attribute, "style.", `props.style@${alias}.`),
        filters?.variantId ?? null,
        defaults,
      );
      if (valueForAlias.value) {
        return valueForAlias;
      }
      return null;
    }),
  );
  return resolutions.length > 0 ? resolutions[0]! : resolvedValue;
};

const getResolvedValue = (
  componentDataMapping: ComponentDataMapping,
  componentMapping: ComponentMapping,
  componentId: string | null,
  draftSymbolInstanceId: string | null,
  draftComponentId: string | null,
  symbolMapping: Record<string, ReploSymbol>,
  componentIdToDraftVariantId: Record<string, string | undefined>,
  baseAttribute: string,
  actualAttribute: string,
  specificVariantIdIfRequested: string | null,
  defaults: GetAttributeDefaults,
): ResolvedValue => {
  const resolvedComponent = resolveComponentForSymbolsAndVariants(
    componentDataMapping,
    componentMapping,
    componentId,
    draftSymbolInstanceId,
    draftComponentId,
    symbolMapping,
    componentIdToDraftVariantId,
    specificVariantIdIfRequested,
  );
  let defaultValue = defaults?.defaultValue || null;
  if (defaults?.componentNode) {
    const cssAttribute = last(baseAttribute.split("."));
    if (cssAttribute) {
      defaultValue = get(
        getComputedStyle(defaults?.componentNode),
        cssAttribute,
        null,
      );
    }
  }

  return { value: get(resolvedComponent, actualAttribute, defaultValue) };
};

const getProp = (
  componentDataMapping: ComponentDataMapping,
  componentMapping: ComponentMapping,
  componentId: string | null,
  draftSymbolInstanceId: string | null,
  draftComponentId: string | null,
  symbolMapping: Record<string, ReploSymbol>,
  componentIdToDraftVariantId: Record<string, string | undefined>,
  attribute: string,
  defaults: GetAttributeDefaults,
  filters: GetAttributeFilters | null,
) => {
  const resolvedValue = getResolvedValue(
    componentDataMapping,
    componentMapping,
    componentId,
    draftSymbolInstanceId,
    draftComponentId,
    symbolMapping,
    componentIdToDraftVariantId,
    attribute,
    attribute,
    filters?.variantId ?? null,
    defaults,
  );
  if (resolvedValue.value === undefined || resolvedValue.value === null) {
    resolvedValue.value = defaults?.defaultValue || null;
  }
  return resolvedValue;
};

/**
 * Return variants of a component, considering symbols if necessary.
 */
export const getVariants = (
  component: Component,
  symbols: Record<string, ReploSymbol>,
): ReploState[] => {
  if (hasBuiltInSymbol(component.type)) {
    return builtInSymbolDirectory[component.type]!.variants;
  }

  const symbolId = component.symbolId;

  if (symbolId == null) {
    if (component.variants) {
      return component.variants;
    }
    return [];
  }

  const symbol = symbols[symbolId] ?? null;
  return applyVariantOverrides(
    resolveVariants({
      componentType: component.type,
      componentVariants: component.variants,
      symbolVariants: symbol?.variants,
    }),
    component.variantOverrides,
  );
};

/**
 * Figure out if a component has variants, symbols included.
 */
export const hasVariants = (
  component: Component,
  symbols: Record<string, ReploSymbol>,
): boolean => {
  return (
    emptyIfNullish(component.variants).length > 0 ||
    emptyIfNullish(getBuiltInSymbol(component.type)?.variants).length > 0 ||
    Boolean(
      component.symbolId &&
        emptyIfNullish(getSymbol(component.symbolId, symbols)?.variants)
          .length > 0,
    )
  );
};

/**
 * Calculates if a component takes full width of its parent
 */
export function isFullWidthComponent(
  component: Component | null,
  getAttribute: GetAttributeFunction,
  parent: Component | null,
) {
  if (!component) {
    return false;
  }

  const width = getAttribute(component, "style.width").value;
  if (width === "100%") {
    return true;
  }

  if (!parent) {
    return false;
  }

  const parentFlexDirection = getAttribute(parent, "style.flexDirection").value;
  if (parentFlexDirection === "column") {
    const alignSelf = getAttribute(component, "style.alignSelf").value;
    return alignSelf === "stretch";
  }

  const flexGrow = getAttribute(component, "style.flexGrow").value;
  return flexGrow > 0;
}
