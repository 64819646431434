import { z } from "zod";

export const MAX_FILE_UPLOAD_COUNT = 25 as const;

const sectionKeyRegex = /^sections\/.+\.(?:liquid|json)$/;

export const sectionAssetSchema = z
  .object({
    key: z.string().regex(sectionKeyRegex),
    public_url: z.string().nullable(),
    created_at: z.string(),
    updated_at: z.string(),
    content_type: z.string(),
    size: z.number(),
    checksum: z.string(),
    theme_id: z.number(),
  })
  .describe("SectionAsset");

export const sectionSchema = z
  .object({
    key: z.string().regex(sectionKeyRegex),
    themeId: z.string(),
    publicUrl: z.string().nullable(),
    size: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
  })
  .describe("Section");

export const shopifyAssetSchema = z
  .object({
    id: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    publicUrl: z.string(),
    name: z.string(),
    type: z.enum(["image", "video", "other"]),
    width: z.number().int().nullish(),
    height: z.number().int().nullish(),
    fileSize: z.number().int(),
  })
  .describe("ShopifyAsset");

const pageInfoSchema = z
  .object({
    hasNextPage: z.boolean(),
    hasPreviousPage: z.boolean(),
    prevPage: z.string().nullish(),
    nextPage: z.string().nullish(),
  })
  .nullable();

export const libraryShopifyAssetsSchema = z
  .object({
    assets: z.array(shopifyAssetSchema),
    pageInfo: pageInfoSchema,
  })
  .describe("LibraryShopifyAssets");

export const assetTypeSchema = z
  .enum(["image", "video", "font"])
  .describe("AssetType");

export const assetSchema = z
  .object({
    id: z.string(),
    name: z.string().max(255),
    createdAt: z.date(),
    updatedAt: z.date(),
    url: z.string().max(512),
    type: assetTypeSchema,
    sizeBytes: z.number().int(),
    fileExtension: z.string(),
    width: z.number().int().nullable(),
    height: z.number().int().nullable(),
    projectId: z.string().uuid(),
    altText: z.string().nullable(),
    embeddingText: z.string().nullable(),
    embedding: z.number().array().nullable().optional(),
  })
  .describe("Asset");
