import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { z } from "zod";

/**
 * @returns Either the last visited workspace id (as saved in localStorage) or
 * the first workspace that the user has access to (as determined by the backend)
 *
 * @deprecated TODO (Noah, 2025-02-22): This really shouldn't be necessary - it's only used
 * on the account settings and dashboard /home pages, and in those cases we shouldn't
 * actually show any workspace details at all. Almost always, you want useCurrentWorkspaceId
 * instead. The design of the app should be updated to not need this.
 */
export const useDefaultWorkspaceId = () => {
  const [lastVisitedWorkspaceId] = useLocalStorageState(
    "replo.workspace.lastvisitedWorkspaceId",
    null,
    { schema: z.string().uuid().nullish() },
  );
  const {
    data: lastVisitedWorkspaceData,
    isLoading: isLoadingLastVisitedWorkspace,
  } = trpc.workspace.getById.useQuery(
    lastVisitedWorkspaceId ? { id: lastVisitedWorkspaceId } : skipToken,
  );

  const { user, isLoading: isLoadingUser } = useCurrentUser();

  if (isLoadingUser || isLoadingLastVisitedWorkspace) {
    return null;
  }

  if (lastVisitedWorkspaceData) {
    return lastVisitedWorkspaceData.workspace.id;
  }

  return user?.workspace?.id;
};
