import type { StyleCategory } from "./useDraftStyles";

import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import ShopStylesPreviewCard from "./ShopStylesPreviewCard";
import { usePresetColorStyles } from "./usePresetColorStyles";
import { usePresetTextStyles } from "./usePresetTextStyles";

interface PresetShopStylePreviewCardsProps {
  isLoading: boolean;
  applyPresetStyles: (category: StyleCategory, variantIndex: number) => void;
}

const PresetShopStylePreviewCards = ({
  isLoading,
  applyPresetStyles,
}: PresetShopStylePreviewCardsProps) => {
  const logEvent = useLogAnalytics();
  const { getColorStylesByCategory, colorStyleCategories } =
    usePresetColorStyles();

  const { getTextStylesByCategory } = usePresetTextStyles();

  const renderStyleCards = (variantIndex: number) => {
    return colorStyleCategories.map((category) => {
      const colorStyles = getColorStylesByCategory(category, variantIndex);
      const textStyles = getTextStylesByCategory(category, variantIndex);

      const headerStyle = textStyles.find(
        (style) => style.attributes.htmlTag === "h1",
      );
      const bodyStyle = textStyles.find(
        (style) => style.attributes.htmlTag === "p",
      );

      if (
        textStyles.length === 0 ||
        colorStyles.length === 0 ||
        !headerStyle ||
        !bodyStyle
      ) {
        return null;
      }

      return (
        <button
          key={`${category}-${variantIndex}`}
          onClick={() => {
            logEvent("shopDetails.presetStyle.click", {
              category: category,
            });

            applyPresetStyles(category, variantIndex);
          }}
          disabled={isLoading}
          aria-label={`Apply ${category} style variant ${variantIndex + 1}`}
        >
          <ShopStylesPreviewCard
            headingTextStyle={headerStyle}
            bodyTextStyle={bodyStyle}
            colorStyles={colorStyles}
          />
        </button>
      );
    });
  };

  return (
    <div className="flex flex-col gap-3 pb-3">
      {renderStyleCards(0)}
      {renderStyleCards(1)}
    </div>
  );
};

export default PresetShopStylePreviewCards;
