export const extractTextFromFirstParagraph = (htmlContent: string): string => {
  if (!htmlContent || typeof htmlContent !== "string") {
    return htmlContent;
  }

  const paragraphMatch = htmlContent.match(/<p[^>]*>(.*?)<\/p>/s);
  if (paragraphMatch && paragraphMatch[1]) {
    return paragraphMatch[1].replace(/<[^>]*>/g, "");
  }

  return htmlContent.replace(/<[^>]*>/g, "");
};
