import type { DomainProvider } from "@/features/experiments/tabs/constants/domainProviderOptions";

import * as React from "react";

import InputComponent from "@editor/components/common/designSystem/Input";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { DOMAIN_PROVIDERS } from "@/features/experiments/tabs/constants/domainProviderOptions";
import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsBoxArrowUpRight, BsCheckCircle, BsClock } from "react-icons/bs";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { isValidDomain } from "replo-utils/lib/url";
import { BillingTiers } from "schemas/billing";

import LabeledControl from "../common/designSystem/LabeledControl";

const DomainProviderButton = ({ name, url }: { name: string; url: string }) => (
  <Button
    variant="secondary"
    size="base"
    endEnhancer={<BsBoxArrowUpRight size={10} />}
    to={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {name}
  </Button>
);

const WaitingMessage = () => (
  <div className="bg-blue-50 p-4 rounded-lg">
    <div className="grid grid-cols-[auto,1fr] gap-x-2 gap-y-1">
      <div className="flex items-center">
        <BsClock size={16} />
      </div>
      <div className="text-sm font-normal text-slate-800">
        Wait before checking connection
      </div>
      <div></div> {/* NOTE (Kurt, 2024-10-25): Empty cell for alignment */}
      <div className="text-slate-500 text-sm font-normal">
        It can take up to five minutes for a provider to process the connection.
        Please wait a moment before checking.
      </div>
    </div>
  </div>
);

export const AddAnalyticsCustomDomainModal: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { data: workspaceCustomDomains } =
    trpc.workspace.getCustomDomains.useQuery(workspaceId ?? "");
  const { closeModal } = useModal();
  const [domainData, setDomainData] = React.useState({
    value: workspaceCustomDomains?.[0]?.value ?? "",
    isValid: isValidDomain(workspaceCustomDomains?.[0]?.value ?? ""),
  });

  // NOTE (Kurt, 2025-01-31): We're using the last domain in the array as the current domain.
  const domainDataEdited =
    workspaceCustomDomains?.[-1]?.value !== domainData.value;

  const {
    data: cnameResults,
    isLoading,
    isFetching,
    refetch,
  } = trpc.workspace.checkWorkspaceCustomDomain.useQuery(
    {
      customDomain: domainData.value,
      workspaceId: workspaceId ?? "",
    },
    // NOTE (Kurt, 2024-10-25): Disabled by default to prevent unnecessary queries. This flag
    // prevents the query from running until the user clicks the "Check Connection" button.
    {
      enabled: false,
    },
  );

  const status = (() => {
    if (isLoading || isFetching) {
      return "checking";
    }
    return cnameResults?.ok ? "ok" : "notOk";
  })();

  const isChecking = status === "checking";
  const [hasCheckedConnection, setHasCheckedConnection] = React.useState(false);

  const handleCheckConnection = () => {
    if (domainData.isValid) {
      setHasCheckedConnection(true);
      void refetch();
    }
  };

  const [error, setError] = React.useState<string | null>(null);

  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier ?? BillingTiers.FREE;

  const { mutate: updateDomain, isPending: isUpdating } =
    trpc.workspace.createCustomDomain.useMutation({
      onSuccess: (response: { customDomain: string }) => {
        successToast(
          "Custom Domain Updated",
          `${response.customDomain} is now available for use in experiments.`,
        );
        logEvent("experiment.domain.add", {
          billingPlanTier: subscriptionTier,
        });
        closeModal({ type: "customDomainModal" });
        void trpcUtils.workspace.getCustomDomains.invalidate();
      },
      meta: {
        // NOTE (Kurt, 2024-11-03): We'll display inline error instead of default toast handling
        reploIgnoreDefaultErrorHandling: true,
      },
    });

  const handleAddAndSave = () => {
    if (workspaceId && domainData.value) {
      setError(null);
      updateDomain(
        {
          workspaceId: workspaceId ?? "",
          customDomain: domainData.value,
        },
        {
          onError: (error) => {
            setError(
              error instanceof Error
                ? error.message
                : "Failed to add custom domain",
            );
          },
        },
      );
    }
  };

  const updateButtonDisabled =
    !domainDataEdited || status !== "ok" || isUpdating;

  return (
    <Modal
      isOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          closeModal({ type: "customDomainModal" });
        }
      }}
      title="Use Custom Domain for Testing"
      size="base"
      footer={
        <div className="flex flex-row gap-2 items-center">
          <Button
            variant="link"
            size="base"
            to={docs.abTesting.workspaceCustomDomains}
          >
            Learn More
          </Button>

          <Button
            variant="primary"
            size="base"
            disabled={updateButtonDisabled}
            onClick={handleAddAndSave}
          >
            {isUpdating ? "Saving..." : "Add and Save"}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-col gap-2 typ-body-base">
          <p>To use a custom domain instead of reploedge.com:</p>
          <p>
            Log in to your domain provider and add a CNAME record. Use your
            custom address (for example, go.my-store.com) as the host, and set
            the value to reploedge.com.
          </p>
          <div className="flex flex-col gap-2">
            <div className="typ-header-base">
              See guides from specific providers:
            </div>
            <div className="flex flex-wrap gap-2">
              {DOMAIN_PROVIDERS.map((provider: DomainProvider) => (
                <DomainProviderButton
                  key={provider.name}
                  name={provider.name}
                  url={provider.url}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="typ-header-base">
            Confirm your domain is connected
          </div>
          <WaitingMessage />
          <LabeledControl error={error}>
            <InputComponent
              size="base"
              type="text"
              placeholder="go.my-store.com"
              maxLength={256}
              onChange={(e) => {
                setError(null);
                setDomainData({
                  value: e.currentTarget.value,
                  isValid: isValidDomain(e.currentTarget.value ?? ""),
                });
              }}
              value={domainData.value}
              validityState={error ? "invalid" : "valid"}
            />
          </LabeledControl>
          {status !== "ok" ? (
            <div className="flex flex-row justify-between items-center gap-2">
              <div className="flex-grow">
                {(isChecking || hasCheckedConnection) && domainData.value && (
                  <DomainCheckStatus
                    status={isChecking ? "checking" : status}
                    domain={domainData.value}
                    isValidDomain={domainData.isValid}
                  />
                )}
              </div>
              <Button
                variant="primary"
                size="sm"
                onClick={handleCheckConnection}
                disabled={isChecking || !domainData.isValid}
              >
                Check Connection
              </Button>
            </div>
          ) : (
            <div className="flex flex-row gap-6 bg-green-50 p-4 py-2 rounded-lg">
              <div className="flex flex-row gap-4 items-center justify-start">
                <BsCheckCircle
                  size={16}
                  fill="var(--replo-color-green-600-a100)"
                />
                <div className="type-body-small text-success-soft">
                  Domain connected
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

type DomainCheckStatusProps = {
  status: "ok" | "notOk" | "checking";
  domain: string;
  isValidDomain: boolean;
};

const DomainCheckStatus: React.FC<DomainCheckStatusProps> = ({
  status,
  isValidDomain,
}) => {
  return (
    <div className="w-full flex flex-row items-center content-center justify-start gap-x-2">
      <div
        className={twMerge(
          "rounded-full h-2.5 w-2.5 overflow-hidden flex-shrink-0 group relative",
          status === "ok" && isValidDomain && "bg-green-500",
          (status === "notOk" || !isValidDomain) && "bg-red-500",
          status === "checking" && "bg-gray-500",
        )}
      >
        {status === "checking" && (
          <div className="w-4 h-4 absolute bg-slate-800 opacity-0 animate-pulse group-hover:opacity-100 transition-opacity duration-200"></div>
        )}
      </div>
      <div className="flex-shrink-0 text-sm font-normal">
        {exhaustiveSwitch({ type: status })({
          ok: () => "CNAME entry found",
          notOk: () => "No DNS entry found",
          checking: () => "Checking DNS entry",
        })}
      </div>
    </div>
  );
};
