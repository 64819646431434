import { isUnitTest } from "replo-runtime/shared/env";
import { filterNulls } from "replo-utils/lib/array";

const SHOULD_USE_LOCAL_HTTPS =
  typeof process !== "undefined" && process.env.REPLO_LOCAL_HTTPS === "true";
const SHOULD_USE_LOCAL_PROD_DATA =
  typeof process !== "undefined" &&
  process.env.REPLO_LOCAL_PROD_DATA === "true";

type ConfigValues = {
  hosts?: RegExp[];
  publisherUrl: string;
  dashboardUrl: string;
  shouldLogAnalytics: boolean;
};

type ConfigHost = "development" | "production";

const CONFIG: Record<ConfigHost, ConfigValues> = {
  development: {
    hosts: filterNulls([
      /alchemy-faketown.myshopify.com$/,
      /alchemy-localhost.myshopify.com$/,
      SHOULD_USE_LOCAL_PROD_DATA ? null : /localhost/,
      /127.0.0.1/,
    ]),
    dashboardUrl: "http://localhost:3000",
    publisherUrl: SHOULD_USE_LOCAL_HTTPS
      ? "https://localhost:3004"
      : "http://localhost:3004",
    shouldLogAnalytics: false,
  },
  // If you need to test the app with ngrok, comment this in:
  // ngrok: {
  //   hosts: [
  //     /^e62a94bbe86c.ngrok.io$/,
  //   ],
  //   dashboardUrl: "https://e62a94bbe86c.ngrok.io",
  // shouldLogAnalytics: false,
  // },
  production: {
    hosts: filterNulls([
      /.replo.app$/,
      /.vercel.app$/,
      SHOULD_USE_LOCAL_PROD_DATA ? /localhost:3000$/ : null,
    ]),
    dashboardUrl: "https://dashboard.replo.app",
    publisherUrl: "https://api.replo.app",
    shouldLogAnalytics: true,
  },
};

export const getConfig = <ConfigKey extends keyof ConfigValues>(
  path: ConfigKey,
  defaultValue?: ConfigValues[ConfigKey],
): ConfigValues[ConfigKey] | null => {
  if (isUnitTest()) {
    return CONFIG.development[path] ?? defaultValue ?? null;
  }

  let selectedConfig: ConfigValues | null = null;
  let target = "";
  try {
    if (typeof window !== "undefined") {
      target = window.location.host;
    }
  } catch {
    // allow empty catch
  }
  for (const config of Object.values(CONFIG)) {
    const hosts = config.hosts ?? [];
    if (hosts.some((rx) => rx.test(target))) {
      selectedConfig = config;
    }
  }

  if (!selectedConfig) {
    throw new Error(
      `[Replo] Invalid config environment specified by host '${target}', pages will not update correctly. Please contact support@replo.app`,
    );
  }

  return selectedConfig[path] ?? defaultValue ?? null;
};

export function getPublisherUrl() {
  // NOTE (Chance 2023-06-19) The staging URL can be enabled in any environment
  // with a setting in the debug menu. Noah made a suggestion for a potentially
  // better abstraction but we needed to push this out and didn't want to spend
  // more time thinking about it!
  // https://github.com/replohq/andytown/pull/4662/files#r1234563041
  if (localStorage.getItem("replo.debug.usePublisherStagingUrl") === "true") {
    return "https://replo-publisher-staging.fly.dev";
  }
  return getConfig("publisherUrl")!;
}

export const SHOPIFY_APP_LISTING_URL = "https://apps.shopify.com/alchemy";
