import * as React from "react";

import { BackButton } from "@editor/components/common/BackButton";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useOpenModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import { ExperimentActionsBar } from "@/features/experiments/components/ExperimentActionsBar";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import { ExperimentsEditTabV2 } from "@/features/experiments/tabs/ExperimentsEditTabV2";
import { ExperimentTabWrapper } from "@/features/experiments/tabs/ExperimentTabWrapper";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { ExperimentEditableHeader } from "../ExperimentEditableHeader";
import { useExperimentEdit } from "./hooks/useExperimentEdit";

const ExperimentDetailsTab: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { experimentId } = useParams();
  const { dispatchExperimentEdit, handleReactHookFormSubmit } =
    useExperimentEdit();
  const navigate = useNavigate();
  const openModal = useOpenModal();
  const hasShownModal = React.useRef(false);

  const {
    data: { experiment, doesWorkspaceHaveLatestShopifyIntegrations } = {
      experiment: undefined,
      doesWorkspaceHaveLatestShopifyIntegrations: false,
    },
    isFetching: isFetchingExperiments,
  } = trpc.experiment.findDetail.useQuery(
    {
      experimentId: experimentId ?? "",
      workspaceId: workspaceId ?? "",
    },
    { enabled: Boolean(experimentId) && Boolean(workspaceId) },
  );

  if (!experiment || !workspaceId || isFetchingExperiments) {
    return null;
  }

  if (!doesWorkspaceHaveLatestShopifyIntegrations && !hasShownModal.current) {
    hasShownModal.current = true;
    openModal({
      type: "refreshShopifyIntegrationAnalyticsModal",
      props: {
        workspaceId,
      },
    });
  }

  const experimentsListPath = generatePath(routes.workspace.experiments.list, {
    workspaceId,
  });

  return (
    <ExperimentsLayout
      headerTitleComponent={
        <ExperimentEditableHeader
          title={experiment.name}
          experimentId={experiment.id}
          dispatchExperimentEdit={dispatchExperimentEdit}
          handleReactHookFormSubmit={handleReactHookFormSubmit}
          isLoadingRequiredData={!workspaceId}
        />
      }
      headerActions={
        <ExperimentActionsBar
          workspaceId={workspaceId ?? ""}
          experiment={experiment}
        />
      }
      showStatus
      backButton={<BackButton onClick={() => navigate(experimentsListPath)} />}
    >
      <ExperimentTabWrapper className="max-w-[800px]">
        <ExperimentsEditTabV2 experiment={experiment} />
      </ExperimentTabWrapper>
    </ExperimentsLayout>
  );
};

export default ExperimentDetailsTab;
