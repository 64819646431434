import type { Editor } from "@tiptap/react";
import type { IconType } from "react-icons";
import type { LinkData } from "replo-runtime/shared/types";

import * as React from "react";

import InputComponent from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import {
  selectOpenPopoverId,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { isSelectionEmpty } from "@editor/utils/rte";

import Button from "@replo/design-system/components/button/Button";
import Popover from "@replo/design-system/components/popover/Popover";
import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsLink45Deg } from "react-icons/bs";

export const ToolbarButton = ({
  icon,
  onClick,
  tooltipText,
  isActive,
}: {
  icon: IconType;
  onClick?: () => void;
  tooltipText: string;
  isActive: boolean;
}) => {
  const Icon = icon;
  return (
    <Tooltip triggerAsChild content={tooltipText}>
      <button
        className={twMerge(
          "flex items-center justify-center bg-subtle p-0",
          isActive && "bg-accent text-onEmphasis rounded-sm",
        )}
        onClick={onClick}
      >
        <Icon size={16} />
      </button>
    </Tooltip>
  );
};

const INITIAL_DATA: LinkData = {
  url: "",
  isUnderlined: true,
  isNewTab: true,
};

const TipTapToolbarLink: React.FC<
  React.PropsWithChildren<{
    editor: Editor | null;
    isActive: boolean;
  }>
> = ({ editor, isActive }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [linkData, setLinkData] = React.useState<LinkData>(INITIAL_DATA);
  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  const dispatch = useEditorDispatch();
  const isOpenedFromGlobalContext = openPopoverId === "tiptap-toolbar-link";

  React.useEffect(() => {
    if (isVisible) {
      setLinkData((linkData) => ({
        ...linkData,
        url: editor?.getAttributes("link").href,
        isUnderlined: editor?.isActive("underline") ?? true,
        isNewTab: editor?.getAttributes("link").target === "_blank",
      }));
    }
  }, [isVisible, editor]);

  const setLink = React.useCallback(() => {
    const { url, isUnderlined, isNewTab } = linkData;
    const isPreviouslyUnderlined = editor?.isActive("underline");
    let focusedChain = editor?.chain().focus();
    if (isSelectionEmpty(editor)) {
      focusedChain = focusedChain?.selectAll();
    }
    // cancelled
    if (url === null) {
      return;
    }
    if (url === "") {
      // empty
      focusedChain = focusedChain?.extendMarkRange("link").unsetLink();
    } else {
      // update link
      focusedChain = focusedChain?.extendMarkRange("link").setLink({
        href: url,
        target: isNewTab ? "_blank" : undefined,
      });
    }

    if (isUnderlined && !isPreviouslyUnderlined) {
      focusedChain = focusedChain?.setUnderline();
    } else if (!isUnderlined && isPreviouslyUnderlined) {
      focusedChain = focusedChain?.unsetUnderline();
    }

    focusedChain?.run();
  }, [editor, linkData]);

  const renderContent = () => {
    const { url, isUnderlined, isNewTab } = linkData;

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLink();
          setIsVisible(false);
          setLinkData(INITIAL_DATA);
        }}
        className="flex flex-col gap-4"
      >
        <LabeledControl label="URL" size="sm">
          <InputComponent
            size="sm"
            value={url}
            onChange={(e) =>
              setLinkData((prevData) => ({ ...prevData, url: e.target.value }))
            }
            autoFocus
          />
        </LabeledControl>
        <div className="flex flex-col gap-2">
          <SwitchWithDescription
            isOn={isUnderlined}
            onChange={() =>
              setLinkData((prevData) => ({
                ...prevData,
                isUnderlined: !prevData.isUnderlined,
              }))
            }
            size="sm"
            label="Underline the link content"
          />
          <SwitchWithDescription
            isOn={isNewTab}
            onChange={() =>
              setLinkData((prevData) => ({
                ...prevData,
                isNewTab: !prevData.isNewTab,
              }))
            }
            size="sm"
            label="Open Link In New Tab"
          />
        </div>
        <div className="flex flex-row items-center justify-end gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              setIsVisible(false);
              setLinkData(INITIAL_DATA);
              if (isOpenedFromGlobalContext) {
                dispatch(setOpenPopoverId(null));
              }
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Popover
      shouldPreventDefaultOnInteractOutside={false}
      isOpen={isVisible || isOpenedFromGlobalContext}
      onOpenChange={setIsVisible}
      title="Configure Link"
      content={renderContent()}
      triggerAsChild
      onRequestClose={() => {
        if (isOpenedFromGlobalContext) {
          dispatch(setOpenPopoverId(null));
        }
      }}
      onInteractOutside={() => {
        if (isOpenedFromGlobalContext) {
          dispatch(setOpenPopoverId(null));
        }
      }}
    >
      <button className="flex items-center justify-center">
        <Tooltip content="Set Link" triggerAsChild>
          <div
            className={twMerge(
              "bg-subtle flex items-center justify-center",
              isActive && "bg-accent text-onEmphasis rounded-sm",
            )}
          >
            <BsLink45Deg size={16} />
          </div>
        </Tooltip>
      </button>
    </Popover>
  );
};

export default TipTapToolbarLink;
