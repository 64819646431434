import * as React from "react";

import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";

function useCurrentProjectId() {
  const { project } = useCurrentProjectContext();
  return project?.id;
}

export default useCurrentProjectId;
