import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

export type ProgressStep =
  | "initializing"
  | "uploadingImages"
  | "importComplete";

type WarningTypeAndDetails =
  | { type: "uploadError"; reploComponentIds: string[] }
  | { type: "missingFonts"; fonts: string[] }
  | { type: "multipleFills"; nodeNames: string[] }
  | { type: "mask"; nodeNames: string[] };

export type ImportWarning = WarningTypeAndDetails & {
  object?: string;
  timestamp?: number;
};

export type FigmaToReploState = {
  isOpenPanel: boolean;
  progress: ProgressStep;
  warnings: ImportWarning[];
};

const initialState: FigmaToReploState = {
  isOpenPanel: false,
  progress: "initializing",
  warnings: [],
};

const figmaToReploSlice = createSlice({
  name: "figmaToReplo",
  initialState,
  reducers: {
    setPanelVisibility: (state, action: PayloadAction<boolean>) => {
      state.isOpenPanel = action.payload;
    },
    updateProgress: (state, action: PayloadAction<ProgressStep>) => {
      state.progress = action.payload;
    },
    addWarning: (state, action: PayloadAction<ImportWarning>) => {
      state.warnings.push({
        ...action.payload,
        timestamp: Date.now(),
      });
    },
    reset: (state) => {
      state.warnings = [];
      state.progress = "initializing";
    },
  },
});

export const selectProgress = (state: EditorRootState) =>
  state.figmaToReplo.progress;
export const selectWarnings = (state: EditorRootState) =>
  state.figmaToReplo.warnings;
export const selectIsOpenPanel = (state: EditorRootState) =>
  state.figmaToReplo.isOpenPanel;

const { actions, reducer } = figmaToReploSlice;
export const { updateProgress, addWarning, reset, setPanelVisibility } =
  actions;
export default reducer;
