import type { ElementVersionRevisionHistory } from "@editor/types/core-state";
import type { User } from "replo-runtime/shared/types";
import type {
  ReploElementVersionFilter,
  ReploElementVersionRevision,
} from "schemas/generated/element";

import * as React from "react";

import CollapsibleVersionCard from "@components/version-history/CollapsibleVersionCard";
import VersionCard from "@components/version-history/VersionCard";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import {
  selectSelectedRevisionId,
  setSelectedRevisionId,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import IconButton from "@replo/design-system/components/button/IconButton";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import twMerge from "@replo/design-system/utils/twMerge";
import { ListFilter } from "lucide-react";
import { orderByDate } from "replo-utils/lib/array";
import { ReploElementVersionKinds } from "schemas/element";
import { v4 as uuid } from "uuid";

import Separator from "../common/designSystem/Separator";

// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */

const createFakeRevision = (user: User): ReploElementVersionRevision => {
  return {
    id: uuid(),
    createdAt: new Date().toISOString(),
    kind: ReploElementVersionKinds.current,
    title: "Current Version",
    createdBy: user,
  };
};

const AUTOMATIC_VERSIONS = "AUTOMATIC_VERSIONS";

type ElementVersionCombinedList = Array<
  ReploElementVersionRevision | ElementVersionRevisionHistory
>;

const useElementVersionCombinedList = (
  versions: ReploElementVersionRevision[],
  fakeRevision: ReploElementVersionRevision,
) =>
  React.useMemo(() => {
    const orderedVersions = versions.reduce(
      (
        versionHistory: ElementVersionCombinedList,
        version: ReploElementVersionRevision,
      ) => {
        const mostRecentVersion = versionHistory[versionHistory.length - 1];
        const mostRecentVersionIsAutomatic = Boolean(
          mostRecentVersion?.title == AUTOMATIC_VERSIONS,
        );
        let mostRecentVersionGroup = mostRecentVersionIsAutomatic
          ? (mostRecentVersion as ElementVersionRevisionHistory)
          : null;

        if (version.kind == "automatic") {
          if (!mostRecentVersionIsAutomatic || !mostRecentVersionGroup) {
            mostRecentVersionGroup = {
              title: AUTOMATIC_VERSIONS,
              items: [],
            };
            versionHistory.push(mostRecentVersionGroup);
          }
          mostRecentVersionGroup.items.push(version);
        } else {
          if (
            mostRecentVersionGroup &&
            mostRecentVersionIsAutomatic &&
            mostRecentVersionGroup.items.length < 3
          ) {
            versionHistory = [
              ...versionHistory.slice(0, -1),
              ...mostRecentVersionGroup.items,
            ];
          }
          versionHistory.push(version);
        }
        return versionHistory;
      },
      [],
    );
    return [fakeRevision, ...orderedVersions];
  }, [versions, fakeRevision]);

const VERSION_HISTORY_FILTER_OPTIONS: {
  label: string;
  value: string;
}[] = [
  { label: "All", value: "all" },
  { label: "By you", value: "user" },
  { label: "Published", value: "publish" },
  { label: "Restored", value: "revert" },
];

export const VersionHistoryFilter = ({
  filterBy,
  setFilterBy,
}: {
  filterBy: ReploElementVersionFilter;
  setFilterBy: (filter: ReploElementVersionFilter) => void;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Combobox.Root
      options={VERSION_HISTORY_FILTER_OPTIONS}
      value={filterBy}
      onChange={(value) => setFilterBy(value as ReploElementVersionFilter)}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Combobox.Trigger>
        <IconButton
          icon={<ListFilter size={12} />}
          tooltipText="Filter"
          variant="secondary"
          size="sm"
          isActive={isOpen}
        />
      </Combobox.Trigger>
      <Combobox.Popover layoutClassName="w-40">
        <Combobox.Content />
      </Combobox.Popover>
    </Combobox.Root>
  );
};

export default function VersionHistoryPane({
  versions,
  isLoading,
  isFetchingNextPage,
  loadMoreRef,
}: {
  versions: ReploElementVersionRevision[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  loadMoreRef: (node: HTMLElement | null) => void;
}) {
  const dispatch = useEditorDispatch();
  const selectedRevisionId = useEditorSelector(selectSelectedRevisionId);
  const { user } = useCurrentUser();

  const orderedVersions: ReploElementVersionRevision[] = orderByDate(
    versions,
    "createdAt",
  );

  const fakeRevision = React.useMemo(() => {
    return createFakeRevision(user!);
  }, [user]);

  const versionHistory: ElementVersionCombinedList =
    useElementVersionCombinedList(orderedVersions, fakeRevision);

  const onItemClick = (item: ReploElementVersionRevision) => {
    if (item.kind == ReploElementVersionKinds.current) {
      dispatch(setSelectedRevisionId(null));
    } else {
      dispatch(setSelectedRevisionId(item.id));
    }
  };

  return (
    <div className="flex flex-col flex-1 gap-1 px-2 min-h-0">
      <div className="h-full mt-1.5 overflow-y-scroll no-scrollbar">
        {!isLoading ? (
          <div className="relative">
            {versionHistory.length > 1 && (
              <Separator
                orientation="vertical"
                className={twMerge(
                  "absolute ml-[0.65rem] top-4 z-0",
                  "max-h-[calc(100%-2rem)]",
                )}
              />
            )}
            {versionHistory.map((version, groupIndex) => {
              const isFirstItem = groupIndex === 0;
              const isLastItem = groupIndex === versionHistory.length - 1;
              if (version.title == AUTOMATIC_VERSIONS) {
                const versionsGroup = version as ElementVersionRevisionHistory;
                return (
                  <div
                    key={versionsGroup.items[0]!.id}
                    ref={isLastItem ? loadMoreRef : undefined}
                  >
                    <CollapsibleVersionCard
                      className={twMerge(
                        isLastItem && "relative bg-white",
                        isFirstItem && "mt-0",
                      )}
                      versionsGroup={versionsGroup}
                      selectedRevisionId={selectedRevisionId}
                      onItemClick={onItemClick}
                    />
                  </div>
                );
              }
              const item = version as ReploElementVersionRevision;
              const cardIsActive =
                (item.id === fakeRevision.id && !selectedRevisionId) ||
                selectedRevisionId === item.id;
              return (
                <VersionCard
                  key={item.id}
                  ref={isLastItem ? loadMoreRef : undefined}
                  className={twMerge(
                    isLastItem && "relative bg-white",
                    isFirstItem && "mt-0",
                  )}
                  version={item}
                  isActive={cardIsActive}
                  onClick={() => {
                    onItemClick(item);
                  }}
                />
              );
            })}

            {isFetchingNextPage && (
              <div className="flex min-h-[100px] items-center justify-center">
                <Spinner variant="primary" size={33} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex h-full pt-2 items-center justify-center">
            <Spinner variant="primary" size={33} />
          </div>
        )}
      </div>
    </div>
  );
}
