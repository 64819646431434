import type { WorkspaceSettings } from "../Settings";

import { useCallback } from "react";

import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import { ReploError } from "schemas/errors";

class WorkspaceUpdateError extends ReploError {}
class InvalidTimezoneError extends WorkspaceUpdateError {}

type WorkspaceUpdateProps = {
  id: string;
  data: Partial<WorkspaceSettings>;
  showSuccessToast?: boolean;
};

export function useUpdateWorkspace() {
  const { mutateAsync: update, isPending } = trpc.workspace.update.useMutation({
    onSuccess: (variables) => {
      void trpcUtils.workspace.getById.invalidate({
        id: variables.workspace.id,
      });
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
    },
  });

  const updateWorkspace = useCallback(
    async ({ id, data, showSuccessToast = true }: WorkspaceUpdateProps) => {
      const { timeZone } = data;

      if (timeZone !== undefined) {
        const isValidTimezone =
          // TODO (Kurt, 2025-03-11, INS-421): We allow for null values to be
          // valid timezones here because in our database the timeZone field is
          // nullable. However, we have a ticket to make this non-nullable in the
          // future after backfilling the timeZone field with some default value.
          timeZone == null ||
          timeZone === "UTC" ||
          Intl.supportedValuesOf("timeZone").includes(timeZone);

        if (!isValidTimezone) {
          throw new InvalidTimezoneError({
            message: "Invalid timezone",
            additionalData: { timeZone },
          });
        }
      }

      const result = await update(
        {
          id,
          workspace: data,
        },
        {
          onSuccess: () => {
            if (showSuccessToast) {
              toast({
                header: "Workspace information updated successfully",
              });
            }
          },
        },
      );

      return result;
    },
    [update],
  );

  return {
    updateWorkspace,
    isUpdating: isPending,
  };
}
