import type { Component } from "schemas/component";
import type {
  DesignLibrary,
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import {
  getSavedStyleAttributeValue,
  getSavedStyleId,
  getSavedStyleValue,
} from "replo-runtime/shared/savedStyles";

import { allMediaSizeStyles } from "./breakpoints";
import { forEachComponentAndDescendants } from "./component";

export const designLibraryValueRegex = /library\.[^.]+\.styles\.[^.]+/;

export function isDynamicDesignLibraryValue(value: string | null | string[]) {
  if (!value) {
    return false;
  }

  if (Array.isArray(value)) {
    return value[0] === "library" && value[2] === "styles";
  }

  return designLibraryValueRegex.test(value);
}

export function resolveTextWithSavedStyles(
  text: string,
  designLibrary: DesignLibrary | null,
) {
  if (!designLibrary || !isDynamicDesignLibraryValue(text)) {
    return text;
  }
  return text.replace(/{{\s*library\.[^}]+}}/g, (savedStyleValue) => {
    const savedStyleId = getSavedStyleId(savedStyleValue);
    const savedStyleData =
      designLibrary && savedStyleId
        ? designLibrary.savedStyles[savedStyleId]
        : null;

    if (!savedStyleData) {
      return "";
    }

    return getSavedStyleAttributeValue(savedStyleData, savedStyleValue) ?? "";
  });
}

export const findSavedStylesInComponent = (args: {
  component: Component;
  savedStyles: ((SavedTextStyle | SavedColorStyle) & { id: string })[];
}) => {
  const { component, savedStyles } = args;

  const savedStylesReferences: Record<
    string,
    SavedTextStyle | SavedColorStyle
  > = {};

  const processSavedStyleValue = (styleValue: string) => {
    const savedStyle = getSavedStyleValue(savedStyles, styleValue);

    if (savedStyle) {
      savedStylesReferences[savedStyle.id] = savedStyle;
    }
  };

  forEachComponentAndDescendants(component, (componentOrDescendant) => {
    allMediaSizeStyles.forEach((mediaSize) => {
      const styleProp = componentOrDescendant.props[mediaSize];
      if (styleProp) {
        Object.values(styleProp).forEach((styleValue) => {
          if (
            typeof styleValue === "string" &&
            isDynamicDesignLibraryValue(styleValue)
          ) {
            processSavedStyleValue(styleValue);
          }
        });
      }
    });
  });

  return savedStylesReferences;
};
