import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import {
  buildGoogleFontApiUrl,
  GOOGLE_FONTS,
} from "replo-runtime/shared/utils/font";

type PreviewTextShopStyleProps = {
  attributes: SavedStyleTextAttributes;
  text: string;
  className?: string;
};

const PreviewTextShopStyle: React.FC<PreviewTextShopStyleProps> = ({
  attributes,
  text,
  className,
}) => {
  return (
    <>
      {attributes.fontFamily && (
        <style
          dangerouslySetInnerHTML={{
            __html: getFontFamilyStyleHTML(attributes.fontFamily),
          }}
        />
      )}
      <span
        style={{
          fontFamily: attributes.fontFamily ?? undefined,
          fontWeight: attributes.fontWeight,
          letterSpacing: attributes.letterSpacing,
        }}
        className={className}
      >
        {text}
      </span>
    </>
  );
};

const getFontFamilyStyleHTML = (fontFamily: string) => {
  const isFontFamilyGoogleFont = GOOGLE_FONTS.includes(fontFamily);
  if (isFontFamilyGoogleFont) {
    return `@import url(${buildGoogleFontApiUrl([fontFamily])})`;
  }
  return `@font-face { font-family: "${fontFamily}"; src: url("${fontFamily}") format("${fontFamily}") }`;
};

export default PreviewTextShopStyle;
