import React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import { selectStreamingUpdateId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { toast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import Popover from "@replo/design-system/components/popover/Popover";
import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import twMerge from "@replo/design-system/utils/twMerge";
import { BadgeCheck, Redo2, ThumbsDown, ThumbsUp, Undo2 } from "lucide-react";
import { BsArrowReturnLeft } from "react-icons/bs";

interface PromptFeedbackProps {
  onAccept?: () => void;
  onDiscard?: () => void;
  handleUndo: () => void;
  handleRedo: () => void;
  canvasState: string;
  setCanvasState: (state: string) => void;
}

const PromptFeedback: React.FC<PromptFeedbackProps> = ({
  onAccept,
  onDiscard,
  handleUndo,
  handleRedo,
  canvasState,
  setCanvasState,
}) => {
  const { generationMode } = useAIStreaming();

  const streamingUpdateId = useEditorSelector(selectStreamingUpdateId);

  const [feedback, setFeedback] = React.useState("");
  const [feedbackPopoverOpen, setFeedbackPopoverOpen] = React.useState(false);
  const [submittedFeedback, setSubmittedFeedback] = React.useState<
    "positive" | "negative" | null
  >(null);

  const isBuildAssistantVersion = canvasState === "build-assistant";

  const logEvent = useLogAnalytics();
  const submitFeedback = (isPositive: boolean) => {
    if (generationMode !== "template") {
      return null;
    }

    if (isPositive) {
      logEvent("ai.action.feedback.positive", {
        feedback,
        streamingUpdateId,
      });
    } else {
      logEvent("ai.action.feedback.negative", {
        feedback,
        streamingUpdateId,
      });
    }

    setSubmittedFeedback(isPositive ? "positive" : "negative");
    setFeedbackPopoverOpen(false);
    setFeedback("");

    toast({
      header: "Feedback submitted",
      message: "Thank you!",
    });
  };

  const toggleCanvasState = () => {
    if (isBuildAssistantVersion) {
      handleUndo();
    } else {
      handleRedo();
    }
    setCanvasState(isBuildAssistantVersion ? "original" : "build-assistant");
  };

  return (
    <div className="w-full rounded-lg">
      <div className="flex justify-between items-center border-border border-b-0.5 pb-3 mb-3 typ-button-small">
        <div className="flex items-center gap-1">
          <div className="bg-success-soft p-1 border-0.5 border-[rgba((21,128,61,.2)] flex items-center justify-center rounded-[6px]">
            <BadgeCheck size={12} className="text-success" />
          </div>
          Done!
        </div>
        <Button variant="tertiary" size="sm" onClick={toggleCanvasState}>
          <div className="flex items-center gap-1">
            {isBuildAssistantVersion && <Undo2 size={12} />}
            {isBuildAssistantVersion
              ? "View Original Version"
              : "View Edited Version"}
            {!isBuildAssistantVersion && <Redo2 size={12} />}
          </div>
        </Button>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex gap-2">
          <button
            onClick={() =>
              submittedFeedback !== "positive" && submitFeedback(true)
            }
            className={
              submittedFeedback === "positive"
                ? "cursor-default"
                : "cursor-pointer"
            }
          >
            <ThumbsUp
              size={12}
              className={twMerge(
                submittedFeedback === "positive" ? "fill-current" : "",
              )}
            />
          </button>

          <Popover.Root
            isOpen={feedbackPopoverOpen}
            onOpenChange={setFeedbackPopoverOpen}
          >
            <Popover.Trigger>
              <ThumbsDown
                size={12}
                className={twMerge(
                  submittedFeedback === "negative" ? "fill-current" : "",
                  feedbackPopoverOpen
                    ? "text-slate-600"
                    : "text-inherit hover:text-muted active:text-slate-600",
                )}
              />
            </Popover.Trigger>
            <Popover.Content
              side="top"
              sideOffset={16}
              align="end"
              className="p-2 rounded font-normal text-sm w-[306px] flex flex-col items-end gap-2 bg-white border-0.5 border-border"
              shouldPreventDefaultOnInteractOutside={false}
              hideCloseButton
            >
              <Textarea
                size="sm"
                value={feedback}
                textLength="short"
                placeholder="Give us feedback on what went wrong..."
                onChange={setFeedback}
                onEnter={() => submitFeedback(false)}
                layoutClassName="w-full h-14"
                autoFocus
              />
              <Button
                variant="primary"
                size="sm"
                onClick={() => submitFeedback(false)}
              >
                <div className="flex items-center">
                  Submit
                  <div className="pl-2">
                    <BsArrowReturnLeft />
                  </div>
                </div>
              </Button>
            </Popover.Content>
          </Popover.Root>
        </div>

        <div className="flex gap-2">
          <Button variant="secondary" size="sm" onClick={onDiscard}>
            Discard
          </Button>
          <Button variant="primary" size="sm" onClick={onAccept}>
            Accept Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PromptFeedback;
