import * as React from "react";

import { isDevelopment } from "@editor/utils/env";

import Button from "@replo/design-system/components/button/Button";
import { getProjectPreviewImageUrl } from "replo-utils/lib/project";

type ProjectImageProps = {
  projectId: string;
  workspaceId: string;
  onSelectProject: (e: React.MouseEvent) => void;
};

const ProjectImage: React.FC<ProjectImageProps> = ({
  projectId,
  workspaceId,
  onSelectProject,
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);
  const imageS3Url = getProjectPreviewImageUrl({
    workspaceId,
    projectId,
    env: isDevelopment ? "dev" : process.env.NODE_ENV,
  });

  return (
    <div
      className="relative p-4 bg-slate-50 group gap-2 rounded-t-lg cursor-pointer"
      onClick={onSelectProject}
    >
      <>
        <div className="absolute inset-0 bg-white/50 opacity-0 group-hover:opacity-100 transition-opacity" />
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
          <Button variant="primary">Open Project</Button>
        </div>
        {imageError ? (
          <div className="w-full aspect-video bg-slate-50" />
        ) : (
          <div className="w-full aspect-video overflow-hidden">
            <img
              src={`${imageS3Url}?t=${Date.now()}`}
              className={`w-full object-contain object-top transition-opacity duration-300 ${
                imageLoaded ? "opacity-100" : "opacity-0"
              }`}
              alt={`Project Preview ${projectId}`}
              onLoad={() => setImageLoaded(true)}
              onError={() => setImageError(true)}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default ProjectImage;
