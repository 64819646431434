import * as React from "react";

import Button from "@replo/design-system/components/button/Button";
import EmptyState from "@replo/design-system/components/empty-state/EmptyState";
import { FolderSearch } from "lucide-react";
import { useNavigate } from "react-router-dom";

type NoMatchingProjectsProps = {
  searchQueryInputValue: string;
};

const NoMatchingProjects: React.FC<NoMatchingProjectsProps> = ({
  searchQueryInputValue,
}) => {
  const navigate = useNavigate();

  return (
    <EmptyState
      title={`No results matching &quot;${searchQueryInputValue}&quot;`}
      description="Start building with Replo — we can't wait to bring your ideas to life!"
      icon={<FolderSearch className="icon-size-default" />}
    >
      <Button
        size="base"
        variant="primary"
        onClick={() => navigate("../project/new")}
      >
        New Project
      </Button>
    </EmptyState>
  );
};

export default NoMatchingProjects;
