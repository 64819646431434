import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

const useListAllElementsMetadata = () => {
  const projectId = useCurrentProjectId();
  const { data } = trpc.element.listAllElementsMetadata.useQuery(
    projectId ? { projectId } : skipToken,
  );

  return data ?? [];
};

export default useListAllElementsMetadata;
