export const stringToHTML = (str: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  return doc.body;
};

const getHTMLElementFromString = (rawHtml: string) => {
  const parser = new DOMParser();
  return parser.parseFromString(rawHtml, "text/html");
};

// NOTE (Matt 2024-02-06): these are domains associated with approved integrations that may
// work in the editor.
const domainsAllowed = [
  /rechargecdn/,
  /reviews.io/,
  /rebuyengine/,
  /loox.io/,
  /stamped.io/,
  /judge.me/,
  /productreviews.shopifycdn.com/,
  /okendo-reviews/,
  /fera.js/,
  /junip/,
  /yotpo/,
  /klaviyo/,
  /cdn.tailwindcss.com/,
];
// NOTE (Matt 2024-02-06): These are specific keywords or snippets of code from essential script
// tags that allow certain integrations to work in the editor.
const scriptTagContentAllowed = [
  /reviewsIoStore/,
  /loadReviewsIoRatingSnippets/,
  /var Shopify = Shopify \|\| {}/,
  /oke-reviews/,
  /jdgm/,
  /UploadKit/,
  /junip/,
  /yotpo/,
  /klaviyo/,
];

// NOTE (Matt 2024-02-06): Shopify has a script tag on the page that has an array of urls
// that it adds to the page as script tags. We need to update this array to only include
// the scripts that we allow for integrations inside of the editor. This function takes
// the JS from the original script tag as a string, finds the urls array, and updates it
// based on the domainsAllowed array from above, then returns the modified script code.
function filterAndModifyUrlsInScript(scriptCode: string) {
  const urlsArrayRegex = /var urls = \[([^\]]+)]/;
  const match = scriptCode.match(urlsArrayRegex);

  if (match?.[1]) {
    // Extract the array part and split into individual URLs, trimming quotes and commas
    let urls = match[1]
      .split(",")
      .map((url) => url.trim().replace(/^"|"$/g, ""));

    // Filter the URLs based on the domainsAllowed regexes
    urls = urls.filter((url) =>
      domainsAllowed.some((regex) => regex.test(url)),
    );

    // Reconstruct the urls array string
    const modifiedUrlsArray = `var urls = ["${urls.join('","')}"]`;

    // Replace the old urls array in the script with the modified one
    const modifiedScript = scriptCode.replace(
      urlsArrayRegex,
      modifiedUrlsArray,
    );

    return modifiedScript;
  }
  return scriptCode; // Return the original script if urls array is not found
}

// NOTE (Matt 2024-02-06): This function essentially removes all unessential JS
// before rendering in the canvas. The only JS assets we allow from a users theme
// are third party widget scripts, and some native Shopify scripts.
// We need to look through scripts and filter out based on src as well as innerHTML,
// additionally we need to find the shopify script loader (see isShopifyTagLoader)
// below and parse its contents to block any unapproved scripts.
export function processHtmlForCanvasWithoutThemeScripts({
  rawHTML,
}: {
  rawHTML: string;
}) {
  const parsedHTML = getHTMLElementFromString(rawHTML);
  // NOTE (Matt 2024-02-06): We don't want to remove script tags inside of shopify-app-blocks
  // because these scripts correspond directly to widgets that render on the page, which
  // is not the type of script that we're worried about crashing the editor. Including this
  // logic allows us to have a smaller list of validScriptContents and validScriptSrc.
  [
    ...parsedHTML.querySelectorAll(
      'script:not(.shopify-app-block script):not([type="application/json"]',
    ),
  ].forEach((scriptTag) => {
    const validScriptSrc =
      scriptTag.hasAttribute("src") &&
      domainsAllowed.some((regex: RegExp) =>
        regex.test(scriptTag.getAttribute("src")!),
      );
    const validScriptContents =
      scriptTag.innerHTML &&
      scriptTagContentAllowed.some((regex: RegExp) =>
        regex.test(scriptTag.innerHTML),
      );
    const isShopifyTagLoader = scriptTag.innerHTML?.includes(
      "function asyncLoad() {",
    );
    if (isShopifyTagLoader) {
      scriptTag.innerHTML = filterAndModifyUrlsInScript(scriptTag.innerHTML);
    } else if (!validScriptSrc && !validScriptContents) {
      scriptTag.remove();
    }
  });
  // NOTE (Matt 2024-02-21): This is a generic solution to remove the x-cloak and v-cloak
  // attributes from the main content elements. These attributes are used by AlpineJS and VueJS
  // to hide elements until the page is loaded, but they cause issues with the editor.
  const mainContentElements =
    parsedHTML.documentElement.querySelectorAll("body, main");
  mainContentElements.forEach((element) => {
    if (element) {
      element.removeAttribute("x-cloak");
      element.removeAttribute("v-cloak");
    }
  });
  return parsedHTML.documentElement.innerHTML;
}
