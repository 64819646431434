import type {
  AnalyticsReadQuery,
  MetricName,
} from "schemas/generated/analyticsRead";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import {
  RightBarBody,
  RightBarLayout,
} from "@editor/components/layouts/RightBarLayout";
import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useOpenModal } from "@editor/hooks/useModal";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";
import { setIsRightBarAnalyticsOpen } from "@editor/reducers/ui-reducer";
import { useEditorDispatch } from "@editor/store";
import {
  calculateDelta,
  generateDeepDiveNavigationPathFromUrl,
} from "@editor/utils/analytics";
import { trpc } from "@editor/utils/trpc";

import {
  DEFAULT_QUERY,
  OVERVIEW_TABLE_METRICS,
} from "@/features/analytics/constants";
import { useAnalyticsOnboardingOAuthLink } from "@/features/analytics/useAnalyticsOnboaredingOAuthLink";
import useBasicAnalyticsRead from "@/features/analytics/useBasicAnalyticsRead";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import Button from "@replo/design-system/components/button/Button";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BsArrowRight, BsLock, BsX } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import { getRangesFromTimePeriod } from "replo-utils/analytics";
import { convertDaysToMs } from "replo-utils/lib/datetime";
import { BillingTiers } from "schemas/billing";

type AnalyticsMetricCardProps = {
  title: string;
  doesCompareValueExist: boolean;
  mainValue: number | undefined;
  compareValue: number | undefined;
  metricKey: MetricName;
  chartValues: number[] | undefined;
  isLoading: boolean;
};

const BoxWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col justify-start items-start border border-slate-300 shadow-sm rounded-md bg-white">
      <div className="flex flex-col grow p-4">{children}</div>
    </div>
  );
};

const UpgradeBox = () => {
  const openModal = useOpenModal();
  const openBillingModal = () =>
    openModal({
      type: "billingModal",
      props: {
        source: "analytics",
        billingPlanMessageKey: "billingPlan.analytics",
      },
    });
  return (
    <>
      <div className="flex mb-2">
        <div className="flex flex-row gap-1 items-center bg-slate-100 rounded p-1 text-neutral">
          <BsLock size={12} />
          <span className="text-xs">Paid Feature</span>
        </div>
      </div>
      <div className="text-sm font-semibold text-default mb-2">
        Unlock Replo Analytics
      </div>
      <div className="text-xs text-muted mb-4">
        See how your pages are performing in real time with Replo Analytics
      </div>
      <Button
        size="sm"
        variant="primary"
        isLoading={false}
        onClick={openBillingModal}
      >
        Unlock Analytics
      </Button>
    </>
  );
};

const ConnectShopifyBox = () => {
  const productAnalytics = useLogAnalytics();
  const { oauthLink, isLoading } = useAnalyticsOnboardingOAuthLink();
  return (
    <>
      <img
        src="/replo-shopify-connector.svg"
        alt="Connect Replo To Shopify"
        className="block object-scale-down mb-4"
      />
      <div className="text-sm font-semibold text-default mb-2">
        Connect to see store Analytics
      </div>
      <div className="text-xs text-muted mb-4">
        Get started by enabling the Replo Pixel through Shopify
      </div>
      <Button
        size="sm"
        variant="primary"
        to={oauthLink ?? ""}
        isLoading={isLoading || !oauthLink}
        onClick={() =>
          productAnalytics("analytics.connect", {
            tab: "analytics_tab",
          })
        }
      >
        Connect Shopify
      </Button>
    </>
  );
};

const AnalyticsMetricCard: React.FC<AnalyticsMetricCardProps> = ({
  title,
  doesCompareValueExist,
  mainValue,
  compareValue,
  metricKey,
  chartValues,
  isLoading,
}) => {
  if (isLoading) {
    return <Skeleton className="h-[80px] w-full" />;
  }

  return (
    <div className="px-6 py-4 border rounded-lg grid grid-cols-12">
      <div className="flex flex-col gap-2 col-span-7">
        <span className="text-muted text-xs">{title}</span>
        <MetricWithDelta
          name={metricKey}
          value={mainValue ?? 0}
          delta={calculateDelta(mainValue, compareValue)}
          doesCompareValueExist={doesCompareValueExist}
          wrapperClassName="gap-2"
          valueClassName="text-sm font-semibold"
        />
      </div>
      <div className="col-end-13 col-span-4">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={100}
            height={250}
            data={chartValues?.map((value) => ({ value }))}
          >
            <Line
              type="monotone"
              dataKey="value"
              stroke="#2463EB"
              dot={false}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const DummyLoadedAnalyticsMenuPane: React.FC = () => {
  return (
    <div className="flex flex-col gap-3 h-full">
      {OVERVIEW_TABLE_METRICS.map(({ label: metricTitle, key: metricKey }) => {
        const mainValue = Math.floor(Math.random() * 800);
        const compareValue = Math.floor(Math.random() * 800);

        return (
          <AnalyticsMetricCard
            key={metricKey}
            metricKey={metricKey}
            title={metricTitle}
            doesCompareValueExist={true}
            mainValue={mainValue}
            compareValue={compareValue}
            chartValues={Array.from({ length: 7 }, () =>
              Math.floor(Math.random() * 800),
            )}
            isLoading={false}
          />
        );
      })}
    </div>
  );
};

type LoadedAnalyticsMenuPaneProps = {
  workspaceId: string;
  query: AnalyticsReadQuery;
};
const LoadedAnalyticsMenuPane: React.FC<LoadedAnalyticsMenuPaneProps> = ({
  workspaceId,
  query,
}) => {
  const { ranges } = query;
  const chartRanges = {
    ...ranges,
    mainRange: {
      ...ranges.mainRange,
      interval: convertDaysToMs(1),
    },
    compareAtRanges: [],
  };

  const chartQuery = { ...query, ranges: chartRanges };

  const {
    mainRangeResults,
    compareAtRangeResults,
    isLoading: isRangeResultsLoading,
  } = useBasicAnalyticsRead({
    workspaceId,
    type: "withoutUrlSyncQuery",
    queryConfig: query,
  });

  const { mainRangeResults: chartMainRangeResults, isLoading: isChartLoading } =
    useBasicAnalyticsRead({
      workspaceId,
      type: "withoutUrlSyncQuery",
      queryConfig: chartQuery,
    });

  const isLoading = isRangeResultsLoading || isChartLoading;

  const compareAtRangeViewsArray = compareAtRangeResults?.[0]?.metrics.views;

  const doesCompareValueExist = Boolean(compareAtRangeViewsArray);

  return (
    <div className="flex flex-col gap-3 ">
      {OVERVIEW_TABLE_METRICS.map(({ label: metricTitle, key: metricKey }) => {
        const mainValue = mainRangeResults[0]?.metrics[metricKey]?.[0];
        const compareValue = compareAtRangeResults[0]?.metrics[metricKey]?.[0];

        const chartValues = chartMainRangeResults[0]?.metrics[metricKey];

        return (
          <AnalyticsMetricCard
            key={metricKey}
            metricKey={metricKey}
            title={metricTitle}
            doesCompareValueExist={doesCompareValueExist}
            mainValue={mainValue}
            compareValue={compareValue}
            chartValues={chartValues}
            isLoading={isLoading}
          />
        );
      })}
    </div>
  );
};

const SetupAnalyticsMenuPane: React.FC<{ isCurrentlyPaying: boolean }> = ({
  isCurrentlyPaying,
}) => {
  return (
    <div className="relative h-full">
      <DummyLoadedAnalyticsMenuPane />
      <div className="absolute inset-0 w-full h-full flex flex-col justify-center items-center bg-white bg-opacity-[0.10] backdrop-blur-sm">
        <BoxWrapper>
          {isCurrentlyPaying ? <ConnectShopifyBox /> : <UpgradeBox />}
        </BoxWrapper>
      </div>
    </div>
  );
};

type AnalyticsMenuPaneProps = {
  width: number;
  elementRef: React.RefObject<HTMLDivElement>;
};
export const AnalyticsMenuPane: React.FC<AnalyticsMenuPaneProps> = ({
  width,
  elementRef,
}) => {
  const { workspaceId, workspace } = useCurrentWorkspaceContext();
  const { shopifyUrl, customDomainUrl, path } = usePublishedInfo();

  const dispatch = useEditorDispatch();

  const logEvent = useLogAnalytics();

  const { data: isEnabled, isLoading: isLoadingEnableCheck } =
    trpc.analytics.isEnabled.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );

  const { data: workspaceSubscription, isLoading: isLoadingSubscription } =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspaceId ?? reactQuerySkipToken,
    );

  const isCurrentlyPaying =
    Boolean(workspaceSubscription) &&
    workspaceSubscription?.name !== BillingTiers.FREE;

  const url = customDomainUrl ?? shopifyUrl ?? null;

  const showSetupPage =
    !Boolean(url) ||
    (!isEnabled && !isLoadingEnableCheck && !isLoadingSubscription);

  const workspaceUrlHosts = useWorkspaceUrlHosts(workspaceId ?? null);

  if (!workspaceId || !path) {
    return null;
  }

  const { urlSyncedQuery, deepDivePathWithCompressedUrlSyncedQuery } =
    generateDeepDiveNavigationPathFromUrl({
      url,
      urlHosts: workspaceUrlHosts.data,
      path,
      workspaceId,
      selectedTimePeriod: {
        type: "relative",
        value: "last-7-days",
      },
    });

  const { updatedRanges: ranges } = getRangesFromTimePeriod({
    selectedTimePeriod: urlSyncedQuery.selectedTimePeriod,
    timeZone: workspace?.timeZone ?? "UTC",
  });

  /**
   * NOTE (Max, 2024-10-17): We use a basic query where we get:
   * 1) urlHosts from the useWorkspaceUrlHosts hook
   * 2) mainRange datetimes from the Experiment's createdAt / endedAt dates
   * 3) filters using the experimentId: we're only interested in CH rows that contain
   * the experimentId as a urlParam
   */
  const query: AnalyticsReadQuery = {
    ...DEFAULT_QUERY,
    urlHosts: urlSyncedQuery.urlHosts,
    ranges,
    filters: urlSyncedQuery.filters,
  };

  return (
    <RightBarLayout rightBarWidth={width} rightBarElementRef={elementRef}>
      <div className="flex flex-row px-3 py-4 gap-1 justify-between">
        <div
          className={twMerge(
            "flex flex-col gap-2",
            showSetupPage && "opacity-30",
          )}
        >
          <span className="font-semibold text-sm">Page Analytics</span>
          <span className="text-xs text-muted">Showing Last 7 days</span>
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex flex-row justify-end">
            <BsX
              size={20}
              onClick={() => {
                dispatch(setIsRightBarAnalyticsOpen(false));
              }}
              className="cursor-pointer"
            />
          </div>
          <div
            className={twMerge(
              "flex flex-row text-accent items-center gap-1",
              showSetupPage && "opacity-30 pointer-events-none",
            )}
          >
            <Link
              to={deepDivePathWithCompressedUrlSyncedQuery ?? ""}
              className="text-xs"
              onClick={() => {
                logEvent("analytics.editor.menu.deepDive.click", {});
              }}
            >
              See more
            </Link>
            <BsArrowRight size={12} />
          </div>
        </div>
      </div>
      <RightBarBody isEditMode={false} className="px-2">
        {showSetupPage ? (
          <SetupAnalyticsMenuPane isCurrentlyPaying={isCurrentlyPaying} />
        ) : (
          <LoadedAnalyticsMenuPane workspaceId={workspaceId} query={query} />
        )}
      </RightBarBody>
    </RightBarLayout>
  );
};
