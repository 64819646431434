import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type VariationNameStartEnhancerProps = {
  index: number;
  size?: "base" | "sm";
};

export const VariationNameStartEnhancer: React.FC<
  VariationNameStartEnhancerProps
> = ({ index, size = "base" }) => {
  const { borderClassName, backgroundClassName } = exhaustiveSwitch({
    // NOTE (Kurt, 2025-02-26): We use the index modulo 3 to get a different color for each variation
    // in a round-robin fashion.
    type: (index % 3).toString(),
  })({
    0: {
      borderClassName: "border-blue-500",
      backgroundClassName: "bg-blue-300",
    },
    1: {
      borderClassName: "border-purple-500",
      backgroundClassName: "bg-purple-300",
    },
    2: {
      borderClassName: "border-green-500",
      backgroundClassName: "bg-green-300",
    },
  });

  return (
    <div
      className={twMerge(
        "rounded-full flex items-center justify-center border",
        size === "base" ? "w-4 h-4" : "w-2 h-2",
        backgroundClassName,
        borderClassName,
      )}
    />
  );
};
