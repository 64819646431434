import type {
  ErrorUserFacingDetails,
  ErrorUserFacingDetailsWithMessage,
} from "schemas/generated/errors";

import { hasOwnProperty } from "replo-utils/lib/misc";
import { z, ZodError } from "zod";

/**
 * Config to use for any standard error we throw in Replo
 */
export type ReploErrorConfig = {
  /**
   * Message of the error, used only internally. If not provided, defaults to
   * the error's class name
   */
  message?: string;
  /**
   * Any additional data about the error, e.g. ids of content that was trying
   * to be accessed, contextual variables, etc. This is included when the error
   * is logged to Sentry, etc
   */
  additionalData?: Record<string, unknown>;
  /** Meta details for error reporting */
  errorReportingDetails?: {
    /**
     * If true, tells Sentry that it should group this error differently when
     * it happens on different endpoints ("transactions" in Sentry are endpoints)
     */
    includeTransactionInFingerprint?: boolean;
    /**
     * If provided, these get added to the Sentry fingerprint for this error. Useful
     * for when you want to modify the grouping of an error, especially for reusing
     * errors in different places.
     */
    customFingerprint?: string[];
  };
};

/**
 * Base error that all errors in Replo inherit from
 */
export abstract class ReploError extends Error {
  config: ReploErrorConfig;

  constructor(config: ReploErrorConfig) {
    super(config.message);
    this.message = config.message ?? this.constructor.name;
    this.config = config;
  }
}

/**
 * Error which automatically makes all Replo services respond with a specific
 * HTTP status code when caught. Should only be thrown from routes, makes it
 * easier to provide a standardized way of implementing user-facing messages
 */
export class ReploHTTPError extends ReploError {
  userFacingDetails: ErrorUserFacingDetails;
  statusCode: number;

  constructor(config: {
    statusCode: number;
    userFacingDetails: ErrorUserFacingDetails;
  }) {
    super({
      message: config.userFacingDetails.message,
      additionalData: { statusCode: config.statusCode },
    });
    this.statusCode = config.statusCode;
    this.userFacingDetails = config.userFacingDetails;
  }
}

/**
 * Error used to represent an HTTP error that should be surfaced as such. This
 * should be thrown when we want to pass the error through without changing the
 * status code and without providing any user facing details (because this route
 * is not serving the client e.g. when we're calling Mirror through the
 * Publisher)
 */
export class ReploRawHTTPError extends ReploError {
  statusCode: number;
  errorType: string;

  constructor(config: {
    statusCode: number;
    message: string;
    errorType: string;
  }) {
    super({
      message: config.message,
      additionalData: { statusCode: config.statusCode },
    });
    this.statusCode = config.statusCode;
    this.errorType = config.errorType;
  }
}

/**
 * Error which is automatically mapped to a status code response wherever it's
 * thrown. Errors which are thrown from many different places where we want to
 * always respond in the same way should inherit this, e.g. an error thrown for
 * any Shopify call when the user has uninstalled the app.
 *
 * Inheriting from this should be uncommon, for most errors we want to inherit
 * from ReploError and catch/map them to ReploHTTPErrors in our routes. For the
 * uncommon cases where we want to globally map errors this reduces boilerplate.
 *
 * Globally mapped errors are never reported to Sentry or logged.
 */
export class Uncommon_GloballyMappedError extends ReploError {
  userFacingDetails: ErrorUserFacingDetails;
  statusCode: number;

  constructor(
    config: ReploErrorConfig & {
      statusCode: number;
      userFacingDetails: ErrorUserFacingDetails;
    },
  ) {
    super(config);
    this.statusCode = config.statusCode;
    this.userFacingDetails = config.userFacingDetails;
  }
}

export class ReploValidationError extends ReploError {
  constructor(message: string) {
    super({ message: `[replo-validation-error]: ${message}` });
  }
}

export const DEFAULT_ERROR_MESSAGE_CONFIG = {
  header: "Unknown Error",
  message: "An unknown error has occurred",
  isFullPage: false,
};

export type ErrorDetails = {
  header: string;
  subheader?: string;
  message: string | string[];
  messageDetail?: string;
  isRecoverable?: boolean;
  isFullPage?: boolean;
  callToAction?:
    | { type: "installApp" }
    | { type: "callback"; name: string; onClick(): void }
    | { type: "link"; name: string; to: string; target?: string }
    | { type: "closeModal"; name: string }
    | {
        type: "navigate";
        location: "integrationHub"; // Add more navigation locations as needed
      }
    | {
        type: "custom";
        callToActionType: ErrorUserFacingDetailsWithMessage["callToAction"];
      };
};

/**
 * Map of error keys to custom user-facing details. Should ONLY be used
 * in cases where we want to override the default behavior of ErrorUserFacingDetails
 * from the backend.
 *
 * TODO (Noah, 2024-01-15): Clean up these custom configs, all of them
 * are only used by Django (and 'base' can be moved directly into the reducer function)
 */
export const CUSTOM_ERROR_KEY_TO_PROPS = {
  "shopifyArticle.failedToPublish": () => ({
    header: "Shopify was unable to save this Article",
    message: "Check that the Article does not contain invalid liquid code.",
  }),
  "shopifySection.failedToPublish": () => ({
    header: "Shopify was unable to save this Section",
    message: "Check that the Section does not contain invalid liquid code.",
  }),
  "page.failedToPublish": () => ({
    header: "Shopify was unable to save this Page",
    message: "Check that the Page does not contain invalid liquid code.",
  }),
  "shopifyProductTemplate.failedToPublish": () => ({
    header: "Shopify was unable to save this Product Template",
    message: "Check that the Page does not contain invalid liquid code.",
  }),
  "element.failedToPublish": () => ({
    header: "Failed to Publish",
    message: "Something went wrong publishing. Please try again",
  }),
  "element.failedToUpdate": () => ({
    header: "Failed to Autosave",
    message: "Your changes may not have been saved",
  }),
  "store.billingPlan.alreadyExist": () => ({
    header: "Failed to change Plan",
    message: "Same tier subscription already exists for this store",
  }),
  "error.assets.unsupportedFileType": ({ serverMessage }) => ({
    header: "Unsupported file type",
    message:
      serverMessage ?? "Please upload a file with a supported file type.",
  }),
  "error.assets.fileTooLarge": () => ({
    header: "File Too Large",
    message:
      "Theme asset upload too large. Uploading to Files may accommodate larger assets.",
  }),
  "error.files.fileTooLarge": () => ({
    header: "File Too Large",
    message:
      "File uploads must be smaller than 20MB and 20 Megapixels for images, and 1GB for videos. Please upload a smaller file or reach out to support@replo.app for help.",
  }),
  base: ({ endpoint }) => {
    if (!endpoint) {
      return DEFAULT_ERROR_MESSAGE_CONFIG;
    }
    switch (endpoint) {
      case "getStore":
        return {
          header: "Unknown Error",
          subheader: "Please contact support@replo.app if this error persists.",
          message: "An unknown error has occurred while loading your store.",
          isFullPage: true,
        };
      default:
        return DEFAULT_ERROR_MESSAGE_CONFIG;
    }
  },
} satisfies Record<
  string,
  (params: {
    type: string;
    serverMessage?: string;
    endpoint: string | null;
  }) => ErrorDetails | null
>;

export const errorUserFacingDetailsMessageSchema = z
  .object({
    type: z.enum(["toast", "fullPageModal", "shopifyStorePassword"]),
    message: z.string(),
    isRecoverable: z.boolean().optional(),
    // NOTE (Matt 2024-03-22): We support detail being an array of strings
    // in FullPageModal only.
    detail: z.array(z.string()).or(z.string()).optional(),
    key: z.string(),
    callToAction: z
      .union([
        z.literal("reload"),
        z.literal("installApp"),
        z.literal("goToHomepage"),
        z.literal("navigateToProjectEditor"),
        z.literal("closeModal"),
        z.object({
          type: z.literal("navigateToLink"),
          link: z.discriminatedUnion("type", [
            z.object({ type: z.literal("errors.wrongJsonExtension") }),
            z.object({ type: z.literal("errors.chunkingError") }),
            z.object({ type: z.literal("errors.indexBackupError") }),
            z.object({
              type: z.literal("customLink"),
              url: z.string(),
            }),
          ]),
        }),
      ])
      .optional(),
  })
  .describe("ErrorUserFacingDetailsWithMessage");

/**
 * Schema for user-facing details which the frontend uses to show toasts, modals, etc
 * when an error is thrown from the backend
 */
export const errorUserFacingDetailsSchema = z
  .discriminatedUnion("type", [
    /**
     * Normal errors, show error UI depending on the type
     */
    errorUserFacingDetailsMessageSchema,
    /**
     * Errors which should be ignored by the frontend. This is uncommon, usually
     * you want to show an error in the UI, and if you don't want to then you catch
     * the error in your route.
     */
    z.object({
      type: z.literal("uncommon_none"),
      key: z.string(),
      message: z.string().optional(),
      isRecoverable: z.boolean().optional(),
      detail: z.array(z.string()).or(z.string()).optional(),
    }),
  ])
  .describe("ErrorUserFacingDetails");

export const extractErrorTrackingEventDetails = (
  error: unknown,
  transaction: string | undefined,
) => {
  if (error instanceof ZodError) {
    /**
     * NOTE: Ben 2023-11-01 Since most ZodErrors originate from the
     * pre-request logic inside the fastify plugin, Sentry tries to group them
     * together. We want to split them out based on route in the UI though, so
     * we extend the "fingerprint" with the "transaction", which is just the
     * method + path. See
     * https://docs.sentry.io/platforms/node/usage/sdk-fingerprinting/ for
     * more info.
     */
    return {
      fingerprint: ["{{ default }}", transaction ?? ""],
      extra: {
        zodIssues: error.issues,
      },
    };
  } else if (error && error instanceof ReploError) {
    const fingerprint = ["{{ default }}"];
    if (error.config.errorReportingDetails?.includeTransactionInFingerprint) {
      fingerprint.push(transaction ?? "");
    }
    if (error.config.errorReportingDetails?.customFingerprint) {
      fingerprint.push(...error.config.errorReportingDetails.customFingerprint);
    }
    return {
      fingerprint,
      extra: error.config.additionalData,
    };
  }
  if (error && error instanceof Error && hasOwnProperty(error, "cause")) {
    return { extra: { cause: error.cause } };
  }
  return null;
};

export const shouldReportErrorToSentry = (error: Error) => {
  if (
    // Note (Noah, 2024-01-21): Because Got doesn't allow throwing specific types
    // of errors in the beforeError hook, we have to set the cause and map that
    // instead, in order to implement globally mapped errors
    error.cause instanceof ReploHTTPError ||
    error.cause instanceof Uncommon_GloballyMappedError ||
    error.cause instanceof ReploValidationError
  ) {
    return false;
  }
  if (
    error instanceof ReploHTTPError ||
    error instanceof Uncommon_GloballyMappedError ||
    error instanceof ReploValidationError
  ) {
    return false;
  }
  return true;
};
