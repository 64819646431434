import type { DeleteElementConfirmationModalProps } from "@editor/components/AppModalTypes";
import type { EditorRoute } from "@editor/utils/router";

import * as React from "react";

import DeleteConfirmationModal from "@editor/components/common/DeleteConfirmationModal";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useModal } from "@editor/hooks/useModal";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  deleteElement as deleteElementAction,
  selectDraftElementId,
  selectDraftElementProjectId,
  selectDraftElementType,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { successToast } from "@replo/design-system/components/alert/Toast";
import { skipToken } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";

export const DeleteElementConfirmationModal = (
  props: DeleteElementConfirmationModalProps,
) => {
  const pageToDelete = props.element.id;
  const modal = useModal();
  const {
    mutate: deleteElement,
    data,
    isPending: isLoading,
    error,
  } = trpc.element.delete.useMutation({
    onSuccess: () => {
      void trpcUtils.project.listWithStats.invalidate();
      void trpcUtils.element.listAllElementsMetadata.invalidate();
    },
  });
  const draftElementId = useEditorSelector(selectDraftElementId);
  const draftElementProjectId = useEditorSelector(selectDraftElementProjectId);
  const draftElementType = useEditorSelector(selectDraftElementType);
  const setDraftElement = useSetDraftElement();

  const nextElementId = React.useMemo(() => {
    if (!props.elements?.length) {
      return null;
    }
    const currentIndex = props.elements?.findIndex(
      (p) => p.id === pageToDelete,
    );
    const nextIndex = currentIndex === 0 ? currentIndex + 1 : currentIndex - 1;
    return props.elements[nextIndex]?.id;
  }, [pageToDelete, props.elements]);

  const { data: nextElementMetadata } =
    trpc.element.getElementMetadataById.useQuery(nextElementId ?? skipToken);

  const dispatch = useEditorDispatch();
  const navigate = useNavigate();
  const errorToast = useErrorToast();
  const handleDeletion = React.useCallback(() => {
    if (props.type === "unpublish") {
      props.onDelete();
    } else if (props.type === "delete" && pageToDelete) {
      void deleteElement(pageToDelete);
    }
  }, [deleteElement, pageToDelete, props]);

  const pageEditorData = elementTypeToEditorData[props.element?.type];

  // biome-ignore lint/correctness/useExhaustiveDependencies: This is weird, no idea why we're doing these toasts inside an effect
  React.useEffect(() => {
    if (
      props.type === "delete" &&
      pageToDelete &&
      data?.elementId &&
      pageToDelete === data.elementId
    ) {
      dispatch(deleteElementAction(pageToDelete));
      successToast(
        `${pageEditorData.singularDisplayName} Deleted`,
        `${pageEditorData.singularDisplayName} deleted successfully! 🎉`,
      );
      modal.closeModal({});

      // redirect to another page or article if it's deleting the current draft one
      if (
        draftElementId === data.elementId &&
        draftElementProjectId &&
        props.elements.length > 1 &&
        nextElementMetadata
      ) {
        setDraftElement({ id: nextElementMetadata.id });
        navigate(
          generatePath(routes.editor.element, {
            projectId: draftElementProjectId,
            elementId: nextElementMetadata.id,
          } as EditorRoute),
        );
      }
    } else if (error) {
      errorToast(
        "Failed Deleting Element",
        `Failed deleting ${pageEditorData.singularDisplayName}. Please try again or reach out to support@replo.app for help.`,
        "error.element.delete",
        {
          error: error.message,
        },
      );
    }
  }, [
    data,
    draftElementId,
    draftElementProjectId,
    draftElementType,
    error,
    pageToDelete,
    dispatch,
    modal,
    navigate,
    pageEditorData,
    props.type,
    props.elements,
    setDraftElement,
    errorToast,
    nextElementMetadata,
  ]);

  return (
    <DeleteConfirmationModal
      onRequestClose={() =>
        modal.closeModal({ type: "deleteElementConfirmationModal" })
      }
      onDelete={handleDeletion}
      assetTypeDisplayName={pageEditorData.singularDisplayName}
      assetName={props.element.name}
      extraMessage={
        props.element.isHomepage
          ? "Because this page is set as your store's homepage, Replo will convert your store's homepage back to the homepage from your Shopify theme."
          : undefined
      }
      isLoading={isLoading}
      confirmationType={props.type}
    />
  );
};
