import { z } from "zod";

export interface StoreProductSummary {
  title: string;
  id: number;
  featuredImage: string | null;
  defaultVariantId: number;
}

/**
 * Note (Noah, 2023-03-12): This sentinel value is used to indicate that the
 * user has specifically selected one-time purchase. If that's the case, then
 * the dynamic data value for selected selling plan id will always resolve to
 * null. If the selectedSellingPlanId is not set and an action references it
 * with dynamic data, it will resolved to the first selling plan (instead of no
 * selling plan).
 *
 * This is because we need to support the case where you have two
 * tabs, one with OPT and one with SNS, where the SNS tab has a selling plan
 * dropdown - we want that selling plan dropdown to default to the first selling
 * plan, and for the buttons inside that tab to resolve the first selling plan
 * with dynamic data. However, the other case we want to support is just having
 * one selling plan dropdown, not inside tabs - in this case, the selected selling
 * plan dynamic data value needs to resolve to null if the user specifically selects
 * OTP from the dropdown.
 *
 * Note: we could do this with null vs undefined, but a sentinel value is harder
 * to screw up.
 */
export type SelectedSellingPlanIdOrOneTimePurchase =
  | number
  | { __reploOneTimePurchase: true };

const metafieldTypeSchema = z.union([
  z.literal("boolean"),
  z.literal("collection_reference"),
  z.literal("color"),
  z.literal("date"),
  z.literal("date_time"),
  z.literal("dimension"),
  z.literal("file_reference"),
  z.literal("json"),
  z.literal("money"),
  z.literal("multi_line_text_field"),
  z.literal("number_decimal"),
  z.literal("number_integer"),
  z.literal("page_reference"),
  z.literal("product_reference"),
  z.literal("rating"),
  z.literal("single_line_text_field"),
  z.literal("url"),
  z.literal("variant_reference"),
  z.literal("volume"),
  z.literal("weight"),
  z.literal("rich_text_field"),
]);

export interface ProductRef {
  productId: number | string;
  variantId?: number;
  quantity?: number;
  title?: string;
  id?: number;
}
export type ProductRefOrDynamic = ProductRef | ContextRef;

export interface ContextRef {
  type: "contextRef";
  ref: string;
}

export const metafieldDefinitionSchema = z
  .object({
    key: z.string(),
    name: z.string(),
    namespace: z.string(),
    id: z.string(),
    type: metafieldTypeSchema,
    description: z.string().nullable(),
    ownerType: z.enum(["PRODUCT", "PRODUCTVARIANT", "PAGE"]),
  })
  .describe("MetafieldDefinition");

const metafieldValuesMapping = z.record(
  z.string(),
  z.object({
    type: metafieldTypeSchema,
    value: z.union([z.number(), z.string()]),
  }),
);

export const productMetafieldMappingSchema = z
  .record(z.string(), z.record(z.string(), metafieldValuesMapping))
  .describe("ProductMetafieldMapping");

export const variantMetafieldMappingSchema = z
  .record(z.string(), z.record(z.string(), metafieldValuesMapping))
  .describe("VariantMetafieldMapping");

const productStatusSchema = z.string(); // Assuming ProductStatus is a string enum
const shopifySellingPlanGroupSchema = z.object({
  id: z.string(),
  appId: z.string().nullable(),
  options: z.array(
    z.object({
      name: z.string(),
      position: z.number(),
      values: z.array(z.union([z.string(), z.null()])),
    }),
  ),
  sellingPlans: z.array(
    z.object({
      id: z.union([z.number(), z.string()]),
      name: z.string(),
      description: z.string().nullable(),
      options: z.array(
        z.object({
          name: z.string().optional(),
          position: z.number(),
          value: z.string(),
        }),
      ),
      priceAdjustments: z.array(
        z.object({
          value: z.number(),
          value_type: z.enum(["percentage", "fixed_amount", "price"]),
        }),
      ),
      appId: z.string().nullable().optional(),
    }),
  ),
});

const reploShopifyOptionSchema = z.object({
  key: z.enum(["option1", "option2", "option3"]),
  name: z.string(),
  values: z
    .array(
      z.object({
        title: z.string(),
        available: z.boolean().optional(),
      }),
    )
    .optional(),
});

const reploShopifyVariantSchema = z.object({
  id: z.number(),
  variantId: z.number(),
  productId: z.union([z.string(), z.number()]),
  sku: z.string().nullable(),
  name: z.string(),
  option1: z.string(),
  option2: z.string().nullable().optional(),
  option3: z.string().nullable().optional(),
  title: z.string(),
  available: z.boolean(),
  featuredImage: z.string().nullable(),
  productHandle: z.string(),
  variantMetafields: z
    .record(z.record(z.union([z.string(), z.number()])))
    .optional(),
  sellingPlanIds: z.array(z.number()),
  sellingPlanGroupIds: z.array(z.string()),
});

export const reploShopifyProductSchema = z
  .object({
    id: z.number(),
    productId: z.union([z.string(), z.number()]),
    title: z.string(),
    type: z.string().optional(),
    images: z.array(z.string()),
    status: productStatusSchema,
    featured_image: z.string().nullable(),
    variants: z.array(reploShopifyVariantSchema),
    variant: reploShopifyVariantSchema,
    variantId: z.number(),
    options: z.array(reploShopifyOptionSchema),
    optionsValues: z.array(z.string()),
    description: z.string(),
    handle: z.string(),
    quantity: z.number(),
    productMetafields: z
      .record(z.record(z.union([z.string(), z.number()])))
      .optional(),
    sellingPlanGroups: z.array(shopifySellingPlanGroupSchema),
  })
  .describe("SchemaReploShopifyProduct");
