import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import { trpc } from "@editor/utils/trpc";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { skipToken } from "@tanstack/react-query";
import { getRangesFromTimePeriod } from "replo-utils/analytics";

type BasicAnalyticsReadProps = {
  workspaceId: string | null;
} & (
  | {
      type: "withoutUrlSyncQuery";
      queryConfig: AnalyticsReadQuery;
    }
  | {
      type: "withUrlSyncQuery";
      queryConfig: {
        useChartInterval: boolean;
        queryOverrides?: Partial<AnalyticsReadQuery>;
      };
    }
);

/**
 * The hook that communicates with the backend to fetch metrics from ClickHouse.
 *
 * This hook can either be used:
 * 1) within the Analytics dashboard (overview page / deep dive page)
 * 2) outside the Analytics Dashboard (in Experiments, or the editor's RightBar's AnalyticsMenuPane)
 *
 * For 1), use the type = 'withUrlSyncQuery', as we want to use the urlSyncedQuery fields that are in the URL.
 * In this case, we'll use the internalQuery and urlSyncedQuery objects exposed by the AnalyticsContext, and simply
 * combine them to form the final AnalyticsReadQuery.
 *
 * For 2), use the type = 'withoutUrlSyncQuery', as there won't be any fields in the URL (as we're outside the
 * Analytics dashboard). In this case, provide the full AnalyticsReadQuery to this hook, and it'll use it to query ClickHouse.
 *
 * We should ONLY use the queryOverrides if we purposely don't want the URL params to be in sync with
 * the fields of type UrlSyncedQuery that we're sending to the backend. The only time we do this is in the
 * AnalyticsHostPagesCombobox, where we want to override the urlPath filters so that only urlPaths containing
 * the searchTerm are returned (check the comments in that component for more details).
 *
 * @author Max 2024-12-15
 */
const useBasicAnalyticsRead = ({
  workspaceId,
  type,
  queryConfig,
}: BasicAnalyticsReadProps) => {
  const { query: contextQuery, timeZone } = useAnalyticsQueryContext();

  let query: AnalyticsReadQuery;

  if (type === "withoutUrlSyncQuery") {
    query = queryConfig;
  } else {
    const { useChartInterval, queryOverrides } = queryConfig;

    const { selectedTimePeriod, chartInterval } = contextQuery;

    // Compute the final ranges (with startDatetime/endDatetime/interval) from the fields
    // in the urlSyncedQuery.
    const rangesFromUrlSyncedQuery = getRangesFromTimePeriod({
      selectedTimePeriod,
      chartInterval: useChartInterval ? chartInterval : null,
      timeZone,
    }).updatedRanges;

    // The final AnalyticsReadQuery is a simple combination of the internalQuery and the urlSyncedQuery
    query = {
      ...contextQuery,
      urlHosts: contextQuery.urlHosts,
      filters: contextQuery.filters,
      ranges: rangesFromUrlSyncedQuery,
      ...queryOverrides,
    };
  }

  const shouldFetch = Boolean(workspaceId) && query.urlHosts.length > 0;

  const find = trpc.analytics.find.useQuery(
    shouldFetch
      ? {
          query,
          workspaceId: workspaceId ?? "",
        }
      : skipToken,
  );

  const hasData = trpc.analytics.hasData.useQuery(
    shouldFetch ? { workspaceId: workspaceId ?? "" } : skipToken,
  );
  const refetch = async () => Promise.all([find.refetch(), hasData.refetch()]);

  const mainRangeId = query.ranges.mainRange.id;
  const compareAtRangeId = query.ranges.compareAtRanges[0]?.id;

  const rangeResults = find.data?.rangeResults;

  const { mainRangeResults, compareAtRangeResults } = React.useMemo(
    () => ({
      mainRangeResults:
        rangeResults?.filter(
          (rangeResultObj) => rangeResultObj.rangeId === mainRangeId,
        ) ?? [],
      compareAtRangeResults:
        rangeResults?.filter(
          (rangeResultObj) => rangeResultObj.rangeId === compareAtRangeId,
        ) ?? [],
    }),
    [rangeResults, mainRangeId, compareAtRangeId],
  );

  return {
    refetch,
    hasAnyData: Boolean(hasData.data),
    totalRowsCount: find.data?.totalRowsCount ?? null,
    isLoading: find.isLoading || hasData.isLoading,
    mainRangeResults,
    compareAtRangeResults,
    constructedQuery: query,
  };
};

export default useBasicAnalyticsRead;
