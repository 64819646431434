// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { MenuItem } from "@replo/design-system/components/menu/Menu";
import type { IconType } from "react-icons";

import * as React from "react";

import {
  Group,
  GroupHeader,
  GroupTitle,
  GroupTitleContainer,
} from "@editor/components/common/designSystem/Group";

import IconButton from "@replo/design-system/components/button/IconButton";
import { Menu } from "@replo/design-system/components/menu/Menu";
import { Plus } from "lucide-react";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { hasOwnProperty } from "replo-utils/lib/misc";

type ContextProps = {
  name: string;
  isCollapsed: boolean;
};

const ModifierGroupContext = React.createContext<ContextProps | null>(null);
ModifierGroupContext.displayName = "ModifierGroupContext";

type IconProps =
  | {
      icon: React.ComponentType | null | undefined;
      iconTooltip: string | null | undefined;
      iconAriaLabel?: string | null;
      iconShouldOpenModifierGroup?: boolean;
    }
  | {
      icon: React.ComponentType | null | undefined;
      iconTooltip?: never;
      iconAriaLabel: string | null | undefined;
      iconShouldOpenModifierGroup?: boolean;
    }
  | {
      icon?: never;
      iconTooltip?: never;
      iconAriaLabel?: never;
      iconShouldOpenModifierGroup?: never;
    };

type ModifierGroupProps = IconProps & {
  isCollapsible?: boolean;
  isDefaultOpen?: boolean;
  isOpen?: boolean;
  onOpenChange?: (value: boolean) => void;
  hideEndEnhancerOnGroupClosed?: boolean;
  title: string;
  onClick?(isActive: boolean): void;
  isDisabled?: boolean;
  endEnhancer?: React.ReactNode;
  titleEnhancer?: React.ReactNode;
  titleClassName?: string;
  onClose?(): void;
  menuItems?: MenuItem[];
  tooltipText?: string;
  disableMenuTriggerFocusOnClose?: boolean;
};

const ModifierGroup = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ModifierGroupProps>
>(
  (
    {
      title,
      onClick,
      icon,
      iconTooltip,
      isDisabled = false,
      endEnhancer,
      isCollapsible = true,
      isDefaultOpen = true,
      children,
      hideEndEnhancerOnGroupClosed = false,
      titleEnhancer,
      titleClassName,
      iconShouldOpenModifierGroup = false,
      menuItems,
      disableMenuTriggerFocusOnClose = false,
      tooltipText,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useOverridableState(
      isDefaultOpen,
      undefined,
      undefined,
    );
    const hasEnabledMenuItems =
      menuItems &&
      menuItems?.some(
        (menuItem) =>
          hasOwnProperty(menuItem, "disabled") && !menuItem.disabled,
      );

    const endEnhancers = (() => {
      const IconComponent = icon as IconType;
      return (
        <>
          {icon ? (
            <div className="flex flex-row items-center justify-end">
              <IconButton
                size="sm"
                icon={<IconComponent size={20} className="text-muted" />}
                tooltipText={iconTooltip}
                variant="tertiary"
                onClick={() => {
                  onClick?.(true);
                  if (iconShouldOpenModifierGroup) {
                    setIsOpen(true);
                  }
                }}
                disabled={isDisabled}
              />
            </div>
          ) : null}
          {endEnhancer}
          {hasEnabledMenuItems ? (
            <ModifierGroupMenu
              menuItems={menuItems}
              tooltipText={tooltipText}
              onTriggerClick={() => {
                if (!isOpen) {
                  setIsOpen(true);
                }
              }}
              disableMenuTriggerFocusOnClose={disableMenuTriggerFocusOnClose}
            />
          ) : null}
        </>
      );
    })();

    return (
      <ModifierGroupContext.Provider
        value={{ name: title, isCollapsed: isCollapsible && !isOpen }}
      >
        <Group
          ref={ref}
          name={title ?? ""}
          isCollapsible={isCollapsible}
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          className="relative w-full border-b border-slate-200 pb-3 last:border-b-0 last:pb-0"
          header={
            <GroupHeader
              endEnhancer={endEnhancers}
              titleEnhancer={titleEnhancer}
              hideEndEnhancerOnGroupClosed={hideEndEnhancerOnGroupClosed}
            >
              <GroupTitleContainer>
                <GroupTitle className={titleClassName} />
              </GroupTitleContainer>
            </GroupHeader>
          }
        >
          {children}
        </Group>
      </ModifierGroupContext.Provider>
    );
  },
);

ModifierGroup.displayName = "ModifierGroup";

const ModifierGroupMenu: React.FC<{
  menuItems: MenuItem[];
  isDisabled?: boolean;
  onTriggerClick?: () => void;
  tooltipText?: string;
  disableMenuTriggerFocusOnClose?: boolean;
}> = ({
  menuItems,
  isDisabled = false,
  onTriggerClick,
  tooltipText,
  disableMenuTriggerFocusOnClose,
}) => {
  return (
    <Menu
      items={menuItems}
      menuType="normal"
      align="end"
      isDisabled={isDisabled}
      onRequestOpen={onTriggerClick}
      trigger={
        <IconButton
          size="sm"
          icon={<Plus className="text-muted" size={16} />}
          tooltipText={tooltipText}
          variant="tertiary"
        />
      }
      disableTriggerFocusOnClose={disableMenuTriggerFocusOnClose}
    />
  );
};

export default ModifierGroup;
