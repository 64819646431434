import type { DateRange } from "react-day-picker";

import { TZDate } from "@date-fns/tz";
import { format } from "date-fns";

export function hours(hoursToAdd: number) {
  return hoursToAdd * 60 * 60 * 1000;
}

export function minutes(minutesToAdd: number) {
  return minutesToAdd * 60 * 1000;
}

export function convertDaysToMs(days: number) {
  return days * 24 * 60 * 60 * 1000;
}

export function convertMsToDays(ms: number) {
  const msPerDay = 1000 * 60 * 60 * 24;
  return Math.floor(ms / msPerDay);
}

export const dateRangeFormatter = (dateRange: DateRange) => {
  if (!dateRange.from || !dateRange.to) {
    return "Select a date range";
  }
  return `${dateFormatter(dateRange.from)} - ${dateFormatter(dateRange.to)}`;
};

const dateFormatter = (date: Date) => format(date, "LLL dd, y");

export function convertDateToUTC(date: Date) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new TZDate(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    timezone,
  );
}

export function minutesToSeconds(minutes: number) {
  return minutes * 60;
}
