import type { View } from "@editor/components/left-bar/insert-pane/InsertPane";
import type { CategoryCard } from "@editor/components/left-bar/insert-pane/TemplateCategories";
import type { ComponentTemplate } from "@editor/types/component-template";

import * as React from "react";

import { Group } from "@editor/components/common/designSystem/Group";
import { HotkeyIndicator } from "@editor/components/common/HotkeyIndicator";
import {
  ComponentTemplateListItem,
  ComponentTemplateListSkeleton,
} from "@editor/components/left-bar/insert-pane/template-list-items/ComponentTemplateListItem";
import { SavedTemplateListItem } from "@editor/components/left-bar/insert-pane/template-list-items/SavedTemplateListItem";
import {
  SectionTemplateListItem,
  SectionTemplateListSkeleton,
} from "@editor/components/left-bar/insert-pane/template-list-items/SectionTemplateListItem";
import useInfiniteTemplates from "@editor/hooks/useInfiniteTemplates";
import useSearchableTemplateLeftBarCategories from "@editor/hooks/useSearchableTemplateLeftBarCategories";
import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import EmptyState from "@replo/design-system/components/empty-state/EmptyState";
import twMerge from "@replo/design-system/utils/twMerge";
import startCase from "lodash-es/startCase";
import { Search } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";

const SectionTemplatesList = ({
  category,
  searchString,
}: {
  category?: CategoryCard;
  searchString?: string;
}) => {
  const selectedCategories = category?.id ? [category.id] : [];

  const {
    componentTemplatesList,
    fetchNextPage,
    isLoadingInitialPage,
    hasNextPage,
  } = useInfiniteTemplates({
    searchText: searchString,
    includeComponent: true,
    selectedCategories: selectedCategories,
    scope: "global",
    componentCategoryType: ["section"],
    pageSize: 15,
    cursor: 0,
    selectedIndustries: [],
    includeArchivedTemplates: false,
  });

  if (isLoadingInitialPage) {
    return <SectionTemplateListSkeleton hideTitle={Boolean(searchString)} />;
  }

  if (componentTemplatesList?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 h-full">
        <EmptyState
          title="No Results"
          description={
            searchString
              ? `No results matching "${searchString}"`
              : "No results found"
          }
          icon={<Search className="icon-size-default" />}
          size="sm"
        />
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={componentTemplatesList?.length ?? 0}
      next={() => {
        void fetchNextPage();
      }}
      hasMore={hasNextPage}
      scrollableTarget={
        searchString
          ? "insert-pane-search-wrapper"
          : "insert-pane-template-list-wrapper"
      }
      loader={
        <div className={twMerge(searchString ? "mt-[-28px]" : "p-3 pt-0")}>
          <SectionTemplateListSkeleton hideTitle count={1} />
        </div>
      }
      className="no-scrollbar"
      style={{
        overflow: "visible",
      }}
    >
      <div
        className={twMerge("flex flex-col gap-3", searchString ? "" : "p-3")}
      >
        <Group
          key={category?.name ?? ""}
          name={category?.name ?? ""}
          header={
            category?.name ? (
              <span className="typ-button-small">{category?.name}</span>
            ) : null
          }
        >
          <ul
            className={twMerge(
              "flex flex-col gap-2 mb-8",
              searchString ? "" : "mt-3",
            )}
          >
            {componentTemplatesList?.map((componentTemplate) => {
              return (
                <SectionTemplateListItem
                  key={componentTemplate.id}
                  componentTemplate={componentTemplate}
                />
              );
            })}
          </ul>
        </Group>
      </div>
    </InfiniteScroll>
  );
};

const ComponentTemplatesList = ({
  category,
  searchString,
}: {
  category?: CategoryCard;
  searchString?: string;
}) => {
  const leftBarCategories = useSearchableTemplateLeftBarCategories(
    "left-bar",
    searchString ?? "",
  );
  const hasTemplatesForCategory = leftBarCategories.categories.some(
    (category) =>
      category.subCategories.some(
        (subCategory) => subCategory.componentTemplates.length > 0,
      ),
  );

  if (!hasTemplatesForCategory) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 h-full">
        <EmptyState
          title="No Results"
          description={
            searchString
              ? `No results matching "${searchString}"`
              : "No results found"
          }
          icon={<Search className="icon-size-default" />}
          size="sm"
        />
      </div>
    );
  }

  if (searchString) {
    const searchResults: ComponentTemplate[] = [];

    for (const category of leftBarCategories.categories) {
      for (const subCategory of category.subCategories) {
        searchResults.push(...subCategory.componentTemplates);
      }
    }

    return (
      <ul className="h-full">
        <div className="grid grid-cols-2 gap-3 pb-32">
          {searchResults.map((componentTemplate) => (
            <ComponentTemplateListItem
              key={componentTemplate.id}
              componentTemplate={componentTemplate}
            />
          ))}
        </div>
      </ul>
    );
  }

  if (!category) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 h-full">
        <EmptyState
          title="No Results"
          description="No results found"
          icon={<Search className="icon-size-default" />}
          size="sm"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 p-3">
      {category.name &&
        leftBarCategories.categories.map((leftBarCategory) => {
          if (leftBarCategory.mainCategory === category.value) {
            return (
              <>
                {leftBarCategory.subCategories.map((subCategory) => (
                  <Group
                    key={subCategory.subCategory}
                    name={startCase(subCategory.subCategory)}
                    header={
                      <span className="typ-button-small">
                        {startCase(subCategory.subCategory)}
                      </span>
                    }
                  >
                    <ul
                      className="grid grid-cols-2 gap-3 my-3 h-full"
                      key={subCategory.subCategory}
                    >
                      {subCategory.componentTemplates.map(
                        (componentTemplate) => (
                          <ComponentTemplateListItem
                            key={componentTemplate.id}
                            componentTemplate={componentTemplate}
                          />
                        ),
                      )}
                    </ul>
                  </Group>
                ))}
              </>
            );
          }
          return null;
        })}
    </div>
  );
};

const SavedTemplatesList = ({ selectedView }: { selectedView: View }) => {
  const projectId = useEditorSelector(selectProjectId);
  const {
    componentTemplatesList,
    hasNextPage,
    fetchNextPage,
    isLoadingInitialPage,
  } = useInfiniteTemplates({
    storeId: projectId,
    scope: "store",
    includeComponent: true,
    componentCategoryType:
      selectedView === "components" ? ["component"] : ["section"],
    pageSize: 15,
    cursor: 0,
    selectedIndustries: [],
    selectedCategories: [],
    includeArchivedTemplates: false,
  });

  if (isLoadingInitialPage) {
    return selectedView === "components" ? (
      <ComponentTemplateListSkeleton />
    ) : (
      <SectionTemplateListSkeleton />
    );
  }

  if (componentTemplatesList?.length === 0) {
    return (
      <div className="overflow-y-hidden flex flex-col items-center justify-center gap-2 h-full">
        <p className="text-center text-xs font-semibold">No Saved Components</p>
        <p className="text-center text-xs">
          Save a component by selecting it and pressing Command + Shift + S
        </p>
        <HotkeyIndicator hotkey="saveComponentTemplate" title="" />
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={componentTemplatesList?.length ?? 0}
      next={() => void fetchNextPage()}
      hasMore={hasNextPage}
      scrollableTarget="insert-pane-template-list-wrapper"
      loader={
        <div className="p-3 pt-0">
          {selectedView === "components" ? (
            <ComponentTemplateListSkeleton hideTitle count={2} />
          ) : (
            <SectionTemplateListSkeleton hideTitle count={1} />
          )}
        </div>
      }
      style={{
        overflow: "visible",
      }}
    >
      <div className="flex flex-col p-3">
        <Group
          name="Saved"
          isDefaultOpen
          header={<span className="typ-button-small">Saved</span>}
        >
          <ul
            className={twMerge(
              "mt-3",
              selectedView === "components"
                ? "grid grid-cols-2 gap-3 h-full"
                : "flex flex-col gap-2",
            )}
          >
            {componentTemplatesList?.map((componentTemplate) => {
              return (
                <SavedTemplateListItem
                  key={componentTemplate.id}
                  componentTemplate={componentTemplate}
                  selectedView={selectedView}
                />
              );
            })}
          </ul>
        </Group>
      </div>
    </InfiniteScroll>
  );
};

const TemplateList = ({
  selectedView,
  category,
  searchString,
}: {
  selectedView: View;
  category?: CategoryCard;
  searchString?: string;
}) => {
  if (category?.value === "saved") {
    return <SavedTemplatesList selectedView={selectedView} />;
  }

  return selectedView === "components" ? (
    <ComponentTemplatesList category={category} searchString={searchString} />
  ) : (
    <SectionTemplatesList category={category} searchString={searchString} />
  );
};

export default TemplateList;
