import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { ComponentConfig } from "../../components";

import { mapAndConvertComponentStylePropsToStyle } from "../../../shared/utils/breakpoints";

const customProperties = {
  svgWidth: "--rsw",
};

const styleElements = {
  root: {
    defaultStyles: {
      lineHeight: "100%",
    },
    overrideStyles({ component, styleProps }) {
      const size = component.props.size;
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        const onlyHeightAvailable = styles.__hasHeight && !styles.__hasWidth;
        const svgWidth = onlyHeightAvailable ? "auto" : "100%";

        return {
          width: styles.width ?? size,
          height: styles.height ?? size,
          [customProperties.svgWidth]: svgWidth,
        };
      });
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
