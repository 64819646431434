import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { EditorDispatch } from "@editor/store";
import type { ContextMenuActions } from "@editor/types/component-tree";
import type {
  MenuItem,
  MenuItemSection,
} from "@replo/design-system/components/menu/Menu";
import type { AnyAction } from "redux";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { VariantWithState } from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import * as React from "react";

import * as coreActions from "@actions/core-actions";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectComponentDataMapping,
  selectDraftComponentIds,
  selectDraftComponentName,
  selectDraftComponentOrAncestorVariants,
  selectDraftComponentOrDescendantIsOneOfTypes,
  selectNearestComponentWithVariantsId,
  selectRootComponentId,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { canGroupIntoContainer } from "@editor/utils/component";
import useContextMenuActions from "@hooks/useContextMenuActions";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import { successToast } from "@replo/design-system/components/alert/Toast";
import copy from "copy-to-clipboard";
import cloneDeep from "lodash-es/cloneDeep";
import { forEachComponentAndDescendants } from "replo-runtime";
import {
  isAnyComponentAncestorOfType,
  isAnyComponentDescendantOfType,
} from "replo-runtime/shared/utils/component";
import { hasOwnProperty, isEmpty } from "replo-utils/lib/misc";

const MENU_ITEM_SECTIONS = [
  // AI & Generation Section
  { type: "section", items: ["replo-ai"] },

  // Important Contextual Operations Section
  { type: "section", items: ["openCodeEditor", "changeMediaContent"] },

  // Basic Operations Section
  {
    type: "section",
    items: [
      "copy",
      "paste",
      "pasteFigma",
      "duplicate",
      "turnInto",
      "rename",
      "delete",
    ],
  },

  // Component Organization Section
  {
    type: "section",
    items: ["groupContainer", "createTemplate", "exportToSection", "styles"],
  },

  // Styles & Debug Section
  {
    type: "section",
    items: [
      "copyJson",
      "replaceComponentJSON",
      "applyComponentAction",
      "copyAiResponsiveInfo",
      "debug",
    ],
  },
] as const;

export default function useContextMenuItems(
  source: "canvasRightClickMenu" | "componentTree",
  onRename?: (() => void) | null,
) {
  const dispatch = useEditorDispatch();

  const rootComponentId = useEditorSelector(selectRootComponentId);
  const contextMenuActions = useContextMenuActions();
  const applyComponentAction = useApplyComponentAction();
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const activeVariant = useEditorSelector(
    selectDraftComponentOrAncestorVariants,
  ).find((variant) => variant.isActive);
  const componentWithVariantsId = useEditorSelector(
    selectNearestComponentWithVariantsId,
  );
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isDebugMode = useIsDebugMode();
  const draftComponentName = useEditorSelector(selectDraftComponentName);
  const draftComponentOrDescendantIsText = useEditorSelector((state) =>
    selectDraftComponentOrDescendantIsOneOfTypes(state, ["text"]),
  );
  const contextMenuItems = React.useMemo(() => {
    return getContextMenuItems({
      contextMenuActions,
      draftComponentIds,
      activeVariant,
      componentWithVariantsId: componentWithVariantsId ?? undefined,
      source,
      rootComponentId,
      onStartRename: onRename ?? null,
      isDebugMode,
      activeCanvas,
      draftComponentName,
      draftComponentOrDescendantIsText,
      componentDataMapping,
      applyComponentAction,
      dispatch,
    });
  }, [
    contextMenuActions,
    draftComponentIds,
    activeVariant,
    componentWithVariantsId,
    source,
    rootComponentId,
    onRename,
    isDebugMode,
    activeCanvas,
    draftComponentName,
    draftComponentOrDescendantIsText,
    componentDataMapping,
    applyComponentAction,
    dispatch,
  ]);

  return contextMenuItems;
}

function getContextMenuItems(config: {
  contextMenuActions: ContextMenuActions | null;
  draftComponentIds: string[];
  activeVariant: VariantWithState | undefined;
  componentWithVariantsId: string | undefined;
  source: "canvasRightClickMenu" | "componentTree";
  rootComponentId: string | null;
  onStartRename: (() => void) | null;
  isDebugMode?: boolean;
  activeCanvas: EditorCanvas;
  draftComponentName: string | null;
  draftComponentOrDescendantIsText: boolean;
  componentDataMapping: ComponentDataMapping;
  applyComponentAction: (action: UseApplyComponentActionType) => AnyAction;
  dispatch: EditorDispatch;
}): MenuItem[] {
  const {
    contextMenuActions,
    draftComponentIds,
    activeVariant,
    componentWithVariantsId,
    source,
    rootComponentId,
    onStartRename = null,
    isDebugMode = false,
    activeCanvas,
    draftComponentName,
    componentDataMapping,
  } = config;
  const isRootComponentSelected = rootComponentId
    ? draftComponentIds.includes(rootComponentId)
    : false;

  if (!contextMenuActions) {
    return [];
  }

  const {
    handlePaste,
    handlePasteFromFigma,
    handleCopy,
    handleDuplicate,
    handleReplaceComponent,
    handleReplaceAllContentWithPlaceholders,
    getStringFromComponentJson,
    getComponent,
    handleGroupContainer,
    handleGroupDelete,
    handleDelete,
    canDelete,
    handleOpenModal,
    forceSentryError,
    setDraftComponentId,
    handleResetStylesToDefaultState,
    handlePushPropsToDefaultState,
    handleCopyStyles,
    handlePersistStylesToUpstreamDevices,
    resetComponentAlignment,
    getAlignSelf,
    resetStyleOverrides,
    openCodeEditor,
    turnInto,
  } = contextMenuActions;

  function getTurnIntoItems() {
    const allComponentsAreContainers = draftComponentIds.every(
      (id) => componentDataMapping[id]?.type === "container",
    );
    const anyComponentIsTooltip = draftComponentIds.some(
      (id) => componentDataMapping[id]?.type === "tooltip",
    );
    const anyAncestorIsButton = draftComponentIds.some((id) =>
      isAnyComponentAncestorOfType(id, componentDataMapping, "button"),
    );
    const anyChildIsButton = draftComponentIds.some((id) =>
      isAnyComponentDescendantOfType(id, componentDataMapping, "button"),
    );
    const anyAncestorIsTicker = draftComponentIds.some((id) =>
      isAnyComponentAncestorOfType(id, componentDataMapping, "marquee"),
    );
    const anyChildIsTicker = draftComponentIds.some((id) =>
      isAnyComponentDescendantOfType(id, componentDataMapping, "marquee"),
    );
    const anyAncestorIsTooltip = draftComponentIds.some((id) =>
      isAnyComponentAncestorOfType(id, componentDataMapping, "tooltip"),
    );
    const anyChildIsTooltip = draftComponentIds.some((id) =>
      isAnyComponentDescendantOfType(id, componentDataMapping, "tooltip"),
    );

    return [
      {
        id: "turnIntoButton",
        type: "leaf",
        title: "Button",
        variant: "default",
        size: "sm",
        onSelect: () => turnInto({ type: "button" }),
        disabled:
          isRootComponentSelected ||
          !allComponentsAreContainers ||
          anyAncestorIsButton ||
          anyChildIsButton,
      },
      {
        id: "turnIntoProduct",
        type: "leaf",
        title: "Product",
        variant: "default",
        size: "sm",
        onSelect: () => turnInto({ type: "product" }),
        disabled: isRootComponentSelected || !allComponentsAreContainers,
      },
      {
        id: "turnIntoTicker",
        type: "leaf",
        title: "Ticker",
        variant: "default",
        size: "sm",
        onSelect: () =>
          turnInto({ type: "ticker", selectedComponentIds: draftComponentIds }),
        disabled:
          isRootComponentSelected ||
          !allComponentsAreContainers ||
          anyAncestorIsTicker ||
          anyChildIsTicker,
      },
      {
        id: "addTooltipToComponent",
        type: "leaf",
        title: "Tooltip Trigger",
        variant: "default",
        size: "sm",
        onSelect: () =>
          turnInto({
            type: "tooltipTrigger",
            selectedComponentIds: draftComponentIds,
          }),
        disabled:
          isRootComponentSelected ||
          anyComponentIsTooltip ||
          anyAncestorIsTooltip ||
          anyChildIsTooltip,
      },
    ];
  }

  function getStylesItems() {
    const items = [
      {
        type: "leaf",
        id: "copyStyles",
        title: "Copy Styles",
        variant: "command",
        size: "sm",
        disabled: draftComponentIds.length !== 1,
        onSelect: () => {
          const [componentId] = draftComponentIds;
          if (!componentId) {
            return;
          }
          handleCopyStyles(componentId, activeVariant?.id);
        },
        shortcut: "copyStyles",
      },
      {
        type: "leaf",
        id: "pasteStyles",
        title: "Paste Styles",
        variant: "command",
        size: "sm",
        disabled: !draftComponentIds.length,
        onSelect: () => {
          void handlePaste("alt");
        },
        shortcut: "pasteStyles",
      },
      {
        type: "leaf",
        id: "resetContentsAlignment",
        title: "Reset Content Alignment",
        variant: "default",
        size: "sm",
        disabled: draftComponentIds.some((id) => !getAlignSelf(id)),
        onSelect: () => {
          for (const id of draftComponentIds) {
            resetComponentAlignment(id);
          }
        },
      },
      {
        type: "leaf",
        id: "resetStyleOverrides",
        title: "Reset Style Overrides",
        variant: "default",
        size: "sm",
        disabled: activeCanvas === "desktop" || !draftComponentIds.length,
        onSelect: () => {
          for (const id of draftComponentIds) {
            resetStyleOverrides(id, activeVariant?.id);
          }
        },
      },
    ];

    if (isDebugMode) {
      items.push({
        type: "leaf",
        id: "stripNonDesktopStyles",
        title: "Strip Non-Desktop Styles",
        variant: "default",
        size: "sm",
        disabled: !draftComponentIds.length,
        onSelect: () => {
          for (const id of draftComponentIds) {
            const component = cloneDeep(getComponent(id));
            if (!component) {
              return "Component not found";
            }

            forEachComponentAndDescendants(component, (component) => {
              delete component.props["style@md"];
              delete component.props["style@sm"];
            });
            handleReplaceComponent(id, component);
          }
        },
      });
    }

    if (
      draftComponentIds.length === 1 &&
      activeVariant &&
      componentWithVariantsId
    ) {
      items.push(
        {
          id: "resetStylesToDefaultState",
          type: "leaf",
          title: "Reset Styles to Default State",
          variant: "default",
          size: "sm",
          disabled: activeVariant.name === "default",
          onSelect: () => {
            const [componentId] = draftComponentIds;
            handleResetStylesToDefaultState(componentId!, activeVariant.id);
          },
        },
        {
          id: "pushPropsToDefaultState",
          type: "leaf",
          title: "Push Changes to Default State",
          variant: "default",
          size: "sm",
          disabled: activeVariant.name === "default",
          onSelect: () => {
            const [componentId] = draftComponentIds;
            handlePushPropsToDefaultState(componentId!, activeVariant.id);
          },
        },
      );
    }

    return items;
  }

  function getOtherDebugOptionsItems() {
    return [
      {
        type: "leaf",
        id: "copyComponentId",
        variant: "default",
        size: "sm",
        disabled: draftComponentIds.length !== 1,
        onSelect: () => {
          const [componentId] = draftComponentIds;
          if (!componentId) {
            return;
          }
          copy(componentId);
          successToast("Component ID Copied", "");
        },
        title: "Copy Component ID",
      },
      {
        id: "setDraftElement",
        type: "leaf",
        onSelect: () => {
          const id = window.prompt();
          if (id) {
            setDraftComponentId(id);
          }
        },
        title: "Set Draft Component ID",
        variant: "default",
        size: "sm",
      },
      {
        id: "cleanComponentWithoutImages",
        type: "leaf",
        title: "Replace all content with placeholders",
        variant: "default",
        size: "sm",
        disabled: draftComponentIds.length !== 1,
        onSelect: () => {
          const [componentId] = draftComponentIds;
          if (!componentId) {
            return;
          }
          handleReplaceAllContentWithPlaceholders(componentId);
        },
      },
      {
        id: "forceSentryError",
        type: "leaf",
        title: "Force Sentry Error",
        variant: "default",
        size: "sm",
        onSelect: () => {
          forceSentryError();
        },
      },
      {
        type: "nested",
        title: "Persist Styles Upstream",
        items: [
          {
            id: "persistStylesToUpstreamDevicesExcludingDescendants",
            type: "leaf",
            title: "Copy Styles to Larger Devices (This Component Only)",
            variant: "default",
            size: "sm",
            disabled: draftComponentIds.length !== 1,
            onSelect: () => {
              const [componentId] = draftComponentIds;
              if (!componentId) {
                return;
              }
              handlePersistStylesToUpstreamDevices(componentId, false);
            },
          },
          {
            id: "persistStylesToUpstreamDevicesExcludingDescendants",
            type: "leaf",
            title: "Copy Styles to Larger Devices (Include Descendants)",
            variant: "default",
            size: "sm",
            disabled: draftComponentIds.length !== 1,
            onSelect: () => {
              const [componentId] = draftComponentIds;
              if (!componentId) {
                return;
              }
              handlePersistStylesToUpstreamDevices(componentId, true);
            },
          },
        ],
      },
    ];
  }

  const unorderedItems: MenuItem[] = [
    // Open Code Editor
    draftComponentIds.length === 1 && openCodeEditor
      ? ([
          {
            id: "openCodeEditor",
            type: "leaf",
            variant: "default",
            size: "sm",
            title: "Open Code Editor",
            onSelect: () => openCodeEditor(),
          },
        ] as MenuItem[])
      : [],

    // Group Into Container, Create Saved Component, Export To Section
    [
      {
        type: "leaf",
        id: "groupContainer",
        variant: "command",
        size: "sm",
        title: "Group Into Container",
        disabled:
          !draftComponentIds.length ||
          isRootComponentSelected ||
          !canGroupIntoContainer(draftComponentIds, componentDataMapping)
            .canGroupIntoContainer,
        onSelect: () => handleGroupContainer(draftComponentIds),
        shortcut: "groupIntoContainer",
      },
      {
        id: "createTemplate",
        type: "leaf",
        variant: "command",
        size: "sm",
        title: "Save",
        disabled: draftComponentIds.length !== 1,
        onSelect: () => {
          handleOpenModal({
            type: "saveTemplateModal",
            props: {
              initialName: draftComponentName,
            },
          });
        },
        shortcut: "saveComponentTemplate",
      },
      {
        id: "exportToSection",
        type: "leaf",
        variant: "default",
        size: "sm",
        title: "Export To Section",
        disabled: draftComponentIds.length !== 1,
        onSelect: () => {
          handleOpenModal({
            type: "exportToSectionModal",
          });
        },
      },
    ] as MenuItem[],

    // Turn Into
    draftComponentIds.length
      ? ([
          {
            id: "turnInto",
            type: "nested",
            variant: "expandable",
            size: "sm",
            title: "Turn Into",
            items: getTurnIntoItems(),
          },
        ] as MenuItem[])
      : [],

    // Copy, Paste, Paste From Figma
    [
      {
        type: "leaf",
        id: "copy",
        variant: "command",
        size: "sm",
        title: `Copy${draftComponentIds.length > 1 ? ` (${draftComponentIds.length})` : ""}`,
        shortcut: "copy",
        disabled: !draftComponentIds.length,
        onSelect: () => handleCopy(draftComponentIds),
      },
      {
        type: "leaf",
        id: "paste",
        variant: "command",
        size: "sm",
        title: "Paste",
        shortcut: "paste",
        onSelect: () => {
          void handlePaste("normal");
        },
      },
      {
        type: "leaf",
        id: "pasteFigma",
        variant: "default",
        size: "sm",
        title: "Paste from Figma",
        onSelect: () => {
          void handlePasteFromFigma();
        },
      },
    ] as MenuItem[],

    // Rename, Duplicate, Delete
    draftComponentIds.length
      ? ([
          {
            type: "leaf",
            id: "rename",
            variant: "default",
            size: "sm",
            title: "Rename",
            disabled:
              draftComponentIds.length !== 1 ||
              isRootComponentSelected ||
              !onStartRename,
            onSelect: () => onStartRename!(),
          },
          {
            type: "leaf",
            id: "duplicate",
            variant: "command",
            size: "sm",
            title: "Duplicate",
            disabled: !draftComponentIds.length || isRootComponentSelected,
            onSelect: () => {
              for (const id of draftComponentIds) {
                handleDuplicate(id, source);
              }
            },
            shortcut: "duplicate",
          },
          {
            type: "leaf",
            id: "delete",
            variant: "command",
            size: "sm",
            title: `Delete${draftComponentIds.length > 1 ? ` (${draftComponentIds.length})` : ""}`,
            disabled:
              !draftComponentIds.length ||
              draftComponentIds.some((id) => !canDelete(id)),
            onSelect: () => {
              if (draftComponentIds.length > 1) {
                handleGroupDelete(draftComponentIds);
              } else {
                const [componentId] = draftComponentIds;
                handleDelete(componentId!, source);
              }
            },
            shortcut: "delete",
          },
        ] as MenuItem[])
      : [],

    // Styles
    draftComponentIds.length
      ? ([
          {
            id: "styles",
            type: "nested",
            variant: "expandable",
            size: "sm",
            title: "Styles",
            items: getStylesItems(),
          },
        ] as MenuItem[])
      : [],

    // Debug mode
    isDebugMode
      ? ([
          {
            type: "leaf",
            id: "copyJson",
            variant: "default",
            size: "sm",
            title: "Copy Component JSON",
            onSelect: () => {
              const [componentId] = draftComponentIds;
              if (!componentId) {
                return;
              }
              copy(getStringFromComponentJson(componentId));
              successToast("Component JSON Copied", "");
            },
          },
          {
            id: "replaceComponentJSON",
            type: "leaf",
            variant: "default",
            size: "sm",
            disabled: draftComponentIds.length !== 1,
            onSelect: () => {
              const [componentId] = draftComponentIds;
              if (!componentId) {
                return;
              }
              let componentJSON = window.prompt();
              if (componentJSON) {
                try {
                  componentJSON = JSON.parse(componentJSON);
                } catch {}

                if (componentJSON) {
                  handleReplaceComponent(componentId, componentJSON);
                }
              }
            },
            title: "Set Component JSON",
          },
          {
            id: "applyComponentAction",
            type: "leaf",
            isDisabled: false,
            title: (
              <div
                onClick={() => {
                  const [componentId] = draftComponentIds;
                  if (!componentId) {
                    return;
                  }
                  let componentAction = window.prompt();
                  if (componentAction) {
                    try {
                      componentAction = JSON.parse(componentAction);
                    } catch {
                      // Some error here
                    }

                    if (componentAction) {
                      // @ts-ignore (TODO, Yuxin, 2025-03-02): Fix this
                      dispatch(
                        coreActions.applyComponentAction({
                          activeCanvas: "desktop",
                          type: "applyCompositeAction",
                          // @ts-ignore (TODO, Yuxin, 2025-03-02): Fix this
                          value: componentAction?.length
                            ? componentAction
                            : [componentAction],
                        }),
                      );
                    }
                  }
                }}
              >
                Apply Component Action
              </div>
            ),
          },
          {
            type: "leaf",
            id: "copyAiResponsiveInfo",
            variant: "default",
            size: "sm",
            disabled: draftComponentIds.length !== 1,
            title: "Copy AI Responsive Info",
            onSelect: () => {
              const [componentId] = draftComponentIds;
              if (!componentId) {
                return;
              }
              const component = cloneDeep(getComponent(componentId));
              if (!component) {
                return "Component not found";
              }
              const actions: object[] = [];

              forEachComponentAndDescendants(component, (component) => {
                // NOTE (Gabe 2024-07-09): We merge md and sm styles here
                // because we currently only care about mobile responsivity. If
                // we want to have tablet specific styles we'll have to generate
                // multiple actions.
                if (
                  !isEmpty(component.props["style@md"]) ||
                  !isEmpty(component.props["style@sm"])
                ) {
                  actions.push({
                    componentId: component.id,
                    type: "setStylesMobile",
                    value: {
                      ...component.props["style@md"],
                      ...component.props["style@sm"],
                    },
                  });
                }
                delete component.props["style@md"];
                delete component.props["style@sm"];
              });

              const result = { component, actions };
              copy(JSON.stringify(result, null, 2));
              successToast("AI Responsive Info Copied", "");
            },
          },
          {
            type: "nested",
            id: "debug",
            variant: "expandable",
            size: "sm",
            title: "Other Debug Options",
            items: getOtherDebugOptionsItems(),
          },
        ] as MenuItem[])
      : [],
  ].flat();
  // Instead of sorting, we'll organize items into sections
  const itemsById = Object.fromEntries(
    unorderedItems
      .filter((item) => hasOwnProperty(item, "id"))
      .map((item) => [item.id, item]),
  );

  // Create sections with ordered items
  const sections: MenuItemSection[] = MENU_ITEM_SECTIONS.map((section) => ({
    type: "section" as const,
    items: section.items
      .map((id) => itemsById[id])
      .filter((item) => item !== undefined),
  })).filter((section) => section.items.length > 0);

  return sections;
}
