import * as React from "react";

import { routes } from "@editor/utils/router";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";

export const EditorNoAccessToProjectScreen: React.FC = () => {
  return (
    <div id="app" className="h-screen w-full bg-subtle">
      <Modal
        isOpen={true}
        onOpenChange={() => {}}
        title="Request access to this project"
        description="You don't have access to this Replo project."
        size="sm"
        closeOnOverlayClick={false}
        footer={
          <Button variant="primary" size="base" to={routes.home.root}>
            Back to Dashboard
          </Button>
        }
      >
        <p className="typ-body-base">
          Please request access from the project admin. You can view this
          project once your request is approved.
        </p>
      </Modal>
    </div>
  );
};
