import type {
  Gradient,
  GradientOrSolidOnChangeProps,
  GradientValue,
  SolidOrGradient,
  SolidValue,
} from "replo-runtime/shared/types";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { SavedStyleColorAttributes } from "schemas/generated/savedStyles";
import type { PreviewableProperty } from "schemas/preview";

import * as React from "react";

import GradientSelector from "@editor/components/editor/page/element-editor/components/GradientSelector";
import SolidColorSelector from "@editor/components/editor/page/element-editor/components/SolidColorSelector";
import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { useInCanvasPreview } from "@editor/utils/preview";
import DynamicDataValueIndicator from "@editorExtras/DynamicDataValueIndicator";
import { hasDynamicData } from "@editorModifiers/utils";

import { isDynamicDataValue } from "replo-runtime";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import {
  cssGradientToGradient,
  isCssGradient,
} from "replo-runtime/shared/utils/gradient";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { hasOwnProperty, isNotNullish } from "replo-utils/lib/misc";

import { DynamicDataSelector } from "../../../DynamicDataSelector";

type DynamicColorModifierProps = {
  gradientSelectionType: "color" | "backgroundColor" | null;
  field: string;
  value?: string | ReploMixedStyleValue;
  previewProperty?: PreviewableProperty;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  popoverTitle?: string;
  gradientData?: Gradient | ReploMixedStyleValue;
  popoverSideOffset?: number;
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  showSavedStyles?: boolean;
};

type DynamicColorSelectorProps = {
  hasDynamicData?: boolean;
  field: string;
  previewProperty?: PreviewableProperty;
  openDynamicData?: (attribute: string) => void;
  onRemove?(): void;
  popoverTitle?: string;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  componentId: string;
  popoverSideOffset?: number;
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  showSavedStyles?: boolean;
  onSelectSavedStyle?: (value: string) => void;
} & GradientOrSolidOnChangeProps;

export const DynamicColorSelector = (props: DynamicColorSelectorProps) => {
  const isDraggingRef = React.useRef(false);
  const applyComponentAction = useApplyComponentAction();
  const {
    enableCanvasPreviewCSSProperties,
    disableCanvasPreviewCSSProperties,
    setPreviewCSSPropertyValue,
  } = useInCanvasPreview();

  const { colorShopStyles: colorSavedStyles } = useShopStyles();

  const attribute = props.field.replace("style.", "");
  const value =
    props.value != null &&
    typeof props.value === "object" &&
    !isMixedStyleValue(props.value) &&
    props.value.type === "solid"
      ? props.value.color
      : props.value;

  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleColorAttributes>(
      String(value) ?? null,
    )?.color;

  const logEvent = useLogAnalytics();

  const onSelectSavedStyle =
    props.onSelectSavedStyle ??
    ((value: string) => {
      const savedStyle = getSavedStyleValue(colorSavedStyles, value);
      if (savedStyle?.attributes.color?.includes("linear-gradient")) {
        applyComponentAction({
          type: "setStyles",
          value: {
            [attribute]: "alchemy:gradient",
            __reploGradient__color__design_library: value,
          },
        });
      } else {
        applyComponentAction({
          type: "setStyles",
          value: {
            [attribute]: value,
          },
        });
      }

      logEvent("library.style.apply", {
        modifier: attribute,
        type: "color",
      });
    });

  // Note (Noah, 2022-03-14): We need this useEffect to disable the canvas preview
  // for colors because the color modifier is debounced - if we didn't have this
  // and just disabled the preview on dragEnd like normal, then there would be a time
  // before the debounced updated processed but after the preview was disabled where
  // we'd actually show the old color value. Instead, we just disable the preview
  // any time the value changes (i.e., when the debounce processes), unless the user
  // is currently dragging
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (!isDraggingRef.current && props.previewProperty) {
      disableCanvasPreviewCSSProperties([props.previewProperty]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");

  // Note (Noah, 2022-03-15): If the value contains handlebars, that means it's a
  // dynamic data value (the user set it from a data table or something instead of
  // specifying a color manually), so render a dynamic data indicator
  if (typeof value === "string" && isDynamicDataValue(value)) {
    return (
      <>
        {isNewDynamicData ? (
          <DynamicDataSelector
            targetType={DynamicDataTargetType.TEXT_COLOR}
            layoutClassName="w-full"
            initialPath={value.split(".")}
            trigger={
              <DynamicDataValueIndicator
                type="color"
                templateValue={value}
                onRemove={() => props.onRemove?.()}
              />
            }
            onChange={(value) => {
              props.onChange(value as (string | null) & SolidOrGradient);
            }}
          />
        ) : (
          <DynamicDataValueIndicator
            type="color"
            templateValue={value}
            onClick={() => {
              props.openDynamicData?.(attribute);
            }}
            onRemove={() => props.onRemove?.()}
            componentId={props.componentId}
          />
        )}
      </>
    );
  }

  const dragProps = {
    onDragEnd: (e: React.MouseEvent) => {
      isDraggingRef.current = false;
      props.onDragEnd?.(e);
    },
    onDragStart: (e: React.MouseEvent) => {
      isDraggingRef.current = true;

      if (
        props.previewProperty &&
        isNotNullish(value) &&
        typeof value === "string"
      ) {
        enableCanvasPreviewCSSProperties([props.previewProperty], value);
        setPreviewCSSPropertyValue([props.previewProperty], value);
      }

      props.onDragStart?.(e);
    },
    onPreviewChange: (value: any) => {
      if (typeof value === "string") {
        if (props.previewProperty && value) {
          setPreviewCSSPropertyValue([props.previewProperty], value);
        }
      } else if (
        hasOwnProperty(value, "type") &&
        hasOwnProperty(value, "color") &&
        props.previewProperty
      ) {
        setPreviewCSSPropertyValue([props.previewProperty], value.color);
      }
      props.onPreviewChange?.(value);
    },
  };

  if (props.allowsGradientSelection) {
    const propertyValue = deletedSavedStyleValue
      ? (getColorValueFromSavedStyles(
          deletedSavedStyleValue,
          value,
        ) as SolidOrGradient | null)
      : props.value;

    return (
      <GradientSelector
        isPopoverOpen={props.isPopoverOpen}
        onOpenPopoverChange={props.onOpenPopoverChange}
        value={propertyValue}
        onChange={props.onChange}
        openDynamicData={() => props.openDynamicData?.(attribute)}
        popoverTitle={props.popoverTitle}
        onRemove={() => props.onRemove?.()}
        popoverSideOffset={props.popoverSideOffset}
        onSelectSavedStyle={onSelectSavedStyle}
        showSavedStyles={props.showSavedStyles}
        {...dragProps}
      />
    );
  }

  const propertyValue = deletedSavedStyleValue
    ? (getColorValueFromSavedStyles(deletedSavedStyleValue, value) as
        | string
        | null)
    : props.value;

  return (
    <SolidColorSelector
      isPopoverOpen={props.isPopoverOpen}
      onOpenPopoverChange={props.onOpenPopoverChange}
      value={propertyValue}
      onChange={props.onChange}
      openDynamicData={() => props.openDynamicData?.(attribute)}
      popoverTitle={props.popoverTitle}
      popoverSideOffset={props.popoverSideOffset}
      onSelectSavedStyle={onSelectSavedStyle}
      showSavedStyles={props.showSavedStyles}
      {...dragProps}
    />
  );
};

const DynamicColorModifier: React.FC<
  React.PropsWithChildren<
    DynamicColorModifierProps & { onChange: Function; onRemove?(): void }
  >
> = ({
  gradientSelectionType,
  previewProperty,
  onDragEnd,
  onDragStart,
  onRemove,
  popoverTitle,
  field,
  value,
  onChange,
  gradientData,
  popoverSideOffset,
  isPopoverOpen,
  onOpenPopoverChange,
  showSavedStyles,
}) => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();

  const attribute = field.replace("style.", "");

  if (!draftComponent) {
    return null;
  }

  const openDynamicData = (attribute: string) => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT_COLOR,
        referrerData: {
          type: "style",
          styleAttribute: attribute,
        },
        initialPath: !isMixedStyleValue(value)
          ? getPathFromVariable(value)
          : undefined,
      },
    });
  };

  const handleRemove = (attribute: string) => {
    applyComponentAction({
      type: "setStyles",
      value: {
        [attribute]: null,
        // NOTE (Fran 2024-11-27): Remove gradient data when remove color
        __alchemyGradient__color__tilt: null,
        __alchemyGradient__color__stops: null,
        __reploGradient__color__design_library: null,
      },
    });
  };

  if (gradientSelectionType !== null) {
    let colorValue: GradientOrSolidOnChangeProps["value"] = null;
    if (isMixedStyleValue(value)) {
      colorValue = value;
    } else if (value === "alchemy:gradient") {
      if (isMixedStyleValue(gradientData)) {
        colorValue = gradientData;
      } else {
        colorValue = {
          type: "gradient",
          gradient: gradientData!,
        };
      }
    } else {
      colorValue = { type: "solid", color: value };
    }

    return (
      <DynamicColorSelector
        isPopoverOpen={isPopoverOpen}
        onOpenPopoverChange={onOpenPopoverChange}
        previewProperty={previewProperty}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        hasDynamicData={hasDynamicData(draftComponent?.id)}
        field={field}
        value={colorValue}
        allowsGradientSelection
        onChange={(value: any) => onChange?.(value as SolidOrGradient)}
        onRemove={() => {
          if (onRemove) {
            onRemove();
          } else {
            handleRemove(attribute);
          }
        }}
        openDynamicData={openDynamicData}
        popoverTitle={popoverTitle}
        componentId={draftComponent.id}
        popoverSideOffset={popoverSideOffset}
        showSavedStyles={showSavedStyles}
      />
    );
  }
  return (
    <DynamicColorSelector
      isPopoverOpen={isPopoverOpen}
      onOpenPopoverChange={onOpenPopoverChange}
      previewProperty={previewProperty}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      hasDynamicData={hasDynamicData(draftComponent?.id)}
      field={field}
      value={value ?? null}
      allowsGradientSelection={false}
      onChange={(value: any) => onChange?.(value as SolidOrGradient)}
      onRemove={() => {
        if (onRemove) {
          onRemove();
        } else {
          handleRemove(attribute);
        }
      }}
      openDynamicData={openDynamicData}
      popoverTitle={popoverTitle}
      componentId={draftComponent.id}
      popoverSideOffset={popoverSideOffset}
      showSavedStyles={showSavedStyles}
    />
  );
};

function getColorValueFromSavedStyles(
  deletedSavedStyleValue: string,
  value: string | GradientValue | ReploMixedStyleValue | null | undefined,
) {
  if (
    typeof value === "string" &&
    isDynamicDesignLibraryValue(value) &&
    isCssGradient(deletedSavedStyleValue)
  ) {
    return {
      type: "gradient",
      gradient: cssGradientToGradient(deletedSavedStyleValue),
    } as GradientValue;
  }

  if (
    typeof value === "string" &&
    isDynamicDesignLibraryValue(value) &&
    deletedSavedStyleValue
  ) {
    return {
      type: "solid",
      color: deletedSavedStyleValue,
    } as SolidValue;
  }

  return value ?? null;
}

export default DynamicColorModifier;
