import type { UrlFormValues } from "schemas/url";

import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { useGetCurrentStepResultsData } from "@components/flows/hooks/useGetCurrentStepResultsData";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import { useReploFlowsStepContext } from "@editor/components/flows/context/ReploFlowsStepContext";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { urlFormSchema } from "schemas/url";

import StepSubtitle from "./components/StepSubtitle";

const ImportFromUrlStep: React.FC = () => {
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.import-from-url">();
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const [searchParams] = useSearchParams();
  const shopify_shop_url = searchParams.get("shopify_shop_url");

  const initialUrl = stepResultsData?.url ?? shopify_shop_url ?? "";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      url: initialUrl,
    },
    resolver: zodResolver(urlFormSchema),
  });

  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const onSubmit = ({ url }: UrlFormValues) => {
    if (currentStep) {
      if (url) {
        // NOTE (Gabe 2024-10-28): We are prefetching these queries because they are
        // slow. This way we cut off a bit of the time that the user will have to wait
        // in later steps.
        void trpcUtils.ai.generateBrandDetailsAndIndustry.prefetch(
          { url },
          { gcTime: Number.POSITIVE_INFINITY },
        );
        void trpcUtils.ai.generatePrimaryStyles.prefetch(
          { url },
          { gcTime: Number.POSITIVE_INFINITY },
        );
      }
      submitStep(
        currentStep.id,
        currentStep.type,
        {
          url,
        },
        ({ instance, nextStep }) => {
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          });
        },
      );
    }
  };

  const urlError = errors.url?.message;

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/styles.png" />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div>
          <StepTitle>What's your brand's URL?</StepTitle>
          <StepSubtitle>
            We will use this to import your styles and brand details.
          </StepSubtitle>
        </div>
        <div className="grid grid-cols-1 gap-6">
          <LabeledControl
            label="Website URL"
            className="text-default font-medium"
            size="base"
          >
            <div className="flex flex-col gap-2">
              <Input
                aria-invalid={Boolean(urlError) ? "true" : undefined}
                aria-describedby={Boolean(urlError) ? "error-url" : undefined}
                autoComplete="off"
                placeholder="https://www.mywebsite.com"
                {...register("url", {
                  required: "Url is required",
                })}
                type="text"
                size="base"
                validityState={Boolean(urlError) ? "invalid" : "valid"}
                autoFocus
              />
              {urlError && <ErrorMessage id="error-url" error={urlError} />}
            </div>
          </LabeledControl>
        </div>
        <FlowActionButtons
          shouldDisableNextButton={false}
          handleSkip={() => {
            onSubmit({ url: "" });
          }}
        />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

export default ImportFromUrlStep;
