import type { editor } from "monaco-editor";

import * as React from "react";

import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";

import Editor, { useMonaco } from "@monaco-editor/react";
import InlineAlert from "@replo/design-system/components/alert/InlineAlert";
import debounce from "lodash-es/debounce";

const ElementCustomCssEditor: React.FC = () => {
  const {
    errorMapping,
    setErrors: setElementErrors,
    clearErrors,
  } = useElementEditorErrorContext();
  const { element, onChangeElement } = useElementEditorDataContext();

  const [cssErrors, setCssErrors] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (cssErrors.length > 0) {
      setElementErrors("customCss", ["invalidCustomCss"]);
    } else if (errorMapping["customCss"]?.includes("invalidCustomCss")) {
      clearErrors("customCss", ["invalidCustomCss"]);
    }
  }, [cssErrors, clearErrors, setElementErrors, errorMapping]);

  const monaco = useMonaco();
  monaco?.editor.defineTheme("replo", {
    base: "vs",
    colors: {
      "editor.background": "#f1f5f9", // slate-100
      "editor.lineHighlightBackground": "#f1f5f9",
    },
    inherit: true,
    rules: [],
  });

  const handleCSSContentChange = (value: string) => {
    onChangeElement((element) => ({
      ...element,
      customCss: value,
    }));
  };

  const handleErrors = debounce((errors: editor.IMarker[]) => {
    setCssErrors(
      errors.map(
        ({ endLineNumber, endColumn, message }) =>
          `error on line ${endLineNumber} at column ${endColumn}: ${message}`,
      ),
    );
  }, 300);

  return (
    <LabeledControl label="Custom CSS" id="element-data-css" size="sm">
      <p className="typ-body-small text-muted">
        This CSS will be inserted along with all other styles generated for your
        Replo content.
      </p>
      <div
        className="flex flex-col gap-2 rounded overflow-hidden"
        data-testid="custom-css-editor"
      >
        <Editor
          height="300px"
          defaultLanguage="css"
          defaultValue={element.customCss ?? ""}
          onValidate={handleErrors}
          onChange={(value) => handleCSSContentChange(String(value))}
          options={{
            minimap: { enabled: false },
            scrollbar: { verticalScrollbarSize: 0 },
            lineNumbers: "off",
            lineDecorationsWidth: 0,
            padding: { top: 16 },
          }}
          theme="replo"
        />
      </div>
      {element.customCss && cssErrors.length > 0 && (
        <InlineAlert variant="error" multiline>
          <div className="flex flex-col gap-2">
            <p>
              Replo detected CSS which is invalid. This may cause your CSS to
              not be saved. If you see this page not working correctly, please
              reach out to support@replo.app with any questions.
            </p>
            <ul className="flex flex-col gap-1">
              {cssErrors.map((error) => (
                <li key={error} style={{ listStyleType: "circle" }}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        </InlineAlert>
      )}
    </LabeledControl>
  );
};

export default ElementCustomCssEditor;
