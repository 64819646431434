import type {
  Gradient,
  MetafieldDefinition,
  MetafieldType,
  SwatchValue,
} from "replo-runtime/shared/types";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { Context } from "replo-runtime/store/ReploVariable";
import type { Formatter } from "schemas/dynamicData";

import React from "react";

import { filterDeep } from "deepdash-es/standalone";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import isObject from "lodash-es/isObject";
import pickBy from "lodash-es/pickBy";
import uniq from "lodash-es/uniq";
import {
  Palette as ColorIcon,
  DollarSign as CurrencyIcon,
  Hash as HashIcon,
  Image as ImageIcon,
  Link as LinkIcon,
  List as ListIcon,
  ShoppingBag as ProductIcon,
  Type as TextIcon,
} from "lucide-react";
import { isColor } from "replo-editor/src/editor/utils/string";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isGradient } from "replo-runtime/shared/types";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { extractUpdatedDefaultDynamicDataPriceInfo } from "replo-runtime/shared/utils/dynamic-data";
import {
  extractHandlebarsContent,
  findBalancedDoubleBrackets,
} from "replo-runtime/store/ReploVariable";
import { exhaustiveSwitch, hasOwnProperty } from "replo-utils/lib/misc";
import { isValidHttpUrl } from "replo-utils/lib/url";
import { formatterSchema } from "schemas/dynamicData";
import { validate as uuidValidate } from "uuid";
import { z } from "zod";

/*
 * NEW
 */
// WIP - more types to come later
type NewDynamicDataType =
  | "text"
  | "price"
  | "image"
  | "list"
  | "number"
  | "product"
  | "color"
  | "url";

// Type for the allowed attributes in the dynamic data tree
export type DynamicDataAttribute = {
  key: string;
  displayName: string;
  description?: string;
  type: NewDynamicDataType;
};

// Type for the dynamic data tree category
type DynamicDataTreeCategory = {
  categoryName: string;
  categoryDescription?: string;
  // NOTE (Jackson, 2025-03-25): parentKey is null if we need to select data
  // from the top level of the attributes hierarchy
  parentKey: string | null;
  allowedAttributes: DynamicDataAttribute[];
};

/*
 * OLD
 */
type DynamicDataType =
  | "productOnly"
  | "productVariant"
  | "productOption"
  | "sellingPlan"
  | "dataTableRow"
  | "imageList"
  | "list"
  | "text"
  | "text/color"
  | "text/currency"
  | "text/url"
  | "image"
  | "object"
  | "swatches"
  | "text/integer"
  | "quantity"
  | "?";

type DynamicDataValue = {
  type: DynamicDataType;
  displayValue: string | null;
  displayName: string | null;
};

// Smart fields are fields that should always be present on the list of options,
// even if they are not currently present as dynamic data.
type SmartField = {
  key: string;
  type: DynamicDataType;
};

const SmartFields: {
  product: SmartField[];
  variant: SmartField[];
  sellingPlan: SmartField[];
} = {
  product: [
    { key: "description", type: "text" },
    { key: "featured_image", type: "text/url" },
  ],
  sellingPlan: [{ key: "_selectedSellingPlan", type: "sellingPlan" }],
  variant: [{ key: "featuredImage", type: "text/url" }],
};

function extractSwatchImageLists(
  swatchesObj: Record<string, SwatchValue>,
  parentKey: string,
  optionName?: string,
) {
  return Object.entries(swatchesObj ?? {}).reduce(
    (imageLists: DynamicDataAttribute[], [swatchName, swatchValue]) => {
      if (swatchValue?.imageList) {
        imageLists.push({
          key: `${parentKey}.${optionName ? `${optionName}.` : ""}_swatches.${swatchName}.imageList`,
          displayName: `${swatchName} Swatch Image List`,
          description: `Images for ${swatchName} swatch`,
          type: "image",
        });
      }
      return imageLists;
    },
    [],
  );
}

function extractSwatchImageAndColors(
  swatchesObj?: Record<string, SwatchValue>,
) {
  if (!swatchesObj) {
    return null;
  }
  return Object.entries(swatchesObj).reduce(
    (swatchesData, [swatchName, swatchValue]) => {
      if (swatchValue.color) {
        swatchesData.colors.push(swatchName);
      }
      if (swatchValue.image) {
        swatchesData.images.push(swatchName);
      }
      return swatchesData;
    },
    { images: [], colors: [] } as {
      images: string[];
      colors: string[];
    },
  );
}

// NOTE (Matt 2025-03-07): We need to sort the formatters to get this ideal order.
// The reason being that some formatters make sense to apply before others,
// particularly when it comes to price formatting.
const idealFormatterOrder: Formatter["type"][] = [
  "discount",
  "rounded",
  "currency",
  "date",
];

export const updateFormatters = (
  formatters: Formatter[],
  dynamicDataString: string,
) => {
  const parsedFormatters = z
    .object({
      formatters: z.array(formatterSchema),
    })
    .safeParse({ formatters });
  if (parsedFormatters.error) {
    return null;
  }
  let matches = findBalancedDoubleBrackets(dynamicDataString);
  matches = uniq(matches);
  let result = dynamicDataString;
  if (!matches) {
    return result;
  }
  for (const match of matches) {
    let [oldPath] = extractHandlebarsContent(match)?.split("|") ?? [];
    oldPath = oldPath?.trim();
    if (!oldPath) {
      continue;
    }
    if (!oldPath || isDynamicDesignLibraryValue(oldPath)) {
      continue;
    }
    const updatedDefaults = extractUpdatedDefaultDynamicDataPriceInfo(oldPath);
    const path = updatedDefaults ? updatedDefaults.path : oldPath;
    let newDynamicDataReference = `{{ ${path} }}`;

    if (formatters && formatters.length > 0) {
      const sortedFormatters = formatters.sort(
        (a, b) =>
          idealFormatterOrder.indexOf(a.type) -
          idealFormatterOrder.indexOf(b.type),
      );

      const formattersString = JSON.stringify({ formatters: sortedFormatters });
      newDynamicDataReference = `{{ ${path} | formatters:${formattersString} }}`;
    }
    result = result.replaceAll(match, newDynamicDataReference);
  }
  return result;
};

export function getDynamicDataValue(
  key: string | null,
  targetType: DynamicDataType | null,
  value: any,
  productDependencies: ProductResolutionDependencies,
): DynamicDataValue {
  // Special treatment for smart fields that have no value
  if (!value) {
    const smartField =
      (SmartFields.product.find((f) => f.key === key) ||
        SmartFields.variant.find((f) => f.key === key)) ??
      null;

    if (smartField) {
      return generateDynamicDataValue(
        smartField.type,
        null,
        "No available value",
      );
    }
  }

  if (isArray(value)) {
    return generateDynamicDataValue("list", "");
  }

  if (key === "metafieldsWithNamespaceAndKey") {
    // TODO (Noah, 2022-11-26, REPL-5268): Assume that if we're looking
    // at a metafield value, the type of that value is always the target type,
    // because we can't know ahead of time before we fetch the definitions whether
    // to filter metafield definitions out or not.
    //
    // Consider the case where we're looking for a text/url value. We COULD filter
    // down the definitions to just include file_reference, but then we'd accidentally
    // filter out an single_line_text values that were a valid url, which we actually
    // want to include. You might think that we could filter out the definitions based
    // on their value like normal dynamic data fields, but we don't actually know the
    // metafield values until we fetch them.
    //
    // Probably the correct solution here is to fetch the metafield values at the same
    // time that we fetch the definitions, but putting this hack here now so that image
    // metafields work correctly.
    return generateDynamicDataValue(targetType ?? "text", null, null);
  }

  if (key === "_templateProduct") {
    // NOTE (Evan, 8/18/23): Attempt to resolve the template productRef to a StoreProduct
    value =
      productDependencies.products.find((p) => p.id === value?.productId) ??
      value;
  }

  if (typeof value === "string") {
    if (isColor(value)) {
      return generateDynamicDataValue("text/color", null, value);
    } else if (isValidHttpUrl(value)) {
      return generateDynamicDataValue("text/url", null, value);
    }
    if (Boolean(value) && !Number.isNaN(Number(value))) {
      return generateDynamicDataValue("text/integer", null, value);
    }
    return generateDynamicDataValue("text", null, value);
  }

  if (isGradient(value)) {
    return generateDynamicDataValue("text/color", null, value);
  }

  const fromKey = getDynamicDataValueFromKey(key, value);

  if (fromKey) {
    return fromKey;
  }

  if (hasOwnProperty(value, "productId")) {
    return generateDynamicDataValue("productOnly", "Product", value.title);
  }

  if (
    hasOwnProperty(value, "id") &&
    hasOwnProperty(value, "priceAdjustments") &&
    hasOwnProperty(value, "name")
  ) {
    return generateDynamicDataValue(
      "sellingPlan",
      "Selling Plan",
      get(value, "name"),
    );
  }

  if (isObject(value)) {
    const count = Object.keys(value).length;
    return generateDynamicDataValue(
      "object",
      key,
      `${count} value${count > 1 ? "s" : ""}`,
    );
  }

  return generateDynamicDataValue("?", null, "?");
}

export function filterDynamicDataByType(
  dynamicData: any,
  targetType: DynamicDataTargetType | null,
  excludedKeys: string[],
  productDependencies: ProductResolutionDependencies,
) {
  return filterDeep(
    pickBy(dynamicData, (_, key) => !uuidValidate(key)),
    (value: any, key: any, parentValue: any) => {
      if (excludedKeys.includes(key)) {
        return false;
      }

      // HACK (Noah, 2023-02-17, REPL-6353): This placeholder is here to ensure that we don't
      // filter out products/variants where the only thing applicable to the targetType is a
      // metafield. We should really make this dynamic data system have more of a semantic understanding
      // of what fields a product has, so we wouldn't need this
      if (key === "metafieldsPlaceholder") {
        return true;
      }

      // Special treatment for smart fields that have no value
      if (!value) {
        if (parentValue?.productId) {
          if (SmartFields.product.map((field) => field.key).includes(key)) {
            return true;
          }
          if (
            parentValue?.variantId &&
            SmartFields.variant.map((field) => field.key).includes(key)
          ) {
            return true;
          }
        }
        if (
          SmartFields.sellingPlan.some(
            (field) => field.type === targetType && key === field.key,
          )
        ) {
          return true;
        }

        return false;
      }

      // Lists are unstable, don't ever include text in a list
      if (targetType !== "list" && isArray(parentValue)) {
        return false;
      }

      const { type } = getDynamicDataValue(
        key,
        targetType,
        value,
        productDependencies,
      );

      const matchesType = targetType ? type.startsWith(targetType) : false;

      if (matchesType) {
        return true;
      }

      // Include list of images if looking for "text/url"
      if (targetType === "text/url" && key == "images" && isArray(value)) {
        return true;
      }

      // Returning undefined means we maybe include children
      return isArrayOrObject(value) ? undefined : false;
    },
    { leavesOnly: false },
  );
}

function isArrayOrObject(value: unknown) {
  return isArray(value) || isObject(value);
}

export const getPathFromVariable = (variable: string | undefined): string[] => {
  // Note (Noah, 2022-01-17, REPL-6017): replace out any content outside of the {{ }},
  // like closing <p> tags for RTE content
  return (
    variable?.replace(/.*{{/, "").replace(/}}.*/, "").split(".").slice(1) ?? []
  );
};

function generateDynamicDataValue(
  type: DynamicDataType,
  name?: string | null | undefined,
  value?: string | Gradient | null | undefined,
): DynamicDataValue {
  return {
    type,
    displayValue: isGradient(value) ? "Gradient" : value ?? null,
    displayName: isGradient(value) ? "Gradient" : name ?? null,
  };
}

function getDynamicDataValueFromKey(
  key: string | null,
  value: any,
): DynamicDataValue | null {
  switch (key) {
    case "_product":
      return generateDynamicDataValue(
        "productOnly",
        "Current Product",
        value.title,
      );

    case "_templateProduct":
      return generateDynamicDataValue(
        "productOnly",
        "Template Product",
        value.title,
      );

    case "_variant":
      return generateDynamicDataValue(
        "productVariant",
        "Selected Variant",
        value?.title || "None Currently Selected",
      );
    case "_currentVariant":
      return generateDynamicDataValue(
        "productVariant",
        "Repeated Variant",
        value?.title || "No Repeated Variant",
      );
    case "_selectedSellingPlan":
      return generateDynamicDataValue(
        "sellingPlan",
        "Selected Selling Plan",
        value?.name || "No Selected Selling Plan",
      );
    case "_currentSellingPlan":
      return generateDynamicDataValue(
        "sellingPlan",
        "Current Selling Plan",
        value?.name || "No Current Selling Plan",
      );
    case "_selectedOptionValues":
      return generateDynamicDataValue(
        "object",
        "Selected Options",
        isObject(value)
          ? `${Object.keys(value).join(", ")}`
          : "None Currently Selected",
      );
    case "_currentOption":
      return generateDynamicDataValue(
        "productOption",
        "Repeated Option",
        value.title || "No Repeated Option",
      );
    case "_currentTemporaryCartItem":
      return generateDynamicDataValue(
        "productVariant",
        "Current Temporary Cart Variant",
        value.title,
      );
    case "_currentSelection":
      return generateDynamicDataValue(
        "dataTableRow",
        "Current Selected Item",
        "Data Collection Row",
      );
    case "_currentItem":
      return generateDynamicDataValue(
        "dataTableRow",
        "Current Row",
        "Data Collection Row",
      );
    case "_swatches":
      return generateDynamicDataValue(
        "swatches",
        "Swatches",
        "Available swatches",
      );
    default:
      return null;
  }
}

const SUPPORTED_METAFIELD_TYPES = new Map<MetafieldType, string>([
  ["color", "Color"],
  ["multi_line_text_field", "Multi-line text"],
  ["number_decimal", "Decimal number"],
  ["number_integer", "Integer number"],
  ["single_line_text_field", "Single-line text"],
  ["url", "URL"],
  ["file_reference", "File reference"],
  ["rich_text_field", "Rich Text"],
]);

const textMetafieldTypes = new Set<MetafieldType>([
  "multi_line_text_field",
  "single_line_text_field",
  "rich_text_field",
  "number_decimal",
  "number_integer",
]);

// NOTE (Jackson, 2025-03-26): While url can techincally be anything and not just an image src, we're including
// it here and leaving it up to the user to ensure that the url is an image src. We'd rather not do some sort of
// leaky validation at this time but can revisit this later
const imageMetafieldTypes = new Set<MetafieldType>(["file_reference", "url"]);

/**
 * Generates a one or two layered tree structure of dynamic data options
 * based on the context and target data type
 *
 * @param componentAttributes - The attributes of the selected component(s)
 * @param targetDataType - The type of data being targeted, used for icon selection
 * @param productMetafields - Product metafield definitions
 * @param variantMetafields - Variant metafield definitions
 * @returns One or more DynamicDataTreeCategory objects, where more than one category indicates
 * a multi-layered tree structure
 *
 * Use null for parentKey to display top level options
 */
export const getDynamicDataTree = (
  componentAttributes: Context["attributes"],
  targetDataType: DynamicDataTargetType,
  productMetafields: MetafieldDefinition[],
  variantMetafields: MetafieldDefinition[],
): DynamicDataTreeCategory[] => {
  if (!componentAttributes) {
    return [];
  }

  // Filter supported metafields by type
  const supportedProductMetafields = productMetafields.filter((def) =>
    SUPPORTED_METAFIELD_TYPES.has(def.type),
  );

  const supportedVariantMetafields = variantMetafields.filter((def) =>
    SUPPORTED_METAFIELD_TYPES.has(def.type),
  );

  // TODO (Matt 2025-03-17): This tree fn is just setting up support for targetDataType TEXT right now
  // to allow for the initial build of the new DynamicData UI. There is a REPL-16545 for adding the
  // necessary data for the other targetTypes.

  // NOTE (Matt 2025-03-25): There are some keys with the keyword "current" that are used when we
  // render an item in a list of items (ie a variant in a list of variants). In some cases, it's
  // an abstract key like "currentItem" and others it's a specifc key like "currentVariant". We
  // need to support both.
  const parentKey = [
    "_currentVariant",
    "_currentSellingPlan",
    "_currentItem",
    "_currentTabItem",
  ].find((key) => key in componentAttributes) as
    | keyof typeof componentAttributes
    | undefined;

  // #region COUNTDOWN TIMER DYNAMIC DATA
  // If _endTime is present, we are in a countdown timer component
  if (componentAttributes._endTime) {
    return exhaustiveSwitch({ type: targetDataType })({
      [DynamicDataTargetType.TEXT]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Countdown Timer Data",
            parentKey: null,
            allowedAttributes: [
              {
                key: "daysUntilEnd",
                displayName: "Days Until End",
                type: "number",
              },
              {
                key: "hoursUntilEnd",
                displayName: "Hours Until End",
                type: "number",
              },
              {
                key: "minutesUntilEnd",
                displayName: "Minutes Until End",
                type: "number",
              },
              {
                key: "secondsUntilEnd",
                displayName: "Seconds Until End",
                type: "number",
              },
            ],
          },
        ];
      },
      [DynamicDataTargetType.INTEGER]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.QUANTITY]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.PRODUCT]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.PRODUCT_VARIANT]:
        (): DynamicDataTreeCategory[] => {
          return [];
        },
      [DynamicDataTargetType.SELLING_PLAN]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.TEXT_COLOR]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.URL]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.ANY_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE]: (): DynamicDataTreeCategory[] => {
        return [];
      },
    });
  }
  // #endregion

  // #region CURRENT ITEM IMAGE CAROUSEL
  // NOTE (Matt 2025-03-27): If CurrentItem is a string instead of an object, and there is the `_carouselSlides` key
  // in componentAttributes, then we're inside a carousel and the only option should be the currentItem for Image.
  if (
    typeof componentAttributes._currentItem === "string" &&
    "_carouselSlides" in componentAttributes
  ) {
    return exhaustiveSwitch({ type: targetDataType })({
      [DynamicDataTargetType.TEXT]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.PRODUCT]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.PRODUCT_VARIANT]:
        (): DynamicDataTreeCategory[] => {
          return [];
        },
      [DynamicDataTargetType.SELLING_PLAN]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.TEXT_COLOR]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.URL]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.ANY_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.INTEGER]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.QUANTITY]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Image",
            parentKey: null,
            allowedAttributes: [
              {
                key: "_currentItem",
                displayName: "Carousel Image",
                type: "image",
              },
            ],
          },
        ];
      },
    });
  }
  // #endregion

  // #region VARIANT LIST DYNAMIC DATA
  // If _currentVariant is present, we are in a repeated variant list
  if (parentKey && componentAttributes[parentKey]?.productId) {
    const swatchData = extractSwatchImageAndColors(
      componentAttributes[parentKey]?._swatches,
    );
    return exhaustiveSwitch({ type: targetDataType })({
      [DynamicDataTargetType.TEXT]: (): DynamicDataTreeCategory[] => {
        // Create a map to use option names as titles rather than "option1", "option2", etc.
        const optionNameMap: Record<string, string> = {};
        if (
          componentAttributes._options &&
          Array.isArray(componentAttributes._options)
        ) {
          componentAttributes._options.forEach((option: any) => {
            if (option.key && option.name) {
              optionNameMap[option.key] = option.name;
            }
          });
        }

        const categories: DynamicDataTreeCategory[] = [
          {
            categoryName: "Variant Data",
            categoryDescription:
              "Data associated with each variant in this list.",
            parentKey,
            allowedAttributes: [
              { key: "title", displayName: "Title", type: "text" },
              { key: "price", displayName: "Price", type: "price" },
              {
                key: "compareAtPrice",
                displayName: "Compare At Price",
                type: "price",
              },
              {
                key: "compareAtPriceDifference",
                displayName: "Price Difference",
                type: "price",
              },
              {
                key: "compareAtPriceDifferencePercentage",
                displayName: "Price Difference Percentage",
                type: "text",
              },
              ...Object.entries(optionNameMap).map(([key, name]) => ({
                key,
                displayName: name,
                description: getVariantOptionDescription(
                  componentAttributes,
                  key,
                ),
                type: "text" as const,
              })),
            ],
          },
        ];

        // Add variant metafields if available
        const textVariantMetafieldDefinitions =
          supportedVariantMetafields.filter((def) =>
            textMetafieldTypes.has(def.type),
          );

        if (textVariantMetafieldDefinitions.length > 0) {
          categories.push({
            categoryName: "Variant Metafields",
            categoryDescription:
              "Metafields associated with each variant in this list.",
            parentKey,
            allowedAttributes: textVariantMetafieldDefinitions.map((def) => ({
              key: `variantMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "text",
            })),
          });
        }

        return categories;
      },
      [DynamicDataTargetType.PRODUCT]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.PRODUCT_VARIANT]:
        (): DynamicDataTreeCategory[] => {
          return [];
        },
      [DynamicDataTargetType.SELLING_PLAN]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.TEXT_COLOR]: (): DynamicDataTreeCategory[] => {
        if (!swatchData || swatchData.colors.length < 1) {
          return [];
        }
        const { colors } = swatchData;
        return [
          {
            categoryName: "Option Data",
            parentKey,
            allowedAttributes: colors.map((swatchName) => ({
              key: `_swatches.${swatchName}.color`,
              displayName: `${swatchName} Swatch`,
              type: "color",
            })),
          },
        ];
      },
      [DynamicDataTargetType.URL]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.ANY_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.INTEGER]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.QUANTITY]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE]: (): DynamicDataTreeCategory[] => {
        const swatchImages = swatchData?.images ?? [];

        return [
          {
            categoryName: "Variant Media",
            parentKey,
            allowedAttributes: [
              {
                key: "featuredImage",
                displayName: "Featured Image",
                description: "From the current variant",
                type: "image",
              },
              ...swatchImages.map(
                (swatchName) =>
                  ({
                    key: `_swatches.${swatchName}.image`,
                    displayName: `${swatchName} Swatch`,
                    type: "image",
                  }) as DynamicDataAttribute,
              ),
            ],
          },
        ];
      },
    });
  }
  // #endregion

  // #region OPTIONS LIST DYNAMIC DATA
  // If _currentOption is present, we are in a repeated option list
  if (componentAttributes._currentOption) {
    const swatchData = extractSwatchImageAndColors(
      componentAttributes._currentOption?._swatches,
    );
    return exhaustiveSwitch({ type: targetDataType })({
      [DynamicDataTargetType.TEXT]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Option Data",
            parentKey: "_currentOption",
            allowedAttributes: [
              {
                key: "title",
                displayName: "Title",
                description: getOptionDescription(componentAttributes, "title"),
                type: "text",
              },
              {
                key: "price",
                displayName: "Price",
                description: getOptionDescription(componentAttributes, "price"),
                type: "price",
              },
              {
                key: "compareAtPrice",
                displayName: "Compare At Price",
                description: getOptionDescription(
                  componentAttributes,
                  "compareAtPrice",
                ),
                type: "price",
              },
            ],
          },
        ];
      },
      [DynamicDataTargetType.PRODUCT]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Product",
            parentKey: null,
            allowedAttributes: [
              { key: "_product", displayName: "Product", type: "product" },
            ],
          },
        ];
      },
      [DynamicDataTargetType.PRODUCT_VARIANT]:
        (): DynamicDataTreeCategory[] => {
          return [];
        },
      [DynamicDataTargetType.SELLING_PLAN]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.TEXT_COLOR]: (): DynamicDataTreeCategory[] => {
        if (!swatchData || swatchData.colors.length < 1) {
          return [];
        }
        const { colors } = swatchData;
        return [
          {
            categoryName: "Option Data",
            parentKey: "_currentOption",
            allowedAttributes: colors.map((swatchName) => ({
              key: `_swatches.${swatchName}.color`,
              displayName: `${swatchName} Swatch`,
              type: "color",
            })),
          },
        ];
      },
      [DynamicDataTargetType.URL]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.ANY_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.INTEGER]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.QUANTITY]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE]: (): DynamicDataTreeCategory[] => {
        if (!swatchData || swatchData.images.length < 1) {
          return [];
        }
        const { images } = swatchData;
        return [
          {
            categoryName: "Option Data",
            parentKey: "_currentOption",
            allowedAttributes: images.map((swatchName) => ({
              key: `_swatches.${swatchName}.image`,
              displayName: `${swatchName} Swatch`,
              type: "image",
            })),
          },
        ];
      },
    });
  }
  // #endregion

  // #region SELLING PLAN LIST DYNAMIC DATA
  // If _currentSellingPlan is present, we are in a repeated selling plan list
  if (parentKey && componentAttributes[parentKey]?.priceAdjustments) {
    return exhaustiveSwitch({ type: targetDataType })({
      [DynamicDataTargetType.TEXT]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Selling Plan Data",
            parentKey,
            allowedAttributes: [
              {
                key: "name",
                displayName: "Name",
                type: "text",
                description: "Name of the current selling plan",
              },
              {
                key: "price",
                displayName: "Price",
                type: "text",
                description: "Adjusted price of the current selling plan.",
              },
            ],
          },
        ];
      },
      [DynamicDataTargetType.PRODUCT]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.PRODUCT_VARIANT]:
        (): DynamicDataTreeCategory[] => {
          return [];
        },
      [DynamicDataTargetType.SELLING_PLAN]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.TEXT_COLOR]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.URL]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.ANY_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.INTEGER]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.QUANTITY]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE]: (): DynamicDataTreeCategory[] => {
        return [];
      },
    });
  }
  // #endregion

  // #region PRODUCT BUY BOX DYNAMIC DATA
  // If _product is present, we are certain we are inside a buy box component. Above cases should supercede
  // this where applicable (variant list, option list, selling plan list, etc.)
  if (componentAttributes._product) {
    return exhaustiveSwitch({ type: targetDataType })({
      [DynamicDataTargetType.TEXT]: (): DynamicDataTreeCategory[] => {
        // Apply text-only filter for TEXT target type
        const textProductMetafieldsDefinitions =
          supportedProductMetafields.filter((def) =>
            textMetafieldTypes.has(def.type),
          );

        const textVariantMetafieldDefinitions =
          supportedVariantMetafields.filter((def) =>
            textMetafieldTypes.has(def.type),
          );

        const availableProductOptions =
          componentAttributes._product.options.map(
            (option: {
              key: string;
              name: string;
              values: { title: string; available: boolean }[];
            }) => ({
              key: option.key,
              name: option.name,
            }),
          );

        const categories: DynamicDataTreeCategory[] = [
          {
            categoryName: "Product Data",
            categoryDescription: "Data for the current buy box product.",
            parentKey: "_product",
            allowedAttributes: [
              { key: "title", displayName: "Title", type: "text" },
              { key: "description", displayName: "Description", type: "text" },
              { key: "handle", displayName: "Handle", type: "text" },
              { key: "type", displayName: "Type", type: "text" },
              { key: "vendor", displayName: "Vendor", type: "text" },
              { key: "variant.rawPrice", displayName: "Price", type: "price" },
              {
                key: "variant.compareAtPrice",
                displayName: "Compare At Price",
                type: "price",
              },
            ],
          },
          {
            categoryName: "Variant Data",
            categoryDescription:
              "Data for the selected variant of a product in this buy box.",
            parentKey: "_variant",
            allowedAttributes: [
              { key: "title", displayName: "Title", type: "text" },
              { key: "price", displayName: "Price", type: "price" },
              {
                key: "compareAtPrice",
                displayName: "Compare At Price",
                type: "price",
              },
              {
                key: "compareAtPriceDifference",
                displayName: "Price Difference",
                type: "price",
              },
              {
                key: "compareAtPriceDifferencePercentage",
                displayName: "Price Difference Percentage",
                type: "text",
              },
              ...availableProductOptions.map(
                (option: { key: string; name: string }) => ({
                  key: `${option.key}`,
                  displayName: `Selected ${option.name}`,
                  type: "text",
                }),
              ),
            ],
          },
        ];

        // Add product metafields category if there are supported product metafields
        if (textProductMetafieldsDefinitions.length > 0) {
          categories.push({
            categoryName: "Product Metafields",
            categoryDescription:
              "Metafields associated with the buy box product.",
            parentKey: "_product",
            allowedAttributes: textProductMetafieldsDefinitions.map((def) => ({
              key: `productMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "text",
            })),
          });
        }

        // Add variant metafields category if there are supported variant metafields
        if (textVariantMetafieldDefinitions.length > 0) {
          categories.push({
            categoryName: "Variant Metafields",
            categoryDescription:
              "Metafields associated with the selected variant.",
            parentKey: "_variant",
            allowedAttributes: textVariantMetafieldDefinitions.map((def) => ({
              key: `variantMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "text",
            })),
          });
        }

        return categories;
      },
      [DynamicDataTargetType.PRODUCT]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Product",
            parentKey: null,
            allowedAttributes: [
              {
                key: "_product",
                displayName: "Selected Product",
                description: "From the current Buy Box",
                type: "product",
              },
            ],
          },
        ];
      },
      [DynamicDataTargetType.PRODUCT_VARIANT]:
        (): DynamicDataTreeCategory[] => {
          return [
            {
              categoryName: "Product",
              parentKey: null,
              allowedAttributes: [
                {
                  key: "_variant",
                  displayName: "Selected Variant",
                  description: "From the current Buy Box",
                  type: "product",
                },
              ],
            },
          ];
        },
      [DynamicDataTargetType.SELLING_PLAN]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Selling Plan",
            parentKey: null,
            allowedAttributes: [
              {
                key: "_selectedSellingPlan",
                displayName: "Selected Selling Plan",
                type: "text",
                description: "From the current Buy Box",
              },
            ],
          },
        ];
      },
      [DynamicDataTargetType.TEXT_COLOR]: (): DynamicDataTreeCategory[] => {
        const colorProductMetafieldsDefinitions =
          supportedProductMetafields.filter((def) => def.type === "color");
        const colorVariantMetafieldsDefinitions =
          supportedVariantMetafields.filter((def) => def.type === "color");

        const categories: DynamicDataTreeCategory[] = [];

        if (colorProductMetafieldsDefinitions.length > 0) {
          categories.push({
            categoryName: "Product Data",
            parentKey: "_product",
            allowedAttributes: colorProductMetafieldsDefinitions.map((def) => ({
              key: `productMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "color",
            })),
          });
        }

        if (colorVariantMetafieldsDefinitions.length > 0) {
          categories.push({
            categoryName: "Variant Data",
            parentKey: "_variant",
            allowedAttributes: colorVariantMetafieldsDefinitions.map((def) => ({
              key: `variantMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "color",
            })),
          });
        }

        return categories;
      },

      [DynamicDataTargetType.URL]: (): DynamicDataTreeCategory[] => {
        // Filter product and variant metafields for URL type
        const urlProductMetafieldsDefinitions =
          supportedProductMetafields.filter((def) => def.type === "url");
        const urlVariantMetafieldsDefinitions =
          supportedVariantMetafields.filter((def) => def.type === "url");

        const categories: DynamicDataTreeCategory[] = [];

        // Add product URL metafields if available
        if (urlProductMetafieldsDefinitions.length > 0) {
          categories.push({
            categoryName: "Product Metafields",
            categoryDescription: "URL metafields for the current product",
            parentKey: "_product",
            allowedAttributes: urlProductMetafieldsDefinitions.map((def) => ({
              key: `productMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "url",
            })),
          });
        }

        // Add variant URL metafields if available
        if (urlVariantMetafieldsDefinitions.length > 0) {
          categories.push({
            categoryName: "Variant Metafields",
            categoryDescription: "URL metafields for the selected variant",
            parentKey: "_variant",
            allowedAttributes: urlVariantMetafieldsDefinitions.map((def) => ({
              key: `variantMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "url",
            })),
          });
        }

        return categories;
      },
      [DynamicDataTargetType.INTEGER]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.QUANTITY]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Product Data",
            parentKey: null,
            allowedAttributes: [
              {
                key: "_quantity",
                displayName: "Selected Quantity",
                type: "number",
                description: "From the current Buy Box",
              },
            ],
          },
        ];
      },
      [DynamicDataTargetType.ANY_LIST]: (): DynamicDataTreeCategory[] => {
        // NOTE (Matt 2025-03-25): We don't support Product.Options inside of iterable components
        // like the SelectionList because some of the formatting that is useful to the Options
        // data is done inside of OptionSelect. For now we'll continue this, unless users seem
        // to be needing to use SelectionList or Tabs instead of OptionSelect.
        return [
          {
            categoryName: "Product Data",
            parentKey: null,
            allowedAttributes: [
              {
                key: "_product.variants",
                displayName: "Variants",
                type: "list",
                description: "From the current Buy Box",
              },
              {
                key: "_sellingPlans",
                displayName: "Selling Plans",
                type: "list",
                description: "From the current Buy Box",
              },
            ],
          },
        ];
      },
      [DynamicDataTargetType.IMAGE_LIST]: (): DynamicDataTreeCategory[] => {
        const selectedOptionValues: Record<
          string,
          { _swatches?: Record<string, SwatchValue>; [key: string]: any }
        > = componentAttributes._selectedOptionValues ?? {};
        const swatchOptionImageLists = Object.entries(
          selectedOptionValues,
        ).reduce((lists: DynamicDataAttribute[], [optionName, optionValue]) => {
          if (optionValue?._swatches) {
            const imageLists = extractSwatchImageLists(
              optionValue._swatches,
              "_selectedOptionValues",
              optionName,
            );
            lists.push(...imageLists);
          }
          return lists;
        }, []);

        const swatchVariantImageLists = componentAttributes._variant
          ? extractSwatchImageLists(
              componentAttributes._variant._swatches,
              "_variant",
            )
          : [];
        return [
          {
            categoryName: "Image Lists",
            parentKey: null,
            allowedAttributes: [
              {
                key: "_product.images",
                displayName: "Product Images",
                description: "Images for the current product",
                type: "image",
              },
              ...swatchOptionImageLists,
              ...swatchVariantImageLists,
            ],
          },
        ];
      },
      [DynamicDataTargetType.IMAGE]: (): DynamicDataTreeCategory[] => {
        const imageProductMetafieldsDefinitions =
          supportedProductMetafields.filter((def) =>
            imageMetafieldTypes.has(def.type),
          );
        const imageVariantMetafieldsDefinitions =
          supportedVariantMetafields.filter((def) =>
            imageMetafieldTypes.has(def.type),
          );

        const categories: DynamicDataTreeCategory[] = [
          {
            categoryName: "Product Media",
            categoryDescription: "Media for the current buy box product.",
            parentKey: "_product",
            allowedAttributes: [
              {
                key: "featured_image",
                displayName: "Featured Image",
                description: "From the  buy box product",
                type: "image",
              },
            ],
          },
          {
            categoryName: "Variant Media",
            categoryDescription:
              "Media for the selected variant of a product in this buy box.",
            parentKey: "_variant",
            allowedAttributes: [
              {
                key: "featuredImage",
                displayName: "Featured Image",
                description: "From the selected variant",
                type: "image",
              },
            ],
          },
        ];

        if (imageProductMetafieldsDefinitions.length > 0) {
          categories.push({
            categoryName: "Product Metafields",
            categoryDescription:
              "Image metafields for the current buy box product",
            parentKey: "_product",
            allowedAttributes: imageProductMetafieldsDefinitions.map((def) => ({
              key: `productMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "image",
            })),
          });
        }

        if (imageVariantMetafieldsDefinitions.length > 0) {
          categories.push({
            categoryName: "Variant Metafields",
            categoryDescription:
              "Image metafields for the selected variant in the current buy box",
            parentKey: "_variant",
            allowedAttributes: imageVariantMetafieldsDefinitions.map((def) => ({
              key: `variantMetafields.${def.namespace}.${def.key}`,
              displayName: def.name,
              description: `${def.namespace}`,
              type: "image",
            })),
          });
        }

        return categories;
      },
    });
  }
  // #endregion

  // #region TEMPLATE PRODUCT DYNAMIC DATA
  // If _templateProduct is present, we're on a PDP. Currently only supports selecting
  // 'template product' for product/integration components
  if (componentAttributes._templateProduct) {
    return exhaustiveSwitch({ type: targetDataType })({
      [DynamicDataTargetType.TEXT]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.PRODUCT]: (): DynamicDataTreeCategory[] => {
        return [
          {
            categoryName: "Product",
            parentKey: null,
            allowedAttributes: [
              {
                key: "_templateProduct",
                displayName: "Template Product",
                type: "product",
                description: "From current page",
              },
            ],
          },
        ];
      },
      [DynamicDataTargetType.PRODUCT_VARIANT]:
        (): DynamicDataTreeCategory[] => {
          return [];
        },
      [DynamicDataTargetType.SELLING_PLAN]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.TEXT_COLOR]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.URL]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.INTEGER]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.QUANTITY]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.ANY_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE_LIST]: (): DynamicDataTreeCategory[] => {
        return [];
      },
      [DynamicDataTargetType.IMAGE]: (): DynamicDataTreeCategory[] => {
        return [];
      },
    });
  }
  // #endregion

  return [];
};

export function getDynamicDataTypeIcon(
  type: NewDynamicDataType,
): React.ReactNode {
  return exhaustiveSwitch({ type })({
    text: <TextIcon />,
    price: <CurrencyIcon />,
    image: <ImageIcon />,
    number: <HashIcon />,
    product: <ProductIcon />,
    list: <ListIcon />,
    color: <ColorIcon />,
    url: <LinkIcon />,
  });
}

/**
 * Creates a description string for an option by joining its values with forward slashes
 * @param componentAttributes - Component attributes containing options data
 * @returns A string of option values separated by " / " or empty string if not found
 */
const getOptionDescription = (
  componentAttributes: Context["attributes"],
  targetValueKey: string,
): string | undefined => {
  if (
    !componentAttributes?._currentOption?.title ||
    !componentAttributes?._options ||
    !Array.isArray(componentAttributes._options)
  ) {
    return undefined;
  }

  // Get the current option title (value title)
  const currentOptionValueTitle = componentAttributes._currentOption.title;

  // Find which option this value belongs to by checking each option's values array
  for (const option of componentAttributes._options) {
    const valueExists = option.values.some(
      (value: { title: string }) => value.title === currentOptionValueTitle,
    );

    if (valueExists) {
      return option.values
        .map((value: any) => value[targetValueKey])
        .join(" / ");
    }
  }

  return undefined;
};

/**
 * Creates a description string for an option in a variant list by joining all possible values
 * @param componentAttributes - Component attributes containing options data
 * @param optionKey - The option key (e.g., "option1", "option2")
 * @returns A string of option values separated by " / " or empty string if not found
 */
const getVariantOptionDescription = (
  componentAttributes: Context["attributes"],
  optionKey: string,
): string | undefined => {
  if (
    !componentAttributes?._options ||
    !Array.isArray(componentAttributes._options)
  ) {
    return undefined;
  }

  // Find the option object that matches the given key
  const option = componentAttributes._options.find(
    (option) => option.key === optionKey,
  );

  if (!option || !option.values || !Array.isArray(option.values)) {
    return undefined;
  }

  return option.values
    .map((value: { title: string }) => value.title)
    .join(" / ");
};
