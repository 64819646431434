import type { LeftBarTab } from "@editor/reducers/ui-reducer";
import type { MenuItem as MenuItemType } from "@replo/design-system/components/menu/Menu";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { CanvasData } from "./canvas-types";

import * as React from "react";

import Selectable from "@common/designSystem/Selectable";
import Separator from "@common/designSystem/Separator";
import { LengthInputSelector } from "@components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import { BuildAssistantPopover } from "@editor/components/editor/ai/build-assistant/BuildAssistantPopover";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useSelectedArchivedElement } from "@editor/hooks/useSelectedArchivedElement";
import { useUnarchiveElement } from "@editor/hooks/useUnarchiveElement";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  redoOperation,
  selectEditorMode,
  selectIsPreviewMode,
  selectValidNextOperation,
  selectValidPreviousOperation,
  setEditorMode,
  undoOperation,
} from "@editor/reducers/core-reducer";
import {
  selectLeftBarActiveTab,
  setLeftBarActiveTab,
} from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";

import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { HotkeyIndicator } from "@replo/design-system/components/hotkeys/HotKeyIndicator";
import { Menu, MenuTrigger } from "@replo/design-system/components/menu/Menu";
import { MenuItem } from "@replo/design-system/components/menu/MenuItem";
import Popover from "@replo/design-system/components/popover/Popover";
import twMerge from "@replo/design-system/utils/twMerge";
import {
  ChevronDown,
  ChevronLeft,
  ChevronUp,
  Redo,
  Sparkles,
  TvMinimalPlay,
  Undo,
} from "lucide-react";
import {
  BsBoxArrowLeft,
  BsFillAspectRatioFill,
  BsFillTabletFill,
} from "react-icons/bs";
import { IoTabletLandscape } from "react-icons/io5";
import { RiComputerFill } from "react-icons/ri";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { parseFloat } from "replo-utils/lib/math";
import { capitalizeFirstLetter } from "replo-utils/lib/string";

import { CANVAS_DATA } from "./canvas-constants";
import {
  selectActiveCanvas,
  selectActiveCanvasFrameWidth,
  selectCanvasFrameWidths,
  selectCanvasScale,
  selectPreviewWidth,
  setPreviewWidth,
} from "./canvas-reducer";
import { getCanvasData, getPresets } from "./canvas-utils";
import { useCanvasZoom } from "./useCanvasZoom";
import { useDeviceControls } from "./useDeviceControls";

// Constants
const SCALE_OPTIONS = [
  { label: "50%", value: "0.5" },
  { label: "75%", value: "0.75" },
  { label: "100%", value: "1.0" },
  { label: "125%", value: "1.25" },
];

const SCALE_ACTIONS = [
  { label: "Zoom In", value: "zoomIn" },
  { label: "Zoom Out", value: "zoomOut" },
];

// Shared Components
interface ControlsContainerProps {
  children: React.ReactNode;
  className?: string;
}

const ControlsContainer = React.forwardRef<
  HTMLDivElement,
  ControlsContainerProps
>(({ children, className }, ref) => (
  <aside
    aria-label="Canvas Controls"
    className={twMerge(
      "fixed bottom-0 -translate-y-6 left-1/2 flex -translate-x-1/2 flex-row items-center gap-2 bg-white py-2 px-2 shadow-modal rounded-xl border-[0.5] border-border",
      className,
    )}
    ref={ref}
  >
    {children}
  </aside>
));

ControlsContainer.displayName = "ControlsContainer";

export const CanvasControls: React.FC = () => {
  const [isScaleMenuVisible, setIsScaleMenuVisible] = React.useState(false);
  const [previousLeftBarTab, setPreviousLeftBarTab] =
    React.useState<LeftBarTab | null>(null);

  const store = useEditorStore();
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const {
    abort: abortAIStream,
    isBuildAssistantOpen,
    setIsBuildAssistantOpen,
    status,
  } = useAIStreaming();

  const isGenerating =
    status === "generating" || status === "generationInitialized";

  // Selectors
  const editorMode = useEditorSelector(selectEditorMode);
  const canvasScale = useEditorSelector(selectCanvasScale);
  const hasPreviousOperation = useEditorSelector(selectValidPreviousOperation);
  const hasNextOperation = useEditorSelector(selectValidNextOperation);

  const archivedElement = useSelectedArchivedElement();

  // Hooks
  const { handleCanvasZoom, handleCanvasZoomIn, handleCanvasZoomOut } =
    useCanvasZoom();
  const { unarchiveElement, isLoading: isUnarchiveLoading } =
    useUnarchiveElement();

  // Derived state
  const isEditMode =
    editorMode === EditorMode.edit || editorMode === EditorMode.aiGeneration;
  const formattedCanvasScale = `${(canvasScale * 100).toFixed(0)}%`;
  const areControlsDisabled = editorMode === EditorMode.aiGeneration;

  // Event handlers
  function handleEditorModeChange() {
    const activeCanvasFrameWidth = selectActiveCanvasFrameWidth(
      store.getState(),
    );

    if (
      editorMode === EditorMode.versioning ||
      editorMode === EditorMode.archived
    ) {
      dispatch(setEditorMode(EditorMode.edit));
      return;
    }

    // Cancel AI streaming when switching back to edit mode
    if (editorMode === EditorMode.aiGeneration) {
      abortAIStream();
    }

    if (isEditMode) {
      logEvent("canvas.preview", { source: "toolbar" });
      dispatch(setPreviewWidth(activeCanvasFrameWidth));
    }

    if (isEditMode) {
      setPreviousLeftBarTab(selectLeftBarActiveTab(store.getState()));
      dispatch(setLeftBarActiveTab(null));
      dispatch(setEditorMode(EditorMode.preview));
    } else {
      dispatch(setLeftBarActiveTab(previousLeftBarTab ?? "components"));
      setPreviousLeftBarTab(null);
      dispatch(setEditorMode(EditorMode.edit));
    }
  }

  function handleUndo() {
    dispatch(undoOperation());
  }

  function handleRedo() {
    dispatch(redoOperation());
  }

  function onBuildAssistantClose() {
    abortAIStream();
    setIsBuildAssistantOpen(false);
  }

  function handleBuildAssistantButtonClick() {
    if (isBuildAssistantOpen) {
      onBuildAssistantClose();
    } else {
      logEvent("ai.action.open");
      setIsBuildAssistantOpen(true);
    }
  }

  function handleScaleChange(value: string) {
    if (value === "zoomIn") {
      handleCanvasZoomIn();
    } else if (value === "zoomOut") {
      handleCanvasZoomOut();
    } else {
      const scale = parseFloat(value);
      if (!Number.isNaN(scale)) {
        handleCanvasZoom(scale);
      }
    }
  }

  // Scale menu items
  const scaleItems = [
    ...SCALE_OPTIONS.map(({ label, value }, idx) => ({
      type: "leaf" as const,
      variant: "default" as const,
      size: "sm" as const,
      id: idx.toString(),
      title: label,
      onSelect: () => handleScaleChange(value),
    })),
    {
      type: "section" as const,
      items: SCALE_ACTIONS.map(({ label, value }, idx) => ({
        type: "leaf" as const,
        variant: "command" as const,
        size: "sm" as const,
        id: idx.toString(),
        title: label,
        onSelect: () => handleScaleChange(value),
        shortcut: value,
      })),
    },
  ];

  // Render different controls based on editor mode
  if (editorMode === EditorMode.archived) {
    return (
      <ArchivedModeControls
        archivedElement={archivedElement}
        areControlsDisabled={areControlsDisabled}
        handleEditorModeChange={handleEditorModeChange}
        unarchiveElement={unarchiveElement}
        isUnarchiveLoading={isUnarchiveLoading}
      />
    );
  }

  if (editorMode === EditorMode.versioning) {
    return null;
  }

  // Default controls (edit/preview mode)
  return (
    <ControlsContainer>
      <IconButton
        id={isEditMode ? "preview-button" : "edit-button"}
        variant="tertiary"
        icon={
          <div className="text-default">
            {isEditMode ? (
              <TvMinimalPlay size={20} />
            ) : (
              <ChevronLeft size={20} />
            )}
          </div>
        }
        layoutClassName="h-8 w-8"
        disabled={areControlsDisabled}
        tooltipText={<HotkeyIndicator hotkey="togglePreviewMode" />}
        onClick={handleEditorModeChange}
        data-testid={isEditMode ? "preview-button" : "edit-button"}
      />

      {isEditMode && (
        <>
          <Separator orientation="vertical" className="h-6" />
          <div className="flex flex-row gap-1">
            <IconButton
              variant="tertiary"
              layoutClassName="h-8 w-8"
              icon={<Undo className="text-xl text-default" />}
              tooltipText={<HotkeyIndicator hotkey="undo" />}
              onClick={handleUndo}
              disabled={!hasPreviousOperation || isGenerating}
            />
            <IconButton
              variant="tertiary"
              layoutClassName="h-8 w-8"
              icon={<Redo className="text-xl text-default" />}
              tooltipText={<HotkeyIndicator hotkey="redo" />}
              onClick={handleRedo}
              disabled={!hasNextOperation || isGenerating}
            />
          </div>

          <Separator orientation="vertical" className="h-6" />

          <ScaleMenu
            formattedCanvasScale={formattedCanvasScale}
            isScaleMenuVisible={isScaleMenuVisible}
            setIsScaleMenuVisible={setIsScaleMenuVisible}
            scaleItems={scaleItems}
          />
        </>
      )}
      <Separator orientation="vertical" className="h-6" />

      {editorMode === EditorMode.preview && <DeviceControls />}

      {editorMode !== EditorMode.preview && (
        <BuildAssistantPopover
          isOpen={isBuildAssistantOpen}
          onClose={onBuildAssistantClose}
        >
          <IconButton
            id="ai-button"
            variant="tertiary"
            UNSAFE_className={twMerge(
              isBuildAssistantOpen && "bg-light-surface",
            )}
            icon={<Sparkles size={20} />}
            layoutClassName="h-8 w-8"
            disabled={areControlsDisabled}
            tooltipText={
              isBuildAssistantOpen ? null : (
                <HotkeyIndicator hotkey="toggleAIMenu" />
              )
            }
            onClick={handleBuildAssistantButtonClick}
            data-testid="ai-button"
          />
        </BuildAssistantPopover>
      )}
    </ControlsContainer>
  );
};

// Mode-specific Controls Components
interface ArchivedModeControlsProps {
  archivedElement: ReturnType<typeof useSelectedArchivedElement>;
  areControlsDisabled: boolean;
  handleEditorModeChange: () => void;
  unarchiveElement: ReturnType<typeof useUnarchiveElement>["unarchiveElement"];
  isUnarchiveLoading: boolean;
}

const ArchivedModeControls: React.FC<ArchivedModeControlsProps> = ({
  archivedElement,
  areControlsDisabled,
  handleEditorModeChange,
  unarchiveElement,
  isUnarchiveLoading,
}) => (
  <ControlsContainer className="px-2">
    <IconButton
      id="edit-button"
      variant="tertiary"
      size="lg"
      layoutClassName="h-8 w-8"
      icon={<BsBoxArrowLeft size={24} className="text-xl" />}
      disabled={areControlsDisabled}
      tooltipText="Exit Archive"
      onClick={handleEditorModeChange}
      data-testid="edit-button"
    />

    <Separator orientation="vertical" className="h-6" />

    <p className="flex gap-2 items-center text-default">
      <span className="typ-label-base">{archivedElement?.name}</span>
      <Separator orientation="vertical" className="h-6 bg-dark-contrast" />
      {archivedElement && (
        <span className="typ-body-base">
          Archived{" "}
          {new Date(archivedElement?.archivedAt ?? "").toLocaleDateString()}
        </span>
      )}
    </p>

    <Button
      variant="primary"
      size="base"
      onClick={() => {
        if (!archivedElement?.id) {
          return;
        }
        unarchiveElement(archivedElement, { from: "button" });
      }}
      disabled={isUnarchiveLoading || !archivedElement?.id}
      isLoading={isUnarchiveLoading}
    >
      Unarchive
    </Button>
  </ControlsContainer>
);

// UI Components
interface ScaleMenuProps {
  formattedCanvasScale: string;
  isScaleMenuVisible: boolean;
  setIsScaleMenuVisible: (isVisible: boolean) => void;
  scaleItems: MenuItemType[];
}

const ScaleMenu: React.FC<ScaleMenuProps> = ({
  formattedCanvasScale,
  isScaleMenuVisible,
  setIsScaleMenuVisible,
  scaleItems,
}) => (
  <Menu
    items={scaleItems}
    customWidth={180}
    align="center"
    onRequestClose={() => setIsScaleMenuVisible(false)}
    onRequestOpen={() => setIsScaleMenuVisible(true)}
    trigger={
      <MenuTrigger asChild>
        <button
          type="button"
          className="flex h-6 w-20 items-center justify-between gap-2 rounded bg-subtle px-3 py-1 typ-body-base text-default"
        >
          {formattedCanvasScale}
          <ChevronUp
            size={24}
            className={twMerge(
              "text-default transition-all",
              isScaleMenuVisible && "rotate-180",
            )}
          />
        </button>
      </MenuTrigger>
    }
  />
);

// Device Controls Components
const DeviceControls: React.FC = () => {
  const editorMode = useEditorSelector(selectEditorMode);
  const isEditMode = editorMode === EditorMode.edit;

  return (
    <div className="flex flex-row items-center gap-2">
      {Object.values(CANVAS_DATA).map((canvasData) => (
        <CanvasDeviceControls
          key={canvasData.canvasName}
          canvasData={canvasData}
        />
      ))}

      {!isEditMode && (
        <>
          <Separator orientation="vertical" className="h-6" />
          <CustomViewportControl />
        </>
      )}
    </div>
  );
};

interface CanvasDeviceControlsProps {
  canvasData: CanvasData;
}

const CanvasDeviceControls: React.FC<CanvasDeviceControlsProps> = ({
  canvasData,
}) => {
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const previewWidth = useEditorSelector(selectPreviewWidth);
  const { handleDeviceChange } = useDeviceControls();
  const Icon = getCanvasIconComponent(canvasData.canvasName);

  let isActive = activeCanvas === canvasData.canvasName;
  if (isPreviewMode) {
    const previewData = getCanvasData(previewWidth);
    isActive = previewData?.canvasName === canvasData.canvasName;
  }

  return (
    <IconButton
      key={canvasData.canvasName}
      variant="tertiary"
      id="page-settings-button"
      layoutClassName="h-8 w-8"
      tooltipText={capitalizeFirstLetter(canvasData.canvasName)}
      icon={
        <Icon
          className={twMerge(
            "text-xl",
            isActive ? "text-default" : "text-subtle",
          )}
        />
      }
      onClick={() => handleDeviceChange(canvasData)}
    />
  );
};

interface CustomViewportControlProps {
  canvas?: EditorCanvas;
}

export const CustomViewportControl: React.FC<CustomViewportControlProps> = ({
  canvas,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const visibleCanvasesFrameWidth = useEditorSelector(selectCanvasFrameWidths);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const previewWidth = useEditorSelector(selectPreviewWidth);

  const { handleViewportChange, handlePresetDeviceChange } =
    useDeviceControls();

  const currentCanvas = canvas ?? activeCanvas;
  const presets = getPresets(canvas ?? undefined);
  const canvasData = getFromRecordOrNull(CANVAS_DATA, canvas);
  const width = visibleCanvasesFrameWidth[currentCanvas];

  const isOnCanvasNavbar = Boolean(canvas);
  const selectedPreset = presets.find(
    (preset) => preset.value === (isPreviewMode ? previewWidth : width),
  );
  const presetTriggerStartEnhancer = (() => {
    if (!selectedPreset) {
      return null;
    }

    const Icon = getCanvasIconComponent(selectedPreset.canvas);
    return <Icon className="text-subtle" />;
  })();

  return (
    <Popover
      triggerAsChild
      isOpen={isPopoverOpen}
      shouldPreventDefaultOnInteractOutside={false}
      onOpenChange={(isOpen) => setIsPopoverOpen(isOpen)}
      style={{ marginTop: isOnCanvasNavbar ? 8 : undefined }}
      content={
        <div className="flex flex-col gap-1 pb-3">
          <h4 className="text-xs font-normal text-subtle">Set Preview Width</h4>
          <LengthInputSelector
            field="default"
            startEnhancer={<BsFillAspectRatioFill />}
            value={`${isPreviewMode ? previewWidth : width}px`}
            metrics={["px"]}
            onChange={(value) => {
              handleViewportChange(value, canvas);
            }}
            draggingType={DraggingTypes.Vertical}
            draggingDirection={DraggingDirections.Positive}
            minDragValues={{ px: canvasData?.range[0] ?? 0 }}
            minValues={{ px: canvasData?.range[0] ?? 0 }}
            maxDragValues={{
              px: canvasData?.range[1] ?? Number.POSITIVE_INFINITY,
            }}
            maxValues={{ px: canvasData?.range[1] ?? Number.POSITIVE_INFINITY }}
            allowsNegativeValue={false}
            dragTrigger="startEnhancer"
          />
          <h4 className="text-xs font-normal text-subtle">By Device</h4>
          <Selectable
            placeholder="Select..."
            ignoreValueMismatchError
            onSelect={(value) => {
              if (!value) {
                return;
              }
              handlePresetDeviceChange(value, canvas);
            }}
            triggerStartEnhancer={presetTriggerStartEnhancer}
            value={selectedPreset?.value ? String(selectedPreset.value) : null}
            options={presets.map(({ canvas, label, value }) => {
              const Icon = getCanvasIconComponent(canvas);
              return {
                label: (
                  <MenuItem
                    variant="default"
                    selected={selectedPreset?.value === value}
                    startEnhancer={<Icon className="text-subtle" />}
                  >
                    <div className="flex w-full shrink-0 items-center p-1 text-xs text-default gap-2">
                      <span className="font-normal truncate">{label}</span>
                      <span className="text-subtle">({value}px)</span>
                    </div>
                  </MenuItem>
                ),
                displayValue: `${label} (${value}px)`,
                value: String(value),
              };
            })}
          />
        </div>
      }
      title="Preview Dimensions"
      className="w-60 py-4"
      stayInPosition
      side="bottom"
      align={isOnCanvasNavbar ? "start" : "center"}
      alignOffset={isOnCanvasNavbar ? -10 : 0}
    >
      <div
        className={twMerge(
          "flex h-6 cursor-pointer items-center justify-center gap-2 rounded py-1 px-2 text-sm text-slate-500",
          !isOnCanvasNavbar && "bg-slate-100 text-slate-400",
          !isOnCanvasNavbar &&
            width !== CANVAS_DATA[currentCanvas].defaultFrameWidth &&
            "text-default",
        )}
      >
        {isPreviewMode ? previewWidth : width}px
        {isOnCanvasNavbar ? (
          <ChevronDown
            size={12}
            className={twMerge(
              "text-slate-600 transition-all",
              isPopoverOpen && "rotate-180",
            )}
          />
        ) : (
          <ChevronUp
            size={12}
            className={twMerge(
              "text-slate-600 transition-all",
              isPopoverOpen && "rotate-180",
            )}
          />
        )}
      </div>
    </Popover>
  );
};

// Utility Functions
function getCanvasIconComponent(canvas: EditorCanvas) {
  if (canvas === "mobile") {
    return BsFillTabletFill;
  }
  if (canvas === "tablet") {
    return IoTabletLandscape;
  }
  return RiComputerFill;
}
