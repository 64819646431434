import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import * as React from "react";

import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { RiLineHeight } from "react-icons/ri";
import { CSS_LENGTH_TYPES_WITH_COMPUTED } from "replo-runtime/shared/utils/units";

import ModifierLabel from "../../extras/ModifierLabel";
import { LengthInputSelector } from "../../modifiers/LengthInputModifier";

interface LineHeightSelectorProps {
  lineHeight: string | ReploMixedStyleValue | null;
  onChange: (value: string) => void;
}

const LineHeightSelector: React.FC<LineHeightSelectorProps> = ({
  lineHeight,
  onChange,
}) => {
  const lineHeightDefaultValue =
    styleAttributeToEditorData.lineHeight.defaultValue;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Line" />}
      startEnhancer={
        <Tooltip inheritCursor content="Line Height" triggerAsChild>
          <span tabIndex={0}>
            <RiLineHeight />
          </span>
        </Tooltip>
      }
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.lineHeight"
      value={lineHeight}
      anchorValue={lineHeightDefaultValue}
      dragTrigger="label"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      resetValue={lineHeightDefaultValue}
      allowsNegativeValue={false}
      previewProperty="lineHeight"
      onChange={onChange}
    />
  );
};

export default LineHeightSelector;
