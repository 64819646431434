import type {
  ExpandableContentProps,
  ExpandableProps,
  ExpandableRootProps,
  ExpandableTriggerProps,
} from "./types";

import React, { useContext, useState } from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { LucideChevronDown } from "lucide-react";

import Button from "../button/Button";
import { ExpandableContext } from "./ExpandableContext";

const ExpandableRoot: React.FC<
  React.PropsWithChildren<ExpandableRootProps>
> = ({ children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  return (
    <ExpandableContext.Provider value={{ isOpen, setOpen: setIsOpen }}>
      {children}
    </ExpandableContext.Provider>
  );
};

const ExpandableTrigger: React.FC<ExpandableTriggerProps> = ({ children }) => {
  const context = useContext(ExpandableContext);
  if (!context) {
    throw new Error(
      "Expandable.Trigger must be used within an Expandable.Root",
    );
  }
  const { isOpen, setOpen } = context;

  return (
    <Button
      variant="tertiary"
      onClick={() => setOpen(!isOpen)}
      endEnhancer={
        <LucideChevronDown
          className={twMerge(
            "transition-transform",
            isOpen ? "rotate-180" : "",
          )}
          size={12}
        />
      }
    >
      {typeof children === "function" ? children(context) : children}
    </Button>
  );
};

const ExpandableContent: React.FC<ExpandableContentProps> = ({ children }) => {
  const context = useContext(ExpandableContext);
  if (!context) {
    throw new Error(
      "Expandable.Content must be used within an Expandable.Root",
    );
  }
  const { isOpen } = context;

  return (
    <div
      className={twMerge(
        "overflow-hidden transition-all duration-300 ease-in-out transform",
        isOpen
          ? `max-h-[1000px] opacity-100 translate-y-0`
          : "max-h-0 opacity-0 -translate-y-2",
      )}
    >
      {children}
    </div>
  );
};

export const Expandable = (props: ExpandableProps) => {
  return (
    <ExpandableRoot {...props}>
      <ExpandableTrigger>{props.trigger}</ExpandableTrigger>
      <ExpandableContent>{props.content}</ExpandableContent>
    </ExpandableRoot>
  );
};

Expandable.Root = ExpandableRoot;
Expandable.Trigger = ExpandableTrigger;
Expandable.Content = ExpandableContent;
