import * as React from "react";

import { CommandInput } from "@replo/design-system/components/shadcn/core/command";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsSearch } from "react-icons/bs";

import { useCombobox } from "../hooks/useCombobox";
import { OptionsList } from "./OptionsList";

export type ComboboxContentProps = {
  style?: React.CSSProperties;
  size?: "sm" | "base" | "lg";
  title?: string;
  areOptionsSearchable?: boolean;
  inputPlaceholder?: string;
  emptySearchMessage?: string;
  footer?: React.ReactNode;
  onScrolledToEnd?: () => void;
  loader?: React.ReactNode | null;
  UNSAFE_listClassName?: string;
};

export const ComboboxContent: React.FC<ComboboxContentProps> = ({
  title,
  areOptionsSearchable,
  inputPlaceholder,
  emptySearchMessage,
  footer,
  size = "sm",
  onScrolledToEnd,
  loader,
  UNSAFE_listClassName,
}) => {
  const { options, setValue, setOpen, value, input, setInput } = useCombobox();

  return (
    <>
      <div
        className={twMerge(
          "flex flex-col gap-2 px-2",
          areOptionsSearchable && "border-b border-slate-200 py-2",
          !areOptionsSearchable && title && "pt-2",
        )}
      >
        {title && (
          <div
            className={twMerge(
              "font-semibold text-default",
              size === "sm" ? "text-xs" : "text-sm",
            )}
          >
            {title}
          </div>
        )}
        {areOptionsSearchable && (
          <CommandInput
            value={input}
            onValueChange={setInput}
            placeholder={inputPlaceholder ?? "Search..."}
            startEnhancer={
              <BsSearch
                className={twMerge(
                  "text-slate-400",
                  size === "sm" ? "size-2.5" : "size-3",
                )}
              />
            }
            variant={size === "lg" ? "base" : size}
            className="placeholder:font-normal"
          />
        )}
      </div>
      <OptionsList
        options={options}
        setValue={setValue}
        setOpen={setOpen}
        value={value}
        input={input}
        emptySearchMessage={emptySearchMessage}
        setInput={setInput}
        areOptionsSearchable={areOptionsSearchable}
        size={size}
        onScrolledToEnd={onScrolledToEnd}
        loader={loader}
        UNSAFE_className={UNSAFE_listClassName}
      />
      {footer}
    </>
  );
};
