import type { ItemsConfig } from "schemas/dynamicData";
import type { DataTable } from "../../shared/DataTable";
import type { ProductResolutionDependencies } from "../ReploProduct";
import type { Context } from "../ReploVariable";

import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { ItemsConfigType } from "schemas/dynamicData";

import {
  getRowObjectsFromDataTable,
  preloadAllImagesFromDataTable,
} from "../AlchemyDataTable";
import { getProduct } from "../ReploProduct";
import {
  extractDynamicDataInfo,
  resolveDynamicDataPath,
} from "../ReploVariable";

export const itemTypeToRenderData = (itemType: ItemsConfigType) => {
  return exhaustiveSwitch({ type: itemType })({
    dataTable: {
      displayName: "Data Collection",
    },
    productImages: {
      displayName: "Product Images",
    },
    inline: {
      displayName: "Specific Values",
    },
    htmlAttributes: {
      displayName: "HTML Attributes",
    },
  });
};

export const itemsConfigToRenderData = (
  config: ItemsConfig,
): {
  getValues: (config: {
    dataTables: Record<string, DataTable>;
    context?: Context;
    productDependencies: ProductResolutionDependencies;
  }) => any[];
} => {
  return exhaustiveSwitch(config)({
    dataTable: (config) => ({
      getValues: ({ dataTables, productDependencies }) => {
        if (!config || !config.id) {
          return [];
        }
        preloadAllImagesFromDataTable(config.id, dataTables);
        return (
          getRowObjectsFromDataTable(
            config.id,
            dataTables,
            productDependencies,
          ) ?? []
        );
      },
    }),
    productImages: (config) => ({
      getValues: ({ context, productDependencies }) => {
        const {
          products,
          currencyCode,
          language,
          moneyFormat,
          fakeProducts,
          loadingProduct,
          templateProduct,
          isShopifyProductsLoading,
        } = productDependencies;
        const product = getProduct(
          config?.productRef ?? null,
          context ?? null,
          {
            productMetafieldValues: {},
            variantMetafieldValues: {},
            products,
            currencyCode,
            moneyFormat,
            fakeProducts,
            loadingProduct,
            language,
            templateProduct,
            isEditor: productDependencies.isEditor,
            isShopifyProductsLoading,
          },
        );
        return product?.images ?? [];
      },
    }),
    htmlAttributes: () => ({
      // Note (Noah, 2025-03-20): _context needs to be here so typescript can infer the correct type
      getValues: ({ context: _context }) => {
        return [];
      },
    }),
    inline: (config) => ({
      getValues: ({ context }) => {
        if (config.valueType === "dynamic") {
          const matches = extractDynamicDataInfo(config.dynamicPath);
          const path = matches?.[0]?.path;
          if (!path) {
            return [];
          }
          return resolveDynamicDataPath(path, "", context!, null, undefined);
        }
        return config.values ?? [];
      },
    }),
  });
};

export const getItemObjectsForRender = (
  itemsConfig: ItemsConfig,
  dataTables: Record<string, DataTable>,
  context: Context,
  productDependencies: ProductResolutionDependencies,
): unknown[] | null => {
  if (!itemsConfig?.type) {
    return null;
  }
  return itemsConfigToRenderData(itemsConfig).getValues({
    dataTables,
    context,
    productDependencies,
  });
};

export const isItemsDynamic = (items: ItemsConfig | null | undefined) => {
  return Boolean(
    items &&
      exhaustiveSwitch(items)({
        [ItemsConfigType.htmlAttributes]: ({ values }) => values.length > 0,
        [ItemsConfigType.dataTable]: ({ id }) => Boolean(id),
        [ItemsConfigType.inline]: (inlineItems) => {
          return !(
            inlineItems.valueType === "string" &&
            inlineItems.values?.length === 0
          );
        },
        [ItemsConfigType.productImages]: ({ productRef }) =>
          Boolean(productRef),
      }),
  );
};
