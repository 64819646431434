import type { ToastProps } from "@replo/design-system/components/alert/Toast";

import { getDependencyData, publishDraftElement } from "@actions/core-actions";
import { elementTypeToEditorData } from "@components/editor/element";
import { useDraftElementMetadata } from "@editor/hooks/useDraftElementMetadata";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftElementId,
  selectDraftElementType,
  selectIsElementUpdateInProgressOrQueued,
  selectIsPublishing,
  selectRootComponentId,
  selectStoreUrl,
  selectTemplateShopifyProductIdsLength,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { getPageUrl } from "@editor/utils/element";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { toast } from "@replo/design-system/components/alert/Toast";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";

const publishErrorsBeforeStartMessages = {
  elementUpdatingOrQueued: {
    title: "An update to the page is saving.",
    message: "Please publish after saving is complete.",
  },
  productsStillLoading: {
    title: "Shopify Product data is still loading.",
    message: "Please publish after Shopify Product data finishes loading.",
  },
  elementStillLoading: {
    title: "Page data is still loading.",
    message: "Please publish after page data finishes loading.",
  },
};

export default function usePublishOnClickInfo() {
  const store = useEditorStore();
  const storeUrl = useEditorSelector(selectStoreUrl);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const draftElementType = useEditorSelector(selectDraftElementType);
  const elementMetadata = useDraftElementMetadata();
  const pageEditorData = elementTypeToEditorData[draftElementType];

  const publishElement = trpc.element.publish.useMutation({
    onMutate: async () => {
      return {
        shopifyPagePath: elementMetadata?.shopifyPagePath,
      };
    },
    onSuccess: (_errs, variables, context) => {
      let toastProps: ToastProps = {
        header: `${pageEditorData.singularDisplayName} Published`,
        message: `Your ${pageEditorData.singularDisplayName} was published to Shopify`,
      };
      if (draftElementType === "page" && context.shopifyPagePath) {
        const url = getPageUrl({
          storeUrl: storeUrl ?? undefined,
          shopifyPagePath: context.shopifyPagePath,
        });
        if (url) {
          toastProps = {
            ...toastProps,
            cta: "View Live Page",
            ctaOnClick: () => {
              window.open(url, "_blank", "noreferrer");
            },
          };
        }
      }
      toast(toastProps);

      void trpcUtils.element.findRevisions.invalidate({
        elementId: variables.elementId,
      });
      void trpcUtils.element.getElementMetadataById.invalidate(
        variables.elementId,
      );
      void trpcUtils.element.listAllElementsMetadata.invalidate();
    },
  });

  const isPublishingState = useEditorSelector(selectIsPublishing);
  const isPublishing = isFeatureEnabled("refactor-publishing")
    ? publishElement.isPending
    : isPublishingState;

  const dispatch = useEditorDispatch();
  // Note (Noah, 2024-02-29, USE-774): It might be that products have loaded but
  // the full component for this element hasn't yet. If this is the case, we still
  // want to block publishing
  const draftElementHasLoaded = Boolean(
    useEditorSelector(selectRootComponentId),
  );
  const { isLoading: isFetchProductLoading } =
    useStoreProductsFromDraftElement();
  const numAssignedProducts = useEditorSelector(
    selectTemplateShopifyProductIdsLength,
  );

  const publish = () => {
    if (isFeatureEnabled("refactor-publishing")) {
      if (draftElementId) {
        const dependencies = getDependencyData(store.getState());
        publishElement.mutate({
          elementId: draftElementId,
          publishDependencies: {
            ...dependencies,
            elementId: draftElementId,
          },
        });
      }
    } else {
      dispatch(publishDraftElement() as any);
    }
  };

  const isElementUpdateInProgressOrQueued = useEditorSelector(
    selectIsElementUpdateInProgressOrQueued,
  );

  let publishNotAvailableKey:
    | keyof typeof publishErrorsBeforeStartMessages
    | null = null;
  if (isElementUpdateInProgressOrQueued) {
    publishNotAvailableKey = "elementUpdatingOrQueued";
  } else if (isFetchProductLoading) {
    publishNotAvailableKey = "productsStillLoading";
  } else if (!draftElementHasLoaded) {
    publishNotAvailableKey = "elementStillLoading";
  }
  const publishNotAvailableReason = getFromRecordOrNull(
    publishErrorsBeforeStartMessages,
    publishNotAvailableKey,
  );

  return {
    publishNotAvailableReason,
    publishErrorsBeforeStartMessages,
    draftElementType,
    numAssignedProducts,
    publish,
    isPublishing,
    pageEditorData,
  };
}
