import type { WorkspaceRole } from "schemas/generated/workspace";
import type { InviteTeamMembersModalProps } from "../AppModalTypes";

import * as React from "react";

import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import PillTextArea from "@editor/components/common/PillTextArea";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { LargeMenuItem } from "@replo/design-system/components/menu/LargeMenuItem";
import { Modal } from "@replo/design-system/components/modal/Modal";
import * as Yup from "yup";

const getRoleOptions = (isWorkspaceOwner: boolean) => [
  {
    displayValue: "Member",
    label: (
      <LargeMenuItem
        label="Member"
        variant="default"
        description="Can create, edit, and share projects. No billing access."
      />
    ),
    value: "member",
    estimatedSize: 44,
  },
  ...(isWorkspaceOwner
    ? [
        {
          displayValue: "Owner",
          label: (
            <LargeMenuItem
              label="Owner"
              variant="default"
              description="Can fully configure and edit workspaces (billing, projects, permissions)."
            />
          ),
          value: "owner",
          estimatedSize: 44,
        },
      ]
    : []),
];

export const InviteTeamMembersModal: React.FC<InviteTeamMembersModalProps> = ({
  workspaceId,
  email,
}) => {
  const logEvent = useLogAnalytics();
  const [inviteAs, setInviteAs] = React.useState<WorkspaceRole>("member");
  const [emails, setEmails] = React.useState<string[]>(email ? [email] : []);
  const [inputError, setInputError] = React.useState<string | null>(null);
  const { closeModal } = useModal();

  const { data: users } =
    trpc.workspace.getWorkspaceAndProjectMembers.useQuery(workspaceId);

  const currentMembersList =
    users
      ?.filter((user) => user.workspaceMemberships.length > 0)
      .map((user) => user.email) ?? [];

  const {
    mutate: inviteWorkspaceMembers,
    isPending: isWorkspaceMembershipsLoading,
  } = trpc.workspace.inviteMembers.useMutation({
    onSuccess: () => {
      void trpcUtils.workspace.getWorkspaceAndProjectMembers.invalidate(
        workspaceId,
      );
      successToast("Invites sent", "The invites were sent successfully.");
      logEvent("workspace.members.add", {
        numberOfInvites: emails.length,
        emails,
        permissionLevel: inviteAs,
        workspaceId,
      });
      closeModal({ type: "inviteTeamMembersModal" });
    },
  });

  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);

  const roleOptions = getRoleOptions(isWorkspaceOwner);

  const handleSendInvites = () => {
    setInputError(null);
    const invalidEmails = emails.filter((email) =>
      currentMembersList.includes(email),
    );
    if (invalidEmails.length > 0) {
      setInputError(
        `The following email${invalidEmails.length === 1 ? " is" : "s are"} already ${invalidEmails.length === 1 ? "a member" : "members"} of this workspace: ${invalidEmails.join(
          ", ",
        )}`,
      );
      return;
    }

    if (emails.length === 0) {
      setInputError("Please enter at least one email address");
      return;
    }
    void inviteWorkspaceMembers({
      workspaceId: workspaceId ?? "",
      usersToGrantAccess: emails.map((email) => ({
        email,
        role: inviteAs,
      })),
    });
  };

  return (
    <Modal
      title="Invite Team Members"
      isOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          closeModal({ type: "inviteTeamMembersModal" });
        }
      }}
      size="base"
      footer={
        <Button
          variant="primary"
          size="base"
          onClick={handleSendInvites}
          isLoading={isWorkspaceMembershipsLoading}
          disabled={isWorkspaceMembershipsLoading}
        >
          Send Invites
        </Button>
      }
    >
      <div className="flex flex-col gap-5 w-full">
        <div className="flex justify-between items-center">
          <div className="typ-body-base text-default">Invite members as</div>
          <Combobox.Root
            value={inviteAs}
            onChange={(value) =>
              setInviteAs((value ?? "member") as "owner" | "viewer" | "member")
            }
            options={roleOptions}
          >
            <Combobox.Trigger>
              <Combobox.SelectionButton
                title={
                  roleOptions.find((option) => option.value === inviteAs)
                    ?.displayValue ?? "Member"
                }
                size="sm"
              />
            </Combobox.Trigger>
            <Combobox.Popover
              layoutClassName="w-[450px]"
              side="bottom"
              align="end"
            >
              <Combobox.Content />
            </Combobox.Popover>
          </Combobox.Root>
        </div>
        <PillTextArea
          pills={emails}
          setPills={setEmails}
          inputValidator={Yup.string()
            .email("Invalid email address")
            .required("Email is required")}
        />
        <ErrorMessage error={inputError} />
      </div>
    </Modal>
  );
};
