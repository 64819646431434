import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { SavedTextStyle } from "schemas/generated/designLibrary";

import * as React from "react";

import { DebouncedInput } from "@common/designSystem/Input";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";

import Popover from "@replo/design-system/components/popover/Popover";

import Separator from "../common/designSystem/Separator";
import FontFamilySelector from "../editor/page/element-editor/components/selectors/text-style/FontFamilySelector";
import FontSizeSelector from "../editor/page/element-editor/components/selectors/text-style/FontSizeSelector";
import FontWeightSelector from "../editor/page/element-editor/components/selectors/text-style/FontWeightSelector";
import LetterSpacingSelector from "../editor/page/element-editor/components/selectors/text-style/LetterSpacingSelector";
import LineHeightSelector from "../editor/page/element-editor/components/selectors/text-style/LineHeightSelector";
import TagSelector from "../editor/page/element-editor/components/selectors/text-style/TagSelector";
import ShopStylePreviewText from "./ShopStylePreviewText";

type TextSavedStyleModifierProps = {
  isPopoverOpen: boolean;
  onPopoverOpenChange: (isOpen: boolean) => void;
  textSavedStyle: SavedTextStyle & { id: string };
  handleUpdateTextSavedStyle: (
    updatedStyle: SavedTextStyle & { id: string },
  ) => void;
  sideOffset?: number;
};

const htmlTagToRichTextEditorTag = (htmlTag: string): RichTextEditorTag => {
  if (htmlTag === "p") {
    return "P";
  }
  const num = htmlTag.slice(1);
  return num as RichTextEditorTag;
};

const richTextEditorTagToHtmlTag = (
  tag: RichTextEditorTag,
): "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" => {
  if (tag === "P") {
    return "p";
  }
  return `h${tag}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
};

const TextSavedStyleModifier: React.FC<TextSavedStyleModifierProps> = ({
  isPopoverOpen,
  onPopoverOpenChange,
  textSavedStyle,
  sideOffset,
  handleUpdateTextSavedStyle,
}) => {
  const { storeName } = useGetStoreNameAndUrl();

  return (
    <Popover.Root isOpen={isPopoverOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside
        side="right"
        align="start"
        className="p-2 w-[250px]"
        title="Edit Text Style"
        sideOffset={sideOffset}
        onInteractOutside={() => {
          onPopoverOpenChange(false);
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex rounded-lg w-full p-2 items-center bg-neutral-soft h-16 justify-center">
            <ShopStylePreviewText
              attributes={textSavedStyle.attributes}
              text={storeName || "Your Brand"}
              className="text-[24px] leading-[32px] truncate overflow-x-hidden"
            />
          </div>

          <ControlGroup label="Name">
            <DebouncedInput
              id="name"
              placeholder="Name"
              value={textSavedStyle.name}
              onValueChange={(value) => {
                handleUpdateTextSavedStyle({
                  ...textSavedStyle,
                  name: value,
                });
              }}
            />
          </ControlGroup>

          <Separator />

          <TagSelector
            currentTag={htmlTagToRichTextEditorTag(
              textSavedStyle.attributes.htmlTag,
            )}
            onChangeTag={(richTextTag) => {
              const htmlTag = richTextEditorTagToHtmlTag(richTextTag);
              handleUpdateTextSavedStyle({
                ...textSavedStyle,
                attributes: {
                  ...textSavedStyle.attributes,
                  htmlTag,
                },
              });
            }}
          />

          <FontFamilySelector
            fontFamily={textSavedStyle.attributes.fontFamily}
            onSelect={(fontFamily) => {
              handleUpdateTextSavedStyle({
                ...textSavedStyle,
                attributes: {
                  ...textSavedStyle.attributes,
                  fontFamily: fontFamily ?? null,
                },
              });
            }}
            placeholder="Select a font"
          />

          <FontWeightSelector
            fontFamily={textSavedStyle.attributes.fontFamily}
            fontWeight={textSavedStyle.attributes.fontWeight}
            onSelect={(fontWeight) => {
              handleUpdateTextSavedStyle({
                ...textSavedStyle,
                attributes: {
                  ...textSavedStyle.attributes,
                  fontWeight: fontWeight as
                    | "100"
                    | "200"
                    | "300"
                    | "400"
                    | "500"
                    | "600"
                    | "700"
                    | "800"
                    | "900"
                    | "950",
                },
              });
            }}
          />

          <FontSizeSelector
            size={textSavedStyle.attributes.fontSize ?? null}
            onChange={(fontSize) => {
              handleUpdateTextSavedStyle({
                ...textSavedStyle,
                attributes: {
                  ...textSavedStyle.attributes,
                  fontSize,
                },
              });
            }}
          />

          <LineHeightSelector
            lineHeight={textSavedStyle.attributes.lineHeight ?? null}
            onChange={(lineHeight) => {
              handleUpdateTextSavedStyle({
                ...textSavedStyle,
                attributes: { ...textSavedStyle.attributes, lineHeight },
              });
            }}
          />

          <LetterSpacingSelector
            onChange={(letterSpacing) => {
              handleUpdateTextSavedStyle({
                ...textSavedStyle,
                attributes: { ...textSavedStyle.attributes, letterSpacing },
              });
            }}
          />
        </div>
      </Popover.Content>
      <Popover.Anchor className="top-0 left-0" />
    </Popover.Root>
  );
};

export default TextSavedStyleModifier;
