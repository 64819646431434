import * as React from "react";

import AssetsLibrary from "./AssetsLibrary";

const AssetsLibraryPane: React.FC = () => {
  return (
    <div className="flex flex-col flex-1 pl-3 min-h-0">
      <AssetsLibrary />
    </div>
  );
};

export default AssetsLibraryPane;
