import type { StoreProductSummary } from "schemas/product";

import * as React from "react";

import { RIGHT_BAR_WIDTH } from "@editor/components/editor/constants";
import { ProductSelectionCombobox } from "@editor/components/editor/page/ProductSelectionCombobox";
import {
  productSummaryRequestLimits,
  useAssignedProductsSummary,
  useFirstNProductsSummaryOrPlaceholderProducts,
  useTemplateEditorProductSummary,
} from "@editor/hooks/useProductsSummary";
import useRightBarVisibility from "@editor/hooks/useRightBarVisibility";
import {
  selectAllComponentIdsWithTemplateProductEqualsStates,
  selectDraftElementId,
  selectDraftElementTemplateProducts,
  selectDraftElementType,
  selectProjectId,
  setComponentVariant,
} from "@editor/reducers/core-reducer";
import {
  selectTemplateEditorProduct,
  setTemplateEditorProduct,
} from "@editor/reducers/template-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import IconButton from "@replo/design-system/components/button/IconButton";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { isEmpty } from "replo-utils/lib/misc";

const TemplateProductSelector = () => {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const isRightBarVisible = useRightBarVisibility();
  const projectId = useEditorSelector(selectProjectId);
  const dispatch = useEditorDispatch();
  const templateEditorProduct = useEditorSelector(selectTemplateEditorProduct);
  const assignedProductIds = useEditorSelector(
    selectDraftElementTemplateProducts,
  );

  const { isLoading: isLoadingProductsToLoopOver } = useProductsToLoopOver();
  const { isLoading: isLoadingAssignedProductsSummary } =
    useAssignedProductsSummary();
  useUpdateTemplateEditorProduct();
  const {
    templateEditorProductSummary,
    isLoading: isLoadingTemplateEditorProductSummary,
  } = useTemplateEditorProductSummary();

  const handleArrowButtons = useArrowButtonHandler();

  const anchorRef = React.useRef(null);

  const resetTemplateProductEqualsStates =
    useResetTemplateProductEqualsStates();

  if (
    draftElementType !== "shopifyProductTemplate" ||
    !draftElementId ||
    !projectId ||
    !templateEditorProduct
  ) {
    return null;
  }

  const isLoading =
    isLoadingProductsToLoopOver ||
    isLoadingAssignedProductsSummary ||
    isLoadingTemplateEditorProductSummary;

  return (
    <div
      className="fixed"
      style={{
        right: isRightBarVisible ? RIGHT_BAR_WIDTH + 14 : 14,
        top: 72,
      }}
    >
      <div className="box-border rounded bg-white shadow-lg py-2 px-3 h-16 w-64 flex flex-col justify-between">
        <div className="text-xs font-medium text-default">
          Editing Product Template With
        </div>
        {isLoading && (
          <div className="w-[256px] h-[24px] box-border bg-white absolute bottom-2 rounded">
            <Skeleton
              className="w-[256px] h-[24px]"
              speed={2}
              backgroundColor="#e2e8f0"
              foregroundColor="#cbd5e1"
            />
          </div>
        )}
        {!isLoading && templateEditorProductSummary && (
          <div
            className="flex items-start justify-between max-w-full"
            ref={anchorRef}
          >
            {templateEditorProductSummary && (
              <ProductSelectionCombobox
                size="sm"
                value={{
                  productId: templateEditorProductSummary.id,
                  title: templateEditorProductSummary.title,
                  variantId: undefined,
                }}
                onChange={(newProductRef) => {
                  if (newProductRef) {
                    dispatch(
                      setTemplateEditorProduct({
                        productId: newProductRef.productId,
                      }),
                    );
                    resetTemplateProductEqualsStates();
                  }
                }}
                side="left"
                sideOffset={20}
              />
            )}
            <div className="flex gap-1.5 ml-2">
              <IconButton
                icon={<BsChevronLeft size={8} className="text-subtle" />}
                variant="secondary"
                size="sm"
                tooltipText="Previous Product"
                onClick={() => {
                  handleArrowButtons("decrement");
                }}
                disabled={assignedProductIds?.length === 1}
              />
              <IconButton
                icon={<BsChevronRight size={8} className="text-subtle" />}
                variant="secondary"
                size="sm"
                tooltipText="Next Product"
                onClick={() => {
                  handleArrowButtons("increment");
                }}
                disabled={assignedProductIds?.length === 1}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const useProductsToLoopOver = (): {
  productsSummary: StoreProductSummary[] | undefined;
  isLoading: boolean;
  productListType: "assigned" | "all" | "placeholder" | undefined;
} => {
  const {
    productsSummary: assignedProductsSummary,
    isLoading: isLoadingAssignedProducts,
  } = useAssignedProductsSummary();

  const {
    productsSummary: firstNOrPlaceholderProductsSummary,
    isLoading: isLoadingFirstNProducts,
    isPlaceholderProducts,
  } = useFirstNProductsSummaryOrPlaceholderProducts(
    productSummaryRequestLimits.default,
  );

  if (isLoadingAssignedProducts || isLoadingFirstNProducts) {
    return {
      productsSummary: [],
      isLoading: true,
      productListType: undefined,
    };
  }

  if (!isEmpty(assignedProductsSummary)) {
    return {
      productsSummary: assignedProductsSummary,
      isLoading: false,
      productListType: "assigned",
    };
  }

  return {
    productsSummary: firstNOrPlaceholderProductsSummary,
    isLoading: false,
    productListType: isPlaceholderProducts ? "placeholder" : "all",
  };
};

const isPlaceholderProduct = (productId: number) => {
  return fakeProducts.some(
    (fakeProduct) => Number(fakeProduct.id) === productId,
  );
};

// Note (Evan, 2023-10-25): This hook handles updating the template editor product when we change stores/elements/etc.
const useUpdateTemplateEditorProduct = () => {
  const templateEditorProduct = useEditorSelector(selectTemplateEditorProduct);

  const {
    productsSummary: productsToLoopOver,
    productListType,
    isLoading: isLoadingProductsToLoopOver,
  } = useProductsToLoopOver();

  const {
    templateEditorProductSummary,
    isLoading: isLoadingTemplateEditorProductSummary,
  } = useTemplateEditorProductSummary();

  const assignedProductIds = useEditorSelector(
    selectDraftElementTemplateProducts,
  );

  const dispatch = useEditorDispatch();

  const resetTemplateProductEqualsStates =
    useResetTemplateProductEqualsStates();

  // Note (Evan, 2023-10-25): "One effect to rule them all" – this replaces the complex logic of resetting
  // [ 1) when the canvas loads, 2) when the draft element changes, and 3) when the assigned products change such that the
  // current product is no longer assigned ] with a single effect that resets the template editor product whenever it is
  // invalid (when it's not in the list of possible template products).
  //
  // We determine this based on the productListType (the strategy of what kinds of products are shown)
  // If we're showing assigned products, we should reset when the current product id is not assigned.
  // If we're showing all products, we should reset when the /summary endpoint can't find the current product.
  // If we're showing placeholder products, we should reset when the current product isn't a placeholder product.
  React.useEffect(() => {
    if (
      !isLoadingProductsToLoopOver &&
      productsToLoopOver &&
      !isLoadingTemplateEditorProductSummary
    ) {
      const shouldReset = (() => {
        if (!templateEditorProduct) {
          return true;
        }
        if (
          productListType === "assigned" &&
          assignedProductIds &&
          !assignedProductIds.includes(Number(templateEditorProduct.productId))
        ) {
          return true;
        }
        if (productListType === "all" && !templateEditorProductSummary) {
          return true;
        }
        if (
          productListType === "placeholder" &&
          !isPlaceholderProduct(Number(templateEditorProduct.productId))
        ) {
          return true;
        }
        return false;
      })();
      if (shouldReset) {
        const newProductSummary = productsToLoopOver[0];
        // Note (Evan, 2023-10-25): We know productIdsToLoopOver[0] will always be defined because we're using useProductsSummaryOrPlaceholderProducts,
        // so we're guaranteed to at least have placeholder products, but we put a check here to make Typescript (and Gabe) happy.
        if (newProductSummary) {
          dispatch(
            setTemplateEditorProduct({ productId: newProductSummary.id }),
          );
          resetTemplateProductEqualsStates();
        }
      }
    }
  }, [
    isLoadingProductsToLoopOver,
    productsToLoopOver,
    isLoadingTemplateEditorProductSummary,
    assignedProductIds,
    templateEditorProduct,
    productListType,
    templateEditorProductSummary,
    dispatch,
    resetTemplateProductEqualsStates,
  ]);
  return templateEditorProduct;
};

const useArrowButtonHandler = (): ((
  actionType: "increment" | "decrement",
) => void) => {
  const dispatch = useEditorDispatch();
  const templateEditorProduct = useEditorSelector(selectTemplateEditorProduct);
  const { productsSummary } = useProductsToLoopOver();
  const resetTemplateProductEqualsStates =
    useResetTemplateProductEqualsStates();

  return (actionType: "increment" | "decrement") => {
    if (!productsSummary || !templateEditorProduct) {
      return;
    }

    const productIdsToLoopOver = productsSummary.map((productSummary) =>
      Number(productSummary.id),
    );

    const currentIndex = productIdsToLoopOver.findIndex(
      (productId) => productId === Number(templateEditorProduct.productId),
    );

    const newIndex =
      actionType === "increment"
        ? (currentIndex + 1) % productIdsToLoopOver.length
        : (currentIndex + productIdsToLoopOver.length - 1) %
          productIdsToLoopOver.length;

    const newProduct = { productId: productIdsToLoopOver[newIndex] };
    // Note (Evan, 2023-10-12):  Typescript isn't smart enough to know that the
    // modulos ensure that newIndex will be <= products.length, so we expect an
    // error here
    // @ts-expect-error
    dispatch(setTemplateEditorProduct(newProduct));
    resetTemplateProductEqualsStates();
  };
};

// Note (Evan, 2023-11-16): Returns a function that looks for all components which have a "product equals" state and
// removes their entry from componentIdToDraftVariantId. The reasoning here is that changing the template product should
// cause states which depend on the template product to change.
const useResetTemplateProductEqualsStates = () => {
  const dispatch = useEditorDispatch();
  const ids = useEditorSelector(
    selectAllComponentIdsWithTemplateProductEqualsStates,
  );
  return () => {
    ids.forEach((componentId) => {
      dispatch(
        setComponentVariant({
          componentId,
          variantId: undefined,
        }),
      );
    });
  };
};

const TemplateProductSelectorWrapper = () => {
  const elementType = useEditorSelector(selectDraftElementType);
  return elementType === "shopifyProductTemplate" ? (
    <TemplateProductSelector />
  ) : null;
};

export default TemplateProductSelectorWrapper;
