import React from "react";

import Selectable from "@editor/components/common/designSystem/Selectable";
import { useFontWeightOptions } from "@editor/hooks/useFontWeightOptions";

import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import ControlGroup from "../../extras/ControlGroup";

interface FontWeightSelectorProps {
  fontFamily: string | null | undefined;
  fontWeight:
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"
    | "950"
    | null
    | undefined;
  onSelect: (newValue: string) => void;
}

const FontWeightSelector: React.FC<FontWeightSelectorProps> = ({
  fontFamily,
  fontWeight,
  onSelect,
}) => {
  const options = useFontWeightOptions(
    fontFamily && !isMixedStyleValue(fontFamily) ? fontFamily.toString() : null,
  );

  return (
    <ControlGroup label="Weight">
      <Selectable
        layoutClassName="w-full"
        placeholder="Weight"
        options={options}
        ignoreValueMismatchError
        value={
          isMixedStyleValue(fontWeight) ? fontWeight : fontWeight?.toString()
        }
        onSelect={onSelect}
      />
    </ControlGroup>
  );
};

export default FontWeightSelector;
