import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { DropTarget as AlchemyDropTarget } from "@editor/types/drop-target";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { Component } from "schemas/component";
import type { ReploElement } from "schemas/generated/element";

import {
  HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
  prepareComponentTemplate,
  VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
} from "@components/editor/defaultComponentTemplates";
import { transformDefaultValues } from "@editor/components/editor/page/element-editor/components/modifiers/utils";
import { shouldRemoveWhenLastChildIsRemoved } from "@editor/reducers/utils/component-actions";
import { getComponentMappingFromElement } from "@editor/reducers/utils/core-reducer-utils";
import {
  canDeleteComponent,
  canMoveComponentToParent,
  findComponentById,
} from "@utils/component";
import { ComponentCannotBeMoved } from "@utils/errors";

import { findParent } from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";

function findPositionInParent(parent: Component | null, componentId: string) {
  return parent?.children?.findIndex(({ id }) => id == componentId);
}

function findTargetPosition(
  targetEdge: string,
  parentOfTarget: Component | null,
  possibleTargetId: string,
) {
  const relativePosition =
    findPositionInParent(parentOfTarget, possibleTargetId) ?? 0;

  if (targetEdge !== "center") {
    return targetEdge === "top" || targetEdge === "left"
      ? { isBefore: true, targetPosition: relativePosition }
      : { isBefore: false, targetPosition: relativePosition + 1 };
  }

  return null;
}
function getNewContainer({
  draggingId,
  targetComponent,
  targetEdge,
  draftElement,
  getAttribute,
  componentDataMapping,
  productResolutionDependencies,
}: {
  draggingId: string;
  targetComponent: Component | null;
  targetEdge: string;
  draftElement: ReploElement;
  getAttribute: GetAttributeFunction;
  componentDataMapping: ComponentDataMapping;
  productResolutionDependencies: ProductResolutionDependencies;
}):
  | {
      needsNewContainer: true;
      newContainer: Component;
      shouldReverseFlexDirection: false;
    }
  | {
      needsNewContainer: false;
      newContainer: null;
      shouldReverseFlexDirection: boolean;
    } {
  if (!targetComponent) {
    return {
      needsNewContainer: false,
      newContainer: null,
      shouldReverseFlexDirection: false,
    };
  }

  const targetHasOnlyOneNonDraggingChild =
    targetComponent.children?.filter(({ id }) => id !== draggingId)?.length ===
    1;

  const isTargetVertical =
    getAttribute(targetComponent, "style.flexDirection", undefined).value ===
    "column";

  const isTargetEdgeLeftOrRight =
    targetEdge === "left" || targetEdge === "right";
  const isTargetEdgeTopOrBottom =
    targetEdge === "top" || targetEdge === "bottom";

  const needsNewContainer = isTargetVertical
    ? isTargetEdgeLeftOrRight
    : isTargetEdgeTopOrBottom;

  // Note (Fran, 2022-10-07): If the target has only one children we don't need
  // to add a new unnecessary container
  if (!needsNewContainer || targetHasOnlyOneNonDraggingChild) {
    // Note (Fran, 2022-10-07): If the target has only one children, we need to
    // figure with the target edge and the flex direction of the target if is
    // necessary to revert the flex direction to keep the alignment on the canvas
    // and not produce unwanted jumps or changes in the editor.
    const shouldReverseFlexDirection =
      (isTargetEdgeTopOrBottom &&
        !isTargetVertical &&
        targetHasOnlyOneNonDraggingChild) ||
      (isTargetEdgeLeftOrRight &&
        isTargetVertical &&
        targetHasOnlyOneNonDraggingChild);

    return {
      needsNewContainer: false,
      newContainer: null,
      shouldReverseFlexDirection,
    };
  }

  const newContainer = prepareComponentTemplate(
    isTargetVertical
      ? HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE
      : VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
    targetComponent,
    draftElement,
    {
      getAttribute: getAttribute,
      productResolutionDependencies,
      context: getCurrentComponentContext(targetComponent.id, 0) ?? null,
      componentDataMapping,
    },
  );

  const targetStyles: Record<string, string> = {};
  for (const attribute of [
    "justifyContent",
    "alignItems",
    "flexDirection",
  ] as const) {
    const newValue = getAttribute(
      targetComponent!,
      `style.${attribute}`,
      undefined,
    ).value;
    targetStyles[attribute] = newValue;
  }

  const newContainerFlexDirection = getAttribute(
    newContainer!,
    `style.flexDirection`,
    undefined,
  ).value;

  // Note (Fran, 2022-10-03): If we need to add a new container, when we drop
  // a component, we need to keep the alignment of the parent to not have some
  // random weird alignment in the editor.
  const isFlexDirectionSameInNewContainerAndTargetComponent =
    newContainerFlexDirection === targetStyles.flexDirection;

  // Note (Noah, 2023-07-15): We want to swap the justifyContent and alignItems
  // if we're creating a perpendicular container, but the "space" values for justifyContent
  // aren't valid for alignItems, so if justifyContent is spaced, we just default to setting
  // justifyContent to the same value as alignItems, regardless of orientation
  const justifyContentValues = [
    "space-between",
    "space-evenly",
    "space-around",
  ] as (string | undefined)[];
  const isJustifyContentSpaceValue = justifyContentValues.includes(
    targetStyles.justifyContent,
  );
  const newAlignItemsForSameDirection = isJustifyContentSpaceValue
    ? targetStyles.alignItems
    : targetStyles.justifyContent;

  newContainer.props.style!["alignItems"] =
    isFlexDirectionSameInNewContainerAndTargetComponent
      ? targetStyles.alignItems
      : newAlignItemsForSameDirection;

  newContainer.props.style!["justifyContent"] =
    isFlexDirectionSameInNewContainerAndTargetComponent
      ? targetStyles.justifyContent
      : targetStyles.alignItems;

  return {
    needsNewContainer,
    newContainer,
    shouldReverseFlexDirection: false,
  };
}

export const finalizeDropComponentActions = (
  target: AlchemyDropTarget,
  template: ComponentTemplate | null,
  draftElement: ReploElement,
  draftComponentId: string,
  getAttribute: GetAttributeFunction,
  componentDataMapping: ComponentDataMapping,
  productResolutionDependencies: ProductResolutionDependencies,
  isSavedComponent?: boolean,
  handleReplaceComponentWithDesignLibraryReferences?: (
    components: Component[],
    designLibraryMetadata: any | null,
  ) => Component[],
):
  | { result: "error"; message: string | null }
  | {
      result: "success";
      actions: UseApplyComponentActionType[];
      addedComponent: Component | null;
      needsNewContainer: boolean;
      parentId: string | null;
    } => {
  try {
    const componentActions: UseApplyComponentActionType[] = [];
    const componentMapping = getComponentMappingFromElement(draftElement);

    if (!target.edge || !target.componentId) {
      if (target.error) {
        return { result: "error", message: target.error };
      }
      return {
        result: "success",
        actions: componentActions,
        addedComponent: null,
        needsNewContainer: false,
        parentId: null,
      };
    }
    let possibleTarget = findComponentById(draftElement, target.componentId);

    if (!possibleTarget) {
      return { result: "error", message: null };
    }

    let parentOfTarget = findParent(draftElement, possibleTarget.id);

    const targetEdge = target.edge;

    let position;
    if (targetEdge === "top" || targetEdge === "left") {
      position = "sibling-before";
    } else if (targetEdge === "inside") {
      position = "child";
    } else {
      position = "sibling-after";
    }

    // If we tried to drag before or after the root component, treat this as
    // a special case and put the component inside the root component instead:
    if (
      !parentOfTarget &&
      ["sibling-after", "sibling-before"].includes(position)
    ) {
      // If the root component has no children, this functions the same as if
      // we dragged a component inside the root component
      if (possibleTarget.children?.length === 0) {
        position = "child";
      } else {
        // If the root component has children, put the dragged component above/
        // below the first/last child of the root
        parentOfTarget = possibleTarget;
        possibleTarget =
          (possibleTarget.children
            ? possibleTarget.children[
                position === "sibling-before"
                  ? 0
                  : possibleTarget.children.length - 1
              ]
            : null) ?? null;
      }
    }

    if (template && template.canBeAddedAsChild) {
      const result = template.canBeAddedAsChild({
        parent: position === "child" ? possibleTarget! : parentOfTarget!,
        element: draftElement,
      });
      if (result.canBeAdded === false) {
        throw new ComponentCannotBeMoved({
          message: result.message,
          type: "templateCannotBeAddedAsChild",
        });
      }
    }

    const componentToAddTo =
      position === "child" ? possibleTarget! : parentOfTarget!;
    const preparedComponent = template
      ? prepareComponentTemplate(template, componentToAddTo, draftElement, {
          getAttribute: getAttribute,
          productResolutionDependencies,
          context: getCurrentComponentContext(componentToAddTo.id, 0) ?? null,
          componentDataMapping,
        })
      : findComponentById(draftElement, draftComponentId);
    if (!preparedComponent) {
      return { result: "error", message: null };
    }

    const [componentToAdd] = handleReplaceComponentWithDesignLibraryReferences
      ? handleReplaceComponentWithDesignLibraryReferences(
          [preparedComponent],
          template?.designLibraryMetadata ?? null,
        )
      : [preparedComponent];
    if (!componentToAdd) {
      return { result: "error", message: null };
    }

    const leavingParent = template
      ? null
      : findParent(draftElement, componentToAdd.id);

    const { isBefore, targetPosition } = findTargetPosition(
      targetEdge,
      parentOfTarget!,
      possibleTarget!.id,
    ) ?? { isBefore: false, targetPosition: null };

    const { needsNewContainer, newContainer, shouldReverseFlexDirection } =
      getNewContainer({
        draggingId: template?.id ?? draftComponentId,
        targetComponent: parentOfTarget!,
        targetEdge,
        draftElement,
        getAttribute,
        componentDataMapping,
        productResolutionDependencies,
      });

    if (
      componentToAdd?.id === possibleTarget!.id ||
      componentToAdd?.id === parentOfTarget?.id
    ) {
      return { result: "error", message: null };
    }

    if (needsNewContainer && newContainer) {
      const targetStyles: Record<string, any> = {};
      for (const attribute of [
        "top",
        "left",
        "bottom",
        "right",
        "position",
        "zIndex",
        "flexDirection",
      ] as const) {
        const newValue = getAttribute(
          possibleTarget!,
          `style.${attribute}`,
          undefined,
        ).value;
        targetStyles[attribute] = newValue;
      }

      // add newContainer to componentTree
      componentActions.push(
        {
          type: "addComponentToComponent",
          componentId: possibleTarget!.id,
          elementId: draftElement.id,
          value: {
            newComponent: newContainer,
            position: "sibling-after",
          },
          analyticsExtras: { createdBy: "replo" },
        },
        // Move previous targetComponent to newContainer
        // Note (Noah, 2021-08-21): We don't check canMoveComponentToParent here
        // because we assume that if the child was in a valid position before, it
        // will still be in a valid position inside a container where the container
        // is where it was before. This is definitely the case today and probably
        // will be indefinitely
        {
          type: "moveComponentToParent",
          componentId: possibleTarget!.id,
          value: {
            parentComponentId: newContainer.id,
            positionWithinSiblings: 0,
          },
          analyticsExtras: { createdBy: "replo" },
        },
      );

      const targetFlexGrow = getAttribute(
        possibleTarget!,
        "style.flexGrow",
        null,
      ).value;

      // NOTE (Mariano, 2022-03-11): we overwrite the flexGrow because in this case
      // container should not have flex-grow unless the original component has it
      componentActions.push({
        type: "setStyles",
        componentId: newContainer.id,
        value: {
          flexGrow: targetFlexGrow ? targetStyles.flexGrow : "unset",
        },
        analyticsExtras: { createdBy: "replo" },
      });

      if (["absolute", "fixed"].includes(targetStyles.position)) {
        // Change alignItems and justifyContent of the container based on the target's position
        const { flexDirection } = targetStyles;
        const isFlexRow = flexDirection !== "column";
        const flexAlignStyles: Record<string, string> = {};
        const cssPropertyMap: Record<string, [string, string]> = {
          top: [isFlexRow ? "justifyContent" : "alignItems", "flex-start"],
          bottom: [isFlexRow ? "justifyContent" : "alignItems", "flex-end"],
          left: [isFlexRow ? "alignItems" : "justifyContent", "flex-start"],
          right: [isFlexRow ? "alignItems" : "justifyContent", "flex-end"],
        };

        Object.keys(cssPropertyMap).forEach((key) => {
          if (
            targetStyles[key] &&
            targetStyles[key] !== "auto" &&
            !targetStyles[key].includes("NaN")
          ) {
            const [cssProperty, cssValue] = cssPropertyMap[key]!;
            flexAlignStyles[cssProperty] = cssValue;
          }
        });

        componentActions.push(
          {
            type: "setStyles",
            componentId: possibleTarget!.id,
            value: {
              position: "relative",
              top: 0,
              left: 0,
            },
            analyticsExtras: { createdBy: "replo" },
          },
          {
            type: "setStyles",
            componentId: newContainer.id,
            value: {
              top: targetStyles.top,
              right: targetStyles.right,
              bottom: targetStyles.bottom,
              left: targetStyles.left,
              position: targetStyles.position,
              zIndex: targetStyles.zIndex,
              ...flexAlignStyles,
            },
            analyticsExtras: { createdBy: "replo" },
          },
        );
      }

      if (template) {
        componentActions.push({
          type: "addComponentToComponent",
          componentId: possibleTarget!.id,
          elementId: draftElement.id,
          value: {
            newComponent: componentToAdd,
            position,
          },
          analyticsExtras: {
            createdBy: "user",
            actionType: "create",
            isSavedComponent,
          },
        });
      } else {
        // move previous componentToAdd to newContainer
        componentActions.push({
          type: "moveComponentToParent",
          componentId: componentToAdd.id,
          value: {
            parentComponentId: newContainer.id,
            positionWithinSiblings: isBefore ? 0 : 1,
          },
        });
      }

      // delete previous component if the moved component was the only child
      // of its parent
      if (
        leavingParent &&
        (leavingParent.children?.length ?? 0) <= 1 &&
        canDeleteComponent(leavingParent.id, componentMapping).canDelete
      ) {
        componentActions.push({
          type: "deleteComponent",
          componentId: leavingParent.id,
          analyticsExtras: { createdBy: "replo" },
        });
      }
    } else if (template) {
      if (position === "child") {
        const result = canMoveComponentToParent(
          draftElement,
          componentToAdd,
          possibleTarget!,
          getAttribute,
          "leftBarTemplate",
          componentDataMapping,
        );
        if (!result.canMove) {
          throw new ComponentCannotBeMoved({
            message: result.message,
            type: "cannotMoveTemplateToParent",
          });
        }
      }
      componentActions.push({
        type: "addComponentToComponent",
        componentId: possibleTarget!.id,
        elementId: draftElement.id,
        value: {
          newComponent: componentToAdd,
          position,
        },
        analyticsExtras: {
          createdBy: "user",
          actionType: "create",
          isSavedComponent,
        },
      });

      // if no new container is needed, and new component is created from template
      // just add new component to the appropriate edge of targetComponent
    } else if (targetEdge === "inside") {
      const result = canMoveComponentToParent(
        draftElement,
        componentToAdd,
        possibleTarget!,
        getAttribute,
        template ? "leftBarTemplate" : "canvas",
        componentDataMapping,
      );
      if (!result.canMove) {
        throw new ComponentCannotBeMoved({
          message: result.message,
          type: "cannotBeMovedInside",
        });
      }
      componentActions.push({
        type: "moveComponentToParent",
        componentId: componentToAdd.id,
        value: {
          parentComponentId: possibleTarget!.id,
          positionWithinSiblings: targetPosition ?? 0,
        },
      });
    } else {
      const result = parentOfTarget
        ? canMoveComponentToParent(
            draftElement,
            componentToAdd,
            parentOfTarget,
            getAttribute,
            template ? "leftBarTemplate" : "canvas",
            componentDataMapping,
          )
        : { canMove: true as const };
      // if no new container is needed, just move componentToAdd to targetComponent
      if (!result.canMove) {
        throw new ComponentCannotBeMoved({
          message: result.message,
          type: "cannotAddChildToParent",
        });
      }
      componentActions.push({
        type: "moveComponentToParent",
        componentId: componentToAdd.id,
        value: {
          parentComponentId: parentOfTarget!.id,
          positionWithinSiblings: targetPosition ?? 0,
        },
      });

      // delete previous component if the
      // moved component was only child
      if (
        leavingParent &&
        shouldRemoveWhenLastChildIsRemoved(leavingParent.type) &&
        (leavingParent.children?.length ?? 0) <= 1 &&
        canDeleteComponent(leavingParent.id, componentMapping)
      ) {
        componentActions.push({
          type: "deleteComponent",
          componentId: leavingParent.id,
          analyticsExtras: { createdBy: "replo" },
        });
      }
    }

    if (
      getAttribute(componentToAdd, "style.position", undefined).value ===
      "absolute"
    ) {
      componentActions.push({
        type: "setStyles",
        value: {
          position: "static",
          // Note (Sebas, 2022-10-13): In case we have position absolute we also need
          // to reset the transform values because of the center positioning.
          __transform: transformDefaultValues,
        },
        analyticsExtras: { createdBy: "replo" },
      });
    }

    // Note (Fran, 2022-10-07): If we need to revert the flex direction, we also
    // need to revert the alignments of the parent.
    if (shouldReverseFlexDirection) {
      const parentOfTheTargetStyles: Record<string, string> = {};
      for (const attribute of [
        "justifyContent",
        "alignItems",
        "flexDirection",
      ] as const) {
        const value = getAttribute(
          parentOfTarget!,
          `style.${attribute}`,
          undefined,
        ).value;
        parentOfTheTargetStyles[attribute] = value;
      }

      componentActions.push({
        type: "setStyles",
        componentId: parentOfTarget!.id,
        value: {
          flexDirection:
            parentOfTheTargetStyles.flexDirection === "column"
              ? "row"
              : "column",
          justifyContent: parentOfTheTargetStyles.alignItems,
          alignItems: parentOfTheTargetStyles.justifyContent,
        },
        analyticsExtras: { createdBy: "replo" },
      });
    }

    return {
      result: "success",
      actions: componentActions,
      addedComponent: componentToAdd,
      needsNewContainer,
      parentId: parentOfTarget?.id ?? null,
    };
  } catch (error) {
    if (error instanceof ComponentCannotBeMoved) {
      return { result: "error", message: error.message };
    }
    throw error;
  }
};
