import type { EditorRootState } from "@editor/store";
import type { ReorderableTreeNode } from "@editor/types/tree";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Component } from "schemas/component";

import { findAncestorComponentData, isModal } from "@editor/utils/component";
import {
  elementToDataTree,
  forEachNodeAndDescendent,
  getComponentFlexDirection,
  isComponentHidden,
} from "@editor/utils/tree-utils";
import {
  selectComponentDataMapping,
  selectComponentMapping,
  selectDraftComponentIds,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftElementType,
  selectGetAttribute,
  selectRootComponent,
} from "@reducers/core-reducer";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import createCachedSelector from "re-reselect";
import { editorCanvasToMediaSize } from "replo-runtime/shared/utils/breakpoints";
import {
  forEachComponentAndDescendants,
  getChildren,
} from "replo-runtime/shared/utils/component";

export type TreeState = {
  explicitExpandedNodeIds: string[];
};

const initialState: TreeState = {
  explicitExpandedNodeIds: [],
};

const treeSlice = createSlice({
  name: "tree",
  initialState,
  reducers: {
    setExplicitExpandedNodeIds: (state, action: PayloadAction<string[]>) => {
      state.explicitExpandedNodeIds = action.payload;
    },
    toggleExpandedTreeNode: (
      state,
      action: PayloadAction<{ id: string; isExpanding: boolean }>,
    ) => {
      if (action.payload.isExpanding) {
        state.explicitExpandedNodeIds = [
          ...state.explicitExpandedNodeIds,
          action.payload.id,
        ];
      } else {
        state.explicitExpandedNodeIds = state.explicitExpandedNodeIds.filter(
          (nodeId) => nodeId !== action.payload.id,
        );
      }
    },
  },
});

const { actions, reducer } = treeSlice;

export const { setExplicitExpandedNodeIds, toggleExpandedTreeNode } = actions;
export default reducer;

export const selectExplicitExpandedNodeIds = (state: EditorRootState) => {
  return state.tree.explicitExpandedNodeIds;
};

const selectComponentTreeData = createSelector(
  selectRootComponent,
  selectDraftElementType,
  selectComponentDataMapping,
  (rootComponent, draftElementType, componentDataMapping) => {
    if (!rootComponent || !draftElementType) {
      return null;
    }
    return elementToDataTree({
      component: rootComponent,
      elementType: draftElementType,
      componentDataMapping,
    });
  },
);

export const selectLastVisibleTreeNodeId = createSelector(
  selectComponentTreeData,
  selectExplicitExpandedNodeIds,
  (treeData, expandedNodeIds) => {
    if (treeData) {
      const rootChildren = treeData.children;

      // Note (Noah, 2022-03-24): If the page is empty, assume the last visible node is
      // the page itself
      if (rootChildren.length === 0) {
        return treeData.id;
      }
      let lastVisibleTreeNode = rootChildren[rootChildren.length - 1]!;
      while (
        expandedNodeIds.includes(lastVisibleTreeNode.id) &&
        lastVisibleTreeNode.children.length > 0
      ) {
        lastVisibleTreeNode =
          lastVisibleTreeNode.children[
            lastVisibleTreeNode.children.length - 1
          ]!;
      }
      return lastVisibleTreeNode.id;
    }

    return null;
  },
);

export const selectIsLoadingTreePane = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectComponentTreeData,
  (draftElement, reorderableTreeData) => {
    return !draftElement?.component || !reorderableTreeData;
  },
);

export const selectIdToTreeNodeData = createSelector(
  selectComponentTreeData,
  (reorderableTreeData) => {
    const newIdToParentNode: Record<string, ReorderableTreeNode | null> = {};
    const newIdToNodeData: Record<
      string,
      { node: ReorderableTreeNode; order: number; depth: number }
    > = {};

    if (!reorderableTreeData) {
      return {
        idToParentNode: {},
        idToNodeData: {},
      };
    }
    let order = 0;
    forEachNodeAndDescendent(
      reorderableTreeData,
      null,
      (node, parentNode, depth) => {
        order += 1;
        newIdToParentNode[node.id] = parentNode || null;
        newIdToNodeData[node.id] = {
          node,
          order,
          depth,
        };
      },
      0,
    );
    return {
      idToNodeData: newIdToNodeData,
      idToParentNode: newIdToParentNode,
    };
  },
);

const selectAncestorTreeInfo = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponentIds,
  (draftElement, selectedIds) => {
    const info: Record<
      string,
      { isHidden: boolean; isSelected: boolean; props?: Record<string, any> }
    > = {};
    if (!draftElement || !draftElement.component) {
      return null;
    }
    forEachComponentAndDescendants(draftElement.component, (component) => {
      if (!info[component.id]) {
        info[component.id] = { isHidden: false, isSelected: false };
      }

      getChildren(component).forEach((child) => {
        if (!info[child.id]) {
          info[child.id] = { isHidden: false, isSelected: false };
        }

        info[child.id]!.isSelected =
          Boolean(info[component.id]?.isSelected) ||
          selectedIds.includes(component.id);

        info[child.id]!.props = component.props;
      });
    });

    return info;
  },
);

export const selectComponentTreeInfo = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    const info: Record<string, { component: Component }> = {};
    if (!draftElement || !draftElement.component) {
      return null;
    }
    forEachComponentAndDescendants(draftElement.component, (component) => {
      info[component.id] = { component };
    });
    return info;
  },
);

export const selectIsAncestorHidden = createCachedSelector(
  selectComponentDataMapping,
  selectComponentMapping,
  selectGetAttribute,
  (_: EditorRootState, id: string) => id,
  (componentDataMapping, componentMapping, getAttribute, id) => {
    return Boolean(
      findAncestorComponentData(
        id,
        (dataMapping) => {
          const component = componentMapping[dataMapping.id];
          if (!component || !component.parentComponent) {
            return false;
          }
          const ancestorComponent =
            componentMapping[component.parentComponent.id];
          if (!ancestorComponent) {
            return false;
          }
          return isComponentHidden(ancestorComponent.component, getAttribute);
        },
        componentDataMapping,
      ),
    );
  },
)((_state, id) => id);

export const selectAncestorFlexDirection = createSelector(
  selectAncestorTreeInfo,
  selectActiveCanvas,
  (_: EditorRootState, id: string) => id,
  (ancestorTreeInfo, device, id) => {
    const componentProps = ancestorTreeInfo?.[id]?.props;
    return getComponentFlexDirection(
      editorCanvasToMediaSize[device].mediaSize,
      componentProps,
    );
  },
);

export const selectIsAncestorSelected = createSelector(
  selectAncestorTreeInfo,
  (_: EditorRootState, id: string) => id,
  (ancestorTreeInfo, id) => {
    return ancestorTreeInfo?.[id]?.isSelected || false;
  },
);

export const selectRenderedTreeNodeIds = createSelector(
  selectIdToTreeNodeData,
  selectExplicitExpandedNodeIds,
  ({ idToNodeData, idToParentNode }, explicitExpandedNodeIds) => {
    return Object.keys(idToNodeData).filter((id) => {
      const parentNode = idToParentNode[id] as ReorderableTreeNode | null;
      return !parentNode || explicitExpandedNodeIds.includes(parentNode.id);
    });
  },
);

export const selectIsExpandedTreeNode = createSelector(
  selectExplicitExpandedNodeIds,
  selectIdToTreeNodeData,
  (_: EditorRootState, id: string) => id,
  (explicitExpandedNodeIds, { idToNodeData }, id) => {
    if (!idToNodeData[id]) {
      return false;
    }
    const { node } = idToNodeData[id]!;
    // Note: Ovishek (2022-03-21) - we need to add node.children.length > 0 to the condition b/c
    // it's possible to create the scenario where node that has no child is in expandedNodeIds
    // to repro this, drag a node to a empty container then undo via cmd+z, the container id will be still in expandedNodeIds
    return (
      explicitExpandedNodeIds.includes(node?.id) && node?.children.length > 0
    );
  },
);

export const selectAreAnyComponentModals = createSelector(
  selectComponentDataMapping,
  (mapping) => {
    return Object.values(mapping).some((data) => {
      return isModal(data.type);
    });
  },
);

export const selectModalComponentData = createSelector(
  selectComponentDataMapping,
  (mapping) => {
    return Object.values(mapping)
      .filter((data) => isModal(data.type))
      .map((data) => ({
        id: data.id,
        name: data.label,
      }));
  },
);

export const selectIsComponentHidden = createSelector(
  selectComponentTreeInfo,
  selectGetAttribute,
  (_: EditorRootState, id: string) => id,
  (componentTreeInfo, getAttribute, id) => {
    const component = componentTreeInfo?.[id]?.component;
    if (!component) {
      return true;
    }
    return isComponentHidden(component, getAttribute);
  },
);

export const selectDoesNodeHaveChildren = createSelector(
  selectComponentTreeInfo,
  (_: EditorRootState, id: string) => id,
  (componentTreeInfo, id) => {
    const component = componentTreeInfo?.[id]?.component;
    if (!component) {
      return false;
    }
    const children = getChildren(component);
    return Boolean(children.length);
  },
);

export const selectComponentMarkers = createSelector(
  selectComponentTreeInfo,
  (_: EditorRootState, id: string) => id,
  (componentTreeInfo, id) => {
    const component = componentTreeInfo?.[id]?.component;
    return component?.markers;
  },
);
