import type { Component } from "schemas/component";
import type {
  RenderComponentAttributes,
  RenderComponentProps,
} from "../../../shared/types";
import type { Context } from "../../ReploVariable";
import type { ActionType } from "./config";

import * as React from "react";

import * as Accordion from "@radix-ui/react-accordion";
import * as Collapsible from "@radix-ui/react-collapsible";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { useAccordion } from "../../components/AccordionBlock/context";
import useSharedState from "../../hooks/useSharedState";
import { ReploComponent } from "../ReploComponent";

const CollapsibleV2: React.FC<RenderComponentProps> = (props) => {
  const { component, context, componentAttributes } = props;

  const openComponent = component.props["_openComponent"];
  const collapsibleComponent = component.props["_collapsibleComponent"];
  const defaultOpen = component.props["_defaultOpen"];
  const isAnimated = component.props["_isAnimated"] ?? false;
  const componentId = component.id;
  const repeatedIndexPath = context.repeatedIndexPath;

  const [isOpen, setIsOpen] = useSharedState(
    [componentId, "isOpen"],
    defaultOpen,
  );

  const accordionRoot = useAccordion();
  const isAccordion = Boolean(accordionRoot);

  const ReploCollapsibleComponent = isAccordion ? Accordion : Collapsible;

  const collapsibleIsOpen = isAccordion
    ? accordionRoot!.openItems.some((itemId: string) => itemId === componentId)
    : isOpen;

  // NOTE (Chance 2024-03-26, USE-825): We create our own DOM ID for the
  // collapsible content. This is necessary because we wrap `ReploComponent`
  // with a Radix component and use `asChild`. This actually breaks a few things
  // we end up patching in this component, but one of them is that the
  // underlying `id` generated by Radix is not associated with the correct DOM
  // element when used in `aria-controls`. This allows us to override that id
  // and then use it for both the trigger and content components.
  const contentDomId = `replo-${React.useId()}`;

  const actionHooks = {
    toggleCollapsible: () => {
      if (isAccordion) {
        accordionRoot!.toggleItem(componentId);
      } else {
        setIsOpen(!isOpen);
      }
    },
  } satisfies {
    [K in ActionType]: Function;
  };

  const nextContext = mergeContext(context, {
    // Note (Fran, 2023-05-31): If the collapsible is inside an accordion, we need to toggle it from the accordion
    // that way we will manage the open collapsibles from the Accordion component. Otherwise, we will manage the open
    // with the isOpen collapsible state.
    actionHooks,
    state: {
      collapsibleV2: {
        isOpen: collapsibleIsOpen,
        collapsibleComponentId: componentId,
      },
    },
  });

  const collapsibleReploComponent = collapsibleComponent && (
    <ReploComponent
      component={collapsibleComponent}
      context={nextContext}
      repeatedIndexPath={repeatedIndexPath ?? ".0"}
    />
  );

  const { isPublishing } = useRuntimeContext(RenderEnvironmentContext);

  // NOTE (Gabe 2023-06-09): For server-side rendering (SSR), we avoid rendering
  // Collapsible.Content prior to hydration. This is due to the requirement of
  // having liquid functionality inside our collapsibles. The contents of
  // Collapsible.Content isn't rendered on the server side by default, unless we
  // pass the forceMount parameter. However, utilizing forceMount negatively
  // impacts our animations.
  //
  // As a workaround, we render the content within a div that carries identical
  // styles and attributes. If the content is initially collapsed, we render it
  // as "closed" using 'hidden' to prevent layout shifts. This approach ensures
  // that animations are preserved and SSR requirements are met without
  // disrupting the UX with unexpected layout changes.
  const skipRadixComponent = isPublishing;

  // NOTE (Chance 2024-03-26): Intentionally using `null` here instead of
  // `undefined`. If we pass undefined, related DOM attributes will fall-back to
  // what is assigned by Radix. If the content component isn't present we don't
  // want to apply aria attributes at all since necessary elements won't exist.
  const ariaControls = collapsibleReploComponent ? contentDomId : null!;

  const extraAttributes = React.useMemo(() => {
    return {
      "aria-expanded": collapsibleIsOpen,
      "aria-controls": ariaControls,
    };
  }, [ariaControls, collapsibleIsOpen]);

  return (
    <ReploCollapsibleV2Wrapper
      isOpen={collapsibleIsOpen ?? false}
      isAccordion={isAccordion}
      component={component}
      openComponent={openComponent}
      collapsibleComponent={collapsibleComponent}
      defaultOpen={defaultOpen}
      isAnimated={isAnimated}
      componentId={componentId}
      context={context}
      attributes={componentAttributes}
      repeatedIndexPath={repeatedIndexPath}
    >
      {openComponent && (
        <ReploCollapsibleComponent.Trigger asChild>
          <ReploComponent
            component={openComponent}
            context={nextContext}
            repeatedIndexPath={repeatedIndexPath ?? ".0"}
            extraAttributes={extraAttributes}
          />
        </ReploCollapsibleComponent.Trigger>
      )}
      {collapsibleReploComponent &&
        (skipRadixComponent ? (
          <div
            className={isAnimated ? "replo-animated" : undefined}
            hidden={!defaultOpen}
            id={contentDomId}
            aria-controls={extraAttributes["aria-controls"] ?? undefined}
            aria-expanded={extraAttributes["aria-expanded"]}
          >
            {collapsibleReploComponent}
          </div>
        ) : (
          <ReploCollapsibleComponent.Content
            className={isAnimated ? "replo-animated" : undefined}
            id={contentDomId}
          >
            {collapsibleReploComponent}
          </ReploCollapsibleComponent.Content>
        ))}
    </ReploCollapsibleV2Wrapper>
  );
};

export default CollapsibleV2;

interface CollapsibleWrapperProps {
  component: Component;
  openComponent?: Component;
  collapsibleComponent?: Component;
  defaultOpen: boolean | undefined;
  isAnimated: boolean;
  componentId: string;
  context: Context;
  attributes: RenderComponentAttributes;
  repeatedIndexPath: string;
  isOpen: boolean;
  isAccordion: boolean;
}

const ReploCollapsibleV2Wrapper: React.FC<
  React.PropsWithChildren<CollapsibleWrapperProps>
> = ({ children, componentId, attributes, isOpen, isAccordion }) => {
  return isAccordion ? (
    <Accordion.Item asChild key={componentId} value={componentId}>
      <div {...attributes} data-replo-collapsible="true">
        {children}
      </div>
    </Accordion.Item>
  ) : (
    <Collapsible.Root asChild open={isOpen}>
      <div {...attributes} data-replo-collapsible="true">
        {children}
      </div>
    </Collapsible.Root>
  );
};
