import type {
  FlowNextStep,
  FlowStepConfig,
  FlowStepData,
} from "schemas/generated/flow";
import type {
  SavedColorStyle,
  SavedTextStyle,
} from "./generated/designLibrary";

import pickBy from "lodash-es/pickBy";
import { z } from "zod";

import { brandDetailsSchema } from "./ai";
import { componentTemplateIndustryNamesSchema } from "./componentTemplates";
import { savedColorStyleSchema, savedTextStyleSchema } from "./designLibrary";

// #region Flow input components schemas
const optionSchema = z.object({
  value: z.string(),
  label: z.string(),
  hasInput: z.boolean(),
  extraData: z.record(z.string(), z.unknown()).optional(),
});

const selectableButtonWithInputPropsSchema = z.object({
  type: z.literal("selectable-button"),
  label: z.string(),
  options: z.array(optionSchema),
});

const textInputPropsSchema = z.object({
  type: z.literal("input"),
  label: z.string(),
  placeholder: z.string(),
  inputType: z.literal("text"),
  isRequired: z.boolean(),
});

const stepResultPropertySchema = z.union([
  z.literal("buildingFor"),
  z.literal("isUserReferredOrInvited"),
  z.literal("userHasPendingAppInstallation"),
  z.literal("wasUrlProvided"),
  z.literal("userSource"),
]);

// #endregion

const flowSteps = [
  // #region Onboarding flow steps
  "onboarding.user.full-name",
  "onboarding.user.import-from-url",
  "onboarding.user.who-are-you-building-pages-for",
  "onboarding.user.workspace-name",
  "onboarding.user.what-do-you-want-to-do-in-replo",
  "onboarding.user.who-are-you-building-pages-for.revenue",
  "onboarding.user.which-best-describes-your-industry",
  "onboarding.user.brand-details",
  "onboarding.user.saved-styles",
  "onboarding.user.do-you-want-to-start-from-template",
  "onboarding.user.connect-shopify",
  // #endregion
  // #region Editor tour flow steps
  "tour.editor.generic",
  // #endregion
  // Add other flow steps here
] as const;

export const stepTypeSchema = z.enum(flowSteps).describe("StepType");

export type FlowStepType = (typeof flowSteps)[number];

export const flowSlugSchema = z
  .enum([
    "onboarding-styles",
    "editor-tour",
    // Add other flow slugs here
  ])
  .describe("FlowSlug");

const onboardingFlowConfigSchema = z.union([
  z.object({
    type: z.literal("onboarding.user.full-name"),
    props: z.array(textInputPropsSchema),
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.workspace-name"),
    props: z.object({}),
  }),
  z.object({
    type: z.literal("onboarding.user.what-do-you-want-to-do-in-replo"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.do-you-want-to-start-from-template"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for.revenue"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.which-best-describes-your-industry"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.import-from-url"),
    props: z.object({}),
  }),
  z.object({
    type: z.literal("onboarding.user.brand-details"),
    props: z.object({}),
  }),
  z.object({
    type: z.literal("onboarding.user.saved-styles"),
    props: z.object({}),
  }),
  z.object({
    type: z.literal("onboarding.user.connect-shopify"),
    props: z.object({}),
  }),
]);

export const editorTourStepPositionSchema = z
  .union([
    z.literal("top-start"),
    z.literal("top-center"),
    z.literal("top-end"),
    z.literal("right-start"),
    z.literal("right-center"),
    z.literal("right-end"),
    z.literal("bottom-start"),
    z.literal("bottom-center"),
    z.literal("bottom-end"),
    z.literal("left-start"),
    z.literal("left-center"),
    z.literal("left-end"),
  ])
  .describe("EditorTourStepPosition");

const editorTourConfigSchema = z.object({
  type: z.literal("tour.editor.generic"),
  props: z.object({
    content: z.object({
      type: z.literal("text"),
      text: z.string(),
    }),
    side: editorTourStepPositionSchema,
  }),
});

export const flowStepConfigSchema = z
  .union([onboardingFlowConfigSchema, editorTourConfigSchema])
  .describe("FlowStepConfig");

const onboardingFlowStepDataSchema = z.union([
  z.object({
    type: z.literal("onboarding.user.full-name"),
    data: z.object({
      firstName: z.string(),
      lastName: z.string(),
      shopifyUrl: z.string().nullish(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for"),
    data: z.record(z.literal("buildingFor"), z.string()),
  }),
  z.object({
    type: z.literal("onboarding.user.workspace-name"),
    data: z.object({
      workspaceName: z.string(),
      projectId: z.string().optional(),
      createdWorkspaceId: z.string().optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.what-do-you-want-to-do-in-replo"),
    data: z.record(z.literal("whatToDo"), z.array(z.string())),
  }),

  z.object({
    type: z.literal("onboarding.user.do-you-want-to-start-from-template"),
    data: z.object({
      templateId: z.string().optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for.revenue"),
    data: z.object({
      yearlyRevenue: z.string().nullish(),
      clientCount: z.string().nullish(),
      clientYearlyRevenue: z.string().nullish(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.which-best-describes-your-industry"),
    data: z.record(z.literal("industry"), z.string()),
  }),
  z.object({
    type: z.literal("onboarding.user.import-from-url"),
    data: z.object({
      url: z.string().url().or(z.literal("")),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.brand-details"),
    data: brandDetailsSchema.extend({
      industry: componentTemplateIndustryNamesSchema.or(z.string()).optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.saved-styles"),
    data: z.object({
      saveStyles: z.boolean(),
      primaryStyles: z
        .object({
          color: z.array(savedColorStyleSchema as z.ZodType<SavedColorStyle>),
          text: z.array(savedTextStyleSchema as z.ZodType<SavedTextStyle>),
        })
        .optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.connect-shopify"),
    data: z.object({}),
  }),
]);
const editorTourStepDataSchema = z.object({
  type: z.literal("tour.editor.generic"),
  data: z.object({}),
});

export const flowStepDataSchema = z
  .union([onboardingFlowStepDataSchema, editorTourStepDataSchema])
  .describe("FlowStepData");

export const flowConditionSchema = z
  .union([
    z.object({
      type: z.literal("response"),
      nextStep: z.string().nullable(),
      operator: z.literal("eq"),
      property: stepResultPropertySchema,
      value: z.union([z.string(), z.boolean(), z.null()]),
    }),
    z.object({
      type: z.literal("response"),
      nextStep: z.string().nullable(),
      operator: z.literal("notIn"),
      property: stepResultPropertySchema,
      value: z.array(z.string()),
    }),
    z.object({
      type: z.literal("and"),
      nextStep: z.string().nullable(),
      conditions: z.array(
        z.union([
          z.object({
            type: z.literal("response"),
            operator: z.literal("eq"),
            property: stepResultPropertySchema,
            value: z.union([z.string(), z.boolean(), z.null()]),
          }),
          z.object({
            type: z.literal("response"),
            operator: z.literal("notIn"),
            property: stepResultPropertySchema,
            value: z.array(z.string()),
          }),
        ]),
      ),
    }),
    z.object({
      type: z.literal("else"),
      nextStep: z.string().nullable(),
    }),
  ])
  .describe("FlowStepCondition");

export const flowNextStepSchema = z
  .union([z.string(), z.array(flowConditionSchema), z.null()])
  .describe("FlowNextStep");

export const flowNextStepSubmitResponseSchema = z.union([
  z.string(),
  z.null(),
  z.undefined(),
]);

export const flowStepSchema = z
  .object({
    id: z.string(),
    isSkippable: z.boolean(),
    nextStep: flowNextStepSchema as z.ZodType<FlowNextStep>,
  })
  .and(flowStepConfigSchema)
  .describe("FlowStep");

export const arrayOfFlowStepSchema = z.array(flowStepSchema);

export const flowEntityTypeSchema = z
  .union([z.literal("project"), z.literal("workspace"), z.literal("user")])
  .describe("FlowEntity");

export const flowStatusSchema = z
  .union([z.literal("draft"), z.literal("live")])
  .describe("FlowStatus");

export const flowSchema = z
  .object({
    slug: flowSlugSchema,
    name: z.string(),
    type: flowEntityTypeSchema,
    status: flowStatusSchema,
    steps: arrayOfFlowStepSchema,
    isRepeatable: z.boolean(),
  })
  .describe("Flow");

export const flowInstanceStepResultSchema = z
  .intersection(
    z.object({ completedAt: z.string().nullable(), skipped: z.boolean() }),
    flowStepDataSchema,
  )
  .describe("FlowInstanceStepResult");

// NOTE (Cole, 2025-03-25): This is where we want to filter out steps that exist in the db but are now not in the flow. Zod errors will be thrown if we don't do this.
export const flowStepResultsSchema = z
  .preprocess(
    (stepResults) => {
      const validStepTypes = flowSteps;

      const filteredResults = pickBy(
        stepResults as Record<string, unknown>,
        (_, key) => {
          const keyWithPrefix = `onboarding.user.${key}`;
          const isValid = validStepTypes.includes(
            keyWithPrefix as FlowStepType,
          );

          return isValid;
        },
      );

      return filteredResults;
    },
    z.record(z.string(), flowInstanceStepResultSchema),
  )
  .describe("FlowStepResultsDataType");

export const flowInstanceSchema = z
  .object({
    id: z.string(),
    flow: flowSchema,
    entityId: z.string(),
    entityType: z.string(),
    stepResults: flowStepResultsSchema.optional().nullable(),
    completedAt: z.coerce.date().nullable(),
    isDebug: z.boolean(),
  })
  .describe("FlowInstance");

export const flowInstanceResponseSchema = z
  .object({
    instance: flowInstanceSchema,
    nextStep: z.string().nullish(),
  })
  .describe("FlowInstanceResponse");

// TODO (Gabe 2024-09-27): Lets get the functionality working before working
// about these types.

export type FlowStepDataValueOf<TargetType extends FlowStepData["type"]> =
  Extract<FlowStepData, { type: TargetType }>["data"];
export type FlowStepConfigPropsValueOf<
  TargetType extends FlowStepConfig["type"],
> = Extract<FlowStepConfig, { type: TargetType }>["props"];
export type FlowStepOptionSchema = {
  value: string;
  label: string;
  hasInput: boolean;
  extraData?: Record<string, unknown>;
};
