import * as React from "react";

import { ConnectShopifyCallout } from "@editor/components/editor/page/ConnectShopifyCallout";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { selectDoesExistDraftElement } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import Button from "@replo/design-system/components/button/Button";
import Popover from "@replo/design-system/components/popover/Popover";

export const PublishPageButtonWithCallout: React.FC = () => {
  const draftElementExists = useEditorSelector(selectDoesExistDraftElement);
  const projectId = useCurrentProjectId();
  const workspaceId = useCurrentWorkspaceId();

  return (
    <Popover
      shouldPreventDefaultOnInteractOutside={false}
      content={
        <ConnectShopifyCallout
          cookieValue={{
            type: "publish",
            projectId: projectId!,
            workspaceId: workspaceId!,
          }}
        />
      }
      side="bottom"
      align="end"
      sideOffset={16}
      triggerAsChild
      hideCloseButton
      className="w-72"
    >
      <Button variant="primary" size="base" disabled={!draftElementExists}>
        Publish
      </Button>
    </Popover>
  );
};
