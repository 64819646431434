import type { ComponentTemplate } from "@editor/types/component-template";

export const h3: ComponentTemplate = {
  id: "35625d1a-3c26-4bdc-b700-c4a39d918e05",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Basic/h3",
  leftBarCategory: { main: "basic", subCategory: "text" },
  preview: "basic/h3.svg",
  tags: ["heading", "text"],
  transforms: [],
  template: {
    id: "$uid",
    type: "text",
    props: {
      text: "<h3>Add new heading here</h3>",
      style: {
        fontSize: "24px",
        lineHeight: "28px",
      },
    },
  },
};
