import type { BillingStripeNotificationModalProps } from "../AppModalTypes";

import * as React from "react";

import { useModal } from "@editor/hooks/useModal";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";

export const BillingStripeNotificationModal: React.FC<
  BillingStripeNotificationModalProps
> = ({ buttonText, onConfirm }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const modal = useModal();

  const closeModal = () => {
    modal.closeModal({ type: "billingStripeNotificationModal" });
  };
  const onClick = () => {
    setIsLoading(true);
    onConfirm();
  };

  return (
    <Modal
      isOpen={true}
      title="Welcome to a New Billing Portal"
      onOpenChange={(open) => {
        if (!open) {
          closeModal();
        }
      }}
      size="base"
      footer={
        <Button
          variant="primary"
          size="base"
          onClick={onClick}
          isLoading={isLoading}
        >
          {buttonText}
        </Button>
      }
      data-testid="billing-plan-confirmation-modal"
    >
      <div className="flex flex-col gap-4">
        To enhance your Replo experience, we've transitioned to a new billing
        portal that offers:
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <span className="font-bold">Enhanced Security</span> - Payment
            information is safer
          </li>
          <li>
            <span className="font-bold">Full Control</span> - Easily upgrade or
            downgrade
          </li>
          <li>
            <span className="font-bold">Clear Visibility</span> - Understand
            exactly how and when you are billed
          </li>
          <li>
            <span className="font-bold">Direct Support</span> - Work directly
            with the Replo team for any billing issues
          </li>
        </ul>
        Please Note: You'll need to input your payment information again in the
        new portal. Your current plan billed through Shopify will be
        discontinued and a prorated refund will be issued.
      </div>
    </Modal>
  );
};
