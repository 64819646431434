import type { Option } from "@editorComponents/Lists";
import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type {
  ReploShopifyVariant,
  ShopifySellingPlan,
  StoreProduct,
} from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/ReploVariable";
import type {
  Component,
  CustomComponentPropType,
  CustomPropDefinition,
} from "schemas/component";
import type {
  ProductRef,
  SelectedSellingPlanIdOrOneTimePurchase,
} from "schemas/product";

import * as React from "react";

import ButtonGroupComponent from "@common/designSystem/ButtonGroup";
import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import Selectable from "@common/designSystem/Selectable";
import AnyDynamicDataValueSelector from "@components/editor/customProp/AnyDynamicDataValueSelector";
import { FieldWithDescription } from "@components/editor/customProp/FieldWithDescription";
import { ImageCustomPropModifier } from "@components/editor/customProp/ImageCustomPropModifier";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { CodeEditorCustomPropModifier } from "@editor/components/editor/customProp/CodeEditorCustomPropModifier";
import { OptionsCustomPropModifier } from "@editor/components/editor/customProp/OptionsCustomPropModifier";
import { ProductsCustomPropModifier } from "@editor/components/editor/customProp/ProductsCustomPropModifier";
import { SwatchesCustomPropModifier } from "@editor/components/editor/customProp/SwatchesCustomPropModifier";
import { SUB_APP_APPID_TO_NAME } from "@editor/constants/sub-app-appid-to-name";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { selectStoreShopifyUrl } from "@editor/reducers/core-reducer";
import { selectThemeId } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingTypes } from "@editor/utils/editor";
import { trpc } from "@editor/utils/trpc";
import DynamicDataValueIndicator from "@editorExtras/DynamicDataValueIndicator";
import {
  CombinedItemsSelector,
  ItemsSelector,
} from "@editorModifiers/ItemsModifier";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";
import Stepper from "@editorModifiers/Stepper";

import { Badge } from "@replo/design-system/components/badge/Badge";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import { skipToken } from "@tanstack/react-query";
import orderBy from "lodash-es/orderBy";
import startCase from "lodash-es/startCase";
import { BsFillBagPlusFill } from "react-icons/bs";
import { FiMinus, FiPlus } from "react-icons/fi";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import {
  DEFAULT_ACTIVE_CURRENCY,
  DEFAULT_ACTIVE_LANGUAGE,
  DEFAULT_MONEY_FORMAT,
} from "replo-runtime/shared/liquid";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { isNotNullish } from "replo-utils/lib/misc";
import { isBoolean } from "replo-utils/lib/type-check";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";
import { ItemsConfigType } from "schemas/dynamicData";
import { ReploError } from "schemas/errors";

import DynamicDataButton from "../common/designSystem/DynamicDataButton";
import SellingPlanSelector from "./actions/SellingPlanSelector";
import { IntegerSelector } from "./customProp/IntegerSelector";
import { ConnectShopifyCallout } from "./page/ConnectShopifyCallout";
import { DynamicDataSelector } from "./page/DynamicDataSelector";
import SolidColorSelector from "./page/element-editor/components/SolidColorSelector";

class InvalidDateValueError extends ReploError {
  constructor(value: string) {
    super({
      message: "Invalid value returned from date picker input",
      additionalData: { value },
    });
  }
}

// https://webreflection.medium.com/using-the-input-datetime-local-9503e7efdce
function toDatetimeLocal(date: Date) {
  const ten = function (i: number) {
      return (i < 10 ? "0" : "") + i;
    },
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes()),
    SS = ten(date.getSeconds());
  return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`;
}

type CustomComponentPropRenderProps = {
  component: Component;
  value: any;
  defaultValue?: any;
  customPropId: string;
  description: string;
  onChange(value: any): void;
  onDelete(): void;
  attributeName: string;
  options?: any;
  isEnabled: boolean;
  disabledDescription?: string;
  disableDynamicData?: boolean;
  definition: CustomPropDefinition;
  context: Context | null;
  products: StoreProduct[];
  templateProduct: StoreProduct | null;
  placeholder?: string;
};

type ComponentPropTypeEditorData = {
  render(renderProps: CustomComponentPropRenderProps): React.ReactNode;
  shouldRender(component: Component, context?: Context): boolean;
  defaultValue: any;
  hasCustomWrapper?: boolean;
};

function MultilineFieldControl({
  value,
  description,
  onChange,
}: {
  value: string;
  description: string;
  onChange: (value: string) => void;
}) {
  const handleTextareaChange = useDebouncedCallback((value: string) => {
    onChange(value);
  }, 300);

  return (
    <FieldWithDescription description={description}>
      <Textarea
        size="sm"
        layoutClassName="w-full"
        value={value}
        onChange={handleTextareaChange}
      />
    </FieldWithDescription>
  );
}

export const getCustomComponentPropTypeEditorData = (
  pt: CustomComponentPropType,
): ComponentPropTypeEditorData => {
  return customComponentPropTypeToEditorData[pt]!;
};

const customComponentPropTypeToEditorData: Record<
  CustomComponentPropType,
  ComponentPropTypeEditorData | null
> = {
  component: {
    render: () => null,
    shouldRender: () => false,
    defaultValue: null,
  },
  timeInterval: {
    render: ({
      value,
      description,
      onChange,
      isEnabled,
      disabledDescription,
    }) => {
      const { minutes, hours, seconds, days } = value as {
        minutes: number;
        hours: number;
        seconds: number;
        days: number;
      };
      return (
        <FieldWithDescription
          description={
            isEnabled ? description : disabledDescription ?? description
          }
        >
          <LabeledControl label="Seconds" size="sm">
            <Stepper
              value={seconds}
              field="seconds"
              placeholder="0"
              onChange={(newSeconds) => {
                onChange({
                  ...value,
                  seconds: newSeconds,
                });
              }}
            />
          </LabeledControl>
          <LabeledControl label="Minutes" size="sm">
            <Stepper
              value={minutes}
              field="minutes"
              placeholder="0"
              onChange={(newMinutes) => {
                onChange({
                  ...value,
                  minutes: newMinutes,
                });
              }}
            />
          </LabeledControl>
          <LabeledControl label="Hours" size="sm">
            <Stepper
              value={hours}
              field="hours"
              placeholder="0"
              onChange={(newHours) => {
                onChange({
                  ...value,
                  hours: newHours,
                });
              }}
            />
          </LabeledControl>
          <LabeledControl label="Days" size="sm">
            <Stepper
              value={days}
              field="days"
              placeholder="0"
              onChange={(newDays) => {
                onChange({
                  ...value,
                  days: newDays,
                });
              }}
            />
          </LabeledControl>
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  boolean: {
    render: ({
      value,
      description,
      onChange,
      isEnabled,
      disabledDescription,
      definition,
    }) => {
      let displayedValue = Boolean(value);
      if (!isEnabled && !definition.preserveValueWhenDisabled) {
        displayedValue = Boolean(definition.defaultValue);
      }

      return (
        <SwitchWithDescription
          disabled={!isEnabled}
          isOn={displayedValue}
          size="sm"
          onChange={onChange}
          label={isEnabled ? description : disabledDescription ?? description}
        />
      );
    },
    shouldRender: () => true,
    defaultValue: false,
  },
  product_variant: {
    render: ({ value, description, options, onChange }) => {
      const comboboxOptions = options.map((opt: Option) => ({
        ...opt,
        value: String(opt.value),
      }));

      const selectedOption = comboboxOptions.find(
        (v: ComboboxOption) => v.value === String(value),
      );
      const selectedVariantLabel = selectedOption?.label as string | undefined;
      const isInvalid = value != null && !selectedOption?.isSelectable;

      return (
        <FieldWithDescription description={description}>
          <Combobox
            title="Select a variant"
            side="left"
            sideOffset={16}
            options={comboboxOptions}
            value={String(value)}
            onChange={(newSelectedVariantIdString) => {
              onChange(
                newSelectedVariantIdString
                  ? Number(newSelectedVariantIdString)
                  : null,
              );
            }}
            trigger={
              <Combobox.SelectionButton
                title={selectedVariantLabel ?? "Select a Variant"}
                titleAlignment="start"
                placeholder="Select a Variant"
                size="sm"
                validityState={isInvalid ? "invalid" : undefined}
                endEnhancer={
                  value != null ? (
                    <FormFieldXButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(null);
                      }}
                    />
                  ) : undefined
                }
              />
            }
          />
        </FieldWithDescription>
      );
    },
    shouldRender: (component) => {
      return (
        Boolean(component.props._autoSelectVariant ?? true) &&
        Boolean((component.props._product as ProductRef)?.productId)
      );
    },
    defaultValue: null,
  },
  productVariants: {
    render: ({ value: selectedValues, description, context, onChange }) => {
      const variants: ReploShopifyVariant[] =
        context?.attributes?._variants ?? [];
      const baseOptions: ComboboxOption[] = variants.map(
        (variant: ReploShopifyVariant) => {
          return {
            label: variant.title,
            value: String(variant.id),
            isSelectable: true,
            size: "sm",
            toolTip: variant.title.length > 28 ? variant.title : null,
          };
        },
      );

      const SELECT_ALL_VALUE = "_all_";
      const allSelectableValues = baseOptions
        .filter((opt) => opt.isSelectable)
        .map((opt: ComboboxOption) => opt.value);

      const currentSelectedValues = (
        Array.isArray(selectedValues) ? selectedValues : []
      ).map(String);

      const allSelected =
        selectedValues === null ||
        (Array.isArray(selectedValues) &&
          allSelectableValues.every((val) =>
            currentSelectedValues.includes(val),
          ));

      const comboboxOptions: ComboboxOption[] = [
        {
          label: "Select All",
          value: SELECT_ALL_VALUE,
          isSelectable: true,
        },
        ...baseOptions,
      ] as ComboboxOption[];

      const comboboxValues = allSelected
        ? [SELECT_ALL_VALUE, ...allSelectableValues]
        : currentSelectedValues;

      let triggerTitle: string;
      if (allSelected) {
        triggerTitle = "All Variants";
      } else if (currentSelectedValues.length === 0) {
        triggerTitle = "Select Variant";
      } else {
        triggerTitle = `${currentSelectedValues.length} Variant${
          currentSelectedValues.length !== 1 ? "s" : ""
        }`;
      }

      if (variants.length > 0) {
        return (
          <FieldWithDescription description={description}>
            <Combobox
              title="Select Variants"
              side="left"
              sideOffset={16}
              options={comboboxOptions}
              isMultiselect
              value={comboboxValues}
              onChange={(newValues: string[]) => {
                const allSelectedNow = newValues.includes(SELECT_ALL_VALUE);

                if (allSelectedNow && !allSelected) {
                  onChange(null);
                } else if (!allSelectedNow && allSelected) {
                  onChange([]);
                } else {
                  const actualSelectedValues = newValues.filter(
                    (v) => v !== SELECT_ALL_VALUE,
                  );
                  onChange(actualSelectedValues.map(Number));
                }
              }}
              trigger={
                <Combobox.SelectionButton
                  title={triggerTitle}
                  titleAlignment="start"
                  placeholder="Select Variant"
                  startEnhancer={
                    <Badge
                      type="icon"
                      icon={
                        <BsFillBagPlusFill
                          size={10}
                          className="text-slate-50"
                        />
                      }
                      UNSAFE_className="bg-accent"
                    />
                  }
                  size="sm"
                  layoutClassName="w-full"
                />
              }
            />
          </FieldWithDescription>
        );
      }
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  productSellingPlan: {
    shouldRender: (component) => {
      return isNotNullish(component.props._product);
    },
    render: ({
      value,
      description,
      onChange,
      context,
      component,
      products,
      templateProduct,
    }) => {
      const product = getProduct(component.props._product ?? null, context, {
        productMetafieldValues: {},
        variantMetafieldValues: {},
        products,
        currencyCode: DEFAULT_ACTIVE_CURRENCY,
        language: DEFAULT_ACTIVE_LANGUAGE,
        moneyFormat: DEFAULT_MONEY_FORMAT,
        templateProduct,
        isEditor: true,
        isShopifyProductsLoading: false,
      });

      if (!product) {
        return null;
      }

      const allowAllPlans =
        !component.props._defaultSelectedVariantId ||
        !(
          isBoolean(component.props._autoSelectVariant) &&
          component.props._autoSelectVariant
        );

      return (
        <FieldWithDescription description={description}>
          <SellingPlanSelector
            product={product}
            allowAllPlans={allowAllPlans}
            selectedSellingPlanId={
              value as SelectedSellingPlanIdOrOneTimePurchase | null
            }
            onChange={onChange}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: null,
  },
  date: {
    render: ({ value, description, onChange }) => {
      const timeFormat =
        value != null ? toDatetimeLocal(new Date(value)) : null;
      return (
        <div className="flex flex-col gap-2">
          <span className="w-4/5 text-xs text-muted">{description}</span>
          <input
            value={timeFormat ?? undefined}
            type="datetime-local"
            className="text-xs"
            onChange={(e) => {
              try {
                onChange(new Date(e.target.value).toISOString());
              } catch (error) {
                if (error instanceof ReferenceError) {
                  throw new InvalidDateValueError(e.target.value);
                }
                throw error;
              }
            }}
          />
        </div>
      );
    },
    shouldRender: () => true,
    defaultValue: false,
  },
  product: {
    render: ({ value, description, onChange }) => {
      return (
        <ProductsCustomPropModifier
          onChange={(value) => onChange(value)}
          products={value}
          allowDynamicData
          description={description}
          isMultiProducts={false}
        />
      );
    },
    defaultValue: false,
    shouldRender: () => {
      return true;
    },
    hasCustomWrapper: true,
  },
  products: {
    render: ({ value, onChange }) => {
      return (
        <ProductsCustomPropModifier
          isMultiProducts={true}
          onChange={(productRef) => onChange(productRef)}
          products={value}
          allowDynamicData={false}
        />
      );
    },
    defaultValue: false,
    shouldRender: () => true,
    hasCustomWrapper: true,
  },
  string: {
    render: ({ value, description, onChange, disableDynamicData }) => {
      return (
        <FieldWithDescription description={description}>
          <InputWithDynamicData
            onChange={onChange}
            value={value}
            disableDynamicData={disableDynamicData}
            dynamicDataTargetType={DynamicDataTargetType.TEXT}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: "",
    shouldRender: () => true,
  },
  url: {
    render: ({ value, description, onChange, disableDynamicData }) => {
      return (
        <FieldWithDescription description={description}>
          <InputWithDynamicData
            onChange={onChange}
            value={value}
            disableDynamicData={disableDynamicData}
            dynamicDataTargetType={DynamicDataTargetType.URL}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: "",
    shouldRender: () => true,
  },
  multiline: {
    render: ({ value, description, onChange }) => {
      return (
        <MultilineFieldControl
          value={value}
          description={description}
          onChange={onChange}
        />
      );
    },
    defaultValue: "",
    shouldRender: () => true,
  },
  float: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <Input
            value={value}
            onChange={(e) => {
              const float = Number.parseFloat(e.target.value);
              if (!Number.isNaN(float)) {
                onChange(e.target.value);
              }
            }}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  integer: {
    render: ({
      value,
      description,
      onChange,
      isEnabled,
      disabledDescription,
      definition,
    }) => {
      return (
        <FieldWithDescription
          description={
            isEnabled ? description : disabledDescription ?? description
          }
        >
          <IntegerSelector
            min={definition.min}
            value={Number.parseInt(String(value))}
            onChange={(newQuantity) => {
              onChange(newQuantity);
            }}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  color: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <SolidColorSelector
            value={value}
            onChange={onChange}
            popoverSideOffset={14}
            showSavedStyles
            onSelectSavedStyle={onChange}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  ratingBalancer: {
    render: ({ value, description, onChange }) => {
      const defaultValue = 0;
      const onSubmit = () => {
        if (!value) {
          onChange(defaultValue);
          return;
        }
        const balancedValue = Math.ceil(value * 2) / 2;
        onChange(balancedValue);
      };
      return (
        <FieldWithDescription description={description}>
          <Input
            value={value}
            onBlur={onSubmit}
            onEnter={onSubmit}
            onChange={(e) => {
              const float = Number.parseFloat(e.target.value || "0");
              if (!Number.isNaN(float)) {
                onChange(e.target.value);
              }
            }}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  pixels: {
    render: ({ value, description, onChange, defaultValue }) => {
      return (
        <FieldWithDescription description={description}>
          <LengthInputSelector
            value={value}
            minDragValues={{ all: 0 }}
            placeholder={defaultValue ?? "16px"}
            field="customProp"
            onChange={(value) => onChange(value)}
            allowsNegativeValue={false}
            resetValue={defaultValue ?? "16px"}
            anchorValue={defaultValue ?? "16px"}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  pixelsIncludingNegativeValues: {
    render: ({ value, description, onChange, defaultValue }) => (
      <FieldWithDescription description={description}>
        <LengthInputSelector
          value={value}
          placeholder={defaultValue}
          field="customProp"
          onChange={(value) => onChange(value)}
          resetValue={defaultValue}
          anchorValue={defaultValue}
        />
      </FieldWithDescription>
    ),
    defaultValue: 0,
    shouldRender: () => true,
  },
  hashmark: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <div className="flex flex-row items-center">
            <div className="flex-1">
              <HashmarkInput
                value={String(value ?? "")}
                onValueChange={onChange}
              />
            </div>
          </div>
        </FieldWithDescription>
      );
    },
    defaultValue: "",
    shouldRender: () => true,
  },
  location: null,
  collection: null,
  image: {
    render: ({ value, customPropId, description, definition }) => {
      return (
        <div className="flex flex-col justify-between">
          <span className="text-xs text-muted">{description}</span>
          <div className="mt-2">
            <ImageCustomPropModifier
              value={value}
              customPropId={customPropId}
              definition={definition}
            />
          </div>
        </div>
      );
    },
    shouldRender: () => true,
    defaultValue: false,
  },
  inlineItems: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <ItemsSelector
            allowsDynamicDataWhenAvailable={false}
            allowedItemTypes={[ItemsConfigType.inline]}
            value={value}
            onChange={(value) => {
              onChange(value);
            }}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  htmlAttributes: {
    render: ({ value, description, onChange, onDelete }) => {
      return (
        <FieldWithDescription description={description}>
          <ItemsSelector
            allowsDynamicDataWhenAvailable
            allowedItemTypes={[ItemsConfigType.htmlAttributes]}
            value={value}
            onChange={onChange}
            onDelete={onDelete}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  dynamicItems: {
    render: ({ value, description, onChange, onDelete }) => {
      return (
        <FieldWithDescription description={description}>
          <CombinedItemsSelector
            value={value}
            onChange={onChange}
            onDelete={() => onDelete()}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  anyDynamicImage: {
    render: ({
      value,
      description,
      onChange,
      onDelete,
      component,
    }: Omit<CustomComponentPropRenderProps, "value"> & {
      value: string | null;
    }) => {
      const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");
      if (isNewDynamicData) {
        return (
          <DynamicDataSelector
            side="left"
            targetType={DynamicDataTargetType.IMAGE}
            initialPath={getPathFromVariable(value ?? "")}
            onChange={onChange}
            triggerClassName="w-full"
            trigger={
              <DynamicDataValueIndicator
                type="text"
                labelClassname="text-left"
                templateValue={value}
                componentId={component.id}
                onRemove={onDelete}
              />
            }
          ></DynamicDataSelector>
        );
      }
      return (
        <FieldWithDescription description={description}>
          <AnyDynamicDataValueSelector
            dynamicDataTemplate={value}
            onChange={onChange as (value: string) => void}
            onDelete={onDelete}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  seconds: {
    render: ({ value, description, onChange, attributeName }) => {
      const onChangeValue = (
        v: any,
        type: "increment" | "decrement" | "input",
      ) => {
        let val = Number.parseFloat(v);
        if (type === "increment") {
          val = val + 1;
        } else if (type === "decrement") {
          val = Math.max(val - 1, 0);
        }

        if (Number.isNaN(val)) {
          val = 0;
        }

        onChange(`${val}s`);
      };
      return (
        <FieldWithDescription description={description}>
          <div className="flex flex-row items-end justify-between">
            <div className="mr-1 flex-1">
              <LengthInputSelector
                placeholder="0s"
                key={`props.${attributeName}`}
                field={`props.${attributeName}`}
                value={value}
                onChange={(v: any) => onChange(v)}
                draggingType={DraggingTypes.Vertical}
                metrics={["s"]}
                resetValue="0s"
                anchorValue="0s"
                minDragValues={{ all: 0 }}
                minValues={{ all: 0 }}
                allowsNegativeValue={false}
              />
            </div>
            <ButtonGroupComponent
              options={[
                {
                  label: <FiMinus size={12} />,
                  onClick: () => onChangeValue(value, "decrement"),
                  isDisabled: value > 0,
                },
                {
                  label: <FiPlus size={12} />,
                  onClick: () => onChangeValue(value, "increment"),
                },
              ]}
            />
          </div>
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  productOptions: {
    render: ({ value, onChange }) => {
      return <OptionsCustomPropModifier value={value} onChange={onChange} />;
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  liquidCodeEditor: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <CodeEditorCustomPropModifier
            language="liquid"
            value={value}
            onChange={(value) => onChange(value)}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  htmlCodeEditor: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <CodeEditorCustomPropModifier
            language="html"
            value={value}
            onChange={(value) => onChange(value)}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  cssCodeEditor: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <CodeEditorCustomPropModifier
            language="css"
            value={value}
            onChange={(value) => onChange(value)}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },

  shopifyThemeSections: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <SectionsSelectable defaultValue={value} onChange={onChange} />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },

  selectable: {
    render: ({ value, description, onChange, options = [], placeholder }) => {
      return (
        <FieldWithDescription description={description}>
          <Selectable
            options={options}
            onSelect={(value) => onChange(value)}
            value={value}
            placeholder={placeholder ?? "Select an option"}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },

  swatches: {
    render: () => {
      return <SwatchesCustomPropModifier key="swatches" />;
    },
    shouldRender: () => true,
    defaultValue: null,
    hasCustomWrapper: true,
  },
  percentage: {
    render: ({ value, description, onChange, attributeName }) => {
      return (
        <FieldWithDescription description={description}>
          <LengthInputSelector
            value={value}
            metrics={["%"]}
            minValues={{ "%": 0 }}
            maxValues={{ "%": 100 }}
            placeholder="50%"
            field={`props.${attributeName}`}
            onChange={(value) => onChange(value)}
            allowsNegativeValue={false}
            resetValue="50%"
            anchorValue="50%"
            menuOptions={[
              {
                label: "0%",
                value: "0%",
              },
              {
                label: "25%",
                value: "25%",
              },
              {
                label: "50%",
                value: "50%",
              },
              {
                label: "75%",
                value: "75%",
              },
              {
                label: "100%",
                value: "100%",
              },
            ]}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: "50%",
    shouldRender: () => true,
  },
  productSellingPlans: {
    render: ({ value, description, context, onChange }) => {
      const sellingPlans = context?.attributes?._sellingPlans;

      const sortedSellingPlans = orderBy(
        sellingPlans ?? [],
        [(plan) => Boolean(plan.appId)],
        ["desc"],
      );

      const getAppNameFromAppId = (
        appId: string | null | undefined,
      ): string => {
        if (!appId) {
          return "Other";
        }

        if (appId in SUB_APP_APPID_TO_NAME) {
          return SUB_APP_APPID_TO_NAME[
            appId as keyof typeof SUB_APP_APPID_TO_NAME
          ];
        }

        return startCase(appId);
      };

      const baseOptions: ComboboxOption[] =
        sortedSellingPlans?.map(
          (sellingPlan: ShopifySellingPlan): ComboboxOption => {
            const appName = getAppNameFromAppId(sellingPlan.appId);
            return {
              label: sellingPlan.name,
              value: String(sellingPlan.id),
              isSelectable: true,
              groupTitle: appName,
            };
          },
        ) ?? [];

      const allSelectableValues = baseOptions
        .filter((opt: ComboboxOption) => opt.isSelectable)
        .map((opt: ComboboxOption) => opt.value);

      const currentSelectedValuesAsString = (
        Array.isArray(value) ? value : []
      ).map(String);

      const allSelected =
        value === null ||
        (Array.isArray(value) &&
          allSelectableValues.length > 0 &&
          allSelectableValues.every((val) =>
            currentSelectedValuesAsString.includes(val as string),
          ));

      const comboboxOptions: ComboboxOption[] = [
        {
          label: "Select All",
          value: "_all_",
          isSelectable: true,
        },
        ...baseOptions,
      ] as ComboboxOption[];

      const comboboxValues = allSelected
        ? ["_all_", ...allSelectableValues]
        : currentSelectedValuesAsString;

      let triggerTitle: string;
      if (allSelected) {
        triggerTitle = "All Selling Plans";
      } else if (currentSelectedValuesAsString.length === 0) {
        triggerTitle = "Select Selling Plan";
      } else {
        triggerTitle = `${currentSelectedValuesAsString.length} Selling Plan${
          currentSelectedValuesAsString.length !== 1 ? "s" : ""
        }`;
      }

      if (baseOptions.length > 0) {
        return (
          <FieldWithDescription description={description}>
            <Combobox
              title="Select Selling Plans"
              side="left"
              sideOffset={16}
              options={comboboxOptions}
              isMultiselect
              value={comboboxValues}
              onChange={(newValues: string[]) => {
                const allSelectedNow = newValues.includes("_all_");

                if (allSelectedNow && !allSelected) {
                  onChange(null);
                } else if (!allSelectedNow && allSelected) {
                  onChange([]);
                } else {
                  const actualSelectedValues = newValues.filter(
                    (v) => v !== "_all_",
                  );
                  // Note (Jackson, 2025-04-01): ree
                  onChange(actualSelectedValues.map(Number));
                }
              }}
              trigger={
                <Combobox.SelectionButton
                  title={triggerTitle}
                  titleAlignment="start"
                  placeholder="Select Selling Plan"
                  startEnhancer={
                    <Badge
                      type="icon"
                      icon={
                        <BsFillBagPlusFill
                          size={10}
                          className="text-slate-50"
                        />
                      }
                      UNSAFE_className="bg-accent"
                    />
                  }
                  size="sm"
                  layoutClassName="w-full"
                />
              }
            />
          </FieldWithDescription>
        );
      }
    },
    shouldRender: () => true,
    defaultValue: null,
  },
};

function HashmarkInput({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange(value: string): void;
}) {
  const inputProps = useOverridableInput({ value, onValueChange });
  return (
    <Input
      startEnhancer={<span className="text-muted">#</span>}
      {...inputProps}
    />
  );
}

const InputWithDynamicData = ({
  onChange,
  value,
  disableDynamicData = false,
  dynamicDataTargetType,
}: {
  onChange: (value: string) => void;
  value: string;
  disableDynamicData?: boolean;
  dynamicDataTargetType: DynamicDataTargetType;
}) => {
  const modal = useModal();
  const onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "callback",
          onChange: (value: any) => {
            onChange(value);
          },
        },
        initialPath: value ? getPathFromVariable(value) : undefined,
      },
    });
  };

  const inputProps = useOverridableInput({ value, onValueChange: onChange });
  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");
  return typeof value === "string" &&
    isDynamicDataValue(value) &&
    !disableDynamicData ? (
    isNewDynamicData ? (
      <DynamicDataSelector
        targetType={dynamicDataTargetType}
        initialPath={value.split(".")}
        onChange={(value) => onChange(value as string)}
        trigger={
          <DynamicDataValueIndicator
            type="text"
            templateValue={value}
            onRemove={() => {
              onChange("");
            }}
          />
        }
      />
    ) : (
      <DynamicDataValueIndicator
        type="text"
        templateValue="Dynamic Value"
        onClick={() => onClickDynamicData?.()}
        onRemove={() => {
          onChange("");
        }}
      />
    )
  ) : (
    <div className="flex flex-row gap-2 items-center">
      <Input
        {...inputProps}
        allowsDynamicData={!disableDynamicData}
        onClickDynamicData={onClickDynamicData}
      />
      {isNewDynamicData && !disableDynamicData && (
        <DynamicDataSelector
          targetType={dynamicDataTargetType}
          onChange={(value) => onChange(value as string)}
          trigger={<DynamicDataButton />}
        />
      )}
    </div>
  );
};

function SectionsSelectable({
  onChange,
  defaultValue,
}: {
  onChange: (value: string | null) => void;
  defaultValue: string | null;
}) {
  const themeId = useEditorSelector(selectThemeId);
  const { project } = useCurrentProjectContext();
  const shopifyUrl = useEditorSelector(selectStoreShopifyUrl);
  const { isSuccess, isLoading, data } =
    trpc.asset.getShopifyThemeSections.useQuery(
      project?.id && shopifyUrl
        ? {
            storeId: project.id,
            themeId,
          }
        : skipToken,
    );

  if (project && !shopifyUrl) {
    return (
      <ConnectShopifyCallout
        cookieValue={{
          type: "sections",
          projectId: project.id,
          workspaceId: project.ownerWorkspaceId,
        }}
      />
    );
  }

  const comboboxOptions = isSuccess
    ? data.sections.map((section) => {
        const sectionName = getSectionName(section.key);
        return {
          label: sectionName,
          value: sectionName,
          isSelectable: true,
        };
      })
    : [];

  const selectedOption = comboboxOptions.find(
    (opt: ComboboxOption) => opt.value === defaultValue,
  );

  return (
    <Combobox
      title="Select Shopify Sections"
      areOptionsSearchable
      side="left"
      sideOffset={16}
      options={comboboxOptions}
      value={defaultValue ?? undefined}
      onChange={(newValue) => onChange(newValue ?? null)}
      trigger={
        <Combobox.SelectionButton
          title={selectedOption?.label ?? "Select Section"}
          titleAlignment="start"
          placeholder={isLoading ? "Loading Sections…" : "Select Section"}
          size="sm"
          layoutClassName="w-full"
          endEnhancer={
            defaultValue != null ? (
              <FormFieldXButton
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(null);
                }}
              />
            ) : undefined
          }
        />
      }
    />
  );
}

function getSectionName(key: string) {
  return key.replace(/^sections\//, "").replace(/\.liquid$/, "");
}
