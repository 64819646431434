import type { ReploElementType } from "schemas/generated/element";

type ElementTypeEditorData = {
  displayName: string;

  /**
   * Render type of this page for rendering this element. This is used by canvas to
   * determine whether we need to re-request the page from mirror when editing this element.
   */
  pageRenderType: "fullPage" | "articleContent";
  singularDisplayName: string;
  templateSelectorDisplayName: string;
  path?: string;
};

export const allElementTypes: ReploElementType[] = [
  "page",
  "shopifyProductTemplate",
  "shopifyArticle",
  "shopifySection",
];

export const elementTypeToEditorData: Record<
  ReploElementType,
  ElementTypeEditorData
> = {
  page: {
    displayName: "Shopify Pages",
    pageRenderType: "fullPage",
    singularDisplayName: "Page",
    templateSelectorDisplayName: "Landing Pages",
    path: "/pages/",
  },
  shopifyProductTemplate: {
    displayName: "Product Templates",
    pageRenderType: "fullPage",
    singularDisplayName: "Product Template",
    templateSelectorDisplayName: "Product Templates",
  },
  shopifyArticle: {
    displayName: "Blog Post Content",
    pageRenderType: "articleContent",
    singularDisplayName: "Blog Post",
    templateSelectorDisplayName: "Blog Post Template",
    path: "/blogs/",
  },
  shopifySection: {
    displayName: "Shopify Sections",
    pageRenderType: "fullPage",
    singularDisplayName: "Section",
    templateSelectorDisplayName: "Shopify Sections",
  },
};
