import type { Component } from "schemas/component";
import type { ReploElementType } from "schemas/generated/element";

import productTemplate from "@components/editor/templates/product/product";

import { refreshComponentIds } from "replo-runtime/shared/refreshComponentIds";

export const getNewPagePlaceholderComponent = (
  elementType: ReploElementType,
) => {
  const template: Component = {
    id: "$uid",
    type: "container",
    props: {
      style: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    children:
      elementType === "shopifyProductTemplate" && productTemplate.template
        ? [productTemplate.template]
        : [],
  };
  return refreshComponentIds(template).component;
};
