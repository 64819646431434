import React from "react";

import { Item } from "@radix-ui/react-toggle-group";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { useOverflowDetection } from "@replo/design-system/utils/useOverflowDetection";

type ToggleProps = {
  size: "base" | "sm";
  tooltipContent?: React.ReactNode;
  value: string;
  className?: string;
  isActive: boolean;
  disabled?: boolean;
  label: React.ReactNode;
} & Omit<React.HTMLAttributes<HTMLButtonElement>, "className">;

const Toggle: React.FC<ToggleProps> = ({
  size,
  value,
  isActive,
  tooltipContent,
  className,
  label,
  disabled,
  ...rest
}) => {
  const [itemRef, isOverflowing] = useOverflowDetection();

  const Wrapper = tooltipContent || isOverflowing ? Tooltip : React.Fragment;

  const wrapperProps = (
    tooltipContent || isOverflowing
      ? {
          content:
            tooltipContent && !isOverflowing ? (
              tooltipContent
            ) : (
              <div className="flex flex-col">
                <div className="typ-label-small">{label}</div>
                {tooltipContent && (
                  <div className="typ-body-small">{tooltipContent}</div>
                )}
              </div>
            ),
          triggerAsChild: true,
        }
      : {}
  ) as any;

  const ariaLabel = (() => {
    if (typeof tooltipContent === "string") {
      return tooltipContent;
    } else if (typeof label === "string") {
      return label;
    } else {
      return value ?? undefined;
    }
  })();

  return (
    <Wrapper {...wrapperProps}>
      <Item
        {...rest}
        value={value}
        className={twMerge(
          "flex items-center justify-center rounded-[4px] text-muted cursor-pointer w-full h-full px-1.5 py-1",
          size === "sm" && "typ-body-small",
          size === "base" && "typ-body-base",
          isActive && "text-primary rounded-[4px]",
          disabled && "opacity-50 cursor-not-allowed",
          className,
        )}
        aria-label={ariaLabel}
      >
        <span className="cursor-pointer truncate" ref={itemRef}>
          {label}
        </span>
      </Item>
    </Wrapper>
  );
};

export { Toggle };
