import type { ButtonProps } from "@replo/design-system/components/button/Button";
import type { ButtonVariant } from "./button-shared";

import * as React from "react";

import Button from "@replo/design-system/components/button/Button";
import twMerge from "@replo/design-system/utils/twMerge";

// NOTE (Chance 2023-12-04): This union type ensures that all icon buttons have
// a prop that can be used for accessible labels. A button shouldn't have both
// `aria-label` and `aria-labelledby`, but it can have one or the other
// alongside a `tooltipText` prop. If neither `aria-label` nor `aria-labelledby`
// is provided, then `tooltipText` is required as it will be used as the
// accessible label.
type AccessibleLabelProps =
  | {
      tooltipText?: React.ReactNode;
      "aria-label": string;
      "aria-labelledby"?: never;
    }
  | {
      tooltipText?: React.ReactNode;
      "aria-label"?: never;
      "aria-labelledby": string;
    }
  | {
      tooltipText: React.ReactNode;
      "aria-label"?: string;
      "aria-labelledby"?: never;
    }
  | {
      tooltipText: React.ReactNode;
      "aria-label"?: never;
      "aria-labelledby"?: string;
    }
  // NOTE (Chance 2023-12-04): For fake buttons, neither aria-label nor
  // aria-labelledby will make a difference because the underlying button
  // doesn't have a role. So we'd expect the parent component to ensure there is
  // an accessible label.
  | {
      tooltipText?: React.ReactNode;
      "aria-label"?: never;
      "aria-labelledby"?: never;
    };

type InheritedButtonProps = Omit<
  ButtonProps,
  "size" | "aria-label" | "aria-labelledby" | "tooltipText"
>;

type IconButtonProps = {
  variant: ButtonVariant;
  icon: React.ReactNode;
  size?: "sm" | "base" | "lg";
  isActive?: boolean;
} & InheritedButtonProps &
  AccessibleLabelProps;

const IconButton = React.forwardRef<any, IconButtonProps>(
  (
    {
      icon,
      UNSAFE_className,
      layoutClassName,
      size = "base",
      hasMinDimensions = true,
      isActive = false,
      ...props
    },
    ref,
  ) => {
    const mergedClassName = twMerge(
      size === "sm" && "h-6 w-6 p-[4px]",
      // Craig (2025-03-12): Have sm: on these attributes to overide the sm: in the button component.
      size === "base" && "p-[7px] max-sm:p-[7px] w-8 max-sm:w-8 h-8 max-sm:h-8",
      size === "lg" &&
        "p-[12px] h-10 w-10 max-sm:p-[12px] max-sm:h-8 max-sm:w-8",
      hasMinDimensions && "min-w-[1.5rem] min-h-[1.5rem]",
      layoutClassName,
      UNSAFE_className,
    );

    // Craig (2025-03-12): Leaving large as a sizing on IconButton because some icons still use large sizing.
    // If size is large, then pass base to the button.
    // TODO: We should probably separate out IconButton from Button as the interaction is kind of weird.
    const buttonSize = size === "lg" ? "base" : size;

    return (
      <Button
        ref={ref}
        {...props}
        UNSAFE_className={mergedClassName}
        size={buttonSize}
        aria-label={
          props["aria-label"] ||
          props["aria-labelledby"] ||
          (typeof props.tooltipText === "string"
            ? props.tooltipText
            : undefined)
        }
        hasMinDimensions={hasMinDimensions}
        hasHoverHighlight={true}
        isActive={isActive}
      >
        {icon}
      </Button>
    );
  },
);

IconButton.displayName = "IconButton";

export default IconButton;
