import type { EditorPropsRecord } from "replo-runtime/shared/utils/editorProps";
import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { AlchemyActionType } from "../../../shared/enums";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/props";

const styleElements = {
  root: {
    overrideStyles: ({ component }) => {
      if (component.props._dragToScroll === true) {
        return {
          userSelect: "none",
          WebkitTouchCallout: "none",
          touchAction: "pan-y",
          WebkitTapHighlightColor: "transparent",
        };
      }
    },
  },
} as const satisfies StyleElements;

const slidesStyleElements = {
  root: {
    defaultStyles: {
      // TODO (Chance 2024-01-12): This should probably change for vertical
      // carousels, but this is what we did in v3 so I am keeping for back-compat
      overflowY: "visible",
      overflowX: "clip",
      // Note (Noah, 2024-05-11, USE-939): In certain contexts, Safari calculates
      // 100% as 100% of the containing flex container (which may not be the parent
      // element). This is here so that the height: 100% which we have on slides
      // track is relative to this component, not this component's parent Container
      display: "flex",
    },
  },
  slide: {
    defaultStyles: {
      // TODO (Chance 2024-01-31, REPL-10139): This is a temporary fix to
      // prevent cutoff outlines appearing while navigating via arrow keys. The
      // better solution is to move manage visual focus on the top-level slides
      // element and move the indicator to that element. This will be resolved
      // during the a11y testing phase in v4.
      outline: "none",
    },
  },
} as const satisfies StyleElements;

const controlStyleElements = {
  root: {},
} as const satisfies StyleElements;

const indicatorsStyleElements = {
  root: {},
} as const satisfies StyleElements;

function getEditorProps(): EditorPropsRecord {
  return {
    activeSlide: {
      type: "index",
      title: "Active slide",
      description: "If set, the editor will show the slide with this index",
      defaultValue: 0,
    },
  };
}

function getConfigurableProps(): CustomPropsRecord {
  return {
    _items: {
      type: "dynamicItems",
      name: "Dynamic items",
      description:
        "If set, the carousel will dynamically update its content based on the given items.",
      defaultValue: null,
    },
    _autoNextInterval: {
      type: "seconds",
      name: "Auto scroll to next element",
      defaultValue: "0s",
    },
    _pauseOnHover: {
      type: "boolean",
      name: "Pause Auto Scroll on Hover",
      description: "Pause on Hover",
      defaultValue: true,
      isEnabled: ({ component }) => {
        return (component.props._autoNextInterval ?? "0s") !== "0s";
      },
    },
    _animationStyle: {
      type: "selectable",
      name: "Animate Transitions",
      defaultValue: "slide",
      selectableValues: {
        type: "options",
        options: [
          { label: "No Animation", value: "off" },
          { label: "Slide", value: "slide" },
          { label: "Fade", value: "fade" },
        ],
      },
    },
    _infinite: {
      type: "boolean",
      name: "Infinite loop",
      description:
        "If enabled, the carousel content continues at the beginning or end of the slides.",
      defaultValue: false,
    },
    _itemsPerView: {
      type: "integer",
      name: "Items per slide",
      description: "How many items can be viewed per slide view.",
      defaultValue: 1,
      isEnabled: ({ component }) => {
        return !(component.props._autoWidth ?? false);
      },
      disabledDescription:
        "This property does not apply when Items Auto-width is set.",
    },
    _itemsPerMove: {
      type: "integer",
      name: "Items per move",
      description: "How many items get scrolled when arrows are used.",
      defaultValue: 1,
      isEnabled: ({ component }) => {
        return !(component.props._autoWidth ?? false);
      },
      disabledDescription:
        "This property does not apply when Items Auto-width is set.",
    },
    _autoWidth: {
      type: "boolean",
      name: "Items auto-width",
      description:
        "If enabled, the width of slides are determined by their original width",
      defaultValue: false,
    },
    _mouseWheel: {
      type: "boolean",
      name: "Change slides by scrolling",
      description:
        "Enables scrolling through the carousel using the mouse or trackpad.",
      defaultValue: false,
    },
    _slideClickTransition: {
      type: "boolean",
      name: "Change slides on click",
      description:
        "Enables transitioning to a slide when it is clicked with a mouse or touch device.",
      defaultValue: true,
    },
    _activeArea: {
      type: "selectable",
      name: "Active area",
      description:
        "What part of the carousel is the 'active' for Replo States (either the first slide or the center slide can be used).",
      defaultValue: "first",
      selectableValues: {
        type: "options",
        options: [
          { label: "First Slide", value: "first" },
          { label: "Center Slide", value: "center" },
        ],
      },
    },
    _selectedItem: {
      type: "anyDynamicImage",
      name: "Selected Variant Image",
      description:
        "If set, the carousel will automatically scroll to currently selected variant's featured image whenever it changes.",
      defaultValue: null,
    },
    _title: {
      type: "string",
      name: "Carousel name",
      description: `The name of the carousel used for accessibility, e.g. "Product Images Carousel". This name should be short, unique among all carousels on the page, and should contain the word "carousel".`,
      defaultValue: "",
    },
    _accessibilityScreenreaderInstructions: {
      type: "multiline",
      name: "Screenreader Instructions",
      description: `Instructions for interacting with the carousel for people using screenreaders. These should be clear and concise. E.g. "This is a product images carousel. Use the next and previous buttons to navigate through the slides."`,
      defaultValue: "",
    },
  };
}

function getCarouselV3SlidesConfigurableProps(): CustomPropsRecord {
  return {
    _useCustomConfig: {
      type: "boolean",
      name: "Use custom configuration",
      description:
        "If enabled, some settings can be configured separately from the main Carousel component.",
      defaultValue: false,
    },
    _animationStyle: {
      type: "selectable",
      name: "Transition style",
      defaultValue: undefined,
      selectableValues: {
        type: "options",
        options: [
          { label: "Slide", value: "slide" },
          { label: "Fade", value: "fade" },
          { label: "No Animation", value: "off" },
        ],
      },
      shouldDisplay: ({ component }) => {
        return component.props._useCustomConfig ?? false;
      },
    },
    _slideClickTransition: {
      type: "boolean",
      name: "Change slides on click",
      description:
        "Enables transitioning to a slide when it is clicked with a mouse or touch device.",
      defaultValue: false,
    },
    _itemsPerView: {
      type: "integer",
      name: "Items per slide",
      description: "How many items can be viewed per slide view.",
      defaultValue: undefined,
      isEnabled: ({ component }) => {
        return !(component.props._autoWidth ?? false);
      },
      shouldDisplay: ({ component }) => {
        return component.props._useCustomConfig ?? false;
      },
      disabledDescription:
        "This property does not apply when Items Auto-width is set.",
    },
    _autoWidth: {
      type: "boolean",
      name: "Items auto-width",
      description:
        "If enabled, the width of slides are determined by their original width",
      defaultValue: undefined,
      shouldDisplay: ({ component }) => {
        return component.props._useCustomConfig ?? false;
      },
    },
    _activeArea: {
      type: "selectable",
      name: "Active area",
      description:
        "What part of the carousel is the 'active' for Replo States (either the first slide or the center slide can be used).",
      defaultValue: undefined,
      selectableValues: {
        type: "options",
        options: [
          { label: "First Slide", value: "first" },
          { label: "Center Slide", value: "center" },
        ],
      },
      shouldDisplay: ({ component }) => {
        return component.props._useCustomConfig ?? false;
      },
    },
  };
}

const actionTypes = [
  "goToItem",
  "goToPrevItem",
  "goToNextItem",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    showAccessibilityMenu: true,
    customProps: convertToLegacyProps(getConfigurableProps()),
    editorProps: getEditorProps(),
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    dynamicItemsPropName: "_items",
    ancestorDisallowList: [
      {
        ancestorTypes: ["carouselV3"],
        message: "Carousels cannot be dropped into other carousels.",
      },
      {
        ancestorTypes: ["button"],
        message: "Carousels cannot be nested inside buttons.",
      },
      {
        ancestorTypes: ["marquee"],
        message: "Carousels cannot be nested inside tickers.",
      },
    ],
    styleElements,
    variantTriggers: [
      "state.group.isFirstItemActive",
      "state.group.isLastItemActive",
    ],
    actionTypes,
  },
  children: {
    carouselV3Slides: {
      renderData: {
        customProps: convertToLegacyProps(
          getCarouselV3SlidesConfigurableProps(),
        ),
        newInstancesUseNumbering: true,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => true,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        ancestorAllow: {
          ancestorTypes: ["carouselV3"],
          message:
            "Carousel Slide components may only be placed inside a Carousel.",
        },
        extractIsDynamicFromContext: (context) => {
          return Boolean(context?.group?.isDynamic);
        },
        canContainChildren: true,
        styleElements: slidesStyleElements,
        variantTriggers: ["state.group.isCurrentItemActive"],
      },
    },
    carouselV3Control: {
      renderData: {
        newInstancesUseNumbering: true,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => true,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        ancestorAllow: {
          ancestorTypes: ["carouselV3"],
          message:
            "Carousel Control components may only be placed inside a Carousel box.",
        },
        extractIsDynamicFromContext: (context) => {
          return Boolean(context?.group?.isDynamic);
        },
        canContainChildren: true,
        styleElements: controlStyleElements,
      },
    },
    carouselV3Indicators: {
      renderData: {
        newInstancesUseNumbering: true,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => true,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        ancestorAllow: {
          ancestorTypes: ["carouselV3"],
          message:
            "Carousel Indicator components may only be placed inside a Carousel box.",
        },
        extractIsDynamicFromContext: (context) => {
          return Boolean(context?.group?.isDynamic);
        },
        canContainChildren: true,
        styleElements: indicatorsStyleElements,
        variantTriggers: ["state.group.isCurrentItemActive"],
      },
    },
  },
} satisfies ComponentConfig;

export default config;
