import type { ElementMetafieldType } from "replo-runtime/shared/types";
import type { ReploGenericMetafield } from "schemas/generated/element";

import * as React from "react";

import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";

interface SEOVisibilityToggleProps {
  metafields: ReploGenericMetafield[];
  onMetafieldChange: (
    key: string,
    value: string | number,
    namespace: string,
    type: ElementMetafieldType,
  ) => void;
}

const SEOVisibilityToggle: React.FC<SEOVisibilityToggleProps> = ({
  metafields,
  onMetafieldChange,
}) => {
  const shouldShowInSEO = !Boolean(
    metafields?.find(
      (field) => field.key === "hidden" && field.namespace === "seo",
    )?.value,
  );

  const handleSEOVisibilityChange = (shouldShow: boolean) => {
    // When shouldShow is true, hidden should be 0
    // When shouldShow is false, hidden should be 1
    const hiddenValue = Number(!shouldShow);
    onMetafieldChange("hidden", hiddenValue, "seo", "number_integer");
  };

  return (
    <SwitchWithDescription
      label="Show Page in SEO"
      description="This page will show up in search engines like Google."
      isOn={shouldShowInSEO}
      size="sm"
      onChange={handleSEOVisibilityChange}
      variant="card"
    />
  );
};

export default SEOVisibilityToggle;
