import type { BrandDetails } from "schemas/generated/ai";
import type { UrlFormValues } from "schemas/url";

import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { analytics } from "@editor/infra/analytics";
import {
  selectLoadableProject,
  selectStoreShopifyUrl,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpcClient } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import InlineAlert from "@replo/design-system/components/alert/InlineAlert";
import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import { useForm } from "react-hook-form";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { urlFormSchema } from "schemas/url";

import ErrorMessage from "../account/Dashboard/ErrorMessage";
import Input from "../common/designSystem/Input";
import Separator from "../common/designSystem/Separator";
import useBrandContext from "./useBrandContext";

type BrandContextMenuState =
  | {
      view: "detailsList";
      error?: boolean;
    }
  | {
      view: "pullFromUrl";
      isLoading: boolean;
    };

const BrandContextEditor: React.FC = () => {
  const logEvent = useLogAnalytics();

  const {
    brandContext,
    setBrandContext,
    isLoading: isLoadingBrandDetails,
  } = useBrandContext();

  const [draftBrandContext, setDraftBrandContext] =
    React.useState<BrandDetails | null>(null);

  // NOTE (Cole, 2025-03-10): UseEffect is bad, I'd prefer a redux slice like shop styles but don't think it's worth the effort until next time we touch this.
  React.useEffect(() => {
    if (!isLoadingBrandDetails && brandContext) {
      setDraftBrandContext(brandContext);
    }
  }, [isLoadingBrandDetails, brandContext]);

  const hasUnsavedChanges = React.useMemo(() => {
    if (!draftBrandContext || !brandContext) {
      return false;
    }

    return (
      draftBrandContext.brandName !== brandContext.brandName ||
      draftBrandContext.brandVoice !== brandContext.brandVoice ||
      draftBrandContext.whatBusinessSells !== brandContext.whatBusinessSells ||
      draftBrandContext.whoIsCustomer !== brandContext.whoIsCustomer
    );
  }, [draftBrandContext, brandContext]);

  const [brandContextMenuState, setBrandContextMenuState] =
    React.useState<BrandContextMenuState>({
      view: "detailsList",
    });

  const storeShopifyUrl = useEditorSelector(selectStoreShopifyUrl);
  const { project } = useEditorSelector(selectLoadableProject);

  // Note (Evan, 2024-09-26): Hook form for the URL input (to get validation)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      url: storeShopifyUrl ?? "",
    },
    resolver: zodResolver(urlFormSchema),
  });

  const urlError = errors.url?.message;

  const onSubmitPullFromUrl = async ({ url }: UrlFormValues) => {
    setBrandContextMenuState({ view: "pullFromUrl", isLoading: true });

    analytics.logEvent("brandDetails.pullFromUrl", {
      url,
    });

    try {
      const result = await trpcClient.ai.generateBrandDetailsAndIndustry.query({
        url,
        projectId: project?.id,
      });

      setBrandContext(result.brandDetails);
      setDraftBrandContext(result.brandDetails);

      setBrandContextMenuState({
        view: "detailsList",
      });
    } catch {
      setBrandContextMenuState({
        view: "detailsList",
        error: true,
      });
    }
  };

  const handleBrandVoiceChange = (value: string) => {
    if (!draftBrandContext) {
      return;
    }

    setDraftBrandContext({
      ...draftBrandContext,
      brandVoice: value,
    });
  };

  const handleWhatBusinessSellsChange = (value: string) => {
    if (!draftBrandContext) {
      return;
    }

    setDraftBrandContext({
      ...draftBrandContext,
      whatBusinessSells: value,
    });
  };

  const handleWhoIsCustomerChange = (value: string) => {
    if (!draftBrandContext) {
      return;
    }

    setDraftBrandContext({
      ...draftBrandContext,
      whoIsCustomer: value,
    });
  };

  const handleSave = () => {
    if (draftBrandContext && hasUnsavedChanges) {
      setBrandContext(draftBrandContext);
      successToast("Brand context saved");
    }
    logEvent("shopDetails.context.save");
  };

  const handleCancel = () => {
    if (brandContext) {
      setDraftBrandContext(brandContext);
    } else {
      setDraftBrandContext({
        brandName: "",
        brandVoice: "",
        whatBusinessSells: "",
        whoIsCustomer: "",
      });
    }
    logEvent("shopDetails.context.cancel");
  };

  return (
    <div className="text-default text-sm flex flex-col gap-3 font-normal mt-0">
      <div className="flex flex-col gap-2">
        {exhaustiveSwitch(
          brandContextMenuState,
          "view",
        )({
          detailsList: ({ error }) => {
            if (isLoadingBrandDetails) {
              return (
                <div className="h-[244px] flex items-center justify-center">
                  <Spinner size={40} variant="primary" />
                </div>
              );
            }

            return (
              <>
                {error && (
                  <InlineAlert
                    variant="error"
                    onClose={() => {
                      // Note (Evan, 2024-09-27): Clear the error
                      setBrandContextMenuState({
                        view: "detailsList",
                      });
                    }}
                  >
                    Couldn't retrieve details from URL. Please try a different
                    URL.
                  </InlineAlert>
                )}
                <div className="w-full flex flex-row items-center justify-between">
                  <div className="typ-header-small">Enter Context</div>
                  <Button
                    variant="link"
                    onClick={() => {
                      setBrandContextMenuState({
                        view: "pullFromUrl",
                        isLoading: false,
                      });
                    }}
                  >
                    Pull from URL
                  </Button>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="typ-label-small">Brand Name</div>
                  <Input
                    size="sm"
                    value={draftBrandContext?.brandName ?? ""}
                    layoutClassName="w-full h-9"
                    onChange={(e) => {
                      setDraftBrandContext({
                        ...draftBrandContext,
                        brandName: e.target.value,
                      });
                    }}
                    placeholder="Rockstar Bags"
                    maxLength={512}
                  />
                </div>
                <div className="typ-label-small">Tone and voice</div>
                <Textarea
                  size="sm"
                  textLength="short"
                  value={draftBrandContext?.brandVoice}
                  layoutClassName="h-[72px] w-full"
                  onChange={handleBrandVoiceChange}
                  placeholder="Fun, quirky, and playful - we use humor to connect with our audience and keep things light-hearted..."
                  maxLength={512}
                />
                <div className="flex flex-col gap-2">
                  <div className="typ-label-small">What are you selling?</div>
                  <Textarea
                    size="sm"
                    textLength="short"
                    value={draftBrandContext?.whatBusinessSells}
                    layoutClassName="h-[72px] w-full"
                    onChange={handleWhatBusinessSellsChange}
                    placeholder="We sell handmade purses made from recycled concert tshirts"
                    maxLength={512}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="typ-label-small">Who do you sell to?</div>
                  <Textarea
                    size="sm"
                    textLength="short"
                    value={draftBrandContext?.whoIsCustomer}
                    layoutClassName="h-[72px] w-full"
                    onChange={handleWhoIsCustomerChange}
                    placeholder="Our customers are usually Gen Z teenagers who love TikTok"
                    maxLength={512}
                  />
                </div>

                <Separator className="my-2" />

                <div className="flex flex-row gap-2">
                  <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={!hasUnsavedChanges}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleCancel}
                    disabled={!hasUnsavedChanges}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            );
          },
          pullFromUrl: ({ isLoading }) => (
            <form
              className="flex flex-col gap-3"
              onSubmit={(data) => {
                void handleSubmit(onSubmitPullFromUrl)(data);
              }}
            >
              <div className="w-full flex flex-row items-center justify-between">
                <div className="typ-header-small">Enter Context</div>
                <Button
                  variant="link"
                  onClick={() =>
                    setBrandContextMenuState({
                      view: "detailsList",
                    })
                  }
                >
                  Enter Manually
                </Button>
              </div>
              <div className="flex flex-col gap-2">
                <Input
                  aria-invalid={Boolean(urlError) ? "true" : undefined}
                  aria-describedby={
                    Boolean(urlError) ? "error-first-name" : undefined
                  }
                  autoComplete="off"
                  placeholder="https://www.mywebsite.com"
                  {...register("url")}
                  type="text"
                  size="base"
                  validityState={Boolean(urlError) ? "invalid" : "valid"}
                  autoFocus
                />
                {urlError && (
                  <ErrorMessage id="error-first-name" error={urlError} />
                )}
              </div>
              <div className="flex flex-row items-center justify-end">
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Pull from URL
                </Button>
              </div>
            </form>
          ),
        })}
      </div>
    </div>
  );
};

export default BrandContextEditor;
