import type { DeleteComponentTemplateConfirmationModalProps } from "@editor/components/AppModalTypes";

import * as React from "react";

import DeleteConfirmationModal from "@editor/components/common/DeleteConfirmationModal";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import { trpc } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";

export const DeleteComponentTemplateConfirmationModal = ({
  template,
}: DeleteComponentTemplateConfirmationModalProps) => {
  const modal = useModal();
  const projectId = useCurrentProjectId();
  const {
    mutateAsync: deleteComponentTemplate,
    isSuccess,
    isPending,
  } = trpc.componentTemplates.delete.useMutation();

  const closeModal = React.useCallback(() => {
    modal.closeModal({ type: "deleteComponentTemplateConfirmationModal" });
  }, [modal]);

  React.useEffect(() => {
    if (isSuccess) {
      toast({
        header: "Template Deleted",
        message: `${template.name} deleted successfully! 🎉`,
      });
      closeModal();
    }
  }, [isSuccess, closeModal, template.name]);

  if (!projectId) {
    return null;
  }

  return (
    <DeleteConfirmationModal
      onRequestClose={() => closeModal()}
      onDelete={() => {
        void deleteComponentTemplate({
          templateId: template.id,
          projectId,
        });
      }}
      assetTypeDisplayName="Saved Component"
      assetName={template.name}
      isLoading={isPending}
    />
  );
};
