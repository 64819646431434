import * as React from "react";

import { GOOGLE_FONTS } from "replo-runtime/shared/utils/font";

export type FontOption = {
  label: string;
  isDefaultActive: boolean;
  isDisabled: boolean;
  value: string;
  isSelectable: boolean;
  groupTitle?: string;
  endEnhancer?: JSX.Element | null;
};

export const GOOGLE_FONT_OPTIONS: FontOption[] = GOOGLE_FONTS.map((f) => ({
  value: f,
  label: f,
  isDisabled: false,
  isDefaultActive: false,
  isSelectable: true,
  groupTitle: "Google Fonts",
}));

// Helper
const arrayContainsFontOption = (
  array: FontOption[],
  font: FontOption,
): boolean => {
  return array.some((fontFromArray) => fontFromArray.value === font.value);
};

export const filterOutPageFonts = (
  fontOptions: FontOption[],
  pageFontOptions: FontOption[],
): FontOption[] => {
  const filteredFonts = fontOptions.filter(
    (f) => !arrayContainsFontOption(pageFontOptions, f) || f.isDisabled,
  );
  return filteredFonts.length >= 1 ? filteredFonts : [];
};
