import * as React from "react";

import { Loader } from "@editor/components/common/Loader";
import { useDefaultWorkspaceId } from "@editor/components/dashboard/hooks/useDefaultWorkspaceId";
import { routes } from "@editor/utils/router";

import { generatePath, Navigate } from "react-router-dom";

export const AnalyticsRedirect: React.FC = () => {
  const workspaceId = useDefaultWorkspaceId();

  if (!workspaceId) {
    return <Loader />;
  }

  return (
    <Navigate
      to={generatePath(routes.analytics.insights, {
        workspaceId: workspaceId,
      })}
      replace
    />
  );
};
