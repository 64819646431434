import type { ElementRequestType } from "@editor/utils/element";

import * as React from "react";

import ElementNameEditor from "@components/editor/elementEditors/ElementNameEditor";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useInitial } from "@editor/hooks/useInitial";
import { canRenameElement } from "@editor/utils/element";

import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { slugify } from "replo-utils/lib/string";

import ElementCustomCssEditor from "./ElementCustomCssEditor";

const SectionElementEditor: React.FC<{
  requestType: ElementRequestType;
  initialName?: string;
}> = ({ requestType, initialName }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const initialElementIsPublished = useInitial(element.isPublished);
  const handleNameChange = (name: string) => {
    // NOTE (Matt 08-14-2023): In this context, the 'shopifyPagePath' is the Shopify Section File name.
    // This is used in the modal to validate whether or not a section already exists in this theme with
    // the same name.
    const shopifyPagePath =
      requestType !== "update" || !element?.shopifyPagePath
        ? slugify(name)
        : element.shopifyPagePath;
    onChangeElement({
      ...element,
      name,
      shopifyPagePath,
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <ElementNameEditor
        type="shopifySection"
        initialName={initialName ?? element.name}
        inputName="section-name"
        onChange={handleNameChange}
        isDisabled={!canRenameElement(element)}
      />
      {requestType === "update" && initialElementIsPublished && (
        <SwitchWithDescription
          label="Is Published"
          description="This section will be available to use in the Shopify Theme Editor."
          isOn={element.isPublished}
          onChange={(isPublished) => {
            onChangeElement({ ...element, isPublished });
          }}
          size="sm"
          variant="card"
        />
      )}
      <SwitchWithDescription
        label="Editable in Shopify Theme Editor"
        description="Text and images in this section will be editable in the Shopify Theme Editor."
        isOn={element.useSectionSettings ?? false}
        onChange={(useSectionSettings) => {
          onChangeElement({ ...element, useSectionSettings });
        }}
        size="sm"
        variant="card"
      />
      <ElementCustomCssEditor />
    </div>
  );
};

export default SectionElementEditor;
