import type { ComponentTemplate } from "@editor/types/component-template";

export const tooltip: ComponentTemplate = {
  storeId: null,
  scope: "left-bar",
  type: "component",
  id: "tooltip",
  name: "Component/Tooltip",
  preview: "component/tooltip.svg",
  leftBarCategory: {
    main: "interactive",
    subCategory: "popup",
  },
  template: {
    id: "6b3e2c90-7f78-4ddb-bba0-38b589a92638",
    name: "Tooltip Wrapper",
    type: "tooltip",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "center",
        alignSelf: "stretch",
      },
      _showArrow: true,
      _arrowSize: "20px",
      _side: "top",
      _alignment: "center",
      _sideOffset: "0px",
      _alignOffset: "0px",
      _tooltipContentComponent: {
        id: "d16b2030-1358-46c9-bc11-563835aac805",
        name: "Tooltip",
        type: "tooltipContent",
        props: {
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "12px",
            paddingRight: "12px",
            borderTopWidth: "2px",
            borderRightWidth: "2px",
            borderBottomWidth: "2px",
            borderLeftWidth: "2px",
            borderTopColor: "#000",
            borderRightColor: "#000",
            borderBottomColor: "#000",
            borderLeftColor: "#000",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            backgroundColor: "#FFFFFFFF",
          },
        },
        children: [
          {
            id: "cc05ccc6-5e93-4e4c-9cbc-3ef6f85e773c",
            type: "text",
            props: {
              text: "<p>Tooltip Content</p>",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
    },
    children: [
      {
        id: "77e115fb-d6b3-42e1-ae44-192b449e6d3f",
        name: "Container",
        type: "container",
        props: {
          style: {
            color: "#FFFFFFFF",
            display: "flex",
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "center",
            paddingTop: "10px",
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingBottom: "10px",
            justifyContent: "center",
            backgroundColor: "#00000080",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          },
        },
        children: [
          {
            id: "db09bd31-42a4-4330-9a2c-16e360ae9f14",
            type: "text",
            props: {
              text: "<p>Tooltip Trigger</p>",
              style: {
                fontSize: "16px",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
    ],
  },
};

export const getWrapWithTooltipTemplate = (): ComponentTemplate => {
  return {
    ...tooltip,
    template: {
      ...tooltip.template!,
      children: [],
    },
  };
};
