import * as React from "react";

export type ComboboxOption = {
  value: string;
  isDisabled?: boolean;
  groupTitle?: string;
  isSelectable?: boolean;
  estimatedSize?: number;
  excludeFromSearch?: boolean;
  tooltip?: React.ReactNode;
} & ({ label: string } | { label: React.ReactNode; displayValue: string });

// NOTE (Jackson, 2025-03-11): Context type differs slightly from the root type
// (discriminated union vs. intersection) as typescript struggles with narrowing
// types from contexts. This could potentially be made more type-safe in the future.
type ComboboxContextType = {
  open: boolean;
  isMultiselect: boolean;
  setOpen: (open: boolean) => void;
  value: string | string[];
  setValue: (value: string | string[]) => void;
  input: string;
  setInput: (input: string) => void;
  options: ComboboxOption[];
  triggerInputRef: React.RefObject<HTMLInputElement>;
  isUsingTriggerInput: boolean;
  setIsUsingTriggerInput: (isUsingTriggerInput: boolean) => void;
  syncInputWithSelectedValue: boolean;
};

export const ComboboxContext = React.createContext<
  ComboboxContextType | undefined
>(undefined);
