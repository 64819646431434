import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { CanvasData } from "./canvas-types";

import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { selectIsPreviewMode } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorStore } from "@editor/store";

import { parseInteger } from "replo-utils/lib/math";

import { CANVAS_DATA } from "./canvas-constants";
import {
  selectActiveCanvas,
  selectActiveCanvasWidth,
  setCanvasFrameWidth,
  setPreviewWidth,
} from "./canvas-reducer";
import { getCanvasData, getPresets } from "./canvas-utils";
import useSetActiveCanvas from "./useSetActiveCanvas";

export function useDeviceControls() {
  const store = useEditorStore();
  const dispatch = useEditorDispatch();
  const logAnalytics = useLogAnalytics();
  const setActiveCanvas = useSetActiveCanvas();

  const handleDeviceChange = React.useCallback(
    (value: CanvasData) => {
      const isPreviewMode = selectIsPreviewMode(store.getState());

      logAnalytics("canvas.selectActiveFrame", {
        frame: value.canvasName,
        mode: isPreviewMode ? "preview" : "edit",
      });

      if (isPreviewMode) {
        dispatch(setPreviewWidth(value.defaultFrameWidth));
        return;
      }

      setActiveCanvas({ canvas: value.canvasName, source: "toolbar" });
    },
    [setActiveCanvas, logAnalytics, store, dispatch],
  );

  const handleViewportChange = React.useCallback(
    (value: string, canvas?: EditorCanvas) => {
      const newFrameWidth = parseInteger(value);
      const isPreviewMode = selectIsPreviewMode(store.getState());
      const activeCanvas = selectActiveCanvas(store.getState());
      const canvasData = getCanvasData(newFrameWidth);

      if (canvasData) {
        logAnalytics("canvas.frame.resize", {
          method: "input",
          previousSize: selectActiveCanvasWidth(store.getState()),
          size: newFrameWidth,
          mode: isPreviewMode ? "preview" : "edit",
        });
      }

      dispatch(
        isPreviewMode
          ? setPreviewWidth(
              Number.isNaN(newFrameWidth)
                ? CANVAS_DATA.desktop.defaultFrameWidth
                : newFrameWidth,
            )
          : setCanvasFrameWidth({
              canvas: canvas ?? activeCanvas,
              width: newFrameWidth,
            }),
      );
    },
    [logAnalytics, store, dispatch],
  );

  const handlePresetDeviceChange = React.useCallback(
    (value: string, canvas?: EditorCanvas) => {
      const presets = getPresets();
      const isPreviewMode = selectIsPreviewMode(store.getState());
      const preset = presets.find((preset) => String(preset.value) === value);
      if (!preset) {
        return;
      }

      logAnalytics("canvas.frame.resize", {
        method: "presetsDropdown",
        previousSize: selectActiveCanvasWidth(store.getState()),
        size: preset.value,
        device: preset.label,
        mode: isPreviewMode ? "preview" : "edit",
      });

      dispatch(
        isPreviewMode
          ? setPreviewWidth(preset.value)
          : setCanvasFrameWidth({
              canvas: canvas ?? selectActiveCanvas(store.getState()),
              width: preset.value,
            }),
      );
    },
    [logAnalytics, store, dispatch],
  );

  return {
    handleDeviceChange,
    handleViewportChange,
    handlePresetDeviceChange,
  };
}
