import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import { SortButton } from "@editor/components/analytics/SortIndicator";
import Input from "@editor/components/common/designSystem/Input";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useOpenModal } from "@editor/hooks/useModal";

import { OVERVIEW_TABLE_METRICS } from "@/features/analytics/constants";
import { useAnalyticsOnboardingOAuthLink } from "@/features/analytics/useAnalyticsOnboaredingOAuthLink";
import Button from "@replo/design-system/components/button/Button";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import { BsCalendar3, BsGlobe2, BsSearch } from "react-icons/bs";

export const SetupPage = ({
  isCurrentlyPaying,
}: {
  isCurrentlyPaying: boolean;
}) => {
  return (
    <div className="max-h-[80vh]">
      <div className="flex flex-col">
        <div className="grid grid-cols-12">
          <div className="col-span-9">
            <div className="flex flex-row gap-2 mr-2">
              <Combobox
                options={[{ value: "all", label: "Last 7 Days" }]}
                value="all"
                trigger={
                  <Combobox.SelectionButton
                    isDisabled
                    startEnhancer={<BsCalendar3 className="h-4 w-4" />}
                    title="Last 7 Days"
                  />
                }
              />
              <Combobox
                options={[
                  { value: "all", label: "Compare To: Previous Period" },
                ]}
                value="all"
                trigger={
                  <Combobox.SelectionButton
                    isDisabled
                    startEnhancer={<BsCalendar3 className="h-4 w-4" />}
                    title="Compare To: Previous Period"
                  />
                }
              />
              <Combobox
                options={[{ value: "all", label: "All Domains" }]}
                value="all"
                trigger={
                  <Combobox.SelectionButton
                    isDisabled
                    startEnhancer={<BsGlobe2 className="h-4 w-4" />}
                    title="All Domains"
                  />
                }
              />
              <Combobox
                options={[]}
                trigger={<Combobox.SelectionButton title="Filter" isDisabled />}
              />
            </div>
          </div>
          <div className="col-span-3">
            <Input
              placeholder="Search path..."
              size="base"
              disabled={true}
              startEnhancer={<BsSearch className="text-slate-400" />}
            />
          </div>
        </div>
        <div className="relative min-w-screen pt-2 pb-8 grow">
          <div className="flex w-full flex-col space-y-[8px]">
            <Table>
              <TableHeader className="bg-subtle font-medium text-xs text-default">
                <TableRow>
                  <TableHead className="w-[300px]">Page Name</TableHead>
                  {OVERVIEW_TABLE_METRICS.map((metric) => (
                    <TableHead key={metric.key}>
                      <div className="flex items-center gap-2">
                        <span>{metric.label}</span>
                        <SortButton
                          sortMetric={metric.key}
                          sortOrder="ASC"
                          isActiveMetric={false}
                          onChange={() => {}}
                        />
                      </div>
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
            </Table>
            <div className="relative flex w-full flex-col overflow-hidden grow max-h-[80vh]">
              <Table>
                <TableBody>
                  {DUMMY_DATA.map((data, index) => (
                    <FakeTableRow key={index} {...data} />
                  ))}
                </TableBody>
              </Table>
              <div className="absolute inset-0 w-full h-full flex flex-col justify-center items-center bg-white bg-opacity-[0.80] backdrop-blur-sm">
                {isCurrentlyPaying ? <ConnectShopifyBox /> : <UpgradeBox />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConnectShopifyBox = () => {
  const productAnalytics = useLogAnalytics();
  const { oauthLink, isLoading } = useAnalyticsOnboardingOAuthLink();
  return (
    <div className="flex w-[380px] flex-col justify-start items-start border border-slate-300 shadow-sm rounded-md bg-white">
      <div className="flex flex-col grow py-6 px-8 space-y-8">
        <img
          src="/replo-shopify-connector.svg"
          alt="Connect Replo To Shopify"
          className="block object-scale-down"
        />
        <div className="space-y-2">
          <div className="text-xl font-semibold text-default">
            Connect to see store Analytics
          </div>
          <div className="text-sm text-muted">
            Get started by enabling the Replo Pixel through Shopify, then watch
            your store's analytics come to life.
          </div>
        </div>
        <Button
          layoutClassName="w-full"
          size="base"
          variant="primary"
          to={oauthLink ?? ""}
          isLoading={isLoading || !oauthLink}
          onClick={() =>
            productAnalytics("analytics.connect", {
              tab: "analytics_tab",
            })
          }
        >
          Connect Shopify
        </Button>
      </div>
    </div>
  );
};

const UpgradeBox = () => {
  const openModal = useOpenModal();
  const openBillingModal = () =>
    openModal({
      type: "billingModal",
      props: {
        source: "analytics",
        billingPlanMessageKey: "billingPlan.analytics",
      },
    });
  return (
    <div className="flex w-[378px] flex-col justify-start items-start border border-slate-300 shadow-sm rounded-md bg-white">
      <div className="flex flex-col grow py-6 px-8 space-y-2">
        <img
          src="/images/analytics/analytics-enable.svg"
          className="rounded-md border border-slate-300"
          alt="Get started"
        />
        <div className="text-xl font-semibold text-default">
          Unlock Replo Analytics Beta
        </div>
        <div className="text-sm text-muted">
          Currently the Replo Analytics Beta is only accessible to paying
          customers.
        </div>
        <div className="flex flex-row items-end justify-end">
          <Button
            size="base"
            variant="primary"
            isLoading={false}
            onClick={openBillingModal}
          >
            Upgrade
          </Button>
        </div>
      </div>
    </div>
  );
};

type FakeTableRowData = {
  title: string;
  url: string;
  metrics: Array<{ value: number; delta: number }>;
};

const DUMMY_DATA: Array<FakeTableRowData> = new Array(12)
  .fill(null)
  .map((_) => crypto.randomUUID().slice(0, 6 + Math.floor(Math.random() * 36)))
  .map((rand) => ({
    title: rand,
    url: `/pages/${rand}`,
    metrics: OVERVIEW_TABLE_METRICS.map((_) => ({
      value: Math.floor(Math.random() * 800),
      delta: Math.floor(Math.random() * 9) - 2,
    })),
  }));

const FakeTableRow = ({ title, url, metrics }: FakeTableRowData) => {
  return (
    <TableRow>
      <TableCell className="flex justify-left items-center">
        <div className="flex flex-col gap-1 w-[300px]">
          <div className="text-blue-600 truncate">{title}</div>
          <div className="text-xs font-light text-slate-500 truncate">
            {url}
          </div>
        </div>
      </TableCell>
      {metrics.map(({ value, delta }, index) => {
        return (
          <TableCell key={index}>
            <MetricWithDelta
              name="conversions"
              value={value}
              delta={delta}
              doesCompareValueExist={true}
              wrapperClassName="justify-start flex-grow"
              valueClassName="text-xs"
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};
