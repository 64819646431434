import type { Hotkey } from "@editor/utils/hotkeys";

import * as React from "react";

import { useModal } from "@editor/hooks/useModal";
import { hotkeysData } from "@editor/utils/hotkeys";

import { HotkeyIndicator } from "@replo/design-system/components/hotkeys/HotKeyIndicator";
import { Modal } from "@replo/design-system/components/modal/Modal";
import groupBy from "lodash-es/groupBy";

type HotkeyDisplay = {
  group: string;
  displayName: string;
  key: Hotkey;
};

type OrderedGroups = [string, HotkeyDisplay[]][][];

export const HotkeysModal: React.FC = () => {
  const modal = useModal();

  // Define the grouping of groups
  const groupOrder: (string | string[])[] = ["Text", "Edit", ["View", "Zoom"]];

  const groupedHotkeyElements = groupHotkeys(groupOrder);

  return (
    <Modal
      isOpen={true}
      onOpenChange={() => modal.closeModal({ type: "hotkeysModal" })}
      size="lg"
      title="Keyboard Shortcuts"
    >
      <div className="grid grid-cols-3 gap-6 pr-3 w-full">
        {groupedHotkeyElements.map((group, index) => (
          <div key={index}>
            {group.map(([groupName, hotkeys]) => (
              <div key={groupName}>
                <h3 className="text-xs font-semibold">{groupName}</h3>
                <hr className="my-2" />
                {hotkeys.map((hotkey) => (
                  <HotkeyIndicator
                    key={hotkey.key}
                    title={hotkey.displayName}
                    hotkey={hotkey.key}
                    className="text-neutral-700 flex justify-between my-1.5 items-center"
                  />
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
};

function groupHotkeys(groupOrder: (string | string[])[]): OrderedGroups {
  const hotkeys: HotkeyDisplay[] = [];
  Object.entries(hotkeysData).forEach(([key, hotkeyData]) => {
    const { group, displayName } = hotkeyData;
    if (!group) {
      return;
    }
    hotkeys.push({ group, displayName, key: key as Hotkey });
  });

  const groupedHotkeys = groupBy(hotkeys, (hotkey) => hotkey.group);

  // Create the ordered groups based on the specified groupOrder
  const orderedGroups: OrderedGroups = groupOrder.map((group) => {
    if (Array.isArray(group)) {
      return group.map((subGroup) => [
        subGroup,
        groupedHotkeys[subGroup] || [],
      ]);
    }
    return [[group, groupedHotkeys[group] || []]];
  });

  return orderedGroups;
}
