import type { ComponentTemplate } from "@editor/types/component-template";
import type { DragType } from "@editor/types/drag-type";
import type { DraggableEvent } from "react-draggable";
import type { Position } from "replo-runtime/shared/types";

import * as React from "react";

import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useDropTarget from "@editor/hooks/useDropTarget";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useDragAndDrop from "@editor/providers/DragAndDropProvider";
import { selectCandidateComponent } from "@editor/reducers/candidate-reducer";
import {
  selectComponentDataMapping,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectGetAttribute,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { processDropTargetDrag } from "@editor/utils/dropTarget";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import { useSetCandidateNodeFromPoint } from "@/features/canvas/useCandidateNode";
import twMerge from "@replo/design-system/utils/twMerge";
import isEqual from "lodash-es/isEqual";
import Draggable from "react-draggable";

const ComponentTemplateDragOverlay: React.FC<
  React.PropsWithChildren<{
    showOutlineOnHover?: boolean;
    identifier: string;
    template: ComponentTemplate;
    dragType: DragType;
    scope?: "left-bar" | "store";
  }>
> = ({ template, scope, showOutlineOnHover = true, dragType, identifier }) => {
  const store = useEditorStore();
  const candidateComponent = useEditorSelector(selectCandidateComponent);
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const { onDrop } = useDragAndDrop();
  const logEvent = useLogAnalytics();

  const {
    currentDragType,
    currentDragIdentifier,
    setCurrentDragTypeAndIdentifier,
  } = useCurrentDragType();
  const { dropTarget, setDropTarget } = useDropTarget();
  const dragPosition = useDragPosition();

  const { setCandidateNodeFromPoint } = useSetCandidateNodeFromPoint();

  const onDragStart = (dragType: DragType) => {
    setCurrentDragTypeAndIdentifier(dragType, identifier);
  };

  const onDrag = (e: DraggableEvent, dragType: DragType) => {
    // Note (Ovishek, 2022-06-16): TouchEvent doesn't support e.clientX
    // we should return on touchEvent, b/c we don't support touch drag yet
    if (!(e instanceof MouseEvent)) {
      return;
    }

    // Note (Noah, 2021-11-15): This is here because we need to pass the fact
    // that we're dragging to Canvas in order to have the candidate box update
    // when dragging a component template card. This is specifically an issue on
    // Firefox, where the drag events don't propagate through to Canvas

    const candidateNode = setCandidateNodeFromPoint(e.clientX, e.clientY);

    if (candidateNode) {
      const { x, y } = dragPosition;
      const state = store.getState();
      const newDropTarget = processDropTargetDrag(
        {
          x,
          y,
        },
        candidateNode,
        { type: "template", template },
        candidateComponent!,
        dropTarget,
        draftElement!,
        selectGetAttribute(state),
        selectComponentDataMapping(state),
        selectActiveCanvas(state),
        dragType,
      );

      if (newDropTarget && !isEqual(dropTarget, newDropTarget)) {
        setDropTarget(newDropTarget);
      } else if (!newDropTarget) {
        setDropTarget({
          componentId: null,
          edge: null,
          error: null,
        });
      }
    } else {
      setDropTarget({
        componentId: null,
        edge: null,
        error: null,
      });
    }
  };

  const onDragStop = () => {
    if (dropTarget.componentId) {
      logEvent("editor.componentTemplate.used", {
        componentTemplateId: template.id,
        collectionId: template.collectionId,
        categoryId: template.categoryId,
        componentTemplateName: template.name,
        componentTemplateType: template.type,
        componentScope: template.scope,
        source: scope === "left-bar" ? "insert-pane" : "marketplace",
      });
    }

    onDrop?.current?.(dropTarget, template, [], scope === "store");
    setDropTarget({ ...dropTarget, componentId: null });
    // NOTE (Fran 2024-05-02): In some cases, we need to know the current drag type to check if we
    // are dragging a new component. So, we need to set the current drag type to null after the
    // drop process is completed.
    setCurrentDragTypeAndIdentifier(null, null);
  };

  return (
    <Draggable
      axis="both"
      position={{ x: 0, y: 0 }}
      onStart={() => onDragStart(dragType)}
      onDrag={(e) => onDrag(e, dragType)}
      onStop={onDragStop}
    >
      <div
        className={twMerge(
          // NOTE (Sebas, 2024-06-05): The visible class is needed to make the
          // draggable component visible on the screen when dragging it. This is
          // because we are hiding the parent component of the draggable component
          // when dragging.
          "visible absolute top-0 left-0 z-[2147483647] h-full w-full rounded border border-transparent",
          showOutlineOnHover && !currentDragType && "hover:border-blue-600",
          currentDragType &&
            currentDragIdentifier === identifier &&
            "border-blue-600",
        )}
        style={{ cursor: currentDragType ? "grabbing" : "grab" }}
      />
    </Draggable>
  );
};

// Calculate dragging coordinates within the application viewport
function useDragPosition() {
  const { currentDragType } = useCurrentDragType();
  const [position, setPosition] = React.useState<Position>({
    x: 0,
    y: 0,
  });

  const hasDragType = currentDragType != null;
  React.useEffect(() => {
    // Note (Noah, 2022-02-07): Only update client if we're dragging, otherwise
    // all ComponentTemplateCards will rerender on every mousemove
    if (!hasDragType) {
      return;
    }

    function handleMouseMove({ clientX, clientY }: MouseEvent) {
      setPosition(() => ({ x: clientX, y: clientY }));
    }

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [hasDragType]);

  return position;
}

export default ComponentTemplateDragOverlay;
