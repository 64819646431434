import type { ComponentTemplate } from "@editor/types/component-template";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const feraProductRatingWidget: ComponentTemplate = {
  id: "2ea12ad2-5bb7-41eb-8ff8-ae21860b0b76",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Fera Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "fera" },
  preview: "integrations/fera-product-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star", "widget"],
  template: {
    id: "$uid",
    type: "feraProductRatingWidget",
    props: {},
  },
};
