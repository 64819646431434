/**
 * For API calls, you still have to write the boilerplate code but this will automatically
 * GENERATE AND EXPORT the THREE ACTION RSAA TYPES for you
 */
const __apiGenericBaseActionTypes = {
  /* ACCOUNT */
  CREATE_ACCOUNT: true,
  LOGOUT_ACCOUNT: true,
  LOGIN: true,
  GET_USER: true,
  INIT_RESET_PASSWORD: true,
  RESET_PASSWORD: true,

  /* PROJECT MEMBERSHIPS */
  FIND_PROJECT_MEMBERSHIPS: true,
  DELETE_PROJECT_MEMBERSHIP: true,
  CREATE_PROJECT_MEMBERSHIP: true,

  /* EDITOR */
  GET_STORE: true,
  CREATE_OR_UPDATE_ELEMENT: true,
  PUBLISH_SNIPPET: true,
  CREATE_OR_UPDATE_DATA_TABLE: true,
  CREATE_ELEMENT: true,
  CREATE_OR_UPDATE_ASSET: true,
  FIND_ASSETS: true,

  CREATE_OR_UPDATE_COMPONENT_TEMPLATE: true,
  GET_CANVAS_DOCUMENT: true,

  CREATE_PRODUCT_TEMPLATE: true,
  MAKE_PAGE_HOMEPAGE: true,
  REMOVE_PAGE_HOMEPAGE: true,
} as const;

type _ApiActionType<Base extends string> = {
  base: Base;
  start: `${Base}_START`;
  success: `${Base}_SUCCESS`;
  error: `${Base}_ERROR`;
};

function generateApiActionTypes<Base extends string>(
  base: Base,
): _ApiActionType<Base> {
  return {
    base,
    start: `${base}_START`,
    success: `${base}_SUCCESS`,
    error: `${base}_ERROR`,
  };
}

const __generateApiActionTypes = <ActionTypes extends { [key in string]: any }>(
  baseActionTypes: ActionTypes,
) => {
  const result = {} as {
    [Key in keyof ActionTypes]: Key extends string
      ? _ApiActionType<Key>
      : Key extends number
        ? _ApiActionType<`${Key}`>
        : never;
  };
  for (const baseActionType of Object.keys(baseActionTypes)) {
    (result as any)[baseActionType] = generateApiActionTypes(baseActionType);
  }
  return result;
};

export const API_ACTIONS = __generateApiActionTypes(
  __apiGenericBaseActionTypes,
);
