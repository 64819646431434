import type { ComponentTemplate } from "@editor/types/component-template";

import set from "lodash-es/set";

export const image: ComponentTemplate = {
  id: "cc80c0b5-409d-4e90-a15d-a80254020c19",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Basic/Image",
  leftBarCategory: { main: "basic", subCategory: "core" },
  preview: "basic/image.svg",
  transforms: [
    (component, parent) => {
      if (parent?.type === "container" && parent.children?.length === 0) {
        set(component, "props.style.flexGrow", 1);
      }
      return component;
    },
  ],

  template: {
    id: "$uid",
    type: "image",
    props: {
      src: "",
      style: {
        objectFit: "cover",
        flexBasis: "auto",
      },
    },
  },
};
