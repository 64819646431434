import * as React from "react";

import { docs } from "@editor/utils/docs";

type ReferredTableEmptyStateProps = {
  type: "users" | "workspaces";
};

const ReferredTableEmptyState: React.FC<ReferredTableEmptyStateProps> = ({
  type,
}) => {
  return (
    <>
      <p className="mt-2 max-w-2xl typ-body-base text-subtle">
        When someone {type === "users" ? "signs up" : "creates a workspace"}{" "}
        with your referral link, you'll see their information here. If you have
        any questions, please reach out at{" "}
        <a className="underline" href="mailto:support@replo.app">
          support@replo.app
        </a>
        , or visit our{" "}
        <a className="underline" href={docs.faq}>
          FAQ
        </a>
        .
      </p>
    </>
  );
};

export default ReferredTableEmptyState;
