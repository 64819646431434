import type { PendingShopifyIntegrationCookie } from "schemas/cookies";

import * as React from "react";

import { useCookies } from "react-cookie";
import {
  PENDING_SHOPIFY_INTEGRATION_COOKIE,
  pendingShopifyIntegrationCookieSchema,
} from "schemas/cookies";

const usePendingShopifyIntegrationCookie = () => {
  const [cookies, setCookie] = useCookies([PENDING_SHOPIFY_INTEGRATION_COOKIE]);

  const pendingShopifyIntegrationCookie = React.useMemo(() => {
    if (!cookies.replo_pending_shopify_integration) {
      return null;
    }
    const parsedCookie = pendingShopifyIntegrationCookieSchema.safeParse(
      cookies.replo_pending_shopify_integration,
    );
    if (!parsedCookie.success) {
      return null;
    }
    return parsedCookie.data;
  }, [cookies.replo_pending_shopify_integration]);

  const setPendingShopifyIntegrationCookie = (
    pendingShopifyIntegrationCookie: PendingShopifyIntegrationCookie,
  ) => {
    const validatedCookie = pendingShopifyIntegrationCookieSchema.parse(
      pendingShopifyIntegrationCookie,
    );
    return setCookie(
      PENDING_SHOPIFY_INTEGRATION_COOKIE,
      JSON.stringify(validatedCookie),
      {
        path: "/",
        maxAge: 60 * 10, // 10 minutes
      },
    );
  };

  return {
    pendingShopifyIntegrationCookie,
    setPendingShopifyIntegrationCookie,
  };
};

export default usePendingShopifyIntegrationCookie;
