import type { ComponentTemplate } from "@editor/types/component-template";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const stampedProductRatingWidget: ComponentTemplate = {
  id: "1555100d-1896-4592-ba0b-3738c3ad1b53",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Stamped Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "stamped" },
  preview: "integrations/stamped-product-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star", "widget"],
  template: {
    id: "$uid",
    type: "stampedProductRatingWidget",
    props: { style: { alignSelf: "stretch" } },
  },
};
