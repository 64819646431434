// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { ToggleOption } from "@replo/design-system/components/toggle/ToggleGroup";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { PreviewableProperty } from "schemas/preview";
import type { BorderSuffix } from "schemas/styleAttribute";

import * as React from "react";

import Border from "@common/icons/Border";
import BorderLeft from "@common/icons/BorderLeft";
import Selectable from "@editor/components/common/designSystem/Selectable";
import { BADGE_TRIGGER_OFFSET } from "@editor/components/editor/constants";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useDynamicCommandMenuItem } from "@editor/hooks/useDynamicCommandMenuItems";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { usePrevious } from "@editor/hooks/usePrevious";
import {
  selectActiveBorderSideColor,
  selectActiveBorderSideStyle,
  selectActiveBorderSideWidth,
  selectBorderColor,
  selectBorderPropertiesValues,
  selectBorderRadius,
  selectBorderWidth,
  selectDraftComponentId,
  selectDraftComponentIds,
  selectedDraftComponentIsRoot,
  selectHasBorderStyles,
  selectInitialBorderSide,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { prepareBorderStyles } from "@editor/utils/border";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";
import DocumentationInfoIcon from "@editorComponents/DocumentationInfoIcon";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { DynamicColorSelector } from "@editorModifiers/DynamicColorModifier";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";
import BorderAllSides from "@svg/icons/border-all-sides";
import BorderSide from "@svg/icons/border-side";

import IconButton from "@replo/design-system/components/button/IconButton";
import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import { Plus } from "lucide-react";
import { AiOutlineRadiusUpleft } from "react-icons/ai";
import { BsBorder } from "react-icons/bs";
import { RxBox } from "react-icons/rx";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { useOnValueChange } from "replo-utils/react/use-on-value-change";

import ModifierLabel from "../extras/ModifierLabel";

const BorderModifier = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const [showControls, setShowControls] = React.useState(
    selectHasBorderStyles(store.getState()),
  );

  useOnValueChange(draftComponentIds, () => {
    setShowControls(selectHasBorderStyles(store.getState()));
  });

  const addDefaultBorder = React.useCallback(() => {
    const styles = prepareBorderStyles("All", "1px", "#000000", "solid");
    setShowControls(true);
    applyComponentAction({
      type: "setStyles",
      value: styles,
    });
  }, [applyComponentAction]);

  const dynamicMenuItem = React.useMemo(() => {
    return !showControls
      ? ({
          label: "Add Border",
          group: "add",
          icon: { type: "iconComponent", component: BsBorder },
          onSelect: addDefaultBorder,
        } as const)
      : null;
  }, [showControls, addDefaultBorder]);
  useDynamicCommandMenuItem("border", dynamicMenuItem);

  return (
    <ModifierGroup
      title="Border"
      titleEnhancer={<DocumentationInfoIcon documentationType="border" />}
      isDefaultOpen={showControls}
      endEnhancer={
        !showControls ? (
          <IconButton
            size="sm"
            icon={<Plus className="text-muted" size={16} />}
            tooltipText="Add Border"
            variant="tertiary"
            onClick={addDefaultBorder}
            disabled={showControls}
          />
        ) : null
      }
      isCollapsible={showControls}
    >
      {showControls && <BorderControls />}
    </ModifierGroup>
  );
};

const iconSize = 16;

const radiusOptions = [
  {
    value: "",
    label: "Reset",
  },
  {
    value: "4px",
    label: "4px",
  },
  {
    value: "8px",
    label: "8px",
  },
  {
    value: "12px",
    label: "12px",
  },
];

const widthOptions = [
  {
    value: "1px",
    label: "1px",
  },
  {
    value: "2px",
    label: "2px",
  },
  {
    value: "3px",
    label: "3px",
  },
  {
    value: "5px",
    label: "5px",
  },
  {
    value: "10px",
    label: "10px",
  },
];

const borderOptions = [
  {
    value: "solid",
    label: "Solid",
  },
  {
    value: "dashed",
    label: "Dashed",
  },
  {
    value: "dotted",
    label: "Dotted",
  },
  {
    value: "double",
    label: "Double",
  },
  {
    value: "inset",
    label: "Inset",
  },
  {
    value: "none",
    label: "None",
  },
];

const borderRadiusIndividualOptions = [
  {
    key: "borderTopLeftRadius",
    enhancerClassName: "",
  },
  {
    key: "borderTopRightRadius",
    enhancerClassName: "rotate-90",
  },
  {
    key: "borderBottomLeftRadius",
    enhancerClassName: "-rotate-90",
  },
  {
    key: "borderBottomRightRadius",
    enhancerClassName: "rotate-180",
  },
];

const BorderControls: React.FC = () => {
  const isRoot = useEditorSelector(selectedDraftComponentIsRoot);
  const initialBorderSide = useEditorSelector(selectInitialBorderSide);
  const [activeSide, setActiveSide] = React.useState(initialBorderSide);
  const activeSideStyle = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });

  return (
    <div className="flex flex-col gap-2">
      <BorderSideToggles
        value={activeSide}
        onChange={(newValue: BorderSuffix | null) => {
          setActiveSide(newValue ?? "All");
        }}
      />
      <BorderStyleSelectable activeSide={activeSide} />

      {activeSideStyle && (
        <>
          <BorderColorControl activeSide={activeSide} />
          <BorderWidthControl activeSide={activeSide} />
          {/* Note (Noah, 2023-02-16, REPL-5519): Don't allow setting border radius on the root component,
        because it sets overflow to hidden and thus does not allow sticky positioning to work */}
          {!isRoot && <BorderRadiusControls />}
        </>
      )}
    </div>
  );
};

const sidesOptions: ToggleOption[] = [
  {
    value: "all",
    label: <BorderAllSides />,
  },
  {
    value: "oneSide",
    label: <BorderSide />,
  },
];

const sideOptions: ToggleOption[] = [
  {
    value: "All",
    label: <Border size={iconSize} className="rounded-sm text-slate-200" />,
    tooltipContent: "All Sides",
  },
  {
    value: "Left",
    label: <BorderLeft size={iconSize} className="rounded-sm text-slate-200" />,
    tooltipContent: "Left Border",
  },
  {
    value: "Top",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-90 rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Top Border",
  },
  {
    value: "Right",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-180 rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Right Border",
  },
  {
    value: "Bottom",
    label: (
      <BorderLeft
        size={iconSize}
        className="rotate-[270deg] rounded-sm text-slate-200"
      />
    ),
    tooltipContent: "Bottom Border",
  },
];

const BorderSideToggles: React.FC<{
  value: BorderSuffix | null;
  onChange: (value: BorderSuffix | null) => void;
}> = ({ value, onChange }) => {
  const borderPropertiesValues = useEditorSelector(
    selectBorderPropertiesValues,
  );
  const areBorderPropertiesValuesConsistent = borderPropertiesAreConsistent(
    borderPropertiesValues,
  );

  const [sideSelection, setSideSelection] = useOverridableState<
    "all" | "oneSide"
  >(areBorderPropertiesValuesConsistent ? "all" : "oneSide");

  return (
    <div className="flex w-full">
      <ModifierLabel label="Sides" layoutClassName="mt-1.5" />
      <div className="flex flex-col gap-2 w-full">
        <ToggleGroup
          size="sm"
          options={sidesOptions}
          onChange={(value) => {
            setSideSelection(value as "all" | "oneSide");
            if (value === "all") {
              onChange(null);
            }
          }}
          selectedValue={sideSelection}
        />
        {sideSelection === "oneSide" && (
          <ToggleGroup
            size="sm"
            options={sideOptions}
            onChange={(value) => {
              onChange(value as BorderSuffix);
            }}
            selectedValue={value}
          />
        )}
      </div>
    </div>
  );
};

const BorderColorControl: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const logEvent = useLogAnalytics();
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideColor(state, activeSide);
  });
  const activeBorderSideWidth = useEditorSelector((state) => {
    return selectActiveBorderSideWidth(state, activeSide);
  });
  const activeBorderSideStyle = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });
  const borderSide = activeSide === "All" ? "" : activeSide;
  const field = `style.border${borderSide}Color`;

  if (!draftComponentId) {
    return null;
  }

  const onChange = (value: string | null) => {
    const styles = prepareBorderStyles(
      activeSide,
      !isMixedStyleValue(activeBorderSideWidth) ? activeBorderSideWidth : "1px",
      value ? value : "#00000000",
      !isMixedStyleValue(activeBorderSideStyle)
        ? activeBorderSideStyle
        : "solid",
    );

    applyComponentAction({
      type: "setStyles",
      value: styles,
    });
  };

  return (
    <div className="flex items-center">
      <ModifierLabel label="Color" />
      <DynamicColorSelector
        allowsGradientSelection={false}
        value={value ?? null}
        hasDynamicData
        field={field}
        popoverTitle="Border Color"
        onChange={onChange}
        openDynamicData={() => {
          modal.openModal({
            type: "dynamicDataModal",
            props: {
              requestType: "prop",
              targetType: DynamicDataTargetType.TEXT_COLOR,
              referrerData: {
                type: "callback",
                onChange: (value: string) => {
                  onChange(value);
                },
              },
              initialPath:
                value && !isMixedStyleValue(value)
                  ? getPathFromVariable(value)
                  : undefined,
            },
          });
        }}
        onRemove={() => onChange(null)}
        componentId={draftComponentId}
        popoverSideOffset={BADGE_TRIGGER_OFFSET}
        showSavedStyles
        onSelectSavedStyle={(value) => {
          logEvent("library.style.apply", {
            modifier: "borderColor",
            type: "color",
          });
          onChange(value);
        }}
      />
    </div>
  );
};

const BorderWidthControl: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideWidth(state, activeSide);
  });
  const activeBorderSideColor = useEditorSelector((state) => {
    return selectActiveBorderSideColor(state, activeSide);
  });
  const activeBorderSideStyle = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });

  const onChange = (value: string | null) => {
    const width = value === "reset" ? "" : value;
    const styles = prepareBorderStyles(
      activeSide,
      width ?? null,
      activeBorderSideColor && !isMixedStyleValue(activeBorderSideColor)
        ? activeBorderSideColor
        : "#000",
      value && !isMixedStyleValue(activeBorderSideStyle)
        ? activeBorderSideStyle || "solid"
        : "none",
    );

    applyComponentAction({
      type: "setStyles",
      value: styles,
    });
  };

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Width" />}
      draggingType={DraggingTypes.Vertical}
      dragTrigger="label"
      minDragValues={{ px: 0 }}
      resetValue=""
      placeholder="auto"
      key={`border${activeSide}Width`}
      onChange={onChange}
      field={`style.border${activeSide}Width`}
      value={value}
      menuOptions={widthOptions}
      metrics={CSS_LENGTH_TYPES}
      previewProperty={`border${activeSide}Width` as PreviewableProperty}
    />
  );
};

const BorderStyleSelectable: React.FC<{
  activeSide: BorderSuffix | null;
}> = ({ activeSide }) => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector((state) => {
    return selectActiveBorderSideStyle(state, activeSide);
  });
  const selectableValue = value ?? "none";
  const activeBorderWidth = useEditorSelector((state) =>
    selectActiveBorderSideWidth(state, activeSide),
  );
  const activeBorderColor = useEditorSelector((state) =>
    selectActiveBorderSideColor(state, activeSide),
  );

  const borderColor = useEditorSelector(selectBorderColor);
  const borderWidth = useEditorSelector(selectBorderWidth);

  const previousBorderColor = usePrevious(borderColor);
  const previousBorderWidth = usePrevious(borderWidth);

  const handleChangeStyle = (value: string) => {
    if (value === "none") {
      applyComponentAction({
        type: "setStyles",
        value: prepareBorderStyles(activeSide, null, null, value),
      });
    } else {
      const borderSide = activeSide === "All" ? null : activeSide;
      const borderColorToApply =
        activeBorderColor ??
        previousBorderColor?.[borderSide ?? "Top"] ??
        "#000000";
      const borderWidthToApply =
        activeBorderWidth ??
        previousBorderWidth?.[borderSide ?? "Top"] ??
        "1px";

      applyComponentAction({
        type: "setStyles",
        value: prepareBorderStyles(
          activeSide,
          String(borderWidthToApply),
          !isMixedStyleValue(borderColorToApply)
            ? borderColorToApply
            : "#000000",
          value,
        ),
      });
    }
  };

  return (
    <div className="flex items-center">
      <ModifierLabel label="Style" />

      <Selectable
        layoutClassName="w-full"
        triggerLayoutClassName="w-full"
        options={borderOptions}
        onSelect={handleChangeStyle}
        defaultValue="none"
        value={selectableValue}
        onRemove={
          selectableValue !== "none"
            ? () => {
                handleChangeStyle("none");
              }
            : undefined
        }
      />
    </div>
  );
};

const borderRadiusOptions: ToggleOption[] = [
  {
    value: "single",
    label: <RxBox size={iconSize} />,
  },
  {
    value: "individual",
    label: <AiOutlineRadiusUpleft size={iconSize} />,
  },
];

const BorderRadiusControls: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const isRootComponent = useEditorSelector(selectedDraftComponentIsRoot);
  const borderRadiusValues = useEditorSelector(selectBorderRadius);
  const value = borderRadiusValues[0] ? borderRadiusValues[0] : "0px";
  const allSidesAreEqual =
    (borderRadiusValues[0] &&
      borderRadiusValues.every(
        (value) => String(value) === String(borderRadiusValues[0]),
      )) ??
    // NOTE (Sebas, 2024-10-17): If there is no border radius value set, we consider that all sides are equal
    // to show the correct toggle button option selected.
    true;
  // NOTE (Sebas, 2024-10-16): If all border radius are equal, we only want to show
  // a single input for the radius.`
  const [isExpandedBorderRadius, setIsExpandedBorderRadius] =
    React.useState(!allSidesAreEqual);

  return (
    <LengthInputSelector.Root
      isDisabled={isExpandedBorderRadius}
      // TODO (Noah, 2021-06-27): We use borderTopLeftRadius here because
      // this is the one that shows before you expand all the individual border
      // radii inputs. If we just use `borderRadius`, it gets reset since the
      // actual onchange sets the radii individually, as it should. However,
      // when the radii are open, this box doesn't make sense since its value
      // reflects only the top left radius. Maybe there's a better way to handle
      // this UX
      field="style.borderTopLeftRadius"
      value={isMixedStyleValue(value) ? value : String(value)}
      draggingType={DraggingTypes.Vertical}
      allowsNegativeValue={false}
      minValues={{ px: 0 }}
      minDragValues={{ px: 0 }}
      resetValue="0px"
      dragTrigger="label"
      onChange={(newValue: string) => {
        applyComponentAction({
          type: "setStyles",
          value: {
            borderTopLeftRadius: newValue,
            borderTopRightRadius: newValue,
            borderBottomLeftRadius: newValue,
            borderBottomRightRadius: newValue,
          },
        });
      }}
      metrics={CSS_LENGTH_TYPES}
      previewProperty="borderRadius"
    >
      {/* NOTE (Fran 2024-10-16): 74px is the fixed width of the label. */}
      <div className="grid grid-cols-[74px,auto] w-full items-center gap-y-2">
        <LengthInputSelector.DraggableArea>
          <ModifierLabel label="Radius" />
        </LengthInputSelector.DraggableArea>
        <div className="flex gap-1">
          <LengthInputSelector.Input
            placeholder="0px"
            key="style.borderTopLeftRadius"
            menuOptions={radiusOptions}
            className="w-full"
          />
          <ToggleGroup
            size="sm"
            options={borderRadiusOptions}
            selectedValue={isExpandedBorderRadius ? "individual" : "single"}
            onChange={(value) => {
              // NOTE (Sebas, 2024-10-01): In case we are switching from individual to single, we need to
              // set the same value for all corners.
              if (value === "single") {
                applyComponentAction({
                  type: "setStyles",
                  value: {
                    borderTopLeftRadius: isMixedStyleValue(
                      borderRadiusValues[0],
                    )
                      ? null
                      : borderRadiusValues[0],
                    borderTopRightRadius: isMixedStyleValue(
                      borderRadiusValues[0],
                    )
                      ? null
                      : borderRadiusValues[0],
                    borderBottomLeftRadius: isMixedStyleValue(
                      borderRadiusValues[0],
                    )
                      ? null
                      : borderRadiusValues[0],
                    borderBottomRightRadius: isMixedStyleValue(
                      borderRadiusValues[0],
                    )
                      ? null
                      : borderRadiusValues[0],
                  },
                });
              }
              setIsExpandedBorderRadius(value === "individual");
            }}
          />
        </div>
        {!isRootComponent && isExpandedBorderRadius && (
          <div className="col-start-2">
            <BorderRadiusIndividualControls />
          </div>
        )}
      </div>
    </LengthInputSelector.Root>
  );
};

const BorderRadiusIndividualControls: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  return (
    <div className="self-end grid grid-cols-2 grid-rows-2 gap-1">
      {borderRadiusIndividualOptions.map((option) => {
        return (
          <LengthInputSelector
            key={option.key}
            metrics={CSS_LENGTH_TYPES}
            placeholder="0"
            field={`style.${option.key}`}
            dragTrigger="label"
            draggingDirection={DraggingDirections.Positive}
            draggingType={DraggingTypes.Vertical}
            resetValue="0px"
            allowsNegativeValue={false}
            minValues={{ px: 0 }}
            label={
              <div className="mr-1">
                <AiOutlineRadiusUpleft
                  size={iconSize}
                  className={option.enhancerClassName}
                />
              </div>
            }
            minDragValues={{ px: 0 }}
            onChange={(newValue) => {
              applyComponentAction({
                type: "setStyles",
                value: {
                  [option.key]: newValue === "reset" ? null : newValue,
                },
              });
            }}
            previewProperty={option.key as PreviewableProperty}
          />
        );
      })}
    </div>
  );
};

function checkBorderPropertiesConsistency(
  property: Record<
    "Left" | "Top" | "Right" | "Bottom",
    string | null | undefined | ReploMixedStyleValue
  >,
) {
  const values = Object.values(property);
  const firstValue = values[0];
  for (const value of values) {
    if (value !== firstValue) {
      return false; // End loop if a different value is found
    }
  }
  return true; // All values are consistent
}

function borderPropertiesAreConsistent(
  properties: Record<
    "borderWidth" | "borderColor" | "borderStyle",
    Record<
      "Left" | "Top" | "Right" | "Bottom",
      string | null | undefined | ReploMixedStyleValue
    >
  >,
) {
  for (const [_, value] of Object.entries(properties)) {
    const isConsistent = checkBorderPropertiesConsistency(value);
    if (!isConsistent) {
      return false;
    }
  }
  return true;
}

export default BorderModifier;
