import type { Flow, FlowStep } from "schemas/generated/flow";

import * as React from "react";

import StartFrom from "@components/flows/onboardingSteps/StartFrom";
import UserFullNameStep from "@components/flows/onboardingSteps/UserFullNameStep";
import WhatDoYouWantToDoInReplo from "@components/flows/onboardingSteps/WhatDoYouWantToDoInReplo";
import WhoAreYouBuildingPagesFor from "@components/flows/onboardingSteps/WhoAreYouBuildingPagesForStep";
import { ReploFlowsStepContext } from "@editor/components/flows/context/ReploFlowsStepContext";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import useGetFlowActions from "@editor/components/flows/hooks/useGetFlowActions";
import Industry from "@editor/components/flows/onboardingSteps/Industry";
import Revenue from "@editor/components/flows/onboardingSteps/Revenue";

import { useSearchParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import { useRouterFlowCallbacks } from "./hooks/useRouterFlowCallbacks";
import BrandDetailsStep from "./onboardingSteps/BrandDetails";
import ConnectShopifyStep from "./onboardingSteps/ConnectShopifyStep";
import ImportFromUrlStep from "./onboardingSteps/ImportFromUrl";
import PrimarySavedStylesStep from "./onboardingSteps/PrimarySavedStyles";
import WorkspaceName from "./onboardingSteps/WorkspaceName";

type FlowsProps = {
  entityType: Flow["type"];
  /**
   * If provided, overrides the current step. This is useful for e.g. when we're rendering
   * a specific step in a flow due to a URL route.
   */
  currentStepOverride?: FlowStep | null;
};

const Flows: React.FC<FlowsProps> = ({ entityType, currentStepOverride }) => {
  const [searchParams] = useSearchParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { nextStep: lastNonCompletedStep } = useGetCurrentFlow({
    entityType,
    isDebug,
  });
  const currentStep = currentStepOverride ?? lastNonCompletedStep ?? null;

  const { submitStep, skipStep, goBack, isSubmitting } = useGetFlowActions(
    entityType,
    currentStep,
  );
  const { goBackCallback } = useRouterFlowCallbacks();

  const onGoBack = React.useCallback(() => {
    goBack((currentFlow, previousStepId) =>
      goBackCallback(currentFlow.slug, previousStepId),
    );
  }, [goBack, goBackCallback]);

  if (currentStep === null) {
    return null;
  }

  return (
    <ReploFlowsStepContext.Provider
      value={{
        currentStep,
        goBack: onGoBack,
        submitStep,
        skipStep,
        isSubmitting,
      }}
    >
      <>{getStepComponent(currentStep)}</>
    </ReploFlowsStepContext.Provider>
  );
};

function getStepComponent(step: FlowStep) {
  return exhaustiveSwitch({ type: step.type })({
    // ---------- Onboarding steps ----------
    "onboarding.user.full-name": () => <UserFullNameStep />,

    "onboarding.user.who-are-you-building-pages-for": () => (
      <WhoAreYouBuildingPagesFor />
    ),
    "onboarding.user.workspace-name": () => <WorkspaceName />,
    "onboarding.user.what-do-you-want-to-do-in-replo": () => (
      <WhatDoYouWantToDoInReplo />
    ),

    "onboarding.user.which-best-describes-your-industry": () => <Industry />,
    "onboarding.user.do-you-want-to-start-from-template": () => <StartFrom />,
    "onboarding.user.who-are-you-building-pages-for.revenue": () => <Revenue />,
    "onboarding.user.import-from-url": () => <ImportFromUrlStep />,
    "onboarding.user.brand-details": () => <BrandDetailsStep />,
    "onboarding.user.saved-styles": () => <PrimarySavedStylesStep />,
    "onboarding.user.connect-shopify": () => <ConnectShopifyStep />,
    // ---------- End Onboarding steps ----------

    // ---------- Editor Tour steps ----------
    "tour.editor.generic": () => null,
    // ---------- End Editor Tour steps ----------
  });
}

export default Flows;
