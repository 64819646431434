import * as React from "react";

import AnalyticsContext from "@/features/analytics/AnalyticsContext";
import { Outlet } from "react-router-dom";

import { useAutoSetWorkspaceSettingsIfNull } from "../workspace/hooks/useAutoSetWorkspaceSettingsIfNull";

const WorkspaceAnalyticsDashboard: React.FC = () => {
  useAutoSetWorkspaceSettingsIfNull();
  return (
    <AnalyticsContext>
      <Outlet />
    </AnalyticsContext>
  );
};

export default WorkspaceAnalyticsDashboard;
