import * as React from "react";

import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";

export const PartnershipLevelCardSkeleton: React.FC = () => {
  return (
    <div className="w-[600px] h-[139px] relative">
      <Skeleton
        className="w-[117px] h-[16px] absolute top-[6px]"
        speed={2}
        backgroundColor="#f1f5f9"
        foregroundColor="#e2e8f0"
      />
      <Skeleton
        className="w-[200px] h-[35px] absolute top-[28px]"
        speed={2}
        backgroundColor="#f1f5f9"
        foregroundColor="#e2e8f0"
      />
      <Skeleton
        className="w-[155px] h-[20px] absolute top-[74px]"
        speed={2}
        backgroundColor="#f1f5f9"
        foregroundColor="#e2e8f0"
      />
      <Skeleton
        className="w-[500px] h-[20px] absolute top-[119px]"
        speed={2}
        backgroundColor="#f1f5f9"
        foregroundColor="#e2e8f0"
      />
    </div>
  );
};

export const PartnershipReferralBoardCardSkeleton: React.FC<{
  showCta: boolean;
}> = ({ showCta }) => {
  return (
    <div className="w-[240px] h-[118px] relative">
      <Skeleton
        className="w-[200px] h-5 absolute top-[6px]"
        speed={2}
        backgroundColor="#f1f5f9"
        foregroundColor="#e2e8f0"
      />
      <Skeleton
        className="w-[200px] h-9 absolute top-[40px]"
        speed={2}
        backgroundColor="#f1f5f9"
        foregroundColor="#e2e8f0"
      />
      {showCta && (
        <Skeleton
          className="w-[138px] h-5 absolute top-[90px]"
          speed={2}
          backgroundColor="#f1f5f9"
          foregroundColor="#e2e8f0"
        />
      )}
    </div>
  );
};

export const SidebarWorkspacesSkeleton: React.FC = () => {
  return (
    <div className="w-[310px] h-[35px] relative box-border bg-white rounded px-1">
      <Skeleton
        className="w-[28px] h-[28px] absolute top-0 left-0 rounded-[50px]"
        speed={2}
        backgroundColor="#e2e8f07E"
        foregroundColor="#cbd5e17E"
      />
      <Skeleton
        className="w-[240px] h-[12px] absolute top-[8px] left-[35px]"
        speed={2}
        backgroundColor="#e2e8f07E"
        foregroundColor="#cbd5e17E"
      />
    </div>
  );
};

export const TitleSkeleton = () => (
  <div className="w-[200px] h-[30px">
    <Skeleton
      className="w-[200px] h-[28px]"
      speed={2}
      backgroundColor="#f1f5f9"
      foregroundColor="#e2e8f0"
    />
  </div>
);

export const InvitationModalSkeleton = () => (
  <div className="w-[302px] h-[30px]">
    <Skeleton
      className="w-[302px] h-[28px]"
      speed={2}
      backgroundColor="#f1f5f9"
      foregroundColor="#e2e8f0"
    />
  </div>
);

export const SubtitleSkeleton = () => (
  <div className="w-[400px] h-[20px]">
    <Skeleton
      className="w-[400px] h-[18px]"
      speed={2}
      backgroundColor="#f1f5f9"
      foregroundColor="#e2e8f0"
    />
  </div>
);
