export function isColor(val: string) {
  return val.includes("rgb(") || /#[\dA-Fa-f]{3,8}$/.test(val);
}

export function stripHtmlTags(html: string): string {
  if (!html) {
    return "";
  }
  return html.replace(/<[^>]*>/g, "");
}
