import {
  selectDraftComponentIds,
  selectEditorMode,
} from "@editor/reducers/core-reducer";
import { selectIsRightBarAnalyticsOpen } from "@editor/reducers/ui-reducer";
import { EditorMode } from "@editor/types/core-state";

import { createSelector } from "@reduxjs/toolkit";

export const selectIsRightBarVisible = createSelector(
  selectEditorMode,
  selectIsRightBarAnalyticsOpen,
  selectDraftComponentIds,
  (editorMode, isRightBarAnalyticsOpen, draftComponentIds) => {
    return (
      (editorMode === EditorMode.edit ||
        editorMode === EditorMode.aiGeneration) &&
      (draftComponentIds.length > 0 || isRightBarAnalyticsOpen)
    );
  },
);
