import type { StorePasswordRequiredModalProps } from "@editor/components/AppModalTypes";

import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import { useLazyCanvasDocumentPublisherQuery } from "@editor/reducers/api-reducer";
import {
  selectDraftElementId,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { closeModal } from "@editor/reducers/modals-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { useForm } from "react-hook-form";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { z } from "zod";

const formSchema = z.object({
  password: z.string().min(1, "Please provide your store password"),
});
type FormData = z.infer<typeof formSchema>;

export const StorePasswordRequiredModal: React.FC<
  StorePasswordRequiredModalProps
> = ({ type }) => {
  const projectId = useEditorSelector(selectProjectId);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const dispatch = useEditorDispatch();
  const [isIncorrectPassword, setIsIncorrectPassword] = useOverridableState(
    type === "wrongPassword",
  );
  const [hasAttempted, setHasAttempted] = React.useState(false);

  const [triggerPublisherCanvasDocumentQuery, { isLoading }] =
    useLazyCanvasDocumentPublisherQuery();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  // Note (Noah, 2025-03-18): Weird that we have to do an effect like this, but
  // the state of when there are actually incorrect passwords is currently updated
  // through the props of this modal
  React.useEffect(() => {
    if (!isLoading) {
      if (isIncorrectPassword && !hasAttempted) {
        setError("password", {
          message: "Your Shopify store's password was incorrect.",
        });
      }
      if (isIncorrectPassword && hasAttempted) {
        setError("password", {
          message: "That password was incorrect. Please try again.",
        });
      }
    }
  }, [isIncorrectPassword, hasAttempted, setError, isLoading]);

  const isButtonLoading = isSubmitting || isLoading;

  const onSubmit = async (data: FormData) => {
    if (isButtonLoading) {
      return;
    }
    const result = await triggerPublisherCanvasDocumentQuery({
      storeId: projectId,
      storePassword: data.password,
      draftElementId,
    });
    if (result?.data?.status === "success") {
      dispatch(closeModal({ type: "storePasswordRequiredModal" }));
    } else {
      setHasAttempted(true);
      setIsIncorrectPassword(true);
    }
  };

  return (
    <form
      data-testid="store-password-modal-form"
      onSubmit={(e) => {
        void handleSubmit(onSubmit)(e);
      }}
    >
      <Modal
        title="Store Password Required"
        isOpen={true}
        onOpenChange={(open) => {
          if (!open) {
            dispatch(closeModal({ type: "storePasswordRequiredModal" }));
          }
        }}
        size="sm"
        description="Looks like your Shopify store is password protected! Please enter your password to allow editing pages."
        footer={
          <Button
            variant="primary"
            size="base"
            type="submit"
            isLoading={isButtonLoading}
          >
            Submit Password
          </Button>
        }
      >
        <div className="flex flex-col gap-y-4 text-muted">
          <div className="typ-body-small">
            You can find this password in Shopify Admin under Sales Channels
            &gt; Online Store &gt; Preferences &gt; Password Protection.
          </div>
          <LabeledControl
            label="Store Password"
            size="sm"
            error={errors.password?.message}
          >
            <Input
              size="base"
              autoFocus={true}
              validityState={errors.password?.message ? "invalid" : undefined}
              placeholder="Password"
              {...register("password")}
            />
          </LabeledControl>
        </div>
      </Modal>
    </form>
  );
};
