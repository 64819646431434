import type { ComponentTemplatesFindQuery } from "schemas/generated/componentTemplates";

import * as React from "react";

import { trpc } from "@editor/utils/trpc";

export default function useInfiniteTemplates({
  storeId,
  componentCategoryType,
  componentCollectionId,
  searchText,
  pageSize,
  cursor,
  scope,
  includeComponent,
  selectedIndustries,
  selectedCategories,
  includeArchivedTemplates,
}: ComponentTemplatesFindQuery) {
  const queryParams = {
    storeId: storeId,
    componentCategoryType: componentCategoryType,
    componentCollectionId: componentCollectionId,
    searchText: searchText,
    pageSize: pageSize,
    cursor: cursor,
    scope: scope,
    includeComponent: includeComponent,
    selectedIndustries: selectedIndustries,
    selectedCategories: selectedCategories,
    includeArchivedTemplates: includeArchivedTemplates,
  };

  const { data, isLoading, fetchNextPage, isSuccess } =
    trpc.componentTemplates.find.useInfiniteQuery(queryParams, {
      getNextPageParam: ({ nextSkip }) => (nextSkip ? nextSkip : 0),
    });

  const templatesFromServer = React.useMemo(() => {
    return data?.pages.map((page) => page.componentTemplates).flat();
  }, [data]);
  const pagesLength = data?.pages.length ?? 0;
  const pageData = data?.pages[pagesLength ? pagesLength - 1 : 0];
  const queryHasResults = pageData?.queryHasResults;
  const hasNextPage = Boolean(pageData?.nextSkip);

  return {
    fetchNextPage,
    componentTemplatesList:
      !isLoading && templatesFromServer ? templatesFromServer : null,
    isLoading,
    isLoadingInitialPage: !templatesFromServer,
    isSuccessComponentTemplates: isSuccess,
    hasNextPage,
    queryHasResults,
  };
}
