import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { ChevronDown } from "lucide-react";

import { useCombobox } from "../hooks/useCombobox";

type ComboboxSelectionButtonProps = {
  id?: string;
  startEnhancer?: React.ReactNode;
  title: string;
  isPlaceholder?: boolean;
  placeholder?: string;
  layoutClassName?: string;
  isDisabled?: boolean;
  unsafe_className?: string;
  size?: "sm" | "base";
  variant?: "primary" | "secondary";
  validityState?: "valid" | "invalid";
  titleAlignment?: "start" | "center";
  endEnhancer?: React.ReactElement;
};

const variantClassName = {
  primary: "bg-slate-100 hover:bg-slate-200 text-slate-800",
  secondary: "bg-white hover:bg-slate-100",
};

const isActiveVariantMap = {
  primary: "bg-slate-200",
  secondary: "bg-slate-100",
};

export const ComboboxSelectionButton = React.forwardRef<
  HTMLButtonElement,
  ComboboxSelectionButtonProps
>((props, ref) => {
  const { open, setOpen } = useCombobox();
  const {
    startEnhancer,
    title,
    isPlaceholder,
    layoutClassName,
    isDisabled,
    unsafe_className,
    size = "base",
    variant = "primary",
    id,
    validityState,
  } = props;
  const endEnhancer = (() => {
    if (props.endEnhancer !== undefined) {
      return props.endEnhancer;
    }

    return (
      <ChevronDown
        size={size === "sm" ? 12 : 16}
        className="text-muted flex-shrink-0"
      />
    );
  })();

  return (
    <button
      id={id}
      className={twMerge(
        "gap-2",
        size === "sm" && "py-1 px-2 h-6 typ-body-small",
        size === "base" && "p-2 h-8 typ-body-base",
        "rounded flex overflow-hidden leading-5 items-center font-normal transition duration-300",
        endEnhancer &&
          (props.titleAlignment ?? "center") === "center" &&
          "justify-between",
        isDisabled
          ? "bg-gray-200 text-gray-500 cursor-not-allowed"
          : variantClassName[variant],
        validityState === "invalid" &&
          "bg-danger-emphasis outline outline-danger outline-1",
        // If combobox is open, make background the same as hover state
        open && isActiveVariantMap[variant],
        layoutClassName,
        unsafe_className,
      )}
      ref={ref}
      type="button"
      role="combobox"
      aria-expanded={open}
      disabled={isDisabled}
      onClick={() => setOpen(!open)}
    >
      {startEnhancer}
      <div
        className={twMerge(
          "flex truncate",
          endEnhancer &&
            props.titleAlignment === "start" &&
            "flex-1 justify-start min-w-0",
        )}
      >
        <span
          className={twMerge("truncate", isPlaceholder && "text-placeholder")}
        >
          {title}
        </span>
      </div>
      {endEnhancer}
    </button>
  );
});

ComboboxSelectionButton.displayName = "ComboboxSelectionButton";
