import * as React from "react";

import Separator from "@common/designSystem/Separator";
import { ColorStylesGrid } from "@editor/components/designLibrary/ColorSavedStyleGroup";
import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";

import Popover from "@replo/design-system/components/popover/Popover";

type ColorPickerWrapperProps = {
  trigger: React.ReactNode;
  content: React.ReactNode;
  popoverTitle?: string;
  popoverSideOffset?: number;
  popoverSide?: "top" | "right" | "bottom" | "left";
  isPopoverOpen?: boolean;
  showSavedStyles?: boolean;
  allowsGradientSelection?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  onSelectSavedStyle?: (value: string) => void;
  onInteractOutside?: (event: CustomEvent) => void;
};

const ColorPickerWrapper: React.FC<ColorPickerWrapperProps> = ({
  content,
  trigger,
  popoverTitle,
  isPopoverOpen,
  popoverSideOffset,
  popoverSide,
  showSavedStyles,
  onOpenPopoverChange,
  onSelectSavedStyle,
  onInteractOutside,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  const { colorShopStyles, isLoading, designLibrary } = useShopStyles();

  const formattedOnSelectSavedStyle = (styleId: string) => {
    onSelectSavedStyle?.(
      `{{ library.${designLibrary?.id}.styles.${styleId}.attributes.color }}`,
    );
  };

  return (
    <Popover
      title={popoverTitle}
      isOpen={isPopoverOpen}
      onOpenChange={onOpenPopoverChange}
      shouldPreventDefaultOnInteractOutside={areModalsOpen}
      sideOffset={popoverSideOffset}
      side={popoverSide}
      onInteractOutside={onInteractOutside}
      onRequestClose={() => {
        onOpenPopoverChange?.(false);
      }}
      content={
        <div className="flex flex-col gap-2 w-full">
          {content}
          {showSavedStyles && (
            <>
              <Separator />
              <div className="typ-header-small">Color Styles</div>
              <ColorStylesGrid
                colorSavedStyles={colorShopStyles}
                isLoading={isLoading}
                onColorClick={formattedOnSelectSavedStyle}
                modificationEnabled={false}
                showTooltips={true}
              />
            </>
          )}
        </div>
      }
    >
      <div
        data-testid={
          popoverTitle
            ? `${popoverTitle.toLowerCase()}-gradient-selector`
            : undefined
        }
      >
        {trigger}
      </div>
    </Popover>
  );
};

export default ColorPickerWrapper;
