/**
 * Scopes requested for app installs after this date (includes analytics scopes).
 *
 * @author Ben 2024-07-11
 */
const SHOPIFY_API_SCOPES_NORMAL = [
  "read_content",
  "read_files",
  "read_product_listings",
  "read_products",
  "read_script_tags",
  "read_themes",
  "write_content",
  "write_files",
  "write_products",
  "write_script_tags",
  "write_themes",
  // These are the additional ones for analytics.
  "read_customer_events",
  "write_pixels",
];

export const serializedScopes = {
  Normal: SHOPIFY_API_SCOPES_NORMAL.sort().join(","),
};

export function areAccessScopesEqual(a: string, b: string): boolean {
  return a.split(",").sort().join(",") === b.split(",").sort().join(",");
}
