import type { DeleteWorkspaceModalProps } from "../AppModalTypes";

import * as React from "react";

import Input from "@common/designSystem/Input";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { skipToken } from "@tanstack/react-query";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  workspaceName: string;
};

export const DeleteWorkspaceModal: React.FC<DeleteWorkspaceModalProps> = ({
  workspaceId,
}) => {
  const logEvent = useLogAnalytics();
  const { closeModal } = useModal();
  const navigate = useNavigate();

  // Note (Noah, 2025-02-22): Technically this could be a useCurrentWorkspaceId, but
  // since this modal specifically takes a workspaceId and could be used in other
  // contexts, we're fetching workspace data directly from the id here.
  const { data } = trpc.workspace.getById.useQuery(
    workspaceId ? { id: workspaceId } : skipToken,
  );
  const { mutate: deleteWorkspace, isPending: isLoading } =
    trpc.workspace.delete.useMutation({
      onSuccess: async () => {
        await trpcUtils.user.get.invalidate();
        await trpcUtils.workspace.getUserWorkspacesList.invalidate();
        toast({
          header: "Workspace deleted",
        });
        if (data?.workspace) {
          logEvent("workspace.delete", {
            workspaceId: data?.workspace.id,
            workspaceName: data?.workspace.name,
          });
        }
        navigate(routes.home.root);
        closeModal({ type: "deleteWorkspaceModal" });
      },
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      workspaceName: "",
    },
  });
  const workspaceNameValue = useWatch({ control, name: "workspaceName" });
  const hasWorkspaceNameErrors = !isEmpty(errors.workspaceName);

  const onSubmit = ({ workspaceName }: FormValues) => {
    if (data?.workspace.name === workspaceName) {
      void deleteWorkspace(data.workspace.id);
    }
  };

  return (
    <form
      onSubmit={(formData) => {
        void handleSubmit(onSubmit)(formData);
      }}
    >
      <Modal
        title="Delete Workspace"
        isOpen={true}
        onOpenChange={(open) => {
          if (!open) {
            closeModal({ type: "deleteWorkspaceModal" });
          }
        }}
        description="When you delete a workspace, all content such as referral codes and workspace members will also be deleted."
        size="base"
        footer={
          <div className="flex flex-row gap-2">
            <Button
              variant="secondary"
              size="base"
              onClick={() => closeModal({ type: "deleteWorkspaceModal" })}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              size="base"
              type="submit"
              disabled={hasWorkspaceNameErrors || !workspaceNameValue}
              isLoading={isLoading}
            >
              Delete
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col text-sm text-slate-400">
            <span>Type "{data?.workspace.name}" to confirm deletion.</span>
          </div>
          <label htmlFor="workspaceName" className="sr-only">
            Workspace name
          </label>
          <Input
            id="workspaceName"
            aria-invalid={hasWorkspaceNameErrors ? "true" : undefined}
            aria-describedby={
              hasWorkspaceNameErrors ? "error-workspace-name" : undefined
            }
            autoComplete="off"
            placeholder={data?.workspace.name}
            {...register("workspaceName", {
              required: "Please enter the workspace name.",
              validate: (value) => value === data?.workspace.name,
            })}
            type="text"
            size="base"
            validityState={hasWorkspaceNameErrors ? "invalid" : "valid"}
            autoFocus
          />
          <ErrorMessage
            id="error-workspace-name"
            error={errors.workspaceName?.message}
          />
        </div>
      </Modal>
    </form>
  );
};
