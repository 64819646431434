import type { DeleteProjectModalProps } from "../AppModalTypes";

import * as React from "react";

import Input from "@common/designSystem/Input";
import { Loader } from "@common/Loader";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { getProjectName } from "@editor/utils/project-utils";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { skipToken } from "@tanstack/react-query";
import { useForm, useWatch } from "react-hook-form";
import { generatePath } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

import LabeledControl from "../common/designSystem/LabeledControl";

type FormValues = {
  projectName: string;
};

export const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({
  projectId,
  callback,
  from,
}) => {
  const { closeModal } = useModal();
  // NOTE (Fran 2024-08-29): We don't want to use the current project context here because this will
  // depend on which project the user clicks on in the dashboard. So, we only want to get the project
  // the user needs to delete.
  const { data: project, isLoading: isLoadingProject } =
    trpc.project.get.useQuery(projectId ?? skipToken);
  const { data: projects } = trpc.project.listWithStats.useQuery(
    project?.ownerWorkspaceId
      ? { workspaceId: project.ownerWorkspaceId }
      : skipToken,
  );
  const { data: elementMetadata, isLoading: isLoadingElementMetadata } =
    trpc.element.listAllElementsMetadata.useQuery(
      projectId ? { projectId } : skipToken,
    );
  const { data: projectActiveSubscription } =
    trpc.subscriptions.getActiveSubscriptionByProject.useQuery(
      projectId ?? skipToken,
    );
  const logEvent = useLogAnalytics();
  const { mutate: deleteProject, isPending } = trpc.project.delete.useMutation({
    onSuccess: () => {
      toast({
        header: "Project deleted",
      });
      closeModal({ type: "deleteProjectModal" });
      callback?.();
      if (project?.id) {
        logEvent("project.delete", {
          projectId: project.id,
          projectName: projectNameFromData,
          from,
        });
      }
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.project.findByUserId.invalidate();
      void trpcUtils.project.listWithStats.invalidate();
      void trpcUtils.user.get.invalidate();
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      projectName: "",
    },
  });
  const projectNameValue = useWatch({ control, name: "projectName" });
  const hasProjectNameErrors = !isEmpty(errors.projectName);
  const publishedElements = elementMetadata?.filter(
    (element) => element.isPublished,
  );
  const hasPublishedElements =
    !isLoadingElementMetadata &&
    publishedElements &&
    publishedElements.length > 0;
  // NOTE (Fran 2024-05-02): There were cases where the user created a project with a space at the end
  // but is not super obvious in the UI, so we are trimming the project name to avoid this problem.
  const projectNameFromData = getProjectName(project).trim();

  const onSubmit = ({ projectName }: FormValues) => {
    if (projectNameFromData === projectName && project?.id) {
      void deleteProject({ projectId: project.id });
    }
  };

  const shopifyUrl = project?.integrations?.shopify?.store.shopifyUrl;

  const isLastProjectOnWorkspaceWithShopifySubscription = Boolean(
    projectActiveSubscription?.paymentProcessor === "shopify" &&
      shopifyUrl &&
      projects?.projects.filter(
        ({ integrations }) =>
          integrations?.shopify?.store.shopifyUrl == shopifyUrl,
      ).length === 1,
  );

  const isDeleteButtonDisabled =
    hasProjectNameErrors || !projectNameValue || hasPublishedElements;

  const onSubmitForm = (data: React.FormEvent) => {
    data.preventDefault();
    void handleSubmit(onSubmit)(data);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={onSubmitForm}>
      <Modal
        isOpen={true}
        onOpenChange={(open) => {
          if (!open) {
            closeModal({ type: "deleteProjectModal" });
          }
        }}
        title="Delete Project"
        size="base"
        description={
          !hasPublishedElements
            ? "Deleting this project will permanently erase it. The Replo Team can assist with recovery if needed."
            : "You must unpublish all live pages before deleting this project."
        }
        footer={
          <div className="flex flex-row gap-2 justify-end">
            <Button
              variant="secondary"
              size="base"
              onClick={() => closeModal({ type: "deleteProjectModal" })}
            >
              Cancel
            </Button>
            {!hasPublishedElements && !isLoadingProject && (
              <Button
                variant="danger"
                size="base"
                type="submit"
                disabled={isDeleteButtonDisabled}
                isLoading={isPending}
              >
                Delete
              </Button>
            )}
          </div>
        }
      >
        <div className="flex flex-col gap-6">
          {!hasPublishedElements &&
            isLastProjectOnWorkspaceWithShopifySubscription &&
            project?.ownerWorkspaceId && (
              <p className="typ-body-base text-default">
                Your subscription will not be affected and you can update it in
                your{" "}
                <a
                  className="font-medium typ-body-base text-primary underline"
                  href={generatePath("/workspace/:workspaceId/billing", {
                    workspaceId: project.ownerWorkspaceId,
                  })}
                >
                  Workspace Billing Settings
                </a>
                .
              </p>
            )}
          {isLoadingProject ? (
            <div className="w-16 mx-auto">
              <Loader />
            </div>
          ) : (
            <>
              {hasPublishedElements ? (
                <div className="text-sm font-semibold text-slate-700">
                  Live Pages: {publishedElements.length}
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  <div className="typ-body-base flex flex-col">
                    Type "{projectNameFromData}" to confirm deletion.
                  </div>
                  <LabeledControl
                    label="Project Name"
                    id="projectName"
                    error={errors.projectName?.message}
                  >
                    <Input
                      id="projectName"
                      aria-invalid={hasProjectNameErrors ? "true" : undefined}
                      aria-describedby={
                        hasProjectNameErrors ? "error-project-name" : undefined
                      }
                      autoComplete="off"
                      placeholder={projectNameFromData}
                      {...register("projectName", {
                        required: "Please type the name of the project.",
                        validate: (value) => value === projectNameFromData,
                        // NOTE (Fran 2024-05-02): We should clear the errors when the user is typing
                        onChange: () => clearErrors("projectName"),
                      })}
                      type="text"
                      size="base"
                      validityState={hasProjectNameErrors ? "invalid" : "valid"}
                      autoFocus
                    />
                  </LabeledControl>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
    </form>
  );
};
