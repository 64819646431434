import type { UnpublishableWarningType } from "@editor/components/header/unpublishableWarnings";

import * as React from "react";

import LabeledControl from "@common/designSystem/LabeledControl";
import Input from "@editor/components/common/designSystem/Input";
import { unpublishableWarnings } from "@editor/components/header/unpublishableWarnings";
import { useElementName } from "@editor/hooks/element/useElementName";
import { useDraftElementMetadata } from "@editor/hooks/useDraftElementMetadata";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import usePublishOnClickInfo from "@editor/hooks/usePublishingInfo";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { selectUnpublishableComponentIssues } from "@editor/reducers/core-reducer";
import { selectIsShopifyStoreClosed } from "@editor/reducers/ui-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { docs } from "@editor/utils/docs";
import { MAX_SECTION_NAME_LENGTH } from "@editor/utils/element";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { isEmpty } from "replo-utils/lib/misc";

type PublishPageButtonProps = {
  isPreviewBeforePublishView: boolean;
};

export const PublishPageButton: React.FC<PublishPageButtonProps> = ({
  isPreviewBeforePublishView,
}) => {
  const reduxStore = useEditorStore();
  const [productTemplateConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [warningsModalOpen, setWarningsModalOpen] = React.useState(false);
  const [renameModalOpen, setRenameModalOpen] = React.useState(false);
  const [renameSectionName, setRenameSectionName] = React.useState("");
  const [isRenameSaving, setIsRenameSaving] = React.useState(false);
  const isStoreClosed = useEditorSelector(selectIsShopifyStoreClosed);
  const errorToast = useErrorToast();
  const logEvent = useLogAnalytics();
  const setDraftElement = useSetDraftElement();
  const { products } = useStoreProductsFromDraftElement();
  const elementMetadata = useDraftElementMetadata();
  const { elementName, handleUpdateElementName } = useElementName({
    element: elementMetadata,
    initialName: elementMetadata?.name,
  });

  const onSelectComponentId = (componentId: string) => {
    setDraftElement({ componentIds: [componentId] });
    setWarningsModalOpen(false);
  };

  const {
    publishNotAvailableReason,
    draftElementType,
    numAssignedProducts,
    publish,
    isPublishing,
    pageEditorData,
  } = usePublishOnClickInfo();

  const publishOnClick = () => {
    if (!elementMetadata) {
      return;
    }

    const componentUnpublishableIssues = selectUnpublishableComponentIssues(
      reduxStore.getState(),
      products,
    );

    if (publishNotAvailableReason) {
      errorToast(
        publishNotAvailableReason.title,
        publishNotAvailableReason.message,
        "error.publish.unavailable",
        {
          publishNotAvailableReasonTitle: publishNotAvailableReason.title,
          publishNotAvailableReasonMessage: publishNotAvailableReason.message,
        },
      );
      return;
    }
    if (!isEmpty(componentUnpublishableIssues)) {
      setWarningsModalOpen(true);
    } else if (
      draftElementType === "shopifyProductTemplate" &&
      numAssignedProducts > 0
    ) {
      setConfirmationModalOpen(true);
    } else if (
      elementMetadata.type === "shopifySection" &&
      !elementMetadata.publishedAt
    ) {
      setRenameModalOpen(true);
    } else {
      publish();
    }
  };

  const handleRenameAndPublish = async (newName: string) => {
    if (elementName !== newName) {
      await handleUpdateElementName(newName);
    }
    publish();
    setRenameModalOpen(false);
  };

  const handleSubmitRename = async () => {
    setIsRenameSaving(true);
    try {
      await handleRenameAndPublish(renameSectionName);
    } finally {
      setIsRenameSaving(false);
    }
  };

  React.useEffect(() => {
    if (elementName) {
      setRenameSectionName(elementName);
    }
  }, [elementName]);

  return (
    <>
      <div className="flex flex-1">
        <Button
          layoutClassName="w-full"
          onClick={() => {
            if (isPreviewBeforePublishView) {
              logEvent("header.publish", {
                buttonLocation: "preview",
              });
            }
            publishOnClick();
          }}
          /**
           * Note (Noah, 2022-12-18): We specifically don't want the button to show
           * as disabled while an update to the element is saving, because it's really
           * distracting if it changes between blue and gray all the time when you're
           * editing the page. Instead, we bail out early in onClick if that's the case
           */
          disabled={isPublishing || isStoreClosed}
          variant={isPublishing ? "secondary" : "primary"}
          size="base"
          tooltipText={`Publish ${pageEditorData.singularDisplayName} to Shopify`}
          isLoading={isPublishing}
          id="publish-button"
          data-testid="publish-button"
        >
          {`Publish ${pageEditorData.singularDisplayName}`}
        </Button>
      </div>
      <Modal
        isOpen={productTemplateConfirmationModalOpen}
        onOpenChange={(open) => {
          if (!open) {
            setConfirmationModalOpen(false);
          }
        }}
        title="Publish Product Template?"
        size="sm"
        description={`After publishing, this Product Template will apply to the ${numAssignedProducts > 1 ? numAssignedProducts : ""} assigned product${numAssignedProducts > 1 ? "s" : ""} on your live store.`}
        footer={
          <div className="flex flex-row gap-2">
            <Button
              variant="secondary"
              size="base"
              type="button"
              onClick={() => setConfirmationModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="base"
              type="button"
              onClick={() => {
                publish();
                setConfirmationModalOpen(false);
              }}
            >
              Publish Product Template
            </Button>
          </div>
        }
      />
      <Modal
        isOpen={warningsModalOpen}
        onOpenChange={(open) => {
          if (!open) {
            setWarningsModalOpen(false);
          }
        }}
        title="Publishing Errors"
        size="base"
        footer={
          <Button
            variant="primary"
            size="base"
            type="button"
            onClick={() => {
              publish();
              setWarningsModalOpen(false);
            }}
          >
            Publish Anyway
          </Button>
        }
      >
        <UnpublishableComponentsWarningContent
          onSelectComponentId={onSelectComponentId}
        />
      </Modal>
      <Modal
        isOpen={renameModalOpen}
        onOpenChange={(open) => {
          if (!open) {
            setRenameModalOpen(false);
          }
        }}
        title="Publish with this name?"
        size="sm"
        footer={
          <Button
            variant="primary"
            size="base"
            onClick={() => void handleSubmitRename()}
            isLoading={isRenameSaving}
            disabled={renameSectionName.length > MAX_SECTION_NAME_LENGTH}
          >
            Save and Publish
          </Button>
        }
      >
        <RenameSectionModalContent
          sectionName={renameSectionName}
          setSectionName={setRenameSectionName}
        />
      </Modal>
    </>
  );
};

const UnpublishableComponentsWarningContent = ({
  onSelectComponentId,
}: {
  onSelectComponentId: (componentId: string) => void;
}) => {
  const { products } = useStoreProductsFromDraftElement();
  const componentUnpublishableIssues = useEditorSelector((state) =>
    selectUnpublishableComponentIssues(state, products),
  );
  const processedIssueTypes = new Set();
  return (
    <>
      {componentUnpublishableIssues.flatMap(
        (componentUnpublishableIssue, i) => {
          return componentUnpublishableIssue.issues.map((issue) => {
            if (processedIssueTypes.has(issue.type)) {
              return null;
            }
            processedIssueTypes.add(issue.type);
            const issueData =
              unpublishableWarnings[issue.type as UnpublishableWarningType];
            return (
              <div key={issue.type}>
                {i > 0 && <hr className="h-px my-2 bg-gray-200 border-0" />}
                <h2 className="text-base font-medium">{issueData?.title}</h2>
                <p className="mb-4">{issueData?.text}</p>
                <ul className="list-disc list-inside mb-4">
                  {componentUnpublishableIssues
                    .filter((component) =>
                      component.issues.some((iss) => iss.type === issue.type),
                    )
                    .map(({ componentId, componentData }) => (
                      <li key={componentId} className="mt-2 pl-4">
                        <button
                          onClick={() => onSelectComponentId(componentId)}
                          className="underline text-blue-600 hover:text-blue-800"
                        >
                          {componentData.label}
                        </button>
                      </li>
                    ))}
                </ul>
                {issueData?.solution && (
                  <p>
                    <b>Solution: </b>
                    {issueData?.solution}
                  </p>
                )}
              </div>
            );
          });
        },
      )}
    </>
  );
};

const RenameSectionModalContent = ({
  sectionName,
  setSectionName,
}: {
  sectionName: string;
  setSectionName: (name: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="typ-body-base">
        This section's name will show in the Shopify Theme Customizer. You will
        not be able to rename this section after you publish it.{" "}
        <a
          href={docs.warnings.cannotRenameSection}
          target="_blank"
          className="underline"
          rel="noreferrer"
        >
          Learn More
        </a>
      </p>

      <LabeledControl
        label="Section Name"
        id="save-section-name"
        error={
          sectionName.length > MAX_SECTION_NAME_LENGTH
            ? `Section name must be less than ${MAX_SECTION_NAME_LENGTH} characters`
            : undefined
        }
      >
        <Input
          size="base"
          autoFocus
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          placeholder="Enter section name..."
        />
      </LabeledControl>
    </div>
  );
};
