import * as React from "react";

import { Root, Thumb } from "@radix-ui/react-switch";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";

import twMerge from "../../utils/twMerge";

type SwitchProps = {
  isOn?: boolean;
  onChange?(isOn: boolean): void;
  trackClassName?: string;
  thumbClassName?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  tooltipText?: string | null;
  id?: string;
  size: "base" | "sm";
  "aria-label"?: string;
  "aria-labelledby"?: string;
  "aria-describedby"?: string;
};
const Switch = ({
  isOn,
  onChange,
  trackClassName,
  thumbClassName,
  style,
  disabled,
  tooltipText,
  size,
  id,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
  "aria-describedby": ariaDescribedBy,
  ...props
}: SwitchProps) => {
  const transition =
    size === "base" ? "translate-x-[20px]" : "translate-x-[16px]";

  return (
    <Tooltip content={tooltipText} triggerAsChild>
      <div tabIndex={disabled ? 0 : undefined}>
        <Root
          id={id}
          disabled={disabled ?? false}
          defaultChecked={isOn}
          className={twMerge(
            "relative flex items-center rounded-full shrink-0 cursor-pointer transition-colors",
            size === "base" && "h-[24px] w-[42px]",
            size === "sm" && "h-[18px] w-[32px]",
            isOn ? "bg-primary" : "bg-light-surface",
            disabled && "opacity-50 cursor-not-allowed",
            trackClassName,
          )}
          style={{ ...style }}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedBy}
        >
          <Thumb asChild>
            <div
              {...props}
              className={twMerge(
                "block rounded-full bg-white shadow-thumb transition-transform duration-200",
                size === "base" && "h-[20px] w-[20px]",
                size === "sm" && "h-[14px] w-[14px]",
                isOn ? transition : "translate-x-[2px]",
                thumbClassName,
              )}
            />
          </Thumb>
        </Root>
      </div>
    </Tooltip>
  );
};

export default Switch;
