import "../index.scss";

import * as React from "react";

import { AuthLayout } from "@components/account/AuthLayout";
import AccountDashboard from "@components/account/Dashboard";
import ProfileBoard from "@components/account/Dashboard/ProfileBoard";
import SecurityBoard from "@components/account/Dashboard/SecurityBoard";
import InitResetPassword from "@components/account/InitResetPassword";
import LoginForm from "@components/account/LoginForm";
import ResetPassord from "@components/account/ResetPasswordForm";
import SignupForm from "@components/account/SignupForm";
import { FullScreenLoader } from "@components/common/FullScreenLoader";
import SentryBoundary from "@components/common/SentryBoundary";
import DashboardHome from "@components/dashboard/DashboardHome";
import ShopifyIntegrationSettings from "@components/dashboard/integrations/ShopifyIntegrationSettings";
import WorkspaceIntegrations from "@components/dashboard/integrations/WorkspaceIntegrations";
import { PartnerProgramLPContent } from "@components/dashboard/partner/partner-program-v2-lp-components/PartnerProgramLP";
import { PartnershipReferralBoard } from "@components/dashboard/partner/PartnershipReferralBoard";
import PartnershipDashboard from "@components/dashboard/PartnershipDashboard";
import AllProjects from "@components/dashboard/projects/AllProjects";
import NewProjectFlow from "@components/dashboard/projects/NewProjectFlow";
import { FlowsLayout } from "@components/flows/FlowsLayout";
import { FlowsProvider } from "@components/flows/FlowsProvider";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import AuthenticatedUserRequiredRoute from "@components/router/AuthenticatedUserRequiredRoute";
import ReferralCodeRedirectRoute from "@components/router/ReferralCodeRedirectRoute";
import { SharedTemplateDetailsModal } from "@editor/components/templateLibrary/SharedTemplateDetailsModal";
import CurrentProjectProvider from "@editor/contexts/CurrentProjectContext";
import { CurrentUserProvider } from "@editor/contexts/CurrentUserContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { store } from "@editor/store";
import { TRPCProvider } from "@editor/utils/trpc";
import { LocalStorageProvider } from "@hooks/useLocalStorage";
import { AIStreamingProvider } from "@providers/AIStreamingProvider";
import { addReferralToUrl, routes } from "@utils/router";
import { trpc } from "@utils/trpc";

import { AnalyticsOverview } from "@/features/analytics/AnalyticsOverview";
import { AnalyticsRedirect } from "@/features/analytics/AnalyticsRedirect";
import AnalyticsInsights from "@/features/analytics/insights/AnalyticsInsights";
import { InsightsRedirect } from "@/features/analytics/insights/InsightsRedirect";
import PageDetails from "@/features/analytics/PageDetails";
import WorkspaceAnalyticsDashboard from "@/features/analytics/WorkspaceAnalyticsDashboard";
import ExperimentEditWrapper from "@/features/experiments/ExperimentEditWrapper";
import Experiments from "@/features/experiments/Experiments";
import { ExperimentsRedirect } from "@/features/experiments/ExperimentsRedirect";
import { ExperimentSettingsTab } from "@/features/experiments/ExperimentsSettingsTab";
import ExperimentDetailsTabV2 from "@/features/experiments/tabs/ExperimentDetailsTab";
import ExperimentResultsTab from "@/features/experiments/tabs/ExperimentResultsTab";
import Billing from "@/features/workspace/Billing";
import Members from "@/features/workspace/Members";
import WorkspaceSettings from "@/features/workspace/Settings";
import { WorkspaceBillingRedirectRoute } from "@/features/workspace/WorkspaceBillingRedirectRoute";
import WorkspaceDashboard from "@/features/workspace/WorkspaceDashboard";
import WorkspaceProjects from "@/features/workspace/WorkspaceProjects";
import { CookiesProvider } from "react-cookie";
import { Provider as ReduxProvider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useOnValueChange } from "replo-utils/react/use-on-value-change";

import AppModals from "../components/AppModals";
import { PartnerOffersFrame } from "../components/dashboard/PartnerOffersFrame";
import { BrandDetailsProvider } from "../components/designLibrary/BrandDetailsContext";
import {
  useCurrentWorkspaceContext,
  useCurrentWorkspaceId,
} from "../contexts/WorkspaceDashboardContext";
import { useModal } from "../hooks/useModal";
import ElementEditorLayout from "./ElementEditorLayout";
import TemplateLibraryEditorLayout from "./TemplateLibraryEditorLayout";

export function App() {
  return (
    <SentryBoundary>
      <ReduxProvider store={store}>
        <TRPCProvider>
          <RouterProvider router={router} />
        </TRPCProvider>
      </ReduxProvider>
    </SentryBoundary>
  );
}

const router = (() => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RootRoute />}>
        {/* Index redirect route */}
        <Route index element={<RootRouteIndexRedirectRoute />} />
        {/* Auth routes */}
        <Route path="/auth" element={<AuthLayout />}>
          <Route index element={<Navigate to={routes.signup} replace />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="signup" element={<SignupForm />} />
          <Route path="password/reset/init" element={<InitResetPassword />} />
          <Route path="password/reset" element={<ResetPassord />} />
        </Route>
        {/* Redirect routes */}
        <Route
          path="/signup/:referralCode"
          element={<ReferralCodeRedirectRoute />}
        />
        <Route
          path="/partner/program"
          element={<PartnerProgramRedirectRoute />}
        />
        <Route
          path="/workspace/billing"
          element={<WorkspaceBillingRedirectRoute />}
        />

        {/* Project routes */}
        <Route
          path="/project"
          element={
            <AuthenticatedUserRequiredRoute>
              <AllProjects>
                <AppModals />
              </AllProjects>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route path="new" element={<NewProjectFlow />} />
        </Route>

        <Route
          path={routes.allProjects}
          element={
            <AuthenticatedUserRequiredRoute>
              <AllProjects>
                <AppModals />
              </AllProjects>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route path="project/new" element={<NewProjectFlow />} />
        </Route>

        {/* Dashboard routes */}
        <Route
          path={routes.home.root}
          element={
            <AuthenticatedUserRequiredRoute>
              <DashboardHome>
                <AppModals />
              </DashboardHome>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route
            path={routes.home.shareTemplate}
            element={
              <AuthenticatedUserRequiredRoute>
                <SharedTemplateDetailsModal />
              </AuthenticatedUserRequiredRoute>
            }
          />
        </Route>

        {/* Legacy settings routes */}
        <Route
          path="/settings"
          element={
            <AuthenticatedUserRequiredRoute>
              <AccountDashboard>
                <AppModals />
              </AccountDashboard>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route path="profile" element={<ProfileBoard />} />
          <Route path="security" element={<SecurityBoard />} />
        </Route>
        {/* Workspace routes */}
        <Route
          path="/workspace/:workspaceId"
          element={
            <AuthenticatedUserRequiredRoute>
              <WorkspaceDashboard>
                <AppModals />
              </WorkspaceDashboard>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route index element={<WorkspaceIndexRedirectRoute />} />
          <Route path="projects" element={<WorkspaceProjects />} />
          <Route
            path="project"
            element={
              <AuthenticatedUserRequiredRoute>
                <WorkspaceProjects>
                  <AppModals />
                </WorkspaceProjects>
              </AuthenticatedUserRequiredRoute>
            }
          >
            <Route path="new" element={<NewProjectFlow />} />
          </Route>

          <Route path="members" element={<Members />} />
          <Route path="billing" element={<Billing />} />
          <Route path="partner" element={<PartnershipDashboard />}>
            <Route path="program" element={<PartnerProgramLPContent />} />

            <Route
              path="home"
              element={
                <ActivePartnershipRequiredRoute>
                  <PartnershipReferralBoard />
                </ActivePartnershipRequiredRoute>
              }
            />
          </Route>
          <Route path="integrations" element={<WorkspaceIntegrations />} />
          <Route
            path="integrations/shopify"
            element={<ShopifyIntegrationSettings />}
          />
          <Route path="analytics" element={<WorkspaceAnalyticsDashboard />}>
            <Route path="overview" element={<AnalyticsOverview />} />
            <Route path="deep-dive" element={<PageDetails />} />
            <Route path="insights" element={<AnalyticsInsights />} />
          </Route>
          <Route path="abtesting" element={<Experiments />} />
          <Route
            path="abtesting/:experimentId"
            element={<ExperimentEditWrapper />}
          >
            <Route
              path={routes.workspace.experiments.details}
              element={<ExperimentDetailsTabV2 />}
            />
            <Route
              path={routes.workspace.experiments.results}
              element={<ExperimentResultsTab />}
            />
          </Route>
          <Route
            path={routes.workspace.experiments.settings}
            element={<ExperimentSettingsTab />}
          />
          <Route path="settings" element={<WorkspaceSettings />} />
          <Route path={routes.growthAudits} element={<PartnerOffersFrame />} />
        </Route>
        {/* Analytics workspace agnostic route */}
        <Route path="/analytics" element={<AnalyticsRedirect />} />
        {/* Experiments workspace agnostic route */}
        <Route path="/abtesting" element={<ExperimentsRedirect />} />
        {/* Insights workspace agnostic route */}
        <Route path="/analytics/insights" element={<InsightsRedirect />} />
        {/* Editor routes */}
        <Route path="/editor/:projectId" element={<ProjectRouteWrapper />}>
          <Route
            path="new"
            element={
              <TemplateLibraryEditorLayout>
                <AppModals />
              </TemplateLibraryEditorLayout>
            }
          />
          <Route
            path=":elementId"
            element={
              <ElementEditorLayout>
                <AppModals />
              </ElementEditorLayout>
            }
          />
        </Route>

        {/* Flow routes */}
        <Route
          path="/flows/:flowSlug"
          element={
            <AuthenticatedUserRequiredRoute>
              <FlowsLayout />
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route
            path=":flowStepId"
            element={
              <AuthenticatedUserRequiredRoute>
                <FlowsLayout />
              </AuthenticatedUserRequiredRoute>
            }
          />
        </Route>
        {/*  404 redirect route */}
        <Route path="*" element={<Navigate to={routes.home.root} replace />} />
      </Route>,
    ),
  );
})();

function RootRoute() {
  return (
    <RootRouteProviders>
      <RootRouteLayout>
        <Outlet />
      </RootRouteLayout>
    </RootRouteProviders>
  );
}

function RootRouteProviders({ children }: React.PropsWithChildren) {
  return (
    // NOTE (Martin, 2024-10-09): We need an extra SentryBoundary component
    // here so that Sentry can catch errors inside the routing tree before
    // the React Router Boundary does it.
    <SentryBoundary>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <LocalStorageProvider>
          <CurrentUserProvider>
            <AIStreamingProvider>
              <FlowsProvider>
                <BrandDetailsProvider>{children}</BrandDetailsProvider>
              </FlowsProvider>
            </AIStreamingProvider>
          </CurrentUserProvider>
        </LocalStorageProvider>
      </CookiesProvider>
    </SentryBoundary>
  );
}

function RootRouteLayout({ children }: React.PropsWithChildren) {
  const { isLoading: areFlowsLoading } = useGetCurrentFlow();
  const { closeModalsWithExclusions } = useModal();

  const referral = useReferralFromSearchParams();
  const { pathname } = useLocation();
  const { isLoading: isLoadingUser, isAuthenticated } = useCurrentUser();

  useOnValueChange(pathname, () => {
    closeModalsWithExclusions({
      typesToExclude: [
        "deleteSwatchConfirmationModal",
        "deleteElementConfirmationModal",
      ],
    });
  });

  // NOTE (Martin, 2024-10-03): if we are not in auth routes and the user is not
  // authenticated, we redirect to the signup page.
  if (!pathname.includes("/auth") && !isLoadingUser && !isAuthenticated) {
    return <Navigate to={addReferralToUrl(routes.signup, referral)} replace />;
  }

  // NOTE (Fran 2024-10-25): We must wait until the flows are loaded to know if the user has an
  // incomplete onboarding flow.
  if (isLoadingUser || areFlowsLoading) {
    return <FullScreenLoader />;
  }

  return children;
}

function RootRouteIndexRedirectRoute() {
  const templateId = useTemplateIdFromSearchParams();
  const referral = useReferralFromSearchParams();
  const { data: workspaceData, isLoading: isLoadingWorkspaces } =
    trpc.workspace.getUserWorkspacesList.useQuery();

  if (isLoadingWorkspaces) {
    return <FullScreenLoader />;
  }

  if (templateId) {
    return (
      <Navigate
        to={generatePath(routes.home.shareTemplate, { templateId })}
        replace
      />
    );
  }

  const userHasOnlyOneWorkspace =
    (workspaceData?.workspaces ?? []).length === 1;
  const toUrl =
    userHasOnlyOneWorkspace && workspaceData?.workspaces![0]!.id
      ? generatePath("/workspace/:workspaceId/projects", {
          workspaceId: workspaceData.workspaces[0].id,
        })
      : routes.home.root;

  return <Navigate to={addReferralToUrl(toUrl, referral)} replace />;
}

function PartnerProgramRedirectRoute() {
  const { user } = useCurrentUser();
  return (
    <Navigate
      to={
        user!.workspace?.id
          ? generatePath("/workspace/:workspaceId/partner/program", {
              workspaceId: user!.workspace.id,
            })
          : routes.home.root
      }
      replace
    />
  );
}

function ActivePartnershipRequiredRoute({
  children,
}: {
  children: JSX.Element;
}) {
  const { workspace, isLoading: isLoadingWorkspace } =
    useCurrentWorkspaceContext();

  if (!isLoadingWorkspace && !workspace?.isOnboarded) {
    return (
      <Navigate
        to={
          workspace?.id
            ? generatePath("/workspace/:workspaceId/partner/program", {
                workspaceId: workspace?.id,
              })
            : // NOTE (Fran 2023-11-21): Fallback to home if user doesn't have a workspace
              routes.home.root
        }
        replace
      />
    );
  }

  return children;
}

function WorkspaceIndexRedirectRoute() {
  const workspaceId = useCurrentWorkspaceId();
  return (
    <Navigate
      to={
        workspaceId
          ? generatePath(routes.workspace.projects, {
              workspaceId,
            })
          : routes.home.root
      }
      replace
    />
  );
}

function useTemplateIdFromSearchParams() {
  const [searchParams] = useSearchParams();
  return searchParams.get("templateId");
}

function useReferralFromSearchParams() {
  const [searchParams] = useSearchParams();
  return searchParams.get("referral");
}

function ProjectRouteWrapper() {
  const { projectId } = useParams<{ projectId: string }>();

  if (!projectId) {
    return <Navigate to={routes.home.root} replace />;
  }

  return (
    <CurrentProjectProvider
      projectId={projectId}
      navigateToDefaultElementAfterFetch
    >
      <Outlet />
    </CurrentProjectProvider>
  );
}
