import * as React from "react";

import ProjectsTable from "@editor/components/dashboard/projects/ProjectsTable";
import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { Outlet, useNavigate } from "react-router-dom";

const WorkspaceProjects: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { workspace, isLoading: isLoadingWorkspace } =
    useCurrentWorkspaceContext();
  const workspaceId = workspace?.id;
  const { data, isLoading } = trpc.project.listWithStats.useQuery(
    workspaceId ? { workspaceId } : skipToken,
    {
      refetchOnMount: true,
    },
  );

  return (
    <>
      <ProjectsTable
        title={!isLoading ? `${workspace?.name} Projects` : null}
        subtitle={`These are the projects within ${workspace?.name}`}
        projects={data?.projects}
        isLoading={isLoading || isLoadingWorkspace}
        buttonLabel="New Replo Project"
        onButtonClick={() => navigate("../project/new")}
      />
      {children}
      <Outlet />
    </>
  );
};

export default WorkspaceProjects;
