import type { RGBColor } from "react-color";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import React from "react";

import { PickerComponent } from "@common/designSystem/color/CustomPicker";
import Input from "@common/designSystem/Input";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import {
  getAutoCompletedColor,
  getFormattedColor,
  getFormattedColorWithoutOpacity,
  getFormattedOpacity,
  getHex8Color,
  getHexColor,
  getInitialColorValue,
  getRGB,
} from "@editor/utils/colors";

import { Badge } from "@replo/design-system/components/badge/Badge";
import debounce from "lodash-es/debounce";
import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { isNotNullish } from "replo-utils/lib/misc";

import PageColors from "./PageColors";

type SolidColorPickerProps = {
  value: string | ReploMixedStyleValue | null;
  onChange: (value: string | null) => void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  onPreviewChange?(value: string | null): void;
  showShopStyles?: boolean;
};

const SolidColorPicker: React.FC<SolidColorPickerProps> = ({
  value,
  onChange,
  onDragStart,
  onDragEnd,
  onPreviewChange,
  showShopStyles = true,
}) => {
  const { colorShopStyles: colorSavedStyles } = useShopStyles();
  const savedStyleColor =
    value && !isMixedStyleValue(value) && isDynamicDesignLibraryValue(value)
      ? getSavedStyleValue(colorSavedStyles, value)?.attributes.color
      : null;

  const [color, setColor] = React.useState<RGBColor>(
    getInitialColorValue(
      !isMixedStyleValue(value) ? savedStyleColor ?? value : null,
    ),
  );

  const formattedColor = getFormattedColor(getHexColor(color));
  const [inputColorValue, setInputColorValue] = React.useState(formattedColor);
  const [lastValidColorValue, setLastValidColorValue] =
    React.useState(formattedColor);

  const _debouncedOnChange = React.useMemo(() => {
    return debounce((value: string | null) => {
      onChange?.(value);
    }, 300);
  }, [onChange]);

  const submitChange = React.useCallback(
    (opts: { value: string | null; changeType: "preview" | "submit" }) => {
      const { value, changeType } = opts;
      onPreviewChange?.(value);
      if (changeType === "submit") {
        _debouncedOnChange(value);
      }
    },
    [onPreviewChange, _debouncedOnChange],
  );

  /**
   * Internal onChange function that should be called whenever anything changes. Does things based
   * on the provided change type - "preview" will call onPreviewChange, and "submit" will call the main
   * onChange callback (debounced).
   *
   * TODO (Noah, 2024-11-15): This code is super old and really convoluted - you can tell by how every
   * time it's called, one argument is different and the other two are the current state values. This
   * should really be a useReducer.
   */
  const _internalOnChange = React.useCallback(
    (opts: { color: RGBColor; changeType: "preview" | "submit" }) => {
      const { color, changeType } = opts;
      const hex8Color = getHex8Color(color);
      const newColor = getFormattedColor(hex8Color);
      const formattedColor = getFormattedColor(hex8Color);
      setInputColorValue(getFormattedColorWithoutOpacity(formattedColor) ?? "");
      setLastValidColorValue(formattedColor);
      setColor(color);
      submitChange({ value: newColor, changeType });
    },
    [submitChange],
  );

  const _pickerOnChange = React.useCallback(
    (rgb: RGBColor) => {
      _internalOnChange({ color: rgb, changeType: "preview" });
      setColor(rgb);
    },
    [_internalOnChange],
  );

  const handleInputColorChange = React.useCallback(() => {
    const completeColor = getAutoCompletedColor(inputColorValue);

    if (completeColor?.toLowerCase() === lastValidColorValue?.toLowerCase()) {
      return;
    }

    if (completeColor) {
      const colorValue = getFormattedColor(completeColor);
      const rgbColor = { ...getRGB(colorValue), a: color.a };
      setColor(rgbColor);
      setLastValidColorValue(colorValue);
      _internalOnChange({
        color: rgbColor,

        changeType: "submit",
      });
    } else if (lastValidColorValue) {
      setInputColorValue(
        getFormattedColorWithoutOpacity(lastValidColorValue) ?? "",
      );
    }
  }, [lastValidColorValue, inputColorValue, _internalOnChange, color]);

  const handleInputOpacityChange = React.useCallback(
    (value: string) => {
      const rgbColor = {
        ...color,
        a: Number.parseInt(value.replace("%", ""), 10) / 100,
      };
      setColor(rgbColor);
      _internalOnChange({
        color: rgbColor,
        changeType: "submit",
      });
    },
    [color, _internalOnChange],
  );

  const getOpacity = () => {
    if (isNotNullish(color.a)) {
      return getFormattedOpacity(color.a);
    }
    return "100%";
  };

  const opacity = getOpacity();

  const _onDragEnd = React.useCallback(
    (e: React.MouseEvent) => {
      _internalOnChange({ color, changeType: "submit" });
      onDragEnd?.(e);
    },

    [_internalOnChange, color, onDragEnd],
  );

  const onChangeInputColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputColorValue(e.target.value);
  };

  const isMixedValue = isMixedStyleValue(value);

  return (
    <div className="flex flex-col gap-2">
      <PickerComponent
        color={!isMixedValue ? color : undefined}
        onDragStart={onDragStart}
        onDragEnd={_onDragEnd}
        onChange={({ rgb }) => _pickerOnChange(rgb)}
        isMixedStyleValue={isMixedValue}
      />
      <div className="flex w-full gap-2">
        <Input
          value={!isMixedStyleValue(value) ? inputColorValue : "Mixed"}
          placeholder="#000000"
          onChange={onChangeInputColor}
          onKeyDown={(e) => e.stopPropagation()}
          startEnhancer={
            <Badge
              type={isMixedStyleValue(value) ? "unknown" : "color"}
              isFilled
              backgroundColor={inputColorValue ?? ""}
            />
          }
          onBlur={handleInputColorChange}
          onEnter={handleInputColorChange}
          autoFocus={false}
        />
        <LengthInputSelector
          field="opacity"
          placeholder="100%"
          metrics={["%"]}
          onChange={handleInputOpacityChange}
          className="w-16"
          value={opacity}
          allowsNegativeValue={false}
          minValues={{ "%": 0 }}
          maxValues={{ "%": 100 }}
        />
      </div>
      {showShopStyles && (
        <PageColors
          onDocumentColorChange={(value) => {
            _internalOnChange({
              color: getRGB(value),
              changeType: "submit",
            });
          }}
        />
      )}
    </div>
  );
};

export default SolidColorPicker;
