import * as React from "react";

import { normalizeFontFamily } from "@editor/components/editor/page/element-editor/components/modifiers/utils";
import { selectSrcDocFonts } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { FONT_WEIGHT_OPTIONS } from "replo-runtime/shared/utils/font";

// NOTE (Jackson, 2025-02-24): We use the user's store as a primary source of truth
// for which font weights are available for given font families. However, some fonts
// may not be present in a user's store, in which case we access document.fonts
// to get the available weights after we've loaded the font asset into the editor
// (ex: google fonts).
export const calculateFontWeights = (
  srcDocFonts: {
    family: string;
    weights: string[] | null;
  }[],
  fontFamily: string | null,
  isSafeOrNotAvailableFont?: boolean,
) => {
  const defaultWeightOptions = FONT_WEIGHT_OPTIONS.map(({ label, value }) => ({
    label,
    value: value.toString(),
  }));

  if (!fontFamily || isSafeOrNotAvailableFont) {
    return defaultWeightOptions;
  }

  // 1. First try srcDocFonts as primary source
  const matchingFonts = srcDocFonts.filter(
    (font) => normalizeFontFamily(fontFamily) === font.family,
  );

  const availableWeights = new Set<string>();
  if (matchingFonts.length > 0) {
    matchingFonts.forEach((font) => {
      if (font.weights) {
        font.weights.forEach((weight) => {
          availableWeights.add(weight);
        });
      }
    });
  }

  if (availableWeights.size > 0) {
    return FONT_WEIGHT_OPTIONS.filter((fontWeight) =>
      availableWeights.has(fontWeight.value.toString()),
    ).map(({ label, value }) => ({
      label,
      value: value.toString(),
    }));
  }

  // 2. For fonts that are not already present in the users store, we need
  // to access document.fonts after we've loaded the font asset into the editor
  const editorFontsArray = Array.from(document.fonts);
  const fontsMatchingFamily = editorFontsArray.filter((font) =>
    fontFamily?.includes(font.family ?? ""),
  );

  const availableFontWeights = FONT_WEIGHT_OPTIONS.filter((fontWeight) => {
    return fontsMatchingFamily.some((font) => {
      const fontWeightValue = font.weight === "normal" ? "400" : font.weight;
      return fontWeightValue === fontWeight.value.toString();
    });
  });

  const filteredOptions = availableFontWeights.map(({ label, value }) => ({
    label,
    value: value.toString(),
  }));

  if (filteredOptions.length > 0) {
    return filteredOptions;
  }

  return defaultWeightOptions;
};

// NOTE (Fran 2025-01-28): We don't get the fontFamily from the component because
// the fontFamily could came from the canvas or from the design library.
export const useFontWeightOptions = (fontFamily: string | null) => {
  // NOTE (Sebas, 2023-03-27): This counter is required to avoid
  // an infinite loop in case the font is not found on the document.
  // This can happen if the user selects a safe font, like Arial,
  // or if the font is not being imported correctly.
  const intervalCounter = React.useRef(0);
  const srcDocFonts = useEditorSelector(selectSrcDocFonts);
  const [options, setOptions] = React.useState(
    calculateFontWeights(
      srcDocFonts.map(({ family, weights }) => ({ family, weights })),
      fontFamily,
      false,
    ),
  );

  // NOTE (Sebas, 2023-03-21): We need this effect because we need a timeout in case the
  // user selects a new font we need to wait for the font to be downloaded/added to the
  // DOM and then calculate the available weights for that font.
  React.useEffect(() => {
    const interval = setInterval(() => {
      const newOptions = calculateFontWeights(
        srcDocFonts.map(({ family, weights }) => ({ family, weights })),
        fontFamily,
        intervalCounter.current > 5,
      );
      intervalCounter.current += 1;
      if (newOptions.length > 0) {
        // NOTE (Fran 2025-01-07): We only want to set the options if the new options are not empty
        // because we don't want to show an error until the font is downloaded to the DOM.
        setOptions(newOptions);
        clearInterval(interval);
        intervalCounter.current = 0;
      }
    }, 100);
    return () => {
      clearInterval(interval);
      intervalCounter.current = 0;
    };
  }, [fontFamily, srcDocFonts]);

  return options;
};
