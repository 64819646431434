import type { MenuItem } from "@replo/design-system/components/menu/Menu";
import type { Asset } from "schemas/generated/asset";

import * as React from "react";

import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { analytics } from "@editor/infra/analytics";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import Label from "@replo/design-system/components/label/Label";
import { Menu } from "@replo/design-system/components/menu/Menu";
import Popover from "@replo/design-system/components/popover/Popover";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import twMerge from "@replo/design-system/utils/twMerge";
import { format } from "date-fns";
import { BsThreeDots } from "react-icons/bs";
import { LuArrowUp, LuImageOff, LuPenLine, LuTrash } from "react-icons/lu";
import { convertToReploSizedImageSource } from "replo-utils/lib/assets";
import { exhaustiveSwitch, formatFileSize } from "replo-utils/lib/misc";

import AssetCardDragOverlay from "./AssetCardDragOverlay";
import AssetCardRenameForm from "./AssetCardRenameForm";

const PREVIEW_WIDTH = 200;
const PREVIEW_HEIGHT = 300;

// TODO (Gabe 2025-02-19): Do we need this?
type AssetCardState = {
  isPopoverOpen: boolean;
  isMenuOpen: boolean;
  actionPopoverContent: "delete" | "rename" | null;
};

type AssetCardAction =
  | { type: "setIsPopoverOpen"; payload: boolean }
  | { type: "setIsMenuOpen"; payload: boolean }
  | { type: "setActionPopoverContent"; payload: "delete" | "rename" | null };

const initialState: AssetCardState = {
  isPopoverOpen: false,
  isMenuOpen: false,
  actionPopoverContent: null,
};

const reducer = (
  state: AssetCardState,
  action: AssetCardAction,
): AssetCardState => {
  switch (action.type) {
    case "setIsPopoverOpen":
      return { ...state, isPopoverOpen: action.payload };
    case "setIsMenuOpen":
      return { ...state, isMenuOpen: action.payload };
    case "setActionPopoverContent":
      return { ...state, actionPopoverContent: action.payload };
    default:
      return state;
  }
};

type AssetCardProps = {
  asset: Asset;
  onClick?: () => void;
  shouldAllowActions?: boolean;
  shouldAllowDrag?: boolean;
  isUploading?: boolean;
  className?: string;
};

const AssetCard: React.FC<AssetCardProps> = ({
  asset,
  onClick,
  shouldAllowActions = true,
  shouldAllowDrag = true,
  isUploading = false,
  className,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { isPopoverOpen, isMenuOpen, actionPopoverContent } = state;
  const [isAssetPreviewLoaded, setIsAssetPreviewLoaded] = React.useState(false);

  const setIsPopoverOpen = (isOpen: boolean) => {
    dispatch({
      type: "setIsPopoverOpen",
      payload: isOpen,
    });
    if (!isOpen) {
      setIsAssetPreviewLoaded(false);
    }
  };

  const setIsMenuOpen = (isOpen: boolean) =>
    dispatch({
      type: "setIsMenuOpen",
      payload: isOpen,
    });

  const setActionPopoverContent = (content: "delete" | "rename" | null) =>
    dispatch({
      type: "setActionPopoverContent",
      payload: content,
    });

  const { currentDragIdentifier } = useCurrentDragType();
  const isDragging = currentDragIdentifier !== null;

  return (
    <Popover.Root isOpen={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Anchor
        className={twMerge(
          "flex flex-col gap-1 p-1.5 border-0.5 border-border rounded-md group/asset-card",
          onClick && "cursor-pointer",
          className,
        )}
        onMouseEnter={() => {
          if (
            !isDragging &&
            !isMenuOpen &&
            actionPopoverContent === null &&
            !isUploading
          ) {
            setIsPopoverOpen(true);
          }
        }}
        onMouseLeave={() => {
          setIsPopoverOpen(false);
        }}
        onClick={onClick}
      >
        <AssetCardDragOverlay
          asset={{
            id: asset.id,
            type: asset.type,
            url: asset.url,
          }}
          shouldAllowDrag={shouldAllowDrag && !isUploading}
        >
          <AssetThumbnail asset={asset} isUploading={isUploading} />
        </AssetCardDragOverlay>
        <div className="flex items-center justify-between gap-1 relative min-h-6">
          <span className="block typ-label-small truncate">
            {asset.name}
            {asset.fileExtension ? `.${asset.fileExtension}` : ""}
          </span>
          {shouldAllowActions && !isUploading && (
            <AssetCardActions
              className={twMerge(
                "invisible w-0 group-hover/asset-card:visible group-hover/asset-card:w-auto",
                isMenuOpen && "visible w-auto",
              )}
              asset={asset}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              setActionPopoverContent={setActionPopoverContent}
              actionPopoverContent={actionPopoverContent}
            />
          )}
        </div>
      </Popover.Anchor>
      <Popover.Content
        onOpenAutoFocus={(event) => {
          event.preventDefault();
        }}
        shouldPreventDefaultOnInteractOutside={false}
        hideCloseButton
        side="right"
        align="start"
        sideOffset={4}
        className={twMerge(
          "flex flex-col gap-1 rounded-md w-min pointer-events-none opacity-0 isolation-auto",
          // NOTE (Gabe 2025-03-03): We use this trick to prevent layout shift
          // since the popover dimensions are based on the loaded image/video.
          isAssetPreviewLoaded && "opacity-100",
        )}
      >
        <AssetPreview
          asset={asset}
          className="flex-1 self-center max-w-[200px] max-h-[300px]"
          onLoad={() => setIsAssetPreviewLoaded(true)}
        />
        <span className="typ-label-small break-all w-full">{asset.name}</span>
        <div className="flex gap-1 typ-body-small text-muted whitespace-nowrap">
          <div className="bg-selectable-selected rounded">
            <Label
              label={
                <span className="uppercase text-primary py-0.5 px-1">
                  {asset.fileExtension}
                </span>
              }
              size="sm"
              layoutClassName="w-fit h-4"
            />
          </div>
          {asset.width && asset.height && (
            <span>
              {asset.width}x{asset.height}
            </span>
          )}
          {asset.sizeBytes && (
            <>
              <span>•</span>
              <span>{formatFileSize(asset.sizeBytes)}</span>
            </>
          )}
        </div>
        {asset.createdAt && (
          <span className="typ-body-small text-muted">
            Added {format(asset.createdAt, "MMM d, yyyy")}
          </span>
        )}
      </Popover.Content>
    </Popover.Root>
  );
};

const AssetCardLoader = () => {
  return (
    <div className="flex items-center justify-center absolute h-8 w-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-dark-contrast">
      <Spinner size={28} variant="white" />
      <LuArrowUp
        size={12}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"
      />
    </div>
  );
};

const AssetCardActions: React.FC<{
  className?: string;
  asset: Asset;
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  setActionPopoverContent: (
    actionPopoverContent: "delete" | "rename" | null,
  ) => void;
  actionPopoverContent: "delete" | "rename" | null;
}> = ({
  className,
  asset,
  isMenuOpen,
  setIsMenuOpen,
  setActionPopoverContent,
  actionPopoverContent,
}) => {
  const projectId = useCurrentProjectId();
  const { mutateAsync: deleteAsset } = trpc.asset.delete.useMutation({
    onSuccess: () => {
      analytics.logEvent("asset.deleted", {
        projectId: projectId!,
        assetId: asset.id,
      });
      void trpcUtils.asset.list.invalidate();
      setIsMenuOpen(false);
      setActionPopoverContent(null);
    },
  });
  const { mutateAsync: updateAsset } = trpc.asset.update.useMutation({
    onSuccess: () => {
      analytics.logEvent("asset.updated", {
        projectId: projectId!,
        assetId: asset.id,
      });
      void trpcUtils.asset.list.invalidate();
      setIsMenuOpen(false);
      setActionPopoverContent(null);
    },
  });

  const onDeleteAsset = async () => {
    if (!projectId) {
      return;
    }

    await deleteAsset({
      assetId: asset.id,
      projectId,
    });
  };

  const onRenameAsset = async (assetName: string) => {
    if (!projectId) {
      return;
    }

    await updateAsset({
      assetId: asset.id,
      projectId,
      name: assetName,
    });
  };

  const menuActions: MenuItem[] = [
    {
      type: "leaf",
      id: "edit",
      variant: "default",
      size: "sm",
      startEnhancer: <LuPenLine size={12} />,
      title: "Rename",
      onSelect: () => setActionPopoverContent("rename"),
    },
    {
      type: "leaf",
      id: "delete",
      variant: "default",
      size: "sm",
      startEnhancer: <LuTrash size={12} />,
      title: "Delete",
      onSelect: () => setActionPopoverContent("delete"),
    },
  ];

  return (
    <Popover.Root
      isOpen={Boolean(actionPopoverContent)}
      onOpenChange={(open) => {
        if (!open) {
          setActionPopoverContent(null);
        }
      }}
    >
      <Popover.Content
        shouldPreventDefaultOnInteractOutside
        onRequestClose={() => setActionPopoverContent(null)}
        side="right"
        align="end"
        sideOffset={4}
        title={
          actionPopoverContent === "delete" ? "Delete asset" : "Rename asset"
        }
        className="flex flex-col"
      >
        <div className="flex flex-col gap-3">
          {actionPopoverContent === "delete" ? (
            <AssetCardDeleteForm
              onDeleteAsset={() => void onDeleteAsset()}
              onCancel={() => setActionPopoverContent(null)}
            />
          ) : (
            <AssetCardRenameForm
              originalAssetName={asset.name}
              onRenameAsset={(assetName) => void onRenameAsset(assetName)}
            />
          )}
        </div>
      </Popover.Content>
      <Popover.Trigger className={className}>
        <Menu
          isOpen={isMenuOpen}
          onRequestOpen={() => setIsMenuOpen(true)}
          onRequestClose={() => setIsMenuOpen(false)}
          align="start"
          size="sm"
          side="right"
          sideOffset={4}
          disableTriggerFocusOnClose
          items={menuActions}
          contentClassNames="w-40"
          trigger={
            <IconButton
              variant="tertiary"
              size="sm"
              layoutClassName="h-4 w-4"
              icon={<BsThreeDots />}
              aria-hidden
            />
          }
        />
      </Popover.Trigger>
    </Popover.Root>
  );
};

const AssetCardDeleteForm: React.FC<{
  onDeleteAsset: () => void;
  onCancel: () => void;
}> = ({ onDeleteAsset, onCancel }) => {
  return (
    <>
      <div className="typ-body-small">
        If this asset is being used on a page, deleting it will break the asset
        until it is replaced.
      </div>
      <div className="flex gap-2">
        <Button variant="danger" size="sm" onClick={onDeleteAsset}>
          Delete
        </Button>
        <Button variant="secondary" size="sm" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

const BrokenAssetCard: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div
      className={twMerge(
        "rounded bg-light-surface typ-button-small text-muted text-center flex flex-col gap-1.5 items-center justify-center",
        className,
      )}
    >
      <LuImageOff size={24} />
      <span>Preview unavailable</span>
    </div>
  );
};

const AssetPreview: React.FC<{
  asset: Asset;
  className?: string;
  isUploading?: boolean;
  onLoad?: () => void;
  fit?: Parameters<typeof convertToReploSizedImageSource>[0]["fit"];
}> = ({ asset, className, isUploading, fit = "contain", onLoad }) => {
  const [isBroken, setIsBroken] = React.useState(false);
  const internalClassName = twMerge(
    "object-cover rounded min-h-[95px]",
    isUploading && "blur-sm opacity-50",
    className,
  );
  if (isBroken) {
    return <BrokenAssetCard className={internalClassName} />;
  }

  return exhaustiveSwitch(asset)({
    image: (asset) => {
      const assetUrl = convertToReploSizedImageSource({
        imageSource: asset.url,
        // NOTE (Gabe 2025-03-03): We use the same width and height for the
        // thumbnail and preview so that the aspect ration is know when we
        // mouseover the thumbnail since the same image resource is used for
        // both.
        width: PREVIEW_WIDTH,
        height: PREVIEW_HEIGHT,
        fit,
      });
      return (
        <img
          src={assetUrl}
          alt={asset.name}
          onError={() => setIsBroken(true)}
          onLoad={onLoad}
          className={internalClassName}
        />
      );
    },
    video: (asset) => (
      <video
        src={asset.url}
        className={internalClassName}
        onError={() => setIsBroken(true)}
        onLoadedMetadata={onLoad}
        preload="metadata"
      />
    ),
    font: () => null,
  });
};

const AssetThumbnail: React.FC<{
  asset: Asset;
  isUploading?: boolean;
}> = ({ asset, isUploading }) => {
  return (
    <div className="h-24 overflow-hidden rounded relative">
      <AssetPreview asset={asset} isUploading={isUploading} />
      {isUploading && <AssetCardLoader />}
    </div>
  );
};

export default AssetCard;
