import type { EducationalContentTitle } from "@editor/infra/analytics";

import * as React from "react";

import { Loader } from "@editor/components/common/Loader";
import {
  useCurrentWorkspace,
  useCurrentWorkspaceId,
} from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { docs } from "@editor/utils/docs";
import { routes } from "@editor/utils/router";

import { AllExperimentsTable } from "@/features/experiments/AllExperimentsTable";
import { CreateExperimentButton } from "@/features/experiments/components/CreateExperimentButton";
import { HelpDocumentationIcon } from "@/features/experiments/components/HelpDocumentationIcon";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import ExperimentsSetup from "@/features/experiments/Setup";
import { useExperimentApi } from "@/features/experiments/utils";
import IconButton from "@replo/design-system/components/button/IconButton";
import { BsGear } from "react-icons/bs";
import { generatePath, useNavigate } from "react-router-dom";

const ExperimentActions: React.FC<{
  showCreateExperimentButton: boolean;
}> = ({ showCreateExperimentButton }) => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const navigate = useNavigate();
  return (
    <div className="flex flex-row gap-2">
      <HelpDocumentationIcon />
      <IconButton
        variant="secondary"
        size="base"
        icon={<BsGear size={16} className="text-slate-800" />}
        onClick={() => {
          navigate(
            generatePath(routes.workspace.experiments.settings, {
              workspaceId,
            }),
          );
        }}
      ></IconButton>
      {showCreateExperimentButton && <CreateExperimentButton />}
    </div>
  );
};

type EducationSectionContainerProps = {
  title: EducationalContentTitle;
  image: string;
  link: string;
};

const EducationSectionContainer: React.FC<EducationSectionContainerProps> = ({
  title,
  image,
  link,
}) => {
  const logEvent = useLogAnalytics();
  return (
    <div
      className="py-3 border rounded-md flex flex-col justify-center items-center gap-2 cursor-pointer bg-light-sheet hover:bg-light-surface"
      onClick={() => {
        window.open(link, "_blank");
        logEvent("experiment.educationalContent.clicked", {
          from: "allExperimentsTable",
          contentTitle: title,
        });
      }}
    >
      <img src={image} alt={title} />
      <span>{title}</span>
    </div>
  );
};

const EDUCATION_SECTIONS: Array<EducationSectionContainerProps> = [
  {
    title: "Get Started",
    image: "/images/experiments/education/how-to-get-started.svg",
    link: docs.abTesting.education.getStarted,
  },
  {
    title: "Explore Top Tests",
    image: "/images/experiments/education/explore-top-tests.svg",
    link: docs.abTesting.education.exploreTopTests,
  },
  {
    title: "Why A/B Testing Works",
    image: "/images/experiments/education/why-you-should-test.svg",
    link: docs.abTesting.education.whyABTestingWorks,
  },
  {
    title: "Frequently Asked Questions",
    image: "/images/experiments/education/frequently-asked-questions.svg",
    link: docs.abTesting.education.frequentlyAskedQuestions,
  },
];

const EducationSection: React.FC = () => {
  return (
    <div className="grid grid-cols-4 gap-3 typ-label-small">
      {EDUCATION_SECTIONS.map((section) => (
        <EducationSectionContainer
          key={section.title}
          title={section.title}
          image={section.image}
          link={section.link}
        />
      ))}
    </div>
  );
};

const Experiments: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const workspaceName = useCurrentWorkspace()?.name;

  const {
    list: { data, isFetching: isFetchingExperiments },
    links: { data: links, isFetching: isFetchingLinks },
  } = useExperimentApi({ workspaceId });

  if (isFetchingExperiments || isFetchingLinks || !links || !data) {
    return <Loader />;
  }

  const experiments = data.experiments;
  const hasExperiments = experiments.length > 0;

  return (
    <ExperimentsLayout
      headerTitle={`${workspaceName}'s Tests`}
      headerActions={
        <ExperimentActions showCreateExperimentButton={hasExperiments} />
      }
    >
      <EducationSection />
      {hasExperiments ? (
        <AllExperimentsTable experiments={experiments} links={links} />
      ) : (
        <ExperimentsSetup />
      )}
    </ExperimentsLayout>
  );
};

export default Experiments;
