import type { ComponentTemplate } from "@editor/types/component-template";

import { findAncestorComponent } from "@editor/utils/component";
import { isElementPartial } from "@editor/utils/element";

import { getProduct } from "replo-runtime/store/ReploProduct";
import { fakeSwatches } from "replo-runtime/store/utils/fakeSwatches";

export const swatchOptionListCircular: ComponentTemplate = {
  id: "55134865-3102-4985-b08f-176922f8de9c",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Circular Option List",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "product/circular-option-list.svg",
  tags: ["product", "product circular option list"],
  transforms: [
    (component, parent, element, extras) => {
      if (
        !parent ||
        isElementPartial(element) ||
        !extras.productResolutionDependencies
      ) {
        return component;
      }

      const productComponent = findAncestorComponent(
        element,
        parent.id,
        (component) => component.type === "product",
      );

      const product = getProduct(
        productComponent?.props._product ?? null,
        extras.context ?? null,
        {
          productMetafieldValues: {},
          variantMetafieldValues: {},
          products: extras.productResolutionDependencies.products,
          currencyCode: extras.productResolutionDependencies.currencyCode,
          moneyFormat: extras.productResolutionDependencies.moneyFormat,
          language: extras.productResolutionDependencies.language,
          fallbackStrategy: { type: "defaultProduct" },
          templateProduct: extras.productResolutionDependencies.templateProduct,
          isEditor: extras.productResolutionDependencies.isEditor,
          isShopifyProductsLoading: false,
        },
      );

      const fakeSwatch = fakeSwatches.find((swatch) => {
        return (
          swatch.name === "Fake Swatch 1" &&
          swatch.data.productIds?.includes(product!.productId)
        );
      });

      if (!fakeSwatch || !productComponent) {
        delete component.props._option;
        return component;
      }

      return component;
    },
  ],
  template: {
    id: "ec61e4fe-41f5-4a46-8520-4d2801d6eebf",
    name: "Circular Option List",
    type: "optionSelect",
    props: {
      style: {
        display: "flex",
        __flexGap: "12px",
        alignSelf: "stretch",
      },
      _option: "Color",
    },
    children: [
      {
        id: "81b58010-4be4-4ab3-b4a6-c33e9db25ac4",
        name: "Outer Circle",
        type: "container",
        props: {
          style: {
            color: "#FFFFFFFF",
            width: "50px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            paddingTop: "0.5%",
            paddingLeft: "0.5%",
            paddingRight: "0.5%",
            paddingBottom: "0.5%",
            borderTopColor: "#00000000",
            borderTopWidth: "1px",
            justifyContent: "center",
            borderLeftColor: "#00000000",
            borderLeftWidth: "1px",
            borderRightColor: "#00000000",
            borderRightWidth: "1px",
            borderBottomColor: "#00000000",
            borderBottomWidth: "1px",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            borderBottomRightRadius: "50%",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
          },
        },
        children: [
          {
            id: "976e868e-bc6a-48ae-b6a0-2878a5ebef7b",
            name: "Inner Circle",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopLeftRadius: "50%",
                borderTopRightRadius: "50%",
                borderBottomLeftRadius: "50%",
                borderBottomRightRadius: "50%",
                backgroundColor:
                  "{{attributes._currentOption._swatches.Fake Swatch 1.color}}",
              },
            },
            children: [],
          },
        ],
        variants: [
          {
            id: "af4d156c-cbf5-4760-b529-4e22abc11a87",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "22b8e19c-972b-4742-ad64-8a18092bfa95",
            name: "Hover",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "18ad1de3-50b3-4a81-901a-bcb487cd4698",
                  field: "interaction.hover",
                },
              ],
            },
            componentOverrides: null,
          },
          {
            id: "3de96b8f-d729-492c-9f95-709668655373",
            name: "Selected",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  field: "state.product.selectedOptionValues",
                  id: "b3e03892-5561-4c6a-b18d-40b2c4b3a2a5",
                  operator: null,
                  value: null,
                },
              ],
            },
          },
        ],
        variantOverrides: {
          "22b8e19c-972b-4742-ad64-8a18092bfa95": {
            componentOverrides: {
              "81b58010-4be4-4ab3-b4a6-c33e9db25ac4": {
                props: {
                  style: {
                    borderColor: null,
                    borderStyle: null,
                    borderWidth: null,
                    borderTopColor: "#000000FF",
                    borderTopStyle: "solid",
                    borderTopWidth: "1px",
                    borderLeftColor: "#000000FF",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1px",
                    borderRightColor: "#000000FF",
                    borderRightStyle: "solid",
                    borderRightWidth: "1px",
                    borderBottomColor: "#000000FF",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1px",
                  },
                },
              },
            },
          },
          "3de96b8f-d729-492c-9f95-709668655373": {
            componentOverrides: {
              "81b58010-4be4-4ab3-b4a6-c33e9db25ac4": {
                props: {
                  style: {
                    borderTopWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftWidth: "1px",
                    borderWidth: null,
                    borderTopColor: "#000000FF",
                    borderRightColor: "#000000FF",
                    borderBottomColor: "#000000FF",
                    borderLeftColor: "#000000FF",
                    borderColor: null,
                    borderTopStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    borderLeftStyle: "solid",
                    borderStyle: null,
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};
