import type { SavedColorStyle } from "schemas/generated/designLibrary";
import type { StyleCategory } from "./useDraftStyles";

import * as React from "react";

const colorData: Record<StyleCategory, string[][]> = {
  playful: [
    ["#FFFAF1", "#D7DEDC", "#FBE783", "#F1873B", "#3E1E1E"],
    ["#FFFFFF", "#FDF0E6", "#F08E80", "#0B0D63", "#000000"],
    ["#F9F6F5", "#EFE1DD", "#F49C3A", "#DF529A", "#244144"],
    ["#FFF5DD", "#FFA2B6", "#D5546D", "#E43D12", "#000000"],
  ],
  bold: [
    ["#FFFFFF", "#FFCF5A", "#FD7E00", "#423F45", "#000000"],
    ["#FFFFFF", "#DEF988", "#E02F66", "#274AE2", "#000000"],
    ["#FFFFFF", "#FFFA96", "#5ACDF5", "#515151", "#040E11"],
    ["#FFFFFF", "#D174D2", "#E0563F", "#88798E", "#301542"],
  ],
  natural: [
    ["#FFF0E4", "#D3C3C4", "#8A737D", "#9F997F", "#000000"],
    ["#FEFCEA", "#E3EEDD", "#F6C454", "#F1A04C", "#1A3B1D"],
    ["#FCFCF2", "#D5E3E6", "#D18166", "#5C6D93", "#364042"],
    ["#FFF8F0", "#D8DBB9", "#687953", "#D7AC99", "#462B0C"],
  ],
  modern: [
    ["#FEFCF2", "#CCC5B9", "#3F3D39", "#252422", "#EB5E27"],
    ["#FCFCF8", "#EFEBFA", "#F1FFE0", "#5A767B", "#000000"],
    ["#FFFFFF", "#E3E0FF", "#6722F2", "#D7DEDC", "#1E1D1D"],
    ["#FFFFFF", "#E1DFD9", "#207F43", "#1D231C", "#000000"],
  ],
  professional: [
    ["#FBFBFB", "#D0E2F3", "#5D99CE", "#1469AB", "#0C1013"],
    ["#FFFFFF", "#E5E5E5", "#FCA311", "#14213D", "#000000"],
    ["#FCFAF4", "#F0CD5F", "#DA5B37", "#4277B0", "#141413"],
    ["#FFFFFF", "#FFFA96", "#FFB931", "#013E37", "#000000"],
  ],
  elegant: [
    ["#FFFFFF", "#EBE7DE", "#B76E78", "#2D0D3A", "#544C5B"],
    ["#FFFBF2", "#EDE7C7", "#980203", "#660807", "#210E00"],
    ["#FFF8EB", "#C44901", "#432534", "#193A37", "#04151F"],
    ["#F6E9DD", "#DFB3A5", "#B2BCBB", "#4B6979", "#204248"],
  ],
  futuristic: [
    ["#FCFCFC", "#E0DCCA", "#EAFD5E", "#ACC0C5", "#171F21"],
    ["#F5F5F5", "#473297", "#00E5FF", "#D93F87", "#331F53"],
    ["#FFFFFF", "#66FCF2", "#46A29F", "#202833", "#0B0C0F"],
    ["#FFFFFF", "#EEA4C6", "#E9175F", "#393939", "#000000"],
  ],
};

export function usePresetColorStyles() {
  const buildColorStyles = React.useCallback(
    (category: StyleCategory, paletteIndex: number): SavedColorStyle[] => {
      const categoryPalettes = colorData[category];
      if (!categoryPalettes || !categoryPalettes[paletteIndex]) {
        return [];
      }

      const palette = categoryPalettes[paletteIndex];
      return palette.map((color, index) => ({
        type: "color",
        name: `Color ${index + 1}`,
        attributes: {
          type: "color",
          color,
          colorType: "backgroundPrimary",
        },
      }));
    },
    [],
  );

  const getColorStylesByCategory = React.useCallback(
    (category: StyleCategory, paletteIndex = 0): SavedColorStyle[] => {
      if (!colorData[category] || !colorData[category][paletteIndex]) {
        return [];
      }
      return buildColorStyles(category, paletteIndex);
    },
    [buildColorStyles],
  );

  const getAllColorStyles = React.useCallback((): SavedColorStyle[] => {
    return Object.keys(colorData).flatMap((category) =>
      colorData[category as StyleCategory].flatMap((_, paletteIndex) =>
        buildColorStyles(category as StyleCategory, paletteIndex),
      ),
    );
  }, [buildColorStyles]);

  return {
    colorStyleCategories: Object.keys(colorData) as StyleCategory[],
    getColorStylesByCategory,
    getAllColorStyles,
  };
}
