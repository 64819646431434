import type { ComponentTemplate } from "@editor/types/component-template";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const shopifyProductReviewsWidget: ComponentTemplate = {
  id: "7fddb1f3-fd24-4b52-af92-f99796096bf9",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Shopify Product Reviews",
  leftBarCategory: { main: "productIntegrations", subCategory: "shopify" },
  preview: "integrations/shopify-product-reviews.svg",
  tags: ["widget"],
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  template: {
    id: "$uid",
    type: "shopifyProductReviewsWidget",
    props: { style: { alignSelf: "stretch" } },
  },
};
