import type { EditorPropsRecord } from "replo-runtime/shared/utils/editorProps";
import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "..";

import { convertToLegacyProps } from "../../../shared/utils/props";
import {
  styleElements as containerStyleElements,
  getConfigurableProps as getContainerConfigurableProps,
} from "../Container/config";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _sliderComponent: {
      type: "component",
      name: "Slider Control",
      defaultValue: null,
      description:
        "This component is the slider between the two different content components.",
    },
    _beforeComponent: {
      type: "component",
      name: "Slider Before Content",
      defaultValue: null,
      description: `This component is the content which is displayed for the "Before" part of the slider.`,
    },
    _afterComponent: {
      type: "component",
      name: "Slider After Content",
      defaultValue: null,
      description: `This component is the content which is displayed for the "After" part of the slider.`,
    },
    _orientation: {
      type: "selectable",
      name: "Orientation",
      defaultValue: "horizontal",
      description: "The orientation of the slider",
      selectableValues: {
        type: "options",
        options: [
          { label: "Horizontal", value: "horizontal" },
          { label: "Vertical", value: "vertical" },
        ],
      },
    },
    _dragFromHandleOnly: {
      type: "boolean",
      name: "Drag From Handle Only",
      defaultValue: true,
      description:
        "If set, the position will change only when dragging the handle",
    },
    _changePositionOnHover: {
      type: "boolean",
      name: "Change Position On Hover",
      defaultValue: false,
      description: "If set, the position will change on hover",
    },
    _startingPosition: {
      type: "percentage",
      name: "Starting Position",
      defaultValue: "50%",
      description: "The position of the slider when the page loads.",
    },
  };
}

function getEditorProps(): EditorPropsRecord {
  return {
    activeSlide: {
      type: "activeBeforeAfterState",
      title: "Active slide",
      description: "The active slide to show",
      defaultValue: "both",
    },
  };
}

const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    editorProps: getEditorProps(),
    newInstancesUseNumbering: true,
    allowsLayoutModification: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
    allowChildrenReorder: false,
  },
  children: {
    beforeAfterSliderThumb: {
      renderData: {
        customProps: convertToLegacyProps(getContainerConfigurableProps()),
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => true,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        canContainChildren: true,
        styleElements: containerStyleElements,
        ancestorAllow: {
          ancestorTypes: ["beforeAfterSlider"],
          message:
            "Slider can only be added as a direct child of Before & After Slider",
          directChildOnly: true,
        },
      },
    },
    beforeAfterSliderBeforeContent: {
      renderData: {
        customProps: convertToLegacyProps(getContainerConfigurableProps()),
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => true,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        canContainChildren: true,
        styleElements: containerStyleElements,
        ancestorAllow: {
          ancestorTypes: ["beforeAfterSlider"],
          message:
            "Before Content can only be added as a direct child of Before & After Slider",
          directChildOnly: true,
        },
      },
    },
    beforeAfterSliderAfterContent: {
      renderData: {
        customProps: convertToLegacyProps(getContainerConfigurableProps()),
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => true,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        canContainChildren: true,
        styleElements: containerStyleElements,
        ancestorAllow: {
          ancestorTypes: ["beforeAfterSlider"],
          message:
            "Before Content can only be added as a direct child of Before & After Slider",
          directChildOnly: true,
        },
      },
    },
  },
} satisfies ComponentConfig;

export default config;
