import type {
  ComponentTemplateIndustry,
  ComponentTemplateIndustryId,
  ComponentTemplateSelectionCategories,
} from "schemas/componentTemplates";

import { CATEGORIES_IDS } from "schemas/componentTemplates";

export const COMPONENT_TEMPLATE_CATEGORIES: ComponentTemplateSelectionCategories[] =
  [
    // Section categories
    {
      id: CATEGORIES_IDS.heros,
      name: "Hero",
      type: "section",
      order: 1,
      slug: "heros",
    },
    {
      id: CATEGORIES_IDS.banners,
      name: "Banners",
      type: "section",
      order: 2,
      slug: "banners",
    },
    {
      id: CATEGORIES_IDS.benefits,
      name: "Benefits & Features",
      type: "section",
      order: 3,
      slug: "benefits",
    },
    {
      id: CATEGORIES_IDS.productDetails,
      name: "Product Detail",
      type: "section",
      order: 4,
      slug: "product-details",
    },
    {
      id: CATEGORIES_IDS.conversionCTAs,
      name: "Conversion CTAs",
      type: "section",
      order: 5,
      slug: "conversion-ctas",
    },
    {
      id: CATEGORIES_IDS.comparisons,
      name: "Comparisons",
      type: "section",
      order: 6,
      slug: "comparisons",
    },
    {
      id: CATEGORIES_IDS.testimonials,
      name: "Social Proof",
      type: "section",
      order: 7,
      slug: "testimonials",
    },
    {
      id: CATEGORIES_IDS.trustSignals,
      name: "Guarantees",
      type: "section",
      order: 8,
      slug: "trust-signals",
    },
    {
      id: CATEGORIES_IDS.faqs,
      name: "FAQs",
      type: "section",
      order: 9,
      slug: "faqs",
    },
    {
      id: CATEGORIES_IDS.collections,
      name: "Collections",
      type: "section",
      order: 10,
      slug: "collections",
    },
    {
      id: CATEGORIES_IDS.education,
      name: "How to & Education",
      type: "section",
      order: 11,
      slug: "education",
    },
    {
      id: CATEGORIES_IDS.aboutUs,
      name: "About & Story",
      type: "section",
      order: 12,
      slug: "about-us",
    },
    {
      id: CATEGORIES_IDS.sections,
      name: "Sections",
      type: "section",
      order: 13,
      slug: "sections",
    },
    {
      id: CATEGORIES_IDS.listicle,
      name: "Listicle",
      type: "section",
      order: 14,
      slug: "listicle",
    },

    // Page categories
    {
      id: CATEGORIES_IDS.landingPage,
      name: "Landing Pages",
      type: "page",
      order: 2,
      slug: "landing-pages",
    },
    {
      id: CATEGORIES_IDS.advertorialPage,
      name: "Advertorial",
      type: "page",
      order: 2,
      slug: "advertorial",
    },
    {
      id: CATEGORIES_IDS.blogPostPage,
      name: "Blogs",
      type: "page",
      order: 2,
      slug: "blog-posts",
    },
    {
      id: CATEGORIES_IDS.productDropPage,
      name: "Product Drop",
      type: "page",
      order: 2,
      slug: "product-drop",
    },
    {
      id: CATEGORIES_IDS.productPageTemplates,
      name: "Product Page Templates",
      type: "page",
      order: 2,
      slug: "product-page-templates",
    },
    {
      id: CATEGORIES_IDS.faqs,
      name: "FAQs",
      type: "page",
      order: 2,
      slug: "faqs",
    },
    {
      id: CATEGORIES_IDS.listicle,
      name: "Listicle",
      type: "page",
      order: 2,
      slug: "listicle",
    },
    {
      id: CATEGORIES_IDS.aboutUs,
      name: "About Us",
      type: "page",
      order: 3,
      slug: "about-us",
    },
  ];

export const componentTemplateIndustries: ComponentTemplateIndustry[] = [
  { id: "dbec89c6-0222-4115-a994-967c07514b5b", name: "Accessories" },
  { id: "58785c14-4db0-47b5-9fcf-bd48cbb92938", name: "Activities & Outdoors" },
  { id: "ef386249-c49f-49b3-8fcc-350a7b8ab1e0", name: "Baby & Kids" },
  {
    id: "a95bc923-28ac-41da-978f-46be5c024793",
    name: "Beauty",
  },
  { id: "d16c03d2-c5b8-40e9-8f8b-a59d2c5755a1", name: "Bags & Luggage" },
  { id: "26fd6a4e-525c-48b9-bfb4-718332f2a661", name: "Clothing" },
  {
    id: "5b5f7cf6-2aee-4ea3-85c6-ea73111aca9e",
    name: "Drinks",
  },
  { id: "2932fe19-bbbf-4219-8b8d-b181b670f5d8", name: "Food" },
  { id: "3f39766e-9c1b-44ae-b105-5d64d93f2cad", name: "Hair Care" },
  { id: "68395a9c-e77b-460b-9806-9cb1ec7e0dc1", name: "Health & Wellness" },
  { id: "9107a7ec-4a26-4ed3-9307-98fbad60fbb1", name: "Home" },
  { id: "56ec366e-8626-46c3-ba69-6343b651f534", name: "Personal Care" },
  { id: "be3a36d9-113e-4188-99dc-97a4420409e5", name: "Pets" },
  { id: "73cb5719-5bd0-47a0-a861-9fa66818debf", name: "Shoes" },
  { id: "b785dd5d-efb4-4fe0-85a6-c3b3b59a34db", name: "Skin Care" },
  { id: "d4e28d8a-9c59-4744-9044-121162f9b247", name: "Supplements" },
  { id: "7c7825e2-bf9f-4cae-81c4-2692d5213603", name: "Tech" },
];

export const getComponentTemplateIndustryName = (
  industryId: ComponentTemplateIndustryId,
) => {
  return componentTemplateIndustries.find(
    (industry) => industry.id === industryId,
  )?.name;
};

export const getComponentTemplateIndustrySlugFromName = (
  industryName: string,
) => {
  return industryName.replace(/&/g, "and").replace(/ /g, "-").toLowerCase();
};
