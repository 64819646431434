import React from "react";

import { setLeftBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorDispatch } from "@editor/store";

import { Pencil } from "lucide-react";

import { useBrandDetailsTabs } from "../designLibrary/BrandDetailsContext";
import useBrandDetails from "../editor/ai/useBrandDetails";

interface BrandContextPreviewProps {}

export const BrandContextPreview: React.FC<BrandContextPreviewProps> = () => {
  const { brandDetails } = useBrandDetails();
  const { setSelectedTab } = useBrandDetailsTabs();
  const dispatch = useEditorDispatch();

  // Check if there are any brand details to display
  const hasBrandDetails =
    brandDetails &&
    (brandDetails.brandName ||
      brandDetails.brandVoice ||
      brandDetails.whatBusinessSells ||
      brandDetails.whoIsCustomer);

  return (
    <button
      className="w-[200px] border-0.5 border-border rounded-md py-1 px-2 flex items-center gap-1 group relative"
      onClick={() => {
        dispatch(setLeftBarActiveTab("shopStyles"));
        setSelectedTab("context");
      }}
    >
      <div className="overflow-hidden text-ellipsis line-clamp-2 typ-body-small w-full text-left">
        {hasBrandDetails ? (
          <>
            {brandDetails?.brandName} {brandDetails?.brandVoice}{" "}
            {brandDetails?.whatBusinessSells} {brandDetails?.whoIsCustomer}
          </>
        ) : (
          <span className="text-placeholder">Add your brand context!</span>
        )}
      </div>
      <div className="absolute rounded-md inset-0 bg-light-surface opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center z-50">
        <Pencil className="h-5 w-5 text-muted" />
      </div>
    </button>
  );
};
