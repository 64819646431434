import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { SavedStyleColorAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import ColorPickerWrapper from "@editor/components/common/designSystem/color/ColorPickerWrapper";
import SolidColorPicker from "@editor/components/common/designSystem/color/SolidColorPicker";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import Input from "@editor/components/common/designSystem/Input";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { selectDraftComponentId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getAutoCompletedColor,
  getFormattedColor,
  getFormattedColorWithoutOpacity,
  getHex8Color,
} from "@editor/utils/colors";
import { getColorBadgeValue, hasDynamicData } from "@editorModifiers/utils";

import { Badge } from "@replo/design-system/components/badge/Badge";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import debounce from "lodash-es/debounce";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type SolidColorSelectorProps = {
  value: string | ReploMixedStyleValue | null;
  defaultValue?: string;
  onPreviewChange?(value: string | null): void;
  onChange(value: string | null): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  isDisabled?: boolean;
  autofocus?: boolean;
  allowColorPickerPopover?: boolean;
  popoverSideOffset?: number;
  popoverSide?: "left" | "right" | "bottom" | "top";
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  onSelectSavedStyle?(value: string): void;
  showSavedStyles?: boolean;
};

const SolidColorSelector: React.FC<SolidColorSelectorProps> = ({
  value,
  defaultValue,
  onChange,
  onPreviewChange,
  onDragEnd,
  onDragStart,
  openDynamicData,
  popoverTitle = "Color",
  popoverSide,
  isDisabled = false,
  autofocus = false,
  allowColorPickerPopover = true,
  popoverSideOffset = 28,
  isPopoverOpen: controlledIsPopoverOpen,
  onOpenPopoverChange,
  onSelectSavedStyle,
  showSavedStyles,
}) => {
  const hasMixedStyleValue = isMixedStyleValue(value);
  const [lastValidColorValue, setLastValidColorValue] = React.useState(
    !hasMixedStyleValue ? value : null,
  );

  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");

  const [isPopoverOpen, setIsPopoverOpen] = useControllableState(
    controlledIsPopoverOpen,
    false,
    onOpenPopoverChange,
  );
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const dynamicData =
    hasDynamicData(draftComponentId) && openDynamicData !== undefined;

  /* eslint-disable react-hooks/exhaustive-deps */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = React.useCallback(
    debounce((value) => onChange(value), 300),
    [onChange],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const isColorFromDesignLibrary =
    typeof value === "string" && isDynamicDesignLibraryValue(value);

  const shouldShowColorRemoveButton = value && value !== defaultValue;

  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleColorAttributes>(
      String(value) ?? null,
    )?.color;

  const { textShopStyles: textSavedStyles, colorShopStyles: colorSavedStyles } =
    useShopStyles();
  const wasSavedStyleDeleted = Boolean(deletedSavedStyleValue);

  const getInputValue = () => {
    if (!value) {
      return "";
    }

    if (hasMixedStyleValue) {
      return "Mixed";
    }

    if (!wasSavedStyleDeleted && isColorFromDesignLibrary) {
      // NOTE (Fran 2024-11-27): If the color is from the design library, we need to
      // show the saved style value reference instead of the value itself.
      const savedStyle = getSavedStyleValue(
        // NOTE (Sebas, 2024-11-14): We look for the color in both text and color type saved
        // styles. This is because when we select a text style with a font color we
        // want to show the color in the value indicator.
        [...textSavedStyles, ...colorSavedStyles],
        value,
      );

      return savedStyle?.type === "text"
        ? `${savedStyle?.attributes.color} (${savedStyle?.name})`
        : savedStyle?.name;
    }

    return getFormattedColorWithoutOpacity(value) ?? "";
  };
  const valueText = getInputValue();
  const [inputValue, setInputValue] = useOverridableState(valueText);

  const handleOnChange = (
    color: string | null,
    opts: { debounce: boolean },
  ) => {
    let value: string | null = null;
    if (color) {
      const hex8color = getHex8Color(color);
      const formattedHex8Color = getFormattedColor(hex8color);
      value = formattedHex8Color;
    }

    onPreviewChange?.(value);
    setInputValue(getFormattedColorWithoutOpacity(value) ?? "");
    if (opts.debounce) {
      debounceOnChange(value);
    } else {
      onChange(value);
    }
  };

  const handleColorChange = () => {
    //  Note (Sebas, 2022-05-29): Check if the user enters a value
    // or if the value is the same as the last valid color to prevent
    // the border from updating if it is not needed.
    if (
      inputValue !== null &&
      (inputValue === lastValidColorValue ||
        isMixedStyleValue(inputValue) ||
        isColorFromDesignLibrary)
    ) {
      return;
    }
    const completedColor = getAutoCompletedColor(inputValue);
    if (completedColor) {
      const hexColor = getFormattedColor(completedColor);
      setLastValidColorValue(hexColor);
      handleOnChange(hexColor, { debounce: false });
    } else if (lastValidColorValue) {
      onChange(lastValidColorValue);
    }
  };

  const handleColorRemove = () => {
    onChange(defaultValue || null);
  };

  const getBadgeValue = () => {
    if (!value || hasMixedStyleValue) {
      return "";
    }

    if (!wasSavedStyleDeleted && isColorFromDesignLibrary) {
      // NOTE (Fran 2024-11-27): If the color is from the design library, we need to
      // show the saved style value reference instead of the value itself.
      const savedStyle = getSavedStyleValue(
        // NOTE (Sebas, 2024-11-14): We look for the color in both text and color type saved
        // styles. This is because when we select a text style with a font color we
        // want to show the color in the value indicator.
        [...textSavedStyles, ...colorSavedStyles],
        value,
      );

      return savedStyle?.attributes.color ?? "";
    }

    return getColorBadgeValue(value);
  };
  const badgeValue = getBadgeValue();

  const shouldOpenPopover = isColorFromDesignLibrary;

  return (
    <div
      className="flex flex-grow flex-row justify-items-start items-center gap-1"
      onClick={() => {
        if (shouldOpenPopover) {
          setIsPopoverOpen(true);
        }
      }}
    >
      <Input
        value={inputValue}
        placeholder="#000000"
        onChange={(e) => {
          if (!isColorFromDesignLibrary) {
            setInputValue(e.target.value);
          }
        }}
        isReadOnly={isColorFromDesignLibrary}
        onKeyDown={(e) => e.stopPropagation()}
        startEnhancer={
          allowColorPickerPopover || !hasMixedStyleValue ? (
            <ColorPickerWrapper
              isPopoverOpen={isPopoverOpen}
              onOpenPopoverChange={setIsPopoverOpen}
              popoverTitle={popoverTitle}
              popoverSide={popoverSide}
              popoverSideOffset={popoverSideOffset}
              onSelectSavedStyle={onSelectSavedStyle}
              showSavedStyles={showSavedStyles}
              trigger={
                <Badge
                  type={!hasMixedStyleValue ? "color" : "unknown"}
                  isFilled
                  backgroundColor={badgeValue}
                />
              }
              content={
                <Tooltip content={popoverTitle ?? ""} triggerAsChild>
                  <div
                    tabIndex={0}
                    // NOTE (Sebas, 2024-11-05): This is needed to prevent selecting the input when
                    // clicking on the color picker popover trigger. If we don't do this, the input
                    // will focus and unfocus triggering an onChange event because of the onBlur event.
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SolidColorPicker
                      value={!hasMixedStyleValue ? value ?? null : ""}
                      onChange={(value) => {
                        handleOnChange(value, { debounce: false });
                      }}
                      onPreviewChange={onPreviewChange}
                      onDragEnd={onDragEnd}
                      onDragStart={onDragStart}
                    />
                  </div>
                </Tooltip>
              }
            />
          ) : (
            <Badge type="color" isFilled backgroundColor={badgeValue} />
          )
        }
        onOptionClick={handleColorRemove}
        onBlur={handleColorChange}
        onEnter={handleColorChange}
        isDisabled={isDisabled}
        endEnhancer={() =>
          handleColorRemove &&
          shouldShowColorRemoveButton && (
            <FormFieldXButton
              onClick={() => {
                setLastValidColorValue(null);
                handleColorRemove?.();
              }}
            />
          )
        }
        autoFocus={autofocus}
      />
      {dynamicData && !isNewDynamicData && (
        <div className="flex">
          <DynamicDataButton
            tooltipText="Add Dynamic Data"
            onClick={(e) => {
              e.stopPropagation();
              openDynamicData?.();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SolidColorSelector;
