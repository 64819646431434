import type { LucideIcon } from "lucide-react";
import type { ComponentTemplateCategoryId } from "schemas/componentTemplates";

import {
  Bookmark,
  BookOpenText,
  CircleCheck,
  CircleHelp,
  CodeXml,
  GraduationCap,
  Image,
  LayoutGrid,
  List,
  Megaphone,
  MessageCircle,
  MousePointerClick,
  Package,
  Plug,
  Puzzle,
  ShieldCheck,
  ShoppingCart,
  Table,
  ToggleLeft,
  Type,
} from "lucide-react";
import { CATEGORIES_IDS } from "schemas/componentTemplates";

export type CategoryCard = {
  name: string;
  value: string;
  icon: LucideIcon;
  id?: ComponentTemplateCategoryId;
  description?: string;
};

export const SECTION_CATEGORIES: CategoryCard[] = [
  {
    name: "Saved",
    value: "saved",
    icon: Bookmark,
    description: "Your saved sections",
  },
  {
    id: CATEGORIES_IDS.heros,
    name: "Hero",
    value: "heros",
    icon: Image,
    description: "Grab attention",
  },
  {
    id: CATEGORIES_IDS.banners,
    name: "Banners",
    value: "banners",
    icon: Megaphone,
    description: "Drive urgency",
  },
  {
    id: CATEGORIES_IDS.benefits,
    name: "Benefits & Features",
    value: "benefits",
    icon: CircleCheck,
    description: "Prove the value",
  },
  {
    id: CATEGORIES_IDS.productDetails,
    name: "Product Detail",
    value: "product-details",
    icon: ShoppingCart,
    description: "Sell that product",
  },
  {
    id: CATEGORIES_IDS.conversionCTAs,
    name: "Conversion CTAs",
    value: "conversion-ctas",
    icon: MousePointerClick,
    description: "Drive more actions",
  },
  {
    id: CATEGORIES_IDS.comparisons,
    name: "Comparisons",
    value: "comparisons",
    icon: Table,
    description: "Show why it wins",
  },
  {
    id: CATEGORIES_IDS.testimonials,
    name: "Social Proof",
    value: "testimonials",
    icon: MessageCircle,
    description: "Boost credibility",
  },
  {
    id: CATEGORIES_IDS.trustSignals,
    name: "Guarantees",
    value: "trust-signals",
    icon: ShieldCheck,
    description: "Reduce risk",
  },
  {
    id: CATEGORIES_IDS.faqs,
    name: "FAQs",
    value: "faqs",
    icon: CircleHelp,
    description: "Remove Objections",
  },
  {
    id: CATEGORIES_IDS.collections,
    name: "Collections",
    value: "collections",
    icon: LayoutGrid,
    description: "Show options",
  },
  {
    id: CATEGORIES_IDS.education,
    name: "How to & Education",
    value: "education",
    icon: GraduationCap,
    description: "Guide and inform",
  },
  {
    id: CATEGORIES_IDS.aboutUs,
    name: "About & Story",
    value: "about-us",
    icon: BookOpenText,
    description: "Build connection",
  },
  {
    id: CATEGORIES_IDS.listicle,
    name: "Listicle",
    value: "listicle",
    icon: List,
    description: "Inform and engage",
  },
];

export const COMPONENT_CATEGORIES: CategoryCard[] = [
  {
    name: "Saved",
    value: "saved",
    icon: Bookmark,
  },
  {
    name: "Basic",
    value: "basic",
    icon: Type,
  },
  {
    name: "Layout",
    value: "layout",
    icon: LayoutGrid,
  },
  {
    name: "Interactive",
    value: "interactive",
    icon: ToggleLeft,
  },
  {
    name: "Product",
    value: "product",
    icon: Package,
  },
  {
    name: "Product Integrations",
    value: "productIntegrations",
    icon: Puzzle,
  },
  {
    name: "Page Integrations",
    value: "pageIntegrations",
    icon: Plug,
  },
  {
    name: "Media",
    value: "media",
    icon: Image,
  },
  {
    name: "Custom Code",
    value: "customCode",
    icon: CodeXml,
  },
];
