import type { DateRange, Matcher } from "react-day-picker";

import * as React from "react";

import Button from "@replo/design-system/components/button/Button";
import Calendar from "@replo/design-system/components/shadcn/core/calendar";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import { addDays } from "date-fns";
import { BsCalendar3 } from "react-icons/bs";
import { convertDateToUTC, dateRangeFormatter } from "replo-utils/lib/datetime";
import { useControllableState } from "replo-utils/react/use-controllable-state";

// NOTE (Kurt, 2024-11-22): We use the popover align offset to vertically align the date picker
// with the trigger button.
const POPOVER_ALIGN_OFFSET = -15;
// NOTE (Kurt, 2024-11-22): We use the side offset to horizontally align the date picker
// with the trigger button. This is the horizontal distance between the date picker and
// the trigger button.
const POPOVER_SIDE_OFFSET = 20;

type DatePickerWithRangeProps = React.HTMLAttributes<HTMLDivElement> & {
  isDisabled?: boolean;
  popoverTriggerComponent?: () => React.ReactNode;
  initialDateRange?: DateRange;
  customTrigger?: React.ReactNode;
  triggerOnHover?: boolean;
  value?: DateRange;
  onChange?: (value: DateRange) => void;
  updateAction: (dateRange: DateRange) => void;
  closeAction?: () => void;
  excludeDisabledDates?: boolean;
  disabledDates?: Matcher | Matcher[];
  rangeExtendsOnSelect?: boolean;
};

export function DatePickerWithRange({
  className,
  isDisabled,
  popoverTriggerComponent,
  initialDateRange,
  triggerOnHover = false,
  value: controlledValue,
  onChange: onControlledChange,
  updateAction,
  closeAction,
  excludeDisabledDates = false,
  disabledDates,
  rangeExtendsOnSelect = true,
}: DatePickerWithRangeProps) {
  const [open, setOpen] = React.useState(false);

  const [date, setDate] = useControllableState(
    controlledValue,
    initialDateRange ?? {
      from: addDays(new Date(), -7),
      to: new Date(),
    },
    onControlledChange,
  );

  const utcDate = {
    from: date?.from ? convertDateToUTC(date.from) : undefined,
    to: date?.to ? convertDateToUTC(date.to) : undefined,
  };

  const validDateRange = Boolean(date.from && date.to);

  const handleMouseEnter = () => {
    if (triggerOnHover) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (triggerOnHover) {
      setTimeout(() => {
        setOpen(false);
      }, 100);
    }
  };

  const defaultTrigger = (
    <button
      className={clsxMerge(
        "h-8 rounded p-2 border flex items-center justify-center",
        !isDisabled
          ? "border-slate-400 bg-slate-100 text-slate-800"
          : "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed",
        className,
      )}
    >
      <div className="flex items-center justify-center gap-[8px]">
        <BsCalendar3 className="h-4 w-4" />
        {date?.from ? (
          dateRangeFormatter(date)
        ) : (
          <span className="text-slate-800">Pick a date</span>
        )}
      </div>
    </button>
  );

  return (
    <div className={clsxMerge("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {popoverTriggerComponent
              ? popoverTriggerComponent()
              : defaultTrigger}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0 rounded-[12px] mb-2"
          align="start"
          side="right"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          avoidCollisions={true}
          sideOffset={POPOVER_SIDE_OFFSET}
          alignOffset={POPOVER_ALIGN_OFFSET}
        >
          <Calendar
            mode="range"
            defaultMonth={utcDate?.from}
            selected={utcDate}
            onSelect={
              !rangeExtendsOnSelect
                ? (nextRange, selectedDay) => {
                    if (nextRange) {
                      // NOTE (Kurt, 2024-09-10): This enables us to reset the date range whenever we
                      // select a date with a range already selected.
                      setDate((range) => {
                        if (range.from && range.to) {
                          return { from: selectedDay, to: undefined };
                        }
                        return nextRange as DateRange;
                      });
                    } else {
                      setDate({ from: selectedDay, to: selectedDay });
                    }
                  }
                : (newDate) => {
                    setDate(newDate as DateRange);
                  }
            }
            numberOfMonths={2}
            required={false}
            excludeDisabled={excludeDisabledDates}
            disabled={disabledDates}
          />
          <div className="h-[52px] w-full flex justify-between items-center py-3 px-2.5 border-t border-slate-200">
            <div className="flex items-center gap-[8px]">
              <div className="text-sm text-slate-600">Selected period</div>
              <div className="text-sm text-slate-800 font-semibold">
                {utcDate?.from ? (
                  dateRangeFormatter(utcDate)
                ) : (
                  <span className="text-slate-800">Pick a date</span>
                )}
              </div>
            </div>
            <div className="flex items-center justify-center gap-[8px]">
              <PopoverClose asChild>
                <Button variant="secondary" size="base" onClick={closeAction}>
                  Cancel
                </Button>
              </PopoverClose>
              <Button
                variant="primary"
                size="base"
                onClick={() => validDateRange && updateAction(date)}
                disabled={!validDateRange}
                tooltipText={validDateRange ? undefined : "Select a date range"}
              >
                Update
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
