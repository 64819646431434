import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { storeToken } from "@editor/reducers/utils/store-token";
import { trpc } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { useForm } from "react-hook-form";
import { passwordSchema } from "schemas/auth";
import { z } from "zod";

const validationSchema = z.object({
  password: z.string().min(1, "Please enter a valid password"),
  newPassword: passwordSchema,
});

export default function SecurityBoard() {
  const logEvent = useLogAnalytics();
  const { mutate: updateAccountPassword, isPending: isLoading } =
    trpc.user.updateAccountPassword.useMutation({
      onSuccess: ({ token }) => {
        storeToken(token);
        toast({
          header: "Password Updated",
          message: "Your password has successfully been updated.",
        });
        logEvent("user.setting.updated", {
          infoUpdated: "password",
        });
        reset();
      },
    });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      newPassword: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const hasErrors = Object.keys(errors).length > 0;

  async function onSubmit({
    password,
    newPassword,
  }: {
    password: string;
    newPassword: string;
  }) {
    updateAccountPassword({
      password: password,
      newPassword: newPassword,
    });
  }

  return (
    <div className="flex w-64 flex-col">
      <h3 className="mb-2 typ-header-h3 text-default mb-4">Update Password</h3>
      <form
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <LabeledControl
          label="Current Password"
          error={errors?.password?.message}
        >
          <Input
            // TODO (Chance 2023-11-10): This field should be marked as
            // required in its markup.
            aria-invalid={errors?.password?.message ? "true" : undefined}
            aria-describedby={
              errors?.password?.message ? "error-password" : undefined
            }
            validityState={errors?.password?.message ? "invalid" : undefined}
            placeholder="••••••••••••"
            type="password"
            autoComplete="current-password"
            size="base"
            {...register("password")}
          />
        </LabeledControl>
        <LabeledControl
          label="New Password"
          className="mt-2"
          error={errors?.newPassword?.message}
        >
          <Input
            // TODO (Chance 2023-11-10): This field should be marked as
            // required in its markup.
            aria-invalid={errors?.newPassword?.message ? "true" : undefined}
            aria-describedby={
              errors?.newPassword?.message
                ? "error-password-confirm"
                : undefined
            }
            validityState={errors?.newPassword?.message ? "invalid" : undefined}
            placeholder="••••••••••••"
            type="password"
            autoComplete="new-password"
            size="base"
            {...register("newPassword")}
          />
        </LabeledControl>
        <div className="mt-4">
          <Button
            variant="primary"
            type="submit"
            size="base"
            disabled={isLoading || hasErrors}
            isLoading={isLoading}
          >
            Update Password
          </Button>
        </div>
      </form>
    </div>
  );
}
