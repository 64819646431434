import type { ComponentTemplate } from "@editor/types/component-template";

export const bannerCarousel: ComponentTemplate = {
  id: "1a9f1a71-852f-4fdc-9eb9-c2c7aa487394",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Carousel/Banner Carousel",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/banner.png",
  template: {
    id: "58490ad4-7dcd-4bb9-8ad0-36358eb94548",
    name: "banner carousel",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        alignSelf: "stretch",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      _infinite: true,
    },
    children: [
      {
        id: "fe24b41b-c658-44c8-a162-d6d2caabf0c8",
        name: "Previous Button",
        type: "carouselV3Control",
        props: {
          style: {
            top: "50%",
            left: "0px",
            cursor: "pointer",
            zIndex: 1,
            display: "flex",
            flexWrap: "wrap",
            position: "absolute",
            alignItems: "center",
            __transform: {
              skewX: "0deg",
              skewY: "0deg",
              scaleX: "100%",
              scaleY: "100%",
              scaleZ: "100%",
              rotateX: "0deg",
              rotateY: "0deg",
              rotateZ: "0deg",
              translateX: "0px",
              translateY: "-50%",
              translateZ: "0px",
            },
            justifyContent: "center",
          },
          direction: "previous",
        },
        children: [
          {
            id: "9d07b4c3-8710-4bb8-9484-110719ebc6b3",
            type: "icon",
            props: {
              style: {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
              },
              iconName: "chevronLeft",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "fb48c8d5-06d0-4c80-a7f3-7dede46dcebd",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "db3fcc99-02d9-46cf-b8a2-2a6fa1867180",
            name: "First Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "bf8b0b4c-79fd-4043-a18b-9840a038a6e3",
                  field: "state.group.isFirstItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "db3fcc99-02d9-46cf-b8a2-2a6fa1867180": {
            componentOverrides: {
              "9d07b4c3-8710-4bb8-9484-110719ebc6b3": {
                props: {
                  style: {
                    opacity: "20%",
                  },
                },
              },
              "fe24b41b-c658-44c8-a162-d6d2caabf0c8": {
                props: {
                  style: {
                    cursor: "default",
                  },
                },
              },
            },
          },
        },
      },
      {
        id: "8527d9e8-cc24-4769-a31b-717fe126b022",
        name: "Slides",
        type: "carouselV3Slides",
        props: {
          style: {
            width: "auto",
            height: null,
            __width: "1197px",
            flexGrow: 1,
            overflow: "hidden",
            alignSelf: "stretch",
            flexBasis: "auto",
          },
        },
        children: [
          {
            id: "20e961cd-59f7-4b43-aea3-9e23bd74799e",
            name: "Slide 1",
            type: "container",
            props: {
              style: {
                height: "600px",
                __width: "auto",
                display: "flex",
                __height: "600px",
                flexGrow: "1",
                __flexGap: "12px",
                alignItems: "flex-start",
                paddingTop: "120px",
                paddingLeft: "120px",
                paddingRight: "120px",
                flexDirection: "column",
                paddingBottom: "120px",
                backgroundSize: "cover",
                justifyContent: "center",
                backgroundImage:
                  'url("https://images.unsplash.com/photo-1504198266287-1659872e6590?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
                backgroundPositionX: "0px",
                backgroundPositionY: "0px",
              },
              "style@md": {
                height: "600px",
                __height: "600px",
                alignSelf: "auto",
                paddingTop: "120px",
                paddingLeft: "120px",
                paddingRight: "120px",
                paddingBottom: "120px",
              },
              "style@sm": {
                height: "600px",
                display: "flex",
                __height: "600px",
                alignSelf: "center",
                alignItems: "center",
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "24px",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "63ca49dc-6065-41cc-afc4-d9b54671ee59",
                type: "text",
                props: {
                  text: "<h1>Slide Title</h1>",
                  style: {
                    fontSize: "40px",
                    fontFamily: "Assistant, sans-serif",
                    fontWeight: "700",
                    lineHeight: "42px",
                  },
                },
              },
              {
                id: "f2df87be-028a-47a8-ae93-727103190540",
                type: "text",
                props: {
                  text: "<p>Slide 1 of 4</p>",
                  style: {
                    fontSize: "20px",
                    fontFamily: "Assistant, sans-serif",
                    fontWeight: "400",
                    lineHeight: "24px",
                  },
                },
              },
              {
                id: "3f45b079-8d0a-498c-a6c8-1183ed62bf83",
                type: "button",
                props: {
                  style: {
                    color: "#FFFFFF",
                    display: "flex",
                    paddingTop: "16px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    paddingBottom: "16px",
                    backgroundColor: "#000000",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                },
                children: [
                  {
                    id: "c8fea814-df27-4146-8923-483e33752853",
                    type: "text",
                    props: {
                      text: "<p>Button</p>",
                      style: {
                        color: "#FFFFFF",
                        flexGrow: 1,
                        fontSize: "20px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: "2px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "ff846c3f-951a-46e5-b583-4ecb816ecb1c",
            name: "Slide 2",
            type: "container",
            props: {
              style: {
                height: "600px",
                __width: "auto",
                display: "flex",
                __height: "600px",
                flexGrow: "1",
                __flexGap: "12px",
                alignItems: "flex-start",
                paddingTop: "120px",
                paddingLeft: "120px",
                paddingRight: "120px",
                flexDirection: "column",
                paddingBottom: "120px",
                backgroundSize: "cover",
                justifyContent: "center",
                backgroundImage:
                  'url("https://images.unsplash.com/photo-1504204267155-aaad8e81290d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
                backgroundPositionX: "0px",
                backgroundPositionY: "0px",
              },
              "style@md": {
                height: "600px",
                alignSelf: "auto",
                paddingTop: "120px",
                paddingLeft: "120px",
                paddingRight: "120px",
                paddingBottom: "120px",
              },
              "style@sm": {
                height: "600px",
                display: "flex",
                alignItems: "center",
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "24px",
                backgroundSize: "cover",
                justifyContent: "center",
                backgroundImage:
                  'url("https://images.unsplash.com/photo-1504204267155-aaad8e81290d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
              },
            },
            children: [
              {
                id: "2586418b-9eaa-47a7-9c60-c80d89703d0c",
                type: "text",
                props: {
                  text: "<h1>Slide Title</h1>",
                  style: {
                    fontSize: "40px",
                    fontFamily: "Assistant, sans-serif",
                    fontWeight: "700",
                    lineHeight: "42px",
                  },
                },
              },
              {
                id: "2a54d50c-58ff-4a25-b7f6-891542c07831",
                type: "text",
                props: {
                  text: "<p>Slide 2 of 4</p>",
                  style: {
                    fontSize: "20px",
                    fontFamily: "Assistant, sans-serif",
                    fontWeight: "400",
                    lineHeight: "24px",
                  },
                },
              },
              {
                id: "219584e6-0a92-42c9-8204-8aecdb0fc586",
                type: "button",
                props: {
                  style: {
                    color: "#FFFFFF",
                    display: "flex",
                    paddingTop: "16px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    paddingBottom: "16px",
                    backgroundColor: "#000000",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                },
                children: [
                  {
                    id: "067f86b9-0ac1-4e37-a450-757814851d7c",
                    type: "text",
                    props: {
                      text: "<p>Button</p>",
                      style: {
                        color: "#FFFFFF",
                        flexGrow: 1,
                        fontSize: "20px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: "2px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "669d579e-cac3-4f9b-ac04-51355200bb67",
            name: "Slide 3",
            type: "container",
            props: {
              style: {
                height: "600px",
                __width: "auto",
                display: "flex",
                __height: "600px",
                flexGrow: "1",
                __flexGap: "12px",
                alignItems: "flex-start",
                paddingTop: "120px",
                paddingLeft: "120px",
                paddingRight: "120px",
                flexDirection: "column",
                paddingBottom: "120px",
                backgroundSize: "cover",
                justifyContent: "center",
                backgroundImage:
                  'url("https://images.unsplash.com/photo-1471086569966-db3eebc25a59?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
                backgroundPositionX: "0px",
                backgroundPositionY: "0px",
              },
              "style@md": {
                height: "600px",
                alignSelf: "auto",
                paddingTop: "120px",
                paddingLeft: "120px",
                paddingRight: "120px",
                paddingBottom: "120px",
              },
              "style@sm": {
                height: "600px",
                display: "flex",
                alignItems: "center",
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "24px",
                backgroundSize: "cover",
                justifyContent: "center",
                backgroundImage:
                  'url("https://images.unsplash.com/photo-1471086569966-db3eebc25a59?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
              },
            },
            children: [
              {
                id: "85740119-b638-42cb-a68b-d56032d5b2e1",
                type: "text",
                props: {
                  text: "<h1>Slide Title</h1>",
                  style: {
                    fontSize: "40px",
                    fontFamily: "Assistant, sans-serif",
                    fontWeight: "700",
                    lineHeight: "42px",
                  },
                },
              },
              {
                id: "d838d6bc-fb25-45d3-a369-8cefb5aaab03",
                type: "text",
                props: {
                  text: "<p>Slide 3 of 4</p>",
                  style: {
                    fontSize: "20px",
                    fontFamily: "Assistant, sans-serif",
                    fontWeight: "400",
                    lineHeight: "24px",
                  },
                },
              },
              {
                id: "a576d56d-920d-446f-ba36-b4b6b143975b",
                type: "button",
                props: {
                  style: {
                    color: "#FFFFFF",
                    display: "flex",
                    paddingTop: "16px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    paddingBottom: "16px",
                    backgroundColor: "#000000",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                },
                children: [
                  {
                    id: "12ed6246-aafb-4ec9-b31b-37b0845809b2",
                    type: "text",
                    props: {
                      text: "<p>Button</p>",
                      style: {
                        color: "#FFFFFF",
                        flexGrow: 1,
                        fontSize: "20px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: "2px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "64068c1e-55e5-413c-b57a-01d0d365c425",
            name: "Slide 4",
            type: "container",
            props: {
              style: {
                height: "600px",
                __width: "auto",
                display: "flex",
                __height: "600px",
                flexGrow: "1",
                __flexGap: "12px",
                alignItems: "flex-start",
                paddingTop: "120px",
                paddingLeft: "120px",
                paddingRight: "120px",
                flexDirection: "column",
                paddingBottom: "120px",
                backgroundSize: "cover",
                justifyContent: "center",
                backgroundImage:
                  'url("https://images.unsplash.com/photo-1504198322253-cfa87a0ff25f?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
                backgroundPositionX: "0px",
                backgroundPositionY: "0px",
              },
              "style@md": {
                height: "600px",
                alignSelf: "auto",
                paddingTop: "120px",
                paddingLeft: "120px",
                paddingRight: "120px",
                paddingBottom: "120px",
              },
              "style@sm": {
                height: "600px",
                display: "flex",
                alignSelf: "stretch",
                alignItems: "center",
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "24px",
                backgroundSize: "cover",
                justifyContent: "center",
                backgroundImage:
                  'url("https://images.unsplash.com/photo-1504198322253-cfa87a0ff25f?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
              },
            },
            children: [
              {
                id: "b213b95f-abb0-43fa-ae45-0b5cb252336e",
                type: "text",
                props: {
                  text: "<h1>Slide Title</h1>",
                  style: {
                    fontSize: "40px",
                    fontFamily: "Assistant, sans-serif",
                    fontWeight: "700",
                    lineHeight: "42px",
                  },
                },
              },
              {
                id: "6be5c86d-c058-473e-9386-d2d273f88447",
                type: "text",
                props: {
                  text: "<p>Slide 4 of 4</p>",
                  style: {
                    fontSize: "20px",
                    fontFamily: "Assistant, sans-serif",
                    fontWeight: "400",
                    lineHeight: "24px",
                  },
                },
              },
              {
                id: "56927535-21b8-4a85-be18-4ae89efe8248",
                type: "button",
                props: {
                  style: {
                    color: "#FFFFFF",
                    display: "flex",
                    paddingTop: "16px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    paddingBottom: "16px",
                    backgroundColor: "#000000",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                },
                children: [
                  {
                    id: "834c7410-a047-4449-81a1-b2ae127339ea",
                    type: "text",
                    props: {
                      text: "<p>Button</p>",
                      style: {
                        color: "#FFFFFF",
                        flexGrow: 1,
                        fontSize: "20px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: "2px",
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "9d500ed0-c985-417f-b55c-40f29ede0032",
        name: "Next Button",
        type: "carouselV3Control",
        props: {
          style: {
            top: "50%",
            right: "0px",
            cursor: "pointer",
            zIndex: 1,
            display: "flex",
            position: "absolute",
            alignItems: "center",
            __transform: {
              skewX: "0deg",
              skewY: "0deg",
              scaleX: "100%",
              scaleY: "100%",
              scaleZ: "100%",
              rotateX: "0deg",
              rotateY: "0deg",
              rotateZ: "0deg",
              translateX: "0px",
              translateY: "-50%",
              translateZ: "0px",
            },
            justifyContent: "center",
          },
          direction: "next",
        },
        children: [
          {
            id: "75371f2e-4ff8-4d9b-a99c-ad1bd2830675",
            type: "icon",
            props: {
              style: {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
              },
              iconName: "chevronRight",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "efddb0fb-af70-421c-9642-6c26e80c62ef",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "ce593bbe-c836-4827-ac85-952d1e617698",
            name: "Last Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fa7db26c-7755-450b-b10f-b5f0f5c6a624",
                  field: "state.group.isLastItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "ce593bbe-c836-4827-ac85-952d1e617698": {
            componentOverrides: {
              "75371f2e-4ff8-4d9b-a99c-ad1bd2830675": {
                props: {
                  style: {
                    opacity: "20%",
                  },
                },
              },
              "9d500ed0-c985-417f-b55c-40f29ede0032": {
                props: {
                  style: {
                    cursor: "default",
                  },
                },
              },
            },
          },
        },
      },
      {
        id: "f4d575e3-2ded-452d-a4d4-ae94938729e9",
        name: "Indicators",
        type: "carouselV3Indicators",
        props: {
          style: {
            left: "50%",
            bottom: "0px",
            zIndex: 2,
            display: "flex",
            position: "absolute",
            alignItems: "center",
            paddingTop: "16px",
            __transform: {
              skewX: "0deg",
              skewY: "0deg",
              scaleX: "100%",
              scaleY: "100%",
              scaleZ: "100%",
              rotateX: "0deg",
              rotateY: "0deg",
              rotateZ: "0deg",
              translateX: "-50%",
              translateY: "0px",
              translateZ: "0px",
            },
            paddingBottom: "16px",
            justifyContent: "center",
          },
        },
        children: [
          {
            id: "e7a00ae1-af94-4d8a-a09a-1321fa847b93",
            name: "Container 5",
            type: "container",
            props: {
              style: {
                width: "10px",
                cursor: "pointer",
                height: "10px",
                __width: "10px",
                display: "flex",
                __height: "10px",
                flexGrow: "unset",
                maxWidth: "100%",
                alignSelf: "auto",
                alignItems: "flex-start",
                marginLeft: "10px",
                flexDirection: "row",
                justifyContent: "flex-start",
                backgroundColor: "#0000004D",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
              },
            },
            children: [],
            variants: [
              {
                id: "1e701973-6817-4e80-89dc-0d8ef793ed4c",
                name: "default",
                query: null,
              },
              {
                id: "1b4f580a-6a0f-4296-947c-262c09d0fc5d",
                name: "Current item is active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "a5eefa18-3bc3-4890-a191-335f2c071f83",
                      field: "state.group.isCurrentItemActive",
                    },
                  ],
                },
              },
            ],
            variantOverrides: {
              "1b4f580a-6a0f-4296-947c-262c09d0fc5d": {
                componentOverrides: {
                  "e7a00ae1-af94-4d8a-a09a-1321fa847b93": {
                    props: {
                      style: {
                        backgroundColor: "#000000FF",
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
    ],
  },
};
