import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import {
  CircleAlert,
  CircleCheck,
  Info,
  MessageCircle,
  Rocket,
  TriangleAlert,
  X,
} from "lucide-react";

export type InlineAlertProps = {
  variant:
    | "success"
    | "info"
    | "error"
    | "warning"
    | "neutral"
    | "announcement";
  children?: React.ReactNode;
  UNSAFE_className?: string;
  layoutClassName?: string;
  multiline?: boolean;
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  centerAlign?: boolean;
};

const InlineAlert: React.FC<InlineAlertProps> = ({
  variant,
  children,
  UNSAFE_className,
  layoutClassName,
  multiline = false,
  onClose,
  endEnhancer,
  startEnhancer,
  centerAlign = false,
}) => {
  return (
    <div
      className={twMerge(
        "flex items-center rounded-[6px] typ-body-small text-default p-2 border-[.5px] gap-2",
        multiline && "items-start p-3",
        centerAlign && "justify-center",
        variantClassNameMapping[variant],
        layoutClassName,
        UNSAFE_className,
      )}
    >
      {startEnhancer ?? startEnhancerMapping[variant]}
      <div
        className={twMerge(
          "flex grow justify-between gap-3",
          centerAlign && "grow-0",
          multiline && "flex-col items-start flex-wrap gap-2",
        )}
      >
        {children}
        {endEnhancer}
      </div>
      {onClose && (
        // Craig (2025-03-04) TODO: Replace with IconButton component after changes are made for tertiary button
        <button className="h-fit" onClick={onClose}>
          <X size={16} className="flex-shrink-0" />
        </button>
      )}
    </div>
  );
};

const variantClassNameMapping = {
  success: "bg-success-soft border-success",
  info: "bg-info-soft border-info",
  error: "bg-danger-soft border-danger",
  warning: "bg-attention-soft border-attention",
  neutral: "bg-neutral-soft border-neutral",
  announcement: "bg-beta-soft border-beta",
};

const startEnhancerMapping = {
  success: <CircleCheck size={16} className="flex-shrink-0 text-success" />,
  info: <Info size={16} className="flex-shrink-0 text-info" />,
  error: <CircleAlert size={16} className="flex-shrink-0 text-danger" />,
  warning: <TriangleAlert size={16} className="flex-shrink-0 text-attention" />,
  neutral: <MessageCircle size={16} className="flex-shrink-0 text-neutral" />,
  announcement: <Rocket size={16} className="flex-shrink-0 text-beta" />,
};

export default InlineAlert;
