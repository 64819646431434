import type { DataTableColumnType } from "replo-runtime/shared/DataTable";
import type { ProductRef } from "schemas/product";

import * as React from "react";

import Input from "@common/designSystem/Input";
import { ProductSelector } from "@common/designSystem/ProductSelector";
import {
  arrayMove,
  SortableItem,
  SortableList,
} from "@common/designSystem/SortableList";
import PopupRichTextEditor from "@components/editor/page/data-tables-page/PopupRichTextEditor";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import ImageSourceSelector from "@editor/components/editor/page/element-editor/components/modifiers/ImageSourceSelector";
import SolidColorSelector from "@editorComponents/SolidColorSelector";

import Button from "@replo/design-system/components/button/Button";
import { BiFontColor, BiGift, BiImage, BiImages, BiText } from "react-icons/bi";
import { FiMinus } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";

type DataCollectionComponentProps<ValueType, ChangeHandlerValue = ValueType> = {
  value: ValueType;
  onChange: (value: ChangeHandlerValue) => void;
  rowIndex?: number;
  columnIndex?: number;
};

type DataCollectionColumnEditorData<
  ValueType = any,
  ChangeHandlerValue = ValueType,
> = {
  component: React.FC<
    DataCollectionComponentProps<ValueType, ChangeHandlerValue>
  >;
  icon: React.ReactNode;
  transformValue?: (value: ChangeHandlerValue) => ValueType;
  displayName: string;
  defaultValue: ValueType;
  width: string;
};

export const dataCollectionColumnTypeToEditorData = {
  product: {
    component: function DataTableColumnTypeProduct({ value, onChange }) {
      return (
        <ProductSelector
          selectedProductRef={value}
          onChange={onChange}
          productRequestType="productsFromDataCollections"
          isMultiProducts={false}
          allowDynamicData={false}
          className="w-full"
        />
      );
    },
    icon: <BiGift size="32" />,
    displayName: "Shopify Product",
    // Note (Chance 2023-07-18) Feels like this should just be null, but unsure
    // if that would have downstream effects. I changed the `variantId` from an
    // empty string to `NaN` because it is technically TS-correct, and they are
    // both falsey and should satisfy the same checks in ProductSelector
    defaultValue: { productId: "", variantId: undefined },
    width: "200px",
  } satisfies DataCollectionColumnEditorData<ProductRef | null>,

  text: {
    component: function DataTableColumnTypeText({
      value,
      onChange,
      rowIndex = 0,
      columnIndex = 0,
    }) {
      return (
        <PopupRichTextEditor
          value={value?.text}
          id={`${rowIndex.toString()}-${columnIndex.toString()}`}
          onChange={onChange}
        />
      );
    },
    icon: <BiText size="32" />,
    displayName: "Rich Text",
    defaultValue: { text: "" },
    width: "200px",
    transformValue: (value) => ({ text: value }),
  } satisfies DataCollectionColumnEditorData<
    { text: string } | undefined,
    string
  >,

  plainText: {
    component: function DataTableColumnTypePlainText({
      value: providedValue,
      onChange,
    }) {
      const inputProps = useOverridableInput({
        value: providedValue?.text ?? "",
        onValueChange: onChange,
      });

      return <Input unsafe_className="bg-gray-100" {...inputProps} />;
    },
    icon: <BiText size="32" />,
    displayName: "Plain Text",
    defaultValue: { text: "" },
    width: "200px",
    transformValue: (value) => ({ text: value }),
  } satisfies DataCollectionColumnEditorData<
    { text: string } | undefined,
    string
  >,

  color: {
    component: function DataTableColumnTypeColor({ value, onChange }) {
      const _onChange = React.useCallback(
        (color: string | null) => {
          onChange({ type: "solid", color });
        },
        [onChange],
      );
      return (
        <SolidColorSelector onChange={_onChange} value={value?.color ?? null} />
      );
    },
    icon: <BiFontColor size="32" />,
    displayName: "Color",
    defaultValue: { type: "solid", color: "" },
    width: "200px",
  } satisfies DataCollectionColumnEditorData<
    { type: "solid"; color: string | null } | undefined
  >,

  image: {
    component: function DataTableColumnTypeImage({ value, onChange }) {
      return (
        <ImageSourceSelector
          size="sm"
          src={value?.imageSource}
          onChangeImageSource={(value) => {
            onChange({ imageSource: value });
          }}
          onRemove={() => onChange({ imageSource: "" })}
          allowsDynamicData={false}
        />
      );
    },
    icon: <BiImage size="32" />,
    displayName: "Image",
    // Note (Chance 2023-07-18) Is this correct? The value uses an `imageSource`
    // key so the effect on `ImageSourceSelector` is essentially just passing
    // undefined to `src`. Unsure if this needs to be updated or may have
    // downstream effects.
    // @ts-expect-error
    defaultValue: { __imageSource: "" },
    width: "200px",
  } satisfies DataCollectionColumnEditorData<{ imageSource: string } | null>,

  imageArray: {
    component: function DataTableColumnTypeImageArray({
      value: values,
      onChange,
    }) {
      const imageSources = {
        value: (values.value ?? []).map((v) => {
          return v.id ? v : { ...v, id: uuidv4() };
        }),
      };
      const onChangeImageSource = (value: string, index: number) => {
        const newValue = [...imageSources.value];
        newValue[index] = {
          id: newValue[index]?.id ?? uuidv4(),
          imageSource: value,
        };
        onChange({ value: newValue });
      };

      return (
        <div
          className={`flex flex-col gap-1 justify-center ${
            imageSources.value?.length > 0 ? "items-end" : "items-start"
          }`}
        >
          <SortableList
            onReorderEnd={({ oldIndex, newIndex }) => {
              onChange({
                ...imageSources,
                value: arrayMove(imageSources.value ?? [], oldIndex, newIndex),
              });
            }}
            withDragHandle
            withShadow={false}
          >
            {(imageSources.value ?? []).map((item, index) => {
              const itemKey = item.id ?? item.imageSource ?? `no-img-${index}`;
              return (
                <SortableItem key={itemKey} id={itemKey}>
                  <div className="flex flex-row items-center justify-center gap-1">
                    <ImageSourceSelector
                      size="sm"
                      src={item.imageSource}
                      onChangeImageSource={(value: string) =>
                        onChangeImageSource(value, index)
                      }
                      allowsDynamicData={false}
                      onRemove={() => onChangeImageSource("", index)}
                    />
                    <FiMinus
                      className="cursor-pointer"
                      size={20}
                      onClick={() => {
                        const newValue = [...(imageSources.value ?? [])];
                        newValue.splice(index, 1);
                        onChange({
                          ...imageSources,
                          value: newValue,
                        });
                      }}
                    />
                  </div>
                </SortableItem>
              );
            })}
          </SortableList>
          <Button
            onClick={() => {
              onChange({
                ...imageSources,
                value: (imageSources.value ?? []).concat([
                  { imageSource: null as unknown as string },
                ]),
              });
            }}
            UNSAFE_className="rounded-md border-black"
            variant="secondary"
          >
            Add Image
          </Button>
        </div>
      );
    },
    icon: <BiImages size="32" />,
    displayName: "Image List",
    // Note (Chance 2023-07-18) Is this correct? Shouldn't this be
    // `{ value: [] }`?
    // @ts-expect-error
    defaultValue: [],
    width: "230px",
  } satisfies DataCollectionColumnEditorData<{
    value: { imageSource: string; id?: string }[];
  }>,
} satisfies Record<DataTableColumnType, DataCollectionColumnEditorData>;
