import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import * as React from "react";

import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

function useShopStyles() {
  const projectId = useEditorSelector(selectProjectId);
  const { data: designLibrary, isLoading } = trpc.designLibrary.get.useQuery(
    projectId ? { projectId } : skipToken,
  );

  const shopStylesIncludingDeletedOnes = React.useMemo(() => {
    if (!designLibrary?.savedStyles) {
      return [];
    }

    return Object.entries(designLibrary.savedStyles).map(([id, savedStyle]) => {
      return { ...savedStyle, id };
    });
  }, [designLibrary?.savedStyles]);

  const { textShopStyles, colorShopStyles } = React.useMemo(() => {
    const shopStyles = shopStylesIncludingDeletedOnes.reduce(
      (savedStyles, savedStyle) => {
        if (savedStyle.deletedAt) {
          return savedStyles;
        }

        if (savedStyle.type === "text") {
          savedStyles.textSavedStyles.push(savedStyle);
        } else {
          savedStyles.colorSavedStyles.push(savedStyle);
        }
        return savedStyles;
      },
      {
        colorSavedStyles: [],
        textSavedStyles: [],
      } as {
        colorSavedStyles: (SavedColorStyle & { id: string })[];
        textSavedStyles: (SavedTextStyle & { id: string })[];
      },
    );

    const sortedTextShopStyles = shopStyles.textSavedStyles.sort(
      (textStyleA, textStyleB) => {
        const headingOrder: Record<string, number> = {
          h1: 1,
          h2: 2,
          h3: 3,
          h4: 4,
          h5: 5,
          h6: 6,
          p: 7,
        };

        const tagA = textStyleA.attributes.htmlTag;
        const tagB = textStyleB.attributes.htmlTag;

        const orderA = headingOrder[tagA] || 999;
        const orderB = headingOrder[tagB] || 999;

        if (orderA !== orderB) {
          return orderA - orderB;
        }

        return textStyleA.name.localeCompare(textStyleB.name);
      },
    );

    const sortedColorShopStyles = shopStyles.colorSavedStyles.sort(
      (colorStyleA, colorStyleB) =>
        colorStyleA.name.localeCompare(colorStyleB.name),
    );

    return {
      textShopStyles: sortedTextShopStyles,
      colorShopStyles: sortedColorShopStyles,
    };
  }, [shopStylesIncludingDeletedOnes]);

  return {
    textShopStyles,
    colorShopStyles,
    designLibrary,
    shopStylesIncludingDeletedOnes,
    isLoading,
  };
}

export default useShopStyles;
