import type { ComponentTemplate } from "@editor/types/component-template";

export const tikTokEmbed: ComponentTemplate = {
  id: "tikTokEmbed",
  storeId: null,
  scope: "left-bar",
  type: "component",

  name: "Video/TikTok",
  leftBarCategory: { main: "media", subCategory: "video" },
  tags: ["embed", "video"],
  preview: "video/tiktok-embed.svg",

  template: {
    id: "a782f056-c006-495a-a3c5-f1c28673b649",
    type: "tikTokEmbed",
    props: {},
  },
};
