import * as React from "react";

import Input from "@common/designSystem/Input";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import ColorSavedStyleGroup from "@editor/components/designLibrary/ColorSavedStyleGroup";
import TextSavedStyleGroup from "@editor/components/designLibrary/TextSavedStyleGroup";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpcClient } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button/Button";
import { useForm } from "react-hook-form";
import { BsCheckCircleFill, BsStars } from "react-icons/bs";
import { animated, config, useTransition } from "react-spring";
import { urlFormSchema } from "schemas/url";
import { v4 as uuidv4 } from "uuid";

import Separator from "../common/designSystem/Separator";
import { normalizeFontFamily } from "../editor/page/element-editor/components/modifiers/utils";
import IntegrateWithShopifyCard from "./IntegrateWithShopifyCard";
import PresetShopStylePreviewCards from "./PresetShopStylePreviewCards";
import ShopStylesPreviewCard from "./ShopStylesPreviewCard";
import { useDraftStyles } from "./useDraftStyles";

type FormValues = {
  url: string;
};

const SavedStylesEditor: React.FC = () => {
  const logEvent = useLogAnalytics();
  const [isImportingStyles, setIsImportingStyles] = React.useState(false);
  const [viewState, setViewState] = React.useState<"standard" | "pullFromUrl">(
    "standard",
  );
  const { project } = useCurrentProjectContext();
  const projectId = project?.id;
  const errorToast = useErrorToast();
  const workspaceId = useCurrentWorkspaceId();

  const {
    draftStyles,
    isLoading,
    setDraftStyles,
    areDraftStylesDifferentFromShopStyles,
    persistDraftStyles,
    applyPresetStyles,
    createDraftTextStyle,
    updateDraftTextStyle,
    deleteDraftTextStyle,
    createDraftColorStyle,
    updateDraftColorStyle,
    deleteDraftColorStyle,
  } = useDraftStyles();

  const projectHasShopifyIntegration = Boolean(project?.integrations?.shopify);

  const { colorShopStyles, textShopStyles } = useShopStyles();

  const handleCancel = () => {
    setDraftStyles({
      text: textShopStyles || [],
      color: colorShopStyles || [],
    });
  };

  const hasSavedStyles =
    draftStyles !== null &&
    (draftStyles.text.length > 0 || draftStyles.color.length > 0);

  // Find the highest level heading style and paragraph style
  const { highestHeadingStyle, paragraphStyle } = React.useMemo(() => {
    const headingPriority = ["h1", "h2", "h3", "h4", "h5", "h6"];
    let highestHeadingStyle = undefined;
    let paragraphStyle = undefined;

    const stylesToUse = draftStyles?.text || textShopStyles || [];

    if (stylesToUse.length > 0) {
      for (const tag of headingPriority) {
        const foundStyle = stylesToUse.find(
          (style) => style.type === "text" && style.attributes?.htmlTag === tag,
        );

        if (foundStyle) {
          highestHeadingStyle = foundStyle;
          break;
        }
      }

      paragraphStyle = stylesToUse.find(
        (style) => style.type === "text" && style.attributes?.htmlTag === "p",
      );
    }

    return {
      highestHeadingStyle,
      paragraphStyle,
    };
  }, [draftStyles, textShopStyles]);

  async function handleUrlSubmit(url: string) {
    setIsImportingStyles(true);

    try {
      const { color: colorStyles, text: textStyles } =
        await trpcClient.ai.generatePrimaryStyles.query({
          url,
          projectId,
        });

      setDraftStyles({
        text:
          textStyles?.map((style) => ({
            ...style,
            id: uuidv4(),
            attributes: {
              ...style.attributes,
              fontFamily: normalizeFontFamily(
                style.attributes.fontFamily ?? null,
              ),
              lineHeight:
                style.attributes.lineHeight ||
                (() => {
                  const fontSize = style.attributes.fontSize;
                  if (!fontSize) {
                    return undefined;
                  }

                  const fontSizeValue = Number.parseInt(
                    fontSize.replace(/\D/g, ""),
                  );
                  if (isNaN(fontSizeValue)) {
                    return undefined;
                  }

                  return `${fontSizeValue + 4}px`;
                })(),
            },
          })) || [],
        color: colorStyles?.map((style) => ({ ...style, id: uuidv4() })) || [],
      });
    } catch {
      errorToast(
        "Failed to generate styles",
        "Please try again, or contact support@replo.app for help.",
        "error.ai.generatePrimaryStyles",
        {},
      );
    }
    setViewState("standard");
    setIsImportingStyles(false);
  }

  const displayColorStyles = draftStyles?.color ?? colorShopStyles;

  const showIntegrateWithShopifyCard =
    !projectHasShopifyIntegration && !hasSavedStyles;

  const showPresetStyleList =
    !hasSavedStyles && !isImportingStyles && viewState === "standard";

  const showPullFromUrlView =
    !hasSavedStyles && !isImportingStyles && viewState === "pullFromUrl";

  const showSaveChanges =
    !isImportingStyles &&
    viewState === "standard" &&
    (hasSavedStyles ||
      (showPresetStyleList && areDraftStylesDifferentFromShopStyles));

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col w-full h-full gap-3 relative">
      {showIntegrateWithShopifyCard && (
        <>
          <IntegrateWithShopifyCard
            cookieValue={{
              type: "shopStyles",
              workspaceId: workspaceId!,
              projectId: projectId!,
            }}
          />
          <div className="flex items-center gap-2">
            <Separator />
            <span className="typ-body-small text-muted">or</span>
            <Separator />
          </div>
        </>
      )}
      {hasSavedStyles && (
        <>
          <ShopStylesPreviewCard
            headingTextStyle={
              highestHeadingStyle ?? {
                type: "text",
                name: "Default Header",
                attributes: {
                  type: "text",
                  htmlTag: "p",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "400",
                  color: "#000000",
                },
                optionalUsageGuidelines: null,
                isPrimary: false,
              }
            }
            bodyTextStyle={
              paragraphStyle ?? {
                type: "text",
                name: "Default Paragraph",
                attributes: {
                  type: "text",
                  htmlTag: "p",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "400",
                  color: "#000000",
                },
                optionalUsageGuidelines: null,
                isPrimary: false,
              }
            }
            colorStyles={displayColorStyles ?? []}
          />
          <Separator />
        </>
      )}

      {isImportingStyles && (
        <PullFromUrlLoadingState isLoading={isImportingStyles} />
      )}

      {hasSavedStyles && (
        <div className="pr-1">
          <TextSavedStyleGroup
            highestHeadingStyle={highestHeadingStyle}
            paragraphStyle={paragraphStyle}
            isLoading={isLoading}
            createDraftTextStyle={createDraftTextStyle}
            updateDraftTextStyle={updateDraftTextStyle}
            deleteDraftTextStyle={deleteDraftTextStyle}
            draftStyles={draftStyles}
          />
          <ColorSavedStyleGroup
            isLoading={isLoading}
            createDraftColorStyle={createDraftColorStyle}
            updateDraftColorStyle={updateDraftColorStyle}
            deleteDraftColorStyle={deleteDraftColorStyle}
            draftStyles={draftStyles}
          />
        </div>
      )}

      {showPresetStyleList && (
        <>
          <div className="w-full flex flex-row items-center justify-between">
            <div className="typ-header-small">Start with a preset</div>
            <Button variant="link" onClick={() => setViewState("pullFromUrl")}>
              Pull from URL
            </Button>
          </div>
          <PresetShopStylePreviewCards
            isLoading={isLoading}
            applyPresetStyles={applyPresetStyles}
          />
        </>
      )}
      {showPullFromUrlView && (
        <PullFromUrlForm
          onCancel={() => setViewState("standard")}
          onSubmit={(url) => {
            setIsImportingStyles(true);
            void handleUrlSubmit(url);
          }}
        />
      )}
      {showSaveChanges && (
        <div className="sticky bottom-0 bg-white w-full z-50">
          <Separator className="mb-4" />
          <div className="flex flex-row gap-3 mb-3">
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                logEvent("shopDetails.styles.save");
                void persistDraftStyles();
              }}
              isLoading={isLoading}
              disabled={!areDraftStylesDifferentFromShopStyles}
            >
              Save
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                logEvent("shopDetails.styles.cancel");
                handleCancel();
              }}
              disabled={!areDraftStylesDifferentFromShopStyles}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const PullFromUrlLoadingState: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  const { project } = useCurrentProjectContext();
  const projectName = project?.name;

  const timeoutsRef = React.useRef<ReturnType<typeof setTimeout>[]>([]);
  const [content, setContent] = React.useState<string[]>([
    "Analyzing text styles...",
  ]);
  const transitions = useTransition(content, {
    from: { opacity: 0, innerHeight: 0 },
    enter: { opacity: 1, innerHeight: 28 },
    config: config.stiff,
  });

  React.useEffect(() => {
    // NOTE (Fran 2024-12-20): This is a multistage animation that runs when the loading state is triggered.
    // It's based on a React Spring example https://codesandbox.io/s/k467x.
    // The timings are not based on any real data, but they are designed to be
    // long enough to be noticeable, but not too long. Since the AI generation styles takes time,
    // we want to show the user that something is happening.
    if (isLoading) {
      const initLoadingAnimation = () => {
        timeoutsRef.current.forEach(clearTimeout);
        timeoutsRef.current = [];
        timeoutsRef.current.push(
          setTimeout(
            () => setContent((prev) => [...prev, "Parsing color palette..."]),
            5000,
          ),
        );
        timeoutsRef.current.push(
          setTimeout(
            () =>
              setContent((prev) => [
                ...prev,
                "Naming and tagging your styles...",
              ]),
            10_000,
          ),
        );
        timeoutsRef.current.push(
          setTimeout(
            () =>
              setContent((prev) => [
                ...prev,
                projectName
                  ? `Preparing styles for ${projectName}...`
                  : "Preparing styles...",
              ]),
            15_000,
          ),
        );
      };
      initLoadingAnimation();
    }
    return () => {
      timeoutsRef.current.forEach(clearTimeout);
      timeoutsRef.current = [];
    };
  }, [isLoading, projectName]);

  return (
    <div className="flex flex-col gap-2 border rounded-md p-4 items-center justify-center h-40 transition-all">
      {transitions(({ innerHeight, ...styles }, item, _, index) => {
        const isLast = index === content.length - 1;
        return (
          <animated.div
            style={styles}
            className="flex items-center gap-2 w-full text-default"
          >
            <animated.div
              style={{ maxHeight: innerHeight }}
              className="flex gap-2"
            >
              {!isLast ? (
                <BsCheckCircleFill
                  size={16}
                  className="text-primary shrink-0"
                />
              ) : (
                <BsStars size={16} className="text-primary shrink-0" />
              )}
              <span className="typ-body-small">{item}</span>
            </animated.div>
          </animated.div>
        );
      })}
    </div>
  );
};

const PullFromUrlForm: React.FC<{
  onCancel: () => void;
  onSubmit: (url: string) => void;
}> = ({ onCancel, onSubmit }) => {
  const logEvent = useLogAnalytics();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      url: "",
    },
    resolver: zodResolver(urlFormSchema),
  });

  const urlError = errors.url?.message;
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmitForm = async ({ url }: FormValues) => {
    setIsLoading(true);
    logEvent("library.style.import", {
      importMethod: "url",
    });
    onSubmit(url);
  };

  return (
    <form
      className="flex flex-col gap-3"
      onSubmit={(e) => {
        e.preventDefault();
        void handleSubmit(onSubmitForm)(e);
      }}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div className="typ-header-small">Enter URL</div>
        <Button variant="link" onClick={onCancel}>
          Show Presets
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <Input
          aria-invalid={Boolean(urlError) ? "true" : undefined}
          aria-describedby={Boolean(urlError) ? "error-url" : undefined}
          autoComplete="off"
          placeholder="https://www.mywebsite.com"
          {...register("url")}
          type="text"
          size="base"
          validityState={Boolean(urlError) ? "invalid" : "valid"}
          autoFocus
        />
        {urlError && <ErrorMessage id="error-url" error={urlError} />}
      </div>
      <div className="flex flex-row items-center justify-end">
        <Button
          type="submit"
          variant="primary"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Pull from URL
        </Button>
      </div>
    </form>
  );
};

export default SavedStylesEditor;
