import type { RuntimeContextNullableValueMap } from "replo-runtime/shared/runtime-context";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type {
  ReploElement,
  ReploElementMetadata,
  ReploElementType,
} from "schemas/generated/element";
import type { CanvasFrameRefs, CanvasState } from "./canvas-types";

import * as React from "react";

import { HEADER_HEIGHT } from "@components/editor/constants";
import { AITemplateLoadingState } from "@editor/components/common/ai-template-loading/AITemplateLoadingState";
import {
  useInitComponentErrorContext,
  useInitComponentInventoryContext,
  useInitComponentUpdateContext,
  useInitCustomFontsContext,
  useInitDraftElementContext,
  useInitDynamicDataStoreContext,
  useInitExtraContext,
  useInitFeatureFlagsContext,
  useInitRenderEnvironmentContext,
  useInitReploEditorActiveCanvasContext,
  useInitReploEditorCanvasContext,
  useInitReploElementContext,
  useInitReploSymbolsContext,
  useInitRuntimeHooksContext,
  useInitShopifyStoreContext,
  useInitSyncRuntimeStateContext,
} from "@editor/contexts/editor-runtime-context";
import useAutoRefetchCanvasDocument from "@editor/hooks/useAutoRefetchCanvasDocument";
import useContextMenuItems from "@editor/hooks/useContextMenuItems";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useDraftElementMetadata } from "@editor/hooks/useDraftElementMetadata";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useRightBarVisibility from "@editor/hooks/useRightBarVisibility";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { trackError } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectDraftComponentId,
  selectDraftElementId,
  selectEditorMode,
  selectIsPreviewMode,
  selectPendingElementUpdatesSize,
  selectSrcDocRootFont,
} from "@editor/reducers/core-reducer";
import { selectIsRightBarVisible } from "@editor/selectors/ui";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { isDevelopment } from "@editor/utils/env";
import { injectStylesIntoDocument } from "@editor/utils/styles";

import { toast } from "@replo/design-system/components/alert/Toast";
import { Menu, MenuTrigger } from "@replo/design-system/components/menu/Menu";
import twMerge from "@replo/design-system/utils/twMerge";
import debounce from "lodash-es/debounce";
import mapValues from "lodash-es/mapValues";
import pick from "lodash-es/pick";
import useMeasure from "react-use-measure";
import { ErrorBoundary } from "replo-runtime/shared/ErrorBoundary";
import {
  hideAnnouncementBarCss,
  hideFooterCss,
  hideHeaderCss,
  WrappedAlchemyElement,
} from "replo-runtime/store/AlchemyElement";
import { fullPageQuerySelector } from "replo-runtime/store/utils/cssSelectors";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { useRequiredContext } from "replo-utils/react/context";

import { BoundingBoxes } from "./BoundingBoxes";
import { CANVAS_FRAME_GAP } from "./canvas-constants";
import { CanvasAreaContext, CanvasContext } from "./canvas-context";
import {
  resetFrameXPosition,
  resetFrameYPosition,
  selectActiveCanvas,
  selectActiveCanvasFrame,
  selectCanvases,
  selectCanvasFrameWidths,
  selectCanvasHtml,
  selectCanvasInteractionMode,
  selectCanvasWillChangeStatus,
  selectLargestVisibleCanvasHeight,
  selectPreviewWidth,
  selectVisibleCanvases,
  selectVisibleCanvasesTotalWidth,
  setCanvasArea,
  setCanvasHeight,
  setCanvasInteractionMode,
  setCanvasOffset,
  setCanvasWrapperFrame,
  setStateFromLocalStorage,
} from "./canvas-reducer";
import {
  getCanvasLocalStorageState,
  setCanvasLocalStorageState,
} from "./canvas-utils";
import { CanvasHeaderBar } from "./CanvasHeaderBar";
import { CanvasResizer } from "./CanvasResizer";
import { setupJss } from "./jss";
import { ReploCanvasFileDropZone } from "./ReploCanvasFileDropZone";
import {
  useDesignLibrary,
  useSyncRuntimeStoreWithEditorState,
} from "./stores/runtime";
import { useCanvasEnterLeaveMouseHandlers } from "./useCanvasEnterLeaveMouseHandlers";
import { useDragToPanCanvasMouseHandlers } from "./useDragToPanCanvasMouseHandlers";
import useGetElementsMapping from "./useGetElementsMapping";
import { usePaintTargetFrames } from "./usePaintTargetFrames";
import useSetActiveCanvas from "./useSetActiveCanvas";
import { useUpdateFramePositionStyles } from "./useUpdateFramePositionStyles";
import { useWheelHandler } from "./useWheelHandler";

setupJss();

let iFrameNotLoadedTimeout: NodeJS.Timeout | null = null;
let hasTriggeredTimeout = false;

export function CanvasArea() {
  const dispatch = useEditorDispatch();
  const setDraftElement = useSetDraftElement();
  const projectId = useCurrentProjectId();

  const store = useEditorStore();

  // NOTE (Kevin, 2025-04-01): Effect to reset canvas position (X and Y) on initial load
  // for a new project ID. This ensures a consistent, centered starting view.
  // Uses store.getState() to avoid subscribing to state changes that would trigger unnecessary resets
  React.useEffect(() => {
    if (projectId) {
      const isRightBarVisible = selectIsRightBarVisible(store.getState());
      dispatch(resetFrameXPosition({ isRightBarVisible }));
      dispatch(resetFrameYPosition());
    }
  }, [projectId, dispatch, store]);

  const canvasAreaRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      dispatch(setCanvasArea(node));
    },
    [dispatch],
  );

  const frameRefs = React.useRef<CanvasFrameRefs>(
    new Map([
      ["mobile", { element: null, isLoaded: false }],
      ["tablet", { element: null, isLoaded: false }],
      ["desktop", { element: null, isLoaded: false }],
    ]),
  );

  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const interactionMode = useEditorSelector(selectCanvasInteractionMode);
  const editorMode = useEditorSelector(selectEditorMode);
  const canvasHtml = useEditorSelector(selectCanvasHtml);
  const activeCanvasFrame = useEditorSelector(selectActiveCanvasFrame);

  const isContentEditing = interactionMode === "content-editing";
  const { currentDragType } = useCurrentDragType();

  usePersistCanvasSettings();
  useCanvasEnterLeaveMouseHandlers();
  useWheelHandler();

  let cursor: string;
  if (interactionMode === "readyToGrab") {
    cursor = "grab";
  } else if (interactionMode === "grabbing") {
    cursor = "grabbing";
  } else {
    cursor = currentDragType ? "grabbing" : "default";
  }

  const canvasEditorStyles: React.CSSProperties = {
    left: 0,
    top: `${HEADER_HEIGHT}px`,
    cursor,
  };
  const canvasPreviewStyles: React.CSSProperties = {
    top: `${HEADER_HEIGHT}px`,
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    cursor: currentDragType ? "grabbing" : "default",
    overflow: "hidden",
  };

  return (
    <div
      ref={canvasAreaRef}
      className="canvas unselectable fixed inset-0 w-full h-full"
      data-testid="canvas-background"
      style={isPreviewMode ? canvasPreviewStyles : canvasEditorStyles}
      onMouseDown={() => {
        if (interactionMode === "readyToGrab") {
          dispatch(setCanvasInteractionMode("grabbing"));
        }
      }}
      onClick={() => {
        if (isContentEditing) {
          dispatch(setCanvasInteractionMode("edit"));
          // Note (Ovishek, 2022-06-08): blur() is used so that hotkeys work
          activeCanvasFrame?.blur?.();
        } else if (editorMode === EditorMode.edit) {
          // Reset draft component when the canvas is clicked outside of
          // the rendered components.
          setDraftElement({ componentIds: [] });
        }
      }}
    >
      {!isPreviewMode && <CanvasHeaderBar />}
      <CanvasContextMenu>
        <CanvasAreaContext.Provider
          value={{
            canvasHtml,
            currentDragType,
            frameRefs,
          }}
        >
          <CanvasAreaImpl />
        </CanvasAreaContext.Provider>
      </CanvasContextMenu>
    </div>
  );
}

const CanvasContextMenu: React.FC<React.PropsWithChildren> = ({ children }) => {
  const contextMenuItems = useContextMenuItems("canvasRightClickMenu");

  return (
    <Menu
      disableOutsideInteractionsWhileOpen={false}
      menuType="context"
      items={contextMenuItems}
      customWidth={280}
      trigger={
        <MenuTrigger asChild>
          <div
            // Note (Noah, 2022-03-34): Cursor inherit here, since by default
            // the cursor is pointer for Menus and we don't want that to
            // interfere with other things that set the cursor styles in Canvas
            className="h-full cursor-[inherit] rounded-none"
          >
            {children}
          </div>
        </MenuTrigger>
      }
    />
  );
};

function CanvasAreaImpl() {
  const canvases = useEditorSelector(selectCanvases);
  const canvasFrameWidths = useEditorSelector(selectCanvasFrameWidths);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const previewWidth = useEditorSelector(selectPreviewWidth);

  useWindowResize();
  useResetDraftComponentIfNeeded();
  useAutoRefetchCanvasDocument();
  useExitPromptIfPendingUpdates();
  useResetCanvasOnElementChange();
  useDragToPanCanvasMouseHandlers();
  usePaintTargetFrames();
  useSyncRuntimeStoreWithEditorState();

  return (
    <CanvasWrapperFrame canvases={canvases}>
      <div className="absolute left-0 bottom-0 w-full h-full">
        <ErrorBoundary onError={handleCanvasRenderError}>
          <div
            className={twMerge(
              "h-full",
              isPreviewMode ? "overflow-y-scroll w-[calc(100%+15px)]" : "flex",
            )}
            style={{ gap: isPreviewMode ? 0 : CANVAS_FRAME_GAP }}
          >
            {Object.entries(canvases).map(([key, canvasState]) => {
              const {
                canvasHeight,
                canvasWidth,
                isVisible,
                targetFrame,
                canvasTopOffset,
                canvasLeftOffset,
                canvasBottomOffset,
              } = canvasState;
              const canvas = key as EditorCanvas;
              const frameWidth = canvasFrameWidths[canvas];
              if (!isVisible) {
                return null;
              }

              const canvasPreviewWidth = previewWidth - canvasLeftOffset * 2;

              return (
                <CanvasContext.Provider
                  key={key}
                  value={{
                    canvas,
                    // NOTE (Matt 2025-01-27): In no-mirror, canvasWidth and previewWidth are not really linked- frameWidth and previewWidth are.
                    canvasWidth: isPreviewMode
                      ? canvasPreviewWidth
                      : canvasWidth,
                    frameWidth: isPreviewMode ? previewWidth : frameWidth,
                    canvasHeight,
                    targetFrame,
                    canvasTopOffset,
                    canvasLeftOffset,
                    canvasBottomOffset,
                  }}
                >
                  <Canvas />
                  <CanvasOverlayWrapper>
                    <CanvasOverlay />
                    <CanvasResizer />
                  </CanvasOverlayWrapper>
                </CanvasContext.Provider>
              );
            })}
          </div>
        </ErrorBoundary>
      </div>
    </CanvasWrapperFrame>
  );
}

const CanvasWrapperFrame: React.FC<
  React.PropsWithChildren<{
    canvases: CanvasState["canvases"];
  }>
> = ({ canvases, children }) => {
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const canvasWillChangeStatus = useEditorSelector(
    selectCanvasWillChangeStatus,
  );
  const previewWidth = useEditorSelector(selectPreviewWidth);
  const canvasesTotalWidth = useEditorSelector(selectVisibleCanvasesTotalWidth);
  const largestCanvasHeight = useEditorSelector(
    selectLargestVisibleCanvasHeight,
  );
  const dispatch = useEditorDispatch();
  const frameRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      dispatch(setCanvasWrapperFrame(node));
    },
    [dispatch],
  );

  useUpdateFramePositionStyles({
    width: isPreviewMode ? previewWidth : canvasesTotalWidth,
    height: isPreviewMode ? canvases.desktop.canvasHeight : largestCanvasHeight,
  });

  return (
    <div
      ref={frameRef}
      className={twMerge("origin-top-left", isPreviewMode && "h-full")}
      onClick={(e) => e.stopPropagation()}
      style={{ willChange: isPreviewMode ? "auto" : canvasWillChangeStatus }}
    >
      {children}
    </div>
  );
};

const CanvasOverlayWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const {
    canvas,
    frameWidth,
    canvasHeight,
    canvasTopOffset,
    canvasLeftOffset,
  } = useRequiredContext(CanvasContext);
  const xOffset = useCanvasXOffset(canvas);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const isContentEditing =
    useEditorSelector(selectCanvasInteractionMode) === "content-editing";
  const { isAIPreLoading } = useAIStreaming();

  // NOTE (Martin, 2024-09-27): We don't need any of the overlay wrappers in
  // text editing mode since we need content editable component to be interactive.
  if (isContentEditing) {
    return null;
  }

  // NOTE (Martin, 2024-07-29): We don't need a wrapper div on preview mode
  // since overlay is gone and we will only have one set of resizers that can
  // accomodate themselves to the parent container.
  if (isPreviewMode) {
    return children;
  }

  return (
    <div
      className="absolute transition-spacing duration-300"
      style={{
        width: frameWidth,
        height: canvasHeight,
        left: xOffset,
        top: canvasTopOffset,
        paddingLeft: `${canvasLeftOffset}px`,
        paddingRight: `${canvasLeftOffset}px`,
      }}
    >
      <AITemplateLoadingState shouldShow={isAIPreLoading} />
      <div className="relative">{children}</div>
    </div>
  );
};

function CanvasOverlay() {
  const interactionMode = useEditorSelector(selectCanvasInteractionMode);
  const shouldShowCanvasOverlayForInteractionMode = exhaustiveSwitch({
    type: interactionMode,
  })({
    "dragging-components": true,
    edit: true,
    grabbing: false,
    readyToGrab: false,
    resizing: false,
    "content-editing": false,
    locked: true,
  });

  const editorMode = useEditorSelector(selectEditorMode);
  const shouldShowCanvasOverlayForEditorMode = exhaustiveSwitch({
    type: editorMode,
  })({
    [EditorMode.edit]: true,
    [EditorMode.aiGeneration]: true,
    [EditorMode.versioning]: false,
    [EditorMode.archived]: false,
    // Note (Noah, 2024-07-14, REPL-12588): We want to keep the overlay if we're in
    // preview mode but we're resizing the canvas frame, because if we don't, the mouse
    // will go outside of the ReactResizable which means onResize handlers won't be called
    [EditorMode.preview]: interactionMode === "resizing",
  });

  if (
    !shouldShowCanvasOverlayForEditorMode ||
    !shouldShowCanvasOverlayForInteractionMode
  ) {
    return null;
  }

  return (
    <PotentiallyInteractiveDropZone>
      <BoundingBoxes />
    </PotentiallyInteractiveDropZone>
  );
}

/**
 * Renders either a dropzone where users can upload images, OR just a normal div,
 * depending on the edit mode and interaction state of the canvas which it's rendered in.
 */
const PotentiallyInteractiveDropZone: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const interactionMode = useEditorSelector(selectCanvasInteractionMode);

  // Note (Noah, 2024-07-13): Important to keep this both for edit and dragging-components
  // because otherwise React will change the DOM structure when you begin to drag a
  // component, which will mess up the events from react-draggable and make drag and drop
  // not work
  if (interactionMode === "edit" || interactionMode === "dragging-components") {
    return (
      <ReploCanvasFileDropZone
        // NOTE (Chance 2024-06-10): File upload area is role `button` by
        // default, but in the canvas it doesn't work like a button so we can
        // just use `presentation` role to reset it like a normal div.
        role="presentation"
        className="absolute inset-0"
      >
        {children}
      </ReploCanvasFileDropZone>
    );
  }
  return <div className="absolute inset-0">{children}</div>;
};

function useMeasureCanvasElementHeight() {
  const { canvas, canvasHeight } = useRequiredContext(CanvasContext);
  const [ref, { height: canvasElementHeight }] = useMeasure({
    offsetSize: true,
  });
  const dispatch = useEditorDispatch();
  React.useEffect(() => {
    // Note (Jackson, 2025-01-18): We need to check if canvasElementHeight is
    // greater than 0, as the height of the canvas element is 0 when the
    // iframe is first loaded. This allows our initial height to take over
    // when loading
    if (canvasElementHeight > 0 && canvasHeight !== canvasElementHeight) {
      dispatch(
        setCanvasHeight({ canvas: canvas, height: canvasElementHeight }),
      );
    }
  }, [canvas, canvasHeight, dispatch, canvasElementHeight]);
  return { ref };
}

function Canvas() {
  const {
    canvas,
    canvasWidth,
    canvasHeight,
    canvasTopOffset,
    canvasLeftOffset,
    frameWidth,
    canvasBottomOffset,
  } = useRequiredContext(CanvasContext);
  const { canvasHtml } = useRequiredContext(CanvasAreaContext);
  const elementMapping = useGetElementsMapping();
  const draftElementMetadata = useDraftElementMetadata();
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const renderEnvironmentContext = useInitRenderEnvironmentContext();
  const componentErrorContext = useInitComponentErrorContext();
  const componentInventoryContext = useInitComponentInventoryContext();
  const componentUpdateContext = useInitComponentUpdateContext();
  const customFontsContext = useInitCustomFontsContext();
  const draftElementContext = useInitDraftElementContext();
  const dynamicDataStoreContext = useInitDynamicDataStoreContext();
  const shopifyStoreContext = useInitShopifyStoreContext();
  const extraContext = useInitExtraContext();
  const featureFlagsContext = useInitFeatureFlagsContext();
  const reploEditorCanvasContext = useInitReploEditorCanvasContext();
  const reploEditorActiveCanvasContext =
    useInitReploEditorActiveCanvasContext();
  const reploElementContext = useInitReploElementContext();
  const reploSymbolsContext = useInitReploSymbolsContext();
  const syncRuntimeStateContext = useInitSyncRuntimeStateContext();
  const runtimeHooksContext = useInitRuntimeHooksContext();
  const designLibrary = useDesignLibrary();
  const srcDocRootFont = useEditorSelector(selectSrcDocRootFont);

  const { ref: canvasElementRef } = useMeasureCanvasElementHeight();

  const contexts: RuntimeContextNullableValueMap = {
    editorCanvas: canvas,
    globalWindow: window,
    componentError: componentErrorContext,
    componentInventory: componentInventoryContext,
    componentUpdate: componentUpdateContext,
    customFonts: customFontsContext,
    draftElement: draftElementContext,
    dynamicDataStore: dynamicDataStoreContext,
    shopifyStore: shopifyStoreContext,
    syncRuntimeState: syncRuntimeStateContext,
    extraContext: extraContext,
    featureFlags: featureFlagsContext,
    renderEnvironment: renderEnvironmentContext,
    reploEditorCanvas: reploEditorCanvasContext,
    reploEditorActiveCanvas: reploEditorActiveCanvasContext,
    reploElement: reploElementContext,
    reploSymbols: reploSymbolsContext,
    runtimeHooks: runtimeHooksContext,
    designLibrary: { designLibrary },
  };

  const { draftElementId } = draftElementContext;

  if (!draftElementId || !elementMapping[draftElementId]) {
    return null;
  }

  // NOTE (Matt 2025-02-25): the ElementMapping does not include any recent changes a user may have
  // made to their elementMetadata (like hiding the Header or any Custom CSS).
  const draftElement = {
    ...elementMapping[draftElementId],
    ...draftElementMetadata,
  };

  return (
    <div
      className={twMerge("relative", isPreviewMode && "h-max")}
      style={{
        display: isPreviewMode && canvas !== "desktop" ? "none" : "block",
      }}
    >
      <CanvasBackground srcDoc={canvasHtml} element={draftElement} />
      <div
        ref={canvasElementRef}
        data-canvas-id={canvas}
        data-replo-canvas
        style={
          {
            width: canvasWidth,
            top: `${canvasTopOffset}px`,
            left: `${canvasLeftOffset}px`,
            fontFamily: srcDocRootFont ?? undefined,
            "--canvas-width": `${canvasWidth}px`,
            "--canvas-height": `${canvasHeight}px`,
          } as React.CSSProperties
        }
        className="absolute block"
      >
        {/* NOTE (Jackson 2025-01-28): This element serves as the injection point
        for the Popup component in the editor. In mirror land we had the iframe document
        as the parent of the Popup component, however in no-mirror land we have to
        construct a suitible parent for each canvas */}
        <div
          className="absolute h-full w-full pointer-events-none"
          style={{ zIndex: 1000 }}
        >
          <div
            style={{
              width: frameWidth,
              height: `calc(100% + ${canvasBottomOffset}px + ${canvasTopOffset}px)`,
              top: `-${canvasTopOffset}px`,
            }}
            className="relative"
            data-replo-canvas-modal-parent={canvas}
          ></div>
        </div>
        <WrappedAlchemyElement
          element={draftElement}
          contexts={contexts}
          srcDocRootFont={srcDocRootFont ?? undefined}
        />
      </div>
    </div>
  );
}

const PlaceholderHeader = ({
  elementType,
  isIframeLoaded,
  hideShopifyHeader,
}: {
  elementType: ReploElementType;
  hideShopifyHeader: boolean;
  isIframeLoaded: boolean;
}) => {
  if (hideShopifyHeader || elementType === "shopifySection") {
    return null;
  }

  return (
    <>
      <div
        className={twMerge(
          "hidden r-md:flex bg-slate-50 py-4 px-12 w-full items-center justify-between h-full",
          "transition-opacity duration-300",
          isIframeLoaded ? "opacity-0" : "opacity-100",
        )}
      >
        <div className="w-36 h-10 bg-slate-200 rounded-md"></div>
        <div className="flex justify-end grow gap-4">
          <div className="w-16 r-md:w-[12%] h-6 bg-slate-200 rounded-md"></div>
          <div className="hidden r-md:block w-16 r-md:w-[12%] h-6 bg-slate-200 rounded-md"></div>
          <div className="hidden r-md:block w-16 r-md:w-[12%] h-6 bg-slate-200 rounded-md"></div>
          <div className="hidden r-md:block w-[12%] h-6 bg-slate-200 rounded-md"></div>
        </div>
      </div>
      <div
        className={twMerge(
          "flex r-md:hidden bg-slate-50 py-4 px-12 w-full items-center justify-between h-full",
          "transition-opacity duration-300",
          isIframeLoaded ? "opacity-0" : "opacity-100",
        )}
      >
        <div className="flex flex-col items-center gap-1">
          <div className="w-10 h-2 bg-slate-200 rounded-md"></div>
          <div className="w-10 h-2 bg-slate-200 rounded-md"></div>
          <div className="w-10 h-2 bg-slate-200 rounded-md"></div>
        </div>
        <div className="w-24 r-sm:w-36 h-10 bg-slate-200 rounded-md"></div>
        <div className="w-12 h-6 bg-slate-200 rounded-md"></div>
      </div>
    </>
  );
};

const PlaceholderFooter = ({
  isIframeLoaded,
  hideShopifyFooter,
  elementType,
}: {
  elementType: ReploElementType;
  hideShopifyFooter: boolean;
  isIframeLoaded: boolean;
}) => {
  if (hideShopifyFooter || elementType === "shopifySection") {
    return null;
  }

  return (
    <div
      className={twMerge(
        "bg-slate-50 py-8 px-12 w-full flex flex-col gap-6 items-center justify-between h-full",
        "transition-opacity duration-300",
        isIframeLoaded ? "opacity-0" : "opacity-100",
      )}
    >
      <div className="w-36 h-16 bg-slate-200 rounded-md"></div>
      <div className="w-full flex flex-col items-center justify-center gap-4">
        <div className="w-full flex justify-center gap-4">
          <div className="w-1/12 h-6 bg-slate-200 rounded-md"></div>
          <div className="w-2/12 h-6 bg-slate-200 rounded-md"></div>
          <div className="w-1/12 h-6 bg-slate-200 rounded-md"></div>
        </div>
        <div className="w-1/2 h-6 bg-slate-200 rounded-md"></div>
      </div>
      <div className="w-full flex justify-center gap-4">
        <div className="size-6 bg-slate-200 rounded-full"></div>
        <div className="size-6 bg-slate-200 rounded-full"></div>
        <div className="size-6 bg-slate-200 rounded-full"></div>
        <div className="size-6 bg-slate-200 rounded-full"></div>
      </div>
    </div>
  );
};

const useLoadHeaderFooterStyles = (
  ref: React.MutableRefObject<HTMLIFrameElement | null>,
  elementMetadata: ReploElementMetadata,
) => {
  const {
    hideDefaultHeader,
    hideDefaultFooter,
    hideShopifyAnnouncementBar,
    customCss,
  } = elementMetadata;
  return React.useCallback(() => {
    const iframe = ref.current;
    if (iframe?.contentDocument) {
      const stylesToInject = [NO_SCROLLBAR_CSS_CONTENT];

      if (customCss) {
        stylesToInject.push(customCss);
      }
      if (hideDefaultHeader) {
        stylesToInject.push(hideHeaderCss);
      }
      if (hideDefaultFooter) {
        stylesToInject.push(hideFooterCss);
      }
      if (hideShopifyAnnouncementBar) {
        stylesToInject.push(hideAnnouncementBarCss);
      }
      injectStylesIntoDocument(
        stylesToInject.join("\n"),
        iframe.contentDocument,
      );
      iframe.contentDocument.body.classList.add(NO_SCROLLBAR_CLASSNAME);
    }
  }, [
    hideDefaultHeader,
    hideDefaultFooter,
    hideShopifyAnnouncementBar,
    customCss,
    ref,
  ]);
};

const CanvasBackground = ({
  srcDoc,
  element,
}: {
  srcDoc: string | null;
  element: ReploElement;
}) => {
  const { canvas, canvasWidth, frameWidth, canvasHeight } =
    useRequiredContext(CanvasContext);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const ref = React.useRef<HTMLIFrameElement | null>(null);
  const logEvent = useLogAnalytics();
  const preventThemeHeaderFooter = isFeatureEnabled(
    "prevent-theme-header-footer",
  );

  const dispatch = useEditorDispatch();

  const [frameStyles, setFrameStyles] = React.useState<React.CSSProperties>({
    zIndex: -1,
    width: frameWidth,
    height: 0,
  });
  const [frameMargins, setFrameMargins] = React.useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });
  const [isIframeLoaded, setIsIframeLoaded] = React.useState(false);
  const [iframeSrcDoc, setIframeSrcDoc] = React.useState(srcDoc);
  const elementMetadata = useDraftElementMetadata();

  const loadHeaderFooterStyles = useLoadHeaderFooterStyles(
    ref,
    elementMetadata ?? element,
  );

  const THEME_FRAME_ERROR_TIMEOUT_SECONDS = 20;

  // NOTE (Matt 2025-01-15): This useEffect is necessary to essentially
  // reset all of the spacing/sizing styles of the iframe when the srcDoc
  // changes, which can happen when the element changes.
  React.useEffect(() => {
    if (srcDoc !== iframeSrcDoc) {
      setIframeSrcDoc(srcDoc);
      setFrameMargins({
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
      setFrameStyles({
        zIndex: -1,
        height: 0,
      });
      setIsIframeLoaded(false);
    }
  }, [srcDoc, iframeSrcDoc]);

  // NOTE (Matt 2025-01-16): When the iframe loads, we need to detect the bounds of the
  // pageContentFrame, which is where the Replo Element would be rendered. This load
  // event handles that and stores it in `frameMargins`. Additionally, we load header/footer
  // styles and set isIframeLoaded.

  const handleLoad = React.useCallback(() => {
    const iframe = ref?.current;
    if (!iframe?.contentDocument || !iframe?.contentWindow) {
      return;
    }

    // NOTE (Matt 2025-01-27): this is necessary to hide scrollbars inside of our canvas frame.
    iframe.contentDocument.body.classList.add("overflow-hidden");

    if (iFrameNotLoadedTimeout) {
      clearTimeout(iFrameNotLoadedTimeout);
      iFrameNotLoadedTimeout = null;
    }

    const pageContentFrame = iframe.contentDocument.querySelector(
      fullPageQuerySelector,
    );
    if (!pageContentFrame || preventThemeHeaderFooter) {
      return;
    }
    // NOTE (Matt 2025-02-07): It is possible that the user's theme's HTML and CSS hierarchy
    // makes it so that the pageContentFrame has a width of 0 (like, if the `content_for_layout`
    // div is set to display flex). By setting it to 100% width we can ensure it takes up the full
    // available width. We shouldn't be using canvasWidth here because that could cause
    // infinite loop.
    if (pageContentFrame.scrollWidth === 0) {
      const existingInlineStyle = pageContentFrame.getAttribute("style");
      pageContentFrame.setAttribute(
        "style",
        `${existingInlineStyle};width:100%;`,
      );
    }
    loadHeaderFooterStyles();
    const {
      top,
      left,
      right: rightFromLeft,
      bottom: bottomFromTop,
    } = pageContentFrame.getBoundingClientRect();
    // NOTE (Matt 2025-02-12): this 1000 top check is basically to see if the header of the theme
    // has rendered within the realm of what we would consider "normally". Basically, if it's one
    // of the themes we don't care to support that relies on JS to render and therefore could render
    // improperly and push the canvas off the page, then we pop this toast and don't render the theme.
    if (top > 1000) {
      toast({
        header: "Unable to Load Theme Header and Footer",
        message:
          "Please refresh the page or reach out to support@replo.app if the issue persists.",
        type: "error",
        cta: "Refresh",
        ctaOnClick: () => {
          window.location.reload();
        },
      });
      // NOTE (Matt 2025-02-12): this dispatch is useful in order to ensure that any lingering
      // offsetTop/left/bottom values that could come from localStorage aren't being used.
      dispatch(
        setCanvasOffset({
          canvas,
          offset: { top: 72, left: 0, bottom: 0 },
        }),
      );
      return;
    }
    const { scrollHeight, scrollWidth } = iframe.contentDocument.body;
    const bottom = Math.abs(scrollHeight - bottomFromTop);
    const right = Math.abs(scrollWidth - rightFromLeft);
    setFrameMargins({ top, left, right, bottom });
    dispatch(
      setCanvasOffset({
        canvas,
        offset: { top, left, bottom },
      }),
    );

    setIsIframeLoaded(true);
  }, [dispatch, canvas, loadHeaderFooterStyles, preventThemeHeaderFooter]);

  // NOTE (Matt 2025-01-16): loadHeaderFooterStyles updates whenever the selected values for
  // hideHeader, hideFooter, and hideAnnouncement bar change. If those change, then we need
  // to reload that css into the iframe and run `handleLoad` again to recalc the frameMargins.
  React.useEffect(() => {
    loadHeaderFooterStyles();
    handleLoad();
  }, [loadHeaderFooterStyles, handleLoad]);

  // NOTE (Jackson 2025-01-15): If the iframe doesn't load after 20 seconds,
  // we assume there is an issue and show an error toast
  React.useEffect(() => {
    if (!hasTriggeredTimeout && !iFrameNotLoadedTimeout) {
      hasTriggeredTimeout = true;
      iFrameNotLoadedTimeout = setTimeout(() => {
        toast({
          header: "Unable to Load Theme Header and Footer",
          message:
            "Please refresh the page or reach out to support@replo.app if the issue persists.",
          type: "error",
          cta: "Refresh",
          ctaOnClick: () => {
            window.location.reload();
          },
        });
        logEvent("error.iframe.load", {
          error: `Loading header / footer timed out after ${THEME_FRAME_ERROR_TIMEOUT_SECONDS} seconds`,
        });
      }, THEME_FRAME_ERROR_TIMEOUT_SECONDS * 1000);
    }

    return () => {
      if (iFrameNotLoadedTimeout) {
        clearTimeout(iFrameNotLoadedTimeout);
        iFrameNotLoadedTimeout = null;
      }
    };
  }, [logEvent]);

  const isHiddenInPreviewMode = isPreviewMode && canvas !== "desktop";

  const handleResize = React.useCallback(() => {
    if (preventThemeHeaderFooter) {
      return;
    }
    const iframe = ref?.current;
    if (!iframe?.contentDocument || isHiddenInPreviewMode) {
      return;
    }
    const pageContentFrame = iframe.contentDocument.querySelector(
      fullPageQuerySelector,
    );
    if (!pageContentFrame) {
      return;
    }
    const { top, bottom } = frameMargins;

    pageContentFrame.setAttribute(
      "style",
      `width:${canvasWidth}px;height:${canvasHeight}px;display:block;`,
    );

    const newHeight = canvasHeight + top + bottom;
    setFrameStyles((prevFrameStyles) => ({
      ...prevFrameStyles,
      height: newHeight,
      top: top * -1,
    }));
  }, [
    canvasWidth,
    canvasHeight,
    frameMargins,
    isHiddenInPreviewMode,
    preventThemeHeaderFooter,
  ]);

  React.useEffect(() => handleResize(), [handleResize]);

  return (
    <>
      {iframeSrcDoc && (
        <div className="bg-white">
          <iframe
            key={iframeSrcDoc}
            ref={ref}
            className={twMerge(
              "border-0",
              "transition-opacity duration-300",
              isIframeLoaded ? "opacity-100" : "opacity-0",
            )}
            style={{
              ...frameStyles,
              width: frameWidth,
              pointerEvents: "none",
            }}
            sandbox="allow-same-origin allow-top-navigation"
            srcDoc={iframeSrcDoc}
            onLoad={handleLoad}
            data-testid="canvas-iframe"
          />
        </div>
      )}
      {!isIframeLoaded && (
        <div
          className="bg-white"
          data-replo-canvas
          style={{ width: frameWidth }}
        >
          <PlaceholderHeader
            elementType={element.type}
            hideShopifyHeader={elementMetadata?.hideDefaultHeader ?? false}
            isIframeLoaded={isIframeLoaded}
          />
          <div className="w-full" style={{ height: canvasHeight }}></div>
          <PlaceholderFooter
            elementType={element.type}
            hideShopifyFooter={elementMetadata?.hideDefaultFooter ?? false}
            isIframeLoaded={isIframeLoaded}
          />
        </div>
      )}
    </>
  );
};

// #region Hooks
function useWindowResize() {
  const isRightBarVisible = useRightBarVisibility();
  const dispatch = useEditorDispatch();

  React.useEffect(() => {
    const debouncedResetFrameXPosition = debounce(() => {
      dispatch(resetFrameXPosition({ isRightBarVisible }));
    }, 100);

    window.addEventListener("resize", debouncedResetFrameXPosition);
    return () => {
      window.removeEventListener("resize", debouncedResetFrameXPosition);
    };
  }, [isRightBarVisible, dispatch]);
}

function useResetDraftComponentIfNeeded() {
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const setDraftElement = useSetDraftElement();

  React.useEffect(() => {
    if (isPreviewMode && draftComponentId) {
      setDraftElement({ componentIds: [] });
    }
  }, [isPreviewMode, draftComponentId, setDraftElement]);
}

function useExitPromptIfPendingUpdates() {
  const pendingUpdatesSize = useEditorSelector(selectPendingElementUpdatesSize);
  const hasPendingUpdates = pendingUpdatesSize > 0;
  React.useEffect(() => {
    if (!hasPendingUpdates) {
      return;
    }

    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue =
        "There are unsaved changes, are you sure you want to exit?";
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasPendingUpdates]);
}

function useResetCanvasOnElementChange() {
  const draftElementId = useEditorSelector(selectDraftElementId);
  const setActiveCanvas = useSetActiveCanvas();

  const draftElementIdRef = React.useRef<string | null>(draftElementId ?? null);
  React.useEffect(() => {
    if (draftElementIdRef.current !== draftElementId) {
      draftElementIdRef.current = draftElementId ?? null;
      setActiveCanvas({ canvas: "desktop", source: "reset" });
    }
  }, [draftElementId, setActiveCanvas]);
}

/**
 * Persist the canvas settings to local storage. The reason for this
 * implementation vs. useLocalStorageState is that we want the actual state to
 * be managed by the canvas reducer. We only need to use local storage to
 * persist the state across sessions, so its updates do not need to be stateful
 * on their own. This reduces duplication and unnecessary updates when syncing.
 */
function usePersistCanvasSettings() {
  const dispatch = useEditorDispatch();
  const canvases = useEditorSelector(selectCanvases);
  const activeCanvas = useEditorSelector(selectActiveCanvas);

  const projectId = useCurrentProjectId();

  // NOTE (Chance 2024-06-19): There are two effects here we want to coordinate.
  // We need to "initialize" the canvas settings from local storage based on the
  // current project ID. This is not initially set in the reducer because the
  // project ID may not be set depending on which route the user initially lands
  // on. Only after we have initialized the state do we want to sync later
  // updates back to local storage.
  const initializedProjectId = React.useRef<string | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies(activeCanvas): We actually want this
  React.useEffect(() => {
    if (!projectId) {
      return;
    }
    if (initializedProjectId.current !== projectId) {
      // state has not been initially set by the local storage value for this
      // project, so we should not sync its value. Otherwise the localstorage
      // will be updated w/ the current state and screw up persistence.
      return;
    }

    setCanvasLocalStorageState(
      projectId,
      mapValues(canvases, (canvas) => {
        const picked = pick(canvas, "isVisible", "canvasWidth");
        return picked;
      }),
    );
  }, [projectId, canvases, activeCanvas]);

  React.useEffect(() => {
    if (!projectId) {
      return;
    }

    if (initializedProjectId.current === projectId) {
      // state has already been initialized from local storage value
      return;
    }

    initializedProjectId.current = projectId;
    const initialStateFromLocalStorage = getCanvasLocalStorageState(projectId);
    if (initialStateFromLocalStorage) {
      dispatch(setStateFromLocalStorage(initialStateFromLocalStorage));
    }
  }, [projectId, dispatch]);
}

const NO_SCROLLBAR_CLASSNAME = "replo-editor-no-scrollbar";
const NO_SCROLLBAR_CSS_CONTENT = `
  .${NO_SCROLLBAR_CLASSNAME}::-webkit-scrollbar {
    display: none !important;
  }
  .${NO_SCROLLBAR_CLASSNAME} {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }
`;

function useCanvasXOffset(canvas: EditorCanvas) {
  const visibleCanvases = useEditorSelector(selectVisibleCanvases);
  const visibleCanvasesFrameWidth = useEditorSelector(selectCanvasFrameWidths);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  if (isPreviewMode) {
    return 0;
  }

  let xOffset = 0;
  for (const key of Object.keys(visibleCanvases)) {
    if (key === canvas) {
      break;
    }
    const width = visibleCanvasesFrameWidth[key as EditorCanvas];
    xOffset += width + CANVAS_FRAME_GAP;
  }
  return xOffset;
}
// #endregion

function handleCanvasRenderError(error: unknown, info: React.ErrorInfo) {
  if (isDevelopment) {
    console.error("[REPLO] Component rendering error in Canvas", {
      error,
      reactErrorInfo: info,
    });
  }
  trackError(error);
}
