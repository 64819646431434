import type { UploadResult } from "@editor/reducers/commerce-reducer";
import type { EditorRootState } from "@editor/store";
import type { FetchArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import type { OkendoNamespace, StoreProduct } from "replo-runtime/shared/types";
import type { StoreProductSummary } from "schemas/product";

import { getTokenFromStorage } from "@editor/infra/auth";
import {
  setActiveCurrency,
  setActiveLanguage,
  setMoneyFormat,
  setOkendoWidgetNamespace,
  setProductData,
  setProductSummaries,
  setShopifyUrlRoot,
} from "@editor/reducers/commerce-reducer";
import { defaultFetchCredentials } from "@editor/utils/env";
import {
  selectDraftElementId,
  selectStoreUrl,
  setSrcDocFonts,
  setSrcDocFontUrls,
  setSrcDocRootFont,
} from "@reducers/core-reducer";
import { closeModal, openModal } from "@reducers/modals-reducer";
import { replaceCdnUrls } from "@reducers/utils/api-reducer-utils";
import { setHeaderToken } from "@reducers/utils/set-header-token";

import { getPublisherUrl } from "@/config";
import { setCanvasHtml } from "@/features/canvas/canvas-reducer";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

// TODO (gabe, 2023-05-05): move this into shared package
export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  prevPage: string | null;
  nextPage: string | null;
};

type GetShopifyProductsRawResult = {
  products: StoreProduct[];
  pageInfo: PageInfo;
  success: boolean;
};

type GetShopifyProductsSummaryRawResult = {
  products: StoreProductSummary[];
  pageInfo: PageInfo;
  success: boolean;
};

// TODO (Kurt, 2024-11-15): We want to propogate this all the way to the backend
// so that we can verify and type check the source of the billing plan upgrade. Tracked in:
// https://linear.app/replo/issue/REPL-14697/consolidate-product-level-analytics-tracking-in-editor-and-publisher
export type BillingPlanUpgradeSource =
  | "unknown"
  | "publish.productTemplate"
  | "publish.pageOrDefaultElementType"
  | "publish.section"
  | "ai.credits"
  | "projectMemberships.add"
  | "direct.canvasControls"
  | "direct.header"
  | "direct.pdpBanner"
  | "activate.experiments"
  | "analytics"
  | "experiments"
  | "direct.billingDashboard"
  | "integrationHub.shopify";

/**
 * API to use for fetching product data from shopify via our backend.
 *
 * Note (Noah, 2022-07-19): This is a separate createApi definition because
 * we want different retry mechanics for this API, since it's more likely
 * to get throttled by Shopify and if that happens we'd really like to retry
 * because the alternative would be to not have product data available in the
 * editor.
 */
export const productsApi = createApi({
  reducerPath: "products",
  baseQuery: retry(async (args: FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: `${getPublisherUrl()}`,
      prepareHeaders: setHeaderToken,
      credentials: defaultFetchCredentials,
    })(args, api, extraOptions);
    // NOTE (Evan, 7/12/23) If the request fails with code 401 (unauthorized), we want to skip
    // retrying and immediately throw an error (to show the modal)
    if (result.error?.status === 401) {
      retry.fail(result.error);
    }

    return result;
  }),
  keepUnusedDataFor: 120,
  endpoints: (builder) => ({
    getShopifyProducts: builder.query<
      { products: StoreProduct[]; pageInfo: PageInfo },
      {
        storeId: string;
        pageSize: number;
        nextPage?: string;
        query: string;
      }
    >({
      query: ({ storeId, pageSize, nextPage = "", query }) => {
        const path = `/api/v1/shopify/products`;
        const params = new URLSearchParams({
          storeId,
          pageSize: pageSize.toString(),
          nextPage,
          q: query,
        });
        return `${path}?${params.toString()}`;
      },
      transformResponse: (rawResult: GetShopifyProductsRawResult) => {
        return { products: rawResult.products, pageInfo: rawResult.pageInfo };
      },
      async onQueryStarted(_draft, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data?.products) {
          dispatch(setProductData(data.products));
        }
      },
    }),
    getShopifyProductsSummary: builder.query<
      { products: StoreProductSummary[]; pageInfo: PageInfo },
      {
        storeId: string;
        pageSize: number;
        nextPage?: string;
        query?: string;
      }
    >({
      query: ({ storeId, pageSize, nextPage = "", query = "" }) => {
        const path = `/api/v1/shopify/products/summary`;
        const params = new URLSearchParams({
          storeId,
          pageSize: pageSize.toString(),
          nextPage,
          q: query,
        });
        return `${path}?${params.toString()}`;
      },
      transformResponse: (rawResult: GetShopifyProductsSummaryRawResult) => {
        return { products: rawResult.products, pageInfo: rawResult.pageInfo };
      },
      async onQueryStarted(_draft, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data?.products) {
          dispatch(setProductSummaries(data.products));
        }
      },
    }),
  }),
});

export const publisherApi = createApi({
  reducerPath: "publisherApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getPublisherUrl()}/api/v1`,
    prepareHeaders: setHeaderToken,
    credentials: defaultFetchCredentials,
  }),
  endpoints: (builder) => ({
    getCanvasDocument: builder.query<
      {
        html: string;
        fontUrls: string[];
        srcDocFonts: {
          family: string;
          fontFaceDeclarations: string[];
          weights: string[] | null;
        }[];
        srcDocRootFont: string | null;
        currencyCode: string;
        moneyFormat: string;
        activeLanguage: string | null;
        activeShopifyUrlRoot: string | null;
        okendoNamespace: OkendoNamespace | null;
        status: string;
        message?: "shopifyStoreClosed";
        error?: Record<string, string>;
      },
      {
        storeId?: string;
        draftElementId?: string;
        storePassword?: string | null;
      }
    >({
      query: ({ storeId, storePassword, draftElementId }) => {
        const params: Record<string, string> = {
          storeId: storeId ?? "",
        };

        if (storePassword) {
          params.storePassword = storePassword;
        }

        if (draftElementId) {
          params.elementId = draftElementId;
        }
        const qs = new URLSearchParams(params);

        return `/mirror?${qs.toString()}`;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        const queriedElementId = selectDraftElementId(
          getState() as EditorRootState,
        );
        const storeUrl = selectStoreUrl(getState() as EditorRootState);

        const { data } = await queryFulfilled;
        const currentDraftElementId = selectDraftElementId(
          getState() as EditorRootState,
        );

        // Note (Ovishek, 2022-09-09): We should not care if we get data back for a query for an element which
        // is not currently the draft element - this means user moved to another element, so we can ignore the response
        if (queriedElementId !== currentDraftElementId) {
          return;
        }

        if (data.html) {
          dispatch(closeModal({ type: "storePasswordRequiredModal" }));
          dispatch(
            setCanvasHtml(
              storeUrl ? replaceCdnUrls(data.html, storeUrl) : data.html,
            ),
          );
        }

        if (data.fontUrls) {
          dispatch(setSrcDocFontUrls(data.fontUrls));
        }

        if (data.srcDocFonts) {
          dispatch(setSrcDocFonts(data.srcDocFonts));
        }

        if (data.srcDocRootFont) {
          dispatch(setSrcDocRootFont(data.srcDocRootFont));
        }

        dispatch(setActiveCurrency(data.currencyCode));
        dispatch(setMoneyFormat(data.moneyFormat));
        dispatch(setActiveLanguage(data.activeLanguage));
        dispatch(setShopifyUrlRoot(data.activeShopifyUrlRoot));
        dispatch(setOkendoWidgetNamespace(data.okendoNamespace));

        if (data.message) {
          exhaustiveSwitch({ type: data.message })({
            shopifyStoreClosed: () => {
              dispatch(
                openModal({
                  type: "fullPageErrorModal",
                  props: {
                    details: {
                      header: "Shopify store has been closed",
                      message:
                        "The connected Shopify store has been closed. You will still be able to make edits to this page, but you won't be able to publish or use product data.",
                      messageDetail:
                        "If you'd like to remove this Shopify store, you can disconnect it in the Integrations settings for your workspace.",
                      isFullPage: true,
                      callToAction: {
                        type: "navigate",
                        location: "integrationHub",
                      },
                    },
                  },
                }),
              );
            },
          });
        }
      },
    }),
  }),
});

export const {
  useGetShopifyProductsQuery,
  useLazyGetShopifyProductsQuery,
  useLazyGetShopifyProductsSummaryQuery,
} = productsApi;

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getPublisherUrl()}`,
    credentials: defaultFetchCredentials,
    // Note (Evan, 2024-01-16): We don't add content-type: JSON here
    // because the updateOrCreateAsset is not JSON (it is multipart)
    prepareHeaders: (headers) => {
      const token = getTokenFromStorage();
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateOrCreateAsset: builder.mutation<
      UploadResult,
      {
        file: any;
        storeId: string;
        sourceType: "theme" | "files";
        filename: string;
        displayName?: string;
      }
    >({
      query: ({ file, storeId, sourceType, filename, displayName }) => {
        const body = new FormData();
        body.append("file", file);
        body.append("storeId", storeId);
        body.append("filename", filename);
        body.append("sourceType", sourceType);
        if (displayName) {
          body.append("displayName", displayName);
        }
        return {
          url: `/api/v1/shopify/assets`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const { useUpdateOrCreateAssetMutation } = assetsApi;

export const { useGetCanvasDocumentQuery: useGetCanvasDocumentPublisherQuery } =
  publisherApi;

export const useLazyCanvasDocumentPublisherQuery =
  publisherApi.endpoints.getCanvasDocument.useLazyQuery;
