import type {
  ReploAnnouncement,
  ReploAnnouncementTag,
} from "schemas/generated/announcement";

import * as React from "react";
import { useEffect, useState } from "react";

import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { useListAnnouncements } from "@/features/announcements/hooks/useListAnnouncements";
import { extractTextFromFirstParagraph } from "@/features/announcements/utils/announcement";
import InlineAlert from "@replo/design-system/components/alert/InlineAlert";
import Button from "@replo/design-system/components/button/Button";
import { Megaphone } from "lucide-react";

const AnnouncementMessage: React.FC<{
  tags?: ReploAnnouncementTag[];
}> = ({ tags }) => {
  const [announcement, setAnnouncement] = useState<ReploAnnouncement | null>(
    null,
  );
  const [lastSeenAnnouncementId, setLastSeenAnnouncementId] =
    useLocalStorageState<string>(
      "replo.announcements.message.lastSeenAnnouncementId",
      "",
      {
        validate: (value): value is string => typeof value === "string",
      },
    );
  const logEvent = useLogAnalytics();

  const { announcements, isLoading } = useListAnnouncements({
    tags: tags,
    limit: 1,
  });

  useEffect(() => {
    if (
      announcements.length > 0 &&
      !isLoading &&
      announcements[0] &&
      announcements[0].id !== lastSeenAnnouncementId
    ) {
      setAnnouncement(announcements[0]);
    }
  }, [lastSeenAnnouncementId, isLoading, announcements]);

  const handleAnnouncementClick = () => {
    if (announcement && announcement.ctaLink) {
      window.open(announcement.ctaLink, "_blank");
      logEvent("announcements.clicked", {
        announcementId: announcement.id,
        announcementWebflowId: announcement.webflowId,
        source: "AnnouncementMessage",
      });
    }
  };

  const handleCloseAnnouncement = () => {
    setAnnouncement(null);
    if (announcement) {
      setLastSeenAnnouncementId(announcement.id);
      logEvent("announcements.dismissed", {
        announcementId: announcement.id,
        announcementWebflowId: announcement.webflowId,
        source: "AnnouncementMessage",
      });
    }
  };

  if (isLoading || !announcement) {
    return null;
  }

  const endEnhancer = announcement.ctaText && announcement.ctaLink && (
    <Button variant="tertiary" size="sm" onClick={handleAnnouncementClick}>
      {announcement.ctaText}
    </Button>
  );

  return (
    <InlineAlert
      variant="announcement"
      onClose={handleCloseAnnouncement}
      startEnhancer={<Megaphone size={16} className="text-beta" />}
      endEnhancer={endEnhancer}
    >
      {extractTextFromFirstParagraph(announcement.description)}
    </InlineAlert>
  );
};

export default AnnouncementMessage;
