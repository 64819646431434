import type { MenuItem } from "@replo/design-system/components/menu/Menu";

import * as React from "react";

import { Menu, MenuTrigger } from "@replo/design-system/components/menu/Menu";
import twMerge from "@replo/design-system/utils/twMerge";
import { Link } from "react-router-dom";

type DashboardMenuItemProps = {
  item: {
    title: string;
    onClick?: () => void;
    path?: string;
    additionalPath?: string;
    startEnhancer?: React.ReactNode;
    endEnhancer?: React.ReactNode;
    href?: string;
    className?: string;
    textClassName?: string;
    isSelected?: boolean;
    hasSubItems?: boolean;
    menuItems?: MenuItem[];
  };
};

type MenuItemContentProps = {
  startEnhancer?: React.ReactNode;
  title: string;
  textClassNames: string;
  endEnhancer?: React.ReactNode;
};

const DashboardMenuItem: React.FC<DashboardMenuItemProps> = ({
  item: {
    title,
    onClick,
    path,
    startEnhancer,
    href,
    endEnhancer,
    className,
    textClassName,
    isSelected,
    hasSubItems,
    menuItems,
  },
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const itemProps = {
    className: twMerge(
      "flex gap-2 justify-start items-center text-default hover:bg-menu-item-hover p-1.5 cursor-pointer rounded",
      path &&
        isSelected &&
        !hasSubItems &&
        "bg-menu-item-selected text-primary hover:bg-menu-item-selected hover:text-primary",
      isMenuOpen && "bg-button-tertiary-hover",
      hasSubItems && isSelected && "text-primary",
      className,
    ),
    textClassNames: twMerge("text-left w-full typ-body-base", textClassName),
    startEnhancer,
    endEnhancer,
  };

  if (href) {
    return (
      <Link
        key={title}
        className={itemProps.className}
        to={href}
        target="_blank"
      >
        <MenuItemContent {...itemProps} title={title} />
      </Link>
    );
  }

  if (menuItems) {
    return (
      <Menu
        customWidth={160}
        items={menuItems}
        onRequestOpen={() => setIsMenuOpen(true)}
        onRequestClose={() => setIsMenuOpen(false)}
        itemClassName="hover:bg-button-tertiary-hover"
        trigger={
          <MenuTrigger>
            <div className={itemProps.className}>
              <MenuItemContent {...itemProps} title={title} />
            </div>
          </MenuTrigger>
        }
        side="right"
        sideOffset={16}
      />
    );
  }

  return (
    <button key={title} className={itemProps.className} onClick={onClick}>
      <MenuItemContent {...itemProps} title={title} />
    </button>
  );
};

const MenuItemContent: React.FC<MenuItemContentProps> = ({
  startEnhancer,
  title,
  textClassNames,
  endEnhancer,
}) => (
  <>
    {startEnhancer && (
      <div className="flex items-center justify-center h-4 w-4">
        {startEnhancer}
      </div>
    )}
    <TruncatedText text={title} className={textClassNames} />
    {endEnhancer && endEnhancer}
  </>
);

const TruncatedText = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => {
  return (
    <div className="inline-grid w-full my-auto">
      <span className={twMerge("truncate", className)}>{text}</span>
    </div>
  );
};

export default DashboardMenuItem;
