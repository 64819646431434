import { createClient } from "@sanity/client";

const config = {
  projectId: "089m4oq9", // This is public, so no need to hide it
  dataset: "production",
  apiVersion: "2023-09-19",
  useCdn: false,
};

export const sanityClient = createClient(config);
