import type {
  FlowStepConfigPropsValueOf,
  FlowStepDataValueOf,
} from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import { useGetCurrentStepResultsData } from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";

import SelectableButtons from "@replo/design-system/components/selectable-buttons/SelectableButtons";
import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "replo-utils/lib/misc";

import { useGetCurrentFlow } from "../hooks/useGetCurrentFlow";

type FormValues = {
  yearlyRevenue: string;
  clientCount?: string;
};

const WhatIsYourYearlyRevenue: React.FC = () => {
  const stepType = "onboarding.user.who-are-you-building-pages-for.revenue";
  const stepResultsData = useGetCurrentStepResultsData<typeof stepType>();
  const { currentStep, skipStep, submitStep } = useReploFlowsStepContext();
  const { submitOrSkipStepCallback } = useRouterFlowCallbacks();
  const { currentInstance } = useGetCurrentFlow();
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      yearlyRevenue:
        stepResultsData?.yearlyRevenue ??
        stepResultsData?.clientYearlyRevenue ??
        "",
      clientCount: stepResultsData?.clientCount ?? undefined,
    },
  });
  const whoAreYouBuildingPagesForData = currentInstance?.stepResults?.[
    "who-are-you-building-pages-for"
  ]
    ?.data as FlowStepDataValueOf<"onboarding.user.who-are-you-building-pages-for">;
  const buildingFor = whoAreYouBuildingPagesForData?.buildingFor;

  const shouldAskClientCount = [
    "freelancer",
    "development_agency",
    "digital_marketing_agency",
  ].includes(buildingFor ?? "");

  const onSubmit = ({ yearlyRevenue, clientCount }: FormValues) => {
    if (currentStep) {
      if (currentStep?.isSkippable && isEmpty(yearlyRevenue)) {
        skipStep(currentStep.id, currentStep.type, ({ instance, nextStep }) =>
          submitOrSkipStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
        );
      } else {
        void submitStep(
          currentStep.id,
          currentStep.type,
          {
            // NOTE (Sebas, 2024-11-07): This is because we use the same yearlyRevenue
            // field for both cases, and we need to save different properties depending
            // on the value of shouldAskClientCount.
            yearlyRevenue: !shouldAskClientCount ? yearlyRevenue : null,
            clientCount: shouldAskClientCount ? clientCount : null,
            clientYearlyRevenue: shouldAskClientCount ? yearlyRevenue : null,
          },
          ({ instance, nextStep }) =>
            submitOrSkipStepCallback({
              nextStep: nextStep ?? null,
              flowSlug: instance.flow.slug,
            }),
        );
      }
    }
  };
  const yearlyRevenueValue = useWatch({ control, name: "yearlyRevenue" });
  const stepProps = currentStep?.props as FlowStepConfigPropsValueOf<
    typeof stepType
  >;
  const selectedValueLabel = stepProps.options?.find(
    (option) => option.value === yearlyRevenueValue,
  )?.label;

  return (
    <OnboardingStepLayout
      rightPanelContent={<RevenueStepImage label={selectedValueLabel} />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div className="flex flex-col gap-14">
          <div>
            <StepTitle>
              {shouldAskClientCount
                ? "What is the average annual revenue of your clients?"
                : "What is your yearly revenue?"}
            </StepTitle>
          </div>
          <Controller
            name="yearlyRevenue"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect={false}
                options={stepProps.options}
                value={[value]}
                onChange={(value) => onChange(value[0] ?? "")}
              />
            )}
          />
          {shouldAskClientCount && (
            <div className="flex flex-col gap-1">
              <h3 className="text-default font-semibold">
                How many active clients do you have?
              </h3>
              <Controller
                name="clientCount"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectableButtons
                    multiSelect={false}
                    options={[
                      {
                        label: "1",
                        value: "1",
                      },
                      {
                        label: "2-10",
                        value: "2",
                      },
                      {
                        label: "11-50",
                        value: "11",
                      },
                      {
                        label: "50+",
                        value: "50",
                      },
                    ]}
                    value={[value ?? ""]}
                    onChange={(value) => onChange(value[0] ?? "")}
                  />
                )}
              />
            </div>
          )}
        </div>
        <FlowActionButtons shouldDisableNextButton={false} />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

const RevenueStepImage: React.FC<{ label?: string }> = ({ label }) => {
  return (
    <div className="col-span-7 xl:col-span-8 mx-auto relative lg:flex px-32 py-44 items-center justify-center hidden overflow-y-hidden">
      <img src="/images/flows/revenue.png" alt="Flow step image" />
      {label ? (
        <span className="absolute font-bold lg:text-5xl xl:text-8xl text-blue-600">
          {label}
        </span>
      ) : null}
    </div>
  );
};

export default WhatIsYourYearlyRevenue;
