import type { FlowSlug } from "schemas/generated/flow";

import * as React from "react";

import { ReploFlowsContext } from "@editor/components/flows/context/ReploFlowsContext";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";

export const FlowsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [flowToDebug, setFlowToDebug] = React.useState<FlowSlug | null>(null);
  const { currentFlow, currentInstance, nextStep } = useGetCurrentFlow();
  return (
    <ReploFlowsContext.Provider
      value={{
        currentFlow,
        currentInstance,
        nextStep,
        debug: {
          flowToDebug,
          setFlowToDebug,
        },
      }}
    >
      {children}
    </ReploFlowsContext.Provider>
  );
};
