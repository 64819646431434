import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";

import React from "react";

import Input from "@editor/components/common/designSystem/Input";

import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import Popover from "@replo/design-system/components/popover/Popover";
import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import twMerge from "@replo/design-system/utils/twMerge";
import { ChevronDown } from "lucide-react";
import { BsArrowReturnLeft, BsLockFill, BsSearch } from "react-icons/bs";

type PageUrlSelectProps = {
  isEditable: boolean;
  target: string | null;
  onChange: (value: string) => void;
  options: ComboboxOption[];
};
type PageUrlType = "replo" | "custom";

type ReploPageComboboxProps = {
  options: ComboboxOption[];
  target: string | null;
  onChange: (value: string) => void;
  setIsPopoverOpen: (value: boolean) => void;
  UNSAFE_className?: string;
};

const ReploPageCombobox = ({
  options,
  target,
  onChange,
  setIsPopoverOpen,
  UNSAFE_className,
}: ReploPageComboboxProps) => {
  const [input, setInput] = React.useState("");

  return (
    <Combobox.Root
      options={options}
      value={target ?? ""}
      onChange={(value) => {
        onChange(value);
        setInput("");
        setIsPopoverOpen(false);
      }}
      input={input}
      onInputChange={(value) => setInput(value)}
      syncInputWithSelectedValue={false}
    >
      <Combobox.Trigger>
        <Combobox.Input
          placeholder="Search published Replo pages..."
          startEnhancer={<BsSearch className="h-3 w-3" />}
          outlineOnActive={false}
        />
      </Combobox.Trigger>
      <Combobox.Content UNSAFE_listClassName={UNSAFE_className} />
    </Combobox.Root>
  );
};

type CustomPageInputProps = {
  onChange: (value: string) => void;
  setIsPopoverOpen: (value: boolean) => void;
};

const CustomPageInput = ({
  onChange,
  setIsPopoverOpen,
}: CustomPageInputProps) => {
  const [input, setInput] = React.useState("");

  const onAddCustomURL = (url: string) => {
    if (url) {
      onChange(url);
      setInput("");
      setIsPopoverOpen(false);
    }
  };

  return (
    <Input
      value={input}
      onChange={(e) => {
        setInput(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && input) {
          onAddCustomURL(input);
        }
      }}
      placeholder="Enter your page URL"
      layoutClassName="h-8"
      size="sm"
      endEnhancer={() => (
        <BsArrowReturnLeft
          size={13}
          className="text-subtle cursor-pointer"
          onClick={() => {
            onAddCustomURL(input);
          }}
        />
      )}
    />
  );
};

const PageUrlSelect: React.FC<PageUrlSelectProps> = ({
  isEditable,
  target,
  onChange,
  options,
}) => {
  const popoverTriggerRef = React.useRef<HTMLDivElement>(null);

  const [pageUrlType, setPageUrlType] = React.useState<PageUrlType>("replo");

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [popoverContentWidth, setPopoverContentWidth] =
    React.useState<number>(0);

  React.useEffect(() => {
    if (popoverTriggerRef.current) {
      setPopoverContentWidth(popoverTriggerRef.current.offsetWidth);
    }
  }, []);

  return (
    <Popover.Root
      isOpen={isPopoverOpen}
      onOpenChange={(value) => {
        if (isEditable) {
          setIsPopoverOpen(value);
        }
      }}
    >
      <Popover.Trigger asChild>
        <div ref={popoverTriggerRef}>
          <div
            className={twMerge(
              "h-8 gap-1 bg-light-surface rounded flex justify-between items-center px-2 cursor-pointer typ-body-small",
              !isEditable &&
                "bg-disabled text-disabled cursor-not-allowed opacity-50",
            )}
          >
            <div className="truncate text-ellipsis">
              <span className={twMerge("", !target && "text-placeholder")}>
                {target ?? "Select a page"}
              </span>
            </div>
            {isEditable ? (
              <ChevronDown className="flex-shrink-0" size={16} />
            ) : (
              <BsLockFill className="flex-shrink-0" size={12} />
            )}
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Content
        side="bottom"
        style={{ width: popoverContentWidth }}
        className="p-4"
        shouldPreventDefaultOnInteractOutside={false}
        collisionPadding={10}
        hideCloseButton
      >
        <div className="flex flex-col gap-2">
          <div>
            <ToggleGroup
              size="sm"
              options={[
                {
                  label: "Replo Pages",
                  value: "replo",
                },
                {
                  label: "Custom URL",
                  value: "custom",
                },
              ]}
              selectedValue={pageUrlType}
              onChange={(value) => {
                setPageUrlType(value as PageUrlType);
              }}
            />
          </div>
          {pageUrlType === "replo" ? (
            <ReploPageCombobox
              options={options}
              target={target}
              onChange={onChange}
              setIsPopoverOpen={setIsPopoverOpen}
              UNSAFE_className="max-h-[calc(var(--radix-popover-content-available-height)-100px)]"
            />
          ) : (
            <CustomPageInput
              onChange={onChange}
              setIsPopoverOpen={setIsPopoverOpen}
            />
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export { PageUrlSelect };
