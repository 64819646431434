import type { PopoverRootProps } from "@replo/design-system/components/popover/Popover";
import type { ToggleOption } from "@replo/design-system/components/toggle/ToggleGroup";
import type {
  DateFormatOptions,
  Formatter,
  ReferenceType,
} from "schemas/dynamicData";

import React, { useCallback, useMemo } from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  selectDraftComponentText,
  selectDraftComponentTextDynamicDataInfo,
  selectEvaluatedDraftComponentText,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { updateFormatters } from "@editor/utils/dynamic-data";
import { stripHtmlTags } from "@editor/utils/string";

import Popover from "@replo/design-system/components/popover/Popover";
import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import ControlGroup from "./element-editor/components/extras/ControlGroup";

type DynamicDataFormatterProps = Pick<
  Omit<PopoverRootProps, "shouldClose">,
  "isDefaultOpen" | "shouldIgnoreOutsideInteractions"
> & {
  trigger: React.ReactNode;
  side?: "top" | "bottom" | "left" | "right";
  sideOffset?: number;
  location?: "canvas" | "designPanel";
  onChange: (value: string) => void;
  initialValue?: Formatter[];
  referenceType: ReferenceType;
  triggerClassName?: string;
};

const FormattedPreview = () => {
  const _evaluatedValue = useEditorSelector(selectEvaluatedDraftComponentText);
  if (!_evaluatedValue) {
    return null;
  }

  const evaluatedValue = stripHtmlTags(_evaluatedValue);
  return (
    <p className="bg-light-surface flex items-center justify-center typ-header-h1 py-2 my-4 rounded">
      {evaluatedValue}
    </p>
  );
};

type FormatterToggleProps = {
  label: string;
  selectedValue: string;
  options: ToggleOption[];
  onChange: (value: string) => void;
};

const FormatterToggleControl = ({
  label,
  selectedValue,
  options,
  onChange,
}: FormatterToggleProps) => (
  <ControlGroup label={label} className="py-2">
    <ToggleGroup
      size="sm"
      selectedValue={selectedValue}
      options={options}
      onChange={onChange}
    />
  </ControlGroup>
);

function useFormatter<T extends Formatter>(
  initialFormatters: T[],
  location: "canvas" | "designPanel",
  onChange: (value: string) => void,
) {
  const text = useEditorSelector(selectDraftComponentText);
  const analytics = useLogAnalytics();

  const updateFormatter = useCallback(
    (newFormatters: Formatter[]) => {
      if (!text) {
        return;
      }
      const type = newFormatters.some(({ type }) => type === "date")
        ? "date"
        : "currency";
      analytics("editor.dynamicData.format", {
        location,
        type,
        formatters: newFormatters,
      });
      const newDynamicDataValue = updateFormatters(newFormatters, text);
      if (newDynamicDataValue) {
        onChange(newDynamicDataValue);
      }
    },
    [text, onChange, analytics, location],
  );

  const hasFormatterType = useCallback(
    (type: string) =>
      initialFormatters.some((formatter) => formatter.type === type),
    [initialFormatters],
  );

  const toggleFormatter = useCallback(
    (type: string, newValue: string, enabledValue: string) => {
      let newFormatters = [...initialFormatters];
      if (newValue === enabledValue) {
        newFormatters.push({ type } as T);
      } else {
        newFormatters = newFormatters.filter(
          (formatter) => formatter.type !== type,
        );
      }
      updateFormatter(newFormatters);
    },
    [initialFormatters, updateFormatter],
  );

  return {
    formatters: initialFormatters,
    hasFormatterType,
    toggleFormatter,
    updateFormatter,
  };
}

export const DynamicDataFormatter = ({
  trigger,
  isDefaultOpen,
  side = "bottom",
  sideOffset = 4,
  initialValue,
  onChange,
  location = "designPanel",
  referenceType,
  triggerClassName,
}: DynamicDataFormatterProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const header = exhaustiveSwitch({ type: referenceType })({
    currency: "Price Formatting",
    date: "Date Formatting",
  });

  const FormatterComponent = useMemo(() => {
    return exhaustiveSwitch({ type: referenceType })({
      currency: () => (
        <CurrencyFormatter
          formatters={initialValue ?? []}
          location={location}
          onChange={onChange}
        />
      ),
      date: () => (
        <DateFormatter
          formatters={initialValue ?? []}
          location={location}
          onChange={onChange}
        />
      ),
    });
  }, [referenceType, initialValue, onChange, location]);

  return (
    <Popover.Root
      isDefaultOpen={isDefaultOpen}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      shouldIgnoreOutsideInteractions={false}
    >
      <Popover.Trigger className={triggerClassName}>{trigger}</Popover.Trigger>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside={false}
        side={side}
        sideOffset={sideOffset}
        align="center"
        title={header}
        titleClassnames="typ-header-base"
        className="px-4 py-4 w-[267px]"
      >
        {FormatterComponent}
      </Popover.Content>
    </Popover.Root>
  );
};

const CurrencyFormatter = ({
  formatters,
  location,
  onChange,
}: {
  formatters: Formatter[];
  location: "canvas" | "designPanel";
  onChange: (value: string) => void;
}) => {
  const dynamicDataInfoList = useEditorSelector(
    selectDraftComponentTextDynamicDataInfo,
  );
  const dynamicDataInfo = dynamicDataInfoList?.[0];
  const shouldAllowSellingPlanDiscount =
    dynamicDataInfo?.path?.includes("variant.");

  const { hasFormatterType, toggleFormatter, updateFormatter } = useFormatter(
    formatters,
    location,
    onChange,
  );

  const handleToggleDiscountFormatter = useCallback(
    (isEnabled: boolean) => {
      let newFormatters = [...formatters];
      if (isEnabled) {
        newFormatters.push({
          type: "discount",
          discountType: "selectedSellingPlan",
        });
      } else {
        newFormatters = newFormatters.filter(
          (formatter) => formatter.type !== "discount",
        );
      }
      updateFormatter(newFormatters);
    },
    [formatters, updateFormatter],
  );

  return (
    <>
      <FormattedPreview />
      <div className="border-b border-slate-200 my-2 mb-2"></div>
      <FormatterToggleControl
        label="Currency"
        selectedValue={hasFormatterType("currency") ? "currency" : "plain"}
        options={[
          { label: "Currency", value: "currency" },
          { label: "Plain", value: "plain" },
        ]}
        onChange={(value) => toggleFormatter("currency", value, "currency")}
      />
      <FormatterToggleControl
        label="Rounding"
        selectedValue={hasFormatterType("rounded") ? "rounded" : "precise"}
        options={[
          { label: "Precise", value: "precise" },
          { label: "Rounded", value: "rounded" },
        ]}
        onChange={(value) => toggleFormatter("rounded", value, "rounded")}
      />
      {shouldAllowSellingPlanDiscount && (
        <SwitchWithDescription
          layoutClassName="my-2"
          label="Apply Selling Plan Discount"
          description="For the selected selling plan"
          isOn={hasFormatterType("discount")}
          size="sm"
          onChange={handleToggleDiscountFormatter}
          variant="inline"
        />
      )}
    </>
  );
};

const DEFAULT_TEXT_DATE_OPTIONS: DateFormatOptions = {
  format: "text",
  includeYear: true,
  includeWeekday: false,
};

const DEFAULT_NUMBER_DATE_OPTIONS: DateFormatOptions = {
  format: "number",
  includeYear: true,
  separator: "-",
  order: "month-first",
};

function isDateFormatter(
  formatter: Formatter,
): formatter is { type: "date"; dateOptions: DateFormatOptions } {
  return formatter.type === "date" && Boolean(formatter.dateOptions);
}

const DateFormatter = ({
  formatters,
  location,
  onChange,
}: {
  formatters: Formatter[];
  location: "canvas" | "designPanel";
  onChange: (value: string) => void;
}) => {
  const { updateFormatter } = useFormatter(formatters, location, onChange);

  const dateFormat = useMemo(() => {
    const existing = formatters.find(isDateFormatter);
    if (!existing) {
      return {
        type: "date" as const,
        dateOptions: DEFAULT_TEXT_DATE_OPTIONS,
      };
    }
    return existing;
  }, [formatters]);

  const dateOptions = dateFormat.dateOptions;

  const handleToggleFormatType = useCallback(
    (newType: string) => {
      const newDateFormat: DateFormatOptions = {
        ...(newType === "text"
          ? DEFAULT_TEXT_DATE_OPTIONS
          : DEFAULT_NUMBER_DATE_OPTIONS),
        includeYear: dateOptions.includeYear,
      };

      let newFormatters = formatters.map((formatter) => {
        if (formatter.type !== "date") {
          return formatter;
        }
        return { type: "date", dateOptions: newDateFormat };
      });

      if (newFormatters.length < 1) {
        newFormatters = [{ type: "date", dateOptions: newDateFormat }];
      }

      updateFormatter(newFormatters as Formatter[]);
    },
    [formatters, dateOptions, updateFormatter],
  );

  const handleToggleAttribute = useCallback(
    (
      key: "includeYear" | "includeWeekday" | "order" | "separator",
      value: any,
    ) => {
      const newDateOptions = {
        ...dateOptions,
        [key]: value,
      };

      const newFormatters = formatters.map((formatter) => {
        if (formatter.type !== "date") {
          return formatter;
        }
        return {
          ...formatter,
          dateOptions: newDateOptions,
        };
      });

      updateFormatter(newFormatters);
    },
    [formatters, dateOptions, updateFormatter],
  );

  return (
    <>
      <FormattedPreview />
      <FormatterToggleControl
        label="Format"
        selectedValue={dateOptions.format}
        options={[
          { label: "Number", value: "number" },
          { label: "Text", value: "text" },
        ]}
        onChange={handleToggleFormatType}
      />

      {dateOptions.format === "text" ? (
        <>
          <FormatterToggleControl
            label="Year"
            selectedValue={
              dateOptions.includeYear ? "include-year" : "exclude-year"
            }
            options={[
              { label: "Included", value: "include-year" },
              { label: "Hidden", value: "exclude-year" },
            ]}
            onChange={(newValue) =>
              handleToggleAttribute("includeYear", newValue === "include-year")
            }
          />

          <FormatterToggleControl
            label="Weekday"
            selectedValue={
              dateOptions.includeWeekday ? "include-weekday" : "exclude-weekday"
            }
            options={[
              { label: "Included", value: "include-weekday" },
              { label: "Hidden", value: "exclude-weekday" },
            ]}
            onChange={(newValue) =>
              handleToggleAttribute(
                "includeWeekday",
                newValue === "include-weekday",
              )
            }
          />
        </>
      ) : (
        <>
          <FormatterToggleControl
            label="Order"
            selectedValue={dateOptions.order}
            options={[
              {
                label: "Month First",
                value: "month-first",
              },
              {
                label: "Day First",
                value: "day-first",
              },
            ]}
            onChange={(newValue) => handleToggleAttribute("order", newValue)}
          />

          <FormatterToggleControl
            label="Separator"
            selectedValue={dateOptions.separator}
            options={[
              { label: "-", value: "-" },
              { label: "/", value: "/" },
            ]}
            onChange={(newValue) =>
              handleToggleAttribute("separator", newValue)
            }
          />

          <FormatterToggleControl
            label="Year"
            selectedValue={
              dateOptions.includeYear ? "include-year" : "exclude-year"
            }
            options={[
              { label: "Included", value: "include-year" },
              { label: "Hidden", value: "exclude-year" },
            ]}
            onChange={(newValue) =>
              handleToggleAttribute("includeYear", newValue === "include-year")
            }
          />
        </>
      )}
    </>
  );
};
