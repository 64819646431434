import type { FontOption } from "@editor/utils/font";

import * as React from "react";

import {
  selectDraftElementFontFamilies,
  selectProject,
  selectSrcDocFonts,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { GOOGLE_FONT_OPTIONS } from "@editor/utils/font";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { shallowEqual } from "react-redux";
import { filterNulls } from "replo-utils/lib/array";

export const usePageFontOptions = (): FontOption[] => {
  const pageFonts = useEditorSelector(
    selectDraftElementFontFamilies,
    shallowEqual,
  );
  const pageFontOptions = filterNulls(Array.from(pageFonts)).map(
    (fontFamily) => {
      return {
        value: fontFamily,
        label: fontFamily,
        isDisabled: false,
        isDefaultActive: false,
        isSelectable: true,
      };
    },
  );
  return filterNulls([
    ...pageFontOptions
      .map((option) => {
        return {
          ...option,
          isSelectable: true,
          isDefaultActive: false,
          endEnhancer: null,
          groupTitle: "From Page",
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
  ]);
};

export const useShopifyFontOptions = () => {
  const project = useEditorSelector(selectProject);
  const { data: uploadedFonts, refetch: refetchUploadedFonts } =
    trpc.store.getFonts.useQuery(
      project?.id ? { storeId: project?.id } : skipToken,
    );

  const srcDocFonts = useEditorSelector(selectSrcDocFonts);

  const uploadedFontNames = uploadedFonts?.map((font) => font.name) ?? [];

  const nameToDisplayName: Record<string, string | undefined> = uploadedFonts
    ? uploadedFonts.reduce(
        (acc, { name, displayName }) => ({
          ...acc,
          [name]: displayName,
        }),
        {},
      )
    : {};

  const themeFontNames = React.useMemo(() => {
    const srcDocFontFamilies = srcDocFonts.map((font) => font.family);
    return filterNulls(srcDocFontFamilies);
  }, [srcDocFonts]);

  const combinedFontNames = [
    ...new Set(uploadedFontNames.concat(themeFontNames)),
  ];

  const formattedFonts = combinedFontNames
    ?.map((fontName) => {
      return {
        label: nameToDisplayName[fontName] ?? fontName,
        isDefaultActive: false,
        isDisabled: false,
        value: fontName ?? null,
        isSelectable: true,
        groupTitle: "From Shopify",
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const refetch = async () => {
    const result = await refetchUploadedFonts();
    return result.data;
  };

  return {
    fontOptions: formattedFonts,
    fallbacks: ["sans-serif"],
    refetch,
    nameToDisplayName,
  };
};

export const useAvailableFontFamilies = () => {
  const { fontOptions: shopifyFontOptions, nameToDisplayName } =
    useShopifyFontOptions();

  return React.useMemo(() => {
    const allOptions = [...shopifyFontOptions, ...GOOGLE_FONT_OPTIONS];

    return Array.from(
      new Set(filterNulls(allOptions.map((option) => option.value))),
    ).map((value) => ({
      name: value,
      displayName: nameToDisplayName[value],
    }));
  }, [shopifyFontOptions, nameToDisplayName]);
};
