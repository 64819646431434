import type { Nullish } from "replo-utils/lib/types";
import type { CarouselContextValue } from "../carousel";
import type { CarouselState } from "../carousel-state";
import type { Orientation } from "./types";

import {
  END_LOOP,
  ORIENTATION_VERTICAL,
  SLIDE_POSITION_CENTER,
  SLIDE_POSITION_START,
} from "./constants";

export function getTrackDOMId(carouselId: string) {
  return makeId(carouselId, "track");
}

const MAKE_ID_DELIMITER = ":";
export function makeId(...parts: (string | Nullish | number)[]) {
  return parts.filter((part) => part != null).join(MAKE_ID_DELIMITER);
}
makeId.DELIMITER = MAKE_ID_DELIMITER;

function getTrackTransform(
  startPosition: number,
  context: {
    orientation: Orientation;
  },
) {
  const transform = isVertical(context)
    ? `translate3d(0px, ${startPosition}px, 0px)`
    : `translate3d(${startPosition}px, 0px, 0px)`;
  return transform;
}

export function getTrackTransitionDuration(context: {
  transitionSpeed: number;
}): React.CSSProperties["transitionDuration"] {
  return `${context.transitionSpeed}ms`;
}

export function getTrackTransitionTimingFunction(context: {
  transitionEasing: string;
}): React.CSSProperties["transitionTimingFunction"] {
  return context.transitionEasing;
}

export function getTrackTransitionDelay(context: {
  transitionSpeed: number;
}): React.CSSProperties["transitionDelay"] {
  return context.transitionSpeed === 0 ? "0ms" : undefined;
}

export function getTrackTransitionProperty(
  context: Pick<CarouselContextValue, "transitionSpeed" | "isFade">,
): React.CSSProperties["transitionProperty"] {
  if (context.transitionSpeed === 0) {
    return "none";
  }
  return context.isFade ? "opacity, visibility" : "transform";
}

export function getTrackStartPosition(
  slideIndex: number,
  context: Pick<
    CarouselContextValue,
    | "trackRef"
    | "activeSlidePosition"
    | "slideCount"
    | "slidesPerMove"
    | "slidesPerPage"
    | "autoWidth"
    | "endBehavior"
    | "orientation"
    | "listSize"
    | "isFade"
    | "isHydrated"
  >,
) {
  const {
    trackRef,
    activeSlidePosition,
    slideCount,
    orientation,
    listSize,
    isFade,
  } = context;

  if (isFade || slideCount === 1) {
    return 0;
  }

  const vertical = isVertical({ orientation });
  const beforeClones = getBeforeClones(context);

  const trackElement = trackRef.current;
  if (!trackElement) {
    return 0;
  }

  const targetSlideIndex = slideIndex + beforeClones;
  const targetSlideElement = getTrackChildElement(
    trackElement,
    targetSlideIndex,
  );

  if (activeSlidePosition !== SLIDE_POSITION_CENTER) {
    return targetSlideElement
      ? targetSlideElement[vertical ? "offsetTop" : "offsetLeft"] * -1
      : 0;
  }

  const nonCenteredStartPos = targetSlideElement
    ? targetSlideElement[vertical ? "offsetTop" : "offsetLeft"] * -1
    : 0;

  const targetSlideSize = (vertical ? getElementHeight : getElementWidth)(
    targetSlideElement,
  );

  return nonCenteredStartPos + parseInt(listSize) / 2 - targetSlideSize / 2;
}

function getTrackChildElement(trackElement: HTMLElement, index: number) {
  return trackElement.children[index] as HTMLElement | undefined;
}

function isVertical(context: { orientation: Orientation }): context is {
  orientation: typeof ORIENTATION_VERTICAL;
} {
  return context.orientation === ORIENTATION_VERTICAL;
}

export function parseInt(val: string | number | null | undefined) {
  const num = typeof val === "string" ? Number.parseInt(val, 10) : val ?? 0;
  // eslint-disable-next-line no-self-compare
  return num !== num || num === 0 ? 0 : num;
}

export function getBeforeClones(
  context: Pick<
    CarouselContextValue,
    | "activeSlidePosition"
    | "autoWidth"
    | "endBehavior"
    | "slideCount"
    | "slidesPerPage"
    | "slidesPerMove"
    | "isFade"
    | "isHydrated"
  >,
) {
  if (!isInfinite(context) || context.isFade || context.slideCount === 0) {
    return 0;
  }

  // NOTE (Chance 2024-05-20, REPL-11523): If the carousel is not hydrated and
  // the active slide is at the start, we should not need any clones before the
  // first slide as they are not visible anyway. This will prevent the carousel
  // from jumping to the first slide after hydration.
  if (
    context.activeSlidePosition === SLIDE_POSITION_START &&
    !context.isHydrated
  ) {
    return 0;
  }

  // if the active slide is at the start, we should only need `slidesPerMove`
  // clones because that's all that can be visible during a transition
  if (context.activeSlidePosition === SLIDE_POSITION_START) {
    return context.slidesPerMove;
  }

  if (context.autoWidth) {
    return context.slideCount;
  }

  return context.slidesPerPage + 1;
}

export function getAfterClones(
  context: Pick<
    CarouselContextValue,
    | "activeSlidePosition"
    | "autoWidth"
    | "endBehavior"
    | "slideCount"
    | "slidesPerPage"
    | "slidesPerMove"
    | "isFade"
  >,
) {
  if (!isInfinite(context) || context.isFade || context.slideCount === 0) {
    return 0;
  }

  // TODO (Chance 2024-01-23): For autowidth, ideally we'd measure total width
  // of slides in view at a given state to calculate the slides on that page and
  // adjust clones accordingly. This adds complexity and just using the slide
  // count is probably good enough for now.
  if (context.autoWidth) {
    return (
      context.slideCount +
      (context.activeSlidePosition === SLIDE_POSITION_CENTER ? 1 : 0)
    );
  }

  return (
    context.slidesPerPage +
    (context.activeSlidePosition === SLIDE_POSITION_CENTER ? 1 : 0)
  );
}

export function isInfinite(
  props: Pick<CarouselContextValue, "endBehavior">,
): props is {
  endBehavior: typeof END_LOOP;
} {
  return props.endBehavior === END_LOOP;
}

export function shouldAutoPlay(
  context: Pick<CarouselContextValue, "autoPlayInterval">,
): context is { autoPlayInterval: number } {
  return context.autoPlayInterval != null && context.autoPlayInterval > 0;
}

export function canGoNext(
  context: Pick<
    CarouselContextValue,
    | "endBehavior"
    | "activeSlidePosition"
    | "activeSlideIndex"
    | "slideCount"
    | "slidesPerPage"
  >,
) {
  if (isInfinite(context)) {
    return true;
  }

  if (
    context.activeSlidePosition === SLIDE_POSITION_CENTER &&
    context.activeSlideIndex >= context.slideCount - 1
  ) {
    return false;
  }
  if (
    context.slideCount <= context.slidesPerPage ||
    context.activeSlideIndex >= context.slideCount - context.slidesPerPage
  ) {
    return false;
  }

  return true;
}

export function canGoBack(
  context: Pick<
    CarouselContextValue,
    | "endBehavior"
    | "activeSlidePosition"
    | "activeSlideIndex"
    | "slideCount"
    | "slidesPerPage"
  >,
) {
  if (isInfinite(context)) {
    return true;
  }
  return (
    context.activeSlideIndex !== 0 && context.slideCount > context.slidesPerPage
  );
}

export function getElementWidth(elem: HTMLElement | null | undefined) {
  if (!elem) {
    return 0;
  }
  // TODO (Chance 2023-11-22): Not the most performant way to get the size as
  // getBoundingClientRect forces a reflow. But offsetWidth/offsetHeight won't
  // account for size affected by CSS transform. If that's not something we
  // care to support for slide elements we can use those instead.
  const rect = elem.getBoundingClientRect();
  return rect.width;
}

export function getElementHeight(elem: HTMLElement | null | undefined) {
  if (!elem) {
    return 0;
  }
  // TODO (Chance 2023-11-22): Not the most performant way to get the size as
  // getBoundingClientRect forces a reflow. But offsetWidth/offsetHeight won't
  // account for size affected by CSS transform. If that's not something we
  // care to support for slide elements we can use those instead.
  const rect = elem.getBoundingClientRect();
  return rect.height;
}

export function getTrackStaticStyles(
  startPosition: number,
  context: Pick<
    CarouselContextValue,
    | "activeSlidePosition"
    | "endBehavior"
    | "slideCount"
    | "slidesPerMove"
    | "slidesPerPage"
    | "trackRef"
    | "listSize"
    | "autoWidth"
    | "isFade"
    | "orientation"
  >,
) {
  const style: React.CSSProperties = {
    transitionDuration: "0ms",
    transitionProperty: "none",
  };
  if (context.isFade) {
    style.opacity = 1;
  } else {
    style.transform = getTrackTransform(startPosition, {
      orientation: context.orientation,
    });
  }
  return style;
}

export function getTrackAnimatingStyles(
  startPosition: number,
  context: Pick<
    CarouselContextValue,
    | "transitionSpeed"
    | "transitionEasing"
    | "activeSlidePosition"
    | "endBehavior"
    | "slideCount"
    | "slidesPerMove"
    | "slidesPerPage"
    | "trackRef"
    | "listSize"
    | "autoWidth"
    | "isFade"
    | "orientation"
  >,
) {
  const style = getTrackStaticStyles(startPosition, context);
  style.transitionDelay = getTrackTransitionDelay(context);
  style.transitionDuration = getTrackTransitionDuration(context);
  style.transitionProperty = getTrackTransitionProperty(context);
  style.transitionTimingFunction = getTrackTransitionTimingFunction(context);
  return style;
}

export function querySelectorAll<T extends HTMLElement = HTMLElement>(
  carouselId: string,
  element: HTMLElement | null,
  selector: string,
) {
  const nodeList = element?.querySelectorAll<T>(
    `[data-replo-root-id="${carouselId}"]${selector}`,
  );
  return nodeList ? Array.from(nodeList) : [];
}

export function getSlideTransitionStates(
  index: number,
  context: Pick<
    CarouselContextValue,
    | "activeSlideIndex"
    | "activeSlidePosition"
    | "autoWidth"
    | "carouselId"
    | "endBehavior"
    | "isFade"
    | "isHydrated"
    | "isImmediateTransition"
    | "isTransitioning"
    | "listSize"
    | "orientation"
    | "slideCount"
    | "slidesPerMove"
    | "slidesPerPage"
    | "trackRef"
    | "transitionEasing"
    | "transitionSpeed"
  >,
  opts?: GoToOptions,
): undefined | CarouselTransitionStates {
  const {
    slideCount,
    activeSlideIndex,
    activeSlidePosition,
    slidesPerMove,
    slidesPerPage,
  } = context;

  if (slideCount < 1) {
    // Either slides haven't been rendered or they haven't been initialized yet,
    // so there's nothing to update.
    return;
  }

  const infinite = isInfinite(context);
  if (!infinite && (index < 0 || index >= slideCount)) {
    // edge slide; nothing to update
    return;
  }

  const targetSlideIndex = index;
  if (context.isFade) {
    let animationSlide = index;
    if (index < 0) {
      animationSlide = index + slideCount;
    } else if (index >= slideCount) {
      animationSlide = index - slideCount;
    }
    return {
      state: {
        isTransitioning: true,
        activeSlideIndex: animationSlide,
        targetSlideIndex: animationSlide,
      },
      nextState: {
        isTransitioning: false,
        targetSlideIndex: animationSlide,
      },
    };
  }

  let animationSlide = index;
  let finalSlide = animationSlide;
  if (animationSlide < 0) {
    finalSlide =
      slideCount % slidesPerMove !== 0
        ? slideCount - (slideCount % slidesPerMove)
        : animationSlide + slideCount;
  } else if (!canGoNext(context) && animationSlide > activeSlideIndex) {
    animationSlide = finalSlide = activeSlideIndex;
  } else if (
    activeSlidePosition === SLIDE_POSITION_CENTER &&
    animationSlide >= slideCount
  ) {
    animationSlide = infinite ? slideCount : slideCount - 1;
    finalSlide = infinite ? 0 : slideCount - 1;
  } else if (animationSlide >= slideCount) {
    finalSlide = animationSlide - slideCount;
    if (!infinite) {
      finalSlide = slideCount - slidesPerPage;
    } else if (slideCount % slidesPerMove !== 0) {
      finalSlide = 0;
    }
  }

  if (!infinite && animationSlide + slidesPerPage >= slideCount) {
    finalSlide = slideCount - slidesPerPage;
  }

  let initialStartPosition = getTrackStartPosition(animationSlide, context);
  const finalStartPosition = getTrackStartPosition(finalSlide, context);
  if (!infinite) {
    if (initialStartPosition === finalStartPosition) {
      animationSlide = finalSlide;
    }
    initialStartPosition = finalStartPosition;
  }

  if (opts?.disableAnimation || context.isImmediateTransition) {
    return {
      state: {
        isTransitioning: false,
        activeSlideIndex: finalSlide,
        trackStartPosition: finalStartPosition,
        targetSlideIndex,
      },
      nextState: null,
    };
  }
  return {
    state: {
      isTransitioning: true,
      activeSlideIndex: finalSlide,
      trackStartPosition: initialStartPosition,
      targetSlideIndex,
    },
    nextState: {
      isTransitioning: false,
      activeSlideIndex: finalSlide,
      trackStartPosition: finalStartPosition,
      targetSlideIndex,
    },
  };
}

export type GoToOptions = {
  disableAnimation?: boolean;
};

export type CarouselTransitionStates =
  | FadeTransitionStates
  | ImmediateTransitionStates
  | SlideTransitionStates;

export type FadeTransitionStates = {
  state: {
    isTransitioning: true;
    activeSlideIndex: CarouselState["activeSlideIndex"];
    targetSlideIndex: CarouselState["targetSlideIndex"];
  };
  nextState: {
    isTransitioning: false;
    targetSlideIndex: CarouselState["targetSlideIndex"];
  };
};

export type ImmediateTransitionStates = {
  state: {
    isTransitioning: false;
    activeSlideIndex: CarouselState["activeSlideIndex"];
    targetSlideIndex: CarouselState["targetSlideIndex"];
    trackStartPosition: number;
  };
  nextState: null;
};

export type SlideTransitionStates = {
  state: {
    isTransitioning: true;
    activeSlideIndex: CarouselState["activeSlideIndex"];
    targetSlideIndex: CarouselState["targetSlideIndex"];
    trackStartPosition: number;
  };
  nextState: {
    isTransitioning: false;
    activeSlideIndex: CarouselState["activeSlideIndex"];
    targetSlideIndex: CarouselState["targetSlideIndex"];
    trackStartPosition: number;
  };
};
