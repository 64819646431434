import type { AnySchema } from "yup";

import * as React from "react";

import ErrorMessage from "@components/account/Dashboard/ErrorMessage";

import { warningToast } from "@replo/design-system/components/alert/Toast";
import { BsX } from "react-icons/bs";

type PillTextAreaProps = {
  pills: string[];
  setPills: React.Dispatch<React.SetStateAction<string[]>>;
  inputValidator?: AnySchema;
};

const PillTextArea: React.FC<PillTextAreaProps> = ({
  inputValidator,
  pills,
  setPills,
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [inputError, setInputError] = React.useState<string | null>(null);
  const input = React.useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Note (Sebas,2023-11-14): This is needed to prevent the input value from being updated when
    // the user pastes a value. This is because the paste is handled in another function.
    // @ts-expect-error
    if (event.nativeEvent.inputType === "insertFromPaste") {
      return;
    }
    setInputValue(event.target.value);
  };
  const showPlaceholder = pills.length === 0 && inputValue.length === 0;

  const handleAddPill = (value: string) => {
    const trimmedValue = value.trim();
    if (
      trimmedValue !== "" &&
      isValueValid(trimmedValue) &&
      !isPillDuplicated(pills, trimmedValue)
    ) {
      setPills([...pills, trimmedValue]);
      setInputValue("");
      input.current!.value = "";
    }
  };

  const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      inputValue === ""
    ) {
      const newPills = [...pills];
      newPills.pop();
      setPills(newPills);
    } else if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      handleAddPill(inputValue);
    }
  };

  const handleOnBlur = () => {
    if (inputValue !== "") {
      handleAddPill(inputValue);
    }
  };

  const handleRemovePill = (pill: string) => {
    setPills(pills.filter((p) => p !== pill));
  };

  const isValueValid = (value: string) => {
    try {
      inputValidator?.validateSync(value);
      setInputError(null);
      return true;
    } catch (error: any) {
      setInputError(error.message);
      return false;
    }
  };

  const handleInputPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData("text");

    const emails = pastedText.split(",").map((email) => email.trim());
    const validEmails: string[] = [];

    emails.forEach((email) => {
      if (isValueValid(email) && !isPillDuplicated(pills, email)) {
        validEmails.push(email);
      }
    });

    setPills([...pills, ...validEmails]);
    setInputValue("");
    setInputError(null);
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <div
        className="flex relative flex-wrap w-full min-h-[150px] bg-subtle p-3 gap-1 content-start cursor-text rounded text-sm"
        onClick={() => {
          // Note (Sebas,2023-10-02): This is needed to make the cursor go to the end of the input when
          // the user clicks on the div. This is because the input is hidden and the div is the one
          // that receives the click.
          const currentInputValue = input.current?.value;
          input.current!.value = "";
          setTimeout(() => {
            input.current!.value = currentInputValue ?? "";
          }, 0);
          input.current?.focus();
        }}
      >
        {showPlaceholder && (
          <span className="absolute text-subtle text-sm pt-1">
            email@example.com, email2@example.com
          </span>
        )}
        {pills.map((pill, index) => (
          <div
            key={index}
            className="flex items-center gap-1 text-sm text-default py-1 pl-2 pr-1 rounded bg-hover h-fit"
          >
            {pill}
            <BsX
              size={18}
              onClick={() => handleRemovePill(pill)}
              className="cursor-pointer"
            />
          </div>
        ))}
        <input
          ref={input}
          onClick={(event) => event.stopPropagation()}
          autoFocus
          type="text"
          className="h-7 w-min outline-none text-sm overflow-hidden bg-transparent"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleOnBlur}
          onKeyDown={handleInputKeydown}
          size={input.current?.value.length || 1}
          onPaste={handleInputPaste}
        />
      </div>
      <ErrorMessage error={inputError} className="mb-0" />
    </div>
  );
};

const isPillDuplicated = (pills: string[], pill: string) => {
  if (pills.includes(pill)) {
    warningToast(
      "Duplicate Value Detected",
      "The value you inputted has already been included.",
    );
    return true;
  }
  return false;
};

export default PillTextArea;
