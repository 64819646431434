import type { Component } from "schemas/component";
import type { ProductRef } from "schemas/product";
import type { GetProductFallbackStrategy } from "../ReploProduct";
import type { Context } from "../ReploVariable";

import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { getProduct } from "../ReploProduct";

/**
 * @returns A Shopify product object based on the component's `_product` prop or
 * its context's `_product` attribute.
 */
export function useProductFromProps(
  componentProps: Component["props"],
  componentContext: Context,
  args?: {
    fallbackStrategy?: GetProductFallbackStrategy | null;
  },
) {
  const { fallbackStrategy } = args ?? {};
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const {
    fakeProducts,
    activeCurrency,
    moneyFormat,
    activeLanguage,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const isShopifyProductsLoading =
    useRuntimeContext(RuntimeHooksContext).useIsShopifyProductsLoading();
  const productMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyProductMetafieldValues();
  const variantMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyVariantMetafieldValues();
  const productRef: ProductRef =
    componentProps._product || componentContext.attributes?._product;
  return getProduct(productRef, componentContext, {
    products,
    currencyCode: activeCurrency,
    moneyFormat,
    language: activeLanguage,
    productMetafieldValues,
    variantMetafieldValues,
    isEditor: isEditorApp,
    fakeProducts,
    fallbackStrategy,
    templateProduct,
    isShopifyProductsLoading,
  });
}
