import React from "react";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";

export interface TagProps {
  size: "base" | "sm";
  variant: "success" | "info" | "danger" | "ai" | "beta" | "neutral";
  children?: React.ReactNode;
  UNSAFE_className?: string;
  layoutClassName?: string;
  startEnhancer?: React.ReactNode;
  tooltipText?: string;
}

const Tag: React.FC<TagProps> = ({
  size = "base",
  variant,
  children,
  UNSAFE_className,
  layoutClassName,
  startEnhancer,
  tooltipText,
}) => {
  const sizeClassName = {
    sm: "text-[10px] font-medium py-[2px] px-1 gap-1",
    base: "typ-body-small py-1 px-[6px] gap-1",
  };

  const variantClassName = {
    success: "bg-success-soft text-success border-success/20",
    info: "bg-info-soft text-info border-info/20",
    danger: "bg-danger-soft text-danger border-danger/20",
    ai: "bg-ai-soft text-ai border-ai/20",
    beta: "bg-beta-soft text-beta border-beta/20",
    neutral: "bg-neutral-soft text-neutral border-neutral/20",
  };

  const tagComponent = (
    <div
      className={twMerge(
        "flex items-center justify-center rounded font-normal w-fit border",
        sizeClassName[size],
        variantClassName[variant],
        layoutClassName,
        UNSAFE_className,
      )}
    >
      {startEnhancer}
      {children}
    </div>
  );

  return tooltipText ? (
    <Tooltip content={tooltipText} triggerAsChild>
      {tagComponent}
    </Tooltip>
  ) : (
    tagComponent
  );
};

export { Tag };
