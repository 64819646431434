// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { MenuItem } from "@replo/design-system/components/menu/Menu";
import type { BoxSide } from "replo-utils/lib/types";
import type { PreviewableProperty } from "schemas/preview";
import type { RuntimeStyleProperties } from "schemas/styleAttribute";

import * as React from "react";

import Selectable from "@components/common/designSystem/Selectable";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useDraftElementMetadata } from "@editor/hooks/useDraftElementMetadata";
import { useDynamicCommandMenuItem } from "@editor/hooks/useDynamicCommandMenuItems";
import {
  selectBottom,
  selectDraftComponentsPositionKey,
  selectDraftComponentsPositionOffset,
  selectDraftElementType,
  selectPosition,
  selectTop,
  selectTransform,
  selectZIndex,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import DocumentationInfoIcon from "@editorComponents/DocumentationInfoIcon";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";
import {
  isEmptyOrCssDefault,
  transformDefaultValues,
} from "@editorModifiers/utils";
import ArrowWithLine from "@svg/icons/arrow-with-line";

import { MenuItem as MenuItemComponent } from "@replo/design-system/components/menu/MenuItem";
import merge from "lodash-es/merge";
import { AiOutlineVerticalAlignMiddle } from "react-icons/ai";
import { BsLayersHalf } from "react-icons/bs";
import { GiPositionMarker } from "react-icons/gi";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";
import { shopifyHeaderSelector } from "replo-runtime/store/utils/cssSelectors";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { coerceNumberToString } from "replo-utils/lib/misc";

import ModifierLabel from "../extras/ModifierLabel";

/**
 * NOTE (Yuxin) We set a z-index here so that it stacks above other components which don't
 * have a z-index, but not an insanely high value so that it overlaps with things like the page header
 *
 * https://replohq.slack.com/archives/C02784LUZPD/p1668282852421849
 */
const FIXED_Z_INDEX = 1;

const menuOptions = [
  { label: "Reset", value: "0px" },
  { label: "12px", value: "12px" },
  { label: "24px", value: "24px" },
  { label: "32px", value: "32px" },
];

const createPositioningOptions = (isStickyToHeaderDisabled: boolean) => [
  {
    value: "relative",
    label: "Default",
  },
  {
    value: "absolute",
    label: "Relative to Container",
  },
  {
    value: "fixed",
    label: "Fixed",
  },
  {
    value: "sticky",
    label: "Sticky",
  },
  {
    value: "__alchemy:stickyToHeader",
    label: "Sticky to Header",
    isDisabled: isStickyToHeaderDisabled,
  },
];

const PositioningModifier: React.FC = () => {
  const position = useCurrentPosition();
  const zIndex = useEditorSelector(selectZIndex);
  const groupRef = React.useRef<HTMLDivElement>(null);

  const [controls, addControl] = useGetModifierControls<"position">(
    "position",
    groupRef,
  );

  const hasPositionControl = controls.has("position");
  const hasZIndexControl = controls.has("zIndex");

  const onSelectPosition = React.useCallback(() => {
    addControl("position");
  }, [addControl]);

  const positionDynamicMenuItem = React.useMemo(() => {
    return !controls.has("position")
      ? ({
          label: "Add Position",
          group: "add",
          icon: { type: "iconComponent", component: GiPositionMarker },
          onSelect: onSelectPosition,
        } as const)
      : null;
  }, [controls, onSelectPosition]);

  useDynamicCommandMenuItem("position", positionDynamicMenuItem);

  const onSelectZIndex = React.useCallback(() => {
    addControl("zIndex");
  }, [addControl]);

  const zIndexDynamicMenuItem = React.useMemo(() => {
    return !controls.has("zIndex")
      ? ({
          label: "Add Z-Index",
          group: "add",
          icon: { type: "iconComponent", component: BsLayersHalf },
          onSelect: onSelectZIndex,
        } as const)
      : null;
  }, [controls, onSelectZIndex]);

  useDynamicCommandMenuItem("zIndex", zIndexDynamicMenuItem);

  const menuItems: MenuItem[] = [
    {
      id: "position",
      title: "Position",
      type: "leaf",
      variant: "default",
      size: "sm",
      disabled: hasPositionControl,
      onSelect: () => {
        addControl("position");
      },
    },
    {
      id: "zIndex",
      title: "Z-Index",
      type: "leaf",
      variant: "default",
      size: "sm",
      disabled: hasZIndexControl,
      onSelect: () => {
        addControl("zIndex");
      },
    },
  ];

  const hasControlsToRender = controls.size > 0;
  const isDefaultOpen =
    isMixedStyleValue(position) ||
    isMixedStyleValue(zIndex) ||
    !isEmptyOrCssDefault(
      !isMixedStyleValue(position) ? position : null,
      styleAttributeToEditorData.position.defaultValue,
    ) ||
    !isEmptyOrCssDefault(
      !isMixedStyleValue(zIndex) ? coerceNumberToString(zIndex) : null,
    );

  return (
    <ModifierGroup
      ref={groupRef}
      title="Positioning"
      titleEnhancer={<DocumentationInfoIcon documentationType="positioning" />}
      tooltipText="Add Position or Z-Index"
      isDefaultOpen={isDefaultOpen}
      menuItems={menuItems}
      isCollapsible={hasControlsToRender}
    >
      {hasControlsToRender ? (
        <div className="flex flex-col gap-2">
          {hasPositionControl ? (
            <>
              <PositionSelectable />
              <PositionConditionalControls />
            </>
          ) : null}
          {hasZIndexControl ? <ZIndexControl /> : null}
        </div>
      ) : null}
    </ModifierGroup>
  );
};

const PositionSelectable = () => {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const draftElementMetadata = useDraftElementMetadata();
  const draftElementHideDefaultHeader =
    draftElementMetadata?.hideDefaultHeader ?? false;
  const applyComponentAction = useApplyComponentAction();
  const currentPosition = useCurrentPosition();
  const zIndex = useEditorSelector(selectZIndex);

  const shopifySectionHeader = window.document.querySelector(
    shopifyHeaderSelector,
  );
  const isShopifySectionHeaderFixed =
    shopifySectionHeader &&
    getComputedStyle(shopifySectionHeader).position === "fixed";

  const shouldShowStickyToHeader =
    draftElementType === "page" &&
    !draftElementHideDefaultHeader &&
    !isShopifySectionHeaderFixed;

  // Note (Sebas, 2022-10-19): This is necessary to reset the
  // transform translate properties for centered relative
  // to container components
  const translateResetValue = {
    __transform: {
      translateY: "0px",
      translateX: "0px",
    },
  };
  // Note (Sebas, 2022-10-20): When we change between default/absolute
  // we need to reset the flexbox modifier values to show the correct
  // option on the toggle group
  const getFlexboxModifierResetValue = () => {
    return { alignSelf: "auto", flexGrow: "unset" };
  };

  const zIndexValueToApply = isMixedStyleValue(zIndex)
    ? FIXED_Z_INDEX
    : zIndex ?? FIXED_Z_INDEX;

  return (
    <div className="flex">
      <ModifierLabel label="Position" UNSAFE_className="items-center" />
      <Selectable
        layoutClassName="w-full"
        triggerLayoutClassName="w-full"
        popoverLayoutClassName="w-44"
        value={currentPosition}
        options={createPositioningOptions(!shouldShowStickyToHeader)}
        onSelect={(value) => {
          switch (value) {
            case "relative":
              applyComponentAction({
                type: "setStyles",
                value: {
                  position: value,
                  top: "auto",
                  left: "auto",
                  right: "auto",
                  bottom: "auto",
                  ...translateResetValue,
                  ...getFlexboxModifierResetValue(),
                },
              });
              break;

            case "fixed":
              applyComponentAction({
                type: "setStyles",
                value: {
                  position: value,
                  top: "0px",
                  left: "0px",
                  right: "auto",
                  bottom: "auto",
                  ...translateResetValue,
                  ...getFlexboxModifierResetValue(),
                },
              });
              break;

            case "sticky":
              applyComponentAction({
                type: "setStyles",
                value: {
                  position: value,
                  top: "0px",
                  bottom: "initial",
                  left: "auto",
                  right: "auto",
                  zIndex: zIndexValueToApply,
                  ...translateResetValue,
                },
              });

              break;

            case "absolute":
              applyComponentAction({
                type: "setStyles",
                value: {
                  zIndex: zIndexValueToApply,
                  position: value,
                  top: "0px",
                  left: "0px",
                  right: "auto",
                  bottom: "auto",
                  ...translateResetValue,
                  ...getFlexboxModifierResetValue(),
                },
              });

              break;

            case "__alchemy:stickyToHeader":
              applyComponentAction({
                type: "setStyles",
                value: {
                  position: value,
                  top: "0px",
                  left: "auto",
                  right: "auto",
                  bottom: "auto",
                  ...translateResetValue,
                },
              });
              break;

            default:
              return;
          }
        }}
      />
    </div>
  );
};

const PositionConditionalControls: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const value = useCurrentPosition();
  const topValue = useEditorSelector(selectTop);
  const bottomValue = useEditorSelector(selectBottom);

  if (value === "__alchemy:stickyToHeader") {
    return <StickyToHeaderPositioningModifier />;
  }

  if (value === "sticky") {
    const getStickyValue = () => {
      if (topValue && topValue !== "initial") {
        return "top";
      }
      if (bottomValue && bottomValue !== "initial") {
        return "bottom";
      }
      return "top";
    };
    const stickyValue = getStickyValue();
    return (
      <div className="flex flex-col gap-2">
        <div className="flex">
          <ModifierLabel label="Sticky to" UNSAFE_className="items-center" />
          <Selectable
            layoutClassName="w-full"
            triggerLayoutClassName="w-full"
            value={stickyValue}
            options={[
              {
                value: "top",
                label: "Top",
              },
              {
                value: "bottom",
                label: "Bottom",
              },
            ]}
            onSelect={(value: "top" | "bottom") => {
              const propToReset = value === "top" ? "bottom" : "top";
              applyComponentAction({
                type: "setStyles",
                value: {
                  [value]: "0px",
                  [propToReset]: "initial",
                },
              });
            }}
          />
        </div>
        <LengthInputSelector
          draggingType={DraggingTypes.Vertical}
          draggingDirection={DraggingDirections.Positive}
          anchorValue="0px"
          // Note (Noah, 2024-05-04, USE-917): This modifier's resetValue is not the
          // same as the CSS default value, since having no "top" or "bottom" breaks
          // position: sticky, so we never want the user to remove this value
          resetValue="0px"
          allowsNegativeValue={true}
          dragTrigger="label"
          label={<ModifierLabel label="Y" UNSAFE_className="items-center" />}
          menuOptions={menuOptions}
          placeholder="0px"
          key={`style.${stickyValue}`}
          field={`style.${stickyValue}`}
          startEnhancer={<ArrowWithLine />}
          metrics={CSS_LENGTH_TYPES}
          onChange={(value: any) => {
            applyComponentAction({
              type: "setStyles",
              value: { [stickyValue]: value },
            });
          }}
        />
      </div>
    );
  }

  return value !== "sticky" && <PresetSpacingToggles />;
};

const ZIndexControl = () => {
  const zIndexValue = useEditorSelector(selectZIndex);
  const applyComponentAction = useApplyComponentAction();

  return (
    <LengthInputSelector
      draggingType={DraggingTypes.Vertical}
      draggingDirection={DraggingDirections.Positive}
      anchorValue="0"
      resetValue="0"
      allowsNegativeValue={false}
      menuOptions={[
        { label: "Reset", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
      ]}
      placeholder="0"
      key="style.zIndex"
      field="style.zIndex"
      value={
        isMixedStyleValue(zIndexValue)
          ? zIndexValue
          : coerceNumberToString(zIndexValue) ?? "0"
      }
      dragTrigger="label"
      label={<ModifierLabel label="Z-Index" UNSAFE_className="items-center" />}
      metrics={[""]}
      onChange={(value: any) => {
        applyComponentAction({
          type: "setStyles",
          value: {
            zIndex: value,
          },
        });
      }}
    />
  );
};

const StickyToHeaderPositioningModifier = () => {
  const applyComponentAction = useApplyComponentAction();
  return (
    <LengthInputSelector
      draggingType={DraggingTypes.Vertical}
      draggingDirection={DraggingDirections.Positive}
      anchorValue="0px"
      // Note (Noah, 2024-05-04, USE-917): This modifier's resetValue is not the
      // same as the CSS default value, since having no "top" or "bottom" breaks
      // position: sticky, so we never want the user to remove this value
      resetValue="0px"
      allowsNegativeValue={true}
      menuOptions={menuOptions}
      placeholder="None"
      key="style.top"
      field="style.top"
      dragTrigger="label"
      label={<ModifierLabel label="Offset" UNSAFE_className="items-center" />}
      metrics={CSS_LENGTH_TYPES}
      onChange={(value: any) => {
        applyComponentAction({
          type: "setStyles",
          value: { top: value },
        });
      }}
    />
  );
};

const PresetSpacingToggles = () => {
  const applyComponentAction = useApplyComponentAction();
  const [verticalPosition, horizontalPosition] = useEditorSelector(
    selectDraftComponentsPositionKey,
  );
  const [verticalOffset, horizontalOffset] = useEditorSelector(
    selectDraftComponentsPositionOffset,
  );
  const transformValues = useEditorSelector(selectTransform);
  const currentPosition = useCurrentPosition();
  const verticalPositionDefaultValue =
    !isMixedStyleValue(verticalPosition) &&
    verticalPosition &&
    verticalPosition !== "center"
      ? styleAttributeToEditorData[verticalPosition]?.defaultValue
      : undefined;
  const horizontalPositionDefaultValue =
    !isMixedStyleValue(horizontalPosition) &&
    horizontalPosition &&
    horizontalPosition !== "center"
      ? styleAttributeToEditorData[horizontalPosition]?.defaultValue
      : undefined;

  const onClickPresetSpacingArrangement = (
    orientation: BoxSide & "center",
    positionSelector: "vertical" | "horizontal",
  ) => {
    let newValues = {};
    const translateX = transformValues?.translateX ?? "0px";
    const translateY = transformValues?.translateY ?? "0px";
    if (positionSelector === "vertical") {
      let newVerticalValues = {
        top: styleAttributeToEditorData.top.defaultValue,
        bottom: styleAttributeToEditorData.bottom.defaultValue,
        __transform: {
          ...transformDefaultValues,
          translateX,
          translateY,
        },
      };
      if (orientation === "center") {
        newVerticalValues = merge({}, newVerticalValues, {
          top: "50%",
          bottom: "auto",
          __transform: {
            translateY: "-50%",
          },
        });
      } else {
        newVerticalValues = merge({}, newVerticalValues, {
          [orientation as "top" | "bottom"]: "0px",
          __transform: {
            translateY: "0px",
          },
        });
      }
      newValues = newVerticalValues;
    }
    if (positionSelector === "horizontal") {
      let newHorizontalValues = {
        left: styleAttributeToEditorData.top.defaultValue,
        right: styleAttributeToEditorData.bottom.defaultValue,
        __transform: {
          ...transformDefaultValues,
          translateX,
          translateY,
        },
      };
      if (orientation === "center") {
        newHorizontalValues = merge({}, newHorizontalValues, {
          left: "50%",
          right: "auto",
          __transform: {
            translateX: "-50%",
          },
        });
      } else {
        newHorizontalValues = merge({}, newHorizontalValues, {
          [orientation as "left" | "right"]: "0px",
          __transform: {
            translateX: "0px",
          },
        });
      }
      newValues = newHorizontalValues;
    }

    applyComponentAction({
      type: "setStyles",
      value: newValues,
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex">
        <ModifierLabel label="X" UNSAFE_className="items-center" />
        <Selectable
          layoutClassName="w-full"
          triggerLayoutClassName="w-full"
          value={horizontalPosition}
          options={[
            {
              value: "left",
              label: (
                <MenuItemComponent
                  variant="default"
                  size="sm"
                  startEnhancer={<ArrowWithLine className="-rotate-90" />}
                  selected={horizontalPosition === "left"}
                >
                  Left
                </MenuItemComponent>
              ),
              displayValue: "Left",
            },
            {
              value: "center",
              label: (
                <MenuItemComponent
                  variant="default"
                  size="sm"
                  startEnhancer={
                    <AiOutlineVerticalAlignMiddle
                      size={16}
                      className="text-subtle rotate-90"
                    />
                  }
                  selected={horizontalPosition === "center"}
                >
                  Center
                </MenuItemComponent>
              ),
              displayValue: "Center",
            },
            {
              value: "right",
              label: (
                <MenuItemComponent
                  variant="default"
                  size="sm"
                  startEnhancer={<ArrowWithLine className="rotate-90" />}
                  selected={horizontalPosition === "right"}
                >
                  Right
                </MenuItemComponent>
              ),
              displayValue: "Right",
            },
          ]}
          onSelect={(value: BoxSide & "center") =>
            onClickPresetSpacingArrangement(value, "horizontal")
          }
        />
      </div>
      {currentPosition !== "sticky" && (
        <LengthInputSelector
          placeholder="auto"
          // NOTE (Sebas, 2024-12-19): If the offset is mixed we need to pass the mixed value
          // here because there are cases where it can't be calculated by the field prop.
          // For example if one component has a left offset of 5px and another has a right offset
          // of 10px, the field prop will be checking only the left offset and not the right offset.
          value={
            isMixedStyleValue(horizontalOffset)
              ? horizontalOffset
              : horizontalOffset?.value
          }
          field={`style.${!isMixedStyleValue(horizontalOffset) ? horizontalOffset?.value : null}`}
          draggingType={DraggingTypes.Vertical}
          draggingDirection={DraggingDirections.Positive}
          allowsNegativeValue={true}
          anchorValue="0px"
          resetValue={horizontalPositionDefaultValue}
          dragTrigger="label"
          label={
            <ModifierLabel label="X Offset" UNSAFE_className="items-center" />
          }
          metrics={CSS_LENGTH_TYPES}
          onChange={(value: any) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                [horizontalPosition as string]: value,
                // Note (Sebas, 2022-06-20, REPL-2261): We need to always set the position because if not,
                // the values top/left don't work.
                position: !isMixedStyleValue(currentPosition)
                  ? currentPosition
                  : null,
              },
            });
          }}
          previewProperty={`${horizontalPosition}Offset` as PreviewableProperty}
          menuOptions={menuOptions}
          isDisabled={horizontalPosition === "center"}
        />
      )}
      <div className="flex">
        <ModifierLabel label="Y" UNSAFE_className="items-center" />
        <Selectable
          layoutClassName="w-full"
          triggerLayoutClassName="w-full"
          value={verticalPosition}
          options={[
            {
              value: "top",
              label: (
                <MenuItemComponent
                  variant="default"
                  size="sm"
                  startEnhancer={<ArrowWithLine />}
                  selected={verticalPosition === "top"}
                >
                  Top
                </MenuItemComponent>
              ),
              displayValue: "Top",
            },
            {
              value: "center",
              label: (
                <MenuItemComponent
                  variant="default"
                  size="sm"
                  startEnhancer={
                    <AiOutlineVerticalAlignMiddle
                      size={16}
                      className="text-subtle"
                    />
                  }
                  selected={verticalPosition === "center"}
                >
                  Center
                </MenuItemComponent>
              ),
              displayValue: "Center",
            },
            {
              value: "bottom",
              label: (
                <MenuItemComponent
                  variant="default"
                  size="sm"
                  startEnhancer={<ArrowWithLine className="rotate-180" />}
                  selected={verticalPosition === "bottom"}
                >
                  Bottom
                </MenuItemComponent>
              ),
              displayValue: "Bottom",
            },
          ]}
          onSelect={(value: BoxSide & "center") =>
            onClickPresetSpacingArrangement(value, "vertical")
          }
        />
      </div>
      <LengthInputSelector
        placeholder="auto"
        draggingType={DraggingTypes.Vertical}
        draggingDirection={DraggingDirections.Positive}
        value={
          isMixedStyleValue(verticalOffset)
            ? verticalOffset
            : verticalOffset?.value
        }
        field={`style.${!isMixedStyleValue(verticalOffset) ? verticalOffset?.value : null}`}
        anchorValue="0px"
        resetValue={verticalPositionDefaultValue}
        allowsNegativeValue={true}
        label={
          <ModifierLabel label="Y Offset" UNSAFE_className="items-center" />
        }
        dragTrigger="label"
        metrics={CSS_LENGTH_TYPES}
        onChange={(value: any) => {
          applyComponentAction({
            type: "setStyles",
            value: {
              [verticalPosition as string]: value,
              // Note (Sebas, 2022-06-20, REPL-2261): We need to always set the position because if not,
              // the values top/left don't work.
              position: !isMixedStyleValue(currentPosition)
                ? currentPosition
                : null,
            },
          });
        }}
        previewProperty={`${verticalPosition}Offset` as PreviewableProperty}
        menuOptions={menuOptions}
        isDisabled={verticalPosition === "center"}
      />
    </div>
  );
};

export function useCurrentPosition() {
  const rawValue = useEditorSelector(selectPosition);
  const isMixedValue = isMixedStyleValue(rawValue);
  const value = rawValue || "relative";
  if (isMixedValue) {
    return rawValue;
  }
  return value === "static"
    ? "relative"
    : (value as RuntimeStyleProperties["position"]);
}

export default PositioningModifier;
