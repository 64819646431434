import type { Hotkey } from "@replo/design-system/utils/hotkeys";

import React from "react";

import { Checkbox } from "@replo/design-system/components/checkbox/Checkbox";
import { HotkeyIndicator } from "@replo/design-system/components/hotkeys/HotKeyIndicator";
import Switch from "@replo/design-system/components/switch/Switch";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { Check, ChevronRight } from "lucide-react";

type BaseMenuItemProps = {
  children?: React.ReactNode;
  size?: "sm" | "base";
  disabled?: boolean;
  layoutClassName?: string;
  UNSAFE_className?: string;
  onHover?: () => void;
  toolTipText?: string | null | undefined;
};

export type DefaultVariant = {
  variant: "default";
  selected?: boolean;
  startEnhancer?: React.ReactNode;
};

export type CommandVariant = {
  variant: "command";
  shortcut?: string;
  startEnhancer?: React.ReactNode;
};

export type ExpandableVariant = {
  variant: "expandable";
  startEnhancer?: React.ReactNode;
};

export type CheckboxVariant = {
  variant: "checkbox";
  selected?: boolean;
};

export type SwitchVariant = {
  variant: "switch";
  isOn: boolean;
};

export type CustomVariant = {
  variant: "custom";
  selected?: boolean;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
};

export type ProductVariant = {
  variant: "product";
  selected?: boolean;
  startEnhancer?: React.ReactNode;
  hasCheckbox?: boolean;
};

export type MenuItemProps = BaseMenuItemProps &
  (
    | DefaultVariant
    | CommandVariant
    | ExpandableVariant
    | CheckboxVariant
    | SwitchVariant
    | CustomVariant
    | ProductVariant
  ) &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

// TODO (Craig, 2025-02-19) This is a helper function to check if the first child is a valid React element.
// This is used to determine if the children should be truncated.
// At some point, we should refactor how we render children to avoid this.
function validChildElement(children: React.ReactNode): boolean {
  const childrenArray = React.Children.toArray(children);
  return React.isValidElement(childrenArray[0]);
}

const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  (props, ref) => {
    const size = props.size ?? "base";
    const content = (
      <button
        ref={ref}
        role="menuitem"
        className={twMerge(
          "w-full cursor-pointer flex items-center justify-between relative shrink rounded hover:bg-selectable-hover disabled:opacity-50 disabled:hover:bg-transparent disabled:cursor-not-allowed outline-none",
          size === "sm" && "px-2 h-6 typ-body-small",
          size === "base" && "px-2 h-7 typ-body-base",
          "selected" in props &&
            props.selected &&
            "bg-selectable-selected hover:bg-selectable-selected-hover",
          props.layoutClassName,
          props.UNSAFE_className,
        )}
        disabled={props.disabled}
        onMouseEnter={props.onHover}
        {...props}
      >
        <div className="flex w-full items-center justify-items-stretch gap-2 truncate mr-auto">
          {"startEnhancer" in props && props.startEnhancer}
          {props.variant === "checkbox" && (
            <Checkbox checked={props.selected} />
          )}
          <div className="flex grow truncate">
            {validChildElement(props.children) ? (
              props.children
            ) : (
              <span className="truncate">{props.children}</span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 shrink-0">
          {"shortcut" in props && props.shortcut && (
            <HotkeyIndicator hotkey={props.shortcut as Hotkey} title="" />
          )}
          {"endEnhancer" in props && props.endEnhancer}
          {props.variant === "default" && props.selected && (
            <Check className="icon-size-default" />
          )}
          {props.variant === "expandable" && (
            <ChevronRight className="icon-size-default" />
          )}
          {props.variant === "switch" && (
            <Switch size={props.size ?? "base"} isOn={props.isOn} />
          )}
          {props.variant === "product" && props.hasCheckbox && (
            <Checkbox checked={props.selected} />
          )}
        </div>
      </button>
    );
    if (props.toolTipText) {
      return (
        <Tooltip content={props.toolTipText} triggerAsChild={true}>
          {content}
        </Tooltip>
      );
    } else {
      return content;
    }
  },
);

MenuItem.displayName = "MenuItem";

export { MenuItem };
