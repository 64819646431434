import type { LeftBarTab } from "@editor/reducers/ui-reducer";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { ReploElement, ReploElementType } from "schemas/generated/element";

import * as React from "react";

import {
  HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
  prepareComponentTemplate,
} from "@components/editor/defaultComponentTemplates";
import useHandleReplaceComponentWithDesignLibraryReferences from "@editor/hooks/designLibrary/useHandleReplaceComponentWithDesignLibraryReferences";
import { useCreateElementMutation } from "@editor/hooks/element/useCreateElementMutation";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  selectGetAttribute,
  selectIsShopifyIntegrationEnabled,
} from "@editor/reducers/core-reducer";
import { setLeftBarActiveTab } from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { sanitizeElementName } from "@editor/utils/element";
import { getEmptyTemplate } from "@editor/utils/template";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { reploElementTypeSchema } from "schemas/element";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";

import { useLocalStorageState } from "../useLocalStorage";

interface CreateElementParams {
  type: ReploElementType;
  folderId?: string;
  initialTemplate?: ComponentTemplate;
  projectId?: string;
  withAiFlow?: boolean;
  leftBarActiveTab?: LeftBarTab | null;
  applyAIGenerationOnNavigate?: boolean;
}

const useCollapsedElementTypeGroups = (projectId: string) => {
  const localStorageKey = `replo.project.id.${projectId}.elements-panel.v1`;
  const [collapsedGroups, setCollapsedGroups] = useLocalStorageState(
    localStorageKey,
    [] as ReploElementType[],
    { schema: z.array(reploElementTypeSchema) },
  );

  const addCollapsedGroup = React.useCallback(
    (type: ReploElementType) => {
      if (!collapsedGroups.includes(type)) {
        setCollapsedGroups([...collapsedGroups, type]);
      }
    },
    [collapsedGroups, setCollapsedGroups],
  );

  return { collapsedGroups, addCollapsedGroup };
};

export function useCreateElement() {
  const navigate = useNavigate();
  const dispatch = useEditorDispatch();
  const { createElement } = useCreateElementMutation();
  const errorToast = useErrorToast();
  const setDraftElement = useSetDraftElement();
  const store = useEditorStore();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const currentProjectId = useCurrentProjectId();
  const { addCollapsedGroup } = useCollapsedElementTypeGroups(
    currentProjectId ?? "",
  );
  const { handleReplaceComponentWithDesignLibraryReferences } =
    useHandleReplaceComponentWithDesignLibraryReferences();
  const { data: shopifyBlogs } = trpc.shopify.getBlogs.useQuery(
    currentProjectId && isShopifyIntegrationEnabled
      ? currentProjectId
      : skipToken,
  );

  const getElement = React.useCallback(
    (
      projectId: string,
      type: ReploElementType,
      initialTemplate?: ComponentTemplate,
      folderId?: string,
    ) => {
      let shopifyBlogId: string | undefined;
      if (type === "shopifyArticle") {
        shopifyBlogId = shopifyBlogs?.[0]?.id;
        if (!shopifyBlogId) {
          return null;
        }
      }

      const shortUniqueId = uuidv4().slice(0, 6);

      const element = {
        name:
          type === "shopifySection" ? `Untitled ${shortUniqueId}` : "Untitled",
        projectId,
        type,
        hideDefaultHeader: false,
        hideDefaultFooter: false,
        hideShopifyAnnouncementBar: false,
        shopifyPagePath: `untitled-${shortUniqueId}`,
        shopifyTemplateSuffix: "",
        isHomepage: false,
        isPublished: false,
        shopifyMetafields: [],
        shopifyArticleImage: { src: "" },
        ...(shopifyBlogId && { shopifyBlogId }),
        ...(folderId && { folderId }),
      };

      const template = initialTemplate ?? getEmptyTemplate(type);
      const sanitizedTemplate =
        template?.template?.type !== "container"
          ? {
              ...HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
              template: {
                ...HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE.template!,
                children: [template.template],
              },
            }
          : template;

      const preparedComponent = prepareComponentTemplate(
        sanitizedTemplate,
        null,
        element,
        {
          getAttribute: selectGetAttribute(store.getState()),
          productResolutionDependencies: {
            products: [],
            currencyCode: "",
            language: "",
            moneyFormat: "",
            templateProduct: null,
            isEditor: true,
            isShopifyProductsLoading: false,
          },
          componentDataMapping: {},
          context: null,
        },
      );
      const [componentToAdd] = handleReplaceComponentWithDesignLibraryReferences
        ? handleReplaceComponentWithDesignLibraryReferences(
            [preparedComponent],
            template?.designLibraryMetadata ?? null,
          )
        : [preparedComponent];

      return {
        ...element,
        name: sanitizeElementName(element.name, type),
        component: componentToAdd,
      };
    },
    [shopifyBlogs, store, handleReplaceComponentWithDesignLibraryReferences],
  );

  const handleElementCreationResponse = React.useCallback(
    (
      result:
        | {
            element: ReploElement;
          }
        | {
            key: string;
            message: string;
            element: ReploElement | null;
          },
      type: ReploElementType,
      leftBarActiveTab?: LeftBarTab | null,
      applyAIGenerationOnNavigate?: boolean,
    ) => {
      if (result.element?.id) {
        setDraftElement({
          id: result.element.id,
        });
        const path = `/editor/${result.element.projectId}/${result.element.id}`;

        const searchParams = new URLSearchParams();
        const searchString = searchParams.toString();

        navigate(
          searchString ? `${path}?${searchString}` : path,
          applyAIGenerationOnNavigate
            ? { state: { isGeneratingTemplatePage: true } }
            : undefined,
        );
        dispatch(
          setLeftBarActiveTab(
            leftBarActiveTab === undefined ? "components" : leftBarActiveTab,
          ),
        );
        addCollapsedGroup(type);

        return result.element;
      } else {
        errorToast(
          "Please try again or reach out to support@replo.app for help.",
          "Failed to create page",
          "error.element.create",
          {
            elementDetail: JSON.stringify(result),
          },
        );
      }
      return null;
    },
    [setDraftElement, navigate, dispatch, addCollapsedGroup, errorToast],
  );

  const handleCreateElement = React.useCallback(
    async ({
      type,
      folderId,
      initialTemplate,
      projectId,
      leftBarActiveTab,
      applyAIGenerationOnNavigate,
    }: CreateElementParams) => {
      const targetProjectId = projectId ?? currentProjectId;
      if (!targetProjectId) {
        return null;
      }

      const element = getElement(
        targetProjectId,
        type,
        initialTemplate,
        folderId,
      );
      if (!element) {
        return null;
      }

      const result = await createElement(element);
      return handleElementCreationResponse(
        result,
        type,
        leftBarActiveTab,
        applyAIGenerationOnNavigate,
      );
    },
    [
      createElement,
      currentProjectId,
      handleElementCreationResponse,
      getElement,
    ],
  );

  return { handleCreateElement };
}
