import type { Workspace } from "schemas/generated/workspace";

import * as React from "react";

import { useRequiredContext } from "replo-utils/react/context";

/**
 * Context object for the current workspace. Components inside a WorkspaceProvider
 * can use this context to access the current workspace data
 */
export const WorkspaceDashboardContext = React.createContext<{
  workspaceId: string | null;
  workspace: Workspace | null;
  isLoading: boolean;
} | null>(null);

/**
 * @returns The entire current workspace context. If not inside a workspace provider,
 * this errors. If inside a workspace provider but the workspace data has not loaded yet,
 * the workspace on the context will be null.
 */
export const useCurrentWorkspaceContext = () => {
  return useRequiredContext(WorkspaceDashboardContext);
};

/**
 * @returns The entire current workspace id. If not inside a workspace provider, or if
 * the workspace data has not loaded yet, this returns nullish.
 */
export const useCurrentWorkspaceId = () => {
  return React.useContext(WorkspaceDashboardContext)?.workspaceId;
};

/**
 * @returns The entire current workspace object. If not inside a workspace provider,
 * or if the workspace data has not loaded yet, this returns nullish.
 */
export const useCurrentWorkspace = () => {
  return React.useContext(WorkspaceDashboardContext)?.workspace;
};
