import type { AlchemyActionType } from "replo-runtime/shared/enums";

import * as React from "react";

import Input from "@common/designSystem/Input";
import DynamicDataValueIndicator from "@editor/components/editor/page/element-editor/components/extras/DynamicDataValueIndicator";
import { useModal } from "@editor/hooks/useModal";
import { getPathFromVariable } from "@editor/utils/dynamic-data";

import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";

type LinkData = { url?: string; openNewTab?: boolean };

type LinkEditorProps = {
  data?: LinkData | string;
  onChange(data: LinkData | string): void;
  placeholder?: string;
  type?: AlchemyActionType;
  allowsDynamicData?: boolean;
};

const LinkEditor: React.FC<React.PropsWithChildren<LinkEditorProps>> = ({
  data,
  onChange,
  placeholder,
  type,
  allowsDynamicData = false,
}) => {
  const isDataAString = typeof data === "string";
  const isRedirectUrlLike = type === "redirect";
  const modal = useModal();

  const openDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "callback",
          onChange: (value: string) => {
            onChange({ url: value });
          },
        },
        initialPath: getPathFromVariable(
          (isDataAString ? data : data?.url) ?? "",
        ),
      },
    });
  };

  const urlValue = isDataAString ? data : data?.url;
  return (
    <>
      <div className="flex flex-row gap-2">
        {urlValue && isDynamicDataValue(urlValue) && (
          <DynamicDataValueIndicator
            type="text"
            templateValue={urlValue}
            onClick={openDynamicData}
            onRemove={() => onChange({ url: undefined })}
          />
        )}
        {!isDynamicDataValue(urlValue) && (
          <Input
            id="link-editor-input"
            autoFocus={true}
            size="sm"
            value={urlValue}
            placeholder={placeholder}
            onChange={(e) => {
              const val = e?.target?.value ?? "";
              onChange(isDataAString ? val : { url: val });
            }}
            allowsDynamicData={allowsDynamicData}
            onClickDynamicData={openDynamicData}
          />
        )}
      </div>
      {!isDataAString && isRedirectUrlLike && (
        <SwitchWithDescription
          size="sm"
          isOn={data?.openNewTab ?? false}
          onChange={(checked) => {
            onChange({ openNewTab: checked });
          }}
          label="Open new tab"
          layoutClassName="mt-2"
        />
      )}
    </>
  );
};

export default LinkEditor;
