import React from "react";

import { Navigate, Outlet, useParams } from "react-router-dom";
import { validate as isUUIDValid } from "uuid";

import { EditorApp } from "./EditorApp";

interface EditorLayoutProps {
  children: React.ReactNode;
  toRenderWithinEditor?: React.ReactNode;
  toRenderOverEditor?: React.ReactNode;
  projectHeader?: React.ReactNode;
}

export function EditorLayout({
  children,
  toRenderWithinEditor,
  toRenderOverEditor,
  projectHeader,
}: EditorLayoutProps) {
  const { projectId } = useParams();

  // Note (Noah, 2024-08-18): JSS checks for this csp-nonce meta property any
  // time a stylesheet is created, memoizing the result. Adding in the nonce
  // here, even though it's a dummy value, means that JSS won't do an expensive
  // querySelector every time a new stylesheet is added, which would adversely
  // affect performance. See
  // https://github.com/cssinjs/jss/blob/901882a894c7e802450696ebe2ea633ae63c5977/packages/jss/src/DomRenderer.js#L219
  React.useEffect(() => {
    const nonce = document.createElement("meta");
    nonce.setAttribute("property", "csp-nonce");
    nonce.setAttribute("content", "replo-jss-nonce");
    document.head.append(nonce);
  }, []);

  if (!projectId || !isUUIDValid(projectId ?? "")) {
    return <Navigate to="/home" replace />;
  }

  return (
    <>
      <EditorApp
        toRenderWithinEditor={toRenderWithinEditor}
        toRenderOverEditor={toRenderOverEditor}
        projectHeader={projectHeader}
      />
      {children}
      <Outlet />
    </>
  );
}
