import type {
  Hotkey,
  HotkeyIndicatorCharacter,
  HotkeyMetaKey,
} from "@editor/utils/hotkeys";

import * as React from "react";

import { getHotkeyData, HotkeyMetaKeyToLabel } from "@editor/utils/hotkeys";

import { Badge } from "@replo/design-system/components/badge/Badge";
import { isMacintosh } from "@replo/design-system/utils/platform";
import twMerge from "@replo/design-system/utils/twMerge";

const FancyHotkeyMetaKeyToLabel = {
  ...HotkeyMetaKeyToLabel,
  meta: {
    mac: (HotkeyMetaKeyToLabel.meta as { mac: string }).mac,
    windows: <span style={{ fontSize: "6px", fontWeight: "bold" }}>CTRL</span>,
  },
  esc: {
    mac: (HotkeyMetaKeyToLabel.esc as { mac: string }).mac,
    windows: <span style={{ fontSize: "6px", fontWeight: "bold" }}>ESC</span>,
  },
};

export const HotkeyIndicator: React.FC<{
  title?: string;
  hotkey: Hotkey;
  className?: string;
  badgeBackgroundColor?: string;
}> = ({ hotkey, title, className, badgeBackgroundColor }) => {
  const hotkeyData = getHotkeyData(hotkey);
  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-center gap-1 text-xs",
        className,
      )}
    >
      <span className="block">{title ?? hotkeyData.displayName}</span>
      <div
        className={twMerge(
          "flex flex-row items-center gap-1",
          !badgeBackgroundColor && "gap-[2px]",
        )}
      >
        {getHotkeyLabel(hotkeyData.indicatorCharacters).map(
          ({ id, display }) => (
            <>
              {badgeBackgroundColor ? (
                <Badge
                  key={id}
                  type="icon"
                  icon={display}
                  isFilled
                  UNSAFE_className={twMerge(
                    "text-subtle",
                    badgeBackgroundColor
                      ? badgeBackgroundColor
                      : "bg-slate-200",
                  )}
                />
              ) : (
                <div key={id} className="typ-body-small text-placeholder">
                  {display}
                </div>
              )}
            </>
          ),
        )}
      </div>
    </div>
  );
};

function getHotkeyLabel(hotkey: HotkeyIndicatorCharacter[]) {
  return hotkey.map((character) => {
    const label = FancyHotkeyMetaKeyToLabel[character as HotkeyMetaKey];
    if (label) {
      if (typeof label === "string") {
        return { id: character, display: label };
      }
      return {
        id: character,
        display: !isMacintosh() ? label.windows : label.mac,
      };
    }
    return { id: character, display: character.toUpperCase() };
  });
}
