import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { AlchemyActionType } from "../../../shared/enums";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/props";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _alchemyEditor_previewProducts: {
      name: "Editor Preview Products",
      type: "products",
      description:
        "Select products to display in the editor preview. This Temporary Cart will act like it contains these products. This has no effect on the real page.",
      defaultValue: null,
    },
  };
}

const styleElements = {
  root: {},
} as const satisfies StyleElements;

const actionTypes = [
  "addVariantToTemporaryCart",
  "removeVariantFromTemporaryCart",
  "decreaseVariantCountInTemporaryCart",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: true,
    styleElements,
    variantTriggers: [
      "state.temporaryCart.numberOfItems",
      "state.temporaryCart.containsVariant",
    ],
    actionTypes,
  },
} satisfies ComponentConfig;

export default config;
