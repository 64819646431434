import * as React from "react";

import { Loader } from "@editor/components/common/Loader";
import { isDevelopment } from "@editor/utils/env";

import { AllMetricsTable } from "@/features/analytics/AllMetricsTable";
import AnalyticsLayout from "@/features/analytics/Layout";
import { SetupPage } from "@/features/analytics/Setup";
import useAnalyticsStatus from "@/features/analytics/useAnalyticsStatus";

export const AnalyticsOverview: React.FC = () => {
  const { isEnabled, isCurrentlyPaying, isLoading } = useAnalyticsStatus();

  return (
    <AnalyticsLayout>
      {isLoading ? (
        <Loader className="grow min-h-0 h-[70vh]" />
      ) : (
        <LoadedAnalyticsOverview
          isEnabled={isEnabled ?? false}
          isCurrentlyPaying={isCurrentlyPaying}
        />
      )}
    </AnalyticsLayout>
  );
};

type LoadedAnalyticsOverviewProps = {
  isEnabled: boolean;
  isCurrentlyPaying: boolean;
};

const LoadedAnalyticsOverview = ({
  isEnabled,
  isCurrentlyPaying,
}: LoadedAnalyticsOverviewProps) => {
  const showAllMetricsTable = isEnabled || isDevelopment;
  return (
    <>
      {showAllMetricsTable ? (
        <AllMetricsTable />
      ) : (
        <SetupPage isCurrentlyPaying={isCurrentlyPaying} />
      )}
    </>
  );
};
