import type { MetricName, Order } from "schemas/generated/analyticsRead";

import * as React from "react";

import { METRIC_SORT_TOOLTIP_TEXT } from "@/features/analytics/constants";
import IconButton from "@replo/design-system/components/button/IconButton";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsSortUpAlt } from "react-icons/bs";

type SortIndicatorProps = {
  sortOrder: Order;
  isActiveMetric: boolean;
  sortMetric: MetricName;
  onClick: () => void;
};

const SortIndicator: React.FC<SortIndicatorProps> = ({
  isActiveMetric,
  sortOrder,
  onClick,
  sortMetric,
}) => {
  return (
    <Tooltip
      triggerAsChild
      content={`${METRIC_SORT_TOOLTIP_TEXT[sortMetric]} ${sortOrder === "ASC" ? "Ascending" : "Descending"}`}
      delay={0}
    >
      <IconButton
        icon={
          sortOrder === "ASC" ? (
            <BsSortUpAlt
              className={twMerge(
                "h-[18px] w-[18px]",
                isActiveMetric && "fill-primary",
                !isActiveMetric && "fill-placeholder transform scale-y-[-1]",
              )}
            />
          ) : (
            <BsSortUpAlt
              className={twMerge(
                "h-[18px] w-[18px] transform scale-y-[-1]",
                isActiveMetric && "fill-primary",
                !isActiveMetric && "fill-placeholder",
              )}
            />
          )
        }
        variant="secondary"
        layoutClassName="h-6 w-6"
        onClick={onClick}
      />
    </Tooltip>
  );
};

interface SortButtonProps {
  sortMetric: MetricName;
  sortOrder: Order;
  isActiveMetric: boolean;
  onChange: () => void;
}

const SortButton: React.FC<SortButtonProps> = ({
  sortMetric,
  sortOrder,
  isActiveMetric,
  onChange,
}) => {
  return (
    <SortIndicator
      isActiveMetric={isActiveMetric}
      sortMetric={sortMetric}
      sortOrder={sortOrder}
      onClick={onChange}
    />
  );
};

export { SortButton };
