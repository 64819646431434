import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { Component } from "schemas/component";
import type { Formatter, ReferenceType } from "schemas/dynamicData";
import type { RuntimeStyleProperties } from "schemas/styleAttribute";
import type {
  GradientValue,
  ReploShopifyVariant,
  SolidOrGradient,
  Swatch,
  SwatchImage,
  SwatchValue,
  VariantPriceLabels,
} from "../types";

import isObject from "lodash-es/isObject";
import startCase from "lodash-es/startCase";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import {
  extractDynamicDataInfo,
  topLevelDynamicDataKeys,
} from "replo-runtime/store/ReploVariable";
import { isLiquidDynamicData } from "replo-runtime/store/utils/liquid";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import { isSolidValue } from "../types";
import { getCurrentComponentContext } from "./context";
import { isDynamicDesignLibraryValue } from "./designLibrary";

type SwatchValueMappingValue = string | string[] | GradientValue;
type SwatchValueMapping = Record<string, SwatchValueMappingValue>;

function getSwatchValueMapping(values: SwatchValue): SwatchValueMapping {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      switch (key) {
        case "color": {
          const colorValue = value as SolidOrGradient;
          return [
            key,
            isSolidValue(colorValue) ? colorValue.color : colorValue.gradient,
          ];
        }
        case "image":
          return [key, (value as SwatchImage)?.src];

        case "imageList":
          return [key, (value as SwatchImage[]).map((image) => image.src)];

        default:
          return [];
      }
    }),
  );
}

export function getOptionSwatchValueMapping(
  swatches: Swatch[],
  productId: string | number,
  option: { name: string; value: string },
) {
  const { name, value } = option;
  const mappedSwatches = swatches.reduce(
    (currentMappedSwatches: (string | SwatchValueMapping)[][], swatch) => {
      const {
        data: { options },
      } = swatch;

      let currentOption = options?.find(
        (swatchOptionRow) =>
          swatchOptionRow.key.name === name &&
          swatchOptionRow.key.value === value &&
          (!swatchOptionRow.key.productId ||
            String(swatchOptionRow.key.productId) === String(productId)),
      );

      // NOTE (Gabe 2023-10-09): If we can't find the swatch value for the
      // current product we'll fallback to any product. This way if Green is
      // defined on any product it will be used for any products where this
      // option value is not explicitly defined.
      if (!currentOption?.value) {
        currentOption = options?.find(
          (swatchOptionRow) =>
            swatchOptionRow.key.name === name &&
            swatchOptionRow.key.value === value,
        );
      }

      if (!currentOption?.value) {
        return currentMappedSwatches;
      }

      return [
        ...currentMappedSwatches,
        [swatch.name, getSwatchValueMapping(currentOption.value)],
      ];
    },
    [],
  );

  return mappedSwatches.some((mappedSwatch) => mappedSwatch.length > 0)
    ? Object.fromEntries(mappedSwatches)
    : null;
}

export function getVariantSwatchValueMapping(
  swatches: Swatch[],
  variant: ReploShopifyVariant,
) {
  const { variantId, productId } = variant;
  const mappedSwatches = swatches.reduce(
    (currentMappedSwatches: (string | SwatchValueMapping)[][], swatch) => {
      const {
        data: { variants },
      } = swatch;

      const currentVariant = variants?.find(
        (v) =>
          String(v.key.variantId) === String(variantId) &&
          String(v.key.productId) === String(productId),
      );
      if (!currentVariant?.value) {
        return currentMappedSwatches;
      }

      return [
        ...currentMappedSwatches,
        [swatch.name, getSwatchValueMapping(currentVariant.value)],
      ];
    },
    [],
  );

  return mappedSwatches.some((mappedSwatch) => mappedSwatch.length > 0)
    ? Object.fromEntries(mappedSwatches)
    : null;
}

export const getNonDesignLibraryDynamicDataExpressions = (
  value: string | ReploMixedStyleValue | undefined,
) => {
  if (!value || isMixedStyleValue(value)) {
    return null;
  }

  const regex = /{{(.*?)}}/g;
  return (
    value
      .match(regex)
      ?.filter((match) => !isDynamicDesignLibraryValue(match)) ?? null
  );
};

export const getDesignLibraryDynamicDataExpressions = (
  value: string | undefined,
) => {
  if (!value) {
    return null;
  }

  const regex = /{{(.*?)}}/g;
  return (
    value.match(regex)?.filter((match) => isDynamicDesignLibraryValue(match)) ??
    null
  );
};

export const getNonDesignLibraryDynamicDataInfo = (value: string) => {
  return extractDynamicDataInfo(value)
    .filter(({ path }) => path && !isDynamicDesignLibraryValue(path))
    .map((info) => {
      const { path } = info;
      if (!path) {
        return info;
      }
      const updatedInfo = extractUpdatedDefaultDynamicDataPriceInfo(path);
      return updatedInfo ?? info;
    });
};

export const getDynamicDataBreadcrumbs = (value: string) => {
  const paths = getNonDesignLibraryDynamicDataInfo(value);
  if (!paths[0] || !paths[0].path) {
    return "Dynamic Value";
  }
  const path = paths[0].path;
  return path
    .split(".")
    .filter((pathKey) => pathKey !== "attributes")
    .map((pathKey) => {
      if (pathKey === "rawPrice") {
        return "Price";
      }
      return startCase(pathKey.replace("_", ""));
    })
    .join(" > ");
};

const SUPPORTED_QUICK_ACCESS_DYNAMIC_DATA_PROP: Partial<
  Record<
    Component["type"],
    {
      propKey: string;
      targetType: DynamicDataTargetType;
    }
  >
> = {
  product: { propKey: "_product", targetType: DynamicDataTargetType.PRODUCT },
  text: { propKey: "text", targetType: DynamicDataTargetType.TEXT },
  image: { propKey: "__imageSource", targetType: DynamicDataTargetType.IMAGE },
  carouselV3: {
    propKey: "_items",
    targetType: DynamicDataTargetType.IMAGE_LIST,
  },
  selectionList: {
    propKey: "_items",
    targetType: DynamicDataTargetType.ANY_LIST,
  },
  tabsV2__block: {
    propKey: "_items",
    targetType: DynamicDataTargetType.ANY_LIST,
  },
};
const getPrimaryDynamicDataPropInfo = (component: Component) => {
  const componentContext = getCurrentComponentContext(component.id, 0);
  const componentAttributes = componentContext?.attributes;
  const dynamicDataPropInfo =
    SUPPORTED_QUICK_ACCESS_DYNAMIC_DATA_PROP[component.type];
  if (!dynamicDataPropInfo || !componentAttributes) {
    return null;
  }
  const doesComponentHaveDynamicAttribute = topLevelDynamicDataKeys.some(
    (key) => componentAttributes[key],
  );
  if (!doesComponentHaveDynamicAttribute) {
    return null;
  }
  return dynamicDataPropInfo;
};

export const getPrimaryDynamicDataProp = (
  component: Component,
): {
  propKey: string;
  targetType: DynamicDataTargetType;
  propValue?: any;
} | null => {
  const dynamicDataPropInfo = getPrimaryDynamicDataPropInfo(component);
  if (!dynamicDataPropInfo) {
    return null;
  }
  const { propKey, targetType } = dynamicDataPropInfo;
  const isStyleProp = [DynamicDataTargetType.IMAGE].includes(targetType);
  const propValue = isStyleProp
    ? component.props.style?.[propKey as keyof RuntimeStyleProperties]
    : component.props[propKey];
  return {
    propKey,
    targetType,
    propValue,
  };
};

// NOTE (Matt 2025-03-28): These are components that are considered "always connected" to
// dynamic data based ont he way they work under the hood, but don't have "quick-access"
// dynamic data props like those ofund in getPrimaryDynamicDataProp;
const ALWAYS_DYNAMIC_COMPONENTS: Set<Component["type"]> = new Set([
  "variantSelect",
  "variantSelectDropdown",
  "optionSelect",
  "optionSelectDropdown",
  "sellingPlanSelect",
  "sellingPlanSelectDropdown",
]);

export const doesComponentHaveConnectedDynamicDataProps = (
  component: Component,
) => {
  if (ALWAYS_DYNAMIC_COMPONENTS.has(component.type)) {
    return true;
  }
  const propInfo = getPrimaryDynamicDataProp(component);
  if (
    propInfo &&
    [DynamicDataTargetType.ANY_LIST, DynamicDataTargetType.IMAGE_LIST].includes(
      propInfo.targetType,
    )
  ) {
    return isObject(propInfo.propValue) && "dynamicPath" in propInfo.propValue;
  }
  return (
    propInfo?.propValue &&
    (propInfo.targetType === DynamicDataTargetType.PRODUCT ||
      isDynamicDataValue(propInfo.propValue))
  );
};

export const doesComponentSupportDynamicDataProps = (component: Component) => {
  return Boolean(getPrimaryDynamicDataPropInfo(component));
};
const REFERENCE_TYPE_MAPPING: Record<string, ReferenceType> = {
  rawPrice: "currency",
  price: "currency",
  compareAtPrice: "currency",
  compareAtPriceDifference: "currency",
  _endTime: "date",
};
export const getDynamicDataReferenceType = (
  path: string,
): ReferenceType | null => {
  const lastKey = path.split(".").slice(-1)[0]!;
  return REFERENCE_TYPE_MAPPING[lastKey] ?? null;
};

const UNSUPPORTED_PRICE_LABELS = new Set<VariantPriceLabels>([
  "price",
  "priceRounded",
  "priceWithoutSellingPlanDiscount",
  "priceWithoutSellingPlanDiscountRounded",
  "displayPrice",
  "displayPriceWithoutQuantity",
  "displayPriceWithoutSellingPlanDiscount",
  "displayPriceRounded",
  "compareAtPriceRounded",
  "compareAtDisplayPrice",
  "compareAtDisplayPriceRounded",
  "compareAtPriceDifference",
  "compareAtPriceDifferencePercentage",
  "compareAtPriceDifferenceRounded",
  "compareAtDisplayPriceDifference",
  "compareAtDisplayPriceDifferenceRounded",
]);

/**
 * Used to update old dynamic data formats to new ones. We used to have prescriptive prices that were formatted in the runtime,
 * and now we use the formatters array to apply formatting to just `price` and `compareAtPrice`.
 */
export const extractUpdatedDefaultDynamicDataPriceInfo = (
  dynamicDataPath: string,
): {
  path?: string;
  formatters: Formatter[];
} | null => {
  const path = dynamicDataPath.split(".");
  const lastKey = path.slice(-1)[0] as VariantPriceLabels | undefined;
  if (
    !path.includes("_variant") ||
    !lastKey ||
    !UNSUPPORTED_PRICE_LABELS.has(lastKey)
  ) {
    return null;
  }
  return exhaustiveSwitch({ type: lastKey })({
    rawPrice: {
      path: "attributes._variant.rawPrice",
      formatters: [{ type: "discount", discountType: "selectedSellingPlan" }],
    },
    price: {
      path: "attributes._variant.rawPrice",
      formatters: [{ type: "discount", discountType: "selectedSellingPlan" }],
    },
    priceRounded: {
      path: "attributes._variant.rawPrice",
      formatters: [
        { type: "discount", discountType: "selectedSellingPlan" },
        { type: "rounded" },
      ],
    },
    priceWithoutSellingPlanDiscount: {
      path: "attributes._variant.rawPrice",
      formatters: [],
    },
    priceWithoutSellingPlanDiscountRounded: {
      path: "attributes._variant.rawPrice",
      formatters: [{ type: "rounded" }],
    },
    displayPrice: {
      path: "attributes._variant.rawPrice",
      formatters: [
        { type: "discount", discountType: "selectedSellingPlan" },
        { type: "currency" },
      ],
    },
    displayPriceWithoutQuantity: {
      path: "attributes._variant.rawPrice",
      formatters: [
        { type: "discount", discountType: "selectedSellingPlan" },
        { type: "currency" },
      ],
    },
    displayPriceWithoutSellingPlanDiscount: {
      path: "attributes._variant.rawPrice",
      formatters: [{ type: "currency" }],
    },
    displayPriceRounded: {
      path: "attributes._variant.rawPrice",
      formatters: [
        { type: "discount", discountType: "selectedSellingPlan" },
        { type: "rounded" },
        { type: "currency" },
      ],
    },
    compareAtPrice: {
      path: "attributes._variant.compareAtPrice",
      formatters: [],
    },
    compareAtPriceRounded: {
      path: "attributes._variant.compareAtPrice",
      formatters: [{ type: "rounded" }],
    },
    compareAtDisplayPrice: {
      path: "attributes._variant.compareAtPrice",
      formatters: [{ type: "currency" }],
    },
    compareAtDisplayPriceRounded: {
      path: "attributes._variant.compareAtPrice",
      formatters: [{ type: "rounded" }, { type: "currency" }],
    },
    compareAtPriceDifference: {
      path: "attributes._variant.compareAtPriceDifference",
      formatters: [],
    },
    compareAtPriceDifferencePercentage: {
      path: "attributes._variant.compareAtPriceDifferencePercentage",
      formatters: [],
    },
    compareAtPriceDifferenceRounded: {
      path: "attributes._variant.compareAtPriceDifference",
      formatters: [{ type: "rounded" }],
    },
    compareAtDisplayPriceDifference: {
      path: "attributes._variant.compareAtPriceDifference",
      formatters: [{ type: "currency" }],
    },
    compareAtDisplayPriceDifferenceRounded: {
      path: "attributes._variant.compareAtPriceDifference",
      formatters: [{ type: "rounded" }, { type: "currency" }],
    },
  });
};

export const isDynamicDataValue = (
  value: string | ReploMixedStyleValue | undefined,
) => {
  if (!value || isMixedStyleValue(value)) {
    return false;
  }

  const matches = getNonDesignLibraryDynamicDataExpressions(value);
  if (matches) {
    return matches.length > 0;
  }

  return isLiquidDynamicData(value);
};
