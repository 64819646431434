import React from "react";

import { Badge } from "@replo/design-system/components/badge/Badge";
import twMerge from "@replo/design-system/utils/twMerge";

export type OverlapSize = "none" | "small" | "medium" | "large";

interface ColorPaletteDisplayProps {
  colors: string[];
  overlapSize: OverlapSize;
  onClick?: () => void;
  className?: string;
}

const ColorPaletteDisplay: React.FC<ColorPaletteDisplayProps> = ({
  colors,
  overlapSize,
  onClick,
  className,
}) => {
  const getZIndexClass = (index: number) => {
    const zClasses = ["z-50", "z-40", "z-30", "z-20", "z-10", "z-0"];
    return zClasses[index] ?? "z-0";
  };

  const getOverlapClass = (size: OverlapSize) => {
    switch (size) {
      case "none":
        return "";
      case "small":
        return "-ml-2";
      case "medium":
        return "-ml-3";
      case "large":
        return "-ml-5";
      default:
        return "";
    }
  };

  const overlapClass = getOverlapClass(overlapSize);

  const content = colors
    .slice(0, 6)
    .map((color, index) => (
      <Badge
        key={index}
        type="color"
        isFilled
        backgroundColor={color}
        layoutClassName={twMerge(
          index === 0 ? "" : overlapClass,
          getZIndexClass(index),
          "w-6 h-6 border-0",
        )}
      />
    ));

  const containerClassName = twMerge(
    "flex h-fit items-center rounded relative border-0.5 border-secondary",
    className,
  );
  const ariaLabel = "Color palette";

  return onClick ? (
    <button
      className={containerClassName}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {content}
    </button>
  ) : (
    <div className={containerClassName} aria-label={ariaLabel}>
      {content}
    </div>
  );
};

export default ColorPaletteDisplay;
