import type { Component } from "schemas/component";
import type { AiProjectContext } from "./generated/ai";
import type {
  Dependency,
  MetaFieldValuesMapping,
  StoreProduct,
} from "./generated/element";

import { reploComponentSchema } from "schemas/component";
import {
  dependencySchema,
  metafieldValuesMappingSchema,
  storeProductSchema,
} from "schemas/element";
import { z } from "zod";

import { reploShopifyProductSchema } from "./product";
import {
  colorTypeSchema,
  savedStyleTextAttributesSchema,
  savedStyleTextHtmlTagSchema,
} from "./savedStyles";

export const altTextSchema = z.object({
  result: z.string().nullable(),
});

const SupportedAIModel = [
  "claude-3-5-sonnet",
  "gpt-4o",
  "claude-3-7-sonnet-20250219",
  "gpt-4o-mini",
  "gemini-2.0-flash",
  "gemini-2.5-pro-exp-03-25",
] as const;

const aiStreamingOperations = [
  "template",
  "design",
  "textV2",
  "mobileResponsive",
  "savedStyles",
  "multi",
] as const;

const aiTriggeringFeatures = ["build-assistant", "new-page-flow"] as const;

export type SupportedAIModel = (typeof SupportedAIModel)[number];

export const aiTriggeringFeatureSchema = z
  .enum(aiTriggeringFeatures)
  .describe("AITriggeringFeature");

const baseAIActionSchema = z.object({
  elementId: z.string(),
  component: reploComponentSchema as z.ZodType<Component>,
  projectId: z.string(),
  streamingUpdateId: z.string().uuid(),

  screenshotDependencies: z.object({
    dependencies: z.record(z.array(dependencySchema as z.ZodType<Dependency>)),
    products: z.array(storeProductSchema as z.ZodType<StoreProduct>),
    productMetafieldValues: z.record(
      metafieldValuesMappingSchema as z.ZodType<MetaFieldValuesMapping>,
    ),
    variantMetafieldValues: z.record(
      metafieldValuesMappingSchema as z.ZodType<MetaFieldValuesMapping>,
    ),
    fullComponent: reploComponentSchema as z.ZodType<Component>,
  }),
  model: z.enum(SupportedAIModel).optional(),
  triggeringFeature: aiTriggeringFeatureSchema.optional(),
});

// TODO (Evan, 2024-09-13): for a little while, we're going to have two version of this -
// one with brand URL (to infer tone), and the other with the tone already inferred. Eventually,
// we will only have the second - after UI updates for AI in onboarding.
const aiProjectContextBase = z.object({
  whatBusinessSells: z.string().optional(),
  whoIsCustomer: z.string().optional(),
});

export const aiProjectContextSchema = aiProjectContextBase
  .extend({
    useExistingSite: z.boolean().optional(),
    existingSiteUrl: z.string().optional(),
  })
  .describe("AiProjectContext");

export const brandDetailsSchema = z
  .object({
    brandName: z.string().max(512).optional(),
    whatBusinessSells: z.string().max(512).optional(),
    whoIsCustomer: z.string().max(512).optional(),
    brandVoice: z.string().max(512).optional(),
  })
  .describe("BrandDetails");

const textV2ParamsSchema = z.object({
  userPrompt: z.string(),
});

export const chatMessageSchema = z
  .object({
    role: z.enum(["user", "assistant"]),
    content: z.string(),
    chatId: z.string(),
  })
  .describe("ChatMessage");

const designParamsSchema = z.object({
  nonce: z.number(),
  userPrompt: z.string(),
  conversationMessages: z.any(),
  page: reploComponentSchema.optional(),
});

const templateParamsSchema = z.object({
  nonce: z.number(),
  userPrompt: z.string(),
  conversationMessages: z.any(),
  sectionType: z.string().optional(),
  systemPrompt: z.string().optional(),
  initialPrompt: z.string().optional(),
});

export const templateBodySchema = baseAIActionSchema
  .merge(templateParamsSchema)
  .describe("TemplateBody");

export const designBodySchema = baseAIActionSchema
  .merge(designParamsSchema)
  .describe("DesignBody");

export const textv2Schema = baseAIActionSchema
  .merge(textV2ParamsSchema)
  .describe("TextV2Body");

export type TextContextPresent = Extract<
  keyof AiProjectContext,
  "whatBusinessSells" | "whoIsCustomer" | "existingSiteUrl"
>[];

const mobileResponsiveParamsSchema = z.object({});

export const mobileResponsiveSchema = baseAIActionSchema
  .merge(mobileResponsiveParamsSchema)
  .describe("MobileResponsiveBody");

const savedStylesParamsSchema = z.object({});

export const BuildAssistantParamsSchema = z
  .object({
    projectId: z.string(),
    contextComponentMapping: z
      .record(z.string(), reploComponentSchema)
      .optional(),
    selectedProduct: reploShopifyProductSchema
      .optional()
      .nullable()
      .describe("SchemaReploShopifyProduct"),
    streamingUpdateId: z.string().uuid(),
    model: z.enum(SupportedAIModel).optional(),
    systemPrompt: z.string().optional(),
    initialPrompt: z.string().optional(),
    componentsToGenerate: z.array(
      templateParamsSchema.extend({
        component: reploComponentSchema,
        elementId: z.string(),
      }),
    ),
    contextComponent: reploComponentSchema.optional(),
    useChainOfThought: z.boolean().optional(),
    generationType: z.enum(["parallel", "sequential"]).optional(),
    triggeringFeature: aiTriggeringFeatureSchema.optional(),
  })
  .describe("BuildAssistantParams");

export const multiSchema = baseAIActionSchema
  .merge(
    z.object({
      textV2: textV2ParamsSchema.optional(),
      mobileResponsive: mobileResponsiveParamsSchema.optional(),
      savedStyles: savedStylesParamsSchema.optional(),
    }),
  )
  .describe("MultiBody");

// Note (Evan, 2024-06-14): Our generated actions are either component actions (i.e., from ComponentActionType)
// or pseudo-actions actions that aren't part of ComponentActionType but are mapped to ComponentActionType actions.
// This is not 1:1 with ComponentActionType, since only a subset of actions are generated by the AI model.
export const bulkReplaceTextActionSchema = z.object({
  type: z.literal("bulkReplaceText"),
  mapping: z.record(z.string(), z.string()),
});

export const setPropsActionSchema = z.object({
  componentId: z.string(),
  type: z.literal("setProps"),
  value: z.record(z.any()),
});

const addComponentTemplateToComponentActionSchema = z.object({
  type: z.literal("addComponentTemplateToComponent"),
  componentId: z.string(),
  value: z.object({
    newComponentId: z.string(),
    position: z.string(),
    positionWithinSiblings: z.number().optional(),
  }),
});

const addComponentToComponentActionSchema = z.object({
  type: z.literal("addComponentToComponent"),
  componentId: z.string(),
  value: z.object({
    newComponent: reploComponentSchema,
    newComponentId: z.string(),
    position: z.string(),
    positionWithinSiblings: z.number().optional(),
  }),
});

export const setStylesActionSchema = z
  .object({
    componentId: z.string(),
    type: z.literal("setStyles"),
    // Note (Evan, 2024-07-10): Zod doesn't support passing the actual enum
    // (https://github.com/colinhacks/zod/discussions/2125)
    activeCanvas: z.enum(["desktop", "tablet", "mobile"]),
    value: z.record(z.any()),
  })
  .describe("SetStylesAction");

export const setStylesMobileActionSchema = z
  .object({
    componentId: z.string(),
    type: z.literal("setStylesMobile"),
    value: z.record(z.any()),
  })
  .describe("SetStylesMobileAction");

export const applySavedStyleActionSchema = z
  .object({
    type: z.literal("applySavedStyle"),
    componentId: z.string(),
    style: z.string(),
    textColorOverride: z.string().optional(),
  })
  .describe("ApplySavedStyleAction");

// Note (Evan, 2024-07-03): These are separated so that we can accept only
// "real" component actions (not pseudoactions) on the frontend.
const aiComponentActions = [
  setPropsActionSchema,
  setStylesActionSchema,
  addComponentTemplateToComponentActionSchema,
  addComponentToComponentActionSchema,
] as const;
export const aiComponentActionSchema = z
  .union(aiComponentActions)
  .describe("AIComponentAction");

const aiPseudoActions = [
  bulkReplaceTextActionSchema,
  setStylesMobileActionSchema,
  applySavedStyleActionSchema,
] as const;

const allAIActions = [...aiComponentActions, ...aiPseudoActions] as const;

// Note (Evan, 2024-07-03): Slight confusion here:
// - AnyAIActionSchema is the Typescript type for any (single) schema.
// - AnyAIAction is the Typescript type inferred from any (single) schema.
export type AnyAIActionSchema = (typeof allAIActions)[number];
export type AnyAIAction = z.infer<AnyAIActionSchema>;

export const aiActionTypeSchema = z
  .enum(aiStreamingOperations)
  .describe("AIStreamingOperation");

export const aiMetaEventSchema = z
  .object({
    // Note (Evan, 2024-11-07): We ~could~ do this as a z.enum()
    type: z.string(),
  })
  .describe("AIMetaEvent");

// #region Saved Styles Generation

export const colorStyleDependencySchema = z
  .string()
  .describe("ColorStyleDependency");

export const textStyleDependencySchema = savedStyleTextAttributesSchema
  .omit({ type: true })
  .extend({
    htmlTag: z.string(),
    fontWeight: z.string().optional(),
    textAlign: z.string().optional(),
    textTransform: z.string().optional(),
    textDecorationLine: z.string().optional(),
  })
  .describe("TextStyleDependency");

export const savedStylesDependenciesSchema = z
  .object({
    textStyleDependencies: z.array(textStyleDependencySchema),
    colorStyleDependencies: z.array(colorStyleDependencySchema),
  })
  .describe("SavedStylesDependencies");

export const generatedTextStyleSchema = z
  .object({
    tag: savedStyleTextHtmlTagSchema,
    usage: z.string().nullable(),
    attributes: z.array(z.string()).array(),
  })
  .describe("GeneratedTextStyle");

export const generatedColorStyleSchema = z
  .object({
    type: colorTypeSchema,
    color: z.string(),
    usage: z.string().nullable(),
  })
  .describe("GeneratedColorStyle");

/**
 * We use nullable here because the ai sdk currently doesn't support optional
 * fields and this enables us to provide this schema as is to the ai sdk.
 */
export const generatedPrimaryStylesSchema = z
  .object({
    color: generatedColorStyleSchema.array(),

    text: generatedTextStyleSchema.array(),
  })
  .describe("GeneratedPrimaryStyles");

// #endregion
