import type { ComponentTemplate } from "@editor/types/component-template";

export const modal: ComponentTemplate = {
  id: "b5546675-caea-4cfa-94db-63fb64317733",
  storeId: null,
  scope: "left-bar",
  type: "component",

  name: "Component/Popup",
  leftBarCategory: { main: "interactive", subCategory: "popup" },
  preview: "component/popup.svg",
  template: {
    id: "7c818aef-f428-49ba-9ab5-379354754092",
    name: "Popup",
    type: "modal",
    props: {
      _overlayColor: "#56607296",
      style: {
        marginLeft: "auto",
        marginRight: "auto",
      },
      "style@md": { alignSelf: "auto" },
      "style@sm": {
        overflow: "scroll",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    children: [
      {
        id: "3e4f1485-200a-475e-852c-09576908f2e8",
        type: "container",
        props: {
          style: {
            width: "1000px",
            __width: "1000px",
            display: "flex",
            flexGrow: "unset",
            paddingTop: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
            flexDirection: "row",
            paddingBottom: "0px",
            backgroundColor: "#ffffff",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            overflow: "hidden",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "start",
          },
          "style@md": {
            width: "100%",
            __width: "100%",
            display: "flex",
            flexGrow: "unset",
            flexDirection: "column",
          },
          "style@sm": {
            width: "100%",
            height: "100%",
            marginTop: "0px",
            alignItems: "center",
            paddingTop: "52px",
            paddingLeft: "21px",
            paddingRight: "21px",
            flexDirection: "column",
            paddingBottom: "30px",
            justifyContent: "flex-start",
            backgroundColor: null,
            alignSelf: null,
          },
        },
        children: [
          {
            id: "786c44ee-ce40-4a02-a392-a6922e8f426f",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                alignSelf: "stretch",
                maxWidth: "100%",
                flexGrow: "1",
                __flexGap: null,
                borderTopLeftRadius: "14px",
                borderTopRightRadius: "14px",
                borderBottomLeftRadius: "14px",
                borderBottomRightRadius: "14px",
              },
              "style@sm": { display: "flex", flexDirection: "column" },
            },
            children: [
              {
                id: "df2c3d8f-5296-44ca-af16-e5791a36cdb1",
                type: "image",
                props: {
                  src: "",
                  style: {
                    objectFit: "cover",
                    flexBasis: "auto",
                    __imageSource:
                      "https://cdn.shopify.com/s/files/1/0589/8921/5933/t/1/assets/DeathtoStock_Simplify2.jpeg?v=1651778938",
                    flexGrow: "unset",
                    width: "50%",
                    __width: "50%",
                    height: "600px",
                    __height: "600px",
                    objectPosition: "center bottom",
                  },
                  loading: "lazy",
                  "style@sm": {
                    alignSelf: "stretch",
                    __width: "50%",
                    width: "auto",
                    __height: "200px",
                    height: "200px",
                    flexGrow: "unset",
                  },
                },
                name: "Image 1",
              },
              {
                id: "d184def5-ae59-46da-9460-27837ee78c8c",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    alignSelf: "stretch",
                    maxWidth: "100%",
                    flexGrow: "1",
                    __flexGap: "20px",
                    paddingLeft: "5%",
                    paddingTop: "5%",
                    paddingRight: "5%",
                    paddingBottom: "5%",
                  },
                  "style@sm": { height: null, flexGrow: 1, flexBasis: "auto" },
                },
                children: [
                  {
                    id: "3da2ddbd-5e36-44af-8c0c-f7f482c072fc",
                    type: "text",
                    props: {
                      text: '<p><span style="color: rgb(86, 96, 114);">Unlock</span></p>',
                      style: {
                        fontSize: "30px",
                        lineHeight: "62px",
                        flexGrow: "unset",
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontFamily: "Avenir",
                        fontWeight: "900",
                        height: null,
                        flexBasis: "auto",
                        alignSelf: "center",
                        letterSpacing: "28px",
                      },
                    },
                  },
                  {
                    id: "976785b3-49c8-4626-b124-506263affa09",
                    type: "text",
                    props: {
                      text: '<p><span style="color: rgb(86, 96, 114);">30% off</span></p>',
                      style: {
                        fontSize: "80px",
                        lineHeight: "82px",
                        flexGrow: "unset",
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontFamily: "Avenir",
                        fontWeight: "900",
                        height: null,
                        flexBasis: "auto",
                        alignSelf: "center",
                      },
                    },
                  },
                  {
                    id: "bea6bc77-483e-4d18-8a5e-7262015d84a6",
                    type: "text",
                    props: {
                      text: "<p>Sign up for email &amp; SMS to get your coupon code &amp; get exclusive updates about product launches, sales, and more.</p>",
                      style: {
                        fontSize: "16px",
                        lineHeight: "",
                        flexGrow: "unset",
                        textAlign: "center",
                        textTransform: "",
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        height: null,
                        flexBasis: "auto",
                        alignSelf: "center",
                      },
                    },
                  },
                  {
                    id: "874aec28-d2a4-4abe-967e-b684bb2460e2",
                    type: "button",
                    props: {
                      text: "<p>Get Discount Code</p>",
                      style: {
                        fontSize: "20px",
                        fontWeight: "900",
                        lineHeight: "20px",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "32px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px",
                        paddingRight: "32px",
                        letterSpacing: "2px",
                        backgroundColor: "#48BE97FF",
                        color: "#FFFFFF",
                        alignSelf: "stretch",
                        marginTop: "8%",
                        marginBottom: "8%",
                        textTransform: "uppercase",
                        fontFamily: "Avenir",
                        display: "flex",
                      },
                      "style@md": { lineHeight: "26px" },
                    },
                  },
                  {
                    id: "6f4566ab-62fe-4ee3-9dc0-30f3fc091bd7",
                    type: "text",
                    props: {
                      text: '<p>By entering, you agree to the <a href="#" rel="noopener noreferrer" target="_blank"><u>Terms of Service</u></a> and <a href="#" rel="noopener noreferrer" target="_blank"><u>Privacy Policy</u></a>.</p>',
                      style: {
                        fontSize: "16px",
                        lineHeight: "",
                        flexGrow: "unset",
                        textAlign: "center",
                        textTransform: "",
                        fontFamily: "Avenir",
                        fontWeight: "400",
                        height: null,
                        flexBasis: "auto",
                        alignSelf: "center",
                      },
                    },
                  },
                ],
                name: "Container 3",
              },
            ],
            name: "Container 2",
          },
          {
            id: "eee57eab-1637-4afc-b331-45b955a19f5e",
            type: "icon",
            props: {
              style: {
                top: "12px",
                left: "auto",
                right: "12px",
                width: "20px",
                bottom: "auto",
                height: "20px",
                zIndex: 1,
                __width: "20px",
                __height: "20px",
                flexGrow: "unset",
                position: "absolute",
                flexBasis: "auto",
              },
              width: "24px",
              height: "24px",
              onClick: [
                {
                  id: "1c1233ea-242c-4ade-9f80-f8e910aa7cd7",
                  type: "closeModalComponent",
                  value: null,
                },
              ],
              iconName: "close",
              "style@sm": {
                top: "8px",
                right: "8px",
                width: "40px",
                height: "40px",
                position: "absolute",
              },
            },
          },
        ],
        name: "Container 1",
      },
    ],
  },
};
