import type { ComponentTemplate } from "@editor/types/component-template";

import set from "lodash-es/set";

export const subscribeAndSave: ComponentTemplate = {
  id: "25299112-b0aa-4528-a2cc-bedcb4c8968f",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Subscribe & Save",
  leftBarCategory: { main: "product", subCategory: "sellingPlans" },
  preview: "product/subscribeAndSave.svg",
  transforms: [
    (component, parent) => {
      if (parent?.type === "container" && parent.children?.length === 0) {
        set(component, "props.style.flexGrow", 1);
      }
      return component;
    },
  ],
  template: {
    id: "33c19413-846b-4d2e-ae30-41a7e4fd1501",
    name: "Subscribe & Save",
    type: "selectionList",
    props: {
      style: {
        __width: "50%",
        display: "flex",
        __flexGap: "10px",
        alignSelf: "stretch",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      _accessibilitySelection: true,
    },
    children: [
      {
        id: "9ad18bab-ec0d-45f8-b2f0-eeb93854146b",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            flexGrow: 1,
            alignSelf: "stretch",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            borderTopColor: "#000000",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            backgroundColor: "#FFFFFFFF",
            borderLeftColor: "#000000",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderRightColor: "#000000",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderBottomColor: "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            alignItems: "center",
            justifyContent: "flex-start",
            __flexGap: "10px",
          },
          onClick: [
            {
              id: "10a95de9-c71d-4fd7-a6d8-328accd841dc",
              type: "setActiveSellingPlan",
              value: {
                sellingPlanId: {
                  __reploOneTimePurchase: true,
                },
              },
            },
          ],
        },
        children: [
          {
            id: "71069550-2cb2-4a06-84b7-333fd07f7729",
            type: "text",
            props: {
              text: "<p>One-time purchase</p>",
              style: {
                fontSize: "20px",
                lineHeight: "20px",
                letterSpacing: "2px",
              },
            },
          },
          {
            id: "d48306ec-9349-47f9-b840-55e6ae418720",
            type: "text",
            props: {
              text: "{{attributes._variant.displayPriceWithoutSellingPlanDiscount}}",
              style: {
                fontSize: "16px",
                alignSelf: "stretch",
                textAlign: "left",
                fontFamily: "ITCGaramondStd-LtCond, sans-serif",
                fontWeight: "700",
                lineHeight: "22px",
              },
              "style@md": {
                fontSize: "14px",
                lineHeight: "18px",
              },
            },
            name: "Price without Selling Plan",
          },
        ],
        variants: [
          {
            id: "82d14389-e2d1-44c1-ad90-e49662a97916",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "43f9a9ec-5048-4323-a2dd-72c19bed0e8d",
            name: "Selected List Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fde26c5f-3ebe-4607-965b-f0e4aa350826",
                  field: "state.selectionList.isItemSelected",
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "43f9a9ec-5048-4323-a2dd-72c19bed0e8d": {
            componentOverrides: {
              "9ad18bab-ec0d-45f8-b2f0-eeb93854146b": {
                props: {
                  style: {
                    color: null,
                    borderColor: null,
                    borderStyle: null,
                    borderWidth: null,
                    borderTopColor: "#000000",
                    borderTopStyle: "solid",
                    borderTopWidth: "3px",
                    backgroundColor: null,
                    borderLeftColor: "#000000",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "3px",
                    borderRightColor: "#000000",
                    borderRightStyle: "solid",
                    borderRightWidth: "3px",
                    borderBottomColor: "#000000",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "3px",
                  },
                },
              },
            },
          },
        },
        name: "One-time Purchase",
      },
      {
        id: "11794ea9-17df-49c5-8627-b4b67b3f9401",
        name: "Subscribe",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "center",
            paddingTop: "20px",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "20px",
            borderTopColor: "#000000",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            justifyContent: "center",
            backgroundColor: "#FFFFFFFF",
            borderLeftColor: "#000000",
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderRightColor: "#000000",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderBottomColor: "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            __animateVariantTransitions: false,
            flexDirection: "column",
            cursor: "default",
          },
          onClick: [
            {
              id: "8cb4fd69-b79a-4446-8fcf-69f529fe7eee",
              type: "setActiveSellingPlan",
            },
          ],
        },
        children: [
          {
            id: "5489b226-172b-450f-8266-e8965c6a2425",
            type: "container",
            props: {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                maxWidth: "100%",
                alignSelf: "stretch",
                __flexGap: "10px",
              },
            },
            children: [
              {
                id: "1611ccde-2dc4-4517-8fa9-234afdec89b8",
                type: "text",
                props: {
                  text: "<p>Subscribe and Save</p>",
                  style: {
                    fontSize: "20px",
                    lineHeight: "20px",
                    letterSpacing: "2px",
                  },
                },
              },
              {
                id: "30b17e8c-8618-43d0-9669-c2a933ea2ea1",
                type: "text",
                props: {
                  text: "{{attributes._variant.displayPrice}}",
                  style: {
                    fontSize: "16px",
                    alignSelf: "stretch",
                    textAlign: "left",
                    fontWeight: "700",
                    lineHeight: "22px",
                  },
                  "style@md": {
                    fontSize: "14px",
                    lineHeight: "18px",
                  },
                },
                name: "Price",
              },
            ],
            name: "Title and Price",
          },
          {
            id: "c022bac9-f9f1-48c8-8cbc-257ee4d8fd8a",
            name: "Selling Plan List Dropdown Container",
            type: "container",
            props: {
              style: {
                color: "#000000FF",
                display: "none",
                flexGrow: 1,
                maxWidth: "100%",
                __display: "flex",
                __flexGap: "24px",
                alignSelf: "stretch",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#FCFCFCFF",
              },
              "style@md": {
                display: "none",
                __display: "flex",
              },
              "style@sm": {
                display: "none",
                __display: "flex",
              },
            },
            children: [
              {
                id: "addfa9a4-86ab-4dcd-8cb5-6aa43279b5c6",
                name: "Selling Plan Dropdown 1",
                type: "sellingPlanSelectDropdown",
                props: {
                  style: {
                    zIndex: "2",
                    fontSize: "16px",
                    alignSelf: "stretch",
                    fontWeight: 700,
                    paddingTop: "12px",
                    borderColor: "rgb(0, 0, 0)",
                    borderWidth: "1px",
                    paddingLeft: "12px",
                    paddingRight: "24px",
                    paddingBottom: "12px",
                    borderTopStyle: "solid",
                    borderLeftStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                  _includeOneTimePurchase: false,
                  _hideDefaultArrowDisplay: true,
                },
              },
              {
                id: "b045b794-290d-41f3-8b91-dafa52415dde",
                name: "Arrow",
                type: "icon",
                props: {
                  style: {
                    top: "50%",
                    right: "2px",
                    width: "24px",
                    height: "24px",
                    zIndex: 1,
                    position: "absolute",
                    __transform: {
                      skewX: "0deg",
                      skewY: "0deg",
                      scaleX: "100%",
                      scaleY: "100%",
                      scaleZ: "100%",
                      rotateX: "0deg",
                      rotateY: "0deg",
                      rotateZ: "0deg",
                      translateX: "0px",
                      translateY: "-50%",
                      translateZ: "0px",
                    },
                  },
                  iconName: "chevron-down",
                },
              },
            ],
          },
        ],
        variants: [
          {
            id: "7b769317-0dc7-47fb-a9b5-006844ccf356",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "9d1abee5-97cb-48fc-b036-e1e3875aa298",
            name: "Selected List Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "3c43ef1c-4465-44f5-942d-5653e84ee6b1",
                  field: "state.selectionList.isItemSelected",
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "9d1abee5-97cb-48fc-b036-e1e3875aa298": {
            componentOverrides: {
              "c022bac9-f9f1-48c8-8cbc-257ee4d8fd8a": {
                props: {
                  style: {
                    display: "flex",
                  },
                  "style@md": {
                    display: "flex",
                  },
                  "style@sm": {
                    display: "flex",
                  },
                },
              },
              "11794ea9-17df-49c5-8627-b4b67b3f9401": {
                props: {
                  style: {
                    color: null,
                    __flexGap: "10px",
                    backgroundColor: null,
                    borderTopWidth: "3px",
                    borderRightWidth: "3px",
                    borderBottomWidth: "3px",
                    borderLeftWidth: "3px",
                    borderWidth: null,
                    borderTopColor: "#000",
                    borderRightColor: "#000",
                    borderBottomColor: "#000",
                    borderLeftColor: "#000",
                    borderColor: null,
                    borderTopStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    borderLeftStyle: "solid",
                    borderStyle: null,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  },
                },
              },
              "5489b226-172b-450f-8266-e8965c6a2425": {
                props: {
                  style: {
                    alignItems: "center",
                    justifyContent: "flex-start",
                    __flexGap: "10px",
                    display: "flex",
                    flexDirection: "row",
                  },
                },
              },
              "1611ccde-2dc4-4517-8fa9-234afdec89b8": {
                props: {
                  style: {
                    width: "auto",
                    flexGrow: "unset",
                  },
                },
              },
              "30b17e8c-8618-43d0-9669-c2a933ea2ea1": {
                props: {
                  style: {
                    height: "auto",
                  },
                },
              },
              "addfa9a4-86ab-4dcd-8cb5-6aa43279b5c6": {
                props: {
                  style: {
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};
