import type { FormKey } from "@editor/contexts/ElementEditorErrorContext";
import type { ElementErrorType } from "@editor/utils/element";
import type {
  TabsContentOption,
  TabsTriggerOption,
} from "@replo/design-system/components/tabs/Tabs";
import type { StringMetafield } from "replo-runtime/shared/types";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Separator from "@editor/components/common/designSystem/Separator";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import { useInitial } from "@editor/hooks/useInitial";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useOnChangeMetafields from "@editor/hooks/useOnChangeMetafields";
import validatePageModalPath from "@editor/utils/validatePageModalPath";

import Editor, { useMonaco } from "@monaco-editor/react";
import InlineAlert from "@replo/design-system/components/alert/InlineAlert";
import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { Tabs } from "@replo/design-system/components/tabs/Tabs";
import debounce from "lodash-es/debounce";
import { slugify } from "replo-utils/lib/string";

import ElementCustomCssEditor from "./ElementCustomCssEditor";
import { ElementEditorErrors } from "./ElementEditorErrors";
import ElementNameEditor from "./ElementNameEditor";
import SeoElementEditor from "./SeoElementEditor";
import { ShopifyThemeSettings } from "./ShopifyThemeSettings";

type PageElementEditorTab = "general" | "shopifyTheme" | "advanced";

const PAGE_ELEMENT_EDITOR_TABS: TabsTriggerOption[] = [
  { value: "general", label: "General" },
  { value: "shopifyTheme", label: "Shopify Theme" },
  { value: "advanced", label: "Advanced" },
];

const PageElementEditor: React.FC = () => {
  const [selectedTab, setSelectedTab] =
    React.useState<PageElementEditorTab>("general");

  const PAGE_ELEMENT_EDITOR_CONTENT: TabsContentOption[] = [
    { value: "general", tabContent: <PageBasicSettings /> },
    {
      value: "shopifyTheme",
      tabContent: <ShopifyThemeSettings />,
    },
    { value: "advanced", tabContent: <PageAdvancedSettings /> },
  ];

  return (
    <div className="flex h-full">
      <Tabs
        tabsOptions={PAGE_ELEMENT_EDITOR_TABS}
        contentOptions={PAGE_ELEMENT_EDITOR_CONTENT}
        value={selectedTab}
        onValueChange={(value) => {
          setSelectedTab(value as PageElementEditorTab);
        }}
        size="sm"
        defaultValue={selectedTab}
      />
    </div>
  );
};

const PageBasicSettings: React.FC = () => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const initialElementIsPublished = useInitial(element.isPublished);
  const { errorMapping, setErrors, clearErrors } =
    useElementEditorErrorContext();
  const logEvent = useLogAnalytics();

  const { metafields, onMetafieldChange } = useOnChangeMetafields(
    element,
    onChangeElement,
  );

  const handleNameChange = (name: string) => {
    const shopifyPagePath = !element?.shopifyPagePath
      ? slugify(name)
      : element.shopifyPagePath;
    if (errorMapping["path"]?.includes("pathAlreadyExists")) {
      clearErrors("path", ["pathAlreadyExists"]);
    }
    onChangeElement({
      ...element,
      name,
      shopifyPagePath,
    });
  };

  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const shopifyPagePath = e.target.value;

    if (shopifyPagePath.length === 0) {
      setErrors("path", ["pathIsEmpty"]);
    } else {
      clearErrors("path", ["pathIsEmpty"]);
      if (validatePageModalPath(shopifyPagePath)) {
        setErrors("path", ["isInvalidPathName"]);
      } else {
        clearErrors("path", ["isInvalidPathName"]);
      }
    }

    if (errorMapping["path"]?.includes("pathAlreadyExists")) {
      clearErrors("path", ["pathAlreadyExists"]);
    }
    if (errorMapping["path"]?.includes("pathIsEmpty")) {
      clearErrors("path", ["pathIsEmpty"]);
    }

    onChangeElement({
      ...element,
      shopifyPagePath,
    });
  };

  const routes = [
    {
      value: "pages/",
      isSelected: true,
    },
  ];

  return (
    <div className="flex flex-col gap-y-4">
      <ElementNameEditor
        type="page"
        inputName="page-name"
        initialName={element.name}
        onChange={handleNameChange}
      />

      <LabeledControl
        label="URL"
        id="page-data-path"
        error={
          <PathInputError
            errorMapping={errorMapping}
            elementType={element.type}
          />
        }
        size="sm"
      >
        <div className="flex flex-row gap-2 items-center">
          <div className="flex-none">
            <Input
              value={routes.find((route) => route.isSelected)?.value}
              size="sm"
              layoutClassName="w-16"
              isDisabled={true}
            />
          </div>
          <Input
            id="page-data-path"
            value={element.shopifyPagePath}
            placeholder="path"
            size="sm"
            onChange={handlePathChange}
          />
        </div>
      </LabeledControl>

      {initialElementIsPublished && (
        <SwitchWithDescription
          label="Is Published"
          description="Public users can see this page."
          isOn={element.isPublished}
          onChange={(isPublished) =>
            onChangeElement({ ...element, isPublished })
          }
          size="sm"
          variant="card"
        />
      )}

      <SwitchWithDescription
        label="Set As Homepage"
        description="Set this page as the homepage of your store."
        isOn={element.isHomepage}
        onChange={(isHomepage) => {
          logEvent("editor.element.update.homepage", { isHomepage });
          onChangeElement({ ...element, isHomepage });
        }}
        size="sm"
        variant="card"
      />

      <Separator />

      <SeoElementEditor
        isPageSettings={true}
        metafields={metafields}
        onMetafieldChange={onMetafieldChange}
      />
    </div>
  );
};

const PathInputError: React.FC<{
  errorMapping: Record<FormKey, ElementErrorType[] | null>;
  elementType: ReploElementType;
}> = ({ errorMapping, elementType }) => {
  const isError =
    errorMapping &&
    Object.keys(errorMapping).some((key) =>
      errorMapping[key as FormKey]?.some((error) =>
        [
          "pathAlreadyExists",
          "isInvalidPathName",
          "pathIsEmpty",
          "loadingMissingData",
          "errorLoadingData",
          "storeHasNoBlogs",
        ].includes(error),
      ),
    );

  if (isError) {
    const errors = Object.values(errorMapping).flat();
    const primaryErrorType = errors.find(
      (error) =>
        error?.toLowerCase().includes("path") && typeof error === "string",
    );
    const errorType = primaryErrorType ?? errors[0];
    if (errorType) {
      return (
        <ElementEditorErrors errorType={errorType} elementType={elementType} />
      );
    }
  }

  return null;
};

const PageAdvancedSettings: React.FC = () => {
  const { setErrors: setElementErrors, clearErrors } =
    useElementEditorErrorContext();
  const { element, onChangeElement } = useElementEditorDataContext();
  const { metafields, onMetafieldChange } = useOnChangeMetafields(
    element,
    onChangeElement,
  );
  const [htmlErrors, setHtmlErrors] = React.useState<string[]>([]);

  const monaco = useMonaco();
  monaco?.editor.defineTheme("replo", {
    base: "vs",
    colors: {
      "editor.background": "#f1f5f9", // slate-100
      "editor.lineHighlightBackground": "#f1f5f9",
    },
    inherit: true,
    rules: [],
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now: eslint-disable-next-line react-hooks/exhaustive-deps
  const handleHeadContentChange = React.useCallback(
    debounce((value: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        `<html>${value}</html>`,
        "application/xml",
      );
      const errors = Array.from(doc.querySelectorAll("parsererror > div"))
        .map((el) => el.innerHTML)
        // NOTE (Evan, 9/25/23) Omitting the closing meta tag is very common,
        // so we hide associated errors to avoid confusion.
        .filter(
          (error) => !error.includes("Opening and ending tag mismatch: meta"),
        );
      setHtmlErrors(errors);
      if (errors.length > 0) {
        setElementErrors("customHtml", ["invalidCustomHtml"]);
      } else {
        clearErrors("customHtml", ["invalidCustomHtml"]);
      }
      onChangeElement((element) => ({
        ...element,
        customHeadContent: value,
      }));
    }, 300),
    [onMetafieldChange, debounce],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const beforeHeadEndContent =
    // NOTE (Gabe 2023-11-08): This fallback is where we used to store the
    // customHead. It no longer exists in the schema.
    // @ts-expect-error
    element.customHeadContent ??
    (
      metafields?.find((field) => field.key === "before_head_end_content") as
        | StringMetafield
        | undefined
    )?.value;

  return (
    <div className="flex flex-col gap-y-4">
      <LabeledControl label="Custom Page <head>" id="page-data-name" size="sm">
        <p className="text-xs text-neutral">
          {
            "This content will be inserted before the closing </head> tag in your Shopify theme."
          }
        </p>
        <div
          className="flex flex-col gap-2 rounded overflow-hidden"
          data-testid="custom-head-editor"
        >
          <Editor
            height="300px"
            defaultLanguage="html"
            defaultValue={beforeHeadEndContent}
            onChange={(value) => handleHeadContentChange(String(value))}
            options={{
              minimap: { enabled: false },
              scrollbar: { verticalScrollbarSize: 0 },
              lineNumbers: "off",
              lineDecorationsWidth: 0,
              padding: { top: 16 },
            }}
            theme="replo"
          />
        </div>
        {htmlErrors.length > 0 && (
          <InlineAlert variant="error" multiline>
            <div className="flex flex-col gap-2">
              <p>
                Replo detected HTML which may be invalid. This may cause issues
                after this page is published. If you see this page not working
                correctly, please reach out to support@replo.app with any
                questions.
              </p>
              <ul>
                {htmlErrors.map((error) => (
                  <li
                    key={error}
                    className="ml-4"
                    style={{ listStyleType: "circle" }}
                  >
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          </InlineAlert>
        )}
      </LabeledControl>
      <ElementCustomCssEditor />
    </div>
  );
};

export default PageElementEditor;
