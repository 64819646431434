import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

type ComboboxFooterProps = {
  children?: React.ReactNode;
  size: "sm" | "base";
};

export function ComboboxFooter({ children, size }: ComboboxFooterProps) {
  if (!children) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "border-t border-slate-200",
        size === "sm" ? "text-xs py-2 px-2" : "text-sm py-3 px-3",
      )}
    >
      {children}
    </div>
  );
}
