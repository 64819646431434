import type { ComponentTemplate } from "@editor/types/component-template";

import { getCanBeAddedFunction } from "@editor/utils/template";

import { ItemsConfigType } from "schemas/dynamicData";

export const carouselWithProductImages: ComponentTemplate = {
  id: "7f094b9b-77ee-498c-8f28-f3900af074f2",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Product Images",
  leftBarCategory: { main: "product", subCategory: "productData" },
  tags: ["carousel"],
  preview: "product/images.png",
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Image Carousels can only be added inside Product boxes.",
  ),
  template: {
    id: "237512e6-d49c-4c87-98bd-a75bed5ce6c4",
    name: "Carousel 1",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        alignSelf: "stretch",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      _items: {
        type: ItemsConfigType.inline,
        valueType: "dynamic",
        dynamicPath: "{{attributes._product.images}}",
      },
    },
    children: [
      {
        id: "ac74165c-9773-450c-992d-b17523ca2604",
        name: "Previous Button 1",
        type: "carouselV3Control",
        props: {
          style: {
            cursor: "pointer",
            display: "flex",
            flexGrow: "unset",
            flexWrap: "wrap",
            alignSelf: "stretch",
            flexBasis: "auto",
            alignItems: "center",
            justifyContent: "center",
          },
          direction: "previous",
        },
        children: [
          {
            id: "0432d84f-2ea6-4e74-aa83-f3b43b7378db",
            type: "icon",
            props: {
              style: {
                width: "60px",
                height: "60px",
                __width: "60px",
                __height: "60px",
                flexGrow: "unset",
              },
              iconName: "chevronLeft",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "ded2a465-3c15-40f0-b1a4-15969eed3f1b",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "0d6f4db8-0a8e-45b9-9c21-9150cec45825",
            name: "First Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "bf8b0b4c-79fd-4043-a18b-9840a038a6e3",
                  field: "state.group.isFirstItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "0d6f4db8-0a8e-45b9-9c21-9150cec45825": {
            componentOverrides: {
              "0432d84f-2ea6-4e74-aa83-f3b43b7378db": {
                props: { style: { opacity: "20%" } },
              },
              "ac74165c-9773-450c-992d-b17523ca2604": {
                props: { style: { cursor: "default" } },
              },
            },
          },
        },
      },
      {
        id: "53da05a0-e7a8-48aa-947a-a3c0455a5930",
        name: "Container 1010",
        type: "container",
        props: {
          style: {
            display: "flex",
            flexGrow: "1",
            maxWidth: "100%",
            alignSelf: "stretch",
            flexBasis: "auto",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
        },
        children: [
          {
            id: "5e9ea28c-afb9-4df3-a2aa-bd25bfca8671",
            name: "Carousel Slides",
            type: "carouselV3Slides",
            props: {
              style: {
                width: "auto",
                height: null,
                __width: "1197px",
                flexGrow: 1,
                overflow: "hidden",
                alignSelf: "stretch",
                flexBasis: "auto",
              },
            },
            children: [
              {
                id: "2188bda5-9908-4cc6-9c01-7a8717ec3e65",
                name: "Slide 1",
                type: "container",
                props: {
                  style: {
                    __width: "auto",
                    display: "flex",
                    flexGrow: "1",
                    __flexGap: "12px",
                    flexBasis: "auto",
                    alignItems: "center",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    justifyContent: "center",
                  },
                },
                children: [
                  {
                    id: "969c627a-dae3-4ba7-a3cf-4ecc98033f2c",
                    name: "Image 1",
                    type: "image",
                    props: {
                      src: "",
                      style: {
                        flexGrow: 1,
                        objectFit: "cover",
                        alignSelf: "stretch",
                        __imageSource: "{{attributes._currentItem}}",
                      },
                    },
                  },
                ],
              },
              {
                id: "7f0e3569-c0a4-4faf-acc8-ada24c30b107",
                name: "Slide 2",
                type: "container",
                props: {
                  style: {
                    __width: "auto",
                    display: "flex",
                    flexGrow: "1",
                    __flexGap: "12px",
                    flexBasis: "auto",
                    alignItems: "center",
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    justifyContent: "center",
                  },
                },
                children: [
                  {
                    id: "28c94652-b311-4218-89af-c52fce42bd5a",
                    name: "Image 2",
                    type: "image",
                    props: {
                      src: "",
                      style: { flexBasis: "auto", objectFit: "cover" },
                    },
                  },
                  {
                    id: "cd466111-e2d0-4cdf-a115-94e0dbe099f9",
                    type: "text",
                    props: {
                      text: "<p>Slide 2 of 2</p>",
                      style: {
                        flexGrow: "unset",
                        fontSize: "20px",
                        flexBasis: "auto",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "411a7736-1bcf-4164-b875-9b1c40b1ed23",
            name: "Carousel Indicators",
            type: "carouselV3Indicators",
            props: {
              style: {
                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "auto",
                zIndex: 2,
                display: "flex",
                position: "relative",
                alignSelf: "stretch",
                marginTop: "0px",
                alignItems: "center",
                marginLeft: "0px",
                paddingTop: "16px",
                paddingBottom: "16px",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "6def7401-2b38-49ba-8aca-5a791780a24d",
                name: "Container 1011",
                type: "container",
                props: {
                  style: {
                    width: "10px",
                    cursor: "pointer",
                    height: "10px",
                    __width: "10px",
                    display: "flex",
                    __height: "10px",
                    flexGrow: "unset",
                    maxWidth: "100%",
                    alignSelf: "auto",
                    alignItems: "flex-start",
                    marginLeft: "10px",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    backgroundColor: "#0000004D",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  },
                },
                children: [],
                variants: [
                  {
                    id: "5701ee18-03da-4a6f-a2a7-4c8b30915e26",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "7fa8382f-138e-4ca4-a510-1d245b168fb1",
                    name: "Current item is active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "a5eefa18-3bc3-4890-a191-335f2c071f83",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "7fa8382f-138e-4ca4-a510-1d245b168fb1": {
                    componentOverrides: {
                      "6def7401-2b38-49ba-8aca-5a791780a24d": {
                        props: { style: { backgroundColor: "#000000FF" } },
                      },
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      {
        id: "9169e0dd-ccb1-4708-bcc3-301d3121b2df",
        name: "Next Button 1",
        type: "carouselV3Control",
        props: {
          style: {
            cursor: "pointer",
            display: "flex",
            flexGrow: "unset",
            alignSelf: "stretch",
            flexBasis: "auto",
            alignItems: "center",
            justifyContent: "center",
          },
          direction: "next",
        },
        children: [
          {
            id: "2edd75e1-65a7-4955-ba1c-88e3541d97f0",
            type: "icon",
            props: {
              style: {
                width: "60px",
                height: "60px",
                __width: "60px",
                __height: "60px",
                flexGrow: "unset",
              },
              iconName: "chevronRight",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "425c80ee-d156-4891-9122-bd4e8cd4bac5",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "24c2e5b0-8360-41c3-bcc4-160915eecf18",
            name: "Last Item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "fa7db26c-7755-450b-b10f-b5f0f5c6a624",
                  field: "state.group.isLastItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "24c2e5b0-8360-41c3-bcc4-160915eecf18": {
            componentOverrides: {
              "2edd75e1-65a7-4955-ba1c-88e3541d97f0": {
                props: { style: { opacity: "20%" } },
              },
              "9169e0dd-ccb1-4708-bcc3-301d3121b2df": {
                props: { style: { cursor: "default" } },
              },
            },
          },
        },
      },
    ],
  },
};
