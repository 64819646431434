import type { EditorRootState } from "@editor/store";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { componentTemplatePreviewPropsSchema } from "schemas/element";
import type {
  ComponentTemplateScope,
  ComponentTemplateType,
} from "schemas/generated/componentTemplates";
import type z from "zod";

import { getDependencyData } from "@editor/actions/core-actions";
import useGetDesignLibraryComponentReferences from "@editor/hooks/designLibrary/useGetDesignLibraryComponentReferences";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  selectDraftComponent,
  selectDraftComponentNodeFromActiveCanvas,
  selectDraftParentComponent,
  selectGetAttribute,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { isFullWidthComponent } from "@editor/utils/component-attribute";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { successToast } from "@replo/design-system/components/alert/Toast";
import { refreshComponentIds } from "replo-runtime/shared/refreshComponentIds";

function useDraftComponentWithData() {
  const component = useEditorSelector(selectDraftComponent);
  const parent = useEditorSelector(selectDraftParentComponent);
  const getAttribute = useEditorSelector(selectGetAttribute);
  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );

  return {
    component,
    node: draftComponentNode,
    isFullWidth: isFullWidthComponent(component, getAttribute, parent),
  };
}

const createTemplatePreviewProps = (
  state: EditorRootState,
  storeShopifyUrl: string,
): z.infer<typeof componentTemplatePreviewPropsSchema> | null => {
  const publishData = getDependencyData(state);
  const projectId = selectProjectId(state);

  if (publishData.elementId === null || !projectId) {
    return null;
  }

  return {
    ...publishData,
    projectId,
    storeUrl: storeShopifyUrl,
    elementProducts: publishData.products,
    currencyCode: state.commerce.activeCurrency,
    activeLanguage: state.commerce.activeLanguage,
    activeShopifyUrlRoot: state.commerce.shopifyUrlRoot,
    moneyFormat: state.commerce.moneyFormat,
  };
};

export const useSaveComponentTemplate = (
  onSuccess?: () => void,
): {
  isLoading: boolean;
  handleSubmit: (
    name: string,
    selectedType: ComponentTemplateType,
  ) => Promise<void>;
} => {
  const projectId = useCurrentProjectId();
  const store = useEditorStore();
  const { storeUrl } = useGetStoreNameAndUrl();
  const { component: draftComponent } = useDraftComponentWithData();
  const logEvent = useLogAnalytics();
  const { getDesignLibraryComponentReferences } =
    useGetDesignLibraryComponentReferences();

  const { mutateAsync: createComponentTemplate, isPending: isLoadingV1 } =
    trpc.componentTemplates.createComponentTemplate.useMutation({
      onSuccess: (_, variables) => {
        handleComponentTemplateSuccess(variables.componentTemplate);
        void trpcUtils.componentTemplates.find.invalidate();
      },
    });

  const { mutateAsync: createComponentTemplateV2, isPending: isLoadingV2 } =
    trpc.componentTemplates.createComponentTemplateV2.useMutation({
      onSuccess: (_, variables) => {
        handleComponentTemplateSuccess(variables.componentTemplate);
        void trpcUtils.componentTemplates.find.invalidate();
      },
    });

  const isLoading = isLoadingV1 || isLoadingV2;

  function handleComponentTemplateSuccess(
    componentTemplate: Omit<ComponentTemplate, "id">,
  ) {
    logEvent("editor.componentTemplate.save", {
      scope: "store",
      type: componentTemplate.type,
      name: componentTemplate.name,
    });

    if (onSuccess) {
      onSuccess();
    }

    const componentTemplateTypeName =
      componentTemplate.type.slice(0, 1).toUpperCase() +
      componentTemplate.type.slice(1);
    const SavedLocation =
      componentTemplate.type === "page" ? "template library" : "insert panel";

    successToast(
      `${componentTemplateTypeName} saved`,
      `Saved ${componentTemplateTypeName}s can be found in the ${SavedLocation}.`,
    );
  }

  const handleSubmit = async (
    name: string,
    selectedType: ComponentTemplateType,
  ) => {
    if (draftComponent) {
      const generateCreateComponentTemplatePayload = () => {
        const designLibraryMetadata = getDesignLibraryComponentReferences([
          draftComponent.id,
        ]);
        const newComponent = refreshComponentIds(draftComponent).component;

        return {
          componentTemplate: {
            name,
            type: selectedType,
            scope: "store" as ComponentTemplateScope,
            storeId: projectId ?? "",
            template: newComponent,
            categoryId: null,
            designLibraryMetadata,
          },
          storeId: projectId ?? "",
        };
      };

      if (storeUrl) {
        const previewData = createTemplatePreviewProps(
          store.getState(),
          storeUrl ?? "",
        );
        if (previewData) {
          await createComponentTemplateV2({
            ...generateCreateComponentTemplatePayload(),
            previewRenderProps: previewData,
          });
        }
      } else {
        await createComponentTemplate(generateCreateComponentTemplatePayload());
      }
    }
  };

  return {
    isLoading,
    handleSubmit,
  };
};
