import { trpc, trpcUtils } from "@editor/utils/trpc";

const useInitializeSubscriptionUpdateMutation = () => {
  const {
    mutateAsync: initializeSubscriptionUpdate,
    isPending,
    data,
  } = trpc.subscriptions.initializeSubscriptionUpdate.useMutation({
    onSuccess: (data) => {
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.subscriptions.getActiveSubscriptionByProject.invalidate();
      void trpcUtils.subscriptions.getActiveSubscriptionByWorkspace.invalidate();
      void trpcUtils.workspace.getPublishedElementsUsage.invalidate();
      void trpcUtils.workspace.getShopifyIntegrationsUsage.invalidate();

      if (
        window &&
        data.success &&
        data.confirmationRequired &&
        data.confirmationUrl
      ) {
        window.location.href = data.confirmationUrl;
      }
    },
  });

  return {
    initializeSubscriptionUpdate,
    isLoading: isPending,
    data,
  };
};

export default useInitializeSubscriptionUpdateMutation;
