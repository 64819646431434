import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { getHotKey } from "@editor/utils/getHotKey";

import twMerge from "@replo/design-system/utils/twMerge";

import { useOverridableInput } from "./hooks/useOverridableInput";

type InputSize = "sm" | "base";

type InputOwnProps = {
  value?: string | number;
  defaultValue?: string | number;
  size?: InputSize;
  type?: string;
  placeholder?: string | null;
  autoFocus?: boolean;
  autoComplete?: string;
  startEnhancer?: React.ReactNode;
  endEnhancer?(): React.ReactNode;
  isDisabled?: boolean;
  inputContainerRef?: React.Ref<HTMLDivElement>;
  isReadOnly?: boolean;
  // NOTE (Chance 2023-11-13): Similar to `isPhonyButton` in `Button`, this prop
  // renders a container `div` that is styled like an input but does not accept
  // user input or hold a value.
  isPhonyInput?: boolean;
  validityState?: "valid" | "invalid";
  name?: string;
  id?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onEnter?(): void;
  onEscape?(): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyPress?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onMouseDown?(e: React.MouseEvent<HTMLInputElement>): void;
  onMouseUp?(e: React.MouseEvent<HTMLInputElement>): void;
  onMouseMove?(e: React.MouseEvent<HTMLInputElement>): void;
  onClick?(e: React.MouseEvent<HTMLInputElement>): void;
  onPaste?(e: React.ClipboardEvent<HTMLInputElement>): void;
  layoutClassName?: string;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_className?: string;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_inputClassName?: string;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_style?: React.CSSProperties;
  allowsDragOnStartEnhancer?: boolean;
  isFocused?: boolean;
  isRequired?: boolean;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  allowsDynamicData?: boolean;
  onClickDynamicData?(): void;
  shouldSelectTextOnFocus?: boolean;
  onOptionClick?(): void;
  startEnhancerClassName?: string;
};

type InputProps = InputOwnProps &
  Omit<
    React.ComponentPropsWithRef<"input">,
    keyof InputOwnProps | "className" | "style"
  >;

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    placeholder,
    size = "sm",
    type = "text",
    autoFocus = false,
    autoComplete = "on",
    startEnhancer,
    endEnhancer,
    isDisabled = false,
    validityState,
    isReadOnly,
    isPhonyInput,
    id,
    inputContainerRef,
    onBlur,
    onFocus,
    onEnter,
    onEscape,
    onKeyPress,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    unsafe_className: className,
    layoutClassName,
    unsafe_inputClassName: inputClassName,
    allowsDragOnStartEnhancer = false,
    unsafe_style: style,
    // TODO (Noah, Chance, REPL-9830): We need to fix the controlledFocus
    // of these inputs, currently it's broken
    isFocused: _controlledFocus = false,
    isRequired = false,
    allowsDynamicData = false,
    onClickDynamicData,
    startEnhancerClassName,
    shouldSelectTextOnFocus = false,
    onOptionClick,
    ...props
  }: InputOwnProps,
  forwardedRef,
) {
  const inputClassNames = twMerge(
    "bg-inherit text-default truncate border-0 rounded w-full focus:outline-none",
    "placeholder:text-placeholder placeholder:font-normal",
    "disabled:cursor-not-allowed",
    size === "sm" && "typ-body-small h-small leading-small",
    size === "base" &&
      "typ-body-base sm:text-sm sm:h-base sm:leading-base h-10",
    layoutClassName,
    inputClassName,
  );

  const enhancerSizeClasses = {
    sm: "text-xs",
    base: "sm:text-sm text-base",
  }[size];

  const enhancerClassNames = twMerge(
    "flex items-center text-subtle",
    enhancerSizeClasses,
  );

  function onKeyPressInput(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && onEnter) {
      onEnter?.();
    }
    if (e.key === "Escape" && onEscape) {
      onEscape?.();
    }
    return onKeyPress?.(e);
  }

  const shouldAddMouseHandlers =
    !Boolean(startEnhancer) || !allowsDragOnStartEnhancer;

  const onInputMouseUp = (
    e: React.MouseEvent<HTMLInputElement, globalThis.MouseEvent>,
  ) => {
    onMouseUp?.(e);
    if (onOptionClick) {
      const hotkey = getHotKey(e);
      if (hotkey === "altKey") {
        onOptionClick();
      }
    }
  };
  const endEnhancerContent = endEnhancer?.();

  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");

  return (
    <div className="flex w-full h-full gap-2">
      <div
        className={twMerge(
          "bg-light-surface flex justify-content-around w-full items-center text-default rounded transition-all duration-75 relative px-[1px]",
          isDisabled && "opacity-50 cursor-not-allowed",
          layoutClassName,
          className,
        )}
        ref={inputContainerRef}
        // NOTE (Sebas, 2024-06-27): We need to stop propagation of the keydown event
        // to prevent the hotkeys from being triggered.
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div
          className={twMerge(
            "flex justify-content-around items-center w-full flex-1 rounded transition-all duration-75 relative",
            validityState !== "invalid" &&
              "focus-within:outline focus-within:outline-primary focus-within:outline-1",
            validityState === "invalid" &&
              "bg-danger-soft outline outline-danger outline-1",
            size === "sm" && "px-1 gap-1",
            size === "base" && "px-1.5 gap-2",
            layoutClassName,
            className,
          )}
        >
          {startEnhancer && (
            <div
              className={twMerge(
                enhancerClassNames,
                "pr-0",
                startEnhancerClassName,
              )}
              style={{
                cursor: allowsDragOnStartEnhancer ? "ns-resize" : style?.cursor,
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            >
              {startEnhancer}
            </div>
          )}
          {isPhonyInput ? (
            <div className={inputClassNames} style={style} id={id}></div>
          ) : (
            <input
              {...props}
              className={inputClassNames}
              style={style}
              type={type}
              placeholder={placeholder ?? undefined}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              disabled={isDisabled}
              readOnly={isReadOnly}
              onBlur={onBlur}
              onFocus={(event) => {
                onFocus?.(event);
                if (!event.defaultPrevented && shouldSelectTextOnFocus) {
                  event.target.select();
                }
              }}
              onKeyPress={onKeyPressInput}
              onMouseDown={shouldAddMouseHandlers ? onMouseDown : undefined}
              onMouseUp={shouldAddMouseHandlers ? onInputMouseUp : undefined}
              onMouseMove={shouldAddMouseHandlers ? onMouseMove : undefined}
              ref={forwardedRef}
              id={id}
              required={isRequired}
            />
          )}
          {endEnhancerContent}
        </div>
      </div>
      {allowsDynamicData && !isNewDynamicData && (
        <DynamicDataButton
          onClick={(e) => {
            e.stopPropagation();
            onClickDynamicData?.();
          }}
        />
      )}
    </div>
  );
});

export default Input;

export function DebouncedInput({
  value,
  onValueChange,
  ...props
}: Omit<InputProps, "defaultValue" | "onChange"> & {
  value: string;
  onValueChange(value: string): any;
}) {
  const inputProps = useOverridableInput({ value, onValueChange });
  return <Input {...props} {...inputProps} />;
}
