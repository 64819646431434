import type { InternalQuery, UrlSyncedQuery } from "@/features/analytics/query";
import type { WorkspaceUrlHost } from "@/features/analytics/useWorkspaceUrlHosts";
import type { DateRange } from "react-day-picker";
import type {
  ChartInterval,
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "replo-utils/analytics";
import type {
  AnalyticsReadQuery,
  ConditionOperatorEnum,
  PageTypeEnum,
} from "schemas/generated/analyticsRead";

import * as React from "react";

import { DEFAULT_FILTERS, DEFAULT_QUERY } from "@/features/analytics/constants";
import {
  DEFAULT_CHART_INTERVAL,
  DEFAULT_COMPARE_RANGE_TIME_FRAME,
  DEFAULT_MAIN_RELATIVE_TIME_FRAME,
} from "replo-utils/analytics";

type UrlParamPayload = {
  attribute: string;
  operator: ConditionOperatorEnum;
  value: string;
};

export type AnalyticsQueryAction =
  | {
      type: "updatePagination";
      payload: AnalyticsReadQuery["offset"];
    }
  | {
      type: "sortMetric";
      payload: AnalyticsReadQuery["sortMetric"];
    }
  | {
      type: "urlHosts";
      payload: AnalyticsReadQuery["urlHosts"];
    }
  | {
      type: "updateMainRange";
      payload: RelativeTimeFrame | DateRange;
    }
  | {
      type: "updateCompareRange";
      payload: ComparisonTimeFrame | DateRange;
    }
  | {
      type: "filters.updateUrlPathSearch";
      payload: string;
    }
  | {
      type: "filters.replaceUrlPath";
      payload: string;
    }
  | {
      type: "updateChartInterval";
      payload: ChartInterval;
    }
  | {
      type: "initDeepDivePage";
      payload: {
        urlPath: string;
      };
    }
  | {
      type: "initOverviewPage";
    }
  | {
      type: "filters.pageType";
      payload: PageTypeEnum;
    }
  | {
      type: "filters.deleteUrlParam";
      payload: UrlParamPayload;
    }
  | {
      type: "filters.addUrlParam";
      payload: UrlParamPayload;
    }
  | {
      type: "openDeepDivePageFromOverviewPage";
      payload: string;
    }
  | {
      type: "openOverviewPageFromDeepDivePage";
    };

const DEFAULT_URL_SYNCED_QUERY: UrlSyncedQuery = {
  selectedTimePeriod: {
    type: "relative",
    value: DEFAULT_MAIN_RELATIVE_TIME_FRAME,
  },
  selectedComparePeriod: {
    type: "relative",
    value: DEFAULT_COMPARE_RANGE_TIME_FRAME,
  },
  chartInterval: DEFAULT_CHART_INTERVAL,
  filters: DEFAULT_FILTERS,
  urlHosts: [],
};

export const AnalyticsQueryContext = React.createContext<{
  resetInternalAndUrlSyncedQueries: () => void;
  dispatchAnalyticsQuery: (action: AnalyticsQueryAction) => void;
  query: UrlSyncedQuery & InternalQuery;
  workspaceUrlHosts: WorkspaceUrlHost;
  timeZone: string;
}>({
  resetInternalAndUrlSyncedQueries: () => null,
  workspaceUrlHosts: {
    data: [],
    isLoading: false,
  },
  dispatchAnalyticsQuery: () => null,
  query: { ...DEFAULT_QUERY, ...DEFAULT_URL_SYNCED_QUERY },
  timeZone: "UTC",
});

export const useAnalyticsQueryContext = () =>
  React.useContext(AnalyticsQueryContext);
