import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

function FeraStoreReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const liquidSource = '<div data-fera-container="all_reviews"></div>';

  return (
    <SharedShopifyLiquid
      liquidSource={liquidSource}
      componentId={component.id}
      componentAttributes={componentAttributes}
      title="Fera Store Reviews"
      placeholderType="reviewFull"
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
    />
  );
}

export default FeraStoreReviewsWidget;
