import type { RGBColor } from "react-color";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { isNotNullish, isNullish } from "replo-utils/lib/misc";
import tinycolor from "tinycolor2";

// Test for #hex colors
const colorStringRegex = new RegExp(/^#(?:[\dA-Fa-f]{3,4}){1,2}$/);

// Return uppercase color if it matches the color string regex
export const getFormattedColor = (color: string): string => {
  if (color && colorStringRegex.test(color)) {
    return color.toUpperCase();
  }

  return color;
};

export const getFormattedColorWithoutOpacity = (
  color: string | null,
): string | null => {
  if (color && colorStringRegex.test(color)) {
    return getHexColor(color).toUpperCase();
  }
  return color;
};

export const getFormattedOpacity = (opacity: number | undefined) => {
  if (isNotNullish(opacity)) {
    return `${(opacity * 100).toFixed(0)}%`;
  }
  return "100%";
};

export const getInitialColorValue = (
  value: string | SolidOrGradient | null,
): RGBColor => {
  if (isNullish(value)) {
    return getRGB("#000000");
  }
  if (typeof value === "string") {
    return getRGB(value);
  }
  if (value.type === "solid") {
    return getRGB(value.color);
  }

  return getRGB(value?.gradient?.stops[0]?.color || "");
};

/**
 * Return whether a value should be displayed as a solid color (as opposed to gradient)
 * in the color picker.
 *
 * Note (Noah, 2022-03-23): This returns true in null cases since we want to default to
 * showing the solid color selector if there's a null value.
 */
export const isSolidColor = (
  value: string | SolidOrGradient | ReploMixedStyleValue | null,
) => {
  if (!value || isMixedStyleValue(value)) {
    return true;
  }

  if (typeof value !== "string") {
    return value.type === "solid";
  }
  return true;
};

export const isGradientColor = (
  value: string | SolidOrGradient | ReploMixedStyleValue | null,
) => {
  if (!value || isMixedStyleValue(value)) {
    return false;
  }

  if (
    typeof value === "object" &&
    "type" in value &&
    value.type === "gradient"
  ) {
    return true;
  }

  return false;
};

export const getHexColor = (value: string | RGBColor) =>
  tinycolor(value).toHexString();

export const getHex8Color = (value: string | RGBColor) =>
  tinycolor(value).toHex8String();

export const getRGB = (value: string | null | undefined) =>
  tinycolor(value ?? undefined).toRgb();

export const getAutoCompletedColor = (color?: string | null) => {
  if (!color) {
    return null;
  }

  let hexColor = color.replace(/#/g, "");
  if (hexColor.length === 0) {
    return null;
  }

  if (hexColor.length < 3) {
    hexColor = hexColor.repeat(6 / hexColor.length);
  }

  return tinycolor(hexColor).isValid() ? getHex8Color(hexColor) : null;
};
