import * as React from "react";

import Separator from "@common/designSystem/Separator";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { trpc } from "@editor/utils/trpc";

import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import InlineAlert from "@replo/design-system/components/alert/InlineAlert";
import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import { skipToken } from "@tanstack/react-query";
import { X } from "lucide-react";

const useAnalyticsMicroVac = ({
  promptOverride,
}: {
  promptOverride: string;
}) => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const { data: workspaceUrlHosts } = useWorkspaceUrlHosts(workspaceId ?? "");

  const shouldFetch = workspaceId && workspaceUrlHosts?.length > 0;

  const { data, isLoading } = trpc.analytics.generateInsights.useQuery(
    shouldFetch
      ? {
          workspaceId,
          urlHosts: workspaceUrlHosts,
          promptOverride: promptOverride === "" ? null : promptOverride,
        }
      : skipToken,
  );

  return {
    insights: data?.insights ?? [],
    insightRangeResults: data?.insightRangeResults ?? [],
    isLoading,
  };
};

interface InsightsAIMicrovacModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InsightsAIMicrovacModal: React.FC<
  InsightsAIMicrovacModalProps
> = ({ isOpen, onClose }) => {
  const [position, setPosition] = React.useState({
    x: window.innerWidth - 1320,
    y: 50,
  });
  const [isDragging, setIsDragging] = React.useState(false);
  const [dragOffset, setDragOffset] = React.useState({ x: 0, y: 0 });

  const modalRef = React.useRef<HTMLDivElement>(null);

  // Handle dragging
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && (e.target as HTMLElement).closest(".cursor-grab")) {
      setIsDragging(true);
      const rect = modalRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const handleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        setPosition({
          x: e.clientX - dragOffset.x,
          y: e.clientY - dragOffset.y,
        });
      }
    },
    [isDragging, dragOffset],
  );

  const handleMouseUp = React.useCallback(() => {
    setIsDragging(false);
  }, []);

  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={modalRef}
      className="fixed bg-white p-4 w-[1200px] h-[800px] overflow-hidden flex flex-col z-50 border-2 border-primary shadow-lg "
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: isDragging ? "grabbing" : "default",
      }}
    >
      <div
        className="flex justify-between items-center mb-3 cursor-grab  pb-2 flex-shrink-0"
        onMouseDown={handleMouseDown}
      >
        <div className="cursor-grab flex flex-col gap-2 flex-1">
          <div className="flex justify-between items-center">
            <span className="text-sm font-mono typ-header-h3 uppercase">
              Insights AI Microvac
            </span>
            <IconButton
              icon={<X className="h-4 w-4" />}
              variant="secondary"
              layoutClassName="h-6 w-6"
              onClick={onClose}
            />
          </div>
          <InlineAlert variant="info">
            <span>
              [INTERNAL] Use this to prompt engineer - our current live prod
              prompt is{" "}
              <a
                href="https://www.notion.so/replo/Current-Live-prompt-1bd8170f84a68033afa4ff0d228dcca6?pvs=4"
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                here
              </a>
              : copy it, make changes to it, hit generate to see the insights
              that get generated with that prompt, and iterate!
            </span>
          </InlineAlert>
        </div>
      </div>

      <div className="flex-1 overflow-auto">
        <InsightsAIMicrovacContent />
      </div>
    </div>
  );
};

type ResultType = "insights" | "rangeResults";

const InsightsAIMicrovacContent: React.FC = () => {
  const [promptOverrideInput, setPromptOverrideInput] =
    React.useState<string>("");

  const [promptOverride, setPromptOverride] = React.useState<string>("");

  const { insights, insightRangeResults, isLoading } = useAnalyticsMicroVac({
    promptOverride,
  });

  const [resultType, setResultType] = React.useState<ResultType>("insights");

  return (
    <div className="p-1 flex flex-col gap-3">
      <div className="flex flex-col gap-2">
        <Textarea
          size="base"
          layoutClassName="h-24 w-full mb-3"
          value={promptOverrideInput}
          onChange={setPromptOverrideInput}
          placeholder="Enter your system prompt override. If empty, our AI will use the system prompt that's currently in prod."
          autoFocus
        />

        <Button
          variant="primary"
          size="base"
          disabled={isLoading}
          onClick={() => {
            setPromptOverride(promptOverrideInput);
          }}
        >
          Generate Insights
        </Button>
      </div>

      <Separator className="my-4" />

      {isLoading ? (
        <span className="font-mono">
          Aight chill, I'm cookin' up them insights. Gimme 1min, these numbers
          ain't gonna hustle themselves...
        </span>
      ) : (
        <div className="flex flex-col gap-4 ">
          <ToggleGroup
            size="sm"
            options={[
              { label: "Insights", value: "insights" },
              {
                label: "ClickHouse Metrics",
                value: "clickhouseMetrics",
              },
            ]}
            selectedValue={resultType}
            onChange={(value) => {
              setResultType(value as ResultType);
            }}
          />
          <pre className="whitespace-pre-wrap overflow-auto border rounded-md p-2">
            {JSON.stringify(
              resultType === "insights" ? insights : insightRangeResults,
              null,
              2,
            )}
          </pre>
        </div>
      )}
    </div>
  );
};
