import type {
  AnalyticsReadQuery,
  QueryRange,
} from "schemas/generated/analyticsRead";

import { TZDate } from "@date-fns/tz";
import {
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import {
  convertDaysToMs,
  dateRangeFormatter,
  hours,
} from "replo-utils/lib/datetime";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { v5 as uuidv5 } from "uuid";

export type ChartInterval = "hour" | "day";
export type SelectedTimePeriod =
  | {
      type: "relative";
      value: RelativeTimeFrame | ComparisonTimeFrame;
    }
  | {
      type: "custom";
      value: {
        from: AnalyticsReadQuery["ranges"]["mainRange"]["startDatetime"];
        to: AnalyticsReadQuery["ranges"]["mainRange"]["endDatetime"];
      };
    };

const UUID_V5_NAMESPACE = "177bbf4d-91da-4117-b227-4fbad66a41f0";

export type RelativeTimeFrame =
  // Last-N-Days: All will be N * DAY_IN_MS in length.
  | "last-1-days" // AKA: "Yesterday"
  | "last-7-days"
  | "last-14-days"
  | "last-30-days"
  | "last-90-days"
  | "last-180-days"
  // Last-X-Unit: All will be their unit in length.
  | "last-week"
  | "last-month"
  // X-to-Date: No more than their unit in length.
  | "day-to-date" // AKA: "Today"
  | "week-to-date"
  | "month-to-date"
  | "year-to-date"
  | "all-time";

export const DEFAULT_MAIN_RELATIVE_TIME_FRAME: RelativeTimeFrame =
  "last-7-days";

export const DEFAULT_CHART_INTERVAL: ChartInterval = "day";

// TODO (Max, 2024-09-17): We'll later add e.g 'no-comparison'
export type ComparisonTimeFrame = "previous-period";

export const DEFAULT_COMPARE_RANGE_TIME_FRAME: ComparisonTimeFrame =
  "previous-period";

export const COMPARE_PREVIOUS_PERIOD: ComparisonTimeFrame = "previous-period";

export const MAIN_RELATIVE_TIME_FRAMES: Array<{
  value: RelativeTimeFrame;
  label: string;
  displayValue: string;
}> = [
  { value: "day-to-date", label: "Today", displayValue: "Today" },
  { value: "last-1-days", label: "Yesterday", displayValue: "Yesterday" },
  { value: "last-7-days", label: "Last 7 days", displayValue: "Last 7 days" },
  {
    value: "last-14-days",
    label: "Last 14 days",
    displayValue: "Last 14 days",
  },
  {
    value: "last-30-days",
    label: "Last 30 days",
    displayValue: "Last 30 days",
  },
  {
    value: "last-90-days",
    label: "Last 90 days",
    displayValue: "Last 90 days",
  },
  {
    value: "last-180-days",
    label: "Last 180 days",
    displayValue: "Last 180 days",
  },
  { value: "last-week", label: "Last week", displayValue: "Last week" },
  {
    value: "week-to-date",
    label: "Week to date",
    displayValue: "Week to date",
  },
  { value: "last-month", label: "Last month", displayValue: "Last month" },
  {
    value: "month-to-date",
    label: "Month to date",
    displayValue: "Month to date",
  },
  {
    value: "year-to-date",
    label: "Year to date",
    displayValue: "Year to date",
  },
  { value: "all-time", label: "All time", displayValue: "All time" },
];

export const COMPARE_RANGE_TIME_FRAME_OPTIONS: Array<{
  value: ComparisonTimeFrame;
  label: string;
  displayValue: string;
}> = [
  {
    value: "previous-period",
    label: "Previous period",
    displayValue: "Previous period",
  },
];

/**
 * Given the startDatetime and endDatetime from the main range,
 * this returns the startDatetime and endDatetime for the default
 * compareAt range (i.e. the previous continuous range).
 *
 * @author Max 2024-09-16
 */
function calculatePreviousPeriodDatetimes(
  mainStartDatetime: number,
  mainEndDatetime: number,
) {
  const interval =
    adjustEndTimeToFullDays(mainStartDatetime, mainEndDatetime) -
    mainStartDatetime;
  return {
    compareStartDatetime: mainStartDatetime - interval,
    compareEndDatetime: mainEndDatetime - interval,
    compareInterval: interval,
  };
}

export function calculateCompareRangePredefined(
  comparisonTimeFrame: ComparisonTimeFrame,
  mainRange: QueryRange,
) {
  const { startDatetime: mainStartDatetime, endDatetime: mainEndDatetime } =
    mainRange;

  const { compareStartDatetime, compareEndDatetime, compareInterval } =
    calculatePreviousPeriodDatetimes(mainStartDatetime, mainEndDatetime);

  const compareAtRange = generateRange(
    compareStartDatetime,
    compareEndDatetime,
    compareInterval,
  );

  return {
    range: compareAtRange,
    selectedPeriod: comparisonTimeFrame,
  };
}

/**
 * Given a "from" & "to" selected by a custom date picker, it returns the associated
 * range. This function can be used both for the main range (custom time frame), and the
 * compareAt range (custom period).
 *
 * @author Max 2024-09-16
 */
export function calculateCustomRange({
  start,
  end,
  interval,
  timeZone,
}: {
  start: number;
  end: number;
  interval?: number | null;
  timeZone: string;
}) {
  const rangeInterval = interval ?? adjustEndTimeToFullDays(start, end) - start;

  const range = generateRange(start, end, rangeInterval);

  const selectedPeriod = dateRangeFormatter({
    from: new TZDate(start, timeZone),
    to: new TZDate(end, timeZone),
  });

  return {
    range,
    selectedPeriod,
  };
}

/**
 * Given the "from" & "to" selected custom range, it returns
 * the main range, starting at the startOfDay of the "from", and
 * at the endOfDay of "to". For the compareAtRange, it uses the
 * default previous continuous range.
 *
 * Datetimes are in UTC.
 *
 * @author Max 2024-09-16
 */
export function calculateRangesForCustomTimeFrame({
  start,
  end,
  chartInterval,
  timeZone,
}: {
  start: number;
  end: number;
  chartInterval?: ChartInterval | null;
  timeZone: string;
}) {
  const interval = chartInterval
    ? mapChartIntervalLabelToMs(chartInterval)
    : null;

  const { range: mainRange } = calculateCustomRange({
    start,
    end,
    interval,
    timeZone,
  });

  const { compareStartDatetime, compareEndDatetime, compareInterval } =
    calculatePreviousPeriodDatetimes(
      mainRange.startDatetime,
      mainRange.endDatetime,
    );

  const compareAtRange = generateRange(
    compareStartDatetime,
    compareEndDatetime,
    interval ?? compareInterval,
  );

  const adjustedMainRange = {
    ...mainRange,
    endDatetime: adjustEndTimeToFullDays(
      mainRange.startDatetime,
      mainRange.endDatetime,
    ),
  };

  const adjustedCompareAtRange = {
    ...compareAtRange,
    endDatetime: adjustEndTimeToFullDays(
      compareAtRange.startDatetime,
      compareAtRange.endDatetime,
    ),
  };

  const updatedRanges = {
    mainRange: adjustedMainRange,
    compareAtRanges: [adjustedCompareAtRange],
  };

  const selectedTimeFrame = dateRangeFormatter({
    from: new TZDate(start, timeZone),
    to: new TZDate(end, timeZone),
  });

  return {
    updatedRanges,
    selectedTimeFrame,
  };
}

type ReturnForRangeTimeFrameToRange = {
  main: { start: number; end: number };
  compare: Array<{ start: number; end: number }>;
};

function calculateRangesForLastNDays(
  now: TZDate,
  days: number,
): ReturnForRangeTimeFrameToRange {
  const { start, end } = getRangeForLastNDays(days, now.timeZone ?? "UTC");

  return {
    main: {
      start: start.valueOf(),
      end: adjustEndTimeToFullDays(start.valueOf(), end.valueOf()),
    },
    compare: [
      {
        start: subDays(start, days).valueOf(),
        end: adjustEndTimeToFullDays(
          subDays(start, days).valueOf(),
          start.valueOf(),
        ),
      },
    ],
  };
}

function startOfNextDay(date: TZDate): TZDate {
  const start = startOfDay(date);
  return new TZDate(start.valueOf() + convertDaysToMs(1), date.timeZone);
}

/**
 * Converts a time frame to range, using the supplied date that defaults to `now` which allows
 * us to test this by giving it an arbitrary time.
 *
 * All calculations are in UTC where possible (ie unix epoch timestamps as numbers) while date
 * relativity is handled using `Date` objects, and `Date.UTC`. For example, going back a month
 * requires using `Date`s because of the variation in days in a given month, but once we've got
 * the start of a month for instance in UTC, calculating a range forward is as simple as adding or
 * subtracting milliseconds multiplied out by the given units, since UTC is represented as simple
 * integers. We need to use the `getUTC___` methods because the add and subtract methods provided
 * by libraries like `date-fns` account for timezones -- the one thing we don't want.
 *
 * All ranges are relative to the current time, but some comparison ranges need to be adjusted with
 * respect to the current range. In general, these fall into several categories:
 *
 * - Last-N-Days (1, 7, 14, 30, 90, 180 days): The last N full days, so today is not
 *   included, ending on 00:00:00 of the next day after the period.
 * - Last-X: (last-week, last-month): Uses 00:00:00 today, then goes back to _last_ week or month,
 *   then uses that full week or month. For a week, this will always be 7 days, starting Sunday.
 *   But for months, this will be 28 through 31 days, and the corresponding comparison range will
 *   be the same number of days for the sake of comparison. See below for more details.
 * - X-to-Date: (today, week-to-date, month-to-date, year-to-date) Takes current time, goes to the
 *   beginning of the unit, then goes up to 00:00:00 tomorrow, including "now".
 * - All-Time: Given that all-time not very precise, I'm declaring it to be year-to-date with no
 *   comparison period.
 *
 * About last-month, and last-year.
 *
 * The last month may have 28, 29, 30, or 31 days in it. Much of our code makes the presumption
 * that the comparison ranges will be the exact same as the main range. But this isn't the
 * case for many months. For example, December has 31 days, while November has 30. Should the
 * comparison range have 31 or 30? If it's 31, then it will give a total that is "comparable"
 * yet, slightly misleading. For the sake of simplifying this, I'm making the assumption that the
 * comparison ranges _should_ be the same length as the main range, and adjusting them. This
 * applies to last-year as well.
 *
 * @author Ben 2024-11-04
 */
export function rangeTimeFrameToRange({
  timeFrame,
  now,
}: {
  timeFrame: RelativeTimeFrame;
  now: TZDate;
}): ReturnForRangeTimeFrameToRange {
  return exhaustiveSwitch({ type: timeFrame })({
    // TODO (Kurt, 2025-03-07, INS-460): We should not use a static 1 day/1 hour interval for our
    // queries in analytics. Instead, the interval should be dynamic accounting for things
    // like DST shifts. For now, we perform all time calculations manually using timestamps
    // in ms from epoch time; however, this is not ideal.

    // Last-N-Days
    "last-1-days": () => calculateRangesForLastNDays(now, 1),
    "last-7-days": () => calculateRangesForLastNDays(now, 7),
    "last-14-days": () => calculateRangesForLastNDays(now, 14),
    "last-30-days": () => calculateRangesForLastNDays(now, 30),
    "last-90-days": () => calculateRangesForLastNDays(now, 90),
    "last-180-days": () => calculateRangesForLastNDays(now, 180),

    // Last-X
    "last-week": () => {
      // NOTE (Kurt, 2025-03-07): weekStartsOn: 1 is used to start the week on Monday.
      const end = startOfWeek(now, { weekStartsOn: 1 });
      const start = subDays(end, 7);
      const before = subDays(start, 7);
      return {
        main: {
          start: start.valueOf(),
          end: adjustEndTimeToFullDays(start.valueOf(), end.valueOf()),
        },
        compare: [
          {
            start: before.valueOf(),
            end: adjustEndTimeToFullDays(before.valueOf(), start.valueOf()),
          },
        ],
      };
    },
    "last-month": () => {
      const end = startOfMonth(now);
      const start = subMonths(end, 1);
      const compareEnd = start;
      const compareStart = subMonths(start, 1);
      return {
        main: {
          start: start.valueOf(),
          end: adjustEndTimeToFullDays(start.valueOf(), end.valueOf()),
        },
        compare: [
          {
            start: compareStart.valueOf(),
            end: adjustEndTimeToFullDays(
              compareStart.valueOf(),
              compareEnd.valueOf(),
            ),
          },
        ],
      };
    },

    // X-To-Date
    "day-to-date": () => {
      const start = startOfDay(now);
      const end = startOfNextDay(start);
      const before = subDays(start, 1).valueOf();
      return {
        main: {
          start: start.valueOf(),
          end: adjustEndTimeToFullDays(start.valueOf(), end.valueOf()),
        },
        compare: [
          {
            start: before.valueOf(),
            end: adjustEndTimeToFullDays(before.valueOf(), start.valueOf()),
          },
        ],
      };
    },
    "week-to-date": () => {
      const start = startOfWeek(now, { weekStartsOn: 1 });
      const end = startOfNextDay(now).valueOf();
      return {
        main: {
          start: start.valueOf(),
          end: adjustEndTimeToFullDays(start.valueOf(), end),
        },
        compare: [
          {
            start: subDays(start, 7).valueOf(),
            end: adjustEndTimeToFullDays(
              subDays(start, 7).valueOf(),
              start.valueOf(),
            ),
          },
        ],
      };
    },
    "month-to-date": () => {
      const start = startOfMonth(now);
      const end = startOfNextDay(now).valueOf();
      const previousMonth = subMonths(now, 1);
      const compareStart = startOfMonth(previousMonth).valueOf();
      const compareEnd = startOfNextDay(previousMonth).valueOf();
      return {
        main: {
          start: start.valueOf(),
          end: adjustEndTimeToFullDays(start.valueOf(), end),
        },
        compare: [
          {
            start: compareStart,
            end: adjustEndTimeToFullDays(compareStart, compareEnd),
          },
        ],
      };
    },
    "year-to-date": () => {
      const start = startOfYear(now);
      const end = startOfNextDay(now).valueOf();
      const previousYearDate = new TZDate(
        now.valueOf() - convertDaysToMs(365),
        now.timeZone,
      );
      const compareStart = startOfYear(previousYearDate).valueOf();
      const compareEnd = startOfNextDay(previousYearDate).valueOf();
      return {
        main: {
          start: start.valueOf(),
          end: adjustEndTimeToFullDays(start.valueOf(), end),
        },
        compare: [
          {
            start: compareStart,
            end: adjustEndTimeToFullDays(compareStart, compareEnd),
          },
        ],
      };
    },
    "all-time": () => {
      const start = new TZDate("2024-01-01", now.timeZone).valueOf();
      const end = startOfNextDay(now).valueOf();
      return {
        main: {
          start: start.valueOf(),
          end: adjustEndTimeToFullDays(start, end),
        },
        compare: [],
      };
    },
  });
}

function mapChartIntervalLabelToMs(chartInterval: ChartInterval) {
  return exhaustiveSwitch({ type: chartInterval })({
    hour: () => hours(1),
    day: () => hours(24),
  });
}

/**
 * Given a predefined time frame ("last-7-days", "last-1-days", returned the
 * updatedRanges, consisting of mainRange and compareAtRange)
 *
 * @author Max 2024-09-16
 */
export function calculateQueryRangesForRelativeTimeFrame({
  timeFrame,
  chartInterval,
  timeZone,
}: {
  timeFrame: RelativeTimeFrame;
  chartInterval?: ChartInterval | null;
  timeZone: string;
}) {
  const { main, compare } = rangeTimeFrameToRange({
    timeFrame,
    now: new TZDate(new Date(), timeZone),
  });

  const chartIntervalMs = chartInterval
    ? mapChartIntervalLabelToMs(chartInterval)
    : null;

  return {
    selectedTimeFrame: timeFrame,
    updatedRanges: {
      mainRange: {
        id: "61dddc62-1cc1-4d6c-a435-8b54803ca027",
        startDatetime: main.start,
        endDatetime: main.end,
        interval: chartIntervalMs ?? main.end - main.start,
      },
      compareAtRanges: compare[0]
        ? [
            // NOTE/TODO Ben 2024-11-05: If we randomly generate this, then when we use this
            // function in a react component it causes re-renders. We should avoid doing stuff
            // like this, but for now I'm just using constant IDs.
            {
              id: "2485e7b2-44e4-4e53-b312-2f4b92c6ef39",
              startDatetime: compare[0]!.start,
              endDatetime: compare[0]!.end,
              interval: chartIntervalMs ?? compare[0]!.end - compare[0]!.start,
            },
          ]
        : [],
    },
  };
}

export function getRangesFromTimePeriod({
  selectedTimePeriod,
  chartInterval,
  timeZone,
}: {
  selectedTimePeriod: SelectedTimePeriod;
  chartInterval?: ChartInterval | null;
  timeZone: string;
}) {
  return exhaustiveSwitch(selectedTimePeriod)({
    custom: ({ value }) =>
      calculateRangesForCustomTimeFrame({
        start: value.from,
        /**
         * NOTE (Max, 2025-01-02): Hack to make sure that the endDatetime is inclusive: on the
         * custom date picker, if the user selects [Dec 14th -> Dec 15th], then the endDatetime
         * is Dec 15th at 00:00:00, meaning we're not really including Dec 15th in the range.
         * That's why we add 1 day here, so that we're querying until Dec 16th 00:00:00, as
         * the user expects.
         *
         * We're not directly adding 1 day in the custom date picker because we still want the
         * date picker to show "Dec 15th" as the end date: it wouldn't make sense to
         * the user if we showed "Dec 16th" when they clicked "Dec 15th".
         */
        end: value.to + convertDaysToMs(1),
        chartInterval: chartInterval ?? null,
        timeZone,
      }),
    relative: ({ value }) =>
      calculateQueryRangesForRelativeTimeFrame({
        timeFrame: value as RelativeTimeFrame,
        chartInterval: chartInterval ?? null,
        timeZone,
      }),
  });
}

/**
 * Given a UNIX startDatetime, endDatetime, and interval, returns the object
 * (with random id) which will be used by QueryRanges. Either for the
 * mainRange, or compareAtRange.
 *
 * @author Max 2024-09-16
 */
function generateRange(start: number, end: number, interval: number) {
  return {
    id: uuidv5(
      JSON.stringify({ startDatetime: start, endDatetime: end, interval }),
      UUID_V5_NAMESPACE,
    ),
    startDatetime: start,
    endDatetime: end,
    interval,
  };
}

function getRangeForLastNDays(days: number, timeZone: string) {
  const nowInTargetTZ = toZonedTime(new Date(), timeZone);
  const todayStart = startOfDay(nowInTargetTZ);
  const startDate = subDays(todayStart, days);

  return {
    start: fromZonedTime(startDate, timeZone).getTime(),
    end: fromZonedTime(todayStart, timeZone).getTime(),
  };
}

/**
 * Adjusts the end time of a range to ensure it's divisible by full days from the start time.
 * If the range is already divisible by full days, returns the original end time.
 *
 * @param startMs The start time in milliseconds
 * @param endMs The end time in milliseconds
 * @returns The adjusted end time in milliseconds
 */
function adjustEndTimeToFullDays(startMs: number, endMs: number): number {
  const oneDayMs = convertDaysToMs(1);
  const rangeDuration = endMs - startMs;

  // If already divisible by full days, return as is
  if (rangeDuration % oneDayMs === 0) {
    return endMs;
  }

  // Calculate number of full days in the range
  const fullDays = Math.floor(rangeDuration / oneDayMs);

  // Adjust to the end of the last full day
  return startMs + (fullDays + 1) * oneDayMs;
}
