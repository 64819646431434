import * as React from "react";

import { createUrlParamsFilterOptions } from "@/features/analytics/moreFilters/constants";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { BsFilter } from "react-icons/bs";

type TriggerBarPopoverComboboxProps = {
  handleUrlParamOptionClick: (value: string) => void;
};

const TriggerBarPopoverCombobox: React.FC<TriggerBarPopoverComboboxProps> = ({
  handleUrlParamOptionClick,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const handleOptionClick = (value: string) => {
    setOpen(false);

    // NOTE (Jackson, 2024-03-03): wait 200ms to allow the combobox to close completely,
    // otherwise the bar popover will open before the combobox closes
    setTimeout(() => {
      handleUrlParamOptionClick(value);
    }, 200);
  };

  return (
    <Combobox.Root
      options={createUrlParamsFilterOptions((value) =>
        handleOptionClick(value),
      )}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Combobox.Trigger>
        <Combobox.SelectionButton
          title="Filter"
          variant="secondary"
          endEnhancer={<BsFilter className="h-4 w-4 text-default" />}
        />
      </Combobox.Trigger>
      <Combobox.Popover layoutClassName="w-[125px]">
        <Combobox.Content />
      </Combobox.Popover>
    </Combobox.Root>
  );
};

export default TriggerBarPopoverCombobox;
