import type { ButtonProps } from "@replo/design-system/components/button/Button";
import type { ButtonSize } from "@replo/design-system/components/button/button-shared";

import * as React from "react";

import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";

import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";

import { useExperimentCreate } from "../tabs/hooks/useExperimentCreate";

type CreateExperimentButtonProps = {
  size?: ButtonSize;
};

export const CreateExperimentButton: React.FC<CreateExperimentButtonProps> = ({
  size = "base",
}) => {
  const commonProps: ButtonProps = {
    size: "base",
    variant: "primary",
  };

  const { workspaceId, isLoading: isWorkspaceLoading } =
    useCurrentWorkspaceContext();
  const { createExperiment, isCreatingExperiment } = useExperimentCreate(
    workspaceId ?? "",
  );

  const handleCreateNewExperiment = async () => {
    await createExperiment({
      location: "experiment_list_tab",
      onExperimentCreateSuccess: () => {
        successToast("Test Created", "");
      },
    });
  };

  return (
    <Button
      {...commonProps}
      size={size}
      onClick={() => void handleCreateNewExperiment()}
      isLoading={isCreatingExperiment || isWorkspaceLoading}
    >
      Create New A/B Test
    </Button>
  );
};
