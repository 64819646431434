import React from "react";

import { Checkbox } from "@replo/design-system/components/checkbox/Checkbox";
import Description from "@replo/design-system/components/label/Description";
import Label from "@replo/design-system/components/label/Label";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { useOverflowDetection } from "@replo/design-system/utils/useOverflowDetection";
import pickBy from "lodash-es/pickBy";
import { Check, Image as ImageIcon } from "lucide-react";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type BaseMenuItemProps = {
  disabled?: boolean;
  layoutClassName?: string;
  UNSAFE_className?: string;
  onHover?: () => void;
  label?: string;
  description?: string;
  selected?: boolean;
};

type DefaultVariant = {
  variant: "default";
  startEnhancer?: React.ReactNode;
};

type IconVariant = {
  variant: "icon";
  startEnhancer: React.ReactNode;
};

type ProductVariant = {
  variant: "product";
  startEnhancer?: React.ReactNode;
  hasCheckbox?: boolean;
  tag?: React.ReactNode;
  productImage?: string;
};

type FlexibleVariant = {
  variant: "flexible";
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
};

type VariantProps = {
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  hasCheckbox?: boolean;
  tag?: React.ReactNode;
  productImage?: string;
};

export type LargeMenuItemProps = BaseMenuItemProps &
  (DefaultVariant | ProductVariant | IconVariant | FlexibleVariant) &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

// NOTE (Jackson, 2025-03-28): We should not pass custom props directly on the button element as React
// will throw console errors (these are not valid DOM attributes).
const CUSTOM_PROPS = new Set([
  "variant",
  "layoutClassName",
  "UNSAFE_className",
  "onHover",
  "startEnhancer",
  "endEnhancer",
  "hasCheckbox",
  "tag",
  "productImage",
]);

const LargeMenuItem = React.forwardRef<HTMLButtonElement, LargeMenuItemProps>(
  (props, ref) => {
    const [descriptionRef, isOverflowingDescription] = useOverflowDetection();
    const [labelRef, isOverflowingLabel] = useOverflowDetection();

    const {
      disabled = false,
      layoutClassName,
      UNSAFE_className,
      variant,
      onHover,
      label,
      description,
      selected,
      ...restProps
    } = props;

    // Filter out custom props that shouldn't be passed to DOM
    const domProps = pickBy(restProps, (_, key) => !CUSTOM_PROPS.has(key));

    const variantProps = exhaustiveSwitch(
      props,
      "variant",
    )<VariantProps>({
      default: (props) => ({
        startEnhancer: props.startEnhancer,
      }),
      product: (props) => ({
        startEnhancer: props.startEnhancer,
        hasCheckbox: props.hasCheckbox,
        tag: props.tag,
        productImage: props.productImage,
      }),
      icon: (props) => ({
        startEnhancer: props.startEnhancer,
      }),
      flexible: (props) => ({
        startEnhancer: props.startEnhancer,
        endEnhancer: props.endEnhancer,
      }),
    });

    const content = (
      <button
        ref={ref}
        role="menuitem"
        className={twMerge(
          "w-full cursor-pointer flex relative shrink disabled:opacity-50 disabled:hover:bg-transparent disabled:cursor-not-allowed p-base rounded-md hover:bg-selectable-hover gap-[6px]",
          variant !== "flexible" && "h-11",
          variant === "product" && "items-center",
          selected &&
            "bg-selectable-selected hover:bg-selectable-selected-hover",
          layoutClassName,
          UNSAFE_className,
        )}
        disabled={disabled}
        onMouseEnter={onHover}
        {...domProps}
      >
        {variantProps.startEnhancer && (
          <div
            className={twMerge(
              variant === "icon" &&
                "flex items-center justify-center w-5 h-5 p-1 rounded bg-light-surface typ-body-small flex-shrink-0",
            )}
          >
            {variantProps.startEnhancer}
          </div>
        )}
        {variant === "product" && (
          <div className="w-8 h-8 rounded-md border-[.5px] border-border bg-white flex-shrink-0">
            {variantProps.productImage ? (
              <img
                src={variantProps.productImage}
                className="w-full h-full object-contain rounded-md"
                loading="eager"
                alt=""
              />
            ) : (
              // image placeholder
              <div className="w-full h-full flex items-center justify-center bg-light-surface rounded-md">
                <ImageIcon className="size-4 text-muted" />
              </div>
            )}
          </div>
        )}
        <div
          className={twMerge(
            "flex flex-col cursor-pointer grow text-left min-w-0",
          )}
        >
          <Label size="sm" label={label} ref={labelRef} truncate pointer />
          {variantProps.tag ? (
            <div className="flex items-center gap-1">
              {variantProps.tag}
              <Description
                size="sm"
                description={description}
                // Craig (2025-03-05): Adding this so that the tag and description are aligned vertically.
                // Otherwise it is off center.
                UNSAFE_className="leading-none truncate"
                type="muted"
                ref={descriptionRef}
              />
            </div>
          ) : (
            <Description
              size="sm"
              description={description}
              layoutClassName="m-0"
              type="muted"
              UNSAFE_className={twMerge(variant !== "flexible" && "truncate")}
              ref={descriptionRef}
            />
          )}
        </div>
        {variantProps.hasCheckbox && <Checkbox checked={selected} />}
        {selected && !variantProps.hasCheckbox && (
          <div className="flex items-center justify-center h-full typ-body-large">
            <Check className="icon-size-default" />
          </div>
        )}
        {variant === "flexible" && variantProps.endEnhancer && (
          <div className="flex items-center">{variantProps.endEnhancer}</div>
        )}
      </button>
    );
    if (isOverflowingDescription || isOverflowingLabel) {
      const tooltipContent = (
        <div className="flex flex-col gap-1 w-[250px]">
          <p className="font-medium">{label}</p>
          <p>{description}</p>
        </div>
      );
      return (
        <Tooltip content={tooltipContent} triggerAsChild={true}>
          {content}
        </Tooltip>
      );
    } else {
      return content;
    }
  },
);

LargeMenuItem.displayName = "LargeMenuItem";

export { LargeMenuItem };
