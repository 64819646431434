import * as React from "react";
import { forwardRef } from "react";

import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";

import { Badge } from "@replo/design-system/components/badge/Badge";
import twMerge from "@replo/design-system/utils/twMerge";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getDynamicDataBreadcrumbs } from "replo-runtime/shared/utils/dynamic-data";
import { evaluateVariable } from "replo-runtime/store/ReploVariable";
import tinycolor from "tinycolor2";

type DynamicDataIndicatorType =
  | "color"
  | "gradient"
  | "text"
  | "image"
  | "video"
  | "other";

type DynamicDataValueIndicatorProps = {
  type: DynamicDataIndicatorType;
  templateValue: string | null;
  onClick?(): void;
  onRemove?(): void;
  componentId?: string;
  className?: string;
  labelClassname?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const DynamicDataIndicatorBadge = ({
  templateValue,
  type,
  componentId,
}: {
  templateValue: string | null;
  type: DynamicDataIndicatorType;
  componentId?: string;
}) => {
  if (!templateValue) {
    return null;
  }
  const isText = type === "text";
  let bgColor = "bg-accent";
  let isValidColor = false;
  if (
    (type === "color" || type === "gradient") &&
    templateValue &&
    componentId
  ) {
    const context = getCurrentComponentContext(componentId, 0);
    const resolutionConfig = {
      selectedSellingPlan: context?.attributes?._selectedSellingPlan,
    };
    const evaluatedVariable = evaluateVariable(
      templateValue,
      context,
      resolutionConfig,
    );
    if (typeof evaluatedVariable === "string") {
      isValidColor = tinycolor(evaluatedVariable).isValid();
      if (isValidColor) {
        bgColor = evaluatedVariable;
      }
    }
  }

  if (isText || !isValidColor) {
    return <Badge type="dynamicData" />;
  }
  return <Badge type="color" isFilled backgroundColor={bgColor} />;
};

const DynamicDataValueIndicator = forwardRef<
  HTMLDivElement,
  DynamicDataValueIndicatorProps
>(
  (
    {
      type,
      templateValue,
      onClick,
      onRemove,
      componentId,
      className,
      labelClassname,
    },
    ref,
  ) => {
    const title = templateValue
      ? getDynamicDataBreadcrumbs(templateValue)
      : "Select Dynamic Data";
    return (
      <SelectionIndicator
        ref={ref}
        title={title}
        className={twMerge("cursor-pointer text-left", className)}
        labelClassname={twMerge(
          templateValue ? undefined : "text-muted",
          labelClassname,
        )}
        onClick={onClick}
        startEnhancer={
          <DynamicDataIndicatorBadge
            templateValue={templateValue}
            type={type}
            componentId={componentId}
          />
        }
        endEnhancer={
          onRemove &&
          templateValue && (
            <FormFieldXButton
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
            />
          )
        }
      />
    );
  },
);

DynamicDataValueIndicator.displayName = "DynamicDataValueIndicator";

export default DynamicDataValueIndicator;
