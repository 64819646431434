import type { RGBColor } from "react-color";
import type {
  Gradient,
  GradientValue,
  SolidOrGradient,
} from "replo-runtime/shared/types";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import React from "react";

import { PickerComponent } from "@common/designSystem/color/CustomPicker";
import Input from "@common/designSystem/Input";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import {
  getAutoCompletedColor,
  getFormattedColor,
  getFormattedColorWithoutOpacity,
  getFormattedOpacity,
  getHex8Color,
  getHexColor,
  getInitialColorValue,
  getRGB,
} from "@editor/utils/colors";

import { Badge } from "@replo/design-system/components/badge/Badge";
import IconButton from "@replo/design-system/components/button/IconButton";
import debounce from "lodash-es/debounce";
import { RotateCcw, RotateCw } from "lucide-react";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { CSS_ANGLE_TYPES } from "replo-runtime/shared/utils/units";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { isEmpty, isNotNullish } from "replo-utils/lib/misc";
import { v4 as uuidv4 } from "uuid";

import GradientSlider from "../GradientSlider";
import PageColors from "./PageColors";

type GradientPickerProps = {
  value: GradientValue | ReploMixedStyleValue | null;
  onPreviewChange?(value: SolidOrGradient): void;
  onChange(value: SolidOrGradient): void;
  onDragStart?: (e: React.MouseEvent) => void;
  onDragEnd?: (e: React.MouseEvent) => void;
  showShopStyles?: boolean;
};

const GradientPicker = ({
  value,
  onDragStart,
  onDragEnd,
  onChange,
  onPreviewChange,
  showShopStyles = true,
}: GradientPickerProps) => {
  const [color, setColor] = React.useState<RGBColor>(
    getInitialColorValue(!isMixedStyleValue(value) ? value : null),
  );
  const formattedColor = getFormattedColor(getHexColor(color));
  const [inputColorValue, setInputColorValue] = React.useState(formattedColor);
  const [lastValidColorValue, setLastValidColorValue] =
    React.useState(formattedColor);
  const [tilt, setTilt] = React.useState(
    _getInitialTiltValue(!isMixedStyleValue(value) ? value : null),
  );
  const [gradientColors, setGradientColors] = useOverridableState<Gradient>(
    _getInitialGradientValue(!isMixedStyleValue(value) ? value : null, tilt),
    (value) => {
      submitChange({
        value: {
          type: "gradient",
          gradient: { tilt, stops: value.stops },
        },
        changeType: "submit",
      });
    },
  );
  const [selectedDraggable, setSelectedDraggable] = React.useState(
    gradientColors.stops[0]?.id || null,
  );

  const _debouncedOnChange = React.useMemo(() => {
    return debounce((value) => {
      onChange?.(value);
    }, 300);
  }, [onChange]);

  const submitChange = React.useCallback(
    (opts: { value: SolidOrGradient; changeType: "preview" | "submit" }) => {
      const { value, changeType } = opts;
      onPreviewChange?.(value);
      if (changeType === "submit") {
        _debouncedOnChange(value);
      }
    },
    [onPreviewChange, _debouncedOnChange],
  );

  /**
   * Internal onChange function that should be called whenever anything changes. Does things based
   * on the provided change type - "preview" will call onPreviewChange, and "submit" will call the main
   * onChange callback (debounced).
   *
   * TODO (Noah, 2024-11-15): This code is super old and really convoluted - you can tell by how every
   * time it's called, one argument is different and the other two are the current state values. This
   * should really be a useReducer.
   */
  const _internalOnChange = React.useCallback(
    (opts: {
      color: RGBColor;
      tilt: string;
      changeType: "preview" | "submit";
    }) => {
      const { color, changeType } = opts;
      const hex8Color = getHex8Color(color);
      const formattedColor = getFormattedColor(hex8Color);
      setInputColorValue(getFormattedColor(formattedColor));
      setLastValidColorValue(formattedColor);

      // If there is no draggable we add a new one with the previous solid color selected
      if (isEmpty(gradientColors.stops)) {
        const uuid = uuidv4();
        setGradientColors({
          tilt: tilt,
          stops: [
            {
              id: uuid,
              color: color ? hex8Color : "transparent",
              location: "0%",
            },
          ],
        });
        setSelectedDraggable(uuid);
      }
      submitChange({
        value: {
          type: "gradient",
          gradient: {
            tilt,
            stops: gradientColors.stops,
          },
        },
        changeType,
      });
    },
    [gradientColors.stops, setGradientColors, submitChange, tilt],
  );

  const _pickerOnChange = React.useCallback(
    (rgb: RGBColor) => {
      _internalOnChange({ color: rgb, tilt, changeType: "preview" });
      setColor(rgb);
    },
    [_internalOnChange, tilt],
  );

  const handleInputColorChange = React.useCallback(() => {
    const completeColor = getAutoCompletedColor(inputColorValue);

    if (completeColor?.toLowerCase() === lastValidColorValue?.toLowerCase()) {
      return;
    }

    if (completeColor) {
      const colorValue = getFormattedColor(completeColor);
      const rgbColor = getRGB(colorValue);
      setColor(rgbColor);
      setLastValidColorValue(colorValue);
      _internalOnChange({
        color: rgbColor,
        tilt,
        changeType: "submit",
      });
    } else if (lastValidColorValue) {
      setInputColorValue(lastValidColorValue);
    }
  }, [lastValidColorValue, inputColorValue, _internalOnChange, tilt]);

  const handleInputOpacityChange = React.useCallback(
    (value: string) => {
      const rgbColor = {
        ...color,
        a: Number.parseInt(value.replace("%", ""), 10) / 100,
      };
      setColor(rgbColor);
      _internalOnChange({
        color: rgbColor,
        tilt,
        changeType: "submit",
      });
    },
    [color, _internalOnChange, tilt],
  );

  const getOpacity = () => {
    if (isNotNullish(color.a)) {
      return getFormattedOpacity(color.a);
    }
    return "100%";
  };

  const opacity = getOpacity();

  const onChangeInputColor = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputColorValue(e.target.value);
      _debouncedOnChange(e.target.value ?? null);
    },
    [_debouncedOnChange],
  );

  function _onTiltChange(value: string) {
    setTilt(value);
    setGradientColors({
      tilt: value,
      stops: gradientColors.stops,
    });
    _internalOnChange({ color, tilt: value, changeType: "submit" });
  }

  function _rotateGradient(to: "left" | "right") {
    const deg = Number.parseInt(tilt.replace(/\D+/g, ""), 10);
    let newDeg = deg;
    if (to === "left") {
      newDeg -= 90;
      if (newDeg < 0) {
        newDeg = 360 + newDeg;
      }
    } else {
      newDeg += 90;
      if (newDeg > 360) {
        newDeg = newDeg - 360;
      }
    }
    _onTiltChange(`${newDeg}deg`);
  }

  const handleDocumentColorChange = React.useCallback(
    (documentColor: string) => {
      const gradientStops = gradientColors.stops.map((stop) => {
        if (stop.id === selectedDraggable) {
          return {
            id: stop.id,
            color: documentColor,
            location: stop.location,
          };
        }
        return stop;
      });
      setGradientColors({
        tilt: gradientColors.tilt,
        stops: gradientStops,
      });
      const formattedColor = getFormattedColorWithoutOpacity(documentColor);
      if (formattedColor) {
        setInputColorValue(formattedColor);
      }
    },
    [gradientColors, selectedDraggable, setGradientColors],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (selectedDraggable) {
      const stop = gradientColors.stops.find(
        (stop) => stop.id === selectedDraggable,
      );
      if (stop) {
        const { id, location } = stop;
        setGradientColors({
          tilt: gradientColors.tilt,
          stops: gradientColors.stops.map((stop) => {
            if (stop.id === selectedDraggable) {
              return {
                id,
                color: getFormattedColor(getHex8Color(color))!,
                location,
              };
            }
            return stop;
          }),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, selectedDraggable]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (selectedDraggable) {
      const stop = gradientColors.stops.find(
        (stop) => stop.id === selectedDraggable,
      );
      if (stop) {
        const rgbColor = getRGB(stop.color);
        setColor(rgbColor);
        setInputColorValue(getFormattedColor(stop.color));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDraggable]);

  const isMixedValue = isMixedStyleValue(value);

  return (
    <div className="flex select-none flex-col items-center gap-1">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-1">
          <LengthInputSelector.Root
            field="tilt"
            resetValue="90deg"
            metrics={CSS_ANGLE_TYPES}
            onChange={_onTiltChange}
            value={tilt}
            dragTrigger="label"
          >
            <LengthInputSelector.DraggableArea>
              <ModifierLabel label="Rotation" />
            </LengthInputSelector.DraggableArea>
            <LengthInputSelector.Input
              placeholder="90deg"
              autofocus={!isMixedValue}
            />
          </LengthInputSelector.Root>
          <IconButton
            size="sm"
            icon={<RotateCcw size={12} className="text-muted" />}
            onClick={() => _rotateGradient("left")}
            variant="secondary"
            aria-label="Rotate gradient left"
          />
          <IconButton
            size="sm"
            icon={<RotateCw size={12} className="text-muted" />}
            onClick={() => _rotateGradient("right")}
            variant="secondary"
            aria-label="Rotate gradient right"
          />
        </div>
        <GradientSlider
          onChange={setGradientColors}
          gradient={gradientColors}
          selectedStopId={selectedDraggable ?? undefined}
          onChangeSelection={setSelectedDraggable}
          debounceDragging
          colorForFirstStop={getHex8Color(color)}
        />
      </div>
      <PickerComponent
        color={!isMixedValue ? color : undefined}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onChange={({ rgb }) => _pickerOnChange(rgb)}
        isMixedStyleValue={isMixedValue}
      />
      <div className="flex w-full gap-2">
        <Input
          value={
            !isMixedValue
              ? getFormattedColorWithoutOpacity(inputColorValue) ?? ""
              : "Mixed"
          }
          placeholder="#000000"
          onChange={onChangeInputColor}
          onKeyDown={(e) => e.stopPropagation()}
          startEnhancer={
            <Badge
              type={!isMixedValue ? "color" : "unknown"}
              isFilled
              backgroundColor={inputColorValue ?? ""}
            />
          }
          onBlur={handleInputColorChange}
          onEnter={handleInputColorChange}
          autoFocus={false}
        />
        <LengthInputSelector.Root
          field="opacity"
          metrics={["%"]}
          onChange={handleInputOpacityChange}
          value={opacity}
          allowsNegativeValue={false}
          minValues={{ "%": 0 }}
          maxValues={{ "%": 100 }}
        >
          <div className="flex flex-row items-center justify-center w-16">
            <LengthInputSelector.Input placeholder="100%" />
          </div>
        </LengthInputSelector.Root>
      </div>
      {showShopStyles && (
        <PageColors onDocumentColorChange={handleDocumentColorChange} />
      )}
    </div>
  );
};

function _getInitialGradientValue(
  value: string | SolidOrGradient | null,
  tilt: string,
) {
  if (
    isNotNullish(value) &&
    typeof value !== "string" &&
    value.type === "gradient"
  ) {
    return value.gradient;
  }
  return { tilt: tilt, stops: [] };
}

function _getInitialTiltValue(value: string | SolidOrGradient | null) {
  if (
    isNotNullish(value) &&
    typeof value !== "string" &&
    value.type === "gradient"
  ) {
    return value.gradient.tilt;
  }
  return "90deg";
}

export default GradientPicker;
