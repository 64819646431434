import React from "react";

/**
 * Hook to detect if an element's content is overflowing
 * @returns [refCallback, isOverflowing] - A callback ref to attach to any element and a boolean indicating overflow state
 */
export function useOverflowDetection(): [
  (element: HTMLElement | null) => void,
  boolean,
] {
  const [element, setElement] = React.useState<HTMLElement | null>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const refCallback = React.useCallback((node: HTMLElement | null) => {
    if (node) {
      setElement(node);
    }
  }, []);

  React.useEffect(() => {
    if (!element) {
      return;
    }

    const checkOverflow = () => {
      // (Craig, 03-10-2025): This timeout allows the component to render first
      // before checking for overflow
      setTimeout(() => {
        if (element) {
          const hasOverflow = element.scrollWidth > element.clientWidth;
          setIsOverflowing(hasOverflow);
        }
      }, 100);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [element]);

  return [refCallback, isOverflowing];
}
