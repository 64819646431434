import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { DropTarget } from "@editor/types/drop-target";
import type { Component } from "schemas/component";

import * as React from "react";

import { useAiAltTextMutation } from "@editor/reducers/ai-reducer";
import {
  selectComponentDataMapping,
  selectDraftElementType,
} from "@editor/reducers/core-reducer";
import { selectCurrentDragType } from "@editor/reducers/drag-and-drop-reducer";
import {
  setOpenPopoverId,
  setRightBarActiveTab,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorStore } from "@editor/store";

import { setCanvasInteractionMode } from "@/features/canvas/canvas-reducer";
import isFunction from "lodash-es/isFunction";
import { isComponentInsideProductComponent } from "replo-runtime/shared/Component";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { exhaustiveSwitch, noop } from "replo-utils/lib/misc";

export type CanvasOffset = {
  x: number;
  y: number;
  scale: number;
  canvasWidth: number;
  canvasHeight: number;
};

function isCanvasOffsetEqual(
  offset1: CanvasOffset | null,
  offset2: CanvasOffset | null,
) {
  if (offset1 === null && offset2 === null) {
    return true;
  }
  if (offset1 === null || offset2 === null) {
    return false;
  }
  return (
    offset1.x === offset2.x &&
    offset1.y === offset2.y &&
    offset1.scale === offset2.scale &&
    offset1.canvasWidth === offset2.canvasWidth &&
    offset1.canvasHeight === offset2.canvasHeight
  );
}

type SetCanvasOffset = React.SetStateAction<CanvasOffset | null>;

type DragAndDropContextProps = {
  offset: CanvasOffset | null;
  setOffset: React.Dispatch<SetCanvasOffset>;
  onDrop: React.MutableRefObject<
    | ((
        dropTarget: DropTarget,
        template: ComponentTemplate | null | undefined,
        previousActions: UseApplyComponentActionType[],
        isSavedComponent?: boolean,
      ) => void)
    | null
  >;
  onSuccessFinishDrop?: (
    addedComponent: Component,
    parentId?: string | null,
  ) => void;
};

const DragAndDropContext = React.createContext<DragAndDropContextProps>({
  offset: null,
  setOffset: noop,
  onDrop: { current: null },
  onSuccessFinishDrop: noop,
});
DragAndDropContext.displayName = "DragAndDropContext";

/**
 * Hook for components to use drag and drop data.
 */
export default function useDragAndDrop() {
  return React.useContext(DragAndDropContext);
}

/**
 * Provider component that makes drag and drop data available
 * to any children that calls useDragAndDrop().
 */
export const DragAndDropProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const store = useEditorStore();

  const [offset, _setOffset] = React.useState<CanvasOffset | null>(null);
  const dispatch = useEditorDispatch();
  // TODO (Martin, 2022-01-03): Figure out if this polymorphic functions can be dealt in a better way.
  const onDrop = React.useRef(null);

  const setOffset = React.useCallback((offsetParam: SetCanvasOffset) => {
    // Note (Noah, 2022-02-21): Prevent useless rerenders by manually avoiding
    // a new state if the new offset is equal to the old one
    _setOffset((oldOffset) => {
      const newOffset = isFunction(offsetParam)
        ? offsetParam(oldOffset)
        : offsetParam;
      return isCanvasOffsetEqual(oldOffset, newOffset) ? oldOffset : newOffset;
    });
  }, []);

  const [generateAiAltText] = useAiAltTextMutation();

  const onSuccessFinishDrop = React.useCallback(
    (addedComponent: Component, parentId?: string | null) => {
      const storeState = store.getState();
      const currentDragType = selectCurrentDragType(storeState);
      const componentDataMapping = selectComponentDataMapping(storeState);
      const draftElementType = selectDraftElementType(storeState);
      if (currentDragType === "newComponent") {
        const openProductSelector = () => {
          // NOTE (Fran 2024-05-21): We only want to open the product selector if the component is
          // not inside a product context.
          if (
            draftElementType === "shopifyProductTemplate" ||
            (parentId &&
              isComponentInsideProductComponent(parentId, componentDataMapping))
          ) {
            return;
          }

          dispatch(setRightBarActiveTab("custom"));
          dispatch(setOpenPopoverId("config-product-selector"));
        };

        exhaustiveSwitch({ type: addedComponent.type })({
          modal: () => null,
          shopifySection: () => null,
          text: () => {
            const componentHasDynamicText = isDynamicDataValue(
              addedComponent.props.text,
            );
            if (!componentHasDynamicText) {
              dispatch(setCanvasInteractionMode("content-editing"));
            }
          },
          image: () => {
            // NOTE (Gabe 2025-03-06): This enables us to trigger the AI alt text
            // generation when an image is dropped into the canvas. We may be able to
            // remove this in the future after we start generating descriptions for all
            // images on the backend.
            if (
              typeof addedComponent.props.src === "string" &&
              !isDynamicDataValue(addedComponent.props.src)
            ) {
              void generateAiAltText({
                componentId: addedComponent.id,
                imageUrl: addedComponent.props.src,
                triggeredManually: false,
              });
            }
          },
          circle: () => null,
          container: () => null,
          toggleContainer: () => null,
          toggleIndicator: () => null,
          symbolRef: () => null,
          button: () => null,
          spacer: () => null,
          icon: () => {
            dispatch(setOpenPopoverId("modifier-icon-selector"));
          },
          collapsible: () => null,
          slidingCarousel: () => null,
          player: () => null,
          player__playIcon: () => null,
          player__muteIcon: () => null,
          player__fullScreenIcon: () => null,
          collectionSelect: () => null,
          product: () => {
            dispatch(setRightBarActiveTab("custom"));
            dispatch(setOpenPopoverId("config-product-selector"));
          },
          productCollection: () => null,
          quantitySelector: () => null,
          dropdown: () => null,
          variantSelect: () => null,
          optionSelect: () => null,
          variantSelectDropdown: () => null,
          optionSelectDropdown: () => null,
          sellingPlanSelect: () => null,
          sellingPlanSelectDropdown: () => null,
          collection: () => null,
          collectionV2: () => null,
          googleMapsEmbed: () => null,
          klaviyoEmbed: () => null,
          temporaryCart: () => null,
          temporaryCartItems: () => null,
          vimeoEmbed: () => null,
          vimeoEmbedV2: () => null,
          youtubeEmbed: () => null,
          youtubeEmbedV2: () => null,
          carouselV2: () => null,
          carouselV2__panels: () => null,
          carouselV2__indicator: () => null,
          carouselV3: () => null,
          carouselV3Slides: () => null,
          carouselV3Control: () => null,
          carouselV3Indicators: () => null,
          carouselPanelsCount: () => null,
          shopifyRawLiquid: () => null,
          shopifyAppBlocks: () => null,
          collapsibleV2: () => null,
          collapsibleV2Header: () => null,
          collapsibleV2Content: () => null,
          tabsBlock: () => null,
          tabs__list: () => null,
          tabs__panelsContent: () => null,
          tabs__onePanelContent: () => null,
          tabsV2__block: () => null,
          tabsV2__list: () => null,
          tabsV2__panelsContent: () => null,
          marquee: () => null,
          rawHtmlContent: () => null,
          starRating: () => null,
          tikTokEmbed: () => null,
          rechargeSubscriptionWidget: () => null,
          staySubscriptionWidget: () => null,
          okendoReviewsWidget: openProductSelector,
          okendoProductRatingSummary: openProductSelector,
          junipProductRating: openProductSelector,
          junipReviews: openProductSelector,
          yotpoProductRating: openProductSelector,
          yotpoReviews: openProductSelector,
          looxProductRating: openProductSelector,
          looxReviews: openProductSelector,
          knoCommerceWidget: () => null,
          reviewsIoProductRating: openProductSelector,
          reviewsIoReviews: openProductSelector,
          h1: () => null,
          h2: () => null,
          h3: () => null,
          spinner: () => null,
          dynamicCheckoutButtons: () => null,
          paymentTerms: () => null,
          countdownTimer: () => null,
          accordionBlock: () => null,
          subscribeAndSave: () => null,
          rebuyWidget: () => null,
          buyWithPrimeButton: () => null,
          stampedProductReviewsWidget: openProductSelector,
          stampedProductRatingWidget: openProductSelector,
          feraProductRatingWidget: openProductSelector,
          feraProductReviewsWidget: openProductSelector,
          feraStoreReviewsWidget: openProductSelector,
          feraMediaGalleryWidget: () => null,
          shopifyProductReviewsWidget: openProductSelector,
          shopifyProductRatingWidget: openProductSelector,
          judgeProductRatingWidget: openProductSelector,
          judgeProductReviewsWidget: openProductSelector,
          infiniteOptionsWidget: () => null,
          kachingBundles: () => null,
          postscriptSignupForm: () => null,
          beforeAfterSlider: () => null,
          beforeAfterSliderThumb: () => null,
          beforeAfterSliderBeforeContent: () => null,
          beforeAfterSliderAfterContent: () => null,
          tooltip: () => null,
          tooltipContent: () => null,
          selectionList: () => null,
        });
      }
    },
    [dispatch, generateAiAltText, store],
  );

  return (
    <DragAndDropContext.Provider
      value={{
        offset,
        setOffset,
        onDrop,
        onSuccessFinishDrop,
      }}
    >
      {children}
    </DragAndDropContext.Provider>
  );
};
