import type { Digits, LowercaseAlphabet } from "replo-runtime/shared/types";
import type { ReploGlobalCommand } from "./commands";

import { GLOBAL_COMMANDS } from "./commands";

export type Hotkey =
  | ReploGlobalCommand
  | "escape"
  | "deselectCurrentComponent"
  | "editText"
  | "debug"
  | "groupIntoContainerTwo"
  | "preventSelectingAllTextsOnEditor"
  | "openHotkeysModal"
  | "enter"
  | "selectAIText"
  | "selectAIMobileResponsive"
  | "mockSave";

export type HotkeyMetaKey =
  | "meta"
  | "shift"
  | "altOption"
  | "backspace"
  | "delete"
  | "space"
  | "esc"
  | "arrowUp"
  | "arrowDown"
  | "arrowLeft"
  | "arrowRight";

export type HotkeyIndicatorCharacter =
  | HotkeyMetaKey
  | LowercaseAlphabet
  | Digits
  | "-"
  | "+"
  | "="
  | "<"
  | ">"
  | "↑"
  | "↓";

export const HotkeyMetaKeyToLabel: Record<
  HotkeyMetaKey,
  string | { mac: string; windows: string }
> = {
  meta: {
    mac: "⌘",
    windows: "CTRL",
  },
  shift: "⇧",
  altOption: {
    mac: "⌥",
    windows: "⎇",
  },
  backspace: "⌫",
  delete: "del",
  esc: {
    mac: "⎋",
    windows: "ESC",
  },
  space: "␣",
  arrowRight: "→",
  arrowDown: "↓",
  arrowLeft: "←",
  arrowUp: "↑",
};

type HotkeyData = {
  controlStrings: string[];
  indicatorCharacters: HotkeyIndicatorCharacter[];
  ignoresModifiersOnKeyup: boolean;
  displayName: string;
  triggerInTextInputs: boolean;
  disallowWhenDialogsAndPopoversOpen?: boolean;
  group?: "Text" | "Edit" | "View" | "Zoom";
};

export const hotkeysData: Record<Hotkey, HotkeyData> = {
  undo: {
    controlStrings: ["mod+z"],
    indicatorCharacters: GLOBAL_COMMANDS.undo.hotkey!,
    displayName: GLOBAL_COMMANDS.undo.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  redo: {
    controlStrings: ["mod+shift+z"],
    indicatorCharacters: GLOBAL_COMMANDS.redo.hotkey!,
    displayName: GLOBAL_COMMANDS.redo.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  copy: {
    controlStrings: ["mod+c"],
    indicatorCharacters: GLOBAL_COMMANDS.copy.hotkey!,
    displayName: GLOBAL_COMMANDS.copy.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  copyStyles: {
    controlStrings: ["alt+mod+c"],
    indicatorCharacters: GLOBAL_COMMANDS.copyStyles.hotkey!,
    displayName: GLOBAL_COMMANDS.copyStyles.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  paste: {
    controlStrings: ["mod+v"],
    indicatorCharacters: GLOBAL_COMMANDS.paste.hotkey!,
    displayName: GLOBAL_COMMANDS.paste.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  pasteStyles: {
    controlStrings: ["alt+mod+v"],
    indicatorCharacters: GLOBAL_COMMANDS.pasteStyles.hotkey!,
    displayName: GLOBAL_COMMANDS.pasteStyles.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  toggleVisibility: {
    controlStrings: ["mod+shift+h"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleVisibility.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleVisibility.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  delete: {
    controlStrings: ["backspace", "delete"],
    indicatorCharacters: GLOBAL_COMMANDS.delete.hotkey!,
    displayName: GLOBAL_COMMANDS.delete.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  duplicate: {
    controlStrings: ["mod+d"],
    indicatorCharacters: GLOBAL_COMMANDS.duplicate.hotkey!,
    displayName: GLOBAL_COMMANDS.duplicate.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  debug: {
    controlStrings: ["mod+shift+o"],
    indicatorCharacters: ["meta", "shift", "o"],
    displayName: "Debug",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  groupIntoContainer: {
    controlStrings: ["mod+g"],
    indicatorCharacters: GLOBAL_COMMANDS.groupIntoContainer.hotkey!,
    displayName: GLOBAL_COMMANDS.groupIntoContainer.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  groupIntoContainerTwo: {
    controlStrings: ["shift+a"],
    indicatorCharacters: ["shift", "a"],
    displayName: "Group into container",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  saveComponentTemplate: {
    controlStrings: ["mod+shift+s"],
    indicatorCharacters: GLOBAL_COMMANDS.saveComponentTemplate.hotkey!,
    displayName: GLOBAL_COMMANDS.saveComponentTemplate.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  mockSave: {
    controlStrings: ["mod+s"],
    indicatorCharacters: ["meta", "s"],
    displayName: "Save",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  zoomIn: {
    controlStrings: ["mod+="],
    indicatorCharacters: GLOBAL_COMMANDS.zoomIn.hotkey!,
    displayName: GLOBAL_COMMANDS.zoomIn.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Zoom",
  },
  zoomOut: {
    controlStrings: ["mod+-"],
    indicatorCharacters: GLOBAL_COMMANDS.zoomOut.hotkey!,
    displayName: GLOBAL_COMMANDS.zoomOut.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Zoom",
  },
  resetZoom: {
    controlStrings: ["mod+0"],
    indicatorCharacters: GLOBAL_COMMANDS.resetZoom.hotkey!,
    displayName: GLOBAL_COMMANDS.resetZoom.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Zoom",
  },
  grabCanvas: {
    // Note (Noah, 2023-04-22, USE-77): We want to ignore modifier keys on keyup
    // here because the keydown and keyup for this hotkey are different - keydown
    // enables grab mode and keyup disables it. If we don't ignore modifiers on
    // keyup, then holding down space, then pressing shift, then releasing space
    // will result in the keyup not being called and the grab mode will be stuck.
    ignoresModifiersOnKeyup: true,
    controlStrings: ["space"],
    indicatorCharacters: GLOBAL_COMMANDS.grabCanvas.hotkey!,
    displayName: GLOBAL_COMMANDS.grabCanvas.label,
    triggerInTextInputs: false,
    group: "View",
  },
  deselectCurrentComponent: {
    controlStrings: ["escape"],
    indicatorCharacters: ["esc"],
    displayName: "Deselect component",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  toggleCodeEditor: {
    controlStrings: ["shift+d"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleCodeEditor.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleCodeEditor.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  preventSelectingAllTextsOnEditor: {
    ignoresModifiersOnKeyup: false,
    controlStrings: ["mod+a"],
    indicatorCharacters: ["meta", "a"],
    displayName: "Prevent selecting all texts on editor",
    triggerInTextInputs: false,
  },
  togglePreviewMode: {
    ignoresModifiersOnKeyup: false,
    controlStrings: ["shift+p"],
    indicatorCharacters: GLOBAL_COMMANDS.togglePreviewMode.hotkey!,
    displayName: GLOBAL_COMMANDS.togglePreviewMode.label,
    triggerInTextInputs: false,
    group: "View",
  },
  escape: {
    ignoresModifiersOnKeyup: false,
    controlStrings: ["escape"],
    indicatorCharacters: ["esc"],
    displayName: "Escape",
    triggerInTextInputs: false,
    group: "Edit",
  },
  toggleAIMenu: {
    controlStrings: ["mod+e"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleAIMenu.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleAIMenu.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  toggleH1Text: {
    controlStrings: ["mod+alt+1"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleH1Text.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleH1Text.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH2Text: {
    controlStrings: ["mod+alt+2"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleH2Text.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleH2Text.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH3Text: {
    controlStrings: ["mod+alt+3"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleH3Text.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleH3Text.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH4Text: {
    controlStrings: ["mod+alt+4"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleH4Text.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleH4Text.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH5Text: {
    controlStrings: ["mod+alt+5"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleH5Text.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleH5Text.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH6Text: {
    controlStrings: ["mod+alt+6"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleH6Text.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleH6Text.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleBoldText: {
    controlStrings: ["mod+b"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleBoldText.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleBoldText.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleLinkText: {
    controlStrings: ["mod+k"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleLinkText.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleLinkText.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleItalicText: {
    controlStrings: ["mod+i"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleItalicText.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleItalicText.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleUnderlineText: {
    controlStrings: ["mod+u"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleUnderlineText.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleUnderlineText.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleStrikethroughText: {
    controlStrings: ["shift+mod+x"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleStrikethroughText.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleStrikethroughText.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleBulletList: {
    controlStrings: ["mod+alt+7"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleBulletList.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleBulletList.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleNumberedList: {
    controlStrings: ["mod+alt+8"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleNumberedList.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleNumberedList.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  setDesignPanel: {
    controlStrings: ["s"],
    indicatorCharacters: GLOBAL_COMMANDS.setDesignPanel.hotkey!,
    displayName: GLOBAL_COMMANDS.setDesignPanel.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setConfigPanel: {
    controlStrings: ["d"],
    indicatorCharacters: GLOBAL_COMMANDS.setConfigPanel.hotkey!,
    displayName: GLOBAL_COMMANDS.setConfigPanel.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setInteractionsPanel: {
    controlStrings: ["i"],
    indicatorCharacters: GLOBAL_COMMANDS.setInteractionsPanel.hotkey!,
    displayName: GLOBAL_COMMANDS.setInteractionsPanel.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setComponentsPanel: {
    controlStrings: ["alt+1"],
    indicatorCharacters: GLOBAL_COMMANDS.setComponentsPanel.hotkey!,
    displayName: GLOBAL_COMMANDS.setComponentsPanel.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setLayersPanel: {
    controlStrings: ["alt+2"],
    indicatorCharacters: GLOBAL_COMMANDS.setLayersPanel.hotkey!,
    displayName: GLOBAL_COMMANDS.setLayersPanel.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  openPageSettings: {
    controlStrings: ["alt+3"],
    indicatorCharacters: ["altOption", "3"],
    displayName: GLOBAL_COMMANDS.openPageSettings.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  toggleVersionHistory: {
    controlStrings: ["alt+4"],
    indicatorCharacters: GLOBAL_COMMANDS.toggleVersionHistory.hotkey!,
    displayName: GLOBAL_COMMANDS.toggleVersionHistory.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },

  setElementsPanel: {
    controlStrings: ["alt+5"],
    indicatorCharacters: GLOBAL_COMMANDS.setElementsPanel.hotkey!,
    displayName: GLOBAL_COMMANDS.setElementsPanel.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setAssetsPanel: {
    controlStrings: ["alt+6"],
    indicatorCharacters: ["altOption", "6"],
    displayName: GLOBAL_COMMANDS.setAssetsPanel.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setSavedStylesPanel: {
    controlStrings: ["alt+7"],
    indicatorCharacters: GLOBAL_COMMANDS.setSavedStylesPanel.hotkey!,
    displayName: GLOBAL_COMMANDS.setSavedStylesPanel.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  openProjectSettings: {
    controlStrings: ["mod+alt+s"],
    indicatorCharacters: GLOBAL_COMMANDS.openProjectSettings.hotkey!,
    displayName: GLOBAL_COMMANDS.openProjectSettings.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  openHotkeysModal: {
    controlStrings: ["shift+?"],
    indicatorCharacters: ["shift"],
    displayName: "Show hotkeys modal",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  decreaseFontSize: {
    controlStrings: ["shift+mod+,", "shift+mod+<"],
    indicatorCharacters: GLOBAL_COMMANDS.decreaseFontSize.hotkey!,
    displayName: GLOBAL_COMMANDS.decreaseFontSize.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  increaseFontSize: {
    controlStrings: ["shift+mod+.", "shift+mod+>"],
    indicatorCharacters: GLOBAL_COMMANDS.increaseFontSize.hotkey!,
    displayName: GLOBAL_COMMANDS.increaseFontSize.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  decreaseLetterSpacing: {
    controlStrings: ["alt+,", "alt+<"],
    indicatorCharacters: GLOBAL_COMMANDS.decreaseLetterSpacing.hotkey!,
    displayName: GLOBAL_COMMANDS.decreaseLetterSpacing.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  increaseLetterSpacing: {
    controlStrings: ["alt+.", "alt+>"],
    indicatorCharacters: GLOBAL_COMMANDS.increaseLetterSpacing.hotkey!,
    displayName: GLOBAL_COMMANDS.increaseLetterSpacing.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  decreaseLineHeight: {
    controlStrings: ["alt+shift+,", "alt+shift+<"],
    indicatorCharacters: GLOBAL_COMMANDS.decreaseLineHeight.hotkey!,
    displayName: GLOBAL_COMMANDS.decreaseLineHeight.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  increaseLineHeight: {
    controlStrings: ["alt+shift+.", "alt+shift+>"],
    indicatorCharacters: GLOBAL_COMMANDS.increaseLineHeight.hotkey!,
    displayName: GLOBAL_COMMANDS.increaseLineHeight.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  decreaseFontWeight: {
    controlStrings: ["alt+mod+,", "alt+mod+<"],
    indicatorCharacters: GLOBAL_COMMANDS.decreaseFontWeight.hotkey!,
    displayName: GLOBAL_COMMANDS.decreaseFontWeight.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  increaseFontWeight: {
    controlStrings: ["alt+mod+.", "alt+mod+>"],
    indicatorCharacters: GLOBAL_COMMANDS.increaseFontWeight.hotkey!,
    displayName: GLOBAL_COMMANDS.increaseFontWeight.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  moveUpInTheTree: {
    controlStrings: ["ArrowUp"],
    indicatorCharacters: GLOBAL_COMMANDS.moveUpInTheTree.hotkey!,
    displayName: GLOBAL_COMMANDS.moveUpInTheTree.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  moveDownInTheTree: {
    controlStrings: ["ArrowDown"],
    indicatorCharacters: GLOBAL_COMMANDS.moveDownInTheTree.hotkey!,
    displayName: GLOBAL_COMMANDS.moveDownInTheTree.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  exportToSection: {
    controlStrings: ["alt+mod+k"],
    indicatorCharacters: GLOBAL_COMMANDS.exportToSection.hotkey!,
    displayName: GLOBAL_COMMANDS.exportToSection.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  setWidthToFillAvailable: {
    controlStrings: ["mod+shift+arrowRight"],
    indicatorCharacters: GLOBAL_COMMANDS.setWidthToFillAvailable.hotkey!,
    displayName: GLOBAL_COMMANDS.setWidthToFillAvailable.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  setWidthToWrapContent: {
    controlStrings: ["mod+shift+arrowLeft"],
    indicatorCharacters: GLOBAL_COMMANDS.setWidthToWrapContent.hotkey!,
    displayName: GLOBAL_COMMANDS.setWidthToWrapContent.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  setHeightToFillAvailable: {
    controlStrings: ["mod+shift+arrowUp"],
    indicatorCharacters: GLOBAL_COMMANDS.setHeightToFillAvailable.hotkey!,
    displayName: GLOBAL_COMMANDS.setHeightToFillAvailable.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  setHeightToWrapContent: {
    controlStrings: ["mod+shift+arrowDown"],
    indicatorCharacters: GLOBAL_COMMANDS.setHeightToWrapContent.hotkey!,
    displayName: GLOBAL_COMMANDS.setHeightToWrapContent.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  enter: {
    controlStrings: ["Enter"],
    indicatorCharacters: [],
    displayName: "Enter",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  editText: {
    controlStrings: ["Enter"],
    indicatorCharacters: [],
    displayName: "Edit Text",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    disallowWhenDialogsAndPopoversOpen: true,
  },
  // TODO (Gabe 2024-08-09): Get these out of here, there's no reason to define
  // component specific hotkeys so far away from the components.
  selectAIText: {
    controlStrings: ["t"],
    indicatorCharacters: [],
    displayName: "Select AI Text",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  selectAIMobileResponsive: {
    controlStrings: ["r"],
    indicatorCharacters: [],
    displayName: "Select AI Mobile Responsive",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  alignLeft: {
    controlStrings: ["alt+a"],
    indicatorCharacters: GLOBAL_COMMANDS.alignLeft.hotkey!,
    displayName: GLOBAL_COMMANDS.alignLeft.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  alignRight: {
    controlStrings: ["alt+d"],
    indicatorCharacters: GLOBAL_COMMANDS.alignRight.hotkey!,
    displayName: GLOBAL_COMMANDS.alignRight.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  alignTop: {
    controlStrings: ["alt+w"],
    indicatorCharacters: GLOBAL_COMMANDS.alignTop.hotkey!,
    displayName: GLOBAL_COMMANDS.alignTop.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  alignBottom: {
    controlStrings: ["alt+s"],
    indicatorCharacters: GLOBAL_COMMANDS.alignBottom.hotkey!,
    displayName: GLOBAL_COMMANDS.alignBottom.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  alignHorizontalCenter: {
    controlStrings: ["alt+h"],
    indicatorCharacters: GLOBAL_COMMANDS.alignHorizontalCenter.hotkey!,
    displayName: GLOBAL_COMMANDS.alignHorizontalCenter.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  alignVerticalCenter: {
    controlStrings: ["alt+v"],
    indicatorCharacters: GLOBAL_COMMANDS.alignVerticalCenter.hotkey!,
    displayName: GLOBAL_COMMANDS.alignVerticalCenter.label,
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
};

export function getHotkeyData(action: Hotkey): HotkeyData {
  return hotkeysData[action];
}
