import type { ComponentTemplate } from "@editor/types/component-template";

export const starRatingAndText: ComponentTemplate = {
  id: "1c94988f-1a4b-434e-a594-86ba086e8ff7",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Basic/Stars + Text",
  leftBarCategory: { main: "basic", subCategory: "text" },
  preview: "basic/stars-plus-text.svg",
  tags: ["rating", "review"],
  template: {
    id: "3b60145b-dcec-40a0-bb89-91e37fc92127",
    name: "Star Rating & Text",
    type: "container",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        __flexGap: "10px",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
    children: [
      {
        id: "c4a45211-ca14-46e8-8b9a-82632f8be02d",
        name: "Star Rating 1",
        type: "starRating",
        props: {
          _size: "16px",
          style: {
            display: "flex",
            flexDirection: "row",
          },
          _starColor: "#000000",
          _starCount: 5,
          _totalStars: 5,
        },
      },
      {
        id: "cd4487b5-91f7-4db5-94ac-4f71dd8bb7ea",
        type: "text",
        props: {
          text: "<p>100 Reviews</p>",
          style: {
            fontSize: "16px",
            lineHeight: "24px",
          },
        },
      },
    ],
  },
};
