import type { ReactNode } from "react";
import type { ButtonProps } from "./Button";
import type { ButtonVariant } from "./button-shared";

import * as React from "react";

import { BsChevronDown } from "react-icons/bs";

import twMerge from "../../utils/twMerge";
import Popover from "../popover/Popover";
import Button from "./Button";
import IconButton from "./IconButton";

type SplitButtonProps = {
  isButtonDisabled?: boolean;
  isButtonLoading?: boolean;
  buttonToolTipText?: string;
  onButtonClick: () => void;
  buttonText: ReactNode;
  buttonId: string;
  size: "sm" | "base";
  variant: ButtonVariant;
  popoverProps: {
    isOpen?: boolean;
    onOpenChange?: (isPreviewPopoverOpen: boolean) => void;
    content: ReactNode;
    title?: ReactNode;
    ariaLabel?: string;
    titleClassnames?: string;
    className?: string;
    sideOffset: number;
    skipTriggerStyles?: boolean;
    isDisabled?: boolean;
    tooltipText?: string;
    dataTestId?: string;
    hideCloseButton?: boolean;
  };
};

export default function SplitButton({
  buttonId,
  buttonToolTipText,
  disabled,
  isButtonDisabled,
  isButtonLoading,
  onButtonClick,
  buttonText,
  size,
  variant,
  popoverProps,
  ...buttonProps
}: SplitButtonProps & Omit<ButtonProps, "size">) {
  return (
    <div className="flex flex-row">
      <Button
        {...buttonProps}
        variant={variant}
        size={size}
        tooltipText={buttonToolTipText}
        UNSAFE_className={twMerge(
          `rounded-tr-none rounded-br-none border-r-0.5`,
          variant === "secondary" && "border-border",
        )}
        id={buttonId}
        disabled={disabled || isButtonDisabled}
        isLoading={isButtonLoading}
        onClick={() => {
          if (!isButtonDisabled) {
            onButtonClick();
          }
        }}
      >
        {buttonText}
      </Button>
      <Popover
        isOpen={popoverProps.isOpen}
        onOpenChange={popoverProps.onOpenChange}
        shouldPreventDefaultOnInteractOutside={false}
        content={popoverProps.content}
        hideCloseButton={popoverProps.hideCloseButton}
        side="bottom"
        title={popoverProps.title}
        align="end"
        sideOffset={popoverProps.sideOffset | 16}
        skipTriggerStyles={popoverProps.skipTriggerStyles}
        className={popoverProps.className}
        triggerAsChild
      >
        <IconButton
          variant={variant}
          size={size}
          UNSAFE_className={twMerge(
            `rounded-tl-none rounded-bl-none w-[30px]`,
            variant === "secondary" && "text-muted",
          )}
          tooltipText={popoverProps.tooltipText}
          aria-label={popoverProps.ariaLabel}
          disabled={disabled || popoverProps.isDisabled}
          data-testid={popoverProps.dataTestId}
          icon={<BsChevronDown aria-hidden size={12} />}
          isActive={popoverProps.isOpen}
        />
      </Popover>
    </div>
  );
}
