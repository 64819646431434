import type { IconType } from "react-icons";

import * as React from "react";

import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import twMerge from "@replo/design-system/utils/twMerge";
import {
  BsArrowRight,
  BsBookmarkFill,
  BsChat,
  BsKeyboard,
  BsPersonFillAdd,
  BsSlack,
} from "react-icons/bs";

interface Action {
  title: string;
  icon: IconType;
  iconForeground: string;
  iconBackground: string;
  text: string;
  ctaText?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
}

function SupportModalContent() {
  const modal = useModal();
  const actions: Action[] = [
    {
      title: "Chat with us",
      icon: BsChat,
      iconForeground: "text-blue-600",
      iconBackground: "bg-blue-50",
      text: "We can get back to you in a few minutes over live chat, just look for the chat icon in the lower left.",
      onClick: window?.zE
        ? () => {
            window.zE("messenger", "open");
            modal.closeModal({ type: "supportModal" });
          }
        : undefined,
      ctaText: "Open Chat",
    },
    {
      title: "Find a Replo Expert",
      icon: BsPersonFillAdd,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      text: "Need professional help with your Replo project? Hire an independent Replo Expert or Builder to help.",
      ctaText: "Find an Expert",
      onClick: () => {
        window.open("https://www.replo.app/experts", "_blank");
      },
    },
    {
      title: "Read our docs",
      icon: BsBookmarkFill,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      text: "Check out our docs for all the deets on the what, why and where of Replo.",
      ctaText: "Read the Docs",
      onClick: () => {
        window.open(docs.home, "_blank");
      },
    },
    {
      title: "Join the community",
      icon: BsSlack,
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
      text: "Join the Replo Slack community to hear about the latest and greatest in Replo.",
      ctaText: "Go to Slack",
      onClick: () => {
        window.open("https://replo.app/community", "_blank");
      },
    },
    {
      title: "Hotkeys",
      icon: BsKeyboard,
      iconForeground: "text-green-700",
      iconBackground: "bg-green-50",
      text: "View all available keyboard shortcuts for Replo.",
      ctaText: "View Hotkeys",
      onClick: () => {
        modal.closeModal({ type: "supportModal" });
        modal.openModal({ type: "hotkeysModal" });
      },
    },
  ];
  return (
    <div className="flex flex-col gap-4 overflow-hidden rounded-lg transition-all sm:grid sm:grid-cols-2">
      {actions.map((action: Action) => (
        <div
          key={action.title}
          className={twMerge(
            "group relative flex flex-col gap-2 items-start",
            Boolean(action.onClick) && "cursor-pointer",
          )}
          onClick={action.onClick}
        >
          <span
            className={twMerge(
              action.iconBackground,
              action.iconForeground,
              "inline-flex rounded-lg p-2 ring-4 ring-white",
            )}
          >
            <action.icon className="h-5 w-5" aria-hidden="true" />
          </span>
          <div className="flex flex-col gap-1">
            <h3 className="text-sm font-medium">{action.title}</h3>
            <p className="mt-1 text-xs text-muted">{action.text}</p>
          </div>
          {action.onClick && (
            <Button
              variant="link"
              size="sm"
              endEnhancer={<BsArrowRight size={14} />}
            >
              {action.ctaText}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}

export function SupportModal() {
  const modal = useModal();

  return (
    <Modal
      title="Need some help?"
      description="Here are a few options..."
      isOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          modal.closeModal({ type: "supportModal" });
        }
      }}
      size="lg"
    >
      <SupportModalContent />
    </Modal>
  );
}
