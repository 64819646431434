import type { ComponentTemplate } from "@editor/types/component-template";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const looxReviews: ComponentTemplate = {
  id: "0bfeda61-de5f-4706-8de3-8a7fde72e49f",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Loox Reviews",
  leftBarCategory: { main: "productIntegrations", subCategory: "loox" },
  preview: "integrations/loox-product-reviews.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  template: {
    id: "$uid",
    type: "looxReviews",
    props: {},
  },
};
