import type { ComponentTemplate } from "@editor/types/component-template";

import { getCanBeAddedFunction } from "@editor/utils/template";

const quantity: ComponentTemplate = {
  id: "e0d6cb42-0612-42b0-b358-25415420b95c",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Product Quantity",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "product/quantity.svg",
  template: {
    id: "df63a851-4084-4104-8d39-2fec4d7bb1a9",
    type: "text",
    props: {
      text: "{{attributes._quantity}}",
      style: { fontSize: "20px", lineHeight: "24px" },
    },
  },
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Quantities can only be added inside Product boxes.",
  ),
};

export default quantity;
