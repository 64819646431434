import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "../../components";

import { mapAndConvertComponentStylePropsToStyle } from "../../../shared/utils/breakpoints";
import { convertToLegacyProps } from "../../../shared/utils/props";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _items: {
      type: "dynamicItems",
      name: "Dynamic items",
      description:
        "If set, the carousel will dynamically update its content based on the given items.",
      defaultValue: null,
    },
  };
}

const styleElements = {
  root: {
    defaultStyles: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minWidth: "var(--rmw, auto)",
      minHeight: "var(--rmh, auto)",
    },

    overrideStyles({ styleProps }) {
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        return {
          "--rmw": !styles.__hasWidth ? "200px" : "auto",
          "--rmh": !styles.__hasHeight ? "200px" : "auto",
        };
      });
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
