import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useCreateElement } from "@editor/hooks/element/useCreateElement";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useInstallShopify from "@editor/hooks/useInstallShopify";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import twMerge from "@replo/design-system/utils/twMerge";
import { Controller, useForm, useWatch } from "react-hook-form";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import { ShopifyIntegrationSelectable } from "./ShopifyIntegrationSelectable";

const buttonClassName =
  "border rounded-lg hover:border-blue-600 font-medium grid place-content-center flex-1 py-4 px-8 text-sm";
const selectedButtonClassName = "border-blue-600 bg-blue-50";

type CreateProjectFormData = {
  name: string;
  workspaceId: string;
  shopifyIntegrationId?: string | null;
  shouldConnectShopifyStore: boolean;
};

const NEW_SHOPIFY_INTEGRATION_ID = "NEW_STORE_INTEGRATION";

const NewProjectFlow: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  return <NewProjectForm initialWorkspaceId={workspaceId ?? undefined} />;
};

function NewProjectForm({
  initialWorkspaceId,
}: {
  initialWorkspaceId: string | undefined;
}) {
  const location = useLocation();
  const { from } = location.state || {};
  const { user } = useCurrentUser();
  const { register, handleSubmit, control, formState } =
    useForm<CreateProjectFormData>({
      defaultValues: {
        name: "",
        workspaceId: initialWorkspaceId,
        shouldConnectShopifyStore: true,
        shopifyIntegrationId: null,
      },
      mode: "onBlur",
    });

  const newProjectPayload = useWatch({ control });

  const installShopify = useInstallShopify();

  const analytics = useLogAnalytics();
  const { handleCreateElement } = useCreateElement();
  const { mutate: createProject, isPending: isCreatingProject } =
    trpc.project.create.useMutation({
      onSuccess: async ({ project: newProject }) => {
        analytics("project.create", {
          withShopify: Boolean(newProjectPayload.shouldConnectShopifyStore),
          createdFrom: from === "allProjects" ? "allProjects" : "orgPage",
        });
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        void trpcUtils.project.findByUserId.invalidate();
        void trpcUtils.project.listWithStats.invalidate();
        if (!newProject) {
          return;
        }
        if (
          newProjectPayload.shouldConnectShopifyStore &&
          newProjectPayload.shopifyIntegrationId === NEW_SHOPIFY_INTEGRATION_ID
        ) {
          await installShopify({
            cookie: {
              type: "newProject",
              projectId: newProject.id,
              workspaceId: newProject.ownerWorkspaceId,
            },
            workspaceId: newProject.ownerWorkspaceId,
          });
        } else {
          // Note (Ben O., 2025-02-20): This will automatically navigate to the new element in
          // in the new project after element creation. if it doesn't we will just open the project
          const newElement = await handleCreateElement({
            type: "page",
            projectId: newProject.id,
            leftBarActiveTab: null,
            withAiFlow: true,
          });

          if (!newElement) {
            navigate(
              generatePath(routes.editor.project, {
                projectId: newProject.id,
              }),
            );
          }
        }
      },
    });
  const navigate = useNavigate();

  const shouldShowShopifyStoreSelector =
    newProjectPayload.shouldConnectShopifyStore;

  const currentWorkspaceId = useCurrentWorkspaceId();

  const onClose = () => {
    if (from === "allProjects") {
      navigate(routes.allProjects);
    } else if (currentWorkspaceId) {
      navigate(
        generatePath(routes.workspace.projects, {
          workspaceId: currentWorkspaceId,
        }),
      );
    } else {
      navigate(routes.home.root);
    }
  };

  function onSubmit({
    name,
    workspaceId,
    shouldConnectShopifyStore,
    shopifyIntegrationId,
  }: CreateProjectFormData) {
    const isNewStore = shopifyIntegrationId == NEW_SHOPIFY_INTEGRATION_ID;
    createProject({
      name: name ?? `${user?.name}'s project`,
      // TODO (Ben, 2024-10-09): Figure out what to do
      // with "NEW_WORKSPACE_ID": we were adding null for the
      // workspaceId if it was equal to "NEW_WORKSPACE_ID".
      workspaceId,
      shopifyIntegrationId:
        shouldConnectShopifyStore && !isNewStore ? shopifyIntegrationId : null,
    });
  }

  return (
    <form
      onSubmit={(values) => {
        void handleSubmit(onSubmit)(values);
      }}
    >
      <Modal
        isOpen={true}
        onOpenChange={onClose}
        size="base"
        title="New Replo Project"
        footer={
          <Button
            type="submit"
            variant="primary"
            size="base"
            disabled={!formState.isValid || isCreatingProject}
            isLoading={isCreatingProject}
          >
            {isCreatingProject ? "Creating" : "Create"} Project
          </Button>
        }
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h2 className="text-sm font-medium text-default">
              Do you want to connect a Shopify Store?
            </h2>
            <div role="group" className="inline-grid grid-cols-2 gap-3">
              <Controller
                control={control}
                name="shouldConnectShopifyStore"
                render={({ field: { onChange, value } }) => (
                  <button
                    type="button"
                    className={twMerge(
                      buttonClassName,
                      value && selectedButtonClassName,
                    )}
                    onClick={() => {
                      onChange(true);
                    }}
                    aria-pressed={value}
                  >
                    Connect Shopify store
                  </button>
                )}
              />
              <Controller
                control={control}
                name="shouldConnectShopifyStore"
                render={({ field: { onChange, value } }) => (
                  <button
                    type="button"
                    className={twMerge(
                      buttonClassName,
                      !value && selectedButtonClassName,
                    )}
                    onClick={() => {
                      onChange(false);
                    }}
                    aria-pressed={!value}
                  >
                    <p>
                      Create project without <br /> Shopify store
                    </p>
                    <p className="text-muted text-xs font-normal whitespace-nowrap">
                      (You can connect a store later)
                    </p>
                  </button>
                )}
              />
            </div>
          </div>
          <LabeledControl label="Project Name">
            <Input
              autoFocus
              placeholder="Project Name"
              {...register("name", {
                required: "Please enter a project name.",
              })}
              autoComplete="off"
              size="base"
            />
          </LabeledControl>
          <LabeledControl label="Workspace">
            <Controller
              render={({ field: { onChange, value } }) => (
                <WorkspaceSelectable value={value} onChange={onChange} />
              )}
              control={control}
              name="workspaceId"
            />
          </LabeledControl>
          {shouldShowShopifyStoreSelector && (
            <LabeledControl label="Shopify Integration">
              <Controller
                render={({ field: { value, onChange } }) => (
                  <ShopifyIntegrationSelectable
                    workspaceId={newProjectPayload.workspaceId}
                    selectedIntegrationId={value}
                    handleChangeSelection={onChange}
                  />
                )}
                name="shopifyIntegrationId"
                control={control}
              />
            </LabeledControl>
          )}
        </div>
      </Modal>
    </form>
  );
}

export function WorkspaceSelectable({
  value,
  onChange,
}: {
  value: string | null;
  onChange: (value: string) => void;
}) {
  const { data } = trpc.workspace.getUserWorkspacesList.useQuery();
  const workspaces = data?.workspaces ?? [];
  return (
    <Selectable
      size="base"
      placeholder="Choose Workspace"
      options={workspaces.map((workspace) => ({
        label: workspace.name,
        value: workspace.id,
      }))}
      value={value}
      onSelect={onChange}
    />
  );
}

export default NewProjectFlow;
