import type { ReploPartialElement } from "schemas/generated/element";

import {
  createElement as createElementReduxAction,
  setElementsLoading,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch } from "@editor/store";
import { trpc, trpcUtils } from "@editor/utils/trpc";

export const useCreateElementMutation = () => {
  const dispatch = useEditorDispatch();
  const { mutateAsync: createElementMutation, isPending: isLoading } =
    trpc.element.create.useMutation({
      onMutate: () => {
        dispatch(setElementsLoading(true));
      },
      onSuccess: ({ element }) => {
        if (element) {
          dispatch(createElementReduxAction({ element }));
          void trpcUtils.project.listWithStats.invalidate();
          void trpcUtils.element.listAllElementsMetadata.invalidate();
        }
      },
      onSettled: () => {
        dispatch(setElementsLoading(false));
      },
    });

  const createElement = async (element: ReploPartialElement) => {
    return await createElementMutation({ element });
  };

  return { createElement, isLoading };
};
