import * as React from "react";

import styles from "./AITemplateLoadingState.module.css";

interface AITemplateLoadingStateProps {
  shouldShow: boolean;
}

export const AITemplateLoadingState: React.FC<AITemplateLoadingStateProps> = ({
  shouldShow,
}) => {
  // State to control visibility and fade-out
  const [isVisible, setIsVisible] = React.useState(shouldShow);
  const [isFadingOut, setIsFadingOut] = React.useState(false);

  // Use a ref to ensure we can force immediate rendering
  const containerRef = React.useRef<HTMLDivElement>(null);

  // Handle changes to shouldShow prop
  React.useEffect(() => {
    if (shouldShow) {
      // Show immediately when needed
      setIsFadingOut(false);
      setIsVisible(true);

      // Force immediate visibility via DOM manipulation
      if (containerRef.current) {
        containerRef.current.style.opacity = "1";
        containerRef.current.style.transition = "none";

        // Reset transition after a frame to allow fade-out to work later
        requestAnimationFrame(() => {
          if (containerRef.current) {
            containerRef.current.style.transition = "";
          }
        });
      }
    } else if (isVisible) {
      // Start fade-out animation
      setIsFadingOut(true);

      // After animation completes, hide the component
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 500); // Match this with the CSS transition duration

      return () => clearTimeout(timeout);
    }
  }, [shouldShow, isVisible]);

  // Don't render anything if not visible
  if (!isVisible && !shouldShow) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={`flex justify-center w-full h-full absolute z-10 bg-white pointer-events-none ${styles.container} ${
        isFadingOut ? styles.fadeOut : styles.visible
      }`}
    >
      <img
        src="/images/templates/ai-loader.svg"
        alt="AI Template Loading"
        className={`w-[450px] h-[450px] mt-[400px] ${styles.spinningLoader}`}
      />
    </div>
  );
};
