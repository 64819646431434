import type { MetricName, RangeResult } from "schemas/generated/analyticsRead";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import { SortButton } from "@editor/components/analytics/SortIndicator";
import { TableLoadingRows } from "@editor/components/analytics/TableLoadingRows";
import { TablePageCellContent } from "@editor/components/analytics/TablePageCellContent";
import TablePagination from "@editor/components/common/designSystem/TablePagination";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { calculateDelta } from "@editor/utils/analytics";

import AnalyticsFilters from "@/features/analytics/AnalyticsFilters";
import {
  OVERVIEW_TABLE_METRICS,
  RESULTS_PER_PAGE,
} from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { NoData } from "@/features/analytics/NoData";
import useBasicAnalyticsRead from "@/features/analytics/useBasicAnalyticsRead";
import { successToast } from "@replo/design-system/components/alert/Toast";
import EmptyState from "@replo/design-system/components/empty-state/EmptyState";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import { useSearchParams } from "react-router-dom";
import { useInterval } from "replo-utils/react/use-interval";
import { ConditionOperatorEnum } from "schemas/generated/analyticsRead";

const FIRST_TIME_ON_ANALYTICS_PARAM = "firstTimeOnAnalytics";
const PAGE_NAME_COLUMN_WIDTH_CLASSNAME = "w-[300px]";

const AllMetricsRow: React.FC<{
  urlPath: string;
  mainRangeResultMetrics: RangeResult["metrics"];
  compareAtRangeResultMetrics: RangeResult["metrics"];
  metadata: RangeResult["metadata"];
  onPageTitleClick: (urlPath: string) => void;
}> = ({
  urlPath,
  mainRangeResultMetrics,
  compareAtRangeResultMetrics,
  metadata,
  onPageTitleClick,
}) => {
  const analytics = useLogAnalytics();

  const handleUrlPathClick = () => {
    analytics("analytics.url.open", {
      source: "analytics_overview",
    });
  };

  const { title, url } = metadata;

  const compareAtRangeViewsArray = compareAtRangeResultMetrics.views ?? [];
  const doesCompareValueExist = (compareAtRangeViewsArray[0] ?? 0) > 0;

  return (
    <TableRow key={url}>
      <TableCell className="flex justify-left items-center">
        <div className={PAGE_NAME_COLUMN_WIDTH_CLASSNAME}>
          <TablePageCellContent
            title={title}
            url={url}
            urlPath={urlPath}
            onPageTitleClick={onPageTitleClick}
            onUrlPathClick={handleUrlPathClick}
          />
        </div>
      </TableCell>
      {OVERVIEW_TABLE_METRICS.map((metric) => {
        const mainRangeResultCurrentMetricArray =
          mainRangeResultMetrics[metric.key as MetricName] ?? [];
        const compareAtRangeResultCurrentMetricArray =
          compareAtRangeResultMetrics[metric.key as MetricName] ?? [];

        return (
          <TableCell key={metric.key}>
            <MetricWithDelta
              name={metric.key}
              value={mainRangeResultCurrentMetricArray[0] ?? 0}
              delta={calculateDelta(
                mainRangeResultCurrentMetricArray[0],
                compareAtRangeResultCurrentMetricArray[0],
              )}
              doesCompareValueExist={doesCompareValueExist}
              wrapperClassName="justify-start flex-grow"
              valueClassName="text-xs"
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const AllMetricsRows: React.FC<{
  mainRangeResults: RangeResult[];
  compareAtRangeResults: RangeResult[];
  onPageTitleClick: (urlPath: string) => void;
}> = ({ mainRangeResults, compareAtRangeResults, onPageTitleClick }) => {
  return (
    <>
      {mainRangeResults.map((mainRangeResult) => {
        const {
          urlPath,
          metadata,
          metrics: mainRangeResultMetrics,
        } = mainRangeResult;

        const compareAtRangeResult = compareAtRangeResults.find(
          (rangeResult) => rangeResult.urlPath === urlPath,
        );

        const compareAtRangeResultMetrics = compareAtRangeResult?.metrics ?? {};

        return (
          <AllMetricsRow
            key={metadata.url}
            urlPath={urlPath}
            mainRangeResultMetrics={mainRangeResultMetrics}
            compareAtRangeResultMetrics={compareAtRangeResultMetrics}
            metadata={metadata}
            onPageTitleClick={onPageTitleClick}
          />
        );
      })}
    </>
  );
};

export const AllMetricsTable: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId() ?? "";

  const { query, dispatchAnalyticsQuery, resetInternalAndUrlSyncedQueries } =
    useAnalyticsQueryContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  React.useEffect(() => {
    dispatchAnalyticsQuery({
      type: "initOverviewPage",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchTerm = React.useMemo(() => {
    const searchTerm =
      query.filters.urlPath.find(
        (filterCondition) =>
          filterCondition.operator === ConditionOperatorEnum.CONTAINS,
      )?.value ?? "";

    return searchTerm;
  }, [query.filters.urlPath]);

  const handleSearchChange = (value: string) => {
    dispatchAnalyticsQuery({
      type: "filters.updateUrlPathSearch",
      payload: value,
    });
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const firstTimeOnAnalytics =
    searchParams.get(FIRST_TIME_ON_ANALYTICS_PARAM) !== null;

  const {
    hasAnyData,
    mainRangeResults,
    compareAtRangeResults,
    totalRowsCount,
    isLoading,
    refetch: refetchAnalyticsQuery,
  } = useBasicAnalyticsRead({
    workspaceId,
    type: "withUrlSyncQuery",
    queryConfig: {
      useChartInterval: false,
    },
  });

  const loadedButNoDataForWorkspace = !isLoading && !hasAnyData;

  const queryHasNoData =
    !isLoading &&
    (totalRowsCount === 0 || !totalRowsCount || !mainRangeResults);

  /**
   * NOTE Ben 2024-11-01: When we first load analytics after an install, there will be no data for
   * at least a few seconds. In this case, we want to do refetch on the analytics data every
   * 10 seconds. If we've loaded before though, or there's just no query data, we don't want to
   * do this, in which case we pass `null` to the `useInterval` which makes it skip.
   */
  useInterval(
    () => {
      void refetchAnalyticsQuery();
    },
    loadedButNoDataForWorkspace ? 10_000 : null,
  );

  // NOTE (Max, 2024-08-26): pageNumber is the value we show to the users. It starts
  // from 1. However, when querying the backend, we'll need to subtract 1, as
  // ClickHouse's OFFSET starts at 0
  const [currentPageNumber, setCurrentPageNumber] = React.useState<number>(1);

  const resultsPerPage = RESULTS_PER_PAGE;
  // TODO (Max, 2024-08-27): Uncomment once we allow user to specify how many
  // pages per result they want

  // const [resultsPerPage, setResultsPerPage] =
  //   React.useState<number>(RESULTS_PER_PAGE);

  const handleOnPageNumberChange = (clickedPageNumber: number) => {
    if (clickedPageNumber !== currentPageNumber) {
      setCurrentPageNumber(clickedPageNumber);

      const offset = (clickedPageNumber - 1) * resultsPerPage;
      dispatchAnalyticsQuery({
        type: "updatePagination",
        payload: offset,
      });
    }
  };

  const handleMetricSortClick = (sortMetric: MetricName) => {
    resetPagination();
    dispatchAnalyticsQuery({
      type: "sortMetric",
      payload: sortMetric,
    });
  };

  const resetAllFilters = () => {
    resetInternalAndUrlSyncedQueries();
    resetPagination();
  };

  const resetPagination = () => {
    setCurrentPageNumber(1);
  };

  /**
   * When clicking on the page, we generate a new path to the deep-dive page
   * with the update urlPathFilters to be EQUAL to whatever page's urlPath just
   * got clicked.
   *
   * We don't need to updateUrlSyncedQuery() here, as we're redirectig to the path
   * which contains these filters. Then, in AnalyticsContext.tsx, the urlSyncedQuery
   * will be udpated anyways, as it gets updated whenever searchParams change (which it
   * will, as we're generating/redirecting to a new path here).
   *
   * @author Max 2024-12-16
   */
  const handlePageTitleClick = (urlPath: string) => {
    dispatchAnalyticsQuery({
      type: "openDeepDivePageFromOverviewPage",
      payload: urlPath,
    });
  };

  React.useEffect(() => {
    if (firstTimeOnAnalytics) {
      successToast(
        "Analytics Connected",
        "Your store has been connected and tracking has started!",
      );
      setSearchParams(
        (params) => {
          params.delete(FIRST_TIME_ON_ANALYTICS_PARAM);
          return new URLSearchParams(params);
        },
        { replace: true },
      );
    }
  }, [firstTimeOnAnalytics, setSearchParams]);

  const DisplayTableContent = () => {
    if (isLoading) {
      return (
        <TableBody>
          <TableLoadingRows
            rows={5}
            columns={OVERVIEW_TABLE_METRICS.length + 1}
          />
        </TableBody>
      );
    } else if (queryHasNoData) {
      return (
        <TableBody>
          <TableRow className="border-b-0">
            <TableCell
              colSpan={OVERVIEW_TABLE_METRICS.length + 1}
              className="h-[300px] w-full"
            >
              <div className="flex justify-center items-end h-full w-full">
                {loadedButNoDataForWorkspace ? (
                  <EmptyState
                    size="base"
                    variant="pinging"
                    title="Gathering data..."
                    description="As visitors start exploring your store, you’ll see real-time data populate this dashboard. Check back soon!"
                  />
                ) : (
                  <NoData resetAll={resetAllFilters} />
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    } else {
      return (
        <TableBody>
          <AllMetricsRows
            mainRangeResults={mainRangeResults}
            compareAtRangeResults={compareAtRangeResults}
            onPageTitleClick={handlePageTitleClick}
          />
        </TableBody>
      );
    }
  };

  return (
    <div className="relative min-w-screen">
      <div className="flex w-full flex-col space-y-[8px]">
        <AnalyticsFilters
          resetPagination={resetPagination}
          search={{
            value: searchTerm,
            onChange: handleSearchChange,
          }}
        />
        <Table>
          <TableHeader className="bg-subtle font-medium text-xs text-default">
            <TableRow>
              <TableHead className={PAGE_NAME_COLUMN_WIDTH_CLASSNAME}>
                Page Name
              </TableHead>
              {OVERVIEW_TABLE_METRICS.map((metric) => (
                <TableHead key={metric.key}>
                  <div className="flex items-center gap-2">
                    <span>{metric.label}</span>
                    {/* TODO (Max, 2024-10-09): Once Tooltip gets moved into packages/design-system, add
                    this SortButton as a prop of the shadcn's tableHead component, so we can re-use it
                    for other tables. For now, we'll have to duplicate the Sort logic
                     */}
                    <SortButton
                      sortMetric={metric.key}
                      sortOrder={query.order}
                      isActiveMetric={query.sortMetric === metric.key}
                      onChange={() => handleMetricSortClick(metric.key)}
                    />
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          {DisplayTableContent()}
        </Table>
      </div>
      {totalRowsCount && totalRowsCount > 0 ? (
        <div className="grid grid-cols-2 mt-5 mb-4">
          <div className="col-start-2">
            <TablePagination
              currentPageNumber={currentPageNumber}
              totalRowsCount={totalRowsCount}
              resultsPerPage={resultsPerPage}
              handleOnPageNumberChange={handleOnPageNumberChange}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
