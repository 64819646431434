import type { ComponentTemplate } from "@editor/types/component-template";

export const carouselSlides: ComponentTemplate = {
  id: "babd295f-98ce-44b6-87d0-0cf4c28b89d7",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Carousel/Carousel Slides",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/carousel-slides.svg",
  template: {
    id: "8eae208a-d5c6-4561-85da-ec2e2455a459",
    name: "Carousel Slides",
    type: "carouselV3Slides",
    props: {
      style: {
        width: "auto",
        height: null,
        __width: "1197px",
        flexGrow: 1,
        overflow: "hidden",
        alignSelf: "stretch",
        flexBasis: "auto",
      },
    },
    children: [
      {
        id: "bbd6560e-c9ca-49db-bbcb-2d34c790b68f",
        name: "Slide 1",
        type: "container",
        props: {
          style: {
            __width: "auto",
            display: "flex",
            flexGrow: "1",
            __flexGap: "12px",
            flexBasis: "auto",
            alignItems: "center",
            paddingTop: "24px",
            paddingBottom: "24px",
            justifyContent: "center",
          },
        },
        children: [
          {
            id: "250f7e7c-6599-411e-9efc-a4f0f46a52bf",
            name: "Image 12",
            type: "image",
            props: {
              src: "",
              style: {
                flexGrow: "unset",
                flexBasis: "auto",
                objectFit: "cover",
              },
            },
          },
          {
            id: "7746821e-a4b9-417b-84a6-529c95c2adde",
            type: "text",
            props: {
              text: "<p>Slide 1 of 2</p>",
              style: {
                flexGrow: "unset",
                fontSize: "20px",
                flexBasis: "auto",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
      {
        id: "b23e65f0-022a-49c9-ae4f-7f227fb14805",
        name: "Slide 2",
        type: "container",
        props: {
          style: {
            __width: "auto",
            display: "flex",
            flexGrow: "1",
            __flexGap: "12px",
            flexBasis: "auto",
            alignItems: "center",
            paddingTop: "24px",
            paddingBottom: "24px",
            justifyContent: "center",
          },
        },
        children: [
          {
            id: "cfc57b06-f3d6-4f6c-9a6c-3256ea11f63e",
            name: "Image 12",
            type: "image",
            props: {
              src: "",
              style: { flexBasis: "auto", objectFit: "cover" },
            },
          },
          {
            id: "d72c7bde-7b14-47eb-95aa-8283827b4c13",
            type: "text",
            props: {
              text: "<p>Slide 2 of 2</p>",
              style: {
                flexGrow: "unset",
                fontSize: "20px",
                flexBasis: "auto",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
    ],
  },
};
