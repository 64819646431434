import * as React from "react";

import startCase from "lodash-es/startCase";

import {
  selectSrcDocFonts,
  selectSrcDocFontUrls,
} from "../../reducers/core-reducer";
import { useEditorSelector } from "../../store";

// NOTE (Jackson, 2025-02-21): If a @font-face declaration has a relative path, we want to
// skip appending the @font-face declaration directly and instead fall back using the font URL,
// as the relative path will not resolve correctly in a @font-face declaration.
// regex: find "url('./{...})"
const RELATIVE_FONT_PATH_REGEX = /url\(["']?\.\/([^"')]+)["']?\)/g;

const hasRelativeFontPath = (declaration: string): boolean => {
  return RELATIVE_FONT_PATH_REGEX.test(declaration);
};

const getFontFamilyNameFromSrcUrl = (url: string): string => {
  const patterns = {
    files: /\/files\/([^.]+)/,
    fonts: /\/fonts\/([^/]+)\//,
    assets: /\/assets\/([^.]+)/,
  };

  for (const pattern of Object.values(patterns)) {
    const match = url.match(pattern)?.[1];
    if (match) {
      return startCase(match.replace(/_/g, " "));
    }
  }

  return "ShopifyFont";
};

const getFontType = (url: string): string | null => {
  if (url.toLowerCase().match(/\.woff2($|\?|#)/)) {
    return "woff2";
  } else if (url.toLowerCase().match(/\.woff($|\?|#)/)) {
    return "woff";
  } else if (url.toLowerCase().match(/\.ttf($|\?|#)/)) {
    return "truetype";
  } else if (url.toLowerCase().match(/\.otf($|\?|#)/)) {
    return "opentype";
  }
  return null;
};

// NOTE (Jackson, 2024-12-18): This component is used to load fonts into the editor based
// on mirror response data. We'll attempt to get the proper font family name from the URL and then
// create a style element to load the font.
// NOTE (Jackson, 2025-02-04): Updated to a two step process. First, we check for fonts
// from stylesheets parsed in mirror. This is more reliable than attempting to determine
// font-family names from URLs. If we're unable to find a font-face declaration for a family,
// then we fall back to parsing the family name from the URL and loading that instead.

export function SrcDocFontLoader() {
  const fontUrls = useEditorSelector(selectSrcDocFontUrls);
  const srcDocFonts = useEditorSelector(selectSrcDocFonts);

  React.useEffect(() => {
    // Reset font elements
    const existingFontElements = document.head.querySelectorAll(
      "[data-src-doc-font]",
    );
    existingFontElements.forEach((element) => element.remove());
    const familiesWithRelativePaths = new Set<string>();

    // 1. Append any font-face declarations we found from stylesheets to the <head>
    // of the editor, except those with relative paths
    srcDocFonts.forEach(({ fontFaceDeclarations, family }) => {
      if (fontFaceDeclarations.length > 0) {
        let hasRelativePathInFamily = false;

        for (const declaration of fontFaceDeclarations) {
          if (hasRelativeFontPath(declaration)) {
            hasRelativePathInFamily = true;
          }
        }

        if (hasRelativePathInFamily) {
          familiesWithRelativePaths.add(family);
        } else {
          const styleElement = document.createElement("style");
          styleElement.dataset.srcDocFont = "true";
          styleElement.textContent = fontFaceDeclarations.join("\n");
          document.head.append(styleElement);
        }
      }
    });

    // 2. Handle any remaining font URLs that don't have declarations
    for (const url of fontUrls) {
      try {
        const fontFamily = decodeURIComponent(getFontFamilyNameFromSrcUrl(url));

        // Skip if we already have declarations for this font family from step 1
        const hasExistingDeclarations = srcDocFonts.some(
          (font) =>
            font.family === fontFamily && font.fontFaceDeclarations.length > 0,
        );

        if (
          !hasExistingDeclarations ||
          familiesWithRelativePaths.has(fontFamily)
        ) {
          const fontType = getFontType(url);
          if (!fontType) {
            console.warn("Unsupported font format:", url);
            continue;
          }

          // Create preload link
          const linkElement = document.createElement("link");
          linkElement.dataset.srcDocFont = "true";
          linkElement.rel = "preload";
          linkElement.href = url;
          linkElement.as = "font";
          linkElement.type = `font/${fontType}`;
          linkElement.crossOrigin = "anonymous";
          document.head.append(linkElement);

          // Create @font-face
          const styleElement = document.createElement("style");
          styleElement.dataset.srcDocFont = "true";
          styleElement.textContent = `
            @font-face {
              font-family: ${fontFamily};
              src: url('${url}') format('${fontType}');
            }
          `;
          document.head.append(styleElement);
        }
      } catch (error) {
        console.error("Failed to load font:", error, "URL:", url);
      }
    }

    // Cleanup on unmount
    return () => {
      const elements = document.head.querySelectorAll("[data-src-doc-font]");
      elements.forEach((element) => element.remove());
    };
  }, [fontUrls, srcDocFonts]);

  return null;
}
