import * as React from "react";

import coldbruLogo from "@editor/components/dashboard/partner/partner-program-v2-lp-components/logos/coldbru-logo.svg";
import nessa from "@editor/components/dashboard/partner/partner-program-v2-lp-components/logos/nessa-logo.svg";
import paulSt from "@editor/components/dashboard/partner/partner-program-v2-lp-components/logos/paul-st-logo.svg";
import snow from "@editor/components/dashboard/partner/partner-program-v2-lp-components/logos/snow.svg";
import vaynerCommerce from "@editor/components/dashboard/partner/partner-program-v2-lp-components/logos/vaynermedia-logo.svg";

import twMerge from "@replo/design-system/utils/twMerge";

interface LogoCloudProps {
  className?: string;
}

export const LogoCloud = ({ className }: LogoCloudProps) => {
  return (
    <div className="my-6 md:my-16">
      <p className="flex justify-center py-4 text-center text-2xl font-semibold leading-loose lg:text-4xl">
        Teams That Partner with Replo
      </p>
      <div className={twMerge("no-scrollbar overflow-y-hidden", className)}>
        <div className="mt-5 flex w-full min-w-[600px] items-center justify-center gap-10 overflow-x-scroll px-5 md:gap-28 md:overflow-x-hidden">
          <img
            src={coldbruLogo}
            alt="Cold Bru brand logo"
            className="md:w-30 h-28 w-24 grayscale"
            style={{ objectFit: "contain" }}
          />
          <img
            src={paulSt}
            alt="Paul St. brand logo"
            className="md:w-30 h-28 w-24 grayscale"
            style={{ objectFit: "contain" }}
          />
          <img
            src={vaynerCommerce}
            alt="Vayner Commerce brand logo"
            className="md:w-30 h-28 w-24 grayscale"
            style={{ objectFit: "contain" }}
          />
          <img
            src={snow}
            alt="Snow brand logo"
            className="md:w-30 h-28 w-24 grayscale"
            style={{ objectFit: "contain" }}
          />
          <img
            src={nessa}
            alt="Nessa brand logo"
            className="md:w-30 h-28 w-24 grayscale"
            style={{ objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  );
};
