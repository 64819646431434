import type { RemoveMemberModalProps } from "../AppModalTypes";

import * as React from "react";

import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { useNavigate } from "react-router-dom";

export const RemoveMemberModal = ({
  workspaceId,
  user,
}: RemoveMemberModalProps) => {
  const { user: currentUser } = useCurrentUser();

  const { closeModal } = useModal();
  const logEvent = useLogAnalytics();
  const navigate = useNavigate();

  const { mutate: removeWorkspaceMembership, isPending: isLoading } =
    trpc.workspace.removeMember.useMutation({
      onError: () => {
        closeModal({ type: "removeMemberModal" });
      },
      onSuccess: () => {
        void trpcUtils.workspace.getWorkspaceAndProjectMembers.invalidate();
        void trpcUtils.project.membership.list.invalidate();
        successToast("User Removed", "The user was removed successfully.");
        logEvent("workspace.member.removed", {
          workspaceId,
          memberRemoved: user.email,
          removedBy: currentUser?.email,
        });

        closeModal({ type: "removeMemberModal" });

        if (currentUser?.id === user.id) {
          navigate("/home");
        }
      },
    });

  return (
    <Modal
      isOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          closeModal({ type: "removeMemberModal" });
        }
      }}
      title="Remove Member"
      size="base"
      footer={
        <div className="flex flex-row gap-2 justify-end">
          <Button
            variant="secondary"
            size="base"
            onClick={() => closeModal({ type: "removeMemberModal" })}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            size="base"
            onClick={() =>
              void removeWorkspaceMembership({
                userId: user.id,
                workspaceId,
              })
            }
            isLoading={isLoading}
          >
            Remove
          </Button>
        </div>
      }
    >
      <p className="typ-body-base text-default">
        {user.firstName
          ? `Are you sure you want to remove ${user.firstName} (${user.email}) from this workspace?`
          : "Are you sure you want to remove the user from this workspace?"}
      </p>
    </Modal>
  );
};
