import type {
  ElementGenericMetafield,
  ElementMetafieldType,
  StringMetafield,
} from "replo-runtime/shared/types";

import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import ImageSourceSelector from "@editor/components/editor/page/element-editor/components/modifiers/ImageSourceSelector";

import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";

import { ElementEditorHeading } from "./ElementEditorHeading";
import SEOVisibilityToggle from "./SEOVisibilityToggle";

const SeoElementEditor: React.FC<{
  isPageSettings: boolean;
  metafields: ElementGenericMetafield[];
  onMetafieldChange(
    metafieldName: string,
    value: string | number,
    namespace: string,
    type: ElementMetafieldType,
  ): void;
}> = ({ isPageSettings, onMetafieldChange, metafields }) => {
  const handleSeoTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onMetafieldChange("title_tag", value, "global", "string");
  };

  const handleSeoDescriptionChange = useDebouncedCallback((value: string) => {
    onMetafieldChange("description_tag", value, "global", "string");
  }, 300);

  const handleFeaturedImageChange = (value: string) => {
    onMetafieldChange("page_featured_image", value, "replo", "string");
  };

  const titleTag = (
    metafields?.find((field) => field.key === "title_tag") as
      | StringMetafield
      | undefined
  )?.value;
  const descriptionTag = (
    metafields?.find((field) => field.key === "description_tag") as
      | StringMetafield
      | undefined
  )?.value;
  const featuredImage = (
    metafields?.find((field) => field.key === "page_featured_image") as
      | StringMetafield
      | undefined
  )?.value;

  return (
    <div className="flex flex-col gap-y-2">
      <ElementEditorHeading className="text-sm">
        SEO Settings
      </ElementEditorHeading>
      <LabeledControl label="Page Title" id="page-data-title" size="sm">
        <Input
          id="page-data-title"
          autoFocus={false}
          value={titleTag}
          placeholder={titleTag}
          size="sm"
          onChange={handleSeoTitleChange}
        />
      </LabeledControl>

      <LabeledControl label="Description" id="page-data-description" size="sm">
        <Textarea
          size="sm"
          layoutClassName="w-full flex-1"
          value={descriptionTag}
          id="page-data-description"
          onChange={handleSeoDescriptionChange}
        />
      </LabeledControl>

      {isPageSettings && (
        <LabeledControl
          label="Featured Image"
          id="page-data-featured-image"
          size="sm"
        >
          <ImageSourceSelector
            size="sm"
            src={featuredImage}
            onChangeImageSource={handleFeaturedImageChange}
            onRemove={() => handleFeaturedImageChange("")}
            allowsDynamicData={false}
          />
        </LabeledControl>
      )}
      <SEOVisibilityToggle
        metafields={metafields}
        onMetafieldChange={onMetafieldChange}
      />
    </div>
  );
};

export default SeoElementEditor;
