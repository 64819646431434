import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

interface FixedWidthProps extends SvgProps {
  isActive: boolean;
}

const FixedWidth = ({ isActive, ...props }: FixedWidthProps) => {
  if (isActive) {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 113 48"
        width="90"
        {...props}
      >
        <rect x="8" y="21" width="12" height="12" rx="6" fill="#BFDBFE" />
        <path
          d="M13.6486 27.0002L15.1335 25.5153L14.7093 25.091L12.8001 27.0002L14.7093 28.9094L15.1335 28.4851L13.6486 27.0002Z"
          fill="#1E40AF"
        />
        <rect x="22" y="9" width="69" height="36" rx="4" fill="#2563EB" />
        <path
          d="M57.4854 23.7273V31H56.6048V24.6506H56.5621L54.7866 25.8295V24.9347L56.6048 23.7273H57.4854Z"
          fill="#60A5FA"
        />
        <rect
          width="12"
          height="12"
          rx="6"
          transform="matrix(-1 0 0 1 105 21)"
          fill="#BFDBFE"
        />
        <path
          d="M99.3514 27.0002L97.8665 25.5153L98.2907 25.091L100.2 27.0002L98.2907 28.9094L97.8665 28.4851L99.3514 27.0002Z"
          fill="#1E40AF"
        />
      </Svg>
    );
  }
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 113 48"
      width="90"
      {...props}
    >
      <rect x="8" y="21" width="12" height="12" rx="6" fill="#E2E8F0" />
      <path
        d="M13.6486 27.0002L15.1335 25.5153L14.7093 25.091L12.8001 27.0002L14.7093 28.9094L15.1335 28.4851L13.6486 27.0002Z"
        fill="#94A3B8"
      />
      <rect x="22" y="9" width="69" height="36" rx="4" fill="#E2E8F0" />
      <path
        d="M57.4854 23.7273V31H56.6048V24.6506H56.5621L54.7866 25.8295V24.9347L56.6048 23.7273H57.4854Z"
        fill="#94A3B8"
      />
      <rect
        width="12"
        height="12"
        rx="6"
        transform="matrix(-1 0 0 1 105 21)"
        fill="#E2E8F0"
      />
      <path
        d="M99.3514 27.0002L97.8665 25.5153L98.2907 25.091L100.2 27.0002L98.2907 28.9094L97.8665 28.4851L99.3514 27.0002Z"
        fill="#94A3B8"
      />
    </Svg>
  );
};
export default FixedWidth;
