import type {
  DesignLibrarySavedStyleMetadata,
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import { selectComponent } from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";

import { findSavedStylesInComponent } from "replo-runtime/shared/utils/designLibrary";

import useShopStyles from "./useGetDesignLibrarySavedStyles";

function useGetDesignLibraryComponentReferences() {
  const {
    textShopStyles: textSavedStyles,
    colorShopStyles: colorSavedStyles,
    designLibrary,
  } = useShopStyles();
  const store = useEditorStore();

  if (!designLibrary) {
    return {
      getDesignLibraryComponentReferences: () => null,
    };
  }

  const getDesignLibraryComponentReferences = (
    componentIds: string[],
  ): DesignLibrarySavedStyleMetadata => {
    const savedStyles = [...textSavedStyles, ...colorSavedStyles];
    const allSavedStylesReferences = componentIds.reduce(
      (acc, componentId) => {
        const component = selectComponent(store.getState(), componentId);

        if (!component) {
          return acc;
        }

        return {
          ...acc,
          ...findSavedStylesInComponent({ component, savedStyles }),
        };
      },
      {} as Record<string, SavedTextStyle | SavedColorStyle>,
    );

    return {
      library: {
        [designLibrary.id]: {
          styles: allSavedStylesReferences,
        },
      },
    };
  };

  return {
    getDesignLibraryComponentReferences,
  };
}

export default useGetDesignLibraryComponentReferences;
