import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

export const ExperimentTabWrapper: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className="flex justify-center">
      <div className={twMerge("w-full", className)}>{children}</div>
    </div>
  );
};
