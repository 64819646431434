import type { TableMetric } from "@/features/analytics/constants";

import * as React from "react";

import { formatValueToTableCell } from "@/editor/utils/analytics";
import { Tag } from "@replo/design-system/components/tag/Tag";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsDash } from "react-icons/bs";
import { LuArrowDown, LuArrowUp } from "react-icons/lu";
import { formatWithCommasAndTwoDecimals } from "replo-utils/lib/math";

const MetricWithDelta: React.FC<{
  name: TableMetric;
  value: number | null;
  delta?: number;
  doesCompareValueExist: boolean;
  wrapperClassName?: string;
  valueClassName?: string;
}> = ({
  name,
  value,
  delta,
  doesCompareValueExist,
  wrapperClassName,
  valueClassName,
}) => {
  const getVariant = (() => {
    if (!delta || !doesCompareValueExist) {
      return "neutral";
    }
    return delta > 0 ? "success" : "danger";
  })();

  const getIcon = (() => {
    if (!doesCompareValueExist) {
      return <BsDash />;
    }
    if (delta && delta !== 0) {
      return delta > 0 ? <LuArrowUp /> : <LuArrowDown />;
    }
  })();

  return (
    <div
      className={twMerge(
        "inline-flex items-center justify-left gap-3",
        wrapperClassName,
      )}
    >
      <div className={twMerge("min-w-[10px]", valueClassName)}>
        {value !== null ? formatValueToTableCell({ value, name }) : <BsDash />}
      </div>
      {delta !== undefined && (
        <Tag size="base" variant={getVariant} startEnhancer={getIcon}>
          {doesCompareValueExist &&
            `${formatWithCommasAndTwoDecimals(Math.abs(delta), true)}%`}
        </Tag>
      )}
    </div>
  );
};

export default MetricWithDelta;
