import type { ProjectMembership } from "schemas/generated/projectMembership";

import * as React from "react";

import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import Avatar from "@editor/components/common/designSystem/Avatar";
import InputComponent from "@editor/components/common/designSystem/Input";
import Separator from "@editor/components/common/designSystem/Separator";
import { InvitationModalSkeleton } from "@editor/components/dashboard/SkeletonLoaders";
import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useHasWorkspaceMembership } from "@editor/hooks/useHasWorkspaceMembership";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { skipToken } from "@tanstack/react-query";
import ContentLoader from "react-content-loader";
import { Controller, useForm } from "react-hook-form";
import { BsChevronRight, BsPeople, BsSearch, BsX } from "react-icons/bs";
import { generatePath, Link } from "react-router-dom";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { z } from "zod";

/**
 * Emails which we'll reject instead of adding them to the project if the user types them in
 */
const blockedEmailConfig: Record<string, { title: string; subtitle: string }> =
  {
    "support@replo.app": {
      title: "support@replo.app doesn't need to be added as a collaborator.",
      subtitle:
        "Don't worry, Replo Support will be able to access this project to help.",
    },
  };

const AddCollaboratorSchema = z.object({
  email: z
    .string()
    .email("Please enter a valid email address")
    .min(1, "Please enter a valid email address"),
});

export const ProjectMembershipModal = () => {
  const modal = useModal();
  const {
    handleSubmit,
    formState: { errors, isLoading: isLoadingForm },
    reset,
    control,
  } = useForm<{ email: string }>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(AddCollaboratorSchema),
  });
  const projectId = useCurrentProjectId();
  const { isAuthenticated } = useCurrentUser();
  const trpcUtils = trpc.useUtils();
  const errorToast = useErrorToast();
  const { data, isLoading } = trpc.project.membership.list.useQuery(
    projectId && isAuthenticated ? { projectId } : skipToken,
  );

  const {
    workspace,
    isLoading: isLoadingWorkspace,
    workspaceId,
  } = useCurrentWorkspaceContext();

  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);

  const { workspaceMembershipsIsLoading, workspaceMembershipsData } =
    useHasWorkspaceMembership();

  const workspaceName = workspace?.name;

  const { mutateAsync: _createProjectMembership } =
    trpc.project.membership.create.useMutation({
      onSuccess: async ({ projectMembership }) => {
        void trpcUtils.workspace.getWorkspaceAndProjectMembers.invalidate();
        void trpcUtils.project.membership.list.invalidate({ projectId });
        if (isWorkspaceOwner && workspaceId && workspaceName) {
          modal.closeModal({ type: "projectMembershipModal" });
          modal.openModal({
            type: "workspaceMembershipModal",
            props: {
              workspaceId,
              workspaceName,
              email: projectMembership.project.user.email,
            },
          });
        }
      },
    });

  const createProjectMembership = async ({ email }: { email: string }) => {
    const values = {
      email,
      projectId: projectId ?? "",
    };
    const blockedConfig = getFromRecordOrNull(blockedEmailConfig, values.email);
    if (blockedConfig) {
      errorToast(
        blockedConfig.title,
        blockedConfig.subtitle,
        "error.project.membership.blocked",
        {
          blockedConfigTitle: blockedConfig.title,
          blockedConfigSubtitle: blockedConfig.subtitle,
        },
      );
      return;
    }
    await _createProjectMembership(values);
    reset();
  };
  const areProjectOrWorkspaceMembershipsLoading =
    isLoading || workspaceMembershipsIsLoading || isLoadingWorkspace;
  const shouldShowWorkspaceMembershipsWarn =
    !areProjectOrWorkspaceMembershipsLoading && workspaceId;

  const projectMembershipsThatAreNotWorkspaceMembers =
    data?.projectMemberships.filter(
      (projectMembership) =>
        !workspaceMembershipsData?.workspaceMemberships.some(
          (workspaceMembership) =>
            workspaceMembership.user.id === projectMembership.project.user.id,
        ),
    );

  return (
    <form
      onSubmit={(data) => {
        void handleSubmit(createProjectMembership)(data);
      }}
    >
      <Modal
        title="Invite to Project"
        onOpenChange={(open) => {
          if (!open) {
            modal.closeModal({ type: "projectMembershipModal" });
          }
        }}
        isOpen
        size="base"
      >
        <div className="flex flex-col gap-1">
          <div className="flex w-full flex-row gap-x-2">
            <Controller
              render={({ field }) => (
                <InputComponent
                  {...field}
                  autoComplete="off"
                  placeholder="Collaborator Email"
                  startEnhancer={<BsSearch className="text-muted" />}
                />
              )}
              control={control}
              name="email"
            />
            <Button
              variant="primary"
              type="submit"
              size="sm"
              disabled={isLoading || isLoadingForm}
            >
              Invite
            </Button>
          </div>
          <ErrorMessage error={errors["email"]?.message} />
        </div>
        {areProjectOrWorkspaceMembershipsLoading && <InvitationModalSkeleton />}
        {shouldShowWorkspaceMembershipsWarn && (
          <Link
            to={generatePath(routes.workspace.members, {
              workspaceId,
            })}
            className="flex flex-row gap-2 items-center"
          >
            <BsPeople size={14} />
            <p className="typ-body-base">
              Everyone in {workspaceName ?? "this workspace"} has edit access
            </p>
            <div className="flex flex-1 flex-row justify-end">
              <BsChevronRight size={14} />
            </div>
          </Link>
        )}
        <Separator />
        <div className="typ-body-small">More People with Access</div>
        <div className="flex flex-col max-h-72 gap-y-2 overflow-y-scroll">
          {isLoading && <ProjectMembershipRowSkeletonLoader />}
          {!isLoading &&
            projectMembershipsThatAreNotWorkspaceMembers?.map(
              (project: ProjectMembership) => {
                return (
                  <ProjectMembershipRow
                    projectMembership={project}
                    key={project.id}
                  />
                );
              },
            )}
          {((!isLoading && !projectMembershipsThatAreNotWorkspaceMembers) ||
            projectMembershipsThatAreNotWorkspaceMembers?.length === 0) && (
            <p className="typ-body-small text-muted">
              No members have single-project access.
            </p>
          )}
        </div>
      </Modal>
    </form>
  );
};

type ProjectMembershipRowProps = {
  projectMembership: ProjectMembership;
};

const ProjectMembershipRow = ({
  projectMembership,
}: ProjectMembershipRowProps) => {
  const projectId = projectMembership.project.id;
  const trpcUtils = trpc.useUtils();
  const { mutateAsync: deleteProjectMembership } =
    trpc.project.membership.delete.useMutation({
      onSuccess: async () => {
        void trpcUtils.project.membership.list.invalidate({ projectId });
      },
    });
  const { user: currentUser } = useCurrentUser();

  const { user } = projectMembership.project;
  const removeLabel = `Remove ${
    user.name && user.name.length > 0 ? user.name : user.email
  }`;

  const isCurrentUser = currentUser?.id === user.id;

  return (
    <div className="align-center flex flex-row items-center justify-center">
      <div className="flex grow flex-row items-center gap-x-3">
        <Avatar name={user.name} className="h-7 w-7" />
        <div className="flex flex-col items-start typ-body-small">
          <div className="text-xs text-default">{user.name}</div>
          <div className="text-xs text-muted">{user.email}</div>
        </div>
      </div>
      {!isCurrentUser && (
        <IconButton
          UNSAFE_className="col-span-1 justify-self-end"
          icon={<BsX size={16} />}
          tooltipText={removeLabel}
          variant="tertiary"
          aria-label={removeLabel}
          onClick={() => {
            void deleteProjectMembership({
              id: projectMembership.id,
              projectId: projectMembership.project.id,
            });
          }}
        />
      )}
    </div>
  );
};

const ProjectMembershipRowSkeletonLoader = () => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, i) => (
        <ContentLoader
          key={i}
          speed={2}
          width={302}
          height={40}
          viewBox="0 0 302 40"
          backgroundColor="#f1f5f9"
          foregroundColor="#e2e8f0"
        >
          <circle cx="20" cy="20" r="18" />
          <rect x="48" y="4" rx="4" ry="4" width="170" height="12" />
          <rect x="48" y="22" rx="4" ry="4" width="170" height="12" />
        </ContentLoader>
      ))}
    </>
  );
};
