import type { TableMetric } from "@/features/analytics/constants";
import type { UrlSyncedQuery } from "@/features/analytics/query";
import type { DateRange } from "react-day-picker";
import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
  SelectedTimePeriod,
} from "replo-utils/analytics";

import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTERS,
  METRICS_REQUIRING_CURRENCY,
  METRICS_REQUIRING_DECIMALS,
  METRICS_REQUIRING_PERCENTAGE,
} from "@/features/analytics/constants";
import { generateDefaultUrlSyncedQuery } from "@/features/analytics/query";
import { generatePath } from "react-router-dom";
import { compressObjectToLzString } from "replo-utils/lib/lzString";
import { formatWithCommasAndTwoDecimals } from "replo-utils/lib/math";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { ConditionOperatorEnum } from "schemas/generated/analyticsRead";

import { routes } from "./router";

export const URL_SYNCED_QUERY_URL_PARAM = "query";

export function calculateDelta(
  newValue: number | undefined,
  oldValue: number | undefined,
) {
  if (!oldValue || newValue === undefined) {
    return 0;
  }

  return oldValue === 0 ? newValue : ((newValue - oldValue) / oldValue) * 100;
}

/**
 * Given a selected timeframe/period, returns true if it's a custom date range.
 * If it returns true, we can be 100% sure that the value has a .from/.to
 *
 * @author Max 2024-09-17
 */
export function isCustomDateRange(
  value: ComparisonTimeFrame | RelativeTimeFrame | DateRange,
): value is { from: Date; to: Date } {
  return (
    (value as DateRange).from !== undefined &&
    (value as DateRange).to !== undefined
  );
}

/**
 * Sanitizes the page URL path by replacing "/" with "Homepage".
 *
 * @author Kurt 2024-10-13
 */
export function sanitizePageUrlPath(urlPath: string) {
  return urlPath !== "/" ? urlPath : "Homepage";
}

export function generateDeepDiveNavigationPathFromUrl({
  url,
  urlHosts,
  path,
  workspaceId,
  selectedTimePeriod,
}: {
  url: string | null;
  urlHosts: string[];
  path: string;
  workspaceId: string;
  selectedTimePeriod: SelectedTimePeriod;
}) {
  const urlSyncedQuery = generateDefaultUrlSyncedQuery({
    urlHosts,
    overrides: {
      selectedTimePeriod,
      filters: {
        ...DEFAULT_FILTERS,
        urlPath: [
          {
            value: [path ?? ""],
            operator: ConditionOperatorEnum.EQUALS,
          },
        ],
      },
    },
  });

  if (!url) {
    return { urlSyncedQuery, deepDivePathWithCompressedUrlSyncedQuery: null };
  }

  const deepDivePath = generatePath(routes.analytics.pageDetails, {
    workspaceId,
  });

  const urlHost = new URL(url).host;
  urlSyncedQuery.urlHosts = [urlHost];

  const deepDivePathWithCompressedUrlSyncedQuery =
    generatePathWithCompressedUrlSyncedQuery({
      path: deepDivePath,
      urlSyncedQueryConfig: {
        type: "decompressed",
        urlSyncedQuery: urlSyncedQuery,
      },
    });

  return { urlSyncedQuery, deepDivePathWithCompressedUrlSyncedQuery };
}

/**
 * Should be used when navigating to the overview page or deep dive page.
 *
 * Given a path and a urlSyncedQueryConfig, it returns the path with the compressed config's
 * urlSyncedQuery appended to the path with ?query=''.
 *
 * The config's type is either:
 * 1) compressed: we already have the compressed string (i.e. we extracted the ?query='' param and are
 * redirecting to a new analytics path -> this happens in the DashboardMenuItems.tsx)
 *
 * 2) decompressed: we have the urlSyncedQuery as an object, which we now need to compress & append to the path. This
 * happens e.g. in AnalyticsMenuPane, where we manually construct a default urlSyncedQuery, which we have as an object,
 * and we now want to redirect to the deep dive page.
 *
 * @author Max 2024-12-16
 */
export function generatePathWithCompressedUrlSyncedQuery({
  path,
  urlSyncedQueryConfig,
}: {
  path: string | null;
  urlSyncedQueryConfig:
    | { type: "compressed"; urlSyncedQuery: string | null }
    | { type: "decompressed"; urlSyncedQuery: UrlSyncedQuery };
}) {
  if (!path) {
    return "";
  }

  return exhaustiveSwitch(urlSyncedQueryConfig)({
    compressed: ({ urlSyncedQuery }) => {
      return urlSyncedQuery
        ? `${path}?${URL_SYNCED_QUERY_URL_PARAM}=${urlSyncedQuery}`
        : path;
    },
    decompressed: ({ urlSyncedQuery }) => {
      if (!urlSyncedQuery) {
        return path;
      }

      const compressedUrlSyncedQuery = compressObjectToLzString(urlSyncedQuery);
      return `${path}?${URL_SYNCED_QUERY_URL_PARAM}=${compressedUrlSyncedQuery}`;
    },
  });
}

export function formatValueToTableCell({
  value,
  name,
}: {
  value: number;
  name: TableMetric;
}) {
  const showPercentage = METRICS_REQUIRING_PERCENTAGE.includes(name);
  const showCurrency = METRICS_REQUIRING_CURRENCY.includes(name);
  const showDecimals = METRICS_REQUIRING_DECIMALS.includes(name);

  if (showCurrency) {
    return Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: DEFAULT_CURRENCY,
    }).format(value);
  }

  const formattedValue = formatWithCommasAndTwoDecimals(value, showDecimals);

  return showPercentage ? `${formattedValue}%` : formattedValue;
}
