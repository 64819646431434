import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type { ReploShopifyProduct } from "replo-runtime/shared/types";

export const getSellingPlanOptionsFromProduct = (
  product: ReploShopifyProduct,
  allowAllPlans: boolean,
): ComboboxOption[] => {
  // NOTE (Matt 2024-03-06): There are a few use cases we need to account for:
  // If the array of sellingPlanGroupIds doesn't exist on the variant object,
  // we should assume all product selling plans apply to the each variant.
  // It is only when the array is empty that there are no selling plans
  // that apply. Additionally, if `allowAllPlans` is true, we just allow every
  // plan. If there is no product, then the array should be empty.
  const applicableSellingPlanGroupIds =
    allowAllPlans || !product?.variant?.sellingPlanGroupIds
      ? product?.sellingPlanGroups.map(({ id }) => id)
      : product?.variant?.sellingPlanGroupIds ?? [];

  const applicableSellingPlanIds =
    allowAllPlans || !product?.variant?.sellingPlanIds
      ? product?.sellingPlanGroups.flatMap(({ sellingPlans }) =>
          sellingPlans.map(({ id }) => id),
        )
      : product?.variant?.sellingPlanIds ?? [];

  return (
    product?.sellingPlanGroups
      .map((sellingPlanGroup) => {
        const groupCanBeSelected = applicableSellingPlanGroupIds.includes(
          sellingPlanGroup.id,
        );
        let toolTip: string | null = null;
        if (allowAllPlans) {
          const applicableVariants = product.variants.filter(
            ({ sellingPlanGroupIds }) => {
              return (
                !sellingPlanGroupIds ||
                sellingPlanGroupIds.includes(sellingPlanGroup.id)
              );
            },
          );
          if (applicableVariants.length == product.variants.length) {
            toolTip = "Compatible with all variants";
          } else if (applicableVariants.length == 1 && applicableVariants[0]) {
            toolTip = `Only compatible with ${applicableVariants[0].title}`;
          } else {
            toolTip = `Compatible with ${applicableVariants.length} variants`;
          }
        }
        return sellingPlanGroup.sellingPlans.map((plan) => {
          const sellingPlanCanBeSelected =
            groupCanBeSelected && applicableSellingPlanIds.includes(plan.id);
          return {
            label: plan.name,
            value: String(plan.id),
            isDisabled: !sellingPlanCanBeSelected,
            tooltip: toolTip,
            isSelectable: sellingPlanCanBeSelected,
          };
        });
      })
      .flat() ?? []
  );
};
