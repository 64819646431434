import type { ComponentTemplate } from "@editor/types/component-template";

export const centeredCarousel: ComponentTemplate = {
  id: "1ab60aeb-62eb-49c8-b11c-9b3e3deb3a50",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Carousel/Centered Carousel",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/centered.png",
  template: {
    id: "693c8621-f26b-48f0-bfba-140225f83ce1",
    name: "centered carousel",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        flexGrow: 1,
        maxWidth: "100%",
        alignSelf: "stretch",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      _infinite: true,
      _autoWidth: true,
      _activeArea: "center",
      _mouseWheel: false,
      _autoNextInterval: "0s",
    },
    children: [
      {
        id: "523ebaf3-0570-44b2-84c7-f33db5ac0ad4",
        name: "Container 9",
        type: "container",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
        },
        children: [
          {
            id: "9f59fffb-59fc-4b92-be0d-b047a3786555",
            name: "Slides",
            type: "carouselV3Slides",
            props: {
              style: {
                __width: "1197px",
                display: "flex",
                flexGrow: 1,
                overflow: "hidden",
                __flexGap: "24px",
                alignSelf: "stretch",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              },
              "style@sm": {
                __flexGap: "16px",
              },
            },
            children: [
              {
                id: "c05d3e3f-b256-46cc-a1bf-df0ab1e1b3ae",
                name: "Slide 1",
                type: "container",
                props: {
                  style: {
                    width: "400px",
                    __width: "400px",
                    display: "flex",
                    opacity: "50%",
                    __flexGap: "20px",
                    flexBasis: 0,
                    alignItems: "flex-start",
                    paddingTop: "24px",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    justifyContent: "center",
                    __animateVariantTransitions: false,
                  },
                  "style@sm": {
                    width: "250px",
                    __width: "250px",
                    flexGrow: "unset",
                  },
                },
                children: [
                  {
                    id: "638a7075-3f70-4868-afa5-b65eeaa11666",
                    name: "Image 1",
                    type: "image",
                    props: {
                      style: {
                        width: "100%",
                        __width: "100%",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1504198266287-1659872e6590?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTd8fHxlbnwwfHx8fHw%3D",
                        __imageAltText:
                          "A single green leaf against a white background.",
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "835e016b-31f7-4302-937a-316d95222abb",
                    name: "Container 1",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "47d430e6-3dae-4de6-846d-9bb437b735f8",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "5348cdab-0607-4ce1-974a-f20684102d88",
                        type: "text",
                        props: {
                          text: "<p>Description</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "673199e5-5f01-4548-8804-14d1399b8828",
                        type: "text",
                        props: {
                          text: "<p>SLIDE 1 OF 8</p>",
                          style: {
                            color: "#A8A8A8FF",
                            fontSize: "10px",
                            marginTop: "6px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "283151d2-2bd7-4205-ab2f-903055adbb4a",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "856530d6-b668-41b0-89be-13c8b1b8f0dd",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "7adb0d47-bdaa-4f04-b475-981a8ed283f8",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "856530d6-b668-41b0-89be-13c8b1b8f0dd": {
                    componentOverrides: {
                      "c05d3e3f-b256-46cc-a1bf-df0ab1e1b3ae": {
                        props: {
                          style: {
                            width: "500px",
                            __width: "500px",
                            opacity: "100%",
                            flexGrow: "unset",
                          },
                          "style@sm": {
                            width: "300px",
                            __width: "300px",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "bc6a27a6-bf3c-4ee6-a29b-a1ca1fa5ea06",
                name: "Slide 2",
                type: "container",
                props: {
                  style: {
                    width: "400px",
                    __width: "400px",
                    display: "flex",
                    opacity: "50%",
                    __flexGap: "20px",
                    flexBasis: 0,
                    alignItems: "flex-start",
                    paddingTop: "24px",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    justifyContent: "center",
                    __animateVariantTransitions: false,
                  },
                  "style@sm": {
                    width: "250px",
                    __width: "250px",
                    flexGrow: "unset",
                  },
                },
                children: [
                  {
                    id: "0e16a098-c418-4318-bc08-d6b4cccae499",
                    name: "Image 2",
                    type: "image",
                    props: {
                      style: {
                        width: "100%",
                        __width: "100%",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1504204267155-aaad8e81290d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTR8fHxlbnwwfHx8fHw%3D",
                        __imageAltText:
                          "A single green leaf in a clear glass of water on a white background.",
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "995cff69-0e3a-4d10-b88c-1f2e4c9be11e",
                    name: "Container 2",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "4cc8c107-c85d-461b-8d59-ff891a0cd36d",
                        type: "text",
                        props: {
                          text: "<p>Slide Title<br></p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "fede6ba2-c0ef-4fcd-a5a1-e67156c4ed6a",
                        type: "text",
                        props: {
                          text: "<p>Description</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "fc48db93-cacd-452d-bf01-c029850ead11",
                        type: "text",
                        props: {
                          text: "<p>SLIDE 2 OF 8</p>",
                          style: {
                            color: "#A8A8A8FF",
                            fontSize: "10px",
                            marginTop: "6px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "27f95917-46c2-4da8-9d91-9de6f42b70ec",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "44fa75c9-2393-4c2f-8bce-9b59693aa81f",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "efa03956-f591-4d03-b75f-ecb2b8f207b6",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "44fa75c9-2393-4c2f-8bce-9b59693aa81f": {
                    componentOverrides: {
                      "bc6a27a6-bf3c-4ee6-a29b-a1ca1fa5ea06": {
                        props: {
                          style: {
                            width: "500px",
                            __width: "500px",
                            opacity: "100%",
                            flexGrow: "unset",
                          },
                          "style@sm": {
                            width: "300px",
                            __width: "300px",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "f88d8fff-de3b-48a2-9a91-7cf3ba2d0505",
                name: "Slide 3",
                type: "container",
                props: {
                  style: {
                    width: "400px",
                    __width: "400px",
                    display: "flex",
                    opacity: "50%",
                    __flexGap: "20px",
                    flexBasis: 0,
                    alignItems: "flex-start",
                    paddingTop: "24px",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    justifyContent: "center",
                    __animateVariantTransitions: false,
                  },
                  "style@sm": {
                    width: "250px",
                    __width: "250px",
                    flexGrow: "unset",
                  },
                },
                children: [
                  {
                    id: "56b42446-3f8a-4bfd-8f4f-1ebf94327fa9",
                    name: "Image 3",
                    type: "image",
                    props: {
                      style: {
                        width: "100%",
                        __width: "100%",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1471086569966-db3eebc25a59?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MjZ8fHxlbnwwfHx8fHw%3D",
                        __imageAltText:
                          "A monstera leaf in a clear glass vase against a white background.",
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "5767fd64-1256-417c-985d-7ab4deaae793",
                    name: "Container 3",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "4ac58987-0b12-4cb6-ad6b-55d4381f5033",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "b5317d58-5a19-4324-ae5c-8042fd1bb2d2",
                        type: "text",
                        props: {
                          text: "<p>Description</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "0e324176-e8e3-41cb-b6ee-81f4d7c37beb",
                        type: "text",
                        props: {
                          text: "<p>SLIDE 3 OF 8</p>",
                          style: {
                            color: "#A8A8A8FF",
                            fontSize: "10px",
                            marginTop: "6px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "97057d65-ffeb-4c3f-aa1b-1548c6195230",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "39dc4150-6696-4f7c-8b03-ffbc009e7747",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "2af9eacd-a858-4250-ad3f-d100e71a160a",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "39dc4150-6696-4f7c-8b03-ffbc009e7747": {
                    componentOverrides: {
                      "f88d8fff-de3b-48a2-9a91-7cf3ba2d0505": {
                        props: {
                          style: {
                            width: "500px",
                            __width: "500px",
                            opacity: "100%",
                            flexGrow: "unset",
                          },
                          "style@sm": {
                            width: "300px",
                            __width: "300px",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "b06698ad-c8ea-48fc-8811-2dafdbc27474",
                name: "Slide 4",
                type: "container",
                props: {
                  style: {
                    width: "400px",
                    __width: "400px",
                    display: "flex",
                    opacity: "50%",
                    __flexGap: "20px",
                    flexBasis: 0,
                    alignItems: "flex-start",
                    paddingTop: "24px",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    justifyContent: "center",
                    __animateVariantTransitions: false,
                  },
                  "style@sm": {
                    width: "250px",
                    __width: "250px",
                    flexGrow: "unset",
                  },
                },
                children: [
                  {
                    id: "70439fd3-c44e-4bc1-98c9-97ad45de6175",
                    name: "Image 4",
                    type: "image",
                    props: {
                      style: {
                        width: "100%",
                        __width: "100%",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1655658233701-a0bada796e94?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D",
                        __imageAltText:
                          "Green palm leaf beside two blank white cards on a white background.",
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "18c32b4a-e509-414a-8367-a946e0b14bf7",
                    name: "Container 4",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "d896d368-2bf9-416a-99cf-fa7fdb5b69a4",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "b308a9a9-1cdb-4238-bad2-32c4ee02385f",
                        type: "text",
                        props: {
                          text: "<p>Description</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "9c349402-7920-4c80-ae50-bb075d6e29f5",
                        type: "text",
                        props: {
                          text: "<p>SLIDE 4 OF 8</p>",
                          style: {
                            color: "#A8A8A8FF",
                            fontSize: "10px",
                            marginTop: "6px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "bb6c1f98-41b2-49fc-aafa-ad2eb97d04bd",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "e06dd968-6c9a-4402-b714-1e0b40a22929",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "02b838e5-c7ff-41e7-ba77-584b41448918",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "e06dd968-6c9a-4402-b714-1e0b40a22929": {
                    componentOverrides: {
                      "b06698ad-c8ea-48fc-8811-2dafdbc27474": {
                        props: {
                          style: {
                            width: "500px",
                            __width: "500px",
                            opacity: "100%",
                            flexGrow: "unset",
                          },
                          "style@sm": {
                            width: "300px",
                            __width: "300px",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "9148660b-ba8f-40d0-b355-0600b4b42436",
                name: "Slide 5",
                type: "container",
                props: {
                  style: {
                    width: "400px",
                    __width: "400px",
                    display: "flex",
                    opacity: "50%",
                    __flexGap: "20px",
                    flexBasis: 0,
                    alignItems: "flex-start",
                    paddingTop: "24px",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    justifyContent: "center",
                    __animateVariantTransitions: false,
                  },
                  "style@sm": {
                    width: "250px",
                    __width: "250px",
                    flexGrow: "unset",
                  },
                },
                children: [
                  {
                    id: "34f81f1b-e1d9-4aa5-98c8-6c1514e15650",
                    name: "Image 5",
                    type: "image",
                    props: {
                      style: {
                        width: "100%",
                        __width: "100%",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1625474941653-4343bbb8267a?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8NHx8fGVufDB8fHx8fA%3D%3D",
                        __imageAltText:
                          "Indoor plant beside a smartphone with an app on screen, on a white background with shadows.",
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "de65f20c-c2eb-4bc3-9f87-206f362e609e",
                    name: "Container 5",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "bb7653f6-f231-4b7f-8f28-63edacd96e61",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "2155547a-9842-4735-9e86-871983f6a576",
                        type: "text",
                        props: {
                          text: "<p>Description</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "58804e5b-70c5-45aa-98d8-78062ba43e4e",
                        type: "text",
                        props: {
                          text: "<p>SLIDE 5 OF 8</p>",
                          style: {
                            color: "#A8A8A8FF",
                            fontSize: "10px",
                            marginTop: "6px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "8bab5c96-9836-4b06-adb3-670f4cba58b8",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "2d47dfcc-d495-4049-8805-285e2c777716",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "1d777085-52fa-4624-8870-29abe286befb",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "2d47dfcc-d495-4049-8805-285e2c777716": {
                    componentOverrides: {
                      "9148660b-ba8f-40d0-b355-0600b4b42436": {
                        props: {
                          style: {
                            width: "500px",
                            __width: "500px",
                            opacity: "100%",
                            flexGrow: "unset",
                          },
                          "style@sm": {
                            width: "300px",
                            __width: "300px",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "13f178a4-607d-4ea7-854e-4498a674b671",
                name: "Slide 6",
                type: "container",
                props: {
                  style: {
                    width: "400px",
                    __width: "400px",
                    display: "flex",
                    opacity: "50%",
                    __display: "flex",
                    __flexGap: "20px",
                    flexBasis: 0,
                    alignItems: "flex-start",
                    paddingTop: "24px",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    justifyContent: "center",
                    __animateVariantTransitions: false,
                  },
                  "style@md": {
                    display: "inline",
                    __display: "inline",
                  },
                  "style@sm": {
                    width: "250px",
                    __width: "250px",
                    display: "inline",
                    flexGrow: "unset",
                    __display: "inline",
                  },
                },
                children: [
                  {
                    id: "2def1014-dae1-4ffa-8dac-6d47b8dbe4a9",
                    name: "Image 6",
                    type: "image",
                    props: {
                      style: {
                        width: "100%",
                        __width: "100%",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1623824505948-2938b8818e12?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8N3x8fGVufDB8fHx8fA%3D%3D",
                        __imageAltText:
                          "Green palm leaves against a white background.",
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "5d145e9d-543e-4028-9a74-14e8108a13f4",
                    name: "Container 6",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "8608ac99-a8c9-4e2b-89f9-f84f82caa5f5",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "b8ca0a5a-5713-49ed-b062-108aa760c7ac",
                        type: "text",
                        props: {
                          text: "<p>Description</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "1bf6bc19-e32f-4703-9ce9-ed00549efd4e",
                        type: "text",
                        props: {
                          text: "<p>SLIDE 6 OF 8</p>",
                          style: {
                            color: "#A8A8A8FF",
                            fontSize: "10px",
                            marginTop: "6px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "d2ed71ce-28f4-4aa4-a3f4-a91064093f53",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "79d32511-1086-42f8-99cf-d0cfa026dafd",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "6a757d35-f8f3-4a82-839e-5e589b7c4ac3",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "79d32511-1086-42f8-99cf-d0cfa026dafd": {
                    componentOverrides: {
                      "13f178a4-607d-4ea7-854e-4498a674b671": {
                        props: {
                          style: {
                            width: "500px",
                            __width: "500px",
                            opacity: "100%",
                            flexGrow: "unset",
                          },
                          "style@sm": {
                            width: "300px",
                            __width: "300px",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "7350a718-dbc8-4152-b907-083418234950",
                name: "Slide 7",
                type: "container",
                props: {
                  style: {
                    width: "400px",
                    __width: "400px",
                    display: "flex",
                    opacity: "50%",
                    __flexGap: "20px",
                    flexBasis: 0,
                    alignItems: "flex-start",
                    paddingTop: "24px",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    justifyContent: "center",
                    __animateVariantTransitions: false,
                  },
                  "style@sm": {
                    width: "250px",
                    __width: "250px",
                    flexGrow: "unset",
                  },
                },
                children: [
                  {
                    id: "36f92752-f19b-4750-9e21-2fe663040f2a",
                    name: "Image 7",
                    type: "image",
                    props: {
                      style: {
                        width: "100%",
                        __width: "100%",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1622624671653-18a35aba747d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTJ8fHxlbnwwfHx8fHw%3D",
                        __imageAltText:
                          "Smartphone on a white surface displaying an article with image and text.",
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "caacad64-4c2e-4498-a1ed-e04e18a8839b",
                    name: "Container 7",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "5002f3c1-ea0f-4080-875e-3bd609d9d81e",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "8dc94fa5-85ce-4a27-bc17-05715f0f2732",
                        type: "text",
                        props: {
                          text: "<p>Description</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "aac03726-2b87-4c52-aa67-ce7bc613cc00",
                        type: "text",
                        props: {
                          text: "<p>SLIDE 7 OF 8</p>",
                          style: {
                            color: "#A8A8A8FF",
                            fontSize: "10px",
                            marginTop: "6px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "6b15dfd9-53b1-4171-9875-357f8e0e3891",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "a278148c-5f02-48c5-b78c-c70e46e41604",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "a6b39f15-ad7f-4848-8755-d184d3b1ad3a",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "a278148c-5f02-48c5-b78c-c70e46e41604": {
                    componentOverrides: {
                      "7350a718-dbc8-4152-b907-083418234950": {
                        props: {
                          style: {
                            width: "500px",
                            __width: "500px",
                            opacity: "100%",
                            flexGrow: "unset",
                          },
                          "style@sm": {
                            width: "300px",
                            __width: "300px",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "992b9874-36e2-4e69-80f6-8ec462e9d314",
                name: "Slide 8",
                type: "container",
                props: {
                  style: {
                    width: "400px",
                    __width: "400px",
                    display: "flex",
                    opacity: "50%",
                    __flexGap: "20px",
                    flexBasis: 0,
                    alignItems: "flex-start",
                    paddingTop: "24px",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    justifyContent: "center",
                    __animateVariantTransitions: false,
                  },
                  "style@sm": {
                    width: "250px",
                    __width: "250px",
                    flexGrow: "unset",
                  },
                },
                children: [
                  {
                    id: "08981410-0e8b-46f5-a6b1-51039253f83e",
                    name: "Image 8",
                    type: "image",
                    props: {
                      style: {
                        width: "100%",
                        __width: "100%",
                        objectFit: "cover",
                        __imageSource:
                          "https://images.unsplash.com/photo-1504198322253-cfa87a0ff25f?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTZ8fHxlbnwwfHx8fHw%3D",
                        __imageAltText:
                          "Hand holding an open magazine with a black and white architectural photo.",
                      },
                    },
                    markers: {
                      _aiGeneratedAltText: true,
                    },
                  },
                  {
                    id: "144a2c3b-f85e-4c17-aed2-167ec0b3acf3",
                    name: "Container 8",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "d4acfdf1-4e99-47a0-99d0-fd3b29b68307",
                        type: "text",
                        props: {
                          text: "<p>Slide Title</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            fontWeight: "700",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "cedb0e09-ab35-4303-8f74-1ea5d79001cb",
                        type: "text",
                        props: {
                          text: "<p>Description</p>",
                          style: {
                            color: "#000000",
                            fontSize: "15px",
                            fontFamily: "Assistant, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                      {
                        id: "5d662e35-a029-4b64-b86d-b988a2ea3328",
                        type: "text",
                        props: {
                          text: "<p>SLIDE 8 OF 8</p>",
                          style: {
                            color: "#A8A8A8FF",
                            fontSize: "10px",
                            marginTop: "6px",
                            fontFamily: "IBM Plex Sans, sans-serif",
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "027b9af1-8b2a-4468-86a4-8e56dd9ac2ca",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "83c36cad-e47b-40ea-8791-53aa389848e1",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "75afd408-7223-42e4-b4d9-fcd50f601b11",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "83c36cad-e47b-40ea-8791-53aa389848e1": {
                    componentOverrides: {
                      "992b9874-36e2-4e69-80f6-8ec462e9d314": {
                        props: {
                          style: {
                            width: "500px",
                            __width: "500px",
                            opacity: "100%",
                            flexGrow: "unset",
                          },
                          "style@sm": {
                            width: "300px",
                            __width: "300px",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
          {
            id: "96ce2c56-60d3-4ebd-b27a-5821421ac377",
            name: "Indicators",
            type: "carouselV3Indicators",
            props: {
              style: {
                zIndex: 2,
                display: "flex",
                position: "relative",
                alignSelf: "stretch",
                alignItems: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "10bb71a3-a89b-4c50-a3d8-45064595a04a",
                name: "Container 9",
                type: "container",
                props: {
                  style: {
                    width: "10px",
                    cursor: "pointer",
                    height: "10px",
                    __width: "10px",
                    display: "flex",
                    __height: "10px",
                    flexGrow: "unset",
                    maxWidth: "100%",
                    alignSelf: "auto",
                    alignItems: "flex-start",
                    marginLeft: "10px",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    backgroundColor: "#0000004D",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  },
                },
                children: [],
                variants: [
                  {
                    id: "bd9f84db-0858-4a5f-9b32-fdd6c59ee62d",
                    name: "default",
                    query: null,
                  },
                  {
                    id: "b50cd4f2-68bc-467c-b4fc-0ebc525b1508",
                    name: "Current item is active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "a5eefa18-3bc3-4890-a191-335f2c071f83",
                          field: "state.group.isCurrentItemActive",
                        },
                      ],
                    },
                  },
                ],
                variantOverrides: {
                  "b50cd4f2-68bc-467c-b4fc-0ebc525b1508": {
                    componentOverrides: {
                      "10bb71a3-a89b-4c50-a3d8-45064595a04a": {
                        props: {
                          style: {
                            backgroundColor: "#000000FF",
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      {
        id: "eec29788-7cfd-4b75-857a-cf1ade2b4ccb",
        name: "Previous",
        type: "carouselV3Control",
        props: {
          style: {
            top: "1.8402294371561945px",
            left: "0px",
            cursor: "pointer",
            height: "100%",
            zIndex: 1_000_000,
            display: "flex",
            __height: "375px",
            flexWrap: "wrap",
            position: "absolute",
            alignSelf: "stretch",
            alignItems: "center",
            marginLeft: "24px",
            justifyContent: "center",
            __animateVariantTransitions: true,
          },
          direction: "previous",
        },
        children: [
          {
            id: "90ae3646-5632-4543-8c25-48e37339c814",
            type: "icon",
            props: {
              style: {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
                marginBottom: "96px",
              },
              iconName: "chevronLeft",
              "style@sm": {
                width: "24px",
                height: "24px",
                __width: "24px",
                __height: "24px",
                flexGrow: "unset",
              },
            },
            variantOverrides: {},
          },
        ],
        variantOverrides: {},
      },
      {
        id: "9f56ae9d-906e-4f4e-9eb4-2e4d752d6606",
        name: "Next",
        type: "carouselV3Control",
        props: {
          style: {
            top: "0px",
            right: "-0.9767032436466252px",
            cursor: "pointer",
            height: "100%",
            zIndex: 1_000_000,
            display: "flex",
            __height: "375px",
            position: "absolute",
            alignSelf: "stretch",
            alignItems: "center",
            marginRight: "24px",
            justifyContent: "center",
            __animateVariantTransitions: true,
          },
          direction: "next",
        },
        children: [
          {
            id: "00ea7aa4-85dc-41f8-ab5c-b9ee15da02be",
            type: "icon",
            props: {
              style: {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
                marginBottom: "96px",
              },
              iconName: "chevronRight",
              "style@sm": {
                width: "24px",
                height: "24px",
                __width: "24px",
                __height: "24px",
                flexGrow: "unset",
              },
            },
            variantOverrides: {},
          },
        ],
        variantOverrides: {},
      },
    ],
  },
};
