import * as React from "react";

import { useDefaultWorkspaceId } from "@editor/components/dashboard/hooks/useDefaultWorkspaceId";
import { NavigationTabs } from "@editor/components/dashboard/NavigationTabs";
import DashboardLayout from "@editor/components/layouts/DashboardLayout";
import WorkspaceProvider from "@editor/components/WorkspaceProvider";

import { Outlet } from "react-router-dom";

const links = [
  {
    path: "/settings/profile",
    text: "Profile",
  },
  {
    path: "/settings/security",
    text: "Security",
  },
];

export default function AccountDashboard({
  children,
}: {
  children: React.ReactNode;
}) {
  const workspaceId = useDefaultWorkspaceId();

  return (
    <WorkspaceProvider workspaceId={workspaceId ?? undefined}>
      <DashboardLayout>
        <div className="flex w-full flex-col px-6 pt-4">
          <NavigationTabs
            header="Your Profile"
            subtitle="Manage your profile and preferences"
            links={links}
          />
          {children}
          <Outlet />
        </div>
      </DashboardLayout>
    </WorkspaceProvider>
  );
}
