import * as React from "react";

import ReferralLinkItem from "@components/dashboard/partner/ReferralLinkItem";
import { useCurrentWorkspace } from "@editor/contexts/WorkspaceDashboardContext";
import { useModal } from "@editor/hooks/useModal";

import IconButton from "@replo/design-system/components/button/IconButton";
import { BsPlusLg } from "react-icons/bs";

export const ReferralLinkCallout = () => {
  const { openModal } = useModal();

  const workspace = useCurrentWorkspace();

  const referralCodes = workspace?.referralCodes ?? [];
  const shouldDisableButton = referralCodes.length >= 5;

  return (
    <div className="flex flex-col gap-y-4">
      <div>
        <div className="flex flex-row gap-x-1 items-center">
          <h3 className="text-lg font-medium">
            {`Your Custom Referral ${referralCodes.length > 1 ? "Codes" : "Code"}`}
          </h3>
          <IconButton
            disabled={shouldDisableButton}
            onClick={() => openModal({ type: "addReferralCodeModal" })}
            tooltipText={
              shouldDisableButton
                ? "Only 5 custom referral codes are supported. Please contact support@replo.app if you need more."
                : undefined
            }
            variant="tertiary"
            icon={<BsPlusLg />}
          />
        </div>
        <span className="text-sm font-normal text-slate-400">
          {referralCodes.length === 0
            ? "You do not have any custom referral codes yet. Press + icon to create a new referral code."
            : "Your custom referral codes"}
        </span>
      </div>
      {referralCodes.length > 0 ? (
        <div className="flex flex-col gap-y-2">
          {referralCodes.map((referralCode) => (
            <ReferralLinkItem
              key={referralCode.id}
              referralCode={referralCode}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
