import * as React from "react";

import { useErrorToast } from "@editor/hooks/useErrorToast";
import { analytics, trackError } from "@editor/infra/analytics";
import { useUpdateOrCreateAssetMutation } from "@editor/reducers/api-reducer";
import { selectProject } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { useUploadAsset } from "@/features/assets/useUploadAsset";
import { useDropzone } from "react-dropzone";
import { isEmpty } from "replo-utils/lib/misc";
import { MAX_FILE_UPLOAD_COUNT } from "schemas/asset";
import { v4 as uuidv4 } from "uuid";

import { sizeValidator } from "./utils/sizeValidator";

type CommonProps = {
  onDropBeforeUpload?: () => string | null;
  onUploadComplete: (res: any, imageComponentId: string | null) => void;
  onError?: (imageComponentId: string | null) => void;
  acceptDropAssetType: string[] | null | undefined;
  allowClickToUpload: boolean;
  isDisabled?: boolean;
};

export type SourceType = "theme" | "files";

// TODO (Gabe 2025-03-18, REPL-16485): Remove this prop once Shopify uploads
// (fonts) is fully disabled.
export function useShopifyFileDropZone({
  sourceType,
  ...props
}: { sourceType: SourceType } & CommonProps) {
  const project = useEditorSelector(selectProject);
  const [updateOrCreateAsset, { isLoading }] = useUpdateOrCreateAssetMutation();
  const result = useFileDropZone({
    ...props,
    uploadFile: async ({ file }) => {
      const res = await updateOrCreateAsset({
        file,
        filename: file.name,
        storeId: project?.id ?? "",
        sourceType,
      });
      if ("error" in res) {
        throw res.error;
      }
      return res.data.asset.publicUrl;
    },
  });
  return { ...result, isUploading: isLoading };
}

export function useReploFileDropZone(props: CommonProps) {
  const { uploadAsset, isUploading } = useUploadAsset();
  const result = useFileDropZone({
    ...props,
    uploadFile: async ({ id, file }) => {
      const url = await uploadAsset({ assetId: id, file });
      return url;
    },
  });
  return { ...result, isUploading };
}

function useFileDropZone({
  onDropBeforeUpload,
  onUploadComplete,
  onError,
  acceptDropAssetType,
  allowClickToUpload,
  isDisabled = false,
  uploadFile,
}: CommonProps & {
  uploadFile: (args: { file: File; id: string }) => Promise<string>;
}) {
  const [droppedFilesMap, setDroppedFilesMap] = React.useState<
    Map<string, File>
  >(new Map());
  const project = useEditorSelector(selectProject);
  const errorToast = useErrorToast();

  const handleError = React.useCallback(
    ({
      id,
      imageComponentId,
    }: {
      id: string;
      imageComponentId?: string | null;
    }) => {
      setDroppedFilesMap((prev) => {
        const newMap = new Map(prev);
        newMap.delete(id);
        return newMap;
      });
      onError?.(imageComponentId ?? null);
    },
    [onError],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
    acceptedFiles,
  } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (!isEmpty(fileRejections)) {
        if (
          fileRejections.find((fileRejection) =>
            fileRejection.errors.find(
              (error) => error.code === "too-many-files",
            ),
          )
        ) {
          errorToast(
            "Files Exceeding Limit",
            `Too many files selected. Please upload up to ${MAX_FILE_UPLOAD_COUNT} files at a time for a successful upload.`,
            "error.asset.upload.too_many_files",
          );
          return;
        }
        const fileInvalidTypeError = fileRejections
          .flatMap((rejection) => rejection.errors)
          .find((error) => error.code === "file-invalid-type");

        if (fileInvalidTypeError) {
          errorToast(
            "Invalid File Type",
            `${fileInvalidTypeError.message}`,
            "error.asset.upload.invalid_file_type",
          );
        }
      }

      const internalDroppedFilesMap = new Map(
        acceptedFiles.map((file) => [uuidv4(), file]),
      );
      setDroppedFilesMap(internalDroppedFilesMap);

      const uploadPromises = Array.from(internalDroppedFilesMap.entries()).map(
        async ([id, file]) => {
          const imageComponentId = onDropBeforeUpload?.();
          try {
            const url = await uploadFile({ file, id });
            onUploadComplete(url, imageComponentId ?? null);
            analytics.logEvent("asset.upload.success", {
              projectId: project!.id,
              assetId: id,
              contentType: file.type,
              publicUrl: url,
            });
          } catch (error) {
            const extras = {
              projectId: project!.id,
              fileName: file.name,
              contentType: file.type,
              size: file.size,
            };
            trackError(error, extras);
            analytics.logEvent("asset.upload.failed", {
              error,
              ...extras,
            });
            errorToast(
              "Error Uploading Asset",
              "An error occurred while uploading the asset. Please try again.",
              "error.asset.upload.unknown",
              {},
            );
            handleError({ id, imageComponentId });
          }
        },
      );

      // NOTE (Sebas, 2025-01-31): We cannot await the uploadPromises because the onDrop
      // function is called synchronously and the type does not allow for async.
      void Promise.all(uploadPromises);
    },
    accept: acceptDropAssetType ?? undefined,
    maxFiles: MAX_FILE_UPLOAD_COUNT,
    noClick: !allowClickToUpload,
    noKeyboard: true,
    validator: sizeValidator,
    disabled: isDisabled,
  });

  return {
    getRootProps,
    getInputProps,
    openFileDialog,
    isDragActive,
    droppedFilesMap,
    acceptedFiles,
  };
}
