import type { ReactNode } from "react";

import * as React from "react";

import Header from "@editor/components/dashboard/Header";

import AnnouncementMessage from "@/features/announcements/AnnouncementMessage";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { Tag } from "@replo/design-system/components/tag/Tag";
import { experimentStatusMapping } from "@replo/design-system/utils/experimentStatus";
import { getExperimentStatus } from "schemas/experiment";

import { TabMenu } from "./tabs/TabMenu";

type ExperimentsLayoutProps = {
  children: ReactNode;
  headerTitle?: string;
  headerActions?: ReactNode;
  showStatus?: boolean;
  headerTitleComponent?: ReactNode;
  backButton?: ReactNode;
};

export const ExperimentsLayout = ({
  headerTitle,
  headerTitleComponent,
  children,
  headerActions,
  showStatus = false,
  backButton,
}: ExperimentsLayoutProps) => {
  const { experiment } = useExperimentDetails();

  return (
    <div className="flex flex-col px-6 py-1 w-full relative overflow-hidden gap-4">
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-4">
            {backButton ? backButton : null}
            <div className="flex flex-row gap-2">
              {headerTitle ? (
                <Header title={headerTitle} />
              ) : (
                headerTitleComponent
              )}

              {showStatus && experiment && (
                <Tag
                  variant={
                    experimentStatusMapping[getExperimentStatus(experiment)]
                      .variant
                  }
                  size="base"
                >
                  {
                    experimentStatusMapping[getExperimentStatus(experiment)]
                      .label
                  }
                </Tag>
              )}
            </div>
          </div>
          <div className="flex gap-4 items-center justify-center">
            {headerActions}
          </div>
        </div>
        {!experiment && <AnnouncementMessage tags={["A/B Testing"]} />}
        {experiment && <TabMenu />}
      </div>
      {children}
    </div>
  );
};
