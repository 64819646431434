import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import twMerge from "@replo/design-system/utils/twMerge";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface IntegrationCardProps {
  title: string;
  redirectUrl: string;
  image: string;
  summary?: string;
  isConnected?: boolean;
  isConfigurable?: true;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  title,
  summary,
  redirectUrl,
  isConnected,
  image,
  isConfigurable,
}) => {
  const navigate = useNavigate();
  const logEvent = useLogAnalytics();
  return (
    <div
      className={twMerge(
        "border rounded-lg p-4 md:w-[300px] flex",
        isConfigurable
          ? "flex-col justify-between items-start p5"
          : "items-center",
      )}
    >
      <div
        className={twMerge(
          isConfigurable && "flex justify-between items-start mb-6 w-full",
        )}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: isConfigurable ? "70px" : "30px",
            height: isConfigurable ? "70px" : "30px",
          }}
          className="contain rounded"
        />
        {isConnected && (
          <span className="bg-green-600 text-white text-sm font-medium rounded-full px-2 py-1">
            Connected
          </span>
        )}
      </div>
      <h2
        className={twMerge(
          "text-sm font-bold",
          isConfigurable ? "text-xl mb-4" : "ml-4 mr-auto",
        )}
      >
        {title}
      </h2>
      {summary && <p className="text-sm text-slate-600 mb-4">{summary}</p>}
      {isConfigurable ? (
        <Button
          variant="secondary"
          size="base"
          layoutClassName="w-full"
          onClick={() => {
            logEvent("integration.select", {
              integration_name: title,
            });
            navigate(redirectUrl);
          }}
          endEnhancer={<ArrowRight size={16} className="w-4 h-4" />}
        >
          Configure Integration
        </Button>
      ) : (
        <IconButton
          variant="secondary"
          icon={<ArrowRight size={16} className="w-4 h-4" />}
          size="lg"
          target="_blank"
          onClick={() => {
            logEvent("integration.select", {
              integration_name: title,
            });
          }}
          to={redirectUrl}
        />
      )}
    </div>
  );
};

export default IntegrationCard;
