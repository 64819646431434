import * as React from "react";

import Scrollable from "@editor/components/common/designSystem/Scrollable";

import twMerge from "@replo/design-system/utils/twMerge";

type RightBarLayoutProps = {
  rightBarWidth: number;
  rightBarElementRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  className?: string;
};

export const RightBarLayout: React.FC<RightBarLayoutProps> = ({
  rightBarWidth,
  rightBarElementRef,
  children,
  className,
}) => {
  return (
    <div
      className="flex w-full flex-1 flex-col items-end"
      ref={rightBarElementRef}
    >
      <div
        style={{
          width: rightBarWidth,
        }}
        className={twMerge(
          "z-0 h-0 grow border-l-[.5px] border-border shadow-lg bg-white transition-[width] ease-out duration-300",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

type RightBarBodyProps = {
  children: React.ReactNode;
  isEditMode: boolean;
  className?: string;
};
export const RightBarBody: React.FC<RightBarBodyProps> = ({
  children,
  isEditMode,
  className,
}) => {
  return (
    <Scrollable
      // NOTE (Gabe 2023-06-08): The bg-color must exist on this component
      // so that it exists in the same stacking context as the Canvas
      // (positioned) and appropriately overlays it.
      className={twMerge(`w-full h-full bg-white pb-2`, className)}
      type={isEditMode ? "both" : "vertical"}
      shouldShowScrollbar={false}
    >
      {children}
    </Scrollable>
  );
};
