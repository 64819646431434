import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import React from "react";

import Input from "@common/designSystem/Input";
import SelectionIndicator from "@common/designSystem/SelectionIndicator";
import FormFieldXButton from "@common/FormFieldXButton";
import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";

import { Badge } from "@replo/design-system/components/badge/Badge";
import Popover from "@replo/design-system/components/popover/Popover";
import { BsSearch, BsType } from "react-icons/bs";
import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import SavedStylesEmptyState from "./SavedStylesEmptyState";
import { TextStyleRow } from "./TextSavedStyleGroup";

type TextShopStyleModifierProps = {
  savedStyleValueReference: string | ReploMixedStyleValue;
  onSelectSavedStyle(value: string): void;
  onRemove?(): void;
  popoverSideOffset?: number;
};

const TextShopStyleModifier: React.FC<TextShopStyleModifierProps> = ({
  savedStyleValueReference,
  onSelectSavedStyle,
  onRemove,
  popoverSideOffset,
}) => {
  const { textShopStyles: textSavedStyles } = useShopStyles();
  const textSavedStyleName = !isMixedStyleValue(savedStyleValueReference)
    ? getSavedStyleValue(textSavedStyles, savedStyleValueReference)?.name
    : "Mixed";

  const isTextSavedStyleSelected = Boolean(textSavedStyleName);
  const shouldRenderXButton = onRemove && isTextSavedStyleSelected;

  return (
    <Popover
      shouldPreventDefaultOnInteractOutside={false}
      title="Text Styles"
      content={
        <TextValueIndicatorPopoverContent
          onSelectSavedStyle={onSelectSavedStyle}
        />
      }
      sideOffset={popoverSideOffset}
      triggerAsChild
    >
      <SelectionIndicator
        title={textSavedStyleName}
        startEnhancer={<TextBadge />}
        placeholder="Select..."
        endEnhancer={
          shouldRenderXButton && <FormFieldXButton onClick={onRemove} />
        }
        style={{
          width: "100%",
        }}
      />
    </Popover>
  );
};

const TextValueIndicatorPopoverContent: React.FC<{
  onSelectSavedStyle: (value: string) => void;
}> = ({ onSelectSavedStyle }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { textShopStyles, designLibrary } = useShopStyles();

  const filteredTextSavedStyles = textShopStyles.filter((style) =>
    style.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex w-full justify-between gap-1">
          <Input
            size="sm"
            value={searchTerm}
            startEnhancer={<BsSearch />}
            endEnhancer={() =>
              searchTerm?.trim() && (
                <FormFieldXButton onClick={() => setSearchTerm("")} />
              )
            }
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full h-56">
          {filteredTextSavedStyles?.length > 0 ? (
            <div className="flex flex-col gap-1 overflow-y-auto styled-scrollbar">
              {filteredTextSavedStyles.map((shopStyle) => (
                <TextStyleRow
                  key={shopStyle.id}
                  savedStyle={shopStyle}
                  onClick={() =>
                    onSelectSavedStyle?.(
                      `library.${designLibrary?.id}.styles.${shopStyle.id}.attributes`,
                    )
                  }
                  modificationEnabled={false}
                />
              ))}
            </div>
          ) : (
            <SavedStylesEmptyState title="No Styles Found" showCta={false} />
          )}
        </div>
      </div>
    </div>
  );
};

const TextBadge: React.FC = () => {
  return <Badge type="icon" icon={<BsType className="text-subtle" />} />;
};

export default TextShopStyleModifier;
