import React from "react";

import Header from "@editor/components/header/Header";
import LeftBar from "@editor/components/left-bar/LeftBar";
import ChooseTemplateOverlay from "@editor/components/new-page-flow/ChooseTemplateOverlay";

import { EditorLayout } from "./EditorLayout";

interface TemplateLibraryEditorLayoutProps {
  children: React.ReactNode;
}

const TemplateLibraryEditorLayout = ({
  children,
}: TemplateLibraryEditorLayoutProps) => {
  const templateLibraryEditorOverlays = (
    <>
      <LeftBar
        showElementSpecificContent={false}
        fullPageOverlay={<ChooseTemplateOverlay />}
      />
    </>
  );

  return (
    <EditorLayout
      toRenderOverEditor={templateLibraryEditorOverlays}
      projectHeader={<Header showElementSpecificContent={false} />}
    >
      {children}
    </EditorLayout>
  );
};

export default TemplateLibraryEditorLayout;
