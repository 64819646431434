import type { PendingShopifyIntegrationCookie } from "schemas/cookies";

import React from "react";

import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";

import Button from "@replo/design-system/components/button/Button";

import { ShopifyIntegrationSelectable } from "../dashboard/projects/ShopifyIntegrationSelectable";
import { useShopifyIntegrationConnect } from "../editor/page/useShopifyIntegrationConnect";

interface IntegrateWithShopifyCardProps {
  cookieValue: PendingShopifyIntegrationCookie;
}

const IntegrateWithShopifyCard: React.FC<IntegrateWithShopifyCardProps> = ({
  cookieValue,
}) => {
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();

  const {
    selectedIntegrationId,
    setSelectedIntegrationId,
    isLoading,
    handleConnect,
  } = useShopifyIntegrationConnect({
    workspaceId,
    projectId,
    cookieValue: cookieValue,
  });

  return (
    <div className="w-full h-64 rounded-md border-0.5 border-border flex flex-col justify-between">
      <div className="p-3 flex flex-col">
        <span className="typ-header-small pb-0.5">Integrate with Shopify</span>
        <span className="typ-body-small text-muted mb-4">
          We'll automatically pull your theme, products, and store info.
        </span>

        <div className="flex flex-col gap-2">
          <ShopifyIntegrationSelectable
            isDisabled={isLoading}
            workspaceId={workspaceId}
            selectedIntegrationId={selectedIntegrationId}
            handleChangeSelection={setSelectedIntegrationId}
            size="sm"
          />

          <Button
            variant="primary"
            size="sm"
            layoutClassName="w-full"
            onClick={(e) => {
              void handleConnect(e);
            }}
            isLoading={isLoading}
          >
            Add Integration
          </Button>
        </div>
      </div>

      <img
        src="/images/flows/integrate-shopify.png"
        alt="Integrate with Shopify"
      />
    </div>
  );
};

export default IntegrateWithShopifyCard;
