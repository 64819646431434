import type { PendingShopifyIntegrationCookie } from "schemas/cookies";

import * as React from "react";

import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import usePendingShopifyIntegrationCookie from "@hooks/usePendingShopifyIntegrationCookie";

import { skipToken } from "@tanstack/react-query";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

/**
 * Types of connection targets we may need to process in order to possibly
 * auto-connect an existing integration or a pending app installation to a
 * project or workspace.
 *
 * This target is determined by the combination of cookies which were set when
 * the user clicked an "install shopify" button in our app, the users
 * workspace/project memberships, etc.
 *
 * - Having no auto-connect target means that the user came in from Shopify
 *   without any specific intention that our app knows about.
 * - Having a specific cookie means they came from a specific place in our app
 *   which set that cookie.
 * - Having the "automatic" target means that they didn't come from a specific
 *   place in our app, but we've determined that if they just installed Shopify,
 *   we should auto-connect the store that they came from to a project/workspace
 *   anyway. This is useful in the case where the user installed a shopify store
 *   first, before ever making an account, and we want to connect it to their
 *   first and only project after they finish the onboarding flow.
 * - Having the "wait" autoconnect target means that we should wait until there's
 *   a project/workspace and try again later (for example in onboarding, before the
 *   user has created their first workspace and project)
 */
type PendingIntegrationAutoConnectTarget =
  | PendingShopifyIntegrationCookie
  | {
      type: "automatic";
      projectId: string;
      workspaceId?: string;
    }
  | { type: "wait" };

function getTargetPathBasedOnCookieType(
  pendingIntegrationAutoConnectTarget: PendingIntegrationAutoConnectTarget | null,
) {
  if (!pendingIntegrationAutoConnectTarget) {
    return null;
  }
  return exhaustiveSwitch(pendingIntegrationAutoConnectTarget)({
    automatic: ({ projectId }) =>
      generatePath(routes.editor.project, { projectId }),
    onboarding: () => generatePath(routes.home.root),
    noShopifyErrorModal: ({ projectId, workspaceId }) =>
      projectId
        ? generatePath(routes.editor.project, { projectId })
        : generatePath(routes.workspace.billing, { workspaceId }),
    projectSettings: ({ projectId }) =>
      generatePath(routes.editor.integrations, { projectId }),
    newProject: ({ projectId }) =>
      generatePath(routes.editor.project, { projectId }),
    sections: ({ projectId }) =>
      generatePath(routes.editor.project, { projectId }),
    publish: ({ projectId }) =>
      generatePath(routes.editor.project, { projectId }),
    shopStyles: ({ projectId }) =>
      generatePath(routes.editor.project, { projectId }),
    assets: ({ projectId }) =>
      generatePath(routes.editor.project, { projectId }),
    productPicker: ({ projectId }) =>
      generatePath(routes.editor.project, { projectId }),
    integrationHub: ({ workspaceId }) =>
      generatePath(routes.workspace.integrations.shopify, {
        workspaceId,
      }),
    billingModal: ({ workspaceId }) =>
      generatePath(routes.workspace.billing, { workspaceId }),
    preview: ({ projectId }) =>
      generatePath(routes.editor.project, { projectId }),
    wait: () => null,
  });
}

/**
 * NOTE (Matt 2024-09-26): This hook is responsible for navigating a user coming
 * in from Shopify. There are several scenarios that this hooks needs to manage,
 * primarily those related to the shopify integration installation process but
 * including the use-case of a user simply clicking on Replo from within Shopify
 * after it has already been installed.
 *
 * The backend sends a user to `/home` with either a `pendingAppInstallationId`
 * or a `shopifyIntegrationId`, indicating whether it is a fresh install or if
 * the Shopify Store has already been connected to a workspace. Additionally,
 * the user may have `pendingShopifyIntegrationCookie` set, which would indicate
 * if the user had started the shopify install flow within the last 10 min from
 * within Replo. That cookie can indicate a workspace (and project, where
 * applicable) that the shopify store needs to be connected to.
 *
 * Take a look at the notes peppered throughout the useEffect to get a sense of
 * how we handle each case in this flow.
 */
export default function useConnectShopifyIntegrationNavigator() {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [searchParams] = useSearchParams();
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");
  const shopifyIntegrationId = searchParams.get("shopifyIntegrationId");

  const {
    data: integrationDataFromParams,
    isLoading: isIntegrationDataLoading,
  } = trpc.integration.get.useQuery(
    shopifyIntegrationId ? { id: shopifyIntegrationId } : skipToken,
  );
  const { data: userWorkspaces, isFetching: isUserWorkspacesDataLoading } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const { data: userProjects, isFetching: isUserProjectsLoading } =
    trpc.project.listWithStats.useQuery({});

  const { pendingShopifyIntegrationCookie } =
    usePendingShopifyIntegrationCookie();

  // NOTE (Matt 2024-09-30): If there is a pendingShopifyIntegrationCookie, then that is the target workspace
  // and/or project that we auto-connect an integration to. Alternatively, if the user only has access to 1
  // workspace and 1 project, then we autoconnect to that workspace and that project. This would only happen
  // if a user is coming from onboarding and they have not been invited to other projects or workspaces.
  const pendingIntegrationAutoConnectTarget: PendingIntegrationAutoConnectTarget | null =
    React.useMemo(() => {
      if (
        // Note (Noah, 2025-02-25): If we have a pendingShopifyIntegrationCookie,
        // then just return it. HOWEVER if we're coming from onboarding, we handle it
        // the same as the "automatic" case
        pendingShopifyIntegrationCookie &&
        pendingShopifyIntegrationCookie.type !== "onboarding"
      ) {
        return pendingShopifyIntegrationCookie;
      }
      const projects = userProjects?.projects ?? [];
      if (!projects[0] || !userWorkspaces?.workspaces[0]) {
        return { type: "wait" };
      }

      const userHasOnlyOneWorkspace = userWorkspaces.workspaces.length === 1;
      const userHasOnlyOneProject = projects.length === 1;
      const usersOnlyProjectIsOwnedByUsersOnlyWorkspace =
        userHasOnlyOneWorkspace &&
        userHasOnlyOneProject &&
        projects[0].ownerWorkspace?.id === userWorkspaces.workspaces[0].id;
      if (usersOnlyProjectIsOwnedByUsersOnlyWorkspace) {
        return {
          type: "automatic",
          projectId: projects[0].id,
          workspaceId: userWorkspaces.workspaces[0].id,
        };
      }
      return null;
    }, [pendingShopifyIntegrationCookie, userProjects, userWorkspaces]);

  const integrationOwningWorkspaceId =
    integrationDataFromParams?.integration.workspaceId;

  const integrationOwningWorkspace = userWorkspaces?.workspaces.find(
    ({ id }) => id === integrationOwningWorkspaceId,
  );
  const targetPath = getTargetPathBasedOnCookieType(
    pendingIntegrationAutoConnectTarget,
  );

  const {
    mutate: convertPendingAppInstallationToIntegration,
    isPending: isConnectingWorkspace,
    isError: isWorkspaceError,
  } = trpc.integration.convertPendingAppInstallationToIntegration.useMutation({
    onSuccess: () => {
      void trpcUtils.project.listWithStats.invalidate();
      navigate(targetPath!);
    },
  });
  const {
    mutate: connectProjectToPendingInstallation,
    isPending: isConnectingProjectToPendingInstallation,
    isError: isProjectPendingConnectionError,
  } = trpc.project.connectToPendingInstallation.useMutation({
    onSuccess: () => {
      void trpcUtils.project.listWithStats.invalidate();
      navigate(targetPath!);
    },
  });
  const {
    mutate: connectProjectToExistingIntegration,
    isPending: isConnectingProjectToIntegration,
    isError: isProjectIntegrationConnectionError,
  } = trpc.project.connectToIntegration.useMutation({
    onSuccess: () => {
      void trpcUtils.project.listWithStats.invalidate();
      navigate(targetPath!);
    },
  });

  const isMutationLoading =
    isConnectingWorkspace ||
    isConnectingProjectToPendingInstallation ||
    isConnectingProjectToIntegration;
  const isMutationError =
    isWorkspaceError ||
    isProjectPendingConnectionError ||
    isProjectIntegrationConnectionError;
  const isQueryLoading =
    isIntegrationDataLoading ||
    isUserWorkspacesDataLoading ||
    isUserProjectsLoading;

  React.useEffect(() => {
    // Note (Noah, 2025-02-25): If we're still loading then we might not have determined what to do in the
    // automatic post-onboarding case. Just return because we'll re-run this effect once we have the data
    if (isMutationLoading || isMutationError || isQueryLoading) {
      return;
    }

    // Note (Noah, 2025-02-25): If we have no pending app installation id AND no shopify integration id
    // in the params, then we didn't just come from Shopify. Just do nothing.
    if (!pendingAppInstallationId && !shopifyIntegrationId) {
      return;
    }

    // Note (Noah, 2025-02-25): Now we know that we came from Shopify, because we have a query param
    // indicating it.

    // NOTE (Matt 2024-09-26): If there is a pendingAppInstallationId, and a pendingIntegrationAutoConnectTarget
    // we should automatically connect the corresponding project or workspace to the pending install.
    if (pendingAppInstallationId) {
      if (!pendingIntegrationAutoConnectTarget) {
        // NOTE (Matt 2024-09-26): If there is a pendingAppInstallationId and no cookie,
        // we open the pendingShopifyIntegrationModal which prompts the user to connect
        // a workspace.
        openModal({ type: "pendingShopifyIntegrationModal" });
        return;
      }

      // Note (Noah, 2025-02-25): Based on where we came from, figure out the correct project
      // and/or workspace to connect the pending app installation to.
      exhaustiveSwitch(pendingIntegrationAutoConnectTarget)({
        automatic: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        wait: () => {
          return;
        },
        sections: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        noShopifyErrorModal: ({ projectId, workspaceId }) => {
          if (projectId) {
            connectProjectToPendingInstallation({
              projectId,
              shopify: { pendingAppInstallationId },
            });
          } else if (workspaceId) {
            convertPendingAppInstallationToIntegration({
              workspaceId,
              pendingAppInstallationId,
            });
          }
        },
        projectSettings: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        newProject: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        publish: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        shopStyles: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        preview: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        assets: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        productPicker: ({ projectId }) => {
          connectProjectToPendingInstallation({
            projectId,
            shopify: { pendingAppInstallationId },
          });
        },
        integrationHub: ({ workspaceId }) => {
          convertPendingAppInstallationToIntegration({
            workspaceId,
            pendingAppInstallationId,
          });
        },
        billingModal: ({ workspaceId, projectId }) => {
          if (projectId) {
            connectProjectToPendingInstallation({
              projectId,
              shopify: { pendingAppInstallationId },
            });
          } else {
            convertPendingAppInstallationToIntegration({
              workspaceId,
              pendingAppInstallationId,
            });
          }
        },
      });
      return;
    } else if (shopifyIntegrationId) {
      if (!pendingIntegrationAutoConnectTarget) {
        // NOTE (Matt 2024-09-26): If the user does not have a cookie, then they are just trying to access Replo
        // via Shopify, WITHOUT having triggered the navigation to Shopify through some place in Replo, then we
        // take them to the integration-owning workspace's projects list. If they don't have access to the workspace,
        // they'll see a message on that route.
        //
        // Note (Noah, 2025-02-26): since we know we have a shopifyIntegrationId, it should be implied that
        // there's an integrationOwningWorkspaceId as well. If for some godforsaken reason there isn't, just
        // do nothing out of caution. This whole flow is going away when we switch to JWT auth so that we can
        // figure out the correct redirect on the server instead.
        if (integrationOwningWorkspaceId) {
          navigate(
            generatePath(routes.workspace.projects, {
              workspaceId: integrationOwningWorkspaceId,
            }),
          );
          searchParams.delete("shopifyIntegrationId");
        }
        return;
      }

      // Note (Noah, 2025-02-25): We now do not have a pending app installation
      // id, so we must have a shopifyIntegrationId. This means that the shopify
      // integration was already installed and connected to a workspace.
      // However, it might not be connected to the project we want yet. If this
      // is the case, and we came from a workspace which owns the integration
      // and a project which was not yet connected, we'll try to connect the
      // existing integration to the project.
      const applicableWorkspaceId = exhaustiveSwitch(
        pendingIntegrationAutoConnectTarget,
      )({
        automatic: ({ workspaceId }) => workspaceId,
        sections: ({ workspaceId }) => workspaceId,
        noShopifyErrorModal: ({ workspaceId }) => workspaceId,
        projectSettings: ({ workspaceId }) => workspaceId,
        newProject: ({ workspaceId }) => workspaceId,
        publish: ({ workspaceId }) => workspaceId,
        shopStyles: ({ workspaceId }) => workspaceId,
        preview: ({ workspaceId }) => workspaceId,
        assets: ({ workspaceId }) => workspaceId,
        productPicker: ({ workspaceId }) => workspaceId,
        integrationHub: ({ workspaceId }) => workspaceId,
        billingModal: ({ workspaceId }) => workspaceId,
        wait: () => null,
      });

      if (integrationOwningWorkspaceId === applicableWorkspaceId) {
        // Note (Noah, 2025-02-25): We came from a workspace which IS the same as the one
        // which already owns the integration, so the only thing to do is connect the integration
        // to a specific project, if we came to a specific project. Otherwise, redirect back
        // to where we came from.
        const projectId = exhaustiveSwitch(pendingIntegrationAutoConnectTarget)(
          {
            automatic: ({ projectId }) => projectId,
            sections: ({ projectId }) => projectId,
            noShopifyErrorModal: ({ projectId }) => projectId,
            projectSettings: ({ projectId }) => projectId,
            newProject: ({ projectId }) => projectId,
            publish: ({ projectId }) => projectId,
            shopStyles: ({ projectId }) => projectId,
            preview: ({ projectId }) => projectId,
            assets: ({ projectId }) => projectId,
            productPicker: ({ projectId }) => projectId,
            integrationHub: () => null,
            billingModal: () => null,
            wait: () => null,
          },
        );
        if (projectId) {
          connectProjectToExistingIntegration({
            projectId,
            integrationId: shopifyIntegrationId,
          });
        } else {
          // Navigate to target path
          navigate(targetPath!);
        }
      } else {
        // Note (Noah, 2025-02-25): The referenced Shopify integration is connected to a workspace
        // which is DIFFERENT from the one we came to Shopify from. So we need to show a message -
        // either that the user is not a member of that workspace, or that the workspace does not
        // own the integration and they should disconnect/reconnect if they want to associate it
        // with a different workspace.
        const isUserMemberOfIntegrationOwningWorkspace = Boolean(
          integrationOwningWorkspace,
        );
        if (!isUserMemberOfIntegrationOwningWorkspace) {
          // NOTE (Matt 2024-09-26): At this point, this means the user has attempted to add a shopify store to Replo, but that Shopify Store has been installed
          // by a workspace the user does not belong to. We explain this error and tell them to reach out to support.
          openModal({
            type: "fullPageErrorModal",
            props: {
              details: {
                header: "Shopify Store already installed",
                message:
                  "This Shopify Store is already installed on another workspace that you do not have access to. In order to install this Shopify Store on your workspace, get access from the workspace admin, or contact support@replo.app for help.",
                callToAction: {
                  type: "closeModal",
                  name: "Close",
                },
              },
            },
          });
        } else {
          // NOTE (Matt 2024-09-26): If the user is a member of the workspace that owns the integration, but they have a cookie whose
          // workspaceId does not match that of the workspace that owns the integration, we need to inform them that what they are
          // trying to do won't work, and link them to the integrations hub for the workspace that owns the integration.
          openModal({
            type: "fullPageErrorModal",
            props: {
              details: {
                header: "Shopify Store already installed",
                message: `This Shopify Integration is already installed on another workspace that you belong to. In order to install this Shopify Integration on a different workspace, you will first need to uninstall it from "${integrationOwningWorkspace!.name}".`,
                isFullPage: true,
                callToAction: {
                  type: "callback",
                  name: `View ${integrationOwningWorkspace!.name}`,
                  onClick: () => {
                    navigate(
                      generatePath(routes.workspace.integrations.shopify, {
                        workspaceId: integrationOwningWorkspaceId,
                      }),
                    );
                    closeModal({ type: "fullPageErrorModal" });
                  },
                },
              },
            },
          });
        }
      }
      searchParams.delete("shopifyIntegrationId");
    } else {
      // Note (Noah, 2025-02-25): This case will never be reached!
    }
  }, [
    pendingAppInstallationId,
    shopifyIntegrationId,
    isMutationLoading,
    isMutationError,
    isQueryLoading,
    pendingIntegrationAutoConnectTarget,
    integrationOwningWorkspaceId,
    integrationOwningWorkspace,
    convertPendingAppInstallationToIntegration,
    connectProjectToPendingInstallation,
    connectProjectToExistingIntegration,
    openModal,
    closeModal,
    navigate,
    targetPath,
    searchParams,
  ]);
}
