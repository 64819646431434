import type { OverlapSize } from "./ColorPaletteDisplay";

import React from "react";

import { COLOR_DEFAULTS } from "@editor/components/common/constants/colorStyles";
import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { setLeftBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorDispatch } from "@editor/store";

import ColorPaletteDisplay from "./ColorPaletteDisplay";

interface ShopStylesColorDisplayProps {
  overlapSize: OverlapSize;
  className?: string;
}

const ShopStylesColorDisplay: React.FC<ShopStylesColorDisplayProps> = ({
  overlapSize,
  className,
}) => {
  const { colorShopStyles } = useShopStyles();
  const dispatch = useEditorDispatch();

  // Use saved styles if available, otherwise use defaults
  const colors =
    colorShopStyles.length > 0
      ? colorShopStyles.slice(0, 6).map((style) => style.attributes.color)
      : Object.values(COLOR_DEFAULTS).slice(0, 6);

  const handleOpenBrandDetails = () => {
    dispatch(setLeftBarActiveTab("shopStyles"));
  };

  return (
    <ColorPaletteDisplay
      colors={colors}
      overlapSize={overlapSize}
      className={className}
      onClick={handleOpenBrandDetails}
    />
  );
};

export default ShopStylesColorDisplay;
