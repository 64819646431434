import * as React from "react";

import { WorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import { initWorkspaceBasedAnalytics } from "@editor/infra/analytics";
import { routes } from "@editor/utils/router";
import { handleTRPCClientError, trpc } from "@editor/utils/trpc";

import { errorToast } from "@replo/design-system/components/alert/Toast";
import { skipToken } from "@tanstack/react-query";
import { TRPCClientError } from "@trpc/client";
import { TRPC_ERROR_CODES_BY_KEY } from "@trpc/server/unstable-core-do-not-import";
import { useNavigate } from "react-router-dom";

const WorkspaceProvider: React.FC<
  React.PropsWithChildren<{
    workspaceId?: string;
    saveWorkspaceIdAsLastVisited?: boolean;
    showToastOnNoAccess?: boolean;
    redirectOnNoAccess?: boolean;
  }>
> = ({
  workspaceId,
  children,
  saveWorkspaceIdAsLastVisited = false,
  showToastOnNoAccess = false,
  redirectOnNoAccess = false,
}) => {
  const {
    data: workspaceData,
    isPending: isLoadingWorkspace,
    error: workspaceError,
  } = trpc.workspace.getById.useQuery(
    workspaceId ? { id: workspaceId } : skipToken,
    {
      meta: { reploIgnoreDefaultErrorHandling: true },
      retry: (failureCount, error) => {
        // Note (Noah, 2025-02-26): Don't retry workspace access errors, they'll
        // just fail again
        if (error.shape?.code === TRPC_ERROR_CODES_BY_KEY.FORBIDDEN) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      workspaceError &&
      workspaceError instanceof TRPCClientError &&
      workspaceError.shape?.code === TRPC_ERROR_CODES_BY_KEY.FORBIDDEN
    ) {
      if (showToastOnNoAccess) {
        errorToast(
          "No access to workspace",
          "You don't have access to the requested workspace. Please reach out to support@replo.app with questions.",
        );
      }
      if (redirectOnNoAccess) {
        navigate(routes.allProjects);
      }
    } else if (workspaceError) {
      handleTRPCClientError(workspaceError);
    }
  }, [workspaceError, showToastOnNoAccess, redirectOnNoAccess, navigate]);

  // Update last visited workspace id in localstorage when needed
  React.useEffect(() => {
    // Note (Noah, 2025-02-26): If the workspace id wasn't specified or we weren't able
    // to load it, then don't save it as the last visited workspace since it was just
    // requested, not visited (this is e.g. the case if you have a single-project
    // guest account try to access the workspace via url).
    if (!workspaceId || !saveWorkspaceIdAsLastVisited || !workspaceData) {
      return;
    }
    localStorage.setItem("replo.workspace.lastvisitedWorkspaceId", workspaceId);
  }, [workspaceId, saveWorkspaceIdAsLastVisited, workspaceData]);

  // NOTE Ben 2024-08-16: We initialize workspace analytics here because all
  // workspace - based components (tabs for analytics + settings, etc) are
  // used as interior components.
  // Move to workspace provider pls
  React.useEffect(() => {
    if (workspaceData) {
      initWorkspaceBasedAnalytics(workspaceData.workspace);
    }
  }, [workspaceData]);

  return (
    <WorkspaceDashboardContext.Provider
      value={{
        workspaceId: workspaceId ?? null,
        workspace: workspaceData?.workspace ?? null,
        isLoading: isLoadingWorkspace || !Boolean(workspaceId),
      }}
    >
      {children}
    </WorkspaceDashboardContext.Provider>
  );
};

export default WorkspaceProvider;
