import type { Component, ReploComponentType } from "schemas/component";
import type { ReploSymbol } from "schemas/generated/symbol";

import * as React from "react";

import GoogleMaps from "@svg/google-maps";
import Klaviyo from "@svg/klaviyo";
import Shopify from "@svg/shopify";
import { sanitizeTreeContent } from "@utils/dom";

import get from "lodash-es/get";
import {
  AlignJustify,
  ArrowUp01,
  BetweenHorizonalStart,
  Boxes,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
  Circle,
  CircleDollarSign,
  Clock,
  Code2,
  Columns2,
  Diamond,
  Folder,
  FolderClosed,
  Fullscreen,
  GalleryHorizontalEnd,
  GalleryThumbnails,
  Grid,
  Image,
  Images,
  Infinity,
  Info,
  LayoutGrid,
  ListCollapse,
  Loader,
  Logs,
  MoreHorizontal,
  NotepadTextDashed,
  PanelRightDashed,
  PanelRightOpen,
  Phone,
  Play,
  PlayCircle,
  RectangleEllipsis,
  Rows4,
  Shapes,
  ShoppingBag,
  ShoppingBasket,
  ShoppingCart,
  Square,
  SquareMousePointer,
  Star,
  Tally5,
  TicketMinus,
  ToggleRight,
  Type,
  VolumeX,
  Vote,
  Zap,
} from "lucide-react";
import { isDynamicDataValue } from "replo-runtime";
import { resolveSymbolRef } from "replo-runtime/shared/symbol";
import { getDynamicDataBreadcrumbs } from "replo-runtime/shared/utils/dynamic-data";
import { iconDirectory } from "replo-runtime/store/iconDirectory";

interface ComponentDirectoryItem {
  formatName(
    component: Component,
    extras: { symbols: Record<string, ReploSymbol> | null },
  ): string;
  getIcon(props: { direction?: string; iconName?: string }): any;
  defaultName: string;
  documentationLink: string | null;
}

const getIcon = (Icon: any) => {
  return <Icon size={16} />;
};

export const getComponentEditorData = (
  componentType: string,
): ComponentDirectoryItem | null => {
  return get(componentTypeDirectory, componentType, null);
};

const getDocumentationUrl = (
  slug: string,
  type: "component" | "integrations" | "building",
) => {
  const baseUrl = "https://docs.replo.app";
  const documentationUrl = {
    building: `${baseUrl}/docs/building/${slug}`,
    component: `${baseUrl}/docs/components/${slug}`,
    integrations: `${baseUrl}/docs/integrations/${slug}`,
  };
  return documentationUrl[type];
};

const getTextData = (name: string) => {
  return {
    formatName: (component: Component) => {
      if (component.props.text?.length) {
        if (isDynamicDataValue(component.props.text)) {
          return getDynamicDataBreadcrumbs(component.props.text);
        }
        return sanitizeTreeContent(component.props.text);
      }
      return name;
    },
    defaultName: name,
    getIcon: function Icon() {
      return getIcon(Type);
    },
    documentationLink: getDocumentationUrl("text", "component"),
  };
};

export const componentTypeDirectory: Record<
  ReploComponentType,
  ComponentDirectoryItem
> = {
  container: {
    formatName: () => {
      return "Container";
    },
    defaultName: "Container",
    getIcon: () => {
      return getIcon(Columns2);
    },
    documentationLink: getDocumentationUrl("containers", "component"),
  },
  circle: {
    formatName: () => {
      return "Circle";
    },
    defaultName: "Circle",
    getIcon: () => {
      return getIcon(Circle);
    },
    documentationLink: getDocumentationUrl("containers", "component"),
  },
  toggleContainer: {
    formatName: () => {
      return "Toggle Container";
    },
    defaultName: "Toggle Container",
    getIcon: () => {
      return getIcon(Columns2);
    },
    documentationLink: getDocumentationUrl("containers", "component"),
  },
  toggleIndicator: {
    formatName: () => {
      return "Toggle Indicator";
    },
    defaultName: "Toggle Indicator",
    getIcon: () => {
      return getIcon(Columns2);
    },
    documentationLink: getDocumentationUrl("containers", "component"),
  },
  collapsible: {
    formatName: () => {
      return "Collapsible";
    },
    defaultName: "Collapsible",
    getIcon: function Icon() {
      return getIcon(ChevronRight);
    },
    documentationLink: getDocumentationUrl("collapsibles", "component"),
  },
  marquee: {
    formatName: () => {
      return "Ticker";
    },
    defaultName: "Ticker",
    getIcon: function Icon() {
      return getIcon(TicketMinus);
    },
    documentationLink: getDocumentationUrl("ticker", "component"),
  },
  countdownTimer: {
    formatName: () => {
      return "Countdown Timer";
    },
    defaultName: "Countdown Timer",
    getIcon: function Icon() {
      return getIcon(Clock);
    },
    documentationLink: null,
  },
  rechargeSubscriptionWidget: {
    formatName: () => {
      return "Recharge Subscription Widget";
    },
    defaultName: "Recharge Subscription Widget",
    getIcon: function Icon() {
      return getIcon(Infinity);
    },
    documentationLink: getDocumentationUrl("recharge", "integrations"),
  },
  staySubscriptionWidget: {
    formatName: () => {
      return "Stay Subscription Widget";
    },
    defaultName: "Stay Subscription Widget",
    getIcon: function Icon() {
      return getIcon(Infinity);
    },
    documentationLink:
      "https://support.replo.app/hc/en-us/articles/23634300590605-Stay-Ai-Subscription-Widget",
  },
  okendoProductRatingSummary: {
    formatName: () => {
      return "Okendo Product Rating";
    },
    defaultName: "Okendo Product Rating",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: null,
  },
  okendoReviewsWidget: {
    formatName: () => {
      return "Okendo Reviews Widget";
    },
    defaultName: "Okendo Reviews Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: null,
  },
  tabsBlock: {
    formatName: () => {
      return "Tabs Block";
    },
    defaultName: "Tabs Block",
    getIcon: function Icon() {
      return getIcon(Folder);
    },
    documentationLink: getDocumentationUrl("tabs", "component"),
  },
  tabs__list: {
    formatName: () => {
      return "Tabs Menu";
    },
    defaultName: "Tabs Menu",
    getIcon: function Icon() {
      return getIcon(AlignJustify);
    },
    documentationLink: getDocumentationUrl("tabs", "component"),
  },
  tabs__panelsContent: {
    formatName: () => {
      return "Tabs Content";
    },
    defaultName: "Tabs Content",
    getIcon: function Icon() {
      return getIcon(AlignJustify);
    },
    documentationLink: getDocumentationUrl("tabs", "component"),
  },
  tabs__onePanelContent: {
    formatName: () => {
      return "Tab Panel";
    },
    defaultName: "Tab Panel",
    getIcon: function Icon() {
      return getIcon(AlignJustify);
    },
    documentationLink: getDocumentationUrl("tabs", "component"),
  },
  tabsV2__block: {
    formatName: () => {
      return "Tabs Block";
    },
    defaultName: "Tabs Block",
    getIcon: function Icon() {
      return getIcon(FolderClosed);
    },
    documentationLink: getDocumentationUrl("tabs", "component"),
  },
  tabsV2__list: {
    formatName: () => {
      return "Tabs Menu";
    },
    defaultName: "Tabs Menu",
    getIcon: function Icon() {
      return getIcon(AlignJustify);
    },
    documentationLink: getDocumentationUrl("tabs", "component"),
  },
  tabsV2__panelsContent: {
    formatName: () => {
      return "Tabs Content";
    },
    defaultName: "Tabs Content",
    getIcon: function Icon() {
      return getIcon(AlignJustify);
    },
    documentationLink: getDocumentationUrl("tabs", "component"),
  },
  collection: {
    formatName: () => {
      return "Data Collection List";
    },
    defaultName: "Data Collection List",
    getIcon: function Icon() {
      return getIcon(LayoutGrid);
    },
    documentationLink: getDocumentationUrl("data-collections", "building"),
  },
  collectionV2: {
    formatName: () => {
      return "Dynamic Collection";
    },
    defaultName: "Dynamic Collection",
    getIcon: function Icon() {
      return getIcon(LayoutGrid);
    },
    documentationLink: null,
  },
  klaviyoEmbed: {
    formatName: () => {
      return "Klaviyo Form Embed";
    },
    defaultName: "Klaviyo Form Embed",
    getIcon: function Icon() {
      // Arbitrary, but 68% just looks the best with this particular SVG
      return getIcon(Klaviyo);
    },
    documentationLink: getDocumentationUrl("klaviyo", "integrations"),
  },
  vimeoEmbed: {
    formatName: () => {
      return "Vimeo Embed";
    },
    defaultName: "Vimeo Embed",
    getIcon: function Icon() {
      return getIcon(PlayCircle);
    },
    documentationLink: getDocumentationUrl("vimeo", "integrations"),
  },
  vimeoEmbedV2: {
    formatName: () => {
      return "Vimeo Embed";
    },
    defaultName: "Vimeo Embed",
    getIcon: function Icon() {
      return getIcon(PlayCircle);
    },
    documentationLink: getDocumentationUrl("vimeo", "integrations"),
  },
  youtubeEmbed: {
    formatName: () => {
      return "Youtube Embed";
    },
    defaultName: "Youtube Embed",
    getIcon: function Icon() {
      return getIcon(PlayCircle);
    },
    documentationLink: getDocumentationUrl("youtube", "integrations"),
  },
  youtubeEmbedV2: {
    formatName: () => {
      return "Youtube Embed";
    },
    defaultName: "Youtube Embed",
    getIcon: function Icon() {
      return getIcon(PlayCircle);
    },
    documentationLink: getDocumentationUrl("youtube", "integrations"),
  },
  tikTokEmbed: {
    formatName: () => {
      return "TikTok Embed";
    },
    defaultName: "TikTok Embed",
    getIcon: function Icon() {
      return getIcon(Phone);
    },
    documentationLink: getDocumentationUrl("tiktok", "integrations"),
  },
  temporaryCart: {
    formatName: () => {
      return "Temporary Cart";
    },
    defaultName: "Temporary Cart",
    getIcon: function Icon() {
      return getIcon(ShoppingCart);
    },
    documentationLink: null,
  },
  temporaryCartItems: {
    formatName: () => {
      return "Temporary Cart Products";
    },
    defaultName: "Temporary Cart Products",
    getIcon: function Icon() {
      return getIcon(ShoppingCart);
    },
    documentationLink: null,
  },
  googleMapsEmbed: {
    formatName: () => {
      return "Google Maps Embed";
    },
    defaultName: "Google Maps Embed",
    getIcon: function Icon() {
      // Arbitrary, but this width just looks the best with this particular SVG
      return getIcon(GoogleMaps);
    },
    documentationLink: getDocumentationUrl("google-maps", "integrations"),
  },
  collectionSelect: {
    formatName: () => {
      return "Collection Select";
    },
    defaultName: "Collection Select",
    getIcon: function Icon() {
      return getIcon(Rows4);
    },
    documentationLink: null,
  },
  variantSelect: {
    formatName: () => {
      return "Variant List";
    },
    defaultName: "Variant List",
    getIcon: function Icon() {
      return getIcon(Rows4);
    },
    documentationLink: null,
  },
  sellingPlanSelect: {
    formatName: () => {
      return "Selling Plan List";
    },
    defaultName: "Selling Plan List",
    getIcon: function Icon() {
      return getIcon(Rows4);
    },
    documentationLink: null,
  },
  optionSelect: {
    formatName: () => {
      return "Option List";
    },
    defaultName: "Option List",
    getIcon: () => {
      return getIcon(Rows4);
    },
    documentationLink: null,
  },
  dropdown: {
    formatName: () => {
      return "Dropdown";
    },
    defaultName: "Dropdown",
    getIcon: function Icon() {
      return getIcon(Rows4);
    },
    documentationLink: null,
  },
  variantSelectDropdown: {
    formatName: () => {
      return "Variant Dropdown";
    },
    defaultName: "Variant Dropdown",
    getIcon: function Icon() {
      return getIcon(Rows4);
    },
    documentationLink: null,
  },
  optionSelectDropdown: {
    formatName: () => {
      return "Option Dropdown";
    },
    defaultName: "Option Dropdown",
    getIcon: () => {
      return getIcon(Rows4);
    },
    documentationLink: null,
  },
  sellingPlanSelectDropdown: {
    formatName: () => {
      return "Selling Plan Dropdown";
    },
    defaultName: "Selling Plan Dropdown",
    getIcon: function Icon() {
      return getIcon(Rows4);
    },
    documentationLink: null,
  },
  symbolRef: {
    defaultName: "Symbol",
    formatName: (component, extras) => {
      const resolved = resolveSymbolRef(component, extras.symbols);
      if (!resolved) {
        return "Symbol";
      }

      const componentEditorData = getComponentEditorData(resolved.type);

      if (!componentEditorData) {
        return "Symbol";
      }

      return componentEditorData.formatName(resolved, extras);
    },
    getIcon: function Icon() {
      return getIcon(Diamond);
    },
    documentationLink: null,
  },
  spacer: {
    formatName: () => {
      return "Spacer";
    },
    defaultName: "Spacer",
    getIcon: function Icon() {
      return getIcon(Square);
    },
    documentationLink: null,
  },
  icon: {
    formatName: (component) => {
      // @ts-ignore
      return iconDirectory[component.props.iconName]?.displayName || "Icon";
    },
    defaultName: "Icon",
    getIcon: function Icon({ iconName }) {
      return getIcon(iconDirectory[String(iconName)]?.Component || Grid);
    },
    documentationLink: getDocumentationUrl("icons", "component"),
  },
  button: {
    formatName: (component) => {
      // @ts-ignore
      if (component.props.text?.length > 0) {
        // @ts-ignore
        return sanitizeTreeContent(component.props.text);
      }
      return "Button";
    },
    defaultName: "Button",
    getIcon: function Icon() {
      return getIcon(Zap);
    },
    documentationLink: null,
  },
  player: {
    formatName: () => {
      return "Video Player";
    },
    defaultName: "Video Player",
    getIcon: function Icon() {
      return getIcon(PlayCircle);
    },
    documentationLink: getDocumentationUrl("videos", "component"),
  },
  player__playIcon: {
    formatName: () => {
      return "Play Button";
    },
    defaultName: "Play Button",
    getIcon: function Icon() {
      return getIcon(Play);
    },
    documentationLink: getDocumentationUrl("videos", "component"),
  },
  player__muteIcon: {
    formatName: () => {
      return "Mute Button";
    },
    defaultName: "Mute Button",
    getIcon: function Icon() {
      return getIcon(VolumeX);
    },
    documentationLink: getDocumentationUrl("videos", "component"),
  },
  player__fullScreenIcon: {
    formatName: () => {
      return "Full Screen Button";
    },
    defaultName: "Full Screen Button",
    getIcon: function Icon() {
      return getIcon(Fullscreen);
    },
    documentationLink: getDocumentationUrl("videos", "component"),
  },
  starRating: {
    formatName: () => "Star Rating",
    defaultName: "Star Rating",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: null,
  },
  carouselV2: {
    formatName: () => "Carousel",
    defaultName: "Carousel",
    getIcon: function Icon() {
      return getIcon(GalleryThumbnails);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  carouselV2__panels: {
    formatName: () => "Carousel Panels",
    defaultName: "Carousel Panels",
    getIcon: function Icon() {
      return getIcon(GalleryHorizontalEnd);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  carouselV2__indicator: {
    formatName: () => "Carousel Indicator",
    defaultName: "Carousel Indicator",
    getIcon: function Icon() {
      return getIcon(MoreHorizontal);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  carouselV3: {
    formatName: () => "Carousel",
    defaultName: "Carousel",
    getIcon: function Icon() {
      return getIcon(GalleryThumbnails);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  carouselV3Slides: {
    formatName: () => "Slides",
    defaultName: "Slides",
    getIcon: function Icon() {
      return getIcon(GalleryHorizontalEnd);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  carouselV3Control: {
    formatName: ({ props }) => {
      return `${props.direction === "next" ? "Next" : "Previous"}`;
    },
    defaultName: "Control",
    getIcon: function Icon({ direction }) {
      return getIcon(direction === "next" ? ChevronRight : ChevronLeft);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  carouselV3Indicators: {
    formatName: () => "Indicators",
    defaultName: "Indicators",
    getIcon: function Icon() {
      return getIcon(RectangleEllipsis);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  carouselPanelsCount: {
    formatName: () => "Panels Count",
    defaultName: "Panels Count",
    getIcon: function Icon() {
      return getIcon(Tally5);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  slidingCarousel: {
    formatName: () => "Sliding Carousel",
    defaultName: "Sliding Carousel",
    getIcon: function Icon() {
      return getIcon(PanelRightOpen);
    },
    documentationLink: getDocumentationUrl("carousel", "component"),
  },
  modal: {
    formatName: () => "Popup",
    defaultName: "Popup",
    getIcon: function Icon() {
      return getIcon(SquareMousePointer);
    },
    documentationLink: null,
  },
  text: getTextData("Text"),
  collapsibleV2: {
    formatName: () => {
      return "Collapsible";
    },
    defaultName: "Collapsible",
    getIcon: function Icon() {
      return getIcon(BetweenHorizonalStart);
    },
    documentationLink: null,
  },
  collapsibleV2Header: {
    formatName: () => {
      return "Collapsible Header";
    },
    defaultName: "Collapsible Header",
    getIcon: function Icon() {
      return getIcon(ChevronRight);
    },
    documentationLink: getDocumentationUrl("containers", "component"),
  },
  collapsibleV2Content: {
    formatName: () => {
      return "Collapsible Content";
    },
    defaultName: "Collapsible Content",
    getIcon: function Icon() {
      return getIcon(ChevronsRight);
    },
    documentationLink: getDocumentationUrl("containers", "component"),
  },
  product: {
    formatName: () => {
      return "Product";
    },
    defaultName: "Product",
    getIcon: function Icon() {
      return getIcon(ShoppingBag);
    },
    documentationLink: getDocumentationUrl("products", "component"),
  },
  productCollection: {
    formatName: () => {
      return "Product Collection";
    },
    defaultName: "Product Collection",
    getIcon: function Icon() {
      return getIcon(Shapes);
    },
    documentationLink: null,
  },
  quantitySelector: {
    formatName: () => {
      return "Quantity Selector";
    },
    defaultName: "Quantity Selector",
    getIcon: function Icon() {
      return getIcon(ArrowUp01);
    },
    documentationLink: null,
  },
  image: {
    formatName: () => {
      return "Image";
    },
    defaultName: "Image",
    getIcon: function Icon() {
      return getIcon(Image);
    },
    documentationLink: getDocumentationUrl("images", "component"),
  },
  shopifySection: {
    formatName: () => {
      return "Shopify Section";
    },
    defaultName: "Shopify Section",
    getIcon: function Icon() {
      return getIcon(Shopify);
    },
    documentationLink: null,
  },
  shopifyRawLiquid: {
    formatName: () => {
      return "Shopify Liquid Content";
    },
    defaultName: "Shopify Liquid Content",
    getIcon: function Icon() {
      return getIcon(Shopify);
    },
    documentationLink: getDocumentationUrl("html-liquid", "component"),
  },
  shopifyAppBlocks: {
    formatName: () => {
      return "Shopify App Blocks";
    },
    defaultName: "Shopify App Blocks",
    getIcon: function Icon() {
      return getIcon(Shopify);
    },
    documentationLink: getDocumentationUrl(
      "shopify-app-blocks",
      "integrations",
    ),
  },
  rawHtmlContent: {
    formatName: () => {
      return "HTML Content";
    },
    defaultName: "HTML Content",
    getIcon: function Icon() {
      return getIcon(Code2);
    },
    documentationLink: getDocumentationUrl(
      "custom-html-and-liquid",
      "component",
    ),
  },
  h1: getTextData("h1"),
  h2: getTextData("h2"),
  h3: getTextData("h3"),
  spinner: {
    formatName: () => {
      return "Spinner";
    },
    defaultName: "Spinner",
    getIcon: function Icon() {
      return getIcon(Loader);
    },
    documentationLink: null,
  },
  junipProductRating: {
    formatName: () => {
      return "Junip Product Rating";
    },
    defaultName: "Junip Product Rating",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("junip", "integrations"),
  },
  junipReviews: {
    formatName: () => {
      return "Junip Reviews";
    },
    defaultName: "Junip Reviews",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("junip", "integrations"),
  },
  yotpoProductRating: {
    formatName: () => {
      return "Yotpo Product Rating";
    },
    defaultName: "Yotpo Product Rating",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("yotpo", "integrations"),
  },
  yotpoReviews: {
    formatName: () => {
      return "Yotpo Reviews";
    },
    defaultName: "Yotpo Reviews",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("yotpo", "integrations"),
  },
  knoCommerceWidget: {
    formatName: () => {
      return "KnoCommerce Widget";
    },
    defaultName: "KnoCommerce Widget",
    getIcon: function Icon() {
      return getIcon(Vote);
    },
    documentationLink: getDocumentationUrl("knocommerce", "integrations"),
  },
  looxProductRating: {
    formatName: () => {
      return "Loox Product Rating";
    },
    defaultName: "Loox Product Rating",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("loox", "integrations"),
  },
  looxReviews: {
    formatName: () => {
      return "Loox Reviews";
    },
    defaultName: "Loox Reviews",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("loox", "integrations"),
  },
  reviewsIoProductRating: {
    formatName: () => {
      return "Reviews.io Product Rating";
    },
    defaultName: "Reviews.io Product Rating",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: null,
  },
  reviewsIoReviews: {
    formatName: () => {
      return "Reviews.io Reviews";
    },
    defaultName: "Reviews.io Reviews",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: null,
  },
  dynamicCheckoutButtons: {
    formatName: () => {
      return "Dynamic Checkout Buttons";
    },
    defaultName: "Dynamic Checkout Buttons",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: null,
  },
  paymentTerms: {
    formatName: () => {
      return "Payment Terms";
    },
    defaultName: "Payment Terms",
    getIcon: function Icon() {
      return getIcon(CircleDollarSign);
    },
    documentationLink: null,
  },
  accordionBlock: {
    formatName: () => {
      return "Accordion Block";
    },
    defaultName: "Accordion Block",
    getIcon: function Icon() {
      return getIcon(ListCollapse);
    },
    documentationLink: null,
  },
  rebuyWidget: {
    formatName: () => {
      return "Rebuy Widget";
    },
    defaultName: "Rebuy Widget",
    getIcon: function Icon() {
      return getIcon(Infinity);
    },
    documentationLink: getDocumentationUrl("rebuy", "integrations"),
  },
  buyWithPrimeButton: {
    formatName: () => {
      return "Buy With Prime Widget";
    },
    defaultName: "Buy With Prime Widget",
    getIcon: function Icon() {
      return getIcon(ShoppingBasket);
    },
    documentationLink: null,
  },
  stampedProductReviewsWidget: {
    formatName: () => {
      return "Stamped Product Reviews Widget";
    },
    defaultName: "Stamped Product Reviews Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("stamped", "integrations"),
  },
  feraProductRatingWidget: {
    formatName: () => {
      return "Fera Product Rating Widget";
    },
    defaultName: "Fera Product Rating Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("fera", "integrations"),
  },
  stampedProductRatingWidget: {
    formatName: () => {
      return "Stamped Product Rating Widget";
    },
    defaultName: "Stamped Product Rating Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("stamped", "integrations"),
  },
  shopifyProductReviewsWidget: {
    formatName: () => {
      return "Shopify Product Reviews Widget";
    },
    defaultName: "Shopify Product Reviews Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("shopify-reviews", "integrations"),
  },
  feraProductReviewsWidget: {
    formatName: () => {
      return "Fera Product Reviews Widget";
    },
    defaultName: "Fera Product Reviews Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("fera", "integrations"),
  },
  feraStoreReviewsWidget: {
    formatName: () => {
      return "Fera Store Reviews Widget";
    },
    defaultName: "Fera Store Reviews Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("fera", "integrations"),
  },
  feraMediaGalleryWidget: {
    formatName: () => {
      return "Fera Media Gallery Widget";
    },
    defaultName: "Fera Media Gallery Widget",
    getIcon: function Icon() {
      return getIcon(Images);
    },
    documentationLink: getDocumentationUrl("fera", "integrations"),
  },
  shopifyProductRatingWidget: {
    formatName: () => {
      return "Shopify Product Rating Widget";
    },
    defaultName: "Shopify Product Rating Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("shopify-reviews", "integrations"),
  },
  judgeProductRatingWidget: {
    formatName: () => {
      return "Judge.me Product Rating Widget";
    },
    defaultName: "Judge.me Product Rating Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("judgeme", "integrations"),
  },
  judgeProductReviewsWidget: {
    formatName: () => {
      return "Judge.me Product Reviews Widget";
    },
    defaultName: "Judge.me Product Reviews Widget",
    getIcon: function Icon() {
      return getIcon(Star);
    },
    documentationLink: getDocumentationUrl("judgeme", "integrations"),
  },
  infiniteOptionsWidget: {
    formatName: () => {
      return "Infinite Options Widget";
    },
    defaultName: "Infinite Options Widget",
    getIcon: function Icon() {
      return getIcon(ToggleRight);
    },
    documentationLink: getDocumentationUrl("infinite-options", "integrations"),
  },
  kachingBundles: {
    formatName: () => {
      return "Kaching Bundles";
    },
    defaultName: "Kaching Bundles",
    getIcon: function Icon() {
      return getIcon(Boxes);
    },
    documentationLink: null,
  },
  postscriptSignupForm: {
    formatName: () => {
      return "Postscript Signup Form";
    },
    defaultName: "Postscript Signup Form",
    getIcon: function Icon() {
      return getIcon(NotepadTextDashed);
    },
    documentationLink: getDocumentationUrl("postscript", "integrations"),
  },
  tooltip: {
    formatName: () => {
      return "Tooltip";
    },
    defaultName: "Tooltip",
    getIcon: function Icon() {
      return getIcon(Info);
    },
    documentationLink: getDocumentationUrl("tooltip", "component"),
  },
  tooltipContent: {
    formatName: () => {
      return "Tooltip Content";
    },
    defaultName: "Tooltip Content",
    getIcon: function Icon() {
      return getIcon(Info);
    },
    documentationLink: getDocumentationUrl("containers", "component"),
  },
  subscribeAndSave: {
    formatName: () => {
      return "Subscribe & Save";
    },
    defaultName: "Subscribe & Save",
    getIcon: () => {
      return getIcon(Infinity);
    },
    documentationLink: null,
  },
  beforeAfterSlider: {
    formatName: () => {
      return "Before After Slide";
    },
    defaultName: "Before After Slide",
    getIcon: () => {
      return getIcon(PanelRightDashed);
    },
    documentationLink: null,
  },
  beforeAfterSliderThumb: {
    formatName: () => {
      return "Slider";
    },
    defaultName: "Slider",
    getIcon: () => {
      return getIcon(Columns2);
    },
    documentationLink: null,
  },
  beforeAfterSliderBeforeContent: {
    formatName: () => {
      return "Before Content";
    },
    defaultName: "Before Content",
    getIcon: () => {
      return getIcon(Columns2);
    },
    documentationLink: null,
  },
  beforeAfterSliderAfterContent: {
    formatName: () => {
      return "After Content";
    },
    defaultName: "After Content",
    getIcon: () => {
      return getIcon(Columns2);
    },
    documentationLink: null,
  },
  selectionList: {
    formatName: () => {
      return "Selection List";
    },
    defaultName: "Selection List",
    getIcon: () => {
      return getIcon(Logs);
    },
    documentationLink: null,
  },
};
