import type { FlowStepConfigPropsValueOf } from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import Input from "@editor/components/common/designSystem/Input";
import { useGetCurrentStepResultsData } from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import useIdentifyOtherValue from "@editor/components/flows/hooks/useIdentifyOtherValue";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { processOtherOptions } from "@editor/components/flows/utils/processOtherOptions";

import SelectableButtons from "@replo/design-system/components/selectable-buttons/SelectableButtons";
import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  buildingFor: string;
  buildingForOther?: string;
};

const WhoAreYouBuildingPagesFor: React.FC = () => {
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.who-are-you-building-pages-for">();
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.who-are-you-building-pages-for">;

  const stepCommonOptions = stepProps.options.map((option) => option.value);
  const { otherValue: otherInitialValue, values: initialBuildingForValues } =
    useIdentifyOtherValue(
      stepCommonOptions,
      stepResultsData?.buildingFor ? [stepResultsData?.buildingFor] : [],
    );
  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      buildingFor: initialBuildingForValues[0],
      buildingForOther: otherInitialValue,
    },
  });
  const buildingForValue = useWatch({ control, name: "buildingFor" });
  const buildingForOtherValue = useWatch({ control, name: "buildingForOther" });
  const isOtherSelected = buildingForValue === "other";

  const buildingForOtherError = errors.buildingForOther?.message;
  const shouldDisableNextButton =
    isEmpty(buildingForValue) ||
    (isOtherSelected &&
      (!Boolean(buildingForOtherValue) || Boolean(buildingForOtherError)));

  const onSubmit = ({ buildingFor, buildingForOther }: FormValues) => {
    const [value] = processOtherOptions(
      [buildingFor],
      buildingForOther,
      stepProps,
    );
    if (currentStep && value) {
      void submitStep(
        currentStep.id,
        currentStep.type,
        {
          buildingFor: value,
        },
        ({ instance, nextStep }) =>
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
      );
    }
  };

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/person-logo.png" />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div className="flex flex-col gap-14">
          <div>
            <StepTitle>
              What best describes you and your{" "}
              <span className="whitespace-nowrap">
                team?
                <span className="text-danger">*</span>
              </span>
            </StepTitle>
          </div>
          <Controller
            name="buildingFor"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect={false}
                options={stepProps.options}
                value={[value]}
                onChange={(value) => onChange(value[0] ?? "")}
                inputEnhancer={
                  isOtherSelected && (
                    <div className="flex w-full flex-col gap-2">
                      <Controller
                        name="buildingForOther"
                        control={control}
                        rules={{
                          required: "Please enter a value.",
                          pattern: {
                            value: /\S+/,
                            message: "Please enter a value.",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => (
                          <div className="w-full">
                            <Input
                              type="text"
                              size="base"
                              autoFocus
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              name={name}
                              validityState={
                                Boolean(buildingForOtherError)
                                  ? "invalid"
                                  : "valid"
                              }
                              aria-invalid={
                                Boolean(buildingForOtherError)
                                  ? "true"
                                  : undefined
                              }
                              aria-describedby={
                                Boolean(buildingForOtherError)
                                  ? "error-building-for"
                                  : undefined
                              }
                            />
                          </div>
                        )}
                      />
                      <ErrorMessage
                        id="error-building-for"
                        error={errors.buildingForOther?.message}
                      />
                    </div>
                  )
                }
              />
            )}
          />
        </div>
        <FlowActionButtons shouldDisableNextButton={shouldDisableNextButton} />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

export default WhoAreYouBuildingPagesFor;
