// TODO (Jackson, 2025-03-14): Do this as a followup to the initial DynamicDataPopover refactor
// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum DynamicDataTargetType {
  // TODO (Noah, 2021-07-28): This can't just be "product" because we do startsWith
  // in many places and it conflicts with productVariant
  PRODUCT = "productOnly",
  PRODUCT_VARIANT = "productVariant",
  SELLING_PLAN = "sellingPlan",
  TEXT = "text",
  TEXT_COLOR = "text/color",
  URL = "text/url",
  // NOTE (Jackson, 2025-03-21): This will replace URL
  // NOTE (Jackson, 2025-03-31): Actually, no it won't, URL will be arbitrary URLs from metafields, excluding image source URLs
  IMAGE = "image",
  INTEGER = "text/integer",
  // NOTE (Matt 2025-03-25): ANY_LIST can be a list of any objects, with which a user will be able to select attributes from
  // those objects (ie a list of variants, a list of selling plans, etc.). IMAGE_LIST is purely a list of image urls, intended
  // specifically for Carousels
  ANY_LIST = "list",
  IMAGE_LIST = "imageList",
  QUANTITY = "quantity",
}
