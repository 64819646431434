import * as React from "react";

import { BsCheckLg } from "react-icons/bs";

export const BillingConfirmation: React.FC<{
  title: string;
  body: string;
}> = ({ title, body }) => {
  return (
    <div className="flex flex-col justify-between items-center w-full h-full gap-3 py-5">
      <div className="bg-blue-200 h-[80px] w-[80px] rounded-[40px] flex justify-center items-center">
        <BsCheckLg size={48} className="text-primary" />
      </div>
      <div className="typ-header-h1 font-bold text-primary">{title}</div>
      <div className="typ-body-base">{body}</div>
    </div>
  );
};
