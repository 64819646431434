import type { ComponentTemplate } from "@editor/types/component-template";

const bulletedList: ComponentTemplate = {
  id: "8737e0c9-c021-45e4-b7e4-27a92a986712",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Basic/Bulleted List",
  leftBarCategory: { main: "basic", subCategory: "text" },
  preview: "basic/bulleted-list.svg",
  template: {
    id: "a2564109-378b-433f-a1c6-ec152d44ca94",
    name: "Bulleted List",
    type: "container",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        alignSelf: "stretch",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    children: [
      {
        id: "563e17c5-12cb-434c-a39d-b3e7f77a5e94",
        name: "List Item",
        type: "container",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            __flexGap: "10px",
            alignSelf: "stretch",
            alignItems: "center",
            paddingTop: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            justifyContent: "flex-start",
          },
        },
        children: [
          {
            id: "da686742-c451-4630-8a91-1c0466f839f6",
            type: "icon",
            props: {
              style: {
                color: "#000000FF",
                width: "24px",
                height: "24px",
              },
              iconName: "check",
            },
          },
          {
            id: "749e6c29-344a-43de-8529-ebff16ed6361",
            type: "text",
            props: {
              text: "<p>Line item 1</p>",
              style: {
                flexGrow: 1,
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
      {
        id: "095dfa5c-e557-4504-b6a4-545279975747",
        name: "List Item",
        type: "container",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            __flexGap: "10px",
            alignSelf: "stretch",
            alignItems: "center",
            paddingTop: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            justifyContent: "flex-start",
          },
        },
        children: [
          {
            id: "4064286f-02fc-4d5c-a691-163177dba834",
            type: "icon",
            props: {
              style: {
                color: "#000000FF",
                width: "24px",
                height: "24px",
              },
              iconName: "check",
            },
          },
          {
            id: "b97a22fc-e0a4-4edc-8b0f-9a674f448b98",
            type: "text",
            props: {
              text: "<p>Line item 2</p>",
              style: {
                flexGrow: 1,
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
      {
        id: "a8be6e62-98e5-49b4-8dff-08cecf8723fb",
        name: "List Item",
        type: "container",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            __flexGap: "10px",
            alignSelf: "stretch",
            alignItems: "center",
            paddingTop: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            justifyContent: "flex-start",
          },
        },
        children: [
          {
            id: "4bf98e03-3777-4796-b0d1-9a48736c1cdc",
            type: "icon",
            props: {
              style: {
                color: "#000000FF",
                width: "24px",
                height: "24px",
              },
              iconName: "check",
            },
          },
          {
            id: "4fe0e368-2280-4424-b042-eb59e483f9e1",
            type: "text",
            props: {
              text: "<p>Line item 3</p>",
              style: {
                flexGrow: 1,
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
      {
        id: "11c7b027-be67-4862-bab7-53baca7dd396",
        name: "List Item",
        type: "container",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            __flexGap: "10px",
            alignSelf: "stretch",
            alignItems: "center",
            paddingTop: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            justifyContent: "flex-start",
          },
        },
        children: [
          {
            id: "48f76719-0472-4277-8053-d7e928e8a004",
            type: "icon",
            props: {
              style: {
                color: "#000000FF",
                width: "24px",
                height: "24px",
              },
              iconName: "check",
            },
          },
          {
            id: "e2b306ea-5088-4704-9264-1deb52640bd5",
            type: "text",
            props: {
              text: "<p>Line item 4</p>",
              style: {
                flexGrow: 1,
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
    ],
  },
};

export default bulletedList;
