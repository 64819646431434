import React, { useEffect, useRef } from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import composeRefs from "@seznam/compose-react-refs";

import {
  Tabs as ShadcnTabs,
  TabsContent as ShadcnTabsContent,
  TabsList as ShadcnTabsList,
  TabsTrigger as ShadcnTabsTrigger,
} from "../shadcn/core/tabs";

// Helper function to scroll active tab into view
const scrollActiveTabIntoView = (
  tab: HTMLButtonElement | null,
  container: HTMLDivElement | null,
) => {
  if (!tab || !container) {
    return;
  }

  const containerRect = container.getBoundingClientRect();
  const tabRect = tab.getBoundingClientRect();

  const isNotFullyVisible =
    tabRect.left < containerRect.left || tabRect.right > containerRect.right;

  if (isNotFullyVisible) {
    const scrollLeft =
      tab.offsetLeft - container.offsetWidth / 2 + tab.offsetWidth / 2;
    container.scrollTo({
      left: scrollLeft,
      behavior: "smooth",
    });
  }
};

type TabsTriggerOption = {
  value: string;
  label: React.ReactNode;
} & Omit<TabsTriggerProps, "value" | "children">;

type TabsContentOption = {
  value: string;
  tabContent: React.ReactNode;
} & Omit<TabsContentProps, "value" | "children">;

type TabsProps = {
  value: string;
  onValueChange: (value: string) => void;
  defaultValue: string;
  tabsOptions: TabsTriggerOption[];
  contentOptions?: TabsContentOption[];
  layoutClassName?: string;
  UNSAFE_className?: string;
  size?: "sm" | "base";
} & Omit<TabsRootProps, "className">;

export const Tabs = ({
  value,
  onValueChange,
  defaultValue,
  tabsOptions,
  contentOptions,
  layoutClassName,
  UNSAFE_className,
  size = "sm",
}: TabsProps) => {
  const tabsListRef = useRef<HTMLDivElement>(null);
  const activeTabRef = useRef<HTMLButtonElement>(null);

  return (
    <TabsRoot
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
      layoutClassName={layoutClassName}
      UNSAFE_className={UNSAFE_className}
    >
      <TabsList size={size} ref={tabsListRef}>
        {tabsOptions.map((tab) => {
          const { value: tabValue, label, ...rest } = tab;

          return (
            <TabsTrigger
              ref={value === tabValue ? activeTabRef : undefined}
              size={size}
              key={tabValue}
              value={tabValue}
              {...rest}
            >
              {label}
            </TabsTrigger>
          );
        })}
      </TabsList>
      <div className="flex-1 min-h-0">
        {contentOptions?.map((content) => {
          const { value, tabContent, ...rest } = content;

          return (
            <TabsContent
              key={value}
              value={value}
              UNSAFE_className="pt-4"
              {...rest}
            >
              {tabContent}
            </TabsContent>
          );
        })}
      </div>
    </TabsRoot>
  );
};

interface TabsRootProps
  extends Omit<React.ComponentPropsWithoutRef<typeof ShadcnTabs>, "className"> {
  value?: string;
  onValueChange?: (value: string) => void;
  layoutClassName?: string;
  UNSAFE_className?: string;
}

const TabsRoot = React.forwardRef<HTMLDivElement, TabsRootProps>(
  ({ layoutClassName, UNSAFE_className, ...props }, ref) => {
    return (
      <ShadcnTabs
        ref={ref}
        className={twMerge(
          "flex flex-col h-full",
          layoutClassName,
          UNSAFE_className,
        )}
        {...props}
      />
    );
  },
);
TabsRoot.displayName = "TabsRoot";

interface TabsListProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof ShadcnTabsList>,
    "className"
  > {
  layoutClassName?: string;
  UNSAFE_className?: string;
  size?: "sm" | "base";
}

const TabsList = React.forwardRef<HTMLDivElement, TabsListProps>(
  (
    { layoutClassName, UNSAFE_className, children, size = "sm", ...props },
    ref,
  ) => {
    return (
      <ShadcnTabsList
        ref={ref}
        className={twMerge(
          "flex-none flex items-end justify-start h-[unset] rounded-none bg-transparent p-0 border-none w-full",
          "overflow-x-scroll p-0.5 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]",
          layoutClassName,
          UNSAFE_className,
        )}
        {...props}
      >
        <div
          className={twMerge(
            "flex-1 flex items-end justify-start rounded-none bg-transparent p-0 border-b-0.5 border-border",
            size === "sm" && "gap-2.5",
            size === "base" && "gap-4",
            layoutClassName,
            UNSAFE_className,
          )}
        >
          {children}
        </div>
      </ShadcnTabsList>
    );
  },
);
TabsList.displayName = "TabsList";

interface TabsTriggerProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof ShadcnTabsTrigger>,
    "className"
  > {
  size?: "sm" | "base";
  layoutClassName?: string;
  UNSAFE_className?: string;
}

const TabsTrigger = React.forwardRef<HTMLButtonElement, TabsTriggerProps>(
  ({ layoutClassName, UNSAFE_className, size, value, ...props }, ref) => {
    const triggerRef = useRef<HTMLButtonElement | null>(
      null,
    ) as React.MutableRefObject<HTMLButtonElement | null>;

    useEffect(() => {
      if (!triggerRef.current) {
        return;
      }

      const checkAndScroll = () => {
        if (triggerRef.current) {
          const isActive = triggerRef.current.dataset.state === "active";
          if (isActive) {
            const scrollableParent =
              triggerRef.current.parentElement?.parentElement;

            if (scrollableParent) {
              requestAnimationFrame(() => {
                scrollActiveTabIntoView(
                  triggerRef.current,
                  scrollableParent as HTMLDivElement,
                );
              });
            }
          }
        }
      };

      checkAndScroll();

      const observer = new MutationObserver(checkAndScroll);
      observer.observe(triggerRef.current, {
        attributes: true,
        attributeFilter: ["data-state"],
      });

      return () => {
        observer.disconnect();
      };
    }, [triggerRef]);

    return (
      <ShadcnTabsTrigger
        ref={composeRefs(triggerRef, ref)}
        value={value}
        className={twMerge(
          "text-placeholder border-b-2 border-transparent transition mb-[-2px] relative data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:shadow-none data-[state=active]:z-10 disabled:cursor-not-allowed disabled:pointer-events-auto px-0",
          size === "sm" &&
            "typ-label-small py-1 data-[state=active]:typ-header-small",
          size === "base" &&
            "typ-label-base py-1.5 data-[state=active]:typ-header-base",
          layoutClassName,
          UNSAFE_className,
        )}
        {...props}
      />
    );
  },
);
TabsTrigger.displayName = "TabsTrigger";

interface TabsContentProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof ShadcnTabsContent>,
    "className"
  > {
  layoutClassName?: string;
  UNSAFE_className?: string;
}

const TabsContent = React.forwardRef<HTMLDivElement, TabsContentProps>(
  ({ layoutClassName, UNSAFE_className, ...props }, ref) => {
    return (
      <ShadcnTabsContent
        ref={ref}
        className={twMerge(
          "mt-0 ring-offset-0 focus-visible:outline-none h-full overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]",
          layoutClassName,
          UNSAFE_className,
        )}
        {...props}
      />
    );
  },
);
TabsContent.displayName = "TabsContent";

/** @public */
export type {
  TabsProps,
  TabsTriggerOption,
  TabsContentOption,
  TabsRootProps,
  TabsListProps,
  TabsTriggerProps,
  TabsContentProps,
};

Tabs.Root = TabsRoot;
Tabs.List = TabsList;
Tabs.Trigger = TabsTrigger;
Tabs.Content = TabsContent;
