import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import { Checkbox } from "../checkbox/Checkbox";
import Description from "../label/Description";
import Label from "../label/Label";

type CardProps = {
  size: "base" | "sm";
  UNSAFE_className?: string;
  layoutClassName?: string;
  active?: boolean;
  onChange?: () => void;
  title: string;
  description?: string;
  variant?: "checkbox" | "default";
};

const SelectableCard: React.FC<CardProps> = ({
  size,
  UNSAFE_className,
  layoutClassName,
  active,
  onChange,
  title,
  description,
  variant = "default",
}) => {
  const [isActive, setIsActive] = useControllableState(active, false, onChange);

  const onClick = () => {
    setIsActive(!isActive);
  };

  return (
    <button
      type="button"
      className={twMerge(
        "flex rounded-lg font-normal border border-border text-muted hover:bg-hover cursor-pointer p-3 gap-2 break-words text-left",
        isActive &&
          "border-primary bg-selectable-selected text-primary hover:bg-selectable-selected-hover",
        layoutClassName,
        UNSAFE_className,
      )}
      onClick={onClick}
    >
      {variant === "checkbox" && (
        <Checkbox checked={isActive} onChange={setIsActive} />
      )}
      <div className="flex flex-col gap-[2px]">
        <Label
          label={title}
          size={size}
          breakWords
          UNSAFE_className={twMerge(size === "base" && "!leading-none")}
        />
        <Description description={description} size={size} breakWords />
      </div>
    </button>
  );
};

export default SelectableCard;
