import type { CustomPropDefinitionAndValue } from "@editor/components/editor/page/element-editor/components/config-menus/utils";
import type { ComponentActionType } from "@editor/types/component-action-type";
import type { ToggleOption } from "@replo/design-system/components/toggle/ToggleGroup";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";

import * as React from "react";

import {
  Group,
  GroupHeader,
  GroupTitle,
  GroupTitleContainer,
} from "@editor/components/common/designSystem/Group";
import { FieldWithDescription } from "@editor/components/editor/customProp/FieldWithDescription";
import StandardCustomPropControl from "@editor/components/editor/page/element-editor/components/config-menus/StandardCustomPropControl";
import {
  customPropHasType,
  getOptionsFromCustomPropDefinition,
  useCustomPropDefinitionsWithValues,
} from "@editor/components/editor/page/element-editor/components/config-menus/utils";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectComponentDataMapping,
  selectDraftComponent,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import DynamicDataValueIndicator from "@editorExtras/DynamicDataValueIndicator";
import { CombinedItemsSelector } from "@editorModifiers/ItemsModifier";
import AutoWidth from "@svg/carousel-auto-width";
import FixedWidth from "@svg/carousel-fixed-width";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import SelectableButtons from "@replo/design-system/components/selectable-buttons/SelectableButtons";
import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { isComponentInsideProductComponent } from "replo-runtime/shared/Component";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { getDynamicDataBreadcrumbs } from "replo-runtime/shared/utils/dynamic-data";
import {
  exhaustiveSwitch,
  hasOwnProperty,
  isNotNullish,
} from "replo-utils/lib/misc";
import { ItemsConfigType } from "schemas/dynamicData";

import {
  DynamicDataSelector,
  useDynamicData,
} from "../../../DynamicDataSelector";

const OneToFourButtons: React.FC<{
  active: number | undefined;
  setActive: (value: number) => void;
}> = ({ active, setActive }) => {
  const values = [1, 2, 3, 4];
  const noneAreActive = values.includes(active as number) === false;
  return (
    <div className="flex flex-row gap-1 pt-1">
      {[1, 2, 3, 4].map((value) => {
        const isActive = active === value;

        // TODO (Chance 2024-01-24): Implements roving tabindex for toggle
        // buttons. This should be a DS primitive.
        let tabIndex = -1;
        if (isActive || (noneAreActive && value === 1)) {
          tabIndex = 0;
        }
        return (
          <button
            tabIndex={tabIndex}
            type="button"
            aria-pressed={isActive}
            className={twMerge(
              "h-6 w-6 rounded",
              isActive ? "bg-blue-600 text-white" : "bg-slate-200",
            )}
            key={value}
            onClick={() => setActive(value)}
            onKeyDown={(event) => {
              // TODO (Chance 2024-01-24): Implements keyboard controls for
              // toggle buttons. This should be a DS primitive.
              const preventScroll = () => event.preventDefault();
              const getButtonElement = (value: number) => {
                const currentTarget = event.currentTarget;
                const parentElement = currentTarget.parentElement!;
                const buttons = Array.from(
                  parentElement.children,
                ) as HTMLButtonElement[];
                return buttons[value - 1];
              };
              switch (event.key) {
                case " ":
                case "Enter":
                case "ArrowDown":
                case "ArrowUp":
                  preventScroll();
                  break;
                case "Home":
                  preventScroll();
                  setActive(1);
                  getButtonElement(1)?.focus();
                  break;
                case "End":
                  preventScroll();
                  setActive(4);
                  getButtonElement(4)?.focus();
                  break;
                case "ArrowLeft":
                  preventScroll();
                  const prev = value === 1 ? value : value - 1;
                  setActive(prev);
                  getButtonElement(prev)?.focus();
                  break;
                case "ArrowRight":
                  preventScroll();
                  const next = value === 4 ? value : value + 1;
                  setActive(next);
                  getButtonElement(next)?.focus();
                  break;
                default:
                  return;
              }
            }}
          >
            {value}
          </button>
        );
      })}
    </div>
  );
};

const SlideStyleSelector: React.FC<{
  isAutoWidth: boolean | undefined;
  itemsPerPanel: number | undefined;
  slidesPerMove?: number; // NOTE (Evan, 7/27/23) This is optional b/c we don't use it for the carousel slides menu
  activeAreaProp: CustomPropDefinitionAndValue | undefined;
  setProp: (
    prop:
      | { _autoWidth: boolean; _itemsPerView?: number }
      | { _itemsPerView: number }
      | { _itemsPerMove: number }
      | { _activeArea: string },
  ) => void;
  // Note (Evan, 2024-06-19): This is defined when we are editing the carousel component itself, and
  // undefined when we are editing the carousel slides component (which does not have device-specific overrides)
  activeCanvas?: EditorCanvas;
}> = ({
  isAutoWidth,
  itemsPerPanel,
  slidesPerMove,
  activeAreaProp,
  setProp,
  activeCanvas,
}) => {
  return (
    <Group
      name="Slide Style"
      header={
        <GroupTitleContainer>
          <GroupHeader>
            <GroupTitle>
              {({ name }) => (
                <>
                  <span>{name}</span>
                  {activeCanvas && (
                    <div className="font-normal text-muted inline">
                      <span aria-hidden className="px-1">
                        ·
                      </span>
                      <Tooltip
                        content="These settings can be configured separately on different device sizes"
                        triggerAsChild
                      >
                        <button type="button" tabIndex={0}>
                          <span className="capitalize">{activeCanvas}</span>
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </GroupTitle>
          </GroupHeader>
        </GroupTitleContainer>
      }
      isDefaultOpen
      isCollapsible
    >
      <div className="w-full flex gap-4 px-2">
        <div
          className={twMerge(
            "w-1/2 flex flex-col items-center rounded text-xs border-2 gap-1 p-1 cursor-pointer font-medium",
            isAutoWidth === false
              ? "bg-blue-100 border-blue-600 text-blue-600"
              : "border-transparent bg-slate-100 text-muted",
          )}
          onClick={() => {
            setProp({
              _autoWidth: false,
              _itemsPerView: itemsPerPanel == null ? 1 : itemsPerPanel,
            });
          }}
        >
          <FixedWidth isActive={isAutoWidth === false} />
          <span>Fixed</span>
        </div>
        <div
          className={twMerge(
            "w-1/2 flex flex-col items-center rounded text-xs border-2 gap-1 p-1 cursor-pointer font-medium",
            isAutoWidth === true
              ? "bg-blue-100 border-blue-600 text-blue-600"
              : "border-transparent bg-slate-100 text-muted",
          )}
          onClick={() => setProp({ _autoWidth: true })}
        >
          <AutoWidth isActive={isAutoWidth === true} />
          <span>Auto</span>
        </div>
      </div>
      {isAutoWidth === false && (
        <div className="flex flex-row text-xs text-muted w-full">
          <div className="w-1/2 p-2">
            <span>Items per Panel</span>
            <OneToFourButtons
              active={itemsPerPanel}
              setActive={(value) => setProp({ _itemsPerView: value })}
            />
          </div>
          {slidesPerMove !== undefined && (
            <div className="w-1/2 p-2">
              <span>Slides per Move</span>
              <div className="flex flex-row gap-1">
                <OneToFourButtons
                  active={slidesPerMove}
                  setActive={(value) => setProp({ _itemsPerMove: value })}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="pl-2 pt-2">
        <span className="text-xs text-muted">Active Slide</span>
        <SelectableButtons
          options={
            activeAreaProp?.definition
              ? getOptionsFromCustomPropDefinition(activeAreaProp.definition) ??
                []
              : []
          }
          size="sm"
          value={[activeAreaProp?.value].filter(isNotNullish)}
          onChange={(newValue) => setProp({ _activeArea: newValue[0] })}
          multiSelect={false}
        />
      </div>
    </Group>
  );
};

const AutoScrollSelector: React.FC<{
  draftComponent: Component;
  componentDataMapping: ComponentDataMapping;
  autoScrollProp: CustomPropDefinitionAndValue;
  pauseOnHoverProp?: CustomPropDefinitionAndValue;
  selectedItemProp: CustomPropDefinitionAndValue;
}> = ({
  draftComponent,
  componentDataMapping,
  autoScrollProp,
  pauseOnHoverProp,
  selectedItemProp,
}) => {
  const [valueTabSelected, setValueTabSelected] = React.useState(false);

  const timerIsActive =
    customPropHasType(autoScrollProp.value, "string") &&
    autoScrollProp.value !== "0s";
  const selectedItemIsActive =
    isNotNullish(selectedItemProp.value) || valueTabSelected;

  const isInsideProductComponent = isComponentInsideProductComponent(
    draftComponent.id,
    componentDataMapping,
  );

  let toggleValue = "none";
  if (timerIsActive) {
    toggleValue = "timer";
  }
  if (selectedItemIsActive) {
    toggleValue = "value";
  }

  const applyComponentAction = useApplyComponentAction();

  const TOGGLE_OPTIONS: ToggleOption[] = [
    { value: "none", label: "None" },
    { value: "timer", label: "On Timer" },
  ];
  if (isInsideProductComponent) {
    TOGGLE_OPTIONS.push({ value: "value", label: "To Value" });
  }

  const onToggleGroupChange = (value: "none" | "timer" | "value") => {
    if (value === "none") {
      setValueTabSelected(false);
      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          { type: "setProps", value: { _selectedItem: null } },
          { type: "setProps", value: { _autoNextInterval: "0s" } },
        ],
      });
    } else if (value === "timer") {
      setValueTabSelected(false);
      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          { type: "setProps", value: { _selectedItem: null } },
          { type: "setProps", value: { _autoNextInterval: "1s" } },
        ],
      });
    } else {
      setValueTabSelected(true);
      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          {
            type: "setProps",
            value: { _autoNextInterval: "0s" },
          },
          {
            type: "setProps",
            value: {
              _selectedItem: isInsideProductComponent
                ? `{{attributes._variant.featuredImage}}`
                : null,
            },
          },
        ],
      });
    }
  };

  const dynamicDataDisplayName = selectedItemProp.value
    ? getDynamicDataBreadcrumbs(selectedItemProp.value).toLowerCase()
    : undefined;

  return customPropHasType(autoScrollProp.value, "string") ? (
    <Group name="Autoscroll" isDefaultOpen isCollapsible>
      <ToggleGroup
        size="sm"
        selectedValue={toggleValue}
        options={TOGGLE_OPTIONS}
        onChange={(value) =>
          onToggleGroupChange(value as "none" | "timer" | "value")
        }
      />
      {timerIsActive && (
        <div className="pt-3">
          <div className="text-xs text-muted">Duration until next slide</div>
          <StandardCustomPropControl
            draftComponent={draftComponent}
            customProp={autoScrollProp}
            noWrapper
          />
          {pauseOnHoverProp && (
            <SwitchWithDescription
              label="Pause on Hover"
              size="sm"
              layoutClassName="w-full mt-4"
              isOn={pauseOnHoverProp.value}
              onChange={(newValue: boolean) => {
                applyComponentAction({
                  type: "setProps",
                  value: { _pauseOnHover: newValue },
                });
              }}
            />
          )}
        </div>
      )}
      {selectedItemIsActive && (
        <div className="pt-3">
          <StandardCustomPropControl
            customProp={selectedItemProp}
            draftComponent={draftComponent}
            noWrapper
            description={
              dynamicDataDisplayName
                ? `When the ${dynamicDataDisplayName} changes, this carousel will scroll to it.`
                : undefined
            }
          />
        </div>
      )}
    </Group>
  ) : null;
};

const AnimationStyleSelector: React.FC<{
  draftComponent: Component;
  animationStyleProp?: CustomPropDefinitionAndValue;
}> = ({ draftComponent, animationStyleProp }) => {
  return (
    <div>
      <div className="text-xs text-muted pb-2">Animation</div>
      <StandardCustomPropControl
        draftComponent={draftComponent}
        customProp={animationStyleProp}
        noWrapper
      />
    </div>
  );
};

// TODO (Evan, 8/10/23) It's probably worth adding proper type checking here
const DynamicItemsSelector: React.FC<{
  dynamicItemsProp?: CustomPropDefinitionAndValue;
}> = ({ dynamicItemsProp }) => {
  const applyComponentAction = useApplyComponentAction();
  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");
  if (!dynamicItemsProp) {
    return null;
  }

  // NOTE (Matt 2025-04-02): Until we remove dataTables, we need to allow users to see their
  // connected DataTables in the carousel so they can remove them.
  const initialPath =
    dynamicItemsProp.value?.type === "dataTable"
      ? ["dataTable"]
      : dynamicItemsProp.value?.dynamicPath?.split(".") ?? [];
  return (
    <Group name="Dynamic Data" isDefaultOpen isCollapsible>
      <FieldWithDescription
        description={dynamicItemsProp?.definition.description}
      >
        {isNewDynamicData ? (
          <DynamicDataSelector
            side="left"
            sideOffset={14}
            targetType={DynamicDataTargetType.IMAGE_LIST}
            initialPath={initialPath}
            trigger={
              <DynamicDataValueIndicator
                type="other"
                templateValue={initialPath.join(".")}
                onRemove={() => {
                  applyComponentAction({
                    type: "deleteProps",
                    propName: dynamicItemsProp.definition.id,
                  });
                }}
              />
            }
            onChange={(value) => {
              const transformedValue = {
                type: "inline",
                valueType: "dynamic",
                dynamicPath: value,
              };

              applyComponentAction({
                type: "setProps",
                value: { [dynamicItemsProp.definition.id]: transformedValue },
              });
            }}
          />
        ) : (
          <CombinedItemsSelector
            value={dynamicItemsProp?.value}
            onChange={(value) => {
              applyComponentAction({
                type: "setProps",
                value: { [dynamicItemsProp.definition.id]: value },
              });
            }}
            onDelete={() => {
              applyComponentAction({
                type: "deleteProps",
                propName: dynamicItemsProp.definition.id,
              });
            }}
          />
        )}
      </FieldWithDescription>
    </Group>
  );
};

const shouldDisplaySelectedDynamicItem = (component: Component) => {
  const items = component.props._items;
  if (items) {
    if (
      items.type === ItemsConfigType.inline &&
      (!hasOwnProperty(items, "valueType") ||
        (items.valueType === "string" && (items.values?.length ?? 0) === 0))
    ) {
      return false;
    }
    return true;
  }
  return false;
};

type SetPropsPayload = (ComponentActionType & { type: "setProps" })["value"];

/**
 * Returns a setProps action payload that either sets the prop directly (if
 * editing in desktop mode) or sets the prop as an override.
 */
const getOverridableSetPropsAction = (
  prop: SetPropsPayload,
  activeCanvas: EditorCanvas,
) => {
  return exhaustiveSwitch({ type: activeCanvas })({
    desktop: () => prop,
    tablet: () => ({ overrides: { md: prop } }),
    mobile: () => ({ overrides: { sm: prop } }),
  });
};

const getPropById = (
  customProps: CustomPropDefinitionAndValue[],
  id: string,
) => {
  return customProps.find((customProp) => customProp.definition.id === id);
};

const getPropValueById = (
  customProps: CustomPropDefinitionAndValue[],
  id: string,
) => {
  return getPropById(customProps, id)?.value;
};

export const CarouselConfigMenu: React.FC = () => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const customProps = useCustomPropDefinitionsWithValues({ activeCanvas });
  const applyComponentAction = useApplyComponentAction();
  const { populatedTree: possibleDynamicDataAttributes } = useDynamicData({
    targetType: DynamicDataTargetType.IMAGE_LIST,
  });
  if (!draftComponent) {
    return null;
  }

  const isAutoWidth = getPropValueById(customProps, "_autoWidth");
  const itemsPerPanel = getPropValueById(customProps, "_itemsPerView");
  const slidesPerMove = getPropValueById(customProps, "_itemsPerMove");
  const isMouseScrollEnabled = getPropValueById(customProps, "_mouseWheel");
  const isSlideClickTransitionEnabled = getPropValueById(
    customProps,
    "_slideClickTransition",
  );

  const activeAreaProp = getPropById(customProps, "_activeArea");
  const autoScrollProp = getPropById(customProps, "_autoNextInterval");
  const selectedItemProp = getPropById(customProps, "_selectedItem");
  const animationStyleProp = getPropById(customProps, "_animationStyle");
  const infiniteRepeatProp = getPropById(customProps, "_infinite");

  const existingItemsPropIsDynamic = Boolean(
    getPropById(customProps, "_items")?.value,
  );
  const shouldShowDynamicDataSelector = Boolean(
    possibleDynamicDataAttributes?.[0] || existingItemsPropIsDynamic,
  );

  return (
    <div className="relative flex flex-col gap-4">
      {shouldShowDynamicDataSelector && (
        <>
          <DynamicItemsSelector
            dynamicItemsProp={getPropById(customProps, "_items")}
          />
          {shouldDisplaySelectedDynamicItem(draftComponent) && (
            <StandardCustomPropControl draftComponent={draftComponent} />
          )}
          <hr />
        </>
      )}
      {customPropHasType(isAutoWidth, "boolean") &&
        customPropHasType(itemsPerPanel, "integer") &&
        customPropHasType(slidesPerMove, "integer") &&
        activeAreaProp && (
          <SlideStyleSelector
            isAutoWidth={isAutoWidth}
            setProp={(prop) => {
              const propToSet = getOverridableSetPropsAction(
                prop,
                activeCanvas,
              );
              applyComponentAction({
                type: "setProps",
                value: propToSet,
              });
            }}
            itemsPerPanel={itemsPerPanel}
            slidesPerMove={slidesPerMove}
            activeAreaProp={activeAreaProp}
            activeCanvas={activeCanvas}
          />
        )}
      <hr />
      {autoScrollProp && selectedItemProp && (
        <AutoScrollSelector
          draftComponent={draftComponent}
          componentDataMapping={componentDataMapping}
          autoScrollProp={autoScrollProp}
          pauseOnHoverProp={getPropById(customProps, "_pauseOnHover")}
          selectedItemProp={selectedItemProp}
        />
      )}
      <hr />
      <Group name="Transitions" isDefaultOpen isCollapsible>
        <div className="flex flex-col gap-4 mt-1">
          <AnimationStyleSelector
            draftComponent={draftComponent}
            animationStyleProp={animationStyleProp}
          />
          {infiniteRepeatProp && (
            <SwitchWithDescription
              label="Infinite Repeat Slides"
              size="sm"
              layoutClassName="w-full"
              isOn={infiniteRepeatProp.value}
              onChange={(newValue) =>
                applyComponentAction({
                  type: "setProps",
                  value: { _infinite: newValue },
                })
              }
            />
          )}
          {customPropHasType(isMouseScrollEnabled, "boolean") && (
            <SwitchWithDescription
              label="Enable scroll with mouse"
              size="sm"
              layoutClassName="w-full"
              isOn={isMouseScrollEnabled}
              onChange={(newValue) =>
                applyComponentAction({
                  type: "setProps",
                  value: { _mouseWheel: newValue },
                })
              }
            />
          )}
          {customPropHasType(isSlideClickTransitionEnabled, "boolean") && (
            <SwitchWithDescription
              label="Enable scroll on click"
              size="sm"
              layoutClassName="w-full"
              isOn={isSlideClickTransitionEnabled}
              onChange={(newValue) =>
                applyComponentAction({
                  type: "setProps",
                  value: { _slideClickTransition: newValue },
                })
              }
            />
          )}
        </div>
      </Group>
    </div>
  );
};

export const CarouselSlidesConfigMenu: React.FC = () => {
  const customProps = useCustomPropDefinitionsWithValues();
  const draftComponent = useEditorSelector(selectDraftComponent);
  const applyComponentAction = useApplyComponentAction();
  if (!draftComponent) {
    return null;
  }

  const isAutoWidth = getPropValueById(customProps, "_autoWidth");
  const itemsPerPanel = getPropValueById(customProps, "_itemsPerView");

  const activeAreaProp = getPropById(customProps, "_activeArea");
  const animationStyleProp = getPropById(customProps, "_animationStyle");
  const isSlideClickTransitionEnabled = getPropValueById(
    customProps,
    "_slideClickTransition",
  );

  return (
    <div className="relative flex flex-col gap-4">
      <StandardCustomPropControl
        draftComponent={draftComponent}
        customProp={getPropById(customProps, "_useCustomConfig")}
      />
      {getPropValueById(customProps, "_useCustomConfig") && (
        <>
          <SlideStyleSelector
            isAutoWidth={isAutoWidth}
            setProp={(prop) =>
              applyComponentAction({
                type: "setProps",
                value: prop,
              })
            }
            itemsPerPanel={itemsPerPanel}
            activeAreaProp={activeAreaProp}
          />

          <Group name="Transitions" isDefaultOpen isCollapsible>
            <div className="flex flex-col gap-4 mt-1">
              <AnimationStyleSelector
                draftComponent={draftComponent}
                animationStyleProp={animationStyleProp}
              />
              {customPropHasType(isSlideClickTransitionEnabled, "boolean") && (
                <SwitchWithDescription
                  label="Enable scroll on click"
                  size="sm"
                  layoutClassName="w-full"
                  isOn={isSlideClickTransitionEnabled}
                  onChange={(newValue) =>
                    applyComponentAction({
                      type: "setProps",
                      value: { _slideClickTransition: newValue },
                    })
                  }
                />
              )}
            </div>
          </Group>
        </>
      )}
    </div>
  );
};
