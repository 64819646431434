import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/props";

function getConfigurableProps(): CustomPropsRecord {
  return {};
}

const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
    ancestorAllow: {
      ancestorTypes: ["product"],
      message:
        "Kaching Bundles Widget component may only be placed inside a Product box.",
    },
  },
} satisfies ComponentConfig;

export default config;
