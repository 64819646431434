import type { DynamicDataModalProps } from "@editor/components/AppModalTypes";
import type { MetafieldType } from "replo-runtime/shared/types";
import type { TopLevelDynamicDataKey } from "replo-runtime/store/ReploVariable";

import * as React from "react";

import { componentTypeDirectory } from "@components/editor/componentTypes";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useFetchVariantMetafieldsDefinitions } from "@editor/hooks/useFetchVariantMetafieldsDefinitions";
import { useModal } from "@editor/hooks/useModal";
import { useAnyStoreProducts } from "@editor/hooks/useStoreProducts";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectComponentMapping,
  selectDataTablesMapping,
  selectDraftComponentIds,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import { docs } from "@editor/utils/docs";
import {
  filterDynamicDataByType,
  getDynamicDataValue,
} from "@editor/utils/dynamic-data";
import { useFetchProductMetafieldDefinitions } from "@hooks/useFetchProductMetafieldDefinitions";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import get from "lodash-es/get";
import isArray from "lodash-es/isArray";
import isEmpty from "lodash-es/isEmpty";
import isEqual from "lodash-es/isEqual";
import isNumber from "lodash-es/isNumber";
import isObject from "lodash-es/isObject";
import isString from "lodash-es/isString";
import mapValues from "lodash-es/mapValues";
import omitBy from "lodash-es/omitBy";
import startCase from "lodash-es/startCase";
import { BsChevronRight, BsInfoCircleFill } from "react-icons/bs";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { isTopLevelDynamicDataKey } from "replo-runtime/store/ReploVariable";
import {
  exhaustiveSwitch,
  isNotNullish,
  isNullish,
} from "replo-utils/lib/misc";

/**
 * Main modal which allows the user to display dynamic data
 */
export const DynamicDataModal: React.FC<DynamicDataModalProps> = ({
  targetType,
  referrerData,
  initialPath,
  excludedAttributeKeys,
  allowDataTables,
}) => {
  const modal = useModal();
  const {
    dynamicDataWithComponentId,
    currentDynamicDataPath,
    setCurrentDynamicDataPath,
    isValidDynamicDataPath,
    onSubmitDynamicDataPath,
  } = useDynamicData({
    initialPath,
    targetType,
    referrerData,
    excludedAttributeKeys,
  });

  const isDynamicData =
    allowDataTables || Object.keys(dynamicDataWithComponentId).length > 0;

  const dataTableIsSelected = Boolean(
    currentDynamicDataPath?.length &&
      currentDynamicDataPath?.length >= 1 &&
      currentDynamicDataPath[0] === "_dataTables",
  );

  const onSubmit = () => {
    modal.closeModal({ type: "dynamicDataModal" });
    onSubmitDynamicDataPath();
  };

  return (
    <Modal
      isOpen
      onOpenChange={(open) => {
        if (!open) {
          modal.closeModal({ type: "dynamicDataModal" });
        }
      }}
      title="Add Dynamic Data"
      size="lg"
      footer={
        <div className="flex flex-row gap-2">
          {dataTableIsSelected ? (
            <div className="flex flex-row gap-2">
              <Button
                onClick={() => {
                  modal.openModal({
                    type: "dataTableModal",
                    props: {
                      referrer: "header",
                    },
                  });
                }}
                variant="secondary"
                size="base"
              >
                Edit Data Collections
              </Button>
              <Button
                onClick={onSubmit}
                variant="primary"
                size="base"
                disabled={currentDynamicDataPath?.length !== 2}
              >
                Use Data Collection
              </Button>
            </div>
          ) : (
            <Button
              onClick={onSubmit}
              disabled={!isValidDynamicDataPath}
              variant="primary"
              size="base"
            >
              Use Dynamic Property
            </Button>
          )}
        </div>
      }
    >
      {isDynamicData ? (
        <div className="flex w-full flex-1 flex-col overflow-x-hidden overflow-y-auto">
          <div className="flex w-full grow flex-row gap-3 overflow-x-auto">
            <DynamicDataModalColumn
              currentPath={currentDynamicDataPath}
              columnDataWithComponentId={dynamicDataWithComponentId}
              targetType={targetType ?? null}
              onChange={(value) => {
                setCurrentDynamicDataPath([value]);
              }}
              allowDataTables={allowDataTables}
            />

            {currentDynamicDataPath?.map((path, index) => {
              // Don't render an unnecessary column if path is valid and
              // consider if we are in a metafields/namespace/key path
              const parentColumnPathKey = currentDynamicDataPath.at(-3) ?? "";
              const dynamicDataColumnPathLength = currentDynamicDataPath.length;
              if (
                isValidDynamicDataPath &&
                (dynamicDataColumnPathLength === index + 1 ||
                  (["productMetafields", "variantMetafields"].includes(
                    parentColumnPathKey,
                  ) &&
                    dynamicDataColumnPathLength === index + 2))
              ) {
                return null;
              }

              const columnPath = currentDynamicDataPath.slice(0, index + 1);

              // NOTE (Fran 2024-10-29 USE-1393): We should filter all metafields rows if the target type is ANY_LIST
              // we don't allow any metafield that is a list, so we don't need to allow metafields to be used as dynamic data
              // for ANY_LIST target type.
              const isDynamicDataTargetList =
                targetType === DynamicDataTargetType.ANY_LIST;
              const shouldRenderMetafieldRow =
                !isDynamicDataTargetList &&
                ["productMetafields", "variantMetafields"].includes(
                  columnPath[columnPath.length - 1] ?? "",
                );

              if (shouldRenderMetafieldRow) {
                return (
                  <MetafieldsDynamicDataModalColumn
                    key={path}
                    currentPath={currentDynamicDataPath}
                    columnPath={columnPath}
                    currentMetafieldType={
                      columnPath?.[0] === "_product" ? "product" : "variant"
                    }
                    onChange={({ namespace, key }) => {
                      setCurrentDynamicDataPath([
                        ...columnPath,
                        namespace,
                        key,
                      ]);
                    }}
                  />
                );
              }

              if (dataTableIsSelected && index === 0) {
                const selectedDataTableId = currentDynamicDataPath[1] ?? null;
                return (
                  <DataCollectionsColumn
                    selectedDataTableId={selectedDataTableId}
                    onChange={(value) => {
                      setCurrentDynamicDataPath(["_dataTables", value]);
                    }}
                    key={path}
                  />
                );
              }

              return (
                <DynamicDataModalColumn
                  key={path}
                  targetType={targetType ?? null}
                  currentPath={currentDynamicDataPath}
                  currentPathIndex={index}
                  columnPath={columnPath}
                  columnDataWithComponentId={dynamicDataWithComponentId}
                  onChange={(value) => {
                    setCurrentDynamicDataPath([...columnPath, value]);
                  }}
                  allowDataTables={allowDataTables}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex-1 flex flex-col items-center justify-center">
          <div className="flex flex-col text-muted w-52 gap-2">
            <div className="text-sm text-default font-bold text-left">
              No Dynamic Data Found
            </div>
            <div className="grid text-sm text-slate-500">
              No dynamic data found for this component.
            </div>
            <Button
              variant="primary"
              to={docs.dynamicData}
              target="_blank"
              size="sm"
            >
              Learn More about Dynamic Data
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

/**
 * Component that renders a column of the dynamic data modal
 */
const DynamicDataModalColumn: React.FC<{
  currentPath: string[] | null;
  currentPathIndex?: number | null;
  columnDataWithComponentId: Record<
    string,
    { componentId: string; data: Record<string, any> }
  >;
  targetType: DynamicDataTargetType | null;
  columnPath?: string[];
  onChange(value: string): void;
  allowDataTables?: boolean;
}> = ({
  allowDataTables,
  currentPath,
  currentPathIndex,
  columnPath,
  targetType,
  onChange,
  columnDataWithComponentId,
}) => {
  const { products } = useAnyStoreProducts();
  const componentMapping = useEditorSelector(selectComponentMapping);
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;

  if (!columnDataWithComponentId) {
    return null;
  }
  let columnData = mapValues(columnDataWithComponentId, (value) => {
    return value.data;
  });

  // Note (Sebas, 2023-03-02): In case we are passing currentPathIndex as a prop,
  // we need to replace the columnData with the data of that index, this is required
  // to prevent having the first column duplicated.
  if (currentPath && isNotNullish(currentPathIndex)) {
    columnData = get(
      columnData,
      currentPath.slice(0, currentPathIndex + 1),
      {},
    );
    if (!columnData) {
      return null;
    }
  }
  const productDependencies = {
    products,
    currencyCode: activeCurrency,
    language: activeLanguage,
    moneyFormat,
    templateProduct,
    isEditor: true,
    isShopifyProductsLoading: false,
  };

  const { type: columnType } = getDynamicDataValue(
    columnPath?.[columnPath?.length - 1] ?? null,
    targetType,
    columnData,
    productDependencies,
  );

  const isProductOnlyColumn = columnType === "productOnly";
  const isVariantColumn = columnType === "productVariant";
  const metafieldsField = isVariantColumn
    ? "variantMetafields"
    : "productMetafields";

  const filteredColumnDataEntries = Object.entries(columnData).filter(
    ([field]) =>
      ![
        "rawPrice",
        "productMetafields",
        "variantMetafields",
        // HACK (Noah, 2023-02-17, REPL-6353): This placeholder is here to ensure that we don't
        // filter out products/variants where the only thing applicable to the targetType is a
        // metafield, but we don't want to render it as an accessible dynamic data value
        "metafieldsPlaceholder",
      ].includes(field),
  );

  if (allowDataTables && isNullish(currentPathIndex)) {
    filteredColumnDataEntries.push(["_dataTables", {}]);
  }

  return (
    <div className="h-full w-[250px] shrink-0 overflow-y-auto">
      <div className="flex w-full flex-col">
        {filteredColumnDataEntries.map(([field, value]) => {
          const relatedComponent = getFromRecordOrNull(
            componentMapping,
            columnDataWithComponentId[field]?.componentId ?? null,
          )?.component;
          const { displayValue, displayName } = (() =>
            field === "_dataTables"
              ? { displayValue: null, displayName: "Data Collections" }
              : getDynamicDataValue(
                  field,
                  targetType,
                  value,
                  productDependencies,
                ))();

          const existingPath = currentPath ?? [];
          const pathToCompareAgainst = (columnPath ?? []).concat([field]);
          // Note (Noah, 2022-01-17, REPL-6017): We need to slice the existing path first
          // before comparing, because we want all path segments of the current path to
          // be highlighted, e.g. so the user can tell which swatch
          const isActive = isEqual(
            existingPath.slice(0, pathToCompareAgainst.length),
            pathToCompareAgainst,
          );

          const getSubtitle = () => {
            if (relatedComponent) {
              return `From ${
                componentTypeDirectory[relatedComponent.type].defaultName
              }`;
            }
            return displayValue ?? undefined;
          };

          const rowData = columnData[field as TopLevelDynamicDataKey];

          const isInvalidTargetType = () => {
            return targetType
              ? exhaustiveSwitch({ type: targetType })({
                  "text/color": () => isString(rowData),
                  "text/integer": () => isNumber(rowData),
                  quantity: () => isNumber(rowData),
                  "text/url": () => isString(rowData),
                  list: () => isArray(rowData),
                  imageList: () => isArray(rowData),
                  productOnly: () => isObject(rowData),
                  productVariant: () => isObject(rowData),
                  sellingPlan: () => isObject(rowData),
                  text: () => isString(rowData),
                  image: () => isString(rowData),
                })
              : false;
          };

          const formattedName =
            field === "_currentItem" && relatedComponent
              ? `${
                  componentTypeDirectory[relatedComponent.type].defaultName
                } Item`
              : startCase(field);

          const tooltipWillHaveText =
            isTopLevelDynamicDataKey(field) &&
            field in DYNAMIC_FIELD_TOOLTIP_DATA;

          const showArrow =
            (isNotNullish(rowData) &&
              !isEmpty(rowData) &&
              !isInvalidTargetType()) ||
            field === "_dataTables";
          return (
            <DynamicDataModalRow
              key={field}
              field={field}
              title={Boolean(displayName) ? displayName! : formattedName}
              subtitle={getSubtitle()}
              onClick={() => {
                onChange(field);
              }}
              showArrow={showArrow}
              isActive={isActive}
              tooltipContent={
                field &&
                tooltipWillHaveText && (
                  <TooltipContent
                    field={field as TopLevelDynamicDataKey}
                    relatedComponentName={relatedComponent?.name ?? undefined}
                    displayName={
                      Boolean(displayName) ? displayName! : formattedName
                    }
                  />
                )
              }
            />
          );
        })}
        {/*
            NOTE (Fran 2024-10-29 USE-1393): We should filter all metafields rows if the target type is ANY_LIST
            we don't allow any metafield that is a list, so we don't need to allow metafields to be used as dynamic data
            for ANY_LIST target type.
         */}
        {targetType !== DynamicDataTargetType.ANY_LIST &&
          (isProductOnlyColumn || isVariantColumn) && (
            <DynamicDataModalRow
              field={metafieldsField}
              title="Metafields"
              subtitle={
                isVariantColumn ? "Variant metafields" : "Product metafields"
              }
              onClick={() => {
                onChange(metafieldsField);
              }}
              isActive={(currentPath ?? [])
                .join(".")
                .includes(
                  (columnPath ?? []).concat([metafieldsField]).join("."),
                )}
              showArrow
            />
          )}
      </div>
    </div>
  );
};

const DYNAMIC_FIELD_TOOLTIP_DATA: Record<
  TopLevelDynamicDataKey,
  { docsUrl?: string; description: string } | null
> = {
  _product: {
    description: "is dynamically connected to the Product config setting for",
  },
  _variant: {
    description:
      "is dynamically connected to the variant the user selected for",
  },
  _sellingPlans: {
    description: "is dynamically connected to the selling plans from",
  },
  _selectedSellingPlan: {
    description:
      "is dynamically connected to the selling plan the user selected for",
  },
  _quantity: {
    description:
      "is dynamically connected to the quantity the user selected for",
  },
  _selectedOptionValues: {
    description:
      "are dynamically connected to the options the user selected for",
  },
  _currentOption: {
    description:
      "is dynamically connected to whichever option is being displayed for",
  },
  _currentOptionValue: {
    description:
      "is dynamically connected to whichever option value is being displayed for",
  },
  _currentItem: {
    description:
      "is dynamically connected to whichever item is being displayed in",
  },
  _currentSellingPlan: {
    description:
      "is dynamically connected to whichever selling plan is being displayed for",
  },
  _accessibilityChecked: null,
  _accessibilityHidden: null,
  _accessibilityRole: null,
  _autoEnableHashmark: null,
  _autoSelectVariant: null,
  _currentSelection: null,
  _currentTabItem: null,
  _currentTemporaryCartItem: null,
  _currentVariant: null,
  _defaultSelectedVariantId: null,
  _options: {
    description: "is dynamically connected to the Product config setting for",
  },
  _optionsValues: {
    description: "is dynamically connected to the Product config setting for",
  },
  _products: null,
  _swatches: {
    description: "are the swatch values for this selected variant or option",
    docsUrl: docs.swatches,
  },
  _temporaryCartItems: null,
  _temporaryCartTotalPrice: null,
  _temporaryCartTotalPriceIncludingDiscounts: null,
  _variants: { description: "is dynamically connected to the variants for" },
  daysUntilEnd: null,
  hoursUntilEnd: null,
  minutesUntilEnd: null,
  secondsUntilEnd: null,
  _endTime: null,
  _templateProduct: {
    description:
      "is dynamically connected to the product that is displaying on this Product page",
  },
};

const TooltipContent: React.FC<{
  displayName: string | null;
  field: TopLevelDynamicDataKey;
  relatedComponentName?: string;
}> = ({ field, relatedComponentName, displayName }) => {
  const dynamicFieldData = DYNAMIC_FIELD_TOOLTIP_DATA[field];

  if (!dynamicFieldData) {
    return null;
  }

  return (
    <div className="max-w-[250px]">
      <span className="font-bold">{displayName} </span>
      {dynamicFieldData?.description}{" "}
      {relatedComponentName && (
        <span className="font-bold">{relatedComponentName}</span>
      )}
      {dynamicFieldData?.docsUrl && (
        <a
          href={dynamicFieldData?.docsUrl}
          target="_blank"
          className="font-bold"
          rel="noreferrer"
        >
          {" "}
          Learn More
        </a>
      )}
    </div>
  );
};

const supportedMetafieldTypes = new Map<MetafieldType, string>([
  ["color", "Color"],
  ["multi_line_text_field", "Multi-line text"],
  ["number_decimal", "Decimal number"],
  ["number_integer", "Integer number"],
  ["single_line_text_field", "Single-line text"],
  ["url", "URL"],
  ["file_reference", "File reference"],
  ["rich_text_field", "Rich Text"],
]);

const MetafieldsDynamicDataModalColumn: React.FC<{
  currentPath: string[] | null;
  columnPath: string[];
  currentMetafieldType: "product" | "variant";
  onChange(data: { key: string; namespace: string }): void;
}> = ({ currentPath, currentMetafieldType, columnPath, onChange }) => {
  const isCurrentProductRow = currentMetafieldType === "product";

  const {
    isLoading: productMetafieldsIsLoading,
    productMetafieldsDefinitions = [],
  } = useFetchProductMetafieldDefinitions({
    shouldSkip: currentMetafieldType !== "product",
  });
  const {
    isLoading: variantMetafieldsIsLoading,
    variantMetafieldsDefinitions = [],
  } = useFetchVariantMetafieldsDefinitions({
    shouldSkip: currentMetafieldType !== "variant",
  });

  const { definitions, isLoading, noMetafieldsPlaceholder } = exhaustiveSwitch({
    type: currentMetafieldType,
  })({
    product: {
      definitions: productMetafieldsDefinitions,
      isLoading: productMetafieldsIsLoading,
      noMetafieldsPlaceholder: "No metafields available for this product",
    },
    variant: {
      definitions: variantMetafieldsDefinitions,
      isLoading: variantMetafieldsIsLoading,
      noMetafieldsPlaceholder: "No metafields available for this variant",
    },
  });

  const supportedDefinitions = definitions.filter((def) =>
    supportedMetafieldTypes.has(def.type),
  );

  return (
    <div className="h-full w-[250px] shrink-0 overflow-y-auto">
      <div className="flex w-full flex-col">
        {isLoading && (
          <div className="flex w-full justify-center py-2">
            <Spinner variant="primary" size={50} />
          </div>
        )}
        {!isLoading && supportedDefinitions.length === 0 && (
          <p className="p-2 text-sm text-gray-900">{noMetafieldsPlaceholder}</p>
        )}
        {supportedDefinitions.map(({ name, type, key, namespace }) => (
          <DynamicDataModalRow
            key={key}
            field={
              isCurrentProductRow ? "productMetafields" : "variantMetafields"
            }
            title={name}
            subtitle={supportedMetafieldTypes.get(type) ?? type}
            onClick={() => {
              onChange({
                key,
                namespace,
              });
            }}
            isActive={
              (currentPath ?? []).join(".") ===
              (columnPath ?? []).concat([namespace, key]).join(".")
            }
          />
        ))}
      </div>
    </div>
  );
};

/**
 * Component that renders a specific column's row of the dynamic data modal
 */
const DynamicDataModalRow: React.FC<{
  field: string;
  onClick(): void;
  isActive: boolean;
  title: string;
  subtitle?: string;
  showArrow?: boolean;
  tooltipContent?: React.ReactNode;
}> = ({
  field,
  onClick,
  isActive,
  title,
  subtitle,
  showArrow,
  tooltipContent,
}) => (
  <div
    onClick={onClick}
    className={twMerge(
      "flex cursor-pointer items-center justify-between rounded-md p-3 text-sm font-medium text-default ",
      isActive && "hover:bg-slate-100 hover:text-default",
      isActive && !showArrow && "bg-blue-600 text-white",
      isActive && showArrow && "bg-blue-300 text-white",
    )}
    data-testid={`dynamic-data-row-${field}`}
  >
    <div className="flex w-full flex-col gap-1">
      <div className="flex gap-2 items-center">
        {title}
        {tooltipContent && (
          <Tooltip
            content={tooltipContent}
            disableHoverableContent={
              !Boolean(
                DYNAMIC_FIELD_TOOLTIP_DATA[field as TopLevelDynamicDataKey]
                  ?.docsUrl,
              )
            }
            triggerAsChild
          >
            <span
              tabIndex={0}
              className={twMerge(
                "flex items-center text-muted",
                isActive && "text-white",
              )}
            >
              <BsInfoCircleFill size={12} />
            </span>
          </Tooltip>
        )}
      </div>
      {subtitle && (
        <div
          className={twMerge(
            "truncate text-xs text-muted",
            isActive && "text-white",
          )}
        >
          {subtitle}
        </div>
      )}
    </div>
    {showArrow && (
      <BsChevronRight
        size={16}
        className={twMerge("text-muted", isActive && "text-white")}
      />
    )}
  </div>
);

/**
 * Hook to get the dynamic data along with the current selected path
 */
function useDynamicData({
  initialPath,
  targetType,
  referrerData,
  excludedAttributeKeys,
}: {
  initialPath?: string[];
  targetType: DynamicDataTargetType | undefined;
  referrerData: DynamicDataModalProps["referrerData"];
  excludedAttributeKeys?: string[];
}) {
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const { moneyFormat, activeCurrency, activeLanguage } =
    useEditorSelector(selectLocaleData);
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;

  const { products } = useAnyStoreProducts();
  const applyComponentAction = useApplyComponentAction();

  const productDependencies = {
    products,
    currencyCode: activeCurrency,
    language: activeLanguage,
    moneyFormat,
    templateProduct,
    isEditor: true,
    isShopifyProductsLoading: false,
  };

  // Note (Evan, 2023-10-10): For product templates, we want to avoid showing both "Template Product" and "Current Product"
  // at the same time in the Dynamic Data selector (it's confusing). So we pursue the following strategy:
  // - hide "Current Product" for Product components (this happens in the product selector)
  // - hide "Template Product" for everything else (this happens here)
  // This encourages the pattern of having the template product set for the Product component, then having
  // its children use "current product" to transitively reference the template product. Note that we don't have to check the
  // draft element type here since excluding the _templateProduct key when it already isn't defined is a no-op.
  const isProductLikeTarget =
    targetType &&
    [
      DynamicDataTargetType.PRODUCT,
      DynamicDataTargetType.PRODUCT_VARIANT,
    ].includes(targetType);

  const excludedAttributeKeysPlusTemplateProduct = [
    ...(excludedAttributeKeys ?? []),
    ...(isProductLikeTarget ? [] : ["_templateProduct"]),
  ];

  // We need to get context on the fly  because the selectDraftComponentContext
  // might return null if context is not loaded on window.
  const draftComponentsContexts = draftComponentIds.map((componentId) =>
    getCurrentComponentContext(componentId, 0),
  );
  const draftComponentsAttributes = draftComponentsContexts.map(
    (context) => context?.attributes,
  );

  // NOTE (Matt 2025-01-30): It is possible for these attributes to have a temporarily null value,
  // but we still want to be able to select them in the dynamic data modal.
  const nullableAttributes = new Set(["_selectedSellingPlan"]);

  // NOTE (Jackson, 2025-03-12): This was added to ensure that the dynamic data modal
  // works with multiselect. It checks which attributes are shared across selected components,
  // in case multiple are selected. If only one is selected, it just shows that one.
  const sharedAttributes = omitBy(
    mapValues(draftComponentsAttributes[0], (value, key) => {
      if (nullableAttributes.has(key)) {
        return true;
      }
      if (!value) {
        return null;
      }

      return draftComponentsAttributes
        .slice(1)
        .every((draftComponentAttributes) =>
          Boolean(draftComponentAttributes?.[key as TopLevelDynamicDataKey]),
        )
        ? value
        : null;
    }),
    (value) => value === null,
  );

  const filteredDynamicData: Record<string, any> = filterDynamicDataByType(
    mapValues(sharedAttributes, (value: any, key: TopLevelDynamicDataKey) => {
      if (key === "_product") {
        // NOTE (Martin 2025-01-07): There is no need to include product in
        // dynamic data for selling plans, so we skip it here.
        if (targetType === DynamicDataTargetType.SELLING_PLAN) {
          return;
        }

        return {
          ...value,
          // TODO (Noah, 2023-02-17): For some godforsaken reason, ReploShopifyProduct has
          // a `variant` field which corresponds to the first variant (or the variant specified by
          // the ProductRef, if the productRef has a variant id). This is totally backwards, and
          // it doesn't make sense to allow it to be selectable from dynamic data, so we remove it.
          // We should actually remove this variant entirely from ReploShopifyProduct, since it
          // doesn't make sense
          variant: null,
          // HACK (Noah, 2023-02-17, REPL-6353): Since we filter down a big _attributes object to
          // determine what parts of it to show in dynamic data based on the target type, there might
          // be cases where we're looking for a certain type that only exists after loading metafields.
          // For example, there might be no color attribute on a product, but there might be a valid
          // color in one of its metafields. For this reason, we add this placeholder to make sure that
          // the product doesn't get filtered out of dynamic data (but when actually rendering the column,
          // we ignore it)
          metafieldsPlaceholder: true,
        };
      } else if (
        // Note (Noah, 2023-02-17): We can't check the value for the presence of a "variantId" field here
        // because the variant might be null if the user didn't check the "default selected variant" config
        // prop of the Product box. So, we have to check the dynamic data key names instead. _variant is for
        // the selected variant of the product container, _currentVariant is for the current repetition of
        // a Variant List component
        ["_variant", "_currentVariant"].includes(key)
      ) {
        return {
          ...value,
          // Note (Noah, 2023-02-17): See above for why this metafields placeholder is necessary to ensure
          // we don't filter out selected variant metafields in the dynamic data modal
          metafieldsPlaceholder: true,
        };
      }

      return value;
    }),
    targetType ?? null,
    excludedAttributeKeysPlusTemplateProduct,
    productDependencies,
  );

  const [currentPath, setCurrentPath] = React.useState<string[] | null>(
    draftComponentsContexts.length === 1 ? initialPath ?? null : null,
  );

  const currentPathKey = currentPath?.at(-1) ?? null;
  const parentColumnPathKey = currentPath?.at(-3) ?? "";
  const isMetafieldKey = ["productMetafields", "variantMetafields"].includes(
    parentColumnPathKey,
  );
  const dynamicDataWithComponentId = mapValues(
    filteredDynamicData,
    (value: Record<string, any>, key: TopLevelDynamicDataKey) => {
      return {
        componentId:
          draftComponentsContexts[0]?.attributeKeyToComponentId?.[key] ?? null,
        data: value,
      };
    },
  ) as unknown as Record<
    string,
    { componentId: string; data: Record<string, any> }
  >;

  const dynamicData = mapValues(dynamicDataWithComponentId, (value) => {
    return value.data;
  });

  // TODO (Noah, 2022-11-26): We always set null as the value if the key we're currently
  // looking at is a metafield - we don't know the value at all since we haven't fetched
  // it yet (it will be fetched after we save the key). Really, we should fetch the metafield
  // values at the same time as the metafield definitions so that we could just filter
  // them like any other dynamic data path.
  const currentPathValue = isMetafieldKey
    ? null
    : get(dynamicData, currentPath ?? "", {});

  const keyToGetDynamicDataValue = isMetafieldKey
    ? "metafieldsWithNamespaceAndKey"
    : currentPathKey;

  const { type } = getDynamicDataValue(
    // Special treatment if we are dealing with product metafields
    keyToGetDynamicDataValue,
    targetType ?? null,
    currentPathValue,
    productDependencies,
  );

  // NOTE (Evan, 8/10/2023) If our path corresponds to a data table, we have to pass
  // type="dataTableId" to the callback
  const selectedDataTableId =
    currentPath && currentPath.length == 2 && currentPath[0] === "_dataTables"
      ? currentPath[1]
      : null;

  const isValidPath = targetType && type.startsWith(targetType);
  const onSubmit = () => {
    if (targetType && currentPath) {
      const fullPath = ["attributes"].concat(currentPath).join(".");
      const nextTemplateTag = [
        DynamicDataTargetType.PRODUCT,
        DynamicDataTargetType.PRODUCT_VARIANT,
      ].includes(targetType as DynamicDataTargetType)
        ? {
            type: "contextRef" as const,
            ref: fullPath,
          }
        : `{{${fullPath}}}`;

      switch (referrerData.type) {
        case "style":
          applyComponentAction({
            type: "setStyles",
            value: {
              [referrerData.styleAttribute]: nextTemplateTag,
            },
          });
          return;

        case "prop":
          applyComponentAction({
            type: "setProps",
            value: {
              [referrerData.propId]: nextTemplateTag,
            },
          });
          return;

        case "callback":
          if (selectedDataTableId) {
            referrerData.onChange(selectedDataTableId, "dataTableId");
          } else {
            referrerData.onChange(nextTemplateTag);
          }
          return;

        default:
          return;
      }
    }
  };

  return {
    dynamicDataWithComponentId,
    currentDynamicDataPath: currentPath,
    setCurrentDynamicDataPath: setCurrentPath,
    isValidDynamicDataPath: isValidPath,
    onSubmitDynamicDataPath: onSubmit,
  };
}

const DataCollectionsColumn: React.FC<{
  selectedDataTableId: string | null;
  onChange(value: string): void;
}> = ({ selectedDataTableId, onChange }) => {
  const dataTablesMapping = useEditorSelector(selectDataTablesMapping);
  return (
    <div className="h-full w-[250px] shrink-0 overflow-y-auto">
      {Object.values(dataTablesMapping).map((dataTable) => {
        const nRows = dataTable.data.rows.length;
        return (
          <DynamicDataModalRow
            key={dataTable.id}
            field={dataTable.id}
            title={dataTable.name}
            subtitle={`${nRows} ${nRows === 1 ? "Row" : "Rows"}`}
            onClick={() => {
              onChange(dataTable.id);
            }}
            isActive={dataTable.id === selectedDataTableId}
          />
        );
      })}
    </div>
  );
};
