import * as React from "react";

import {
  SubtitleSkeleton,
  TitleSkeleton,
} from "@editor/components/dashboard/SkeletonLoaders";

type HeaderProps = {
  title: string | null;
  subtitle?: string;
  isLoading?: boolean;
  endEnhancer?: React.ReactNode;
};

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  isLoading,
  endEnhancer,
}) => {
  return (
    <div className="flex w-full justify-between items-center">
      <div className="flex flex-col gap-2">
        {isLoading && (
          <>
            <TitleSkeleton />
            <SubtitleSkeleton />
          </>
        )}
        {!isLoading && title && (
          <h2 className="typ-header-h2 text-default">{title}</h2>
        )}

        {!isLoading && subtitle && (
          <p className="typ-body-base text-muted">{subtitle}</p>
        )}
      </div>
      {endEnhancer}
    </div>
  );
};

export default Header;
