import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import * as React from "react";

import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { selectProjectId } from "@editor/reducers/core-reducer";
import { store, useEditorSelector } from "@editor/store";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import {
  errorToast,
  successToast,
} from "@replo/design-system/components/alert/Toast";

import { usePresetColorStyles } from "./usePresetColorStyles";
import { usePresetTextStyles } from "./usePresetTextStyles";

export type StyleCategory =
  | "playful"
  | "bold"
  | "natural"
  | "modern"
  | "professional"
  | "elegant"
  | "futuristic";

export const useDraftStyles = () => {
  const { colorShopStyles, textShopStyles, designLibrary } = useShopStyles();
  const projectId = useEditorSelector(selectProjectId);
  const { getColorStylesByCategory } = usePresetColorStyles();
  const { getTextStylesByCategory } = usePresetTextStyles();

  const [draftStyles, setDraftStyles] = React.useState<{
    text: (SavedTextStyle & { id: string })[];
    color: (SavedColorStyle & { id: string })[];
  } | null>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const projectIdRef = React.useRef(projectId);

  React.useEffect(() => {
    if (projectIdRef.current !== projectId) {
      setDraftStyles({
        text: textShopStyles || [],
        color: colorShopStyles || [],
      });
    }
  }, [projectId, colorShopStyles, textShopStyles]);

  React.useEffect(() => {
    const shouldInitialize = !draftStyles;

    if (shouldInitialize) {
      setDraftStyles({
        text: textShopStyles || [],
        color: colorShopStyles || [],
      });
      setIsInitialized(true);
    }
  }, [colorShopStyles, textShopStyles, draftStyles]);

  const updateDraftTextStyle = React.useCallback(
    (updatedStyle: SavedTextStyle & { id: string }) => {
      setDraftStyles((prevState) => {
        if (!prevState) {
          return prevState;
        }

        return {
          ...prevState,
          text: prevState.text.map((style) =>
            style.id === updatedStyle.id ? updatedStyle : style,
          ),
        };
      });
    },
    [],
  );

  const deleteDraftTextStyle = React.useCallback((id: string) => {
    setDraftStyles((prevState) => {
      if (!prevState) {
        return prevState;
      }

      const newState = {
        ...prevState,
        text: prevState.text.filter((style) => style.id !== id),
      };

      return newState;
    });
  }, []);

  const createDraftTextStyle = React.useCallback((newStyle: SavedTextStyle) => {
    const id = crypto.randomUUID();

    setDraftStyles((prevState) => {
      const currentStyles = prevState ?? {
        text: [],
        color: [],
      };

      return {
        ...currentStyles,
        text: [...currentStyles.text, { ...newStyle, id }],
      };
    });

    return { id };
  }, []);

  const updateDraftColorStyle = React.useCallback(
    (updatedStyle: SavedColorStyle & { id: string }) => {
      setDraftStyles((prevState) => {
        if (!prevState) {
          return prevState;
        }

        return {
          ...prevState,
          color: prevState.color.map((style) =>
            style.id === updatedStyle.id ? updatedStyle : style,
          ),
        };
      });
    },
    [],
  );

  const deleteDraftColorStyle = React.useCallback((id: string) => {
    setDraftStyles((prevState) => {
      if (!prevState) {
        return prevState;
      }

      const newState = {
        ...prevState,
        color: prevState.color.filter((style) => style.id !== id),
      };

      return newState;
    });
  }, []);

  const createDraftColorStyle = React.useCallback(
    (newStyle: SavedColorStyle) => {
      const id = crypto.randomUUID();

      setDraftStyles((prevState) => {
        const currentStyles = prevState ?? {
          text: [],
          color: [],
        };

        return {
          ...currentStyles,
          color: [...currentStyles.color, { ...newStyle, id }],
        };
      });

      return { id };
    },
    [],
  );

  const areDraftStylesDifferentFromShopStyles = React.useMemo(() => {
    // NOTE (Cole 2025-03-12): These are only null before they've been initialized with a useEffect
    if (!draftStyles || (!colorShopStyles && !textShopStyles)) {
      return false;
    }

    const areAttributesEqual = (a: any, b: any) => {
      const sortedA = JSON.stringify(a, Object.keys(a).sort());
      const sortedB = JSON.stringify(b, Object.keys(b).sort());
      return sortedA === sortedB;
    };

    const findMatchingStyle = (style: any, styleList: any[] | undefined) => {
      return styleList?.find(
        (s) => s.name === style.name && s.type === style.type,
      );
    };

    const textStyleChanges = draftStyles.text.filter((pendingStyle) => {
      const existingStyle = findMatchingStyle(pendingStyle, textShopStyles);

      if (!existingStyle) {
        return true;
      }

      const attributesChanged = !areAttributesEqual(
        pendingStyle.attributes,
        existingStyle.attributes,
      );

      return attributesChanged;
    });

    const deletedTextStyles = textShopStyles?.filter(
      (style) =>
        !draftStyles.text.some(
          (draft) => draft.name === style.name && draft.type === style.type,
        ),
    );

    const colorStyleChanges = draftStyles.color.filter((pendingStyle) => {
      const existingStyle = findMatchingStyle(pendingStyle, colorShopStyles);

      if (!existingStyle) {
        return true;
      }

      const attributesChanged = !areAttributesEqual(
        pendingStyle.attributes,
        existingStyle.attributes,
      );

      return attributesChanged;
    });

    const deletedColorStyles = colorShopStyles?.filter(
      (style) =>
        !draftStyles.color.some(
          (draft) => draft.name === style.name && draft.type === style.type,
        ),
    );

    const textStylesChanged =
      textStyleChanges.length > 0 || (deletedTextStyles?.length || 0) > 0;

    const colorStylesChanged =
      colorStyleChanges.length > 0 || (deletedColorStyles?.length || 0) > 0;

    return textStylesChanged || colorStylesChanged;
  }, [draftStyles, textShopStyles, colorShopStyles]);

  const { mutateAsync: deleteSavedStyles } =
    trpc.designLibrary.savedStyles.delete.useMutation({
      onSettled: async () => {
        const projectId = selectProjectId(store.getState());

        if (!projectId) {
          return;
        }

        await trpcUtils.designLibrary.get.invalidate({ projectId });
      },
    });

  const { mutateAsync: createSavedStyles } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onSettled: async () => {
        const projectId = selectProjectId(store.getState());

        if (!projectId) {
          return;
        }

        await trpcUtils.designLibrary.get.invalidate({ projectId });
      },
    });

  const { mutateAsync: updateSavedStyles } =
    trpc.designLibrary.savedStyles.update.useMutation({
      onSettled: async () => {
        const projectId = selectProjectId(store.getState());

        if (!projectId) {
          return;
        }

        await trpcUtils.designLibrary.get.invalidate({ projectId });
      },
    });

  const { mutateAsync: createDesignLibrary } =
    trpc.designLibrary.create.useMutation({
      onSettled: async () => {
        if (projectId) {
          await trpcUtils.designLibrary.get.invalidate({
            projectId,
          });
        }
      },
    });

  const persistDraftStyles = async () => {
    if (!projectId || !draftStyles || !areDraftStylesDifferentFromShopStyles) {
      return;
    }

    try {
      // NOTE (Cole 2025-03-06): This is temporary until we migrate away from the design library pattern.
      const designLibraryExists =
        designLibrary !== undefined && designLibrary !== null;

      if (!designLibraryExists) {
        await createDesignLibrary({
          projectId,
          savedStyles: { text: [], color: [] },
        });
      }

      const deleteOperation = async () => {
        const textStylesToDelete =
          textShopStyles
            ?.filter(
              (style) =>
                !draftStyles?.text.some((pending) => pending.id === style.id),
            )
            .map((style) => style.id) || [];

        const colorStylesToDelete =
          colorShopStyles
            ?.filter(
              (style) =>
                !draftStyles?.color.some((pending) => pending.id === style.id),
            )
            .map((style) => style.id) || [];

        const stylesToDelete = [...textStylesToDelete, ...colorStylesToDelete];
        if (stylesToDelete.length > 0) {
          return deleteSavedStyles({
            projectId,
            savedStyleIds: stylesToDelete,
          });
        }
        return Promise.resolve();
      };

      const createOperation = async () => {
        const textStylesToCreate = draftStyles?.text.filter(
          (style) =>
            !textShopStyles?.some((existing) => existing.id === style.id),
        );

        const colorStylesToCreate = draftStyles?.color.filter(
          (style) =>
            !colorShopStyles?.some((existing) => existing.id === style.id),
        );

        const stylesToCreate = [...textStylesToCreate, ...colorStylesToCreate];

        if (stylesToCreate.length > 0) {
          return createSavedStyles({
            projectId,
            savedTextStyles: textStylesToCreate,
            savedColorStyles: colorStylesToCreate,
          });
        }
        return Promise.resolve();
      };

      const updateOperation = async () => {
        const textStylesToUpdate = draftStyles?.text.filter((pendingStyle) =>
          textShopStyles?.some(
            (existingStyle) =>
              existingStyle.id === pendingStyle.id &&
              JSON.stringify(pendingStyle) !== JSON.stringify(existingStyle),
          ),
        );

        const colorStylesToUpdate = draftStyles?.color.filter((pendingStyle) =>
          colorShopStyles?.some(
            (existingStyle) =>
              existingStyle.id === pendingStyle.id &&
              JSON.stringify(pendingStyle) !== JSON.stringify(existingStyle),
          ),
        );

        if (
          (textStylesToUpdate && textStylesToUpdate.length > 0) ||
          (colorStylesToUpdate && colorStylesToUpdate.length > 0)
        ) {
          const stylesToUpdate = [
            ...(textStylesToUpdate || []),
            ...(colorStylesToUpdate || []),
          ].map((style) => ({
            savedStyleId: style.id,
            name: style.name,
            attributes: style.attributes,
          }));

          return updateSavedStyles({
            projectId,
            styles: stylesToUpdate,
          });
        }
        return Promise.resolve();
      };

      await Promise.all([
        deleteOperation(),
        createOperation(),
        updateOperation(),
      ]);

      successToast("Shop styles saved");
    } catch {
      errorToast(
        "Failed to save styles",
        "Please try again, or contact support@replo.app for help.",
      );
    }
  };

  const applyPresetStyles = (category: StyleCategory, variantIndex: number) => {
    if (!projectId || isLoading) {
      return;
    }

    setIsLoading(true);

    // NOTE (Cole 2025-03-07): We do this rather than just setting the draft styles because we want to utilize the logic for createTextStyle etc.
    try {
      setDraftStyles({
        text: [],
        color: [],
      });

      const colorStyles = getColorStylesByCategory(category, variantIndex);
      const textStyles = getTextStylesByCategory(category, variantIndex);

      textStyles.forEach((style) => {
        createDraftTextStyle(style);
      });

      colorStyles.forEach((style) => {
        createDraftColorStyle(style);
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    draftStyles,
    isLoading: isLoading || !isInitialized,
    setDraftStyles,
    updateDraftTextStyle,
    deleteDraftTextStyle,
    createDraftTextStyle,
    updateDraftColorStyle,
    deleteDraftColorStyle,
    createDraftColorStyle,
    areDraftStylesDifferentFromShopStyles,
    persistDraftStyles,
    applyPresetStyles,
  };
};
