import * as React from "react";

import { isValidHttpUrl } from "replo-utils/lib/url";

export const isImageSourceValid = (src: string | null) => {
  // NOTE (Sebas, 2024-04-30): If the value is null, we should consider it as a valid src
  // to avoid showing the error message.
  if (!src || src.startsWith("data:image") || src.startsWith("{{attributes")) {
    return true;
  }
  return isValidHttpUrl(src);
};
