import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

export const generateNewName = (
  savedStyles: Array<SavedTextStyle | SavedColorStyle>,
  type: "text" | "color",
): string => {
  const prefix = type === "text" ? "Text" : "Color";

  const existingNumbers = savedStyles
    .filter((style) => style.name.startsWith(prefix))
    .map((style) => {
      const match = style.name.match(/(\d+)$/);
      return match && match[1] ? Number.parseInt(match[1], 10) : 0;
    });

  const nextNumber =
    existingNumbers.length > 0 ? Math.max(0, ...existingNumbers) + 1 : 1;

  return `${prefix} ${nextNumber}`;
};

// NOTE (Sebas, 2025-01-21): This regex is used to separate props from a string
// that can have a design library value. e.g "4px {{library.id.styles.id}}" -> ["4px", "{{library.id.styles.id}}"]
export const regexForSplittingPropValuesWithDynamicData =
  /({{.*?}}|#[\dA-Fa-f]{6,8}|\S+)/g;
