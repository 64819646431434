import * as React from "react";

import SelectableCard from "@replo/design-system/components/card/SelectableCard";
import SelectableChip from "@replo/design-system/components/chip/SelectableChip";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";

type Option<Value> = {
  value: Value;
  tooltip?: string;
  startEnhancer?: React.ReactElement;
  label: React.ReactNode;
  subLabel?: string;
};

type SelectableButtonsProps<Value extends string> = {
  options: Option<Value>[];
  value: Value[];
  onChange: (value: Value[]) => void;
  multiSelect: boolean;
  layoutClassName?: string;
  size?: "sm" | "base";
  /* This prop is helpful when we a have an option called Other */
  inputEnhancer?: React.ReactNode;
  buttonLayoutClassName?: string;
};

const SelectableButtons = <Value extends string>({
  options,
  value: selectedValues,
  layoutClassName,
  size = "base",
  onChange,
  multiSelect,
  inputEnhancer,
  buttonLayoutClassName,
}: SelectableButtonsProps<Value>) => {
  return (
    <div
      className={twMerge(
        "flex flex-wrap gap-3",
        size === "sm" ? "gap-1" : "gap-2",
        layoutClassName,
      )}
    >
      {options.map((option) => {
        return (
          <Tooltip content={option.tooltip} key={option.value} triggerAsChild>
            {option.subLabel ? (
              <SelectableCard
                layoutClassName={buttonLayoutClassName}
                size={size}
                title={
                  typeof option.label === "string" ? option.label : option.value
                }
                description={option.subLabel}
                active={selectedValues.includes(option.value)}
                onChange={() => {
                  if (selectedValues.includes(option.value)) {
                    onChange(
                      [...selectedValues].filter((v) => v !== option.value),
                    );
                  } else {
                    if (multiSelect) {
                      onChange([...selectedValues, option.value]);
                    } else {
                      onChange([option.value]);
                    }
                  }
                }}
              />
            ) : (
              <SelectableChip
                layoutClassName={buttonLayoutClassName}
                size={size}
                variant="default"
                startEnhancer={option.startEnhancer}
                active={selectedValues.includes(option.value)}
                onChange={() => {
                  if (selectedValues.includes(option.value)) {
                    onChange(
                      [...selectedValues].filter((v) => v !== option.value),
                    );
                  } else {
                    if (multiSelect) {
                      onChange([...selectedValues, option.value]);
                    } else {
                      onChange([option.value]);
                    }
                  }
                }}
              >
                {option.label}
              </SelectableChip>
            )}
          </Tooltip>
        );
      })}
      {inputEnhancer}
    </div>
  );
};

export default SelectableButtons;
