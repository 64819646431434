import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import Input from "@common/designSystem/Input";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { isFeatureEnabled } from "@editor/infra/featureFlags";

import AssetLibraryPopover from "@/features/assets/components/AssetLibraryPopover";
import { Badge } from "@replo/design-system/components/badge/Badge";
import twMerge from "@replo/design-system/utils/twMerge";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import { DynamicDataSelector } from "../../../DynamicDataSelector";

type InlineAssetSelectorProps = {
  asset?:
    | { type: "video"; src: string | undefined }
    | { type: "image"; src: string | undefined }
    | ReploMixedStyleValue;
  emptyTitle?: string;
  onClickSelectAsset?(): void;
  onRemoveAsset?(): void;
  allowRemoveAsset?: boolean;
  allowsDynamicData?: boolean;
  onClickDynamicData?(): void;
  // TODO (Jackson, 2025-03-21): Remove old dynamic data handling post 100%
  onSelectDynamicData?(value: string): void;
  alwaysShowRemoveButton?: boolean;
  onInputChange?(url: string): void;
  isDisabled?: boolean;
  inputName?: string;
  autocomplete?: "on" | "off";
  wrapperClassnames?: string;
  inputPlaceholder?: string;
  size: "sm" | "base";
  shouldOpenLibraryPopover?: boolean;
};

const InlineAssetSelector = ({
  asset,
  emptyTitle,
  onClickSelectAsset,
  onRemoveAsset,
  allowsDynamicData = false,
  allowRemoveAsset = true,
  onClickDynamicData,
  onSelectDynamicData,
  alwaysShowRemoveButton,
  onInputChange,
  isDisabled,
  inputName,
  autocomplete = "off",
  wrapperClassnames,
  inputPlaceholder,
  size,
  shouldOpenLibraryPopover = true,
}: InlineAssetSelectorProps) => {
  const [isAssetPickerPopoverOpen, setIsAssetPickerPopoverOpen] =
    React.useState(false);

  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");

  function _getTitle() {
    return !isMixedStyleValue(asset) ? asset?.src ?? emptyTitle : emptyTitle;
  }

  const valueIsDynamic = !isMixedStyleValue(asset)
    ? isDynamicDataValue(asset?.src)
    : false;
  const showInputField = !valueIsDynamic && Boolean(onInputChange);
  const srcInputProps = useOverridableInput({
    value: !isMixedStyleValue(asset) ? asset?.src ?? "" : "Mixed",
    onValueChange: onInputChange,
  });

  const showRemoveButton = (() => {
    if (!onRemoveAsset) {
      return false;
    }
    if (alwaysShowRemoveButton) {
      return true;
    }
    if (!allowRemoveAsset) {
      return false;
    }
    if (isMixedStyleValue(asset)) {
      return true;
    }
    return Boolean(asset?.src);
  })();

  return (
    <div className={twMerge("flex", wrapperClassnames)}>
      <div className="flex w-full items-center gap-1">
        <div className="flex w-full items-center rounded bg-subtle gap-1">
          {showInputField && (
            <Input
              {...srcInputProps}
              shouldSelectTextOnFocus
              placeholder={inputPlaceholder ?? "Source"}
              startEnhancer={
                <button
                  type="button"
                  data-testid="select-asset"
                  disabled={isDisabled}
                  onClick={() => {
                    if (shouldOpenLibraryPopover) {
                      setIsAssetPickerPopoverOpen(true);
                    } else {
                      onClickSelectAsset?.();
                    }
                  }}
                >
                  {isMixedStyleValue(asset) ? (
                    <Badge type="unknown" />
                  ) : (
                    <AssetLibraryPopover
                      isOpen={isAssetPickerPopoverOpen}
                      setIsOpen={setIsAssetPickerPopoverOpen}
                      onChangeSource={onInputChange}
                      assetTypes={[asset?.type ?? "image"]}
                      anchor={
                        // TODO (Gabe 2025-03-12, REPL-16485): This div is
                        // required because the Badge doesn't acccept forwarded
                        // props.
                        <div>
                          <Badge
                            type={asset?.type ?? "image"}
                            src={asset?.src ?? ""}
                            isFilled
                          />
                        </div>
                      }
                    />
                  )}
                </button>
              }
              onOptionClick={onRemoveAsset}
              isDisabled={isDisabled}
              name={inputName}
              autoComplete={autocomplete}
              size={size}
              endEnhancer={() =>
                showRemoveButton && (
                  <RemoveAssetButton onRemoveAsset={onRemoveAsset!} />
                )
              }
            />
          )}
          {!showInputField && (
            <span
              className={twMerge(
                "cursor-pointer truncate text-xs",
                asset ? "text-default" : "text-subtle",
              )}
              onClick={() => onClickSelectAsset?.()}
            >
              {_getTitle()}
            </span>
          )}
          {!showInputField && showRemoveButton && (
            <RemoveAssetButton onRemoveAsset={onRemoveAsset!} />
          )}
        </div>
        {allowsDynamicData && (
          <div className="flex">
            {isNewDynamicData ? (
              <DynamicDataSelector
                trigger={<DynamicDataButton />}
                targetType={DynamicDataTargetType.IMAGE}
                side="left"
                sideOffset={222}
                onChange={(newValue) => {
                  onSelectDynamicData?.(newValue);
                }}
              />
            ) : (
              <DynamicDataButton onClick={() => onClickDynamicData?.()} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const RemoveAssetButton: React.FC<{ onRemoveAsset(): void }> = ({
  onRemoveAsset,
}) => {
  return <FormFieldXButton onClick={() => onRemoveAsset()} />;
};

export default InlineAssetSelector;
