import * as React from "react";

import { ReferralLinkCallout } from "@components/dashboard/partner/ReferralLinkCallout";
import { PartnershipReferralBoardCardSkeleton } from "@components/dashboard/SkeletonLoaders";
import Separator from "@editor/components/common/designSystem/Separator";
import Header from "@editor/components/dashboard/Header";
import PartnershipLevelCard from "@editor/components/dashboard/partner/PartnershipLevelCard";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { parseFloat } from "replo-utils/lib/math";

import ReferredUsersTable from "./components/ReferredUsersTable";
import ReferredWorkspacesTable from "./components/ReferredWorkspacesTable";
import { PaypalForm } from "./forms/PaypalForm";

export const PartnershipReferralBoard = () => {
  const workspaceId = useCurrentWorkspaceId();

  const { data: summary, isLoading } = trpc.referralCode.getSummary.useQuery(
    workspaceId ?? skipToken,
  );

  const query = workspaceId
    ? {
        workspaceId,
      }
    : skipToken;
  const { data: referredUsersData } =
    trpc.referralCode.getReferredUsers.useQuery(query);
  const referredUsers = referredUsersData?.referredUsers ?? [];
  const { data: referredWorkspacesData } =
    trpc.referralCode.getReferredWorkspaces.useQuery(query);
  const referredWorkspaces = referredWorkspacesData?.referredWorkspaces ?? [];
  const payingWorkspaces = referredWorkspacesData?.payingWorkspaces ?? 0;

  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleDateString("en-US", {
    month: "long",
  });

  const totalCommissionInUSD = summary?.totalCommissionInUSD
    ? parseFloat(summary.totalCommissionInUSD)
    : 0;
  const amount = totalCommissionInUSD.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const summaryInfo = [
    {
      title: `${currentMonth} Estimated Earnings`,
      amount,
      callToAction: null,
    },
    {
      title: "Monthly Revenue Share",
      amount: summary?.revenueSharePercentage ?? 0,
      callToAction: null,
      endEnhancer: "%",
    },
    {
      title: "Workspaces Referred",
      amount: referredWorkspaces.length ?? 0,
      callToAction: null,
      endEnhancer: null,
    },
    {
      title: "Users Referred",
      amount: referredUsers.length,
      callToAction: null,
      endEnhancer: null,
    },
  ];

  return (
    <div className="flex flex-col gap-8">
      <PartnershipLevelCard
        payingWorkspaces={payingWorkspaces ?? 0}
        currentTier={summary?.partnershipTier ?? "affiliate"}
        isLoading={isLoading}
      />
      <div className="flex justify-between gap-6">
        {summaryInfo.map(
          ({ title, amount, callToAction: CallToAction, endEnhancer }) => {
            return (
              <div
                key={title}
                className="flex min-h-40 flex-1 flex-col gap-3 rounded-lg border p-5"
              >
                {isLoading ? (
                  <PartnershipReferralBoardCardSkeleton
                    showCta={Boolean(CallToAction)}
                  />
                ) : (
                  <>
                    <div className="flex justify-between">
                      <h3 className="text-sm text-muted">{title}</h3>
                    </div>
                    <span className="text-3xl font-medium">
                      {amount}
                      {endEnhancer}
                    </span>
                  </>
                )}
              </div>
            );
          },
        )}
      </div>
      <Separator />
      <ReferralLinkCallout />
      <Separator />
      <div>
        <div className="flex max-w-4xl flex-col items-start justify-items-start gap-y-4">
          <Header
            title="Payout Information"
            subtitle="At the end of each month, your rewards are calculated and available
            for cash-out via PayPal. Before cashing out, make sure you have the
            correct PayPal email address linked here."
          />
          <PaypalForm />
        </div>
      </div>
      <Separator />
      <ReferredWorkspacesTable />
      <ReferredUsersTable />
    </div>
  );
};
