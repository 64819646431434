import type { ReactElement } from "react";

import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import Tooltip from "../tooltip/Tooltip";

type ChipProps = {
  size: "base" | "sm" | "lg";
  children?: React.ReactNode;
  UNSAFE_className?: string;
  layoutClassName?: string;
  startEnhancer?: ReactElement | false;
  active?: boolean;
  onChange?: () => void;
  tooltipText?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: "default" | "empty";
  showHoverStateWhenActive?: boolean;
};

const SelectableChip: React.FC<
  ChipProps & Omit<React.HTMLAttributes<HTMLButtonElement>, "className">
> = ({
  size,
  children,
  UNSAFE_className,
  layoutClassName,
  startEnhancer,
  active,
  onChange,
  onClick,
  tooltipText,
  disabled,
  variant = "default",
  showHoverStateWhenActive = true,
  ...domProps
}) => {
  const [isActive, setIsActive] = useControllableState<boolean>(
    active,
    false,
    onChange,
  );

  const onChipClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }
    setIsActive(!isActive);
  };

  const chipSize = {
    sm: "typ-body-small py-[6px] px-2 h-[26px]",
    base: "typ-body-base py-2 px-3 h-[32px]",
    lg: "typ-body-large py-2 px-4",
  };

  const chipComponent = (
    <button
      type="button"
      className={twMerge(
        "flex items-center justify-center rounded-md font-normal border-[.5px] border-border gap-[6px] text-muted cursor-pointer hover:bg-selectable-hover bg-white",
        chipSize[size],
        isActive && "border-primary bg-selectable-selected text-primary",
        showHoverStateWhenActive &&
          isActive &&
          "hover:bg-selectable-selected-hover",
        !showHoverStateWhenActive && isActive && "hover:bg-selectable-selected",
        disabled && "opacity-50 cursor-not-allowed",
        variant === "empty" &&
          "border-dashed bg-hover hover:bg-light-surface-hover hover:bg-opacity-45",
        layoutClassName,
        UNSAFE_className,
      )}
      onClick={onChipClick}
      disabled={disabled}
      {...domProps}
    >
      {startEnhancer}
      <span className="leading-none">{children}</span>
    </button>
  );
  return tooltipText ? (
    <Tooltip content={tooltipText} triggerAsChild>
      {chipComponent}
    </Tooltip>
  ) : (
    chipComponent
  );
};

export default SelectableChip;
