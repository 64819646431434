import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import truncate from "lodash-es/truncate";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { distance } from "replo-utils/lib/math";

/**
 * Given a list of elements and a point, returns the element whose bounds are
 * closest to the point.
 *
 * The closeness is calculated on a cartesian basis and we assume there are no
 * overlapping elements. That is, for each element we find the distance to the
 * closest point on the perimeter of the element to the given point, and return
 * the element whose distance is smallest.
 */
export function closestElementToPoint(
  elements: HTMLElement[],
  x: number,
  y: number,
): HTMLElement | null {
  if (elements.length === 0) {
    return null;
  }

  let closestElement: HTMLElement | null = null;
  let minDistance = Number.POSITIVE_INFINITY;

  elements.forEach((element) => {
    const rect = element.getBoundingClientRect();
    const closestX = Math.max(rect.left, Math.min(x, rect.right));
    const closestY = Math.max(rect.top, Math.min(y, rect.bottom));
    const dist = distance(closestX, closestY, x, y);

    if (dist < minDistance) {
      minDistance = dist;
      closestElement = element;
    }
  });

  return closestElement;
}

export function getEditorElementRootNodeByCanvas(canvas: EditorCanvas) {
  return window.document.querySelector<HTMLElement>(
    `[data-canvas-id="${canvas}"]`,
  );
}

export function getEditorModalBodyNode() {
  return window.document.querySelector("#alchemy-modal-body-child");
}

/**
 * Strips the first `numTags` html tags from the input string.
 *
 * This is a naive implementation and doesn't account for a bunch of edge cases,
 * but the tradeoff is that it's much faster than most library implementations.
 * We need this to be fast because it's called frequently (once per text/button
 * component in the component tree on every edit)
 *
 * @param opts.tagCount The number of tags to strip. Defaults to 1.
 * @author Noah 2024-08-17
 */
export function naiveRemoveHtmlTags(
  inputString: string,
  opts?: { tagCount?: number },
): string {
  const regex =
    /^([^<]*?)<(p|h1|h2|h3|h4|h5|h6|span|strong|em|b|i|style|mark|br|div|a)(.*?)>(.*?)<\/\2>(.*?)$/s;

  let strippedString = inputString.replace(/<br\s*?\/?>/g, "");
  const iterationCount = opts?.tagCount ?? 1;
  for (let i = 0; i < iterationCount; i++) {
    const match = strippedString.match(regex);
    if (!match) {
      return strippedString;
    }
    const [
      // Note (Noah, 2024-08-17): Imo it's fine to have unused variables here,
      // having them be named makes it clearer what the capturing groups of the
      // regex are
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _fullString,
      stringBeforeTag,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _tagName,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _tagAttributes,
      stringInsideTag,
      stringAfterTag,
    ] = match;
    strippedString =
      (stringBeforeTag ?? "") +
      (stringInsideTag ?? "") +
      (stringAfterTag ?? "");
  }

  return strippedString;
}

export const sanitizeTreeContent = (content: string, scale = 1) => {
  let sanitized = content;
  if (isDynamicDesignLibraryValue(content)) {
    const innerText = extractInnerTextForDesignLibrary(content);
    if (innerText) {
      sanitized = innerText;
    }
  }
  sanitized = naiveRemoveHtmlTags(
    sanitized.replace("\n", " ").replace("&nbsp;", ""),
    { tagCount: 2 },
  );
  const characters = Math.max(27, Math.round(27 * scale));
  return truncate(sanitized, { length: characters });
};

export const getElementMargin = (position: string, node: Element) => {
  const style = getComputedStyle(node);
  switch (position) {
    case "top":
      return style.marginTop;
    case "left":
      return style.marginLeft;
    case "bottom":
      return style.marginBottom;
    case "right":
      return style.marginRight;
    default:
      return "0px";
  }
};

const getElementPadding = (position: string, node: Element) => {
  const style = getComputedStyle(node);
  switch (position) {
    case "top":
      return style.paddingTop;
    case "left":
      return style.paddingLeft;
    case "bottom":
      return style.paddingBottom;
    case "right":
      return style.paddingRight;
    default:
      return "0px";
  }
};

const getElementAbsoluteMargin = (node: Element, position: string) => {
  return getElementMargin(position, node).replace("-", "");
};

export const getElementAbsoluteMargins = (node: Element) => {
  return {
    top: getElementAbsoluteMargin(node, "top"),
    left: getElementAbsoluteMargin(node, "left"),
    bottom: getElementAbsoluteMargin(node, "bottom"),
    right: getElementAbsoluteMargin(node, "right"),
  };
};

export const getElementPaddings = (node: Element) => {
  return {
    top: getElementPadding("top", node),
    left: getElementPadding("left", node),
    bottom: getElementPadding("bottom", node),
    right: getElementPadding("right", node),
  };
};

export function extractInnerTextForDesignLibrary(text: string) {
  const match = text?.match(
    /<((?:p|h[1-6])|(?:{{\s*library\..*?\.styles\..*?\s*}}))(.*?)>(.*?)<\/\1>/s,
  );

  // NOTE (Fran 2024-12-23): Some text elements are not wrapped in html tags,
  // so we return the text as is. Otherwise we will replace the text with a
  // different string.
  if (!match || match.length === 0) {
    return text;
  }

  // NOTE (Fran 2025-01-14): With this regex we get the <>, the tag name, and the text inside the tag.
  // We want to return the text inside the tag, so we return the 3th element of the match array.
  return match[3];
}
