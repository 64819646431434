import * as React from "react";

import { useGlobalEditorActions } from "@editor/hooks/useGlobalEditorActions";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useVisibleBreakpoints } from "@editor/hooks/useVisibleBreakpoints";
import { canvasToStyleMap } from "@editor/utils/editor";
import DocumentationInfoIcon from "@editorComponents/DocumentationInfoIcon";
import ModifierGroup from "@editorExtras/ModifierGroup";

import SelectableChip from "@replo/design-system/components/chip/SelectableChip";
import { BsPlus } from "react-icons/bs";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

const options = [
  {
    value: canvasToStyleMap.mobile,
    label: "Mobile",
    tooltipText: "Toggle mobile visibility",
  },
  {
    value: canvasToStyleMap.tablet,
    label: "Tablet",
    tooltipText: "Toggle tablet visibility",
  },
  {
    value: canvasToStyleMap.desktop,
    label: "Desktop",
    tooltipText: "Toggle desktop visibility",
  },
];

export const VisibilityModifier = () => {
  useVisibilityHotkeys();
  const { visibleBreakpoints, onChangeVisibility } = useVisibleBreakpoints();
  const isMixedVisibilityValue = isMixedStyleValue(visibleBreakpoints);

  return (
    <ModifierGroup
      title="Visibility"
      titleEnhancer={<DocumentationInfoIcon documentationType="visibility" />}
      icon={isMixedVisibilityValue ? BsPlus : undefined}
      iconTooltip={isMixedVisibilityValue ? "Override visibility" : undefined}
      iconShouldOpenModifierGroup
      onClick={
        isMixedVisibilityValue
          ? () => {
              onChangeVisibility([
                canvasToStyleMap.mobile,
                canvasToStyleMap.tablet,
                canvasToStyleMap.desktop,
              ]);
            }
          : undefined
      }
    >
      {isMixedVisibilityValue ? (
        <div className="text-left text-xs text-gray-400">
          Click the + button to override visibility for all selected components.
        </div>
      ) : (
        <div className="flex w-full flex-row gap-1 rounded-md">
          {options.map((option) => (
            <VisibilityOption
              key={option.value}
              {...option}
              isSelected={visibleBreakpoints?.includes(option.value) ?? false}
            />
          ))}
        </div>
      )}
    </ModifierGroup>
  );
};

const VisibilityOption: React.FC<{
  label: string;
  value: string;
  tooltipText: string;
  isSelected: boolean;
}> = ({ label, value, tooltipText, isSelected }) => {
  const { onChangeVisibility } = useVisibleBreakpoints();
  return (
    <SelectableChip
      size="sm"
      tooltipText={tooltipText}
      active={isSelected}
      onChange={() => {
        onChangeVisibility([value]);
      }}
    >
      <span data-testid={`visibility-toggle-${label}`}>{label}</span>
    </SelectableChip>
  );
};

function useVisibilityHotkeys() {
  const globalActions = useGlobalEditorActions();

  useReploHotkeys({
    toggleVisibility: globalActions.toggleVisibility,
  });
}
