import * as React from "react";

import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";

export const PreviewLinkAndSVGSkeleton: React.FC = () => {
  return (
    <div className="w-[200px] h-[200px] bg-slate-100 rounded-md">
      <Skeleton
        className="w-full h-full"
        speed={2}
        backgroundColor="#f1f5f9"
        foregroundColor="#e2e8f0"
      />
    </div>
  );
};

export const WorkspaceCardSkeleton: React.FC = () => {
  return (
    <div className="w-[275px] h-[68px] relative box-border bg-white rounded">
      <Skeleton
        className="w-[38px] h-[38px] absolute top-[11px] left-[11px] rounded-full"
        speed={2}
        backgroundColor="#e2e8f0"
        foregroundColor="#cbd5e1"
      />
      <Skeleton
        className="w-[160px] h-[20px] absolute top-[9px] left-[60px]"
        speed={2}
        backgroundColor="#e2e8f0"
        foregroundColor="#cbd5e1"
      />
      <Skeleton
        className="w-[160px] h-[16px] absolute top-[36px] left-[60px]"
        speed={2}
        backgroundColor="#e2e8f0"
        foregroundColor="#cbd5e1"
      />
    </div>
  );
};
