import type { ReploElementMetadata } from "schemas/generated/element";

import * as React from "react";

import { useErrorToast } from "@editor/hooks/useErrorToast";
import {
  INVALID_ELEMENT_NAME_CHARACTERS_REGEX,
  MAX_SECTION_NAME_LENGTH,
} from "@editor/utils/element";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { slugify } from "replo-utils/lib/string";

export function useElementName({
  element,
  initialName,
}: {
  element?: ReploElementMetadata;
  initialName?: string;
}): {
  elementName: string;
  setElementName: (name: string) => void;
  isEditingName: boolean;
  setIsEditingName: (isEditing: boolean) => void;
  handleUpdateElementName: (name: string) => Promise<void>;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isInvalidElementName: boolean;
} {
  const [isEditingName, setIsEditingName] = React.useState(false);
  const [elementName, setElementName] = React.useState(initialName);
  const [isInvalidElementName, setisInvalidElementName] = React.useState(false);
  const errorToast = useErrorToast();

  React.useEffect(() => {
    if (element) {
      setElementName(element.name);
    }
  }, [element]);

  const handleChangeElementName = React.useCallback(
    (name: string) => {
      if (element?.type === "shopifySection") {
        if (name.length > MAX_SECTION_NAME_LENGTH) {
          if (!isInvalidElementName) {
            errorToast(
              `Section names must be less than ${MAX_SECTION_NAME_LENGTH} characters.`,
              "Section name too long",
              "error.element.update",
              {
                elementDetail: JSON.stringify(element),
              },
            );
          }
          setisInvalidElementName(true);
        } else {
          setisInvalidElementName(false);
        }
      }

      setElementName(name);
    },
    [element, isInvalidElementName, errorToast],
  );

  const updateElementMetadata = trpc.element.updateElementMetadata.useMutation({
    onMutate: async ({ element: elementToUpdate }) => {
      // NOTE (Ben O., 2025-02-10): to keep the element name in sync in the editor
      // we cancel the outgoing refetch of the element metadata and snapshot the
      // previous value to use in case of error. We also set the trpc call to
      // optimistic update to the new value.
      if (element) {
        await trpcUtils.element.getElementMetadataById.cancel(element.id);
        await trpcUtils.element.listAllElementsMetadata.cancel({
          projectId: element.projectId,
        });

        trpcUtils.element.getElementMetadataById.setData(element.id, (old) =>
          old ? { ...old, name: elementToUpdate.name! } : old,
        );
        trpcUtils.element.listAllElementsMetadata.setData(
          { projectId: element.projectId },
          (old) => {
            if (!old) {
              return old;
            }
            return old.map((el) =>
              el.id === elementToUpdate.id
                ? { ...el, name: elementToUpdate.name! }
                : el,
            );
          },
        );

        return { previousName: element.name };
      }
    },
    onError: (_err, _variables, context) => {
      // Note (Ben O. 2025-02-06): If the update fails, we roll back to the previous value
      if (element && context?.previousName) {
        trpcUtils.element.getElementMetadataById.setData(element.id, {
          ...element,
          name: context.previousName,
        });
        trpcUtils.element.listAllElementsMetadata.setData(
          { projectId: element.projectId },
          (old) => {
            if (!old) {
              return old;
            }
            return old.map((el) =>
              el.id === element.id ? { ...el, name: context.previousName } : el,
            );
          },
        );
      }
      errorToast(
        "Please try again.",
        "Failed to update element name",
        "error.element.update",
        {
          elementDetail: JSON.stringify(element),
        },
      );
    },
    onSuccess: () => {
      if (element) {
        void trpcUtils.element.listAllElementsMetadata.invalidate();
        void trpcUtils.element.getElementMetadataById.invalidate(element.id);
        setIsEditingName(false);
      }
    },
  });

  const handleUpdateElementName = React.useCallback(
    async (name: string): Promise<void> => {
      if (!element) {
        setIsEditingName(false);
        errorToast(
          "Please try again.",
          "Failed to update element name",
          "error.element.update",
          {
            elementDetail: JSON.stringify(element),
          },
        );
        return;
      }

      const newName = name.replace(INVALID_ELEMENT_NAME_CHARACTERS_REGEX, "");

      if (newName === element.name) {
        setIsEditingName(false);
        return;
      }

      if (isInvalidElementName) {
        errorToast(
          "Please rename the page.",
          "Invalid page name",
          "error.element.update",
          {
            elementDetail: JSON.stringify(element),
          },
        );
        return;
      }

      // Update page path only when initially creating a new element
      const shopifyPagePath =
        element.type === "page" &&
        element.name.toLowerCase().includes("untitled")
          ? slugify(newName)
          : element.shopifyPagePath;

      return updateElementMetadata.mutateAsync({
        element: {
          ...element,
          name: newName,
          shopifyPagePath,
        },
      });
    },
    [element, updateElementMetadata, isInvalidElementName, errorToast],
  );

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === "Escape") {
        if (elementName) {
          void handleUpdateElementName(elementName);
        } else {
          setIsEditingName(false);
        }
      }
    },
    [elementName, handleUpdateElementName],
  );

  return {
    elementName: elementName ?? "",
    setElementName: handleChangeElementName,
    isEditingName,
    setIsEditingName,
    handleUpdateElementName,
    handleKeyDown,
    isInvalidElementName,
  };
}
