import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

interface DescriptionProps {
  description: React.ReactNode;
  size: "base" | "sm" | "lg";
  validityState?: "valid" | "invalid";
  layoutClassName?: string;
  UNSAFE_className?: string;
  type?: "default" | "muted";
  breakWords?: boolean;
}

const Description = React.forwardRef<HTMLParagraphElement, DescriptionProps>(
  (
    {
      description,
      size = "base",
      validityState,
      layoutClassName,
      UNSAFE_className,
      type = "default",
      breakWords = false,
    },
    ref,
  ) => {
    return (
      <p
        ref={ref}
        className={twMerge(
          "text-placeholder",
          validityState === "invalid" && "text-danger",
          size === "sm" && "typ-body-small",
          size === "base" && "typ-body-base",
          size === "lg" && "text-base",
          type === "muted" && "text-muted",
          breakWords && "break-words",
          layoutClassName,
          UNSAFE_className,
        )}
      >
        {description}
      </p>
    );
  },
);

Description.displayName = "Description";

export default Description;
