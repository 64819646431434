import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

type ErrorProps = {
  id?: string;
  error?: React.ReactNode;
  className?: string;
};

export default function ErrorMessage({ error, className, id }: ErrorProps) {
  if (!error) {
    return null;
  }
  return (
    <div className={twMerge("typ-body-small text-danger", className)} id={id}>
      {error}
    </div>
  );
}
