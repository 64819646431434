import orderBy from "lodash-es/orderBy";

import { isNotNullish } from "./misc";

export const EMPTY_ARRAY = Object.freeze([]) as unknown as any[];

export function filterNulls<T>(
  value: Array<T | null | undefined> | null | undefined,
): Array<T> {
  if (value == null) {
    return [];
  }
  return value.filter(isNotNullish);
}

export function createArrayOfLength(length: number) {
  const result = [];
  for (let i = 0; i < length; i++) {
    result.push(i);
  }
  return result;
}

export function getRandomElementFromArray<T>(values: Array<T>): T {
  return values[Math.floor(Math.random() * values.length)]!;
}

/* Returns new array */
export const orderByDate = (
  target: any[],
  field: string,
  order: "desc" | "asc" = "desc",
): any[] => {
  return orderBy(
    target,
    [
      function (x: any) {
        return new Date(x[field]);
      },
    ],
    [order],
  );
};

export function sum(array: number[]): number {
  return array.reduce((a, b) => a + b, 0);
}

export function average(array: number[]): number {
  return array.length === 0 ? 0 : sum(array) / array.length;
}

export function median(array: number[]): number {
  if (array.length === 0) {
    return 0;
  }
  const sorted = [...array].sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);
  return sorted.length % 2 === 0
    ? (sorted[middle - 1]! + sorted[middle]!) / 2
    : sorted[middle]!;
}
