import type {
  TabsContentOption,
  TabsTriggerOption,
} from "@replo/design-system/components/tabs/Tabs";
import type { ProductRef } from "schemas/product";

import * as React from "react";

import ElementNameEditor from "@components/editor/elementEditors/ElementNameEditor";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Separator from "@editor/components/common/designSystem/Separator";
import { ShopifyThemeSettings } from "@editor/components/editor/elementEditors/ShopifyThemeSettings";
import { ProductSelectionCombobox } from "@editor/components/editor/page/ProductSelectionCombobox";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useInitial } from "@editor/hooks/useInitial";

import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { Tabs } from "@replo/design-system/components/tabs/Tabs";

import ElementCustomCssEditor from "./ElementCustomCssEditor";
import { ElementEditorHeading } from "./ElementEditorHeading";

type ProductTemplateElementEditorTab = "general" | "shopifyTheme" | "advanced";

const PRODUCT_TEMPLATE_EDITOR_TABS: TabsTriggerOption[] = [
  { value: "general", label: "General" },
  { value: "shopifyTheme", label: "Shopify Theme" },
  { value: "advanced", label: "Advanced" },
];

const ProductTemplateElementEditor: React.FC<{
  initialName?: string;
}> = ({ initialName }) => {
  const [selectedTab, setSelectedTab] =
    React.useState<ProductTemplateElementEditorTab>("general");

  const PRODUCT_TEMPLATE_EDITOR_CONTENT: TabsContentOption[] = [
    {
      value: "general",
      tabContent: <ProductTemplateBasicSettings initialName={initialName} />,
    },
    { value: "shopifyTheme", tabContent: <ShopifyThemeSettings /> },
    {
      value: "advanced",
      tabContent: <ElementCustomCssEditor />,
    },
  ];

  return (
    <div className="flex h-fit flex-col">
      <Tabs
        tabsOptions={PRODUCT_TEMPLATE_EDITOR_TABS}
        contentOptions={PRODUCT_TEMPLATE_EDITOR_CONTENT}
        value={selectedTab}
        onValueChange={(value) => {
          setSelectedTab(value as ProductTemplateElementEditorTab);
        }}
        size="sm"
        defaultValue={selectedTab}
      />
    </div>
  );
};

const ProductTemplateBasicSettings: React.FC<{
  initialName?: string;
}> = ({ initialName }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const initialElementIsPublished = useInitial(element.isPublished);

  const handleProductSelectionChange = (newValue: ProductRef[] | null) => {
    const newTemplateShopifyProductIds = (newValue ?? []).map((ref) =>
      String(ref.productId),
    );

    onChangeElement({
      ...element,
      templateShopifyProductIds: newTemplateShopifyProductIds,
    });
  };

  return (
    <div className="flex h-fit flex-col gap-y-4">
      <ElementNameEditor
        type="shopifyProductTemplate"
        inputName="page-name"
        initialName={initialName ?? element.name}
        onChange={(name) => onChangeElement({ ...element, name })}
      />
      <LabeledControl label="Path" size="sm">
        <div className="flex flex-row gap-2 items-center">
          <div className="flex-none">
            <Input
              value="products/"
              size="sm"
              layoutClassName="w-20"
              isDisabled={true}
            />
          </div>
          <Input
            id="page-data-path"
            placeholder="product-slug"
            value="product-slug"
            size="sm"
            isDisabled
          />
        </div>
      </LabeledControl>

      {initialElementIsPublished && (
        <SwitchWithDescription
          label="Is Published"
          description="Your products will be displayed using this template."
          isOn={element.isPublished}
          onChange={(isPublished) =>
            onChangeElement({ ...element, isPublished })
          }
          size="sm"
          variant="card"
        />
      )}

      <Separator />

      <ProductSelector
        selectedIds={element.templateShopifyProductIds ?? []}
        onChange={handleProductSelectionChange}
      />
    </div>
  );
};

const ProductSelector: React.FC<{
  selectedIds: string[];
  onChange: (newValue: ProductRef[] | null) => void;
}> = ({ selectedIds, onChange }) => {
  const selectedProductRefs = React.useMemo(() => {
    return selectedIds.map((id) => ({ productId: Number(id) }));
  }, [selectedIds]);

  return (
    <div className="mt-2">
      <ElementEditorHeading>
        Products Assigned to Template
        {selectedIds.length > 0 ? ` (${selectedIds.length})` : ""}
      </ElementEditorHeading>
      <div className="my-2 min-h-[32px]">
        <ProductSelectionCombobox
          value={selectedProductRefs}
          onChange={onChange}
          isMultiselect
          side="right"
          sideOffset={20}
        />
      </div>
    </div>
  );
};

export default ProductTemplateElementEditor;
