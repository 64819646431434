// TODO (Gabe 2025-03-04): Ensure this gets updated to assets.replocdn.com
export const ASSETS_HOST = "assets.replocdn.com";

export const isReploSizeableImageUrl = (
  imageUrl: string | undefined | null,
) => {
  return (
    // TODO: move to schemas or something.
    imageUrl?.includes(ASSETS_HOST) && !imageUrl.includes(".svg")
  );
};

/**
 * This function converts an image url to replo sized image url based on the
 * canvas is being used, but keeps the same url if it's not from shopify
 */
export const convertToReploSizedImageSource = ({
  imageSource,
  width,
  height,
  fit,
}: {
  imageSource: string | undefined;
  width?: number | null;
  height?: number | null;
  fit?: "scale-down" | "contain" | "cover" | "crop" | "pad";
}) => {
  if (!imageSource || !isReploSizeableImageUrl(imageSource)) {
    return imageSource;
  }

  let [newImageSource] = imageSource.split("?");

  const params = new URLSearchParams();

  if (width) {
    params.set("width", width.toString());
  }
  if (height) {
    params.set("height", height.toString());
  }
  if (fit) {
    params.set("fit", fit);
  }
  if (params.size > 0) {
    newImageSource += `?${params.toString()}`;
  }

  return newImageSource;
};
