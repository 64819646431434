import type { ComponentTemplate } from "@editor/types/component-template";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const judgeProductRatingWidget: ComponentTemplate = {
  id: "76cea4a1-98d2-4635-817d-2c88295a32f9",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Judge.me Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "judgeMe" },
  preview: "integrations/judgeme-product-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star", "widget"],
  template: {
    id: "$uid",
    type: "judgeProductRatingWidget",
    props: { style: { alignSelf: "stretch" } },
  },
};
