import type { ReploShopifyProduct } from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/ReploVariable";

export const wrapProductAssignmentToLiquidSource = ({
  product,
  liquidSource,
  context,
}: {
  product: ReploShopifyProduct | null;
  liquidSource: string;
  context: Context;
}) => {
  // NOTE (Gabe 2023-09-01): For product templates on published pages we can
  // rely on the product supplied by the Shopify Liquid context.
  // Note (Evan, 2024-08-01): This also applies to other components on PDPs
  // that reference the Template Product, like review widgets.
  const isProductAlreadyAssigned =
    context.isInsideTemplateProductComponent ||
    context.componentReferencesTemplateProduct;

  if (!product || (context.isPublishing && isProductAlreadyAssigned)) {
    return liquidSource;
  }

  return `
    {% if product %}
      {% assign reploExistingProduct = product %}
    {% endif %}

    {% assign product = all_products['${product.handle}'] %} ${liquidSource}

    {% if reploExistingProduct %}
      {% assign product = reploExistingProduct %} 
    {% endif %}
  `;
};
