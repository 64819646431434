import type { ComboboxOption } from "../ComboboxContext";

import * as React from "react";

export const useCreatableComboboxInputOptions = (
  _options: ComboboxOption[],
) => {
  const options = [..._options];
  const [input, setInput] = React.useState("");

  const doesInputExistInOptions = options.some(
    (option) => option.value === input,
  );

  // NOTE (Max, 2025-01-03): If the input value is not in the options, add it.
  // This allows the user to select the current value they're typing as an option.
  if (input && !doesInputExistInOptions) {
    options.unshift({
      label: `Create "${input}"`,
      value: input,
    });
  }

  return [input, setInput, options] as const;
};
