import type {
  AddProductVariantToCartEditorPropsValue,
  LinkData,
} from "replo-runtime/shared/types";
import type {
  ContextRef,
  ProductRef,
  SelectedSellingPlanIdOrOneTimePurchase,
} from "schemas/product";

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum Env {
  DEV = "dev",
  PRODUCTION = "production",
}

type ReploCustomEvent = "ActionStarted" | "ActionEnded";

export const ReploCustomEvents: Record<ReploCustomEvent, string> = {
  ActionStarted: "actionStarted",
  ActionEnded: "actionEnded",
};

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum ConditionFieldType {
  PIXEL = "pixel",
  EVENT = "event",
  PAGE = "page",
  DATA_TABLE_ROW = "dataTableRow",
  HASHMARK = "hashmark",
  INTEGER = "integer",
  BOOLEAN = "boolean",
  PRODUCT_VARIANT = "productVariant",
  TEMPLATE_PRODUCT = "templateProduct",
  PRODUCT = "product",
}

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum ConditionFieldEditorValue {
  NONE = "none",
  INPUT = "input",
  INPUT_WITH_STEPS = "inputWithSteps",
  DATA_TABLE_ROW = "dataTableRow",
  PRODUCT_VARIANT = "productVariant",
  TEMPLATE_PRODUCT = "templateProduct",
  PRODUCT = "product",
}

const operators = [
  "all",
  "gt",
  "gte",
  "lt",
  "lte",
  "eq",
  "neq",
  "includes",
  "excludes",
] as const;

export type Operator = (typeof operators)[number];

const alchemyActionTypes = [
  "activateTabId",
  "addProductVariantToCart",
  "addTemporaryCartProductsToCart",
  "addVariantToTemporaryCart",
  "applyDiscountCode",
  "clearCart",
  "clearDiscountCode",
  "close", // Close modal
  "closeModalComponent",
  "decreaseProductQuantity",
  "decreaseVariantCountInTemporaryCart",
  "executeJavascript",
  "goToItem",
  "goToNextItem",
  "goToPrevItem",
  "increaseProductQuantity",
  "multipleProductVariantsAddToCart",
  "openModal",
  "openKlaviyoModal",
  "phoneNumber",
  "redirect",
  "redirectToProductPage",
  "removeVariantFromTemporaryCart",
  "scrollContainerLeft",
  "scrollContainerRight",
  "scrollToNextCarouselItem",
  "scrollToPreviousCarouselItem",
  "scrollToSpecificCarouselItem",
  "scrollToUrlHashmark",
  "setActiveAlchemyVariant",
  "setActiveOptionValue",
  "setActiveSellingPlan",
  "setActiveTabIndex",
  "setActiveVariant",
  "setCurrentCollectionSelection",
  "setDropdownItem",
  "setProductQuantity",
  "toggleCollapsible",
  "toggleDropdown",
  "toggleFullScreen",
  "toggleMute",
  "togglePlay",
  "updateCart",
  "updateCurrentProduct",
  "setSelectedListItem",
] as const;

export type AlchemyActionType = (typeof alchemyActionTypes)[number];

/**
 * Types of values that Replo Actions can have. These are separate from the action
 * types themselves, because two different types of actions might have the same value
 * type (for example, decreaseProductQuantity and increaseProductQuantity both might have
 * the integer value type).
 *
 * Note: depending on the action type, almost all of these values should be settable to
 * a dynamic value from the context.
 */
type AlchemyActionValueTypeConfig =
  /** No value at all! */
  | { type: "none"; value: never }
  /** A url, including whether to open  */
  | { type: "url"; value: LinkData | string }
  /** Any string */
  | { type: "string"; value: string }
  /** Any string, hint to render a multi-line text area because the value will usually be long */
  | { type: "multilineString"; value: string }
  /** Any product */
  | { type: "product"; value: ProductRef }
  /** Any product with redirection */
  | {
      type: "productRedirect";
      value: { product: ProductRef | null; openInNewTab: boolean };
    }
  /** Any product and optionally also a variant of that product */
  | {
      type: "productWithOptionalVariant";
      value: ProductRef | ContextRef;
    }
  /** A product variant, including data about how and when it should be added to cart */
  | {
      type: "productVariant";
      value: AddProductVariantToCartEditorPropsValue;
    }
  /** A specific row from a data table */
  | { type: "dataTableRow"; value: number }
  /** A number of pixels */
  | { type: "pixels"; value: { pixels: number } }
  /** A Replo modal component (e.g. for "open modal" actions) */
  | { type: "modalComponent"; value: { componentId: string | null } }
  /** A Replo Klaviyo component (e.g. for "open klaviyo modal" actions) */
  | { type: "klaviyoComponent"; value: { componentId: string | null } }
  /** A hashmark on the current page, including optional data about how it should be scrolled to */
  | {
      type: "offsetHashmark";
      value: {
        hashmark?: string;
        offset?: number;
        smoothScroll?: boolean;
      };
    }
  /** Any integer */
  | { type: "integer"; value: number }
  /** Any Shopify discount code */
  | { type: "discountCode"; value: string }
  /** Any Replo State on the current page (for example, for the activate state action) */
  | {
      type: "alchemyVariant";
      value: {
        variantId?: string;
        componentId?: string;
        type?: AlchemyActionType;
      };
    }
  /** An inline javascript syntax value */
  | { type: "jsCodeEditor"; value: string }
  /** Quantity of a product (i.e., a 1+ integer) */
  | {
      type: "productQuantity";
      value: number;
    }
  /** A zero-indexed index representing a tab inside the current tabs block */
  | { type: "tabIndex"; value: { index: number } }
  | {
      type: "sellingPlan";
      value: { sellingPlanId: SelectedSellingPlanIdOrOneTimePurchase };
    };
export type AlchemyActionValueType = AlchemyActionValueTypeConfig["type"];
export type ActionValueTypeValueOf<TargetType extends AlchemyActionValueType> =
  Extract<AlchemyActionValueTypeConfig, { type: TargetType }>["value"];

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum AlchemyActionTrigger {
  OnClick = "onClick",
  OnHover = "onHover",
}
export const AlchemyActionTriggers: AlchemyActionTrigger[] = Object.values(
  AlchemyActionTrigger,
) as AlchemyActionTrigger[];
