import type { ComponentTemplate } from "@editor/types/component-template";
import type { ComponentTemplateIndustryId } from "schemas/componentTemplates";
import type { ReploElementType } from "schemas/generated/element";
import type { SchemaReploShopifyProduct } from "schemas/generated/product";
import type { ResponsiveToggleAllowedDevices } from "../common/designSystem/ResponsiveToggle";

import React from "react";

import { useCreateElement } from "@editor/hooks/element/useCreateElement";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import { setEditorMode } from "@editor/reducers/core-reducer";
import { setLeftBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorDispatch } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

import { setCanvasInteractionMode } from "@/features/canvas/canvas-reducer";
import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { ArrowLeft, Sparkles } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { getComponentTemplateIndustryName } from "replo-runtime/shared/componentTemplates";
import { isReploElementType } from "schemas/element";

import { ComponentTemplateSnapshot } from "../component-template/ComponentTemplateSnapshot";
import { BuildAssistantPrompt } from "../editor/ai/build-assistant/BuildAssistantPrompt";
import CopyTemplateLinkButton from "./CopyTemplateLinkButton";
import PreviewPlatformToggleGroup from "./PreviewPlatformToggleGroup";

interface TemplatePageProps {
  template: ComponentTemplate;
  setSubpageTemplate: (template: ComponentTemplate | null) => void;
  previewPlatform: ResponsiveToggleAllowedDevices;
  setPreviewPlatform: (platform: ResponsiveToggleAllowedDevices) => void;
}

const TemplatePage: React.FC<TemplatePageProps> = ({
  template,
  setSubpageTemplate,
  previewPlatform,
  setPreviewPlatform,
}) => {
  const [prompt, setPrompt] = React.useState("");
  const [selectedProduct, setSelectedProduct] =
    React.useState<SchemaReploShopifyProduct | null>(null);
  const [searchParams] = useSearchParams();
  const folderId = searchParams.get("folderId") || undefined;
  const logEvent = useLogAnalytics();
  const setDraftElement = useSetDraftElement();

  const {
    setIsBuildAssistantOpen,
    initiateParallelGeneration,
    applyChanges,
    setIsAIPreLoading,
  } = useAIStreaming();
  const dispatch = useEditorDispatch();
  const { handleCreateElement } = useCreateElement();

  const elementType = searchParams.get("elementType");

  const isValidElementType = elementType
    ? isReploElementType(elementType)
    : false;

  const handleBuildNoAi = async () => {
    logEvent("editor.componentTemplate.used", {
      componentTemplateId: template.id,
      collectionId: template.collectionId,
      categoryId: template.categoryId,
      componentTemplateName: template.name,
      componentTemplateType: template.type,
      componentScope: template.scope,
      source: "marketplace",
    });

    await handleCreateElement({
      type: isValidElementType ? (elementType as ReploElementType) : "page",
      initialTemplate: template,
      folderId,
    });

    dispatch(setEditorMode(EditorMode.edit));
  };

  const handleBuildWithAi = async () => {
    logEvent("ai.action.submitted", {
      prompt,
      triggeringFeature: "new-page-flow",
    });
    setIsAIPreLoading(true);
    logEvent("editor.componentTemplate.used", {
      componentTemplateId: template.id,
      collectionId: template.collectionId,
      categoryId: template.categoryId,
      componentTemplateName: template.name,
      componentTemplateType: template.type,
      componentScope: template.scope,
      source: "marketplace",
      isAIPersonalized: true,
    });

    const element = await handleCreateElement({
      type: isValidElementType ? (elementType as ReploElementType) : "page",
      initialTemplate: template,
      applyAIGenerationOnNavigate: true,
      folderId,
    });

    dispatch(setEditorMode(EditorMode.edit));

    if (!element?.component) {
      return;
    }

    setDraftElement({ componentIds: [element.component.id] });

    const componentsToGenerate = element?.component.children ?? [];

    dispatch(setLeftBarActiveTab(null));
    setIsBuildAssistantOpen(true);

    initiateParallelGeneration(
      {
        type: "template",
        contextComponent: element.component,
        userPrompt: prompt,
        conversationMessages: [],
        nonce: 0,
        components: componentsToGenerate,
        selectedProduct: selectedProduct ?? undefined,
        triggeringFeature: "new-page-flow",
      },
      () => {
        applyChanges();
        dispatch(setCanvasInteractionMode("edit"));
        setIsAIPreLoading(false);
      },
    );
  };

  const industryName = template.industryId
    ? getComponentTemplateIndustryName(
        template.industryId as ComponentTemplateIndustryId,
      )
    : "No Industry";

  const isPersonalizationEnabled = isFeatureEnabled(
    "template-library-personalization",
  );

  return (
    <div className="w-full h-full flex flex-col items-center gap-12 mt-12 mx-12">
      <div className="flex gap-10 p-10 w-full max-w-[1440px] bg-white rounded-xl">
        <div className="relative overflow-hidden flex-1 min-w-[400px]">
          <div className="absolute top-4 left-4 z-20 items-center">
            <IconButton
              variant="secondary"
              size="base"
              icon={<ArrowLeft size={20} />}
              aria-label="Back"
              onClick={() => setSubpageTemplate(null)}
              UNSAFE_className="shadow-popover"
            />
          </div>
          <div className="absolute top-4 right-4 z-20 items-center">
            <PreviewPlatformToggleGroup
              previewPlatform={previewPlatform}
              setPreviewPlatform={setPreviewPlatform}
            />
          </div>

          {previewPlatform === "mobile" ? (
            <div className="relative mt-4 flex items-center justify-center overflow-hidden">
              <div className="absolute top-0 pointer-events-none z-10 flex justify-center w-full min-w-[417px] min-h-[848px]">
                <img src="/images/templates/Iphone 14.svg" alt="iPhone frame" />
              </div>

              <div className="rounded-[84px] overflow-hidden">
                <div className="w-[400px] max-h-[848px] overflow-auto no-scrollbar">
                  <ComponentTemplateSnapshot
                    componentTemplate={template}
                    previewPlatform={previewPlatform}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="overflow-auto max-h-[100vh] no-scrollbar">
              <div className="rounded-xl overflow-hidden">
                <ComponentTemplateSnapshot
                  componentTemplate={template}
                  previewPlatform={previewPlatform}
                />
              </div>
              {/* Spacing to account for margin at top of screen */}
              <div className="h-36" />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-10 min-w-[480px] flex-1">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2 items-start max-w-[50%]">
              {template.logoUrl && (
                <img
                  src={template.logoUrl}
                  alt={`${template.name} logo`}
                  className="max-h-8 object-contain"
                />
              )}
              <div className="typ-header-h1 truncate">{template.name}</div>
              {industryName && (
                <div className="typ-body-large text-muted">{industryName}</div>
              )}
            </div>
            <div>
              <CopyTemplateLinkButton template={template} />
            </div>
          </div>

          {isPersonalizationEnabled && (
            <div className="flex flex-col gap-3">
              <div className="typ-header-h3">Personalize to your shop</div>
              <BuildAssistantPrompt
                prompt={prompt}
                setPrompt={setPrompt}
                placeholderText="Add details about the page you'd like to build..."
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                onBuild={() => void handleBuildWithAi()}
              />
            </div>
          )}
          <div className="flex justify-end gap-4">
            <Button
              variant="tertiary"
              size="base"
              onClick={() => setSubpageTemplate(null)}
            >
              Back
            </Button>
            <Button
              variant="secondary"
              size="base"
              onClick={() => void handleBuildNoAi()}
            >
              Duplicate Template
            </Button>
            {isPersonalizationEnabled && (
              <Button
                variant="primary"
                size="base"
                onClick={() => void handleBuildWithAi()}
                endEnhancer={<Sparkles size={14} />}
              >
                Personalize
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatePage;
