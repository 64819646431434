import type { GlobalWindow } from "../Window";

import * as React from "react";

type Status = "idle" | "loading" | "success" | "error";

export default function useScript(
  url: string | null | undefined,
  args: {
    globalWindow: GlobalWindow | null;
    forceSkip?: boolean;
    onLoad?: () => void;
    scriptPatternsToRemoveBeforeLoad?: string[];
  },
): { status: Status; error: ErrorEvent | null } {
  const { globalWindow, forceSkip, onLoad, scriptPatternsToRemoveBeforeLoad } =
    args;
  const [status, setStatus] = React.useState<Status>("idle");
  const [error, setError] = React.useState<ErrorEvent | null>(null);

  const onScriptLoad = () => {
    setStatus("success");
    onLoad?.();
  };

  const onError = (e: ErrorEvent) => {
    setStatus("error");
    setError(e);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: allow missing deps scriptLoad, onError, scriptPatternsToRemoveBeforeLoad
  React.useEffect(() => {
    const document = globalWindow?.document;
    if (!url) {
      setStatus("error");
      return undefined;
    }

    const existingScriptId = document && document.getElementById(url)?.id;
    if (
      forceSkip ||
      (existingScriptId &&
        !scriptPatternsToRemoveBeforeLoad?.includes(existingScriptId))
    ) {
      return undefined;
    }

    if (scriptPatternsToRemoveBeforeLoad) {
      scriptPatternsToRemoveBeforeLoad.forEach((script) => {
        const element = document && document.getElementById(script);
        element?.remove();
      });
    }

    setStatus("loading");
    if (document) {
      const script = document.createElement("script");
      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onError);
      script.id = url;
      script.src = url;
      script.async = true;

      // NOTE Ben 2024-05-01: Need to specify this or runtime won't build correctly in edgeserver.
      document.body.append(script as any);

      return () => {
        script.remove();
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalWindow, url, forceSkip]);

  return { status, error };
}
