import type { EditorRepaintDependencies } from "@editor/reducers/utils/core-reducer-utils";
import type {
  ComponentDataMapping,
  ComponentStylesForChildren,
} from "replo-runtime/shared/Component";
import type { ComponentIdToStyleRulesMapping } from "replo-runtime/shared/styles";
import type { RuntimeFeatureFlags } from "replo-runtime/shared/types";
import type { ElementToPaint } from "replo-runtime/store/paint";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";
import type { ReploElement } from "schemas/generated/element";
import type { ReploSymbol } from "schemas/generated/symbol";
import type { PartialCanvases } from "./canvas-types";

import * as React from "react";

import {
  useInitDraftElementContext,
  useInitFeatureFlagsContext,
  useInitReploEditorActiveCanvasContext,
  useInitReploSymbolsContext,
} from "@editor/contexts/editor-runtime-context";
import {
  selectComponentDataMapping,
  selectComponentIdsToStyle,
  selectIsPreviewMode,
  selectStreamingUpdate,
  setComponentDataMappingStylesForChildren,
} from "@editor/reducers/core-reducer";
import { editorNeedsRepaint } from "@editor/reducers/utils/core-reducer-utils";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { findComponentById } from "@editor/utils/component";
import {
  generateStyles,
  injectStylesIntoDocument,
  upsertStyles,
} from "@editor/utils/styles";

import difference from "lodash-es/difference";
import { generateComponentStyleDataMapping } from "replo-runtime/shared/styles";
import { getFullPageOffset } from "replo-runtime/shared/utils/dom";
import { isEmpty } from "replo-utils/lib/misc";

import {
  increasePaintVersion,
  selectCanvases,
  selectCanvasesIFrames,
  selectCanvasesKeys,
  selectVisibleCanvases,
} from "./canvas-reducer";
import useGetElementsMapping from "./useGetElementsMapping";

/**
 * Hook for running paint on the targetFrame when necessary.
 */
export function usePaintTargetFrames() {
  const canvasesKeys = useEditorSelector(selectCanvasesKeys);

  const canvasFramesRef = React.useRef<
    Record<string, HTMLIFrameElement | null>
  >(Object.fromEntries(canvasesKeys.map((canvas) => [canvas, null])));

  const previousRepaintDependenciesRef =
    React.useRef<EditorRepaintDependencies | null>(null);
  const previousVisibleCanvasesKeysRef = React.useRef<EditorCanvas[]>([]);

  const elementMapping = useGetElementsMapping();

  const dispatch = useEditorDispatch();
  const canvasesIframes = useEditorSelector(selectCanvasesIFrames);
  const componentIdsToStyle = useEditorSelector(selectComponentIdsToStyle);
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const canvases = useEditorSelector(selectCanvases);
  const visibleCanvases = useEditorSelector(selectVisibleCanvases);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const streamingUpdate = useEditorSelector(selectStreamingUpdate);
  const draftElementContext = useInitDraftElementContext();
  const featureFlagsContext = useInitFeatureFlagsContext();
  useInitReploEditorActiveCanvasContext();
  const reploSymbolsContext = useInitReploSymbolsContext();

  const { draftElementId } = draftElementContext;
  const { featureFlags } = featureFlagsContext;
  const { symbols } = reploSymbolsContext;

  const draftElement = draftElementId
    ? elementMapping[draftElementId] ?? null
    : null;

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    const canvasesToPaint = isPreviewMode
      ? { desktop: canvases.desktop }
      : visibleCanvases;

    const previousTargetFrames = canvasFramesRef.current;
    canvasFramesRef.current = canvasesIframes;

    const repaintDependencies: EditorRepaintDependencies = {
      elementMapping,
      draftElementId,
    };
    const previousRepaintDependencies = previousRepaintDependenciesRef.current;
    previousRepaintDependenciesRef.current = repaintDependencies;

    const visibleCanvasesKeys = Object.keys(visibleCanvases) as EditorCanvas[];
    const previousVisibleCanvasesKeys = previousVisibleCanvasesKeysRef.current;
    previousVisibleCanvasesKeysRef.current = visibleCanvasesKeys;
    const elementToPaint = getElementToPaint(draftElement);
    const needsRepaint = editorNeedsRepaint(
      previousRepaintDependencies,
      repaintDependencies,
    );

    // Note (Evan, 2024-07-18): We call execUpdateElementStylesheets in the
    // same cases in which we repaint - paintType is not "none" or the target frame
    // is new. In the multicanvas case, we check that any target frame is new.
    const isAnyCanvasNewTargetFrame = Object.entries(visibleCanvases).some(
      ([key, canvas]) =>
        canvas.targetFrame !== previousTargetFrames[key as EditorCanvas],
    );

    if (isAnyCanvasNewTargetFrame || needsRepaint.paintType !== "none") {
      const targetFramesToStyle = [];
      for (const { targetFrame } of Object.values(canvasesToPaint)) {
        if (!targetFrame) {
          continue;
        }

        targetFramesToStyle.push(targetFrame);
      }
      dispatch(increasePaintVersion());
      execUpdateElementStylesheets();
    }

    function execUpdateElementStylesheets() {
      if (elementToPaint.type === "specificElement") {
        // NOTE (Chance 2024-06-25): Non-null assertion is safe here because we
        // checked for the target frame window before exiting via `isSafeToPaint`
        const { stylesForChildrenMapping } =
          updateElementStylesheets({
            draftElement:
              repaintDependencies.elementMapping[
                elementToPaint.specificElementId!
              ]!,
            symbols,
            featureFlags,
            canvases: Object.fromEntries(Object.entries(visibleCanvases)),
            componentIds: componentIdsToStyle,
            componentDataMapping,
            newVisibleCanvases:
              difference(visibleCanvasesKeys, previousVisibleCanvasesKeys) ??
              [],
          }) ?? {};

        if (!isEmpty(stylesForChildrenMapping)) {
          dispatch(
            setComponentDataMappingStylesForChildren(stylesForChildrenMapping),
          );
        }
      }
    }
    // NOTE (Chance 2024-03-28): EXTREMELY IMPORTANT: We should *not* be
    // ignoring this, but since we're constructing new context objects in the
    // effect we need to be careful not to trigger unwanted re-runs of `paint`.
    // If new dependencies are introduced to this effect, make sure to update
    // the dependency array below, as the linter won't catch it. Once we
    // completely eliminate `renderExtras` and `repaintDependencies` we should
    // be able to remove this before the hook itself is removed.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvases.desktop,
    componentDataMapping,
    canvasesIframes,
    componentIdsToStyle,
    dispatch,
    draftElement,
    draftElementId,
    elementMapping,
    featureFlags,
    symbols,
    visibleCanvases,
    isPreviewMode,
    streamingUpdate,
  ]);
}

function getElementToPaint(draftElement: ReploElement | null) {
  return {
    type: "specificElement" as const,
    specificShopifyPageId: draftElement?.shopifyPageId,
    specificElementId: draftElement?.id,
  } satisfies ElementToPaint;
}

function updateElementStylesheets(opts: {
  draftElement: ReploElement;
  symbols: ReploSymbol[];
  featureFlags: RuntimeFeatureFlags;
  canvases: PartialCanvases;
  componentDataMapping: ComponentDataMapping;
  componentIds: string[];
  newVisibleCanvases: EditorCanvas[];
}) {
  const {
    draftElement,
    symbols,
    featureFlags,
    componentIds,
    componentDataMapping,
    newVisibleCanvases,
  } = opts;

  const rootComponent = draftElement?.component;

  if (!rootComponent) {
    return;
  }

  const buildConfig = {
    isEditor: true,
    symbols,
    featureFlags,
  };

  const stylesForChildrenMapping: Record<
    Component["id"],
    ComponentStylesForChildren
  > = {};

  const fullPageOffset = getFullPageOffset(window.document);

  const styleRules: ComponentIdToStyleRulesMapping = {};
  // NOTE (Martin, 2024-05-27): If there are new visible canvases or no specific
  // updated component ids, we generate and apply styles for the root component.
  if (newVisibleCanvases.length > 0 || componentIds.length === 0) {
    const componentStyleDataMapping = generateComponentStyleDataMapping(
      rootComponent,
      {
        componentContext: {
          isRoot: true,
          parentHasVariants: (rootComponent.variants?.length ?? 0) > 0,
          parentStyleProps: null,
          parentOverrideStyleRules: null,
          fullPageOffset,
        },
        buildConfig,
        overridesRegistry: {},
      },
    );
    for (const [componentId, componentStyleData] of Object.entries(
      componentStyleDataMapping,
    )) {
      const { styleRulesMapping, childComponentContext } = componentStyleData;
      if (styleRulesMapping) {
        styleRules[componentId] = styleRulesMapping;
        if (
          childComponentContext?.parentStyleProps ||
          childComponentContext?.parentOverrideStyleRules
        ) {
          stylesForChildrenMapping[componentId] = {
            parentStyleProps: childComponentContext.parentStyleProps ?? null,
            parentOverrideStyleRules:
              childComponentContext.parentOverrideStyleRules ?? null,
          };
        }
      }
    }
    const styles = generateStyles(styleRules);

    injectStylesIntoDocument(styles);
  }

  if (componentIds.length > 0) {
    const styledComponentIds = new Set();
    // NOTE (Martin, 2024-05-27): componentIds might have repeated values for
    // some actions like "Group Into Container" so we create a new in order
    // to be sure that we don't run the loop below unnecessarily.
    const uniqueComponentIds = new Set(componentIds);
    for (const componentId of uniqueComponentIds) {
      // NOTE (Martin, 2024-04-30): this guard optimizes re-styling by
      // preventing style trees that have been already recreated to be
      // done again for no reason. This happens when an ancestor has already
      // triggered a restyle for a child component.
      if (!styledComponentIds.has(componentId)) {
        // Note (Evan, 2024-08-21): We cannot use the componentMapping here
        // (as things currently stand) because it doesn't reflect AI streaming updates.
        const component = findComponentById(draftElement, componentId);
        if (component) {
          const componentData = componentDataMapping[componentId];
          const parentComponentStylesForChildren = componentData?.parentId
            ? componentDataMapping[componentData.parentId]?.stylesForChildren ??
              null
            : null;
          const parsedParentComponentStylesForChildren =
            parentComponentStylesForChildren
              ? (JSON.parse(
                  parentComponentStylesForChildren,
                ) as ComponentStylesForChildren)
              : null;

          const componentStyleDataMapping = generateComponentStyleDataMapping(
            component,
            {
              componentContext: {
                isRoot: componentData?.parentId === null,
                parentHasVariants: componentData?.ancestorHasVariants ?? false,
                parentOverrideStyleRules:
                  parsedParentComponentStylesForChildren?.parentOverrideStyleRules,
                parentStyleProps:
                  parsedParentComponentStylesForChildren?.parentStyleProps ??
                  null,
                fullPageOffset,
              },
              buildConfig,
              overridesRegistry: {},
            },
          );

          for (const [componentId, componentStyleData] of Object.entries(
            componentStyleDataMapping,
          )) {
            const { styleRulesMapping, childComponentContext } =
              componentStyleData;
            if (styleRulesMapping) {
              styleRules[componentId] = styleRulesMapping;
              if (
                childComponentContext?.parentStyleProps ||
                childComponentContext?.parentOverrideStyleRules
              ) {
                stylesForChildrenMapping[componentId] = {
                  parentStyleProps:
                    childComponentContext.parentStyleProps ?? null,
                  parentOverrideStyleRules:
                    childComponentContext.parentOverrideStyleRules ?? null,
                };
              }

              styledComponentIds.add(componentId);
            }
          }
        }
      }
    }

    const updatedStyles = upsertStyles(styleRules);
    injectStylesIntoDocument(updatedStyles);
  }

  return { stylesForChildrenMapping };
}
