import type { OpenModalPayload } from "@editor/reducers/modals-reducer";
import type { ComponentTemplate } from "@editor/types/component-template";
import type {
  ContextMenuActions,
  TurnIntoArgs,
} from "@editor/types/component-tree";
import type {
  ReploClipboard,
  ReploClipboardFigmaStyles,
  ReploClipboardImage,
  ReploClipboardMultiple,
  ReploClipboardSingle,
  ReploClipboardStyles,
  ReploClipboardText,
} from "@editor/utils/copyPaste";
import type { UseApplyComponentActionType } from "@hooks/useApplyComponentAction";
import type { NormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { Component } from "schemas/component";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import * as React from "react";

import {
  HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
  normalizeProductProps,
  prepareComponentTemplate,
  VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
} from "@editor/components/editor/defaultComponentTemplates";
import { image } from "@editor/components/editor/templates/image";
import { text } from "@editor/components/editor/templates/text";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useFindParentForPaste } from "@editor/hooks/useFindParentForPaste";
import { useModal } from "@editor/hooks/useModal";
import useOpenCodeEditor from "@editor/hooks/useOpenCodeEditor";
import {
  useAnyStoreProducts,
  useStoreProductsFromDraftElement,
} from "@editor/hooks/useStoreProducts";
import { selectProductResolutionDependencies } from "@editor/reducers/commerce-reducer";
import {
  selectColor,
  selectComponentActionsToCopy,
  selectComponentAlignSelf,
  selectComponentAsString,
  selectComponentDataMapping,
  selectComponentFromMapping,
  selectComponentMapping,
  selectDoesExistDraftElement,
  selectDraftComponentId,
  selectDraftComponentIds,
  selectDraftComponentText,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftElementId,
  selectGetAttribute,
  selectMultipleComponentsPasteActions,
  selectSymbolsMapping,
} from "@editor/reducers/core-reducer";
import { toggleExpandedTreeNode } from "@editor/reducers/tree-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import {
  canCopyComponent,
  canDeleteComponent,
  canDuplicateComponent,
  canGroupIntoContainer,
  canMoveComponentToParent,
  findAncestorComponentOrSelfWithVariants,
  findAndRenameComponentNames,
  findAndReplaceAssetUrls,
  findAndReplaceTextWithPlaceholderTexts,
  generateContextMenuWrapperComponentActions,
  getFlexDirection,
  getParentComponentFromMapping,
  sanitizeComponentName,
  validateComponent,
} from "@editor/utils/component";
import {
  getComponentClipboard,
  setComponentClipboard,
} from "@editor/utils/copyPaste";
import { getApplicableRuntimeStyleAttributes } from "@editor/utils/modifierGroups";
import useGetDesignLibraryComponentReferences from "@hooks/designLibrary/useGetDesignLibraryComponentReferences";
import useApplyComponentAction from "@hooks/useApplyComponentAction";

import {
  successToast,
  warningToast,
} from "@replo/design-system/components/alert/Toast";
import cloneDeep from "lodash-es/cloneDeep";
import mapValues from "lodash-es/mapValues";
import merge from "lodash-es/merge";
import pickBy from "lodash-es/pickBy";
import { refreshComponentIds } from "replo-runtime/shared/refreshComponentIds";
import { getChildren } from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getNormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import {
  getFromRecordOrNull,
  mapNull,
} from "replo-runtime/shared/utils/optional";
import { findDefault } from "replo-runtime/shared/variant";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { exhaustiveSwitch, isEmpty, isNotNullish } from "replo-utils/lib/misc";
import { forEachPrimitiveValue } from "replo-utils/lib/object";

import useHandleReplaceComponentWithDesignLibraryReferences from "./designLibrary/useHandleReplaceComponentWithDesignLibraryReferences";
import useFigmaPluginPaste from "./figmaToReplo/useFigmaPluginPaste";
import useSetDraftElement from "./useSetDraftElement";

/**
 * Function that can be applied to a component when the component is pasted. Useful
 * for things like modifying product props to reference products when pasting into a
 * new store, etc
 */
type PasteTransform = (config: {
  component: Component;
  productResolutionDependencies: ProductResolutionDependencies;
  parent: Component | null;
}) => Component;

/**
 * List of default transforms that happen to components when they are pasted.
 */
const defaultPasteTransforms: PasteTransform[] = [
  ({ component, productResolutionDependencies, parent }) => {
    return normalizeProductProps(
      component,
      productResolutionDependencies,
      mapNull(parent, (parent) => getCurrentComponentContext(parent.id, 0)) ??
        null,
    );
  },
];

const componentHasShopifyAppBlocks = (component: Component): boolean => {
  if (component.type === "shopifyAppBlocks") {
    return true;
  }

  if (component.children && component.children.length > 0) {
    return component.children.some((child: Component) =>
      componentHasShopifyAppBlocks(child),
    );
  }

  return false;
};

const stripShopifyAppBlocksFromComponent = (
  component: Component,
): Component | null => {
  if (component.type === "shopifyAppBlocks") {
    return null;
  }

  if (component.children && component.children.length > 0) {
    const filteredChildren = component.children
      .map(stripShopifyAppBlocksFromComponent)
      .filter((child): child is Component => child !== null);

    return {
      ...component,
      children: filteredChildren,
    };
  }

  return component;
};

const getStyleProps = (
  componentProps: Record<string, any> | undefined,
): Record<string, Record<RuntimeStyleAttribute, string | null>> => {
  return pickBy(componentProps ?? {}, (_value, key) => key.includes("style"));
};

const useContextMenuActions = (): ContextMenuActions | null => {
  const [shouldForceSentryError, setShouldForceSentryError] =
    React.useState(false);

  if (shouldForceSentryError) {
    throw new Error("This is a test sentry error!!");
  }

  const store = useEditorStore();
  const doesExistDraftElement = useEditorSelector(selectDoesExistDraftElement);
  const openCodeEditor = useOpenCodeEditor();
  const applyComponentAction = useApplyComponentAction();
  const { products: defaultProducts } = useAnyStoreProducts();
  const { products: draftElementProducts } = useStoreProductsFromDraftElement();
  const dispatch = useEditorDispatch();
  const setDraftElement = useSetDraftElement();
  const modal = useModal();
  const errorToast = useErrorToast();

  const { handleReplaceComponentWithDesignLibraryReferences } =
    useHandleReplaceComponentWithDesignLibraryReferences();
  const { getDesignLibraryComponentReferences } =
    useGetDesignLibraryComponentReferences();

  const findParentForPaste = useFindParentForPaste();

  // Note (Evan, 2024-07-31): Ideally we'd define this with the others, but since
  // it's a hook, we have to call it before any returns
  const {
    pasteFromFigma: handlePasteFigmaPluginv2,
    pasteFromFigmaOrToast: handlePasteFromFigma,
  } = useFigmaPluginPaste({
    findParentForPaste,
  });

  // We need to use some default products if draft element doesn't have any (REPL-4765)
  const productResolutionDependenciesProducts = React.useMemo(
    () => [...defaultProducts, ...draftElementProducts],
    [defaultProducts, draftElementProducts],
  );

  const deleteCurrentComponent = React.useCallback(
    (componentId: string, source: string) => {
      const storeState = store.getState();
      const draftComponentId = selectDraftComponentId(storeState);
      const componentMapping = selectComponentMapping(storeState);
      if (!draftComponentId) {
        return;
      }
      const parent = getParentComponentFromMapping(
        componentMapping,
        draftComponentId,
      );
      if (!parent) {
        return;
      }
      applyComponentAction({
        type: "deleteComponent",
        componentId,
        source,
      });
      setDraftElement({
        componentIds: [parent.id],
      });
    },
    [store, setDraftElement, applyComponentAction],
  );

  const handleDelete = React.useCallback(
    (componentId: string, source: string) => {
      const storeState = store.getState();
      const componentMapping = selectComponentMapping(storeState);
      const draftElementId = selectDraftElementId(storeState);
      if (
        !draftElementId ||
        !componentMapping ||
        componentId === draftElementId
      ) {
        return;
      }
      const result = canDeleteComponent(componentId, componentMapping);
      if (result.canDelete) {
        deleteCurrentComponent(componentId, source);
      } else {
        errorToast(
          "Unable to Delete Component",
          result.message,
          "error.component.delete",
          {
            error: result.message,
            componentId,
          },
        );
      }
    },
    [store, deleteCurrentComponent, errorToast],
  );

  const handleCopy = React.useCallback(
    (multipleSelectedNodeIds: string[], clipBoard?: DataTransfer) => {
      const storeState = store.getState();
      const componentMapping = selectComponentMapping(storeState);
      const componentDataMapping = selectComponentDataMapping(storeState);
      const { canCopy, message } = canCopyComponent(
        multipleSelectedNodeIds[0]!,
        componentDataMapping,
      );

      if (!canCopy) {
        return errorToast(
          "Failed Copying Component",
          message,
          "error.component.copy",
          {
            error: message,
            componentIds: multipleSelectedNodeIds,
            clipboard: clipBoard,
          },
        );
      }

      const designLibraryMetadata = getDesignLibraryComponentReferences(
        multipleSelectedNodeIds,
      );

      if (multipleSelectedNodeIds.length > 1) {
        const oldParent = getParentComponentFromMapping(
          componentMapping,
          multipleSelectedNodeIds[0]!,
        );
        const components = multipleSelectedNodeIds.map((selectedNodeId) => {
          return getFromRecordOrNull(componentMapping, selectedNodeId)!
            .component;
        });

        components.sort((a, b) => {
          const aIndex = getChildren(oldParent).indexOf(a);
          const bIndex = getChildren(oldParent).indexOf(b);
          return aIndex - bIndex;
        });

        setComponentClipboard(
          {
            type: "multipleComponents",
            components: components,
            designLibraryMetadata,
          },
          errorToast,
          clipBoard,
        );
      } else {
        const data = getFromRecordOrNull(
          componentMapping,
          multipleSelectedNodeIds[0]!,
        );
        if (!data) {
          return;
        }
        setComponentClipboard(
          {
            type: "singleComponent",
            component: data.component,
            designLibraryMetadata,
          },
          errorToast,
          clipBoard,
        );
      }
    },
    [store, getDesignLibraryComponentReferences, errorToast],
  );

  const handleGroupContainer = React.useCallback(
    (multipleSelectedNodeIds: string[]) => {
      const storeState = store.getState();
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
      if (!draftElement) {
        return;
      }
      const productResolutionDependencies =
        selectProductResolutionDependencies(storeState);
      const componentDataMapping = selectComponentDataMapping(storeState);
      const canGroupData = canGroupIntoContainer(
        multipleSelectedNodeIds,
        componentDataMapping,
      );
      if (!canGroupData.canGroupIntoContainer) {
        return errorToast(
          "Failed Grouping Components",
          canGroupData.message,
          "error.component.group",
          {
            error: canGroupData.message,
            componentIds: multipleSelectedNodeIds,
          },
        );
      }

      const { actions, newContainerId } =
        generateContextMenuWrapperComponentActions({
          draftElement,
          multipleSelectedNodeIds: multipleSelectedNodeIds,
          productResolutionDependencies: {
            ...productResolutionDependencies,
            products: productResolutionDependenciesProducts,
          },
          componentDataMapping,
          getAttribute: selectGetAttribute(storeState),
          wrapperType: "container",
        });

      applyComponentAction({
        type: "applyCompositeAction",
        value: actions,
      });

      setDraftElement({ componentIds: [newContainerId!] });
      dispatch(
        toggleExpandedTreeNode({ id: newContainerId!, isExpanding: true }),
      );
    },
    [
      store,
      dispatch,
      applyComponentAction,
      setDraftElement,
      productResolutionDependenciesProducts,
      errorToast,
    ],
  );

  const handleGroupDelete = React.useCallback(
    (multipleSelectedNodeIds: string[]) => {
      const actions: any[] = [];
      const componentMapping = selectComponentMapping(store.getState());

      multipleSelectedNodeIds.forEach((item) =>
        actions.push({
          type: "deleteComponent",
          componentId: item,
          analyticsExtras: {
            actionType: "delete",
            createdBy: "user",
          },
        }),
      );

      applyComponentAction({
        type: "applyCompositeAction",
        value: actions,
      });

      if (multipleSelectedNodeIds[0]) {
        const parent = getParentComponentFromMapping(
          componentMapping,
          multipleSelectedNodeIds[0],
        );
        if (parent) {
          setDraftElement({
            componentIds: [parent.id],
          });
        }
      }
    },
    [store, applyComponentAction, setDraftElement],
  );

  const handleReplaceAllContentWithPlaceholders = React.useCallback(
    (componentId: string) => {
      if (!componentId) {
        return;
      }

      const componentMapping = selectComponentMapping(store.getState());
      const componentJson = cloneDeep(
        getFromRecordOrNull(componentMapping, componentId)?.component,
      );
      if (componentJson) {
        componentJson.name = sanitizeComponentName(componentJson.type);
        forEachPrimitiveValue({
          target: componentJson,
          fn: findAndRenameComponentNames,
        });

        forEachPrimitiveValue({
          target: componentJson,
          fn: findAndReplaceAssetUrls,
        });

        forEachPrimitiveValue({
          target: componentJson,
          fn: findAndReplaceTextWithPlaceholderTexts,
        });

        applyComponentAction({
          type: "replaceComponent",
          value: {
            newComponent: componentJson,
            shouldRefreshComponentIdsAndNames: false,
          },
          analyticsExtras: {
            actionType: "other",
            createdBy: "replo",
          },
        });
      }
    },
    [store, applyComponentAction],
  );

  // this function pastes a single component from clipboard into the selected component
  const handlePasteSingleComponent = React.useCallback(
    (reploClipboard: ReploClipboardSingle, pasteOnComponentId: string) => {
      const storeState = store.getState();
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
      const componentMapping = selectComponentMapping(storeState);
      const { component: componentFromClipboard, designLibraryMetadata } =
        reploClipboard;

      if (!draftElement || !componentMapping || !componentFromClipboard) {
        return;
      }

      const productResolutionDependencies =
        selectProductResolutionDependencies(storeState);

      const componentWithDesignLibraryReferences =
        handleReplaceComponentWithDesignLibraryReferences(
          [componentFromClipboard],
          designLibraryMetadata,
        )[0]!;

      const { newParent, positionWithinSiblings } = findParentForPaste(
        reploClipboard,
        pasteOnComponentId,
      )!;
      const componentIdCopied = componentWithDesignLibraryReferences?.id;
      let { component, oldIdToNewId } = refreshComponentIds(
        componentWithDesignLibraryReferences,
      );

      const actionsToCopyComponentOverrides = selectComponentActionsToCopy(
        storeState,
        {
          oldComponentId: componentIdCopied,
          oldIdToNewId,
        },
      );

      for (const transform of defaultPasteTransforms) {
        component = transform({
          component,
          productResolutionDependencies: {
            ...productResolutionDependencies,
            products: productResolutionDependenciesProducts,
          },
          parent: newParent,
        });
      }

      if (
        componentHasShopifyAppBlocks(component) &&
        draftElement.type !== "shopifySection"
      ) {
        component = stripShopifyAppBlocksFromComponent(component) ?? component;
        warningToast(
          "Paste Modified",
          "Shopify App Blocks cannot be used outside Sections and have been excluded.",
        );
      }

      const addComponentAction: UseApplyComponentActionType = {
        type: "addComponentToComponent",
        componentId: newParent!.id,
        value: {
          newComponent: component,
          position: "child",
          positionWithinSiblings,
        },
        source: "componentContextMenu",
        analyticsExtras: {
          actionType: "create",
          createdBy: "user",
        },
      };

      const actionsToApply = [
        addComponentAction,
        ...actionsToCopyComponentOverrides,
      ];

      applyComponentAction({
        type: "applyCompositeAction",
        value: actionsToApply,
      });

      setDraftElement({
        componentIds: [component.id],
      });
    },
    [
      store,
      applyComponentAction,
      setDraftElement,
      findParentForPaste,
      handleReplaceComponentWithDesignLibraryReferences,
      productResolutionDependenciesProducts,
    ],
  );

  // this function pastes multiple components from clipboard into the selected component
  const handlePasteMultipleComponents = React.useCallback(
    (reploClipboard: ReploClipboardMultiple, pasteOnComponentId: string) => {
      const storeState = store.getState();
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
      const componentMapping = selectComponentMapping(storeState);

      if (!draftElement || !componentMapping) {
        return;
      }

      const componentDataMapping = selectComponentDataMapping(storeState);
      const productResolutionDependencies =
        selectProductResolutionDependencies(storeState);
      const { components: componentsFromClipboard, designLibraryMetadata } =
        reploClipboard;

      let components = handleReplaceComponentWithDesignLibraryReferences(
        componentsFromClipboard,
        designLibraryMetadata,
      );
      const hasShopifyAppBlocks = components.some((component) =>
        componentHasShopifyAppBlocks(component),
      );
      if (hasShopifyAppBlocks && draftElement.type !== "shopifySection") {
        components = components.map(
          (component) =>
            stripShopifyAppBlocksFromComponent(component) ?? component,
        );
        warningToast(
          "Paste Modified",
          "Shopify App Blocks cannot be used outside Sections and have been excluded.",
        );
      }

      const parent = getParentComponentFromMapping(
        componentMapping,
        components[0]!.id,
      );
      const { newParent, positionWithinSiblings } = findParentForPaste(
        reploClipboard,
        pasteOnComponentId,
      )!;

      let needsNewContainer = false;
      let parentFlexDirection: NormalizedFlexDirection | null = null;
      const getAttribute = selectGetAttribute(storeState);
      if (parent) {
        parentFlexDirection = getNormalizedFlexDirection(
          getFlexDirection(parent, getAttribute),
        );
        const newParentFlexDirection = getNormalizedFlexDirection(
          getFlexDirection(newParent, getAttribute),
        );

        if (parentFlexDirection !== newParentFlexDirection) {
          needsNewContainer = true;
        }
      }

      const productResolutionDependenciesWithProducts = {
        ...productResolutionDependencies,
        products: productResolutionDependenciesProducts,
      };

      const newContainer = prepareComponentTemplate(
        parentFlexDirection === "row"
          ? HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE
          : VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
        parent,
        draftElement,
        {
          getAttribute,
          productResolutionDependencies:
            productResolutionDependenciesWithProducts,
          context: parent
            ? getCurrentComponentContext(parent.id, 0) ?? null
            : null,
          componentDataMapping,
        },
      );

      let actionsToApply: UseApplyComponentActionType[] = [];

      const newComponents = components.map((_component) => {
        const componentIdCopied = _component?.id;
        let { component, oldIdToNewId } = refreshComponentIds(_component);
        const actionsToCopyComponentOverrides = selectComponentActionsToCopy(
          storeState,
          {
            oldComponentId: componentIdCopied,
            oldIdToNewId,
          },
        );
        actionsToApply = [
          ...actionsToApply,
          ...actionsToCopyComponentOverrides,
        ];

        for (const transform of defaultPasteTransforms) {
          component = transform({
            component,
            productResolutionDependencies:
              productResolutionDependenciesWithProducts,
            parent: newParent,
          });
        }
        return normalizeProductProps(
          component,
          productResolutionDependenciesWithProducts,
          mapNull(parent, (parent) =>
            getCurrentComponentContext(parent.id, 0),
          ) ?? null,
        );
      });

      const actions = selectMultipleComponentsPasteActions(storeState, {
        newComponents,
        needsNewContainer,
        newParent,
        positionWithinSiblings,
        newContainer,
      });

      actionsToApply = [...actions, ...actionsToApply];

      applyComponentAction({
        type: "applyCompositeAction",
        value: actionsToApply,
      });

      const newSelectedIds = newComponents.map((component) => component.id);
      setDraftElement({
        componentIds: newSelectedIds,
      });
    },
    [
      store,
      applyComponentAction,
      setDraftElement,
      findParentForPaste,
      handleReplaceComponentWithDesignLibraryReferences,
      productResolutionDependenciesProducts,
    ],
  );

  // this function pastes text or svg images from clipboard into the selected component
  const handlePasteCustom = React.useCallback(
    (
      reploClipboard: ReploClipboardText | ReploClipboardImage,
      pasteOnComponentId: string,
    ) => {
      const componentMapping = selectComponentMapping(store.getState());

      if (!componentMapping) {
        return;
      }

      const { component: newComponent } = reploClipboard;
      const { newParent, positionWithinSiblings } = findParentForPaste(
        reploClipboard,
        pasteOnComponentId,
      )!;

      applyComponentAction({
        type: "addComponentToComponent",
        componentId: newParent!.id,
        value: {
          newComponent,
          position: "child",
          positionWithinSiblings,
        },
        source: "componentContextMenu",
        analyticsExtras: {
          actionType: "create",
          createdBy: "user",
        },
      });

      setDraftElement({
        componentIds: [newComponent.id],
      });
    },
    [store, applyComponentAction, setDraftElement, findParentForPaste],
  );

  const canMoveComponentsToComponent = React.useCallback(
    (reploClipboard: ReploClipboard, componentId: string): boolean => {
      const storeState = store.getState();
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);

      if (!draftElement) {
        return false;
      }

      const newParent = findParentForPaste(
        reploClipboard,
        componentId,
      )?.newParent;

      if (!newParent) {
        return false;
      }

      const componentDataMapping = selectComponentDataMapping(storeState);
      const productResolutionDependencies =
        selectProductResolutionDependencies(storeState);
      const getAttribute = selectGetAttribute(storeState);

      const getNewComponent = (template: ComponentTemplate) => {
        return prepareComponentTemplate(template, newParent, draftElement, {
          getAttribute,
          productResolutionDependencies: {
            ...productResolutionDependencies,
            products: productResolutionDependenciesProducts,
          },
          context: newParent
            ? getCurrentComponentContext(newParent.id, 0) ?? null
            : null,
          componentDataMapping,
        });
      };

      switch (reploClipboard.type) {
        case "multipleComponents": {
          const { components } = reploClipboard;

          const results = components.map((newComponent: Component) => {
            return canMoveComponentToParent(
              draftElement,
              newComponent,
              newParent,
              getAttribute,
              "contextMenu",
              selectComponentDataMapping(store.getState()),
            ).canMove;
          });
          return results.every(Boolean);
        }
        case "singleComponent": {
          const newComponent = reploClipboard.component;

          return canMoveComponentToParent(
            draftElement,
            newComponent,
            newParent,
            getAttribute,
            "contextMenu",
            selectComponentDataMapping(store.getState()),
          ).canMove;
        }
        case "text": {
          const newComponent = getNewComponent(text);
          return canMoveComponentToParent(
            draftElement,
            newComponent,
            newParent,
            getAttribute,
            "contextMenu",
            selectComponentDataMapping(store.getState()),
          ).canMove;
        }
        case "image": {
          const newComponent = getNewComponent(image);
          return canMoveComponentToParent(
            draftElement,
            newComponent,
            newParent,
            getAttribute,
            "contextMenu",
            selectComponentDataMapping(store.getState()),
          ).canMove;
        }
        default:
          return false;
      }
    },
    [store, findParentForPaste, productResolutionDependenciesProducts],
  );

  const canPaste = React.useCallback(
    async (componentId: string, clipBoard?: DataTransfer) => {
      const storeState = store.getState();
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);

      if (!draftElement) {
        return false;
      }

      const componentMapping = selectComponentMapping(storeState);
      const componentDataMapping = selectComponentDataMapping(storeState);
      const productResolutionDependencies =
        selectProductResolutionDependencies(storeState);
      const getAttribute = selectGetAttribute(storeState);
      const reploClipboard = await getComponentClipboard(
        clipBoard,
        draftElement,
        getAttribute,
        componentDataMapping,
        {
          ...productResolutionDependencies,
          products: productResolutionDependenciesProducts,
        },
        errorToast,
      );
      if (reploClipboard) {
        const componentSelectedForPaste = getFromRecordOrNull(
          componentMapping,
          componentId,
        )?.component;
        if (!componentSelectedForPaste) {
          return false;
        }
        const isSingleComponentRootShopifyAppBlock =
          reploClipboard.type === "singleComponent" &&
          (reploClipboard.component ?? reploClipboard).type ===
            "shopifyAppBlocks";

        if (
          isSingleComponentRootShopifyAppBlock &&
          draftElement.type !== "shopifySection"
        ) {
          errorToast(
            "Invalid Paste",
            "Shopify App Blocks cannot be used outside Sections.",
            "error.component.paste.invalid",
            {
              componentId,
              clipboard: reploClipboard,
            },
          );
          return false;
        }
        return canMoveComponentsToComponent(reploClipboard, componentId);
      }
      return false;
    },
    [
      store,
      canMoveComponentsToComponent,
      productResolutionDependenciesProducts,
      errorToast,
    ],
  );

  const handlePasteFigmaStyles = React.useCallback(
    (reploClipboard: ReploClipboardFigmaStyles) => {
      const { rawCss } = reploClipboard;
      if (!rawCss) {
        return;
      }
      applyComponentAction({
        type: "setStylesFromCSS",
        value: rawCss,
      });
    },
    [applyComponentAction],
  );

  const handlePasteStyles = React.useCallback(
    (clipBoard: ReploClipboardStyles) => {
      const stylesFromClipboard: Record<
        string,
        Record<RuntimeStyleAttribute, string>
      > = JSON.parse(clipBoard.styles);
      applyComponentAction({
        type: "pasteStyles",
        value: stylesFromClipboard,
      });
      if (!isEmpty(stylesFromClipboard)) {
        successToast(
          "Styles Pasted",
          "The component styles have been applied to the selected component.",
        );
      }
    },
    [applyComponentAction],
  );

  // This function handles pasting from clipboard, clipboard can have
  // single component, multiple components, text or css styles
  const handlePaste = React.useCallback(
    async (type: "normal" | "alt", clipBoard?: DataTransfer) => {
      const storeState = store.getState();
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
      const draftComponentId = selectDraftComponentId(storeState);
      const draftComponentIds = selectDraftComponentIds(storeState);
      const componentMapping = selectComponentMapping(storeState);
      const isMultiSelect = draftComponentIds.length > 1;
      let lastSelectedId = draftComponentId;

      if (isMultiSelect) {
        const parent = getParentComponentFromMapping(
          componentMapping,
          draftComponentIds[0]!,
        );
        // if it is multi select replace pasteComponentId with the last selected component
        getChildren(parent).forEach((child) => {
          if (draftComponentIds.includes(child.id)) {
            lastSelectedId = child.id;
          }
        });
      }

      const pasteOnComponentId = lastSelectedId;

      if (!pasteOnComponentId || !draftElement) {
        return null;
      }

      const productResolutionDependencies =
        selectProductResolutionDependencies(storeState);
      const componentDataMapping = selectComponentDataMapping(storeState);
      const getAttribute = selectGetAttribute(storeState);
      const reploClipboard = await getComponentClipboard(
        clipBoard,
        draftElement,
        getAttribute,
        componentDataMapping,
        {
          ...productResolutionDependencies,
          products: productResolutionDependenciesProducts,
        },
        errorToast,
      );

      if (!reploClipboard) {
        return null;
      }

      // NOTE (Jose, 2024-06-06) We could paste Figma components anywhere, so excluding from this check
      if (
        type === "normal" &&
        !["pasteCSSStyles", "figmaPluginExportv2"].includes(reploClipboard.type)
      ) {
        const isPasteAllowed = await canPaste(pasteOnComponentId, clipBoard);

        if (!isPasteAllowed) {
          return null;
        }
      }

      return exhaustiveSwitch({ type })({
        normal: () => {
          switch (reploClipboard.type) {
            case "singleComponent":
              return handlePasteSingleComponent(
                reploClipboard,
                pasteOnComponentId,
              );

            case "multipleComponents":
              // TODO: handle pasting multiple components from design library
              return handlePasteMultipleComponents(
                reploClipboard,
                pasteOnComponentId,
              );

            case "pasteCSSStyles":
              return handlePasteFigmaStyles(reploClipboard);

            case "text":
              return handlePasteCustom(reploClipboard, pasteOnComponentId);

            case "image":
              return handlePasteCustom(reploClipboard, pasteOnComponentId);

            case "figmaPluginExportv2":
              return handlePasteFigmaPluginv2(
                reploClipboard,
                pasteOnComponentId,
              );

            default:
              return null;
          }
        },
        alt: () => {
          switch (reploClipboard.type) {
            case "copyPasteStyles":
              return handlePasteStyles(reploClipboard);

            case "pasteCSSStyles":
              return handlePasteFigmaStyles(reploClipboard);

            default:
              return null;
          }
        },
      });
    },
    [
      store,
      canPaste,
      handlePasteFigmaStyles,
      handlePasteStyles,
      handlePasteFigmaPluginv2,
      handlePasteCustom,
      handlePasteMultipleComponents,
      handlePasteSingleComponent,
      productResolutionDependenciesProducts,
      errorToast,
    ],
  );

  const handleCopyStyles = React.useCallback(
    (componentId: string, activeVariantId?: string) => {
      const storeState = store.getState();
      const component = selectComponentFromMapping(storeState, componentId);

      if (!component) {
        return;
      }

      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);

      if (!draftElement) {
        errorToast(
          "Styles not Copied",
          "There was an error copying styles, please reach out to support@replo.app",
          "error.component.copy.styles",
          {
            componentId,
          },
        );
        return;
      }

      const textValue = selectDraftComponentText(storeState);
      const colorValue = selectColor(storeState);
      const styleProps = getStyleProps(component.props);
      const applicableCssProperties = getApplicableRuntimeStyleAttributes(
        component.type,
        !isMixedStyleValue(colorValue) ? colorValue : null,
        textValue ?? "",
      );

      // NOTE (Sebas, 2022-12-23): This is in charge of adding all the applicable
      // css properties with a null value. This allows us to merge the styles
      // when we paste them and prevents us from replacing the previous values a
      // component could have. For example, if I copy styles from a container and
      // then I paste them into a text, it shouldn't replace the text relevant styles.
      const completeStyleProps = mapValues(styleProps, (value) => {
        const styles = { ...value };
        applicableCssProperties?.forEach((cssProperty) => {
          if (!styles[cssProperty]) {
            styles[cssProperty] = null;
          }
        });
        return styles;
      });

      const symbolsMapping = selectSymbolsMapping(storeState);
      const componentWithVariants = findAncestorComponentOrSelfWithVariants(
        draftElement,
        component.id,
        symbolsMapping,
      );
      const defaultVariant = findDefault(componentWithVariants?.variants ?? []);
      const isDefaultVariant = activeVariantId === defaultVariant?.id;
      if (
        activeVariantId &&
        isNotNullish(defaultVariant) &&
        !isDefaultVariant
      ) {
        const variantOverrideProps =
          componentWithVariants?.variantOverrides?.[activeVariantId]
            ?.componentOverrides?.[componentId]?.props;
        if (!variantOverrideProps) {
          void setComponentClipboard(
            {
              type: "copyPasteStyles",
              styles: JSON.stringify(completeStyleProps),
            },
            errorToast,
          );
        } else {
          const stylePropsFromVariantOverrides =
            getStyleProps(variantOverrideProps);
          const mergedStyles = merge(
            {},
            completeStyleProps,
            stylePropsFromVariantOverrides,
          );
          void setComponentClipboard(
            {
              type: "copyPasteStyles",
              styles: JSON.stringify(mergedStyles),
            },
            errorToast,
          );
        }
      } else {
        void setComponentClipboard(
          {
            type: "copyPasteStyles",
            styles: JSON.stringify(completeStyleProps),
          },
          errorToast,
        );
      }
      successToast(
        "Styles Copied",
        "The component styles have been copied to your clipboard.",
      );
    },
    [store, errorToast],
  );

  const handleDuplicate = React.useCallback(
    (componentId: string, source?: string) => {
      const storeState = store.getState();
      const componentMapping = selectComponentMapping(storeState);
      const componentDataMapping = selectComponentDataMapping(storeState);
      const { canDuplicate, message } = canDuplicateComponent(
        componentId,
        componentDataMapping,
      );
      if (!canDuplicate) {
        return errorToast(
          "Unable to duplicate component",
          message,
          "error.component.duplicate",
          {
            error: message,
          },
        );
      }
      const component = getFromRecordOrNull(
        componentMapping,
        componentId,
      )?.component;
      if (component) {
        const { component: newComponent, oldIdToNewId } = refreshComponentIds(
          cloneDeep(component),
        );

        const duplicateComponentAction: UseApplyComponentActionType = {
          type: "duplicateComponent",
          componentId,
          newComponent,
          source,
          analyticsExtras: {
            actionType: "create",
            createdBy: "user",
          },
        };

        const actionsToCopyComponentOverrides = selectComponentActionsToCopy(
          storeState,
          {
            oldComponentId: componentId,
            oldIdToNewId,
          },
        );

        const actionsToApply = [
          duplicateComponentAction,
          ...actionsToCopyComponentOverrides,
        ];

        applyComponentAction({
          type: "applyCompositeAction",
          value: actionsToApply,
        });

        setDraftElement({
          componentIds: [newComponent.id],
        });
      }
    },
    [store, applyComponentAction, setDraftElement, errorToast],
  );

  const setDraftComponentsType = React.useCallback(
    (type: Component["type"]) => {
      const draftComponentIds = selectDraftComponentIds(store.getState());
      applyComponentAction({
        type: "unsafeUpdateComponentType",
        componentIds: draftComponentIds,
        value: type,
        analyticsExtras: {
          actionType: "other",
          createdBy: "replo",
        },
      });
    },
    [store, applyComponentAction],
  );

  const turnInto = React.useCallback(
    (args: TurnIntoArgs) => {
      const storeState = store.getState();
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
      const componentDataMapping = selectComponentDataMapping(storeState);
      const productResolutionDependencies =
        selectProductResolutionDependencies(storeState);

      exhaustiveSwitch(args)({
        button: () => {
          setDraftComponentsType("button");
        },
        product: () => {
          setDraftComponentsType("product");
        },
        ticker: (args) => {
          if (!draftElement) {
            return;
          }

          const { actions, newContainerId } =
            generateContextMenuWrapperComponentActions({
              draftElement,
              multipleSelectedNodeIds: args.selectedComponentIds,
              productResolutionDependencies: {
                ...productResolutionDependencies,
                products: productResolutionDependenciesProducts,
              },
              getAttribute: selectGetAttribute(storeState),
              componentDataMapping,
              wrapperType: "ticker",
            });

          applyComponentAction({
            type: "applyCompositeAction",
            value: actions,
          });

          setDraftElement({ componentIds: [newContainerId!] });
        },
        tooltipTrigger: (args) => {
          if (!draftElement) {
            return;
          }

          const { actions, newContainerId } =
            generateContextMenuWrapperComponentActions({
              draftElement,
              multipleSelectedNodeIds: args.selectedComponentIds,
              productResolutionDependencies: {
                ...productResolutionDependencies,
                products: productResolutionDependenciesProducts,
              },
              getAttribute: selectGetAttribute(storeState),
              componentDataMapping,
              wrapperType: "tooltip",
            });

          applyComponentAction({
            type: "applyCompositeAction",
            value: actions,
          });

          setDraftElement({ componentIds: [newContainerId!] });
          dispatch(
            toggleExpandedTreeNode({ id: newContainerId!, isExpanding: true }),
          );
        },
      });
    },
    [
      store,
      dispatch,
      applyComponentAction,
      setDraftElement,
      setDraftComponentsType,
      productResolutionDependenciesProducts,
    ],
  );

  if (!doesExistDraftElement) {
    return null;
  }

  const handleRename = (name: string, id: string) => {
    applyComponentAction({
      type: "updateComponentName",
      value: name,
      componentId: id,
    });
  };

  const handleReplaceComponent = (componentId: string, json: Object) => {
    const component = validateComponent(json as Component);
    if (!component) {
      errorToast(
        "Invalid Component",
        "Please try again or reach out to support@replo.app for help.",
        "error.component.replace",
        {
          componentId,
        },
      );
      return;
    }

    applyComponentAction({
      type: "replaceComponent",
      componentId,
      value: { newComponent: component },
      analyticsExtras: {
        actionType: "other",
        createdBy: "replo",
      },
    });
  };

  const handlePersistStylesToUpstreamDevices = (
    componentId: string,
    includeDescendants: boolean,
  ) => {
    applyComponentAction({
      type: "persistStylesToUpstreamDevices",
      componentId,
      includeDescendants,
      analyticsExtras: {
        actionType: "other",
        createdBy: "replo",
      },
    });
  };

  const handleOpenModal = (openModalProps: OpenModalPayload) => {
    modal.openModal(openModalProps);
  };

  const forceSentryError = () => {
    setShouldForceSentryError(true);
  };

  const setDraftComponentId = (componentId: string) => {
    setDraftElement({
      componentIds: [componentId],
    });
  };

  const handleResetStylesToDefaultState = (
    componentId: string,
    variantId: string,
  ) => {
    applyComponentAction({
      type: "resetStateToDefault",
      variantId,
      componentId,
    });
  };

  const handlePushPropsToDefaultState = (
    componentId: string,
    variantId: string,
  ) => {
    applyComponentAction({
      type: "pushOverridePropsToDefault",
      variantId,
      componentId,
    });
  };

  const resetComponentAlignment = (componentId: string) => {
    if (!componentId) {
      return;
    }
    applyComponentAction({
      type: "setStyles",
      componentId,
      value: {
        alignSelf: null,
      },
    });
  };

  const resetStyleOverrides = (
    componentId: string,
    activeVariantId?: string,
  ) => {
    applyComponentAction({
      type: "resetStyleOverrides",
      componentId,
      activeVariantId,
    });
  };

  const getComponent = (componentId: string) => {
    const component = selectComponentFromMapping(store.getState(), componentId);
    return component ?? null;
  };

  const getStringFromComponentJson = (componentId: string) => {
    const componentAsString = selectComponentAsString(
      store.getState(),
      componentId,
    );
    return componentAsString ?? "";
  };

  const hasParent = (componentId: string) => {
    const componentMapping = selectComponentMapping(store.getState());
    return (
      getParentComponentFromMapping(componentMapping, componentId) !== null
    );
  };

  const canDelete = (componentId: string) => {
    const componentMapping = selectComponentMapping(store.getState());
    if (!componentMapping) {
      return false;
    }
    return canDeleteComponent(componentId, componentMapping).canDelete;
  };

  const getAlignSelf = (componentId: string) => {
    const alignSelf = selectComponentAlignSelf(store.getState(), componentId);
    return alignSelf;
  };

  return {
    setDraftComponentId,
    handleDelete,
    handleCopy,
    handleGroupContainer,
    handleGroupDelete,
    handleRename,
    handleReplaceComponent,
    handleResetStylesToDefaultState,
    handlePushPropsToDefaultState,
    getStringFromComponentJson,
    getComponent,
    handlePaste,
    handlePasteFromFigma,
    hasParent,
    canPaste,
    canDelete,
    handleDuplicate,
    handleOpenModal,
    forceSentryError,
    handleCopyStyles,
    handlePersistStylesToUpstreamDevices,
    resetComponentAlignment,
    getAlignSelf,
    resetStyleOverrides,
    handleReplaceAllContentWithPlaceholders,
    openCodeEditor,
    turnInto,
  };
};

export default useContextMenuActions;
