import type { ComponentTemplate } from "@editor/types/component-template";
import type {
  ComponentTemplateCategoryId,
  ComponentTemplateIndustryId,
} from "schemas/componentTemplates";
import type { ComponentTemplateScope } from "schemas/generated/componentTemplates";
import type { ResponsiveToggleAllowedDevices } from "../common/designSystem/ResponsiveToggle";

import React, { useState } from "react";

import Button from "@replo/design-system/components/button/Button";
import { ArrowLeft } from "lucide-react";

import SearchTemplates from "./SearchTemplates";
import TemplateFilters from "./TemplateFilters";
import TemplatePage from "./TemplatePage";

const ChooseTemplateOverlay = () => {
  const [selectedIndustryIds, setSelectedIndustryIds] = useState<
    ComponentTemplateIndustryId[]
  >([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<
    ComponentTemplateCategoryId[]
  >([]);
  const [previewPlatform, setPreviewPlatform] =
    React.useState<ResponsiveToggleAllowedDevices>("desktop");
  const [subpageTemplate, setSubpageTemplate] =
    useState<ComponentTemplate | null>(null);
  const [templateScope, setTemplateScope] =
    useState<Omit<ComponentTemplateScope, "left-bar" | "unlisted">>("global");

  return (
    <div className="w-full bg-neutral-soft flex items-center justify-center">
      {subpageTemplate ? (
        <TemplatePage
          template={subpageTemplate}
          setSubpageTemplate={setSubpageTemplate}
          previewPlatform={previewPlatform}
          setPreviewPlatform={setPreviewPlatform}
        />
      ) : (
        <div className="w-full h-full flex flex-col items-center gap-12 mt-32 mx-24">
          <div className="w-full flex items-center justify-center relative">
            <Button
              variant="tertiary"
              size="base"
              layoutClassName="absolute left-0"
              onClick={() => {
                window.history.back();
              }}
              startEnhancer={<ArrowLeft size={16} />}
            >
              Back
            </Button>
            <div className="font-semibold text-3xl">
              What would you like to build today?
            </div>
          </div>

          <div className="flex gap-6 w-full">
            <TemplateFilters
              selectedIndustryIds={selectedIndustryIds}
              setSelectedIndustryIds={setSelectedIndustryIds}
              selectedCategoryIds={selectedCategoryIds}
              setSelectedCategoryIds={setSelectedCategoryIds}
              templateScope={templateScope}
              setTemplateScope={setTemplateScope}
            />
            <SearchTemplates
              selectedIndustryIds={selectedIndustryIds}
              selectedCategoryIds={selectedCategoryIds}
              setSubpageTemplate={setSubpageTemplate}
              previewPlatform={previewPlatform}
              setPreviewPlatform={setPreviewPlatform}
              templateScope={templateScope}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChooseTemplateOverlay;
