import React from "react";

import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";

const ColorSavedStyleSkeleton = () => {
  return (
    <>
      {Array.from({ length: 6 }, (_, i) => (
        <ColorSavedStyleBadgeSkeleton key={i} />
      ))}
    </>
  );
};

const ColorSavedStyleBadgeSkeleton = () => {
  return <Skeleton className="w-[30px] h-[30px] rounded" />;
};

export default ColorSavedStyleSkeleton;
