import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type { ReploShopifyOption } from "replo-runtime/shared/types";

import * as React from "react";

import Selectable from "@editor/components/common/designSystem/Selectable";
import useCurrentContext from "@editor/hooks/useCurrentContext";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { selectProductOptionValues } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { Badge } from "@replo/design-system/components/badge/Badge";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { BsFillBagPlusFill } from "react-icons/bs";

const OptionValuesSelector = (props: {
  option: { label: string; values: string[] };
  selectedValues?: string[];
  onChange: (newValue: string[] | null) => void;
}) => {
  const { option, selectedValues, onChange } = props;
  const options: ComboboxOption[] = option.values.map((optionValue) => {
    return {
      label: optionValue,
      value: optionValue,
      isSelectable: true,
    };
  });
  const allSelected =
    !selectedValues || selectedValues.length >= options.length;
  options.unshift({
    label: "Select All",
    value: "_all_",
    isSelectable: true,
    excludeFromSearch: true,
  });
  let comboboxValues = [...(selectedValues ?? [])];
  if (allSelected) {
    comboboxValues = ["_all_", ...option.values];
  }
  return (
    <Combobox.Root
      options={options}
      isMultiselect
      value={comboboxValues}
      onChange={(value) => {
        // Note (Noah, 2023-12-21, USE-627): If the user selects all
        // option values, we prefer to set null instead of all specific option
        // value names because then if the user changes option value names or adds
        // new option values in Shopify, they'll still be shown here
        if (value.includes("_all_") && !allSelected) {
          // User transitioned select all from not selected to selected. This means
          // they want to select all values, and we represent that as null in this case

          onChange(null);
        } else if (allSelected && !value.includes("_all_")) {
          // User transitioned select all from selected to deselected. This means
          // they want all values to be deselected, so report an empty array
          onChange([]);
        } else {
          // User didn't do anything special, just report the new selected values
          onChange(value.filter((v) => v !== "_all_"));
        }
      }}
    >
      <Combobox.Trigger>
        <Combobox.SelectionButton
          size="sm"
          startEnhancer={
            <Badge
              type="icon"
              icon={
                <BsFillBagPlusFill size={10} className="text-neutral-soft" />
              }
              UNSAFE_className="bg-accent"
            />
          }
          title={
            allSelected
              ? "All Option Values"
              : `${selectedValues.length} Option Value${
                  selectedValues.length !== 1 ? "s" : ""
                }`
          }
          titleAlignment="start"
        />
      </Combobox.Trigger>
      <Combobox.Popover side="left" sideOffset={16} align="center">
        <Combobox.Content
          title="Select Options"
          inputPlaceholder="Select Option Value"
        />
      </Combobox.Popover>
    </Combobox.Root>
  );
};

export const OptionsCustomPropModifier = (props: {
  value: { label: string; values?: string[] } | string;
  onChange: (
    value: { label: string; values?: string[] | null } | string,
  ) => void;
}) => {
  const { value, onChange } = props;
  const { products } = useStoreProductsFromDraftElement();

  // NOTE (Gabe 2023-10-04): Context doesn't contain the required data until
  // after the first render of the Option component so we must use this hook.
  const context = useCurrentContext();

  const existingValueIsLegacyString = typeof value == "string";

  const options: ReploShopifyOption[] = useEditorSelector((state) =>
    selectProductOptionValues(state, products, context),
  );

  const optionsWithValues = options.reduce(
    (
      optionsObject: Record<string, { label: string; values: string[] }>,
      option,
    ) => {
      if (option.values) {
        optionsObject[option.name] = {
          label: option.name,
          values: option.values.map(({ title }) => title),
        };
      }
      return optionsObject;
    },
    {},
  );
  const optionNames = Object.keys(optionsWithValues);
  if (optionNames[0]) {
    const existingOptionName = existingValueIsLegacyString
      ? value
      : value?.label;
    const selectedOptionName = optionsWithValues[existingOptionName]
      ? existingOptionName
      : optionNames[0];

    const draftValue =
      existingValueIsLegacyString || selectedOptionName != existingOptionName
        ? { label: selectedOptionName }
        : { ...value };
    const selectedOption = optionsWithValues[selectedOptionName]!;

    const existingOptionDoesNotMatchCurrentOptions =
      existingOptionName !== selectedOptionName;

    return (
      <>
        <div className="flex flex-col justify-between">
          <div className="justify-self-auto">
            <div className="flex w-full flex-col gap-2">
              <div className="text-left text-xs text-gray-400">
                Select which Product Option to use for this list
              </div>
              <Selectable
                options={optionNames.map((name) => {
                  return { label: name, value: name };
                })}
                onSelect={(newLabel: string) => {
                  onChange({
                    label: newLabel,
                    values: null,
                  });
                }}
                placeholder="Select an option"
                // Note (Noah, 2024-09-01, USE-1259): Use the existing option name specifically
                // here, because the user might have renamed the option in Shopify. If this is
                // the case, we want to show them specifically that they're referencing an option
                // which does not exist
                value={existingOptionName}
              />
              {existingOptionDoesNotMatchCurrentOptions && (
                <div className="text-left text-xs text-danger">
                  The previously selected option &quot;{existingOptionName}
                  &quot; does not exist for the current product. This may be
                  because the option has been renamed in Shopify. Please choose
                  a new option.
                </div>
              )}
              {selectedOption.values.length > 0 && (
                <>
                  <OptionValuesSelector
                    option={selectedOption}
                    selectedValues={draftValue.values}
                    onChange={(newValues) => {
                      onChange({ ...draftValue, values: newValues });
                    }}
                  />
                  <div className="text-left text-xs text-gray-400 mt-2">
                    Select which values for this Product Option will be shown
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
};
