import type { ReploShopifyProduct } from "replo-runtime/shared/types";

import * as React from "react";

import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { docs } from "@editor/utils/docs";
import { getSellingPlanOptionsFromProduct } from "@editor/utils/sellingPlans";
import DynamicDataValueIndicator from "@editorExtras/DynamicDataValueIndicator";

import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { BsInfoCircleFill } from "react-icons/bs";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";

import { DynamicDataSelector } from "../../DynamicDataSelector";

type SellingPlansSelectorProps = {
  product: ReploShopifyProduct;
  sellingPlanId: string | number | null;
  allowThirdPartySellingPlan?: boolean;
  onChange: (sellingPlanId: string | number | null) => void;
  onRemove(): void;
  onChangeThirdPartySellingPlan: (checked: boolean) => void;
  side?: "left" | "right" | "top" | "bottom";
  sideOffset?: number;
};

export const SellingPlansSelector: React.FC<SellingPlansSelectorProps> = ({
  product,
  sellingPlanId,
  allowThirdPartySellingPlan,
  onChangeThirdPartySellingPlan,
  onChange,
  onRemove,
  side = "left",
  sideOffset = 16,
}) => {
  const sellingPlanOptions = getSellingPlanOptionsFromProduct(product, false);
  const selectedSellingPlan = sellingPlanOptions.find(
    (option) => Number(option.value) === Number(sellingPlanId),
  );

  const modal = useModal();
  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");

  const _openDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        excludedAttributeKeys: ["_variant"],
        requestType: "prop",
        targetType: DynamicDataTargetType.SELLING_PLAN,
        referrerData: {
          type: "callback",
          onChange: (sellingPlanId: string) => {
            onChange(sellingPlanId);
          },
        },
      },
    });
  };

  if (sellingPlanOptions.length === 0) {
    return null;
  }

  // Common template value logic for both cases
  const templateValue =
    sellingPlanId && isDynamicDataValue(String(sellingPlanId))
      ? String(sellingPlanId)
      : "{{attributes_selectedSellingPlan}}";

  // NOTE (Jackson, 2025-04-03): Externlly, sellingPlanId is a number, but
  // with respect to the combobox, it needs to be a string. Additionally,
  // use "no-selection" as a sentinel value to indicate that no selling plan
  // is selected.
  const comboboxValue = sellingPlanId ? String(sellingPlanId) : "no-selection";

  // Common switch component for both cases
  const thirdPartySwitch = (
    <div className="mt-1 flex w-full flex-row justify-between">
      <SwitchWithDescription
        isOn={
          allowThirdPartySellingPlan || allowThirdPartySellingPlan == undefined
        }
        onChange={onChangeThirdPartySellingPlan}
        size="sm"
        label={
          <>
            Allow third-party embeds to override selling plan?{" "}
            <a
              href={docs.sellingPlans.thirdPartyAtc}
              className="inline-flex cursor-pointer items-center text-xs text-blue-600"
              target="_blank"
              rel="noreferrer"
              aria-label="Learn More"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <BsInfoCircleFill aria-hidden />
            </a>
          </>
        }
      />
    </div>
  );

  return (
    <LabeledControl label="Selling Plan" size="sm">
      {isDynamicDataValue(String(sellingPlanId)) ? (
        isNewDynamicData ? (
          <DynamicDataSelector
            side="left"
            sideOffset={16}
            initialPath={templateValue?.split("") ?? []}
            targetType={DynamicDataTargetType.SELLING_PLAN}
            trigger={
              <DynamicDataValueIndicator
                type="other"
                templateValue={templateValue}
                onRemove={onRemove}
              />
            }
            onChange={(value: string) => {
              onChange(value);
            }}
          />
        ) : (
          <DynamicDataValueIndicator
            type="other"
            templateValue={templateValue}
            onClick={_openDynamicData}
            onRemove={onRemove}
          />
        )
      ) : (
        <div className="flex-1 flex gap-2">
          <Combobox
            title="Select Selling Plan 2"
            side={side}
            sideOffset={sideOffset}
            options={sellingPlanOptions}
            value={comboboxValue}
            onChange={(value: string | undefined) => {
              if (value === "no-selection" || !value) {
                onChange(null);
              } else {
                onChange(Number(value));
              }
            }}
            layoutClassName="w-full"
            trigger={
              <Combobox.SelectionButton
                title={
                  typeof selectedSellingPlan?.label === "string"
                    ? selectedSellingPlan.label
                    : "Select Selling Plan"
                }
                placeholder="Select Selling Plan"
                size="sm"
                titleAlignment="start"
                endEnhancer={
                  comboboxValue !== "no-selection" ? (
                    <FormFieldXButton
                      onClick={() => {
                        onRemove();
                      }}
                    />
                  ) : undefined
                }
              />
            }
          />
          {isNewDynamicData ? (
            <DynamicDataSelector
              side="left"
              sideOffset={218}
              targetType={DynamicDataTargetType.SELLING_PLAN}
              trigger={<DynamicDataButton />}
              onChange={(value: string) => {
                onChange(value);
              }}
            />
          ) : (
            <DynamicDataButton
              onClick={(e) => {
                e.stopPropagation();
                _openDynamicData();
              }}
              tooltipText="Use dynamic value"
            />
          )}
        </div>
      )}
      {thirdPartySwitch}
    </LabeledControl>
  );
};
