import * as React from "react";

import Input from "@common/designSystem/Input";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { useOverflowDetection } from "@replo/design-system/utils/useOverflowDetection";

type EditableProjectNameProps = {
  projectId: string;
  initialProjectName: string;
  isRenaming: boolean;
  className?: string;
  inputClassName?: string;
  onFinishEditing?: () => void;
  showTooltip?: boolean;
  inputSize?: "sm" | "base";
};

const EditableProjectName: React.FC<EditableProjectNameProps> = ({
  projectId,
  initialProjectName,
  className,
  onFinishEditing,
  showTooltip = false,
  inputSize = "base",
  isRenaming,
}) => {
  const [projectName, setProjectName] = React.useState(initialProjectName);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const logAnalytics = useLogAnalytics();
  const [overflowRef, isOverflowing] = useOverflowDetection();

  const { mutate: updateProjectName } = trpc.project.updateName.useMutation({
    onSuccess: ({ project }) => {
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.project.listWithStats.invalidate();
      toast({
        header: "Project Name Updated",
        message: `Project name has been changed to "${project.name}".`,
      });
      onFinishEditing?.();
    },
  });

  const handleSaveProjectName = () => {
    if (projectName.trim() === "") {
      setProjectName(initialProjectName);
      onFinishEditing?.();
      return;
    }

    if (projectName !== initialProjectName) {
      logAnalytics("project.edit", {
        type: "rename",
      });
      updateProjectName({
        projectId: projectId,
        name: projectName,
      });
    } else {
      onFinishEditing?.();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      e.preventDefault();
      handleSaveProjectName();
    }
    if (e.key === "Escape") {
      e.preventDefault();
      setProjectName(initialProjectName);
      onFinishEditing?.();
    }
  };

  const handleBlur = () => {
    handleSaveProjectName();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setProjectName(e.target.value);
  };

  if (isRenaming) {
    return (
      <Input
        ref={inputRef}
        size={inputSize}
        type="text"
        value={projectName}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onClick={(e) => e.stopPropagation()}
        autoFocus
      />
    );
  }

  const content = (
    <span
      ref={overflowRef}
      className={className}
      data-testid={initialProjectName}
    >
      {initialProjectName}
    </span>
  );

  if (showTooltip && isOverflowing) {
    return (
      <Tooltip content={initialProjectName} triggerAsChild>
        {content}
      </Tooltip>
    );
  }

  return content;
};

export default EditableProjectName;
