import type {
  Flow,
  FlowInstance,
  FlowStep,
  FlowStepData,
} from "schemas/generated/flow";

import * as React from "react";

import { useReploFlowsContext } from "@components/flows/context/ReploFlowsContext";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import getPreviousStepId from "@components/flows/utils/getPreviousStepId";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { analytics } from "@editor/infra/analytics";
import { trpc } from "@editor/utils/trpc";

import { useSearchParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import {
  flowInstanceSchema,
  flowNextStepSubmitResponseSchema,
  flowSlugSchema,
} from "schemas/flow";
import { z } from "zod";

const useGetFlowActions = (
  entityType: Flow["type"],
  currentStep: FlowStep | null,
) => {
  const {
    debug: { flowToDebug },
  } = useReploFlowsContext();
  const [searchParams] = useSearchParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { currentFlow, currentInstance } = useGetCurrentFlow({
    entityType,
    isDebug,
  });
  const trpcUtils = trpc.useUtils();
  const { user } = useCurrentUser();

  const shopifyIntegrationId = searchParams.get("shopifyIntegrationId");
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");

  const { mutateAsync: submitFlowStep, isPending: isLoading } =
    trpc.flow.submitStep.useMutation({
      onSuccess: ({ nextStep }, { flowSlug, flowStep }) => {
        // NOTE (Fran 2024-05-07): We invalidate the user tag to update the user flow instances to
        // keep sync with the flows data.
        void trpcUtils.user.get.invalidate();

        // NOTE (Gabe 2024-10-04): We have to parse theFlowSlug in order to use it in the exhaustiveSwitch
        const typedFlowSlug = flowSlugSchema.parse(flowSlug);

        const invalidatePostOnboarding = () => {
          // NOTE (Fran 2024-05-07): We invalidate the projectMemberships and workspaces tags
          // because we need this updated data because after the onboarding is finished, we are
          // redirecting the user to the editor to a element created in the onboarding flow.
          void trpcUtils.flow.getMany.invalidate();
          void trpcUtils.project.membership.list.invalidate();
          void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        };

        exhaustiveSwitch({ type: typedFlowSlug })({
          "editor-tour": () => {
            if (!nextStep || flowStep.skipped) {
              void trpcUtils.flow.getMany.invalidate();
            }
          },
          "onboarding-styles": () => {
            if (!nextStep) {
              invalidatePostOnboarding();
            }
          },
        });

        exhaustiveSwitch(flowStep)({
          "onboarding.user.workspace-name": () => {
            void trpcUtils.project.listWithStats.invalidate();
          },
          "onboarding.user.connect-shopify": () => {},
          "onboarding.user.brand-details": () => {},
          "onboarding.user.full-name": () => {},
          "onboarding.user.import-from-url": () => {},
          "onboarding.user.who-are-you-building-pages-for": () => {},
          "onboarding.user.what-do-you-want-to-do-in-replo": () => {},
          "onboarding.user.who-are-you-building-pages-for.revenue": () => {},
          "onboarding.user.which-best-describes-your-industry": () => {},
          "onboarding.user.saved-styles": () => {},
          "onboarding.user.do-you-want-to-start-from-template": () => {},
          "tour.editor.generic": () => {},
        });
      },
    });

  const submitStep = React.useCallback(
    (
      stepId: FlowStep["id"],
      stepType: FlowStep["type"],
      stepData: FlowStepData["data"],
      callback?: ({
        nextStep,
        instance,
      }: {
        nextStep?: string | null;
        instance: FlowInstance;
      }) => void,
    ) => {
      if (!currentFlow?.slug) {
        return null;
      }

      if (user) {
        analytics.logEvent("onboarding.flow.step.completed", {
          userId: user.email,
          stepId,
          stepType,
          ...stepData,
        });
      }

      void submitFlowStep({
        flowSlug: currentFlow.slug,
        flowStep: {
          id: stepId,
          type: stepType,
          data: stepData,
          skipped: false,
        },
        instanceId: currentInstance?.id ?? undefined,
        isDebug: isDebug || flowToDebug === currentFlow.slug,
        shopifyInstallationParameters: {
          shopifyIntegrationId: shopifyIntegrationId ?? undefined,
          pendingAppInstallationId: pendingAppInstallationId ?? undefined,
        },
      }).then((response) => {
        callback?.(response);
      });
    },
    [
      currentFlow?.slug,
      submitFlowStep,
      currentInstance?.id,
      isDebug,
      flowToDebug,
      user,
      pendingAppInstallationId,
      shopifyIntegrationId,
    ],
  );

  const skipStep = React.useCallback(
    (
      stepId: FlowStep["id"],
      stepType: FlowStep["type"],
      callback?: ({
        nextStep,
        instance,
      }: {
        nextStep?: string | null;
        instance: FlowInstance;
      }) => void,
    ) => {
      if (currentFlow?.slug && currentStep?.isSkippable) {
        void submitFlowStep({
          flowSlug: currentFlow.slug,
          flowStep: {
            id: stepId,
            type: stepType,
            data: {},
            skipped: true,
          },
          instanceId: currentInstance?.id ?? undefined,
          shopifyInstallationParameters: {
            shopifyIntegrationId: shopifyIntegrationId ?? undefined,
            pendingAppInstallationId: pendingAppInstallationId ?? undefined,
          },
        }).then((response) => {
          const responseSchema = z.object({
            nextStep: flowNextStepSubmitResponseSchema,
            instance: flowInstanceSchema,
          });
          const parsedResponse = responseSchema.safeParse(response);
          if (parsedResponse.success) {
            callback?.(parsedResponse.data);
          } else {
            // NOTE (Fran 2024-02-08): We should log this error to track if there is any error in the
            // JSON of the flow.
            console.error({ parsedResponse, response });
          }
        });
      }
    },
    [
      currentFlow?.slug,
      currentStep?.isSkippable,
      submitFlowStep,
      currentInstance?.id,
      pendingAppInstallationId,
      shopifyIntegrationId,
    ],
  );

  const goBack = React.useCallback(
    (callback?: (currentFlow: Flow, previousStepId: string) => void) => {
      if (currentStep?.id && currentFlow) {
        const previousStepId = getPreviousStepId(
          currentFlow,
          currentStep,
          currentInstance,
        );
        if (previousStepId) {
          callback?.(currentFlow, previousStepId);
        }
      }
    },
    [currentFlow, currentInstance, currentStep],
  );

  return {
    submitStep,
    skipStep,
    goBack,
    isSubmitting: isLoading,
  };
};

export default useGetFlowActions;
