import type { ObjectFitType } from "@editor/types/object-fit";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";
import type { Context } from "replo-runtime/store/ReploVariable";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import { useAiAltTextMutation } from "@editor/reducers/ai-reducer";
import {
  selectDraftComponentIds,
  selectImageAltText,
} from "@editor/reducers/core-reducer";
import {
  resetEditorBrokenMediaComponentId,
  selectEditorBrokenMediaComponentIds,
} from "@editor/reducers/editor-media-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { selectComponentMarkers } from "@editor/reducers/tree-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import DynamicDataValueIndicator from "@editorExtras/DynamicDataValueIndicator";

import AssetPicker from "@/features/assets/old/AssetPicker";
import IconButton from "@replo/design-system/components/button/IconButton";
import Popover from "@replo/design-system/components/popover/Popover";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { Info, Sparkles, X } from "lucide-react";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { evaluateVariableAsString } from "replo-runtime/store/ReploVariable";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";

import ControlGroup from "../extras/ControlGroup";
import ModifierLabel from "../extras/ModifierLabel";
import InlineAssetSelector from "./InlineAssetSelector";

type ImageSourceSelectorProps = {
  src?: string | ReploMixedStyleValue;
  objectFitValue?: ObjectFitType | ReploMixedStyleValue;
  assetLoadingValue?: AssetLoadingType | ReploMixedStyleValue;
  objectPositionValues?: {
    x?: string | ReploMixedStyleValue;
    y?: string | ReploMixedStyleValue;
  };
  onChangeImageSource(value: string): void;
  onChangeObjectFit?(value: string): void;
  onChangeAssetLoading?(value: AssetLoadingType): void;
  onChangeObjectPositionX?(value: string): void;
  onChangeObjectPositionY?(value: string): void;
  onRemove?(): void;
  componentContext?: Context;
  allowsDynamicData?: boolean;
  componentId?: string;
  openPopoverWhenEmpty?: boolean;
  generateAltText?: (imageUrl: string) => void;
  isGeneratingAltText?: boolean;
  size: "sm" | "base";
  offset?: number;
};

const imageSourceAttribute = "__imageSource";

const ImageSourceSelector = ({
  src: originalSource,
  objectFitValue,
  assetLoadingValue,
  objectPositionValues,
  onChangeImageSource,
  onChangeObjectFit,
  onChangeAssetLoading,
  onChangeObjectPositionX,
  onChangeObjectPositionY,
  onRemove: _onRemove,
  componentContext,
  allowsDynamicData = true,
  componentId,
  openPopoverWhenEmpty = false,
  size,
  offset,
}: ImageSourceSelectorProps) => {
  const dispatch = useEditorDispatch();
  const editorBrokenMediaComponentIds = useEditorSelector(
    selectEditorBrokenMediaComponentIds,
  );
  const isAssetDynamicData = !isMixedStyleValue(originalSource)
    ? isDynamicDataValue(originalSource)
    : false;
  const evaluatedSource =
    originalSource && !isMixedStyleValue(originalSource)
      ? evaluateVariableAsString(originalSource, componentContext)
      : null;
  const resolvedSource =
    isAssetDynamicData && componentContext && evaluatedSource
      ? evaluatedSource
      : originalSource;
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const firstDraftComponentId = draftComponentIds[0];

  const isImageBroken = draftComponentIds.some((id) =>
    editorBrokenMediaComponentIds.includes(id),
  );
  const isPopoverVisible = !resolvedSource || isImageBroken;

  const [isVisible, setVisible] = useOverridableState(
    openPopoverWhenEmpty ? isPopoverVisible : false,
    undefined,
    firstDraftComponentId ?? undefined,
  );
  const modal = useModal();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const applyComponentAction = useApplyComponentAction();

  const [generateAiAltText, { isLoading: isGeneratingAltText }] =
    useAiAltTextMutation();

  const _onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.URL,
        referrerData: {
          type: "style",
          styleAttribute: imageSourceAttribute,
        },
        initialPath: !isMixedStyleValue(originalSource)
          ? getPathFromVariable(originalSource)
          : undefined,
      },
    });
  };

  const _onClickAltTextDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "style",
          styleAttribute: "__imageAltText",
        },
        initialPath: altTextValue
          ? getPathFromVariable(altTextValue)
          : undefined,
      },
    });
  };

  const _handleAltTextChange = React.useCallback(
    (value: string) => {
      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          {
            type: "setStyles",
            value: { __imageAltText: value },
          },
          // Note (Evan, 2024-04-25): When the user manually sets alt text,
          // mark the text as NOT ai-generated so we know not to replace it.
          {
            type: "setMarker",
            value: { _aiGeneratedAltText: false },
          },
        ],
      });
    },
    [applyComponentAction],
  );

  const initialAltTextValue = useEditorSelector(selectImageAltText);
  const [altTextValue, setAltTextValue] = useOverridableState(
    isMixedStyleValue(initialAltTextValue)
      ? "Mixed"
      : initialAltTextValue ?? "",
    useDebouncedCallback(_handleAltTextChange, 300),
  );

  // Note (Evan, 2024-04-26): Used to cancel generation requests
  const abortRef = React.useRef<(() => void) | null>(null);

  const componentMarkers = useEditorSelector((state) =>
    selectComponentMarkers(state, firstDraftComponentId ?? ""),
  );

  const isAltTextAiGenerated =
    Boolean(componentMarkers?._aiGeneratedAltText) &&
    altTextValue &&
    !isDynamicDataValue(altTextValue);

  // Note (Evan, 2024-04-25): Replace alt text with AI-generated text if it doesn't exist or if it was already AI-generated
  const generateAltTextIfNecessary = (value: string) => {
    if (
      (!altTextValue || isAltTextAiGenerated) &&
      firstDraftComponentId &&
      value.length > 0
    ) {
      const { abort } = generateAiAltText({
        componentId: firstDraftComponentId,
        imageUrl: value,
        triggeredManually: false,
      });
      abortRef.current = abort;
    }
  };

  const removeAiAltTextIfNecessary = () => {
    if (isAltTextAiGenerated) {
      setAltTextValue("");
    }
  };

  const onRemove = () => {
    removeAiAltTextIfNecessary();
    _onRemove?.();
    if (isImageBroken && firstDraftComponentId) {
      dispatch(resetEditorBrokenMediaComponentId(firstDraftComponentId));
    }
  };

  const onClickSelectAsset = () => {
    // Note (Noah, 2023-12-07): Regardless of whether we allow changing
    // object-fit etc, always set the popover visible instead of going straight
    // to the asset modal because we always support updating the alt text, for
    // which we need to open the popover
    setVisible(true);
  };

  const altTextElementRef = React.useRef<HTMLTextAreaElement>(null);

  const getTooltipText = () => {
    if (!resolvedSource || isGeneratingAltText) {
      return "Generating with AI";
    }
    return isAltTextAiGenerated ? "Re-generate with AI" : "Generate with AI";
  };

  const handleInputChange = (value: string) => {
    generateAltTextIfNecessary(value);
    onChangeImageSource(value);
    if (isImageBroken && firstDraftComponentId) {
      dispatch(resetEditorBrokenMediaComponentId(firstDraftComponentId));
    }
  };

  return (
    <Popover.Root
      isOpen={isVisible}
      onOpenChange={setVisible}
      // Note (Noah, 2022-04-23, REPL-1738): Can't ignore outside interactions
      // here because if we do, that makes the asset library modal not scrollable
      shouldIgnoreOutsideInteractions={false}
    >
      <Popover.Anchor className="w-full">
        {typeof originalSource === "string" && isAssetDynamicData ? (
          <DynamicDataValueIndicator
            type="image"
            templateValue={originalSource}
            onClick={onClickSelectAsset}
            onRemove={onRemove}
            componentId={componentId}
          />
        ) : (
          <InlineAssetSelector
            emptyTitle="Select Image"
            inputPlaceholder="Image URL"
            onClickSelectAsset={onClickSelectAsset}
            onRemoveAsset={onRemove}
            allowRemoveAsset={Boolean(_onRemove)}
            shouldOpenLibraryPopover={false}
            asset={
              isMixedStyleValue(originalSource)
                ? originalSource
                : {
                    type: "image",
                    // TODO (Noah, 2023-05-23): Added this "as string" since we were assuming
                    // it before we switched the return type of evaluateVariable to unknown,
                    // but need to investigate more since the undefined case should be handled
                    // somewhere above
                    src: resolvedSource as string,
                  }
            }
            allowsDynamicData={allowsDynamicData}
            onClickDynamicData={_onClickDynamicData}
            onSelectDynamicData={handleInputChange}
            onInputChange={handleInputChange}
            size={size}
          />
        )}
      </Popover.Anchor>
      {isVisible && (
        <Popover.Content
          title="Image"
          shouldPreventDefaultOnInteractOutside={areModalsOpen}
          sideOffset={offset}
        >
          <AssetPicker
            allowsSettingDynamicData={allowsDynamicData}
            onClickDynamicDataForUrl={_onClickDynamicData}
            objectFitValue={objectFitValue}
            assetLoadingValue={assetLoadingValue}
            objectPositionValue={objectPositionValues}
            assetType="image"
            emptyTitle="No Image Selected"
            selectedStateButtonLabel="Select Image"
            emptyStateButtonLabel="Change Image"
            url={resolvedSource}
            onChangeObjectFit={onChangeObjectFit}
            onChangeAssetLoading={onChangeAssetLoading}
            onChangeObjectPositionX={onChangeObjectPositionX}
            onChangeObjectPositionY={onChangeObjectPositionY}
            onChangeSource={handleInputChange}
            componentId={componentId}
            isUrlDynamicData={isAssetDynamicData}
            templateValue={originalSource}
            onClickRemoveDynamicData={onRemove}
            altTextComponent={
              <ControlGroup
                className={twMerge(
                  "flex",
                  isDynamicDataValue(altTextValue)
                    ? "items-center"
                    : "items-start",
                )}
              >
                <ModifierLabel label="Alt Text" />
                {(() => {
                  if (isDynamicDataValue(altTextValue)) {
                    return (
                      <DynamicDataValueIndicator
                        type="text"
                        templateValue={altTextValue ?? ""}
                        onClick={() => _onClickAltTextDynamicData()}
                        onRemove={() => setAltTextValue("")}
                        componentId={componentId}
                      />
                    );
                  }

                  if (componentContext?.isInsideProductImageCarousel) {
                    return (
                      <div className="flex-grow flex items-center gap-1">
                        <SelectionIndicator
                          title=""
                          placeholder="From Product Image"
                          disabled
                          endEnhancer={
                            <Tooltip
                              triggerAsChild
                              content="To change this image's alt text, edit in Shopify Admin"
                            >
                              <div className="flex items-center justify-center">
                                <Info className="text-subtle" size={12} />
                              </div>
                            </Tooltip>
                          }
                        />
                      </div>
                    );
                  }

                  return (
                    <div className="flex gap-1 flex-1">
                      <div className="flex-1 relative items-center">
                        {!isGeneratingAltText && (
                          <div className=" absolute top-0 left-0 right-0 bottom-0">
                            <Textarea
                              value={altTextValue}
                              onChange={setAltTextValue}
                              ref={altTextElementRef}
                              onFocus={() => {
                                abortRef.current?.();
                              }}
                              layoutClassName="h-14"
                              placeholder="Description"
                              isDisabled={
                                !resolvedSource ||
                                isGeneratingAltText ||
                                draftComponentIds.length > 1
                              }
                              size="sm"
                            />
                          </div>
                        )}

                        {isGeneratingAltText && (
                          <div
                            className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer transition-all duration-700 ease-out"
                            onClick={() => {
                              abortRef.current?.();
                              altTextElementRef.current?.focus();
                            }}
                          >
                            <Skeleton className="h-14" />
                            <span className="absolute top-1 left-2 text-xs text-muted animate-pulseDeep">
                              Generating
                            </span>
                            <div className="absolute right-2 top-1 text-muted">
                              <X size={12} />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col gap-2">
                        <IconButton
                          variant="secondary"
                          size="sm"
                          tooltipText={getTooltipText()}
                          onClick={() => {
                            if (
                              resolvedSource &&
                              !isMixedStyleValue(resolvedSource) &&
                              firstDraftComponentId
                            ) {
                              const { abort } = generateAiAltText({
                                componentId: firstDraftComponentId,
                                imageUrl: resolvedSource,
                                triggeredManually: true,
                              });
                              abortRef.current = abort;
                            }
                          }}
                          disabled={
                            !resolvedSource ||
                            isGeneratingAltText ||
                            draftComponentIds.length > 1
                          }
                          icon={<Sparkles size={16} className="shrink-0" />}
                          aria-label={getTooltipText()}
                        />

                        {allowsDynamicData && (
                          <div className="shrink-0">
                            <DynamicDataButton
                              onClick={() => _onClickAltTextDynamicData()}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })()}
              </ControlGroup>
            }
          />
        </Popover.Content>
      )}
    </Popover.Root>
  );
};

export default ImageSourceSelector;
