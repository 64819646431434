import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import * as React from "react";

import ColorPaletteDisplay from "./ColorPaletteDisplay";
import ShopStylePreviewText from "./ShopStylePreviewText";

interface ShopStylesPreviewCardProps {
  headingTextStyle: SavedTextStyle;
  bodyTextStyle: SavedTextStyle;
  colorStyles: SavedColorStyle[];
}

const ShopStylesPreviewCard: React.FC<ShopStylesPreviewCardProps> = ({
  headingTextStyle,
  bodyTextStyle,
  colorStyles,
}) => {
  const colors = colorStyles?.map((style) => style.attributes.color) || [];

  return (
    <div className="flex w-full rounded-md border-0.5 border-border p-2 gap-2 items-center justify-between">
      <div className="flex flex-col overflow-hidden min-w-0 text-left">
        <ShopStylePreviewText
          attributes={headingTextStyle.attributes}
          text="Heading"
          className="text-[20px] leading-[28px]"
        />
        <ShopStylePreviewText
          attributes={bodyTextStyle.attributes}
          text="Paragraph"
          className="text-[12px] leading-[16px]"
        />
      </div>

      {colors.length > 0 && (
        <ColorPaletteDisplay
          colors={colors}
          overlapSize="medium"
          className="flex-shrink-0"
        />
      )}
    </div>
  );
};

export default ShopStylesPreviewCard;
