import * as React from "react";

import { CreateExperimentButton } from "@/features/experiments/components/CreateExperimentButton";

const ExperimentsSetup = () => {
  return (
    <div className="flex items-center justify-center h-[70vh]">
      <div className="flex flex-col items-center justify-center gap-4">
        <img
          src="/images/experiments/all-experiments-no-data.svg"
          alt="Experiments Overview"
          width={450}
        />
        <div className="typ-header-h3">Test your ideas, see what converts.</div>
        <div className="typ-body-base text-muted max-w-[400px] text-center">
          Run simple A/B tests by splitting traffic to different pages. Discover
          the winner and improve store performance.
        </div>
        <CreateExperimentButton />
      </div>
    </div>
  );
};

export default ExperimentsSetup;
