export const IMAGE_ACCEPT_FILE_EXTENSIONS = [
  ".png",
  ".jpg",
  ".jpeg",
  ".webp",
  ".svg",
  ".gif",
];

export const VIDEO_ACCEPT_FILE_EXTENSIONS = [".mp4"];

export const ACCEPT_FILE_EXTENSIONS = [
  ...IMAGE_ACCEPT_FILE_EXTENSIONS,
  ...VIDEO_ACCEPT_FILE_EXTENSIONS,
];

export const FONT_ACCEPT_FILE_EXTENSIONS = [".woff", ".woff2"];

export const MAX_IMAGE_SIZE_BYTES = 10 * 1024 * 1024; // 10 MB in bytes
export const MAX_VIDEO_SIZE_BYTES = 250 * 1024 * 1024; // 250 MB in bytes
