import type { User } from "replo-runtime/shared/types";

import * as React from "react";

import "@editor/reducers/core-reducer";
import "@editor/store";
import "@editor/utils/localStorage";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { initUserBasedAnalytics } from "@editor/infra/analytics";
import { storeToken } from "@editor/reducers/utils/store-token";
import { trpc } from "@editor/utils/trpc";

import { useSearchParams } from "react-router-dom";
import { isLocalhost } from "replo-runtime/shared/env";

type CurrentUserContext = {
  user: User | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  isError: boolean;
};

export const CurrentUserContext = React.createContext<CurrentUserContext>({
  user: undefined,
  isAuthenticated: false,
  isLoading: false,
  isError: false,
});

export const CurrentUserProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { user, isAuthenticated, isLoading, isError } = useFetchCurrentUser();
  return (
    <CurrentUserContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        isError,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

const MAX_PYLON_INITIALIZE_ATTEMPTS = 10;

function useFetchCurrentUser() {
  const [searchParams] = useSearchParams();
  const shopifyIntegrationId = searchParams.get("shopifyIntegrationId");
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");

  const {
    data: user,
    isLoading,
    isError,
    isSuccess,
  } = trpc.user.get.useQuery({
    shopifyInstallationParameters: {
      shopifyIntegrationId: shopifyIntegrationId ?? undefined,
      pendingAppInstallationId: pendingAppInstallationId ?? undefined,
    },
  });
  const userIsInitialized = React.useRef(false);

  React.useEffect(() => {
    // Note (Noah, 2024-08-21): Only initialize the user-based analytics once
    // throughout the lifetime of the page, since we don't want to re-initialize
    // if multiple components are using this hook.
    if (isSuccess && user && !userIsInitialized.current) {
      initUserBasedAnalytics(user);
      userIsInitialized.current = true;
    }
  }, [isSuccess, user]);

  React.useEffect(() => {
    if (isError) {
      void storeToken(null);
    }
  }, [isError]);

  const isSupportChatEnabled = !isLocalhost();
  const logEvent = useLogAnalytics();

  React.useEffect(() => {
    // This effect handles the Pylon widget script injection and configuration
    if (user && isSupportChatEnabled) {
      window.pylon = window.pylon || {
        chat_settings: {
          app_id: "efe32725-24c5-4f17-bb77-42f06cc70918",
          email: user.email,
          name: user.name ?? user.email,
        },
      };

      let pylonCheckAttempts = 0;

      const configurePylon = () => {
        if (window.Pylon) {
          window.Pylon("onShow", function () {
            logEvent("support.chat.open", {
              source: "pylon",
              userEmail: user.email,
              userName: user.name ?? user.email,
            });
          });
        } else if (pylonCheckAttempts < MAX_PYLON_INITIALIZE_ATTEMPTS) {
          // Note (Juan, 09/11/2024): The Pylon script is loaded asynchronously, which means it might not be
          // immediately available after the script's "load" event fires. To handle this,
          // we implement a retry mechanism using setTimeout. This function will keep
          // checking for the window.Pylon object every 500ms until it becomes available.
          // This ensures that we can configure the Pylon chat as soon as it's ready,
          // without risking trying to use it before it's fully loaded.
          pylonCheckAttempts++;
          setTimeout(configurePylon, 500);
        } else {
          console.warn("Failed to load Pylon after multiple attempts");
        }
      };

      configurePylon();
    }
  }, [user, isSupportChatEnabled, logEvent]);

  return {
    user: user ?? undefined,
    isAuthenticated: Boolean(user?.id),
    isLoading,
    isError,
  };
}
