import { Liquid } from "liquidjs";

const engine = new Liquid();

interface LiquidError {
  message: string;
  line: number;
}

const parseErrorLine = (message: string): number => {
  const lineMatch = message.match(/line:(\d+)/);
  return lineMatch ? Number(lineMatch[1]) : 1;
};

// NOTE (Jackson, 2025-01-10): Liquidjs doesn't support Shopify tags out of the box,
// so we need to register them manually
const shopifyTags = [
  "form",
  "endform",
  "section",
  "endsection",
  "schema",
  "endschema",
  "style",
  "endstyle",
  "stylesheet",
  "endstylesheet",
  "javascript",
  "endjavascript",
  "layout",
  "paginate",
  "endpaginate",
];

shopifyTags.forEach((tag) => {
  engine.registerTag(tag, {
    parse: function () {
      return;
    },
    render: function () {
      return "";
    },
  });
});

// NOTE (Jackson, 2025-01-10): The liquidjs engine only parses up to the first error
export const validateLiquidSyntax = (content: string): LiquidError | null => {
  try {
    engine.parse(content);
    return null;
  } catch (error) {
    return {
      message: (error as Error).message,
      line: parseErrorLine((error as Error).message),
    };
  }
};
