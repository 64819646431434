import * as React from "react";

export const PartnerOffersFrame: React.FC = () => {
  return (
    <iframe
      src="https://www.replo.app/partner-offers"
      className="w-full"
      style={{
        border: "none",
        height: "calc(100vh - 35px)",
        width: "100%",
      }}
      title="Partner Offers"
      allow="fullscreen"
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
    />
  );
};
