import * as React from "react";

import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { LuExternalLink } from "react-icons/lu";
import { generatePath } from "react-router-dom";

export const RefreshShopifyIntegrationAnalyticsModal: React.FC<{
  workspaceId: string;
}> = ({ workspaceId }) => {
  const { closeModal } = useModal();
  return (
    <Modal
      isOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          closeModal({ type: "refreshShopifyIntegrationAnalyticsModal" });
        }
      }}
      title="Update Shopify Integrations"
      description="Looks like some of your Shopify Integrations are missing permissions. Please update your Shopify integration and approve new permissions to continue"
      size="sm"
      footer={
        <div className="flex flex-row gap-2 items-center justify-end">
          <Button
            size="base"
            variant="secondary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh Page
          </Button>
          <Button
            size="base"
            variant="primary"
            endEnhancer={<LuExternalLink size={16} className="w-4 h-4" />}
            onClick={() => {
              window.open(
                generatePath(routes.workspace.integrations.shopify, {
                  workspaceId,
                }),
                "_blank",
              );
            }}
          >
            View Integrations
          </Button>
        </div>
      }
    >
      <div className="flex flex-col w-full items-center mb-6">
        <img
          src="/replo-shopify-connector.svg"
          alt="Connect Replo To Shopify"
          className="w-[250px]"
        />
      </div>
    </Modal>
  );
};
