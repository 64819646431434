import type { AssetType } from "schemas/generated/asset";

import * as React from "react";

import ComponentTemplateDragOverlay from "@editor/components/ComponentTemplateDragOverlay";
import { image as imageComponentTemplate } from "@editor/components/editor/templates/image";
import { videoPlayer as videoComponentTemplate } from "@editor/components/editor/templates/videoPlayer";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

const AssetCardDragOverlay: React.FC<
  React.PropsWithChildren<{
    asset: {
      id: string;
      type: AssetType;
      url: string;
    };
    shouldAllowDrag: boolean;
  }>
> = ({ asset, children, shouldAllowDrag }) => {
  if (!shouldAllowDrag) {
    return children;
  }

  const { id, type, url } = asset;

  const template = exhaustiveSwitch(asset)({
    image: {
      ...imageComponentTemplate,
      template: {
        ...imageComponentTemplate.template!,
        props: { ...imageComponentTemplate.template!.props, src: url },
      },
    },
    video: {
      ...videoComponentTemplate,
      template: {
        ...videoComponentTemplate.template!,
        props: { ...videoComponentTemplate.template!.props, url },
      },
    },
    font: () => {
      throw new Error(`Unknown asset type: ${type}`);
    },
  });

  return (
    <div className="relative">
      <ComponentTemplateDragOverlay
        showOutlineOnHover={false}
        identifier={id}
        template={template}
        scope="left-bar"
        dragType="newComponent"
      />
      {children}
    </div>
  );
};

export default AssetCardDragOverlay;
