import type { SchemaReploShopifyProduct } from "schemas/generated/product";
import type { ProductRef } from "schemas/product";

import React from "react";

import { useFetchStoreProducts } from "@editor/hooks/useStoreProducts";

import twMerge from "@replo/design-system/utils/twMerge";
import { mapStoreProductToReploProduct } from "replo-runtime/shared/mappers/product";

import { ProductSelector } from "../common/designSystem/ProductSelector";

export const SelectProduct: React.FC<{
  allowDynamicData?: boolean;
  selectedProduct?: SchemaReploShopifyProduct | null;
  triggerClassName?: string;
  className?: string;
  selectionIndicatorClassName?: string;
  onProductChange: (product: SchemaReploShopifyProduct | null) => void;
}> = ({
  allowDynamicData,
  onProductChange,
  selectedProduct,
  triggerClassName,
  className,
  selectionIndicatorClassName,
}) => {
  const selectedProductRefProp = selectedProduct
    ? {
        productId: selectedProduct.id,
        title: selectedProduct.title,
        id: selectedProduct.id,
      }
    : null;
  const [selectedProductRef, setSelectedProductRef] =
    React.useState<ProductRef | null>(selectedProductRefProp);

  const currentProductRef = React.useRef<ProductRef | null>(
    selectedProductRefProp,
  );

  const { products } = useFetchStoreProducts([
    Number(selectedProductRef?.productId),
  ]);

  // Patrick (2025-02-21) - Ideally we don't need this effect.
  // This effect should be handled in the handleProductChange function below with some async await
  // but we need to use the useFetchStoreProducts hook in order to get the right product.
  // Then we need to map it to the replo product. Because we need the hook, we use it in the effect here.
  React.useEffect(() => {
    if (
      products &&
      products.length > 0 &&
      currentProductRef.current !== selectedProductRef
    ) {
      if (products[0]) {
        const product = mapStoreProductToReploProduct(products[0], {
          currencyCode: "USD",
          language: "en",
          // eslint-disable-next-line
          moneyFormat: "${{amount}}",
          metafields: {
            productMetafieldsMapping: {},
            variantMetafieldsMapping: {},
          },
          quantity: 1,
        });

        if (product) {
          onProductChange(product);
        }
      }

      currentProductRef.current = selectedProductRef;
    } else if (currentProductRef.current !== selectedProductRef) {
      onProductChange(null);
    }
  }, [products, selectedProductRef, onProductChange]);

  const handleProductChange = (newProduct: ProductRef | null) => {
    setSelectedProductRef(newProduct);
  };

  return (
    <ProductSelector
      selectedProductRef={selectedProductRef}
      onChange={handleProductChange}
      isMultiProducts={false}
      dataType="summary"
      selectionIndicatorClassName={selectionIndicatorClassName}
      className={twMerge("w-40", className)}
      triggerClassName={twMerge(
        "h-[48px] border-0.5 border-border rounded-md bg-hover px-[17.5px]",
        triggerClassName,
      )}
      allowDynamicData={allowDynamicData}
    />
  );
};
