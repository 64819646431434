import type { PendingShopifyIntegrationCookie } from "schemas/cookies";
import type { ReploSimpleProject } from "schemas/generated/project";

import * as React from "react";

import useInstallShopify from "@editor/hooks/useInstallShopify";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc } from "@editor/utils/trpc";

const NEW_SHOPIFY_INTEGRATION_ID = "NEW_STORE_INTEGRATION";

interface UseShopifyIntegrationConnectProps {
  workspaceId: string | null | undefined;
  projectId?: string | null;
  project?: ReploSimpleProject | null;
  cookieValue: PendingShopifyIntegrationCookie;
  onSuccess?: () => void;
}

export const useShopifyIntegrationConnect = ({
  workspaceId,
  projectId,
  project,
  cookieValue,
  onSuccess,
}: UseShopifyIntegrationConnectProps) => {
  const [selectedIntegrationId, setSelectedIntegrationId] = React.useState<
    string | null
  >(null);
  const [isLoading, setLoading] = React.useState(false);

  const installShopify = useInstallShopify();
  const analytics = useLogAnalytics();

  const { mutate: connectIntegration } =
    trpc.project.connectToIntegration.useMutation({
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        } else {
          window.location.reload();
        }
      },
    });

  const handleConnect = React.useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      setLoading(true);

      if (cookieValue.type === "integrationHub") {
        analytics("integration.shopify.add.initiate", {});
      }

      const isConnectingExistingIntegration =
        selectedIntegrationId &&
        selectedIntegrationId !== NEW_SHOPIFY_INTEGRATION_ID;

      const effectiveProjectId = projectId || project?.id;

      if (isConnectingExistingIntegration && effectiveProjectId) {
        try {
          connectIntegration({
            projectId: effectiveProjectId,
            integrationId: selectedIntegrationId,
          });
        } catch {
          setLoading(false);
        }
      } else {
        await installShopify({
          workspaceId: workspaceId ?? undefined,
          cookie: cookieValue,
        });
        setLoading(false);
      }
    },
    [
      analytics,
      connectIntegration,
      cookieValue,
      installShopify,
      project?.id,
      projectId,
      selectedIntegrationId,
      workspaceId,
    ],
  );

  return {
    selectedIntegrationId,
    setSelectedIntegrationId,
    isLoading,
    handleConnect,
  };
};
