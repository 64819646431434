import type { ExperimentStatus } from "schemas/experiment";
import type { TagProps } from "../components/tag/Tag";

export const experimentStatusMapping: Record<
  ExperimentStatus,
  { variant: TagProps["variant"]; label: string }
> = {
  active: {
    variant: "success",
    label: "Live",
  },
  draft: {
    variant: "info",
    label: "Draft",
  },
  complete: {
    variant: "neutral",
    label: "Test Ended",
  },
  archived: {
    variant: "neutral",
    label: "Archived",
  },
};
