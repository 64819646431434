import { z } from "zod";

export const shopifyIntegrationSchema = z
  .object({
    id: z.string().uuid(),
    name: z.string().nullish(),
    shopifyUrl: z.string(),
    url: z.string(),
    accessScopes: z.string(),
    webPixelId: z.string().nullish(),
  })
  .describe("ShopifyIntegrationData");
