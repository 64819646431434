import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import { ConnectShopifyIntegrationCta } from "@editor/components/editor/page/ConnectShopifyCallout";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import OnboardingStepLayout from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";

import Button from "@replo/design-system/components/button/Button";
import { useSearchParams } from "react-router-dom";

import StepSubtitle from "./components/StepSubtitle";

const ConnectShopifyStep: React.FC = () => {
  const { currentStep, submitStep, skipStep } = useReploFlowsStepContext();
  const { submitOrSkipStepCallback } = useRouterFlowCallbacks();
  const { isSubmitting, goBack } = useReploFlowsStepContext();

  const [searchParams] = useSearchParams();
  const shopifyIntegrationId = searchParams.get("shopifyIntegrationId");
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");

  // Note (Noah, 2025-02-20): This is hella hacky, but it's the only way to determine
  // that we successfully came back from the Shopify install flow after correctly installing
  // the app (since we add the query params on redirect). Once we have actual cookie auth,
  // we can actually just submit the step in /postinstall in the backend and not have to do this
  const hasAutomaticallySubmittedStepRef = React.useRef(false);
  React.useEffect(() => {
    if (
      (shopifyIntegrationId || pendingAppInstallationId) &&
      currentStep &&
      !hasAutomaticallySubmittedStepRef.current
    ) {
      hasAutomaticallySubmittedStepRef.current = true;
      submitStep(
        currentStep.id,
        currentStep.type,
        {},
        ({ instance, nextStep }) =>
          submitOrSkipStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
      );
    }
  }, [
    shopifyIntegrationId,
    pendingAppInstallationId,
    submitStep,
    currentStep,
    submitOrSkipStepCallback,
  ]);

  if (!currentStep) {
    return null;
  }

  const handleSkip = () => {
    skipStep(currentStep.id, currentStep.type, ({ instance, nextStep }) => {
      submitOrSkipStepCallback({
        nextStep: nextStep ?? null,
        flowSlug: instance.flow.slug,
      });
    });
  };

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/connect-shopify.png" />}
    >
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-2">
          <StepTitle>Add Shopify Integration</StepTitle>
          <StepSubtitle>
            <div>
              Install our Shopify integration to automatically import your
              products, styles, and allow you to publish pages.
            </div>
            <div className="mt-2">Takes 30 seconds to set up.</div>
          </StepSubtitle>
        </div>
        <div className="flex gap-2 flex-1 self-end justify-self-end items-center">
          <Button
            variant="tertiary"
            size="base"
            disabled={isSubmitting}
            onClick={() => {
              goBack();
            }}
          >
            Back
          </Button>
          <Button
            variant="secondary"
            size="base"
            disabled={isSubmitting}
            onClick={() => {
              handleSkip();
            }}
          >
            Skip
          </Button>
          <ConnectShopifyIntegrationCta
            cookieValue={{ type: "onboarding" }}
            size="base"
            ctaText="Add Integration"
          />
        </div>
      </div>
    </OnboardingStepLayout>
  );
};

export default ConnectShopifyStep;
