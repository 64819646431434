import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import ContentLoader from "react-content-loader";

type SkeletonProps = {
  className?: string;
  speed?: number;
  backgroundColor?: string;
  foregroundColor?: string;
};
export const Skeleton: React.FC<SkeletonProps> = ({
  className,
  speed,
  backgroundColor,
  foregroundColor,
}) => {
  return (
    <ContentLoader
      className={twMerge("rounded-md", className)}
      speed={speed}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect className={twMerge(className)} />
    </ContentLoader>
  );
};

/** @public */
export type { SkeletonProps };
