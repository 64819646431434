import * as React from "react";

import { Loader } from "@common/Loader";
import Header from "@components/dashboard/Header";
import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";

import { Outlet } from "react-router-dom";

export default function PartnerDashboard() {
  const { workspace, isLoading: currentWorkspaceIsLoading } =
    useCurrentWorkspaceContext();

  const isPartnerOnboarded = workspace?.isOnboarded;

  if (!workspace) {
    return null;
  }

  if (currentWorkspaceIsLoading) {
    return (
      <div className="w-full" style={{ height: "calc(100vh - 80px)" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex h-screen w-full flex-col px-6 gap-4">
      {isPartnerOnboarded ? (
        <>
          <Header
            title="Partner Dashboard"
            subtitle="These are all the tools you need to be successful in the Replo Partner Program."
          />
          <hr />
        </>
      ) : null}
      <div className="no-scrollbar grow overflow-y-scroll pb-6">
        <Outlet />
      </div>
    </div>
  );
}
