import type { LeftBarTab } from "@reducers/ui-reducer";
import type { ReploElementVersionRevision } from "schemas/generated/element";

import * as React from "react";

import {
  DEBUG_PANEL_WIDTH,
  HEADER_HEIGHT,
  MINIMUM_LEFT_BAR_WIDTH,
} from "@components/editor/constants";
import TourStepTrigger from "@components/flows/TourStepTrigger";
import { ComponentTreePane } from "@components/left-bar/ComponentTreePane";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import ElementsPane from "@editor/components/left-bar/ElementsPane";
import InsertPane from "@editor/components/left-bar/insert-pane/InsertPane";
import UpdateElementPane from "@editor/components/left-bar/UpdateElementPane";
import ResizablePane from "@editor/components/ResizablePane";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useEditorPerformanceContext } from "@editor/contexts/editor-performance.context";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useDraftElementMetadata } from "@editor/hooks/useDraftElementMetadata";
import useElementVersioning from "@editor/hooks/useElementVersioning";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectLeftBarActiveTab,
  selectLeftBarNavigationOpenedMenu,
  selectLeftBarWidth,
  setLeftBarActiveTab,
  setLeftBarWidth,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { docs } from "@editor/utils/docs";
import useCreateComponentForm from "@hooks/useCreateComponentForm";
import {
  selectInternalDebugModeOn,
  setDebugPanelVisibility,
  setEditorMode,
} from "@reducers/core-reducer";

import AnnouncementsMenu from "@/features/announcements/AnnouncementsMenu";
import AssetsLibraryPane from "@/features/assets/components/AssetsLibraryPane";
import IconButton from "@replo/design-system/components/button/IconButton";
import { HotkeyIndicator } from "@replo/design-system/components/hotkeys/HotKeyIndicator";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import {
  Archive,
  ChevronLeft,
  File,
  History,
  Image,
  Info,
  Layers2,
  LayoutTemplate,
  Plus,
  Settings,
  Store,
} from "lucide-react";
// ToDo (Ben O., 03/11/2025): Replace with custom design system icon when added in
import { BsQuestionLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import BrandDetailsPane from "../designLibrary/BrandDetailsPane";
import VersionHistoryPane, {
  VersionHistoryFilter,
} from "../version-history/VersionHistoryPaneWrapper";

type LeftPanelMenuTab = {
  id: LeftBarTab;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  label?: React.ReactNode;
  type: "page" | "project";
  onClick?: () => void;
};

interface LeftBarProps {
  showElementSpecificContent: boolean;
  fullPageOverlay?: React.ReactNode;
}

const LeftBar: React.FC<LeftBarProps> = React.memo(function LeftBar({
  showElementSpecificContent,
  fullPageOverlay,
}: LeftBarProps) {
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);
  const { isLoading } = useCurrentProjectContext();
  const dispatch = useEditorDispatch();

  // Note (Evan, 2024-10-08): This hook ~will~ re-render with each streamed action,
  // but it's not an issue because we're just returning null while streaming. There is
  // a more global issue with this, which see: https://replohq.slack.com/archives/C03AACVP08Y/p1728413180369459.
  const { hasQueuedGeneration } = useAIStreaming();

  useCreateComponentForm(() => dispatch(setLeftBarActiveTab("components")));

  if (isLoading || hasQueuedGeneration) {
    return null;
  }

  return (
    <div
      // NOTE (Gabe 2023-06-13): z-0 creates a new stacking context which enables
      // using z-index for sticky headers that don't overlay portals in the rest
      // of the app.
      className={twMerge("absolute flex z-0", fullPageOverlay && "w-full")}
      style={{
        top: HEADER_HEIGHT,
        left: isDebugPanelVisible ? DEBUG_PANEL_WIDTH : 0,
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      }}
    >
      <LeftPanelMenu showElementSpecificContent={showElementSpecificContent} />
      <LeftPanelContent />
      {fullPageOverlay}
    </div>
  );
});

export default LeftBar;

const tabToTitleMap = (displayName: string): Record<LeftBarTab, string> => ({
  tree: "Layers",
  elements: "All Pages",
  components: "Insert",
  shopStyles: "Shop Details",
  assets: "Assets",
  settings: `${displayName} Settings`,
  versionHistory: "Version History",
});

const LeftPanelMenu: React.FC<{
  showElementSpecificContent: boolean;
}> = ({ showElementSpecificContent }) => {
  const dispatch = useEditorDispatch();
  const isDebugMode = useIsDebugMode();
  const modal = useModal();
  const logEvent = useLogAnalytics();
  const subscriptionTier = useSubscriptionTier();
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);
  const navigate = useNavigate();
  const leftBarActiveTab = useEditorSelector(selectLeftBarActiveTab);
  const isGivenTabActive = (tab: LeftBarTab) => leftBarActiveTab === tab;
  const projectId = useCurrentProjectId();
  const handleDebug = React.useCallback(() => {
    dispatch(setDebugPanelVisibility(!isDebugPanelVisible));
  }, [dispatch, isDebugPanelVisible]);

  const tabs: Array<LeftPanelMenuTab> = [
    {
      id: "components",
      icon: <Plus size={20} />,
      activeIcon: <Plus size={20} />,
      label: <HotkeyIndicator hotkey="setComponentsPanel" title="Insert" />,
      type: "page",
    },
    {
      id: "tree",
      icon: <Layers2 size={20} />,
      activeIcon: <Layers2 size={20} />,
      label: <HotkeyIndicator hotkey="setLayersPanel" title="Layers" />,
      type: "page",
    },
    {
      id: "settings",
      icon: <Settings size={20} />,
      activeIcon: <Settings size={20} />,
      label: (
        <HotkeyIndicator hotkey="openPageSettings" title="Page Settings" />
      ),
      type: "page",
    },
    {
      id: "versionHistory",
      icon: <History size={20} />,
      activeIcon: <History size={20} />,
      label: (
        <HotkeyIndicator
          hotkey="toggleVersionHistory"
          title="Version History"
        />
      ),
      type: "page",
      onClick: () => {
        dispatch(setEditorMode(EditorMode.versioning));
      },
    },
  ];

  const projectTabs: Array<LeftPanelMenuTab> = [
    {
      id: "elements",
      icon: <File size={20} />,
      activeIcon: <File size={20} />,
      label: <HotkeyIndicator hotkey="setElementsPanel" title="All Pages" />,
      type: "project",
    },
    {
      id: "assets",
      icon: <Image size={20} />,
      activeIcon: <Image size={20} />,
      label: <HotkeyIndicator hotkey="setAssetsPanel" title="Assets" />,
      type: "project",
    },
    {
      id: "shopStyles",
      icon: <Store size={20} />,
      activeIcon: <Store size={20} />,
      label: (
        <HotkeyIndicator hotkey="setSavedStylesPanel" title="Shop Details" />
      ),
      type: "project",
    },
  ];

  const renderLeftPanelMenuButton = (
    tab: LeftPanelMenuTab,
    isActive: boolean,
    title: string,
    onClick: () => void,
  ) => (
    <LeftPanelMenuButton
      key={tab.id}
      label={tab.label}
      ariaLabel={title}
      isActive={isActive}
      onClick={onClick}
    >
      {isActive ? tab.activeIcon : tab.icon}
    </LeftPanelMenuButton>
  );

  return (
    <div
      className={twMerge(
        "bg-default flex flex-col gap-2 h-full border-r border-t border-slate-200 py-3 px-2 justify-between z-10",
        leftBarActiveTab === null && "shadow-lg",
      )}
    >
      <div className="flex flex-col gap-2">
        {showElementSpecificContent && (
          <>
            {tabs.map((tab) => {
              const isActive = isGivenTabActive(tab.id);
              const title = tabToTitleMap("Page")[tab.id];
              return renderLeftPanelMenuButton(tab, isActive, title, () => {
                logEvent("editor.panel.toggle", {
                  toggle: tab.id,
                  isOpened: !isActive,
                  openedWithShortcut: false,
                });
                dispatch(setLeftBarActiveTab(!isActive ? tab.id : null));
              });
            })}
            <div className="border-t-[0.5px] border-slate-300" />
          </>
        )}
        {projectTabs.map((tab) => {
          const isActive = isGivenTabActive(tab.id);
          const title = tabToTitleMap("Page")[tab.id];
          return renderLeftPanelMenuButton(tab, isActive, title, () => {
            logEvent("editor.panel.toggle", {
              toggle: tab.id,
              isOpened: !isActive,
              openedWithShortcut: false,
            });
            dispatch(setLeftBarActiveTab(!isActive ? tab.id : null));
          });
        })}
      </div>
      <div className="flex flex-col gap-2">
        {isFeatureEnabled("archived-pages") && (
          <LeftPanelMenuButton
            label="Archive"
            ariaLabel="Archive"
            onClick={() => {
              dispatch(setEditorMode(EditorMode.archived));
            }}
          >
            <Archive size={20} className="text-muted" />
          </LeftPanelMenuButton>
        )}
        {isDebugMode && (
          <LeftPanelMenuButton
            label="Debug Menu"
            ariaLabel="Replo Debug Menu"
            isActive={isDebugPanelVisible}
            onClick={handleDebug}
          >
            <span role="img" aria-label="Debug">
              🔮
            </span>
          </LeftPanelMenuButton>
        )}
        <LeftPanelMenuButton
          label="Browse Templates"
          ariaLabel="Browse Templates"
          onClick={() => {
            logEvent("editor.marketplace.browse", {
              from: "leftBar",
            });
            navigate(`/editor/${projectId}/new`);
            dispatch(setLeftBarActiveTab(null));
          }}
        >
          <LayoutTemplate size={20} className="text-muted" />
        </LeftPanelMenuButton>

        <AnnouncementsMenu source="editor" />
        <LeftPanelMenuButton
          label="Get Help"
          ariaLabel="Get Help"
          onClick={() => {
            logEvent("user.help.requested", {
              billingPlan: subscriptionTier,
              from: "button",
            });
            modal.openModal({
              type: "supportModal",
            });
          }}
        >
          <BsQuestionLg className="text-muted" />
        </LeftPanelMenuButton>
      </div>
    </div>
  );
};

export const LeftPanelMenuButton: React.FC<
  React.PropsWithChildren<{
    label: React.ReactNode;
    ariaLabel: string;
    onClick: () => void;
    isActive?: boolean;
  }>
> = ({ label, isActive, ariaLabel, children, onClick }) => {
  return (
    <Tooltip content={label} triggerAsChild>
      <IconButton
        variant="secondary"
        UNSAFE_className={twMerge(
          "shadow-[0px_0px_4px_2px_rgba(0,0,0,0.02)] rounded-md bg-white",
          isActive
            ? "bg-slate-800 text-white hover:bg-slate-800"
            : "hover:bg-hover text-muted hover:text-default border-slate-300 border-[0.5px]",
        )}
        aria-label={ariaLabel}
        onClick={onClick}
        icon={children}
      />
    </Tooltip>
  );
};

const LeftPanelContent: React.FC = () => {
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const leftBarActiveTab = useEditorSelector(selectLeftBarActiveTab);
  // NOTE (Bobby, 2025-03-13) Had to move this hook up here because we wanted to use the version history
  // filter to the top bar. Because of how the left panel is currently built, the panel title and panel content
  // are separate components and since this hook manages local states, we needed to lift the state up so
  // the version history filter could be used in the top bar.
  const {
    versions,
    filterBy,
    setFilterBy,
    isLoading,
    isFetchingNextPage,
    loadMoreRef,
  } = useElementVersioning();

  const draftElementMetadata = useDraftElementMetadata();

  const displayName =
    elementTypeToEditorData[draftElementMetadata?.type ?? "page"]
      ?.singularDisplayName ?? "Page";

  if (leftBarActiveTab === null) {
    return null;
  }

  const content = exhaustiveSwitch({ type: leftBarActiveTab })<{
    component: React.ReactNode;
    documentationLink: string | null;
  }>({
    components: () => ({
      documentationLink: docs.leftBar.insert,
      component: (
        <TourStepTrigger step="step-2">
          <InsertPane />
        </TourStepTrigger>
      ),
    }),
    elements: () => ({
      documentationLink: docs.leftBar.elements,
      component: (
        <TourStepTrigger step="step-1">
          <ElementsPane />
        </TourStepTrigger>
      ),
    }),
    tree: () => ({
      // TODO (Fran 2024-09-20): Add this documentation link
      documentationLink: null,
      component: <ComponentTreePane />,
    }),
    shopStyles: () => ({
      documentationLink: docs.savedStyles.main,
      component: <BrandDetailsPane />,
    }),
    assets: () => ({
      // TODO (Sebas, 2025-01-31, REPL-15763): Add documentation link
      documentationLink: null,
      component: <AssetsLibraryPane />,
    }),
    settings: () => ({
      documentationLink: null,
      component: <UpdateElementPane />,
    }),
    versionHistory: () => ({
      documentationLink: null,
      component: (
        <VersionHistoryPaneWrapperWithMode
          versions={versions}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          loadMoreRef={loadMoreRef}
        />
      ),
    }),
  });

  return (
    <ResizableLeftPanelContentWrapper>
      <div className="flex flex-col gap-2 h-full">
        <div className="flex justify-between items-center px-3 pt-3">
          <div className="flex gap-1 items-center">
            <span className="typ-header-base text-default">
              {tabToTitleMap(displayName)[leftBarActiveTab]}
            </span>
            {content.documentationLink && (
              <Info
                size={12}
                className="text-muted cursor-pointer"
                onClick={() => {
                  window.open(content.documentationLink ?? "", "_blank");
                }}
              />
            )}
          </div>

          <div className="flex gap-1 items-center">
            {leftBarActiveTab === "versionHistory" && (
              <VersionHistoryFilter
                filterBy={filterBy}
                setFilterBy={setFilterBy}
              />
            )}
            <IconButton
              variant="tertiary"
              icon={<ChevronLeft size={12} />}
              onClick={() => {
                logEvent("editor.panel.toggle", {
                  toggle: leftBarActiveTab,
                  isOpened: false,
                  openedWithShortcut: false,
                });
                dispatch(setLeftBarActiveTab(null));
              }}
              aria-label="Close left bar panel"
              size="sm"
            />
          </div>
        </div>
        {content.component}
      </div>
    </ResizableLeftPanelContentWrapper>
  );
};

const ResizableLeftPanelContentWrapper: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const leftBarWidth = useEditorSelector(selectLeftBarWidth);
  const leftBarNavigationOpenedMenu = useEditorSelector(
    selectLeftBarNavigationOpenedMenu,
  );
  const activeTab = useEditorSelector(selectLeftBarActiveTab);
  const previousResizableWidth = React.useRef(leftBarWidth);

  const { leftBarElementRef } = useEditorPerformanceContext();

  const isLeftBarNavigationOpen = leftBarNavigationOpenedMenu !== "";

  return (
    <div className="flex" ref={leftBarElementRef}>
      <ResizablePane
        minSize={MINIMUM_LEFT_BAR_WIDTH}
        maxSize={500}
        handleClassName="bg-slate-200 w-[1px]"
        className="flex shadow-lg"
        size={leftBarWidth}
        contentClassName="bg-default border-r border-t border-slate-200"
        onResize={(size) => dispatch(setLeftBarWidth(size))}
        onResizeStop={() => {
          logEvent("editor.panel.resize", {
            previousSize: previousResizableWidth.current,
            newSize: leftBarWidth,
            activePanel: activeTab,
          });
          previousResizableWidth.current = leftBarWidth;
        }}
        isDisabled={isLeftBarNavigationOpen}
      >
        {children}
      </ResizablePane>
    </div>
  );
};

const VersionHistoryPaneWrapperWithMode = ({
  versions,
  isLoading,
  isFetchingNextPage,
  loadMoreRef,
}: {
  versions: ReploElementVersionRevision[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  loadMoreRef: (node: HTMLElement | null) => void;
}) => {
  const dispatch = useEditorDispatch();

  React.useEffect(() => {
    dispatch(setEditorMode(EditorMode.versioning));
    return () => {
      dispatch(setEditorMode(EditorMode.edit));
    };
  }, [dispatch]);

  return (
    <VersionHistoryPane
      versions={versions}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      loadMoreRef={loadMoreRef}
    />
  );
};
