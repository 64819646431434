// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { z } from "zod";

import * as React from "react";

import { isFunction } from "replo-utils/lib/type-check";
import { ReploError } from "schemas/errors";

const canUseStorage = Boolean(
  typeof window !== "undefined" && window.localStorage,
);

class ReploStorageError extends ReploError {}
export class ReploStorageQuotaExceededError extends ReploStorageError {}

/**
 * Return whether an error is an exception that browsers throw when the quota
 * of a local storage is exceeded. Necessary since different browsers implement
 * it differently.
 *
 * See https://mmazzarolo.com/blog/2022-06-25-local-storage-status/
 */
function isQuotaExceededError(err: unknown): boolean {
  return (
    err instanceof DOMException &&
    // everything except Firefox
    (err.code === 22 ||
      // Firefox
      err.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      err.name === "QuotaExceededError" ||
      // Firefox
      err.name === "NS_ERROR_DOM_QUOTA_REACHED")
  );
}

const LocalStorageContext = React.createContext<Storage | null>(null);
LocalStorageContext.displayName = "LocalStorageContext";

/**
 * NOTE (Chance 2023-12-06): Our store reference is just a module-level variable
 * so that it can persist, but we will update it whenever a storage event is
 * fired to ensure components get a new reference when localStorage is mutated.
 * This is 100% fine because we're going to use `useSyncExternalStore` to
 * subscribe and get the updated reference safely in our components.
 */
let store: Storage | null = null;

/**
 * NOTE (Chance 2023-12-06): On the server, we use a mock object so that we
 * don't need to do a bunch of weird checks everywhere before accessing
 * localStorage. This makes is safe to call `storage.getItem` in render.
 */
const mockStorage: Storage = {
  clear: serverMutationWarning,
  getItem: () => null,
  key: () => null,
  removeItem: serverMutationWarning,
  setItem: serverMutationWarning,
  get length() {
    return 0;
  },
};

/**
 * NOTE (Chance 2023-12-06): We only want to subscribe to local storage once,
 * but we want to access it anywhere in the tree. So we expose a provider to
 * prevent the need for a bunch of new listeners and effects when accessed
 * across the app.
 */
export function LocalStorageProvider({ children }: React.PropsWithChildren) {
  const storage = React.useSyncExternalStore(
    subscribeToLocalStorage,
    getLocalStorage,
    getLocalStorageMock,
  );
  return (
    <LocalStorageContext.Provider value={storage}>
      {children}
    </LocalStorageContext.Provider>
  );
}

/**
 * NOTE (Chance 2023-12-06): This hook gives access to the raw localStorage
 * object, except that it is stateful and safe to reference in either a server
 * or client context.
 */
export function useLocalStorage() {
  const storage = React.useContext(LocalStorageContext);
  if (!storage) {
    throw new Error(
      `useLocalStorage must be called within a LocalStorageProvider.`,
    );
  }
  return storage;
}

type StorageHookResult<T> = [T, React.Dispatch<React.SetStateAction<T | null>>];

/**
 * A hook that works exactly like `useState`, except that it pulls/pushes to
 * `localStorage` with the given key.
 *
 * The schema provided is used to infer the type of the state, and also to
 * validate pre-existing values - if a pre-existing value does not parse, the
 * default value will be returned instead.
 *
 * @param key The key to use for the localStorage item. If the key is null,
 * localStorage will not be used. This is useful when you may have some dynamic
 * data that is required before you know the key.
 * ```ts
 * // Good!
 * const [value, setValue] = useLocalStorageState(someCondition ? "key" : null);
 * // Bad! This will set a value to the key "null"
 * const [value, setValue] = useLocalStorageState(`${someCondition ? "key" : null}`);
 * ```
 */
export function useLocalStorageState<ValueType>(
  key: string | null,
  defaultValue: ValueType,
  options: { validate: (value: unknown) => value is ValueType },
): StorageHookResult<ValueType>;

export function useLocalStorageState<ValueType>(
  key: string | null,
  defaultValue: ValueType | null,
  options: { validate: (value: unknown) => value is ValueType },
): StorageHookResult<ValueType | null>;

export function useLocalStorageState<SchemaType extends z.ZodTypeAny>(
  key: string | null,
  defaultValue: z.infer<SchemaType>,
  options: { schema: SchemaType },
): StorageHookResult<z.infer<SchemaType>>;

export function useLocalStorageState<SchemaType extends z.ZodTypeAny>(
  key: string | null,
  defaultValue: z.infer<SchemaType> | null,
  options: { schema: SchemaType },
): StorageHookResult<z.infer<SchemaType> | null>;

export function useLocalStorageState(
  key: string | null,
  defaultValue?: any,
): StorageHookResult<unknown>;

export function useLocalStorageState<SchemaType extends z.ZodTypeAny>(
  key: string | null,
  defaultValue?: z.infer<SchemaType> | null,
  options?:
    | { schema: SchemaType; validate?: never }
    | { schema?: never; validate: <V>(value: unknown) => value is V },
): [
  z.infer<SchemaType>,
  React.Dispatch<React.SetStateAction<z.infer<SchemaType>>>,
] {
  const localStorage = useLocalStorage();
  const rawValue = key != null ? localStorage.getItem(key) : null;
  const { schema, validate } = options ?? {};
  const defaultValueRef = React.useRef(defaultValue);
  React.useInsertionEffect(() => {
    defaultValueRef.current = defaultValue;
  }, [defaultValue]);

  const validatedValue = React.useMemo(() => {
    if (rawValue == null || key == null) {
      return null;
    }
    if (schema) {
      return validateWithSchema(parse(rawValue), schema);
    }
    if (validate) {
      return validateWithValidator(parse(rawValue), validate);
    }
    return parse(rawValue);
  }, [key, rawValue, schema, validate]);

  const setValue = React.useCallback(
    (action: React.SetStateAction<z.TypeOf<SchemaType>>) => {
      if (!canUseStorage) {
        serverMutationWarning();
        return;
      }
      if (key == null) {
        return;
      }

      let currentValue = parse(getStorageItem(key)) ?? defaultValueRef.current;
      if (schema) {
        currentValue = validateWithSchema(currentValue, schema);
      } else if (validate) {
        currentValue = validateWithValidator(currentValue, validate);
      }

      const nextValue = isFunction(action) ? action(currentValue) : action;
      if (Object.is(currentValue, nextValue)) {
        return;
      }

      if (nextValue == null) {
        removeStorageItem(key);
      } else {
        setStorageItem(key, stringify(nextValue, key));
      }
    },
    [key, schema, validate],
  );

  return [validatedValue ?? defaultValue, setValue];
}

function parse(value: any) {
  if (value == null) {
    return null;
  }
  let parsed: unknown;
  try {
    parsed = JSON.parse(value);
  } catch {
    // Note (Noah, 2025-02-22): If the value is not valid json, it could just be a string.
    // That's fine, whatever zod schema you called useLocalStorageState with will validate it
    return value;
  }
  return parsed;
}

function stringify(value: any, key: string) {
  if (value == null) {
    return "null";
  }
  let stringified: string;
  try {
    stringified = JSON.stringify(value);
  } catch {
    console.error("Invalid JSON:", value);
    throw new Error(`Failed to stringify localStorage value for key "${key}".`);
  }
  return stringified;
}

function validateWithValidator<ValueType>(
  value: unknown,
  validator: (value: unknown) => value is ValueType,
): ValueType | null {
  if (validator(value)) {
    return value;
  }
  return null;
}

function validateWithSchema<SchemaType extends z.ZodTypeAny>(
  value: unknown,
  schema: SchemaType,
): z.infer<SchemaType> | null {
  const result = schema.safeParse(value);
  if (result.success) {
    return result.data;
  }
  return null;
}

/**
 * NOTE (Chance 2023-12-06): This is the snapshot function we use to get a
 * storage reference on the client. We don't return localStorage directly here
 * because the reference won't update when storage is mutated, which means our
 * components won't re-render.
 * @see https://react.dev/reference/react/useSyncExternalStore#parameters
 */
function getLocalStorage(): Storage {
  if (!store) {
    store = getNewStorage();
  }
  return store;
}

/**
 * NOTE (Chance 2023-12-06): This is the snapshot function we use to get a mock
 * storage reference on the server. It should always return the same object
 * reference.
 * @see https://react.dev/reference/react/useSyncExternalStore#parameters
 */
function getLocalStorageMock(): Storage {
  return mockStorage;
}

/**
 * NOTE (Chance 2023-12-06): This is the subscribe function that's passed to
 * useSyncExternalStore. This updates our localStorage reference whenever a
 * storage event is fired, and it removes the event listener during the cleanup
 * phase.
 * @see https://react.dev/reference/react/useSyncExternalStore#parameters
 */
function subscribeToLocalStorage(callback: () => void) {
  function handleStorageEvents(
    this: Window,
    event: CustomEvent<ReploStorageEventData>,
  ) {
    if (event.detail.storageArea === window.localStorage) {
      // NOTE (Chance 2023-12-06): It's important to update our store reference
      // before calling the callback so that components subscribe to it will
      // have updated references before rendering.
      store = getNewStorage();
      callback();
    }
  }

  // NOTE (Chance 2023-12-06): We are using a custom event here because we don't
  // want to update our app state when some unrelated local storage changes from
  // outside of the app. Ideally we would do that but only for select keys we
  // know are relevant. This may be done as a follow-up task.
  window.addEventListener("replostorage", handleStorageEvents);
  return () => {
    window.removeEventListener("replostorage", handleStorageEvents);
  };
}

function getNewStorage(): Storage {
  return {
    clear: clearStorage,
    getItem: getStorageItem,
    key: getStorageKeyAtIndex,
    removeItem: removeStorageItem,
    setItem: setStorageItem,
    get length() {
      return localStorage.length;
    },
  };
}

// NOTE (Chance 2023-12-06): The following functions wrap the localStorage
// mutation methods so that we can dispatch a storage event after each mutation.
// This is necessary because the storage event doesn't fire when you mutate
// localStorage from the same document, and sadly/confusingly there's no other
// native event for doing so. They also ensure that references to the storage
// functions are stable between renders, even though the storage object itself
// is updated.
//
// See https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event

function getStorageItem(key: string) {
  return window.localStorage.getItem(key);
}

function getStorageKeyAtIndex(index: number) {
  return window.localStorage.key(index);
}

function clearStorage() {
  const length = window.localStorage.length;
  window.localStorage.clear();
  if (length !== window.localStorage.length) {
    dispatchEvent(
      new CustomEvent<ReploStorageEventData>("replostorage", {
        detail: {
          key: null,
          newValue: null,
          oldValue: null,
          storageArea: window.localStorage,
          url: window.location.href,
        },
      }),
    );
  }
}

export function setStorageItem(key: string, value: any) {
  const oldValue = window.localStorage.getItem(key);
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    if (isQuotaExceededError(error)) {
      throw new ReploStorageQuotaExceededError({
        message: `Storage quota exceeded setting key ${key}`,
      });
    }
    throw error;
  }
  if (oldValue !== window.localStorage.getItem(key)) {
    dispatchEvent(
      new CustomEvent<ReploStorageEventData>("replostorage", {
        detail: {
          key,
          newValue: value,
          oldValue,
          storageArea: window.localStorage,
          url: window.location.href,
        },
      }),
    );
  }
}

function removeStorageItem(key: string) {
  const oldValue = window.localStorage.getItem(key);
  window.localStorage.removeItem(key);
  if (oldValue !== window.localStorage.getItem(key)) {
    dispatchEvent(
      new CustomEvent<ReploStorageEventData>("replostorage", {
        detail: {
          key,
          newValue: null,
          oldValue,
          storageArea: window.localStorage,
          url: window.location.href,
        },
      }),
    );
  }
}

function serverMutationWarning() {
  console.warn("Attempted to set localStorage on the server. This is a no-op.");
}

type ReploStorageEventData = {
  key: string | null;
  newValue: string | null;
  oldValue: string | null;
  storageArea: Storage;
  url: string;
};

interface CustomEventMap {
  replostorage: CustomEvent<ReploStorageEventData>;
}

declare global {
  interface Window {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Window, ev: CustomEventMap[K]) => void,
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Window, ev: CustomEventMap[K]) => void,
    ): void;
  }
}
