import type { DropdownMenuContentProps } from "@radix-ui/react-dropdown-menu";
import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import * as React from "react";

import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { ChevronDown, X } from "lucide-react";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type SelectableProps = {
  id?: string;
  defaultValue?: string;
  value?: string | ReploMixedStyleValue | null;
  options: ComboboxOption[];
  isDisabled?: boolean;
  onSelect(value: string | null): void;
  isOpen?: boolean;
  onOpenChange?(): void;
  layoutClassName?: string;
  onRemove?(): void;
  dropdownAlign?: DropdownMenuContentProps["align"];
  alignOffset?: DropdownMenuContentProps["alignOffset"];
  sideOffset?: DropdownMenuContentProps["sideOffset"];
  size?: "sm" | "base";
  popoverLayoutClassName?: string;
  placeholder?: string;
  ignoreValueMismatchError?: boolean;
  areOptionsSearchable?: boolean;
  triggerStartEnhancer?: React.ReactNode;
  triggerLayoutClassName?: string;
};

const Selectable: React.FC<SelectableProps> = ({
  id,
  placeholder,
  defaultValue = "",
  value: controlledValue,
  options,
  size = "sm",
  isDisabled = false,
  onSelect: onControllableSelect,
  isOpen,
  onOpenChange,
  onRemove,
  dropdownAlign = "center",
  ignoreValueMismatchError = false,
  alignOffset,
  sideOffset = 8,
  areOptionsSearchable,
  triggerStartEnhancer,
  popoverLayoutClassName,
  triggerLayoutClassName,
  layoutClassName,
}) => {
  const [dropdownOpen, setDropdownOpen] = useControllableState(
    isOpen,
    false,
    onOpenChange,
  );
  const [value, setValue] = useControllableState<string | null>(
    !isMixedStyleValue(controlledValue) ? controlledValue : null,
    defaultValue,
    onControllableSelect,
  );

  let boxLabel: string | React.ReactNode;
  if (isMixedStyleValue(controlledValue)) {
    boxLabel = "Mixed";
  } else {
    const selectedOption = options.find((option) => option.value === value);
    boxLabel =
      selectedOption && "displayValue" in selectedOption
        ? selectedOption?.displayValue ?? placeholder
        : selectedOption?.label ?? placeholder;
  }
  function _onOpenChange(checked: boolean) {
    if (!isDisabled) {
      setDropdownOpen(checked);
      onOpenChange?.();
    }
  }

  const valueDoesNotExistInOptions =
    value && !options.some((option) => option.value === value);

  return (
    <Combobox.Root
      options={options}
      open={dropdownOpen}
      onOpenChange={_onOpenChange}
      value={value ?? undefined}
      onChange={(value) => setValue(value)}
      layoutClassName={layoutClassName}
    >
      <Combobox.Trigger>
        {typeof boxLabel === "string" ? (
          <Combobox.SelectionButton
            id={id}
            title={boxLabel}
            isPlaceholder={!value}
            startEnhancer={triggerStartEnhancer}
            titleAlignment="start"
            layoutClassName={triggerLayoutClassName}
            validityState={
              valueDoesNotExistInOptions && !ignoreValueMismatchError
                ? "invalid"
                : undefined
            }
            endEnhancer={
              <div className="flex flex-row gap-1 items-center">
                {onRemove && (
                  <X
                    className="text-muted"
                    size={12}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onRemove();
                    }}
                  />
                )}
                <ChevronDown className="text-muted" size={12} />
              </div>
            }
            size={size}
          />
        ) : (
          boxLabel
        )}
      </Combobox.Trigger>
      <Combobox.Popover
        side="bottom"
        align={dropdownAlign}
        alignOffset={alignOffset}
        sideOffset={sideOffset}
        layoutClassName={popoverLayoutClassName}
      >
        <Combobox.Content areOptionsSearchable={areOptionsSearchable} />
      </Combobox.Popover>
    </Combobox.Root>
  );
};

export default Selectable;
