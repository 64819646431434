import type { Context } from "replo-runtime/store/ReploVariable";
import type { ConditionField, ReploState } from "schemas/generated/symbol";
import type { ProductRef, ProductRefOrDynamic } from "schemas/product";
import type { Operator } from "./enums";
import type { ConditionStatement, ProductIdsWithVariantIds } from "./types";
import type { TempCartItem } from "./utils/temporaryCart";
import type { GlobalWindow } from "./Window";

import get from "lodash-es/get";
import intersection from "lodash-es/intersection";
import { getAbsoluteOffsetY } from "replo-utils/dom/measure";
import {
  exhaustiveSwitch,
  hasOwnProperty,
  isEmpty,
} from "replo-utils/lib/misc";

import { variantIsOnSale } from "../store/utils/variant";
import { ReploCustomEvents } from "./enums";
import { resolveContextValue } from "./utils/context";
import { isDefaultVariant, isHoverVariant } from "./variant";

type GetEventListenerTarget = (args: {
  ref: React.RefObject<HTMLElement>;
  globalWindow: GlobalWindow | null;
}) => Window | Document | HTMLElement | null;
type ConditionFieldRenderData = {
  trigger:
    | {
        type: "eventListener";
        getEventListenerTarget: GetEventListenerTarget;
        eventListenerType: string;
      }
    | { type: "hover"; getEventListenerTarget: GetEventListenerTarget }
    | { type: "loading"; getEventListenerTarget: GetEventListenerTarget }
    | { type: "none" };
  isTrue(args: {
    statement: ConditionStatement;
    event: Event | null;
    context: Context;
    ref: React.RefObject<HTMLElement>;
    isInitialHydration: boolean;
    globalWindow: GlobalWindow | null;
  }): boolean;

  /**
   * If this is true, we force remount those components who use this as a state
   * condition (for example, when products were in stock at the time of publish,
   * but out of stock at the time of hydration).
   *
   * TODO (Noah, 2023-05-29, REPL-7463): This is used to make sure that states
   * like hashmarks and out-of-stock (which depend on product data) are resolved
   * at the time of initial hydration. I'm pretty sure this is required for
   * product data, but for hashmarks I think we should be able to use
   * isInitialHydration here
   */
  shouldForceRemount: boolean;

  /**
   * Passing true here indicates that this condition depends on client-side state
   * that may not be accessible at render time, like scroll position. This is used
   * to ensure that components with states that depend on client state will be
   * able to rerender based on the current client state at hydration-time.
   */
  dependsOnClientState: boolean;

  /**
   * Passing true here indicates that this condition depends on the injected
   * product template which cannot be known when we build the bundle. Any
   * components that have this condition set to true in a product template will
   * be forced to remount post hydration. This enables certain things like
   * selected styles to be quickly updated after hydration.
   */
  dependsOnProductTemplate: boolean;
};

const conditionFieldToRenderData: Record<
  ConditionField,
  ConditionFieldRenderData
> = {
  "screen.pageY": {
    trigger: {
      type: "eventListener",
      getEventListenerTarget: ({ globalWindow }) => {
        return globalWindow?.document ?? null;
      },
      eventListenerType: "scroll",
    },
    shouldForceRemount: false,
    dependsOnClientState: true,
    dependsOnProductTemplate: false,
    isTrue: ({ statement, isInitialHydration, globalWindow }) => {
      const goal = Number.parseInt(statement.value);
      return checkStatement(
        // Note (Noah, 2023-05-29): If we're on the initial hydration, pretend
        // like the page y offset is 0 (as it would be in SSR)
        isInitialHydration ? 0 : globalWindow?.scrollY ?? 0,
        statement.operator ?? null,
        goal,
      );
    },
  },
  "element.offsetY": {
    trigger: {
      type: "eventListener",
      getEventListenerTarget: ({ globalWindow }) => {
        return globalWindow?.document ?? null;
      },
      eventListenerType: "scroll",
    },
    shouldForceRemount: false,
    dependsOnClientState: true,
    dependsOnProductTemplate: false,
    isTrue: ({ statement, ref, globalWindow, isInitialHydration }) => {
      const goal = Number.parseInt(statement.value);

      const currentElement = ref.current;
      if (!currentElement) {
        return false;
      }

      // Note (Noah, 2023-05-29): If we're on the initial hydration, pretend
      // like the page y offset is 0 (as it would be in SSR)
      const pageYOffset = isInitialHydration ? 0 : globalWindow?.scrollY ?? 0;

      const distanceToViewportTop =
        getAbsoluteOffsetY(currentElement) - pageYOffset;

      return checkStatement(
        distanceToViewportTop,
        statement.operator ?? null,
        goal,
      );
    },
  },
  "interaction.clickEvent": {
    trigger: {
      type: "eventListener",
      getEventListenerTarget: ({ ref }) => {
        return ref?.current ?? null;
      },
      eventListenerType: "click",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ event }) => {
      return event?.type === "click";
    },
  },
  "state.product.noProductVariantSelected": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      const selectedVariant = context.state?.product?.selectedVariant;
      // NOTE (Chance 2024-02-09, USE-728): Because this context is created via
      // `mergeContext`, it's possible that even if the selected variant is
      // `undefined` that its value ends up being an empty object because of our
      // gnarly merging logic. So we need to check for that to ensure this state
      // actually works.
      const noProductVariantSelected =
        selectedVariant == null || Object.keys(selectedVariant).length === 0;
      return noProductVariantSelected;
    },
  },
  "state.product.quantity": {
    trigger: { type: "none" },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ statement, context }) => {
      const goal = Number(Number.parseInt(statement.value));

      return checkStatement(
        context.state?.product?.quantity ?? 1,
        statement.operator ?? null,
        goal,
      );
    },
  },
  "page.hashmark": {
    trigger: {
      type: "eventListener",
      getEventListenerTarget: ({ globalWindow }) => {
        return globalWindow ?? null;
      },
      eventListenerType: "hashchange",
    },
    shouldForceRemount: true,
    // TODO (Noah, 2023-05-29, REPL-7463): Once we get rid of shouldForceRemount,
    // this can be true
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ statement, globalWindow }) => {
      return globalWindow?.location.hash === `#${statement.value}`;
    },
  },
  "interaction.hover": {
    trigger: {
      type: "hover",
      getEventListenerTarget: ({ ref }) => {
        return ref?.current ?? null;
      },
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ event }) => {
      return event?.type === "mouseover" || event?.type === "touchstart";
    },
  },
  "state.action.loading": {
    trigger: {
      type: "loading",
      getEventListenerTarget: ({ ref }) => {
        return ref?.current ?? null;
      },
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ event }) => {
      return event?.type === ReploCustomEvents.ActionStarted;
    },
  },
  "collectionSelect.item": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ statement, context }) => {
      return context.state.collectionSelect?.currentIndex === statement.value;
    },
  },
  "state.carouselV2.isCurrentIndicatorItem": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return (
        context.state.carouselV2?.currentIndicatorIndex ===
        context.state.carouselV2?.activeIndex
      );
    },
  },
  "state.carouselV2.isAtStart": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.state.carouselV2?.activeIndex === 0;
    },
  },
  "state.carouselV2.isAtEnd": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return (
        context.state.carouselV2?.activeIndex ===
        context.state.carouselV2?.items?.length - 1
      );
    },
  },
  "state.dropdown.selectedItem": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.state?.dropdown?.isSelected;
    },
  },
  "state.product.selectedVariant": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ context }) => {
      return context.state?.variantSelect?.isSelected;
    },
  },
  product_variant_is_on_sale: {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      const selectedVariant = context.state?.product?.selectedVariant;
      if (!selectedVariant) {
        return false;
      }
      return variantIsOnSale(selectedVariant);
    },
  },
  "state.product.selectedOptionValues": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ context }) => {
      return context.state?.optionSelect?.isSelected;
    },
  },
  "state.product.selectedSellingPlan": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ context }) => {
      return context.state?.sellingPlanSelect?.isSelected;
    },
  },
  "state.product.isOptionValueUnavailable": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ context }) => {
      return context.state?.optionSelect?.isOptionValueUnavailable;
    },
  },
  "state.product.isVariantUnavailable": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ context }) => {
      return context.state?.variantSelect?.isVariantUnavailable;
    },
  },
  "state.product.currentVariantIsOnSale": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ context }) => {
      return context.state?.variantSelect?.isVariantOnSale;
    },
  },
  "state.product.selectedVariantUnavailable": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.state?.product?.selectedVariant?.available === false;
    },
  },
  "state.collapsibleV2.isOpen": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.state?.collapsibleV2?.isOpen;
    },
  },
  "state.tabsBlock.isCurrentTab": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return (
        context.state?.tabsBlock?.currentTabsListItem?.id ===
        context.state?.tabsBlock?.activeTabId
      );
    },
  },
  "state.tabsV2Block.isCurrentTab": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return (
        context.state?.tabsV2Block?.currentTabsListItem?.id ===
          context.state?.tabsV2Block?.activeTabId &&
        context.state?.tabsV2Block?.currentTabIndex ===
          context.state?.tabsV2Block?.activeTabIndex
      );
    },
  },
  "state.selectionList.isItemSelected": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.state?.selectionList?.isItemSelected === true;
    },
  },
  "state.temporaryCart.numberOfItems": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ statement, context }) => {
      const goal = Number(Number.parseInt(statement.value));
      const items: TempCartItem[] = context.state?.temporaryCart?.items || [];
      const totalCount = items.reduce(
        (previousValue, item) =>
          previousValue + (item.productRef?.quantity || 1),
        0,
      );
      return checkStatement(totalCount, statement.operator ?? null, goal);
    },
  },
  "state.temporaryCart.containsVariant": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ statement, context }) => {
      const items = context.state?.temporaryCart?.items || [];
      return items.some((item: { productRef: ProductRef }) => {
        const product = resolveContextValue(
          statement.value,
          context,
        ) as ProductRef;
        if (!product || !item.productRef) {
          return false;
        }
        return (
          String(product.productId) === String(item.productRef.productId) &&
          product.variantId === item.productRef?.variantId
        );
      });
    },
  },
  "state.group.isCurrentItemActive": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ context }) => {
      return context.group?.isActiveItem ?? false;
    },
  },
  "state.group.isFirstItemActive": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.group?.isFirstItem ?? false;
    },
  },
  "state.group.isLastItemActive": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.group?.isLastItem ?? false;
    },
  },
  "state.product.templateProductEquals": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: true,
    dependsOnClientState: true,
    dependsOnProductTemplate: true,
    isTrue: ({ statement, context }) => {
      const templateProduct = context.attributes?._templateProduct as
        | ProductRef
        | undefined;
      const productsFromStatement = statement.value as
        | ProductRefOrDynamic[]
        | null
        | undefined;

      // Note (Evan, 2023-11-01): If the statement doesn't have any products, this should be a no-op.
      if (templateProduct && !isEmpty(productsFromStatement)) {
        // Note (Evan, 2023-11-01): We check if the template product matches any of the
        // product refs from the statement. Then, if the operator is "equals", we return
        // whether there is a match. If the operator is "not equals", we return the inverse.
        const templateProductMatchesStatementProducts =
          productsFromStatement.some((productFromStatement) => {
            const resolvedStatementProduct = resolveContextValue(
              productFromStatement,
              context,
            );
            return Boolean(
              resolvedStatementProduct &&
                Number(templateProduct.productId) ===
                  Number(resolvedStatementProduct.productId),
            );
          });

        if (statement.operator === "eq") {
          return templateProductMatchesStatementProducts;
        }

        if (statement.operator === "neq") {
          return !templateProductMatchesStatementProducts;
        }
      }
      return false;
    },
  },
  "state.product.selectedProductEquals": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ statement, context }) => {
      const productMapping: ProductRef[] = statement.value ?? [];
      const selectedProductId = context.state?.product?.product?.id;

      if (!selectedProductId) {
        return false;
      }

      if (statement.operator === "eq") {
        return productMapping.some(
          (productRef) =>
            String(productRef.productId) === String(selectedProductId),
        );
      }
      if (statement.operator === "neq") {
        return productMapping.every(
          (productRef) =>
            String(productRef.productId) !== String(selectedProductId),
        );
      }
      return false;
    },
  },
  "state.product.selectedVariantEquals": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: true,
    isTrue: ({ statement, context }) => {
      const productIdToVariantMapping: ProductIdsWithVariantIds =
        statement.value ?? {};
      const selectedVariant = context.state?.product?.selectedVariant;
      let variantIsSelected = false;
      if (
        selectedVariant &&
        hasOwnProperty(
          productIdToVariantMapping,
          selectedVariant.productId.toString(),
        )
      ) {
        const variantIds =
          productIdToVariantMapping[selectedVariant.productId.toString()];
        // NOTE (Matt 2024-06-17): We set 'variantIsSelected' to true here if variantIds is null because this
        // means that the user has selected all variants for a product (resulting in `productIdToVariantMapping`
        // to include the productId as a key with a null value).
        variantIsSelected =
          variantIds?.some((id) => id === selectedVariant.id.toString()) ??
          true;
      }
      if (statement.operator === "eq") {
        return variantIsSelected;
      }
      if (statement.operator === "neq") {
        return !variantIsSelected;
      }
      return false;
    },
  },
  "state.beforeAfterSlider.isDragging": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.state?.beforeAfterSlider?.isDragging;
    },
  },
  "state.tooltip.isOpen": {
    trigger: {
      type: "none",
    },
    shouldForceRemount: false,
    dependsOnClientState: false,
    dependsOnProductTemplate: false,
    isTrue: ({ context }) => {
      return context.state?.tooltip?.isOpen;
    },
  },
};

export const getConditionFieldRenderData = (field: ConditionField) => {
  return conditionFieldToRenderData[field];
};

/**
 * Given a list of variants, finds the one which is currently active.
 *
 * @param config.variants List of variants to check
 * @param config.event Event which triggered the check, if any (for initial
 * variant selection, this is always null)
 * @param config.componentContext Context of the current component (used for
 * evaluating dynamic data, etc)
 * @param config.ref Ref to the element of the component whose variants we're
 * checking
 * @param config.isInitialHydration Pass true to indicate that all client-side
 * state should be assumed to be the default which is used in SSR. This is
 * important for states which depend on client-side events, like scroll
 * position, in order to ensure that we produce a diff in the virtual DOM
 * between initial hydration and the initial check of the variant conditions.
 * (If we didn't do this, we'd get into situations where e.g. the scrollPosition
 * on initial page load might be a significant amount down the page, but from
 * the point of view of React's virtual DOM it's always been this way, so it
 * wouldn't produce a diff and we wouldn't rerender the component to show the
 * new state).
 * @param config.skip List of variant ids to skip over (and not use)
 */
export function findVariantWithTrueCondition(config: {
  variants: ReploState[] | null;
  event: Event | null;
  componentContext: Context;
  ref: React.RefObject<HTMLElement>;
  isInitialHydration: boolean;
  skip: string[];
  isPreviewMode: boolean;
  isEditorApp: boolean;
  globalWindow: GlobalWindow | null;
}) {
  const {
    variants,
    event,
    componentContext,
    ref,
    isInitialHydration,
    skip,
    isPreviewMode,
    globalWindow,
    isEditorApp,
  } = config;
  if (!variants) {
    return null;
  }

  let defaultVariantId: string | null = null;
  for (const variant of variants) {
    /* Skip the default variant for now but keep it in your back pocket */
    if (variant.name === "default") {
      defaultVariantId = variant.id;
    }

    const statements: ConditionStatement[] = get(
      variant,
      "query.statements",
      [],
    );

    for (const statement of statements) {
      const data =
        conditionFieldToRenderData[statement.field as ConditionField];
      if (data) {
        const { isTrue } = data;
        if (
          isTrue({
            context: componentContext,
            event,
            globalWindow,
            isInitialHydration,
            ref,
            statement,
          }) &&
          !skip.includes(variant.id) &&
          // NOTE (Fran 2024-03-28): If the user is in the editor, we should not check if the condition
          // is true, we should always show the selected state.
          (isPreviewMode || !isEditorApp)
        ) {
          return variant;
        }
      }
    }
  }

  /* If you don't find anything, return the default */
  return variants.find((v) => v.id === defaultVariantId);
}

// See comments in `onEventListener` about these flags
let touchendFired = false;
let wasHovered = false;

/**
 * This is pretty naively implemented rn
 */
export const initVariantConditions = (args: {
  ref: React.RefObject<HTMLElement>;
  variants: ReploState[];
  context: Context;
  isPreviewMode: boolean;
  isEditorApp: boolean;
  onChange: (variantId: string) => void;
  globalWindow: GlobalWindow | null;
}): (() => void) => {
  const {
    ref,
    variants,
    context,
    onChange,
    globalWindow,
    isPreviewMode,
    isEditorApp,
  } = args;
  const conditionFields: Record<string, boolean> = {};
  const listeners: {
    node: HTMLElement | Window | Document | null;
    type: string;
    listenerFunction: (e: Event) => void;
  }[] = [];

  const timeouts: NodeJS.Timeout[] = [];
  // Get all the different types of fields from each variant
  for (const variant of variants) {
    const statements: ConditionStatement[] = get(
      variant,
      "query.statements",
      [],
    );
    for (const statement of statements) {
      conditionFields[statement.field] = true;
    }
  }

  // Dedupe all the different condition fields so we don't redundantly create
  const deduped: string[] = Object.keys(conditionFields);
  for (const conditionField of deduped) {
    const renderData =
      conditionFieldToRenderData[conditionField as ConditionField];
    if (renderData == null) {
      continue;
    }

    exhaustiveSwitch(renderData.trigger)({
      eventListener: ({ getEventListenerTarget, eventListenerType }) => {
        const eventListenerTarget = getEventListenerTarget({
          ref,
          globalWindow,
        });
        listeners.push({
          node: eventListenerTarget,
          type: eventListenerType,
          listenerFunction: onEventListener,
        });
      },
      hover: ({ getEventListenerTarget }) => {
        const eventListenerTarget = getEventListenerTarget({
          ref,
          globalWindow,
        });
        // Note (Chance: 2023-06-02) We originally used `mouseover` and
        // `mouseout` event listeners to trigger hover variants. This is
        // problematic because both of these events bubble up the DOM tree.
        //
        // By listening for `mouseout` or `mouseover`, when a user's mouse
        // enters and then exits a descendant element, the event bubbles up and
        // triggers the listener on the parent. This means that if `mouseout`
        // triggers a style change, as it does when used for hover variants, the
        // user might see a flash of those changes as they interact with the
        // hovered element's children. I say "might" because in most cases both
        // mouseover and mouseout listeners are executed before a repaint.
        //
        // This does not seem to be the case in USE-209, so the user sees the
        // flickering style changes applied by both mouse events in rapid
        // succession. Using `mouseleave`—which doesn't bubble to child
        // elements—prevents this. It also tracks more closely with the behavior
        // of the CSS `:hover` pseudo in that an element should never exit the
        // hover state while the pointer is still inside an element or its
        // descendants.
        listeners.push(
          {
            node: eventListenerTarget,
            type: "mouseover",
            listenerFunction: onEventListener,
          },
          {
            node: eventListenerTarget,
            type: "mouseleave",
            listenerFunction: onEventListener,
          },
          {
            node: eventListenerTarget,
            type: "touchend",
            listenerFunction: onEventListener,
          },
          {
            node: eventListenerTarget,
            type: "touchstart",
            listenerFunction: onEventListener,
          },
          {
            node: eventListenerTarget,
            type: "mouseup",
            listenerFunction: onEventListener,
          },
          {
            node: eventListenerTarget,
            type: "mouseup",
            /**
             * NOTE (Max, 2024-12-16):
             * We're adding a mouseup listener to check if the user's cursor
             * is within the bounds of the parent element when the mouseup event fires.
             *
             * This is necessary e.g. if the hover state is applied to a component
             * inside a Collapsible, & clicking the Collapsible wouldn't trigger
             * a mouseout event -> so we're instead checking if the user's cursor
             * is outside the parent element (which it would, in the case of the
             * Collapsible)
             */
            listenerFunction: (event: Event) => {
              const timeoutId = setTimeout(() => {
                const rect = (
                  event.target as HTMLElement
                )?.getBoundingClientRect();
                if (rect) {
                  const mouseEvent = event as MouseEvent;
                  const isWithinBounds =
                    mouseEvent.clientX >= rect.left &&
                    mouseEvent.clientX <= rect.right &&
                    mouseEvent.clientY >= rect.top &&
                    mouseEvent.clientY <= rect.bottom;

                  if (!isWithinBounds) {
                    const variant = findVariantWithTrueCondition({
                      variants,
                      event,
                      componentContext: context,
                      ref,
                      isInitialHydration: false,
                      skip: [],
                      isPreviewMode,
                      globalWindow,
                      isEditorApp,
                    });
                    if (variant == null) {
                      return;
                    }
                    onChange(variant.id);
                  }
                }
              }, 0);
              timeouts.push(timeoutId);
            },
          },
        );
      },
      loading: ({ getEventListenerTarget }) => {
        const eventListenerTarget = getEventListenerTarget({
          ref,
          globalWindow,
        });
        listeners.push(
          {
            node: eventListenerTarget,
            type: ReploCustomEvents.ActionStarted,
            listenerFunction: onEventListener,
          },
          {
            node: eventListenerTarget,
            type: ReploCustomEvents.ActionEnded,
            listenerFunction: onEventListener,
          },
        );
      },
      none: () => null,
    });
  }

  for (const listener of listeners) {
    listener.node?.addEventListener(listener.type, listener.listenerFunction);
  }

  return function cleanup() {
    for (const listener of listeners) {
      listener.node?.removeEventListener(
        listener.type,
        listener.listenerFunction,
      );
    }

    timeouts.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
  };

  function onEventListener(event: Event) {
    const variant = findVariantWithTrueCondition({
      variants,
      event,
      componentContext: context,
      ref,
      isInitialHydration: false,
      skip: [],
      isPreviewMode,
      globalWindow,
      isEditorApp,
    });

    if (variant == null) {
      return;
    }

    // NOTE (Chance 2024-03-21, USE-826): We consider a touch-device user
    // touching an element to trigger a hover variant. But we don't want the
    // variant to persist after they stop touching. On touch devices, browsers
    // will fire simulated mouse events as well as touch events, and we don't
    // want those event re-triggering the state after the user has stopped
    // touching. We also want to make sure mouseup doesn't release the hover
    // variant on non-touch devices.
    //
    // To deal with this, we need to do a little coordination between the events
    // for hover variants. First we need to mark that a user has triggered a
    // hover variant. If we were previously hovered and the variant here is the
    // default, we know we're dealing with the event that "released" hovering.
    // If this was a touch event, we can set a flag we can use so know in
    // mouseup events whether or not it fired as a result of a touch.
    if (isHoverVariant(variant)) {
      wasHovered = true;
    } else if (isDefaultVariant(variant) && wasHovered) {
      if (event.type === "touchend") {
        // On touch devices, touchend fires before mouseup. Setting this flag on
        // touchend so we can check it on mouseup, which should always fire well
        // before the timeout resets it.
        touchendFired = true;
        setTimeout(() => {
          touchendFired = false;
        }, 100);
      } else if (event.type === "mouseup" && !touchendFired) {
        // bail here because if mouseup is firing and touchend hasn't, user is
        // not on a touch device and we consider them still hovering
        return;
      }
      wasHovered = false;
    }

    onChange(variant.id);
  }
};

const checkStatement = (
  testValue: any,
  op: Operator | null,
  goalValue: any,
) => {
  if (!op) {
    return true;
  }
  if (
    ["gte", "gt", "eq", "lt", "lte", "neq"].includes(op) &&
    !Number.isNaN(Number.parseInt(testValue))
  ) {
    const [n1, n2] = [Number(testValue), Number(goalValue)];
    if (op === "gte") {
      return n1 >= n2;
    }
    if (op === "gt") {
      return n1 > n2;
    }
    if (op === "eq") {
      return n1 === n2;
    }
    if (op === "lte") {
      return n1 <= n2;
    }
    if (op === "lt") {
      return n1 < n2;
    }
    if (op === "neq") {
      return n1 !== n2;
    }
  }
  if (["includes", "excludes"].includes(op)) {
    if (op === "includes") {
      return intersection(testValue, goalValue)?.length > 0;
    }
    if (op === "excludes") {
      return intersection(testValue, goalValue)?.length === 0;
    }
  }
  if (["eq", "neq"].includes(op) && Number.isNaN(Number.parseInt(testValue))) {
    if (op === "eq") {
      return testValue === goalValue;
    }
    if (op === "neq") {
      return testValue !== goalValue;
    }
  }
  return false;
};
