import type { PluginImageData } from "@editor/utils/copyPaste";

import * as React from "react";

import { useUploadAsset } from "@/features/assets/useUploadAsset";
import { slugify } from "replo-utils/lib/string";
import { v4 as uuidv4 } from "uuid";

const convertImageDataURL = (image: PluginImageData) => {
  const parts = image.dataURL.split(",");
  const mimetype =
    parts[0]?.match(/:(.*?);/)?.[1] || "application/octet-stream";
  if (parts[1]) {
    const byteString = atob(parts[1]);
    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }
    return { mimetype, byteArray: new Uint8Array(byteNumbers) };
  }
  return null;
};

export const useUploadFigmaImage = () => {
  const { uploadAsset } = useUploadAsset();

  const uploadFigmaImage = React.useCallback(
    async (
      image: PluginImageData,
    ): Promise<{ url: string; nodeId: string } | null> => {
      const convertedImage = convertImageDataURL(image);
      if (!convertedImage) {
        return null;
      }
      const filename = slugify(`${image.name}-${image.id}`);
      const blob = new Blob([convertedImage.byteArray], {
        type: convertedImage.mimetype,
      });
      const file = new File(
        [blob],
        `${filename}.${convertedImage.mimetype.split("/")[1]}`,
        {
          type: convertedImage.mimetype,
        },
      );
      try {
        const url = await uploadAsset({
          file,
          assetId: uuidv4(),
        });
        return { url, nodeId: image.id };
      } catch {
        return null;
      }
    },
    [uploadAsset],
  );

  return { uploadFigmaImage };
};
