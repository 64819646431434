import type { ReploAnnouncement } from "schemas/generated/announcement";

import * as React from "react";
import { useEffect, useState } from "react";

import DashboardMenuItem from "@editor/components/dashboard/DashboardMenuItem";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { LeftPanelMenuButton } from "@/editor/components/left-bar/LeftBar";
import AnnouncementsMenuContent from "@/features/announcements/AnnouncementsMenuContent";
import { useListAnnouncements } from "@/features/announcements/hooks/useListAnnouncements";
import Popover from "@replo/design-system/components/popover/Popover";
import twMerge from "@replo/design-system/utils/twMerge";
import { Megaphone } from "lucide-react";

const MAX_ANNOUNCEMENT_HISTORY = 10;

const AnnouncementsMenu = ({ source }: { source: "editor" | "dashboard" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [seenAnnouncementIds, setSeenAnnouncementIds] = useLocalStorageState<
    string[]
  >("replo.announcements.menu.seenAnnouncementIds", [], {
    validate: (value): value is string[] =>
      Array.isArray(value) && value.every((item) => typeof item === "string"),
  });
  const logEvent = useLogAnalytics();
  const [selectedAnnouncement, setSelectedAnnouncement] =
    React.useState<ReploAnnouncement | null>(null);

  const { announcements, isLoading } = useListAnnouncements({
    limit: 4,
  });

  // Note (Ben O, 03-14-2025): We store the last seen announcement menu id in local storage
  // to determine if we should open the popover automatically on load.

  // Effect to update the seen announcements when the menu is open
  useEffect(() => {
    if (isOpen && !isLoading && announcements.length > 0) {
      let newSeenIds = [...seenAnnouncementIds];
      let hasNewIds = false;

      announcements.forEach((announcement) => {
        if (!newSeenIds.includes(announcement.id)) {
          newSeenIds = newSeenIds.filter((id) => id !== announcement.id);
          newSeenIds.push(announcement.id);
          hasNewIds = true;
        }
      });

      if (newSeenIds.length > MAX_ANNOUNCEMENT_HISTORY) {
        newSeenIds = newSeenIds.slice(-MAX_ANNOUNCEMENT_HISTORY);
        hasNewIds = true;
      }

      if (hasNewIds) {
        setSeenAnnouncementIds(newSeenIds);
      }
    }
  }, [
    isOpen,
    announcements,
    seenAnnouncementIds,
    setSeenAnnouncementIds,
    isLoading,
  ]);

  // Effect to automatically open the menu when there's a new announcement
  useEffect(() => {
    if (!isOpen && !isLoading && announcements.length > 0) {
      const hasNewAnnouncement = announcements.some(
        (announcement) => !seenAnnouncementIds.includes(announcement.id),
      );

      if (hasNewAnnouncement) {
        setIsOpen(true);
      }
    }
  }, [announcements, isLoading, seenAnnouncementIds, isOpen]);

  const handleOpen = () => {
    setIsOpen(true);
    logEvent("announcements.menu.opened", {});
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      title={selectedAnnouncement ? null : "What's New"}
      side="top"
      align="start"
      className={twMerge(
        "w-[300px] max-h-[375px] overflow-hidden",
        selectedAnnouncement ? "p-0" : "p-3",
      )}
      shouldPreventDefaultOnInteractOutside={false}
      hideCloseButton={Boolean(selectedAnnouncement)}
      content={
        <AnnouncementsMenuContent
          announcements={announcements}
          isLoading={isLoading}
          handleClose={() => setIsOpen(false)}
          selectedAnnouncement={selectedAnnouncement}
          setSelectedAnnouncement={setSelectedAnnouncement}
        />
      }
    >
      <div className="w-full">
        {source === "dashboard" ? (
          <DashboardMenuItem
            item={{
              title: "What's New",
              startEnhancer: <Megaphone size={16} />,
              onClick: handleOpen,
              className: "w-full",
            }}
          />
        ) : (
          <LeftPanelMenuButton
            label={isOpen ? null : "Announcements"}
            ariaLabel="Announcements"
            onClick={handleOpen}
            isActive={isOpen}
          >
            <Megaphone size={20} className={isOpen ? "" : "text-muted"} />
          </LeftPanelMenuButton>
        )}
      </div>
    </Popover>
  );
};

export default AnnouncementsMenu;
