import type { PageLayoutType } from "@editor/utils/element";
import type {
  ReploElement,
  ReploPartialElement,
} from "schemas/generated/element";

import * as React from "react";

import { elementTypeToEditorData } from "@components/editor/element";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";

import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import twMerge from "@replo/design-system/utils/twMerge";

import { ElementEditorLayoutButton } from "./ElementEditorLayoutButton";

/**
 * Helper function to get page current layout.
 */
function getCurrentLayout({
  hideDefaultHeader,
  hideDefaultFooter,
}: ReploElement | ReploPartialElement): PageLayoutType {
  if (hideDefaultHeader && hideDefaultFooter) {
    return "no-header-footer";
  }
  if (hideDefaultFooter && !hideDefaultHeader) {
    return "no-footer";
  }
  if (!hideDefaultFooter && hideDefaultHeader) {
    return "no-header";
  }
  return "default";
}

export const ShopifyThemeSettings: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const layout = getCurrentLayout(element);

  return (
    <div className={twMerge("flex flex-col gap-y-4", className)}>
      <SwitchWithDescription
        label="Show Announcement Bar"
        description={`Shows Shopify announcement bar on this ${elementTypeToEditorData[element.type].singularDisplayName.toLowerCase()}.`}
        size="sm"
        variant="card"
        isOn={!element.hideShopifyAnnouncementBar}
        onChange={(shouldShow) => {
          onChangeElement({
            ...element,
            hideShopifyAnnouncementBar: !shouldShow,
          });
        }}
      />
      {element.type === "shopifyProductTemplate" && (
        <SwitchWithDescription
          label="Include Product Schema"
          description="Structured product data will be added to enhance search results."
          size="sm"
          isOn={element.includeProductSchema ?? false}
          onChange={(includeProductSchema) => {
            onChangeElement({ ...element, includeProductSchema });
          }}
          variant="card"
        />
      )}
      <ul className="grid grid-cols-2 gap-2 text-muted">
        <li>
          <ElementEditorLayoutButton
            label="Hide Header/Footer"
            isActive={layout === "no-header-footer"}
            onClick={() =>
              onChangeElement({
                ...element,
                hideDefaultHeader: true,
                hideDefaultFooter: true,
              })
            }
          />
        </li>
        <li>
          <ElementEditorLayoutButton
            label="Show Header Only"
            isActive={layout === "no-footer"}
            onClick={() =>
              onChangeElement({
                ...element,
                hideDefaultHeader: false,
                hideDefaultFooter: true,
              })
            }
          >
            <span className="h-[15%] w-full rounded bg-slate-300" />
          </ElementEditorLayoutButton>
        </li>
        <li>
          <ElementEditorLayoutButton
            label="Show Footer Only"
            isActive={layout === "no-header"}
            onClick={() =>
              onChangeElement({
                ...element,
                hideDefaultHeader: true,
                hideDefaultFooter: false,
              })
            }
            className="flex-col-reverse"
          >
            <span className="h-[15%] w-full rounded bg-slate-300" />
          </ElementEditorLayoutButton>
        </li>
        <li>
          <ElementEditorLayoutButton
            label="Show Header/Footer"
            isActive={layout === "default"}
            onClick={() =>
              onChangeElement({
                ...element,
                hideDefaultHeader: false,
                hideDefaultFooter: false,
              })
            }
          >
            <span className="h-[15%] w-full rounded bg-slate-300" />
            <span className="h-[15%] w-full rounded bg-slate-300" />
          </ElementEditorLayoutButton>
        </li>
      </ul>
    </div>
  );
};
