import type { ReploElementVersionKind } from "schemas/element";
import type { ReploElementVersionRevision } from "schemas/generated/element";

import * as React from "react";

import Avatar from "@common/designSystem/Avatar";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  restoreElementRevision,
  selectDraftElementId,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { Badge } from "@replo/design-system/components/badge/Badge";
import Button from "@replo/design-system/components/button/Button";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import {
  differenceInCalendarWeeks,
  format,
  formatDistanceToNowStrict,
} from "date-fns";
import { Clock, CopyX, Save, Send, SkipBack } from "lucide-react";
import { BsInfoCircle } from "react-icons/bs";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { ReploElementVersionKinds } from "schemas/element";

type CardProps = {
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  className?: string;
  isActive: boolean;
  version: ReploElementVersionRevision;
  isDateOnly?: boolean;
};

export const formatVersionHistoryDateAndTime = (date: string) => {
  const dateObj = new Date(date);
  if (differenceInCalendarWeeks(dateObj, new Date()) < -1) {
    return format(dateObj, "MMMM d, hh:mma");
  }
  return `${formatDistanceToNowStrict(dateObj)} ago`;
};

const getVersionTitle = (version: ReploElementVersionRevision) => {
  return exhaustiveSwitch({ type: version.kind })({
    automatic: () => "Autosave",
    automaticElementOutdated: () => "Autosave",
    publish: () => "Published Page",
    unpublish: () => "Unpublished",
    revert: () => "Restored to Past Version",
    current: () => version.title,
  });
};

const VersionIcon = ({
  kind,
  isActive,
}: {
  kind: ReploElementVersionKind;
  isActive: boolean;
}) => {
  const icon = exhaustiveSwitch({ type: kind })({
    current: <Clock strokeWidth={1} size={12} />,
    automatic: <Save strokeWidth={1} size={12} />,
    automaticElementOutdated: <Save strokeWidth={1} size={12} />,
    publish: <Send strokeWidth={1} size={12} />,
    unpublish: <CopyX strokeWidth={1} size={12} />,
    revert: <SkipBack strokeWidth={1} size={12} />,
  });
  return (
    <Badge
      UNSAFE_className={twMerge(
        "bg-slate-100 h-5 w-5",
        isActive && "text-blue-600 bg-blue-50",
      )}
      type="icon"
      icon={icon}
    />
  );
};

export default React.forwardRef<HTMLDivElement, CardProps>(function VersionCard(
  { className, isActive, onClick, version, isDateOnly }: CardProps,
  ref,
) {
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const elementId = useEditorSelector(selectDraftElementId);
  const { createdBy, createdAt, kind, revertedTo } = version;
  const title = getVersionTitle(version);
  const date =
    kind === ReploElementVersionKinds.current
      ? "Now"
      : formatVersionHistoryDateAndTime(createdAt);
  const userName = createdBy?.name ?? createdBy?.email;
  const defaultClassNames = twMerge(
    "flex items-start flex-row pb-6 cursor-pointer w-full",
    isDateOnly && "pl-5",
    className,
  );

  const formattedTitle = isDateOnly
    ? format(new Date(createdAt), "MMMM d, hh:mma")
    : title;

  const showRestoreButton = isActive && version.kind !== "current";
  const utils = trpc.useUtils();
  const {
    mutateAsync: revertElementVersion,
    isPending: isRestoreRevisionLoading,
  } = trpc.element.revertToRevision.useMutation({
    onSuccess: (data) => {
      void utils.element.findRevisions.invalidate({ elementId });
      dispatch(restoreElementRevision(data.element));
    },
  });
  return (
    <div className={defaultClassNames} onClick={onClick} ref={ref}>
      {!isDateOnly && (
        <div className="z-[1]">
          <VersionIcon isActive={isActive} kind={version.kind} />
        </div>
      )}
      <div
        className={twMerge(
          "flex flex-col ml-1.5 gap-1 p-1.5 pt-1 rounded grow corner-[6px]",
          isActive ? "bg-selectable-selected" : "bg-white hover:bg-slate-50",
        )}
      >
        <h3 className="truncate typ-header-small font-semibold text-default">
          {revertedTo ? (
            <Tooltip
              triggerAsChild
              sideOffset={5}
              alignOffset={-5}
              align="start"
              content={`From ${formatVersionHistoryDateAndTime(revertedTo)}`}
            >
              <div className="flex items-center">
                <div>{formattedTitle}</div>
                <div tabIndex={0} className="ml-1">
                  <BsInfoCircle size={12} />
                </div>
              </div>
            </Tooltip>
          ) : (
            formattedTitle
          )}
        </h3>
        {!isDateOnly && (
          <span className="typ-body-small text-muted">{date}</span>
        )}
        {!isDateOnly && (
          <div className="flex items-center">
            <Avatar name={userName} className="mr-1" isSquare size="xxs" />
            <span className="typ-body-small text-muted">{userName}</span>
          </div>
        )}
        {showRestoreButton && (
          <Button
            variant="primary"
            size="sm"
            layoutClassName="w-full mt-1.5"
            onClick={() => {
              if (elementId && version.id) {
                logEvent("editor.history.restore");
                void revertElementVersion({
                  elementId,
                  elementRevisionId: version.id,
                });
              }
            }}
            isLoading={isRestoreRevisionLoading}
          >
            Restore this version
          </Button>
        )}
      </div>
    </div>
  );
});
