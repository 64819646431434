import * as React from "react";

import { DebouncedInput } from "@editor/components/common/designSystem/Input";
import TemplatesMenu from "@editor/components/left-bar/insert-pane/TemplatesMenu";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import { Search } from "lucide-react";

export type View = "sections" | "components";

const InsertPane: React.FC = () => {
  const [searchString, setSearchString] = React.useState("");
  const [selectedView, setSelectedView] = React.useState<View>("sections");
  const logEvent = useLogAnalytics();

  return (
    <div className="flex flex-col h-full items-center gap-3 w-full">
      <div className="z-max sticky top-0 bg-white w-full px-3 ">
        <ToggleGroup
          size="sm"
          options={[
            {
              label: "Sections",
              value: "sections",
            },
            {
              label: "Components",
              value: "components",
            },
          ]}
          selectedValue={selectedView}
          onChange={(value) => {
            setSelectedView(value as View);
          }}
        />
      </div>

      <div className="z-max sticky top-0 bg-white w-full px-3">
        <DebouncedInput
          placeholder={`Search ${selectedView === "sections" ? "Sections" : "Components"}...`}
          value={searchString}
          onValueChange={setSearchString}
          name="search-input"
          onBlur={() => {
            if (searchString.length > 0) {
              logEvent("components.search", {
                searchString: searchString,
              });
            }
          }}
          autoComplete="off"
          startEnhancer={<Search className="w-3 h-3" />}
          autoFocus={true}
        />
      </div>

      <div className="flex-1 min-h-0 w-full mb-8 px-3">
        <div className="h-full overflow-y-auto no-scrollbar">
          <TemplatesMenu
            selectedView={selectedView}
            searchString={searchString}
          />
        </div>
      </div>
    </div>
  );
};

export default InsertPane;
