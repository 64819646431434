import type { CredentialResponse } from "@react-oauth/google";

import * as React from "react";

import Input from "@common/designSystem/Input";
import { ErrorMessage as GlobalErrorMessage } from "@components/account/common";
import GoogleAuth from "@editor/components/account/GoogleAuth";
import Avatar from "@editor/components/common/designSystem/Avatar";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { handleTRPCClientError, trpc, trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import toast from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { useForm } from "react-hook-form";
import { BsCheckCircleFill } from "react-icons/bs";
import { isEmpty } from "replo-utils/lib/misc";
import { z } from "zod";

const validationSchema = z.object({
  firstName: z.string().min(1, "You must enter a first name"),
  lastName: z.string(),
});

type UserName = {
  firstName: string;
  lastName: string;
};

export default function ProfileBoard() {
  const [googleErrorMessage, setGoogleErrorMessage] = React.useState<
    string | null
  >(null);
  const { user, isLoading: isUserLoading } = useCurrentUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
    },
    resolver: zodResolver(validationSchema),
  });

  const { mutate: updateProfileName, isPending: isLoading } =
    trpc.user.updateProfileName.useMutation({
      onSuccess: (user) => {
        toast({
          header: "Profile Updated",
          message: "Your profile has been successfully updated",
        });
        logEvent("user.setting.updated", {
          infoUpdated: "name",
        });
        trpcUtils.user.get.setData({}, (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            firstName: user.firstName,
            lastName: user.lastName,
            name: `${user.firstName} ${user.lastName}`,
          };
        });
      },
      onError: (error) => {
        const zodFormErrorData = z
          .object({
            fieldErrors: z
              .object({
                firstName: z.array(z.string()).nullish(),
                lastName: z.array(z.string()).nullish(),
              })
              .nullish(),
            formErrors: z.array(z.string()).nullish(),
          })
          .safeParse(error.data);
        if (zodFormErrorData.success && !isEmpty(zodFormErrorData.data)) {
          if (zodFormErrorData.data?.fieldErrors?.firstName) {
            setError("firstName", {
              message: zodFormErrorData.data?.fieldErrors?.firstName[0],
            });
          }
          if (zodFormErrorData.data?.fieldErrors?.lastName) {
            setError("lastName", {
              message: zodFormErrorData.data?.fieldErrors?.lastName[0],
            });
          }
        } else {
          handleTRPCClientError(error);
        }
      },
      meta: {
        // Note (Noah, 2024-12-18): Ignore default toast handling since
        // we display an inline error directly
        reploIgnoreDefaultErrorHandling: true,
      },
    });

  const { mutate: connectGoogleAccount } =
    trpc.user.connectGoogleAccount.useMutation({
      onSuccess: ({ verifiedAt }) => {
        trpcUtils.user.get.setData({}, (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            verifiedAt,
          };
        });

        toast({
          header: "Google Account Connected",
          message: "Your Google account has been successfully connected",
        });
      },
      onError: (error) => setGoogleErrorMessage(error.message),
    });

  const onGoogleSubmit = async (credentialResponse: CredentialResponse) => {
    const { clientId, credential } = credentialResponse;
    if (clientId && credential) {
      connectGoogleAccount({ clientId, credential });
    }
  };

  const logEvent = useLogAnalytics();

  const onSubmit = async ({ firstName, lastName }: UserName) => {
    updateProfileName({ firstName, lastName });
  };

  return user && !isUserLoading ? (
    <div className="flex">
      <Avatar name={user.name} size="xl" className="mr-8" />
      <div className="flex flex-1 flex-col">
        <div className="flex w-64 flex-col gap-2">
          <h3 className="text-sm font-medium text-default">Your Profile</h3>
          <form
            onSubmit={(data) => {
              void handleSubmit(onSubmit)(data);
            }}
            className="flex flex-col gap-4"
          >
            <div className="grid grid-cols-2 gap-2">
              <LabeledControl
                label="First Name"
                error={errors?.firstName?.message}
              >
                <Input
                  aria-invalid={errors.firstName ? "true" : undefined}
                  aria-describedby={
                    errors.firstName ? "error-first-name" : undefined
                  }
                  validityState={errors.firstName ? "invalid" : undefined}
                  autoComplete="off"
                  placeholder="First Name"
                  {...register("firstName")}
                  type="text"
                  size="base"
                />
              </LabeledControl>
              <LabeledControl
                label="Last Name"
                error={errors?.lastName?.message}
              >
                <Input
                  // TODO (Chance 2023-11-10): This field should be marked as
                  // required. I assume it's not for stylistic purposes but we
                  // can do this properly while still controlling the style of
                  // the displayed errors.
                  aria-invalid={errors?.lastName ? "true" : undefined}
                  aria-describedby={
                    errors?.lastName ? "error-last-name" : undefined
                  }
                  validityState={errors?.lastName ? "invalid" : undefined}
                  autoComplete="off"
                  placeholder="Last Name"
                  {...register("lastName")}
                  type="text"
                  size="base"
                />
              </LabeledControl>
            </div>
            <div className="flex flex-col gap-4">
              <LabeledControl label="Email">
                <div className="text-sm text-default flex items-center">
                  {user.email}
                  {user.verifiedAt && (
                    <Tooltip content="Email account is verified" triggerAsChild>
                      <div tabIndex={0}>
                        <BsCheckCircleFill className="text-green-400 ml-1" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </LabeledControl>
              {user.referralCode?.code && (
                <LabeledControl label="Referrer Code">
                  <div className="text-sm text-default">
                    {user.referralCode.code}
                  </div>
                </LabeledControl>
              )}
              {!user.verifiedAt && (
                <div className="typ-body-small text-muted">
                  Connect your Google account
                  <GlobalErrorMessage errorMessage={googleErrorMessage} />
                  <GoogleAuth
                    width={256}
                    handleGoogleCredentials={onGoogleSubmit}
                  />
                </div>
              )}
              <div className="typ-body-small text-muted">
                To change your email address, please reach out to
                <a href="mailto:support@replo.app"> support@replo.app</a>.
              </div>
              <Button
                variant="primary"
                size="base"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Update Profile
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : null;
}
