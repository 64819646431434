import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const SHOPIFY_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE =
  '<div id="shopify-product-reviews" data-id="{{product.id}}">{{ product.metafields.spr.reviews }}</div>';

function ShopifyProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: SHOPIFY_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE,
      context: props.context,
    },
  );
  return (
    <SharedShopifyLiquid
      componentId={component.id}
      componentAttributes={componentAttributes}
      liquidSource={liquidSourceWithProductAssignment}
      title="Shopify Product Reviews"
      placeholderType="reviewFull"
      isLiquidSupported
      repeatedIndexPath={context.repeatedIndexPath}
    />
  );
}

export default ShopifyProductReviewsWidget;
