import * as React from "react";

import Separator from "@common/designSystem/Separator";
import Header from "@components/dashboard/Header";

import twMerge from "@replo/design-system/utils/twMerge";
import { NavLink } from "react-router-dom";

type Link = {
  path: string;
  text: string;
};

type NavigationTabsProps = {
  header: string;
  subtitle?: string;
  links: Link[];
};

export const NavigationTabs: React.FC<NavigationTabsProps> = ({
  header,
  subtitle,
  links,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <Header title={header} subtitle={subtitle} />
      <Separator />
      {Boolean(links?.length) && (
        <div className="mb-6 flex w-full gap-4">
          {links.map((link: Link) => {
            return (
              <NavLink
                key={link.path}
                to={link.path}
                className={({ isActive }) => {
                  return twMerge(
                    "text-sm font-semibold",
                    isActive ? "text-default" : "text-muted hover:text-default",
                  );
                }}
              >
                {link.text}
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
};
