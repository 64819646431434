import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { AlchemyActionType } from "../../../shared/enums";
import type { ComponentConfig } from "../../components";

const styleElements = {
  root: {},
} as const satisfies StyleElements;

const actionTypes = [
  "setDropdownItem",
  "toggleDropdown",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    customProps: [
      {
        id: "_selectedComponent",
        type: "component",
        name: "Selection Component",
        defaultValue: null,
        description:
          "This component is displayed as the current selection of the dropdown.",
      },
      {
        id: "_itemComponent",
        type: "component",
        name: "Item Component",
        defaultValue: null,
        description:
          "This component is displayed for every item in the dropdown",
      },
    ],
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: true,
    styleElements,
    actionTypes,
  },
} satisfies ComponentConfig;

export default config;
