import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const JUDGE_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE = `
  <!-- Start of Judge.me code -->
    <div style='clear:both'></div>
    <div id='judgeme_product_reviews' class='jdgm-widget jdgm-review-widget' data-id='{{ product.id }}'>
        {{ product.metafields.judgeme.widget }}
    </div>
  <!-- End of Judge.me code -->
`;

function JudgeProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: JUDGE_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE,
      context,
    },
  );
  return (
    <SharedShopifyLiquid
      componentAttributes={componentAttributes}
      component={component}
      componentId={component.id}
      liquidSource={liquidSourceWithProductAssignment}
      title="Judge.me Product Reviews"
      placeholderType="reviewFull"
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
    />
  );
}

export default JudgeProductReviewsWidget;
