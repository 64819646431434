import type {
  SetDraftElementPayload,
  UpdateEditorAction,
} from "@editor/actions/core-actions";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { EditorRootState } from "@editor/store";
import type { ComponentActionType } from "@editor/types/component-action-type";
import type { ReploComponentIssue } from "@editor/types/component-issues";
import type { CoreState, StreamingUpdate } from "@editor/types/core-state";
import type {
  GetAttributeDependencies,
  GetAttributeFunction,
} from "@editor/types/get-attribute-function";
import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { RightBarTab } from "@reducers/ui-reducer";
import type { PositionAttribute } from "@reducers/utils/core-reducer-utils";
import type { PayloadAction, Reducer, Selector } from "@reduxjs/toolkit";
import type { ToastProps } from "@replo/design-system/components/alert/Toast";
import type { Exception } from "@sentry/react";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";
import type {
  ComponentData,
  ComponentStylesForChildren,
} from "replo-runtime/shared/Component";
import type { DataTable } from "replo-runtime/shared/DataTable";
import type { AlchemyActionType } from "replo-runtime/shared/enums";
import type {
  PredefinedVariant,
  ProductsDependency,
  ReploShopifyOption,
  StoreProduct,
  VariantWithState,
} from "replo-runtime/shared/types";
import type { KlaviyoIdentifiersStore } from "replo-runtime/store/contexts/KlaviyoIdentifiers/context";
import type { RuntimeActiveStateStore } from "replo-runtime/store/contexts/RuntimeActiveState/context";
import type { Context } from "replo-runtime/store/ReploVariable";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component, ReploComponentType } from "schemas/component";
import type {
  ReploElement,
  ReploElementVersionRevision,
} from "schemas/generated/element";
import type { ReploProject } from "schemas/generated/project";
import type { ConditionField, ReploState } from "schemas/generated/symbol";
import type { ProductRef } from "schemas/product";
import type {
  BorderSide,
  BorderSuffix,
  RuntimeStyleAttribute,
  RuntimeStyleProperties,
  SupportedCssProperties,
} from "schemas/styleAttribute";

import * as React from "react";

import { API_ACTIONS } from "@constants/action-types";
import EditorActionType from "@constants/editor-action-types";
import { createElementUpdatingThunk } from "@editor/actions/core-actions";
import { getComponentName } from "@editor/components/editor/component";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import { getVisibleBreakpoints } from "@editor/components/editor/page/element-editor/components/modifiers/utils";
import { analytics, trackError } from "@editor/infra/analytics";
import { EditorMode } from "@editor/types/core-state";
import checkForMixedValues from "@editor/utils/checkForMixedValues";
import {
  getVariants,
  hasVariants,
  memoizedGetAttributeRetriever,
  VALUE_TO_ALIGNMENT_OPTION,
} from "@editor/utils/component-attribute";
import { regexForSplittingPropValuesWithDynamicData } from "@editor/utils/designLibrary";
import { docs } from "@editor/utils/docs";
import { extractInnerTextForDesignLibrary } from "@editor/utils/dom";
import { canvasToStyleMap } from "@editor/utils/editor";
import { getPageUrl } from "@editor/utils/element";
import {
  isImageSourceTooLongError,
  UpdateElementFailedError,
} from "@editor/utils/errors";
import getIssuesForComponent from "@editor/utils/getIssuesForComponent";
import { saveCurrentElementId } from "@editor/utils/localStorage";
import { componentTypeToModifierGroups } from "@editor/utils/modifierGroups";
import { objectId } from "@editor/utils/objectId";
import { getStoreData } from "@editor/utils/project-utils";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import { trpcUtils } from "@editor/utils/trpc";
import { selectLocaleData } from "@reducers/commerce-reducer";
import {
  selectTemplateEditorProduct,
  selectTemplateEditorStoreProduct,
} from "@reducers/template-reducer";
import {
  enhanceElementMapping,
  findComponentsShadows,
  getComponentMappingFromElement,
  getDefaultPositionAttribute,
  getFieldMapping,
  getHistoryAfterPatch,
  getNextElements,
  hardcodedActions,
  mergeElementsStateWithNewElement,
} from "@reducers/utils/core-reducer-utils";
import {
  findAncestorComponent,
  findAncestorComponentData,
  findAncestorComponentOrSelf,
  findAncestorRepeatedIndex,
  findSymbolAncestor,
  getComponentActionTypesFromAncestors,
  getComponentConditionFields,
  getComponentData,
  getEdgeAttribute,
  getEditorComponentNodes,
  isModal,
} from "@utils/component";
import { calculateDependencies } from "@utils/dependencies";
import { expandAllSymbolsOfElement } from "@utils/editorSymbols";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import {
  createAction,
  createReducer,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  errorToast,
  toast,
  ToastCTALink,
  warningToast,
} from "@replo/design-system/components/alert/Toast";
import { getCommandMenuItems } from "@replo/design-system/utils/commands";
import isEqual from "fast-deep-equal";
import { applyPatches, isDraft, original, produce } from "immer";
import difference from "lodash-es/difference";
import filter from "lodash-es/filter";
import forIn from "lodash-es/forIn";
import intersectionBy from "lodash-es/intersectionBy";
import isString from "lodash-es/isString";
import omit from "lodash-es/omit";
import reduce from "lodash-es/reduce";
import uniqBy from "lodash-es/uniqBy";
import uniqWith from "lodash-es/uniqWith";
import createCachedSelector from "re-reselect";
import reduceReducers from "reduce-reducers";
import { isDynamicDataValue } from "replo-runtime";
import { getSavedStyleId } from "replo-runtime/shared/savedStyles";
import { DependencyType } from "replo-runtime/shared/types";
import {
  findComponent,
  forEachComponentAndDescendants,
  getCustomPropDefinitions,
} from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import {
  designLibraryValueRegex,
  isDynamicDesignLibraryValue,
} from "replo-runtime/shared/utils/designLibrary";
import {
  doesComponentHaveConnectedDynamicDataProps,
  getDesignLibraryDynamicDataExpressions,
  getNonDesignLibraryDynamicDataInfo,
} from "replo-runtime/shared/utils/dynamic-data";
import { getNormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import {
  getFromRecordOrNull,
  mapNull,
} from "replo-runtime/shared/utils/optional";
import {
  buildPredefinedVariant,
  getVariantsWithState,
  hasVariantWithConditionField,
} from "replo-runtime/shared/variant";
import { executeAction } from "replo-runtime/store/AlchemyAction";
import { getRenderData } from "replo-runtime/store/components";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { evaluateVariableAsString } from "replo-runtime/store/ReploVariable";
import {
  isMixedStyleValue,
  REPLO_MIXED_STYLE_VALUE,
} from "replo-runtime/store/utils/mixed-values";
import { enhanceVariantsAndOptions } from "replo-runtime/store/utils/product";
import { filterNulls } from "replo-utils/lib/array";
import {
  coerceNumberToString,
  hasOwnProperty,
  isEmpty,
  isNotNullish,
} from "replo-utils/lib/misc";
import { isFunction } from "replo-utils/lib/type-check";
import { isShopifyIntegrationEnabled } from "schemas/utils";
import { v4 as uuidv4 } from "uuid";

const INITIAL_HISTORY_STATE = {
  index: -1,
  lastOperation: null,
  operations: {},
  maxSize: Number.POSITIVE_INFINITY,
};

export const initialState: CoreState = {
  project: null,
  elements: {
    draftElementId: null,
    draftComponentIds: [],
    draftSymbolInstanceId: null,
    draftRepeatedIndex: null,
    componentIdToDraftVariantId: {},
    draftSymbolId: null,
    mapping: {},
    versionMapping: {},
    isLoading: false,
    selectedRevisionId: null,
    selectedArchivedElementId: null,
    elementRevisions: {},
    draftElementColors: [],
    draftElementFontFamilies: [],
    streamingUpdate: null,
    srcDocFontUrls: [],
    srcDocFonts: [],
    srcDocRootFont: "",
  },
  symbols: {
    mapping: {},
  },
  dataTables: {
    draft: null,
    mapping: {},
    isLoading: false,
  },
  editorOptions: {
    isDebugPanelVisible: false,
  },
  history: INITIAL_HISTORY_STATE,
  isLoading: false,
  isPublishing: false,
  isPreviewMode: false,
  editorMode: EditorMode.edit,
  publishingError: null,
  pendingElementUpdates: [],
  updatesSinceLastRequestFinished: 0,
  elementUpdateInProgress: false,
  componentDataMapping: {},
};

// #region Default reducer
const defaultReducer: Reducer<CoreState, UpdateEditorAction> = (
  draftState = initialState,
  action,
): CoreState => {
  // Note (Noah, 2024-08-17, REPL-13239): We're going to do a bunch of property
  // accesses for this state as part of generating the component data mapping,
  // and those are slow if we use immer's proxy. So, we grab the original state
  // to pass it through to the functions where the state is readonly.
  const originalState = (
    isDraft(draftState) ? original(draftState) : draftState
  ) as CoreState;
  switch (action.type) {
    case EditorActionType.UPDATE_ELEMENT:
      if (action.payload.projectId === originalState.project?.id) {
        const currentElement = {
          ...originalState.elements.mapping[action.payload.id],
          ...action.payload,
        };

        // Only update componentDataMapping and componentColors if the element
        // to be updated is the draft element
        const { componentDataMapping, componentColors, componentFontFamilies } =
          currentElement.id === originalState.elements.draftElementId &&
          currentElement.component
            ? getComponentData({
                component: currentElement.component as Component,
                context: {
                  symbols: originalState.symbols.mapping,
                  componentDataMapping: originalState.componentDataMapping,
                },
              })
            : {
                componentDataMapping: originalState.componentDataMapping,
                componentColors: originalState.elements.draftElementColors,
                componentFontFamilies:
                  originalState.elements.draftElementFontFamilies,
              };

        return Object.assign({}, originalState, {
          elements: {
            ...originalState.elements,
            mapping: {
              ...originalState.elements.mapping,
              [action.payload.id]: currentElement,
            },
            draftElementColors: [...componentColors],
            draftElementFontFamilies: [...componentFontFamilies],
          },
          componentDataMapping,
        });
      }
      return draftState;
    default:
      return draftState;
  }
};
// #endregion

// #region Elements slice
type CreateUpdateElementSuccessPayload = {
  success: true;
  elementId: string;
  shopifyPageId: string | null;
};

type CreateUpdateElementErrorPayload = {
  error: string;
  element: ReploElement;
  detail?: string;
};

// Note (Evan, 2024-01-03): This little type guard will help us handle the create/update element
// response in a cleaner way.
const createUpdateElementPayloadIsSuccess = (
  body: CreateUpdateElementSuccessPayload | CreateUpdateElementErrorPayload,
): body is CreateUpdateElementSuccessPayload => {
  return hasOwnProperty(body, "success") && body.success === true;
};

const elementsSlice = createSlice({
  name: "elements",
  initialState,
  reducers: {
    setDraftElement: (state, action: PayloadAction<SetDraftElementPayload>) => {
      /**
       * Note (Noah, 2021-06-17): Check for undefined here since we may use null
       * to remove a draft component id etc
       */
      let newDraftComponentIds: string[] = [];
      if (action.payload.componentIds === undefined) {
        newDraftComponentIds = state.elements.draftComponentIds;
      } else if (action.payload.componentIds) {
        newDraftComponentIds = action.payload.componentIds;
      }
      const newDraftElementId =
        action.payload.id === undefined
          ? state.elements.draftElementId
          : action.payload.id;
      const newDraftRepeatedIndex =
        action.payload.repeatedIndex === undefined
          ? state.elements.draftRepeatedIndex
          : action.payload.repeatedIndex;
      const newDraftSymbolId =
        action.payload.symbolId === undefined
          ? state.elements.draftSymbolId
          : action.payload.symbolId;
      const newComponentIdToDraftVariantId =
        action.payload.variantId && newDraftComponentIds[0]
          ? { [newDraftComponentIds[0]]: action.payload.variantId }
          : null;

      const idToSave = newDraftElementId ?? state.elements.draftElementId;
      if (idToSave && state.elements.mapping[idToSave]?.projectId) {
        saveCurrentElementId(
          idToSave,
          state.elements.mapping[idToSave]!.projectId,
        );
      }

      let newDraftSymbolInstanceId =
        state.elements.draftSymbolInstanceId ?? null;
      const expandedElement = expandAllSymbolsOfElement(
        // TODO (Chance 2023-11-10): Handle `null` cases and remove assertion
        state.elements.mapping[newDraftElementId!] as ReploElement,
        state.symbols.mapping,
        state.elements.componentIdToDraftVariantId,
      );
      const symbolAncestor = findSymbolAncestor(
        expandedElement,
        newDraftComponentIds[0] ?? null,
      );
      if (symbolAncestor) {
        newDraftSymbolInstanceId = symbolAncestor.id;
      } else {
        newDraftSymbolInstanceId = null;
      }

      const componentMapping = getComponentMappingFromElement(expandedElement);
      for (const componentId of newDraftComponentIds) {
        const component = componentMapping[componentId]?.component;
        if (!component) {
          continue;
        }

        // Note (Noah, 2021-08-30): When we select a tab panel in the tree (or via
        // other means) we find the closest panels content component and automatically
        // activate the selected panel. This makes it better UX since the user doesn't
        // have to select the tab manually via the banner.
        const tabPanelAncestor = findAncestorComponentOrSelf(
          expandedElement,
          componentId,
          (component) => {
            return component.type === "tabs__onePanelContent";
          },
        );

        if (tabPanelAncestor) {
          const tabRepeatedIndex = findAncestorRepeatedIndex(
            expandedElement,
            component,
            newDraftRepeatedIndex,
            (component) => {
              return component.type === "tabs__onePanelContent";
            },
          );
          const context = mapNull(tabRepeatedIndex, (tabRepeatedIndex) =>
            getCurrentComponentContext(tabPanelAncestor.id, tabRepeatedIndex),
          );
          if (
            context &&
            context?.state?.tabsBlock?.activeTabId !== tabPanelAncestor.id
          ) {
            void executeAction(
              {
                id: "alchemyEditor:toggleTab",
                type: "activateTabId",
                value: {
                  tabItemId: tabPanelAncestor.id,
                },
              },
              {
                componentId: tabPanelAncestor.id,
                componentContext: context,
                repeatedIndex: "",
                products: [],
                templateProduct: null,
                stores: {
                  // Note (Noah, 2024-10-12): We shouldn't be calling
                  // executeAction in the editor like this, but this is for tabs
                  // (v1) which is an old legacy component, and activateTabId
                  // doesn't use this store, so it's okay to pass null
                  runtimeActiveState:
                    null as unknown as RuntimeActiveStateStore,
                  klaviyoIdentifiers:
                    null as unknown as KlaviyoIdentifiersStore,
                },
              },
            );
          }
        }

        const tabListSingleComponent = findAncestorComponentOrSelf(
          expandedElement,
          componentId,
          (component) => {
            return component.type === "tabs__list";
          },
        );

        if (tabListSingleComponent) {
          const tabRepeatedIndex = findAncestorRepeatedIndex(
            expandedElement,
            component,
            newDraftRepeatedIndex,
            (component) => {
              return component.type === "tabs__list";
            },
          );

          const tabListChildComponent = tabListSingleComponent.children?.[0];

          const context =
            tabListChildComponent && isNotNullish(tabRepeatedIndex)
              ? getCurrentComponentContext(
                  tabListChildComponent.id,
                  tabRepeatedIndex,
                )
              : null;

          if (context) {
            const tabItem = context?.state?.tabsBlock?.currentTabsListItem;
            void executeAction(
              {
                id: "alchemyEditor:toggleTab",
                type: "activateTabId",
                value: {
                  tabItemId: tabItem.id,
                },
              },
              {
                componentId: tabItem.id,
                componentContext: context,
                repeatedIndex: "",
                products: [],
                templateProduct: null,
                stores: {
                  // Note (Noah, 2024-10-12): We shouldn't be calling
                  // executeAction in the editor like this, but this is for tabs
                  // (v1) which is an old legacy component, and activateTabId
                  // doesn't use this store, so it's okay to pass null
                  runtimeActiveState:
                    null as unknown as RuntimeActiveStateStore,
                  klaviyoIdentifiers:
                    null as unknown as KlaviyoIdentifiersStore,
                },
              },
            );
          }
        }
      }

      // Note (Noah, 2022-07-21): Don't create a new object for
      // componentIdToDraftVariantId if we don't need to, since
      // this would cause some components to rerender unnecessarily.
      const componentIdToDraftVariantId = newComponentIdToDraftVariantId
        ? {
            ...state.elements.componentIdToDraftVariantId,
            ...newComponentIdToDraftVariantId,
          }
        : state.elements.componentIdToDraftVariantId;

      state.elements = {
        ...state.elements,
        draftElementId: newDraftElementId,
        draftComponentIds: newDraftComponentIds,
        draftRepeatedIndex: newDraftRepeatedIndex,
        draftSymbolId: newDraftSymbolId,
        draftSymbolInstanceId: newDraftSymbolInstanceId,
        componentIdToDraftVariantId,
      };
    },
    createElement: (
      state,
      action: PayloadAction<{ element: ReploElement & { version: number } }>,
    ) => {
      const element = action.payload.element;
      state.elements = mergeElementsStateWithNewElement(
        state.elements,
        element,
      );
    },
    deleteElement: (state, action: PayloadAction<ReploElement["id"]>) => {
      const elementId = action.payload;
      if (elementId in state.elements.mapping) {
        state.elements.mapping = omit(state.elements.mapping, elementId);
      }
    },
    archiveElement: (state, action: PayloadAction<ReploElement["id"]>) => {
      const elementId = action.payload;
      if (elementId in state.elements.mapping) {
        state.elements.mapping = omit(state.elements.mapping, elementId);
      }
    },
    setDraftRepeatedIndex: (state, action: PayloadAction<string>) => {
      state.elements.draftRepeatedIndex = action.payload;
    },
    setElementsLoading: (state, action: PayloadAction<boolean>) => {
      state.elements.isLoading = action.payload;
    },
    setProjectData: (
      state,
      action: PayloadAction<{
        project: ReploProject;
        draftElementId?: string;
        versionMapping: Record<string, number>;
      }>,
    ) => {
      state.project = action.payload.project;

      state.elements = {
        ...state.elements,
        draftElementId: action.payload.draftElementId ?? null,
        versionMapping: action.payload.versionMapping,
      };

      state.symbols.mapping = getFieldMapping(
        action.payload.project.symbols ?? [],
        "id",
      );

      state.dataTables.mapping = getFieldMapping(
        action.payload.project.dataTables ?? [],
        "id",
      );

      state.isLoading = false;
    },
    setPendingElementUpdate: (
      state,
      action: PayloadAction<ReploElement["id"]>,
    ) => {
      const pendingElementUpdates = new Set(state.pendingElementUpdates);
      pendingElementUpdates.add(action.payload);

      state.pendingElementUpdates = Array.from(pendingElementUpdates);
      state.updatesSinceLastRequestFinished =
        state.updatesSinceLastRequestFinished + 1;
    },
    setElementRevisions: (
      state,
      action: PayloadAction<
        Record<ReploElement["id"], ReploElementVersionRevision[]>
      >,
    ) => {
      state.elements.elementRevisions = {
        ...state.elements.elementRevisions,
        ...action.payload,
      };
    },
    restoreElementRevision: (state, action: PayloadAction<ReploElement>) => {
      state.editorMode = EditorMode.edit;
      state.elements.mapping = {
        ...state.elements.mapping,
        [state.elements.draftElementId!]: action.payload,
      };
      state.elements.versionMapping = {
        ...state.elements.versionMapping,
        [state.elements.draftElementId!]: action.payload.version,
      };
      state.elements.selectedRevisionId = null;
    },
    setSelectedRevisionId: (
      state,
      action: PayloadAction<ReploElementVersionRevision["id"] | null>,
    ) => {
      state.elements.selectedRevisionId = action.payload;
    },
    setSelectedArchivedElementId: (
      state,
      action: PayloadAction<ReploElement["id"] | null>,
    ) => {
      state.elements.selectedArchivedElementId = action.payload;
    },
    setComponentDataMappingStylesForChildren: (
      state,
      action: PayloadAction<Record<string, ComponentStylesForChildren>>,
    ) => {
      for (const [componentId, stylesForChildren] of Object.entries(
        action.payload,
      )) {
        const componentData = state.componentDataMapping?.[componentId];
        if (componentData) {
          componentData.stylesForChildren = JSON.stringify(stylesForChildren);
        }
      }
    },
    setStreamingUpdateComponentIdsSelected: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      if (state.elements.streamingUpdate) {
        state.elements.streamingUpdate.componentIdsSelected = action.payload;
      }
    },
    setStreamingUpdate: (
      state,
      action: PayloadAction<StreamingUpdate | null>,
    ) => {
      state.elements.streamingUpdate = action.payload;
    },

    // Note (Evan, 2024-06-04): Restore any originalStyles set before streaming.
    // BIG ASSUMPTION here is that the styles in originalStyles are not being modified by any streamed actions.
    // If you are using originalStyles, be careful of this!
    markStreamingUpdateFinished: (state) => {
      if (!state.elements.streamingUpdate) {
        return;
      }

      const originalStyles = state.elements.streamingUpdate?.originalStyles;

      if (!originalStyles) {
        return;
      }

      for (const [canvas, canvasStyles] of Object.entries(originalStyles)) {
        const styleKey = canvasToStyleMap[canvas as EditorCanvas];
        forEachComponentAndDescendants(
          state.elements.streamingUpdate?.draftElementComponent ?? null,
          (component) => {
            if (canvasStyles[component.id]) {
              component.props[styleKey] = {
                ...component.props[styleKey],
                ...canvasStyles[component.id],
              };
            }
          },
        );
      }

      state.elements.streamingUpdate.isStreaming = false;
    },
    /**
     * Given a ComponentAction (with definitely-defined componentId), applies
     * that action to the draft element component copy in the streamingUpdate state.
     *
     * We essentially maintain a divergent copy of the draft element component, and an
     * array of actions that have been applied to it. This copy is only used for rendering -
     * if the changes are confirmed, we replay the actions on the actual draft element component.
     */
    handleStreamingAction: (
      state,
      action: PayloadAction<ComponentActionType & { componentId: string }>,
    ) => {
      if (!state.elements.streamingUpdate || !state.elements.draftElementId) {
        return;
      }

      // Note (Evan, 2024-06-04): A little confusing, here the payload of the Redux action
      // is the ComponentAction to apply.
      const componentAction = action.payload;

      state.elements.streamingUpdate.actions?.push(componentAction);

      // Note (Evan, 2024-07-16): When setting text, we want to pass those values down through context
      // instead (for faster updates). So we split out any updated text values and add those to the map
      // in the redux store.
      if (
        componentAction.type === "setProps" &&
        componentAction.value.text &&
        isString(componentAction.value.text)
      ) {
        const { text, ...rest } = componentAction.value;

        // NOTE (Gabe 2024-09-10): Block incomplete HTML tags from being set.
        // We want to ensure tags are properly paired (e.g., <div></div>) or self-closing (e.g., <br/>).
        const hasIncompleteHtmlTags = (text: string): boolean => {
          // Stack to keep track of opening tags
          const tagStack: string[] = [];
          // Match both opening and closing tags, capturing the tag name
          const tagPattern = /<\/?([A-Za-z][\dA-Za-z]*)[^>]*>/g;
          // Find any remaining text that looks like it might be starting a tag
          const incompleteTagPattern = /<[^>]*$/;

          let match = tagPattern.exec(text);
          while (match !== null) {
            const [fullMatch, tagName] = match;
            if (!tagName) {
              // If we can't extract the tag name, consider it malformed
              return true;
            }

            const isClosingTag = fullMatch.startsWith("</");

            if (isClosingTag) {
              // If we find a closing tag but stack is empty or doesn't match, it's malformed
              if (tagStack.length === 0 || tagStack.pop() !== tagName) {
                return true;
              }
            } else if (!fullMatch.endsWith("/>")) {
              // Push opening tag if it's not self-closing
              tagStack.push(tagName);
            }

            match = tagPattern.exec(text);
          }

          // Return true if we have unclosed tags or an incomplete tag at the end
          return tagStack.length > 0 || incompleteTagPattern.test(text);
        };

        if (hasIncompleteHtmlTags(text)) {
          return;
        }

        if (state.elements.streamingUpdate.textMap) {
          state.elements.streamingUpdate.textMap[componentAction.componentId] =
            text;
        }

        // Note (Evan, 2024-07-16): If rest is empty (meaning the only entry in the setProps action's value is text),
        // just return. Otherwise, we need to continue on to set the other props via the usual strategy. Note that
        // we do not increment the repaint key here, since we don't need to repaint the whole canvas when just the text
        // changes (the text components will rerender because of the useEditorOverrideTextValue hook).
        if (isEmpty(rest)) {
          return;
        }
      }

      // Note (Evan, 2024-06-07): To apply the componentAction to our copy of the draft element component, we
      // 1) create a copy of the state with our draftElementComponent swapped in
      const modifiedState = produce(state, (draft) => {
        if (
          state.elements.draftElementId &&
          state.elements.streamingUpdate?.draftElementComponent &&
          state.elements.mapping[state.elements.draftElementId]
        ) {
          // Note (Evan, 2024-06-07): We know this is defined (by checking)
          // but TS doesn't because it's a WritableDraft.
          // @ts-expect-error
          draft.elements.mapping[state.elements.draftElementId].component =
            state.elements.streamingUpdate.draftElementComponent;
        }
      });

      // 2) apply the component action to the modified state
      const { elements: nextElements } = getNextElements({
        action,
        state: modifiedState,
      });

      // 3) extract the next version of the draft element component, and save it to state
      const nextDraftElementComponent =
        nextElements[state.elements.draftElementId]?.component;
      if (nextDraftElementComponent) {
        state.elements.streamingUpdate.draftElementComponent =
          nextDraftElementComponent;
      }

      // Always increment repaint key for non-text updates
      if (state.elements.streamingUpdate.repaintKey === undefined) {
        state.elements.streamingUpdate.repaintKey = 0;
      }
      state.elements.streamingUpdate.repaintKey += 1;
    },
    setSrcDocFontUrls: (state, action: PayloadAction<string[]>) => {
      state.elements.srcDocFontUrls = action.payload;
    },
    setSrcDocFonts: (
      state,
      action: PayloadAction<
        {
          family: string;
          fontFaceDeclarations: string[];
          weights: string[] | null;
        }[]
      >,
    ) => {
      state.elements.srcDocFonts = action.payload;
    },
    setSrcDocRootFont: (state, action: PayloadAction<string>) => {
      state.elements.srcDocRootFont = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.start,
      (
        state,
        action: PayloadAction<
          {},
          "CREATE_OR_UPDATE_ELEMENT_START",
          { elementId: ReploElement["id"] }
        >,
      ) => {
        const pendingElementUpdates = new Set(state.pendingElementUpdates);
        pendingElementUpdates.delete(action.meta.elementId);
        state.elements.isLoading = true;
        state.pendingElementUpdates = Array.from(pendingElementUpdates);
        state.elementUpdateInProgress = true;
      },
    );
    builder.addCase(
      API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.success,
      (
        state,
        action: PayloadAction<
          CreateUpdateElementSuccessPayload | CreateUpdateElementErrorPayload,
          "CREATE_OR_UPDATE_ELEMENT_SUCCESS",
          { elementId: ReploElement["id"]; isSettingHomepage: boolean }
        >,
      ) => {
        // NOTE (Gabe 2025-02-04): This whole thing is wrapped in a
        // try/catch/finally because elementUpdateInProgress NEEDS to be reset
        // even if we run into a runtime error (wrong type returned from the
        // server), or it will block all subsequent element updates from being
        // sent to the server.
        try {
          if (createUpdateElementPayloadIsSuccess(action.payload)) {
            const updatedElementId = action.payload.elementId;

            // NOTE (Fran 2024-04-08): If we are setting a new homepage, we need to remove all old homepages
            // elements.
            if (action.meta.isSettingHomepage) {
              const oldHomepagesElements = filter(
                state.elements.mapping,
                (element) => {
                  return element.isHomepage && element.id !== updatedElementId;
                },
              );

              for (const element of oldHomepagesElements) {
                const elementWithHomepage = state.elements.mapping[element.id];
                if (elementWithHomepage) {
                  elementWithHomepage.isHomepage = false;
                }
              }
            }

            state.elements.isLoading = false;
            state.elements.versionMapping = {
              ...state.elements.versionMapping,
              [updatedElementId]:
                (state.elements.versionMapping[updatedElementId!] ?? 0) + 1,
            };
            // Note (Evan, 2024-01-03): In some scenarios (REPL-9755) the shopify page id changes.
            // We update it here so editing continues to work without a refresh.
            if (
              action.payload.shopifyPageId &&
              state.elements.draftElementId === updatedElementId &&
              state.elements.mapping[state.elements.draftElementId] &&
              action.payload.shopifyPageId !==
                state.elements.mapping[state.elements.draftElementId!]
                  ?.shopifyPageId
            ) {
              // Note (Evan, 2024-01-03): Because state is a WritableDraft here (because of immer), Typescript doesn't
              // recognize that state.elements.mapping[state.elements.draftElementId] is defined, even though we
              // check this in the if-statement. So we have to assert.
              state.elements.mapping[
                state.elements.draftElementId
              ]!.shopifyPageId = action.payload.shopifyPageId;
            }
            state.updatesSinceLastRequestFinished = 0;
          } else if (action.payload.error === "unmatchedSaveVersion") {
            warningToast(
              "Someone else made a change",
              "We updated the page with the latest changes. Try again.",
            );
            analytics.logEvent("element.update.unmatchedSaveVersion", {
              payload: action.payload.detail ?? "No payload details",
            });
            state.elements.mapping = {
              ...state.elements.mapping,
              [action.payload.element.id]: action.payload.element,
            };
            state.elements.versionMapping = {
              ...state.elements.versionMapping,
              [action.payload.element.id]: action.payload.element.version,
            };
          } else {
            throw new UpdateElementFailedError({
              message: "Update element request failed",
              additionalData: {
                error: action.payload?.error ?? "",
                payload: action.payload,
              },
            });
          }
        } catch (error: unknown) {
          errorToast(
            "Failed Updating Element",
            "This element could not be updated. Please try again or reach out to support@replo.app for help.",
          );
          trackError(error);
        } finally {
          // NOTE (Gabe 2025-02-04): elementUpdateInProgress NEEDS to be reset
          // even if we run into a runtime error (wrong type returned from the
          // server), or it will block all subsequent element updates from being
          // sent to the server.
          state.elements.isLoading = false;
          state.elementUpdateInProgress = false;
        }
      },
    );
    builder.addCase(
      API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.error,
      (
        state,
        action: PayloadAction<
          {
            response: {
              key: string;
              element: ReploElement;
            };
            status: number;
          },
          "CREATE_OR_UPDATE_ELEMENT_ERROR",
          { elementId: ReploElement["id"] }
        >,
      ) => {
        state.elements.isLoading = false;
        state.elementUpdateInProgress = false;

        if (action.payload.response?.key === "shopify.index.backup") {
          const elementId = action.meta.elementId;
          if (elementId && state.elements.mapping[elementId]) {
            state.elements.mapping[elementId].isHomepage = false;
          }
        }

        if (
          action.payload.response?.key?.startsWith("billingPlan") ||
          action.payload.response?.key?.startsWith("pathAlreadyInUse")
        ) {
          const element = action.payload.response.element;
          state.elements.mapping = {
            ...state.elements.mapping,
            [element.id]: element,
          };
          state.elements.versionMapping = {
            ...state.elements.versionMapping,
            [element.id]: element.version,
          };
        } else if (action.payload.response) {
          trackError(
            new UpdateElementFailedError({
              message: "Update element request failed",
              additionalData: {
                key: action.payload.response?.key ?? "Unknown error",
                payload: action.payload,
                response: action.payload.response,
                statusCode: action.payload.status,
              },
            }) as Exception,
          );
        } else {
          trackError(
            new UpdateElementFailedError({
              message: "Update element request failed",
              additionalData: {
                error: "Unknown error",
                payload: action.payload,
              },
            }) as Exception,
          );
        }
      },
    );
    builder.addCase(API_ACTIONS.PUBLISH_SNIPPET.start, (state) => {
      state.isPublishing = true;
    });
    builder.addCase(
      API_ACTIONS.PUBLISH_SNIPPET.success,
      (
        state,
        action: PayloadAction<
          { element: ReploElement },
          "PUBLISH_SNIPPET_SUCCESS"
        >,
      ) => {
        const element = action.payload.element;
        const pageEditorData = elementTypeToEditorData[element.type];
        let toastProps: ToastProps = {
          header: `${pageEditorData.singularDisplayName} Published`,
          message: `Your ${pageEditorData.singularDisplayName} was published to Shopify`,
        };
        if (element.type === "page") {
          const url = getPageUrl({
            storeUrl: getStoreData(state.project)?.url ?? undefined,
            shopifyPagePath: element.shopifyPagePath,
          });
          if (url) {
            toastProps = {
              ...toastProps,
              // NOTE (Chance 2024-03-22): Not a tsx file, we can change this
              // later if we want but this is the only React element
              cta: React.createElement(
                ToastCTALink,
                { to: url, rel: "noreferrer", target: "_blank" },
                "View Live Page",
              ),
            };
          }
        }

        toast(toastProps);

        state.isPublishing = false;
        state.publishingError = null;
        state.elements.mapping = {
          ...state.elements.mapping,
          [element.id]: element,
        };

        state.elements.versionMapping = {
          ...state.elements.versionMapping,
          [element.id]: element.version,
        };

        void trpcUtils.element.findRevisions.invalidate({
          elementId: element.id,
        });
        void trpcUtils.element.listAllElementsMetadata.invalidate();
        void trpcUtils.element.getElementMetadataById.invalidate(element.id);
      },
    );
    builder.addCase(
      API_ACTIONS.PUBLISH_SNIPPET.error,
      (
        state,
        action: PayloadAction<{ response: unknown }, "PUBLISH_SNIPPET_ERROR">,
      ) => {
        state.isPublishing = false;
        state.publishingError = action.payload.response;
      },
    );
  },
});

export const {
  createElement,
  deleteElement,
  archiveElement,
  handleStreamingAction,
  markStreamingUpdateFinished,
  setDraftRepeatedIndex,
  setElementsLoading,
  setProjectData,
  setPendingElementUpdate,
  setElementRevisions,
  restoreElementRevision,
  setSelectedRevisionId,
  setSelectedArchivedElementId,
  setDraftElement,
  setComponentDataMappingStylesForChildren,
  setStreamingUpdate,
  setSrcDocFontUrls,
  setSrcDocFonts,
  setSrcDocRootFont,
} = elementsSlice.actions;
const elementsReducer = elementsSlice.reducer;
// #endregion

// #region Component action slice
const componentActionSlice = createSlice({
  name: "componentAction",
  initialState,
  reducers: {
    applyComponentRTKAction: (
      draftState,
      action: PayloadAction<ComponentActionType>,
    ) => {
      // Note (Noah, 2024-08-17, REPL-13239): We're going to do a bunch of property
      // accesses for this state as part of generating the component data mapping,
      // and those are slow if we use immer's proxy. So, we grab the original state
      // to pass it through to the functions where the state is readonly.
      const originalState = (
        isDraft(draftState) ? original(draftState) : draftState
      ) as CoreState;
      try {
        const payload = action.payload;
        const elementId =
          payload.elementId ?? originalState.elements.draftElementId!;
        const { elements, patches, inversePatches, updatedComponentIds } =
          getNextElements({
            action,
            state: originalState,
          });

        const currentElement = elements[elementId]!;

        const { componentDataMapping, componentColors, componentFontFamilies } =
          getComponentData({
            component: currentElement.component as Component,
            context: {
              symbols: originalState.symbols.mapping,
              componentDataMapping: originalState.componentDataMapping,
            },
          });

        draftState.elements.mapping = elements;
        draftState.elements.draftElementColors = [...componentColors];
        draftState.elements.draftElementFontFamilies = [
          ...componentFontFamilies,
        ];
        if (!payload.shouldBypassHistory) {
          draftState.history = getHistoryAfterPatch({
            action,
            history: draftState.history,
            patches,
            inversePatches,
            elementId,
            componentIds: updatedComponentIds,
          });
        }
        draftState.componentDataMapping = componentDataMapping;
      } catch (error) {
        if (isImageSourceTooLongError(error)) {
          toast({
            header: "Invalid image source",
            message:
              "The image you selected contains a source value that is too long. This can happen when copying SVG content with embedded images.",
            type: "error",
            cta: "Learn More",
            ctaOnClick: () => {
              window.open(docs.errors.imageSourceTooLong, "_blank");
            },
          });
        } else {
          throw error;
        }
      }
    },
    setComponentVariant: (
      state,
      action: PayloadAction<{
        componentId?: Component["id"];
        variantId?: ReploState["id"];
      }>,
    ) => {
      if (action.payload.componentId) {
        state.elements.componentIdToDraftVariantId[action.payload.componentId] =
          action.payload.variantId;
      }
    },
  },
});

export const { applyComponentRTKAction, setComponentVariant } =
  componentActionSlice.actions;
const componentReducer = componentActionSlice.reducer;
// #endregion

// #region Editor options slice
const editorOptionsSlice = createSlice({
  name: "editorOptions",
  initialState,
  reducers: {
    setDebugPanelVisibility: (state, action: PayloadAction<boolean>) => {
      state.editorOptions = {
        ...state.editorOptions,
        isDebugPanelVisible: action.payload,
      };
    },
    toggleDebugPanelVisibility: (state) => {
      state.editorOptions = {
        ...state.editorOptions,
        isDebugPanelVisible: !state.editorOptions.isDebugPanelVisible,
      };
    },
    setEditorMode: (state, action: PayloadAction<EditorMode>) => {
      const isPreviewMode = action.payload === EditorMode.preview;

      let elements = state.elements;
      if (state.elements.selectedRevisionId !== null) {
        elements = {
          ...state.elements,
          selectedRevisionId: null,
        };
      }

      if (state.elements.selectedArchivedElementId) {
        elements = {
          ...elements,
          selectedArchivedElementId: null,
        };
      }

      const editorMode = action.payload;

      state.elements = elements;
      state.isPreviewMode = isPreviewMode;
      state.editorMode = editorMode;
    },
  },
});

const editorOptionsReducer = editorOptionsSlice.reducer;
export const {
  setDebugPanelVisibility,
  toggleDebugPanelVisibility,
  setEditorMode,
} = editorOptionsSlice.actions;
// #endregion

// #region Data table slice
const defaultDataTable: DataTable = {
  id: "",
  name: "New Data Collection",
  singularItemName: null,
  pluralItemName: null,
  data: {
    schema: [],
    rows: [],
  },
};

const dataTableSlice = createSlice({
  name: "editorOptions",
  initialState,
  reducers: {
    mappingDataTables: (state) => {
      const draftDataTable = state.dataTables.draft ?? defaultDataTable;
      state.dataTables = {
        ...state.dataTables,
        draft: draftDataTable,
        mapping: {
          ...state.dataTables.mapping,
          [draftDataTable.id]: draftDataTable,
        },
      };
    },
    setDraftDataTable: (
      state,
      action: PayloadAction<{ id: string | null }>,
    ) => {
      let draft = {
        ...defaultDataTable,
        id: uuidv4(),
      };

      if (action.payload.id) {
        const dataTableFromMapping =
          state.dataTables.mapping[action.payload.id];
        if (dataTableFromMapping) {
          draft = dataTableFromMapping;
        }
      }

      state.dataTables.draft = draft;
    },
    updateDraftDataTable: (state, action: PayloadAction<DataTable>) => {
      state.dataTables.draft = action.payload;
    },
    updateDraftDataTableRow: (
      state,
      action: PayloadAction<{
        rowIndex: number;
        schemaId: string;
        value: any;
      }>,
    ) => {
      const dataTable = state.dataTables.draft ?? defaultDataTable;
      const { rowIndex, schemaId, value } = action.payload;

      state.dataTables.draft = {
        ...dataTable,
        data: {
          ...dataTable.data,
          rows: dataTable.data.rows.map((row, index) => {
            return index === rowIndex
              ? {
                  ...row,
                  [schemaId]: value,
                }
              : row;
          }),
        },
      };
    },
  },
});

export const {
  mappingDataTables,
  setDraftDataTable,
  updateDraftDataTable,
  updateDraftDataTableRow,
} = dataTableSlice.actions;
const dataTableReducer = dataTableSlice.reducer;
// #endregion

// #region History reducer
// Note (Noah, 2022-08-19): These are not exported because they should not be used
// by themselves - they need to be used with createElementUpdatingThunk so that they
// get autosaved
const _undoOperation = createAction("history/undo");
const _redoOperation = createAction("history/redo");

export const undoOperation = () => {
  return createElementUpdatingThunk(_undoOperation(), (getState) => {
    const state = getState();
    const nextOperation =
      state.core.history.operations[state.core.history.index - 1];
    return nextOperation?.elementId ?? state.core.elements.draftElementId;
  });
};

export const redoOperation = () => {
  return createElementUpdatingThunk(_redoOperation(), (getState) => {
    const state = getState();
    const nextOperation =
      state.core.history.operations[state.core.history.index + 1];
    return nextOperation?.elementId ?? state.core.elements.draftElementId;
  });
};

export const resetHistory = createAction("history/reset");

const historyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(_redoOperation, (state) => {
      // Early exit if there is nothing to redo
      if (!state.history.operations[state.history.index + 1]) {
        return;
      }

      const patch = state.history.operations[state.history.index + 1]!.redo;
      const next = applyPatches(state.elements.mapping, patch);

      const draftElementId = state.elements.draftElementId;
      const draftComponent =
        draftElementId && state.elements.mapping[draftElementId]?.component;
      // NOTE (Sebas, 2025-01-10): Recalculate the component data mapping and colors
      // on redo operation.
      if (draftComponent) {
        const { componentDataMapping, componentColors, componentFontFamilies } =
          getComponentData({
            component: draftComponent as Component,
            context: {
              symbols: state.symbols.mapping,
              componentDataMapping: state.componentDataMapping,
            },
          });
        state.componentDataMapping = componentDataMapping;
        state.elements.draftElementColors = [...componentColors];
        state.elements.draftElementFontFamilies = [...componentFontFamilies];
      }

      state.elements.mapping = next;
      state.history.index = state.history.index + 1;
      state.history.lastOperation = "redo";
    })
    .addCase(_undoOperation, (state) => {
      // Early exit if there is nothing to undo
      if (!state.history.operations[state.history.index]) {
        return;
      }

      const patch = state.history.operations[state.history.index]!.undo;
      const next = applyPatches(state.elements.mapping, patch);

      const draftElementId = state.elements.draftElementId;
      const draftComponent =
        draftElementId && state.elements.mapping[draftElementId]?.component;
      // NOTE (Sebas, 2025-01-10): Recalculate the component data mapping and colors
      // on undo operation.
      if (draftComponent) {
        const { componentDataMapping, componentColors, componentFontFamilies } =
          getComponentData({
            component: draftComponent as Component,
            context: {
              symbols: state.symbols.mapping,
              componentDataMapping: state.componentDataMapping,
            },
          });
        state.componentDataMapping = componentDataMapping;
        state.elements.draftElementColors = [...componentColors];
        state.elements.draftElementFontFamilies = [...componentFontFamilies];
      }

      state.elements.mapping = next;
      state.history.index = state.history.index - 1;
      state.history.lastOperation = "undo";
    })
    .addCase(resetHistory, (state) => {
      state.history = INITIAL_HISTORY_STATE;
    });
});
// #endregion

export const coreReducer = reduceReducers<CoreState>(
  initialState,
  defaultReducer as Reducer<CoreState>,
  historyReducer,
  elementsReducer,
  editorOptionsReducer,
  componentReducer,
  dataTableReducer,
) as Reducer<CoreState>;

// #region Basic selectors
const selectElementsMapping = (state: EditorRootState) => {
  return state.core.elements.mapping;
};

/**
 * Select the current draft element as an object. WARNING: this selector should
 * usually not be used, since it will cause whatever component its used it to
 * re-render every time anything about any component of the element changes, since
 * that will result in a different object in-memory for the element. Instead of
 * using this, usually you should write a selector that returns the thing you want
 * about the element as a memoizable value (string, number, etc)
 */
export const selectDraftElement_warningThisWillRerenderOnEveryUpdate = (
  state: EditorRootState,
) => {
  return selectDraftElementFromCoreState(state.core);
};

export const selectDraftComponentIds = (state: EditorRootState) => {
  return state.core.elements.draftComponentIds;
};

export const selectAreMultipleDraftComponents = (state: EditorRootState) => {
  return state.core.elements.draftComponentIds.length > 1;
};

export const selectDraftComponentId = (state: EditorRootState) => {
  return state.core.elements.draftComponentIds[0] ?? null;
};

export const selectDraftRepeatedIndex = (state: EditorRootState) => {
  return state.core.elements.draftRepeatedIndex;
};

const selectDraftRepeatedIndexLastComponent = (state: EditorRootState) => {
  const repeatedIndexPath = state.core.elements.draftRepeatedIndex;
  if (!repeatedIndexPath) {
    return null;
  }
  const repeatedIndexComponents = repeatedIndexPath.split(".");
  return Number(repeatedIndexComponents[repeatedIndexComponents.length - 1]);
};

const selectDraftSymbolInstanceId = (state: EditorRootState) => {
  return state.core.elements.draftSymbolInstanceId;
};

export const selectSymbolsMapping = (state: EditorRootState) => {
  return state.core.symbols.mapping;
};

export const selectComponentDataMapping = (state: EditorRootState) => {
  return state.core.componentDataMapping;
};

export const selectComponentIdToDraftVariantId = (state: EditorRootState) => {
  return state.core.elements.componentIdToDraftVariantId;
};

const selectStore = (state: EditorRootState) =>
  getStoreData(state.core.project);

export const selectStoreDoesNotHaveReadFilesAccess = createSelector(
  selectStore,
  // Note (Noah, 2022-09-12, REPL-4086): If we don't have the read_files permission
  // then the server will not be able to return file assets, so there's no point in
  // requesting them
  (store) => !store?.shopifyAccessScopes?.includes("read_files"),
);

export const selectIsShopifyIntegrationEnabled = (state: EditorRootState) =>
  isShopifyIntegrationEnabled(state.core.project);

export const selectProject = (state: EditorRootState) => state.core.project;

export const selectProjectId = (state: EditorRootState) => {
  return state.core.project?.id;
};

export const selectProjectHasShopifyIntegration = (state: EditorRootState) => {
  return Boolean(state.core.project?.integrations?.shopify);
};

export const selectStoreUrl = (state: EditorRootState) => {
  return getStoreData(state.core.project)?.url;
};

export const selectStoreShopifyUrl = (state: EditorRootState) =>
  getStoreData(state.core.project)?.shopifyUrl;

export const selectElementIsLoading = (state: EditorRootState) =>
  state.core.elements.isLoading;

export const selectIsPublishing = (state: EditorRootState) =>
  state.core.isPublishing;

export const selectSelectedRevisionId = (state: EditorRootState) =>
  state.core.elements.selectedRevisionId;

export const selectSelectedArchivedElementId = (state: EditorRootState) =>
  state.core.elements.selectedArchivedElementId;

export const selectPendingElementUpdatesSize = (state: EditorRootState) =>
  state.core.pendingElementUpdates.length;

/**
 * Select the draft component from the state, then return all the DOM nodes
 * which represent it. There may be multiple DOM nodes if the draft component is
 * inside a repeated list.
 *
 * @returns The nodes which represent the draft component, and the node which
 * the user clicked on to select it.
 *
 * TODO (Noah, 2022-06-12, REPL-2602): This selector is intentionally NOT
 * memoized since if we memoize it, there are problems when you pass the result
 * into a mutation observer - if the draft component just changed position,
 * we'll render a new element and thus get a new node, but that new node won't
 * appear until after React renders our tree, which means the result of the
 * memoized selector will be the stale node that USED to represent the draft
 * component before we moved it. Not memoizing means that we'll always get the
 * most up-to-date DOM node. There's probably some better way to do this, maybe
 * with useLayoutEffect?
 */
const selectDraftComponentNodes = (
  state: EditorRootState,
  canvas: EditorCanvas,
) => {
  const draftElementId = selectDraftElementId(state);
  const draftComponentId = selectDraftComponentId(state);
  if (!draftElementId || !draftComponentId) {
    return [];
  }

  const nodes = getEditorComponentNodes({
    canvas,
    componentId: draftComponentId,
  });
  return nodes;
};

const selectDraftComponentsNodes = (
  state: EditorRootState,
  canvas: EditorCanvas,
) => {
  const draftComponents = selectDraftComponents(state);

  const nodes = draftComponents.map((component) => {
    return getEditorComponentNodes({
      canvas,
      componentId: component.id,
    });
  });
  return nodes;
};

export const selectDataTablesMapping = (state: EditorRootState) => {
  return state.core.dataTables.mapping;
};

export const selectDataTables = (state: EditorRootState) => {
  return state.core.dataTables;
};

export const selectDraftDataTable = (state: EditorRootState) => {
  return state.core.dataTables.draft;
};

export const selectDraftComponentHasParent = (state: EditorRootState) =>
  isNotNullish(selectDraftParentComponentId(state));

// Special selector used in places where only the core state is available
export const selectDraftElementFromCoreState = (state: CoreState) => {
  return getFromRecordOrNull(
    state.elements.mapping,
    state.elements.draftElementId,
  );
};

export const selectUpdatesSinceLastRequestFinishedFromCoreState = (
  state: CoreState,
) => state.updatesSinceLastRequestFinished;

export const selectUpdatesSinceLastRequestFinished = (state: EditorRootState) =>
  selectUpdatesSinceLastRequestFinishedFromCoreState(state.core);

const selectVersionMapping = (state: EditorRootState) => {
  return state.core.elements.versionMapping;
};

export const selectIsPreviewMode = (state: EditorRootState) => {
  return state.core.isPreviewMode;
};

export const selectEditorMode = (state: EditorRootState) =>
  state.core.editorMode;

export const selectInternalDebugModeOn = (state: EditorRootState) => {
  return state.core.editorOptions.isDebugPanelVisible;
};

const selectHistoryState = (state: EditorRootState) => state.core.history;

export const selectIsElementUpdateInProgressOrQueued = (
  state: EditorRootState,
) => {
  return (
    state.core.elementUpdateInProgress ||
    state.core.pendingElementUpdates.length > 0
  );
};

export const selectDraftElementColors = (state: EditorRootState) => {
  return state.core.elements.draftElementColors;
};

export const selectDraftElementFontFamilies = (state: EditorRootState) => {
  return state.core.elements.draftElementFontFamilies;
};

export const selectDraftElementVersionFromCoreState = (state: CoreState) => {
  if (!state.elements.draftElementId) {
    return null;
  }
  return state.elements.versionMapping[state.elements.draftElementId];
};

export const selectStreamingUpdate = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate;
};

export const selectStreamingUpdateTextMap = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate?.textMap;
};

export const selectStreamingUpdateComponentIdsSelected = (
  state: EditorRootState,
) => {
  return state.core.elements.streamingUpdate?.componentIdsSelected;
};

export const selectStreamingUpdateActions = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate?.actions;
};

export const selectStreamingUpdateIsStreaming = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate?.isStreaming;
};

export const selectStreamingUpdateId = (state: EditorRootState) => {
  return state.core.elements.streamingUpdate?.id;
};

export const selectSrcDocFontUrls = (state: EditorRootState) => {
  return state.core.elements.srcDocFontUrls;
};

export const selectSrcDocFonts = (state: EditorRootState) => {
  return state.core.elements.srcDocFonts;
};

export const selectSrcDocRootFont = (state: EditorRootState) => {
  return state.core.elements.srcDocRootFont;
};

// #endregion

// #region Memoized selectors

export const selectIsDraftComponent = createSelector(
  selectDraftComponentIds,
  (_: EditorRootState, componentId: Component["id"] | null) => componentId,
  (draftComponentIds, componentId) => {
    return componentId ? draftComponentIds.includes(componentId) : false;
  },
);

export const selectComponentMapping = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    return getComponentMappingFromElement(draftElement);
  },
);

export const selectDraftElementHashmarks = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    if (!draftElement) {
      return [];
    }
    const hashmarks = new Set<string>();
    forEachComponentAndDescendants(draftElement.component, (component) => {
      if (component.props._urlHashmark) {
        hashmarks.add(component.props._urlHashmark);
      }
      const componentVariantOverrides = component.variantOverrides;
      if (componentVariantOverrides) {
        Object.values(componentVariantOverrides).forEach(
          ({ componentOverrides }) => {
            if (componentOverrides) {
              Object.values(componentOverrides).forEach(({ props }) => {
                const urlHashmark = props?._urlHashmark;
                if (urlHashmark) {
                  hashmarks.add(urlHashmark);
                }
              });
            }
          },
        );
      }
    });
    return Array.from(hashmarks);
  },
);

const selectIsLoading = (state: EditorRootState) => {
  return state.core.isLoading;
};

export const selectLoadableProject = createSelector(
  selectProject,
  selectIsLoading,
  (project, isLoading) => ({
    isLoading,
    project,
  }),
);

export const selectDraftComponentAccordionAncestor = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponentId,
  (element, componentId) => {
    return findAncestorComponent(
      element,
      componentId,
      ({ type }) => type === "accordionBlock",
    );
  },
);

export const selectTemplateShopifyProductIdsLength = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    return draftElement?.templateShopifyProductIds?.length ?? 0;
  },
);

const selectGetAttributeDependencies = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectComponentDataMapping,
  selectComponentMapping,
  selectDraftSymbolInstanceId,
  selectDraftComponentId,
  selectSymbolsMapping,
  selectComponentIdToDraftVariantId,
  (
    element,
    componentDataMapping,
    componentMapping,
    draftSymbolInstanceId,
    draftComponentId,
    symbolMapping,
    componentIdToDraftVariantId,
  ): Omit<GetAttributeDependencies, "activeCanvas"> => {
    return {
      element: element as ReploElement,
      componentDataMapping,
      componentMapping,
      draftSymbolInstanceId,
      symbolMapping,
      draftComponentId,
      componentIdToDraftVariantId,
    };
  },
);

function memoizeSelectGetAttribute(
  draftElement: ReploElement | null,
  dependencies: GetAttributeDependencies,
): GetAttributeFunction {
  // Note (Noah, 2022-02-21): Important that this selector is memoized,
  // because otherwise all components which used this selector would
  // rerender on every redux change, since functions are not memoizable
  return (component, attribute, defaults) => {
    return memoizedGetAttributeRetriever(
      component,
      dependencies,
      draftElement ? objectId(draftElement) : null,
    )(attribute, defaults);
  };
}

export const selectGetAttribute = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectGetAttributeDependencies,
  selectActiveCanvas,
  (draftElement, dependencies, activeCanvas): GetAttributeFunction => {
    return memoizeSelectGetAttribute(draftElement, {
      ...dependencies,
      activeCanvas,
    });
  },
);

export const selectGetAttributeForCanvas = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectGetAttributeDependencies,
  (_state: EditorRootState, canvas: EditorCanvas) => canvas,
  (draftElement, dependencies, canvas): GetAttributeFunction => {
    return memoizeSelectGetAttribute(draftElement, {
      ...dependencies,
      activeCanvas: canvas,
    });
  },
);

export const selectDraftElementId = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.id,
);

export const selectDraftElementCurrentVersion = createSelector(
  selectDraftElementId,
  selectVersionMapping,
  (draftElementId, versionMapping) => {
    if (!draftElementId) {
      return null;
    }
    // NOTE (Kurt, 2024-12-13): We use the version mapping here to get the current
    // version of the draft element. The reason why we can't simply do
    // `draftElement.version` is because the version mapping is not necessarily
    // updated synchronously with the draft element. This ensures that we always
    // get the latest version of the draft element.
    return versionMapping[draftElementId];
  },
);

export const selectProductsIdsFromDraftElement = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDataTablesMapping,
  selectTemplateEditorProduct,
  selectLocaleData,
  selectTemplateEditorStoreProduct,
  selectStreamingUpdateActions,
  (
    draftElement,
    dataTablesMapping,
    templateEditorProduct,
    locale,
    templateProduct,
    actions,
  ) => {
    if (draftElement) {
      // TODO (Gabe 2024-09-16): We shouldn't have to call calculateDependencies
      // here. We should create a different function for obtaining the
      // productIds referenced by a component.
      const { dependencies: dependenciesMap } = calculateDependencies(
        draftElement.component,
        {
          dataTables: dataTablesMapping,
          productResolutionDependencies: {
            products: [],
            currencyCode: locale.activeCurrency,
            moneyFormat: locale.moneyFormat,
            language: locale.activeLanguage,
            templateProduct: templateProduct ?? null,
            isEditor: true,
            isShopifyProductsLoading: false,
          },
          // Note (Noah, 2022-11-26): It's okay to send an empty mapping of metafield types
          // here because this call to calculateDependencies is only used to know which _products_
          // to fetch, and doesn't care about metafields
          metafieldsNamespaceKeyTypeMapping: { product: {}, variant: {} },
        },
      );
      const productsDependency = Object.values(dependenciesMap)
        .flat()
        .filter(
          (dependency) => dependency.type === DependencyType.products,
        ) as ProductsDependency[];

      let productIds = productsDependency.reduce(
        (productsIdArray: (string | number)[], dependency) => {
          return [...productsIdArray, ...dependency.productIds];
        },
        [],
      );

      // NOTE (Evan, 8/18/23) We have to add the template editor product as a
      // dependency for product templates
      if (
        draftElement.type === "shopifyProductTemplate" &&
        templateEditorProduct
      ) {
        // NOTE (Gabe 2023-08-28): The Template product must be the first
        // product in the array. This is because getProduct assumes that the
        // first product is the templatized product.
        productIds = [Number(templateEditorProduct.productId), ...productIds];
      }

      // Patrick (2025-03-05): These product ids are necessary in order to optimistically set a product from the LLM
      // we need to add it to the list of product ids to fetch
      actions?.forEach((action) => {
        // @ts-ignore Patrick (2025-03-05): These actions aren't typed properly so it doesn't have value in it but it should
        if (action.value?._product) {
          // @ts-ignore Patrick (2025-03-05): These actions aren't typed properly so it doesn't have value in it but it should
          productIds.push(action.value._product.productId);
        }
      });

      return productIds;
    }
    return [];
  },
);

export const selectRootComponentId = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.component?.id ?? null,
);

export const selectDoesExistDraftElement = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  Boolean,
);

export const selectRootComponent = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.component,
);

export const selectDraftElementProjectId = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.projectId,
);

export const selectDraftElementName = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    return draftElement?.name ?? "";
  },
);

export const selectDraftElementType = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.type || "page",
);

export const selectDraftElementIsPublished = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.isPublished,
);

export const selectDraftElementUseSectionSettings = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => draftElement?.useSectionSettings,
);

export const selectDraftComponent = createSelector(
  selectComponentMapping,
  selectDraftComponentId,
  (componentMapping, draftComponentId) => {
    return (
      getFromRecordOrNull(componentMapping, draftComponentId)?.component ?? null
    );
  },
);

export const selectDraftComponents = createSelector(
  selectComponentMapping,
  selectDraftComponentIds,
  (componentMapping, draftComponentIds) => {
    return filterNulls(
      draftComponentIds.map(
        (id) => getFromRecordOrNull(componentMapping, id)?.component ?? null,
      ),
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const selectDraftComponentContext = createSelector(
  selectDraftComponentId,
  selectDraftRepeatedIndexLastComponent,
  (draftComponentId, draftRepeatedIndex) => {
    return (
      getCurrentComponentContext(draftComponentId, draftRepeatedIndex ?? 0) ??
      null
    );
  },
);

export type ComponentNameID = { id: string; name: string };
function createAllComponentNameOfSpecificTypeSelector(
  componentType: ReploComponentType,
) {
  return createSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
    (draftElement) => {
      if (!draftElement || !componentType) {
        return [];
      }
      const possibleComponents: ComponentNameID[] = [];
      forEachComponentAndDescendants(draftElement.component, (component) => {
        if (component.type === componentType) {
          possibleComponents.push({
            id: component.id,
            name: getComponentName(component, null),
          });
        }
      });
      return possibleComponents;
    },
  );
}
export const selectAllTextComponentNames =
  createAllComponentNameOfSpecificTypeSelector("text");

export const selectComponent = createSelector(
  selectComponentMapping,
  (_: EditorRootState, componentId: string) => componentId,
  (componentMapping, componentId) => {
    return (
      getFromRecordOrNull(componentMapping, componentId)?.component ?? null
    );
  },
);

export const selectDescendantComponentIds = createSelector(
  selectComponent,
  (component) => {
    if (!component) {
      return [];
    }
    const descendantComponentIds: string[] = [];
    forEachComponentAndDescendants(component, (child) => {
      descendantComponentIds.push(child.id);
    });
    return descendantComponentIds;
  },
);

export const selectAncestorComponentIds = createSelector(
  selectComponent,
  selectComponentDataMapping,
  (component, componentDataMapping) => {
    if (!component) {
      return [];
    }
    return (
      componentDataMapping[component.id]?.ancestorComponentData.map(
        (ancestorComponentData) => ancestorComponentData[0],
      ) ?? []
    );
  },
);

export const selectComponentData = createSelector(
  selectComponentDataMapping,
  (_: EditorRootState, componentId: string) => componentId,
  (componentDataMapping, componentId) => {
    return getFromRecordOrNull(componentDataMapping, componentId) ?? null;
  },
);

export const selectComponentHasChildrenInTree = createSelector(
  selectComponent,
  selectComponentData,
  (component, componentData) => {
    if (!component) {
      return false;
    }

    // NOTE (Ovishek, 2022-04-26): We are only showing the first child in the tree if
    // there's a dynamic data involved, b/c in that case we are only using that first child
    // to dynamically render the others.
    // TODO (Martin, 2024-03-29): We should probably do this for more than the
    // "carouselV3Slides" component type.
    const children =
      component.type === "carouselV3Slides" &&
      componentData?.ancestorIsDynamicRepetition
        ? component.children?.slice(0, 1)
        : component.children;

    const childrenNodesFromProps = filterNulls(
      componentData?.customPropDefinitions
        .filter((definition) => definition.type === "component")
        .map((definition) => component.props[definition.id] as Component),
    );

    return (children?.length ?? 0) > 0 || childrenNodesFromProps.length > 0;
  },
);

type ReploComponentIssueWithComponentData = {
  componentId: string;
  componentData: ComponentData;
  issues: ReploComponentIssue[];
};

export const selectUnpublishableComponentIssues = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectComponentDataMapping,
  selectComponentMapping,
  selectLocaleData,
  selectTemplateEditorStoreProduct,
  (_state: EditorRootState, products: StoreProduct[]) => products,
  (
    draftElement,
    componentDataMapping,
    componentMapping,
    locale,
    templateProduct,
    products,
  ) => {
    const componentErrors: ReploComponentIssueWithComponentData[] = [];
    if (!componentDataMapping || !draftElement) {
      return componentErrors;
    }
    return Object.entries(componentDataMapping).reduce(
      (
        componentErrors: ReploComponentIssueWithComponentData[],
        [componentId, componentData],
      ) => {
        const mappingEntry = componentMapping[componentId];
        if (!mappingEntry) {
          return componentErrors;
        }
        const issues = getIssuesForComponent({
          type: "unpublishable",
          component: mappingEntry.component,
          context: {
            useSectionSettings:
              draftElement.useSectionSettings &&
              draftElement.type === "shopifySection",
            shopify: {
              products,
              locale,
              templateProduct: templateProduct ?? null,
            },
          },
        });
        if (issues.length > 0) {
          componentErrors.push({
            componentId,
            componentData,
            issues,
          });
        }
        return componentErrors;
      },
      [],
    );
  },
);

export const selectDraftComponentName = createSelector(
  selectDraftComponent,
  selectSymbolsMapping,
  (draftComponent, symbolsMapping) => {
    if (!draftComponent) {
      return null;
    }

    return getComponentName(draftComponent, symbolsMapping);
  },
);

export const selectDraftComponentVariants = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponents,
  selectSymbolsMapping,
  selectComponentIdToDraftVariantId,
  (
    draftElement,
    draftComponents,
    symbolsMapping,
    componentIdToDraftVariantId,
  ): VariantWithState[] | ReploMixedStyleValue => {
    if (draftComponents.length === 0 || !draftElement) {
      return [];
    }

    const { firstValue, hasMixedValues } = checkForMixedValues(
      draftComponents,
      (draftComponent) =>
        getVariantsWithState(
          getVariants(draftComponent, symbolsMapping),
          componentIdToDraftVariantId[draftComponent.id]!,
        ),
    );

    return hasMixedValues ? REPLO_MIXED_STYLE_VALUE : firstValue;
  },
);

export const selectDraftComponentHasVariants = createSelector(
  selectDraftComponentVariants,
  (variants) => {
    return isMixedStyleValue(variants) || variants.length > 1;
  },
);

const selectDraftComponentOrAncestorWithVariants = createSelector(
  selectComponentDataMapping,
  selectComponentMapping,
  selectDraftComponentId,
  selectSymbolsMapping,
  (
    componentDataMapping,
    componentMapping,
    draftComponentId,
    symbolsMapping,
  ): Component | null => {
    if (!draftComponentId) {
      return null;
    }

    const ancestorOrSelfWithVariantsId =
      componentDataMapping[draftComponentId]?.ancestorOrSelfWithVariantsId;
    const component = getFromRecordOrNull(
      componentMapping,
      ancestorOrSelfWithVariantsId,
    )?.component;

    if (!component) {
      return null;
    }

    return {
      ...component,
      name: getComponentName(component, symbolsMapping),
    };
  },
);

export const selectDraftComponentOrAncestorVariants = createSelector(
  selectDraftComponentOrAncestorWithVariants,
  selectSymbolsMapping,
  selectComponentIdToDraftVariantId,
  (component, symbolsMapping, componentIdToDraftVariantId) => {
    if (!component) {
      return [];
    }

    return getVariantsWithState(
      getVariants(component, symbolsMapping),
      componentIdToDraftVariantId[component.id]!,
    );
  },
);

export const selectDraftComponentOrAncestorVariantId = createSelector(
  selectDraftComponentOrAncestorVariants,
  (selectDraftComponentOrAncestorVariants) => {
    if (!selectDraftComponentOrAncestorVariants) {
      return undefined;
    }
    return selectDraftComponentOrAncestorVariants.find(
      (variant) => variant.isActive,
    )?.id;
  },
);

const selectDraftComponentOrAncestorVariant = createSelector(
  selectDraftComponentOrAncestorVariants,
  (selectDraftComponentOrAncestorVariants) => {
    if (!selectDraftComponentOrAncestorVariants) {
      return null;
    }
    const activeVariant = selectDraftComponentOrAncestorVariants.find(
      (variant) => variant.isActive,
    );
    if (!activeVariant) {
      return null;
    }
    return {
      id: activeVariant.id,
      name: activeVariant.name,
    };
  },
);

export const selectNearestComponentWithVariantsId = createSelector(
  selectDraftComponentId,
  selectComponentDataMapping,
  (draftComponentId, componentDataMapping) => {
    if (!draftComponentId) {
      return null;
    }

    return componentDataMapping[draftComponentId]?.ancestorOrSelfWithVariantsId;
  },
);

export const selectNearestAncestorComponentWithVariants = createSelector(
  selectComponentIdToDraftVariantId,
  selectDraftComponentOrAncestorWithVariants,
  selectDraftComponentId,
  (
    componentIdToDraftVariantId,
    ancestorComponentWithVariants,
    draftComponentId,
  ) => {
    const ancestorComponentId = ancestorComponentWithVariants?.id;
    if (ancestorComponentId === draftComponentId) {
      return {
        variantsWithState: [],
        ancestorComponentId: null,
        ancestorComponentName: null,
      };
    }
    const ancestorVariants = ancestorComponentWithVariants?.variants ?? [];
    const ancestorComponentName = ancestorComponentWithVariants?.name;
    return {
      variantsWithState: getVariantsWithState(
        ancestorVariants,
        componentIdToDraftVariantId[ancestorComponentId!]!,
      ),
      ancestorComponentId,
      ancestorComponentName,
    };
  },
);

export const selectNearestAncestorHasVariants = createSelector(
  selectNearestAncestorComponentWithVariants,
  (ancestorComponentWithVariants) => {
    return ancestorComponentWithVariants.variantsWithState.length > 1;
  },
);

export const selectDraftComponentChildren = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (!draftComponent) {
      return [];
    }
    return draftComponent.children ?? [];
  },
);

export const selectPredefinedVariantsForDraftComponent = createSelector(
  selectDraftComponent,
  selectComponentDataMapping,
  selectDraftComponentOrAncestorVariants,
  (draftComponent, componentDataMapping, selfOrAncestorVariants) => {
    if (!draftComponent) {
      return [];
    }

    const componentData = componentDataMapping[draftComponent.id];

    const hasHoverVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "interaction.hover",
    );
    const predefinedVariants: PredefinedVariant[] = !hasHoverVariant
      ? [buildPredefinedVariant("hover")]
      : [];

    // NOTE (Fran 2024-04-15): It might be nice to convert this to an exhaustive switch in the future. For
    // now, I think it is okay to have only one if statement.
    const hasLoadingVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.action.loading",
    );
    if (draftComponent?.type === "button" && !hasLoadingVariant) {
      predefinedVariants.push(buildPredefinedVariant("loading"));
    }

    const hasProductAncestor = componentData?.ancestorComponentData.some(
      (ancestorComponentType) => ancestorComponentType.includes("product"),
    );
    const hasOutOfStockVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.product.selectedVariantUnavailable",
    );
    if (hasProductAncestor && !hasOutOfStockVariant) {
      predefinedVariants.push(buildPredefinedVariant("outOfStock"));
    }

    const hasTabAncestor = componentData?.ancestorComponentData.some(
      (ancestorComponentType) =>
        ancestorComponentType.includes("tabsV2__block") ||
        ancestorComponentType.includes("tabsV2__list") ||
        ancestorComponentType.includes("tabsV2__panelsContent"),
    );
    const hasActiveTabVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.tabsV2Block.isCurrentTab",
    );
    if (hasTabAncestor && !hasActiveTabVariant) {
      predefinedVariants.push(buildPredefinedVariant("activeTab"));
    }

    const hasOptionsAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "optionSelect",
    );
    const hasSelectedOptionVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.product.selectedOptionValues",
    );
    if (hasOptionsAncestor && !hasSelectedOptionVariant) {
      predefinedVariants.push(buildPredefinedVariant("selectedOption"));
    }

    const hasVariantAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "variantSelect",
    );
    const hasSelectedVariantVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.product.selectedVariant",
    );
    if (hasVariantAncestor && !hasSelectedVariantVariant) {
      predefinedVariants.push(buildPredefinedVariant("selectedVariant"));
    }

    const hasSellingPlanAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "sellingPlanSelect",
    );
    const hasSelectedSellingPlanVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.product.selectedSellingPlan",
    );
    if (hasSellingPlanAncestor && !hasSelectedSellingPlanVariant) {
      predefinedVariants.push(buildPredefinedVariant("selectedSellingPlan"));
    }

    const hasCollapsibleAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "collapsibleV2",
    );
    const hasCollapsibleOpenVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.collapsibleV2.isOpen",
    );
    if (hasCollapsibleAncestor && !hasCollapsibleOpenVariant) {
      predefinedVariants.push(buildPredefinedVariant("collapsibleOpen"));
    }

    const hasBeforeAndAfterAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "beforeAfterSliderThumb",
    );
    const hasBeforeAndAfterDraggingVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.beforeAfterSlider.isDragging",
    );
    if (hasBeforeAndAfterAncestor && !hasBeforeAndAfterDraggingVariant) {
      predefinedVariants.push(buildPredefinedVariant("beforeAfterDragging"));
    }

    const hasTooltipAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "tooltip",
    );
    const hasTooltipOpenVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.tooltip.isOpen",
    );
    if (hasTooltipAncestor && !hasTooltipOpenVariant) {
      predefinedVariants.push(buildPredefinedVariant("tooltipOpen"));
    }

    const hasSelectionListAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "selectionList",
    );
    const hasSelectedOptionListVariant = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.selectionList.isItemSelected",
    );
    if (hasSelectionListAncestor && !hasSelectedOptionListVariant) {
      predefinedVariants.push(buildPredefinedVariant("selectedListItem"));
    }

    const hasCarouselAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "carouselV3",
    );
    const hasSelectedOptionFirstItemIsActive = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.group.isFirstItemActive",
    );
    const hasSelectedOptionLastItemIsActive = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.group.isLastItemActive",
    );
    if (hasCarouselAncestor && !hasSelectedOptionFirstItemIsActive) {
      predefinedVariants.push(buildPredefinedVariant("firstItemIsActive"));
    }
    if (hasCarouselAncestor && !hasSelectedOptionLastItemIsActive) {
      predefinedVariants.push(buildPredefinedVariant("lastItemIsActive"));
    }

    const hasCarouselSlidesAncestor = componentData?.ancestorComponentData.some(
      ([, ancestorType]) => ancestorType === "carouselV3Slides",
    );
    const hasSelectedOptionCurrentItemIsActive = hasVariantWithConditionField(
      selfOrAncestorVariants,
      "state.group.isCurrentItemActive",
    );
    if (hasCarouselSlidesAncestor && !hasSelectedOptionCurrentItemIsActive) {
      predefinedVariants.push(buildPredefinedVariant("currentItemIsActive"));
    }

    return predefinedVariants;
  },
);

export const selectDraftComponentConditionFields = createSelector(
  selectDraftComponent,
  selectComponentDataMapping,
  selectDraftElementType,
  selectGetAttribute,
  selectPredefinedVariantsForDraftComponent,
  (
    draftComponent,
    componentDataMapping,
    draftElementType,
    getAttribute,
    predefinedVariants,
  ) => {
    if (draftComponent) {
      const conditionFields: Set<ConditionField> = new Set([
        "screen.pageY",
        "element.offsetY",
        "page.hashmark",
        "interaction.hover",
        ...getComponentConditionFields(draftComponent, componentDataMapping),
      ]);
      if (draftElementType === "shopifyProductTemplate") {
        conditionFields.add("state.product.templateProductEquals");
      }
      if (getAttribute(draftComponent, "props.onClick").value) {
        conditionFields.add("state.action.loading");
      }

      // NOTE (Fran 2024-04-30): Adding predefined variant condition fields to the draft component
      // condition fields allows the user to create a variant based on the predefined variants.
      // Otherwise, there are some cases where, for example, a button can have a predefined variant
      // for loading, but the user won't see it in the variant edition popover.
      for (const predefinedVariant of predefinedVariants) {
        conditionFields.add(predefinedVariant.query.statements[0]!.field);
      }

      return Array.from(conditionFields);
    }
    return [];
  },
);

export const selectDraftComponentText = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    if (draftComponents.length === 0) {
      return null;
    }

    const { firstValue, hasMixedValues } = checkForMixedValues(
      draftComponents,
      (component) => getAttribute(component, "props.text")?.value ?? null,
    );

    if (hasMixedValues) {
      return REPLO_MIXED_STYLE_VALUE;
    }

    return firstValue;
  },
);

export const selectDraftComponentTextDynamicDataInfo = createSelector(
  selectDraftComponentText,
  (text) => {
    if (!text) {
      return null;
    }
    return getNonDesignLibraryDynamicDataInfo(text);
  },
);

export const selectDraftComponentsInnerTextsAndIds = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    if (draftComponents.length === 0) {
      return null;
    }

    const textsAndIds = draftComponents.map((component) => {
      const text = getAttribute(component, "props.text")?.value ?? null;
      const innerText = text ? extractInnerTextForDesignLibrary(text) : null;
      const appliedSavedStyleId = getSavedStyleId(text);

      return {
        id: component.id,
        text: innerText,
        appliedSavedStyleId,
      };
    });

    return textsAndIds;
  },
);

export const selectDraftComponentTextTagWithMixedValues = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    if (draftComponents.length === 0) {
      return null;
    }

    const { firstValue, hasMixedValues } = checkForMixedValues(
      draftComponents,
      (component) => {
        const textValue = getAttribute(component, "props.text")?.value ?? null;

        if (isDynamicDesignLibraryValue(textValue)) {
          // NOTE (Fran 2025-01-15): We don't support multiple saved styles references inside the text
          // prop, so is safe to get only the first expression. We can assume this value always will be
          // defined because we are checking the condition before calling this selector.
          return getDesignLibraryDynamicDataExpressions(textValue)![0]!;
        }

        const tagMatch = textValue?.match(/^<(\w+)>/);
        if (!tagMatch) {
          return null;
        }

        const [, tagName] = tagMatch;

        // NOTE (Martin 2025-02-06): If the tag is a heading, we want to return
        // only the heading number
        if (tagName.startsWith("h")) {
          const headingLevel = tagName.match(/\d/)?.[0];
          return headingLevel ?? null;
        }

        return tagName;
      },
    );

    if (hasMixedValues) {
      return REPLO_MIXED_STYLE_VALUE;
    }
    return firstValue ? firstValue.toUpperCase() : null;
  },
);

export const selectDraftComponentTextTag = createSelector(
  selectDraftComponentText,
  (text): RichTextEditorTag | undefined => {
    if (!text || isMixedStyleValue(text)) {
      return undefined;
    }
    if (text.startsWith("<h1>")) {
      return "1";
    } else if (text.startsWith("<h2>")) {
      return "2";
    } else if (text.startsWith("<h3>")) {
      return "3";
    } else if (text.startsWith("<h4>")) {
      return "4";
    } else if (text.startsWith("<h5>")) {
      return "5";
    } else if (text.startsWith("<h6>")) {
      return "6";
    }
    return "P";
  },
);

export const selectDraftParentComponent = createSelector(
  selectComponentMapping,
  selectDraftComponentId,
  (componentMapping, draftComponentId) => {
    return componentMapping[String(draftComponentId)]?.parentComponent || null;
  },
);

const selectDraftParentComponentId = createSelector(
  selectDraftParentComponent,
  (draftParentComponent) => {
    if (!draftParentComponent) {
      return null;
    }

    return draftParentComponent.id;
  },
);

export const selectedDraftComponentIsRoot = createSelector(
  selectDraftComponentHasParent,
  (draftComponentHasParent) => {
    return !draftComponentHasParent;
  },
);

export const selectDraftComponentsNode = createSelector(
  selectDraftComponentsNodes,
  selectDraftRepeatedIndex,
  (elements, draftRepeatedIndex) => {
    const selectedNode = elements.map((nodes) => {
      if (nodes.length === 0) {
        return null;
      }

      const selectedNode: HTMLElement | undefined =
        nodes.find((n) => {
          return n.dataset.reploRepeatedIndex === draftRepeatedIndex;
        }) ?? nodes[0];

      return selectedNode || null;
    });

    return selectedNode || null;
  },
);

export const selectDraftComponentNode = createSelector(
  selectDraftComponentNodes,
  selectDraftRepeatedIndex,
  (nodes, draftRepeatedIndex) => {
    if (nodes.length === 0) {
      return null;
    }

    const selectedNode: HTMLElement | undefined =
      nodes.find((n) => n.dataset.reploRepeatedIndex === draftRepeatedIndex) ??
      nodes[0];

    return selectedNode || null;
  },
);

export const selectDraftComponentNodeFromActiveCanvas = createSelector(
  (state: EditorRootState) => state,
  selectActiveCanvas,
  (state, activeCanvas) => {
    return selectDraftComponentNode(state, activeCanvas);
  },
);

export const selectDraftParentComponentNode = createSelector(
  selectDraftElementId,
  selectDraftParentComponentId,
  selectDraftRepeatedIndex,
  (_state: EditorRootState, canvas: EditorCanvas) => canvas,
  (draftElementId, parentComponentId, draftRepeatedIndex, canvas) => {
    if (!draftElementId || !parentComponentId) {
      return null;
    }

    const parentComponentNode =
      getEditorComponentNodes({
        canvas,
        componentId: parentComponentId,
      }).find((node) =>
        draftRepeatedIndex?.startsWith(node.dataset.reploRepeatedIndex!),
      ) ?? null;
    return parentComponentNode;
  },
);

const selectDraftComponentCustomProps = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (!draftComponent) {
      return null;
    }
    return getCustomPropDefinitions(draftComponent).filter(
      (def) => def.type !== "component",
    );
  },
);

export const selectDraftComponentRightBarTabs = createSelector(
  selectDraftComponent,
  selectDraftComponentCustomProps,
  selectGetAttribute,
  selectAreMultipleDraftComponents,
  (draftComponent, customProps, getAttribute, areMultipleDraftComponents) => {
    if (!draftComponent) {
      return null;
    }

    const clickActions = getAttribute(draftComponent, "props.onClick", {
      defaultValue: [],
    }).value;
    const hoverActions = getAttribute(draftComponent, "props.onHover", {
      defaultValue: [],
    }).value;
    const actions = [...clickActions, ...hoverActions];
    const animations = draftComponent?.animations ?? [];

    const interactionsCount = actions.length + animations.length;

    const tabOptions: {
      value: RightBarTab;
      label: string;
      isVisible?: boolean;
    }[] = [
      // NOTE (Reinaldo, 2022-04-29): Hide the design tab for modals since with modal you can only edit its content's design
      {
        value: "design",
        label: "Design",
        isVisible: !isModal(draftComponent.type),
      },
      {
        value: "custom",
        label: "Config",
        isVisible: !isEmpty(customProps) && !areMultipleDraftComponents,
      },
      {
        value: "interactions",
        label: `Interactions${
          interactionsCount > 0 ? ` (${interactionsCount})` : ""
        }`,
        isVisible: !areMultipleDraftComponents,
      },
      {
        value: "accessibility",
        label: "Accessibility",
        isVisible:
          Boolean(getRenderData(draftComponent.type)?.showAccessibilityMenu) &&
          !areMultipleDraftComponents,
      },
    ];
    return tabOptions;
  },
);

export const selectDraftComponentAncestorWithEditorProps = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponent,
  (draftElement, draftComponent) => {
    if (!draftElement || !draftComponent) {
      return null;
    }

    // NOTE (Martin 2025-01-09): We don't want to consider ancestors
    // with editor props if the current draft component is a modal
    if (isModal(draftComponent.type)) {
      return null;
    }

    return findAncestorComponent(
      draftElement,
      draftComponent.id,
      (component) => {
        const renderData = getRenderData(component.type);
        return (
          Boolean(renderData?.editorProps) ||
          Boolean(renderData?.showComponentControlsFromChildren)
        );
      },
    );
  },
);

function createAncestorOrSelfWithSpecificTypeSelector(
  componentType: ReploComponentType,
) {
  return createSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
    selectDraftComponent,
    (draftElement, draftComponent) => {
      if (!draftElement || !draftComponent || !componentType) {
        return null;
      }

      return findAncestorComponentOrSelf(
        draftElement,
        draftComponent.id,
        (component) => {
          return Boolean(component.type === componentType);
        },
      );
    },
  );
}

export const selectAncestorOrSelfWithProductType =
  createAncestorOrSelfWithSpecificTypeSelector("product");

export const selectAncestorOrSelfWithProductCollectionType =
  createAncestorOrSelfWithSpecificTypeSelector("productCollection");

export const selectDraftComponentAncestorWithEditorPropsNode = createSelector(
  selectDraftElementId,
  selectDraftComponentAncestorWithEditorProps,
  (_state: EditorRootState, canvas: EditorCanvas) => canvas,
  (draftElementId, ancestorWithEditorProps, canvas) => {
    if (!draftElementId || !ancestorWithEditorProps) {
      return null;
    }

    const nodes = getEditorComponentNodes({
      canvas,
      componentId: ancestorWithEditorProps.id,
    });
    return nodes[0] ?? null;
  },
);

/**
 * This selector is used to access text on components that are not Text
 * components. It is useful specifically for legacy buttons that have text on
 * them.
 */
export const selectNonTextComponentText = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (draftComponent?.type !== "text") {
      return draftComponent?.props?.text;
    }
    return undefined;
  },
);

const selectHasTextProp = createSelector(selectDraftComponentText, Boolean);

export const selectDraftComponentType = createSelector(
  selectDraftComponent,
  selectSymbolsMapping,
  (draftComponent, symbols) => {
    if (!draftComponent) {
      return null;
    }
    const type =
      draftComponent.type === "symbolRef"
        ? symbols[`${draftComponent.symbolId}.component.type`] ?? "unknown"
        : draftComponent.type;
    return type as ReploComponentType;
  },
);

const selectDraftComponentsTypes = createSelector(
  selectDraftComponents,
  selectSymbolsMapping,
  (draftComponents, symbols) => {
    if (!draftComponents.length) {
      return [];
    }

    // NOTE (Sebas, 2025-01-07): Using a set here to avoid duplicate component types.
    const types = new Set(
      draftComponents.map((draftComponent) => {
        const type =
          draftComponent.type === "symbolRef"
            ? symbols[`${draftComponent.symbolId}.component.type`] ?? "unknown"
            : draftComponent.type;
        return type as ReploComponentType;
      }),
    );

    return [...types];
  },
);

export const selectDraftComponentTypeIsModal = createSelector(
  selectDraftComponentType,
  (draftComponentType) => {
    // Note (Noah, 2023-10-16): For symbols, draftComponentType will be
    // unknown. We assume that symbols are not modals for now, since
    // this will cause crashes elsewhere
    // @ts-expect-error
    return draftComponentType && draftComponentType !== "unknown"
      ? isModal(draftComponentType)
      : false;
  },
);

export const selectDraftComponentAcceptsArbitraryChildren = createSelector(
  selectDraftComponentType,
  selectHasTextProp,
  (draftComponentType, hasTextProp) => {
    if (!draftComponentType) {
      return false;
    }
    const renderData = getRenderData(draftComponentType);
    return Boolean(
      renderData?.acceptsArbitraryChildren({
        hasTextProp,
        movementSource: "gridModifier",
      }),
    );
  },
);

export const selectIsDraftComponentConnectedToDynamicData = createSelector(
  selectDraftComponent,
  (component) => {
    return component && doesComponentHaveConnectedDynamicDataProps(component);
  },
);

export const selectIsDraftComponentDynamic = createSelector(
  selectDraftComponent,
  selectDraftComponentContext,
  (component, context) => {
    const componentType = component?.type;
    if (!componentType || !context) {
      return false;
    }
    const renderData = getRenderData(componentType);
    // for the component type those are always dynamic
    const isAlwaysDynamic = renderData?.isAlwaysDynamic;
    const dynamicItemsPropName = renderData?.dynamicItemsPropName;
    // For components which might be dynamic based on props like _items/_products
    const isDynamicFromItemProp = Boolean(
      dynamicItemsPropName && component?.props[dynamicItemsPropName],
    );

    // Note (Ovishek, 2022-11-18): For some complex components like tabs/carousels knowing if the component
    // dynamic/repeated is complex, b/c the _items prop belongs to any ancestor parents, for example, for tabsList, _items
    // belongs to tabsBlock component which is it's parent.
    const isDynamicFromContext =
      renderData?.extractIsDynamicFromContext?.(context) ?? false;
    return isAlwaysDynamic || isDynamicFromContext || isDynamicFromItemProp;
  },
);

export const selectDraftComponentsPositionKey = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (
    draftComponents,
    getAttribute,
  ): (PositionAttribute | "center" | ReploMixedStyleValue)[] | [] => {
    if (!draftComponents) {
      return [];
    }

    // NOTE (Sebas, 2024-12-26): This function extracts unique position attributes (e.g., "top", "bottom", "left",
    // "right") that have a value from draft components. It uses `uniqWith` to filter out duplicates based on
    // equality check provided by `isEqual`.
    const getUniquePositionAttributes = (positions: PositionAttribute[]) =>
      uniqWith(
        draftComponents.map((draftComponent) =>
          getDefaultPositionAttribute(positions, draftComponent, getAttribute),
        ),
        isEqual,
      );

    const uniqueVerticalValues = getUniquePositionAttributes(["top", "bottom"]);
    const uniqueHorizontalValues = getUniquePositionAttributes([
      "left",
      "right",
    ]);

    const verticalResult =
      uniqueVerticalValues.length > 1
        ? REPLO_MIXED_STYLE_VALUE
        : uniqueVerticalValues[0]!;
    const horizontalResult =
      uniqueHorizontalValues.length > 1
        ? REPLO_MIXED_STYLE_VALUE
        : uniqueHorizontalValues[0]!;

    return [verticalResult, horizontalResult];
  },
);

export const selectDraftComponentsPositionOffset = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    if (!draftComponents) {
      return [];
    }
    // NOTE (Sebas, 2024-12-26): This function takes an array of style attributes and returns a
    // list of unique values for those attributes across the selected draft components.
    // The `uniqBy` function ensures that only unique values are included in the final list,
    // based on the "value" property.
    // This is because we can have the same offset value but applied to different positions
    // (e.g., "top" and "bottom").
    const getUniqueValues = (attributes: RuntimeStyleAttribute[]) =>
      uniqBy(
        draftComponents.map((draftComponent) => {
          for (const attribute of attributes) {
            const _value = getEdgeAttribute(
              draftComponent,
              attribute,
              getAttribute,
            );
            if (_value) {
              return { attribute, value: _value };
            }
          }
          return null;
        }),
        "value",
      );

    const uniqueVerticalValues = getUniqueValues(["top", "bottom"]);
    const uniqueHorizontalValues = getUniqueValues(["left", "right"]);

    return [
      uniqueVerticalValues.length > 1
        ? REPLO_MIXED_STYLE_VALUE
        : uniqueVerticalValues[0],
      uniqueHorizontalValues.length > 1
        ? REPLO_MIXED_STYLE_VALUE
        : uniqueHorizontalValues[0],
    ];
  },
);

export const selectDraftComponentActions = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftComponent,
  selectDraftComponentType,
  selectComponentDataMapping,
  (draftElement, draftComponent, draftComponentType, componentDataMapping) => {
    if (!draftComponent) {
      return [];
    }

    const actionsFromAncestors = getComponentActionTypesFromAncestors(
      draftComponent,
      componentDataMapping,
    );

    const actionsToOmitInTheEditor: AlchemyActionType[] = [
      "activateTabId", // Excluded because it's automatic
      "openKlaviyoModal", // Excluded because we deal with it on componentTypeFnToActionTypes
      "openModal", // Excluded because we deal with it on componentTypeFnToActionTypes
      "setActiveOptionValue", // Excluded because it's for internal use
      "setActiveVariant", // Excluded because it's for internal use
      "setDropdownItem", // Excluded because it's for internal use
    ];

    const validActionsFromAncestors = difference(
      actionsFromAncestors,
      actionsToOmitInTheEditor,
    );

    let shouldShowActiveAlchemyVariant = false;
    forEachComponentAndDescendants(
      draftElement?.component ?? null,
      (component) => {
        const variants = component.variants;
        if (variants) {
          shouldShowActiveAlchemyVariant = true;
        }
      },
    );

    let actions: AlchemyActionType[] = [
      ...hardcodedActions,
      ...validActionsFromAncestors,
    ];
    if (shouldShowActiveAlchemyVariant) {
      actions.push("setActiveAlchemyVariant");
    }

    if (draftComponentType) {
      const shouldHavePosibilityOfSpecialLinks = ["button", "text"].includes(
        draftComponentType,
      );
      if (shouldHavePosibilityOfSpecialLinks) {
        actions.push("phoneNumber");
      }
    }

    const ancestorTypeToActionTypes = {
      collectionSelect: ["setCurrentCollectionSelection"],
      player: ["togglePlay"],
      modal: ["closeModalComponent"],
      product: ["setActiveSellingPlan"],
    } as const;

    for (const [ancestorType, ancestorActions] of Object.entries(
      ancestorTypeToActionTypes,
    )) {
      if (
        draftElement &&
        findAncestorComponent(draftElement, draftComponent.id, (component) => {
          return component.type === ancestorType;
        })
      ) {
        actions = [...actions, ...ancestorActions];
      }
    }

    const componentTypeFnToActionTypes = [
      [isModal, ["openModal"]],
      [
        (type: ReploComponentType) => type == "klaviyoEmbed",
        ["openKlaviyoModal"],
      ],
    ] as const;

    const matchingActionTypes =
      draftElement &&
      componentTypeFnToActionTypes.find(([typeFn]) =>
        findComponent(draftElement, (component) => typeFn(component.type)),
      )?.[1];
    if (matchingActionTypes) {
      actions = [...actions, ...matchingActionTypes];
    }

    return actions;
  },
);

export const selectDraftComponentAnimations = createSelector(
  selectDraftComponent,
  (draftComponent) => {
    if (!draftComponent) {
      return null;
    }

    return draftComponent.animations;
  },
);

export const selectDraftComponentOrDescendantIsOneOfTypes = (
  state: EditorRootState,
  types: ReploComponentType[],
) => {
  const draftComponent = selectDraftComponent(state);
  if (!draftComponent) {
    return false;
  }
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = componentDataMapping[draftComponent.id];
  if (!componentData) {
    return false;
  }
  if (types.includes(componentData?.type)) {
    return true;
  }
  return types.some((type) =>
    componentData.containedComponentData.some(
      ([_, componentType]) => componentType === type,
    ),
  );
};

export const selectDraftElementVersion = createSelector(
  selectDraftElementId,
  selectVersionMapping,
  (id, versionMapping) => {
    if (!id) {
      return null;
    }
    return versionMapping[id];
  },
);

export const selectDraftElementTemplateProducts = createSelector(
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (draftElement) => {
    return draftElement?.templateShopifyProductIds
      ? draftElement.templateShopifyProductIds.map((id) => Number(id))
      : undefined;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const selectAllComponentIdsWithTemplateProductEqualsStates =
  createSelector(
    selectRootComponent,
    (rootComponent) => {
      const results: string[] = [];
      if (rootComponent) {
        forEachComponentAndDescendants(rootComponent, (component) => {
          const productEqualsState = component.variants?.find(
            (variant) =>
              variant.query?.statements[0]?.field ===
              "state.product.templateProductEquals",
          );
          if (productEqualsState) {
            results.push(component.id);
          }
        });
      }
      return results;
    },
    {
      memoizeOptions: {
        resultEqualityCheck: isEqual,
      },
    },
  );

export const selectDraftComponentComputedStyleValue = createSelector(
  (
    state: EditorRootState,
    attribute: keyof SupportedCssProperties,
    canvas: EditorCanvas,
  ) => [state, attribute, canvas] as const,
  ([state, attribute, canvas]) => {
    const draftComponentNode = selectDraftComponentNode(state, canvas);
    if (!draftComponentNode) {
      return null;
    }
    return getComputedStyle(draftComponentNode)[attribute];
  },
);

// Note (Evan, 2024-03-18): Selects history operations if they reference the same
// draft element, otherwise null -- this prevents edits in the background.
export const selectValidPreviousOperation = createSelector(
  selectHistoryState,
  selectDraftElementId,
  (historyState, draftElementId) => {
    const previousOperation = historyState.operations[historyState.index];
    return previousOperation?.elementId === draftElementId
      ? previousOperation
      : null;
  },
);

export const selectValidNextOperation = createSelector(
  selectHistoryState,
  selectDraftElementId,
  (historyState, draftElementId) => {
    const nextOperation = historyState.operations[historyState.index + 1];
    return nextOperation?.elementId === draftElementId ? nextOperation : null;
  },
);

export const selectComponentIdsToStyle = createSelector(
  selectHistoryState,
  selectDraftElementId,
  selectStreamingUpdateComponentIdsSelected,
  (historyState, draftElementId, componentIdsSelected) => {
    if (componentIdsSelected) {
      const streamingActionComponentIds = componentIdsSelected.map(
        (componentId) => componentId,
      );
      return filterNulls(streamingActionComponentIds);
    }

    const operationToStyle =
      historyState.operations[
        historyState.lastOperation === "undo"
          ? historyState.index + 1
          : historyState.index
      ];

    if (!operationToStyle) {
      return [];
    }

    return operationToStyle.elementId === draftElementId
      ? operationToStyle.componentIds
      : [];
  },
);

// Style attribute related selectors
function createDraftComponentStyleSelector<
  T extends keyof RuntimeStyleProperties,
>(
  attribute: T,
): Selector<EditorRootState, RuntimeStyleProperties[T] | ReploMixedStyleValue> {
  return createSelector(
    selectDraftComponents,
    selectGetAttribute,
    (draftComponents, getAttribute) => {
      const { firstValue, hasMixedValues } = checkForMixedValues(
        draftComponents,
        (component) => getEdgeAttribute(component, attribute, getAttribute),
      );

      if (hasMixedValues) {
        return REPLO_MIXED_STYLE_VALUE;
      }

      return firstValue;
    },
  );
}

export const selectFontFamily = createDraftComponentStyleSelector("fontFamily");
export const selectFontWeight = createDraftComponentStyleSelector("fontWeight");
export const selectFontSize = createDraftComponentStyleSelector("fontSize");
export const selectLetterSpacing =
  createDraftComponentStyleSelector("letterSpacing");
export const selectFontStyle = createDraftComponentStyleSelector("fontStyle");
export const selectLineHeight = createDraftComponentStyleSelector("lineHeight");
export const selectTextAlign = createDraftComponentStyleSelector("textAlign");
export const selectTextDecoration =
  createDraftComponentStyleSelector("textDecoration");
export const selectTextTransform =
  createDraftComponentStyleSelector("textTransform");
export const selectAlignSelf = createDraftComponentStyleSelector("alignSelf");
export const selectFlexGap = createDraftComponentStyleSelector("__flexGap");
export const selectColumnGap = createDraftComponentStyleSelector("columnGap");
export const selectRowGap = createDraftComponentStyleSelector("rowGap");
export const selectGridColumnEnd =
  createDraftComponentStyleSelector("gridColumnEnd");
export const selectGridRowEnd = createDraftComponentStyleSelector("gridRowEnd");
export const selectNumberOfColumns =
  createDraftComponentStyleSelector("__numberOfColumns");
export const selectDisplay = createDraftComponentStyleSelector("display");
export const selectGridColumnEndTemplate = createDraftComponentStyleSelector(
  "gridTemplateColumns",
);
export const selectFlexDirection =
  createDraftComponentStyleSelector("flexDirection");
export const selectFlexGrow = createDraftComponentStyleSelector("flexGrow");
export const selectAliasedFlexShrink =
  createDraftComponentStyleSelector("__flexShrink");
export const selectFlexWrap = createDraftComponentStyleSelector("flexWrap");
export const selectJustifyContent =
  createDraftComponentStyleSelector("justifyContent");
export const selectAlignItems = createDraftComponentStyleSelector("alignItems");
export const selectColor = createDraftComponentStyleSelector("color");
export const selectBackgroundColor =
  createDraftComponentStyleSelector("backgroundColor");
export const selectBackgroundImage =
  createDraftComponentStyleSelector("backgroundImage");
export const selectBackgroundSize =
  createDraftComponentStyleSelector("backgroundSize");
export const selectBackgroundRepeat =
  createDraftComponentStyleSelector("backgroundRepeat");
export const selectBackgroundPositionX = createDraftComponentStyleSelector(
  "backgroundPositionX",
);
export const selectBackgroundPositionY = createDraftComponentStyleSelector(
  "backgroundPositionY",
);
export const selectTop = createDraftComponentStyleSelector("top");
export const selectBottom = createDraftComponentStyleSelector("bottom");
export const selectOpacity = createDraftComponentStyleSelector("opacity");
export const selectCursor = createDraftComponentStyleSelector("cursor");
export const selectObjectFit = createDraftComponentStyleSelector("objectFit");
export const selectPosition = createDraftComponentStyleSelector("position");
export const selectWidth = createDraftComponentStyleSelector("width");
export const selectHeight = createDraftComponentStyleSelector("height");
export const selectMaxHeight = createDraftComponentStyleSelector("maxHeight");
export const selectMinHeight = createDraftComponentStyleSelector("minHeight");
export const selectMinWidth = createDraftComponentStyleSelector("minWidth");
export const selectMaxWidth = createDraftComponentStyleSelector("maxWidth");
export const selectZIndex = createDraftComponentStyleSelector("zIndex");
export const selectOverflow = createDraftComponentStyleSelector("overflow");
export const selectPrivateOverflow =
  createDraftComponentStyleSelector("__overflow");
export const selectImageSource =
  createDraftComponentStyleSelector("__imageSource");
export const selectAnimateVariantTransitions =
  createDraftComponentStyleSelector("__animateVariantTransitions");
export const selectBackgroundGradientTilt = createDraftComponentStyleSelector(
  "__alchemyGradient__backgroundColor__tilt",
);
export const selectBackgroundGradientStops = createDraftComponentStyleSelector(
  "__alchemyGradient__backgroundColor__stops",
);
export const selectColorGradientTilt = createDraftComponentStyleSelector(
  "__alchemyGradient__color__tilt",
);
export const selectColorGradientStops = createDraftComponentStyleSelector(
  "__alchemyGradient__color__stops",
);
export const selectColorGradientDesignLibrary =
  createDraftComponentStyleSelector("__reploGradient__color__design_library");
export const selectRotation =
  createDraftComponentStyleSelector("__alchemyRotation");
export const selectIconAltText =
  createDraftComponentStyleSelector("__iconAltText");
export const selectImageAltText =
  createDraftComponentStyleSelector("__imageAltText");
const selectBorderTopLeftRadius = createDraftComponentStyleSelector(
  "borderTopLeftRadius",
);
const selectBorderTopRightRadius = createDraftComponentStyleSelector(
  "borderTopRightRadius",
);
const selectBorderBottomLeftRadius = createDraftComponentStyleSelector(
  "borderBottomLeftRadius",
);
const selectBorderBottomRightRadius = createDraftComponentStyleSelector(
  "borderBottomRightRadius",
);
const selectBorderTopWidth =
  createDraftComponentStyleSelector("borderTopWidth");
const selectBorderRightWidth =
  createDraftComponentStyleSelector("borderRightWidth");
const selectBorderBottomWidth =
  createDraftComponentStyleSelector("borderBottomWidth");
const selectBorderLeftWidth =
  createDraftComponentStyleSelector("borderLeftWidth");
const selectBorderTopColor =
  createDraftComponentStyleSelector("borderTopColor");
const selectBorderRightColor =
  createDraftComponentStyleSelector("borderRightColor");
const selectBorderBottomColor =
  createDraftComponentStyleSelector("borderBottomColor");
const selectBorderLeftColor =
  createDraftComponentStyleSelector("borderLeftColor");
const selectBorderTopStyle =
  createDraftComponentStyleSelector("borderTopStyle");
const selectBorderRightStyle =
  createDraftComponentStyleSelector("borderRightStyle");
const selectBorderBottomStyle =
  createDraftComponentStyleSelector("borderBottomStyle");
const selectBorderLeftStyle =
  createDraftComponentStyleSelector("borderLeftStyle");
export const selectTransform = createDraftComponentStyleSelector("__transform");
export const selectTransformOrigin =
  createDraftComponentStyleSelector("transformOrigin");
export const selectBoxShadow = createDraftComponentStyleSelector("boxShadow");

export const selectObjectPosition = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    const { firstValue: firstValueX, hasMixedValues: hasMixedValuesX } =
      checkForMixedValues(
        draftComponents,
        (draftComponent) =>
          getEdgeAttribute(
            draftComponent,
            "objectPosition",
            getAttribute,
          )?.split(" ")[0],
      );
    const { firstValue: firstValueY, hasMixedValues: hasMixedValuesY } =
      checkForMixedValues(
        draftComponents,
        (draftComponent) =>
          getEdgeAttribute(
            draftComponent,
            "objectPosition",
            getAttribute,
          )?.split(" ")[1],
      );

    const objectPositionX = hasMixedValuesX
      ? REPLO_MIXED_STYLE_VALUE
      : firstValueX;
    const objectPositionY = hasMixedValuesY
      ? REPLO_MIXED_STYLE_VALUE
      : firstValueY;

    return [objectPositionX, objectPositionY] as (
      | ReploMixedStyleValue
      | string
      | undefined
    )[];
  },
);

export const selectSavedStyle = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    const { firstValue, hasMixedValues } = checkForMixedValues(
      draftComponents,
      (draftComponent) => {
        const fontSize = getEdgeAttribute(
          draftComponent,
          // NOTE (Sebas, 2025-01-22🎂): When applying saved styles to texts we apply the
          // prop to multiple properties, I choose fontSize but it could be any other value.
          "fontSize",
          getAttribute,
        );
        const isSavedStyle = isDynamicDesignLibraryValue(fontSize);
        return isSavedStyle ? fontSize : null;
      },
    );

    return hasMixedValues ? REPLO_MIXED_STYLE_VALUE : firstValue;
  },
);

export const selectBoxShadows = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    return findComponentsShadows("boxShadow", draftComponents, getAttribute);
  },
);

export const selectTextShadow = createDraftComponentStyleSelector("textShadow");

export const selectTextShadows = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    return findComponentsShadows("textShadow", draftComponents, getAttribute);
  },
);

export const selectTextOutline = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (
    draftComponents,
    getAttribute,
  ): (ReploMixedStyleValue | string | undefined)[] | string => {
    const values = draftComponents.map((draftComponent) =>
      getEdgeAttribute(draftComponent, "__textStroke", getAttribute),
    );

    // NOTE (Sebas, 2025-01-20): This constant is for when the value is a complete
    // design library value and not a partial one. When we apply a design library
    // color it only applies to the color part. e.g "4px {{library.id.styles.id}}"
    const hasDesignLibraryValue = values.some(isDynamicDesignLibraryValue);

    // NOTE (Sebas, 2025-01-20): If there is one component selected and it has a
    // design library value, we need to directly return the value.
    if (values.length === 1 && hasDesignLibraryValue) {
      return values[0];
    }

    // NOTE (Sebas, 2025-01-20): This is when we have multiple values that can
    // have  a design library value, partial or complete.
    const firstDesignLibraryValue = values.find((value) =>
      designLibraryValueRegex.test(value),
    );

    if (firstDesignLibraryValue) {
      // NOTE (Sebas, 2025-01-21): This regex is used to separate the width and
      // color values. e.g "4px {{library.id.styles.id}}" -> ["4px", "{{library.id.styles.id}}"]
      const [width, color] =
        firstDesignLibraryValue.match(
          regexForSplittingPropValuesWithDynamicData,
        ) ?? [];

      const hasMixedWidthValues = values.some((value) => {
        const [widthValue] = value
          ? value.match(regexForSplittingPropValuesWithDynamicData)
          : [];
        return widthValue !== width;
      });

      const hasMixedColorValues = values.some((value) => {
        const [widthValue, colorValue] = value
          ? value.match(regexForSplittingPropValuesWithDynamicData)
          : [];
        // NOTE (Sebas, 2025-01-20): If the width is a design library value, we
        // need to return true because the color will be undefined and this will
        // return a wrong result.
        return isDynamicDesignLibraryValue(widthValue) || colorValue !== color;
      });

      return [
        hasMixedWidthValues ? REPLO_MIXED_STYLE_VALUE : width,
        hasMixedColorValues ? REPLO_MIXED_STYLE_VALUE : color,
      ];
    }

    const [firstWidth, firstColor] = values[0]?.split(" ") ?? [];

    const hasMixedWidthValues = values.some(
      (value) => value?.split(" ")[0] !== firstWidth,
    );
    const hasMixedColorValues = values.some(
      (value) => value?.split(" ")[1] !== firstColor,
    );

    return [
      hasMixedWidthValues ? REPLO_MIXED_STYLE_VALUE : firstWidth ?? null,
      hasMixedColorValues ? REPLO_MIXED_STYLE_VALUE : firstColor ?? null,
    ];
  },
);

export const selectFlexSpacing = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    const { firstValue, hasMixedValues } = checkForMixedValues(
      draftComponents,
      (component) => {
        const value =
          VALUE_TO_ALIGNMENT_OPTION[
            getEdgeAttribute(component, "justifyContent", getAttribute) ??
              "center"
          ];
        return value === "space-between" ? "spaced" : "packed";
      },
    );

    if (hasMixedValues) {
      return REPLO_MIXED_STYLE_VALUE;
    }

    return firstValue;
  },
);

export const selectAncestorTextColor = createSelector(
  selectDraftComponentId,
  selectComponentDataMapping,
  selectComponentMapping,
  selectGetAttribute,
  (draftComponentId, componentDataMapping, componentMapping, getAttribute) => {
    if (!draftComponentId) {
      return null;
    }

    const ancestorWithTextColorId = findAncestorComponentData(
      draftComponentId,
      (componentData) => {
        const component = componentMapping[componentData.id]?.component;
        return getEdgeAttribute(component ?? null, "color", getAttribute);
      },
      componentDataMapping,
    )?.id;

    return getEdgeAttribute(
      getFromRecordOrNull(componentMapping, ancestorWithTextColorId)
        ?.component ?? null,
      "color",
      getAttribute,
    );
  },
);

export const selectBorderRadius = createSelector(
  selectBorderTopLeftRadius,
  selectBorderTopRightRadius,
  selectBorderBottomLeftRadius,
  selectBorderBottomRightRadius,
  (
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  ) => {
    return [
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
    ];
  },
);

export const selectHasBorderStyles = createSelector(
  selectBorderLeftStyle,
  selectBorderRightStyle,
  selectBorderTopStyle,
  selectBorderBottomStyle,
  (borderLeftStyle, borderRightStyle, borderTopStyle, borderBottomStyle) => {
    return [
      borderLeftStyle,
      borderRightStyle,
      borderTopStyle,
      borderBottomStyle,
    ].some((elem) => elem !== null);
  },
);

export const selectParentFlexDirection = createSelector(
  selectDraftParentComponent,
  selectGetAttribute,
  (parentComponent, getAttribute) => {
    const parentFlexDirection =
      // NOTE (Sebas, 2025-01-10): The modal component has a flexDirection of
      // column, but in the JSON it's not set. This causes that the selector
      // selectParentFlexDirection returns row, inverting the widht/height
      // toggle group options. To fix this, we check if the parent component
      // is a modal and set the flexDirection to column.
      parentComponent && isModal(parentComponent.type)
        ? "column"
        : getEdgeAttribute(parentComponent, "flexDirection", getAttribute);
    return getNormalizedFlexDirection(
      parentFlexDirection ||
        styleAttributeToEditorData.flexDirection.defaultValue,
    );
  },
);

export const selectComponentChildrenPrivateDimensions = createSelector(
  selectDraftComponentChildren,
  selectGetAttribute,
  (draftComponentChildren, getAttribute) => {
    if (!draftComponentChildren) {
      return null;
    }
    return draftComponentChildren.map((component) => {
      const width = getAttribute(component, "style.__width");
      const height = getAttribute(component, "style.__height");
      return {
        width,
        height,
      };
    });
  },
);

export const selectIsParentGrid = createSelector(
  selectDraftParentComponent,
  selectGetAttribute,
  (parentComponent, getAttribute) => {
    return (
      getEdgeAttribute(parentComponent, "display", getAttribute) === "grid"
    );
  },
);

export const selectBorderWidth = createSelector(
  selectBorderTopWidth,
  selectBorderRightWidth,
  selectBorderBottomWidth,
  selectBorderLeftWidth,
  (borderTopWidth, borderRightWidth, borderBottomWidth, borderLeftWidth) => {
    return {
      Left: isMixedStyleValue(borderLeftWidth)
        ? borderLeftWidth
        : coerceNumberToString(borderLeftWidth),
      Top: isMixedStyleValue(borderTopWidth)
        ? borderTopWidth
        : coerceNumberToString(borderTopWidth),
      Right: isMixedStyleValue(borderRightWidth)
        ? borderRightWidth
        : coerceNumberToString(borderRightWidth),
      Bottom: isMixedStyleValue(borderBottomWidth)
        ? borderBottomWidth
        : coerceNumberToString(borderBottomWidth),
    };
  },
);

export const selectBorderColor = createSelector(
  selectBorderTopColor,
  selectBorderRightColor,
  selectBorderBottomColor,
  selectBorderLeftColor,
  (borderTopColor, borderRightColor, borderBottomColor, borderLeftColor) => {
    return {
      Left: borderLeftColor,
      Top: borderTopColor,
      Right: borderRightColor,
      Bottom: borderBottomColor,
    };
  },
);

const selectBorderStyle = createSelector(
  selectBorderTopStyle,
  selectBorderRightStyle,
  selectBorderBottomStyle,
  selectBorderLeftStyle,
  (borderTopStyle, borderRightStyle, borderBottomStyle, borderLeftStyle) => {
    return {
      Left: borderLeftStyle,
      Top: borderTopStyle,
      Right: borderRightStyle,
      Bottom: borderBottomStyle,
    };
  },
);

export const selectInitialBorderSide = createSelector(
  selectBorderWidth,
  (borderWidth): BorderSuffix => {
    const entries = Object.entries(borderWidth).filter(([, value]) => value);
    if (entries.length === Object.values(borderWidth).length) {
      return "All";
    }

    if (entries.length > 0) {
      return entries[0]![0]! as BorderSuffix;
    }

    return "All";
  },
);

export const selectActiveBorderSideWidth = createSelector(
  selectBorderWidth,
  (_: EditorRootState, activeBorderSide: BorderSuffix | null) =>
    activeBorderSide,
  (borderWidth, activeBorderSide) => {
    if (activeBorderSide === null) {
      return null;
    }

    if (activeBorderSide === "All") {
      return borderWidth[Object.keys(borderWidth)[0] as BorderSide];
    }

    return borderWidth[activeBorderSide as BorderSide];
  },
);

export const selectActiveBorderSideColor = createSelector(
  selectBorderColor,
  (_: EditorRootState, activeBorderSide: BorderSuffix | null) =>
    activeBorderSide,
  (borderColor, activeBorderSide) => {
    if (activeBorderSide === null) {
      return null;
    }

    if (activeBorderSide === "All") {
      return borderColor[Object.keys(borderColor)[0] as BorderSide];
    }

    return borderColor[activeBorderSide as BorderSide];
  },
);

export const selectActiveBorderSideStyle = createSelector(
  selectBorderStyle,
  (_: EditorRootState, activeBorderSide: BorderSuffix | null) =>
    activeBorderSide,
  (borderStyle, activeBorderSide) => {
    if (activeBorderSide === null) {
      return null;
    }

    if (activeBorderSide === "All") {
      return borderStyle[Object.keys(borderStyle)[0] as BorderSide];
    }

    return borderStyle[activeBorderSide as BorderSide];
  },
);

export const selectBorderPropertiesValues = createSelector(
  selectBorderWidth,
  selectBorderColor,
  selectBorderStyle,
  (borderWidth, borderColor, borderStyle) => {
    return { borderWidth, borderColor, borderStyle };
  },
);

export const selectDraftComponentActionIssues = createSelector(
  selectDraftComponent,
  selectDraftComponentOrAncestorVariant,
  selectComponentDataMapping,
  (component, activeVariant, componentDataMapping) => {
    return getIssuesForComponent({
      type: "actions",
      component,
      activeVariant,
      componentDataMapping,
    });
  },
);

export const selectDraftComponentAnimationIssues = createSelector(
  selectDraftComponentAnimations,
  (animations) => {
    return getIssuesForComponent({
      type: "animations",
      animations: animations ?? null,
    });
  },
);

// Prop attribute related selectors
function createDraftComponentPropSelector<T = any>(attribute: string) {
  return createSelector(
    selectDraftComponents,
    selectGetAttribute,
    (draftComponents, getAttribute) => {
      if (draftComponents.length === 0) {
        return null;
      }

      const { firstValue, hasMixedValues } = checkForMixedValues(
        draftComponents,
        (component) => getAttribute(component, `props.${attribute}`).value as T,
      );

      if (hasMixedValues) {
        return REPLO_MIXED_STYLE_VALUE;
      }

      return firstValue;
    },
  );
}

export const selectPropSrc = createDraftComponentPropSelector("src");
export const selectPropLoading = createDraftComponentPropSelector<
  AssetLoadingType | undefined
>("loading");
const selectPropImageSource = createDraftComponentPropSelector(
  "style.__imageSource",
);
export const selectPropIconName = createDraftComponentPropSelector("iconName");
export const selectPropUrl = createDraftComponentPropSelector("url");
export const selectPropPoster = createDraftComponentPropSelector<
  string | undefined
>("poster");
export const selectPropOnClick = createDraftComponentPropSelector("onClick");
export const selectPropOnHover = createDraftComponentPropSelector("onHover");
const selectPropProductRef = createDraftComponentPropSelector("_product");
const selectPropProductRefOrFallbackToContext = createSelector(
  selectDraftComponentContext,
  selectPropProductRef,
  (context, productRef: ProductRef | null) => {
    if (!productRef && context?.attributes?._product) {
      const product = context!.attributes!._product;
      return {
        productId: product.id,
        variantId: product.variants?.[0]?.id,
        status: product.status,
      };
    }
    return productRef;
  },
);
export const selectPropHTMLContent =
  createDraftComponentPropSelector("_htmlContent");
export const selectPropCSSContent = createDraftComponentPropSelector("_css");
export const selectPropLiquidContent =
  createDraftComponentPropSelector("_liquidContent");

/**
 * Select either the current options of the _product prop of the draft component, or
 * if no _product prop is specified, the options from the context of the draft component
 */
export const selectProductOptionValues = createSelector(
  selectPropProductRefOrFallbackToContext,
  selectDraftComponentId,
  selectTemplateEditorStoreProduct,
  (_state: EditorRootState, products: StoreProduct[]) => products,
  (
    _state: EditorRootState,
    _products: StoreProduct[],
    context: Context | undefined,
  ) => context,
  selectLocaleData,
  (
    productRef,
    draftComponentId,
    templateProduct,
    products,
    context,
    locale,
  ): ReploShopifyOption[] => {
    // Note (Noah, 2023-01-02, REPL-5766): The options to pick from should be the options
    // EITHER of the product specified by the draft component (we assume the prop is called _product
    // for now) or the options from the current context (current product component), which are
    // available in `attributes`
    return (
      mapNull(draftComponentId, () => {
        // On first render of the OptionsCustomPropModifier optionsFromContext
        // is null. It seems like the context is being maintained outside of
        // redux and therefore when context updates after this is run this
        // selector does not rerun.
        const optionsFromContext = context?.attributes?._options;
        const resolvedProduct = getProduct(
          productRef ?? null,
          context ?? null,
          {
            productMetafieldValues: {},
            variantMetafieldValues: {},
            products,
            currencyCode: locale.activeCurrency,
            moneyFormat: locale.moneyFormat,
            language: locale.activeLanguage,
            templateProduct: templateProduct ?? null,
            isEditor: true,
            isShopifyProductsLoading: false,
          },
        );
        if (!resolvedProduct) {
          return optionsFromContext;
        }
        return enhanceVariantsAndOptions({
          product: resolvedProduct,
          variantMetafieldValues: {},
          swatches: [],
          selectedOptionValues: null,
          showOptionsNotSoldTogether: false,
        }).options;
      }) ?? []
    );
  },
);

export const selectImageSourceComponentProps = createSelector(
  selectPropImageSource,
  (_state: EditorRootState, url: string) => url,
  (_state: EditorRootState, _url: string, imageComponentId?: string) =>
    imageComponentId,
  (imageSourceProp, imageUrl, imageComponentId) => {
    return {
      imageUrl,
      imageSourceProp,
      imageComponentId,
    };
  },
);

export const selectHasDraftComponentAncestorOrSelfWithSpecificType =
  createSelector(
    selectComponentDataMapping,
    selectDraftComponentId,
    (_: EditorRootState, componentType: ReploComponentType) => componentType,
    (componentDataMapping, draftComponentId, componentType) => {
      if (!draftComponentId) {
        return false;
      }
      const componentData = componentDataMapping[draftComponentId];
      if (!componentData) {
        return false;
      }
      if (componentData.type === componentType) {
        return true;
      }

      if (
        componentData?.ancestorComponentData.some(
          ([, ancestorType]) => ancestorType === componentType,
        )
      ) {
        return true;
      }

      return false;
    },
  );

const selectSupportedComponentDynamicDataIssues = createSelector(
  selectComponentMapping,
  selectActiveCanvas,
  (_state: EditorRootState, nodeId: string) => nodeId,
  (componentMapping, activeCanvas, nodeId) => {
    const componentData = componentMapping[nodeId]?.component;
    const componentType = componentData?.type;
    // NOTE (Sebas, 2024-05-16): Regex to validate that it has a valid
    // dynamic data format.
    const regex = /^{{.*}}$/;
    if (componentType === "text") {
      const rawData = componentData?.props.text;
      if (rawData) {
        const withoutPTags = rawData.replace(/<\/?p>/g, "");
        const isValid = regex.test(withoutPTags);

        if (isValid) {
          return withoutPTags;
        }
      }
    } else if (componentType === "image") {
      const style = canvasToStyleMap[activeCanvas];
      const activeCanvasStyles = componentData?.props[style];
      const imageSrc = (activeCanvasStyles?.__imageSource ??
        componentData?.props.src) as string;
      if (imageSrc) {
        const isValid = regex.test(imageSrc);

        if (isValid) {
          return imageSrc;
        }
      }
    }
    return null;
  },
);

const selectDynamicDataIssue = createSelector(
  (_state: EditorRootState, componentId: string) => componentId,
  (state: EditorRootState, componentId: string) =>
    selectSupportedComponentDynamicDataIssues(state, componentId),
  (componentId, dynamicDataString) => {
    const componentContext = getCurrentComponentContext(componentId, 0);
    if (!dynamicDataString || !componentContext) {
      return null;
    }
    const resolutionConfig = {
      selectedSellingPlan: componentContext.attributes?._selectedSellingPlan,
    };
    const dynamicDataValue = evaluateVariableAsString(
      dynamicDataString,
      componentContext,
      resolutionConfig,
    );

    const componentIssues = getIssuesForComponent({
      type: "dynamicData",
      dynamicDataValue,
    });

    if (componentIssues.length > 0) {
      return componentIssues[0];
    }

    return null;
  },
);

const selectComponentMismatchIssue = createSelector(
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentDataMapping, componentId) => {
    const componentType = componentDataMapping[componentId]?.type;

    if (componentType) {
      const mismatchIssues = getIssuesForComponent({
        type: "componentMismatch",
        componentDataMapping,
        componentId,
      });

      if (mismatchIssues.length > 0) {
        return mismatchIssues[0];
      }
    }

    return null;
  },
);

const selectComponentActionIssue = createSelector(
  selectComponentMapping,
  selectDraftComponentOrAncestorVariant,
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentMapping, activeVariant, componentDataMapping, componentId) => {
    const component = getFromRecordOrNull(
      componentMapping,
      componentId,
    )?.component;
    if (!component) {
      return null;
    }

    const issues = getIssuesForComponent({
      type: "actions",
      component,
      activeVariant,
      componentDataMapping,
    });
    if (issues.length > 0) {
      return issues[0];
    }
    return null;
  },
);

const selectOptionVariantListIssue = createSelector(
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentDataMapping, componentId) => {
    const componentType = componentDataMapping[componentId]?.type;
    const componentContext = getCurrentComponentContext(componentId, 0);

    const typesToCheck: Array<ReploComponentType> = [
      "optionSelect",
      "optionSelectDropdown",
      "variantSelect",
      "variantSelectDropdown",
      "sellingPlanSelect",
      "sellingPlanSelectDropdown",
    ];

    if (
      componentType &&
      componentContext &&
      typesToCheck.includes(componentType)
    ) {
      const componentIssues = getIssuesForComponent({
        type: "product",
        componentType,
        context: componentContext,
      });

      if (componentIssues.length > 0) {
        return componentIssues[0];
      }
    }

    return null;
  },
);

const selectAllowedChildrenIssue = createSelector(
  selectComponentDataMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentDataMapping, componentId) => {
    const componentType = componentDataMapping[componentId]?.type;

    if (componentType) {
      const componentIssues = getIssuesForComponent({
        type: "childrenType",
        componentId,
        componentDataMapping,
        componentType,
      });

      if (componentIssues.length > 0) {
        return componentIssues[0];
      }
    }

    return null;
  },
);

export const selectTreeComponentIssue = createCachedSelector(
  selectComponentMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (state: EditorRootState, componentId: string) =>
    selectComponentMismatchIssue(state, componentId),
  (state: EditorRootState, componentId: string) =>
    selectDynamicDataIssue(state, componentId),
  (state: EditorRootState, componentId: string) =>
    selectAllowedChildrenIssue(state, componentId),
  (state: EditorRootState, componentId: string) =>
    selectOptionVariantListIssue(state, componentId),
  (state: EditorRootState, componentId: string) =>
    selectComponentActionIssue(state, componentId),
  (
    _componentMapping,
    _componentId,
    mismatchIssue,
    dynamicDataIssue,
    getAllowedChildrenIssue,
    optionVariantListIssue,
    actionIssue,
  ) => {
    if (mismatchIssue) {
      return mismatchIssue;
    }

    if (dynamicDataIssue) {
      return dynamicDataIssue;
    }

    if (getAllowedChildrenIssue) {
      return getAllowedChildrenIssue;
    }

    if (optionVariantListIssue) {
      return optionVariantListIssue;
    }

    if (actionIssue) {
      return actionIssue;
    }

    return null;
  },
)((_state: EditorRootState, componentId: string) => {
  return componentId;
});

/**
 * Note (Evan, 2024-07-18): You may notice that these parameters are all derivable
 * from the elements state. The reason we parameterize it this way (instead of
 * passing the entire elements state) is to avoid recomputing this selector when
 * a streaming text action is applied -- in that scenario, streamingUpdate.actions
 * and streamingUpdate.textMap are updated, but not anything actually used in this calculation.
 * By only using those parts of the elements state that are necessary, then, we avoid a
 * full repaint.
 */
export const selectEnhacedElementMapping = createSelector(
  [
    selectDraftElementId,
    (state: EditorRootState) => state.core.elements.elementRevisions,
    selectSelectedRevisionId,
    selectVersionMapping,
    selectElementsMapping,
    (state: EditorRootState) =>
      state.core.elements.streamingUpdate?.draftElementComponent,
    selectSelectedArchivedElementId,
    (_state: EditorRootState, archivedElements: ReploElement[]) =>
      archivedElements,
  ],
  (
    draftElementId,
    elementRevisions,
    selectedRevisionId,
    elementVersionMapping,
    elementMapping,
    streamingDraftElementComponent,
    selectedArchivedElementId,
    archivedElements,
  ) => {
    if (
      !selectedRevisionId &&
      !streamingDraftElementComponent &&
      !selectedArchivedElementId
    ) {
      return {
        elementMapping,
        elementVersionMapping,
        elementRevisions,
      };
    }
    const updatedMapping = enhanceElementMapping({
      draftElementId: draftElementId ?? null,
      elementRevisions,
      selectedRevisionId,
      elementMapping,
      streamingDraftElementComponent,
      selectedArchivedElementId,
      archivedElements,
    });
    return {
      elementMapping: updatedMapping,
      elementVersionMapping,
      elementRevisions,
    };
  },
);

export const selectIsTextDynamicData = createSelector(
  selectDraftComponentText,
  (text) => {
    if (!text) {
      return false;
    }

    if (isDynamicDataValue(text)) {
      return true;
    }
  },
);

export const selectEvaluatedDraftComponentText = createSelector(
  selectDraftComponentText,
  selectDraftComponentContext,
  (text, context) => {
    if (!text || !context) {
      return null;
    }
    const resolutionConfig = {
      selectedSellingPlan: context.attributes?._selectedSellingPlan,
    };
    return evaluateVariableAsString(text, context, resolutionConfig);
  },
);

export const selectModalComponentIdFromDraftComponents = createSelector(
  selectDraftComponentIds,
  selectComponentDataMapping,
  (draftComponentIds, componentDataMapping) => {
    const modalIdFromSelectedIds = draftComponentIds.find(
      (id) =>
        componentDataMapping[id] && isModal(componentDataMapping[id].type),
    );

    if (modalIdFromSelectedIds) {
      return modalIdFromSelectedIds;
    }

    let ancestorModalFromSelectedIds = null;
    for (const id of draftComponentIds) {
      const ancestorModal = componentDataMapping[
        id
      ]?.ancestorComponentData.find(([_, ancestorComponentType]) =>
        ancestorComponentType.includes("modal"),
      );
      if (ancestorModal) {
        ancestorModalFromSelectedIds = ancestorModal;
        break;
      }
    }

    if (ancestorModalFromSelectedIds) {
      return ancestorModalFromSelectedIds[0];
    }

    return null;
  },
);

export const selectCommandMenuItems = createSelector(
  selectDraftComponents,
  selectValidPreviousOperation,
  selectValidNextOperation,
  (draftComponents, previousOperation, nextOperation) => {
    return getCommandMenuItems({
      draftComponents,
      hasPreviousOperation: Boolean(previousOperation),
      hasNextOperation: Boolean(nextOperation),
    });
  },
);

export const selectDraftComponentsVisibleBreakpoints = createSelector(
  selectDraftComponents,
  selectGetAttribute,
  (draftComponents, getAttribute) => {
    const { firstValue, hasMixedValues } = checkForMixedValues(
      draftComponents,
      (component) => getVisibleBreakpoints(component, getAttribute),
    );

    if (hasMixedValues) {
      return REPLO_MIXED_STYLE_VALUE;
    }

    return firstValue;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const selectDraftComponentsModifierGroups = createSelector(
  selectDraftComponentsTypes,
  selectNonTextComponentText,
  selectColor,
  selectedDraftComponentIsRoot,
  (draftComponentsTypes, nonTextComponentText, color, isRoot) => {
    if (draftComponentsTypes.length === 0) {
      return [];
    }

    // NOTE (Sebas, 2025-01-07): Retrieve the modifier groups for each component type
    const modifierGroupsList = draftComponentsTypes.map((componentType) => {
      const modifierGroupsOrFunction =
        componentTypeToModifierGroups[componentType];
      if (isFunction(modifierGroupsOrFunction)) {
        return modifierGroupsOrFunction({
          colorValue: !isMixedStyleValue(color) ? color ?? null : null,
          // NOTE (gabe, 05-03-2023): We are only interested in the text value of
          // non-text components (legacy buttons) because using selectText causes
          // unnecessary re-renders of ModifierGroups on text changes.
          textValue: nonTextComponentText,
        });
      }
      return modifierGroupsOrFunction ?? [];
    });

    // NOTE (Sebas, 2025-01-07): Find the intersection of all modifier groups
    let commonModifierGroups = modifierGroupsList.reduce(
      (commonGroups, currentGroups) => {
        return intersectionBy(commonGroups, currentGroups, "type");
      },
    );

    // NOTE (Sebas, 2025-01-07): Filter out the "visibility" modifier if the component is the root component
    if (isRoot) {
      commonModifierGroups = commonModifierGroups.filter(
        (modifier) => modifier.type !== "visibility",
      );
    }

    return commonModifierGroups;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);
// #endregion

export const selectComponentAlignSelf = createSelector(
  selectComponentMapping,
  selectGetAttribute,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentMapping, getAttribute, componentId) => {
    const component = getFromRecordOrNull(
      componentMapping,
      componentId,
    )?.component;

    if (!component) {
      return;
    }

    return getEdgeAttribute(component, "alignSelf", getAttribute);
  },
);

export const selectComponentActionsToCopy = createSelector(
  selectComponentMapping,
  selectComponentDataMapping,
  selectSymbolsMapping,
  (
    _state: EditorRootState,
    opts: { oldComponentId: string; oldIdToNewId: Record<string, string> },
  ) => opts,
  (componentMapping, componentDataMapping, symbolsMapping, opts) => {
    const { oldComponentId, oldIdToNewId } = opts;
    const oldComponent = getFromRecordOrNull(
      componentMapping,
      oldComponentId,
    )?.component;

    if (!oldComponent) {
      return [];
    }

    const ancestorWithVariantsData = findAncestorComponentData(
      oldComponent.id,
      (dataMapping) => {
        const component = componentMapping[dataMapping.id]?.component;
        if (!component) {
          return false;
        }
        return hasVariants(component, symbolsMapping);
      },
      componentDataMapping,
    );
    if (!ancestorWithVariantsData) {
      return [];
    }
    const ancestorWithVariants =
      componentMapping[ancestorWithVariantsData.id]?.component;

    if (!ancestorWithVariants) {
      return [];
    }
    const variantOverrides = ancestorWithVariants.variantOverrides;
    if (!variantOverrides) {
      return [];
    }
    const variantsOverridesEntries = Object.entries(variantOverrides);
    // Note (Fran, 2022-11-07): With this reduce we build a new array with
    // all components ids that have overrides and we relate them with the
    // corresponding variant id, to avoid use for loops inside each others
    const componentIdsWithVariantIds = reduce(
      variantsOverridesEntries,
      (result: Record<string, string>, variantsOverridesEntry) => {
        const [variantId, { componentOverrides }] = variantsOverridesEntry;
        forIn(componentOverrides, (_, key) => {
          result[key] = variantId;
        });
        return result;
      },
      {},
    );

    const actionsToApply: UseApplyComponentActionType[] = [];
    forEachComponentAndDescendants(oldComponent, (eachComponent) => {
      const variantId = componentIdsWithVariantIds[eachComponent.id];
      if (variantId) {
        const correspondingIdInNewComponent = oldIdToNewId[eachComponent.id];

        if (!correspondingIdInNewComponent) {
          return "continue";
        }

        actionsToApply.push({
          type: "duplicateComponentOverrides",
          componentIdToCopyOverridesFrom: eachComponent.id,
          destinationComponentId: correspondingIdInNewComponent,
          variantId: variantId,
          componentId: ancestorWithVariants.id,
        });
      }
      return "continue";
    });

    return actionsToApply;
  },
);

export const selectComponentFromMapping = createSelector(
  selectComponentMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentMapping, componentId) => {
    return getFromRecordOrNull(componentMapping, componentId)?.component;
  },
);

export const selectComponentAsString = createSelector(
  selectComponentMapping,
  (_state: EditorRootState, componentId: string) => componentId,
  (componentMapping, componentId) => {
    const component = getFromRecordOrNull(
      componentMapping,
      componentId,
    )?.component;

    if (!component) {
      return null;
    }

    const shouldMinify = localStorage.getItem(
      "replo.debug.copyComponentJsonMinify",
    );

    if (shouldMinify) {
      return JSON.stringify(component);
    }
    return JSON.stringify(component, null, 2);
  },
);

export const selectMultipleComponentsPasteActions = createSelector(
  selectDraftElementId,
  (
    _state: EditorRootState,
    opts: {
      newComponents: Component[];
      needsNewContainer: boolean;
      newParent: Component;
      positionWithinSiblings: number;
      newContainer: Component;
    },
  ) => opts,
  (draftElementId, opts) => {
    const {
      newComponents,
      needsNewContainer,
      newParent,
      positionWithinSiblings,
      newContainer,
    } = opts;
    const pasteIntoParentId = needsNewContainer
      ? newContainer.id
      : newParent.id;

    const pasteIntoSiblingsOffset = needsNewContainer
      ? 0
      : positionWithinSiblings;
    const actions: UseApplyComponentActionType[] = [];
    if (needsNewContainer) {
      actions.push({
        type: "addComponentToComponent",
        componentId: newParent.id,
        elementId: draftElementId,
        value: {
          newComponent: newContainer,
          position: "child",
          positionWithinSiblings: positionWithinSiblings,
        },
        analyticsExtras: {
          actionType: "create",
          createdBy: "user",
        },
      });
    }

    return actions.concat(
      newComponents.map((newComponent: Component, index: number) => {
        return {
          type: "addComponentToComponent",
          componentId: pasteIntoParentId,
          value: {
            newComponent,
            position: "child",
            positionWithinSiblings: pasteIntoSiblingsOffset + index,
          },
          source: "contextMenu",
          analyticsExtras: {
            actionType: "create",
            createdBy: "user",
          },
        };
      }),
    );
  },
);

export const selectComponentById = createSelector(
  selectComponentMapping,
  (_: EditorRootState, componentId: string | null) => componentId,
  (componentMapping, componentId) => {
    return getFromRecordOrNull(componentMapping, componentId)?.component;
  },
);

export const selectComponentNodes = createSelector(
  selectDraftElementId,
  (_: EditorRootState, componentId: string | null) => componentId,
  (_: EditorRootState, _componentId: string | null, canvas: EditorCanvas) =>
    canvas,
  (draftElementId, componentId, canvas) => {
    if (!draftElementId || !componentId) {
      return [];
    }

    return getEditorComponentNodes({
      canvas,
      componentId,
    });
  },
);

export const selectAreMultipleDraftComponentsInDefaultVariant = createSelector(
  selectDraftComponents,
  selectSymbolsMapping,
  selectComponentIdToDraftVariantId,
  (draftComponents, symbolsMapping, componentIdToDraftVariantId): boolean => {
    if (draftComponents.length <= 1) {
      return false;
    }

    return draftComponents.every((component) => {
      const variants = getVariants(component, symbolsMapping);

      // If no variants, treat as "default"
      if (variants.length === 0) {
        return true;
      }

      const activeVariant = variants.find(
        (variant) => variant.id === componentIdToDraftVariantId[component.id],
      );

      return (activeVariant?.name ?? "default") === "default";
    });
  },
);
// #endregion
