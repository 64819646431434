import type {
  ObjectFitType,
  ObjectPositionXType,
  ObjectPositionYType,
} from "@editor/types/object-fit";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import * as React from "react";

import ChevronMenuIndicator from "@common/designSystem/ChevronMenuIndicator";
import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import Input from "@common/designSystem/Input";
import Selectable from "@common/designSystem/Selectable";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { DynamicDataSelector } from "@editor/components/editor/page/DynamicDataSelector";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import DynamicDataValueIndicator from "@editor/components/editor/page/element-editor/components/extras/DynamicDataValueIndicator";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { DraggingTypes } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import AssetLibraryPopover from "@/features/assets/components/AssetLibraryPopover";
import { Badge } from "@replo/design-system/components/badge/Badge";
import twMerge from "@replo/design-system/utils/twMerge";
import startCase from "lodash-es/startCase";
import { LuImageOff } from "react-icons/lu";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import InlineAssetSelector from "../../../editor/components/editor/page/element-editor/components/modifiers/InlineAssetSelector";

// Note (Evan, 2024-04-26): Support either passing in { allowsChangingAltText, altTextValue, onChangeAltText, altTextControlLabel }
// to use the default LabeledControl or a altTextComponent to be rendered as a child (composition ftw).
type AltTextValueProps = {
  altTextValue?: string;
  allowsChangingAltText?: boolean;
  onChangeAltText?(value: string): void;
  altTextControlLabel?: string;
  altTextComponent?: undefined;
};

type AltTextComponentProps = {
  altTextValue?: undefined;
  allowsChangingAltText?: undefined;
  onChangeAltText?: undefined;
  altTextControlLabel?: undefined;
  altTextComponent: React.ReactNode;
};

type AltTextProps = AltTextValueProps | AltTextComponentProps;

type AssetPickerProps = {
  assetType: "image" | "video";
  url?: string | ReploMixedStyleValue;
  emptyTitle: string;
  emptyStateButtonLabel: string;
  selectedStateButtonLabel: string;
  objectPositionValue?: {
    x?: string | ReploMixedStyleValue;
    y?: string | ReploMixedStyleValue;
  };
  objectFitValue?: ObjectFitType | ReploMixedStyleValue;
  assetLoadingValue?: AssetLoadingType | ReploMixedStyleValue;
  onChangeObjectFit?(value: ObjectFitType): void;
  onChangeAssetLoading?(value: AssetLoadingType): void;
  onChangeObjectPositionX?(value: ObjectPositionXType): void;
  onChangeObjectPositionY?(value: ObjectPositionYType): void;
  onChangeSource?(value: string): void;
  allowsSettingDynamicData?: boolean;
  onClickDynamicDataForUrl?(): void;
  onClickDynamicDataForAltText?(): void;
  onClickDynamicDataForPoster?(): void;
  onPosterInputChange?(value: string): void;
  componentId?: string;
  isUrlDynamicData?: boolean;
  templateValue?: string | ReploMixedStyleValue;
  onClickRemoveDynamicData?(): void;
  onChangeAssetLoadingPoster?(value: string): void;
  onRemoveThumbnail?(): void;
  thumbnailSrc?: string | ReploMixedStyleValue;
} & AltTextProps;

type PreviewProps = {
  url: string;
  objectFitValue?: ObjectFitType;
  objectPositionXValue?: string;
  objectPositionYValue?: string;
};

const loadingOptions = [
  { value: "lazy", label: "Lazy: Loads on scroll" },
  { value: "eager", label: "Eager: Loads with page" },
];

const objectFitOptions = [
  { value: "fill", label: "Stretch To Fill" },
  { value: "contain", label: "Contain In Bounds" },
  { value: "cover", label: "Resize To Fill" },
  { value: "none", label: "Don't Resize" },
  { value: "scale-down", label: "Scale Down" },
];

const AssetPicker = ({
  assetType,
  url,
  emptyTitle,
  emptyStateButtonLabel,
  selectedStateButtonLabel,
  objectPositionValue,
  objectFitValue,
  assetLoadingValue,
  onChangeObjectFit,
  onChangeAssetLoading,
  onChangeObjectPositionX,
  onChangeObjectPositionY,
  onChangeSource,
  altTextControlLabel = "Alt Text",
  allowsChangingAltText = false,
  onChangeAltText,
  allowsSettingDynamicData = false,
  onClickDynamicDataForUrl,
  onClickDynamicDataForAltText,
  onClickDynamicDataForPoster,
  altTextValue,
  componentId,
  isUrlDynamicData = false,
  templateValue,
  onClickRemoveDynamicData,
  onChangeAssetLoadingPoster,
  onRemoveThumbnail,
  thumbnailSrc,
  onPosterInputChange,
  altTextComponent,
}: AssetPickerProps) => {
  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");
  const [objectPositionXValue, setObjectPositionXValue] = React.useState<
    ObjectPositionXType | ReploMixedStyleValue | undefined
  >(objectPositionValue?.x);
  const [objectPositionYValue, setObjectPositionYValue] = React.useState<
    ObjectPositionYType | ReploMixedStyleValue | undefined
  >(objectPositionValue?.y);

  const applyComponentAction = useApplyComponentAction();

  const [isAssetPickerPopoverOpen, setIsAssetPickerPopoverOpen] =
    React.useState(false);

  function _onSelectX(value: ObjectPositionXType) {
    setObjectPositionXValue(value);
    onChangeObjectPositionX?.(value);
  }

  function _onSelectY(value: ObjectPositionYType) {
    setObjectPositionYValue(value);
    onChangeObjectPositionY?.(value);
  }

  const objectPositionXOptions = [
    {
      id: "left",
      type: "leaf" as const,
      variant: "default" as const,
      size: "sm" as const,
      value: "left",
      title: "Left",
      onSelect: () => _onSelectX("left"),
    },
    {
      id: "center",
      type: "leaf" as const,
      variant: "default" as const,
      size: "sm" as const,
      value: "center",
      title: "Center",
      onSelect: () => _onSelectX("center"),
    },
    {
      id: "right",
      type: "leaf" as const,
      variant: "default" as const,
      size: "sm" as const,
      value: "right",
      title: "Right",
      onSelect: () => _onSelectX("right"),
    },
  ];

  const objectPositionYOptions = [
    {
      id: "top",
      type: "leaf" as const,
      variant: "default" as const,
      size: "sm" as const,
      title: "Top",
      onSelect: () => _onSelectY("top"),
    },
    {
      id: "center",
      type: "leaf" as const,
      variant: "default" as const,
      size: "sm" as const,
      title: "Center",
      onSelect: () => _onSelectY("center"),
    },
    {
      id: "bottom",
      type: "leaf" as const,
      variant: "default" as const,
      size: "sm" as const,
      title: "Bottom",
      onSelect: () => _onSelectY("bottom"),
    },
  ];

  const onSelectAsset = (isImagePreview: boolean) => {
    if (isUrlDynamicData && isImagePreview) {
      onClickDynamicDataForUrl?.();
    } else if (!isUrlDynamicData) {
      setIsAssetPickerPopoverOpen((val) => !val);
    }
  };

  const altTextInputProps = useOverridableInput({
    value: altTextValue ?? "",
    onValueChange: onChangeAltText,
  });

  function getObjectPositionValue(
    value: string | ReploMixedStyleValue | undefined,
  ) {
    if (isMixedStyleValue(value)) {
      return value;
    }
    if (value?.includes("px") || value?.includes("%")) {
      return value;
    }
    return startCase(value);
  }

  function getThumbnailSrc(value: string | ReploMixedStyleValue | undefined) {
    if (!value) {
      return undefined;
    }
    if (isMixedStyleValue(value)) {
      return value;
    } else {
      return {
        type: "image" as const,
        src: value,
      };
    }
  }

  return (
    <div className="flex flex-col gap-2 items-stretch">
      <div
        onClick={() => onSelectAsset(true)}
        className="flex mb-2 h-[150px] flex-col cursor-pointer items-stretch"
      >
        {url &&
          !isMixedStyleValue(url) &&
          !isMixedStyleValue(objectFitValue) && (
            <>
              {assetType === "image" ? (
                <ImagePreview url={url} objectFitValue={objectFitValue} />
              ) : (
                <VideoPreview url={url} objectFitValue={objectFitValue} />
              )}
            </>
          )}
        {!url && <EmptyUrlPreview emptyTitle={emptyTitle} />}
        {isMixedStyleValue(url) && <MixedPreview />}
      </div>
      {isUrlDynamicData && !isMixedStyleValue(templateValue) ? (
        isNewDynamicData ? (
          <DynamicDataSelector
            side="left"
            initialPath={templateValue?.split(".") ?? []}
            sideOffset={16}
            trigger={
              <DynamicDataValueIndicator
                type="image"
                templateValue={templateValue ?? "Dynamic Image"}
                componentId={componentId}
                onRemove={onClickRemoveDynamicData}
              />
            }
            targetType={DynamicDataTargetType.IMAGE}
            onChange={(newValue) => onChangeSource?.(newValue)}
          />
        ) : (
          <DynamicDataValueIndicator
            type="image"
            templateValue={templateValue ?? "Dynamic Image"}
            onClick={() => onClickDynamicDataForUrl?.()}
            componentId={componentId}
            onRemove={onClickRemoveDynamicData}
          />
        )
      ) : (
        <div className="flex h-6 gap-2">
          <AssetLibraryPopover
            isOpen={isAssetPickerPopoverOpen}
            setIsOpen={setIsAssetPickerPopoverOpen}
            onChangeSource={onChangeSource}
            assetTypes={[assetType]}
            // TODO (Gabe 2025-02-24): Why do we provide an anchor here? really
            // we should just be able to provide the trigger
            anchor={
              <div
                onClick={() => onSelectAsset(false)}
                className={twMerge(
                  "flex flex-grow cursor-pointer items-center justify-center rounded bg-subtle",
                  isUrlDynamicData && "cursor-not-allowed bg-disbled",
                )}
              >
                <span
                  className={twMerge(
                    "typ-body-small text-default",
                    isUrlDynamicData && "text-disabled",
                  )}
                >
                  {url ? selectedStateButtonLabel : emptyStateButtonLabel}
                </span>
              </div>
            }
          />
          {allowsSettingDynamicData &&
            (isNewDynamicData ? (
              <DynamicDataSelector
                side="left"
                sideOffset={220}
                trigger={<DynamicDataButton />}
                targetType={DynamicDataTargetType.IMAGE}
                onChange={(newValue) => onChangeSource?.(newValue)}
              />
            ) : (
              <DynamicDataButton onClick={() => onClickDynamicDataForUrl?.()} />
            ))}
        </div>
      )}
      {(Boolean(onChangeObjectFit) || allowsChangingAltText) && (
        <>
          {onChangeObjectFit ? (
            <ControlGroup label="Object Fit">
              <Selectable
                layoutClassName="w-full"
                triggerLayoutClassName="w-full"
                placeholder="Don't Resize"
                options={objectFitOptions}
                value={objectFitValue}
                defaultValue={styleAttributeToEditorData.objectFit.defaultValue}
                onSelect={(value: ObjectFitType) => onChangeObjectFit(value)}
                isDisabled={!url}
              />
            </ControlGroup>
          ) : null}
          {onChangeAssetLoading ? (
            <ControlGroup label="Loading">
              <Selectable
                popoverLayoutClassName="w-48"
                placeholder="Loading"
                dropdownAlign="end"
                options={loadingOptions}
                value={assetLoadingValue}
                defaultValue="eager"
                onSelect={(value: AssetLoadingType) =>
                  onChangeAssetLoading(value)
                }
              />
            </ControlGroup>
          ) : null}
          {Boolean(onChangeObjectPositionX) &&
          Boolean(onChangeObjectPositionY) ? (
            <>
              <LengthInputSelector
                value={getObjectPositionValue(objectPositionXValue)}
                label={<ModifierLabel label="X Position" />}
                onChange={_onSelectX}
                endEnhancer={() => (
                  <ChevronMenuIndicator items={objectPositionXOptions} />
                )}
                draggingType={DraggingTypes.Vertical}
                dragTrigger="label"
                field="PositionX"
                placeholder="center"
                metrics={["px", "%", "inherit"]}
                allowedNonUnitValues={["left", "center", "right"]}
                isDisabled={!url}
                previewProperty="objectPosition"
                previewSubProperty="objectPositionX"
                resetValue="center"
              />
              <LengthInputSelector
                value={getObjectPositionValue(objectPositionYValue)}
                label={<ModifierLabel label="Y Position" />}
                onChange={_onSelectY}
                endEnhancer={() => (
                  <ChevronMenuIndicator items={objectPositionYOptions} />
                )}
                draggingType={DraggingTypes.Vertical}
                dragTrigger="label"
                field="PositionY"
                placeholder="center"
                metrics={["px", "%", "inherit"]}
                allowedNonUnitValues={["top", "center", "bottom"]}
                isDisabled={!url}
                previewProperty="objectPosition"
                previewSubProperty="objectPositionY"
                resetValue="center"
              />
            </>
          ) : null}
          {onChangeAssetLoadingPoster ? (
            <ControlGroup label="Thumbnail">
              <div className="flex flex-col gap-2 align-start items-center w-full">
                {!isMixedStyleValue(thumbnailSrc) &&
                isDynamicDataValue(thumbnailSrc) ? (
                  isNewDynamicData ? (
                    <DynamicDataSelector
                      side="left"
                      sideOffset={90}
                      triggerClassName="w-full"
                      initialPath={thumbnailSrc?.split(".") ?? []}
                      trigger={
                        <DynamicDataValueIndicator
                          type="image"
                          className="w-full"
                          templateValue={thumbnailSrc ?? null}
                          onRemove={() => onRemoveThumbnail?.()}
                          componentId={componentId}
                        />
                      }
                      targetType={DynamicDataTargetType.IMAGE}
                      onChange={(value) =>
                        applyComponentAction({
                          type: "setProps",
                          value: { poster: value },
                        })
                      }
                    />
                  ) : (
                    <DynamicDataValueIndicator
                      type="image"
                      templateValue={thumbnailSrc ?? null}
                      onClick={() => onClickDynamicDataForPoster?.()}
                      onRemove={() => onRemoveThumbnail?.()}
                      componentId={componentId}
                    />
                  )
                ) : (
                  <InlineAssetSelector
                    size="sm"
                    emptyTitle="Select Thumbnail"
                    onClickDynamicData={onClickDynamicDataForPoster}
                    onSelectDynamicData={(value) =>
                      applyComponentAction({
                        type: "setProps",
                        value: { poster: value },
                      })
                    }
                    allowsDynamicData
                    onRemoveAsset={() => onRemoveThumbnail?.()}
                    allowRemoveAsset={Boolean(onRemoveThumbnail)}
                    asset={getThumbnailSrc(thumbnailSrc)}
                    onInputChange={onPosterInputChange}
                  />
                )}
              </div>
            </ControlGroup>
          ) : null}
          {altTextComponent ??
            (allowsChangingAltText && (
              <ControlGroup
                label={altTextControlLabel}
                className={twMerge(
                  "flex",
                  !isDynamicDataValue(altTextValue) && "items-start",
                )}
              >
                {isDynamicDataValue(altTextValue) && (
                  <DynamicDataValueIndicator
                    type="text"
                    templateValue={altTextValue ?? ""}
                    onClick={() => onClickDynamicDataForAltText?.()}
                    onRemove={() => onChangeAltText?.("")}
                    componentId={componentId}
                  />
                )}
                {!isDynamicDataValue(altTextValue) && (
                  <div className="flex gap-2">
                    <div className="flex-grow">
                      <Input {...altTextInputProps} />
                    </div>
                    {allowsSettingDynamicData && (
                      <DynamicDataButton
                        onClick={() => onClickDynamicDataForAltText?.()}
                      />
                    )}
                  </div>
                )}
              </ControlGroup>
            ))}
        </>
      )}
    </div>
  );
};

const ImagePreview: React.FC<PreviewProps> = ({ url, objectFitValue }) => {
  const [isBroken, setIsBroken] = React.useState(false);

  // NOTE (Fran 2025-01-29): We need to reset the broken state when the url
  // changes
  React.useEffect(() => {
    if (url) {
      setIsBroken(false);
    }
  }, [url]);

  if (isBroken) {
    return (
      <div className="h-full rounded bg-light-surface typ-button-small text-muted flex flex-col gap-1.5 items-center justify-center">
        <LuImageOff size={24} />
        <span>Preview unavailable</span>
      </div>
    );
  }

  return (
    <img
      src={url}
      onError={() => setIsBroken(true)}
      alt="image"
      className="h-full rounded object-cover object-center shadow-symmetrical"
      style={{
        objectFit: objectFitValue,
      }}
    />
  );
};

const VideoPreview: React.FC<PreviewProps> = ({ url, objectFitValue }) => {
  return (
    <video
      className="h-full rounded object-cover object-center"
      style={{
        objectFit: objectFitValue,
      }}
      src={url}
    />
  );
};

const EmptyUrlPreview: React.FC<{ emptyTitle: string }> = ({ emptyTitle }) => {
  return (
    <div className="flex flex-1 items-center justify-center rounded bg-subtle">
      <span className="text-xs text-subtle">{emptyTitle}</span>
    </div>
  );
};

const MixedPreview: React.FC = () => {
  return (
    <div className="flex h-full gap-2 items-center justify-center rounded bg-info-soft">
      <Badge type="dynamicData" />
      <span className="text-xs text-primary">Mixed</span>
    </div>
  );
};

export default AssetPicker;
