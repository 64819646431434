import type { Asset } from "schemas/generated/asset";

import * as React from "react";

import { DebouncedInput } from "@editor/components/common/designSystem/Input";
import Separator from "@editor/components/common/designSystem/Separator";
import { selectProjectHasShopifyIntegration } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpcUtils } from "@editor/utils/trpc";

import IconButton from "@replo/design-system/components/button/IconButton";
import { Tabs } from "@replo/design-system/components/tabs/Tabs";
import { BsSearch } from "react-icons/bs";
import { LuUpload } from "react-icons/lu";

import { ACCEPT_FILE_EXTENSIONS } from "../constants";
import { useReploFileDropZone } from "../useFileDropZone";
import { ReploAssetsGrid, ShopifyAssetsGrid } from "./AssetsGrid";

type ActiveTab = "replo" | "shopify";

const ASSETS_TABS: { label: string; value: ActiveTab }[] = [
  {
    label: "Replo",
    value: "replo",
  },
  {
    label: "Shopify",
    value: "shopify",
  },
];

type AssetsLibraryProps = {
  assetTypes?: ("image" | "video")[];
  onAssetSelected?: (asset: Asset) => void;
  shouldAllowActions?: boolean;
};

const AssetsLibrary: React.FC<AssetsLibraryProps> = ({
  assetTypes = ["image", "video"],
  onAssetSelected,
  shouldAllowActions = true,
}) => {
  const hasShopifyIntegration = useEditorSelector(
    selectProjectHasShopifyIntegration,
  );
  const [activeTab, setActiveTab] = React.useState<ActiveTab>("replo");
  const [searchTerm, setSearchTerm] = React.useState("");
  const {
    getRootProps,
    getInputProps,
    openFileDialog,
    droppedFilesMap,
    isDragActive,
  } = useReploFileDropZone({
    onUploadComplete: () => {
      void trpcUtils.asset.list.invalidate();
    },
    acceptDropAssetType: ACCEPT_FILE_EXTENSIONS,
    allowClickToUpload: true,
    isDisabled: activeTab === "shopify",
  });

  return (
    <div className="flex flex-col flex-1 gap-1 min-h-0">
      {hasShopifyIntegration && (
        <div className="pr-3">
          <Tabs
            tabsOptions={ASSETS_TABS}
            value={activeTab}
            onValueChange={(value) => setActiveTab(value as ActiveTab)}
            defaultValue={activeTab}
            size="sm"
          />
        </div>
      )}
      <div className="flex flex-col flex-1 pt-2 gap-3 min-h-0">
        <div className="flex gap-2 pr-3">
          <DebouncedInput
            autoComplete="off"
            placeholder="Search"
            value={searchTerm}
            onValueChange={setSearchTerm}
            startEnhancer={<BsSearch />}
          />
          {activeTab === "replo" && (
            <IconButton
              icon={<LuUpload />}
              variant="secondary"
              onClick={openFileDialog}
              size="sm"
            />
          )}
        </div>
        <div className="pr-3">
          <Separator />
        </div>
        {activeTab === "replo" ? (
          <ReploAssetsGrid
            searchTerm={searchTerm}
            getDropzoneInputProps={getInputProps}
            getDropzoneRootProps={getRootProps}
            assetTypes={assetTypes}
            onAssetSelected={onAssetSelected}
            shouldAllowActions={shouldAllowActions}
            droppedFilesMap={droppedFilesMap}
            isDragActive={isDragActive}
          />
        ) : (
          <ShopifyAssetsGrid
            searchTerm={searchTerm}
            assetTypes={assetTypes}
            onAssetSelected={onAssetSelected}
          />
        )}
      </div>
    </div>
  );
};

export default AssetsLibrary;
