import React from "react";

import DraggingCursorFrame from "@editor/components/DraggingCursorFrame";
import Header from "@editor/components/header/Header";
import LeftBar from "@editor/components/left-bar/LeftBar";
import RightBar from "@editor/components/RightBar";
import {
  selectDraftElementId,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { CanvasArea } from "@/features/canvas/Canvas";
import { CanvasControls } from "@/features/canvas/CanvasControls";

import { EditorLayout } from "./EditorLayout";

interface ElementEditorLayoutProps {
  children: React.ReactNode;
}

const ElementEditorLayout = ({ children }: ElementEditorLayoutProps) => {
  const forDisplayingElementWithinEditor = (
    <>
      <DraggingCursorFrame />
      <CanvasWrapper />
    </>
  );

  const elementSpecificEditorOverlays = (
    <>
      <CanvasControls />
      <LeftBar showElementSpecificContent={true} />
      <RightBar />
    </>
  );

  return (
    <EditorLayout
      toRenderWithinEditor={forDisplayingElementWithinEditor}
      toRenderOverEditor={elementSpecificEditorOverlays}
      projectHeader={<Header showElementSpecificContent={true} />}
    >
      {children}
    </EditorLayout>
  );
};

const CanvasWrapper = () => {
  const projectId = useEditorSelector(selectProjectId);
  const elementId = useEditorSelector(selectDraftElementId);
  const isCanvasVisible = Boolean(projectId) && Boolean(elementId);
  return isCanvasVisible ? <CanvasArea /> : null;
};

export default ElementEditorLayout;
