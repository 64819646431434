import type { HotkeyIndicatorCharacter } from "@editor/utils/hotkeys";
import type { LucideIcon } from "lucide-react";
import type { ReactNode } from "react";
import type { IconType } from "react-icons";

import { Sparkles } from "lucide-react";
import { AiOutlineInteraction } from "react-icons/ai";
import { BiDuplicate, BiPaste, BiPlay, BiRedo, BiUndo } from "react-icons/bi";
import {
  BsAlignBottom,
  BsAlignCenter,
  BsAlignEnd,
  BsAlignMiddle,
  BsAlignStart,
  BsAlignTop,
  BsArrowDown,
  BsArrowUp,
  BsBoxArrowUp,
  BsClockHistory,
  BsCode,
  BsCopy,
  BsDatabase,
  BsEye,
  BsFileText,
  BsGear,
  BsImage,
  BsLayers,
  BsLink,
  BsListOl,
  BsListUl,
  BsPalette,
  BsPlusSquare,
  BsSliders,
  BsTrash,
  BsTypeBold,
  BsTypeH1,
  BsTypeH2,
  BsTypeH3,
  BsTypeH4,
  BsTypeH5,
  BsTypeH6,
  BsTypeItalic,
  BsTypeStrikethrough,
  BsTypeUnderline,
  BsZoomIn,
  BsZoomOut,
} from "react-icons/bs";
import { CgTemplate } from "react-icons/cg";
import { FaLayerGroup } from "react-icons/fa";
import { HiOutlineHand } from "react-icons/hi";
import {
  MdHeight,
  MdOutlineDesignServices,
  MdWidthFull,
  MdWidthNormal,
} from "react-icons/md";
import { PiMagnifyingGlass, PiTextAaBold } from "react-icons/pi";
import { RiFontSize, RiLineHeight } from "react-icons/ri";
import { RxLetterSpacing } from "react-icons/rx";

type TextCommand =
  | "toggleH1Text"
  | "toggleH2Text"
  | "toggleH3Text"
  | "toggleH4Text"
  | "toggleH5Text"
  | "toggleH6Text"
  | "toggleBoldText"
  | "toggleLinkText"
  | "toggleItalicText"
  | "toggleUnderlineText"
  | "toggleStrikethroughText"
  | "toggleAIMenu"
  | "toggleBulletList"
  | "toggleNumberedList"
  | "decreaseFontSize"
  | "increaseFontSize"
  | "decreaseLetterSpacing"
  | "increaseLetterSpacing"
  | "decreaseLineHeight"
  | "increaseLineHeight"
  | "decreaseFontWeight"
  | "increaseFontWeight";

type EditCommand =
  | "undo"
  | "redo"
  | "copy"
  | "copyStyles"
  | "paste"
  | "pasteStyles"
  | "delete"
  | "duplicate"
  | "groupIntoContainer"
  | "saveComponentTemplate"
  | "exportToSection"
  | "setWidthToFillAvailable"
  | "setWidthToWrapContent"
  | "setHeightToFillAvailable"
  | "setHeightToWrapContent"
  | "toggleCodeEditor"
  | "alignLeft"
  | "alignRight"
  | "alignTop"
  | "alignBottom"
  | "alignHorizontalCenter"
  | "alignVerticalCenter";

type ViewCommand =
  | "toggleVisibility"
  | "togglePreviewMode"
  | "grabCanvas"
  | "toggleVersionHistory"
  | "toggleAIMenu"
  | "setDesignPanel"
  | "setConfigPanel"
  | "setInteractionsPanel"
  | "setElementsPanel"
  | "setLayersPanel"
  | "setComponentsPanel"
  | "setSavedStylesPanel"
  | "openPageSettings"
  | "setAssetsPanel"
  | "openProjectSettings"
  | "moveUpInTheTree"
  | "moveDownInTheTree"
  | "zoomIn"
  | "zoomOut"
  | "resetZoom";

export type ReploGlobalCommand = EditCommand | TextCommand | ViewCommand;

type CommandData = {
  label: string;
  hotkey?: HotkeyIndicatorCharacter[];
  icon?:
    | { type: "iconComponent"; component: IconType | LucideIcon }
    | { type: "create" }
    | { type: "publishedStatus"; isPublished: boolean }
    | { type: "custom"; content: ReactNode };
};

export const GLOBAL_COMMANDS: Record<ReploGlobalCommand, CommandData> = {
  undo: {
    label: "Undo",
    hotkey: ["meta", "z"],
    icon: { type: "iconComponent", component: BiUndo },
  },
  redo: {
    label: "Redo",
    hotkey: ["meta", "shift", "z"],
    icon: { type: "iconComponent", component: BiRedo },
  },
  copy: {
    label: "Copy",
    hotkey: ["meta", "c"],
    icon: { type: "iconComponent", component: BsCopy },
  },
  copyStyles: {
    label: "Copy styles",
    hotkey: ["altOption", "meta", "c"],
    icon: { type: "iconComponent", component: BsPalette },
  },
  paste: {
    label: "Paste",
    hotkey: ["meta", "v"],
    icon: { type: "iconComponent", component: BiPaste },
  },
  pasteStyles: {
    label: "Paste styles",
    hotkey: ["altOption", "meta", "v"],
    icon: { type: "iconComponent", component: BsPalette },
  },
  toggleVisibility: {
    label: "Toggle visibility",
    hotkey: ["meta", "shift", "h"],
    icon: { type: "iconComponent", component: BsEye },
  },
  delete: {
    label: "Delete",
    hotkey: ["backspace"],
    icon: { type: "iconComponent", component: BsTrash },
  },
  duplicate: {
    label: "Duplicate",
    hotkey: ["meta", "d"],
    icon: { type: "iconComponent", component: BiDuplicate },
  },
  groupIntoContainer: {
    label: "Group into container",
    hotkey: ["meta", "g"],
    icon: { type: "iconComponent", component: FaLayerGroup },
  },
  saveComponentTemplate: {
    label: "Save component template",
    hotkey: ["meta", "shift", "s"],
    icon: { type: "iconComponent", component: CgTemplate },
  },
  zoomIn: {
    label: "Zoom in",
    hotkey: ["meta", "+"],
    icon: { type: "iconComponent", component: BsZoomIn },
  },
  zoomOut: {
    label: "Zoom out",
    hotkey: ["meta", "-"],
    icon: { type: "iconComponent", component: BsZoomOut },
  },
  resetZoom: {
    label: "Reset zoom",
    hotkey: ["meta", "0"],
    icon: { type: "iconComponent", component: PiMagnifyingGlass },
  },
  grabCanvas: {
    label: "Pan canvas",
    hotkey: ["space"],
    icon: { type: "iconComponent", component: HiOutlineHand },
  },
  toggleCodeEditor: {
    label: "Toggle code editor",
    hotkey: ["shift", "d"],
    icon: { type: "iconComponent", component: BsCode },
  },
  togglePreviewMode: {
    label: "Toggle preview mode",
    hotkey: ["shift", "p"],
    icon: { type: "iconComponent", component: BiPlay },
  },
  toggleAIMenu: {
    label: "Build Assistant",
    hotkey: ["meta", "e"],
    icon: { type: "iconComponent", component: Sparkles },
  },
  toggleH1Text: {
    label: "Turn into H1",
    hotkey: ["meta", "altOption", "1"],
    icon: { type: "iconComponent", component: BsTypeH1 },
  },
  toggleH2Text: {
    label: "Turn into H2",
    hotkey: ["meta", "altOption", "2"],
    icon: { type: "iconComponent", component: BsTypeH2 },
  },
  toggleH3Text: {
    label: "Turn into H3",
    hotkey: ["meta", "altOption", "3"],
    icon: { type: "iconComponent", component: BsTypeH3 },
  },
  toggleH4Text: {
    label: "Turn into H4",
    hotkey: ["meta", "altOption", "4"],
    icon: { type: "iconComponent", component: BsTypeH4 },
  },
  toggleH5Text: {
    label: "Turn into H5",
    hotkey: ["meta", "altOption", "5"],
    icon: { type: "iconComponent", component: BsTypeH5 },
  },
  toggleH6Text: {
    label: "Turn into H6",
    hotkey: ["meta", "altOption", "6"],
    icon: { type: "iconComponent", component: BsTypeH6 },
  },
  toggleBoldText: {
    label: "Toggle bold",
    hotkey: ["meta", "b"],
    icon: { type: "iconComponent", component: BsTypeBold },
  },
  toggleLinkText: {
    label: "Create link",
    hotkey: ["meta", "k"],
    icon: { type: "iconComponent", component: BsLink },
  },
  toggleItalicText: {
    label: "Toggle italic",
    hotkey: ["meta", "i"],
    icon: { type: "iconComponent", component: BsTypeItalic },
  },
  toggleUnderlineText: {
    label: "Toggle underline",
    hotkey: ["meta", "u"],
    icon: { type: "iconComponent", component: BsTypeUnderline },
  },
  toggleStrikethroughText: {
    label: "Toggle strikethrough",
    hotkey: ["shift", "meta", "x"],
    icon: { type: "iconComponent", component: BsTypeStrikethrough },
  },
  toggleBulletList: {
    label: "Toggle bullet list",
    hotkey: ["meta", "altOption", "7"],
    icon: { type: "iconComponent", component: BsListUl },
  },
  toggleNumberedList: {
    label: "Toggle numbered list",
    hotkey: ["meta", "altOption", "8"],
    icon: { type: "iconComponent", component: BsListOl },
  },
  setDesignPanel: {
    label: "Show design panel",
    hotkey: ["s"],
    icon: { type: "iconComponent", component: MdOutlineDesignServices },
  },
  setConfigPanel: {
    label: "Show config panel",
    hotkey: ["d"],
    icon: { type: "iconComponent", component: BsDatabase },
  },
  setInteractionsPanel: {
    label: "Show interactions panel",
    hotkey: ["i"],
    icon: { type: "iconComponent", component: AiOutlineInteraction },
  },
  setComponentsPanel: {
    label: "Show insert panel",
    hotkey: ["altOption", "1"],
    icon: { type: "iconComponent", component: BsPlusSquare },
  },
  setLayersPanel: {
    label: "Show layers panel",
    hotkey: ["altOption", "2"],
    icon: { type: "iconComponent", component: BsLayers },
  },
  openPageSettings: {
    label: "Show page settings",
    hotkey: ["altOption", "3"],
    icon: { type: "iconComponent", component: BsGear },
  },
  toggleVersionHistory: {
    label: "Show version history panel",
    hotkey: ["altOption", "4"],
    icon: { type: "iconComponent", component: BsClockHistory },
  },
  setElementsPanel: {
    label: "Show all pages panel",
    hotkey: ["altOption", "5"],
    icon: { type: "iconComponent", component: BsFileText },
  },
  setAssetsPanel: {
    label: "Show assets panel",
    hotkey: ["altOption", "6"],
    icon: { type: "iconComponent", component: BsImage },
  },
  setSavedStylesPanel: {
    label: "Show shop details panel",
    hotkey: ["altOption", "7"],
    icon: { type: "iconComponent", component: BsPalette },
  },
  openProjectSettings: {
    label: "Open project settings",
    hotkey: ["meta", "altOption", "s"],
    icon: { type: "iconComponent", component: BsSliders },
  },
  decreaseFontSize: {
    label: "Decrease font size",
    hotkey: ["shift", "meta", "<"],
    icon: { type: "iconComponent", component: RiFontSize },
  },
  increaseFontSize: {
    label: "Increase font size",
    hotkey: ["shift", "meta", ">"],
    icon: { type: "iconComponent", component: RiFontSize },
  },
  decreaseLetterSpacing: {
    label: "Decrease letter spacing",
    hotkey: ["altOption", "<"],
    icon: { type: "iconComponent", component: RxLetterSpacing },
  },
  increaseLetterSpacing: {
    label: "Increase letter spacing",
    hotkey: ["altOption", ">"],
    icon: { type: "iconComponent", component: RxLetterSpacing },
  },
  decreaseLineHeight: {
    label: "Decrease line height",
    hotkey: ["altOption", "shift", "<"],
    icon: { type: "iconComponent", component: RiLineHeight },
  },
  increaseLineHeight: {
    label: "Increase line height",
    hotkey: ["altOption", "shift", ">"],
    icon: { type: "iconComponent", component: RiLineHeight },
  },
  decreaseFontWeight: {
    label: "Decrease font weight",
    hotkey: ["altOption", "meta", "<"],
    icon: { type: "iconComponent", component: PiTextAaBold },
  },
  increaseFontWeight: {
    label: "Increase font weight",
    hotkey: ["altOption", "meta", ">"],
    icon: { type: "iconComponent", component: PiTextAaBold },
  },
  moveUpInTheTree: {
    label: "Move up in the tree",
    hotkey: ["↑"],
    icon: { type: "iconComponent", component: BsArrowUp },
  },
  moveDownInTheTree: {
    label: "Move down in the tree",
    hotkey: ["↓"],
    icon: { type: "iconComponent", component: BsArrowDown },
  },
  exportToSection: {
    label: "Export to section",
    hotkey: ["altOption", "meta", "k"],
    icon: { type: "iconComponent", component: BsBoxArrowUp },
  },
  setWidthToFillAvailable: {
    label: "Fill Available Width",
    hotkey: ["meta", "shift", "arrowRight"],
    icon: { type: "iconComponent", component: MdWidthFull },
  },
  setWidthToWrapContent: {
    label: "Wrap Content Width",
    hotkey: ["meta", "shift", "arrowLeft"],
    icon: { type: "iconComponent", component: MdWidthNormal },
  },
  setHeightToFillAvailable: {
    label: "Fill Available Height",
    hotkey: ["meta", "shift", "arrowUp"],
    icon: { type: "iconComponent", component: MdHeight },
  },
  setHeightToWrapContent: {
    label: "Wrap Content Height",
    hotkey: ["meta", "shift", "arrowDown"],
    icon: { type: "iconComponent", component: MdHeight },
  },
  alignLeft: {
    label: "Align Left",
    hotkey: ["altOption", "a"],
    icon: { type: "iconComponent", component: BsAlignStart },
  },
  alignRight: {
    label: "Align Right",
    hotkey: ["altOption", "d"],
    icon: { type: "iconComponent", component: BsAlignEnd },
  },
  alignTop: {
    label: "Align Top",
    hotkey: ["altOption", "w"],
    icon: { type: "iconComponent", component: BsAlignTop },
  },
  alignBottom: {
    label: "Align Bottom",
    hotkey: ["altOption", "s"],
    icon: { type: "iconComponent", component: BsAlignBottom },
  },
  alignHorizontalCenter: {
    label: "Align Center Horizontally",
    hotkey: ["altOption", "h"],
    icon: { type: "iconComponent", component: BsAlignCenter },
  },
  alignVerticalCenter: {
    label: "Align Center Vertically",
    hotkey: ["altOption", "v"],
    icon: { type: "iconComponent", component: BsAlignMiddle },
  },
};

type LeafCommandItem = CommandData & {
  isDisabled?: boolean;
  onSelect: () => void;
};

type GlobalLeafCommandItem = CommandData & {
  isDisabled?: boolean;
  command: ReploGlobalCommand;
};

type ChildrenCommandItem = CommandData & {
  type: "children";
  isDisabled?: boolean;
  children: Record<string, CommandItem>;
};

export type CommandItem =
  | LeafCommandItem
  | GlobalLeafCommandItem
  | ChildrenCommandItem;

export const commandItemHasChildren = (
  commandItem: CommandItem,
): commandItem is CommandItem & {
  type: "children";
  children: Record<string, CommandItem>;
} => "type" in commandItem && commandItem.type === "children";

export const commandItemHasGlobalCommand = (
  commandItem: CommandItem,
): commandItem is GlobalLeafCommandItem => "command" in commandItem;

export type CommandGroup = {
  id: string;
  heading: string;
  items: Record<string, CommandItem>;
};

export type CommandGroupWithActions = CommandGroup & {
  items: Record<string, CommandItem>;
};
