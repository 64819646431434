// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { ExperimentStatus } from "schemas/experiment";
import type { ReploElementForExperiments } from "schemas/generated/element";
import type { Experiment } from "schemas/generated/experiment";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import { Loader } from "@editor/components/common/Loader";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import { SHOPIFY_APP_LISTING_URL } from "@/config";
import { useAnalyticsOnboardingOAuthLink } from "@/features/analytics/useAnalyticsOnboaredingOAuthLink";
import { DetailsContainer } from "@/features/experiments/components/DetailsContainer";
import { PageUrlSelect } from "@/features/experiments/components/PageUrlSelect";
import { LinkSection } from "@/features/experiments/components/sections/LinkSection";
import { useExperimentEdit } from "@/features/experiments/tabs/hooks/useExperimentEdit";
import { useExperimentApi } from "@/features/experiments/utils";
import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken } from "@tanstack/react-query";
import { BsInfoCircle, BsPlus } from "react-icons/bs";
import { LuEqual, LuEqualNot, LuExternalLink, LuTrash2 } from "react-icons/lu";
import { generatePath } from "react-router-dom";
import { isValidHttpUrl } from "replo-utils/lib/url";
import { BillingTiers } from "schemas/billing";
import {
  getExperimentStatus,
  VARIATION_ERROR_MESSAGES,
} from "schemas/experiment";
import { isPathSafeSlug } from "schemas/utils";

import { VariationNameStartEnhancer } from "../components/VariationNameStartEnhancer";

export const ExperimentsEditTabV2: React.FC<{
  experiment: Experiment;
}> = ({ experiment }) => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;

  const { data: integrationsData } =
    trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
      workspaceId ? { workspaceId } : skipToken,
    );

  if (!workspaceId || !integrationsData) {
    return <Loader />;
  }

  return (
    <>
      <ExperimentEditComponent
        workspaceId={workspaceId}
        experiment={experiment}
      />
    </>
  );
};

type SplitMethod = "equal" | "manual";

const ExperimentEditComponent = ({
  workspaceId,
  experiment,
}: {
  workspaceId: string;
  experiment: Experiment;
}) => {
  const { watch, dispatchExperimentEdit } = useExperimentEdit();
  const logEvent = useLogAnalytics();

  const {
    links: { data: links, isLoading: isLoadingLinks },
  } = useExperimentApi({ workspaceId });

  const { data: allReploElements, isLoading: isLoadingAllReploElements } =
    trpc.workspace.getAllElementsWithShopifyUrl.useQuery(
      workspaceId ?? skipToken,
    );

  const [splitMethod, setSplitMethod] = React.useState<SplitMethod>("equal");

  const { errors } = useExperimentEdit();

  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;

  const shouldForceCreateNewLink = links !== undefined && links.length === 0;

  const [linkSubSection, setLinkSubSection] = React.useState<
    "chooseLink" | "createNewLink"
  >(shouldForceCreateNewLink ? "createNewLink" : "chooseLink");

  const handleLinkSubsectionChange = (value: string) => {
    setLinkSubSection(value as "chooseLink" | "createNewLink");
    if (value === "createNewLink") {
      logEvent("experiment.link.create", {
        billingPlanTier: subscriptionTier,
      });
    }
  };

  const status = getExperimentStatus(experiment);
  const isEditable = status === "draft";

  const isAllocationPercentValid =
    errors.variations?.message !== VARIATION_ERROR_MESSAGES.allocationPercent;

  return (
    <div className="flex flex-col gap-2 pb-32">
      <div className="space-y-4 text-sm">
        {!isLoadingLinks && (
          <LinkSection
            linkSubSection={linkSubSection}
            handleLinkSubsectionChange={handleLinkSubsectionChange}
            isEditable={isEditable}
            links={links ?? []}
            shouldForceCreateNewLink={shouldForceCreateNewLink}
            experiment={{
              analyticsLinkId:
                watch("completedAnalyticsLinkId") ?? watch("analyticsLinkId"),
              name: experiment.name,
            }}
          />
        )}
        <DetailsContainer
          title="Variants"
          headerComponent={
            isEditable && (
              <div className="max-w-[55px]">
                <ToggleGroup
                  size="sm"
                  options={[
                    {
                      label: <LuEqualNot size={12} />,
                      value: "manual",
                      tooltipContent: "Manual split",
                    },
                    {
                      label: <LuEqual size={12} />,
                      value: "equal",
                      tooltipContent: "Equal split",
                    },
                  ]}
                  selectedValue={splitMethod}
                  onChange={(value) => {
                    setSplitMethod(value as SplitMethod);
                    if (value === "equal") {
                      dispatchExperimentEdit({
                        type: "setEqualVariationSplit",
                      });
                    }
                  }}
                />
              </div>
            )
          }
          isRequired
        >
          <div className="space-y-3">
            {watch("variations").map((variation, index) => (
              <VariationEntry
                key={variation.id}
                index={index}
                {...variation}
                isAllocationPercentValid={isAllocationPercentValid}
                splitMethod={splitMethod}
                allowRemoval={watch("variations").length > 1}
                status={status}
                allReploElements={allReploElements?.elements ?? []}
                isLoadingAllReploElements={isLoadingAllReploElements}
              />
            ))}
            <div
              className={twMerge(
                "text-danger",
                !errors.variations?.message && "hidden",
              )}
            >
              {errors.variations?.message}
            </div>
            {isEditable && (
              <div
                className="cursor-pointer bg-slate-50 hover:bg-slate-100 flex justify-center items-center py-4 border border-dashed border-slate-300 rounded"
                onClick={() => dispatchExperimentEdit({ type: "addVariation" })}
              >
                <div className="flex flex-row items-center gap-1">
                  <BsPlus size={16} />
                  <span className="typ-button-small">Add variant</span>
                </div>
              </div>
            )}
          </div>
        </DetailsContainer>
        <DetailsContainer title="Description">
          <div className="flex border-[0.5px] border-slate-300 p-4 rounded">
            <Textarea
              layoutClassName="w-full"
              placeholder="Keep track of why you are testing or other test details, for yourself or others on your team"
              onChange={(description) =>
                dispatchExperimentEdit({
                  type: "changeProperty",
                  payload: { key: "description", value: description },
                })
              }
              value={watch("description") ?? ""}
              size="sm"
            />
          </div>
        </DetailsContainer>
      </div>
    </div>
  );
};

const ConnectShopifyButton: React.FC = () => {
  return (
    <div className="text-accent">
      <Button variant="inherit" size="sm" to={SHOPIFY_APP_LISTING_URL}>
        Connect Shopify
      </Button>
    </div>
  );
};

const EnableAnalyticsButton: React.FC<{ shopifyUrl: string }> = ({
  shopifyUrl,
}) => {
  const { oauthLink, isLoading } = useAnalyticsOnboardingOAuthLink(shopifyUrl);
  const productAnalytics = useLogAnalytics();

  return (
    <div className="text-accent">
      <Button
        size="sm"
        variant="inherit"
        to={oauthLink ?? ""}
        isLoading={isLoading || !oauthLink}
        onClick={() =>
          productAnalytics("analytics.connect", {
            tab: "experiment_details_tab",
          })
        }
      >
        Enable Analytics
      </Button>
    </div>
  );
};

const ConnectShopifyVariationEntryInfoBanner: React.FC = () => {
  return (
    <div className="bg-accent-emphasis flex flex-row rounded-md px-2 py-1 items-center justify-between">
      <div className="flex flex-row gap-3 text-accent">
        <BsInfoCircle />
        <span className="text-xs">
          This page must be part of a Replo project with a Shopify integration
          to track results.
        </span>
      </div>
      <ConnectShopifyButton />
    </div>
  );
};

const ConnectAnalyticsVariationEntryInfoBanner: React.FC<{
  shopifyUrl: string;
}> = ({ shopifyUrl }) => {
  return (
    <div className="bg-accent-emphasis flex flex-row rounded-md px-2 py-1 items-center justify-between">
      <div className="flex flex-row gap-3 text-accent">
        <BsInfoCircle />
        <span className="text-xs">
          You must enable Analytics for this Shopify integration in order to
          track results on this page.
        </span>
      </div>
      <EnableAnalyticsButton shopifyUrl={shopifyUrl} />
    </div>
  );
};

function convertElementToFullUrl(element: ReploElementForExperiments) {
  return `https://${element.shopifyUrl}/pages/${element.shopifyPagePath}`;
}

const VariationEntryField: React.FC<{
  title: string | null;
  className?: string;
  children: React.ReactNode;
}> = ({ title, children, className }) => {
  return (
    <div className={twMerge("flex flex-col gap-1.5", className)}>
      {title && <span className="typ-label-small">{title}</span>}
      <div>{children}</div>
    </div>
  );
};

const VariationEntry = ({
  allowRemoval,
  isAllocationPercentValid,
  status,
  allReploElements,
  isLoadingAllReploElements,
  splitMethod,
  index,
  ...variation
}: Variation & {
  allowRemoval: boolean;
  isAllocationPercentValid: boolean;
  status: ExperimentStatus;
  allReploElements: ReploElementForExperiments[];
  isLoadingAllReploElements: boolean;
  splitMethod: SplitMethod;
  index: number;
}) => {
  const isEditable = status === "draft";

  const { dispatchExperimentEdit } = useExperimentEdit();

  const allReploElementsOptions = React.useMemo(
    () =>
      allReploElements.map((element) => {
        const fullUrl = convertElementToFullUrl(element);
        return {
          label: fullUrl,
          value: fullUrl,
        };
      }),
    [allReploElements],
  );

  const target = variation.target;

  let isTargetConnectedToShopify = false;
  let isTargetStoreConnectedToAnalytics = false;

  let matchingElement: ReploElementForExperiments | undefined;

  if (target && isValidHttpUrl(target)) {
    const targetDomain = new URL(target).hostname;

    matchingElement = allReploElements.find(
      (element) => element.shopifyUrl === targetDomain,
    );

    isTargetConnectedToShopify = Boolean(matchingElement);
    isTargetStoreConnectedToAnalytics = Boolean(matchingElement?.hasWebPixelId);
  }

  const targetElement = allReploElements.find(
    (element) => convertElementToFullUrl(element) === target,
  );

  const handlePageLinkIconClick = () => {
    if (targetElement) {
      window.open(
        generatePath(routes.editor.element, {
          projectId: targetElement.projectId,
          elementId: targetElement.id,
        }),
        "_blank",
      );
    }
  };

  const shopifyUrl = targetElement?.shopifyUrl;
  const shouldShowShopifyBanner = target && !isTargetConnectedToShopify;
  // NOTE (Max, 2024-11-14): We only wanna show the analyticsBanner if the
  // target is connected to Shopify.
  const shouldShowAnalyticsBanner =
    target &&
    shopifyUrl &&
    isTargetConnectedToShopify &&
    !isTargetStoreConnectedToAnalytics;

  const handleSelectedPageUrlChange = (value: string) => {
    dispatchExperimentEdit({
      type: "changeVariation",
      payload: { ...variation, target: value },
    });
  };

  return (
    <div className="flex flex-col gap-2 border-[0.5px] border-slate-300 p-4 rounded">
      <div className="flex gap-3 w-full">
        <VariationEntryField title="Name" className="flex-none max-w-[178px]">
          {isEditable ? (
            <Input
              startEnhancer={<VariationNameStartEnhancer index={index} />}
              size="sm"
              value={variation.slug}
              type="text"
              layoutClassName="h-8"
              unsafe_inputClassName="typ-body-small"
              validityState={
                !isPathSafeSlug(variation.slug) ? "invalid" : "valid"
              }
              onChange={(e) =>
                dispatchExperimentEdit({
                  type: "changeVariation",
                  payload: { ...variation, slug: e.currentTarget.value },
                })
              }
            />
          ) : (
            <div className="py-1 pr-2 min-w-[120px]">{variation.slug}</div>
          )}
        </VariationEntryField>
        <VariationEntryField title="Page" className="flex-1 min-w-0">
          <PageUrlSelect
            isEditable={isEditable}
            target={target}
            onChange={handleSelectedPageUrlChange}
            options={allReploElementsOptions}
          />
        </VariationEntryField>
        <div className="border-0.5" />
        <VariationEntryField title="Split" className="flex-none max-w-[55px]">
          {isEditable ? (
            <Input
              size="base"
              value={variation.allocationPercent.toString()}
              validityState={!isAllocationPercentValid ? "invalid" : "valid"}
              type="number"
              unsafe_inputClassName="typ-body-small"
              isDisabled={splitMethod === "equal"}
              onChange={(e) => {
                const percent = Number.parseInt(e.currentTarget.value);
                const allocationPercent = Number.isNaN(percent)
                  ? 0
                  : Math.max(percent, 0);
                dispatchExperimentEdit({
                  type: "changeVariation",
                  payload: { ...variation, allocationPercent },
                });
              }}
            />
          ) : (
            <div className="py-1">{variation.allocationPercent}%</div>
          )}
        </VariationEntryField>
        <VariationEntryField title={null} className="flex-none justify-center">
          <div className="flex flex-col gap-2 justify-center items-center">
            <IconButton
              variant="tertiary"
              size="sm"
              disabled={!targetElement}
              onClick={handlePageLinkIconClick}
              icon={<LuExternalLink size={16} />}
            />
            {isEditable && (
              <IconButton
                variant="tertiary"
                size="sm"
                disabled={!allowRemoval}
                onClick={() =>
                  dispatchExperimentEdit({
                    type: "removeVariation",
                    payload: variation.id,
                  })
                }
                icon={<LuTrash2 size={16} />}
              />
            )}
          </div>
        </VariationEntryField>
      </div>
      {shouldShowShopifyBanner && (
        <div>
          <ConnectShopifyVariationEntryInfoBanner />
        </div>
      )}
      {shouldShowAnalyticsBanner && (
        <div>
          <ConnectAnalyticsVariationEntryInfoBanner shopifyUrl={shopifyUrl} />
        </div>
      )}
    </div>
  );
};
