import type { BrandDetails } from "schemas/generated/ai";

import { selectLoadableProject } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

const useBrandDetails = () => {
  const { project } = useEditorSelector(selectLoadableProject);

  const { data, isLoading } = trpc.ai.getBrandDetails.useQuery(
    project ? { projectId: project.id } : skipToken,
  );

  const utils = trpc.useUtils();

  const brandDetails = data?.brandDetails;

  const { mutate } = trpc.ai.createOrUpdateBrandDetails.useMutation({
    // Note (Evan, 2024-09-27): This is the react-query pattern for optimistic updates
    // see: https://tanstack.com/query/v4/docs/framework/react/guides/optimistic-updates
    onMutate: async ({ projectId, brandDetails: newBrandDetails }) => {
      // Note (Evan, 2024-09-24): Optimistically update, first cancel any outgoing refetches
      await utils.ai.getBrandDetails.cancel({
        projectId,
      });

      // Capture the previous value to return as context
      const previousBrandDetails = utils.ai.getBrandDetails.getData();

      // Optimistically update the cache
      utils.ai.getBrandDetails.setData({ projectId }, () => ({
        brandDetails: newBrandDetails,
      }));

      return { previousBrandDetails };
    },
    // Note (Evan, 2024-09-24): On error, roll back the cache to the previous value
    onError: (_err, newBrandDetails, context) => {
      utils.ai.getBrandDetails.setData(
        { projectId: newBrandDetails.projectId },
        context?.previousBrandDetails,
      );
    },
    onSettled: () => {
      void utils.ai.getBrandDetails.invalidate();
    },
  });

  const setBrandDetails = (brandDetails: BrandDetails) => {
    if (!project) {
      return;
    }
    mutate({ projectId: project.id, brandDetails });
  };

  return { brandDetails, setBrandDetails, isLoading };
};

export default useBrandDetails;
