import type { PlanInfo } from "schemas/billing";
import type { SubscriptionDetails } from "schemas/generated/billing";

import * as React from "react";

import Header from "@editor/components/dashboard/Header";
import {
  useCurrentWorkspace,
  useCurrentWorkspaceId,
} from "@editor/contexts/WorkspaceDashboardContext";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useModal } from "@editor/hooks/useModal";
import { usePrefetchBillingInfoImmediately } from "@editor/hooks/usePrefetchImmediately";
import { trpc } from "@editor/utils/trpc";
import Shopify from "@svg/shopify";

import { getPublisherUrl } from "@/config";
import Button from "@replo/design-system/components/button/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@replo/design-system/components/shadcn/core/card";
import { Progress } from "@replo/design-system/components/shadcn/core/progress";
import { skipToken } from "@tanstack/react-query";
import { format } from "date-fns";
import { BsCheckCircleFill, BsFileText } from "react-icons/bs";
import { Link, Outlet } from "react-router-dom";
import { formatPrice } from "replo-utils/lib/math";
import { BILLING_TIER_INFO_MAP } from "schemas/billing";

const getStripePortalUrl = (workspaceId: string) => {
  const url = new URL(`${getPublisherUrl()}/api/v1/stripe/portal`);
  url.searchParams.append("workspaceId", workspaceId);
  url.searchParams.append("returnUrl", window.location.href);
  return url.toString();
};

const Billing: React.FC = () => {
  const modal = useModal();

  const workspace = useCurrentWorkspace();
  const workspaceId = workspace?.id;
  const { data: activeSubscription, isSuccess } =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspace?.id ?? skipToken,
    );

  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);
  usePrefetchBillingInfoImmediately();

  const showStripeConversion =
    isWorkspaceOwner &&
    activeSubscription?.paymentProcessor === "shopify" &&
    workspace?.preferredPaymentProcessor === "stripe";

  const { data: integrationUsage, isLoading: isLoadingIntegrationUsage } =
    trpc.workspace.getShopifyIntegrationsUsage.useQuery(
      workspaceId ?? skipToken,
    );
  const {
    data: publishedElementUsage,
    isLoading: isLoadingPublishedElementUsage,
  } = trpc.workspace.getPublishedElementsUsage.useQuery(
    workspaceId ?? skipToken,
  );

  const { data: subscriptionStatus } = trpc.subscriptions.getStatus.useQuery(
    workspaceId &&
      activeSubscription?.paymentProcessor === "stripe" &&
      activeSubscription.paymentProcessorSubscriptionId
      ? {
          workspaceId,
          subscriptionId: activeSubscription.id,
        }
      : skipToken,
  );

  const { mutate: generateStripeConversionLink } =
    trpc.billing.getStripeConversionLink.useMutation({
      onSuccess: (conversionUrl) => {
        window.location.href = conversionUrl;
      },
    });

  const planName = activeSubscription?.name ?? "free";

  const isLimitExceeded =
    publishedElementUsage &&
    hasReachedPublishingLimit(
      BILLING_TIER_INFO_MAP[planName],
      publishedElementUsage,
    );
  const isLoading = isLoadingPublishedElementUsage || isLoadingIntegrationUsage;
  if (isLoading || !publishedElementUsage || !integrationUsage) {
    return null;
  }

  const shouldShowPublishLimitExceededMessage =
    isLimitExceeded && planName !== "custom";
  const shouldShowIntegrationLimitExceededMessage =
    integrationUsage.current >=
    (integrationUsage.maximum ?? Number.POSITIVE_INFINITY);
  const shouldShowPaymentError = subscriptionStatus?.status === "past_due";

  let alertCard: React.ReactNode;
  if (!activeSubscription) {
    alertCard = (
      <BillingAlertCard
        title="Unlock the power of Replo"
        description="Publish pages to Shopify, track your pages analytics, and AB test all within one platform"
      >
        <Button
          variant="tertiary"
          size="base"
          to="https://support.replo.app/articles/22389707341325-What-is-the-best-Replo-plan-for-me"
        >
          Learn more
        </Button>
        <Button
          variant="primary"
          size="base"
          onClick={() =>
            modal.openModal({
              type: "billingModal",
              props: { source: "direct.billingDashboard" },
            })
          }
        >
          Upgrade
        </Button>
      </BillingAlertCard>
    );
  } else if (shouldShowPaymentError) {
    alertCard = (
      <BillingAlertCard
        title="Payment Method Error"
        description="There is an error with your payment method. Please update your payment method or your Subscription will be canceled."
      >
        <Button
          variant="primary"
          to={getStripePortalUrl(workspaceId ?? "")}
          size="base"
        >
          Update Payment Method
        </Button>
      </BillingAlertCard>
    );
  } else if (shouldShowPublishLimitExceededMessage) {
    alertCard = (
      <BillingAlertCard
        title="Publishing Limits Reached"
        description="You have reached the publishing limits on your current account. Upgrade to increase the publishing limits."
      >
        <Button
          variant="tertiary"
          size="base"
          to="https://support.replo.app/articles/22389707341325-What-is-the-best-Replo-plan-for-me"
        >
          Learn more
        </Button>
      </BillingAlertCard>
    );
  } else if (shouldShowIntegrationLimitExceededMessage) {
    alertCard = (
      <BillingAlertCard
        title="You have reached your limit"
        description="You have reached the Integration limit for this Workspace. Upgrade to increase your limits."
      >
        <Button
          variant="tertiary"
          size="base"
          to="https://support.replo.app/articles/22389707341325-What-is-the-best-Replo-plan-for-me"
        >
          Learn more
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() =>
            modal.openModal({
              type: "billingModal",
              props: { source: "direct.billingDashboard" },
            })
          }
        >
          Upgrade
        </Button>
      </BillingAlertCard>
    );
  } else if (showStripeConversion) {
    alertCard = (
      <BillingAlertCard
        title="Update your payment method"
        description="We recently upgraded to Stripe as our preferred payment platform.
                    To take advantage of benefits such as multiple Shopify integrations within
                    a single workspace, please update your payment method."
      >
        <Button
          variant="primary"
          size="base"
          layoutClassName="mt-1"
          onClick={() => {
            if (!workspaceId) {
              return;
            }
            void generateStripeConversionLink({ workspaceId });
          }}
        >
          Update Payment Method
        </Button>
      </BillingAlertCard>
    );
  }

  return (
    <div className="flex w-full flex-col px-6 gap-4">
      <Header
        title={workspace?.name ? `${workspace?.name} Billing` : ""}
        endEnhancer={
          isWorkspaceOwner &&
          !alertCard && (
            <Button
              variant="primary"
              size="base"
              tooltipText={
                !isWorkspaceOwner
                  ? "Only Workspace Owners have the ability to manage billing details"
                  : undefined
              }
              disabled={!isWorkspaceOwner}
              onClick={() => {
                modal.openModal({
                  type: "billingModal",
                  props: { source: "direct.billingDashboard" },
                });
              }}
            >
              Upgrade
            </Button>
          )
        }
      />
      <hr className="" />
      {alertCard}
      {isSuccess && (
        <BillingInfo
          activeSubscription={activeSubscription ?? undefined}
          isWorkspaceOwner={isWorkspaceOwner}
          showStripeConversion={showStripeConversion}
        />
      )}
      <Outlet />
    </div>
  );
};

const hasReachedPublishingLimit = (
  planInfo: PlanInfo | undefined,
  publishedElements: {
    current: number;
    maximum: number | null;
  },
): boolean => {
  if (!planInfo) {
    return false;
  }
  if (publishedElements.current === 0 && planInfo.tier === "free") {
    return false;
  }
  return Boolean(
    planInfo.maxPublishedElements &&
      publishedElements.current >=
        (publishedElements.maximum ?? Number.POSITIVE_INFINITY),
  );
};

const BillingInfo = ({
  activeSubscription,
  isWorkspaceOwner,
}: {
  activeSubscription: SubscriptionDetails | undefined;
  isWorkspaceOwner: boolean;
  showStripeConversion: boolean;
}) => {
  const workspaceId = useCurrentWorkspaceId();

  const {
    data: nextSubscriptionBillingDate,
    isLoading: isLoadingNextSubscriptionBillingDate,
  } = trpc.billing.getStripeNextBillingDate.useQuery(
    workspaceId &&
      activeSubscription?.paymentProcessor === "stripe" &&
      activeSubscription.paymentProcessorSubscriptionId
      ? {
          workspaceId,
          stripeSubscriptionId:
            activeSubscription.paymentProcessorSubscriptionId,
        }
      : skipToken,
  );

  const { data: integrationUsage, isLoading: isLoadingIntegrationUsage } =
    trpc.workspace.getShopifyIntegrationsUsage.useQuery(
      workspaceId ?? skipToken,
    );
  const {
    data: publishedElementUsage,
    isLoading: isLoadingPublishedElementUsage,
  } = trpc.workspace.getPublishedElementsUsage.useQuery(
    workspaceId ?? skipToken,
  );

  const isLoading =
    isLoadingPublishedElementUsage ||
    isLoadingIntegrationUsage ||
    isLoadingNextSubscriptionBillingDate;

  if (isLoading || !publishedElementUsage || !integrationUsage) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 lg:flex-row">
      <BillingInfoCard
        workspaceId={workspaceId ?? ""}
        activeSubscription={activeSubscription}
        nextBillDate={nextSubscriptionBillingDate}
        isWorkspaceOwner={isWorkspaceOwner}
      />
      <BillingLimitsCard
        elementUsage={publishedElementUsage}
        integrationUsage={integrationUsage}
      />
    </div>
  );
};

type BillingInfoCardProps = {
  workspaceId: string;
  activeSubscription: SubscriptionDetails | undefined;
  nextBillDate?: string;
  isWorkspaceOwner: boolean;
};

const BillingInfoCard: React.FC<BillingInfoCardProps> = ({
  workspaceId,
  activeSubscription,
  nextBillDate,
  isWorkspaceOwner,
}) => {
  const modal = useModal();
  const planInfo = BILLING_TIER_INFO_MAP[activeSubscription?.name ?? "free"];
  return (
    <Card className="flex-1 gap-4">
      <CardHeader className="flex flex-col gap-2">
        <CardTitle className="flex flex-col gap-1">
          <div className="flex flex-row gap-2 typ-header-h1 justify-between">
            {planInfo.displayName}
            {activeSubscription && (
              <Button
                variant="secondary"
                size="sm"
                hasMinDimensions={false}
                disabled={!isWorkspaceOwner}
                tooltipText={
                  !isWorkspaceOwner
                    ? "Only Workspace Owners have the ability to manage plans"
                    : undefined
                }
                onClick={() => {
                  if (!isWorkspaceOwner) {
                    return;
                  }
                  modal.openModal({
                    type: "billingModal",
                    props: { source: "direct.billingDashboard" },
                  });
                }}
              >
                Manage Plan
              </Button>
            )}
          </div>
          {activeSubscription && (
            <div className="typ-header-base">
              {formatPrice(activeSubscription?.monthlyAmount ?? 0)}/Month
            </div>
          )}
        </CardTitle>

        <CardDescription className="typ-body-small text-muted flex flex-col gap-2 items-start">
          {activeSubscription?.paymentProcessor === "shopify" &&
            "Charge details in Shopify"}
          {nextBillDate &&
            `Next charge on ${format(new Date(nextBillDate), "MMMM d, yyyy")}`}
          {activeSubscription?.paymentProcessor === "stripe" && (
            <Link
              to={getStripePortalUrl(workspaceId ?? "")}
              className="text-primary"
            >
              Edit Payment Details
            </Link>
          )}
        </CardDescription>
      </CardHeader>
      <hr />
      <CardContent>
        {planInfo.features.length === 0 ? (
          <div className="typ-body-base text-muted">{planInfo.description}</div>
        ) : (
          <ul className="flex flex-col gap-2 typ-body-bas">
            {planInfo.features.map((item) => (
              <li
                key={item}
                className="typ-body-small flex flex-row gap-2 items-center "
              >
                <BsCheckCircleFill size={16} />
                {item}
              </li>
            ))}
          </ul>
        )}
      </CardContent>
    </Card>
  );
};
type BillingLimitsCardProps = {
  elementUsage: {
    current: number;
    maximum: number | null;
  };
  integrationUsage: {
    current: number;
    maximum: number | null;
  };
};
const BillingLimitsCard: React.FC<BillingLimitsCardProps> = ({
  elementUsage,
  integrationUsage,
}) => {
  const modal = useModal();
  return (
    <Card className="flex-1 gap-2">
      <CardHeader>
        <CardTitle className="flex flex-row gap-2 justify-between items-center">
          Limits
          <Button
            variant="link"
            size="sm"
            onClick={() => {
              modal.openModal({
                type: "billingModal",
                props: { source: "direct.billingDashboard" },
              });
            }}
          >
            Need more?
          </Button>
        </CardTitle>
      </CardHeader>
      <hr className="mt-2 mb-6" />
      <CardContent>
        {[
          {
            title: "Shopify Integrations",
            icon: <Shopify fill="#1E293B" />,
            maximum: integrationUsage.maximum,
            current: integrationUsage.current,
          },
          {
            title: "Published Items",
            icon: <BsFileText size={16} />,
            maximum: elementUsage.maximum,
            current: elementUsage.current,
          },
        ].map((item) => (
          <div key={item.title} className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 items-center justify-between">
              <div className="flex flex-row gap-2 items-center">
                {item.icon}
                <p className="typ-body-base font-medium text-default">
                  {item.title}
                </p>
              </div>

              <div className="typ-label-base text-muted">
                {item.current} used
                <span className="text-placeholder">
                  {" "}
                  /{" "}
                  {item.maximum !== null
                    ? `${item.maximum} included`
                    : "Unlimited"}
                </span>
              </div>
            </div>
            <Progress
              value={item.current}
              max={item.maximum ?? item.current}
              indicatorClassName={
                item.maximum && item.current >= 0.75 * item.maximum
                  ? "bg-yellow-500"
                  : ""
              }
            />
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

type BillingAlertCardProps = {
  title: string;
  description: string;
  children: React.ReactNode;
};
const BillingAlertCard: React.FC<BillingAlertCardProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className="bg-slate-50 flex flex-row gap-2 px-3 py-2 justify-between items-center rounded-lg border-1 border-border">
      <div>
        <div className="typ-header-base">{title}</div>
        <div className="typ-body-base text-muted">{description}</div>
      </div>
      <div className="flex flex-row gap-2">{children}</div>
    </div>
  );
};

export default Billing;
