import type { Exception } from "@sentry/react";
import type { Middleware } from "redux";

import { ErrorTypes } from "@constants/error-types";
import { trackError } from "@editor/infra/analytics";

const errorMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    try {
      return next(action);
    } catch (error) {
      console.error(error);
      trackError(error as Exception, { action });
      return dispatch({
        type: "SET_ERROR",
        payload: {
          message: "An unknown error has occurred. Try refreshing the page.",
          type: ErrorTypes.ACTIONABLE__RELOAD,
        },
      });
    }
  };

export default errorMiddleware;
