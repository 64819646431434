import * as React from "react";

import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";

const TemplateCardSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col gap-2">
      <Skeleton className="aspect-square w-full rounded-md" />
      <Skeleton className="h-4 w-3/4" />
      <div className="flex gap-2">
        <Skeleton className="h-4 w-16" />
      </div>
    </div>
  );
};

export const TemplatesGridSkeleton: React.FC<{
  cardCount?: number;
}> = ({ cardCount = 7 }) => {
  return (
    <div
      className="flex flex-1 flex-col pr-1 no-scrollbar cursor-default overflow-scroll max-h-screen pb-72"
      id="templates-grid"
    >
      <div className="grid grid-cols-1 min-[900px]:grid-cols-2 min-[1500px]:grid-cols-3 min-[2400px]:grid-cols-4 gap-x-2 gap-y-5">
        {Array.from({ length: cardCount }).map((_, index) => (
          <TemplateCardSkeleton key={index} />
        ))}
      </div>
    </div>
  );
};
