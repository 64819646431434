import * as React from "react";

import Input from "@common/designSystem/Input";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

import LabeledControl from "../common/designSystem/LabeledControl";

type FormValues = {
  code: string;
};

export const AddReferralCodeModal = () => {
  const { closeModal } = useModal();
  const analytics = useLogAnalytics();
  const { workspaceId } = useParams();
  const { user } = useCurrentUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });
  const codeValue = useWatch({ control, name: "code" });
  const hasCodeErrors = !isEmpty(errors.code);

  const { mutateAsync: createReferralCode, isPending: isLoadingCreation } =
    trpc.referralCode.create.useMutation({
      onSuccess: async () => {
        if (workspaceId) {
          void trpcUtils.workspace.getById.invalidate({
            id: workspaceId,
          });
        }
      },
    });

  const onSubmit = ({ code }: FormValues) => {
    if (!workspaceId) {
      return;
    }
    void createReferralCode({
      workspaceId,
      code,
    }).finally(() => {
      analytics("referralCode.created", {
        workspaceId,
        email: user?.email ?? "",
        code: code,
      });
      closeModal({ type: "addReferralCodeModal" });
    });
  };

  return (
    <form
      onSubmit={(data) => {
        void handleSubmit(onSubmit)(data);
      }}
    >
      <Modal
        isOpen={true}
        onOpenChange={(open) => {
          if (!open) {
            closeModal({ type: "addReferralCodeModal" });
          }
        }}
        title="Create Referral Code"
        size="base"
        footer={
          <Button
            variant="primary"
            size="base"
            type="submit"
            isLoading={isLoadingCreation}
            disabled={hasCodeErrors || !codeValue}
          >
            Create
          </Button>
        }
      >
        <LabeledControl label="Referral Code" error={errors.code?.message}>
          <Input
            id="referralCode"
            aria-invalid={hasCodeErrors ? "true" : undefined}
            aria-describedby={hasCodeErrors ? "error-referral-code" : undefined}
            autoComplete="off"
            placeholder="Enter referral code"
            {...register("code", {
              required: "Please enter the referral code.",
            })}
            type="text"
            size="base"
            validityState={hasCodeErrors ? "invalid" : "valid"}
            autoFocus
          />
        </LabeledControl>
      </Modal>
    </form>
  );
};
