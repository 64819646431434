import * as React from "react";

import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";

import { useUpdateWorkspace } from "./useUpdateWorkspace";

export const useAutoSetWorkspaceSettingsIfNull = () => {
  const { workspace, isLoading: isLoadingWorkspace } =
    useCurrentWorkspaceContext();
  const { updateWorkspace } = useUpdateWorkspace();
  // Single ref to track all attempted fields
  const updateAttemptsRef = React.useRef<Set<string>>(new Set());

  React.useEffect(() => {
    if (!isLoadingWorkspace && workspace) {
      const fieldsToAutoSet = [
        // NOTE (Kurt, 2025-03-27): To add new auto-set fields, follow this template:
        // { key: 'newField', getValue: () => defaultValue }
        {
          key: "timeZone",
          getValue: () => Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        {
          key: "currency",
          getValue: () => "USD",
        },
      ];
      // NOTE (Kurt, 2025-03-27): Filter for fields that need to be auto-set.
      // We only want to update fields that are currently null/undefined in the workspace
      // and haven't had an update attempt already (tracked in updateAttemptsRef).
      // This prevents infinite update loops and unnecessary API calls.
      const fieldsToUpdate = fieldsToAutoSet.filter(
        (field) =>
          !workspace[field.key as keyof typeof workspace] &&
          !updateAttemptsRef.current.has(field.key),
      );

      if (fieldsToUpdate.length > 0) {
        fieldsToUpdate.forEach((field) => {
          updateAttemptsRef.current.add(field.key);
        });

        const updateData = fieldsToUpdate.reduce(
          (acc, field) => {
            acc[field.key] = field.getValue();
            return acc;
          },
          {} as Record<string, any>,
        );

        void updateWorkspace({
          id: workspace.id,
          data: updateData,
          showSuccessToast: false,
        });
      }
    }
  }, [workspace, updateWorkspace, isLoadingWorkspace]);
};
