import type { ComponentTemplate } from "@editor/types/component-template";

import { ItemsConfigType } from "schemas/dynamicData";

export const productImageGrid: ComponentTemplate = {
  id: "aebfc246-fff6-4d41-a00c-95d6b1c1e2d0",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Basic/Product Image Grid",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "image-grid.svg",
  template: {
    id: "13141ec5-8be0-491c-af8a-f1a5af9ea5a0",
    name: "Product Image Grid",
    type: "selectionList",
    props: {
      style: {
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        __width: "50%",
        __flexGap: 0,
        flexDirection: "column",
        flexGrow: 1,
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        __numberOfColumns: 2,
        columnGap: "15px",
        rowGap: "15px",
      },
      _accessibilitySelection: false,
      _items: {
        type: ItemsConfigType.inline,
        valueType: "dynamic",
        dynamicPath: "{{attributes._product.images}}",
      },
    },
    children: [
      {
        id: "901be695-d18a-4f8b-a291-b36ea6107130",
        type: "image",
        props: {
          style: {
            objectFit: "cover",
            __imageSource: "{{attributes._currentItem}}",
            __imageAltText:
              "Close-up of a single green leaf against a white background.",
            flexGrow: 1,
          },
          loading: "eager",
        },
        name: "Image",
        markers: {
          _aiGeneratedAltText: true,
        },
      },
    ],
  },
};
