import type { ComponentTemplate } from "@editor/types/component-template";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const okendoReviewsWidget: ComponentTemplate = {
  id: "5188516f-298b-4357-ac56-8be96b2b0891",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Okendo Reviews",
  leftBarCategory: { main: "productIntegrations", subCategory: "okendo" },
  preview: "integrations/okendo-product-reviews.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["widget"],
  template: {
    id: "$uid",
    type: "okendoReviewsWidget",
    props: {
      style: {
        alignSelf: "stretch",
      },
    },
  },
};
