import { isNotNullish } from "replo-utils/lib/misc";

type UnitValue = {
  value: string | number;
  unit: string;
  parsedUnit: string | null;
};

export type LengthValueType = {
  value: string | number;
  unit: string;
};

// https://developer.mozilla.org/en-US/docs/Web/CSS/length
// NOTE (Yuxin, 2022-10-04) Technically, we should not take percentage here, and this should
// be handled by a separate constant https://developer.mozilla.org/en-US/docs/Web/CSS/length-percentage
export const CSS_LENGTH_TYPES = [
  "px",
  "%",
  "em",
  "ex",
  "ch",
  "rem",
  "vh",
  "vw",
  "vmin",
  "vmax",
  "mm",
  "cm",
  "in",
  "pt",
  "pc",
];

export const CSS_LENGTH_TYPES_WITH_PERCENTAGE = [...CSS_LENGTH_TYPES, "%"];
export const CSS_LENGTH_TYPES_WITH_COMPUTED = [...CSS_LENGTH_TYPES, "inherit"];
export const CSS_LENGTH_TYPES_COMPUTED_WITH_PERCENTAGE = [
  ...CSS_LENGTH_TYPES_WITH_COMPUTED,
  "%",
];

export const CSS_ANGLE_TYPES = ["deg", "grad", "rad", "turn"];

const fieldToValidUnits = (field: string, metrics?: string[]) => {
  /** Use includes since it might be style.letterSpacing */
  if (field.includes("letterSpacing")) {
    return metrics ?? ["em", "vh", "vw", "px"];
  }

  if (["gradientTilt", "tilt"].includes(field)) {
    return metrics ?? CSS_ANGLE_TYPES;
  }
  return metrics ?? CSS_LENGTH_TYPES;
};

export const parseUnit = (
  target: string | number | null,
  defaultRtn: { value: string | number; unit: string },
  field = "default",
  defaultUnit: string,
  resetValue?: string | null,
  metrics?: string[],
  allowedNonUnitValues: string[] = [],
): UnitValue => {
  if (target === null || !["string", "number"].includes(typeof target)) {
    return Object.assign({}, defaultRtn, { parsedUnit: null });
  }

  target = target.toString().trim();

  // Note (Fran, 2022-04-29): If the string is empty it's because we need to erase
  // the value and set the reset value if this is set, that is because in some
  // styles we need to pass a value, for example the padding, and i think it's
  // correct to pass a default value like the reset value.
  if (target === "" && isNotNullish(resetValue)) {
    return {
      value: resetValue,
      unit: "",
      parsedUnit: null,
    };
  }

  if (
    ["inherit", "auto", "none"]
      .concat(allowedNonUnitValues)
      .includes(target.toLowerCase())
  ) {
    return { value: target, unit: "", parsedUnit: null };
  }

  const num = Number.parseFloat(target || "0");

  if (Number.isNaN(num)) {
    return Object.assign({}, defaultRtn, { parsedUnit: null });
  }

  const validUnits = fieldToValidUnits(field, metrics);
  const match = target.match(/[\d+.-]*\s*(.*)/);
  if (!match) {
    return {
      value: "",
      unit: "",
      parsedUnit: null,
    };
  }
  let unit = match[1] ?? defaultUnit;

  if (!validUnits.includes(unit)) {
    unit = defaultUnit;
  }

  return { value: num, unit, parsedUnit: unit };
};
