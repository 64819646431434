import type { SavedTextStyle } from "schemas/generated/designLibrary";
import type { StyleCategory } from "./useDraftStyles";

import * as React from "react";

const presetTextStyles: Record<StyleCategory, SavedTextStyle[][]> = {
  playful: [
    [
      {
        type: "text",
        name: "Playful Header",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "400",
          fontFamily: "Caprasimo",
        },
      },
      {
        type: "text",
        name: "Playful Body",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Spline Sans",
        },
      },
    ],
    [
      {
        type: "text",
        name: "Playful Header 2",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "700",
          fontFamily: "Raleway",
        },
      },
      {
        type: "text",
        name: "Playful Body 2",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Montserrat",
        },
      },
    ],
  ],
  bold: [
    [
      {
        type: "text",
        name: "Bold Header",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "400",
          fontFamily: "Impact",
        },
      },
      {
        type: "text",
        name: "Bold Body",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Archivo",
        },
      },
    ],
    [
      {
        type: "text",
        name: "Bold Header 2",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "400",
          fontFamily: "Bebas Neue",
        },
      },
      {
        type: "text",
        name: "Bold Body 2",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Mulish",
        },
      },
    ],
  ],
  natural: [
    [
      {
        type: "text",
        name: "Natural Header",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "700",
          fontFamily: "IBM Plex Sans",
        },
      },
      {
        type: "text",
        name: "Natural Body",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Source Sans Pro, sans-serif",
        },
      },
    ],
    [
      {
        type: "text",
        name: "Natural Header 2",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "700",
          fontFamily: "Afacad",
        },
      },
      {
        type: "text",
        name: "Natural Body 2",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Inter",
        },
      },
    ],
  ],
  modern: [
    [
      {
        type: "text",
        name: "Modern Header",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "700",
          fontFamily: "Helvetica",
        },
      },
      {
        type: "text",
        name: "Modern Body",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Arial",
        },
      },
    ],
    [
      {
        type: "text",
        name: "Modern Header 2",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "700",
          fontFamily: "Lexend",
        },
      },
      {
        type: "text",
        name: "Modern Body 2",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Instrument Sans",
        },
      },
    ],
  ],
  professional: [
    [
      {
        type: "text",
        name: "Professional Header",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "700",
          fontFamily: "IBM Plex Sans",
        },
      },
      {
        type: "text",
        name: "Professional Body",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Roboto",
        },
      },
    ],
    [
      {
        type: "text",
        name: "Professional Header 2",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "400",
          fontFamily: "DM Serif Display",
        },
      },
      {
        type: "text",
        name: "Professional Body 2",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Merriweather Sans",
        },
      },
    ],
  ],
  elegant: [
    [
      {
        type: "text",
        name: "Elegant Header",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "400",
          fontFamily: "Yeseva One",
        },
      },
      {
        type: "text",
        name: "Elegant Body",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Montserrat",
        },
      },
    ],
    [
      {
        type: "text",
        name: "Elegant Header 2",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "400",
          fontFamily: "Marcellus",
        },
      },
      {
        type: "text",
        name: "Elegant Body 2",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Raleway",
        },
      },
    ],
  ],
  futuristic: [
    [
      {
        type: "text",
        name: "Futuristic Header",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "700",
          fontFamily: "Orbitron",
        },
      },
      {
        type: "text",
        name: "Futuristic Body",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Roboto Mono",
        },
      },
    ],
    [
      {
        type: "text",
        name: "Futuristic Header 2",
        attributes: {
          type: "text",
          htmlTag: "h1",
          fontSize: "36px",
          fontWeight: "700",
          fontFamily: "Exo",
        },
      },
      {
        type: "text",
        name: "Futuristic Body 2",
        attributes: {
          type: "text",
          htmlTag: "p",
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Michroma",
        },
      },
    ],
  ],
};

export function usePresetTextStyles() {
  const getTextStylesByCategory = React.useCallback(
    (category: StyleCategory, styleIndex = 0): SavedTextStyle[] => {
      return presetTextStyles[category][styleIndex] || [];
    },
    [],
  );

  const getAllTextStyles = React.useCallback((): SavedTextStyle[] => {
    return Object.values(presetTextStyles).flatMap((styles) => styles.flat());
  }, []);

  return {
    textStyleCategories: Object.keys(presetTextStyles) as StyleCategory[],
    getTextStylesByCategory,
    getAllTextStyles,
  };
}
