import * as React from "react";

import LabeledControl from "@common/designSystem/LabeledControl";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import {
  useGetCurrentStepResultsData,
  useGetStepResultsData,
} from "@components/flows/hooks/useGetCurrentStepResultsData";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import Input from "@editor/components/common/designSystem/Input";
import Selectable from "@editor/components/common/designSystem/Selectable";
import { useReploFlowsStepContext } from "@editor/components/flows/context/ReploFlowsStepContext";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { trpc, useLastQueryWasError } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import { skipToken } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { BsFileEarmarkExcel, BsMagic } from "react-icons/bs";
import {
  componentTemplateIndustries,
  getComponentTemplateIndustrySlugFromName,
} from "replo-runtime/shared/componentTemplates";
import { z } from "zod";

import StepSubtitle from "./components/StepSubtitle";

const formSchema = z.object({
  brandName: z.string().optional(),
  industry: z.string(),
  brandVoice: z.string().optional(),
  whatBusinessSells: z.string().optional(),
  whoIsCustomer: z.string().optional(),
});
type FormValues = z.infer<typeof formSchema>;

const BrandDetailsStep: React.FC = () => {
  const importFromUrlStepResultsData =
    useGetStepResultsData<"onboarding.user.import-from-url">("import-from-url");
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.brand-details">();

  const { currentStep, submitStep } = useReploFlowsStepContext();

  const wasUrlProvided = importFromUrlStepResultsData?.url !== "";

  const lastQueryWasError = useLastQueryWasError(
    trpc.ai.generateBrandDetailsAndIndustry,
    {
      url: importFromUrlStepResultsData?.url,
    },
  );

  const {
    data: brandDetailsAndIndustryData,
    isLoading: isBrandDetailsQueryLoading,
    error: brandDetailsAndIndustryError,
  } = trpc.ai.generateBrandDetailsAndIndustry.useQuery(
    importFromUrlStepResultsData?.url && !lastQueryWasError
      ? {
          url: importFromUrlStepResultsData.url,
        }
      : skipToken,
    { retry: false },
  );

  // NOTE (Gabe 2024-10-08): Because the step data is not initially available, we
  // have to construct our own loading variable.
  const areBrandDetailsLoading =
    !importFromUrlStepResultsData || isBrandDetailsQueryLoading;

  const queryReturnedSomeData =
    brandDetailsAndIndustryData?.brandDetails.brandName ||
    brandDetailsAndIndustryData?.industry ||
    brandDetailsAndIndustryData?.brandDetails.brandVoice ||
    brandDetailsAndIndustryData?.brandDetails.whatBusinessSells ||
    brandDetailsAndIndustryData?.brandDetails.whoIsCustomer;

  const queryReturnedAllData =
    brandDetailsAndIndustryData?.brandDetails.brandName &&
    brandDetailsAndIndustryData?.industry &&
    brandDetailsAndIndustryData?.brandDetails.brandVoice &&
    brandDetailsAndIndustryData?.brandDetails.whatBusinessSells &&
    brandDetailsAndIndustryData?.brandDetails.whoIsCustomer;

  const gotBrandDetailsFromUrl = wasUrlProvided && queryReturnedSomeData;
  const unableToGetAnyBrandDetailsFromUrl =
    (!areBrandDetailsLoading && wasUrlProvided && !queryReturnedSomeData) ||
    lastQueryWasError;

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      brandName: stepResultsData?.brandName ?? "",
      industry: stepResultsData?.industry ?? "",
      brandVoice: stepResultsData?.brandVoice ?? "",
      whatBusinessSells: stepResultsData?.whatBusinessSells ?? "",
      whoIsCustomer: stepResultsData?.whoIsCustomer ?? "",
    },
    resolver: zodResolver(formSchema),
  });

  React.useEffect(() => {
    if (brandDetailsAndIndustryError) {
      setValue("brandName", "");
      setValue("industry", "");
      setValue("brandVoice", "");
      setValue("whatBusinessSells", "");
      setValue("whoIsCustomer", "");
      return;
    }
    if (!brandDetailsAndIndustryData) {
      return;
    }
    const { brandName, ...formBrandDetails } = getValues();

    // NOTE (Gabe 2024-11-12): We handle brandName separately, because it may
    // have been prefilled based on responses to previous steps.
    if (!brandName) {
      setValue(
        "brandName",
        brandDetailsAndIndustryData.brandDetails.brandName ?? "",
      );
    }

    const brandDetailsAreUnset = Object.values(formBrandDetails).some(
      (value) => !value,
    );
    if (brandDetailsAreUnset) {
      setValue(
        "industry",
        getComponentTemplateIndustrySlugFromName(
          brandDetailsAndIndustryData.industry ?? "",
        ),
      );
      setValue(
        "brandVoice",
        brandDetailsAndIndustryData.brandDetails.brandVoice ?? "",
      );
      setValue(
        "whatBusinessSells",
        brandDetailsAndIndustryData.brandDetails.whatBusinessSells ?? "",
      );
      setValue(
        "whoIsCustomer",
        brandDetailsAndIndustryData.brandDetails.whoIsCustomer ?? "",
      );
    }
  }, [
    brandDetailsAndIndustryData,
    getValues,
    setValue,
    brandDetailsAndIndustryError,
  ]);

  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      if (currentStep) {
        submitStep(
          currentStep.id,
          currentStep.type,
          values,
          ({ instance, nextStep }) => {
            submitStepCallback({
              nextStep: nextStep ?? null,
              flowSlug: instance.flow.slug,
            });
          },
        );
      }
    },
    [currentStep, submitStep, submitStepCallback],
  );

  const brandNameError = errors.brandName?.message;
  const brandVoiceError = errors.brandVoice?.message;
  const whatBusinessSellsError = errors.whatBusinessSells?.message;
  const whoIsCustomerError = errors.whoIsCustomer?.message;

  const industryOptions = componentTemplateIndustries.map((industry) => ({
    value: getComponentTemplateIndustrySlugFromName(industry.name),
    label: industry.name,
  }));

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/styles.png" />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
        className="gap-8"
      >
        <div className="flex flex-col gap-2">
          <div>
            <StepTitle>Brand details</StepTitle>
            <StepSubtitle>
              These details will help generate personalized, high-quality
              content with Replo AI.
            </StepSubtitle>
          </div>
          <div className="text-xs text-ai flex flex-row gap-2 items-center h-4">
            {gotBrandDetailsFromUrl && (
              <>
                <BsMagic />
                {queryReturnedAllData
                  ? "Results generated from URL"
                  : "Some results generated from URL"}
              </>
            )}
          </div>
          {!areBrandDetailsLoading && unableToGetAnyBrandDetailsFromUrl && (
            <div className="flex flex-row gap-2 items-center bg-slate-50 rounded-md px-4 py-2 text-slate-600 justify-start h-12">
              <BsFileEarmarkExcel />
              <p className="text-xs">
                Couldn’t retrieve details from URL. You can try again later.
              </p>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 gap-6">
          {!unableToGetAnyBrandDetailsFromUrl && (
            <LabeledControl
              label="What is your brand's name?"
              className="text-default font-medium w-full"
              size="base"
            >
              <div className="flex flex-col gap-2">
                <Controller
                  name="brandName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      {areBrandDetailsLoading ? (
                        <Skeleton className="w-full h-[32px]" />
                      ) : (
                        <Input
                          size="sm"
                          value={value}
                          onChange={(value) => onChange(value)}
                          aria-invalid={
                            Boolean(brandNameError) ? "true" : undefined
                          }
                          aria-describedby={
                            Boolean(brandNameError)
                              ? "error-brand-name"
                              : undefined
                          }
                          placeholder="Rockstar Bags"
                          layoutClassName="w-full h-9"
                        />
                      )}
                    </>
                  )}
                />
                {brandNameError && (
                  <ErrorMessage id="error-brand-name" error={brandNameError} />
                )}
              </div>
            </LabeledControl>
          )}
          <LabeledControl
            label={<>What is your industry?</>}
            className="text-default font-medium"
            size="base"
          >
            <div className="flex flex-row gap-2">
              <Controller
                name="industry"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const options = [
                    ...industryOptions,
                    {
                      value: "other",
                      label: "Other",
                    },
                  ];
                  const valueIsStandardIndustry = industryOptions.some(
                    (industry) => industry.value === value,
                  );
                  if (areBrandDetailsLoading) {
                    return <Skeleton className="w-full h-[32px]" />;
                  }
                  return (
                    <>
                      <Selectable
                        options={options}
                        value={valueIsStandardIndustry ? value : "other"}
                        size="sm"
                        placeholder="Select your industry"
                        onSelect={(value) => {
                          if (value) {
                            onChange(value !== "other" ? value : "");
                          }
                        }}
                      />
                      {!valueIsStandardIndustry && (
                        <Input
                          placeholder="Enter your industry"
                          value={value}
                          size="sm"
                          // NOTE (Gabe 2024-09-25): This is the height of a
                          // small sized Selectable.
                          layoutClassName="h-8"
                          onChange={(value) => onChange(value)}
                        />
                      )}
                    </>
                  );
                }}
              />
            </div>
          </LabeledControl>
          {!unableToGetAnyBrandDetailsFromUrl && (
            <>
              <LabeledControl
                label="What are you selling?"
                className="text-default font-medium w-full"
                size="base"
              >
                <div className="flex flex-col gap-2">
                  <Controller
                    name="whatBusinessSells"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        {areBrandDetailsLoading ? (
                          <Skeleton className="w-full h-[50px]" />
                        ) : (
                          <Textarea
                            size="base"
                            textLength="short"
                            value={value}
                            onChange={(value) => onChange(value)}
                            aria-invalid={
                              Boolean(whatBusinessSellsError)
                                ? "true"
                                : undefined
                            }
                            aria-describedby={
                              Boolean(whatBusinessSellsError)
                                ? "error-what-business-sells"
                                : undefined
                            }
                            layoutClassName="w-full"
                            placeholder="We sell handmade purses made from recycled concert tshirts"
                          />
                        )}
                      </>
                    )}
                  />
                  {whatBusinessSellsError && (
                    <ErrorMessage
                      id="error-what-business-sells"
                      error={whatBusinessSellsError}
                    />
                  )}
                </div>
              </LabeledControl>
              <LabeledControl
                label="Who are you selling to?"
                className="text-default font-medium"
                size="base"
              >
                <div className="flex flex-col gap-2">
                  <Controller
                    name="whoIsCustomer"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        {areBrandDetailsLoading ? (
                          <Skeleton className="w-full h-[50px]" />
                        ) : (
                          <Textarea
                            size="base"
                            textLength="short"
                            value={value}
                            onChange={(value) => onChange(value)}
                            aria-invalid={
                              Boolean(whoIsCustomerError) ? "true" : undefined
                            }
                            aria-describedby={
                              Boolean(whoIsCustomerError)
                                ? "error-who-is-customer"
                                : undefined
                            }
                            layoutClassName="w-full"
                            placeholder="Our customers are usually Gen Z teenagers who love TikTok..."
                          />
                        )}
                      </>
                    )}
                  />
                  {whoIsCustomerError && (
                    <ErrorMessage
                      id="error-who-is-customer"
                      error={whoIsCustomerError}
                    />
                  )}
                </div>
              </LabeledControl>
              <LabeledControl
                label="Tone and voice"
                className="text-default font-medium"
                size="base"
              >
                <div className="flex flex-col gap-2">
                  <Controller
                    name="brandVoice"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        {areBrandDetailsLoading ? (
                          <Skeleton className="w-full h-[50px]" />
                        ) : (
                          <Textarea
                            size="base"
                            textLength="short"
                            value={value}
                            onChange={(value) => onChange(value)}
                            aria-invalid={
                              Boolean(brandVoiceError) ? "true" : undefined
                            }
                            aria-describedby={
                              Boolean(brandVoiceError)
                                ? "error-brand-voice"
                                : undefined
                            }
                            layoutClassName="w-full"
                            placeholder="Fun, quirky, and playful - we use humor to connect with our audience and keep things light-hearted..."
                          />
                        )}
                      </>
                    )}
                  />
                  {brandVoiceError && (
                    <ErrorMessage
                      id="error-brand-voice"
                      error={brandVoiceError}
                    />
                  )}
                </div>
              </LabeledControl>
            </>
          )}
        </div>
        <FlowActionButtons />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

export default BrandDetailsStep;
