import * as React from "react";

import { useDraftElementMetadata } from "@editor/hooks/useDraftElementMetadata";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { formatDistanceToNowStrict } from "date-fns";
import { BsFillCircleFill } from "react-icons/bs";

const PublishedStatus = () => {
  const elementMetadata = useDraftElementMetadata();
  const isPublished = elementMetadata?.isPublished;
  const publishedAt = elementMetadata?.publishedAt;

  const difference = publishedAt
    ? formatDistanceToNowStrict(new Date(publishedAt))
    : null;

  const label =
    isPublished && difference
      ? `Last Published ${difference} ago`
      : "Not Published";

  return (
    <div
      className={twMerge(
        "flex items-center gap-2 text-sm font-medium text-muted",
        isPublished && "text-green-400",
      )}
    >
      <Tooltip content={label} triggerAsChild>
        <span tabIndex={0}>
          <BsFillCircleFill size={8} />
        </span>
      </Tooltip>
    </div>
  );
};

export default PublishedStatus;
