import type { OverlapSize } from "@editor/components/designLibrary/ColorPaletteDisplay";
import type { SchemaReploShopifyProduct } from "schemas/generated/product";

import React from "react";

import { useBrandDetailsTabs } from "@editor/components/designLibrary/BrandDetailsContext";
import ShopStylesColorDisplay from "@editor/components/designLibrary/ShopStylesColorDisplay";
import { SelectProduct } from "@editor/components/products/SelectProduct";
import { BrandContextPreview } from "@editor/components/saved-styles/BrandContextPreview";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import { setLeftBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorDispatch } from "@editor/store";

import { Textarea } from "@replo/design-system/components/textarea/Textarea";
import twMerge from "@replo/design-system/utils/twMerge";
import { Pencil, Send } from "lucide-react";

import { Button } from "../chatbot-ui/Button";
import ShopStylesFontSample from "../ShopStylesFontSample";

interface BuildAssistantPromptProps {
  prompt: string;
  setPrompt: (prompt: string) => void;
  placeholderText: string;
  selectedProduct: SchemaReploShopifyProduct | null | undefined;
  setSelectedProduct: (product: SchemaReploShopifyProduct | null) => void;
  onBuild: () => void;
  className?: string;
  textareaClassName?: string;
  colorOverlapSize?: OverlapSize;
  showFontSample?: boolean;
  showBrandContextPreview?: boolean;
  selectProductClassName?: string;
  selectProductTriggerClassName?: string;
  fontSampleTriggerClassName?: string;
  selectProductSelectionIndicatorClassName?: string;
  showSendButton?: boolean;
  isDisabled?: boolean;
}

export const BuildAssistantPrompt: React.FC<BuildAssistantPromptProps> = ({
  prompt,
  setPrompt,
  placeholderText,
  selectedProduct,
  setSelectedProduct,
  onBuild,
  className,
  textareaClassName,
  colorOverlapSize = "small",
  showFontSample = true,
  showBrandContextPreview = true,
  selectProductClassName,
  selectProductTriggerClassName,
  fontSampleTriggerClassName,
  selectProductSelectionIndicatorClassName,
  showSendButton,
  isDisabled,
}) => {
  const dispatch = useEditorDispatch();
  const { setSelectedTab } = useBrandDetailsTabs();
  const { status } = useAIStreaming();

  const isGenerating =
    status === "generating" || status === "generationInitialized";

  return (
    <div
      className={twMerge(
        "w-full relative rounded-lg flex flex-col border-[.5px] border-border",
        className,
      )}
    >
      <Textarea
        value={prompt}
        onChange={(newPrompt) => setPrompt(newPrompt)}
        placeholder={placeholderText}
        onEnter={onBuild}
        isDisabled={isDisabled || isGenerating}
        layoutClassName="h-40"
        UNSAFE_className={twMerge(
          "p-0 bg-white outline-none focus:ring-0 scrollbar-none bg-white rounded-lg gap-3 p-3",
          textareaClassName,
        )}
      />
      <div className="flex gap-3 bottom-0 left-0 bg-white p-2 rounded-lg w-full">
        <button
          className={twMerge(
            "w-fit border-0.5 border-border rounded-md p-2 flex items-center gap-3 group relative",
            fontSampleTriggerClassName,
          )}
          onClick={() => {
            dispatch(setLeftBarActiveTab("shopStyles"));
            setSelectedTab("styles");
          }}
        >
          {showFontSample && <ShopStylesFontSample />}
          <ShopStylesColorDisplay
            overlapSize={colorOverlapSize}
            className={twMerge(colorOverlapSize === "large" && "h-[24px]")}
          />
          <div
            className={twMerge(
              "absolute inset-0 bg-light-surface opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center z-50",
              showFontSample ? "rounded-md" : "rounded",
            )}
          >
            <Pencil
              className={twMerge(
                "h-5 w-5 text-muted",
                !showFontSample && "h-4 w-4",
              )}
            />
          </div>
        </button>

        {showBrandContextPreview && <BrandContextPreview />}
        <SelectProduct
          onProductChange={setSelectedProduct}
          allowDynamicData={false}
          className={twMerge("w-[unset] min-w-[200px]", selectProductClassName)}
          selectionIndicatorClassName={twMerge(
            "h-7 w-7",
            selectProductSelectionIndicatorClassName,
          )}
          triggerClassName={twMerge(
            "border-dashed",
            selectedProduct ? "bg-white" : undefined,
            selectProductTriggerClassName,
          )}
        />
        {showSendButton && (
          <Button
            type="submit"
            size="icon"
            onClick={onBuild}
            className="h-6 w-6 transition-opacity bg-primary text-white ml-auto"
            aria-label="Send message"
            disabled={prompt === "" || isGenerating}
          >
            <Send className="!h-3 !w-3" />
          </Button>
        )}
      </div>
    </div>
  );
};
