const canUseEnv = typeof process !== "undefined" && Boolean(process.env);
export const isDevelopment =
  canUseEnv && process.env.NODE_ENV === "development";

/**
 * The default values we should provide to the `credentials` option of fetch()
 * when we're calling our backend in the editor.
 *
 * TODO (Noah, 2025-02-15, REPL-15991): We should be passing `include` in
 * staging too most likely, problem is env checking in the editor is really
 * weird rn, need to standardize it
 */
export const defaultFetchCredentials = isDevelopment
  ? // TODO (Noah, 2025-02-15, REPL-15991): In order to make local dev with prod
    // data mode work, we need to pass "same-origin" instead of "include" in dev,
    // which is going to break cookie-based auth since we won't be including cookies
    // in requests from local dev to prod. I think the actual fix is to return true
    // instead of "*" in publisher's `origin` CORS config, need to work this out
    "same-origin"
  : "same-origin";
