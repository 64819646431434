import * as React from "react";

import { updateElement } from "@editor/actions/core-actions";
import { useModal } from "@editor/hooks/useModal";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { isValidComponent } from "@editor/utils/component";
import { handleTRPCClientError, trpc, trpcUtils } from "@editor/utils/trpc";
import {
  resetHistory,
  selectDraftElementId,
  selectProjectId,
} from "@reducers/core-reducer";

import { skipToken } from "@tanstack/react-query";
import { TRPCClientError } from "@trpc/client";
import { useParams } from "react-router-dom";

const useFetchDraftElement = () => {
  const dispatch = useEditorDispatch();
  const params = useParams();
  const { openModal } = useModal();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const projectId = useEditorSelector(selectProjectId);
  const elementIdExists = Boolean(draftElementId) || Boolean(params.elementId);
  const shouldSkip = !elementIdExists || projectId !== params.projectId;
  const elementIdParam = draftElementId ?? params.elementId;

  const { data } = trpc.element.getById.useQuery(
    elementIdParam && !shouldSkip ? elementIdParam : skipToken,
  );

  React.useEffect(() => {
    try {
      if (data) {
        dispatch(updateElement(data.element.id, data.element));
        // Note (Noah, 2023-12-11): If we had a componentId= param, set
        // it as the initial component id for debugging purposes (e.g.
        // when DEQ generates a link to a specific component we have to fix)
        const params = new URLSearchParams(window.location.search);
        const initialComponentId = params.get("componentId");
        if (initialComponentId) {
          // Note (Noah, 2023-12-11): dispatch here is not the same typing
          // as the dispatch from useEditorDispatch so it doesn't understand
          // that setDraftElement is a valid action. Unclear as to how to get
          // this to work, so just expecting the error for now
          // @ts-expect-error
          dispatch(setDraftElement({ componentIds: [initialComponentId] }));
        }
        const isNotValidComponent =
          data.element.component && !isValidComponent(data.element.component);

        if (isNotValidComponent) {
          openModal({
            type: "fullPageErrorModal",
            props: {
              details: {
                header: "Page misconfigured",
                message:
                  "Oops! The content of this page was unexpectedly empty. This usually means something went wrong when creating the page. Please contact support@replo.app to help resolve this issue.",
                isFullPage: true,
              },
            },
          });
        }
      }
    } catch (error) {
      if (error instanceof TRPCClientError) {
        // NOTE (Sebas, 2024-08-15): In this case we are not using `trpc` but the `trpcClient` directly
        // so we need to handle TRPC errors manually.
        handleTRPCClientError(error);
      }
    }
  }, [dispatch, data, openModal]);

  React.useEffect(() => {
    if (draftElementId) {
      dispatch(resetHistory());
      void trpcUtils.element.getById.invalidate(draftElementId);
    }
  }, [draftElementId, dispatch]);

  return data;
};

export default useFetchDraftElement;
