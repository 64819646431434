import type { LucideIcon } from "lucide-react";
import type { ReactNode } from "react";
import type { IconType } from "react-icons";
import type { Component } from "schemas/component";
import type { HotkeyIndicatorCharacter } from "./hotkeys";

import { Sparkles } from "lucide-react";
import { AiOutlineInteraction } from "react-icons/ai";
import { BiDuplicate, BiPaste, BiPlay, BiRedo, BiUndo } from "react-icons/bi";
import {
  BsAlignBottom,
  BsAlignCenter,
  BsAlignEnd,
  BsAlignMiddle,
  BsAlignStart,
  BsAlignTop,
  BsArrowDown,
  BsArrowUp,
  BsBoxArrowUp,
  BsClockHistory,
  BsCode,
  BsCopy,
  BsDatabase,
  BsEye,
  BsFileText,
  BsGear,
  BsImage,
  BsLayers,
  BsLink,
  BsListOl,
  BsListUl,
  BsPalette,
  BsPlusSquare,
  BsSliders,
  BsTrash,
  BsTypeBold,
  BsTypeH1,
  BsTypeH2,
  BsTypeH3,
  BsTypeH4,
  BsTypeH5,
  BsTypeH6,
  BsTypeItalic,
  BsTypeStrikethrough,
  BsTypeUnderline,
  BsZoomIn,
  BsZoomOut,
} from "react-icons/bs";
import { CgTemplate } from "react-icons/cg";
import { FaLayerGroup } from "react-icons/fa";
import { HiOutlineHand } from "react-icons/hi";
import {
  MdHeight,
  MdOutlineDesignServices,
  MdWidthFull,
  MdWidthNormal,
} from "react-icons/md";
import { PiMagnifyingGlass, PiTextAaBold } from "react-icons/pi";
import { RiFontSize, RiLineHeight } from "react-icons/ri";
import { RxLetterSpacing } from "react-icons/rx";

type TextCommand =
  | "toggleH1Text"
  | "toggleH2Text"
  | "toggleH3Text"
  | "toggleH4Text"
  | "toggleH5Text"
  | "toggleH6Text"
  | "toggleBoldText"
  | "toggleLinkText"
  | "toggleItalicText"
  | "toggleUnderlineText"
  | "toggleStrikethroughText"
  | "toggleAIMenu"
  | "toggleBulletList"
  | "toggleNumberedList"
  | "decreaseFontSize"
  | "increaseFontSize"
  | "decreaseLetterSpacing"
  | "increaseLetterSpacing"
  | "decreaseLineHeight"
  | "increaseLineHeight"
  | "decreaseFontWeight"
  | "increaseFontWeight";

type EditCommand =
  | "undo"
  | "redo"
  | "copy"
  | "copyStyles"
  | "paste"
  | "pasteStyles"
  | "delete"
  | "duplicate"
  | "groupIntoContainer"
  | "saveComponentTemplate"
  | "exportToSection"
  | "setWidthToFillAvailable"
  | "setWidthToWrapContent"
  | "setHeightToFillAvailable"
  | "setHeightToWrapContent"
  | "toggleCodeEditor"
  | "alignLeft"
  | "alignRight"
  | "alignTop"
  | "alignBottom"
  | "alignHorizontalCenter"
  | "alignVerticalCenter";

type ViewCommand =
  | "toggleVisibility"
  | "togglePreviewMode"
  | "grabCanvas"
  | "toggleVersionHistory"
  | "toggleAIMenu"
  | "setDesignPanel"
  | "setConfigPanel"
  | "setInteractionsPanel"
  | "setElementsPanel"
  | "setLayersPanel"
  | "setComponentsPanel"
  | "setSavedStylesPanel"
  | "openPageSettings"
  | "setAssetsPanel"
  | "openProjectSettings"
  | "moveUpInTheTree"
  | "moveDownInTheTree"
  | "zoomIn"
  | "zoomOut"
  | "resetZoom";

export type ReploGlobalCommand = EditCommand | TextCommand | ViewCommand;

type CommandData = {
  label: string;
  hotkey?: HotkeyIndicatorCharacter[];
  icon?:
    | { type: "iconComponent"; component: IconType | LucideIcon }
    | { type: "create" }
    | { type: "publishedStatus"; isPublished: boolean }
    | { type: "custom"; content: ReactNode };
};

export const GLOBAL_COMMANDS: Record<ReploGlobalCommand, CommandData> = {
  undo: {
    label: "Undo",
    hotkey: ["meta", "z"],
    icon: { type: "iconComponent", component: BiUndo },
  },
  redo: {
    label: "Redo",
    hotkey: ["meta", "shift", "z"],
    icon: { type: "iconComponent", component: BiRedo },
  },
  copy: {
    label: "Copy",
    hotkey: ["meta", "c"],
    icon: { type: "iconComponent", component: BsCopy },
  },
  copyStyles: {
    label: "Copy styles",
    hotkey: ["altOption", "meta", "c"],
    icon: { type: "iconComponent", component: BsPalette },
  },
  paste: {
    label: "Paste",
    hotkey: ["meta", "v"],
    icon: { type: "iconComponent", component: BiPaste },
  },
  pasteStyles: {
    label: "Paste styles",
    hotkey: ["altOption", "meta", "v"],
    icon: { type: "iconComponent", component: BsPalette },
  },
  toggleVisibility: {
    label: "Toggle visibility",
    hotkey: ["meta", "shift", "h"],
    icon: { type: "iconComponent", component: BsEye },
  },
  delete: {
    label: "Delete",
    hotkey: ["backspace"],
    icon: { type: "iconComponent", component: BsTrash },
  },
  duplicate: {
    label: "Duplicate",
    hotkey: ["meta", "d"],
    icon: { type: "iconComponent", component: BiDuplicate },
  },
  groupIntoContainer: {
    label: "Group into container",
    hotkey: ["meta", "g"],
    icon: { type: "iconComponent", component: FaLayerGroup },
  },
  saveComponentTemplate: {
    label: "Save component template",
    hotkey: ["meta", "shift", "s"],
    icon: { type: "iconComponent", component: CgTemplate },
  },
  zoomIn: {
    label: "Zoom in",
    hotkey: ["meta", "+"],
    icon: { type: "iconComponent", component: BsZoomIn },
  },
  zoomOut: {
    label: "Zoom out",
    hotkey: ["meta", "-"],
    icon: { type: "iconComponent", component: BsZoomOut },
  },
  resetZoom: {
    label: "Reset zoom",
    hotkey: ["meta", "0"],
    icon: { type: "iconComponent", component: PiMagnifyingGlass },
  },
  grabCanvas: {
    label: "Pan canvas",
    hotkey: ["space"],
    icon: { type: "iconComponent", component: HiOutlineHand },
  },
  toggleCodeEditor: {
    label: "Toggle code editor",
    hotkey: ["shift", "d"],
    icon: { type: "iconComponent", component: BsCode },
  },
  togglePreviewMode: {
    label: "Toggle preview mode",
    hotkey: ["shift", "p"],
    icon: { type: "iconComponent", component: BiPlay },
  },
  toggleVersionHistory: {
    label: "Show version history panel",
    hotkey: ["altOption", "shift", "h"],
    icon: { type: "iconComponent", component: BsClockHistory },
  },
  toggleAIMenu: {
    label: "Build Assistant",
    hotkey: ["meta", "e"],
    icon: { type: "iconComponent", component: Sparkles },
  },
  toggleH1Text: {
    label: "Turn into H1",
    hotkey: ["meta", "altOption", "1"],
    icon: { type: "iconComponent", component: BsTypeH1 },
  },
  toggleH2Text: {
    label: "Turn into H2",
    hotkey: ["meta", "altOption", "2"],
    icon: { type: "iconComponent", component: BsTypeH2 },
  },
  toggleH3Text: {
    label: "Turn into H3",
    hotkey: ["meta", "altOption", "3"],
    icon: { type: "iconComponent", component: BsTypeH3 },
  },
  toggleH4Text: {
    label: "Turn into H4",
    hotkey: ["meta", "altOption", "4"],
    icon: { type: "iconComponent", component: BsTypeH4 },
  },
  toggleH5Text: {
    label: "Turn into H5",
    hotkey: ["meta", "altOption", "5"],
    icon: { type: "iconComponent", component: BsTypeH5 },
  },
  toggleH6Text: {
    label: "Turn into H6",
    hotkey: ["meta", "altOption", "6"],
    icon: { type: "iconComponent", component: BsTypeH6 },
  },
  toggleBoldText: {
    label: "Toggle bold",
    hotkey: ["meta", "b"],
    icon: { type: "iconComponent", component: BsTypeBold },
  },
  toggleLinkText: {
    label: "Create link",
    hotkey: ["meta", "k"],
    icon: { type: "iconComponent", component: BsLink },
  },
  toggleItalicText: {
    label: "Toggle italic",
    hotkey: ["meta", "i"],
    icon: { type: "iconComponent", component: BsTypeItalic },
  },
  toggleUnderlineText: {
    label: "Toggle underline",
    hotkey: ["meta", "u"],
    icon: { type: "iconComponent", component: BsTypeUnderline },
  },
  toggleStrikethroughText: {
    label: "Toggle strikethrough",
    hotkey: ["shift", "meta", "x"],
    icon: { type: "iconComponent", component: BsTypeStrikethrough },
  },
  toggleBulletList: {
    label: "Toggle bullet list",
    hotkey: ["meta", "altOption", "7"],
    icon: { type: "iconComponent", component: BsListUl },
  },
  toggleNumberedList: {
    label: "Toggle numbered list",
    hotkey: ["meta", "altOption", "8"],
    icon: { type: "iconComponent", component: BsListOl },
  },
  setDesignPanel: {
    label: "Show design panel",
    hotkey: ["s"],
    icon: { type: "iconComponent", component: MdOutlineDesignServices },
  },
  setConfigPanel: {
    label: "Show config panel",
    hotkey: ["d"],
    icon: { type: "iconComponent", component: BsDatabase },
  },
  setInteractionsPanel: {
    label: "Show interactions panel",
    hotkey: ["i"],
    icon: { type: "iconComponent", component: AiOutlineInteraction },
  },
  setElementsPanel: {
    label: "Show all pages panel",
    hotkey: ["altOption", "1"],
    icon: { type: "iconComponent", component: BsFileText },
  },
  setLayersPanel: {
    label: "Show layers panel",
    hotkey: ["altOption", "2"],
    icon: { type: "iconComponent", component: BsLayers },
  },
  setComponentsPanel: {
    label: "Show insert panel",
    hotkey: ["altOption", "3"],
    icon: { type: "iconComponent", component: BsPlusSquare },
  },
  setSavedStylesPanel: {
    label: "Show saved styles panel",
    hotkey: ["altOption", "4"],
    icon: { type: "iconComponent", component: BsPalette },
  },
  openPageSettings: {
    label: "Show page settings",
    hotkey: ["altOption", "4"],
    icon: { type: "iconComponent", component: BsGear },
  },
  setAssetsPanel: {
    label: "Show assets panel",
    hotkey: ["altOption", "5"],
    icon: { type: "iconComponent", component: BsImage },
  },
  openProjectSettings: {
    label: "Open project settings",
    hotkey: ["meta", "altOption", "s"],
    icon: { type: "iconComponent", component: BsSliders },
  },
  decreaseFontSize: {
    label: "Decrease font size",
    hotkey: ["shift", "meta", "<"],
    icon: { type: "iconComponent", component: RiFontSize },
  },
  increaseFontSize: {
    label: "Increase font size",
    hotkey: ["shift", "meta", ">"],
    icon: { type: "iconComponent", component: RiFontSize },
  },
  decreaseLetterSpacing: {
    label: "Decrease letter spacing",
    hotkey: ["altOption", "<"],
    icon: { type: "iconComponent", component: RxLetterSpacing },
  },
  increaseLetterSpacing: {
    label: "Increase letter spacing",
    hotkey: ["altOption", ">"],
    icon: { type: "iconComponent", component: RxLetterSpacing },
  },
  decreaseLineHeight: {
    label: "Decrease line height",
    hotkey: ["altOption", "shift", "<"],
    icon: { type: "iconComponent", component: RiLineHeight },
  },
  increaseLineHeight: {
    label: "Increase line height",
    hotkey: ["altOption", "shift", ">"],
    icon: { type: "iconComponent", component: RiLineHeight },
  },
  decreaseFontWeight: {
    label: "Decrease font weight",
    hotkey: ["altOption", "meta", "<"],
    icon: { type: "iconComponent", component: PiTextAaBold },
  },
  increaseFontWeight: {
    label: "Increase font weight",
    hotkey: ["altOption", "meta", ">"],
    icon: { type: "iconComponent", component: PiTextAaBold },
  },
  moveUpInTheTree: {
    label: "Move up in the tree",
    hotkey: ["↑"],
    icon: { type: "iconComponent", component: BsArrowUp },
  },
  moveDownInTheTree: {
    label: "Move down in the tree",
    hotkey: ["↓"],
    icon: { type: "iconComponent", component: BsArrowDown },
  },
  exportToSection: {
    label: "Export to section",
    hotkey: ["altOption", "meta", "k"],
    icon: { type: "iconComponent", component: BsBoxArrowUp },
  },
  setWidthToFillAvailable: {
    label: "Fill Available Width",
    hotkey: ["meta", "shift", "arrowRight"],
    icon: { type: "iconComponent", component: MdWidthFull },
  },
  setWidthToWrapContent: {
    label: "Wrap Content Width",
    hotkey: ["meta", "shift", "arrowLeft"],
    icon: { type: "iconComponent", component: MdWidthNormal },
  },
  setHeightToFillAvailable: {
    label: "Fill Available Height",
    hotkey: ["meta", "shift", "arrowUp"],
    icon: { type: "iconComponent", component: MdHeight },
  },
  setHeightToWrapContent: {
    label: "Wrap Content Height",
    hotkey: ["meta", "shift", "arrowDown"],
    icon: { type: "iconComponent", component: MdHeight },
  },
  alignLeft: {
    label: "Align Left",
    hotkey: ["altOption", "a"],
    icon: { type: "iconComponent", component: BsAlignStart },
  },
  alignRight: {
    label: "Align Right",
    hotkey: ["altOption", "d"],
    icon: { type: "iconComponent", component: BsAlignEnd },
  },
  alignTop: {
    label: "Align Top",
    hotkey: ["altOption", "w"],
    icon: { type: "iconComponent", component: BsAlignTop },
  },
  alignBottom: {
    label: "Align Bottom",
    hotkey: ["altOption", "s"],
    icon: { type: "iconComponent", component: BsAlignBottom },
  },
  alignHorizontalCenter: {
    label: "Align Center Horizontally",
    hotkey: ["altOption", "h"],
    icon: { type: "iconComponent", component: BsAlignCenter },
  },
  alignVerticalCenter: {
    label: "Align Center Vertically",
    hotkey: ["altOption", "v"],
    icon: { type: "iconComponent", component: BsAlignMiddle },
  },
};

type LeafCommandItem = CommandData & {
  isDisabled?: boolean;
  onSelect: () => void;
};

type GlobalLeafCommandItem = CommandData & {
  isDisabled?: boolean;
  command: ReploGlobalCommand;
};

type ChildrenCommandItem = CommandData & {
  type: "children";
  isDisabled?: boolean;
  children: Record<string, CommandItem>;
};

type CommandItem =
  | LeafCommandItem
  | GlobalLeafCommandItem
  | ChildrenCommandItem;

type CommandTextItems = Record<TextCommand, GlobalLeafCommandItem>;
type CommandEditItems = Record<EditCommand, GlobalLeafCommandItem>;
type CommandViewItems = Record<ViewCommand, GlobalLeafCommandItem>;

type CommandGroup = {
  id: string;
  heading: string;
  items: Record<string, CommandItem>;
};

type GetCommandMenuItemsConfig = {
  draftComponents: Component[];
  hasPreviousOperation: boolean;
  hasNextOperation: boolean;
};

// Note (Noah, 2025-01-22): The functions below transform command data (e.g. hotkeys,
// labels, default icons, etc) into command items, which may have state related to redux,
// or may have different selection handlers in certain circumstances, etc

export function getCommandMenuItems(
  config: GetCommandMenuItemsConfig,
): CommandGroup[] {
  const { draftComponents } = config;
  const commandGroups: CommandGroup[] = [];
  if (draftComponents.every((component) => component.type === "text")) {
    commandGroups.push({
      id: "text",
      heading: "Text",
      items: getTextCommandItems(config),
    });
  }
  commandGroups.push({
    id: "edit",
    heading: "Edit",
    items: getEditCommandItems(config),
  });

  if (draftComponents.length) {
    commandGroups.push({
      id: "add",
      heading: "Add",
      items: {},
    });
  }

  commandGroups.push({
    id: "view",
    heading: "View",
    items: getViewCommandItems(config),
  });

  return commandGroups;
}

function getTextCommandItems(
  config: GetCommandMenuItemsConfig,
): CommandTextItems {
  const { draftComponents } = config;
  const hasManyDraftComponents = draftComponents.length > 1;
  return {
    toggleH1Text: {
      ...GLOBAL_COMMANDS.toggleH1Text,
      command: "toggleH1Text",
      isDisabled: hasManyDraftComponents,
    },
    toggleH2Text: {
      ...GLOBAL_COMMANDS.toggleH2Text,
      command: "toggleH2Text",
      isDisabled: hasManyDraftComponents,
    },
    toggleH3Text: {
      ...GLOBAL_COMMANDS.toggleH3Text,
      command: "toggleH3Text",
      isDisabled: hasManyDraftComponents,
    },
    toggleH4Text: {
      ...GLOBAL_COMMANDS.toggleH4Text,
      command: "toggleH4Text",
      isDisabled: hasManyDraftComponents,
    },
    toggleH5Text: {
      ...GLOBAL_COMMANDS.toggleH5Text,
      command: "toggleH5Text",
      isDisabled: hasManyDraftComponents,
    },
    toggleH6Text: {
      ...GLOBAL_COMMANDS.toggleH6Text,
      command: "toggleH6Text",
      isDisabled: hasManyDraftComponents,
    },
    toggleBoldText: {
      ...GLOBAL_COMMANDS.toggleBoldText,
      command: "toggleBoldText",
    },
    toggleLinkText: {
      ...GLOBAL_COMMANDS.toggleLinkText,
      command: "toggleLinkText",
    },
    toggleItalicText: {
      ...GLOBAL_COMMANDS.toggleItalicText,
      command: "toggleItalicText",
    },
    toggleUnderlineText: {
      ...GLOBAL_COMMANDS.toggleUnderlineText,
      command: "toggleUnderlineText",
    },
    toggleStrikethroughText: {
      ...GLOBAL_COMMANDS.toggleStrikethroughText,
      command: "toggleStrikethroughText",
    },
    toggleAIMenu: { ...GLOBAL_COMMANDS.toggleAIMenu, command: "toggleAIMenu" },
    toggleBulletList: {
      ...GLOBAL_COMMANDS.toggleBulletList,
      command: "toggleBulletList",
    },
    toggleNumberedList: {
      ...GLOBAL_COMMANDS.toggleNumberedList,
      command: "toggleNumberedList",
    },
    decreaseFontSize: {
      ...GLOBAL_COMMANDS.decreaseFontSize,
      command: "decreaseFontSize",
    },
    increaseFontSize: {
      ...GLOBAL_COMMANDS.increaseFontSize,
      command: "increaseFontSize",
    },
    decreaseLetterSpacing: {
      ...GLOBAL_COMMANDS.decreaseLetterSpacing,
      command: "decreaseLetterSpacing",
    },
    increaseLetterSpacing: {
      ...GLOBAL_COMMANDS.increaseLetterSpacing,
      command: "increaseLetterSpacing",
    },
    decreaseLineHeight: {
      ...GLOBAL_COMMANDS.decreaseLineHeight,
      command: "decreaseLineHeight",
    },
    increaseLineHeight: {
      ...GLOBAL_COMMANDS.increaseLineHeight,
      command: "increaseLineHeight",
    },
    decreaseFontWeight: {
      ...GLOBAL_COMMANDS.decreaseFontWeight,
      command: "decreaseFontWeight",
    },
    increaseFontWeight: {
      ...GLOBAL_COMMANDS.increaseFontWeight,
      command: "increaseFontWeight",
    },
  };
}

function getEditCommandItems(
  config: GetCommandMenuItemsConfig,
): CommandEditItems {
  const { draftComponents, hasPreviousOperation, hasNextOperation } = config;
  return {
    undo: {
      ...GLOBAL_COMMANDS.undo,
      command: "undo",
      isDisabled: !hasPreviousOperation,
    },
    redo: {
      ...GLOBAL_COMMANDS.redo,
      command: "redo",
      isDisabled: !hasNextOperation,
    },
    copy: {
      ...GLOBAL_COMMANDS.copy,
      command: "copy",
      isDisabled: !draftComponents.length,
    },
    copyStyles: {
      ...GLOBAL_COMMANDS.copyStyles,
      command: "copyStyles",
      isDisabled: draftComponents.length !== 1,
    },
    paste: {
      ...GLOBAL_COMMANDS.paste,
      command: "paste",
      isDisabled: !draftComponents.length,
    },
    pasteStyles: {
      ...GLOBAL_COMMANDS.pasteStyles,
      command: "pasteStyles",
      isDisabled: draftComponents.length !== 1,
    },
    delete: {
      ...GLOBAL_COMMANDS.delete,
      command: "delete",
      isDisabled: draftComponents.length !== 1,
    },
    duplicate: {
      ...GLOBAL_COMMANDS.duplicate,
      command: "duplicate",
      isDisabled: draftComponents.length !== 1,
    },
    groupIntoContainer: {
      ...GLOBAL_COMMANDS.groupIntoContainer,
      command: "groupIntoContainer",
      isDisabled: draftComponents.length !== 1,
    },
    saveComponentTemplate: {
      ...GLOBAL_COMMANDS.saveComponentTemplate,
      command: "saveComponentTemplate",
      isDisabled: draftComponents.length !== 1,
    },
    exportToSection: {
      ...GLOBAL_COMMANDS.exportToSection,
      command: "exportToSection",
      isDisabled: draftComponents.length !== 1,
    },
    setWidthToFillAvailable: {
      ...GLOBAL_COMMANDS.setWidthToFillAvailable,
      command: "setWidthToFillAvailable",
      isDisabled: !draftComponents.length,
    },
    setWidthToWrapContent: {
      ...GLOBAL_COMMANDS.setWidthToWrapContent,
      command: "setWidthToWrapContent",
      isDisabled: !draftComponents.length,
    },
    setHeightToFillAvailable: {
      ...GLOBAL_COMMANDS.setHeightToFillAvailable,
      command: "setHeightToFillAvailable",
      isDisabled: !draftComponents.length,
    },
    setHeightToWrapContent: {
      ...GLOBAL_COMMANDS.setHeightToWrapContent,
      command: "setHeightToWrapContent",
      isDisabled: !draftComponents.length,
    },
    toggleCodeEditor: {
      ...GLOBAL_COMMANDS.toggleCodeEditor,
      command: "toggleCodeEditor",
      isDisabled: draftComponents.length
        ? !draftComponents.every((component) =>
            ["rawHtmlContent", "shopifyRawLiquid"].includes(component.type),
          )
        : true,
    },
    alignLeft: {
      ...GLOBAL_COMMANDS.alignLeft,
      command: "alignLeft",
      isDisabled: !draftComponents.length,
    },
    alignRight: {
      ...GLOBAL_COMMANDS.alignRight,
      command: "alignRight",
      isDisabled: !draftComponents.length,
    },
    alignTop: {
      ...GLOBAL_COMMANDS.alignTop,
      command: "alignTop",
      isDisabled: !draftComponents.length,
    },
    alignBottom: {
      ...GLOBAL_COMMANDS.alignBottom,
      command: "alignBottom",
      isDisabled: !draftComponents.length,
    },
    alignHorizontalCenter: {
      ...GLOBAL_COMMANDS.alignHorizontalCenter,
      command: "alignHorizontalCenter",
      isDisabled: !draftComponents.length,
    },
    alignVerticalCenter: {
      ...GLOBAL_COMMANDS.alignVerticalCenter,
      command: "alignVerticalCenter",
      isDisabled: !draftComponents.length,
    },
  };
}

function getViewCommandItems(
  config: GetCommandMenuItemsConfig,
): CommandViewItems {
  const { draftComponents } = config;
  return {
    toggleVisibility: {
      ...GLOBAL_COMMANDS.toggleVisibility,
      command: "toggleVisibility",
      isDisabled: !draftComponents.length,
    },
    togglePreviewMode: {
      ...GLOBAL_COMMANDS.togglePreviewMode,
      command: "togglePreviewMode",
    },
    grabCanvas: { ...GLOBAL_COMMANDS.grabCanvas, command: "grabCanvas" },
    toggleVersionHistory: {
      ...GLOBAL_COMMANDS.toggleVersionHistory,
      command: "toggleVersionHistory",
    },
    toggleAIMenu: { ...GLOBAL_COMMANDS.toggleAIMenu, command: "toggleAIMenu" },
    setDesignPanel: {
      ...GLOBAL_COMMANDS.setDesignPanel,
      command: "setDesignPanel",
      isDisabled: !draftComponents.length,
    },
    setConfigPanel: {
      ...GLOBAL_COMMANDS.setConfigPanel,
      command: "setConfigPanel",
      isDisabled: !draftComponents.length,
    },
    setInteractionsPanel: {
      ...GLOBAL_COMMANDS.setInteractionsPanel,
      command: "setInteractionsPanel",
      isDisabled: !draftComponents.length,
    },
    setElementsPanel: {
      ...GLOBAL_COMMANDS.setElementsPanel,
      command: "setElementsPanel",
    },
    setLayersPanel: {
      ...GLOBAL_COMMANDS.setLayersPanel,
      command: "setLayersPanel",
    },
    setComponentsPanel: {
      ...GLOBAL_COMMANDS.setComponentsPanel,
      command: "setComponentsPanel",
    },
    setSavedStylesPanel: {
      ...GLOBAL_COMMANDS.setSavedStylesPanel,
      command: "setSavedStylesPanel",
    },
    openPageSettings: {
      ...GLOBAL_COMMANDS.openPageSettings,
      command: "openPageSettings",
    },
    setAssetsPanel: {
      ...GLOBAL_COMMANDS.setAssetsPanel,
      command: "setAssetsPanel",
    },
    openProjectSettings: {
      ...GLOBAL_COMMANDS.openProjectSettings,
      command: "openProjectSettings",
    },
    moveUpInTheTree: {
      ...GLOBAL_COMMANDS.moveUpInTheTree,
      command: "moveUpInTheTree",
      isDisabled: !draftComponents.length,
    },
    moveDownInTheTree: {
      ...GLOBAL_COMMANDS.moveDownInTheTree,
      command: "moveDownInTheTree",
      isDisabled: !draftComponents.length,
    },
    zoomIn: { ...GLOBAL_COMMANDS.zoomIn, command: "zoomIn" },
    zoomOut: { ...GLOBAL_COMMANDS.zoomOut, command: "zoomOut" },
    resetZoom: { ...GLOBAL_COMMANDS.resetZoom, command: "resetZoom" },
  };
}
