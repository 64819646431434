import type { ButtonSize } from "@replo/design-system/components/button/button-shared";
import type { PendingShopifyIntegrationCookie } from "schemas/cookies";
import type { ReploSimpleProject } from "schemas/generated/project";

import * as React from "react";

import { ShopifyIntegrationSelectable } from "@editor/components/dashboard/projects/ShopifyIntegrationSelectable";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import { selectLoadableProject } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import Button from "@replo/design-system/components/button/Button";
import twMerge from "@replo/design-system/utils/twMerge";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import { useShopifyIntegrationConnect } from "./useShopifyIntegrationConnect";

export const ConnectShopifyCallout: React.FC<{
  cookieValue: Exclude<
    PendingShopifyIntegrationCookie,
    | { type: "onboarding" }
    | { type: "integrationHub" }
    | { type: "noShopifyErrorModal" }
    | { type: "projectSettings" }
    | { type: "newProject" }
  >;
  className?: string;
}> = ({ cookieValue, className }) => {
  const subscriptionTier = useSubscriptionTier();
  const detailMessage = exhaustiveSwitch({ type: cookieValue.type })({
    // Note (Evan, 2024-04-09): We only tell people to upgrade their account when they are on the free plan -
    // there are other cases where an upgrade may be required (e.g. the published pages limit has been reached)
    // but the logic of figuring that out is a little annoying, so we just account for the most obvious case here.
    publish: `To publish this page, add Shopify as an integration${subscriptionTier === "free" ? " and upgrade your account" : ""}.`,
    preview: `To preview this page, add Shopify as an integration.`,
    shopStyles: `To import saved styles from your Shopify theme, add Shopify as an integration.`,
    productPicker:
      "Replo allows you to access Shopify data dynamically in the editor. To access product data, add Shopify as an integration.",
    assets: "To add images via upload, add Shopify as an integration.",
    billingModal: null,
    sections:
      "To pick a section from your Shopify theme, please connect a Shopify Store to this project.",
  });

  const title = exhaustiveSwitch({ type: cookieValue.type })({
    publish: "Publish Page",
    preview: "Preview Page",
    productPicker: "Add Product Data",
    assets: "Upload Images",
    billingModal: null,
    sections: "Load Sections",
    shopStyles: "Import Saved Styles",
  });

  return (
    <div
      className={twMerge(
        "text-sm flex flex-col font-normal text-slate-500 gap-4 py-2",
        (cookieValue.type === "publish" || cookieValue.type === "preview") &&
          "px-2",
        className,
      )}
    >
      {(title || detailMessage) && (
        <div className="flex flex-col gap-2">
          {title && <h2 className="font-semibold text-default">title</h2>}
          {detailMessage && <p className="typ-body-small">{detailMessage}</p>}
        </div>
      )}
      <img
        src="/replo-shopify-connector.svg"
        alt="Connect Replo To Shopify"
        className="block w-full"
      />
      <div className="flex flex-col gap-2">
        <ConnectShopifyIntegrationCta cookieValue={cookieValue} />
        <p className="text-[10px] leading-relaxed">
          Don't have a Shopify Store?{" "}
          <a
            href="https://airtable.com/appy2nf6tNKFEODB4/pagObddhF9f5Pln7P/form"
            className="underline"
          >
            Add yourself to the waitlist for non-Shopify deployment!
          </a>
        </p>
      </div>
    </div>
  );
};

export const ConnectShopifyIntegrationCta: React.FC<{
  cookieValue: PendingShopifyIntegrationCookie;
  className?: string;
  selectedProject?: ReploSimpleProject;
  size?: ButtonSize;
  ctaText?: string;
}> = ({
  cookieValue,
  className,
  selectedProject,
  size = "base",
  ctaText = "Add Shopify Integration",
}) => {
  const workspaceId = useCurrentWorkspaceId();
  const { project: loadableProject } = useEditorSelector(selectLoadableProject);
  const project = selectedProject ?? loadableProject;

  const {
    selectedIntegrationId,
    setSelectedIntegrationId,
    isLoading,
    handleConnect,
  } = useShopifyIntegrationConnect({
    workspaceId,
    project,
    cookieValue,
  });

  // NOTE (Matt 2024-09-17): Given that this component can be used to connect an integration to a project or to add a new integration,
  // we set `allowExistingIntegrations` to determine whether or not to show the existing integrations selectable.
  const allowExistingIntegrations = exhaustiveSwitch({
    type: cookieValue.type,
  })({
    sections: true,
    projectSettings: true,
    publish: true,
    productPicker: true,
    assets: true,
    integrationHub: false,
    billingModal: false,
    noShopifyErrorModal: false,
    preview: true,
    onboarding: false,
    newProject: false,
    shopStyles: true,
  });

  return (
    <div className="flex flex-col gap-4">
      {allowExistingIntegrations && (
        <ShopifyIntegrationSelectable
          isDisabled={isLoading}
          workspaceId={workspaceId}
          selectedIntegrationId={selectedIntegrationId}
          handleChangeSelection={setSelectedIntegrationId}
        />
      )}
      <Button
        variant="primary"
        size={size}
        layoutClassName="w-full"
        UNSAFE_className={className}
        onClick={(e) => {
          void handleConnect(e);
        }}
        isLoading={isLoading}
      >
        {ctaText}
      </Button>
    </div>
  );
};
