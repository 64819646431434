import type { ComponentTemplate } from "@editor/types/component-template";

export const carouselPreviousControl: ComponentTemplate = {
  id: "e5f0025d-d84d-4b7f-9082-f32d971d4721",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Carousel/Carousel Previous",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/carousel-previous.svg",
  template: {
    id: "bd83d592-0c2f-4ede-97ae-1b4f8694e44d",
    name: "Previous Button 1",
    type: "carouselV3Control",
    props: {
      style: {
        cursor: "pointer",
        display: "flex",
        flexGrow: "unset",
        flexWrap: "wrap",
        alignSelf: "stretch",
        flexBasis: "auto",
        alignItems: "center",
        justifyContent: "center",
      },
      direction: "previous",
    },
    children: [
      {
        id: "32fecfc2-b619-481e-b6cf-7d2b150919ca",
        type: "icon",
        props: {
          style: {
            width: "60px",
            height: "60px",
            __width: "60px",
            __height: "60px",
            flexGrow: "unset",
          },
          iconName: "chevronLeft",
        },
        variantOverrides: {},
      },
    ],
    variants: [
      {
        id: "67c398cf-e544-441a-9b8d-0e864d2d3c47",
        name: "default",
        query: null,
        componentOverrides: null,
      },
      {
        id: "37d32387-a52c-45c9-8c33-d5e7efb05207",
        name: "First Item",
        query: {
          type: "expression",
          operator: "or",
          statements: [
            {
              id: "bf8b0b4c-79fd-4043-a18b-9840a038a6e3",
              field: "state.group.isFirstItemActive",
              value: null,
              operator: null,
            },
          ],
        },
        componentOverrides: null,
      },
    ],
    variantOverrides: {
      "37d32387-a52c-45c9-8c33-d5e7efb05207": {
        componentOverrides: {
          "32fecfc2-b619-481e-b6cf-7d2b150919ca": {
            props: { style: { opacity: "20%" } },
          },
          "bd83d592-0c2f-4ede-97ae-1b4f8694e44d": {
            props: { style: { cursor: "default" } },
          },
        },
      },
    },
  },
};
