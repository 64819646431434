import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import Input from "@common/designSystem/Input";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { BADGE_TRIGGER_OFFSET } from "@editor/components/editor/constants";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import {
  selectColor,
  selectDraftComponentId,
  selectIconAltText,
  selectPropIconName,
} from "@editor/reducers/core-reducer";
import {
  selectOpenPopoverId,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import DynamicDataValueIndicator from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { Badge } from "@replo/design-system/components/badge/Badge";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { MenuItem } from "@replo/design-system/components/menu/MenuItem";
import { isDynamicDataValue } from "replo-runtime";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { iconDirectory } from "replo-runtime/store/iconDirectory";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import ModifierLabel from "../extras/ModifierLabel";
import DynamicColorModifier from "./DynamicColorModifier";

const IconModifierRow: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({ label, children }) => (
  <div className="flex items-center w-full">
    <ModifierLabel label={label} />
    <div className="flex-1 min-w-0">{children}</div>
  </div>
);

export const IconModifier = () => {
  return (
    <ModifierGroup title="Icons">
      <div className="flex flex-col gap-2">
        <IconModifierRow label="Icon">
          <IconSelectable />
        </IconModifierRow>
        <IconModifierRow label="Alt Text">
          <IconAltTextControl />
        </IconModifierRow>
        <IconModifierRow label="Color">
          <IconColorControl />
        </IconModifierRow>
      </div>
    </ModifierGroup>
  );
};

const IconSelectable: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector(selectPropIconName);
  const Icon = iconDirectory[value]?.Component;
  const iconName = iconDirectory[value]?.displayName;
  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  const dispatch = useEditorDispatch();

  const isDefaultOpen = openPopoverId === "modifier-icon-selector";
  const [isComboboxOpen, setIsComboboxOpen] = React.useState(isDefaultOpen);

  if (!Icon && !isMixedStyleValue(value)) {
    return null;
  }

  const iconOptions = Object.keys(iconDirectory)
    .sort()
    .map((key) => {
      const iconName = iconDirectory[key]?.displayName ?? "Select an icon";
      const Icon = iconDirectory[key]?.Component;
      return {
        value: key,
        label: (
          <MenuItem
            variant="default"
            size="sm"
            selected={value === key}
            startEnhancer={
              <Badge
                type="icon"
                icon={<Icon />}
                UNSAFE_className="bg-accent text-xs text-white p-0.5"
              />
            }
          >
            {iconName}
          </MenuItem>
        ),
        displayValue: iconName,
        isSelectable: true,
        isDefaultActive: value === key,
      };
    });
  return (
    <Combobox.Root
      options={iconOptions}
      open={isComboboxOpen}
      onOpenChange={(newValue) => {
        // NOTE (Sebas, 2024-05-02): Once the popover is closed, we should reset the open popover state
        // to prevent from opening it again automatically.
        if (isDefaultOpen) {
          dispatch(setOpenPopoverId(null));
        }
        setIsComboboxOpen(newValue);
      }}
      onChange={(value) => {
        applyComponentAction({
          type: "setProps",
          value: { iconName: value },
        });
      }}
    >
      <Combobox.Trigger>
        <Combobox.SelectionButton
          size="sm"
          layoutClassName="flex-1"
          startEnhancer={
            isMixedStyleValue(value) ? (
              <Badge type="unknown" />
            ) : (
              <Badge
                type="icon"
                icon={<Icon />}
                UNSAFE_className="bg-accent p-1 text-white"
              />
            )
          }
          isPlaceholder={false}
          title={
            isMixedStyleValue(value) ? "Mixed" : iconName ?? "Select an icon"
          }
          titleAlignment="start"
        />
      </Combobox.Trigger>
      <Combobox.Popover side="left" align="center" layoutClassName="w-[200px]">
        <Combobox.Content title="Icons" areOptionsSearchable />
      </Combobox.Popover>
    </Combobox.Root>
  );
};

const IconAltTextControl: React.FC = () => {
  const text = useEditorSelector(selectIconAltText);
  const modal = useModal();

  const onOpenDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "style",
          styleAttribute: "__iconAltText",
        },
        initialPath:
          text && !isMixedStyleValue(text)
            ? getPathFromVariable(text)
            : undefined,
      },
    });
  };

  return typeof text === "string" && isDynamicDataValue(text) ? (
    <DynamicIconAltTextControl onOpenDynamicData={onOpenDynamicData} />
  ) : (
    <StaticIconAltTextControl onOpenDynamicData={onOpenDynamicData} />
  );
};

const DynamicIconAltTextControl: React.FC<{
  onOpenDynamicData: () => void;
}> = ({ onOpenDynamicData }) => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const value = useEditorSelector(selectIconAltText);
  const applyComponentAction = useApplyComponentAction();

  return (
    <DynamicDataValueIndicator
      type="text"
      templateValue={!isMixedStyleValue(value) ? value ?? null : null}
      onClick={onOpenDynamicData}
      onRemove={() => {
        applyComponentAction({
          type: "setStyles",
          value: { __iconAltText: "" },
        });
      }}
      componentId={draftComponentId ?? undefined}
    />
  );
};

const StaticIconAltTextControl: React.FC<{
  onOpenDynamicData: () => void;
}> = ({ onOpenDynamicData }) => {
  const iconAltText = useEditorSelector(selectIconAltText);
  const applyComponentAction = useApplyComponentAction();

  const handleChange = React.useCallback(
    (value: string) =>
      applyComponentAction({
        type: "setStyles",
        value: { __iconAltText: value },
      }),
    [applyComponentAction],
  );

  const inputProps = useOverridableInput({
    value: !isMixedStyleValue(iconAltText) ? iconAltText ?? "" : "Mixed",
    onValueChange: handleChange,
  });

  return (
    <div className="flex gap-1">
      <Input placeholder="Icon alt text" {...inputProps} />
      <div>
        <DynamicDataButton onClick={onOpenDynamicData} />
      </div>
    </div>
  );
};

const IconColorControl: React.FC = () => {
  const color = useEditorSelector(selectColor);
  const applyComponentAction = useApplyComponentAction();
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  if (!draftComponentId) {
    return null;
  }

  return (
    <DynamicColorModifier
      previewProperty="color"
      popoverTitle="Icon Color"
      gradientSelectionType={null}
      field="style.color"
      value={color ?? "#000000"}
      onChange={(value: string | null) => {
        applyComponentAction({
          type: "setStyles",
          value: { color: value },
        });
      }}
      onRemove={() => {
        applyComponentAction({
          type: "setStyles",
          value: { color: "" },
        });
      }}
      popoverSideOffset={BADGE_TRIGGER_OFFSET}
      showSavedStyles
    />
  );
};
