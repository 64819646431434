import * as React from "react";

export type BrandDetailsTab = "styles" | "context";

interface BrandDetailsContextType {
  selectedTab: BrandDetailsTab;
  setSelectedTab: (tab: BrandDetailsTab) => void;
}

const BrandDetailsContext = React.createContext<
  BrandDetailsContextType | undefined
>(undefined);

export const BrandDetailsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedTab, setSelectedTab] =
    React.useState<BrandDetailsTab>("styles");

  return (
    <BrandDetailsContext.Provider value={{ selectedTab, setSelectedTab }}>
      {children}
    </BrandDetailsContext.Provider>
  );
};

export const useBrandDetailsTabs = () => {
  const context = React.useContext(BrandDetailsContext);
  if (context === undefined) {
    throw new Error(
      "useBrandDetails must be used within a BrandDetailsProvider",
    );
  }
  return context;
};
