import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

export type ComponentTemplatesState = {
  deletedSavedComponentTemplateIds: string[];
};

const initialState: ComponentTemplatesState = {
  deletedSavedComponentTemplateIds: [],
};

const componentTemplatesSlice = createSlice({
  name: "componentTemplates",
  initialState,
  reducers: {
    setDeletedSavedComponentTemplatesIds: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.deletedSavedComponentTemplateIds = [
        ...state.deletedSavedComponentTemplateIds,
        action.payload,
      ];
    },
  },
});

const { reducer } = componentTemplatesSlice;

export default reducer;
