import type { ExperimentStatus } from "schemas/experiment";
import type { Experiment } from "schemas/generated/experiment";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import DeleteConfirmationModal from "@editor/components/common/DeleteConfirmationModal";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { HelpDocumentationIcon } from "@/features/experiments/components/HelpDocumentationIcon";
import { useExperimentEdit } from "@/features/experiments/tabs/hooks/useExperimentEdit";
import { useExperimentApi } from "@/features/experiments/utils";
import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { RadioButtonGroup } from "@replo/design-system/components/radioButton/RadioButtonGroup";
import { skipToken } from "@tanstack/react-query";
import { BiTrash } from "react-icons/bi";
import { BsPlayFill, BsStopCircle } from "react-icons/bs";
import { generatePath, useNavigate } from "react-router-dom";
import { BillingTiers } from "schemas/billing";
import { getExperimentStatus } from "schemas/experiment";

type ExperimentActionsBarProps = {
  workspaceId: string | null;
  experiment: Experiment;
};

export const ExperimentActionsBar: React.FC<ExperimentActionsBarProps> = ({
  experiment,
}) => {
  const {
    watch,
    hasDataChanged,
    reset,
    handleReactHookFormSubmit,
    isExperimentValid,
    getValues,
  } = useExperimentEdit();
  const logEvent = useLogAnalytics();
  const errorToast = useErrorToast();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;
  const navigate = useNavigate();
  const workspaceId = experiment.workspaceId;

  const { data: planAllowsActivation } = trpc.experiment.canActivate.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );

  const {
    update: updateExperiment,
    remove: removeExperiment,
    complete: completeExperiment,
  } = useExperimentApi({
    workspaceId: workspaceId ?? "",
  });

  const update = updateExperiment.mutateAsync;
  const isUpdating = updateExperiment.isPending;
  const remove = removeExperiment.mutateAsync;
  const isRemoving = removeExperiment.isPending;
  const complete = completeExperiment.mutateAsync;
  const isCompleting = completeExperiment.isPending;

  const [isCompletionModalOpen, setCompletionModalOpen] = React.useState(false);
  const [isDeletionConfirmationOpen, setDeletionConfirmationOpen] =
    React.useState(false);

  const disableControls = isRemoving || isUpdating || isCompleting;

  const modal = useModal();

  if (!experiment) {
    return null;
  }
  const experimentId = experiment.id;

  const status = getExperimentStatus(experiment);

  const allowActivate = status === "draft";
  const allowComplete = status === "active";

  const currentExperiment = getValues();

  const openBillingModal = () =>
    modal.openModal({
      type: "billingModal",
      props: {
        source: "experiments",
        billingPlanMessageKey: "billingPlan.experimentActivationAttempt",
      },
    });

  const updateWithStatus = async (status: ExperimentStatus) => {
    await update({
      ...currentExperiment,
      status,
      variations: currentExperiment.variations.map((v) => ({
        ...v,
        isWinner: false,
      })),
      analyticsLinkId: currentExperiment.analyticsLinkId,
    });
    await trpcUtils.experiment.findDetail.invalidate();
  };

  // Action handlers
  const onActivate = async () => {
    if (planAllowsActivation) {
      await updateWithStatus("active");
      successToast("Experiment Activated", "");
      logEvent("experiment.launch", {
        variantCount: watch("variations").length ?? 0,
        description: watch("description") ?? "",
        billingPlanTier: subscriptionTier,
      });
      navigate(
        generatePath(routes.workspace.experiments.results, {
          workspaceId,
          experimentId: experiment.id,
        }),
      );
    } else {
      openBillingModal();
    }
  };

  const onComplete = () => setCompletionModalOpen(true);
  const onCompleteCancel = () => {
    setCompletionModalOpen(false);
  };
  const onCompleteConfirm = async (
    winnerId: string,
    displaySelectionId: string,
  ) => {
    const winningVariation = experiment.variations.find(
      (variation) => variation.id === winnerId,
    );

    if (!winningVariation) {
      errorToast(
        "Error Completing Experiment",
        "No winning variation found. Please try again, or contact support@replo.app for help.",
        "error.experiment.complete",
        {
          winnerId,
          displaySelectionId,
        },
      );
      return;
    }

    await complete({
      workspaceId,
      experimentId: experiment.id,
      selectedDisplayWinnerVariationId:
        displaySelectionId === "no-winner" ? null : displaySelectionId,
      winnerVariationId: winningVariation.id,
    });

    logEvent("experiment.completed", { billingPlanTier: subscriptionTier });
    successToast("Experiment Completed 🥳", "");
    await trpcUtils.experiment.findDetail.invalidate();
  };

  const onDeleteButtonClick = () => setDeletionConfirmationOpen(true);
  const onDeleteConfirmation = async () => {
    await remove({
      experimentId,
      workspaceId,
    });
    logEvent("experiment.deleted", { billingPlanTier: subscriptionTier });
    successToast("Experiment Deleted", "");
    navigate(generatePath(routes.workspace.experiments.list, { workspaceId }));
  };
  const onDeleteCancel = () => setDeletionConfirmationOpen(false);

  const disableActivateErrors = [];

  if (!currentExperiment.analyticsLinkId) {
    disableActivateErrors.push(
      "An experiment link must be provided to activate your experiment.",
    );
  }

  if (watch("variations").some((variation) => !variation.target)) {
    disableActivateErrors.push(
      "All variations must have a target URL in order to activate your experiment.",
    );
  }

  const hasDisableActivateErrors = disableActivateErrors.length > 0;

  const disableDeleteErrors = [];

  if (status === "active") {
    disableDeleteErrors.push(
      "Please complete your experiment in order to delete it.",
    );
  }

  const hasDisableDeleteErrors = disableDeleteErrors.length > 0;

  const onSave = async (data: Experiment) => {
    void update({
      ...data,
      status,
    });
    reset(data, { keepValues: true });
    successToast("Experiment Updated", "");
  };

  const handleSubmissionClick = handleReactHookFormSubmit(onSave);

  const isUpdateAllowed = hasDataChanged && isExperimentValid;

  return (
    <div>
      {isDeletionConfirmationOpen && (
        <DeleteConfirmationModal
          assetName={experiment.name}
          assetTypeDisplayName="Experiment"
          confirmationType="delete"
          extraMessage={
            <span className="font-medium">
              It will no longer serve traffic.
            </span>
          }
          onDelete={() => void onDeleteConfirmation()}
          onRequestClose={onDeleteCancel}
        />
      )}
      <CompleteModal
        variations={experiment.variations}
        onCancel={onCompleteCancel}
        onConfirm={(winnerId, displaySelectionId) =>
          void onCompleteConfirm(winnerId, displaySelectionId)
        }
        isOpen={isCompletionModalOpen}
        initialSelection={experiment.variations[0]?.id ?? ""}
      />
      <div className="flex-none flex items-center content-center justify-between gap-x-3">
        <HelpDocumentationIcon />
        <IconButton
          variant="secondary"
          size="base"
          layoutClassName="h-8 w-8"
          onClick={() => void onDeleteButtonClick()}
          tooltipText={
            hasDisableDeleteErrors ? disableDeleteErrors[0] : undefined
          }
          disabled={disableControls || hasDisableDeleteErrors}
          icon={<BiTrash size={16} />}
        />
        {!allowComplete && status !== "complete" && (
          <Button
            variant="secondary"
            size="base"
            disabled={!hasDataChanged || !isUpdateAllowed}
            onClick={() => void handleSubmissionClick()}
          >
            Save Draft
          </Button>
        )}
        {allowActivate && (
          <Button
            variant="primary"
            size="base"
            tooltipText={
              hasDisableActivateErrors ? disableActivateErrors[0] : undefined
            }
            onClick={() => void onActivate()}
            disabled={disableControls || hasDisableActivateErrors}
            endEnhancer={<BsPlayFill size={12} />}
            collisionPadding={10}
          >
            Save & Start Test
          </Button>
        )}
        {allowComplete && (
          <Button
            variant="secondary"
            size="base"
            onClick={() => void onComplete()}
            isLoading={disableControls}
            endEnhancer={<BsStopCircle size={12} />}
          >
            End Test
          </Button>
        )}
      </div>
    </div>
  );
};

type CompleteModalProps = {
  variations: Array<Variation>;
  isOpen: boolean;
  onConfirm: (winnerId: string, displaySelectionId: string) => void;
  onCancel: () => void;
  initialSelection?: string;
};

const CompleteModal: React.FC<CompleteModalProps> = ({
  variations,
  isOpen,
  onConfirm,
  onCancel,
  initialSelection,
}) => {
  const [currentStep, setCurrentStep] =
    React.useState<ExperimentCompletionStep>("selectWinner");
  const [displaySelection, setDisplaySelection] = React.useState<string>(
    initialSelection ?? variations[0]?.id ?? "",
  );
  const [winnerSelection, setWinnerSelection] = React.useState<string | null>(
    null,
  );

  const handleClose = () => {
    setCurrentStep("selectWinner");
    setWinnerSelection(null);
    setDisplaySelection(initialSelection ?? variations[0]?.id ?? "");
    onCancel();
  };

  const handleBack = () => {
    setCurrentStep("selectWinner");
  };

  const handleNext = () => {
    if (currentStep === "selectWinner") {
      setWinnerSelection(displaySelection);
      setCurrentStep("selectDirectTrafficTarget");
    } else {
      onConfirm(winnerSelection ?? displaySelection, displaySelection);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        }
      }}
      title={
        currentStep === "selectWinner"
          ? "Declare an outcome"
          : "Direct all traffic to"
      }
      size="base"
      footer={
        <div className="flex items-center justify-between w-full">
          <span className="text-xs text-slate-400">
            Step {currentStep === "selectWinner" ? 1 : 2} of 2
          </span>
          <div className="flex flex-row gap-2">
            {currentStep === "selectDirectTrafficTarget" && (
              <Button variant="secondary" size="base" onClick={handleBack}>
                Back
              </Button>
            )}
            <Button
              variant="primary"
              size="base"
              onClick={handleNext}
              disabled={
                currentStep === "selectDirectTrafficTarget" && !winnerSelection
              }
            >
              {currentStep === "selectDirectTrafficTarget"
                ? "End and Direct Traffic"
                : "Next"}
            </Button>
          </div>
        </div>
      }
    >
      {currentStep === "selectWinner" ? (
        <WinnerSelectionStep
          variations={variations}
          selection={displaySelection}
          onSelectionChange={setDisplaySelection}
        />
      ) : (
        <DirectTrafficStep
          variations={variations}
          winnerSelection={winnerSelection ?? "no-winner"}
          setWinnerSelection={setWinnerSelection}
        />
      )}
    </Modal>
  );
};

type ExperimentCompletionStep = "selectWinner" | "selectDirectTrafficTarget";

type WinnerSelectionStepProps = {
  variations: Array<Variation>;
  selection: string;
  onSelectionChange: (id: string) => void;
};

const WinnerSelectionStep: React.FC<WinnerSelectionStepProps> = ({
  variations,
  selection,
  onSelectionChange,
}) => {
  const options = [
    ...variations.map((variation) => ({
      value: variation.id,
      label: `Variant ${variation.slug} wins`,
      description: variation.target ?? "",
    })),
    {
      value: "no-winner",
      label: "No winner",
      description: "Results were inconclusive or too close to call",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <RadioButtonGroup
        size="base"
        disabled={false}
        selectedValue={selection}
        onChange={(e) => onSelectionChange(e ?? "")}
        options={options}
        variant="card"
      />
    </div>
  );
};

type DirectTrafficStepProps = {
  variations: Array<Variation>;
  selectedVariation?: string;
  winnerSelection?: string;
  setWinnerSelection: (id: string) => void;
};

const DirectTrafficStep: React.FC<DirectTrafficStepProps> = ({
  variations,
  winnerSelection,
  setWinnerSelection,
}) => {
  // TODO (Kurt, 2024-11-16): After we update the experiment model in the database
  // along with updating how traffic is directed once a winner is declared, we will add
  // an "another page" option here where the user can specify a different page than
  // the available variations to direct traffic to. This is not possible yet due to
  // how the winner is tightly coupled to the variations and in the edgeserver.
  // setWinnerSelection(selectedVariation ?? "");
  return (
    <RadioButtonGroup
      size="base"
      disabled={false}
      selectedValue={winnerSelection}
      onChange={(e) => setWinnerSelection(e ?? "")}
      options={variations.map((variation) => ({
        value: variation.id,
        label: `Variant ${variation.slug} wins`,
        description: variation.target ?? "",
      }))}
      variant="card"
    />
  );
};
