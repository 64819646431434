import type { SubscriptionDetails } from "schemas/generated/billing";
import type { Workspace } from "schemas/generated/workspace";
import type { CurrencyCode } from "schemas/workspace";

import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import Separator from "@editor/components/common/designSystem/Separator";
import { Loader } from "@editor/components/common/Loader";
import Header from "@editor/components/dashboard/Header";
import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useOpenModal } from "@editor/hooks/useModal";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { skipToken } from "@tanstack/react-query";
import { formatInTimeZone } from "date-fns-tz";
import { useForm } from "react-hook-form";
import { LuClock } from "react-icons/lu";
import { CURRENCIES } from "schemas/workspace";

import { useAutoSetWorkspaceSettingsIfNull } from "./hooks/useAutoSetWorkspaceSettingsIfNull";
import { useUpdateWorkspace } from "./hooks/useUpdateWorkspace";
import { formatIanaTimezone } from "./utils";

export type WorkspaceSettings = Pick<
  Workspace,
  "name" | "timeZone" | "currency"
>;

const WorkspaceSettingsForm: React.FC<{
  workspace: Workspace;
}> = ({ workspace }) => {
  const { updateWorkspace } = useUpdateWorkspace();
  const {
    handleSubmit,
    setValue,
    formState: { isDirty },
    watch,
    reset,
  } = useForm<WorkspaceSettings>({
    defaultValues: {
      name: workspace.name,
      timeZone:
        workspace.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      currency: workspace.currency ?? "USD",
    },
  });

  const handleSetValue = (
    key: keyof WorkspaceSettings,
    value: WorkspaceSettings[keyof WorkspaceSettings],
  ) => {
    setValue(key, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const updateSettingsSections = [
    {
      title: "Workspace Name",
      component: (
        <WorkspaceNameInput
          initialName={watch("name")}
          onChange={(value) => handleSetValue("name", value)}
        />
      ),
    },
    {
      title: "Timezone",
      component: (
        <WorkspaceTimeZoneSettings
          timeZone={watch("timeZone") ?? ""}
          onChange={(value) => handleSetValue("timeZone", value)}
        />
      ),
    },
    {
      title: "Currency",
      component: (
        <WorkspaceCurrencySettings
          currency={watch("currency") ?? "USD"}
          onChange={(value) => handleSetValue("currency", value)}
        />
      ),
    },
  ];

  const handleWorkspaceUpdate = async (
    workspaceSettings: WorkspaceSettings,
  ) => {
    await updateWorkspace({
      id: workspace.id,
      data: workspaceSettings,
    });
    reset({}, { keepValues: true });
  };

  return (
    <div className="flex flex-col gap-4">
      {updateSettingsSections.map((section) => {
        return (
          <>
            <SettingsContent key={section.title} title={section.title}>
              {section.component}
            </SettingsContent>
          </>
        );
      })}
      <div className="flex flex-row w-full justify-end">
        <Button
          variant="primary"
          size="sm"
          onClick={() => void handleSubmit(handleWorkspaceUpdate)()}
          disabled={!isDirty}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

const WorkspaceTimeZoneSettings: React.FC<{
  timeZone: string;
  onChange: (value: string) => void;
}> = ({ timeZone, onChange }) => {
  const getTimeZoneLabel = (timezone: string) => {
    if (timezone === "UTC") {
      return "UTC (GMT±00:00)";
    }

    const formattedTimezone = formatIanaTimezone(timezone);

    // NOTE (Kurt, 2025-03-03): We format the GMT part to be more readable by
    // replacing GMT+0:00 or GMT-0:00 with GMT±0:00.
    let gmtPart = formatInTimeZone(new Date(), timezone, "'GMT'xxx");
    gmtPart = gmtPart.replace(/GMT[+-]00:00/g, "GMT±00:00");

    return `${formattedTimezone} (${gmtPart})`;
  };

  const timezoneOptions = Intl.supportedValuesOf("timeZone").map(
    (timezone) => ({
      label: getTimeZoneLabel(timezone),
      value: timezone,
    }),
  );

  timezoneOptions.unshift({
    label: getTimeZoneLabel("UTC"),
    value: "UTC",
  });

  return (
    <div className="flex flex-col gap-2">
      <Combobox
        options={timezoneOptions}
        value={timeZone ?? undefined}
        onChange={(value) => onChange(value)}
        trigger={
          <Combobox.SelectionButton
            title={getTimeZoneLabel(timeZone ?? "")}
            titleAlignment="start"
            isPlaceholder={!Boolean(timeZone)}
            startEnhancer={<LuClock size={14} />}
          />
        }
        areOptionsSearchable
      />
      <div className="typ-body-small text-muted">
        All analytics will be reported using this timezone.
      </div>
    </div>
  );
};

const WorkspaceCurrencySettings: React.FC<{
  currency: CurrencyCode;
  onChange: (value: string) => void;
}> = ({ currency, onChange }) => {
  // NOTE (Kurt, 2025-04-01): We use the Intl API's standardized currency formatting to
  // extract the currency symbol. We strip the digits and whitespace to get just the symbol.
  // This handles all currency formats consistently across different locales for proper symbol representation.
  // Taken from: https://stackoverflow.com/questions/50650503/get-the-currency-symbol-for-a-locale
  const getCurrencySymbol = (currency: CurrencyCode) => {
    return (0)
      .toLocaleString("en", {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  const formatCurrencyLabel = (code: CurrencyCode, name: string) => {
    const symbol = getCurrencySymbol(code);
    // NOTE (Kurt, 2025-04-02): Some currency symbols aren't rendered consistently in the browser, so the
    // Intl API returns the currency code instead, if we get a code back we don't want to show it since we
    // already show the currency code in the label.
    return `${code} - ${name}${symbol !== code ? ` (${symbol})` : ""}`;
  };

  const currencyOptions = Object.entries(CURRENCIES).map(
    ([code, { name }]) => ({
      label: formatCurrencyLabel(code as CurrencyCode, name),
      value: code,
    }),
  );

  return (
    <div className="flex flex-col gap-2">
      <Combobox
        options={currencyOptions}
        value={currency}
        onChange={(value) => onChange(value)}
        trigger={
          <Combobox.SelectionButton
            title={formatCurrencyLabel(currency, CURRENCIES[currency].name)}
            titleAlignment="start"
            isPlaceholder={!Boolean(currency)}
          />
        }
        areOptionsSearchable
      />
      <div className="typ-body-small text-muted">
        All analytics will be reported in this currency.
      </div>
    </div>
  );
};

const WorkspaceNameInput = ({
  initialName,
  onChange,
}: {
  initialName: string;
  onChange: (value: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Input
        value={initialName}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter your workspace name"
        size="base"
      />
    </div>
  );
};

type WorkspaceDeleteSectionProps = {
  numberOfProjects: number;
  numberOfReferralCodes: number;
  subscription: SubscriptionDetails | null;
  isOwner: boolean;
  workspaceId: string;
};

const WorkspaceDeleteSection: React.FC<WorkspaceDeleteSectionProps> = ({
  numberOfProjects,
  numberOfReferralCodes,
  subscription,
  isOwner,
  workspaceId,
}) => {
  const canDelete =
    numberOfProjects === 0 &&
    numberOfReferralCodes === 0 &&
    !subscription &&
    isOwner;

  const openModal = useOpenModal();

  const deleteRequirements = [
    { text: "Delete all projects", subtitle: `${numberOfProjects} projects` },
    {
      text: "Cancel your subscription",
      subtitle: `${subscription?.name} plan`,
    },
    {
      text: "Delete all referral codes",
      subtitle: `${numberOfReferralCodes} codes`,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      {!canDelete && (
        <div className="flex flex-col gap-2">
          <span className="typ-body-small text-muted">
            To delete this workspace you must:
          </span>
          <ul className="list-disc list-inside typ-body-small text-muted">
            {deleteRequirements.map((requirement) => (
              <li key={requirement.text}>
                {requirement.text}{" "}
                <span className="typ-button-small">
                  [{requirement.subtitle}]
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="typ-body-small text-muted">
        Deleting this workspace is a permanent action. Deleted workspaces cannot
        be recovered without the help of Replo support.
      </div>
      <div className="flex flex-row w-full justify-end">
        <Button
          variant="danger"
          size="sm"
          disabled={!canDelete}
          onClick={() => {
            openModal({
              type: "deleteWorkspaceModal",
              props: {
                workspaceId,
              },
            });
          }}
        >
          Delete Workspace
        </Button>
      </div>
    </div>
  );
};

const SettingsContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex flex-col gap-3 border py-4 px-6 max-w-[500px] rounded-lg">
      {children}
    </div>
  );
};

type SettingsContentProps = {
  title?: string;
};

const SettingsContent: React.FC<
  React.PropsWithChildren<SettingsContentProps>
> = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-3">
      {title && <span className="typ-label-base">{title}</span>}
      {children}
    </div>
  );
};

type WorkspaceSettingsProps = {
  sections: {
    title?: string;
    component: React.ReactNode;
  }[];
};

const LoadedWorkspaceSettings = ({ sections }: WorkspaceSettingsProps) => {
  return (
    <>
      {sections.map((section, index) => (
        <SettingsContainer key={index}>
          <SettingsContent title={section.title}>
            {section.component}
          </SettingsContent>
        </SettingsContainer>
      ))}
    </>
  );
};

const SettingsLayout: React.FC<React.PropsWithChildren<{ title: string }>> = ({
  children,
  title,
}) => {
  return (
    <div className="flex flex-col gap-4 px-6 w-full">
      <Header title={title} />
      <Separator />
      {children}
    </div>
  );
};

const WorkspaceSettingsWrapper = () => {
  const { workspace, isLoading: isLoadingWorkspace } =
    useCurrentWorkspaceContext();
  const workspaceId = workspace?.id;

  const { data: projectList, isLoading: isLoadingWorkspaceProjectData } =
    trpc.project.listWithStats.useQuery({ workspaceId });

  const { data: workspaceSubscription, isLoading: isLoadingSubsciption } =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspaceId ?? skipToken,
    );

  const userIsOwner = useIsWorkspaceOwner(workspaceId);

  const isLoading =
    isLoadingWorkspace || isLoadingWorkspaceProjectData || isLoadingSubsciption;

  useAutoSetWorkspaceSettingsIfNull();

  if (!workspace || !projectList) {
    return null;
  }

  const sections = [
    {
      component: <WorkspaceSettingsForm workspace={workspace} />,
    },
    {
      title: "Delete Workspace",
      component: (
        <WorkspaceDeleteSection
          numberOfProjects={projectList.projects.length}
          numberOfReferralCodes={workspace.referralCodes.length}
          subscription={workspaceSubscription ?? null}
          isOwner={userIsOwner}
          workspaceId={workspace.id}
        />
      ),
    },
  ];

  return (
    <SettingsLayout title="Workspace Settings">
      {isLoading ? <Loader /> : <LoadedWorkspaceSettings sections={sections} />}
    </SettingsLayout>
  );
};

export default WorkspaceSettingsWrapper;
