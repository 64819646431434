import * as React from "react";

import { CommandInput } from "@replo/design-system/components/shadcn/core/command";
import composeRefs from "@seznam/compose-react-refs";

import { useCombobox } from "../hooks/useCombobox";

type ComboboxInputProps = {
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  placeholder?: string;
  outlineOnActive?: boolean;
};

export const ComboboxInput = React.forwardRef<
  HTMLInputElement,
  ComboboxInputProps
>(
  (
    {
      startEnhancer,
      endEnhancer,
      placeholder,
      outlineOnActive = true,
      ...props
    },
    ref,
  ) => {
    const {
      open,
      triggerInputRef,
      input,
      setInput,
      value,
      isUsingTriggerInput,
      setIsUsingTriggerInput,
      syncInputWithSelectedValue,
    } = useCombobox();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
    React.useEffect(() => {
      setIsUsingTriggerInput(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps

      // NOTE (Max, 2025-01-10): If the combobox already has a value, we make sure
      // that the input has that value: ensures the combobox's value is in sync with
      // the input's value.
      if (syncInputWithSelectedValue && !input && typeof value === "string") {
        setInput(value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * NOTE (Max, 2025-01-04): this handleBlur is used to solve the following situation:
     * 1. The combobox has a selected value.
     * 2. The user starts typing a new value.
     * 3. The user clicks outside the combobox (without having selected a new value).
     *
     * In this case, we want to reset the input to the already-selected
     * value, hence this handleBlur. Otherwise, the input would have still shown the
     * incomplete string that the user was typing (while the actual selected value wouldn't have
     * actually changed. That way, we ensure that the input & the selected value are in sync.
     */
    const handleBlur = () => {
      if (
        syncInputWithSelectedValue &&
        !open &&
        isUsingTriggerInput &&
        typeof value === "string"
      ) {
        setInput(value);
      }
    };

    return (
      <CommandInput
        ref={composeRefs(ref, triggerInputRef)}
        value={input}
        onValueChange={setInput}
        placeholder={placeholder}
        className="typ-body-small h-8"
        startEnhancer={startEnhancer}
        endEnhancer={endEnhancer}
        onBlur={handleBlur}
        outlineOnActive={outlineOnActive}
        {...props}
      />
    );
  },
);

ComboboxInput.displayName = "ComboboxInput";
