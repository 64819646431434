import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

const SvgAssetsEmptyState = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={58}
    height={57}
    fill="none"
    {...props}
  >
    <rect
      x="1.72009"
      y="12.3158"
      width="44.7076"
      height="44.7076"
      rx="4.13959"
      transform="rotate(-14.6478 1.72009 12.3158)"
      fill="#EFF6FF"
    />
    <rect
      x="1.72009"
      y="12.3158"
      width="44.7076"
      height="44.7076"
      rx="4.13959"
      transform="rotate(-14.6478 1.72009 12.3158)"
      stroke="#DBEAFE"
      strokeWidth="1.65584"
    />
    <path
      d="M27.0482 24.094C27.3373 25.1999 26.675 26.3308 25.5691 26.6199C24.4631 26.909 23.3322 26.2467 23.0431 25.1408C22.7541 24.0348 23.4163 22.9039 24.5223 22.6148C25.6282 22.3258 26.7591 22.988 27.0482 24.094Z"
      fill="#2563EB"
    />
    <path
      d="M31.2811 26.4557C31.1067 26.4189 30.9284 26.4978 30.8384 26.6516L28.8526 31.2012L26.1423 29.537C25.9558 29.4726 25.7497 29.5488 25.65 29.7191L23.6406 33.9698L24.0594 35.5718C24.175 36.0142 24.6274 36.2791 25.0697 36.1635L36.2839 33.2324C36.7263 33.1168 36.9912 32.6644 36.8755 32.222L35.9334 28.6175L31.2811 26.4557Z"
      fill="#2563EB"
    />
    <rect
      x="6.5015"
      y="5.7913"
      width="44.7076"
      height="44.7076"
      rx="4.13959"
      fill="#EFF6FF"
    />
    <rect
      x="6.5015"
      y="5.7913"
      width="44.7076"
      height="44.7076"
      rx="4.13959"
      stroke="#DBEAFE"
      strokeWidth="1.65584"
    />
    <path
      d="M28.0278 23.5918C28.0278 24.7349 27.1011 25.6616 25.958 25.6616C24.8149 25.6616 23.8882 24.7349 23.8882 23.5918C23.8882 22.4487 24.8149 21.522 25.958 21.522C27.1011 21.522 28.0278 22.4487 28.0278 23.5918Z"
      fill="#2563EB"
    />
    <path
      d="M31.5259 26.9472C31.3665 26.8675 31.174 26.8987 31.048 27.0247L27.9763 30.9243L25.7749 28.6288C25.6108 28.5194 25.3921 28.541 25.2526 28.6805L22.2336 32.2849V33.9408C22.2336 34.398 22.6043 34.7687 23.0616 34.7687H34.6524C35.1097 34.7687 35.4803 34.398 35.4803 33.9408V30.2151L31.5259 26.9472Z"
      fill="#2563EB"
    />
  </Svg>
);
export default SvgAssetsEmptyState;
