import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

import ModifierLabel from "./ModifierLabel";

type ControlGroupProps = {
  label?: string;
  className?: string;
};

const ControlGroup: React.FC<React.PropsWithChildren<ControlGroupProps>> = ({
  label,
  children,
  className,
}) => {
  return (
    <div className={twMerge("flex items-center", className)}>
      {label ? <ModifierLabel label={label} /> : null}
      {children}
    </div>
  );
};

export default ControlGroup;
