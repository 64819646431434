import type { ComponentTemplateType } from "schemas/generated/componentTemplates";

import { reploComponentSchema } from "schemas/component";
import { componentTemplatePreviewPropsSchema } from "schemas/element";
import { processArrayUrlParam } from "schemas/utils";
import { z } from "zod";

import { designLibrarySavedStyleMetadataSchema } from "./designLibrary";

export const componentTemplateTypeSchema = z
  .enum(["page", "section", "component"])
  .describe("ComponentTemplateType");

export const scopeSchema = z
  .enum(["global", "store", "left-bar"])
  .describe("ComponentTemplateScope");

const categoryIds = [
  "3ad88fad-1019-49f4-b970-f3fcbb61f4fb",
  "6cc4c4ae-5c4b-469c-85d3-59385422e9da",
  "535fc5cf-db07-46dd-b2d4-9a1f7657c196",
  "c5cd3ebe-9548-463f-8056-4e27f0214768",
  "80a8fb99-b475-4372-a07e-d924bd7f44cb",
  "0fc69131-decc-473a-b3bb-e84587edc01a",
  "919a3583-d225-4b8b-bc3b-71ed672ff90e",
  "00de5418-7eea-4b7a-af2b-eeda19c973e1",
  "8afbd31b-6062-4a34-8147-373af45c1c41",
  "f1808527-b40a-4927-87d5-1572459d38da",
  "c2dd8baa-4f70-4cef-a6b4-81c5dc996627",
  "1c283f55-d1a0-4173-804a-a622f1f6c2fe",
  "d9be7379-beba-4334-b7fc-7e7f06f474f3",
  "a5ff41d0-c798-40e3-ad7c-448679ddf021",
  "def7687f-1488-4b81-8679-8944a92d56be",
  "33fd6bd7-a5ad-4e26-a8ff-66505873174e",
  "8cf813d0-6460-11ee-b489-f2472ab43fa3",
  "885e9648-d0f1-46b3-9e97-c2a446bb43d2",
  "824b2b1b-85e7-48a1-b380-41fa448c9b83",
] as const;
export type ComponentTemplateCategoryId = (typeof categoryIds)[number];

// Note (Noah, 2023-05-03): These are a all the known categories we have - eventually
// we plan on moving these to the database. The ids are arbitrary, all that matters
// is that this code and the database agree on them.
export const CATEGORIES_IDS = {
  /* Sections */
  heros: "3ad88fad-1019-49f4-b970-f3fcbb61f4fb",
  banners: "6cc4c4ae-5c4b-469c-85d3-59385422e9da",
  testimonials: "c5cd3ebe-9548-463f-8056-4e27f0214768",
  benefits: "80a8fb99-b475-4372-a07e-d924bd7f44cb",
  productDetails: "0fc69131-decc-473a-b3bb-e84587edc01a",
  comparisons: "919a3583-d225-4b8b-bc3b-71ed672ff90e",
  trustSignals: "00de5418-7eea-4b7a-af2b-eeda19c973e1",
  collections: "8afbd31b-6062-4a34-8147-373af45c1c41",
  education: "f1808527-b40a-4927-87d5-1572459d38da",
  conversionCTAs: "c2dd8baa-4f70-4cef-a6b4-81c5dc996627",
  sections: "824b2b1b-85e7-48a1-b380-41fa448c9b83",

  /* Pages */
  landingPage: "1c283f55-d1a0-4173-804a-a622f1f6c2fe",
  blogPostPage: "d9be7379-beba-4334-b7fc-7e7f06f474f3",
  advertorialPage: "def7687f-1488-4b81-8679-8944a92d56be",
  productDropPage: "33fd6bd7-a5ad-4e26-a8ff-66505873174e",
  productPageTemplates: "8cf813d0-6460-11ee-b489-f2472ab43fa3",

  /* Both Page and Section */
  listicle: "a5ff41d0-c798-40e3-ad7c-448679ddf021",
  faqs: "535fc5cf-db07-46dd-b2d4-9a1f7657c196",
  aboutUs: "885e9648-d0f1-46b3-9e97-c2a446bb43d2",
} as const satisfies Record<string, ComponentTemplateCategoryId>;

const categorySlugs = [
  "heros",
  "banners",
  "sections",
  "faqs",
  "testimonials",
  "landing-pages",
  "advertorial",
  "listicle",
  "video-sales-letter",
  "blog-posts",
  "product-drop",
  "product-page-templates",
  "about-us",
  "education",
  "conversion-ctas",
  "trust-signals",
  "benefits",
  "product-details",
  "comparisons",
  "collections",
] as const;
type ComponentTemplateCategorySlug = (typeof categorySlugs)[number];

const componentTemplatesIndustriesIds = [
  "dbec89c6-0222-4115-a994-967c07514b5b",
  "58785c14-4db0-47b5-9fcf-bd48cbb92938",
  "ef386249-c49f-49b3-8fcc-350a7b8ab1e0",
  "a95bc923-28ac-41da-978f-46be5c024793",
  "d16c03d2-c5b8-40e9-8f8b-a59d2c5755a1",
  "26fd6a4e-525c-48b9-bfb4-718332f2a661",
  "5b5f7cf6-2aee-4ea3-85c6-ea73111aca9e",
  "2932fe19-bbbf-4219-8b8d-b181b670f5d8",
  "3f39766e-9c1b-44ae-b105-5d64d93f2cad",
  "68395a9c-e77b-460b-9806-9cb1ec7e0dc1",
  "9107a7ec-4a26-4ed3-9307-98fbad60fbb1",
  "56ec366e-8626-46c3-ba69-6343b651f534",
  "be3a36d9-113e-4188-99dc-97a4420409e5",
  "73cb5719-5bd0-47a0-a861-9fa66818debf",
  "b785dd5d-efb4-4fe0-85a6-c3b3b59a34db",
  "d4e28d8a-9c59-4744-9044-121162f9b247",
  "7c7825e2-bf9f-4cae-81c4-2692d5213603",
] as const;
export const industryIdSchema = z.enum(componentTemplatesIndustriesIds);
export type ComponentTemplateIndustryId =
  (typeof componentTemplatesIndustriesIds)[number];

export const componentTemplateIndustryNames = [
  "Accessories",
  "Activities & Outdoors",
  "Baby & Kids",
  "Beauty",
  "Bags & Luggage",
  "Clothing",
  "Drinks",
  "Food",
  "Hair Care",
  "Health & Wellness",
  "Home",
  "Personal Care",
  "Pets",
  "Shoes",
  "Skin Care",
  "Supplements",
  "Tech",
] as const;
export const componentTemplateIndustryNamesSchema = z
  .enum(componentTemplateIndustryNames)
  .describe("ComponentTemplateIndustryNames");

type ComponentTemplateIndustryName =
  (typeof componentTemplateIndustryNames)[number];
export type ComponentTemplateIndustry = {
  id: ComponentTemplateIndustryId;
  name: ComponentTemplateIndustryName;
};

const leftBarMainCategories = [
  "basic",
  "layout",
  "product",
  "productIntegrations",
  "media",
  "interactive",
  "pageIntegrations",
  "customCode",
] as const;
const leftBarMainCategorySchema = z.enum(leftBarMainCategories);

const leftBarSubCategories = [
  "text",
  "core",
  "shapes",
  "columns",
  "productLayouts",
  "productData",
  "buttons",
  "variants",
  "options",
  "sellingPlans",
  "subscription",
  "okendo",
  "junip",
  "yotpo",
  "loox",
  "reviewsIo",
  "stamped",
  "fera",
  "judgeMe",
  "infiniteOptions",
  "kachingBundles",
  "image",
  "video",
  "toggles",
  "animations",
  "countdown",
  "popup",
  "carousel",
  "form",
  "map",
  "shopify",
  "rebuy",
  "beforeAfterSlider",
  "other",
] as const;
export const leftBarSubCategorySchema = z
  .enum(leftBarSubCategories)
  .describe("ComponentTemplateLeftBarSubCategory");

type BaseComponentTemplateCategories = {
  name: string;
  title?: string;
  slug: ComponentTemplateCategorySlug;
  type: ComponentTemplateType;
  order: number;
};
export type ComponentTemplateSelectionCategories =
  BaseComponentTemplateCategories & {
    id: ComponentTemplateCategoryId;
  };

export const collectionSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    organizationName: z.string().optional(),
    descriptionMarkdown: z.string().nullable(),
    profileImageUrl: z.string(),
    coverImageUrl: z.string(),
    websiteUrl: z.string().nullable(),
    priority: z.number().nullable(),
    updatedAt: z.coerce.date(),
    createdAt: z.coerce.date(),
  })
  .describe("ComponentTemplateCollection");

export const leftBarCategorySchema = z
  .object({
    main: leftBarMainCategorySchema,
    subCategory: leftBarSubCategorySchema.optional(),
  })
  .describe("ComponentTemplateLeftBarCategory");

export const componentTemplateSchema = z
  .object({
    /* Base Schema */
    id: z.string(),
    name: z.string(),
    type: componentTemplateTypeSchema,
    scope: scopeSchema.optional(),
    template: reploComponentSchema.optional(),
    // NOTE (Fran 2024-08-15): These are strings because to get these templates we are not using the ORM
    // instead we use a Raw query.
    createdAt: z.coerce.string().optional(),
    updatedAt: z.coerce.string().optional(),
    deletedAt: z.coerce.string().optional(),

    /* Marketplace Specific Schema */
    categoryId: z.string().nullish(),
    industryId: industryIdSchema.nullish(),
    collectionId: z.string().nullish(),
    storeId: z.string().uuid().nullable(),
    mobilePreviewUrl: z.string().optional(),
    desktopPreviewUrl: z.string().optional(),
    collection: collectionSchema
      .pick({
        id: true,
        name: true,
        organizationName: true,
        coverImageUrl: true,
        profileImageUrl: true,
        descriptionMarkdown: true,
      })
      .nullish(),
    logoUrl: z.string().nullish(),
    mobileThumbnail: z.string().nullish(),
    desktopThumbnail: z.string().nullish(),
    designLibraryMetadata: designLibrarySavedStyleMetadataSchema.nullish(),

    /* Left Bar Specific Schema */
    leftBarCategory: leftBarCategorySchema.optional(),
    // NOTE (Fran 2024-08-15): This is a little build an schema so and we only need this for hardcoded
    // templates so we can use type this as any, and complete the type with actual types.
    transforms: z.any(),
    // NOTE (Matt, 2023-08-02) canBeAddedAsChild is being deprecated in favor of the newer 'ancestorAllow`,
    // which is defined in Replo Runtime and is more extensible.
    // @deprecated
    // NOTE (Fran 2024-08-15): This is a little build an schema so and we only need this for hardcoded
    // templates so we can use type this as any, and complete the type with actual types.
    canBeAddedAsChild: z.any(),
    /* Tags to allow a narrow search in the left panel */
    tags: z.array(z.string()).optional(),

    preview: z.string().optional(),
    selectedPreview: z.string().optional(),
    embeddingText: z.string().optional().nullable(),
  })
  .describe("ComponentTemplate");

export const componentTemplateWithoutIdSchema = componentTemplateSchema.omit({
  id: true,
});
export const createComponentTemplateBody = z.object({
  componentTemplate: componentTemplateWithoutIdSchema,
  storeId: z.string().uuid(),
  designLibraryMetadata: designLibrarySavedStyleMetadataSchema.nullish(),
});

export const createComponentTemplateV2Body = z
  .object({
    componentTemplate: componentTemplateWithoutIdSchema,
    storeId: z.string().uuid(),
    previewRenderProps: componentTemplatePreviewPropsSchema,
    designLibraryMetadata: designLibrarySavedStyleMetadataSchema.nullish(),
  })
  .describe("CreateComponentTemplateV2Body");

export const componentTemplatesQuerySchema = z
  .object({
    storeId: z.string().nullish(),
    componentCategoryType: z.array(componentTemplateTypeSchema),
    componentCollectionId: z.string().nullish(),
    searchText: z.string().nullish(),
    pageSize: z.preprocess(Number, z.number()).default(24),
    cursor: z.number().default(0),
    scope: z.enum(["global", "store"]).default("global"),
    includeComponent: z.boolean().default(false),
    selectedIndustries: processArrayUrlParam(z.string(), []),
    selectedCategories: processArrayUrlParam(z.string(), []),
    includeArchivedTemplates: z
      .preprocess((value) => value === "true", z.boolean())
      .default(false),
  })
  .describe("ComponentTemplatesFindQuery");
