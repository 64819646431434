import type {
  CheckboxVariant,
  CommandVariant,
  CustomVariant,
  DefaultVariant,
  ExpandableVariant,
  MenuItemProps as MenuItemComponentProps,
  ProductVariant,
  SwitchVariant,
} from "./MenuItem";

import * as React from "react";

import * as DropdownContextMenu from "@radix-ui/react-context-menu";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { animated, config, useSpring } from "react-spring";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import { MenuItem as MenuItemComponent } from "./MenuItem";

export interface MenuItemSection {
  type: "section";
  items: MenuItem[];
}

type MenuItemLeaf = {
  type: "leaf";
  id: string;
  title: React.ReactNode;
  onSelect?: () => void;
} & (
  | DefaultVariant
  | CommandVariant
  | ExpandableVariant
  | CheckboxVariant
  | SwitchVariant
  | CustomVariant
  | ProductVariant
) &
  Omit<MenuItemComponentProps, "type" | "variant" | "children">;

type MenuItemNested = {
  type: "nested";
  id?: string;
  title: React.ReactNode;
  items: MenuItem[];
  onSelect?: () => void;
  size?: "sm" | "base";
  startEnhancer?: React.ReactNode;
} & Omit<MenuItemComponentProps, "type">;

export type MenuItem = MenuItemSection | MenuItemLeaf | MenuItemNested;

type MenuType = "normal" | "context";

type MenuProps = {
  items: MenuItem[];
  isOpen?: boolean;
  onRequestOpen?(): void;
  onRequestClose?(): void;
  trigger: Exclude<React.ReactNode, undefined | null | boolean>;
  menuType?: MenuType;
  customWidth?: number | "auto";
  disableOutsideInteractionsWhileOpen?: boolean;
  stopPropagationOnItemClick?: boolean;
  align?: "end" | "center" | "start" | undefined;
  isDisabled?: boolean;
  contentClassNames?: string;
  itemClassName?: string;
  size?: "base" | "sm";
  side?: "top" | "right" | "bottom" | "left";
  sideOffset?: number;

  /**
   * If true, the menu will not focus the trigger immediately after closing. This is useful for
   * when you want to do things like trigger a popover or a focus as the result of a menu item
   * click.
   *
   * https://medium.com/@ojasskapre/fixing-input-focus-issues-in-a-shadcn-radix-card-ui-dropdown-menu-in-next-js-7b5931e049c8
   */
  disableTriggerFocusOnClose?: boolean;
};

type MenuItemProps = {
  item: MenuItem;
  index?: number;
  lastIndex: number;
  menuType: "normal" | "context";
  customWidth?: number | "auto";
  size?: "sm" | "base";
  className?: string;
  stopPropagationOnItemClick?: boolean;
};

const MenuContext = React.createContext<{
  menuType: MenuType;
  isDisabled: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
} | null>(null);
MenuContext.displayName = "MenuContext";

function useMenuContext() {
  const context = React.useContext(MenuContext);
  if (!context) {
    throw new Error(
      "Menu compound components cannot be rendered outside the Menu component",
    );
  }
  return context;
}

const MenuItem = ({
  item,
  index,
  lastIndex,
  menuType,
  size,
  className,
  stopPropagationOnItemClick = true,
}: MenuItemProps) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  const MenuComponent =
    menuType === "normal" ? DropdownMenu : DropdownContextMenu;

  const transitions = useSpring({
    opacity: openMenu ? 1 : 0,
    transform: openMenu ? `scale(${1})` : `scale(${0.9})`,
    config: config.stiff,
  });

  const changeFocus = () => setIsFocused(!isFocused);
  const { setIsOpen } = useMenuContext();

  switch (item?.type) {
    case "leaf": {
      const {
        id,
        title,
        disabled = false,
        onSelect,
        size: sizeProp,
        variant,
      } = item as MenuItemLeaf;
      return (
        <MenuComponent.Item
          key={id.toString()}
          disabled={disabled}
          onClick={
            stopPropagationOnItemClick ? (e) => e.stopPropagation() : undefined
          }
          onSelect={() => {
            // Note (Noah, 2025-03-11): Important to set the Menu to closed BEFORE triggering
            // the onSelect callback. Otherwise, there's an issue in Radix dropdown / dialog
            // where if the dropdown menu unmounts without closing, when you close the dialog
            // a pointer-events: none will be left on the body.
            //
            // https://github.com/shadcn-ui/ui/issues/1859
            // https://github.com/radix-ui/primitives/issues/837
            setIsOpen(false);
            onSelect?.();
          }}
          asChild
        >
          {exhaustiveSwitch({ ...item, type: variant } as {
            type: MenuItemLeaf["variant"];
          } & typeof item)({
            default: (props: { type: "default" } & DefaultVariant) => (
              <MenuItemComponent
                variant="default"
                size={sizeProp || size}
                disabled={disabled}
                startEnhancer={props.startEnhancer}
                selected={props.selected}
              >
                {title}
              </MenuItemComponent>
            ),
            command: (props: { type: "command" } & CommandVariant) => (
              <MenuItemComponent
                variant="command"
                size={sizeProp || size}
                disabled={disabled}
                startEnhancer={props.startEnhancer}
                shortcut={props.shortcut}
              >
                {title}
              </MenuItemComponent>
            ),
            expandable: (props: { type: "expandable" } & ExpandableVariant) => (
              <MenuItemComponent
                variant="expandable"
                size={sizeProp || size}
                disabled={disabled}
                startEnhancer={props.startEnhancer}
              >
                {title}
              </MenuItemComponent>
            ),
            custom: (props: { type: "custom" } & CustomVariant) => (
              <MenuItemComponent
                variant="custom"
                size={sizeProp || size}
                disabled={disabled}
                startEnhancer={props.startEnhancer}
                endEnhancer={props.endEnhancer}
                selected={props.selected}
              >
                {title}
              </MenuItemComponent>
            ),
            checkbox: (props: { type: "checkbox" } & CheckboxVariant) => (
              <MenuItemComponent
                variant="checkbox"
                size={sizeProp || size}
                disabled={disabled}
                selected={props.selected}
              >
                {title}
              </MenuItemComponent>
            ),
            switch: (props: { type: "switch" } & SwitchVariant) => (
              <MenuItemComponent
                variant="switch"
                size={sizeProp || size}
                disabled={disabled}
                isOn={props.isOn}
              >
                {title}
              </MenuItemComponent>
            ),
            product: (props: { type: "product" } & ProductVariant) => (
              <MenuItemComponent
                variant="product"
                size={sizeProp || size}
                disabled={disabled}
                hasCheckbox={props.hasCheckbox}
                selected={props.selected}
              >
                {title}
              </MenuItemComponent>
            ),
          })}
        </MenuComponent.Item>
      );
    }
    case "section": {
      const { items } = item;
      const indexForNonZero = index === lastIndex ? -3 : -1;
      return (
        <>
          {index !== 0 && (
            <MenuComponent.Separator asChild>
              <hr className="my-2 border-t-0.5 border-border" />
            </MenuComponent.Separator>
          )}
          {items.map((sectionItem, i) => {
            return (
              <div key={i.toString()}>
                <MenuItem
                  item={sectionItem}
                  index={index === 0 ? i : indexForNonZero}
                  lastIndex={
                    index === lastIndex && i === items.length - 1 ? -3 : -2
                  }
                  menuType={menuType}
                  size={size}
                  className={className}
                />
              </div>
            );
          })}
        </>
      );
    }
    case "nested": {
      const { items, title, id, size: sizeProp, startEnhancer } = item;
      const menuDontHaveItems = items.length === 0;
      return (
        <MenuComponent.Sub open={openMenu} onOpenChange={setOpenMenu}>
          <MenuComponent.SubTrigger asChild disabled={menuDontHaveItems}>
            <div
              onFocus={changeFocus}
              onBlur={changeFocus}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <MenuItemComponent
                variant="expandable"
                size={sizeProp || size}
                disabled={menuDontHaveItems}
                startEnhancer={startEnhancer}
                id={id}
              >
                {title}
              </MenuItemComponent>
            </div>
          </MenuComponent.SubTrigger>

          <MenuComponent.Portal>
            <MenuComponent.SubContent asChild sideOffset={4}>
              <animated.div
                style={transitions}
                className="rounded bg-white shadow p-1"
              >
                {items.map((nestedItem, i) => {
                  return (
                    <div key={i.toString()}>
                      <MenuItem
                        item={nestedItem}
                        index={i}
                        lastIndex={items.length - 1}
                        menuType={menuType}
                        size={size}
                        className={className}
                      />
                    </div>
                  );
                })}
              </animated.div>
            </MenuComponent.SubContent>
          </MenuComponent.Portal>
        </MenuComponent.Sub>
      );
    }
    default:
      return null;
  }
};

export function Menu({
  items,
  isOpen: isOpenProp,
  onRequestClose,
  onRequestOpen,
  menuType = "normal",
  customWidth,
  disableOutsideInteractionsWhileOpen,
  align,
  isDisabled = false,
  contentClassNames,
  itemClassName,
  trigger,
  size = "sm",
  disableTriggerFocusOnClose = false,
  side = "bottom",
  sideOffset,
  // NOTE (Fran 2025-01-06 USE-1528): We only need to avoid stopping the propagation on click of
  // an item if the menu conflicts with other functionalities when the user selects an item.
  // One example is this ticket USE-1528
  stopPropagationOnItemClick,
}: MenuProps) {
  const [isOpen, setIsOpen] = useControllableState(
    isOpenProp,
    false,
    onOpenChange,
  );
  const transitions = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `scale(${1})` : `scale(${0.9})`,
    config: config.stiff,
  });

  const MenuComponent =
    menuType === "normal" ? DropdownMenu : DropdownContextMenu;

  function onInteractOutside() {
    if (menuType === "context") {
      onRequestClose?.();
      return;
    }
    if (isOpenProp === undefined) {
      setIsOpen(!isOpen);
    }
    onRequestClose?.();
  }

  function onOpenChange(open: boolean) {
    if (open) {
      onRequestOpen?.();
    } else if (!open) {
      onRequestClose?.();
    }
  }

  const menuAlign = align || (customWidth ? "end" : "center");

  if (!items || items.length === 0) {
    // Note (Noah, 2024-10-31, REPL-14438): If the menu is empty we're not going to render
    // a Menu.Root, so if we've provided a MenuTrigger that will cause an error due to context
    // not being available. So, just render the children directly instead.
    if (React.isValidElement(trigger) && trigger.type === MenuTrigger) {
      return trigger.props.children;
    }
    return trigger;
  }

  const offset = 4;

  return (
    <MenuComponent.Root
      open={isDisabled ? false : isOpen}
      onOpenChange={setIsOpen}
      modal={disableOutsideInteractionsWhileOpen}
    >
      <MenuContext.Provider value={{ menuType, isDisabled, isOpen, setIsOpen }}>
        {!React.isValidElement(trigger) || trigger.type !== MenuTrigger ? (
          <MenuTrigger>{trigger}</MenuTrigger>
        ) : (
          trigger
        )}
        <MenuComponent.Portal>
          <MenuComponent.Content
            asChild
            onInteractOutside={onInteractOutside}
            className={twMerge(
              "p-2 shadow-menu rounded-md border-[.5px] border-border",
              contentClassNames,
            )}
            style={{ width: customWidth }}
            side={side}
            align={menuAlign}
            alignOffset={customWidth ? -offset : 0}
            sideOffset={customWidth && !sideOffset ? offset : sideOffset ?? 0}
            onCloseAutoFocus={
              disableTriggerFocusOnClose ? (e) => e.preventDefault() : undefined
            }
          >
            <animated.div
              style={transitions}
              className="rounded bg-white shadow p-1"
            >
              {items.map((item, index) => {
                return (
                  <div key={index.toString()}>
                    <MenuItem
                      item={item}
                      index={index}
                      lastIndex={items.length - 1}
                      menuType={menuType}
                      customWidth={customWidth}
                      size={size}
                      className={itemClassName}
                      stopPropagationOnItemClick={stopPropagationOnItemClick}
                    />
                  </div>
                );
              })}
            </animated.div>
          </MenuComponent.Content>
        </MenuComponent.Portal>
      </MenuContext.Provider>
    </MenuComponent.Root>
  );
}

export const MenuTrigger = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<{
    className?: string;
    style?: React.CSSProperties;
    asChild?: boolean;
    tooltipText?: string;
  }>
>(function MenuTrigger(
  { className, style, asChild, children, tooltipText, ...props },
  ref,
) {
  const { menuType, isDisabled, isOpen } = useMenuContext();
  const MenuComponent =
    menuType === "normal" ? DropdownMenu : DropdownContextMenu;
  const trigger = (
    <MenuComponent.Trigger
      ref={ref}
      className={twMerge(
        "rounded outline-1 outline-blue-600",
        menuType !== "context" && !isDisabled && "cursor-rounded",
        menuType !== "context" && isDisabled && "cursor-default",
        isOpen && "bg-light-surface",
        className,
      )}
      style={style}
      asChild={asChild}
      {...props}
    >
      {children}
    </MenuComponent.Trigger>
  );
  if (tooltipText) {
    return (
      <Tooltip content={tooltipText} triggerAsChild>
        {trigger}
      </Tooltip>
    );
  }
  return trigger;
});
