import type { ComponentTemplateType } from "schemas/generated/componentTemplates";
import type { SaveTemplateModalProps } from "../AppModalTypes";

import * as React from "react";

import Input from "@common/designSystem/Input";
import { useModal } from "@editor/hooks/useModal";
import { useSaveComponentTemplate } from "@editor/hooks/useSaveComponentTemplate";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import twMerge from "@replo/design-system/utils/twMerge";

const COMPONENT_TEMPLATE_TYPE_OPTIONS = [
  {
    type: "component",
    title: "Component",
    description: "Insert panel",
    imagePath: "/images/templates/saved/save-component-skeleton.svg",
  },
  {
    type: "section",
    title: "Section",
    description: "Insert panel",
    imagePath: "/images/templates/saved/save-section-skeleton.svg",
  },
  {
    type: "page",
    title: "Saved Page",
    description: "Page library",
    imagePath: "/images/templates/saved/save-page-skeleton.svg",
  },
];

const SaveItemSelectOptionButton = ({
  title,
  description,
  imagePath,
  onClick,
  isSelected,
}: {
  title: string;
  description: string;
  imagePath: string;
  onClick: () => void;
  isSelected: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        "px-3 pt-3 rounded-md flex flex-col overflow-hidden border-0.5 group text-left hover:bg-selectable-selected-hover",
        isSelected
          ? "bg-selectable-selected  border-primary"
          : "bg-white border-border",
      )}
    >
      <div className="flex-1">
        <h3
          className={twMerge(
            "typ-label-small font-semibold text-muted",
            isSelected && "text-default",
          )}
        >
          {title}
        </h3>
        <p
          className={twMerge(
            "typ-body-base mb-2 text-placeholder",
            isSelected && "text-muted",
          )}
        >
          {description}
        </p>
      </div>
      <div className="mt-auto relative translate-y-2">
        <img
          src={imagePath}
          alt={title}
          className="w-full object-cover shadow-lg"
        />
      </div>
    </button>
  );
};

export const SaveTemplateModal = (props: SaveTemplateModalProps) => {
  const [selectedType, setSelectedType] =
    React.useState<ComponentTemplateType>("component");
  const modal = useModal();
  const [name, setName] = React.useState(props.initialName ?? "");
  const { isLoading, handleSubmit } = useSaveComponentTemplate(() => {
    modal.closeModal({});
  });
  const onSubmit = async () => {
    void handleSubmit(name, selectedType);
  };

  return (
    <Modal
      isOpen={true}
      onOpenChange={() => modal.closeModal({ type: "saveTemplateModal" })}
      size="base"
      title="New Saved Item"
      description="Save to build faster and share templates with others"
      footer={
        <div className="flex gap-2">
          <Button
            variant="secondary"
            size="base"
            onClick={() => modal.closeModal({})}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={name.trim().length === 0}
            size="base"
            isLoading={isLoading}
            onClick={() => void onSubmit()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Item Name"
        size="base"
      />
      <div className="flex gap-3">
        {COMPONENT_TEMPLATE_TYPE_OPTIONS.map((option) => (
          <SaveItemSelectOptionButton
            key={option.title}
            title={option.title}
            description={option.description}
            imagePath={option.imagePath}
            onClick={() =>
              setSelectedType(option.type as ComponentTemplateType)
            }
            isSelected={selectedType === option.type}
          />
        ))}
      </div>
    </Modal>
  );
};
