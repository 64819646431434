import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import useResetDesignLibraryTextValue from "@editor/hooks/designLibrary/useResetDesignLibraryTextValue";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useShopifyFontOptions } from "@editor/hooks/useFontFamilyOptions";
import { calculateFontWeights } from "@editor/hooks/useFontWeightOptions";
import {
  selectDraftComponentNodeFromActiveCanvas,
  selectFontFamily,
  selectFontWeight,
  selectSavedStyle,
  selectSrcDocFonts,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { normalizeFontFamily } from "@editorModifiers/utils";

import minBy from "lodash-es/minBy";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { useForceUpdate } from "replo-utils/react/use-force-update";

import FontFamilySelector from "../selectors/text-style/FontFamilySelector";
import { fontStartCase } from "../selectors/text-style/utils";

const FontFamilyControl: React.FC<{
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({ value: controlledValue, onChange: controlledOnChange }) => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const selectedFontFamily = useEditorSelector(selectFontFamily);
  const selectedFontWeight = useEditorSelector(selectFontWeight);
  const srcDocFonts = useEditorSelector(selectSrcDocFonts);

  const { fallbacks } = useShopifyFontOptions();

  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      !isMixedStyleValue(selectedFontFamily)
        ? selectedFontFamily ?? null
        : null,
    )?.fontFamily;

  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();

  const fontFamily =
    controlledValue ?? deletedSavedStyleValue ?? selectedFontFamily ?? null;

  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );

  const rerender = useForceUpdate();
  const computedFontStyle =
    draftComponentNode && getComputedStyle(draftComponentNode).fontFamily;

  const onSelect = (fontValue: string | null) => {
    if (controlledOnChange) {
      // Controlled mode
      controlledOnChange(fontValue);
    } else {
      // Uncontrolled mode (existing behavior)
      const newFontValue = fontValue
        ? `${fontValue}, ${fallbacks.join(",")}`
        : null;

      // NOTE (Jackson, 2025-04-04): Determine if the new font's available weights contain the current weight,
      // and if not, find the closest available weight and use that instead
      let newFontWeight = 400;

      const calculatedFontWeights = calculateFontWeights(
        srcDocFonts,
        fontValue,
        false,
      );

      if (!isMixedStyleValue(selectedFontWeight) && selectedFontWeight) {
        const currentWeight = Number(selectedFontWeight);

        const currentWeightIsAvailable = calculatedFontWeights.some(
          (option) => Number(option.value) === currentWeight,
        );

        if (currentWeightIsAvailable) {
          newFontWeight = currentWeight;
        } else if (calculatedFontWeights.length > 0) {
          const closestWeight = minBy(calculatedFontWeights, (option) =>
            Math.abs(Number(option.value) - currentWeight),
          );
          if (closestWeight?.value) {
            newFontWeight = Number(closestWeight.value);
          }
        }
      }

      const actions = [];
      const savedStyle = selectSavedStyle(store.getState());
      if (isMixedStyleValue(savedStyle)) {
        const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
        if (actionsWithoutSavedStyles) {
          actions.push(...actionsWithoutSavedStyles);
        }
      }

      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          ...actions,
          {
            type: "setStyles",
            value: {
              fontFamily: newFontValue,
              fontWeight: newFontWeight,
            },
          },
        ],
      });
    }
  };

  // NOTE (Sebas, 2023-03-22): When we reset the font to the default value,
  // we need to wait some time to get the updated computed font style.
  // NOTE (Chance, 2024-04-09) This should probably be handled in onSelect
  // instead to reduce complexity.
  const fontFamilyRef = React.useRef(fontFamily);
  React.useEffect(() => {
    const previousFontFamily = fontFamilyRef.current;
    fontFamilyRef.current = fontFamily;
    if (previousFontFamily && !fontFamily) {
      const id = setTimeout(() => {
        rerender();
      }, 500);
      return () => {
        clearTimeout(id);
      };
    }
  }, [rerender, fontFamily]);

  let placeholder = "Mixed";
  if (!isMixedStyleValue(fontFamily)) {
    const normalizedFont = normalizeFontFamily(computedFontStyle);
    placeholder = normalizedFont ? fontStartCase(normalizedFont) : "";
  }

  return (
    <FontFamilySelector
      fontFamily={!isMixedStyleValue(fontFamily) ? fontFamily : null}
      onSelect={onSelect}
      placeholder={placeholder}
    />
  );
};

export default FontFamilyControl;
