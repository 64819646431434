import type { SolidOrGradient, SolidValue } from "replo-runtime/shared/types";
import type { SavedColorStyle } from "schemas/generated/designLibrary";

import * as React from "react";

import GradientPicker from "@common/designSystem/color/GradientPicker";
import SolidColorPicker from "@common/designSystem/color/SolidColorPicker";
import { DebouncedInput } from "@common/designSystem/Input";
import Separator from "@common/designSystem/Separator";

import Popover from "@replo/design-system/components/popover/Popover";
import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import {
  cssGradientToGradient,
  gradientToCssGradient,
  isCssGradient,
} from "replo-runtime/shared/utils/gradient";

type ColorSavedStyleModifierProps = {
  isPopoverOpen: boolean;
  onPopoverOpenChange: (isOpen: boolean) => void;
  colorSavedStyle: SavedColorStyle & { id: string };
  handleUpdateColorSavedStyle: (
    updatedStyle: SavedColorStyle & { id: string },
  ) => void;
};

export const ColorSavedStyleModifier: React.FC<
  React.PropsWithChildren<ColorSavedStyleModifierProps>
> = ({
  isPopoverOpen,
  onPopoverOpenChange,
  colorSavedStyle,
  handleUpdateColorSavedStyle,
}) => {
  const colorValue = getColorValue(colorSavedStyle.attributes.color);

  const isGradient = colorValue.type === "gradient";
  const [toggleGroupValue, setToggleGroupValue] = React.useState<
    "solid" | "gradient"
  >(isGradient ? "gradient" : "solid");

  return (
    <Popover.Root isOpen={isPopoverOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside
        side="right"
        align="start"
        sideOffset={20}
        className="p-2 w-[250px] rounded-lg"
        title="Edit Color"
        onInteractOutside={() => {
          onPopoverOpenChange(false);
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex gap-3 items-center">
            <label htmlFor="name" className="typ-body-small">
              Name
            </label>
            <DebouncedInput
              id="name"
              placeholder="Name"
              value={colorSavedStyle.name}
              onValueChange={(value) => {
                handleUpdateColorSavedStyle({
                  ...colorSavedStyle,
                  name: value,
                });
              }}
            />
          </div>
          <Separator className="mt-1" />

          <div className="flex flex-col gap-2 w-full">
            <ToggleGroup
              size="sm"
              options={[
                { label: "Solid", value: "solid" },
                { label: "Gradient", value: "gradient" },
              ]}
              selectedValue={toggleGroupValue}
              onChange={(value) => {
                setToggleGroupValue(value as "solid" | "gradient");
              }}
            />
            {toggleGroupValue === "solid" ? (
              <SolidColorPicker
                showShopStyles={false}
                value={(colorValue as SolidValue)?.color ?? null}
                onChange={(value) => {
                  handleUpdateColorSavedStyle({
                    ...colorSavedStyle,
                    attributes: {
                      ...colorSavedStyle.attributes,
                      color: value ?? "#000000",
                    },
                  });
                }}
              />
            ) : (
              <GradientPicker
                showShopStyles={false}
                value={
                  colorValue.type === "solid"
                    ? {
                        type: "gradient",
                        gradient: {
                          tilt: "90deg",
                          stops: [
                            {
                              id: "1",
                              color: colorValue.color ?? "#000000",
                              location: "0%",
                            },
                          ],
                        },
                      }
                    : colorValue
                }
                onChange={(value) => {
                  if ("gradient" in value) {
                    const color = getSavedStyleColorValue(value);
                    if (color) {
                      handleUpdateColorSavedStyle({
                        ...colorSavedStyle,
                        attributes: {
                          ...colorSavedStyle.attributes,
                          color,
                        },
                      });
                    }
                  }
                }}
              />
            )}
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="top-0 left-0" />
    </Popover.Root>
  );
};

function getColorValue(color: string): SolidOrGradient {
  if (isCssGradient(color)) {
    return {
      type: "gradient",
      gradient: cssGradientToGradient(color),
    };
  }
  return {
    type: "solid",
    color,
  };
}

function getSavedStyleColorValue(color: SolidOrGradient) {
  if (color.type === "gradient") {
    return gradientToCssGradient(color.gradient);
  }
  return color.color;
}
