import type { CodeEditorLanguage } from "@editor/components/modals/CodeEditorModal";

import * as React from "react";

import {
  selectDraftComponentType,
  selectPropCSSContent,
  selectPropHTMLContent,
  selectPropLiquidContent,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import useApplyComponentAction from "@hooks/useApplyComponentAction";
import { useOpenModal } from "@hooks/useModal";

import { getRenderData } from "replo-runtime/store/components";

const useOpenCodeEditor = () => {
  const applyComponentAction = useApplyComponentAction();
  const openModal = useOpenModal();
  const store = useEditorStore();

  const supportedShortcutTypes = new Set([
    "htmlCodeEditor",
    "cssCodeEditor",
    "liquidCodeEditor",
    "rawHtmlContent",
  ]);

  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const openCodeEditor = React.useCallback(() => {
    const htmlContent = selectPropHTMLContent(store.getState());
    const cssContent = selectPropCSSContent(store.getState());
    const liquidContent = selectPropLiquidContent(store.getState());

    const componentType = draftComponentType as
      | "htmlCodeEditor"
      | "cssCodeEditor"
      | "liquidCodeEditor"
      | "rawHtmlContent"
      | "shopifyRawLiquid";

    const editorConfig = {
      htmlCodeEditor: {
        value: htmlContent,
        language: "html",
        propName: "_htmlContent",
      },
      cssCodeEditor: {
        value: cssContent,
        language: "css",
        propName: "_css",
      },
      liquidCodeEditor: {
        value: liquidContent,
        language: "liquid",
        propName: "_liquidContent",
      },
      rawHtmlContent: {
        value: htmlContent,
        language: "html",
        propName: "_htmlContent",
      },
      shopifyRawLiquid: {
        value: liquidContent,
        language: "liquid",
        propName: "_liquidContent",
      },
    }[componentType];

    openModal({
      type: "codeEditorModal",
      props: {
        value: editorConfig.value,
        language: editorConfig.language as CodeEditorLanguage,
        onChange: (value: string) =>
          applyComponentAction({
            type: "setProps",
            value: { [editorConfig.propName]: value },
          }),
      },
    });
  }, [store, openModal, applyComponentAction, draftComponentType]);

  if (!draftComponentType) {
    return;
  }

  const renderData = getRenderData(draftComponentType);
  const currentTypeRenderData = renderData?.customProps?.find((customProp) =>
    supportedShortcutTypes.has(customProp.type),
  );

  if (!currentTypeRenderData) {
    return;
  }

  return openCodeEditor;
};

export default useOpenCodeEditor;
