import type { ReploSimpleProject } from "schemas/generated/project";

import * as React from "react";

import { ConnectShopifyIntegrationCta } from "@editor/components/editor/page/ConnectShopifyCallout";

import { Modal } from "@replo/design-system/components/modal/Modal";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { Link } from "react-router-dom";

type ConnectShopifyModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  project: ReploSimpleProject;
};

const ConnectShopifyModal: React.FC<ConnectShopifyModalProps> = ({
  isOpen,
  onRequestClose,
  project,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onRequestClose();
        }
      }}
      title="Connect Shopify Store"
      size="sm"
      description="Connect your Shopify store to enable product data, image uploads, and publishing capabilities."
    >
      <ConnectShopifyIntegrationCta
        cookieValue={{
          type: "projectSettings",
          projectId: project.id,
          workspaceId: project.ownerWorkspaceId,
        }}
        selectedProject={project}
      />
    </Modal>
  );
};

type ShopifyButtonProps = {
  isConnected: boolean;
  shopifyUrl?: string;
  project: ReploSimpleProject;
};

const ShopifyButton: React.FC<ShopifyButtonProps> = ({
  isConnected,
  shopifyUrl,
  project,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  if (isConnected && shopifyUrl) {
    return (
      <Tooltip content={shopifyUrl} triggerAsChild>
        <Link
          to={`https://${shopifyUrl}`}
          target="_blank"
          className="flex flex-row items-center gap-1 rounded px-2 py-1 bg-green-100 min-w-fit"
        >
          <img
            src="/images/shopify-logo.svg"
            alt="Shopify logo"
            className="w-4 h-4 text-slate-600"
            aria-hidden
          />
          <span className="text-xs text-slate-600 whitespace-nowrap">
            Connected
          </span>
        </Link>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip content="Connect to Shopify" triggerAsChild>
        <button
          className="flex flex-row items-center gap-1 rounded px-2 py-1 bg-slate-100 min-w-fit"
          onClick={() => setIsModalOpen(true)}
        >
          <img
            src="/images/shopify-logo.svg"
            alt="Shopify logo"
            className="w-4 h-4 text-slate-600"
            aria-hidden
          />
          <span className="text-xs text-slate-600 whitespace-nowrap">
            Connect store
          </span>
        </button>
      </Tooltip>
      <ConnectShopifyModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        project={project}
      />
    </>
  );
};

export default ShopifyButton;
