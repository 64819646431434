import * as React from "react";

import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import InputComponent from "@editor/components/common/designSystem/Input";

import Header from "@/editor/components/dashboard/Header";
import IconButton from "@replo/design-system/components/button/IconButton";
import { GoPencil } from "react-icons/go";
import { useLocation } from "react-router-dom";

type EditableHeaderProps = {
  title: string;
  onTitleChange: (newTitle: string) => Promise<void>;
  isLoading?: boolean;
};

const EditableHeader: React.FC<EditableHeaderProps> = ({
  title,
  onTitleChange,
  isLoading,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const location = useLocation();

  const inputProps = useOverridableInput({
    value: title,
    onValueChange: undefined,
    timeout: 0,
  });

  React.useEffect(() => {
    const isNewExperiment = location.state?.newExperiment;
    if (isNewExperiment) {
      // NOTE (Kurt, 2024-12-26): We need to clear the new experiment flag from location state
      // so that we don't trigger the editable header on the next render. For instance,
      // when we initially edit the name we cause the header component to re-render which sees
      // this state as true and triggers the editable header again.
      location.state = {
        ...location.state,
        newExperiment: false,
      };
      setIsEditing(true);
      setTimeout(() => {
        const input = inputRef.current;
        if (input) {
          input.focus();
          input.select();
        }
      }, 0);
    }
  }, [location]);

  const handleEditClick = () => {
    setIsEditing(true);
    // NOTE (Kurt, 2024-12-26): We need to use setTimeout here because React needs to finish rendering the input element
    // before we can focus it. Without setTimeout, the focus() call might happen before the
    // input is actually mounted in the DOM, causing the focus to fail.
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleBlur = () => {
    const newValue = inputProps.value;
    if (newValue !== title && newValue.trim() !== "") {
      void onTitleChange(newValue);
    } else {
      inputProps.onChange({
        target: { value: title },
      } as React.ChangeEvent<HTMLInputElement>);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const newValue = inputProps.value;
      if (newValue !== title && newValue.trim() !== "") {
        void onTitleChange(newValue);
      } else {
        inputProps.onChange({
          target: { value: title },
        } as React.ChangeEvent<HTMLInputElement>);
      }
      setIsEditing(false);
    }
    if (e.key === "Escape") {
      inputProps.onChange({
        target: { value: title },
      } as React.ChangeEvent<HTMLInputElement>);
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col gap-2 items-start">
          <InputComponent
            ref={inputRef}
            size="base"
            type="text"
            {...inputProps}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            unsafe_inputClassName="typ-header-h2"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      <Header title={title} isLoading={isLoading} />
      <IconButton
        onClick={handleEditClick}
        variant="tertiary"
        size="base"
        layoutClassName="h-8 w-8"
        icon={<GoPencil size={14} strokeWidth={1} className="text-muted" />}
      />
    </div>
  );
};

export default EditableHeader;
