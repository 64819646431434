import type { DynamicCommandItem } from "@editor/hooks/useDynamicCommandMenuItems";
import type {
  CommandGroupWithActions,
  CommandItem,
} from "@editor/utils/commands";
import type {
  HotkeyIndicatorCharacter,
  HotkeyMetaKey,
} from "@editor/utils/hotkeys";

import * as React from "react";

import {
  allElementTypes,
  elementTypeToEditorData,
} from "@editor/components/editor/element";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useCreateElement } from "@editor/hooks/element/useCreateElement";
import { useDynamicCommandMenuItems } from "@editor/hooks/useDynamicCommandMenuItems";
import { useElementsMetadataByType } from "@editor/hooks/useElementsMetadataByType";
import { useGlobalEditorActions } from "@editor/hooks/useGlobalEditorActions";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  disableFeatureFlag,
  enableFeatureFlag,
  isFeatureEnabled,
} from "@editor/infra/featureFlags";
import {
  selectCommandMenuItems,
  toggleDebugPanelVisibility,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import {
  commandItemHasChildren,
  commandItemHasGlobalCommand,
} from "@editor/utils/commands";
import { HotkeyMetaKeyToLabel } from "@editor/utils/hotkeys";
import { routes } from "@editor/utils/router";

import { isMacintosh } from "@replo/design-system/utils/platform";
import twMerge from "@replo/design-system/utils/twMerge";
import { Command } from "cmdk";
import { MdAdd, MdFlag } from "react-icons/md";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { featureFlags } from "replo-utils/lib/featureFlags";
import { exhaustiveSwitch, hasOwnProperty } from "replo-utils/lib/misc";

import { useIsDebugMode } from "./editor/debug/useIsDebugMode";

/**
 * State that can be pushed onto the command menu stack. Default means the default
 * command menu items, depending on editor context. Custom means we're viewing a child
 * command group with specific items.
 */
type CommandMenuState =
  | "default"
  | { type: "custom"; commandGroup: CommandGroupWithActions };

const isCustomCommandMenuState = (
  state: CommandMenuState,
): state is { type: "custom"; commandGroup: CommandGroupWithActions } =>
  hasOwnProperty(state, "type") && state.type === "custom";

export function CommandMenu() {
  const defaultCommandMenuItems = useCommandMenuItems();
  const commandMenuStack = useCommandMenuStack();
  const [searchTerm, setSearchTerm] = React.useState("");
  const logEvent = useLogAnalytics();

  // Note (Noah, 2025-01-22): Listener for cmd-k, to open the menu from anywhere
  React.useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        if (commandMenuStack.isEmpty) {
          logEvent("editor.cmdk.open");
          commandMenuStack.push("default");
        } else {
          logEvent("editor.cmdk.close", { interaction: "cmd-k" });
          commandMenuStack.clear();
        }
      }
    }

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [commandMenuStack, logEvent]);

  const commandMenuItems =
    commandMenuStack.currentState &&
    isCustomCommandMenuState(commandMenuStack.currentState)
      ? [commandMenuStack.currentState.commandGroup]
      : defaultCommandMenuItems;

  const globalActions = useGlobalEditorActions();

  const handleItemSelect = (item: CommandItem, key: string) => {
    logEvent("editor.cmdk.select", { label: item.label });
    if (commandItemHasChildren(item)) {
      commandMenuStack.push({
        type: "custom",
        commandGroup: {
          id: key,
          heading: item.label,
          items: item.children,
        },
      });
      setSearchTerm("");
    } else if (commandItemHasGlobalCommand(item)) {
      globalActions[item.command]();
      commandMenuStack.clear();
      setSearchTerm("");
    } else {
      item.onSelect();
      commandMenuStack.clear();
      setSearchTerm("");
    }
  };

  return (
    <Command.Dialog
      open={!commandMenuStack.isEmpty}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          commandMenuStack.push("default");
        } else {
          logEvent("editor.cmdk.close");
          commandMenuStack.clear();
        }
      }}
      label="Replo Command Menu"
      onKeyDown={(e) => {
        // Backspace goes to previous page when search is empty
        const isEscape = e.key === "Escape";
        const isBackspace = e.key === "Backspace";
        const shouldPopDueToBackspace = isBackspace && !searchTerm;
        if (isEscape || shouldPopDueToBackspace) {
          e.preventDefault();

          if (commandMenuStack.length > 1) {
            logEvent("editor.cmdk.back", {
              interaction: e.key === "Escape" ? "esc" : "backspace",
            });
          }

          if (
            commandMenuStack.currentState &&
            isCustomCommandMenuState(commandMenuStack.currentState)
          ) {
            // Note (Noah, 2025-01-22): Go back one stack frame by popping
            // the last item off the stack
            commandMenuStack.pop();
          }
        }

        if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
          e.stopPropagation();
        }
      }}
    >
      <Command.Input
        value={searchTerm}
        onValueChange={setSearchTerm}
        placeholder="Type a command or search"
      />

      <Command.List>
        <Command.Empty>No results found.</Command.Empty>
        {commandMenuItems.map((group) => (
          <Command.Group key={group.heading} heading={group.heading}>
            {Object.entries(group.items)
              .filter(([, item]) => !item.isDisabled)
              .map(([key, item]) => {
                return (
                  <React.Fragment key={key}>
                    <SingleCommandListItem
                      item={item}
                      onSelect={() => handleItemSelect(item, key)}
                    />
                    {/* Note (Noah, 2025-01-19): If we're an item that has children,
                    also add in our children as items so that users can directly find
                    the children without having click us, if they want. This is how
                    Linear's cmd-k works.*/}
                    {commandItemHasChildren(item) &&
                      Object.entries(item.children).map(([key, child]) => (
                        <SingleCommandListItem
                          key={`child-${key}`}
                          item={child}
                          onSelect={() => handleItemSelect(child, key)}
                        />
                      ))}
                  </React.Fragment>
                );
              })}
          </Command.Group>
        ))}
      </Command.List>
    </Command.Dialog>
  );
}

// Note (Noah, 2025-01-22): We use a stack here since there can be multiple
// levels of nested child command groups. Backspace pops off the stack, and the
// last item of the stack is the "current" one. Empty stack means the cmd-k modal
// is not shown at all
function useCommandMenuStack() {
  const location = useLocation();
  const [commandMenuStack, setCommandMenuStack] = React.useState<
    CommandMenuState[]
  >([]);

  const push = React.useCallback(
    (state: CommandMenuState) => {
      setCommandMenuStack([...commandMenuStack, state]);
    },
    [commandMenuStack],
  );

  const pop = React.useCallback(() => {
    setCommandMenuStack(commandMenuStack.slice(0, -1));
  }, [commandMenuStack]);

  const clear = React.useCallback(() => {
    setCommandMenuStack([]);
  }, []);

  // If the location changes,  clear the stack
  React.useEffect(() => {
    if (location) {
      clear();
    }
  }, [location, clear]);

  return {
    currentState: commandMenuStack[commandMenuStack.length - 1],
    isEmpty: commandMenuStack.length === 0,
    length: commandMenuStack.length,
    push,
    pop,
    clear,
  };
}

/**
 * Renders a single <Command.Item> for the given item. If the item has children,
 * this renders only the parent (children should be rendered separately).
 */
function SingleCommandListItem({
  item,
  onSelect,
}: {
  item: CommandItem;
  onSelect: () => void;
}) {
  return (
    <Command.Item onSelect={onSelect}>
      <div className="icon">
        <CommandIcon item={item} />
      </div>
      <div className="label">{item.label}</div>
      {item.hotkey && (
        <div className="ml-auto text-muted">{getHotkeyLabel(item.hotkey)}</div>
      )}
    </Command.Item>
  );
}

function CommandIcon({ item }: { item: CommandItem }) {
  if (item.icon) {
    return exhaustiveSwitch(item.icon)({
      iconComponent: (icon) => <icon.component size={16} />,
      publishedStatus: ({ isPublished }) => (
        <div className="w-[16px] h-[16px] flex items-center justify-center">
          <div
            className={twMerge(
              "w-[8px] h-[8px] rounded-full",
              isPublished ? "bg-green-500" : "bg-slate-400",
            )}
          />
        </div>
      ),
      create: () => <MdAdd size={16} />,
      custom: ({ content }) => {
        return <>{content}</>;
      },
    });
  }

  return <div className="w-[16px] h-[16px] bg-slate-200 rounded-full" />;
}

function useDebugCommandItems(): CommandGroupWithActions["items"] {
  const dispatch = useEditorDispatch();

  return {
    featureFlags: {
      label: "Feature Flags...",
      icon: { type: "iconComponent", component: MdFlag },
      type: "children",
      children: Object.fromEntries(
        featureFlags.map((flag) => {
          const { value } = flag;
          return [
            value,
            {
              label: `Set ${value} to ${isFeatureEnabled(value) ? "OFF" : "ON"} (Currently ${isFeatureEnabled(value) ? "ON" : "OFF"})`,
              icon: {
                type: "custom",
                content: (
                  <div className="w-[16px] h-[16px] flex items-center justify-center">
                    <div
                      className={twMerge(
                        "w-[8px] h-[8px] rounded-full",
                        isFeatureEnabled(value)
                          ? "bg-green-500"
                          : "bg-slate-400",
                      )}
                    />
                  </div>
                ),
              },
              onSelect: () => {
                if (isFeatureEnabled(value)) {
                  disableFeatureFlag(value);
                } else {
                  enableFeatureFlag(value);
                }
              },
            },
          ];
        }),
      ),
    },
    toggleDebugPanel: {
      label: "Toggle Debug Panel",
      icon: { type: "custom", content: <div>🐛</div> },
      onSelect: () => {
        dispatch(toggleDebugPanelVisibility());
      },
    },
  };
}

function useCommandMenuItems(): CommandGroupWithActions[] {
  const commandMenuItems = useEditorSelector(selectCommandMenuItems);
  const createMenuItems = useCreateMenuItems();
  const pagesMenuItems = usePagesMenuItems();
  const dynamicMenuItems = useDynamicCommandMenuItems();
  const debugCommandItems = useDebugCommandItems();

  const isDebugMode = useIsDebugMode();

  const menuItems = commandMenuItems
    .map((group) => {
      return {
        ...group,
        items: appendDynamicItemsToGroup(
          group.items,
          dynamicMenuItems,
          group.id,
        ),
      };
    })
    .concat([
      {
        id: "create",
        heading: "Create",
        items: appendDynamicItemsToGroup(
          createMenuItems,
          dynamicMenuItems,
          "create",
        ),
      },
      {
        id: "navigate",
        heading: "Navigate to...",
        items: appendDynamicItemsToGroup(
          pagesMenuItems,
          dynamicMenuItems,
          "navigate",
        ),
      },
    ]);

  if (isDebugMode) {
    menuItems.push({
      id: "debug",
      heading: "Debug",
      items: debugCommandItems,
    });
  }

  return menuItems;
}

function useCreateMenuItems(): CommandGroupWithActions["items"] {
  const { handleCreateElement } = useCreateElement();

  const items: CommandGroupWithActions["items"] = {};

  for (const type of allElementTypes) {
    items[`${type}-blank`] = {
      label: `Create blank ${elementTypeToEditorData[type].singularDisplayName.toLowerCase()}`,
      icon: { type: "create" },
      onSelect: () => {
        void handleCreateElement({ type });
      },
    };
  }

  return items;
}

function usePagesMenuItems(): CommandGroupWithActions["items"] {
  const navigate = useNavigate();
  const { project } = useCurrentProjectContext();
  const setDraftElement = useSetDraftElement();
  const projectId = project?.id;
  const pages = useElementsMetadataByType("page");

  return Object.fromEntries(
    pages.map((page) => {
      return [
        page.id,
        {
          label: page.name,
          icon: {
            type: "publishedStatus",
            isPublished: page.isPublished ?? false,
          },
          onSelect: () => {
            setDraftElement({
              componentIds: [],
            });
            navigate(
              generatePath(routes.editor.element, {
                projectId,
                elementId: page.id,
              }),
            );
          },
        },
      ];
    }),
  );
}

function appendDynamicItemsToGroup(
  items: CommandGroupWithActions["items"],
  dynamicItems: Record<string, DynamicCommandItem>,
  groupId: string,
): CommandGroupWithActions["items"] {
  return Object.assign(
    items,
    Object.fromEntries(
      Object.entries(dynamicItems).filter(([, item]) => item.group === groupId),
    ),
  );
}

const getHotkeyLabel = (hotkey: HotkeyIndicatorCharacter[]) => {
  return hotkey.map((character) => {
    const metaKeyLabel = HotkeyMetaKeyToLabel[character as HotkeyMetaKey];
    if (!metaKeyLabel) {
      return character.toUpperCase();
    }

    if (typeof metaKeyLabel === "string") {
      return metaKeyLabel;
    }

    return isMacintosh() ? metaKeyLabel.mac : metaKeyLabel.windows;
  });
};
