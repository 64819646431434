import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { ComponentConfig } from "../../components";

const styleElements = {
  root: {},
} satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    ancestorAllow: {
      ancestorTypes: ["product"],
      message:
        "Dynamic Checkout Buttons components may only be placed inside a Product box.",
    },
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
