import type {
  TabsContentOption,
  TabsTriggerOption,
} from "@replo/design-system/components/tabs/Tabs";
import type { BrandDetailsTab } from "./BrandDetailsContext";

import * as React from "react";

import { analytics } from "@editor/infra/analytics";

import { Tabs } from "@replo/design-system/components/tabs/Tabs";

import BrandContextEditor from "./BrandContextEditor";
import { useBrandDetailsTabs } from "./BrandDetailsContext";
import SavedStylesEditor from "./SavedStylesEditor";

const BRAND_DETAILS_TABS: TabsTriggerOption[] = [
  { value: "styles", label: "Styles" },
  { value: "context", label: "Context" },
];

const BRAND_DETAILS_CONTENT: TabsContentOption[] = [
  { value: "styles", tabContent: <SavedStylesEditor /> },
  { value: "context", tabContent: <BrandContextEditor /> },
];

const BrandDetailsPane: React.FC = () => {
  const { selectedTab, setSelectedTab } = useBrandDetailsTabs();

  const onChange = (value: string) => {
    const tab = value as BrandDetailsTab;
    setSelectedTab(tab);
    analytics.logEvent("brandDetails.tabSelected", {
      tab,
    });
  };

  return (
    <div className="flex h-fit flex-col gap-y-2 overflow-x-hidden [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] p-3 pt-2 pb-0 min-h-0">
      <Tabs
        defaultValue={selectedTab}
        value={selectedTab}
        onValueChange={onChange}
        tabsOptions={BRAND_DETAILS_TABS}
        contentOptions={BRAND_DETAILS_CONTENT}
      />
    </div>
  );
};

export default BrandDetailsPane;
