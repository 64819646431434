import React from "react";

import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import { Smartphone, TvMinimal } from "lucide-react";

interface PreviewPlatformToggleGroupProps {
  previewPlatform: "mobile" | "desktop";
  setPreviewPlatform: (platform: "mobile" | "desktop") => void;
}

const PreviewPlatformToggleGroup = ({
  previewPlatform,
  setPreviewPlatform,
}: PreviewPlatformToggleGroupProps) => {
  return (
    <ToggleGroup
      size="base"
      selectedValue={previewPlatform}
      onChange={(value) => setPreviewPlatform(value as "mobile" | "desktop")}
      layoutClassName="w-18"
      options={[
        {
          value: "mobile",
          label: <Smartphone size={18} />,
        },
        {
          value: "desktop",
          label: <TvMinimal size={18} />,
        },
      ]}
    />
  );
};

export default PreviewPlatformToggleGroup;
