import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { AlchemyActionType } from "../../../shared/enums";
import type { ComponentConfig } from "../../components";

const styleElements = {
  root: {},
} as const satisfies StyleElements;

const actionTypes = [
  "toggleCollapsible",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: true,
    styleElements,
    actionTypes,
  },
} satisfies ComponentConfig;

export default config;
