import type { Flow } from "schemas/generated/flow";

import { trpc, trpcUtils } from "@editor/utils/trpc";

import { useNavigate, useSearchParams } from "react-router-dom";

export const useRouterFlowCallbacks = () => {
  const { refetch } = trpc.project.listWithStats.useQuery({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleResponseNavigation = async ({
    nextStep,
    flowSlug,
  }: {
    nextStep: string | null;
    flowSlug: Flow["slug"];
  }) => {
    if (nextStep) {
      return navigate({
        pathname: `/flows/${flowSlug}/${nextStep}`,
        search: searchParams.toString(),
      });
    }

    const data = await refetch();
    const projects = data?.data?.projects ?? [];

    // NOTE (Fran 2024-02-22): If the user abandons the flow and returns later,
    // the browser's local storage is likely cleaned up. In that case, we should find the
    // first project created owned by the user and redirect to it.
    const firstProjectIdFromUserProjects = projects[0]?.id;
    const projectId = firstProjectIdFromUserProjects;
    const pendingAppInstallationId = searchParams.get(
      "pendingAppInstallationId",
    );

    void trpcUtils.project.listWithStats.invalidate();

    if (!projectId || pendingAppInstallationId) {
      // NOTE (Matt 2024-09-30): we invalidate `findByUserId` before navigating to ensure
      // that if a user is taken to the pendingAppInstallation connection flow, their
      // newly created project is included in the available projects list.
      void trpcUtils.project.findByUserId.invalidate();
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();

      return navigate({
        pathname: "/home",
        search: searchParams.toString(),
      });
    }
    navigate(`/editor/${projectId}/new`);
  };

  const submitOrSkipStepCallback = ({
    nextStep,
    flowSlug,
  }: {
    nextStep: string | null;
    flowSlug: Flow["slug"];
  }) => {
    void handleResponseNavigation({ nextStep, flowSlug });
  };

  const goBackCallback = (
    currentFlowSlug: string | null,
    previousStepId: string,
  ) => {
    navigate({
      pathname: `/flows/${currentFlowSlug}/${previousStepId}`,
      search: searchParams.toString(),
    });
  };

  return { submitOrSkipStepCallback, goBackCallback };
};
