import type { Hotkey } from "@editor/utils/hotkeys";

import * as React from "react";

import { getHotkeyData } from "@editor/utils/hotkeys";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

// Note (Reinaldo, 2022-08-25): This is mostly from https://mantine.dev/hooks/use-hotkeys/
// with a few modifications to accommodate our use cases

function shouldFireEventBasedOnElement(event: KeyboardEvent) {
  if (event.target instanceof HTMLElement) {
    return (
      !event.target.isContentEditable &&
      !["INPUT", "TEXTAREA", "SELECT"].includes(event.target.tagName)
    );
  }
  return true;
}

function useHotkeys(hotkeys: HotkeyItem[], target?: any) {
  const listeners = React.useMemo(
    () =>
      hotkeys.map(([hotkeyAction, handler, options]) => {
        const { controlStrings, ignoresModifiersOnKeyup, triggerInTextInputs } =
          getHotkeyData(hotkeyAction);

        return {
          listener: (event: KeyboardEvent) => {
            controlStrings.forEach((controlString) => {
              if (
                getHotkeyMatcher(
                  controlString,
                  ignoresModifiersOnKeyup,
                )(event) &&
                (triggerInTextInputs || shouldFireEventBasedOnElement(event)) &&
                (options?.filter?.(event) ?? true) &&
                // by default ignore keyup and runs on keydown
                (event.type === "keydown"
                  ? options?.keydown ?? true
                  : options?.keyup ?? false)
              ) {
                event.preventDefault();
                handler(event);
              }
            });
          },
          target: options?.disallowWhenDialogsAndPopoversOpen
            ? "documentElement"
            : "body",
        } as const;
      }),
    [hotkeys],
  );

  const getTargetElement = React.useCallback(
    (listenerTarget: "documentElement" | "body") => {
      if (target) {
        return target;
      }
      // Note (Noah, 2024-11-15, REPL-14680): Apparently Radix UI adds some sort of event
      // listener to the body which stops propagation when popovers/modals are open, so doing
      // documentElement here will break hotkeys if there's a popover open. Have to do
      // body instead, wish I was kidding
      return exhaustiveSwitch({ type: listenerTarget })({
        body: window.document.body,
        documentElement: window.document.documentElement,
      });
    },
    [target],
  );

  React.useEffect(() => {
    listeners.forEach(({ listener, target: listenerTarget }) => {
      const targetElement = getTargetElement(listenerTarget);
      targetElement.addEventListener("keydown", listener);
      targetElement.addEventListener("keyup", listener);
    });

    return () => {
      listeners.forEach(({ listener, target: listenerTarget }) => {
        const targetElement = getTargetElement(listenerTarget);
        targetElement.removeEventListener("keydown", listener);
        targetElement.removeEventListener("keyup", listener);
      });
    };
  }, [listeners, getTargetElement]);
}

type Hotkeys = {
  [key in Hotkey]?:
    | ((event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => void)
    | [
        (event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => void,
        HotkeyItemOptions,
      ];
};

export function useReploHotkeys(hotkeys: Hotkeys, target?: any) {
  const _hotkeys = Object.keys(hotkeys).map((hotkey) => {
    const hotkeyAction = hotkeys[hotkey as Hotkey];

    const fn = Array.isArray(hotkeyAction) ? hotkeyAction[0] : hotkeyAction;
    const options = Array.isArray(hotkeyAction) ? hotkeyAction[1] : undefined;
    return [hotkey, fn, options] as HotkeyItem;
  });

  useHotkeys(_hotkeys, target);
}

type KeyboardModifiers = {
  alt: boolean;
  ctrl: boolean;
  meta: boolean;
  mod: boolean;
  shift: boolean;
};

type KeyboardModifiersWithKey = KeyboardModifiers & {
  key?: string;
};

type CheckHotkeyMatch = (event: KeyboardEvent) => boolean;

function parseHotkey(hotkey: string): KeyboardModifiersWithKey {
  const keys = hotkey
    .toLowerCase()
    .split("+")
    .map((part) => part.trim());

  const modifiers: KeyboardModifiers = {
    alt: keys.includes("alt"),
    ctrl: keys.includes("ctrl"),
    meta: keys.includes("meta"),
    mod: keys.includes("mod"),
    shift: keys.includes("shift"),
  };

  const reservedKeys = new Set(["alt", "ctrl", "meta", "shift", "mod"]);

  const freeKey = keys.find((key) => !reservedKeys.has(key));

  return {
    ...modifiers,
    key: freeKey,
  };
}

// NOTE (Sebas, 2024-06-18): This is a mapping for converting combined keys to their
// respective pressed key. For example, if the user presses "¡", the keydown event will
// have the key "1", so we need to convert "¡" to "1" to match the hotkey.
const optionSymbolKeyToNumberKey = {
  "¡": "1",
  "™": "2",
  "£": "3",
  "¢": "4",
  "∞": "5",
  "§": "6",
  "¶": "7",
  "•": "8",
  "≤": ",",
  "≥": ".",
  "¯": ",",
  "˘": ".",
  ø: "o",
};

/**
 * Return whether the given event represents the given hotkey. The event could be
 * any keyboard event, but if ignoresModifiersOnKeyup is passed, then if the event
 * is a keyup, this function returns true even if the modifiers don't match the hotkey.
 * This is useful for hotkeys that are uses as "toggles", where keydown enables something
 * and keyup disables it - in this case, we want to ignore the modifiers on keyup so
 * that things like pressing shift before lifting the key still disables.
 */

function isExactHotkey(
  hotkey: KeyboardModifiersWithKey,
  ignoresModifiersOnKeyup: boolean,
  event: KeyboardEvent,
): boolean {
  const {
    alt: expectedAlt,
    ctrl: expectedCtrl,
    meta: expectedMeta,
    mod: expectedMod,
    shift: expectedShift,
    key: expectedKey,
  } = hotkey;
  const { altKey, ctrlKey, metaKey, shiftKey, key, type } = event;

  const isKeyup = type === "keyup";
  const shouldIgnoreModifiers = isKeyup && ignoresModifiersOnKeyup;

  const pressedKey =
    optionSymbolKeyToNumberKey[
      key as keyof typeof optionSymbolKeyToNumberKey
    ] ?? key;

  const isAltMatch = expectedAlt === altKey || shouldIgnoreModifiers;
  let isCtrlMatch = true;
  let isMetaMatch = true;
  if (!shouldIgnoreModifiers) {
    if (expectedMod) {
      // If we're looking to match "mod", we'll match the hotkey if either ctrl or meta is pressed,
      // since different operating systems implement mod with either ctrl or meta
      isCtrlMatch = ctrlKey || metaKey;
      isMetaMatch = ctrlKey || metaKey;
    } else {
      isCtrlMatch = ctrlKey === expectedCtrl;
      isMetaMatch = metaKey === expectedMeta;
    }
  }
  const isShiftMatch = expectedShift === shiftKey || shouldIgnoreModifiers;
  const normalizedKeyCode = event.code?.replace("Key", "") ?? "";
  if (!expectedKey || !pressedKey) {
    return false;
  }

  const keyPropertyMatches =
    pressedKey.toLowerCase() === expectedKey.toLowerCase();
  const normalizedKeyCodeMatches =
    normalizedKeyCode.toLowerCase() === expectedKey.toLowerCase();

  const isKeyMatch = keyPropertyMatches || normalizedKeyCodeMatches;

  return isAltMatch && isCtrlMatch && isMetaMatch && isShiftMatch && isKeyMatch;
}

function getHotkeyMatcher(
  hotkey: string,
  ignoresModifiersOnKeyup: boolean,
): CheckHotkeyMatch {
  return (event) =>
    isExactHotkey(parseHotkey(hotkey), ignoresModifiersOnKeyup, event);
}

type HotkeyItemOptions = {
  filter?: (event: KeyboardEvent) => boolean;
  keyup?: boolean;
  keydown?: boolean;
  disallowWhenDialogsAndPopoversOpen?: boolean;
};

type HotkeyItem = [
  Hotkey,
  (event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => void,
  HotkeyItemOptions?,
];
