import type { ReploElement } from "schemas/generated/element";

import * as React from "react";
import { useEffect } from "react";

import { CommandMenu } from "@components/CommandMenu";
import { FullScreenLoader } from "@components/common/FullScreenLoader";
import { EditorNoAccessToProjectScreen } from "@components/editor/EditorNoAccessToProjectScreen";
import GlobalHotkeysListener from "@components/GlobalHotkeysListener";
import { RichTextComponentProvider } from "@components/RichTextComponentContext";
import { RIGHT_BAR_WIDTH } from "@editor/components/editor/constants";
import { DebugLeftPanel } from "@editor/components/editor/debug/DebugPanel";
import { SrcDocFontLoader } from "@editor/components/editor/SrcDocFontLoader";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { EditorPerformanceProvider } from "@editor/contexts/editor-performance.context";
import { useSubscriptionDetails } from "@editor/hooks/subscription";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { usePrefetchBillingInfoImmediately } from "@editor/hooks/usePrefetchImmediately";
import { useDraftElementProductsIds } from "@editor/hooks/useStoreProducts";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { docs } from "@editor/utils/docs";
import { routes } from "@editor/utils/router";
import FigmaImportPanel from "@editorComponents/FigmaImportPanel";
import TemplateProductSelectorWrapper from "@editorComponents/TemplateProductSelector";
import useCurrentUser from "@hooks/useCurrentUser";
import useFetchDraftElement from "@hooks/useFetchDraftElement";
import useFetchMetafields from "@hooks/useFetchMetafields";
import useRightBarVisibility from "@hooks/useRightBarVisibility";
import { initProjectBasedAnalytics } from "@infra/analytics";
import { DragAndDropProvider } from "@providers/DragAndDropProvider";
import {
  selectInternalDebugModeOn as selectDevDebugPanelVisibility,
  selectDraftElementType,
  selectProject,
} from "@reducers/core-reducer";
import {
  selectLeftBarActiveTab,
  selectLeftBarWidth,
  setLeftBarActiveTab,
} from "@reducers/ui-reducer";

import { ToastManager } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { shallowEqual } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import ArchivedElementsPane from "../components/modals/ArchivedElementsPane";
import { useLogAnalytics } from "../hooks/useLogAnalytics";
import { useSyncDraftElementWithRouter } from "../hooks/useSyncDraftElementWithRouter";

const PRODUCTS_LIMIT = 20;
const elementTypeToLabel: Record<ReploElement["type"], string> = {
  page: "page",
  shopifyArticle: "article",
  shopifyProductTemplate: "product template",
  shopifySection: "Shopify section",
};

interface EditorAppProps {
  toRenderWithinEditor?: React.ReactNode;
  toRenderOverEditor?: React.ReactNode;
  projectHeader?: React.ReactNode;
}

export function EditorApp({
  toRenderWithinEditor,
  toRenderOverEditor,
  projectHeader,
}: EditorAppProps) {
  const { isLoading: isUserLoading, isAuthenticated } = useCurrentUser();
  const {
    project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useCurrentProjectContext();

  const isDebugPanelVisible = useEditorSelector(selectDevDebugPanelVisibility);

  useFetchDraftElement();
  useSyncDraftElementWithRouter();
  useTrackGroupAnalytics();
  usePrefetchBillingInfoImmediately();
  useSettingsNavigationEffect();

  const errorToast = useErrorToast();

  const isUserNotAuthenticated = !isUserLoading && !isAuthenticated;

  const projectNotFound = Boolean(
    !isProjectLoading && projectError?.meta.response?.status == 404,
  );

  /**
   * If we've tried to load the store, and it's a permission-denied error, then
   * we should show the 'no-access' screen
   */
  const noAccessToProject =
    !isProjectLoading && projectError?.meta.response?.status == 403;

  React.useEffect(() => {
    const modeSuffix =
      window.__BUILD_MODE__ === "development"
        ? " - Replo Dev Mode"
        : " - Replo";
    document.title = `${project?.name ?? "Dashboard"}${modeSuffix}`;
  }, [project]);

  if (isUserNotAuthenticated) {
    return <LoginModal />;
  }

  if (projectNotFound) {
    errorToast(
      "Project not found",
      "Please refresh or reach out to support@replo.app if you think this is a mistake.",
      "error.project.not_found",
      { projectError },
    );
    return <Navigate to={routes.home.root} replace />;
  }

  return (
    <div id="app" className="h-screen w-full bg-gray-200">
      <SupportChatBubbleStyles />
      {noAccessToProject ? (
        <EditorNoAccessToProjectScreen />
      ) : (
        <EditorPerformanceProvider>
          <DndProvider backend={HTML5Backend} context={window}>
            <DragAndDropProvider>
              <MetafieldsAutoFetcher />
              <SrcDocFontLoader />
              {(isUserLoading || isProjectLoading) && <FullScreenLoader />}
              <RichTextComponentProvider>
                {!isProjectLoading && toRenderWithinEditor}
                <CommandMenu />
                <GlobalHotkeysListener />
                <ToastWrapper />
                <div
                  className="flex h-full w-full flex-col overflow-hidden"
                  style={{ zIndex: 2_147_483_649 }}
                >
                  {projectHeader}
                  <div className="flex w-full flex-1 overflow-hidden">
                    {toRenderOverEditor}
                    {isDebugPanelVisible && <DebugLeftPanel />}
                    <TemplateProductSelectorWrapper />
                    <FigmaImportPanel />
                    <GlobalModals />
                    <ArchivedElementsPane />
                  </div>
                </div>
              </RichTextComponentProvider>
            </DragAndDropProvider>
          </DndProvider>
        </EditorPerformanceProvider>
      )}
    </div>
  );
}

function LoginModal() {
  const navigate = useNavigate();

  return (
    <div id="app" className="h-screen w-full bg-blue-200">
      <Modal
        onOpenChange={() => {}}
        isOpen={true}
        size="base"
        title="Your Replo session has expired. Please log in again."
        description="If you think this is a mistake, please reach out to us at support@replo.app"
        footer={
          <Button
            variant="primary"
            size="base"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            Log In
          </Button>
        }
      />
    </div>
  );
}

// Wrapper components to prevent unnecessary re-renders on EditorApp
function ToastWrapper() {
  const isRightBarVisible = useRightBarVisibility();
  const rightOffset = isRightBarVisible ? RIGHT_BAR_WIDTH : 0;
  return (
    <ToastManager
      containerStyle={rightOffset ? { right: rightOffset } : undefined}
    />
  );
}

/**
 * Component which registers hooks to auto-fetch metafield values when needed and
 * returns nothing. This is in its own component rather than in EditorApp because
 * we don't want changes in the products which we need to fetch metafields for to
 * have to rerender the entire React tree
 */
function MetafieldsAutoFetcher() {
  useFetchMetafields({ type: "variant" });
  useFetchMetafields({ type: "product" });
  return null;
}

function GlobalModals() {
  return <TooManyProductsModalWrapper />;
}

function TooManyProductsModalWrapper() {
  const elementType = useEditorSelector(selectDraftElementType);
  const readableElementType =
    elementTypeToLabel[elementType] ?? elementTypeToLabel["page"];
  const productIds = useDraftElementProductsIds();
  const productsCount = new Set(productIds.map((id) => String(id))).size;
  const [modalAlreadyShown, setModalAlreadyShown] = React.useState(false);
  const isOverLimit = productsCount > PRODUCTS_LIMIT;

  return (
    isOverLimit &&
    !modalAlreadyShown && (
      <Modal
        isOpen
        onOpenChange={() => {
          setModalAlreadyShown(true);
        }}
        size="sm"
        title="Too many Shopify products"
        description={`${productsCount}/${PRODUCTS_LIMIT} referenced products`}
        footer={
          <div className="flex flex-row gap-2">
            <Button
              variant="link"
              type="button"
              to={docs.shopifyMaxProductsOnPage}
              target="_blank"
              rel="noreferrer"
              size="base"
            >
              Learn More
            </Button>
            <Button
              variant="primary"
              type="button"
              size="base"
              onClick={() => {
                setModalAlreadyShown(true);
              }}
            >
              Continue
            </Button>
          </div>
        }
      >
        <p className="typ-body-small text-default">
          Your {readableElementType} references more than {PRODUCTS_LIMIT}{" "}
          products, which is a Shopify limit. You'll have to remove a product in
          order to publish successfully.
        </p>
      </Modal>
    )
  );
}

function useTrackGroupAnalytics() {
  const { user } = useCurrentUser();
  const project = useEditorSelector(selectProject, shallowEqual);
  const { data: subscriptionDetails } = useSubscriptionDetails();

  React.useEffect(() => {
    if (project && user) {
      initProjectBasedAnalytics(project, subscriptionDetails ?? undefined);
    }
  }, [project, user, subscriptionDetails]);
}

function useSettingsNavigationEffect() {
  const location = useLocation();
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();

  useEffect(() => {
    const state = location.state as { openSettings?: boolean };

    if (state?.openSettings) {
      logEvent("editor.panel.toggle", {
        toggle: "settings",
        isOpened: true,
        openedWithShortcut: false,
      });
      dispatch(setLeftBarActiveTab("settings"));
      // Clear the navigation state after handling it
      window.history.replaceState({}, "");
    }
  }, [location.state, dispatch, logEvent]);
}

const SupportChatBubbleStyles = () => {
  const isLeftBarOpened = Boolean(useEditorSelector(selectLeftBarActiveTab));
  const leftBarWidth = useEditorSelector(selectLeftBarWidth); // Fetching the current width of the left bar

  const supportChatPosition = isLeftBarOpened ? leftBarWidth + 51 : 51;

  const editorStyles = `
    .PylonChat-bubbleFrameContainer {
      position: fixed;
      z-index: 2147480002;
      bottom: 16px;
      left: ${supportChatPosition}px;
    }

    .PylonChat-chatWindowFrameContainer {
      bottom: 76px !important;
      left: ${supportChatPosition + 15}px !important;
      right: 0px;
      transform-origin: bottom left !important;
    }
  `;

  return <style dangerouslySetInnerHTML={{ __html: editorStyles }} />;
};
