import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const StaySubscriptionWidget: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const liquid = `
    {% render 'rtx-pdp', product: product %}
  `;

  return (
    <SharedShopifyLiquid
      liquidSource={liquid}
      componentId={component.id}
      componentAttributes={componentAttributes}
      title="Stay Ai Subscription"
      placeholderType="productOptions"
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
    />
  );
};

export default StaySubscriptionWidget;
