import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";

import Button from "@replo/design-system/components/button/Button";

type FlowActionButtonsProps = {
  shouldDisableNextButton?: boolean;
  hideBackButton?: boolean;
  handleSkip?: () => void;
  customLabels?: {
    nextButton?: string;
    backButton?: string;
    skipButton?: string;
  };
};

const FlowActionButtons: React.FC<FlowActionButtonsProps> = ({
  shouldDisableNextButton = false,
  hideBackButton = false,
  handleSkip,
  customLabels,
}) => {
  const { isSubmitting, goBack } = useReploFlowsStepContext();

  return (
    <div className="flex flex-1 self-end justify-self-end items-end">
      <div className="flex flex-row gap-2 items-center w-full">
        {handleSkip && (
          <Button
            variant="tertiary"
            size="base"
            disabled={isSubmitting}
            onClick={handleSkip}
          >
            {customLabels?.skipButton ?? "Skip"}
          </Button>
        )}
        {!hideBackButton && (
          <Button
            variant="secondary"
            size="base"
            disabled={isSubmitting}
            onClick={() => goBack()}
          >
            {customLabels?.backButton ?? "Back"}
          </Button>
        )}
        <Button
          variant="primary"
          size="base"
          type="submit"
          isLoading={isSubmitting}
          disabled={shouldDisableNextButton}
        >
          {customLabels?.nextButton ?? "Next"}
        </Button>
      </div>
    </div>
  );
};

export default FlowActionButtons;
