import type { ReploEditableElementWithId } from "@editor/utils/element";

import * as React from "react";

import Separator from "@components/common/designSystem/Separator";
import ArticleElementEditor from "@components/editor/elementEditors/ArticleElementEditor";
import PageElementEditor from "@components/editor/elementEditors/PageElementEditor";
import ProductTemplateElementEditor from "@components/editor/elementEditors/ProductTemplateElementEditor";
import SectionElementEditor from "@components/editor/elementEditors/SectionElementEditor";
import { ElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import {
  useElementEditorErrorContext,
  withElementEditorErrorContext,
} from "@editor/contexts/ElementEditorErrorContext";
import { useEditableElement } from "@editor/hooks/useEditableElement";
import { elementFormHasErrors } from "@editor/utils/element";

import Button from "@replo/design-system/components/button/Button";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import { useParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

const UpdateElementPane = withElementEditorErrorContext(() => {
  const { elementId } = useParams();
  const {
    element,
    onChangeElement,
    validateElement,
    isUpdatingElement,
    updateElementMetadata,
  } = useEditableElement({
    elementId,
  });

  const { errorMapping, setErrors, clearErrors } =
    useElementEditorErrorContext();

  if (!element) {
    return (
      <div className="h-full flex flex-col items-center justify-center p-4">
        <Spinner variant="primary" size={24} />
      </div>
    );
  }

  function onSubmit() {
    clearErrors("path", "allErrorsFromAllFormKeys");

    const { isValid, errors: validationErrors } = validateElement(element!);
    if (!isValid) {
      if (validationErrors.title.length > 0) {
        setErrors("title", validationErrors.title);
      }
      if (validationErrors.path.length > 0) {
        setErrors("path", validationErrors.path);
      }
      return;
    }

    void updateElementMetadata(element as ReploEditableElementWithId);
  }

  return (
    <ElementEditorDataContext.Provider value={{ element, onChangeElement }}>
      <div className="h-full flex flex-col">
        {/* NOTE (Cole, 2025-02-06): The scrollbar stuff is here to hide the scrollbar across major browsers without needing a tailwind plugin */}
        <div className="overflow-x-hidden [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] p-3 pt-0 min-h-0 h-full">
          {exhaustiveSwitch(element)({
            page: () => <PageElementEditor />,
            shopifyArticle: () => <ArticleElementEditor />,
            shopifyProductTemplate: () => <ProductTemplateElementEditor />,
            shopifySection: () => <SectionElementEditor requestType="update" />,
          })}
        </div>

        <div className="sticky bottom-0 bg-white">
          <div className="w-full px-3 pt-2 pb-1.5">
            <Separator />
          </div>

          <div className="p-3">
            <Button
              size="base"
              variant="primary"
              type="submit"
              isLoading={isUpdatingElement}
              onClick={onSubmit}
              disabled={elementFormHasErrors(errorMapping)}
            >
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    </ElementEditorDataContext.Provider>
  );
});

export default UpdateElementPane;
