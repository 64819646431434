import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "../../components";

import { mapAndConvertComponentStylePropsToStyle } from "../../../shared/utils/breakpoints";
import { convertToLegacyProps } from "../../../shared/utils/props";

const customProperties = {
  svgWidth: "--rsw",
};

export const styleElements = {
  root: {
    defaultStyles: {
      lineHeight: "100%",
    },
    overrideStyles({ component, styleProps }) {
      const size = component.props.size;
      return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
        const onlyHeightAvailable = styles.__hasHeight && !styles.__hasWidth;
        const svgWidth = onlyHeightAvailable ? "auto" : "100%";

        return {
          width: styles.width ?? size,
          height: styles.height ?? size,
          [customProperties.svgWidth]: svgWidth,
        };
      });
    },
  },
  icon: {
    defaultStyles: {
      width: `var(${customProperties.svgWidth}, 100%)`,
      height: "100%",
    },
  },
} satisfies StyleElements;

function getConfigurableProps(): CustomPropsRecord {
  return {
    _accessibilityHidden: {
      type: "boolean",
      name: "Accessibility Hidden",
      description:
        "If set, removes this component from the accessibility tree, hiding it from screen readers.",
      defaultValue: null,
    },
    _accessibilityLabelledBy: {
      type: "selectable",
      name: "Accessibility Labelled By",
      description:
        "If you select a Text component here, the Text component will be used to label this component in accessibility controls.",
      defaultValue: null,
      placeholder: "Select a component",
    },
    _accessibilityLabel: {
      type: "string",
      name: "Accessibility Label",
      description:
        "If provided, this will be used as the aria-label for this component.",
      defaultValue: undefined,
    },
  };
}

const config = {
  renderData: {
    showAccessibilityMenu: true,
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: ["width", "height"],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
