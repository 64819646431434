import type { EditorPropsRecord } from "replo-runtime/shared/utils/editorProps";
import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { AlchemyActionType } from "../../../shared/enums";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/props";

function getEditorProps(): EditorPropsRecord {
  return {
    activeTabIndex: {
      type: "index",
      title: "Active tab",
      description: "If set, the editor will show the tab with this index",
      defaultValue: 0,
    },
  };
}

function getConfigurableProps(): CustomPropsRecord {
  return {
    _items: {
      type: "dynamicItems",
      name: "Dynamic items",
      description: "If set, the tabs can use each item for display.",
      defaultValue: null,
    },
    _defaultTab: {
      type: "selectable",
      defaultValue: "0",
      name: "Default active tab",
      description: "Choose which tab will start as active",
      selectableValues: { type: "tabsV2__block" },
    },
  };
}

const styleElements = {
  root: {},
} as const satisfies StyleElements;

const listStyleElements = {
  root: {},
} as const satisfies StyleElements;

const panelStyleElements = {
  root: {},
} as const satisfies StyleElements;

const actionTypes = [
  "setActiveTabIndex",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    editorProps: getEditorProps(),
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    allowsLayoutModification: true,
    newInstancesUseNumbering: true,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    customProps: convertToLegacyProps(getConfigurableProps()),
    ancestorDisallowList: [
      {
        ancestorTypes: ["button"],
        message: "Tabs cannot be nested inside buttons.",
      },
      {
        ancestorTypes: ["marquee"],
        message: "Tabs cannot be nested inside tickers.",
      },
    ],
    dynamicItemsPropName: "_items",
    styleElements,
    variantTriggers: ["state.tabsV2Block.isCurrentTab"],
    actionTypes,
  },
  children: {
    panelsContent: {
      renderData: {
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => true,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        ancestorAllow: {
          ancestorTypes: ["tabsV2__block"],
          message:
            "Tabs Content components may only be placed inside Tabs Block",
        },
        ancestorDisallowList: [
          {
            ancestorTypes: ["tabsV2__list", "tabsV2__panelsContent"],
            message:
              "Tabs Content components cannot be dropped into other inner Tabs components",
          },
        ],
        canContainChildren: true,
        extractIsDynamicFromContext: (context) => {
          return Boolean(context?.state?.tabsV2Block?.isDynamic);
        },
        styleElements: panelStyleElements,
      },
    },
    list: {
      renderData: {
        newInstancesUseNumbering: false,
        allowsLayoutModification: true,
        acceptsArbitraryChildren: () => true,
        isAlwaysDynamic: false,
        canvasIndicatorDragDirections: [],
        ancestorAllow: {
          ancestorTypes: ["tabsV2__block"],
          message: "Tabs Menu components may only be placed inside Tabs Block",
        },
        ancestorDisallowList: [
          {
            ancestorTypes: ["tabsV2__panelsContent", "tabsV2__list"],
            message:
              "Tabs Menu components cannot be dropped into other inner Tabs components",
          },
        ],
        canContainChildren: true,
        extractIsDynamicFromContext: (context) => {
          return Boolean(context?.state?.tabsV2Block?.isDynamic);
        },
        styleElements: listStyleElements,
        childrenAllow: {
          childTypes: ["container"],
          message: "Tabs Menu components may only contain container components",
          directChildOnly: true,
        },
      },
    },
  },
} satisfies ComponentConfig;

export default config;
