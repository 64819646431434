import type { ReploAnnouncementTag } from "schemas/generated/announcement";

import { trpc } from "@editor/utils/trpc";

export const useListAnnouncements = ({
  tags,
  limit,
}: {
  tags?: ReploAnnouncementTag[];
  limit?: number;
}) => {
  const { data, isLoading } = trpc.announcement.list.useQuery(
    { tags, limit },
    {
      refetchOnMount: "always",
    },
  );
  const announcements = data?.announcements || [];

  return {
    announcements,
    isLoading,
  };
};
