import type {
  CustomPropDefinition,
  CustomPropsRecord,
} from "schemas/component";

export function convertToLegacyProps(
  props: CustomPropsRecord,
): CustomPropDefinition[] {
  return Object.entries(props).map(([key, value]) => {
    return {
      ...value,
      id: key,
    };
  });
}
