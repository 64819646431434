import type { ProductRequestType } from "@editor/hooks/useStoreProducts";
import type { ProductRef, ProductRefOrDynamic } from "schemas/product";

import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import {
  arrayMove,
  SortableItem,
  SortableList,
} from "@common/designSystem/SortableList";
import { DynamicDataSelector } from "@editor/components/editor/page/DynamicDataSelector";
import {
  ProductSelectionButton,
  ProductSelectionCombobox,
} from "@editor/components/editor/page/ProductSelectionCombobox";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectAncestorOrSelfWithProductCollectionType,
  selectAncestorOrSelfWithProductType,
  selectDraftComponentId,
  selectDraftElementType,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import DynamicDataValueIndicator from "@editorExtras/DynamicDataValueIndicator";

import twMerge from "@replo/design-system/utils/twMerge";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isContextRef } from "replo-runtime/store/ReploProduct";

type MultiProductProps = {
  isMultiProducts: true;
  onChange: (value: ProductRef[]) => void;
  selectedProductRef: ProductRef[] | null;
};

type SingleProductProps = {
  isMultiProducts?: false;
  onChange: (value: ProductRef | null) => void;
  selectedProductRef: ProductRefOrDynamic | null;
};

type ProductSelectorSharedProps = {
  excludedDynamicDataKeys?: string[];
  allowDynamicData?: boolean;
  productRequestType?: ProductRequestType;
  isVariantSelectable?: boolean;
  dataType?: "summary" | "full";
  className?: string;
  showDeleteButton?: boolean;
  triggerClassName?: string;
  noDynamicData?: boolean;
  selectionIndicatorClassName?: string;
  side?: "top" | "bottom" | "left" | "right";
  sideOffset?: number;
};

type SingleProductSelectorProps = SingleProductProps &
  ProductSelectorSharedProps;

type MultiProductSelectorProps = MultiProductProps & ProductSelectorSharedProps;

export function ProductSelector(
  props: SingleProductSelectorProps,
): React.ReactElement<any, any> | null;
export function ProductSelector(
  props: MultiProductSelectorProps,
): React.ReactElement<any, any> | null;

export function ProductSelector({
  selectedProductRef,
  allowDynamicData = true,
  onChange,
  isMultiProducts,
  excludedDynamicDataKeys,
  isVariantSelectable = false,
  className,
  side = "bottom",
  sideOffset = 4,
}: ProductSelectorSharedProps & (MultiProductProps | SingleProductProps)) {
  const modal = useModal();
  const isDynamicDataSelected =
    !isMultiProducts && selectedProductRef && isContextRef(selectedProductRef);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftElementType = useEditorSelector(selectDraftElementType);
  const productCollectionComponentAncestor = useEditorSelector(
    selectAncestorOrSelfWithProductCollectionType,
  );
  const productComponentAncestor = useEditorSelector(
    selectAncestorOrSelfWithProductType,
  );

  const selectedComponentIsProductCollection =
    productCollectionComponentAncestor &&
    productCollectionComponentAncestor.id === draftComponentId;
  const selectedComponentIsProduct =
    !productCollectionComponentAncestor &&
    productComponentAncestor &&
    productComponentAncestor.id === draftComponentId;

  const shouldExcludeProductKey = (() => {
    // Note (Sebas, 2022-11-25): If the selected component has no dynamic data and
    // if the current selected component is a productCollection or product we need
    // to exclude the _product key to prevent from showing on the dynamicDataModal.
    // This is necessary because if you manually select a product, and then try to
    // add dynamic data, the current selected product will show on the dynamicDataModal
    // but if you select it, it won't work.
    if (
      !isDynamicDataSelected &&
      (selectedComponentIsProductCollection || selectedComponentIsProduct)
    ) {
      return true;
    }

    // NOTE (Evan, 9/11/23) For product templates, we want to avoid showing both "Template Product" and "Current Product"
    // at the same time in the Dynamic Data selector (it's confusing). So we pursue the following strategy:
    // - hide "Current Product" for Product components (this happens here)
    // - hide "Template Product" for everything else (this happens in the useDynamicData hook)
    // This encourages the pattern of having the template product set for the Product component, then having
    // its children use "current product" to transitively reference the template product.
    if (
      selectedComponentIsProduct &&
      draftElementType === "shopifyProductTemplate"
    ) {
      return true;
    }

    return false;
  })();

  const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");

  const _openDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        excludedAttributeKeys: [
          ...(excludedDynamicDataKeys ?? []),
          ...(shouldExcludeProductKey ? ["_product"] : []),
        ],
        targetType: isVariantSelectable
          ? DynamicDataTargetType.PRODUCT_VARIANT
          : DynamicDataTargetType.PRODUCT,
        referrerData: {
          type: "callback",
          onChange: (value: any) => {
            onChange(value);
          },
        },
        initialPath:
          !isMultiProducts &&
          selectedProductRef &&
          isContextRef(selectedProductRef)
            ? getPathFromVariable(selectedProductRef.ref as string)
            : undefined,
      },
    });
  };

  if (
    !isMultiProducts &&
    selectedProductRef &&
    isContextRef(selectedProductRef)
  ) {
    if (isNewDynamicData) {
      return (
        <DynamicDataSelector
          targetType={
            isVariantSelectable
              ? DynamicDataTargetType.PRODUCT_VARIANT
              : DynamicDataTargetType.PRODUCT
          }
          initialPath={selectedProductRef.ref.split(".")}
          side="left"
          sideOffset={12}
          trigger={
            <DynamicDataValueIndicator
              type="other"
              templateValue={selectedProductRef.ref as string}
              onRemove={() => {
                onChange(null);
              }}
            />
          }
          onChange={(value) => {
            (onChange as (value: ProductRefOrDynamic) => void)(value);
          }}
        />
      );
    }
    return (
      <DynamicDataValueIndicator
        type="other"
        templateValue={selectedProductRef.ref as string}
        onClick={() => {
          _openDynamicData();
        }}
        onRemove={() => {
          if (!isMultiProducts) {
            onChange(null);
          }
        }}
      />
    );
  }

  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-between gap-2",
        className,
      )}
    >
      {isMultiProducts ? (
        <div className="w-full">
          <SortableList
            onReorderEnd={({ oldIndex, newIndex }) => {
              const reorderedList = arrayMove(
                (selectedProductRef ?? []) as ProductRef[],
                oldIndex,
                newIndex,
              );
              onChange(reorderedList);
            }}
            withDragHandle
          >
            {((selectedProductRef ?? []) as ProductRef[]).map(
              (productRef, index) => (
                <SortableItem
                  key={productRef?.id ?? `undefined-${index}`}
                  id={productRef?.id ?? `undefined-${index}`}
                >
                  <ProductSelectionCombobox
                    value={productRef ?? null}
                    onChange={(value) => {
                      const updatedProducts = [...(selectedProductRef ?? [])];
                      if (value) {
                        updatedProducts[index] = value;
                      } else {
                        updatedProducts.splice(index, 1);
                      }
                      onChange(updatedProducts);
                    }}
                    side={side}
                    sideOffset={sideOffset}
                    trigger={
                      <ProductSelectionButton
                        title={productRef?.title ?? "Select a product"}
                        // Prevent drag from triggering click
                        onMouseDown={(e) => e.stopPropagation()}
                        onRemove={() => {
                          const updatedProducts = [
                            ...(selectedProductRef ?? []),
                          ];
                          updatedProducts.splice(index, 1);
                          onChange(updatedProducts);
                        }}
                      />
                    }
                  />
                </SortableItem>
              ),
            )}
          </SortableList>
        </div>
      ) : (
        <ProductSelectionCombobox
          // Note (Noah, 2025-04-02): Typescript doesn't understand that this must not
          // be dynamic, even though we did a check for isContextRef above
          value={selectedProductRef as ProductRef | null}
          onChange={onChange}
          side={side}
          sideOffset={sideOffset}
        />
      )}
      {allowDynamicData && (
        <>
          {isNewDynamicData ? (
            <DynamicDataSelector
              side="left"
              sideOffset={222}
              targetType={
                isVariantSelectable
                  ? DynamicDataTargetType.PRODUCT_VARIANT
                  : DynamicDataTargetType.PRODUCT
              }
              trigger={<DynamicDataButton />}
              onChange={(value: any) => {
                onChange(value);
              }}
            />
          ) : (
            <DynamicDataButton
              onClick={(e) => {
                e.stopPropagation();
                _openDynamicData();
              }}
              tooltipText="Use dynamic value"
            />
          )}
        </>
      )}
    </div>
  );
}
