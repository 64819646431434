import type { ProductRef } from "schemas/product";

import { z } from "zod";

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum ItemsConfigType {
  dataTable = "dataTable",
  productImages = "productImages",
  inline = "inline",
  htmlAttributes = "htmlAttributes",
}

export type DataTablesItemsConfig = {
  type: ItemsConfigType.dataTable;
  id?: string | null;
};

type ProductImagesConfig = {
  type: ItemsConfigType.productImages;
  productRef?: ProductRef;
};

type InlineItemsValues =
  | {
      valueType: "string";
      values: { id: string; value: string | undefined }[] | null;
    }
  | {
      valueType: "dynamic";
      dynamicPath: string;
    };

export type InlineItemsConfig = {
  type: ItemsConfigType.inline;
} & InlineItemsValues;

type HTMLAttributeTypes = "dataset";

export type HTMLAttributesValues = {
  id: string;
  valueType: HTMLAttributeTypes;
  key: string | undefined;
  value: string | undefined;
};

export type HTMLAttributesConfig = {
  type: ItemsConfigType.htmlAttributes;
  values: HTMLAttributesValues[];
};
export type ItemsConfig =
  | DataTablesItemsConfig
  | ProductImagesConfig
  | InlineItemsConfig
  | HTMLAttributesConfig;

// NOTE (Matt 2025-03-03): Below are the updated Dynamic Data Prop types, or types related to the way
// Dynamic Data is defined as a prop in component JSON. The above types will be deprecated in the future.

const dateFormatOptionsSchema = z.discriminatedUnion("format", [
  z.object({
    format: z.literal("text"),
    includeYear: z.boolean(),
    includeWeekday: z.boolean(),
  }),
  z.object({
    format: z.literal("number"),
    order: z.enum(["month-first", "day-first"]),
    separator: z.enum(["-", "/"]),
    includeYear: z.boolean(),
  }),
]);

export type DateFormatOptions = z.infer<typeof dateFormatOptionsSchema>;

export type ReferenceType = "currency" | "date";

export const formatterSchema = z.discriminatedUnion("type", [
  z.object({
    // If this is present, apply the associated discount to the number
    // We currently only support selling plan discounts, but this can be
    // a place to put other discounts logic later
    type: z.literal("discount"),
    discountType: z.literal("selectedSellingPlan"),
  }),
  z.object({
    // If this is present, use the currently active currency to format (use existing formatting logic)
    type: z.literal("currency"),
  }),
  z.object({
    // If this is present, round the price to the nearest whole number
    type: z.literal("rounded"),
  }),
  z.object({
    type: z.literal("date"),
    dateOptions: dateFormatOptionsSchema,
  }),
]);

export type Formatter = z.infer<typeof formatterSchema>;
