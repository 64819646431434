import * as React from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { MenuItem } from "@replo/design-system/components/menu/MenuItem";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import { BsCheck } from "react-icons/bs";
import { animated, config, useTransition } from "react-spring";
import { areEqual, FixedSizeList as List } from "react-window";

export type Option = {
  label: React.ReactNode;
  searchValue?: string;
  value?: string | number | null;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode | string;
  isDefaultActive?: boolean;
  isSelectable?: boolean;
  isDisabled?: boolean;
  disabledMessage?: string | null;
  toolTip?: string | null;
  size?: "sm" | "base";
};

export type SelectedValue = string | number | null;

const ListItem = React.forwardRef<
  HTMLButtonElement,
  {
    item: Option;
    isActive: boolean;
    isMultiselect?: boolean;
    onHover?: (option: Option | null) => void;
    onSelect?: (value: string | number | null) => void;
    size?: "sm" | "base";
  }
>(({ item, isActive, isMultiselect, onHover, onSelect, size }) => {
  const {
    isSelectable = true,
    value,
    label,
    endEnhancer,
    startEnhancer,
    isDisabled,
    disabledMessage,
    toolTip,
  } = item;
  const itemRef = React.useRef<HTMLButtonElement>(null);

  const menuItemCheckbox = (
    <MenuItem
      ref={itemRef}
      variant="checkbox"
      selected={isActive}
      disabled={isDisabled}
      toolTipText={isDisabled ? disabledMessage : toolTip}
      onHover={() => onHover?.(item)}
      size={size}
      onClick={() => {
        if (isSelectable) {
          onSelect?.(value ?? null);
        }
      }}
    >
      {label}
    </MenuItem>
  );

  const customMenuItem = (
    <MenuItem
      ref={itemRef}
      disabled={isDisabled}
      toolTipText={isDisabled ? disabledMessage : toolTip}
      onHover={() => onHover?.(item)}
      size={size}
      startEnhancer={startEnhancer}
      endEnhancer={endEnhancer}
      variant="custom"
      onClick={() => {
        if (isSelectable) {
          onSelect?.(value ?? null);
        }
      }}
    >
      {label}
    </MenuItem>
  );

  const itemElement = isMultiselect ? menuItemCheckbox : customMenuItem;
  if (!isSelectable) {
    return <label className="w-full typ-body-small text-muted">{label}</label>;
  }
  return itemElement;
});
ListItem.displayName = "ListItem";

const Row: React.FC<{
  index: number;
  style: React.CSSProperties;
  filteredFormattedOptions: Option[];
  isActive: boolean;
  onSelect?(value: string | number | null): void;
  isMultiselect?: boolean;
  labelClassName?: string;
  onHover?: (option: Option | null) => void;
  size?: "sm" | "base";
}> = ({
  index,
  style,
  filteredFormattedOptions,
  onSelect,
  isActive,
  isMultiselect,
  onHover,
  size,
}) => {
  const item = filteredFormattedOptions[index]!;
  const itemRef = React.useRef<HTMLButtonElement>(null);

  const itemElement = (
    <ListItem
      ref={itemRef}
      item={item}
      isActive={isActive}
      isMultiselect={isMultiselect}
      onHover={onHover}
      onSelect={onSelect}
      size={size}
    />
  );

  return <div style={style}>{itemElement}</div>;
};

const getListHeight = (
  itemSize: number,
  totalItems: number,
  itemsOnViewCount?: number,
): number => {
  if (!itemsOnViewCount) {
    // Note (Mariano, 08-05-22): This is the default height for the list.
    // This magic number comes from the designs and has been in use before adding this logic
    return 260;
  }
  return Math.ceil(itemSize * Math.min(totalItems, itemsOnViewCount));
};

const Placeholder: React.FC<
  React.PropsWithChildren<{ style: React.CSSProperties }>
> = ({ children, ...props }) => {
  return (
    <div
      className="my-3 flex w-full items-center justify-center text-xs font-normal	text-muted"
      {...props}
    >
      {children}
    </div>
  );
};

export const RegularList: React.FC<{
  options: Option[];
  itemSize: number;
  onSelect?: (value: string | number | null) => void;
  itemsOnViewCount?: number;
  selectedItems: SelectedValue[];
  isMultiselect?: boolean;
  labelClassName?: string;
  noItemsPlaceholder?: React.ReactNode;
  totalItems?: number;
  onHover?: (option: Option | null) => void;
  size?: "sm" | "base";
}> = ({
  options,
  onSelect,
  itemSize,
  itemsOnViewCount,
  selectedItems,
  isMultiselect,
  labelClassName,
  noItemsPlaceholder,
  onHover,
  size,
}) => {
  const shouldRenderPlaceholder = Boolean(
    options.length === 0 && noItemsPlaceholder,
  );

  const itemData = React.useMemo(
    () => ({
      options,
      onSelect,
      shouldRenderPlaceholder,
      noItemsPlaceholder,
      isMultiselect,
      selectedItems,
      labelClassName,
      onHover,
      isItemLoaded: () => true,
      isLoadingFirstPage: false,
      size,
    }),
    [
      options,
      onSelect,
      shouldRenderPlaceholder,
      noItemsPlaceholder,
      isMultiselect,
      selectedItems,
      labelClassName,
      onHover,
      size,
    ],
  );

  return (
    // NOTE (Jackson, 12-10-24): Reset hovered item on leaving the list rather than
    // on each row.
    <div onMouseLeave={() => onHover?.(null)}>
      <List
        height={getListHeight(
          itemSize,
          shouldRenderPlaceholder ? 1 : options.length,
          itemsOnViewCount,
        )}
        itemCount={shouldRenderPlaceholder ? 1 : options.length}
        itemSize={shouldRenderPlaceholder ? 200 : itemSize}
        width="100%"
        className="no-scrollbar"
        itemData={itemData}
      >
        {MemoizedRow}
      </List>
    </div>
  );
};

// NOTE (Evan, 8/25/23) This is pretty annoying – in order to prevent each Row from re-mounting on change,
// we need a memoized version of the row so that it isn't recreated. This prevents things like REPL-8332,
// where every checkbox was resetting when any one of them was clicked.

type ItemData = {
  options: Option[];
  onSelect: ((value: string | number | null) => void) | undefined;
  shouldRenderPlaceholder: boolean;
  noItemsPlaceholder: React.ReactNode;
  isItemLoaded: (index: number) => boolean;
  isLoadingFirstPage: boolean;
  isMultiselect: boolean | undefined;
  selectedItems: SelectedValue[];
  labelClassName: string | undefined;
  onHover?: (option: Option | null) => void;
  size?: "sm" | "base";
};

const MemoizedRow = React.memo(
  ({
    data,
    index,
    style,
  }: {
    data: ItemData;
    index: number;
    style: React.CSSProperties;
  }) => {
    const {
      options,
      onSelect,
      shouldRenderPlaceholder,
      noItemsPlaceholder,
      isItemLoaded,
      isLoadingFirstPage,
      isMultiselect,
      selectedItems,
      labelClassName,
      onHover,
      size,
    } = data;

    if (shouldRenderPlaceholder) {
      return <Placeholder style={style}>{noItemsPlaceholder}</Placeholder>;
    }
    return !isItemLoaded(index) || isLoadingFirstPage ? (
      <div className="my-3 flex w-full justify-center" style={style}>
        <Spinner size={20} variant="primary" />
      </div>
    ) : (
      <Row
        index={index}
        style={style}
        isMultiselect={isMultiselect}
        isActive={selectedItems.includes(
          options[index]?.value as SelectedValue,
        )}
        filteredFormattedOptions={options}
        onSelect={onSelect}
        labelClassName={labelClassName}
        onHover={onHover}
        size={size}
      />
    );
  },
  areEqual,
);
MemoizedRow.displayName = "MemoizedRow";

type CheckboxSizes = "sm" | "lg";

type CheckboxProps = {
  isChecked: boolean;
  size?: CheckboxSizes;
  onCheckedChange?: (isChecked: boolean) => void;
};

// NOTE (Chance 2023-11-07): I moved this out of our `designSystem` directory
// because we actually don't use it anywhere else, and we have another Checkbox
// component in `common` with a completely different implementation that we use
// in a bunch of other places. We should figure out which one belongs in the DS
// and update accordingly.
const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ isChecked, size = "lg", onCheckedChange }, ref) => {
    const transitions = useTransition(isChecked, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.stiff,
    });
    const sizes: Record<CheckboxSizes, { box: string; icon: number }> = {
      sm: {
        box: "12px",
        icon: 12,
      },
      lg: {
        box: "20px",
        icon: 16,
      },
    };

    return (
      <CheckboxPrimitive.Root
        ref={ref}
        checked={isChecked}
        onCheckedChange={onCheckedChange}
        style={{
          all: "unset",
          width: sizes[size].box,
          height: sizes[size].box,
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: isChecked ? "#2563EB" : "white",
          border: "1px solid #2563EB",
          flexShrink: 0,
        }}
      >
        <CheckboxPrimitive.Indicator asChild forceMount>
          {transitions((styles, item) => {
            if (!item) {
              return null;
            }

            return (
              <animated.div
                style={{
                  opacity: styles.opacity,
                }}
              >
                <BsCheck color="white" size={sizes[size].icon} />
              </animated.div>
            );
          })}
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    );
  },
);
Checkbox.displayName = "Checkbox";
