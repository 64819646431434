import * as React from "react";

import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import Stepper from "@editorModifiers/Stepper";

type IntegerSelectorProps = {
  value: number;
  onChange: (newValue: number) => void;
  label?: string;
  min?: number;
  onClickDynamicData?(): void;
};

const isNewDynamicData = isFeatureEnabled("dynamic-data-refresh");

export const IntegerSelector = ({
  value,
  onChange,
  label,
  min = 1,
  onClickDynamicData,
}: IntegerSelectorProps) => {
  const content = (
    <div className="flex flex-row gap-2">
      <Stepper
        value={value}
        field="integerSelector"
        minValue={min}
        onChange={onChange}
      />
      {onClickDynamicData && !isNewDynamicData && (
        <DynamicDataButton onClick={onClickDynamicData} />
      )}
    </div>
  );
  if (label) {
    return (
      <LabeledControl label={label} size="sm">
        {content}
      </LabeledControl>
    );
  }
  return content;
};
