import type { FindUrlParamsQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import {
  createUrlParamsFilterOptions,
  OPERATOR_OPTIONS,
} from "@/features/analytics/moreFilters/constants";
import { findLabelForValue } from "@/features/analytics/moreFilters/utils";
import useFindUrlParams from "@/features/analytics/useFindUrlParams";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { useCreatableComboboxInputOptions } from "@replo/design-system/components/combobox/hooks/useCreatableComboboxInputOptions";
import Popover from "@replo/design-system/components/popover/Popover";
import { BsArrowReturnRight, BsSearch } from "react-icons/bs";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

type BarPopoverProps = {
  selectedUrlParamOptionValue: string;
  isOpen: boolean;
  onOpenChange(isOpen: boolean): void;
};

const INITIAL_SEARCH_VALUE = "";
const INITIAL_OPERATOR_VALUE = ConditionOperatorEnum.EQUALS;
const INITIAL_SEARCH_LIMIT = 20;
const INITIAL_SEARCH_OFFSET = 0;
const INITIAL_SEARCH_ORDER = "DESC";

function createOptionFromValue(value: string) {
  return {
    label: value,
    value,
  };
}

const BarPopover: React.FC<BarPopoverProps> = ({
  selectedUrlParamOptionValue,
  isOpen,
  onOpenChange,
}) => {
  const { query, dispatchAnalyticsQuery } = useAnalyticsQueryContext();
  const [searchValue, setSearchValue] = React.useState(INITIAL_SEARCH_VALUE);
  const [operatorValue, setOperatorValue] =
    React.useState<ConditionOperatorEnum>(INITIAL_OPERATOR_VALUE);

  const urlHosts = query.urlHosts;

  const handleSearchSelectValueChange = (value: string) => {
    dispatchAnalyticsQuery({
      type: "filters.addUrlParam",
      payload: {
        attribute: selectedUrlParamOptionValue,
        operator: operatorValue,
        value,
      },
    });

    setSearchValue(value);

    resetState();
    onOpenChange(false);
  };

  const resetState = () => {
    setSearchValue(INITIAL_SEARCH_VALUE);
    setOperatorValue(INITIAL_OPERATOR_VALUE);
  };

  const workspaceId = useCurrentWorkspaceId();

  const findUrlParamsQuery: FindUrlParamsQuery = React.useMemo(() => {
    return {
      urlHosts,
      order: INITIAL_SEARCH_ORDER,
      limit: INITIAL_SEARCH_LIMIT,
      offset: INITIAL_SEARCH_OFFSET,
      key: selectedUrlParamOptionValue,
      value: searchValue,
    };
  }, [urlHosts, selectedUrlParamOptionValue, searchValue]);

  const { urlParams: findUrlParamsResponse } = useFindUrlParams(
    findUrlParamsQuery,
    workspaceId ?? null,
  );

  const urlParamsOptionsFromResponse =
    findUrlParamsResponse?.map((urlParam) => createOptionFromValue(urlParam)) ??
    [];

  const [urlParamsInput, setUrlParamsInput, urlParamsOptions] =
    useCreatableComboboxInputOptions(urlParamsOptionsFromResponse);

  return (
    <Popover.Root
      isOpen={isOpen}
      onOpenChange={() => {
        onOpenChange(!isOpen);
        resetState();
      }}
    >
      <div className="absolute top-0 left-0 w-0 h-0">
        <Popover.Anchor className="relative" />
      </div>
      <Popover.Content
        hideCloseButton={true}
        shouldPreventDefaultOnInteractOutside={false}
        side="bottom"
        sideOffset={35}
        className="border w-fit"
        align="start"
      >
        <div className="flex flex-row gap-2 items-center">
          <div>
            <BsArrowReturnRight className="h-5 w-5 text-subtle" />
          </div>
          <span className="flex whitespace-nowrap text-xs items-center text-medium p-2">
            {findLabelForValue(
              createUrlParamsFilterOptions(),
              selectedUrlParamOptionValue,
            )}
          </span>
          <div>
            <Combobox
              options={OPERATOR_OPTIONS}
              value={operatorValue}
              onChange={(operator) => {
                setOperatorValue(operator as ConditionOperatorEnum);
              }}
              trigger={
                <Combobox.SelectionButton
                  title={
                    OPERATOR_OPTIONS.find((opt) => opt.value === operatorValue)
                      ?.label ?? operatorValue
                  }
                  layoutClassName="min-w-[125px]"
                />
              }
            />
          </div>

          <div className="min-w-[300px] ml-2">
            <Combobox.Root
              options={urlParamsOptions}
              value={searchValue}
              onChange={handleSearchSelectValueChange}
              input={urlParamsInput}
              onInputChange={(input) => {
                setUrlParamsInput(input);
              }}
            >
              <Combobox.Trigger>
                <Combobox.Input
                  placeholder="Search..."
                  startEnhancer={<BsSearch className="h-3 w-3" />}
                  outlineOnActive={false}
                />
              </Combobox.Trigger>
              <Combobox.Popover>
                <Combobox.Content emptySearchMessage="No matches found." />
              </Combobox.Popover>
            </Combobox.Root>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default BarPopover;
