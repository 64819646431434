import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";

interface WidthProperties {
  width?: string;
  maxWidth?: string;
  minWidth?: string;
}

function shouldShowProperty(property: string | undefined): boolean {
  return (
    property !== undefined &&
    property !== "100%" &&
    property !== "auto" &&
    property !== "none"
  );
}

const mapPropertyToName = {
  width: "width",
  maxWidth: "max width",
  minWidth: "min width",
};

function getTooltipMessage(
  badgesToShow: WidthProperties,
  label: string,
): string {
  let result = `${label} has `;
  for (const property in badgesToShow) {
    const value = badgesToShow[property as keyof WidthProperties];
    result += `${
      mapPropertyToName[property as keyof WidthProperties]
    } set to ${value}, `;
  }
  // Note (Evan, 2022-03-22, REPL-6623): this slice removes the final ", "
  // so that the message ends cleanly
  return result.slice(0, -2);
}

function splitPropertyToNumberAndUnit(property: string | undefined): string[] {
  if (!property) {
    return ["", ""];
  }
  const match = property.match(/(\d+)(\D+)/);
  if (!match) {
    return ["", ""];
  }
  return [match[1] ?? "", match[2] ?? ""];
}

type TreeRowWidthBadgeProps = WidthProperties & { label: string };

const hiddenBadgeWidth = 20;
const offset = 5;

const TreeRowWidthBadge: React.FC<TreeRowWidthBadgeProps> = ({
  width,
  maxWidth,
  minWidth,
  label,
}) => {
  const badgesToShow = {
    ...(shouldShowProperty(width) ? { width } : {}),
    ...(shouldShowProperty(maxWidth) ? { maxWidth } : {}),
    ...(shouldShowProperty(minWidth) ? { minWidth } : {}),
  };

  const [topBadgeWidth, setTopBadgeWidth] = React.useState(0);
  const topBadgeRef = React.useRef<HTMLDivElement | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We actually want these deps
  React.useLayoutEffect(() => {
    const topBadgeWidth = topBadgeRef.current?.offsetWidth ?? 0;
    setTopBadgeWidth(topBadgeWidth);
  }, [width, maxWidth, minWidth]);

  const badgeEntries = Object.entries(badgesToShow);

  if (badgeEntries.length === 0) {
    return null;
  }

  return (
    <div className="ml-2 flex items-center justify-self-start">
      <Tooltip content={getTooltipMessage(badgesToShow, label)} triggerAsChild>
        <div tabIndex={-1}>
          <div className="relative">
            {badgeEntries
              .map(([key, value], index) => (
                <SingleWidthBadge
                  key={key}
                  propertyName={key}
                  propertyValue={value}
                  index={index}
                  topBadgeWidth={topBadgeWidth}
                  topBadgeRef={topBadgeRef}
                />
              ))
              // NOTE (Evan, 7/18/23) We reverse this so the first badge is rendered last,
              // placing it on top.
              .reverse()}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default TreeRowWidthBadge;

const SingleWidthBadge: React.FC<{
  propertyName: string;
  propertyValue?: string;
  index: number;
  topBadgeWidth: number;
  topBadgeRef: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ propertyName, propertyValue, index, topBadgeWidth, topBadgeRef }) => {
  const [num, unit] = splitPropertyToNumberAndUnit(propertyValue);
  const isTopBadge = index === 0;
  const left = isTopBadge
    ? 0
    : topBadgeWidth - hiddenBadgeWidth + offset * index;

  const style = {
    left: `${left}px`,
    width: !isTopBadge ? hiddenBadgeWidth : undefined,
  };

  return (
    <div
      className={twMerge(
        "absolute top-1/2 h-[14px] -translate-y-1/2 rounded px-1 text-[8px] text-white",
        propertyName === "width" && "bg-blue-600",
        propertyName === "maxWidth" && "bg-blue-500",
        propertyName === "minWidth" && "bg-blue-400",
      )}
      style={style}
      ref={
        isTopBadge
          ? (ref) => {
              topBadgeRef.current = ref;
            }
          : undefined
      }
    >
      {isTopBadge && (
        <>
          <span className="font-bold">{num}</span>
          <span>{unit}</span>
        </>
      )}
    </div>
  );
};
