import * as React from "react";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import { BsGlobe2 } from "react-icons/bs";

type AnalyticsHostComboboxProps = {
  onChange: (value: string) => void;
  disabled?: boolean;
};

const AnalyticsUrlHostCombobox = ({
  onChange,
  disabled,
}: AnalyticsHostComboboxProps) => {
  const { query, workspaceUrlHosts } = useAnalyticsQueryContext();

  const workspaceUrlHostOptions = workspaceUrlHosts.data.map((host) => ({
    value: host,
    label: host,
  }));

  return workspaceUrlHosts.isLoading ? (
    <Skeleton className="w-full h-[24px]" />
  ) : (
    <Combobox
      options={workspaceUrlHostOptions}
      value={query.urlHosts[0] ?? ""}
      onChange={onChange}
      trigger={
        <Combobox.SelectionButton
          isDisabled={disabled}
          title={query.urlHosts[0] ?? ""}
          isPlaceholder={!Boolean(query.urlHosts[0])}
          startEnhancer={<BsGlobe2 className="h-4 w-4" />}
        />
      }
    />
  );
};

export default AnalyticsUrlHostCombobox;
