import * as React from "react";

export type AccordionContextType = {
  openItems: string[];
  toggleItem: (id: string) => void;
};

export const AccordionContext =
  React.createContext<AccordionContextType | null>(null);

export function useAccordion() {
  return React.useContext(AccordionContext);
}
