import * as React from "react";

import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { toast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { Controller, useForm } from "react-hook-form";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import Input from "../common/designSystem/Input";
import LabeledControl from "../common/designSystem/LabeledControl";
import { WorkspaceSelectable } from "../dashboard/projects/NewProjectFlow";

type WorkspaceFormValues = {
  workspaceId?: string;
  workspaceName?: string;
};

export const PendingShopifyIntegrationModal = () => {
  const [createNewWorkspace, setCreateNewWorkspace] = React.useState(false);
  const [searchParams] = useSearchParams();
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");
  const { register, handleSubmit, formState, control } =
    useForm<WorkspaceFormValues>({
      mode: "onBlur",
    });
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { user } = useCurrentUser();

  const { mutate: connectIntegration, isPending: isConnectingWorkspace } =
    trpc.integration.convertPendingAppInstallationToIntegration.useMutation({
      onSuccess: (_, { workspaceId }) => {
        navigate(
          generatePath(routes.workspace.integrations.shopify, {
            workspaceId,
          }),
        );
        closeModal({ type: "pendingShopifyIntegrationModal" });
      },
    });

  const { mutate: createWorkspace, isPending: isCreatingWorkspace } =
    trpc.workspace.create.useMutation({
      onSuccess: ({ workspace }) => {
        toast({
          header: "Workspace created",
        });
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        if (pendingAppInstallationId) {
          connectIntegration({
            workspaceId: workspace.id,
            pendingAppInstallationId,
          });
        }
      },
    });

  const onSubmit = ({ workspaceId, workspaceName }: WorkspaceFormValues) => {
    if (createNewWorkspace) {
      if (workspaceName && user) {
        createWorkspace({
          name: workspaceName,
          preferredPaymentProcessor:
            user.source === "shopify" ? "shopify" : "stripe",
          eventOrigin: "shopifyInstall",
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      }
    } else if (workspaceId && pendingAppInstallationId) {
      connectIntegration({
        workspaceId,
        pendingAppInstallationId,
      });
    }
  };

  const isPending = isCreatingWorkspace || isConnectingWorkspace;

  const formSubmit = (values: any) => {
    void handleSubmit(onSubmit)(values);
  };

  const renderCreateWorkspaceContent = () => (
    <>
      <p className="typ-body-base">Add your workspace name:</p>
      <LabeledControl label="Workspace Name">
        <Input
          autoFocus
          placeholder="Add Name"
          {...register("workspaceName", {
            shouldUnregister: true,
            required: createNewWorkspace,
          })}
          autoComplete="off"
          size="base"
        />
      </LabeledControl>
    </>
  );

  const renderSelectWorkspaceContent = () => (
    <>
      <p className="typ-body-base">
        Select a workspace to add this Shopify Integration to. If you do not see
        the workspace in the dropdown, create a new workspace using the button
        below.
      </p>
      <LabeledControl label="Workspace">
        <Controller
          render={({ field: { onChange, value } }) => (
            <WorkspaceSelectable value={value ?? null} onChange={onChange} />
          )}
          rules={{
            required: !createNewWorkspace,
          }}
          shouldUnregister
          control={control}
          name="workspaceId"
        />
      </LabeledControl>
    </>
  );

  const renderCreateWorkspaceFooter = () => (
    <div className="flex flex-row gap-2">
      <Button
        variant="secondary"
        size="base"
        disabled={isPending}
        onClick={() => setCreateNewWorkspace(!createNewWorkspace)}
      >
        Back
      </Button>
      <Button
        type="submit"
        variant="primary"
        size="base"
        disabled={!formState.isValid || isPending}
        onClick={formSubmit}
      >
        Create Workspace
      </Button>
    </div>
  );

  const renderSelectWorkspaceFooter = () => (
    <div className="flex flex-row gap-2">
      <Button
        variant="secondary"
        size="base"
        disabled={isPending}
        onClick={() => setCreateNewWorkspace(!createNewWorkspace)}
      >
        Create New Workspace
      </Button>
      <Button
        type="submit"
        variant="primary"
        size="base"
        disabled={!formState.isValid || isPending}
        onClick={formSubmit}
      >
        Connect Workspace
      </Button>
    </div>
  );

  return (
    <form onSubmit={formSubmit}>
      <Modal
        isOpen={Boolean(pendingAppInstallationId)}
        onOpenChange={(open) => {
          if (!open) {
            closeModal({ type: "pendingShopifyIntegrationModal" });
          }
        }}
        closeOnOverlayClick={false}
        title={
          createNewWorkspace
            ? "Create a new Workspace"
            : "Thanks for connecting a Shopify Store"
        }
        size="base"
        footer={
          createNewWorkspace
            ? renderCreateWorkspaceFooter()
            : renderSelectWorkspaceFooter()
        }
      >
        {createNewWorkspace
          ? renderCreateWorkspaceContent()
          : renderSelectWorkspaceContent()}
      </Modal>
    </form>
  );
};
