import type {
  AnalyticsEvent,
  AnalyticsEventParamsOf,
} from "@editor/infra/analytics";

import * as React from "react";

import { errorToast } from "@replo/design-system/components/alert/Toast";

import { useLogAnalytics } from "./useLogAnalytics";

type ErrorEventType = Extract<AnalyticsEvent["type"], `error.${string}`>;

export const useErrorToast = () => {
  const logEvent = useLogAnalytics();

  return React.useCallback(
    (
      title: string,
      message: string,
      eventName: ErrorEventType,
      params?: AnalyticsEventParamsOf<ErrorEventType>,
    ) => {
      logEvent(eventName, params);
      return errorToast(title, message);
    },
    [logEvent],
  );
};
