import type { ChartConfig } from "@replo/design-system/components/shadcn/core/chart";
import type { LineChartDataPoint } from "@replo/design-system/components/shadcn/LineChart";
import type { ChartInterval } from "replo-utils/analytics";
import type { MetricName } from "schemas/generated/analyticsRead";
import type { Workspace } from "schemas/generated/workspace";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import { Loader } from "@editor/components/common/Loader";
import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { calculateDelta } from "@editor/utils/analytics";

import AnalyticsFilters from "@/features/analytics/AnalyticsFilters";
import {
  getChartIntervalOptions,
  getCurrentPeriodDashedTicks,
  getLineChartDataFromAnalyticsRead,
} from "@/features/analytics/chart";
import {
  DEFAULT_ANALYTICS_CHART_CONFIG,
  DETAILS_PAGE_CARD_METRICS,
  METRICS_REQUIRING_CURRENCY,
  METRICS_REQUIRING_PERCENTAGE,
} from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import AnalyticsLayout from "@/features/analytics/Layout";
import useBasicAnalyticsRead from "@/features/analytics/useBasicAnalyticsRead";
import { TZDate } from "@date-fns/tz";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import ReploLineChart from "@replo/design-system/components/shadcn/LineChart";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import { formatInTimeZone } from "date-fns-tz";
import { formatWithCommasAndTwoDecimals } from "replo-utils/lib/math";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

const PageDetails: React.FC = () => {
  const { workspace, isLoading } = useCurrentWorkspaceContext();

  if (!workspace) {
    return null;
  }

  return (
    <AnalyticsLayout headerTitle="Deep Dive" showBackButton>
      {isLoading ? <Loader /> : <LoadedPageDetails workspace={workspace} />}
    </AnalyticsLayout>
  );
};

type LoadedPageDetailsProps = {
  workspace: Workspace;
};

const LoadedPageDetails: React.FC<LoadedPageDetailsProps> = ({ workspace }) => {
  const workspaceId = workspace.id;
  const { query, dispatchAnalyticsQuery, workspaceUrlHosts } =
    useAnalyticsQueryContext();
  const analytics = useLogAnalytics();
  const timeZone =
    workspace.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  /**
   * NOTE (Max, 2024-12-15): When landing on the deep dive page,
   * the query should already have the EQUALS urlPath filter.
   * If it doesn't, it means that we clicked the "Deep Dive" tab on the
   * DashboardMenuItems, in which case we just default to "/".
   *
   * If we default to "/", we'll need to update query's urlPath filters,
   * which is what we do in the useEffect() below.
   */
  const pageUrlPath = React.useMemo(
    () =>
      query.filters.urlPath.find(
        (filter) => filter.operator === ConditionOperatorEnum.EQUALS,
      )?.value[0] ?? "/",
    [query.filters.urlPath],
  );

  const { rangeInDays, intervalOptions } = getChartIntervalOptions(
    query.selectedTimePeriod,
    timeZone,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  React.useEffect(() => {
    dispatchAnalyticsQuery({
      type: "initDeepDivePage",
      payload: {
        urlPath: pageUrlPath,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mainRangeResults, compareAtRangeResults, isLoading } =
    useBasicAnalyticsRead({
      workspaceId,
      type: "withUrlSyncQuery",
      queryConfig: {
        useChartInterval: false,
      },
    });

  const {
    mainRangeResults: chartMainRangeResults,
    compareAtRangeResults: chartCompareAtRangeResults,
    isLoading: isChartResultsLoading,
    constructedQuery: chartQuery,
  } = useBasicAnalyticsRead({
    workspaceId,
    type: "withUrlSyncQuery",
    queryConfig: {
      useChartInterval: true,
    },
  });

  const handleIntervalChange = (newInterval: ChartInterval) => {
    dispatchAnalyticsQuery({
      type: "updateChartInterval",
      payload: newInterval,
    });
  };

  const chartMetrics = [
    {
      key: "conversion_rates",
      label: "Conversion Rate",
      tooltipValueFormatter: (value: string) =>
        `${formatWithCommasAndTwoDecimals(Number(value), true)}%`,
    },
    {
      key: "unique_sessions",
      label: "Total Sessions",
      tooltipValueFormatter: (value: string) => `${value} sessions`,
    },
    {
      key: "average_order_value",
      label: "Average Order Value",
      tooltipValueFormatter: (value: string) =>
        `$${formatWithCommasAndTwoDecimals(Number(value), true)}`,
    },
    {
      key: "revenue_per_session",
      label: "Revenue Per Session",
      tooltipValueFormatter: (value: string) =>
        `$${formatWithCommasAndTwoDecimals(Number(value), true)}`,
    },
  ];

  const getChartData = React.useCallback(
    (metricKey: string) => {
      // NOTE (Kurt, 2024-10-04): We use the first element of these arrays because each array contains results for a single range (main or compare).
      // The filterRangeResults function ensures that we only have results for the specific page and range we're interested in.
      // Therefore, there should only be one element in each array, representing the data for our current page and range.
      if (chartMainRangeResults[0] && chartCompareAtRangeResults[0]) {
        return {
          data: getLineChartDataFromAnalyticsRead(
            chartMainRangeResults[0],
            chartCompareAtRangeResults[0],
            chartQuery,
            metricKey as MetricName,
          ),
          showPercentage: METRICS_REQUIRING_PERCENTAGE.includes(
            metricKey as MetricName,
          ),
          showCurrency: METRICS_REQUIRING_CURRENCY.includes(
            metricKey as MetricName,
          ),
        };
      }
      return null;
    },
    [chartMainRangeResults, chartCompareAtRangeResults, chartQuery],
  );

  const chartData = chartMetrics.map((metric) => ({
    ...metric,
    ...getChartData(metric.key),
  }));

  const selectedIntervalOption = intervalOptions.find(
    (option) => option.value === query.chartInterval,
  );

  return (
    <div className="flex flex-col gap-4">
      <AnalyticsFilters />
      {isLoading && workspaceUrlHosts.isLoading ? (
        <Skeleton className="w-full h-[200px]" />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {DETAILS_PAGE_CARD_METRICS.map((metric, index) => {
            const metricData =
              mainRangeResults[0]?.metrics[metric.key as MetricName];
            const compareAtMetricData =
              compareAtRangeResults[0]?.metrics[metric.key as MetricName];
            const delta = calculateDelta(
              metricData?.[0] as number,
              compareAtMetricData?.[0] as number,
            );
            const doesCompareValueExist =
              ((compareAtMetricData?.[0] as number) ?? 0) > 0;

            return (
              <div
                key={index}
                className="border border-slate-300 rounded-xl p-6"
              >
                <div className="flex flex-col">
                  <div className="text-base text-default font-medium pb-2">
                    <div className="inline-block">{metric.label}</div>
                  </div>
                  <MetricWithDelta
                    name={metric.key}
                    value={metricData?.[0] as number}
                    delta={delta}
                    doesCompareValueExist={doesCompareValueExist}
                    wrapperClassName="justify-left"
                    valueClassName="text-2xl font-semibold min-w-[40px] text-default"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="flex flex-row w-full justify-end">
        <Combobox.Root
          options={intervalOptions}
          onChange={(value) => {
            handleIntervalChange(value as ChartInterval);
            analytics("analytics.chart.update", {
              time: value as ChartInterval,
            });
          }}
          value={query.chartInterval}
        >
          <Combobox.Trigger>
            <Combobox.SelectionButton
              title={selectedIntervalOption?.label ?? "Interval: "}
              isPlaceholder={!selectedIntervalOption}
            />
          </Combobox.Trigger>
          <Combobox.Popover layoutClassName="w-[90px]" align="end">
            <Combobox.Content />
          </Combobox.Popover>
        </Combobox.Root>
      </div>
      {isChartResultsLoading ? (
        <Skeleton className="w-full h-[200px]" />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {chartData.map((metric) => {
            const chartConfig: ChartConfig = {
              ...DEFAULT_ANALYTICS_CHART_CONFIG,
              lines: {
                ...DEFAULT_ANALYTICS_CHART_CONFIG.lines,
                thisPeriod: {
                  ...DEFAULT_ANALYTICS_CHART_CONFIG.lines.thisPeriod,
                  dataKey:
                    DEFAULT_ANALYTICS_CHART_CONFIG.lines.thisPeriod?.dataKey ||
                    "thisPeriod",
                  dashed: {
                    numberOfTicks: getCurrentPeriodDashedTicks(
                      metric.data ?? [],
                      query.chartInterval,
                      timeZone,
                    ),
                  },
                },
              },
              xAxis: {
                ...DEFAULT_ANALYTICS_CHART_CONFIG.xAxis,
                // NOTE (Kurt, 2024-10-03): We reconstruct the config object here based on the current query state
                // to format the x-axis labels differently based on the interval. This is necessary since we don't want
                // to store any formatted data in the chart data object. This makes it necessary for us to use formatter
                // functions that can be passed to the chart component. We conditionally construct the x-axis tick
                // formatter based on the current interval and range in days.
                tickFormatter: (value: LineChartDataPoint["x"]) => {
                  const date = new TZDate(new Date(value), timeZone);
                  if (query.chartInterval === "hour" && rangeInDays <= 2) {
                    return date.toISOString().slice(11, 16);
                  }
                  return date.toLocaleDateString("en-US", {
                    month: "numeric",
                    day: "2-digit",
                  });
                },
              },
              tooltipKeyFormatter: (value: LineChartDataPoint["x"]) => {
                const date = new TZDate(new Date(value), timeZone);
                const dateEnd = query.chartInterval === "hour" ? 16 : 11;
                const formattedDate = date
                  .toISOString()
                  .replace("T", " ")
                  .slice(0, dateEnd);
                let gmt = formatInTimeZone(date, timeZone, "'GMT'xxx");
                gmt = gmt.replace(/GMT[+-]00:00/g, "GMT±00:00");
                return `${formattedDate} (${gmt})`;
              },
            };

            return (
              <div key={metric.key} className="w-full h-[350px]">
                <ReploLineChart<string>
                  title={metric.label}
                  data={metric.data ?? []}
                  config={chartConfig}
                  showTooltipCursor={true}
                  tooltipKey={DEFAULT_ANALYTICS_CHART_CONFIG.xAxis.dataKey}
                  tooltipValueFormatter={metric.tooltipValueFormatter}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PageDetails;
