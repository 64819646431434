import type {
  ReploSimpleProject,
  ReploSimpleProjectWithStats,
} from "schemas/generated/project";

import * as React from "react";

import EditableProjectName from "@editor/components/dashboard/projects/EditableProjectName";
import ProjectMenu from "@editor/components/dashboard/projects/ProjectMenu";
import { useEditedTimeString } from "@editor/hooks/useEditedTimeString";
import { getProjectName } from "@editor/utils/project-utils";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { Link } from "react-router-dom";
import { isShopifyIntegrationEnabled } from "schemas/utils";

type ProjectItemProps = {
  project: ReploSimpleProjectWithStats | ReploSimpleProject;
  onSelectProject: (e: React.MouseEvent) => void;
  className?: string;
  shouldShowCreatedAt?: boolean;
  showWorkspaceColumn?: boolean;
  hideEllipsisButton?: boolean;
  allowsRenaming: boolean;
};

const ListProjectItem: React.FC<ProjectItemProps> = ({
  project,
  onSelectProject,
  className,
  shouldShowCreatedAt = true,
  showWorkspaceColumn = false,
  hideEllipsisButton = false,
  allowsRenaming,
}) => {
  const editedTimeString = useEditedTimeString(
    project.lastEditedAt,
    project.createdAt,
  );
  const [isRenaming, setIsRenaming] = React.useState(false);
  const uniqueId = `project-${project.id}-${React.useId()}`;

  const handleStartRenaming = () => {
    setIsRenaming(true);
  };

  const handleFinishRenaming = () => {
    setIsRenaming(false);
  };

  return (
    <div
      role="button"
      className={twMerge(
        "grid w-full grid-cols-12 items-center justify-center py-4 pr-4 pl-4 gap-2 text-sm text-default",
        className,
      )}
      onClick={onSelectProject}
      id={uniqueId}
    >
      <div className="col-span-3 flex flex-row items-center gap-x-4 text-left">
        <EditableProjectName
          projectId={project.id}
          initialProjectName={getProjectName(project)}
          isRenaming={allowsRenaming && isRenaming}
          onFinishEditing={handleFinishRenaming}
        />
      </div>
      {showWorkspaceColumn ? (
        <div
          className="col-span-3 flex flex-row items-center gap-x-4 text-left"
          aria-describedby={uniqueId}
        >
          <span aria-hidden>{project.ownerWorkspace?.name ?? "-"}</span>
          <span className="sr-only">
            {project.ownerWorkspace?.name != null
              ? `Workspace ${project.ownerWorkspace?.name}`
              : "No workspace"}
          </span>
        </div>
      ) : null}
      {shouldShowCreatedAt && (
        <div className="col-span-3 text-slate-400 flex">
          <span className="sr-only">Edited</span>
          {editedTimeString.charAt(0).toUpperCase() + editedTimeString.slice(1)}
        </div>
      )}
      <div
        className={twMerge(
          "flex flex-row items-center",
          showWorkspaceColumn ? "col-span-2" : "col-span-3",
        )}
      >
        {isShopifyIntegrationEnabled(project) ? (
          <Tooltip
            content={project.integrations?.shopify?.store.shopifyUrl}
            triggerAsChild
          >
            <Link
              tabIndex={0}
              onClick={(e) => {
                e.stopPropagation();
              }}
              to={
                project.integrations?.shopify?.store.shopifyUrl
                  ? `https://${project.integrations?.shopify?.store.shopifyUrl}`
                  : ""
              }
              target="_blank"
            >
              <img
                src="/images/shopify-logo.svg"
                alt="Shopify logo"
                aria-hidden
              />
              <span className="sr-only">Shopify integration is available</span>
            </Link>
          </Tooltip>
        ) : (
          <Tooltip
            content="Shopify integration is not available"
            triggerAsChild
          >
            <div tabIndex={0}>
              <p className="ml-2" aria-hidden>
                -
              </p>
              <span className="sr-only">
                Shopify integration is not available
              </span>
            </div>
          </Tooltip>
        )}
      </div>
      {!hideEllipsisButton && (
        <div
          className={twMerge(
            "flex",
            showWorkspaceColumn ? "col-span-1" : "col-span-3",
          )}
        >
          <ProjectMenu
            project={project}
            allowsRenaming={allowsRenaming}
            onRenameOpen={handleStartRenaming}
          />
        </div>
      )}
    </div>
  );
};

export default ListProjectItem;
