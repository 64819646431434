import * as React from "react";

export function useComposedEventHandlers<
  T extends { defaultPrevented: boolean },
>(...handlers: Array<GenericEventHandler<T> | null | undefined>) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: handlers not specified here, rather the unfurled handlers
  return React.useMemo(() => composeEventHandlers(...handlers), [...handlers]);
}

function composeEventHandlers<T extends { defaultPrevented: boolean }>(
  ...handlers: Array<GenericEventHandler<T> | null | undefined>
) {
  return (event: T) => {
    let previousHandler: GenericEventHandler<T> | null | undefined;
    for (const handler of handlers) {
      previousHandler?.(event);
      if (!event.defaultPrevented) {
        handler?.(event);
      }
      previousHandler = handler;
    }
  };
}

type GenericEventHandler<T extends { defaultPrevented: boolean }> = (
  event: T,
) => void;
