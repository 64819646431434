import * as React from "react";

import LabeledControl from "@common/designSystem/LabeledControl";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import { filterNulls } from "replo-utils/lib/array";

type DeleteConfirmationModalProps = {
  onDelete: () => void;
  onRequestClose: () => void;
  isLoading?: boolean;
  assetName: string;
  assetTypeDisplayName: string;
  confirmationType?: "unpublish" | "delete";
  extraMessage?: React.ReactNode;
};

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  onDelete,
  onRequestClose,
  assetName,
  assetTypeDisplayName,
  isLoading,
  confirmationType = "delete",
  extraMessage,
}) => {
  const title =
    confirmationType === "unpublish"
      ? `Are you sure you want to unpublish this ${assetTypeDisplayName}?`
      : `Are you sure you want to delete this ${assetTypeDisplayName}?`;
  const subtitle =
    confirmationType === "unpublish"
      ? `Unpublishing this ${assetTypeDisplayName} will remove it from all pages or templates where it has been added in the Shopify Theme Customizer.`
      : `By deleting this ${assetTypeDisplayName} you will remove it and all its
  contents from your account permanently.`;

  return (
    <Modal
      isOpen={true}
      onOpenChange={onRequestClose}
      closeOnOverlayClick
      size="base"
      title={title}
      description={filterNulls([subtitle, extraMessage]).join(" ")}
      footer={
        <Button
          variant="danger"
          size="base"
          onClick={onDelete}
          isLoading={isLoading}
          data-testid="delete-confirmation-button"
        >
          {confirmationType === "delete"
            ? `Delete ${assetTypeDisplayName}`
            : "I Understand"}
        </Button>
      }
    >
      <div className="flex flex-col gap-y-2">
        <LabeledControl size="sm" label={`${assetTypeDisplayName} Name`}>
          <p className="typ-body-base text-default">{assetName}</p>
        </LabeledControl>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
