import type { ReploElementMetadata } from "schemas/generated/element";

import * as React from "react";

import { cloneMetafieldsRemovingIds } from "@common/utils";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { analytics } from "@editor/infra/analytics";
import {
  createElement,
  setElementsLoading,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch } from "@editor/store";
import {
  getDuplicatedElementName,
  getDuplicateElementPath,
  sanitizeElementName,
} from "@editor/utils/element";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { useNavigate } from "react-router-dom";
import { ReploError } from "schemas/errors";

class DuplicateElementError extends ReploError {
  constructor(value: string) {
    super({
      message: "Failed to duplicate element",
      additionalData: { value },
    });
  }
}

export function useDuplicateElement() {
  const dispatch = useEditorDispatch();
  const navigate = useNavigate();
  const errorToast = useErrorToast();
  const { mutateAsync: duplicateElement, isPending: isDuplicating } =
    trpc.element.duplicate.useMutation({
      onMutate: () => {
        dispatch(setElementsLoading(true));
      },
      onSuccess: (data) => {
        if (data.element) {
          dispatch(
            createElement({
              element: data.element,
            }),
          );
        }
        void trpcUtils.element.listAllElementsMetadata.invalidate();
        void trpcUtils.folder.list.invalidate({
          projectId: data.element?.projectId,
        });
      },
      onSettled: () => {
        dispatch(setElementsLoading(false));
      },
    });

  const handleDuplicateElement = React.useCallback(
    async function handleDuplicateElementFn(
      element: ReploElementMetadata,
      isSupportMode = false,
    ): Promise<void> {
      try {
        const now = new Date();
        const duplicatedName = getDuplicatedElementName({
          name: element.name,
          now,
          elementType: element.type,
          isSupportCopy: isSupportMode,
        });
        const duplicatedPath = getDuplicateElementPath({
          name: element.name,
          now,
          path: element.shopifyPagePath,
          isSupportCopy: isSupportMode,
        });

        const payload = await duplicateElement({
          element: {
            ...element,
            name: sanitizeElementName(duplicatedName, element.type),
            // Note (Noah, 2023-04-22, USE-85): Don't pass a component here - the backend
            // will take the duplicated element id and automatically sub in the component
            component: undefined,
            // Note (Ovishek, 2023-01-09): We remove all the ids from metafields when duplicating old page metafields.
            // This is because in the backend we update metafields with ids but we should create fresh metafields here.
            shopifyMetafields: cloneMetafieldsRemovingIds(
              element.shopifyMetafields,
            ),
            shopifyPagePath: duplicatedPath,
            // Note (Noah, 2022-10-19, REPL-4659): Default to not having pages published
            // for duplicated pages, because otherwise if a page is published but user
            // is on the trial plan, the page will have is_published = True and the user
            // will be able to publish even if they're not paying
            isPublished: false,
            // Note (Noah, 2023-04-27, USE-112): If this page is a homepage, we don't want
            // the duplicated page to be a homepage two, since that would create two homepages
            isHomepage: false,
            // Note (Ben O, 2025-03-02) Remove the publishedAt field so users can rename the duplicated section
            publishedAt: undefined,
          },
          duplicatingElementId: element.id,
          isSupportMode,
        });

        if (payload.element) {
          analytics.logEvent("element.new", {
            operation: "duplicate",
            type: element.type,
            creationMethod: "duplicate",
          });

          navigate(
            `/editor/${payload.element.projectId}/${payload.element.id}`,
          );
        } else {
          throw new DuplicateElementError(element.id);
        }
      } catch (error) {
        errorToast(
          "Please try again or reach out to support@replo.app for help.",
          "Failed to duplicate page",
          "error.element.duplicate",
          {
            elementDetail: JSON.stringify(error),
          },
        );
      }
    },
    [duplicateElement, navigate, errorToast],
  );

  return {
    handleDuplicateElement,
    isDuplicating,
  };
}
