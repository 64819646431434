import * as React from "react";

import { useControllableState } from "replo-utils/react/use-controllable-state";

import twMerge from "../../utils/twMerge";
import Description from "../label/Description";
import Label from "../label/Label";
import Switch from "./Switch";

type SwitchWithDescriptionProps = {
  label?: React.ReactNode;
  description?: React.ReactNode;
  tooltipText?: string;
  disabled?: boolean;
  size: "base" | "sm";
  layoutClassName?: string;
  UNSAFE_className?: string;
  isOn?: boolean;
  onChange?: (isOn: boolean) => void;
  variant?: "card" | "inline";
  UNSAFE_labelClassName?: string;
  UNSAFE_descriptionClassName?: string;
};

const SwitchWithDescription = ({
  label,
  description,
  tooltipText,
  disabled = false,
  size,
  isOn,
  onChange,
  layoutClassName,
  UNSAFE_className,
  variant = "inline",
  UNSAFE_labelClassName,
  UNSAFE_descriptionClassName,
}: SwitchWithDescriptionProps) => {
  const [isChecked, setIsChecked] = useControllableState(isOn, false, onChange);

  const onClick = (
    _e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
  ) => {
    if (disabled) {
      return;
    }
    setIsChecked(!isChecked);
  };
  const labelVariant = variant === "card" ? "default" : "inline";
  return (
    <div
      className={twMerge(
        "flex gap-4 items-center rounded-lg cursor-pointer",
        variant === "card" && "border-[.5px] border-border p-[12px]",
        disabled && "cursor-not-allowed",
        layoutClassName,
        UNSAFE_className,
      )}
      onClick={onClick}
    >
      {(label || description) && (
        <div className="flex-1 min-w-0 gap-1">
          <Label
            UNSAFE_className={twMerge(
              disabled && "opacity-50 cursor-not-allowed",
              UNSAFE_labelClassName,
            )}
            label={label}
            size={size}
            variant={labelVariant}
            pointer
            breakWords
          />
          <Description
            layoutClassName="mt-[2px]"
            UNSAFE_className={twMerge(
              "cursor-pointer",
              disabled && "opacity-50 cursor-not-allowed",
              UNSAFE_descriptionClassName,
            )}
            description={description}
            size={size}
            breakWords
          />
        </div>
      )}
      <div className="ml-auto shrink-0">
        <Switch
          isOn={isChecked}
          disabled={disabled}
          size={size}
          onChange={setIsChecked}
          tooltipText={tooltipText}
        />
      </div>
    </div>
  );
};

export default SwitchWithDescription;
