import type { ComponentTemplate } from "@editor/types/component-template";

import * as React from "react";

import ComponentTemplateDragOverlay from "@editor/components/ComponentTemplateDragOverlay";

import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import twMerge from "@replo/design-system/utils/twMerge";

export const SectionTemplateListSkeleton = ({
  hideTitle,
  count = 3,
}: {
  hideTitle?: boolean;
  count?: number;
}) => {
  return (
    <div className={twMerge("flex flex-col gap-3", hideTitle ? "" : "p-3")}>
      {!hideTitle && (
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <Skeleton className="h-5 w-32" />
          </div>
        </div>
      )}
      <ul className="flex flex-col gap-3 mb-8 mt-1">
        {new Array(count).fill(0).map((_, index) => (
          <li key={index}>
            <div className="aspect-[16/9] w-full">
              <Skeleton className="h-full w-full rounded border-[0.5px] border-slate-300" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const SectionTemplateListItem = ({
  componentTemplate,
}: {
  componentTemplate: ComponentTemplate;
}) => {
  return (
    <li key={componentTemplate.id}>
      <div className="relative w-full cursor-pointer rounded bg-hover aspect-[16/9]">
        <div
          className="flex h-full w-full place-items-center rounded border-0.5"
          data-testid="component-template-draggable"
        >
          <div className="flex justify-center items-center w-full h-full p-3 object-contain">
            <div className="w-full h-full flex items-center justify-center">
              <div className="relative w-full h-full cursor-pointer overflow-y-hidden flex items-center justify-center">
                <img
                  src={componentTemplate.desktopPreviewUrl}
                  loading="lazy"
                  className="mx-auto my-auto max-h-full max-w-full object-contain transition duration-700 ease-in-out"
                  alt={`${componentTemplate.name} preview`}
                />
              </div>
            </div>
            <ComponentTemplateDragOverlay
              dragType="section"
              template={componentTemplate}
              identifier={componentTemplate.id}
              scope="left-bar"
            />
          </div>
        </div>
      </div>
    </li>
  );
};
