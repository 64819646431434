import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type { ConstructedAnalyticsLink } from "schemas/generated/analyticsLink";
import type { Experiment } from "schemas/generated/experiment";

import * as React from "react";

import Input from "@common/designSystem/Input";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { useModal } from "@hooks/useModal";

import { DetailsContainer } from "@/features/experiments/components/DetailsContainer";
import { useExperimentEdit } from "@/features/experiments/tabs/hooks/useExperimentEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { useCreatableComboboxInputOptions } from "@replo/design-system/components/combobox/hooks/useCreatableComboboxInputOptions";
import { Expandable } from "@replo/design-system/components/expandable/Expandable";
import { MenuItem } from "@replo/design-system/components/menu/MenuItem";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken } from "@tanstack/react-query";
import copy from "copy-to-clipboard";
import { ChevronDown } from "lucide-react";
import { useForm } from "react-hook-form";
import { BsLockFill, BsSearch } from "react-icons/bs";
import { LuCopy, LuExternalLink, LuInfo } from "react-icons/lu";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { BillingTiers } from "schemas/billing";
import { EDGESERVER_DOMAIN_PROD } from "schemas/edgeserver";
import { isPathSafeSlug } from "schemas/utils";
import * as z from "zod";

type SubSectionType = "domain" | "shortName" | "slug";

type SubSectionProps = {
  title: string;
  className?: string;
  type: SubSectionType;
  value: string;
  options?: (ComboboxOption & { displayValue: string })[];
  helpTooltipText: string;
  error?: string;
  setValue: (value: string) => void;
  onBlur?: (value: string) => void;
  onEnter?: (value: string) => void;
};

const SubSection: React.FC<SubSectionProps> = ({
  title,
  className,
  type,
  value,
  options: optionsFromProps,
  helpTooltipText,
  error,
  setValue,
  onBlur,
  onEnter,
}) => {
  const { value: localValue, onChange: handleChange } = useOverridableInput({
    value,
    onValueChange: setValue,
  });

  const [input, setInput, options] = useCreatableComboboxInputOptions(
    optionsFromProps ?? [],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      onEnter(localValue);
    }
  };

  const inputComponent = exhaustiveSwitch({ type })({
    shortName: () => (
      <Combobox.Root
        input={input}
        onInputChange={setInput}
        options={options ?? []}
        value={value}
        onChange={setValue}
      >
        <Combobox.Trigger>
          <Combobox.Input
            placeholder="Select Group Name"
            startEnhancer={<BsSearch className="h-3 w-3" />}
            outlineOnActive={false}
          />
        </Combobox.Trigger>
        <Combobox.Popover>
          <Combobox.Content />
        </Combobox.Popover>
      </Combobox.Root>
    ),
    domain: () => {
      const selectedOption = optionsFromProps?.find(
        (option) => option.value === value,
      );
      return (
        <Combobox
          options={options ?? []}
          value={value}
          onChange={setValue}
          trigger={
            <Combobox.SelectionButton
              title={selectedOption?.displayValue ?? "Select Domain"}
              isPlaceholder={!Boolean(selectedOption)}
            />
          }
        />
      );
    },
    slug: () => (
      <Input
        size="base"
        placeholder="Enter Slug"
        value={localValue}
        onChange={handleChange}
        onBlur={() => onBlur?.(localValue)}
        onKeyDown={handleKeyDown}
        unsafe_inputClassName="text-xs"
      />
    ),
  });

  return (
    <div className={twMerge(className, "flex flex-col gap-1")}>
      <div className="flex flex-row gap-1 items-center">
        <span className="text-sm font-semibold typ-label-small">{title}</span>
        <Tooltip
          content={helpTooltipText}
          side="top"
          delay={300}
          triggerAsChild
        >
          <div>
            <LuInfo size={12} />
          </div>
        </Tooltip>
      </div>
      {inputComponent}
      {error && <span className="text-xs text-danger mt-1">{error}</span>}
    </div>
  );
};

type DomainOrShortName = {
  value: string;
  id: string;
};

type CreateNewLinkProps = {
  experimentName: Experiment["name"];
  handleLinkSubsectionChange: (value: string) => void;
  shouldForceCreateNewLink: boolean;
  workspaceId: string | undefined;
  links: ConstructedAnalyticsLink[];
  customDomains: DomainOrShortName[];
  shortNames: DomainOrShortName[];
};

const createNewLinkSchema = (links: ConstructedAnalyticsLink[]) =>
  z
    .object({
      customDomain: z.string(),
      shortName: z.string().nullable(),
      path: z.string().min(1, "Slug is required").refine(isPathSafeSlug, {
        message: "Slug must contain only letters, numbers, or hyphens",
      }),
    })
    .superRefine((data, ctx) => {
      if (!data.customDomain && !data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Either a custom domain or short name must be provided",
          path: ["_form"],
        });
      }

      if (data.customDomain === EDGESERVER_DOMAIN_PROD && !data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Short name is required when using the default domain",
          path: ["_form"],
        });
      }

      if (data.customDomain !== EDGESERVER_DOMAIN_PROD && data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Short name should not be provided when using a custom domain",
          path: ["_form"],
        });
      }

      const doesLinkAlreadyExist = links.some((link) => {
        const { customDomainValue, shortNameValue, path } = link;

        return (
          customDomainValue ===
            (data.customDomain === EDGESERVER_DOMAIN_PROD
              ? null
              : data.customDomain) &&
          shortNameValue === data.shortName &&
          path === data.path
        );
      });

      if (doesLinkAlreadyExist) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "This combination of domain, group name and slug is already used by another link.",
          path: ["_form"],
        });
      }
    });

type AnalyticsLinkFormData = z.infer<ReturnType<typeof createNewLinkSchema>> & {
  _form?: string;
};

const CreateNewLink: React.FC<
  Omit<CreateNewLinkProps, "customDomains" | "shortNames">
> = (props) => {
  const { workspaceId } = props;
  const { data: customDomains, isLoading: isLoadingCustomDomains } =
    trpc.workspace.getCustomDomains.useQuery(
      workspaceId ? workspaceId : skipToken,
    );

  const { data: shortNames, isLoading: isLoadingShortNames } =
    trpc.workspace.getShortNames.useQuery(
      workspaceId ? workspaceId : skipToken,
    );

  if (
    isLoadingCustomDomains ||
    isLoadingShortNames ||
    !customDomains ||
    !shortNames
  ) {
    return <Spinner size={25} variant="primary" />;
  }

  return (
    <LoadedCreateNewLink
      customDomains={customDomains}
      shortNames={shortNames}
      {...props}
    />
  );
};

const LoadedCreateNewLink: React.FC<CreateNewLinkProps> = ({
  experimentName,
  handleLinkSubsectionChange,
  shouldForceCreateNewLink,
  workspaceId,
  links,
  customDomains,
  shortNames,
}) => {
  const {
    mutateAsync: createAnalyticsLinkAsync,
    isPending: isCreatingAnalyticsLink,
  } = trpc.analyticsLink.create.useMutation({});

  const { dispatchExperimentEdit } = useExperimentEdit();

  const domainOptions = [
    {
      value: EDGESERVER_DOMAIN_PROD,
      label: EDGESERVER_DOMAIN_PROD,
      displayValue: EDGESERVER_DOMAIN_PROD,
    },
    ...(customDomains ?? []).map((domain) => ({
      value: domain.value,
      label: domain.value,
      displayValue: domain.value,
    })),
  ];

  const shortNameOptions = (shortNames ?? []).map((shortName) => ({
    value: shortName.value,
    label: shortName.value,
    displayValue: shortName.value,
  }));

  const customDomainOptions = domainOptions.filter(
    (option) => option.value !== EDGESERVER_DOMAIN_PROD,
  );

  const domainOptionsValue =
    customDomainOptions[0]?.value ?? EDGESERVER_DOMAIN_PROD;

  const defaultAnalyticsLinkCreate: AnalyticsLinkFormData = {
    customDomain: domainOptionsValue,
    shortName:
      domainOptionsValue !== EDGESERVER_DOMAIN_PROD
        ? null
        : shortNameOptions[0]?.value ?? null,
    path: experimentName,
  };

  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<AnalyticsLinkFormData>({
    resolver: zodResolver(createNewLinkSchema(links)),
    defaultValues: defaultAnalyticsLinkCreate,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const formLevelError = errors._form?.message;

  const handleDomainChange = (value: string) => {
    setValue("customDomain", value);
    if (value !== EDGESERVER_DOMAIN_PROD) {
      setValue("shortName", null);
    } else {
      setValue("shortName", shortNameOptions[0]?.value ?? null);
    }
    void trigger();
  };

  const handleShortNameChange = (value: string) => {
    setValue("shortName", value);
    void trigger();
  };

  const handleSlugChange = (value: string) => {
    setValue("path", value);
    void trigger();
  };

  const onSubmit = async (data: AnalyticsLinkFormData) => {
    if (workspaceId) {
      const analyticsLink = {
        ...data,
        customDomain:
          data.customDomain === EDGESERVER_DOMAIN_PROD
            ? null
            : data.customDomain,
      };

      const result = await createAnalyticsLinkAsync({
        workspaceId,
        analyticsLink,
      });
      await trpcUtils.analyticsLink.list.invalidate({ workspaceId });
      await trpcUtils.workspace.getShortNames.invalidate(workspaceId);

      handleLinkSubsectionChange("chooseLink");
      successToast("Experiment Link Created", "");
      dispatchExperimentEdit({
        type: "changeProperty",
        payload: { key: "analyticsLinkId", value: result.analyticsLinkId },
      });
    }
  };

  const customDomain = watch("customDomain");
  const isShortNameFieldRequired =
    !customDomain || customDomain === EDGESERVER_DOMAIN_PROD;

  const handleSubmission = handleSubmit(onSubmit);
  const onCreateLink = () => {
    void handleSubmission();
  };

  const modal = useModal();
  const openAddCustomDomainModal = () =>
    modal.openModal({
      type: "customDomainModal",
    });

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-12 gap-3">
        <SubSection
          title="Domain"
          className="col-span-4"
          type="domain"
          value={customDomain}
          options={[
            ...domainOptions,
            {
              value: "addCustomDomain",
              label: (
                <MenuItem
                  variant="default"
                  size="sm"
                  onClick={openAddCustomDomainModal}
                >
                  <span className="text-accent text-xs font-medium">
                    Add Custom Domain
                  </span>
                </MenuItem>
              ),
              displayValue: "Add Custom Domain",
            },
          ]}
          setValue={handleDomainChange}
          helpTooltipText="Use our default reploedge domain, or use your own custom domain"
          error={errors.customDomain?.message}
        />
        {isShortNameFieldRequired && (
          <SubSection
            title="Group Name"
            className="col-span-4"
            type="shortName"
            value={watch("shortName") ?? ""}
            options={shortNameOptions}
            setValue={handleShortNameChange}
            helpTooltipText="A unique name identifying your workspace, to distinguish it from others in the Replo system. Only required when using reploedge.com as your domain"
            error={errors.shortName?.message}
          />
        )}
        <SubSection
          title="Slug"
          className="col-span-4"
          type="slug"
          value={watch("path") ?? ""}
          setValue={handleSlugChange}
          helpTooltipText="The URL path that distinguishes this link from the other links in your workspace"
          error={errors.path?.message}
        />
      </div>
      {formLevelError && (
        <span className="text-xs text-danger mt-1">{formLevelError}</span>
      )}
      <div className="flex flex-row justify-between">
        <Expandable.Trigger>
          <span className="typ-button-small">What's this?</span>
        </Expandable.Trigger>
        <div className="flex flex-row justify-end gap-2">
          {!shouldForceCreateNewLink && (
            <Button
              variant="secondary"
              size="sm"
              onClick={() => handleLinkSubsectionChange("chooseLink")}
            >
              Cancel
            </Button>
          )}

          <Button
            variant="primary"
            size="sm"
            onClick={onCreateLink}
            isLoading={isCreatingAnalyticsLink}
            disabled={!isValid}
          >
            Create Link
          </Button>
        </div>
      </div>
    </div>
  );
};

const ChooseLinkOption: React.FC<{
  url: string;
  isActive: boolean;
}> = ({ url, isActive }) => {
  return (
    <MenuItem variant="default" size="sm">
      <div className="flex flex-row gap-2 items-center">
        <span className="flex-1 truncate">{url}</span>
        {isActive && <span className="h-1 w-1 bg-green-600 rounded-full" />}
      </div>
    </MenuItem>
  );
};

type ChooseLinkProps = {
  analyticsLinkId: string | null;
  handleChooseLinkChange: (analyticsLinkId: string) => void;
  handleLinkSubsectionChange: (value: string) => void;
  links: ConstructedAnalyticsLink[];
  isEditable?: boolean;
};
const ChooseLink: React.FC<ChooseLinkProps> = ({
  analyticsLinkId,
  handleChooseLinkChange,
  handleLinkSubsectionChange,
  links,
  isEditable = true,
}) => {
  const handleOptionClick = (analyticsLinkId: string) => {
    handleChooseLinkChange(analyticsLinkId);
  };

  const options =
    links.map((constructedLink) => {
      const { id, url, isActive } = constructedLink;
      return {
        value: id,
        label: <ChooseLinkOption url={url} isActive={isActive} />,
        isDisabled: isActive,
        displayValue: url,
      };
    }) ?? [];
  const selectedOption = options.find(
    (option) => option.value === analyticsLinkId,
  );
  return (
    <Combobox
      onChange={handleOptionClick}
      options={options}
      trigger={
        <Combobox.SelectionButton
          title={
            selectedOption?.displayValue ?? "Choose from your existing links"
          }
          endEnhancer={
            isEditable ? (
              <ChevronDown className="ml-auto" size={12} />
            ) : (
              <BsLockFill className="ml-auto" size={12} />
            )
          }
          isDisabled={!isEditable}
          layoutClassName="w-full"
        />
      }
      value={analyticsLinkId ?? undefined}
      footer={
        <Combobox.Footer size="sm">
          <Button
            variant="link"
            size="sm"
            onClick={() => handleLinkSubsectionChange("createNewLink")}
          >
            Create New Link
          </Button>
        </Combobox.Footer>
      }
    />
  );
};

const LinkInfographicContent: React.FC = () => {
  return (
    <div className="rounded-md border-0.5 border-slate-300 bg-slate-100 p-4 pb-0 flex items-center justify-center mt-3">
      <img
        src="/images/experiments/education/what-is-a-link.svg"
        alt="Link Infographic"
      />
    </div>
  );
};

type LinkSectionProps = {
  isEditable?: boolean;
  linkSubSection: "chooseLink" | "createNewLink";
  handleLinkSubsectionChange: (value: string) => void;
  links: ConstructedAnalyticsLink[];
  experiment: {
    analyticsLinkId: Experiment["analyticsLinkId"];
    name: Experiment["name"];
  };
  shouldForceCreateNewLink: boolean;
};

export const LinkSection: React.FC<LinkSectionProps> = ({
  isEditable = true,
  linkSubSection,
  handleLinkSubsectionChange,
  links,
  experiment,
  shouldForceCreateNewLink,
}) => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;

  const { dispatchExperimentEdit } = useExperimentEdit();

  const handleChooseLinkChange = (analyticsLinkId: string) => {
    dispatchExperimentEdit({
      type: "changeProperty",
      payload: { key: "analyticsLinkId", value: analyticsLinkId },
    });
  };

  const getSelectedLinkUrl = (): string | undefined => {
    if (!links || !experiment.analyticsLinkId) {
      return undefined;
    }

    const selectedLink = links.find(
      (link) => link.id === experiment.analyticsLinkId,
    );

    return selectedLink?.url;
  };

  return (
    <DetailsContainer title="Test Link" isRequired>
      <Expandable.Root>
        <div className="flex flex-col border-[0.5px] border-slate-300 p-4 rounded">
          <div className="flex flex-col gap-3">
            {linkSubSection === "chooseLink" ? (
              <ChooseLink
                analyticsLinkId={experiment.analyticsLinkId}
                handleChooseLinkChange={handleChooseLinkChange}
                handleLinkSubsectionChange={handleLinkSubsectionChange}
                links={links ?? []}
                isEditable={isEditable}
              />
            ) : (
              <CreateNewLink
                experimentName={experiment.name}
                handleLinkSubsectionChange={handleLinkSubsectionChange}
                shouldForceCreateNewLink={shouldForceCreateNewLink}
                workspaceId={workspaceId}
                links={links ?? []}
              />
            )}
            {linkSubSection === "chooseLink" && (
              <div className="flex flex-row justify-between">
                <Expandable.Trigger>
                  <span className="typ-button-small">What's this?</span>
                </Expandable.Trigger>
                <div className="flex flex-row justify-end">
                  <div className="flex flex-row gap-2">
                    {experiment.analyticsLinkId && (
                      <Button
                        variant="tertiary"
                        endEnhancer={<LuCopy size={12} />}
                        size="sm"
                        onClick={() => {
                          const url = getSelectedLinkUrl();
                          if (url) {
                            copy(url);
                            logEvent("experiment.link.copy", {
                              billingPlanTier: subscriptionTier,
                            });
                          }
                        }}
                      >
                        Copy Link
                      </Button>
                    )}

                    {!isEditable && (
                      <Button
                        variant="secondary"
                        endEnhancer={<LuExternalLink size={12} />}
                        size="sm"
                        to={getSelectedLinkUrl() ?? ""}
                        onClick={() => {
                          logEvent("experiment.link.preview", {
                            billingPlanTier: subscriptionTier,
                          });
                        }}
                      >
                        Preview
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <Expandable.Content>
            <LinkInfographicContent />
          </Expandable.Content>
        </div>
      </Expandable.Root>
    </DetailsContainer>
  );
};
