import type { SavedTextStyle } from "schemas/generated/designLibrary";

import * as React from "react";

import TextSavedStyleModifier from "@editor/components/designLibrary/TextSavedStyleModifier";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { generateNewName } from "@editor/utils/designLibrary";
import ModifierGroup from "@editorExtras/ModifierGroup";

import IconButton from "@replo/design-system/components/button/IconButton";
import twMerge from "@replo/design-system/utils/twMerge";
import { Pencil, Plus, X } from "lucide-react";
import { convertFontWeightToReadableString } from "replo-runtime/shared/utils/font";

import ShopStylePreviewText from "./ShopStylePreviewText";
import TextSavedStyleSkeleton from "./TextSavedStyleSkeleton";

interface TextSavedStyleGroupProps {
  highestHeadingStyle?: SavedTextStyle;
  paragraphStyle?: SavedTextStyle;
  isLoading: boolean;
  createDraftTextStyle: (style: SavedTextStyle) => { id: string };
  updateDraftTextStyle: (style: SavedTextStyle & { id: string }) => void;
  deleteDraftTextStyle: (styleId: string) => void;
  draftStyles: {
    text: (SavedTextStyle & { id: string })[];
  };
}

const TextSavedStyleGroup: React.FC<TextSavedStyleGroupProps> = ({
  highestHeadingStyle,
  paragraphStyle,
  isLoading,
  createDraftTextStyle,
  updateDraftTextStyle,
  deleteDraftTextStyle,
  draftStyles,
}) => {
  const [textStyleBeingEditedId, setTextStyleBeingEditedId] = React.useState<
    string | null
  >(null);
  const [textModifierPopoverIsOpen, setTextModifierPopoverIsOpen] =
    React.useState(false);

  const textSavedStyles = draftStyles?.text ?? [];

  const projectId = useCurrentProjectId();

  const handleNewTextStyle = async () => {
    if (!projectId) {
      return null;
    }

    const newName = generateNewName(textSavedStyles, "text");
    const savedStyleToCreate = {
      name: newName,
      type: "text" as const,
      attributes: {
        type: "text" as const,
        htmlTag: "p" as const,
      },
    };

    const { id } = createDraftTextStyle(savedStyleToCreate);

    setTextModifierPopoverIsOpen(true);
    setTextStyleBeingEditedId(id);
  };

  const selectedTextStyle = textSavedStyles.find(
    (savedStyle) => savedStyle.id === textStyleBeingEditedId,
  );

  return (
    <>
      {selectedTextStyle && (
        <TextSavedStyleModifier
          isPopoverOpen={textModifierPopoverIsOpen}
          onPopoverOpenChange={(isOpen) => {
            if (!isOpen) {
              setTextStyleBeingEditedId(null);
            }
            setTextModifierPopoverIsOpen(isOpen);
          }}
          textSavedStyle={selectedTextStyle}
          handleUpdateTextSavedStyle={(updatedStyle) =>
            updateDraftTextStyle(updatedStyle)
          }
          sideOffset={20}
        />
      )}

      <ModifierGroup title="Text Styles" tooltipText="Add new text style">
        <div className="flex flex-col gap-2">
          {isLoading ? (
            <TextSavedStyleSkeleton />
          ) : (
            <>
              {(highestHeadingStyle || paragraphStyle) && (
                <div className="flex flex-col rounded-lg w-full p-2 items-center bg-neutral-soft">
                  {highestHeadingStyle && (
                    <ShopStylePreviewText
                      attributes={highestHeadingStyle.attributes}
                      text="Heading"
                      className="text-[20px] leading-[28px]"
                    />
                  )}
                  {paragraphStyle && (
                    <ShopStylePreviewText
                      attributes={paragraphStyle.attributes}
                      text="This is your paragraph."
                      className="text-[12px] leading-[16px]"
                    />
                  )}
                </div>
              )}
              {textSavedStyles.map((savedStyle, index) => (
                <TextStyleRow
                  key={index}
                  savedStyle={savedStyle}
                  editingSavedStyleId={textStyleBeingEditedId}
                  setEditingSavedStyleId={(id) => {
                    setTextModifierPopoverIsOpen(true);
                    setTextStyleBeingEditedId(id);
                  }}
                  handleDeleteTextStyle={(styleId) =>
                    deleteDraftTextStyle(styleId)
                  }
                />
              ))}

              <button
                className="flex items-center rounded-md w-full p-1 gap-2 cursor-pointer hover:bg-neutral-soft"
                onClick={() => void handleNewTextStyle()}
                aria-label="Add new text style"
              >
                <div className="flex items-center justify-center p-[6px] typ-label-base bg-neutral-soft text-muted border-border border-1 border-dashed rounded w-8 h-8 flex-shrink-0">
                  <Plus size={12} className="text-muted" />
                </div>
                <div className="typ-label-small">Add text style</div>
              </button>
            </>
          )}
        </div>
      </ModifierGroup>
    </>
  );
};

export const TextStyleRow: React.FC<{
  savedStyle: SavedTextStyle & { id: string };
  editingSavedStyleId?: string | null;
  setEditingSavedStyleId?: (id: string | null) => void;
  handleDeleteTextStyle?: (styleId: string) => void;
  modificationEnabled?: boolean;
  onClick?: () => void;
}> = ({
  savedStyle,
  editingSavedStyleId,
  setEditingSavedStyleId,
  handleDeleteTextStyle,
  modificationEnabled = true,
  onClick,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const isSelected = editingSavedStyleId === savedStyle.id;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={handleClick}
      className={twMerge(onClick && "cursor-pointer")}
    >
      <div
        className={twMerge(
          "flex rounded w-full p-1 gap-2 items-center",
          (isHovered || isSelected) && "bg-neutral-soft",
        )}
      >
        <div className="flex items-center justify-center p-[6px] typ-label-base bg-white text-muted border-border border-0.5 rounded-md w-8 h-8 flex-shrink-0">
          {savedStyle.attributes.htmlTag.toUpperCase()}
        </div>
        <div className="flex flex-col flex-grow min-w-0 truncate">
          <div className="typ-label-small truncate">{savedStyle.name}</div>
          <div className="typ-body-small text-muted truncate">
            {[
              savedStyle.attributes.fontFamily,
              savedStyle.attributes.fontSize,
              savedStyle.attributes.fontWeight
                ? convertFontWeightToReadableString(
                    savedStyle.attributes.fontWeight,
                  )
                : undefined,
            ]
              .filter(Boolean)
              .join(" \u00B7 ")}
          </div>
        </div>
        {isHovered && modificationEnabled && (
          <div className="flex gap-1">
            {setEditingSavedStyleId && (
              <IconButton
                icon={<Pencil size={12} />}
                onClick={() => setEditingSavedStyleId(savedStyle.id)}
                variant="tertiary"
                aria-label="Edit style"
                size="sm"
              />
            )}
            {handleDeleteTextStyle && (
              <IconButton
                icon={<X size={12} />}
                onClick={() => handleDeleteTextStyle(savedStyle.id)}
                variant="tertiary"
                aria-label="Delete style"
                size="sm"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextSavedStyleGroup;
