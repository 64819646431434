import type { Insight } from "schemas/generated/insights";

import React from "react";

import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";

import { successToast } from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import IconButton from "@replo/design-system/components/button/IconButton";
import twMerge from "@replo/design-system/utils/twMerge";
import {
  Info,
  Lightbulb,
  Sparkles,
  ThumbsDown,
  ThumbsUp,
  TrendingDown,
  TrendingUp,
} from "lucide-react";
import { generatePath, useNavigate } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { getUrlWithoutQueryParams } from "replo-utils/lib/string";

export const InsightsCard: React.FC<{
  insight: Insight;
}> = ({ insight }) => {
  const navigate = useNavigate();

  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const {
    title,
    analysis,
    action,
    sentiment,
    url,
    actionTitle,
    elementId,
    projectId,
  } = insight;
  const isReploPage = Boolean(projectId) && Boolean(elementId);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = React.useState(false);

  const formattedUrl = getUrlWithoutQueryParams(url);

  const logEvent = useLogAnalytics();

  const icon = exhaustiveSwitch({ type: sentiment })({
    positive: () => <TrendingUp className="text-success" size={14} />,
    negative: () => <TrendingDown className="text-danger" size={14} />,
    neutral: () => <Info className="text-info" size={14} />,
  });

  const handleThumbsClick = (thumbsType: "up" | "down") => {
    logEvent("analytics.insights.card.thumbs.click", {
      insightId: insight.id,
      thumbsType,
      workspaceId,
    });

    setIsFeedbackSubmitted(true);
    successToast("Feedback Submitted", "Thank you for your feedback!");
  };

  const handleApplyClick = () => {
    if (!elementId || !projectId) {
      return;
    }

    logEvent("analytics.insights.card.apply.click", {
      insightId: insight.id,
      workspaceId,
      elementId,
      projectId,
    });

    navigate(generatePath(routes.editor.element, { projectId, elementId }));
  };

  return (
    <div className="border rounded-lg p-3 space-y-3">
      <div className="flex flex-row gap-2">
        <div
          className={twMerge(
            "p-0.5 border rounded-lg w-6 h-6 flex justify-center items-center",
            sentiment === "positive" && "bg-success-soft border-success/20",
            sentiment === "negative" && "bg-danger-soft border-danger/20",
            sentiment === "neutral" && "bg-info-soft border-info/20",
          )}
        >
          {icon}
        </div>
        <div className="flex flex-col gap-1 flex-1">
          <div className="flex flex-col gap-0.5">
            <span className="typ-header-base">{title}</span>
            <span className="typ-body-small text-placeholder">
              {formattedUrl}
            </span>
          </div>
          <span className="typ-body-small text-muted">{analysis}</span>
        </div>
      </div>
      <div className="flex rounded-lg bg-light-sheet border px-3 py-2">
        <div className="flex items-center gap-1">
          <div className="flex items-start h-full">
            <Lightbulb size={16} />
          </div>
          <div className="flex flex-col gap-0.5">
            <span className="typ-header-small">{actionTitle}</span>
            <span className="typ-body-small text-muted">{action}</span>
          </div>
        </div>
      </div>

      <div
        className={twMerge(
          "flex flex-row",
          isReploPage ? "justify-between" : "justify-end",
        )}
      >
        {isReploPage && (
          <Button
            variant="secondary"
            size="sm"
            startEnhancer={<Sparkles size={12} />}
            onClick={handleApplyClick}
          >
            Apply
          </Button>
        )}

        {!isFeedbackSubmitted && (
          <div className="flex flex-row justify-end">
            <IconButton
              variant="tertiary"
              size="sm"
              icon={<ThumbsUp size={12} />}
              onClick={() => handleThumbsClick("up")}
            />
            <IconButton
              variant="tertiary"
              size="sm"
              icon={<ThumbsDown size={12} />}
              onClick={() => handleThumbsClick("down")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
