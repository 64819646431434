import type { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import type { Asset, AssetType } from "schemas/generated/asset";

import * as React from "react";

import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import {
  selectProjectId,
  selectStoreDoesNotHaveReadFilesAccess,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import SvgAssetsEmptyState from "@svg/empty-assets";

import {
  IMAGE_ACCEPT_FILE_EXTENSIONS,
  MAX_IMAGE_SIZE_BYTES,
  MAX_VIDEO_SIZE_BYTES,
  VIDEO_ACCEPT_FILE_EXTENSIONS,
} from "@/features/assets/constants";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import { skipToken } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";

import AssetCard from "./AssetCard";

type ShopifyAssetsGridProps = {
  assetTypes?: AssetType[];
  searchTerm: string;
  onAssetSelected?: (asset: Asset) => void;
};

type ReploAssetsGridProps = ShopifyAssetsGridProps & {
  shouldAllowActions?: boolean;
  getDropzoneRootProps?: (
    props?: DropzoneRootProps | undefined,
  ) => DropzoneRootProps;
  getDropzoneInputProps?: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
  droppedFilesMap: Map<string, File>;
  isDragActive?: boolean;
};

const INITIAL_ASSETS_TO_LOAD = 16;

export const ShopifyAssetsGrid: React.FC<ShopifyAssetsGridProps> = ({
  assetTypes = ["image", "video"],
  searchTerm,
  onAssetSelected,
}) => {
  const workspaceId = useCurrentWorkspaceId();
  const projectId = useEditorSelector(selectProjectId);
  const doesNotHaveReadFilesAccess = useEditorSelector(
    selectStoreDoesNotHaveReadFilesAccess,
  );
  const { data, isLoading, fetchNextPage, hasNextPage } =
    trpc.asset.listShopify.useInfiniteQuery(
      workspaceId && projectId && !doesNotHaveReadFilesAccess
        ? {
            workspaceId,
            projectId,
            types: assetTypes,
            limit: INITIAL_ASSETS_TO_LOAD,
            search: searchTerm,
          }
        : skipToken,
      {
        getNextPageParam: ({ nextCursor }) => nextCursor,
      },
    );
  const assets = React.useMemo(
    () => data?.pages?.flatMap((page) => page.assets) ?? [],
    [data],
  );
  return (
    <AssetsGrid
      assets={assets}
      searchTerm={searchTerm}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      fetchNextPage={() => void fetchNextPage()}
      emptyState={
        searchTerm ? (
          <NoSearchResults searchTerm={searchTerm} />
        ) : (
          <ShopifyAssetEmptyState />
        )
      }
      shouldAllowActions={false}
      onAssetSelected={onAssetSelected}
    />
  );
};

export const ReploAssetsGrid: React.FC<ReploAssetsGridProps> = ({
  getDropzoneInputProps,
  getDropzoneRootProps,
  assetTypes = ["image", "video"],
  searchTerm,
  onAssetSelected,
  shouldAllowActions,
  droppedFilesMap,
  isDragActive = false,
}) => {
  const projectId = useCurrentProjectId();

  const { data, isLoading, fetchNextPage, hasNextPage } =
    trpc.asset.list.useInfiniteQuery(
      projectId
        ? {
            projectId,
            types: assetTypes,
            limit: INITIAL_ASSETS_TO_LOAD,
            search: searchTerm,
          }
        : skipToken,
      {
        getNextPageParam: ({ nextCursor }) => nextCursor,
      },
    );

  const assets = React.useMemo(
    () => data?.pages?.flatMap((page) => page.assets) ?? [],
    [data],
  );

  // NOTE (Gabe 2025-02-26): We use this internal map so that assets loaded from
  // the server are removed. Otherwise they will reappear as loading if they are
  // deleted after being uploaded.
  const [internalDroppedFilesMap, setInternalDroppedFilesMap] = React.useState<
    Map<string, File>
  >(new Map());
  React.useEffect(() => {
    const newDroppedFilesMap = new Map(droppedFilesMap);
    setInternalDroppedFilesMap(newDroppedFilesMap);
  }, [droppedFilesMap]);
  React.useEffect(() => {
    setInternalDroppedFilesMap((val) => {
      const newMap = new Map(val);
      assets.forEach((asset) => {
        newMap.delete(asset.id);
      });
      return newMap;
    });
  }, [assets]);

  const uploadingAssets = React.useMemo(
    () =>
      Array.from(internalDroppedFilesMap.entries())
        .map(([id, file]) => ({
          id,
          name: file.name,
          url: URL.createObjectURL(file),
          type: file.type.split("/")[0] as AssetType,
          fileExtension: file.name.split(".").pop() ?? "",
          createdAt: new Date(),
          updatedAt: new Date(),
          sizeBytes: file.size,
          width: null,
          height: null,
          folderId: null,
          projectId: projectId ?? "",
          altText: null,
          embeddingText: null,
        }))
        .filter(({ id }) => !assets.some((a) => a.id === id)),
    [internalDroppedFilesMap, assets, projectId],
  );

  const dropDisabled = Boolean(searchTerm);

  const dropZoneRootProps = React.useMemo(() => {
    if (dropDisabled) {
      return undefined;
    }
    return getDropzoneRootProps?.({
      onClick: (event) => {
        if (assets.length > 0 || (uploadingAssets?.length ?? 0) > 0) {
          event.preventDefault();
          event.stopPropagation();
        }
      },
    });
  }, [getDropzoneRootProps, dropDisabled, assets, uploadingAssets]);

  const dropZoneInputProps = React.useMemo(() => {
    if (dropDisabled) {
      return undefined;
    }
    return getDropzoneInputProps?.();
  }, [getDropzoneInputProps, dropDisabled]);

  return (
    <div {...dropZoneRootProps} className="contents">
      {dropZoneInputProps && <input {...dropZoneInputProps} />}
      <AssetsGrid
        shouldAllowActions={shouldAllowActions}
        assets={assets}
        droppedAssets={uploadingAssets}
        isLoading={isLoading}
        isDragActive={isDragActive}
        onAssetSelected={onAssetSelected}
        hasNextPage={hasNextPage}
        fetchNextPage={() => void fetchNextPage()}
        searchTerm={searchTerm}
        emptyState={
          searchTerm ? (
            <NoSearchResults searchTerm={searchTerm} />
          ) : (
            <ReploAssetEmptyState />
          )
        }
      />
    </div>
  );
};

type AssetsGridProps = {
  assets: Asset[];
  searchTerm: string;
  droppedAssets?: Asset[];
  onAssetSelected?: (asset: Asset) => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  isDragActive?: boolean;
  emptyState: React.ReactNode;
  shouldAllowActions?: boolean;
};

const AssetsGrid: React.FC<AssetsGridProps> = ({
  searchTerm,
  hasNextPage,
  fetchNextPage,
  isLoading,
  isDragActive = false,
  assets,
  droppedAssets = [],
  emptyState,
  onAssetSelected,
  shouldAllowActions = false,
}) => {
  const gridRef = React.useRef<HTMLDivElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies(searchTerm): searchTerm changing is used to reset the scroll position
  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollTop = 0;
    }
  }, [searchTerm]);

  // NOTE (Gabe 2025-03-06): internalAssets is used to prevent flashes when
  // search results are loading.
  const [internalAssets, setInternalAssets] = React.useState<Asset[]>(assets);
  React.useEffect(() => {
    if (assets.length > 0 || !isLoading) {
      setInternalAssets(assets);
    }
  }, [assets, isLoading]);

  if (
    internalAssets.length === 0 &&
    droppedAssets.length === 0 &&
    !searchTerm &&
    isLoading
  ) {
    return <AssetsGridSkeleton length={7} />;
  }

  if (internalAssets.length === 0 && droppedAssets.length === 0) {
    return emptyState;
  }

  return (
    <div
      id="assets-grid"
      className="flex flex-1 flex-col pr-1 styled-scrollbar cursor-default overflow-scroll"
      ref={gridRef}
    >
      {isDragActive ? (
        <div className="flex flex-col flex-1 gap-3 justify-center self-stretch items-center bg-hover rounded border border-dashed border-border">
          <SvgAssetsEmptyState />
          <div className="flex flex-col gap-1 text-center typ-body-small text-muted p-2">
            <div className="typ-header-small">Drop assets here to upload</div>
            <div>Max {MAX_IMAGE_SIZE_BYTES / (1024 * 1024)}MB for images</div>
            <div> {MAX_VIDEO_SIZE_BYTES / (1024 * 1024)}MB for videos</div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={assets.length}
          next={() => void fetchNextPage()}
          hasMore={hasNextPage}
          scrollableTarget="assets-grid"
          loader={<AssetsGridSkeleton length={2} />}
        >
          {/* NOTE (Gabe 2025-03-03): InfiniteScroll doesn't handle grid well */}
          <div className="grid grid-cols-2 gap-2 pb-3 overflow-hidden w-full">
            {droppedAssets?.map((asset) => (
              <AssetCard
                key={asset.id}
                isUploading
                asset={asset}
                shouldAllowActions={shouldAllowActions}
                shouldAllowDrag={!Boolean(onAssetSelected)}
              />
            ))}
            {internalAssets.map((asset) => (
              <AssetCard
                key={asset.id}
                asset={asset}
                onClick={
                  onAssetSelected ? () => onAssetSelected(asset) : undefined
                }
                shouldAllowActions={shouldAllowActions}
                shouldAllowDrag={!Boolean(onAssetSelected)}
                className="h-full"
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

const EmptyState: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="flex flex-col flex-1 pr-3 w-full">
      <div className="flex flex-col gap-3 justify-center items-center bg-hover h-52 rounded border border-dashed border-border p-5">
        <SvgAssetsEmptyState />
        <div className="text-center typ-body-small text-muted">{children}</div>
      </div>
    </div>
  );
};

const NoSearchResults: React.FC<{ searchTerm: string }> = ({ searchTerm }) => {
  return (
    <EmptyState>
      <div className="typ-header-small">No results found</div>
      <div className="typ-body-small"> for &quot;{searchTerm}&quot;</div>
    </EmptyState>
  );
};

const ReploAssetEmptyState: React.FC = () => {
  const fileExtensions = [
    ...IMAGE_ACCEPT_FILE_EXTENSIONS,
    ...VIDEO_ACCEPT_FILE_EXTENSIONS,
  ];
  return (
    <EmptyState>
      <div className="typ-header-small">Click to upload</div>
      <div className="typ-body-small">
        {" "}
        or drag and drop{" "}
        {fileExtensions
          .map((val) => val.replace(".", "").toUpperCase())
          .join(", ")}
      </div>
    </EmptyState>
  );
};

const ShopifyAssetEmptyState: React.FC = () => {
  return (
    <EmptyState>
      <div className="typ-header-small">No Shopify assets found</div>
      <div className="typ-body-small">
        {" "}
        View your Shopify Files here, or upload through Replo for faster load
        times (recommended).
      </div>
    </EmptyState>
  );
};

const AssetsGridSkeleton: React.FC<{ length: number }> = ({ length }) => {
  return (
    <div className="grid grid-cols-2 gap-2 pb-3 pr-3 overflow-hidden w-full">
      {Array.from({ length }).map((_, index) => (
        <div key={index} className="flex flex-col gap-1">
          <Skeleton className="h-[105px] w-full" />
          <Skeleton className="h-3 w-full" />
          <Skeleton className="h-3 w-12" />
        </div>
      ))}
    </div>
  );
};
