// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { ReploProject } from "schemas/generated/project";

import * as React from "react";

import WorkspaceProvider from "@editor/components/WorkspaceProvider";
import { resetProductData } from "@editor/reducers/commerce-reducer";
import { selectProjectId, setProjectData } from "@editor/reducers/core-reducer";
import { getFieldMapping } from "@editor/reducers/utils/core-reducer-utils";
import { useEditorDispatch, useEditorStore } from "@editor/store";
import {
  getLastSelectedElementIdForProject,
  saveCurrentElementId,
} from "@editor/utils/localStorage";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { setCanvasHtml } from "@/features/canvas/canvas-reducer";
import { skipToken } from "@tanstack/react-query";
import mapValues from "lodash-es/mapValues";
import { matchPath, useNavigate, useSearchParams } from "react-router-dom";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";

type CurrentProjectContext = {
  project?: ReploProject;
  isLoading: boolean;
  // TODO (Fran 2024-08-29): improve this type
  error: any;
};

const CurrentProjectContext = React.createContext<CurrentProjectContext>({
  project: undefined,
  isLoading: false,
  error: undefined,
});

export const useCurrentProjectContext = () => {
  return React.useContext(CurrentProjectContext);
};

const CurrentProjectProvider: React.FC<
  React.PropsWithChildren<{
    projectId: string;
    navigateToDefaultElementAfterFetch: boolean;
  }>
> = ({ children, projectId, navigateToDefaultElementAfterFetch }) => {
  const { project, error, isLoading } = useGetProjectQuery(
    projectId ?? null,
    navigateToDefaultElementAfterFetch,
  );

  return (
    <WorkspaceProvider
      workspaceId={project?.ownerWorkspaceId}
      saveWorkspaceIdAsLastVisited
    >
      <CurrentProjectContext.Provider
        value={{
          project,
          error,
          isLoading,
        }}
      >
        {children}
      </CurrentProjectContext.Provider>
    </WorkspaceProvider>
  );
};

export default CurrentProjectProvider;

function useGetProjectQuery(
  projectId: string | null,
  navigateToDefaultElementAfterFetch: boolean,
) {
  // Note (Ben O. 2025-02-25): We use refetchOnMount: "always" because when a users goes from project 1 -> dashboard -> project 1,
  // the projectId does not change so we do not invalidate these queries but the projectData is stale.
  const {
    data: projectData,
    isSuccess: isProjectSuccess,
    isLoading: isProjectLoading,
    error,
    refetch,
  } = trpc.project.get.useQuery(projectId ?? skipToken, {
    refetchOnMount: "always",
  });

  const {
    data: elementMetadata,
    isLoading: isElementMetadataLoading,
    isSuccess: isElementMetadataSuccess,
  } = trpc.element.listAllElementsMetadata.useQuery(
    projectId ? { projectId } : skipToken,
    {
      refetchOnMount: "always",
    },
  );

  const isLoading = isProjectLoading || isElementMetadataLoading;
  const dispatch = useEditorDispatch();
  const store = useEditorStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const existingProjectId = selectProjectId(store.getState());

    // NOTE (Fran 2024-08-23): Reinitialize the project and reset all product data whenever
    // the user switches to a different project.
    if (existingProjectId !== projectId) {
      dispatch(resetProductData());
      dispatch(setCanvasHtml(""));
      void trpcUtils.element.getById.invalidate();
      void trpcUtils.element.listAllElementsMetadata.invalidate();
      void trpcUtils.project.get.invalidate();
    }
  }, [projectId, store, dispatch]);

  React.useEffect(() => {
    // NOTE (Fran 2024-08-23): Initialize the project only once when data is available.
    // Re-initialization occurs only if the project changes.
    if (
      projectData &&
      isProjectSuccess &&
      elementMetadata &&
      isElementMetadataSuccess
    ) {
      const elementMetadataMapping = getFieldMapping(
        elementMetadata ?? [],
        "id",
      );
      const versionMapping = mapValues(
        elementMetadataMapping,
        (value) => value.version,
      );

      let pathMatch = null;
      pathMatch = matchPath(routes.editor.element, window.location.pathname);

      const paramElementId = pathMatch?.params?.elementId || null;

      const localStorageElementId = paramElementId
        ? null
        : getLastSelectedElementIdForProject(projectData.id);

      const validLocalStorageId =
        localStorageElementId &&
        elementMetadataMapping[localStorageElementId]?.projectId ===
          projectData.id;

      const draftElementIdFromUrlOrLocalStorage =
        paramElementId || (validLocalStorageId ? localStorageElementId : null);

      const draftElement =
        getFromRecordOrNull(
          elementMetadataMapping,
          draftElementIdFromUrlOrLocalStorage,
        ) ??
        elementMetadata?.[0] ??
        null;

      if (localStorageElementId !== draftElement?.id && draftElement) {
        saveCurrentElementId(
          draftElement.id,
          elementMetadataMapping[draftElement.id]!.projectId,
        );
      }

      dispatch(
        setProjectData({
          project: projectData,
          draftElementId: draftElement?.id,
          versionMapping,
        }),
      );

      const isNewElementPath = matchPath(
        "/editor/:projectId/new",
        window.location.pathname,
      );

      if (!draftElement) {
        const path = `/editor/${projectData.id}/new`;

        navigate(path, {
          replace: true,
        });
      } else if (
        navigateToDefaultElementAfterFetch &&
        !isNewElementPath &&
        !paramElementId
      ) {
        const path = `/editor/${projectData.id}/${draftElement.id}`;

        // Preserve all existing search params
        const searchString = searchParams.toString();
        const targetPath = searchString ? `${path}?${searchString}` : path;

        navigate(targetPath, {
          replace: true,
        });
      }
    }
  }, [
    projectData,
    isProjectSuccess,
    elementMetadata,
    isElementMetadataSuccess,
    dispatch,
    navigate,
    searchParams,
    navigateToDefaultElementAfterFetch,
  ]);

  React.useEffect(() => {
    if ((error as any)?.error?.status === 401) {
      window.location.href = "/auth/login";
    }
  }, [error]);

  return { project: projectData, refetch, isLoading, error };
}
