import type { SavedColorStyle } from "schemas/generated/designLibrary";
import type { SavedStyleColorAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import { COLOR_DEFAULTS } from "@common/constants/colorStyles";
import { ColorSavedStyleModifier } from "@editor/components/designLibrary/ColorSavedStyleModifier";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { generateNewName } from "@editor/utils/designLibrary";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { Badge } from "@replo/design-system/components/badge/Badge";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { Pencil, Plus, X } from "lucide-react";

import ColorSavedStyleSkeleton from "./ColorSavedStyleSkeleton";

interface ColorSavedStyleGroupProps {
  isLoading: boolean;
  createDraftColorStyle: (style: SavedColorStyle) => { id: string };
  updateDraftColorStyle: (style: SavedColorStyle & { id: string }) => void;
  deleteDraftColorStyle: (styleId: string) => void;
  draftStyles: {
    color: (SavedColorStyle & { id: string })[];
  };
}

const ColorSavedStyleGroup: React.FC<ColorSavedStyleGroupProps> = ({
  isLoading,
  createDraftColorStyle,
  updateDraftColorStyle,
  deleteDraftColorStyle,
  draftStyles,
}) => {
  const [colorStyleBeingEditedId, setColorStyleBeingEditedId] = React.useState<
    string | null
  >(null);
  const [colorModifierPopoverIsOpen, setColorModifierPopoverIsOpen] =
    React.useState(false);

  const handleUpdateColorStyle = (
    updatedStyle: SavedColorStyle & { id: string },
  ) => {
    updateDraftColorStyle(updatedStyle);
  };

  const handleDeleteColorStyle = (styleId: string) => {
    deleteDraftColorStyle(styleId);
  };

  const colorSavedStyles = draftStyles?.color ?? [];

  const projectId = useCurrentProjectId();

  const handleNewColor = async () => {
    if (!projectId) {
      return null;
    }

    const newName = generateNewName(colorSavedStyles, "color");

    const savedStyleToCreate = {
      name: newName,
      type: "color" as const,
      attributes: {
        type: "color" as const,
        color: COLOR_DEFAULTS["basic"] ?? "#000000",
        colorType: "basic" as const,
      } as SavedStyleColorAttributes,
    };

    const { id } = createDraftColorStyle(savedStyleToCreate);

    setColorModifierPopoverIsOpen(true);
    setColorStyleBeingEditedId(id);
  };

  const selectedColorStyle = colorSavedStyles.find(
    (savedStyle) => savedStyle.id === colorStyleBeingEditedId,
  );

  const handleColorClick = (styleId: string) => {
    setColorModifierPopoverIsOpen(true);
    setColorStyleBeingEditedId(styleId);
  };

  return (
    <div className="mt-4">
      {selectedColorStyle && (
        <ColorSavedStyleModifier
          isPopoverOpen={colorModifierPopoverIsOpen}
          onPopoverOpenChange={(isOpen) => {
            if (!isOpen) {
              setColorStyleBeingEditedId(null);
            }
            setColorModifierPopoverIsOpen(isOpen);
          }}
          colorSavedStyle={selectedColorStyle}
          handleUpdateColorSavedStyle={handleUpdateColorStyle}
        />
      )}
      <ModifierGroup title="Color Styles" icon={undefined}>
        <ColorStylesGrid
          colorSavedStyles={colorSavedStyles}
          isLoading={isLoading}
          onColorClick={handleColorClick}
          onDeleteColor={handleDeleteColorStyle}
          onAddNewColor={() => void handleNewColor()}
        />
      </ModifierGroup>
    </div>
  );
};

export const ColorStylesGrid: React.FC<{
  colorSavedStyles: (SavedColorStyle & { id: string })[];
  isLoading?: boolean;
  modificationEnabled?: boolean;
  onColorClick?: (styleId: string) => void;
  onDeleteColor?: (styleId: string) => void;
  onAddNewColor?: () => void;
  showAddButton?: boolean;
  showTooltips?: boolean;
}> = ({
  colorSavedStyles,
  isLoading = false,
  modificationEnabled = true,
  onColorClick,
  onDeleteColor,
  onAddNewColor,
  showAddButton = true,
  showTooltips = false,
}) => {
  return (
    <div className="grid grid-cols-[repeat(auto-fill,minmax(30px,30px))] justify-start gap-2">
      {isLoading ? (
        <ColorSavedStyleSkeleton />
      ) : (
        <>
          {colorSavedStyles.map((savedStyle, index) => (
            <ColorSavedStyleBadge
              key={index}
              color={savedStyle.attributes.color}
              name={savedStyle.name}
              modificationEnabled={modificationEnabled}
              onClick={
                onColorClick ? () => onColorClick(savedStyle.id) : undefined
              }
              onDelete={
                onDeleteColor ? () => onDeleteColor(savedStyle.id) : undefined
              }
              showTooltip={showTooltips}
            />
          ))}
          {showAddButton && onAddNewColor && (
            <button
              className="w-8 h-8 flex items-center justify-center rounded border-1 border-border bg-neutral-soft hover:bg-light-surface-hover border-dashed"
              onClick={() => onAddNewColor()}
              aria-label="Add new color style"
            >
              <Plus size={12} className="text-muted" />
            </button>
          )}
        </>
      )}
    </div>
  );
};

const ColorSavedStyleBadge: React.FC<{
  color: string;
  name?: string;
  onDelete?: () => void;
  onClick?: () => void;
  modificationEnabled?: boolean;
  showTooltip?: boolean;
}> = ({
  color,
  name,
  onDelete,
  onClick,
  modificationEnabled = true,
  showTooltip = true,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent button click
    if (onDelete) {
      onDelete();
    }
  };

  const badgeButton = (
    <button className="relative cursor-pointer" onClick={handleClick}>
      <Badge
        type="color"
        isFilled
        backgroundColor={color}
        UNSAFE_className="w-8 h-8 border-1 border-secondary"
      />
      {modificationEnabled && isHovered && (
        <div className="absolute inset-0 flex items-center justify-center bg-background bg-light-surface rounded">
          <Pencil size={12} className="text-muted" />
        </div>
      )}
    </button>
  );

  const badgeContent = (
    <div
      className="relative group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {showTooltip && name ? (
        <Tooltip content={name} triggerAsChild={true}>
          {badgeButton}
        </Tooltip>
      ) : (
        badgeButton
      )}

      {modificationEnabled && isHovered && onDelete && (
        <button
          className="absolute -top-[8px] -right-[8px] w-4 h-4 rounded-full bg-white flex items-center justify-center hover:bg-light-surface-hover border-0.5 border-border"
          onClick={handleDeleteClick}
          aria-label="Delete color style"
        >
          <X size={10} className="text-muted" />
        </button>
      )}
    </div>
  );

  return badgeContent;
};

export default ColorSavedStyleGroup;
