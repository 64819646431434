import * as React from "react";

export const FieldWithDescription: React.FC<
  React.PropsWithChildren<{ description?: string }>
> = ({ description, children }) => {
  return (
    <div className="flex flex-col gap-1.5">
      <div className="flex flex-col gap-2">{children}</div>
      {description && (
        <div className="text-xs leading-tight text-muted">{description}</div>
      )}
    </div>
  );
};
