import type { Operator } from "replo-runtime/shared/enums";
import type {
  ConditionStatement,
  Option,
  StoreProduct,
} from "replo-runtime/shared/types";
import type { ConditionField } from "schemas/generated/symbol";
import type { ProductRefOrDynamic } from "schemas/product";

import * as React from "react";

import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";

import {
  ConditionFieldEditorValue,
  ConditionFieldType,
} from "replo-runtime/shared/enums";
import { getProduct, isContextRef } from "replo-runtime/store/ReploProduct";

import { getConditionFieldDisplayName } from "./conditionFieldToDisplayName";

type ConditionEditorDataFormatExtras = {
  products: StoreProduct[];
};
interface ConditionEditorData {
  fieldType: ConditionFieldType;
  displayName: string;
  /* Populated later */
  operatorOptions: Option[];
  editorConfig: ConditionFieldEditorValueConfig;
  defaultValue?: string | number;
  isValid?(value: ConditionStatement): boolean;
  formatDisplayValue?(
    value: any,
    extras: ConditionEditorDataFormatExtras,
  ): React.ReactNode;
}

interface OperatorEditorData {
  displayName: string;
  shortName: string;
}

const operatorToEditorData: Record<Operator, OperatorEditorData> = {
  excludes: {
    displayName: "excludes",
    shortName: "excludes",
  },
  includes: {
    displayName: "includes",
    shortName: "in",
  },
  neq: {
    displayName: "not equals",
    shortName: "≠",
  },
  eq: {
    displayName: "equals",
    shortName: "=",
  },
  gt: {
    displayName: "greater than",
    shortName: ">",
  },
  gte: {
    displayName: "greater than/equal to",
    shortName: "≥",
  },
  lt: {
    displayName: "less than",
    shortName: "<",
  },
  lte: {
    displayName: "less than/equal to",
    shortName: "≤",
  },
  all: {
    displayName: "all",
    shortName: "all",
  },
};

const getConditionOperatorEditorData = (o: Operator): OperatorEditorData => {
  return operatorToEditorData[o];
};

const getOperatorOptions = (operators: Operator[]): any => {
  return operators.map((o: Operator) => {
    const data = getConditionOperatorEditorData(o);
    return {
      label: data.displayName,
      value: o,
    };
  });
};

const OperatorOptions: Record<ConditionFieldType, Option[]> = {
  [ConditionFieldType.PIXEL]: getOperatorOptions([
    "lt",
    "lte",
    "gt",
    "gte",
    "eq",
    "neq",
  ]),
  [ConditionFieldType.INTEGER]: getOperatorOptions([
    "lt",
    "lte",
    "gt",
    "gte",
    "eq",
    "neq",
  ]),
  [ConditionFieldType.EVENT]: [],
  [ConditionFieldType.PAGE]: [],
  [ConditionFieldType.BOOLEAN]: [],
  [ConditionFieldType.PRODUCT_VARIANT]: getOperatorOptions(["eq", "neq"]),
  [ConditionFieldType.HASHMARK]: getOperatorOptions(["eq", "neq"]),
  [ConditionFieldType.DATA_TABLE_ROW]: getOperatorOptions(["eq", "neq"]),
  [ConditionFieldType.TEMPLATE_PRODUCT]: getOperatorOptions(["eq", "neq"]),
  [ConditionFieldType.PRODUCT]: getOperatorOptions(["eq", "neq"]),
};

type ConditionFieldEditorValueConfig =
  | { type: ConditionFieldEditorValue.NONE }
  | { type: ConditionFieldEditorValue.DATA_TABLE_ROW }
  | { type: ConditionFieldEditorValue.PRODUCT_VARIANT }
  | { type: ConditionFieldEditorValue.INPUT; placeholder: string }
  | { type: ConditionFieldEditorValue.INPUT_WITH_STEPS; placeholder: string }
  | { type: ConditionFieldEditorValue.TEMPLATE_PRODUCT }
  | { type: ConditionFieldEditorValue.PRODUCT };

const EditorValue: Record<ConditionFieldType, ConditionFieldEditorValueConfig> =
  {
    [ConditionFieldType.PIXEL]: {
      type: ConditionFieldEditorValue.INPUT_WITH_STEPS,
      placeholder: "0",
    },
    [ConditionFieldType.EVENT]: { type: ConditionFieldEditorValue.NONE },
    [ConditionFieldType.BOOLEAN]: {
      type: ConditionFieldEditorValue.INPUT,
      placeholder: "",
    },
    [ConditionFieldType.PAGE]: {
      type: ConditionFieldEditorValue.INPUT,
      placeholder: "0",
    },
    [ConditionFieldType.DATA_TABLE_ROW]: {
      type: ConditionFieldEditorValue.DATA_TABLE_ROW,
    },
    [ConditionFieldType.HASHMARK]: {
      type: ConditionFieldEditorValue.INPUT,
      placeholder: "#",
    },
    // TODO (Noah, 20201-08-06): We should probably not use Inputs for integers
    [ConditionFieldType.INTEGER]: {
      type: ConditionFieldEditorValue.INPUT,
      placeholder: "0",
    },
    [ConditionFieldType.PRODUCT_VARIANT]: {
      type: ConditionFieldEditorValue.PRODUCT_VARIANT,
    },
    [ConditionFieldType.TEMPLATE_PRODUCT]: {
      type: ConditionFieldEditorValue.TEMPLATE_PRODUCT,
    },
    [ConditionFieldType.PRODUCT]: {
      type: ConditionFieldEditorValue.PRODUCT,
    },
  };

function getDefaultOperatorValue(type: ConditionFieldType) {
  switch (type) {
    case ConditionFieldType.PIXEL:
      return "50px";
    case ConditionFieldType.INTEGER:
      return 0;
    default:
      return null;
  }
}

const partialConditionFieldEditorData: Record<
  ConditionField,
  // NOTE: (Kurt, 2024-07-08) We ommit the displayName field here and populate it later while constructing
  // `ConditionFieldEditorData`. This is done to prevent a circular import error where
  // the `selectDraftElement_warningThisWillRerenderOnEveryUpdate` selector is refferred to before being
  // initialized. The conditionfieldToDisplayName.tsx file acts as a source of truth for the display names
  // of condition fields.
  Omit<ConditionEditorData, "displayName">
> = {
  "state.temporaryCart.numberOfItems": {
    fieldType: ConditionFieldType.INTEGER,
    operatorOptions: OperatorOptions[ConditionFieldType.INTEGER],
    defaultValue:
      getDefaultOperatorValue(ConditionFieldType.INTEGER) ?? undefined,
    editorConfig: EditorValue[ConditionFieldType.INTEGER],
    isValid: (statement) => {
      return (
        statement?.value !== undefined && statement?.operator !== undefined
      );
    },
  },
  "state.temporaryCart.containsVariant": {
    fieldType: ConditionFieldType.PRODUCT_VARIANT,
    operatorOptions: OperatorOptions[ConditionFieldType.PRODUCT_VARIANT],
    defaultValue:
      getDefaultOperatorValue(ConditionFieldType.PRODUCT_VARIANT) ?? undefined,
    editorConfig: EditorValue[ConditionFieldType.PRODUCT_VARIANT],
    isValid: (statement) => {
      return statement?.value !== undefined;
    },
    formatDisplayValue: (value, extras) => {
      if (isContextRef(value)) {
        return <div className="mr-1 text-base font-medium">Dynamic Value</div>;
      }
      return (
        <div className="flex flex-col items-start text-base">
          <div className="mr-1 font-medium">Temporary Cart Contains</div>
          <div className="text-xs text-gray-600">
            <ProductVariantName productRef={value} products={extras.products} />
          </div>
        </div>
      );
    },
  },
  "element.offsetY": {
    fieldType: ConditionFieldType.PIXEL,
    operatorOptions: OperatorOptions[ConditionFieldType.PIXEL],
    defaultValue:
      getDefaultOperatorValue(ConditionFieldType.PIXEL) ?? undefined,
    editorConfig: EditorValue[ConditionFieldType.PIXEL],
    isValid: (statement) => {
      return (
        statement?.value !== undefined && statement?.operator !== undefined
      );
    },
  },
  "state.product.quantity": {
    fieldType: ConditionFieldType.INTEGER,
    operatorOptions: OperatorOptions[ConditionFieldType.INTEGER],
    defaultValue:
      getDefaultOperatorValue(ConditionFieldType.INTEGER) ?? undefined,
    editorConfig: EditorValue[ConditionFieldType.INTEGER],
    isValid: (statement) => {
      return (
        statement?.value !== undefined && statement?.operator !== undefined
      );
    },
  },
  "screen.pageY": {
    fieldType: ConditionFieldType.PIXEL,
    operatorOptions: OperatorOptions[ConditionFieldType.PIXEL],
    defaultValue:
      getDefaultOperatorValue(ConditionFieldType.PIXEL) ?? undefined,
    editorConfig: EditorValue[ConditionFieldType.PIXEL],
    isValid: (statement) => {
      return (
        statement?.value !== undefined &&
        statement?.operator !== undefined &&
        statement?.value > 0
      );
    },
  },
  "interaction.clickEvent": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.product.noProductVariantSelected": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">
          No product variant selected
        </div>
      );
    },
  },
  "page.hashmark": {
    fieldType: ConditionFieldType.HASHMARK,
    operatorOptions: OperatorOptions[ConditionFieldType.HASHMARK],
    defaultValue:
      getDefaultOperatorValue(ConditionFieldType.HASHMARK) ?? undefined,
    editorConfig: EditorValue[ConditionFieldType.HASHMARK],
  },
  "collectionSelect.item": {
    fieldType: ConditionFieldType.DATA_TABLE_ROW,
    operatorOptions: OperatorOptions[ConditionFieldType.DATA_TABLE_ROW],
    editorConfig: EditorValue[ConditionFieldType.DATA_TABLE_ROW],
  },
  "interaction.hover": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.action.loading": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.dropdown.selectedItem": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.collapsibleV2.isOpen": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.product.selectedVariant": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.product.selectedSellingPlan": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.product.isOptionValueUnavailable": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">
          Option In List Is Unavailable
        </div>
      );
    },
  },
  "state.product.isVariantUnavailable": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">
          Variant in List Is Unavailable
        </div>
      );
    },
  },
  "state.product.selectedVariantUnavailable": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">
          Selected Variant Unavailable
        </div>
      );
    },
  },
  product_variant_is_on_sale: {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">
          Product Variant Is On Sale
        </div>
      );
    },
  },
  "state.selectionList.isItemSelected": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.product.currentVariantIsOnSale": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">
          Variant In List Is On Sale
        </div>
      );
    },
  },
  "state.product.selectedOptionValues": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.carouselV2.isCurrentIndicatorItem": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">
          This is the current carousel item
        </div>
      );
    },
  },
  "state.carouselV2.isAtStart": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">Carousel Is At Start</div>
      );
    },
  },
  "state.carouselV2.isAtEnd": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">Carousel Is At End</div>
      );
    },
  },
  "state.tabsBlock.isCurrentTab": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">This is the active tab</div>
      );
    },
  },
  "state.tabsV2Block.isCurrentTab": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">This is the active tab</div>
      );
    },
  },
  "state.group.isCurrentItemActive": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">Current item is active</div>
      );
    },
  },
  "state.group.isFirstItemActive": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">First item is active</div>
      );
    },
  },
  "state.group.isLastItemActive": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
    formatDisplayValue: function formatDisplayValue() {
      return (
        <div className="mr-1 text-base font-medium">Last item is active</div>
      );
    },
  },
  "state.product.templateProductEquals": {
    fieldType: ConditionFieldType.TEMPLATE_PRODUCT,
    operatorOptions: OperatorOptions[ConditionFieldType.TEMPLATE_PRODUCT],
    editorConfig: EditorValue[ConditionFieldType.TEMPLATE_PRODUCT],
    formatDisplayValue: function formatDisplayValue() {
      return <div className="mr-1 text-base font-medium">Template Product</div>;
    },
  },
  "state.product.selectedProductEquals": {
    fieldType: ConditionFieldType.PRODUCT,
    operatorOptions: OperatorOptions[ConditionFieldType.PRODUCT],
    editorConfig: EditorValue[ConditionFieldType.PRODUCT],
    formatDisplayValue: function formatDisplayValue() {
      return <div className="mr-1 text-base font-medium">Template Product</div>;
    },
  },
  "state.product.selectedVariantEquals": {
    fieldType: ConditionFieldType.PRODUCT_VARIANT,
    operatorOptions: OperatorOptions[ConditionFieldType.PRODUCT_VARIANT],
    editorConfig: EditorValue[ConditionFieldType.PRODUCT_VARIANT],
    isValid: (statement) => {
      return statement?.value !== undefined;
    },
    formatDisplayValue: () => {
      return (
        <div className="flex flex-col items-start text-base">
          <div className="mr-1 font-medium">Selected Variant</div>
        </div>
      );
    },
  },
  "state.beforeAfterSlider.isDragging": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
  "state.tooltip.isOpen": {
    fieldType: ConditionFieldType.EVENT,
    operatorOptions: OperatorOptions[ConditionFieldType.EVENT],
    editorConfig: EditorValue[ConditionFieldType.EVENT],
  },
};

export const getConditionFieldEditorData = (
  conditionField: ConditionField,
): ConditionEditorData => {
  const conditionFieldEditorData: Record<ConditionField, ConditionEditorData> =
    {} as Record<ConditionField, ConditionEditorData>;

  for (const [field, data] of Object.entries(partialConditionFieldEditorData)) {
    conditionFieldEditorData[field as ConditionField] = {
      ...data,
      displayName: getConditionFieldDisplayName(field as ConditionField),
    };
  }
  const conditionData = conditionFieldEditorData[conditionField];
  return conditionData;
};

export const operationToSymbol: Record<Operator, string> = {
  eq: "=",
  neq: "≠",
  gt: ">",
  lt: "<",
  gte: "≥",
  lte: "≤",
  all: "A",
  includes: "⊃",
  excludes: "!",
};

function ProductVariantName({
  productRef,
  products,
}: {
  productRef: ProductRefOrDynamic | null;
  products: StoreProduct[];
}) {
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);
  const templateEditorProduct = useEditorSelector(
    selectTemplateEditorStoreProduct,
  );
  return (
    <>
      {
        getProduct(productRef, null, {
          productMetafieldValues: {},
          variantMetafieldValues: {},
          products,
          currencyCode: activeCurrency,
          language: activeLanguage,
          moneyFormat,
          templateProduct: templateEditorProduct ?? null,
          isEditor: true,
          isShopifyProductsLoading: false,
        })?.variant?.name
      }
    </>
  );
}
