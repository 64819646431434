import type { ChartInterval, SelectedTimePeriod } from "replo-utils/analytics";
import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import { DEFAULT_FILTERS } from "@/features/analytics/constants";
import {
  DEFAULT_CHART_INTERVAL,
  DEFAULT_COMPARE_RANGE_TIME_FRAME,
  DEFAULT_MAIN_RELATIVE_TIME_FRAME,
} from "replo-utils/analytics";

/**
 * NOTE (Max, 2024-12-15):
 * The final AnalyticsReadQuery send to the backend is contructed in
 * useBasicAnalyticsRead() by combining the UrlSyncedQuery and the InternalQuery.
 *
 * Our AnalyticsContext exposes these two objects.
 *
 * UrlSyncedQuery lives in the URL params, in the ?query='...' param. It MUST always be synced
 * with the urlSyncedQuery object exposed by the AnalyticsContext.
 *
 * InternalQuery lives "internally" (i.e. is not present in the URL).
 *
 * UrlSyncedQuery keeps track of the filters, urlHosts, and two other fields: selected time period
 * and selected compare period. These last two fields are then used in useBasicAnalyticsRead() to contruct
 * the actual ranges (with startDatetime/endDatetime/interval), which are sent to the backend when querying CH.
 *
 * InternalQuery keeps track of the rest of the AnalyticsReadQuery fields that don't need to be synced
 * with the URL (order/metrics/sortMetric etc..)
 *
 * @author Max 2024-12-15
 */
export type UrlSyncedQuery = {
  filters: AnalyticsReadQuery["filters"];
  urlHosts: AnalyticsReadQuery["urlHosts"];
  // NOTE (Max, 2024-12-15): These two fields are used in useBasicAnalyticsRead() to
  // contruct the ranges.
  selectedTimePeriod: SelectedTimePeriod;
  selectedComparePeriod: SelectedTimePeriod;
  chartInterval: ChartInterval;
};

export type InternalQuery = Omit<
  AnalyticsReadQuery,
  "urlHosts" | "ranges" | "filters"
>;

export function generateDefaultUrlSyncedQuery({
  urlHosts,
  overrides,
}: {
  urlHosts: string[];
  overrides?: Partial<Omit<UrlSyncedQuery, "urlHosts">>;
}): UrlSyncedQuery {
  const defaultUrlSyncedQuery: UrlSyncedQuery = {
    selectedTimePeriod: {
      type: "relative",
      value: DEFAULT_MAIN_RELATIVE_TIME_FRAME,
    },
    selectedComparePeriod: {
      type: "relative",
      value: DEFAULT_COMPARE_RANGE_TIME_FRAME,
    },
    chartInterval: DEFAULT_CHART_INTERVAL,
    filters: DEFAULT_FILTERS,
    // TODO (Max, 2024-12-15, ANA-176): We're currently only allowing 1 domain to be selected
    // for the analytics queries (see ticket description). Once we implement ANA-176, we'll
    // be able to update this to allow multiple domains to be selected.
    urlHosts: urlHosts[0] ? [urlHosts[0]] : [],
  };

  return { ...defaultUrlSyncedQuery, ...overrides };
}
