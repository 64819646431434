import * as React from "react";

import DashboardSidebar from "@editor/components/dashboard/sidebar/DashboardSidebar";
import { DebugModal } from "@editor/components/editor/debug/DebugPanel";
import Flows from "@editor/components/flows/Flows";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import {
  selectInternalDebugModeOn as selectDevDebugPanelVisibility,
  setDebugPanelVisibility,
} from "@reducers/core-reducer";

import { ToastManager } from "@replo/design-system/components/alert/Toast";

export default function DashboardLayout({ children }: React.PropsWithChildren) {
  const dispatch = useEditorDispatch();
  const isDebugPanelVisible = useEditorSelector(selectDevDebugPanelVisibility);
  const toggleDebugMode = React.useCallback(() => {
    dispatch(setDebugPanelVisibility(!isDebugPanelVisible));
  }, [dispatch, isDebugPanelVisible]);
  useReploHotkeys({ debug: toggleDebugMode });

  const { user } = useCurrentUser();
  const params = new URLSearchParams({ utm_source: "dashboard" });

  if (user) {
    params.append("email", user.email);
    if (user.firstName) {
      params.append("name", user.firstName);
    }
  }

  return (
    <div className="flex flex-col h-screen">
      <ToastManager />
      {user && <Flows entityType="user" />}
      {isDebugPanelVisible && <DebugModal />}
      <div
        className="grid grow w-screen gap-x-4 overflow-hidden bg-white"
        style={{
          gridTemplateColumns: "250px 1fr",
        }}
      >
        <DashboardSidebar />
        <div
          className="no-scrollbar overflow-scroll"
          // NOTE (Fran 2023-10-03): we need to calculate the exact height of the content without
          // the header to avoid have the wrong size.
          style={{ height: "calc(100vh - 35px)" }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
