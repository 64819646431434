import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const PaymentTerms: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const selectedVariantId = context.state.product?.selectedVariant?.id;
  const product = useProductFromProps(component.props, context);

  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: `{% form 'product', product, id: product_form_id, class: 'form', novalidate: 'novalidate', data-type: 'add-to-cart-form' %}
    <input type="hidden" name="id" value="${selectedVariantId ?? product?.id}" disabled />
    <div class="payment-terms">
        {{ form | payment_terms }}
    </div>
  {% endform %}`,
      context: context,
    },
  );

  return (
    <SharedShopifyLiquid
      liquidSource={liquidSourceWithProductAssignment}
      componentId={component.id}
      componentAttributes={componentAttributes}
      isLiquidSupported={true}
      repeatedIndexPath={context.repeatedIndexPath}
      title="Payment Terms"
    />
  );
};

export default PaymentTerms;
