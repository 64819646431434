import type {
  AggregationMetric,
  Industry,
} from "@/features/analytics/insights/charts";
import type { ChartConfig } from "@replo/design-system/components/shadcn/core/bar-chart";
import type {
  AggregatedResult,
  AnalyticsReadQuery,
} from "schemas/generated/analyticsRead";

import * as React from "react";

import Separator from "@editor/components/common/designSystem/Separator";
import Header from "@editor/components/dashboard/Header";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { docs } from "@editor/utils/docs";
import { isDevelopment } from "@editor/utils/env";
import { trpc } from "@editor/utils/trpc";

import {
  BENCHMARK_END_DATETIME,
  BENCHMARK_START_DATETIME,
  CHART_COLORS,
  HARDCODED_CONVERSION_RATES_FOR_ALL_REPLO_PAGES,
} from "@/features/analytics/insights/charts";
import { InsightsCard } from "@/features/analytics/insights/InsightsCard";
import { SetupPage } from "@/features/analytics/Setup";
import useAnalyticsStatus from "@/features/analytics/useAnalyticsStatus";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import InlineAlert from "@replo/design-system/components/alert/InlineAlert";
import Button from "@replo/design-system/components/button/Button";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@replo/design-system/components/shadcn/core/bar-chart";
import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { skipToken } from "@tanstack/react-query";
import { addDays, startOfDay } from "date-fns";
import {
  LuArrowUpRight,
  LuCalendar,
  LuInfo,
  LuRefreshCw,
} from "react-icons/lu";
import { Bar, BarChart, Cell, LabelList, XAxis, YAxis } from "recharts";
import { PageTypeEnum } from "schemas/generated/analyticsRead";

import { InsightsAIMicrovacModal } from "./AnalyticsMicroVac";

const AGGREGATION_METRICS_OPTIONS: {
  value: AggregationMetric;
  label: string;
}[] = [
  {
    value: "average",
    label: "Average",
  },
  {
    value: "median",
    label: "Median",
  },
];

const INDUSTRY_OPTIONS: {
  value: Industry;
  label: string;
}[] = [
  {
    value: "all",
    label: "All Industries",
  },
  {
    value: "health",
    label: "Health",
  },
  {
    value: "beautyAndFitness",
    label: "Beauty & Fitness",
  },
  {
    value: "foodAndDrink",
    label: "Food & Drink",
  },
  {
    value: "apparel",
    label: "Apparel",
  },
  {
    value: "homeAndGarden",
    label: "Home & Garden",
  },
];
const AnalyticsInsights: React.FC = () => {
  const { isEnabled, isCurrentlyPaying, isLoading } = useAnalyticsStatus();

  const showInsights = isEnabled || isDevelopment;

  return (
    <div className="flex flex-col px-6 w-full relative overflow-hidden">
      <Header title="Insights" />
      <Separator className="my-4 col-span-12" />

      {!isLoading && (
        <>
          {showInsights ? (
            <LoadedInsights />
          ) : (
            <SetupPage isCurrentlyPaying={isCurrentlyPaying} />
          )}
        </>
      )}
    </div>
  );
};

const SkeletonBar: React.FC = () => {
  return <Skeleton className="h-[30px] w-full" />;
};

const SkeletonBars: React.FC = () => {
  return (
    <div className="flex flex-col gap-3 ml-10">
      <SkeletonBar />
      <SkeletonBar />
      <SkeletonBar />
    </div>
  );
};

type InsightsContainerProps = {
  title: string;
  children: React.ReactNode;
  isLoading: boolean;
};

const InsightsContainer: React.FC<InsightsContainerProps> = ({
  title,
  children,
  isLoading,
}) => {
  return (
    <div className="px-6 py-4 rounded-lg border-[0.5px] border-border flex flex-col gap-4">
      <span className="typ-label-base">{title}</span>
      {isLoading ? (
        <SkeletonBars />
      ) : (
        <div className="flex flex-col justify-center h-full">{children}</div>
      )}
    </div>
  );
};

function extractAggregation({
  aggregatedResults,
  group,
  metric,
}: {
  aggregatedResults: AggregatedResult[];
  group: AggregatedResult["group"];
  metric: "average" | "median";
}) {
  const groupResult = aggregatedResults.find(
    (result) => result.group === group,
  );

  return groupResult?.[metric] ?? 0;
}

function getYourPagesColorBasedOnAllReploPages({
  allReploPages,
  yourPages,
}: {
  allReploPages: number;
  yourPages: number;
}) {
  return yourPages > allReploPages
    ? CHART_COLORS.aboveThreshold
    : CHART_COLORS.belowThreshold;
}

type SubHeaderItem = {
  text: string;
  icon: React.ReactNode;
};

const HeaderWithTooltip: React.FC<{
  title: string;
  tooltipContent: string;
  subHeaderItems: SubHeaderItem[];
}> = ({ title, tooltipContent, subHeaderItems }) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row gap-1 items-center">
        <span className="typ-header-h3">{title}</span>
        <Tooltip triggerAsChild content={tooltipContent} delay={300}>
          <span className="text-muted">
            <LuInfo />
          </span>
        </Tooltip>
      </div>
      <div className="flex flex-row gap-3 typ-body-small text-muted w-full justify-start">
        {subHeaderItems.map((item, index) => (
          <div key={index} className="flex flex-row gap-1.5 items-center">
            {item.icon}
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const LoadedInsights: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;

  const [isInsightsAIMicrovacModalOpen, setIsInsightsAIMicrovacModalOpen] =
    React.useState(false);

  const logEvent = useLogAnalytics();

  const [aggregationMetric, setAggregationMetric] =
    React.useState<AggregationMetric>("average");

  const [industry, setIndustry] = React.useState<Industry>("all");

  const { data: workspaceUrlHosts } = useWorkspaceUrlHosts(workspaceId ?? "");

  const shouldFetch = workspaceId && workspaceUrlHosts?.length > 0;

  const interval = BENCHMARK_END_DATETIME - BENCHMARK_START_DATETIME;

  const analyticsReadQuery: AnalyticsReadQuery = {
    urlHosts: workspaceUrlHosts,
    metrics: ["unique_sessions", "conversion_rates"],
    order: "DESC",
    sortMetric: "unique_sessions",
    offset: 0,
    limit: 100,
    ranges: {
      mainRange: {
        id: "bb00d6bb-583f-44b4-bb9b-542d2f5239e6",
        startDatetime: BENCHMARK_START_DATETIME,
        endDatetime: BENCHMARK_END_DATETIME,
        interval,
      },
      compareAtRanges: [],
    },
    filters: {
      urlPath: [],
      pageType: PageTypeEnum.ENTRY_PAGES,
      urlParams: {},
    },
  };

  const { data, isLoading } = trpc.analytics.findAggregated.useQuery(
    shouldFetch
      ? {
          workspaceId,
          query: {
            analyticsReadQuery,
            minSessionsThreshold: 500,
          },
        }
      : skipToken,
  );

  const rangeStartDate = startOfDay(addDays(new Date(), -7));

  const { data: insights, isLoading: isInsightsLoading } =
    trpc.analytics.getInsights.useQuery(
      shouldFetch
        ? {
            workspaceId,
            rangeStart: rangeStartDate.valueOf(),
          }
        : skipToken,
    );

  const aggregatedResults = data?.aggregatedResults ?? [];

  const groups: AggregatedResult["group"][] = [
    "homepage",
    "/products",
    "/collections",
    "/pages",
    "all",
  ];

  const chartData = groups.map((group) => ({
    group,
    allReploPages:
      HARDCODED_CONVERSION_RATES_FOR_ALL_REPLO_PAGES[industry][
        aggregationMetric
      ][group],
    yourPages: extractAggregation({
      group: group,
      aggregatedResults,
      metric: aggregationMetric,
    }),
  }));

  const chartDataOverall = chartData.filter((item) => item.group === "all");
  const chartDataByGroupType = chartData.filter((item) => item.group !== "all");

  const shouldShowSkeleton = isLoading || aggregatedResults.length === 0;

  const { allReploPages: allReploPagesOverall, yourPages: yourPagesOverall } =
    chartDataOverall[0]!;

  const yourPagesOverallColor =
    yourPagesOverall < allReploPagesOverall
      ? CHART_COLORS.belowThreshold
      : CHART_COLORS.aboveThreshold;

  const chartConfigByGroup = {
    allReploPages: {
      label: "All Replo",
      color: CHART_COLORS.allReploPages,
    },
    yourPages: {
      label: "Your Pages",
    },
  } satisfies ChartConfig;

  const chartConfigOverall = {
    ...chartConfigByGroup,
    yourPages: {
      ...chartConfigByGroup.yourPages,
      color: yourPagesOverallColor,
    },
  } satisfies ChartConfig;

  const handleAggregationMetricChange = (value: string) => {
    logEvent("analytics.insights.filters.aggregationMetric.change", {
      workspaceId,
      newAggregationMetric: value as AggregationMetric,
    });
    setAggregationMetric(value as AggregationMetric);
  };

  const handleIndustryChange = (value: string) => {
    logEvent("analytics.insights.filters.industry.change", {
      workspaceId,
      newIndustry: value as Industry,
    });
    setIndustry(value as Industry);
  };

  const handleFeedbackClick = () => {
    window.open(docs.insights.feedbackForm, "_blank");
    logEvent("analytics.insights.feedbackLink.click", {
      workspaceId,
    });
  };

  const keyPagesSubHeaderItems = [
    {
      text: "Mar 17 - Mar 24",
      icon: <LuCalendar />,
    },
    {
      text: "Weekly",
      icon: <LuRefreshCw />,
    },
  ];

  const benchMarksSubHeaderItems = [
    {
      text: "February",
      icon: <LuCalendar />,
    },
    {
      text: "Monthly",
      icon: <LuRefreshCw />,
    },
  ];

  return (
    <div className="flex justify-center">
      <div className="flex flex-col gap-4 overflow-x-auto">
        <div className="flex flex-col gap-3 w-[1000px]">
          {insights && insights.length > 0 && (
            <div className="flex flex-col gap-4">
              <HeaderWithTooltip
                title="Key Pages"
                tooltipContent="Automatically generated insights across all your pages, comparing this week’s data to last week’s to highlight trends, opportunities, and improvements."
                subHeaderItems={keyPagesSubHeaderItems}
              />
              <div className="grid grid-cols-2 gap-3">
                {isInsightsLoading ? (
                  <>
                    <Skeleton className="w-full h-[135px]" />
                    <Skeleton className="w-full h-[135px]" />
                    <Skeleton className="w-full h-[135px]" />
                    <Skeleton className="w-full h-[135px]" />
                  </>
                ) : (
                  insights
                    ?.slice(0, 4)
                    .map((insight, index) => (
                      <InsightsCard key={index} insight={insight} />
                    ))
                )}
              </div>
            </div>
          )}

          <div className="flex flex-row gap-2">
            <div className="flex items-end flex-1">
              <div className="flex flex-col gap-1">
                <HeaderWithTooltip
                  title="Landing page benchmarks"
                  tooltipContent="We analyze high-traffic Replo stores with significant session volume to provide reliable, meaningful comparisons."
                  subHeaderItems={benchMarksSubHeaderItems}
                />
              </div>
              <div className="flex flex-row flex-1 justify-end">
                <div className="flex flex-row gap-2">
                  <Combobox.Root
                    options={AGGREGATION_METRICS_OPTIONS}
                    value={aggregationMetric}
                    onChange={handleAggregationMetricChange}
                  >
                    <Combobox.Trigger>
                      <Combobox.SelectionButton
                        title={
                          AGGREGATION_METRICS_OPTIONS.find(
                            (option) => option.value === aggregationMetric,
                          )?.label ?? "Select Metric"
                        }
                        size="sm"
                      />
                    </Combobox.Trigger>
                    <Combobox.Popover layoutClassName="w-[100px]">
                      <Combobox.Content />
                    </Combobox.Popover>
                  </Combobox.Root>

                  <Combobox.Root
                    options={INDUSTRY_OPTIONS}
                    value={industry}
                    onChange={handleIndustryChange}
                  >
                    <Combobox.Trigger>
                      <Combobox.SelectionButton
                        title={
                          INDUSTRY_OPTIONS.find(
                            (option) => option.value === industry,
                          )?.label ?? "Select Industry"
                        }
                        size="sm"
                      />
                    </Combobox.Trigger>
                    <Combobox.Popover layoutClassName="w-[130px]">
                      <Combobox.Content />
                    </Combobox.Popover>
                  </Combobox.Root>
                </div>
              </div>
            </div>
          </div>
          {chartDataOverall[0]?.yourPages === 0 && !isLoading && (
            <InlineAlert variant="info">
              <div className="flex flex-row justify-between items-center w-full">
                Benchmarks include pages with 500+ sessions from the last
                reporting period. Data updates monthly.
              </div>
            </InlineAlert>
          )}
          <div className="grid grid-cols-2 gap-4">
            <InsightsContainer
              title="Overall conversion rate"
              isLoading={shouldShowSkeleton}
            >
              <ChartContainer config={chartConfigOverall} className="h-[135px]">
                <BarChart
                  accessibilityLayer
                  data={chartDataOverall}
                  layout="vertical"
                  barGap={0}
                  margin={{ right: 100 }}
                >
                  <YAxis
                    dataKey="group"
                    width={120}
                    type="category"
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    hide
                  />
                  <XAxis type="number" hide />
                  <ChartTooltip
                    cursor={false}
                    content={
                      <ChartTooltipContent
                        className="bg-white"
                        valueFormatter={(value) => `${value.toFixed(2)}%`}
                        hideLabel
                      />
                    }
                  />

                  <Bar
                    dataKey="allReploPages"
                    fill="var(--color-allReploPages)"
                    radius={[4, 4, 4, 0]}
                    barSize={60}
                  >
                    <LabelList
                      position="right"
                      offset={20}
                      style={{ fill: CHART_COLORS.labelOutside }}
                      formatter={(value: number) => `${value.toFixed(2)}%`}
                    />
                    <LabelList
                      position="insideLeft"
                      offset={20}
                      style={{ fill: CHART_COLORS.labelInside, fontSize: 12 }}
                      formatter={() =>
                        `${chartConfigOverall.allReploPages.label}`
                      }
                    />
                  </Bar>
                  <Bar
                    dataKey="yourPages"
                    radius={[0, 4, 4, 4]}
                    fill="var(--color-yourPages)"
                    barSize={60}
                  >
                    <LabelList
                      position="right"
                      offset={20}
                      style={{ fill: CHART_COLORS.labelOutside }}
                      formatter={(value: number) =>
                        value > 0 ? `${value.toFixed(2)}%` : ""
                      }
                    />
                    <LabelList
                      dataKey="allReploPages"
                      position="insideLeft"
                      offset={20}
                      style={{ fill: CHART_COLORS.labelInside }}
                      fontSize={12}
                      formatter={() => `${chartConfigOverall.yourPages.label}`}
                    />
                  </Bar>
                </BarChart>
              </ChartContainer>
            </InsightsContainer>
            <InsightsContainer
              title="Conversion rate by page type"
              isLoading={shouldShowSkeleton}
            >
              <ChartContainer config={chartConfigByGroup} className="h-[135px]">
                <BarChart
                  accessibilityLayer
                  data={chartDataByGroupType}
                  layout="vertical"
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                  barGap={40}
                >
                  <YAxis
                    dataKey="group"
                    width={120}
                    type="category"
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                  />
                  <XAxis type="number" hide />
                  <ChartTooltip
                    cursor={false}
                    content={
                      <ChartTooltipContent
                        className="bg-white"
                        valueFormatter={(value) => `${value.toFixed(2)}%`}
                        formatter={(value, name, item) => {
                          const { allReploPages, yourPages } = item.payload;

                          const payloadItemLabel =
                            chartConfigByGroup[
                              name as keyof typeof chartConfigByGroup
                            ]?.label;

                          const yourPagesColor =
                            getYourPagesColorBasedOnAllReploPages({
                              allReploPages,
                              yourPages,
                            });

                          return (
                            <div className="flex flex-1 justify-between leading-none items-center">
                              <div className="flex flex-row gap-1.5 flex-1">
                                <div
                                  className="shrink-0 rounded-sm w-3 h-3"
                                  style={{
                                    backgroundColor:
                                      name === "allReploPages"
                                        ? CHART_COLORS.allReploPages
                                        : yourPagesColor,
                                  }}
                                />
                                <span className="text-muted-foreground">
                                  {payloadItemLabel}
                                </span>
                              </div>
                              <span className="font-mono font-medium tabular-nums text-foreground">
                                {`${(value as number).toFixed(2)}%`}
                              </span>
                            </div>
                          );
                        }}
                      />
                    }
                  />

                  <Bar
                    dataKey="allReploPages"
                    fill="var(--color-allReploPages)"
                    radius={[4, 4, 4, 0]}
                    barSize={10}
                  />
                  <Bar dataKey="yourPages" radius={[0, 4, 4, 4]} barSize={10}>
                    {/* NOTE (Max, 2025-03-05): We need to "manually" return each Cell as the normal flow offered
                by Shadcn & Recharts doesn't allow us to dynamically set the color of each bar. We need the bar to change
                color based on whether it's above or below the threshold. We ensure that this color is in sync with the
                TooltipContent by calling the same getYourPagesColorBasedOnAllReploPages() function in the tooltip content. */}
                    {chartDataByGroupType.map((entry, index) => {
                      const { allReploPages, yourPages } = entry;

                      const color = getYourPagesColorBasedOnAllReploPages({
                        allReploPages,
                        yourPages,
                      });

                      return <Cell key={`cell-${index}`} fill={color} />;
                    })}
                  </Bar>
                </BarChart>
              </ChartContainer>
            </InsightsContainer>
          </div>
          <InlineAlert
            variant="neutral"
            endEnhancer={
              <Button
                variant="tertiary"
                onClick={handleFeedbackClick}
                endEnhancer={<LuArrowUpRight />}
              >
                Send feedback
              </Button>
            }
          >
            New insights coming soon! Have a request or feedback? Let us know
            what would help you most
          </InlineAlert>
        </div>
        {isFeatureEnabled("debug-insights") && (
          <>
            <Button
              variant="primary"
              onClick={() => setIsInsightsAIMicrovacModalOpen(true)}
            >
              [INTERNAL] Open Insights AI Microvac
            </Button>
            <InsightsAIMicrovacModal
              isOpen={isInsightsAIMicrovacModalOpen}
              onClose={() => setIsInsightsAIMicrovacModalOpen(false)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyticsInsights;
