import type { ComponentDimensionsMap } from "@reducers/ai-reducer";
import type { Component } from "replo-runtime";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type {
  AIStreamingOperation,
  AITriggeringFeature,
  BuildAssistantParams,
  ChatMessage,
} from "schemas/generated/ai";
import type { SchemaReploShopifyProduct } from "schemas/generated/product";

import * as React from "react";

import * as coreActions from "@actions/core-actions";
import { useLocalStorage } from "@editor/hooks/useLocalStorage";
import {
  useAiDesignMutation,
  useAiMobileResponsiveMutation,
  useAiMultiMutation,
  useAiTemplateMutation,
  useAiTextV2Mutation,
} from "@editor/reducers/ai-reducer";
import {
  selectComponentDataMapping,
  selectDraftComponent,
  selectDraftComponentId,
  selectDraftElementId,
  selectEditorMode,
  selectProjectId,
  selectRootComponent,
  selectRootComponentId,
  selectStreamingUpdateActions,
  selectStreamingUpdateComponentIdsSelected,
  selectStreamingUpdateId,
  selectStreamingUpdateIsStreaming,
  setDraftElement,
  setEditorMode,
  setStreamingUpdate,
} from "@editor/reducers/core-reducer";
import { setSharedState } from "@editor/reducers/paint-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { getEditorComponentNode } from "@editor/utils/component";

import {
  scrollToComponentId,
  selectActiveCanvas,
  selectCanvasRelativeBoundingClientRect,
  selectVisibleEditorCanvases,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";
import useSetActiveCanvas from "@/features/canvas/useSetActiveCanvas";
import { useSetDeltaXY } from "@/features/canvas/useSetDeltaXY";
import omit from "lodash-es/omit";
import { forEachComponentAndDescendants } from "replo-runtime";
import { getSectionForComponentId } from "replo-runtime/shared/utils/component";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type TemplateStreamingParams = {
  type: "template";
  userPrompt: string;
  contextComponent?: Component;
  nonce: number;
  sectionType?: string;
  selectedProduct?: SchemaReploShopifyProduct | null;
  conversationMessages: ChatMessage[];
  components?: Component[];
  systemPrompt?: string;
  initialPrompt?: string;
  triggeringFeature?: AITriggeringFeature;
};

type AIStreamingSingleParams =
  | TemplateStreamingParams
  | {
      type: "design";
      userPrompt: string;
      nonce: number;
      conversationMessages: ChatMessage[];
    }
  | {
      type: "textV2";
      userPrompt: string;
    }
  | {
      type: "mobileResponsive";
    };

type AIStreamingParams =
  | AIStreamingSingleParams
  | {
      type: "multi";
      operations: AIStreamingSingleParams[];
    };

export type MenuTriggerSource =
  | "page-builder"
  | "pill"
  | "previewMenu"
  | "shortcut"
  | "rightClick"
  | "onboarding";

interface AIStreamingContextType {
  isAIPreLoading: boolean;
  setIsAIPreLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isBuildAssistantOpen: boolean;
  setIsBuildAssistantOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isGenerating: boolean;
  generationMode: AIStreamingOperation | null;
  generationSource: MenuTriggerSource | null;
  queueGeneration: (params: AIStreamingParams) => void;
  hasQueuedGeneration: boolean;
  initiateParallelGeneration: {
    (params: TemplateStreamingParams, onFinish?: () => void): void;
  };
  initiateGeneration: (
    params: AIStreamingParams,
    onFinish?: () => void,
    targetComponent?: Component,
  ) => Promise<void>;
  abort: () => void;
  isFinished: boolean;
  applyChanges: () => void;
  discardChanges: () => void;
  revert: () => void;
  status: AIStatus;
  completionPercentage?: number;
  isParallelGenerating: boolean;
}
type AIStatus =
  | "preGeneration"
  | "generationInitialized"
  | "generating"
  | "finishedGenerating"
  | "autoApplyingChanges";

const AIStreamingContext = React.createContext<
  AIStreamingContextType | undefined
>(undefined);
AIStreamingContext.displayName = "AIStreamingContext";

export const useAIStreaming = (): AIStreamingContextType => {
  const context = React.useContext(AIStreamingContext);
  if (context === undefined) {
    throw new Error(
      "useAIStreaming must be used within an AIStreamingProvider",
    );
  }
  return context;
};

export const AIStreamingProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useEditorDispatch();
  const store = useEditorStore();

  const localStorage = useLocalStorage();

  const lastUsedBuildAssistant = localStorage.getItem("isBuildAssistantOpen");

  const isStreaming = useEditorSelector(selectStreamingUpdateIsStreaming);
  const isStreamingFinished = isStreaming === false;

  const [isBuildAssistantOpen, setIsBuildAssistantOpen] = React.useState(
    lastUsedBuildAssistant === "true" || lastUsedBuildAssistant === null,
  );

  const [isAIPreLoading, setIsAIPreLoading] = React.useState(false);

  const [generationMode, setGenerationMode] =
    React.useState<AIStreamingOperation | null>(null);

  const [generationSource, setGenerationSource] =
    React.useState<MenuTriggerSource | null>(null);

  const shouldAutoApplyChanges = generationSource === "onboarding";

  React.useEffect(() => {
    localStorage.setItem(
      "isBuildAssistantOpen",
      isBuildAssistantOpen.toString(),
    );
  }, [isBuildAssistantOpen, localStorage]);

  const [triggerAiTemplateMutation] = useAiTemplateMutation();
  const [triggerAiDesignMutation] = useAiDesignMutation();
  const [triggerAiTextMutation] = useAiTextV2Mutation();
  const [triggerAiMobileResponsiveMutation] = useAiMobileResponsiveMutation();
  const [triggerAiMultiMutation] = useAiMultiMutation();

  const [componentOrderMap, setComponentOrderMap] = React.useState<
    Map<string, number> | undefined
  >();

  const abortRef = React.useRef<(() => void) | null>(null);
  const measureDraft = useMeasureComponentAndChildrenDimensions();
  const measureText = useMeasureComponentAndChildrenDimensions(
    (component) => component.type === "text",
  );
  const setActiveCanvas = useSetActiveCanvas();

  const editorMode = useEditorSelector(selectEditorMode);
  const actions = useEditorSelector(selectStreamingUpdateActions);

  const status: AIStatus = React.useMemo(() => {
    if (editorMode === EditorMode.aiGeneration) {
      if (isStreaming === false) {
        return shouldAutoApplyChanges
          ? "autoApplyingChanges"
          : "finishedGenerating";
      } else if (actions?.length) {
        return "generating";
      }
      return "generationInitialized";
    }
    return "preGeneration";
  }, [editorMode, isStreaming, actions?.length, shouldAutoApplyChanges]);

  const openAllCollapsibles = useOpenAllCollapsibles();

  const setDeltaXY = useSetDeltaXY();

  const [isParallelGenerating, setIsParallelGenerating] = React.useState(false);

  const initiateParallelGeneration = React.useCallback(
    (params: TemplateStreamingParams, onFinish?: () => void) => {
      setIsParallelGenerating(true);

      const {
        components,
        userPrompt,
        conversationMessages,
        nonce,
        selectedProduct,
        triggeringFeature,
        contextComponent,
      } = params;

      // NOTE (Gabe 2024-08-01): If another generation has already been initiated,
      // this function is a no-op.
      if (status !== "preGeneration") {
        return;
      }

      setGenerationSource("page-builder");
      const rootComponent = selectRootComponent(store.getState());
      setComponentOrderMap(buildComponentOrderMap(rootComponent ?? null));

      const projectId = selectProjectId(store.getState());

      if (!components || !projectId) {
        console.error("No Components or project id");
        return;
      }
      setCompletionPercentage(0);

      dispatch(setEditorMode(EditorMode.aiGeneration));
      setGenerationMode(params.type);
      openAllCollapsibles();

      const componentsToGenerate: BuildAssistantParams["componentsToGenerate"] =
        [];

      const contextComponentMapping: Record<string, Component> = {};

      for (const component of components) {
        const sectionType = component.name;
        const section = getSectionForComponentId(rootComponent, component.id);

        if (section) {
          contextComponentMapping[component.id] = section;
        }

        componentsToGenerate.push({
          conversationMessages,
          nonce,
          sectionType,
          component,
          userPrompt,
          elementId: component.id,
        });
      }

      const wrappedOnFinish = () => {
        setIsParallelGenerating(false);
        onFinish?.();
      };

      const { abort } = triggerAiTemplateMutation({
        componentsToGenerate,
        contextComponentMapping,
        selectedProduct,
        projectId,
        contextComponent,
        triggeringFeature,
        onFinish: wrappedOnFinish,
        generationType: "parallel",
      });
      abortRef.current = abort;
    },
    [openAllCollapsibles, dispatch, status, store, triggerAiTemplateMutation],
  );

  const initiateGeneration = React.useCallback(
    async (
      params: AIStreamingParams,
      onFinish?: () => void,
      targetComponent?: Component,
    ) => {
      if (status !== "preGeneration") {
        return;
      }

      const rootComponent = selectRootComponent(store.getState());
      const draftComponent = selectDraftComponent(store.getState());
      const projectId = selectProjectId(store.getState());

      const componentToEdit =
        targetComponent ?? draftComponent ?? rootComponent;

      if (!componentToEdit || !projectId) {
        console.error("No Component or project id");
        return;
      }
      setCompletionPercentage(0);

      dispatch(setEditorMode(EditorMode.aiGeneration));
      dispatch(setCanvasInteractionMode("locked"));
      setGenerationMode(params.type);

      setComponentOrderMap(buildComponentOrderMap(componentToEdit));

      dispatch(
        scrollToComponentId({
          componentId: componentToEdit.id,
          zoom: true,
          centerHorizontally: true,
        }),
      );

      await exhaustiveSwitch(params)({
        template: async (params) => {
          const {
            userPrompt,
            conversationMessages,
            nonce,
            sectionType,
            systemPrompt,
            initialPrompt,
            selectedProduct,
            triggeringFeature,
          } = params;
          openAllCollapsibles();

          // console.log
          const { abort } = triggerAiTemplateMutation({
            useChainOfThought: true,
            contextComponent:
              getSectionForComponentId(rootComponent, componentToEdit.id) ??
              undefined,
            componentsToGenerate: [
              {
                conversationMessages,
                nonce,
                sectionType: sectionType,
                component: componentToEdit,
                userPrompt,
                elementId: componentToEdit.id,
              },
            ],
            generationType: "sequential",
            selectedProduct,
            projectId,
            onFinish,
            systemPrompt,
            initialPrompt,
            triggeringFeature,
          });
          abortRef.current = abort;
        },
        // DEPRECATED
        design: async (params) => {
          const { userPrompt, conversationMessages, nonce } = params;
          openAllCollapsibles();
          const textDimensions = measureDraft(componentToEdit);

          const { abort } = triggerAiDesignMutation({
            conversationMessages,
            nonce,
            page:
              rootComponent?.id !== componentToEdit.id
                ? rootComponent
                : undefined,
            component: componentToEdit,
            userPrompt,
            textComponentDimensions: textDimensions,
            projectId,
            onFinish,
          });
          abortRef.current = abort;
        },
        // DEPRECATED
        textV2: async (params) => {
          const { userPrompt } = params;
          openAllCollapsibles();
          const textDimensions = measureText(componentToEdit);

          const { abort } = triggerAiTextMutation({
            component: componentToEdit,
            userPrompt,
            textComponentDimensions: textDimensions,
            projectId,
            onFinish,
          });
          abortRef.current = abort;
        },
        // DEPRECATED
        mobileResponsive: async () => {
          const { abort } = triggerAiMobileResponsiveMutation({
            component: componentToEdit,
            projectId,
          });
          abortRef.current = abort;

          setActiveCanvas({ canvas: "mobile", source: "ai" });
        },
        // DEPRECATED
        multi: async (params) => {
          const textOperation = params.operations.find(
            (op) => op.type === "textV2",
          );
          const mobileResponsiveOperation = params.operations.find(
            (op) => op.type === "mobileResponsive",
          );
          const { abort } = triggerAiMultiMutation({
            component: componentToEdit,
            projectId,
            textV2: textOperation ? omit(textOperation, "type") : undefined,
            mobileResponsive: mobileResponsiveOperation
              ? omit(mobileResponsiveOperation, "type")
              : undefined,
            onFinish,
          });
          abortRef.current = abort;
        },
      });
    },
    [
      store,
      dispatch,
      setActiveCanvas,
      measureText,
      measureDraft,
      openAllCollapsibles,
      triggerAiTemplateMutation,
      triggerAiDesignMutation,
      triggerAiTextMutation,
      triggerAiMobileResponsiveMutation,
      triggerAiMultiMutation,
      status,
    ],
  );

  const [queuedGeneration, setQueuedGeneration] =
    React.useState<AIStreamingParams | null>(null);

  const rootComponentId = useEditorSelector(selectRootComponentId);
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  // Note (Evan, 2024-10-10): If there's a queued generation, initialize the draft component
  // to the root component so things like measurements etc work
  React.useEffect(() => {
    if (queuedGeneration && rootComponentId && !draftComponentId) {
      dispatch(setDraftElement({ componentIds: [rootComponentId] }));
    }
  }, [queuedGeneration, rootComponentId, draftComponentId, dispatch]);

  React.useEffect(() => {
    if (queuedGeneration && draftComponentId) {
      // Note (Evan, 2024-10-09): This setTimeout should not be necessary, I think,
      // but we don't really have a reliable way of rendering in response to the
      // canvas successfully rendering - see https://replohq.slack.com/archives/C03AACVP08Y/p1728515710916769.
      setTimeout(() => {
        void initiateGeneration(
          queuedGeneration,
          // Note (Evan, 2024-10-15): on finish, reset the canvas back to the top and show
          // the welcome modal (since this is an onboarding generation)
          () => {
            // Note (Evan, 2024-10-09): This isn't an exact number, just roughly enough
            // to get the whole canvas (+ header) to show
            setDeltaXY({ deltaY: 100 });
          },
        );
        setQueuedGeneration(null);
      });
    }
  }, [queuedGeneration, draftComponentId, initiateGeneration, setDeltaXY]);

  const apply = React.useCallback(() => {
    const actions = selectStreamingUpdateActions(store.getState());
    const streamingUpdateId = selectStreamingUpdateId(store.getState());
    if (!actions || !streamingUpdateId) {
      return;
    }

    dispatch(
      coreActions.applyComponentAction({
        type: "applyCompositeAction",
        value: actions,
        activeCanvas: selectActiveCanvas(store.getState()),
      }),
    );

    dispatch(setEditorMode(EditorMode.edit));

    if (generationSource !== "page-builder") {
      const componentIdsSelected = selectStreamingUpdateComponentIdsSelected(
        store.getState(),
      );
      dispatch(
        setStreamingUpdate({ isStreaming: false, componentIdsSelected }),
      );

      dispatch(setStreamingUpdate(null));
    }
  }, [store, dispatch, generationSource]);

  const discard = React.useCallback(() => {
    const streamingUpdateId = selectStreamingUpdateId(store.getState());
    if (!streamingUpdateId) {
      return;
    }

    const componentIdsSelected = selectStreamingUpdateComponentIdsSelected(
      store.getState(),
    );

    dispatch(setStreamingUpdate({ isStreaming: false, componentIdsSelected }));
    setTimeout(() => {
      dispatch(setStreamingUpdate(null));
    }, 300);
    dispatch(setEditorMode(EditorMode.edit));
  }, [dispatch, store]);

  const revert = React.useCallback(() => {
    const componentIdsSelected = selectStreamingUpdateComponentIdsSelected(
      store.getState(),
    );
    dispatch(setStreamingUpdate({ isStreaming: false, componentIdsSelected }));
  }, [dispatch, store]);

  const abort = () => {
    abortRef.current?.();
    setIsAIPreLoading(false);
  };

  const [completionPercentage, setCompletionPercentage] =
    React.useState<number>(0);

  // NOTE (Gabe 2024-07-26): Because there isn't always a 1:1 mapping between
  // (easily countable) components and actions, we instead use the position in
  // the component tree of the latest action as a proxy for completion
  // percentage.
  const latestActionCompletionPercentage = React.useMemo(() => {
    const latestAction = actions?.[actions.length - 1];

    const numComponents = componentOrderMap?.size;
    const lastActionComponentPosition =
      latestAction?.componentId &&
      componentOrderMap?.get(latestAction.componentId);
    if (numComponents && lastActionComponentPosition) {
      return Math.round((lastActionComponentPosition / numComponents) * 100);
    }
    return 0;
  }, [actions, componentOrderMap]);

  // NOTE (Gabe 2024-08-07): Ensure that completion percentage is always
  // increasing (until it gets reset to 0).
  React.useEffect(() => {
    if (latestActionCompletionPercentage > 0) {
      setCompletionPercentage((val) =>
        Math.max(val, latestActionCompletionPercentage),
      );
    }
  }, [latestActionCompletionPercentage]);

  // Note (Evan, 2024-10-07): This effect is responsible for actually auto-applying changes
  React.useEffect(() => {
    if (status === "autoApplyingChanges") {
      setComponentOrderMap(undefined);
      apply();
    }
  }, [status, apply]);

  return (
    <AIStreamingContext.Provider
      value={{
        isBuildAssistantOpen,
        setIsBuildAssistantOpen,
        isGenerating:
          status === "generating" || status === "generationInitialized",
        generationMode,
        generationSource,
        initiateGeneration,
        isAIPreLoading,
        setIsAIPreLoading,
        initiateParallelGeneration,
        queueGeneration: setQueuedGeneration,
        hasQueuedGeneration: Boolean(queuedGeneration),
        abort,
        isFinished: isStreamingFinished,
        applyChanges: () => {
          setComponentOrderMap(undefined);
          setIsBuildAssistantOpen(false);
          apply();
        },
        discardChanges: () => {
          setComponentOrderMap(undefined);
          setIsBuildAssistantOpen(false);
          discard();
        },
        revert,
        status,
        completionPercentage,
        isParallelGenerating,
      }}
    >
      {children}
    </AIStreamingContext.Provider>
  );
};

/**
 * @param filter Optional function that returns whether a component should be measured
 * @returns A callback that takes an optional target component and returns dimensions for each active canvas
 */
function useMeasureComponentAndChildrenDimensions(
  filter?: (component: Component) => boolean,
): (
  targetComponent?: Component,
) => Partial<Record<EditorCanvas, ComponentDimensionsMap>> {
  const store = useEditorStore();

  return React.useCallback(
    (targetComponent?: Component) => {
      const draftElementId = selectDraftElementId(store.getState());

      // Use provided component or fall back to draft component
      const componentToMeasure =
        targetComponent ?? selectDraftComponent(store.getState());
      if (!draftElementId || !componentToMeasure) {
        return {};
      }

      const componentIdsToMeasure: Set<string> = new Set();
      const result: Partial<Record<EditorCanvas, ComponentDimensionsMap>> = {};

      forEachComponentAndDescendants(componentToMeasure, (component) => {
        if (filter === undefined || filter(component)) {
          componentIdsToMeasure.add(component.id);
        }
      });

      const getCanvasRelativeBoundingClientRect =
        selectCanvasRelativeBoundingClientRect(store.getState());
      const visibleEditorCanvases = selectVisibleEditorCanvases(
        store.getState(),
      );

      // Note (Evan, 2024-09-30): This requestAnimationFrame makes all the
      // calculations happen in one batch.
      requestAnimationFrame(() => {
        for (const canvas of visibleEditorCanvases) {
          componentIdsToMeasure.forEach((componentId) => {
            const node = getEditorComponentNode({
              canvas,
              componentId,
            });
            if (!node) {
              return;
            }
            const rect = getCanvasRelativeBoundingClientRect(node);
            const { height, width } = rect;
            result[canvas] = {
              ...result[canvas],
              [componentId]: { width, height },
            };
          });
        }
      });

      return result;
    },
    [store, filter],
  );
}

function buildComponentOrderMap(
  component: Component | null,
): Map<string, number> {
  const map = new Map<string, number>();
  let count = 0;
  forEachComponentAndDescendants(component, (c) => {
    map.set(c.id, count++);
  });
  return map;
}

const useOpenAllCollapsibles = () => {
  const dispatch = useEditorDispatch();
  const store = useEditorStore();
  return React.useCallback(() => {
    const draftComponent = selectDraftComponent(store.getState());
    if (!draftComponent) {
      return;
    }
    const componentDataMapping = selectComponentDataMapping(store.getState());
    const accordionsToOpen: { [accordionId: string]: string[] } = {};
    const componentIdsToOpen: Set<string> = new Set();
    forEachComponentAndDescendants(draftComponent, (component) => {
      if (component.type === "collapsibleV2") {
        const ancestorComponentData =
          componentDataMapping[component.id]?.ancestorComponentData;
        // Note (Evan, 2024-10-16): The shared state that opens/closes collapsibles ~in accordions~
        // is different, so we have to check this and set state accordingly (accordion-gly?)
        const accordionId = ancestorComponentData?.find(
          ([_, type]) => type === "accordionBlock",
        )?.[0];

        if (accordionId) {
          accordionsToOpen[accordionId] = [
            ...(accordionsToOpen[accordionId] ?? []),
            component.id,
          ];
        } else {
          componentIdsToOpen.add(component.id);
        }
      }
    });
    // Note (Evan, 2024-10-18): No need to batch() these on React 18
    Object.entries(accordionsToOpen).forEach(([accordionId, componentIds]) => {
      dispatch(
        setSharedState({
          key: `${accordionId}.accordionOpenItems`,
          value: componentIds,
        }),
      );
    });
    componentIdsToOpen.forEach((componentId) => {
      dispatch(setSharedState({ key: `${componentId}.isOpen`, value: true }));
    });
  }, [dispatch, store]);
};
