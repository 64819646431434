import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const OkendoReviewsWidget: React.FC<RenderComponentProps> = (props) => {
  // Note (Fran, 2022-12-07): This different selectors will we need because
  // okendo has different installations on diferent stores, and they have
  // different selectors to show the widget.
  // https://www.notion.so/replo/Legacy-Okendo-Installs-0223a8f83de24897962f65adef44b6c4
  const liquidSourceWidget = `
    <div
      data-oke-widget data-oke-reviews-widget
      data-oke-reviews-product-id="shopify-{{ product.id }}">
        {{ product.metafields.okendo.ReviewsWidgetSnippet }}
    </div>`;

  const product = useProductFromProps(props.component.props, props.context);

  const liquid = wrapProductAssignmentToLiquidSource({
    product,
    liquidSource: liquidSourceWidget,
    context: props.context,
  });

  return (
    <SharedShopifyLiquid
      componentId={props.component.id}
      componentAttributes={props.componentAttributes}
      component={props.component}
      liquidSource={liquid}
      repeatedIndexPath={props.context.repeatedIndexPath}
      isLiquidSupported
      title="Okendo Reviews"
      placeholderType="reviewFull"
    />
  );
};

export default OkendoReviewsWidget;
