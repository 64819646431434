import type { DeleteReferralCodeModalProps } from "../AppModalTypes";

import * as React from "react";

import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";

export const DeleteReferralCodeModal: React.FC<
  DeleteReferralCodeModalProps
> = ({ code }) => {
  const { user } = useCurrentUser();
  const { closeModal } = useModal();
  const logEvent = useLogAnalytics();
  const workspaceId = useCurrentWorkspaceId();

  const { mutateAsync: deleteReferralCode } =
    trpc.referralCode.delete.useMutation({
      onSuccess: async () => {
        if (workspaceId) {
          toast({ header: "Referral Code Deleted" });
          logEvent("workspace.referralCode.deleted", {
            workspaceId: workspaceId,
            email: user?.email ?? "",
            code,
          });

          void trpcUtils.workspace.getById.invalidate({
            id: workspaceId,
          });
          void trpcUtils.referralCode.getReferredUsers.invalidate({
            workspaceId,
          });
          void trpcUtils.referralCode.getReferredWorkspaces.invalidate({
            workspaceId,
          });
        }
      },
    });

  const deleteCode = () => {
    if (!code || !workspaceId) {
      return;
    }

    void deleteReferralCode({
      code,
      workspaceId,
    }).finally(() => closeModal({ type: "deleteReferralCodeModal" }));
  };

  return (
    <Modal
      isOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          closeModal({ type: "deleteReferralCodeModal" });
        }
      }}
      title="Delete Referral Code"
      size="base"
      footer={
        <div className="flex flex-row gap-2">
          <Button
            variant="secondary"
            size="base"
            onClick={() => closeModal({ type: "deleteReferralCodeModal" })}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            size="base"
            onClick={() => void deleteCode()}
          >
            Delete
          </Button>
        </div>
      }
    >
      <p className="typ-body-base text-default">
        Once a referral code is deleted,{" "}
        <span className="font-bold">
          there will be no future payouts using this referral code
        </span>{" "}
        and no one will be able to use this referral code to sign up for Replo.{" "}
      </p>
      <p className="typ-body-base text-default">
        This action is permanent and cannot be undone.
      </p>
    </Modal>
  );
};
