import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type { ProductRef } from "schemas/product";

import React from "react";

import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { Loader } from "@editor/components/common/Loader";
import { useInfiniteStoreProducts } from "@editor/hooks/useInfiniteStoreProducts";
import {
  storeProductRequestLimits,
  useFetchStoreProducts,
  useStoreProducts,
} from "@editor/hooks/useStoreProducts";
import {
  selectDraftElementTemplateProducts,
  selectDraftElementType,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { LargeMenuItem } from "@replo/design-system/components/menu/LargeMenuItem";
import { Tag } from "@replo/design-system/components/tag/Tag";
import twMerge from "@replo/design-system/utils/twMerge";
import partition from "lodash-es/partition";
import startCase from "lodash-es/startCase";
import take from "lodash-es/take";
import uniqBy from "lodash-es/uniqBy";
import { Database as DatabaseIcon, Image as ImageIcon } from "lucide-react";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type SingleSelectProps = {
  isMultiselect?: false;
  value: ProductRef | null;
  onChange: (value: ProductRef | null) => void;
};

type MultiSelectProps = {
  isMultiselect: true;
  value: ProductRef[] | null;
  onChange: (value: ProductRef[]) => void;
};
type ProductSelectionComboboxProps = {
  side?: "top" | "bottom" | "left" | "right";
  sideOffset?: number;
  trigger?: React.ReactNode;
  size?: "sm" | "base";
} & (SingleSelectProps | MultiSelectProps);

// Custom button component for product selection
type ProductSelectionButtonProps = {
  title: string;
  size?: "sm" | "base";
  isPlaceholder?: boolean;
  productImage?: string | null;
  className?: string;
  onRemove?: () => void;
};

export const ProductSelectionButton = React.forwardRef<
  HTMLButtonElement,
  ProductSelectionButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      title,
      size = "base",
      isPlaceholder = false,
      productImage,
      className,
      onRemove,
      ...props
    },
    ref,
  ) => {
    const buttonEnhancer = (() => {
      if (isPlaceholder) {
        return (
          <div className="flex-shrink-0">
            <DatabaseIcon size={16} className="text-placeholder" />
          </div>
        );
      }

      if (productImage) {
        return (
          <div
            className={twMerge(
              size === "sm" ? "w-4 h-4" : "w-5 h-5",
              "flex-shrink-0 border border-border rounded",
            )}
          >
            <img
              src={`${productImage}&width=40`}
              width={size === "sm" ? 16 : 20}
              height={size === "sm" ? 16 : 20}
              className="w-full h-full rounded object-contain"
            />
          </div>
        );
      }

      // Default icon when product exists but has no image
      return (
        <div className="flex-shrink-0">
          <ImageIcon size={16} className="text-placeholder" />
        </div>
      );
    })();

    return (
      <button
        ref={ref}
        className={twMerge(
          "flex items-center justify-between w-full px-1  rounded-md group",
          !isPlaceholder
            ? "bg-default border border-secondary shadow-sm"
            : "bg-subtle hover:bg-light-surface-hover border border-border border-dashed",
          "text-default typ-body-small",
          size === "sm" ? "h-6" : "h-8",
          className,
        )}
        type="button"
        data-testid="trigger-product"
        {...props}
      >
        <div className="flex items-center gap-2 overflow-hidden">
          {buttonEnhancer}
          <span className="truncate typ-label-small">{title}</span>
        </div>
        {onRemove && (
          <div
            className={twMerge(
              "transition-opacity duration-200 opacity-0 group-hover:opacity-100",
            )}
          >
            <FormFieldXButton
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
            />
          </div>
        )}
      </button>
    );
  },
);

ProductSelectionButton.displayName = "ProductSelectionButton";

const ProductSelectionComboboxLoader = ({
  isFetching,
  hasNextPage,
}: {
  isFetching: boolean;
  hasNextPage: boolean;
}) => {
  if (isFetching) {
    return (
      <div className="flex items-center justify-center h-6">
        <Loader className="size-6" />
      </div>
    );
  }
  // NOTE: (Jackson, 2025-03-28) Smooth scrolling when loading is needed
  if (hasNextPage) {
    return <div className="h-6" />;
  }

  return null;
};

export const ProductSelectionCombobox = ({
  value,
  onChange,
  side = "bottom",
  sideOffset = 4,
  isMultiselect = false,
  trigger,
  size = "base",
}: ProductSelectionComboboxProps) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const elementType = useEditorSelector(selectDraftElementType);

  const { products: initialProducts } = useStoreProducts(
    "productsFromDraftElement",
  );

  const assignedTemplateProductIds = useEditorSelector(
    selectDraftElementTemplateProducts,
  );

  const { products: templateProducts } = useFetchStoreProducts(
    assignedTemplateProductIds,
  );

  const { products, fetchNextPage, isFetching, pageInfo } =
    useInfiniteStoreProducts({
      pageSize: storeProductRequestLimits.infiniteLoading,
      query: searchTerm,
      debounceDelay: 300,
    });

  // Check if we have real products or should use placeholders
  const hasRealProducts =
    initialProducts.length > 0 ||
    templateProducts.length > 0 ||
    products.length > 0;

  // Combine real products or use first 7 fake ones if no real products exist
  const productsData = hasRealProducts
    ? uniqBy([...initialProducts, ...templateProducts, ...products], "id")
    : take(fakeProducts, 7);

  const options: ComboboxOption[] = React.useMemo(() => {
    // Grouping should only happen on product templates AND if there are template products
    const shouldApplyTemplateGrouping =
      elementType === "shopifyProductTemplate" && templateProducts.length > 0;

    const mappedOptions = productsData.map((product) => {
      const tagVariant: "success" | "info" | "neutral" = exhaustiveSwitch(
        product,
        "status",
      )({
        ACTIVE: "success",
        DRAFT: "info",
        ARCHIVED: "neutral",
      });

      const productImage = product.featured_image
        ? product.featured_image
        : product.images[0];
      const isAssigned =
        Array.isArray(assignedTemplateProductIds) &&
        assignedTemplateProductIds.some(
          (id) => String(id) === String(product.id),
        );

      let groupTitle: string | undefined = undefined;
      // Only assign group titles if we should be grouping
      if (shouldApplyTemplateGrouping) {
        if (isAssigned) {
          groupTitle = "Assigned to this Template";
        } else {
          groupTitle = "Unassigned";
        }
      }

      return {
        value: String(product.id),
        label: (
          <LargeMenuItem
            variant="product"
            label={product.title}
            description={product.handle}
            productImage={productImage ? `${productImage}&width=40` : undefined}
            tag={
              <Tag size="sm" variant={tagVariant}>
                {startCase(product.status)}
              </Tag>
            }
            selected={
              isMultiselect
                ? (value as ProductRef[])?.some(
                    (item) => String(item.productId) === String(product.id),
                  )
                : String((value as ProductRef)?.productId) ===
                  String(product.id)
            }
            hasCheckbox={isMultiselect}
          />
        ),
        displayValue: product.title,
        estimatedSize: 48,
        ...(groupTitle && { groupTitle }),
      };
    });

    if (shouldApplyTemplateGrouping) {
      // Partition the options into assigned and unassigned
      const [assignedOptions, unassignedOptions] = partition(
        mappedOptions,
        (option) => option.groupTitle === "Assigned to this Template",
      );
      // Concatenate them in the desired order
      return [...assignedOptions, ...unassignedOptions];
    }

    return mappedOptions;
  }, [
    productsData,
    value,
    isMultiselect,
    templateProducts,
    assignedTemplateProductIds,
    elementType,
  ]);

  // Internal combobox value is string | string[], so we need to convert between ProductRef and string value
  const selectedValue = React.useMemo(() => {
    if (isMultiselect) {
      return (
        (value as ProductRef[])?.map((item) => String(item.productId)) || []
      );
    }
    return (value as ProductRef)?.productId
      ? String((value as ProductRef).productId)
      : null;
  }, [value, isMultiselect]);

  // Find the selected product(s) to display appropriate title
  const getButtonTitle = () => {
    if (isMultiselect) {
      const multiValue = value as ProductRef[] | null;
      if (!multiValue || multiValue.length === 0) {
        return "Connect Products";
      }
      const count = multiValue.length;
      return `${count} product${count !== 1 ? "s" : ""} connected`;
    } else {
      const singleValue = value as ProductRef | null;
      if (!singleValue) {
        return "Connect Product";
      }

      const product = productsData.find(
        (p) => String(p.id) === String(singleValue.productId),
      );
      return product?.title || "Connect Product";
    }
  };

  const getSelectedProductImage = (productId?: string) => {
    if (isMultiselect) {
      return null;
    }

    const product = productsData.find(
      (p) =>
        String(p.id) === String(productId ?? (value as ProductRef)?.productId),
    );

    const productImage = product?.featured_image
      ? product.featured_image
      : product?.images[0];

    return (productImage && `${productImage}&width=40`) || null;
  };

  const handleChange = (newValue: string | string[]) => {
    if (!newValue) {
      onChange(null as any);
      return;
    }

    if (isMultiselect) {
      const multiValues = newValue as string[];
      const productRefs = multiValues
        .map((id) => {
          const product = productsData.find((p) => String(p.id) === id);
          if (!product) {
            return null;
          }
          return {
            productId: product.id,
            variantId: product.variants[0]?.id,
            title: product.title,
          } as ProductRef;
        })
        .filter(Boolean) as ProductRef[];

      (onChange as (value: ProductRef[] | null) => void)(productRefs);
    } else {
      const selectedProduct = productsData.find(
        (product) => String(product.id) === newValue,
      );
      if (selectedProduct) {
        const productRef: ProductRef = {
          productId: selectedProduct.id,
          variantId: selectedProduct.variants[0]?.id,
          title: selectedProduct.title,
        };
        (onChange as (value: ProductRef | null) => void)(productRef);
      }
    }
  };

  const handleSearch = (input: string) => {
    setSearchTerm(input);
  };

  const loadMoreProducts = async () => {
    if (pageInfo?.hasNextPage && !isFetching) {
      await fetchNextPage();
    }
  };

  if (isMultiselect) {
    return (
      <Combobox.Root
        options={options}
        value={selectedValue as string[]}
        onChange={handleChange}
        onInputChange={handleSearch}
        isMultiselect={true}
        layoutClassName="w-full"
      >
        <Combobox.Trigger>
          {trigger ?? (
            <ProductSelectionButton
              title={getButtonTitle()}
              isPlaceholder={!value}
              productImage={getSelectedProductImage()}
            />
          )}
        </Combobox.Trigger>
        <Combobox.Popover
          side={side}
          sideOffset={sideOffset}
          layoutClassName="w-[260px] max-h-[400px]"
        >
          <Combobox.Content
            title="Connect Product"
            areOptionsSearchable
            inputPlaceholder="Search products..."
            emptySearchMessage="No products found."
            onScrolledToEnd={
              hasRealProducts ? () => void loadMoreProducts() : undefined
            }
            loader={
              <ProductSelectionComboboxLoader
                isFetching={isFetching}
                hasNextPage={pageInfo?.hasNextPage ?? false}
              />
            }
          />
        </Combobox.Popover>
      </Combobox.Root>
    );
  }

  return (
    <Combobox.Root
      options={options}
      value={selectedValue as string}
      onChange={handleChange}
      onInputChange={handleSearch}
      layoutClassName="w-full"
    >
      <Combobox.Trigger>
        {trigger ?? (
          <ProductSelectionButton
            title={getButtonTitle()}
            isPlaceholder={!value}
            productImage={getSelectedProductImage()}
            size={size}
          />
        )}
      </Combobox.Trigger>
      <Combobox.Popover
        side={side}
        sideOffset={sideOffset}
        layoutClassName="w-[260px] h-[400px]"
      >
        <Combobox.Content
          title="Connect Product"
          areOptionsSearchable
          inputPlaceholder="Search products..."
          emptySearchMessage="No products found."
          onScrolledToEnd={
            hasRealProducts ? () => void loadMoreProducts() : undefined
          }
          loader={
            <ProductSelectionComboboxLoader
              isFetching={isFetching}
              hasNextPage={pageInfo?.hasNextPage ?? false}
            />
          }
        />
      </Combobox.Popover>
    </Combobox.Root>
  );
};
