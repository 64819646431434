import * as React from "react";

import DashboardLayout from "@editor/components/layouts/DashboardLayout";
import WorkspaceProvider from "@editor/components/WorkspaceProvider";
import { routes } from "@editor/utils/router";

import { errorToast } from "@replo/design-system/components/alert/Toast";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { validate as isUUIDValid } from "uuid";

const WorkspaceDashboard: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { workspaceId } = useParams();

  if (!workspaceId || !isUUIDValid(workspaceId ?? "")) {
    errorToast(
      "Invalid workspace",
      "Please make sure you typed the URL correctly.",
    );
    return <Navigate to={routes.home.root} />;
  }

  return (
    <WorkspaceProvider
      workspaceId={workspaceId}
      saveWorkspaceIdAsLastVisited
      showToastOnNoAccess
      redirectOnNoAccess
    >
      <DashboardLayout>
        <div className="bg-white no-scrollbar col-span-9 mt-4 flex flex-grow flex-col items-start gap-y-2 overflow-scroll">
          {children}
          <Outlet />
        </div>
      </DashboardLayout>
    </WorkspaceProvider>
  );
};

export default WorkspaceDashboard;
