import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type {
  ProductMetafieldMapping,
  StoreProduct,
  Swatch,
  VariantMetafieldMapping,
} from "replo-runtime/shared/types";
import type { DesignLibrary } from "schemas/generated/designLibrary";
import type { DataTable } from "schemas/generated/element";
import type { ProductRef } from "schemas/product";

import * as React from "react";

import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import {
  selectProductMetafieldValues,
  selectVariantMetafieldValues,
} from "@editor/reducers/commerce-reducer";
import {
  selectComponentDataMapping,
  selectComponentIdToDraftVariantId,
  selectDataTablesMapping,
  selectIsPreviewMode,
  selectModalComponentIdFromDraftComponents,
  selectStreamingUpdateTextMap,
} from "@editor/reducers/core-reducer";
import {
  selectEditorBrokenMediaComponentIds,
  selectEditorMediaUploadingComponentIds,
} from "@editor/reducers/editor-media-reducer";
import { selectSharedState } from "@editor/reducers/paint-reducer";
import { selectTemplateEditorProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { CANVAS_DATA } from "@/features/canvas/canvas-constants";
import { skipToken } from "@tanstack/react-query";
import { create } from "zustand";

import { selectPreviewWidth } from "../canvas-reducer";

type RuntimeState = {
  componentIdToDraftVariantId: Record<string, string | undefined>;
  isPreviewMode: boolean;
  previewWidth: number;
  streamingUpdateTextMap: Record<string, string> | undefined;
  componentDataMapping: ComponentDataMapping;
  swatches: Swatch[];
  designLibrary: DesignLibrary | null;
  sharedState: Record<string, any>;
  dataTableMapping: Record<string, DataTable>;
  templateEditorProduct: ProductRef | null;
  shopifyProducts: StoreProduct[];
  isShopifyProductsLoading: boolean;
  shopifyProductMetafieldValues: ProductMetafieldMapping;
  shopifyVariantMetafieldValues: VariantMetafieldMapping;
  editorMediaUploadingComponentIds: string[];
  editorBrokenMediaComponentIds: string[];
  editorOverrideOpenModalId: string | null;
  actions: {
    setIsPreviewMode: (isPreviewMode: boolean) => void;
    setPreviewWidth: (previewWidth: number) => void;
    setComponentIdToDraftVariantId: (
      componentIdToDraftVariantId: Record<string, string | undefined>,
    ) => void;
    setStreamingUpdateTextMap: (
      textMap: Record<string, string> | undefined,
    ) => void;
    setComponentDataMapping: (
      componentDataMapping: ComponentDataMapping,
    ) => void;
    setSwatches: (swatches: Swatch[]) => void;
    setSharedState: (sharedState: Record<string, any>) => void;
    setDataTableMapping: (dataTableMapping: Record<string, DataTable>) => void;
    setTemplateEditorProduct: (product: ProductRef | null) => void;
    setShopifyProducts: (products: StoreProduct[]) => void;
    setIsShopifyProductsLoading: (isLoading: boolean) => void;
    setShopifyProductMetafieldValues: (
      productMetafieldValues: ProductMetafieldMapping,
    ) => void;
    setShopifyVariantMetafieldValues: (
      variantMetafieldValues: VariantMetafieldMapping,
    ) => void;
    setEditorMediaUploadingComponentIds: (componentIds: string[]) => void;
    setEditorBrokenMediaComponentIds: (componentIds: string[]) => void;
    setEditorOverrideOpenModalId: (modalId: string | null) => void;
    setDesignLibrary: (designLibrary: DesignLibrary) => void;
  };
};

/**
 * Independent store which holds all state which runtime components might subscribe
 * to in the editor.
 *
 * The reason this is necessary and we can't just use redux is that since redux is a
 * global store, every action must call every subscription (that is, any time any action
 * is dispatched, every useEditorSelector() function will be called). This is not ideal
 * for the runtime components, since there may be 1000s of them and they all might
 * subscribe to N different slices of state. This means that any time we dispatch
 * a redux action, even if it's not related to the runtime at all, we'd be calling N * 1000s
 * of functions, which is slow (even if each individual function is fast).
 *
 * This issue is especially apparent when dispatch() is called frequently, for example
 * when scrolling the canvas frames in edit mode.
 *
 * Instead, we use zustand to create a smaller store and define a hook to subscribe this
 * store to the redux state. This way, the runtime components can subscribe to this store
 * instead of the redux store, which means redux actions which aren't related to the runtime
 * won't call the 1000s of subscription functions.
 */
const useRuntimeStore = create<RuntimeState>((set) => {
  return {
    componentIdToDraftVariantId: {},
    isPreviewMode: false,
    previewWidth: CANVAS_DATA.desktop.defaultFrameWidth,
    streamingUpdateTextMap: undefined,
    componentDataMapping: {},
    swatches: [],
    sharedState: {},
    dataTableMapping: {},
    designLibrary: null,
    templateEditorProduct: null,
    shopifyProducts: [],
    isShopifyProductsLoading: false,
    shopifyProductMetafieldValues: {},
    shopifyVariantMetafieldValues: {},
    editorMediaUploadingComponentIds: [],
    editorBrokenMediaComponentIds: [],
    editorOverrideOpenModalId: null,
    actions: {
      setIsPreviewMode: (isPreviewMode) => {
        set({ isPreviewMode });
      },
      setPreviewWidth: (previewWidth) => {
        set({ previewWidth });
      },
      setComponentIdToDraftVariantId: (componentIdToDraftVariantId) => {
        set({ componentIdToDraftVariantId });
      },
      setStreamingUpdateTextMap: (textMap) => {
        set({ streamingUpdateTextMap: textMap });
      },
      setComponentDataMapping: (componentDataMapping) => {
        set({ componentDataMapping });
      },
      setSwatches: (swatches) => {
        set({ swatches });
      },
      setSharedState: (sharedState) => {
        set({ sharedState });
      },
      setDataTableMapping: (dataTableMapping) => {
        set({ dataTableMapping });
      },
      setTemplateEditorProduct: (product) => {
        set({ templateEditorProduct: product });
      },
      setShopifyProducts: (products) => {
        set({ shopifyProducts: products });
      },
      setIsShopifyProductsLoading: (isLoading) => {
        set({ isShopifyProductsLoading: isLoading });
      },
      setShopifyProductMetafieldValues: (values) => {
        set({ shopifyProductMetafieldValues: values });
      },
      setShopifyVariantMetafieldValues: (values) => {
        set({ shopifyVariantMetafieldValues: values });
      },
      setEditorMediaUploadingComponentIds: (componentIds) => {
        set({ editorMediaUploadingComponentIds: componentIds });
      },
      setEditorBrokenMediaComponentIds: (componentIds) => {
        set({ editorBrokenMediaComponentIds: componentIds });
      },
      setEditorOverrideOpenModalId: (value) => {
        set({ editorOverrideOpenModalId: value });
      },
      setDesignLibrary: (designLibrary) => {
        set({ designLibrary });
      },
    },
  };
});

export const useRuntimeOverrideVariantId = (componentId: string) => {
  const isPreviewMode = useRuntimeStore((state) => state.isPreviewMode);
  const editorOverrideVariantId = useRuntimeStore(
    (state) => state.componentIdToDraftVariantId[componentId],
  );
  // Note (Noah, 2024-08-02): If we're in preview mode, we want the variants to be
  // activated based on their event listeners, no overrides.
  if (isPreviewMode) {
    return null;
  }
  return editorOverrideVariantId;
};

export const useIsPreviewMode = () => {
  return useRuntimeStore((state) => state.isPreviewMode);
};

export const usePreviewWidth = () => {
  return useRuntimeStore((state) => state.previewWidth);
};

export const useEditorOverrideText = (componentId: string) => {
  return useRuntimeStore(
    (state) => state.streamingUpdateTextMap?.[componentId],
  );
};

export const useIsLabelledByOtherComponent = (componentId: string) => {
  return useRuntimeStore((state) => {
    return (
      state.componentDataMapping[componentId]?.isLabelledByOtherComponent ??
      false
    );
  });
};

export const useSwatches = () => {
  return useRuntimeStore((state) => state.swatches);
};

export const useSharedState = (key: string) => {
  return useRuntimeStore((state) => state.sharedState[key]);
};

export const useDataTableMapping = () => {
  return useRuntimeStore((state) => state.dataTableMapping);
};

export const useDesignLibrary = () => {
  return useRuntimeStore((state) => state.designLibrary);
};

export const useTemplateEditorProduct = () => {
  return useRuntimeStore((state) => state.templateEditorProduct);
};

const useRuntimeStoreActions = () => {
  return useRuntimeStore((state) => state.actions);
};

export const useShopifyProducts = () => {
  return useRuntimeStore((state) => state.shopifyProducts);
};

export const useIsShopifyProductsLoading = () => {
  return useRuntimeStore((state) => state.isShopifyProductsLoading);
};

export const useShopifyProductMetafieldValues = () => {
  return useRuntimeStore((state) => state.shopifyProductMetafieldValues);
};

export const useShopifyVariantMetafieldValues = () => {
  return useRuntimeStore((state) => state.shopifyVariantMetafieldValues);
};

export const useIsUploadingEditorMediaId = (componentId: string) => {
  return useRuntimeStore((state) =>
    state.editorMediaUploadingComponentIds.includes(componentId),
  );
};

export const useIsBrokenEditorMediaId = (componentId: string) => {
  return useRuntimeStore((state) =>
    state.editorBrokenMediaComponentIds.includes(componentId),
  );
};

export const useEditorOverrideOpenModalId = () => {
  return useRuntimeStore((state) => state.editorOverrideOpenModalId);
};

/**
 * Hook which registers effects to sync the runtime store with a slice of the editor state.
 */
export function useSyncRuntimeStoreWithEditorState() {
  const actions = useRuntimeStoreActions();
  const componentIdToDraftVariantId = useEditorSelector(
    selectComponentIdToDraftVariantId,
  );
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const textMap = useEditorSelector(selectStreamingUpdateTextMap);
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const sharedState = useEditorSelector(selectSharedState);
  const dataTableMapping = useEditorSelector(selectDataTablesMapping);
  const templateEditorProduct = useEditorSelector(selectTemplateEditorProduct);
  const { products, isLoading } = useStoreProductsFromDraftElement();
  const productMetafieldValues = useEditorSelector(
    selectProductMetafieldValues,
  );
  const variantMetafieldValues = useEditorSelector(
    selectVariantMetafieldValues,
  );

  const editorMediaUploadingComponentIds = useEditorSelector(
    selectEditorMediaUploadingComponentIds,
  );

  const editorBrokenMediaComponentIds = useEditorSelector(
    selectEditorBrokenMediaComponentIds,
  );
  const previewWidth = useEditorSelector(selectPreviewWidth);

  React.useEffect(() => {
    actions.setComponentIdToDraftVariantId(componentIdToDraftVariantId);
  }, [componentIdToDraftVariantId, actions]);

  React.useEffect(() => {
    actions.setIsPreviewMode(isPreviewMode);
  }, [isPreviewMode, actions]);

  React.useEffect(() => {
    actions.setPreviewWidth(previewWidth);
  }, [previewWidth, actions]);

  React.useEffect(() => {
    actions.setStreamingUpdateTextMap(textMap);
  }, [textMap, actions]);

  React.useEffect(() => {
    actions.setComponentDataMapping(componentDataMapping);
  }, [componentDataMapping, actions]);

  React.useEffect(() => {
    actions.setSharedState(sharedState);
  }, [sharedState, actions]);

  useSyncSwatchesWithEditorState();

  useSyncDesignLibraryWithEditorState();

  React.useEffect(() => {
    actions.setDataTableMapping(dataTableMapping);
  }, [dataTableMapping, actions]);

  React.useEffect(() => {
    actions.setTemplateEditorProduct(templateEditorProduct);
  }, [templateEditorProduct, actions]);

  React.useEffect(() => {
    actions.setShopifyProducts(products);
  }, [products, actions]);

  React.useEffect(() => {
    actions.setIsShopifyProductsLoading(isLoading);
  }, [isLoading, actions]);

  React.useEffect(() => {
    actions.setShopifyProductMetafieldValues(productMetafieldValues);
  }, [productMetafieldValues, actions]);

  React.useEffect(() => {
    actions.setShopifyVariantMetafieldValues(variantMetafieldValues);
  }, [variantMetafieldValues, actions]);

  React.useEffect(() => {
    actions.setEditorMediaUploadingComponentIds(
      editorMediaUploadingComponentIds,
    );
  }, [editorMediaUploadingComponentIds, actions]);

  React.useEffect(() => {
    actions.setEditorBrokenMediaComponentIds(editorBrokenMediaComponentIds);
  }, [editorBrokenMediaComponentIds, actions]);

  useSyncEditorOverrideOpenModalIdWithEditorState();
}

function useSyncSwatchesWithEditorState() {
  const actions = useRuntimeStoreActions();
  const projectId = useCurrentProjectId();
  const { data: swatches } = trpc.swatch.list.useQuery(
    projectId ? { projectId } : skipToken,
  );

  React.useEffect(() => {
    if (!swatches) {
      return;
    }
    actions.setSwatches(swatches);
  }, [swatches, actions]);
}

function useSyncDesignLibraryWithEditorState() {
  const actions = useRuntimeStoreActions();
  const { designLibrary } = useShopStyles();

  React.useEffect(() => {
    if (!designLibrary) {
      return;
    }
    actions.setDesignLibrary(designLibrary);
  }, [designLibrary, actions]);
}

function useSyncEditorOverrideOpenModalIdWithEditorState() {
  const actions = useRuntimeStoreActions();
  const modalComponentIdFromDraftComponents = useEditorSelector(
    selectModalComponentIdFromDraftComponents,
  );

  React.useEffect(() => {
    if (modalComponentIdFromDraftComponents) {
      actions.setEditorOverrideOpenModalId(modalComponentIdFromDraftComponents);
    } else {
      actions.setEditorOverrideOpenModalId(null);
    }
  }, [modalComponentIdFromDraftComponents, actions]);
}
