import type { MetafieldEntityTypes } from "replo-runtime/shared/types";

import * as React from "react";

import { createFetchMetafieldValuesIfNeededThunk } from "@editor/actions/core-actions";
import { selectDraftElementId } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { useStoreProductsFromDraftElement } from "@hooks/useStoreProducts";

import isEqual from "lodash-es/isEqual";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

let hasEverRequestedDependencies: boolean = false;

export default function useFetchMetafields(config: {
  type: MetafieldEntityTypes;
}) {
  const draftElementId = useEditorSelector(selectDraftElementId);
  const { products, isLoading } = useStoreProductsFromDraftElement();
  const dispatch = useEditorDispatch();
  const productsRef = React.useRef(products);

  React.useEffect(() => {
    const previousProducts = productsRef.current;
    productsRef.current = products;

    // Note (Fran, 2022-11-29): If products change in the editor we need re-request
    // the dependencies to show the metafield for the new product selected
    if (!isEqual(products, previousProducts)) {
      hasEverRequestedDependencies = false;
    }
    if (draftElementId && !isLoading && products && products.length > 0) {
      // Note (Noah, 2022-11-01): We have to set a timeout here because the
      // metafields thunk depends on all the contexts of the components which
      // were rendered into the canvas being set up, and React doesn't provide
      // a callback for this. So we just set an arbitrarily long timeout at which
      // point the canvas React tree has probably rendered and we probably have the
      // context which we need.
      exhaustiveSwitch(config)({
        variant: () => {
          if (!hasEverRequestedDependencies) {
            hasEverRequestedDependencies = true;
            setTimeout(() => {
              // @ts-ignore
              void dispatch(createFetchMetafieldValuesIfNeededThunk());
            }, 500);
          }
        },
        product: () => {
          if (!hasEverRequestedDependencies) {
            hasEverRequestedDependencies = true;
            setTimeout(() => {
              // @ts-ignore
              void dispatch(createFetchMetafieldValuesIfNeededThunk());
            }, 500);
          }
        },
      });
    }
  }, [isLoading, products, draftElementId, dispatch, config]);
}
