import type { CredentialResponse } from "@react-oauth/google";
import type { FlowSlug } from "schemas/generated/flow";

import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import Separator from "@common/designSystem/Separator";
import {
  ErrorMessage as GlobalErrorMessage,
  prefilledErrorMapping,
} from "@components/account/common";
import GoogleAuth from "@components/account/GoogleAuth";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { storeToken } from "@editor/reducers/utils/store-token";
import {
  getReferrerKeyFromURL,
  isValidCookie,
  REFERRER_KEY_COOKIE,
} from "@editor/utils/referralCode";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import get from "lodash-es/get";
import some from "lodash-es/some";
import { useCookies } from "react-cookie";
import { useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";
import { z } from "zod";

const emailSchema = z.string().email();

type SignupFormValues = {
  email: string;
  password: string;
};

const SignupForm = () => {
  const [searchParams] = useSearchParams();
  const [signupErrorMessage, setSignupErrorMessage] = React.useState<
    string | null
  >(get(prefilledErrorMapping, searchParams.get("error") ?? ""));

  const getReferrerKeyFromCookie = useGetReferrerKeyCookiePayload();
  useSetReferrerKeyCookie();
  const analytics = useLogAnalytics();

  const handleAccountCreation = (
    token: string,
    userEmail: string,
    referralCode: string | null,
  ) => {
    storeToken(token);
    if (referralCode) {
      analytics("referralCode.lead", {
        code: referralCode,
        referredUserEmail: userEmail,
      });
    }
    // NOTE (Martin, 2023-02-20): We persist search params so that
    // the onboarding flow can pass them through if necessary. We
    // currently use this to persist pendingAppInstallationId

    const flowToTrigger: FlowSlug = "onboarding-styles";

    window.location.replace(
      `/flows/${flowToTrigger}${searchParams ? `?${searchParams}` : ""}`,
    );
  };

  const { mutate: createGoogleAccountApi } =
    trpc.auth.createAccountWithGoogle.useMutation({
      onSuccess: ({ token, user: { email } }, { referrerKey }) =>
        handleAccountCreation(token, email, referrerKey ?? null),
      onError: ({ message }) => setSignupErrorMessage(message),
    });
  const { mutate: createAccountApi, isPending: isLoadingCreateAccount } =
    trpc.auth.createAccount.useMutation({
      onSuccess: ({ token, user: { email } }, { referrerKey }) =>
        handleAccountCreation(token, email, referrerKey ?? null),
      onError: ({ message }) => setSignupErrorMessage(message),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
  } = useForm({
    defaultValues: {
      email: searchParams.get("email") ?? "",
      password: "",
    },
    mode: "onBlur",
  });
  const watchAllFields = useWatch({ control });
  const someFieldIsEmpty = some(watchAllFields, isEmpty);

  const onGoogleSubmit = async (credentialResponse: CredentialResponse) => {
    const { clientId, credential } = credentialResponse;

    if (clientId && credential) {
      const referralCode = getReferrerKeyFromCookie();
      createGoogleAccountApi({
        clientId,
        credential,
        installationNonce: searchParams.get("nonce"),
        referrerKey: referralCode,
      });
    }
  };

  const onSubmit = async ({ email, password }: SignupFormValues) => {
    const referralCode = getReferrerKeyFromCookie();
    createAccountApi({
      email,
      password,
      installationNonce: searchParams.get("nonce"),
      referrerKey: referralCode,
    });
  };

  const emailError = !isEmpty(watchAllFields.email)
    ? errors?.email?.message
    : undefined;

  const passwordError = !isEmpty(watchAllFields.password)
    ? errors?.password?.message
    : undefined;

  const buttonShouldBeDisabled =
    someFieldIsEmpty || Boolean(passwordError) || Boolean(emailError);

  return (
    <form
      className="no-scrollbar"
      onSubmit={(data) => {
        void handleSubmit(onSubmit)(data);
      }}
    >
      <GlobalErrorMessage errorMessage={signupErrorMessage} />
      <div className="flex flex-col gap-4">
        <LabeledControl
          label="Email"
          className="text-default font-medium"
          size="base"
          id="email"
          error={emailError}
        >
          <Input
            id="email"
            aria-invalid={emailError ? "true" : undefined}
            aria-describedby={emailError ? "error-email" : undefined}
            validityState={emailError ? "invalid" : undefined}
            autoComplete="off"
            placeholder="Your Work Email"
            {...register("email", {
              required: "Please enter a valid email address.",
              validate: (value) => {
                const parsedEmail = emailSchema.safeParse(value);
                return (
                  parsedEmail.success || "Please enter a valid email address"
                );
              },
              // NOTE (Fran 2024-02-27): We should clear the errors when the user is typing
              onChange: () => clearErrors("email"),
            })}
            type="email"
            size="base"
          />
        </LabeledControl>
        <LabeledControl
          label="Password"
          className="text-default font-medium"
          size="base"
          id="password"
          error={passwordError}
        >
          <Input
            id="password"
            aria-invalid={passwordError ? "true" : undefined}
            aria-describedby={passwordError ? "error-password" : undefined}
            validityState={passwordError ? "invalid" : undefined}
            autoComplete="off"
            placeholder="••••••••••••"
            {...register("password", {
              required: "Please enter a valid password",
              minLength: {
                message:
                  "Please enter a valid password. Passwords must contain at least 8 characters",
                value: 8,
              },
              maxLength: {
                message:
                  "Please enter a valid password. Passwords must contain at most 100 characters",
                value: 100,
              },
              onChange: () => clearErrors("password"),
            })}
            type="password"
            size="base"
            // NOTE (Fran 2024-02-27): We should clear the errors when the user is typing
          />
        </LabeledControl>
      </div>
      <div className="unselectable mt-10 text-sm text-slate-500">
        By signing up, you agree to the Replo
        <a
          className="px-1 text-blue-600 no-underline"
          href="https://replo.app/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
        and
        <a
          className="px-1 text-blue-600 no-underline"
          href="https://replo.app/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </div>
      <Button
        layoutClassName="mt-6 w-full"
        variant="primary"
        size="base"
        type="submit"
        isLoading={isLoadingCreateAccount}
        disabled={buttonShouldBeDisabled}
      >
        Create Account
      </Button>
      <Separator className="my-4" />
      <GoogleAuth width={400} handleGoogleCredentials={onGoogleSubmit} />
    </form>
  );
};

export default SignupForm;

const useSetReferrerKeyCookie = () => {
  const referrerKeyFromURL = getReferrerKeyFromURL();
  const [cookies, setCookie] = useCookies([REFERRER_KEY_COOKIE]);
  const cookieReferrerKey = cookies.replo_referrerKey;

  const isCookieSet = cookieReferrerKey;
  const isCookieStringAndNotEqualToReferrerKeyFromURL =
    typeof cookieReferrerKey === "string" &&
    cookieReferrerKey !== referrerKeyFromURL;
  const isCookieObjectAndNotEqualToReferrerKeyFromURL =
    typeof cookieReferrerKey === "object" &&
    cookieReferrerKey.referrerKey !== referrerKeyFromURL;

  if (
    referrerKeyFromURL &&
    (!isCookieSet ||
      isCookieStringAndNotEqualToReferrerKeyFromURL ||
      isCookieObjectAndNotEqualToReferrerKeyFromURL)
  ) {
    setCookie(REFERRER_KEY_COOKIE, {
      referrerKey: referrerKeyFromURL,
      createdAt: new Date(),
    });
  }
};

/**
 *  This function checks if the cookie is a string or an object and returns the referrer key if it applies.
 *  - If the cookie is a string, it returns the string. This is deprecated and should be removed after 60 days.
 *  - If the cookie is an object, it returns the referrer key only if the cookie is not older than 60 days.
 */
const useGetReferrerKeyCookiePayload = () => {
  const [cookies] = useCookies([REFERRER_KEY_COOKIE]);
  const cookieReferrerKey = cookies.replo_referrerKey;

  return () => {
    if (!isValidCookie(cookieReferrerKey)) {
      return null;
    }

    // NOTE (Fran 2024-06-05): For a couple of weeks we will keep the old cookie format to avoid losing the data.
    // After that, we will remove the old cookie format
    if (typeof cookieReferrerKey === "string") {
      return cookieReferrerKey;
    }

    return cookieReferrerKey.referrerKey;
  };
};
