import React from "react";

import { sanitizePageUrlPath } from "@editor/utils/analytics";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { Link } from "react-router-dom";
import { getUrlWithoutQueryParams } from "replo-utils/lib/string";

type TablePageCellContentProps = {
  title: string;
  url: string;
  urlPath: string;
  className?: string;
  onPageTitleClick: (urlPath: string) => void;
  onUrlPathClick: () => void;
};

export const TablePageCellContent: React.FC<TablePageCellContentProps> = ({
  title,
  url,
  urlPath,
  onPageTitleClick,
  onUrlPathClick,
  className,
}: TablePageCellContentProps) => {
  const adjustedUrlPath = sanitizePageUrlPath(urlPath);
  const formattedUrl = url ? getUrlWithoutQueryParams(url) : "";

  return (
    <div className={twMerge("flex flex-col gap-1", className)}>
      <Tooltip triggerAsChild content={title} delay={800} align="start">
        <span
          className={twMerge(
            "text-primary truncate hover:underline cursor-pointer",
            className,
          )}
          onClick={() => onPageTitleClick(urlPath)}
        >
          {title}
        </span>
      </Tooltip>
      <Tooltip triggerAsChild content={formattedUrl} delay={800}>
        <Link
          to={formattedUrl ?? ""}
          target="_blank"
          className={twMerge(
            "typ-body-small text-muted hover:underline truncate",
            className,
          )}
          rel="noopener noreferrer"
          onClick={onUrlPathClick}
        >
          {adjustedUrlPath}
        </Link>
      </Tooltip>
    </div>
  );
};
