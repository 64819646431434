import type { FullPageErrorModalProps } from "@editor/components/AppModalTypes";

import * as React from "react";

import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { useModal } from "@editor/hooks/useModal";
import { useEditorDispatch } from "@editor/store";
import { docs } from "@editor/utils/docs";

import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";
import twMerge from "@replo/design-system/utils/twMerge";
import isArray from "lodash-es/isArray";
import { useNavigate, useParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import { ConnectShopifyIntegrationCta } from "./ConnectShopifyCallout";

export const FullPageErrorModal: React.FC<FullPageErrorModalProps> = ({
  details,
}) => {
  const { projectId } = useParams();
  const { project: currentProject } = useCurrentProjectContext();
  const workspaceId = useCurrentWorkspaceId();
  const fullPageError = details;
  const dispatch = useEditorDispatch();
  const navigate = useNavigate();
  const { closeModal } = useModal();

  if (!fullPageError) {
    return null;
  }

  const {
    header,
    subheader,
    message,
    messageDetail,
    isRecoverable,
    callToAction,
  } = fullPageError;

  const getCustomCallbackData = () => {
    if (callToAction?.type === "custom" && callToAction.callToActionType) {
      if (typeof callToAction?.callToActionType === "object") {
        return exhaustiveSwitch(callToAction?.callToActionType)({
          navigateToLink: (data) => {
            return exhaustiveSwitch(data.link)({
              "errors.wrongJsonExtension": () => {
                return {
                  name: "How To Fix",
                  callback: () => {
                    navigate(docs.errors.wrongJsonExtension);
                  },
                };
              },
              "errors.chunkingError": () => {
                return {
                  name: "Learn more",
                  callback: () => {
                    window.location.href = docs.errors.chunkingError;
                  },
                };
              },
              "errors.indexBackupError": () => {
                return {
                  name: "Learn more",
                  callback: () => {
                    window.location.href = docs.errors.indexBackupError;
                  },
                };
              },
              customLink: (data) => {
                return {
                  name: "View More",
                  callback: () => {
                    navigate(data.url);
                  },
                };
              },
            });
          },
        });
      }
      return exhaustiveSwitch({
        type: callToAction?.callToActionType,
      })({
        navigateToProjectEditor: () => ({
          name: "Go back to project",
          callback: () => {
            closeModal({ type: "fullPageErrorModal" });
            navigate(`/editor/${projectId}`);
          },
        }),
        reload: () => null,
        installApp: () => null,
        goToHomepage: () => null,
        closeModal: () => null,
      });
    }
    return null;
  };

  const customCallbackData = getCustomCallbackData();
  const button = callToAction ? (
    exhaustiveSwitch(callToAction)({
      link: ({ to, name, target }) => (
        <Button
          variant="primary"
          size="base"
          to={to}
          target={target}
          layoutClassName="w-full"
          data-testid="refresh-editor-button"
        >
          {name}
        </Button>
      ),
      callback: ({ onClick, name }) => (
        <Button
          variant="primary"
          size="base"
          layoutClassName="w-full"
          data-testid="refresh-editor-button"
          onClick={onClick}
        >
          {name}
        </Button>
      ),
      closeModal: ({ name }) => (
        <Button
          variant="primary"
          size="base"
          layoutClassName="w-full"
          data-testid="refresh-editor-button"
          onClick={() => dispatch(closeModal({ type: "fullPageErrorModal" }))}
        >
          {name}
        </Button>
      ),
      installApp: () => (
        <ConnectShopifyIntegrationCta
          cookieValue={{
            type: "noShopifyErrorModal",
            projectId: currentProject?.id,
            workspaceId: workspaceId,
          }}
        />
      ),
      custom: () => (
        <Button
          variant="primary"
          size="base"
          layoutClassName="w-full"
          data-testid="refresh-editor-button"
          onClick={customCallbackData?.callback}
        >
          {customCallbackData?.name}
        </Button>
      ),
      navigate: (data) => {
        return exhaustiveSwitch({ type: data.location })({
          integrationHub: () => (
            <Button
              variant="primary"
              size="base"
              to={`/workspace/${currentProject?.ownerWorkspaceId}/integrations`}
              onClick={() =>
                dispatch(closeModal({ type: "fullPageErrorModal" }))
              }
              layoutClassName="w-full"
              data-testid="refresh-editor-button"
            >
              View Integrations
            </Button>
          ),
        });
      },
    })
  ) : (
    <Button
      variant="primary"
      size="base"
      data-testid="refresh-editor-button"
      onClick={() => {
        window.location.reload();
      }}
    >
      Refresh Editor
    </Button>
  );

  const messages = isArray(message) ? message : [message];

  return (
    <Modal
      onOpenChange={(open) => {
        if (!open) {
          closeModal({ type: "fullPageErrorModal" });
        }
      }}
      isOpen
      size="sm"
      title={header}
      description={subheader}
      footer={button}
    >
      <div className="flex flex-col gap-4 w-full">
        {messages.map((msg) => (
          <p
            key={msg}
            className={twMerge(
              "typ-body-small",
              !isRecoverable && "text-danger",
            )}
          >
            {msg}
          </p>
        ))}
        {messageDetail && (
          <p className="typ-body-small text-muted">{messageDetail}</p>
        )}
      </div>
    </Modal>
  );
};
