import type { ElementGenericMetafield } from "replo-runtime/shared/types";

export const cloneMetafieldsRemovingIds = (
  shopifyMetafields: ElementGenericMetafield[] | undefined,
) => {
  return (
    shopifyMetafields?.map((metafield) => ({
      key: metafield.key,
      value: metafield.value,
      namespace: metafield.namespace,
      type: metafield.type,
    })) ?? []
  );
};
