import React from "react";

import Selectable from "@editor/components/common/designSystem/Selectable";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

const NEW_SHOPIFY_INTEGRATION_ID = "NEW_STORE_INTEGRATION";

export function ShopifyIntegrationSelectable({
  workspaceId,
  selectedIntegrationId,
  handleChangeSelection,
  isDisabled = false,
  size = "base",
}: {
  workspaceId?: string | null;
  selectedIntegrationId?: string | null;
  handleChangeSelection: (id: string | null) => void;
  isDisabled?: boolean;
  size?: "base" | "sm";
}) {
  const { data } = trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );
  const existingShopifyIntegrations = data?.integrations;
  const shopifyIntegrations = React.useMemo(
    () => [
      ...(existingShopifyIntegrations ?? []),
      {
        id: NEW_SHOPIFY_INTEGRATION_ID,
        shopifyIntegrationConfig: {
          url: "+ Add a new Shopify Store",
        },
      },
    ],
    [existingShopifyIntegrations],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies(workspaceId): We actually want this
  React.useEffect(() => {
    if (
      selectedIntegrationId &&
      !shopifyIntegrations.find(({ id }) => id == selectedIntegrationId)
    ) {
      handleChangeSelection(null);
    }
  }, [
    shopifyIntegrations,
    workspaceId,
    selectedIntegrationId,
    handleChangeSelection,
  ]);
  return (
    <Selectable
      size={size}
      placeholder="Choose Shopify Store"
      options={shopifyIntegrations.map((integration) => ({
        label: integration.shopifyIntegrationConfig?.url ?? "",
        value: integration.id,
      }))}
      value={selectedIntegrationId}
      onSelect={handleChangeSelection}
      isDisabled={isDisabled}
    />
  );
}
