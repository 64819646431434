import * as React from "react";

export const NoChartData: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-[24px]">
      <div className="relative">
        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
        <div className="absolute top-0 left-0 w-4 h-4 bg-blue-500 rounded-full animate-ping opacity-75"></div>
      </div>
      <div className="flex flex-col items-center justify-between gap-[8px]">
        <div className="text-lg font-semibold text-slate-800">
          No data found...
        </div>
        <div className="text-sm text-slate-500 font-normal">
          There's not enough data to show yet. Check back later.
        </div>
      </div>
    </div>
  );
};
