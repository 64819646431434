import * as React from "react";

import TourStepTrigger from "@components/flows/TourStepTrigger";
import { createPreviewData } from "@editor/actions/core-actions";
import PreviewPageButton from "@editor/components/header/PreviewPageButton";
import { PublishPageButton } from "@editor/components/header/PublishPageButton";
import { PublishPageButtonWithCallout } from "@editor/components/header/PublishPageButtonWithCallout";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { selectIsShopifyStoreClosed } from "@editor/reducers/ui-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { getStoreData } from "@editor/utils/project-utils";
import { selectDraftElementType } from "@reducers/core-reducer";

import { useExperimentCreate } from "@/features/experiments/tabs/hooks/useExperimentCreate";
import { generateExperimentVariation } from "@/features/experiments/utils";
import {
  errorToast,
  successToast,
} from "@replo/design-system/components/alert/Toast";
import IconButton from "@replo/design-system/components/button/IconButton";
import { AnimatePresence, motion } from "framer-motion";
import { ExternalLink, FlaskConical, Users } from "lucide-react";

export default function PageControlButtons() {
  const modal = useModal();
  const draftElementType = useEditorSelector(selectDraftElementType);
  const { project } = useCurrentProjectContext();
  const store = getStoreData(project);
  const isStoreClosed = useEditorSelector(selectIsShopifyStoreClosed);
  const reduxStore = useEditorStore();
  const { storeUrl } = useGetStoreNameAndUrl();
  const projectId = useCurrentProjectId();
  const currentProduct = useEditorSelector(selectTemplateEditorStoreProduct);

  const logEvent = useLogAnalytics();
  const { shopifyUrl, urlIsDisabled, tooltipLabel, customDomainUrl } =
    usePublishedInfo();

  const { workspaceId, isLoading: isWorkspaceLoading } =
    useCurrentWorkspaceContext();
  const { createExperiment, isCreatingExperiment } = useExperimentCreate(
    workspaceId ?? "",
  );

  if (!draftElementType) {
    return null;
  }

  const showABTestButton =
    draftElementType === "page" ||
    draftElementType === "shopifyProductTemplate";

  const handleStartABTest = async () => {
    if (!shopifyUrl) {
      errorToast(
        "Error creating test",
        "Please try again, or contact support@replo.app for help.",
      );
      return;
    }

    await createExperiment({
      location: "editor_header",
      partialExperiment: {
        variations: [
          generateExperimentVariation({
            slug: "Variant-A",
            target: customDomainUrl ?? shopifyUrl,
          }),
          generateExperimentVariation({
            slug: "Variant-B",
            target: null,
          }),
        ],
      },
      onExperimentCreateSuccess: () => {
        successToast(
          "Test Created",
          "Select one other page and choose a link to start",
        );
      },
    });
  };

  const productNotAvailableOnProductTemplate =
    draftElementType === "shopifyProductTemplate" && !currentProduct?.handle;

  return (
    <div className="flex items-center gap-2">
      <IconButton
        variant="secondary"
        size="base"
        tooltipText="Share Project"
        icon={<Users size={16} />}
        onClick={() => {
          modal.openModal({
            type: "projectMembershipModal",
          });
        }}
      />
      <div className="w-[1px] h-[16px] bg-slate-300" />
      {showABTestButton && (
        <IconButton
          variant="secondary"
          size="base"
          icon={<FlaskConical size={16} />}
          onClick={() => void handleStartABTest()}
          disabled={
            !shopifyUrl ||
            !customDomainUrl ||
            isCreatingExperiment ||
            isWorkspaceLoading ||
            productNotAvailableOnProductTemplate
          }
          tooltipText={
            shopifyUrl ? "Run A/B test" : "Publish page to run A/B test"
          }
        />
      )}
      <PreviewPageButton
        isProjectConnectedToShopify={Boolean(store?.shopifyUrl)}
        onCreatePreviewData={() => {
          if (!projectId || !storeUrl) {
            return;
          }
          return createPreviewData({
            state: reduxStore.getState(),
            projectId,
            storeUrl,
          });
        }}
        isStoreClosed={isStoreClosed}
      />
      <AnimatePresence>
        {!urlIsDisabled && (
          <motion.div
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: "auto", opacity: 1 }}
            exit={{ width: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          >
            <IconButton
              variant="secondary"
              data-testid="live-page-button"
              size="base"
              disabled={urlIsDisabled}
              icon={<ExternalLink size={16} />}
              tooltipText={tooltipLabel}
              aria-label={tooltipLabel}
              onClick={() => {
                if (shopifyUrl) {
                  logEvent("header.viewPage", {});
                  window.open(shopifyUrl, "_blank");
                }
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <TourStepTrigger step="step-5">
        {store?.shopifyUrl ? (
          <PublishPageButton isPreviewBeforePublishView />
        ) : (
          <PublishPageButtonWithCallout />
        )}
      </TourStepTrigger>
    </div>
  );
}
