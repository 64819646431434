import type { Swatch, SwatchType } from "replo-runtime/shared/types";

import * as React from "react";

import SwatchPopover from "@components/editor/page/SwatchPopover";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import SearcheableList from "@editor/components/editor/page/element-editor/components/SearcheableList";
import {
  selectDraftComponentType,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import { skipToken } from "@tanstack/react-query";
import { BsPlus } from "react-icons/bs";
import { GiSettingsKnobs } from "react-icons/gi";
import { RiPaletteLine } from "react-icons/ri";
import { fakeSwatches } from "replo-runtime/store/utils/fakeSwatches";
import { v4 as uuidv4 } from "uuid";

type SwatchToggleOption = "swatches" | "placeholders";

export const SwatchesCustomPropModifier: React.FC = () => {
  const projectId = useEditorSelector(selectProjectId);
  const { data: swatches = [] } = trpc.swatch.list.useQuery(
    projectId ? { projectId } : skipToken,
  );
  const [activeSwatchId, setActiveSwatchId] = React.useState<string | null>(
    null,
  );
  const componentType = useEditorSelector(selectDraftComponentType);

  const activeSwatch =
    swatches.find(({ id }) => id === activeSwatchId) ??
    fakeSwatches.find(({ id }) => id === activeSwatchId);

  const [swatchToggleValue, setSwatchToggleValue] =
    React.useState<SwatchToggleOption>("swatches");

  return (
    <ModifierGroup
      title="Swatches"
      icon={BsPlus}
      onClick={() => setActiveSwatchId(uuidv4())}
      hideEndEnhancerOnGroupClosed={false}
      iconTooltip="Add a swatch"
    >
      <div className="flex flex-col gap-2">
        <ToggleGroup
          size="sm"
          options={[
            {
              label: "My Swatches",
              value: "swatches",
            },
            {
              label: "Placeholders",
              value: "placeholders",
            },
          ]}
          selectedValue={swatchToggleValue}
          onChange={(value) => {
            setSwatchToggleValue(value as SwatchToggleOption);
          }}
        />
        {swatchToggleValue === "placeholders" && (
          <SearcheableList
            title="Swatches"
            options={fakeSwatches.map(({ id, name }) => ({
              label: name,
              value: id,
              content: () => (
                <SelectionIndicator
                  title={name}
                  onClick={() => setActiveSwatchId(id)}
                  startEnhancer={
                    <RiPaletteLine
                      className="rounded bg-blue-600 p-0.5 text-white"
                      size={16}
                    />
                  }
                  endEnhancer={
                    <GiSettingsKnobs
                      size={12}
                      onClick={() => setActiveSwatchId(id)}
                    />
                  }
                />
              ),
            }))}
          />
        )}
        {swatchToggleValue === "swatches" && swatches.length > 0 && (
          <SearcheableList
            title="Swatches"
            options={swatches.map(({ id, name }) => ({
              label: name,
              value: id,
              content: () => (
                <SelectionIndicator
                  title={name}
                  onClick={() => setActiveSwatchId(id)}
                  startEnhancer={
                    <RiPaletteLine
                      className="rounded bg-blue-600 p-0.5 text-white"
                      size={16}
                    />
                  }
                  endEnhancer={
                    <GiSettingsKnobs
                      size={12}
                      onClick={() => setActiveSwatchId(id)}
                    />
                  }
                />
              ),
            }))}
          />
        )}
        {swatchToggleValue === "swatches" && swatches.length === 0 && (
          <div
            className="cursor-pointer text-left text-xs text-gray-400"
            onClick={() => setActiveSwatchId(uuidv4())}
          >
            Click the + icon to add a swatch
          </div>
        )}
        <SwatchPopover
          swatch={
            activeSwatch ??
            createNewSwatch(
              activeSwatchId!,
              componentType === "variantSelect" ? "variant" : "option",
            )
          }
          isReadOnly={fakeSwatches.some(({ id }) => id === activeSwatchId)}
          isOpen={activeSwatchId !== null}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setActiveSwatchId(null);
            }
          }}
          isNewSwatch={!activeSwatch}
        />
      </div>
    </ModifierGroup>
  );
};

function createNewSwatch(id: string, defaultType: SwatchType): Swatch {
  return {
    id,
    name: "",
    data: {
      type: defaultType,
      productIds: [],
      options: [],
      variants: [],
    },
  };
}
