import type { ReploAnnouncementTag } from "schemas/generated/announcement";

import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { useListAnnouncements } from "@/features/announcements/hooks/useListAnnouncements";
import { extractTextFromFirstParagraph } from "@/features/announcements/utils/announcement";
import IconButton from "@replo/design-system/components/button/IconButton";
import { ChevronLeft, ChevronRight } from "lucide-react";

const AnnouncementBanner = ({ tags }: { tags: ReploAnnouncementTag[] }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { announcements, isLoading } = useListAnnouncements({
    tags: tags,
    limit: 3,
  });
  const currentAnnouncement = announcements[currentIndex];
  const logEvent = useLogAnalytics();

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? announcements.length - 1 : prevIndex - 1,
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === announcements.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handleAnnouncementClick = () => {
    if (currentAnnouncement && currentAnnouncement.ctaLink) {
      window.open(currentAnnouncement.ctaLink, "_blank");
      logEvent("announcements.clicked", {
        announcementId: currentAnnouncement.id,
        announcementWebflowId: currentAnnouncement.webflowId,
        source: "AnnouncementBanner",
      });
    }
  };

  if (isLoading || announcements.length === 0 || !currentAnnouncement) {
    return null;
  }

  return (
    <div className="bg-neutral-soft rounded border border-border border-solid p-4 flex justify-between">
      <div className="flex gap-4 w-full max-h-24">
        {currentAnnouncement.imageUrl && (
          <img
            src={currentAnnouncement.imageUrl}
            alt="Announcement"
            className="h-full w-auto rounded"
            loading="lazy"
          />
        )}

        <div className="flex flex-col items-start justify-center">
          <p className="typ-label-base mb-1">{currentAnnouncement.title}</p>
          <p className="typ-body-base mb-2 overflow-hidden line-clamp-2">
            {extractTextFromFirstParagraph(currentAnnouncement.description)}
          </p>

          {currentAnnouncement.ctaText && currentAnnouncement.ctaLink && (
            <div
              className="typ-button-base text-primary cursor-pointer"
              onClick={handleAnnouncementClick}
            >
              {currentAnnouncement.ctaText}
            </div>
          )}
        </div>
      </div>

      {announcements.length && (
        <div className="flex gap-1 flex-shrink-0 ml-4">
          <IconButton
            icon={<ChevronLeft size={16} />}
            onClick={goToPrevious}
            size="base"
            variant="tertiary"
            aria-label="Previous announcement"
            disabled={currentIndex === 0}
            layoutClassName=" w-8 h-8"
          />
          <IconButton
            icon={<ChevronRight size={16} />}
            onClick={goToNext}
            size="base"
            variant="tertiary"
            aria-label="Next announcement"
            disabled={currentIndex === announcements.length - 1}
            layoutClassName="w-8 h-8"
          />
        </div>
      )}
    </div>
  );
};

export default AnnouncementBanner;
