import type { ComponentTemplate } from "@editor/types/component-template";
import type {
  ComponentTemplateCategoryId,
  ComponentTemplateIndustryId,
} from "schemas/componentTemplates";
import type { ComponentTemplateScope } from "schemas/generated/componentTemplates";
import type { ResponsiveToggleAllowedDevices } from "../common/designSystem/ResponsiveToggle";

import React from "react";

import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useInfiniteTemplates from "@editor/hooks/useInfiniteTemplates";

import EmptyState from "@replo/design-system/components/empty-state/EmptyState";
import twMerge from "@replo/design-system/utils/twMerge";
import { Bookmark, FileSearch } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";

import TemplateCard from "./TemplateCard";
import { TemplatesGridSkeleton } from "./TemplatesGridSkeleton";

interface TemplatesGridProps {
  searchTerm: string;
  selectedIndustryIds: ComponentTemplateIndustryId[];
  selectedCategoryIds: ComponentTemplateCategoryId[];
  templateScope: Omit<ComponentTemplateScope, "left-bar" | "unlisted">;
  setSubpageTemplate: (template: ComponentTemplate | null) => void;
  previewPlatform: ResponsiveToggleAllowedDevices;
  gridRef?: React.RefObject<HTMLDivElement>;
}

const TemplatesGrid: React.FC<TemplatesGridProps> = ({
  searchTerm,
  selectedIndustryIds,
  selectedCategoryIds,
  setSubpageTemplate,
  previewPlatform,
  gridRef,
  templateScope,
}) => {
  const projectId = useCurrentProjectId()!;

  const { componentTemplatesList, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteTemplates({
      searchText: searchTerm,
      includeComponent: true,
      selectedIndustries: selectedIndustryIds,
      selectedCategories: selectedCategoryIds,
      componentCategoryType: ["page"],
      scope: templateScope as "global" | "store",
      cursor: 0,
      pageSize: 15,
      includeArchivedTemplates: false,
      storeId: projectId,
    });

  const templatesLength = componentTemplatesList?.length ?? 0;

  const mobileGridClasses =
    "grid-cols-1 min-[700px]:grid-cols-2 min-[1200px]:grid-cols-3 min-[1600px]:grid-cols-4 min-[2000px]:grid-cols-5";
  const desktopGridClasses =
    "grid-cols-1 min-[900px]:grid-cols-2 min-[1350px]:grid-cols-3 min-[2400px]:grid-cols-4";

  if (isLoading) {
    return <TemplatesGridSkeleton cardCount={15} />;
  }

  return (
    <div
      className="flex flex-1 flex-col pr-1 no-scrollbar cursor-default overflow-scroll max-h-screen pb-72"
      ref={gridRef}
      id="templates-grid"
    >
      {templatesLength === 0 ? (
        <EmptyState
          title={
            templateScope === "store" && searchTerm.length === 0
              ? "No saved pages yet"
              : `No templates matching "${searchTerm}"`
          }
          description={
            templateScope === "store" && searchTerm.length === 0
              ? "Press ⌘⇧S on your selected page in the editor to save it."
              : "Try searching for a different template or using our filters to find a perfect match."
          }
          icon={
            templateScope === "store" && searchTerm.length === 0 ? (
              <Bookmark size={24} />
            ) : (
              <FileSearch size={24} />
            )
          }
          variant="secondary"
        />
      ) : null}
      <InfiniteScroll
        dataLength={templatesLength}
        next={() => void fetchNextPage()}
        hasMore={hasNextPage}
        loader={<TemplatesGridSkeleton cardCount={3} />}
        scrollableTarget="templates-grid"
      >
        <div
          className={twMerge(
            "grid gap-x-2 gap-y-5",
            previewPlatform === "mobile"
              ? mobileGridClasses
              : desktopGridClasses,
          )}
        >
          {componentTemplatesList?.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              setSubpageTemplate={setSubpageTemplate}
              previewPlatform={previewPlatform}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default TemplatesGrid;
