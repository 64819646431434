import type { Component } from "schemas/component";
import type { StoreApi } from "zustand";

import * as React from "react";

import { useRequiredContext } from "replo-utils/react/context";
import { createStore, useStore } from "zustand";

type KlaviyoIdentifiersContextState = {
  componentIdToIdentifier: Record<Component["id"], string>;
  actions: {
    registerIdentifier: (
      componentId: Component["id"],
      identifier: string,
    ) => void;
  };
};

export type KlaviyoIdentifiersStore = StoreApi<KlaviyoIdentifiersContextState>;

export const KlaviyoIdentifiersContext =
  React.createContext<KlaviyoIdentifiersStore | null>(null);

export const createKlaviyoIdentifiersStore = () => {
  return createStore<KlaviyoIdentifiersContextState>((set) => {
    return {
      componentIdToIdentifier: {},
      actions: {
        registerIdentifier: (
          componentId: Component["id"],
          identifier: string,
        ) => {
          set((previousState) => {
            return {
              ...previousState,
              componentIdToIdentifier: {
                ...previousState.componentIdToIdentifier,
                [componentId]: identifier,
              },
            };
          });
        },
      },
    };
  });
};

export const useKlaviyoIdentifiersStore = () => {
  return useRequiredContext(KlaviyoIdentifiersContext);
};

export const useRegisterKlaviyoIdentifier = () => {
  const store = useKlaviyoIdentifiersStore();
  const registerIdentifier = useStore(
    store,
    (state) => state.actions.registerIdentifier,
  );
  return registerIdentifier;
};
