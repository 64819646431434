import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";

import { setDraftElement } from "@editor/actions/core-actions";
import { getEditorComponentNode } from "@utils/component";

import {
  findComponentByTypeDepthFirst,
  getChildren,
} from "replo-runtime/shared/utils/component";
import { isItemsDynamic } from "replo-runtime/store/utils/items";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

export const controlOnChange = (
  canvas: EditorCanvas,
  component: Component,
  dispatch: any,
  value: any,
) => {
  return exhaustiveSwitch(component)({
    button: null,
    buyWithPrimeButton: null,
    carouselV2: null,
    carouselV2__indicator: null,
    carouselV2__panels: null,
    carouselPanelsCount: null,
    carouselV3: () => {
      const slideListComponent = findComponentByTypeDepthFirst(
        component,
        "carouselV3Slides",
      );

      const carouselHasDynamicData = isItemsDynamic(component?.props._items);

      if (!slideListComponent) {
        return;
      }

      const slideListComponentNode = getEditorComponentNode({
        canvas,
        componentId: slideListComponent?.id,
      });

      const repeatedIndex = slideListComponentNode?.getAttribute(
        "data-replo-repeated-index",
      );

      const slideChildren = getChildren(slideListComponent);

      const componentId = carouselHasDynamicData
        ? slideChildren[0]?.id
        : slideChildren[value]?.id;

      dispatch(
        setDraftElement({
          componentIds: componentId ? [componentId] : undefined,
          // Note (Ovishek, 2023-02-03): Here we need an extra .0 added in the middle of repeated index b/c
          // there is always ReploComponent in between two store component render, like SlidesComponent calls ->
          // ReploComponent calls -> Side 1 Component, and we are always adding and extra ".0" in
          // ReploComponent
          repeatedIndex: repeatedIndex ? `${repeatedIndex}.0.${value}` : null,
        }),
      );
    },
    carouselV3Control: null,
    carouselV3Indicators: null,
    carouselV3Slides: null,
    circle: null,
    collapsible: null,
    collapsibleV2: null,
    collapsibleV2Header: null,
    collapsibleV2Content: null,
    collection: null,
    collectionSelect: null,
    collectionV2: null,
    container: null,
    countdownTimer: null,
    dropdown: null,
    googleMapsEmbed: null,
    spinner: null,
    h1: null,
    h2: null,
    h3: null,
    icon: null,
    image: null,
    klaviyoEmbed: null,
    marquee: null,
    modal: null,
    optionSelect: null,
    optionSelectDropdown: null,
    player: null,
    player__fullScreenIcon: null,
    player__muteIcon: null,
    player__playIcon: null,
    product: null,
    productCollection: null,
    quantitySelector: null,
    rawHtmlContent: null,
    rechargeSubscriptionWidget: null,
    staySubscriptionWidget: null,
    shopifyRawLiquid: null,
    shopifyAppBlocks: null,
    shopifySection: null,
    slidingCarousel: null,
    spacer: null,
    starRating: null,
    symbolRef: null,
    tabsBlock: null,
    tabsV2__block: null,
    tabsV2__list: null,
    tabsV2__panelsContent: null,
    tabs__list: null,
    tabs__onePanelContent: null,
    tabs__panelsContent: null,
    temporaryCart: null,
    temporaryCartItems: null,
    text: null,
    tikTokEmbed: null,
    toggleContainer: null,
    toggleIndicator: null,
    tooltip: null,
    tooltipContent: null,
    variantSelect: null,
    variantSelectDropdown: null,
    sellingPlanSelect: null,
    vimeoEmbed: null,
    vimeoEmbedV2: null,
    youtubeEmbed: null,
    youtubeEmbedV2: null,
    okendoProductRatingSummary: null,
    okendoReviewsWidget: null,
    junipProductRating: null,
    junipReviews: null,
    yotpoProductRating: null,
    yotpoReviews: null,
    looxProductRating: null,
    looxReviews: null,
    knoCommerceWidget: null,
    reviewsIoProductRating: null,
    reviewsIoReviews: null,
    dynamicCheckoutButtons: null,
    paymentTerms: null,
    sellingPlanSelectDropdown: null,
    accordionBlock: null,
    rebuyWidget: null,
    stampedProductReviewsWidget: null,
    stampedProductRatingWidget: null,
    feraProductRatingWidget: null,
    feraProductReviewsWidget: null,
    feraStoreReviewsWidget: null,
    feraMediaGalleryWidget: null,
    shopifyProductReviewsWidget: null,
    shopifyProductRatingWidget: null,
    judgeProductRatingWidget: null,
    judgeProductReviewsWidget: null,
    infiniteOptionsWidget: null,
    kachingBundles: null,
    postscriptSignupForm: null,
    subscribeAndSave: null,
    beforeAfterSlider: null,
    beforeAfterSliderThumb: null,
    beforeAfterSliderBeforeContent: null,
    beforeAfterSliderAfterContent: null,
    selectionList: null,
  });
};
