import type { StreamingUpdate } from "@editor/types/core-state";
import type { SchemaReploShopifyProduct } from "schemas/generated/product";

import React, { useState } from "react";

import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectComponentData,
  selectDraftComponents,
  selectRootComponent,
  selectStreamingUpdate,
  selectStreamingUpdateId,
  setEditorMode,
  setStreamingUpdate,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import {
  getComponentAlignment,
  getComponentPositioning,
  getIconAndMessage,
} from "@editor/utils/tree-utils";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import ReploButton from "@replo/design-system/components/button/Button";
import Chip from "@replo/design-system/components/chip/SelectableChip";
import Popover from "@replo/design-system/components/popover/Popover";
import twMerge from "@replo/design-system/utils/twMerge";
import { ChevronDown, MousePointerClick } from "lucide-react";
import { editorCanvasToMediaSize } from "replo-runtime/shared/utils/breakpoints";

import PromptFeedback from "../PromptFeedback";
import { BuildAssistantPrompt } from "./BuildAssistantPrompt";

interface BuildAssistantPopoverProps {
  isOpen: boolean;
  onClose: () => void;
  triggerRef?: React.RefObject<HTMLElement>;
  children: JSX.Element;
}

export const BuildAssistantPopover: React.FC<BuildAssistantPopoverProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  const [localStreamingUpdate, setLocalStreamingUpdate] =
    useState<StreamingUpdate | null>(null);
  const [prompt, setPrompt] = useState("");
  const [canvasState, setCanvasState] =
    React.useState<string>("build-assistant");
  const [generationState, setGenerationState] = useState<
    "generating" | "finished"
  >("generating");
  const [selectedProduct, setSelectedProduct] =
    useState<SchemaReploShopifyProduct | null>();

  const dispatch = useEditorDispatch();
  const store = useEditorStore();
  const logEvent = useLogAnalytics();
  const {
    abort,
    initiateParallelGeneration,
    applyChanges,
    isGenerating,
    discardChanges,
    revert,
  } = useAIStreaming();

  const draftComponents = useEditorSelector(selectDraftComponents);

  const handleBuild = () => {
    if (!prompt) {
      return;
    }

    logEvent("ai.action.submitted", {
      prompt,
      triggeringFeature: "build-assistant",
    });

    const rootComponent = selectRootComponent(store.getState());
    if (!rootComponent) {
      return;
    }

    const componentsToGenerate = draftComponents ?? [];

    initiateParallelGeneration(
      {
        type: "template",
        userPrompt: prompt,
        conversationMessages: [],
        nonce: 0,
        components: componentsToGenerate,
        selectedProduct,
        triggeringFeature: "build-assistant",
      },
      () => {
        setPrompt("");
        setGenerationState("finished");
        const streamingUpdate = selectStreamingUpdate(store.getState());
        setLocalStreamingUpdate(streamingUpdate);
      },
    );
  };

  const handleAbort = () => {
    // TODO (Yuxin, 2025-03-16): Add triggeringFeature to the event
    // since you can abort in both the new page and the build assistant
    logEvent("ai.action.aborted");
    abort();
  };

  function handleUndo() {
    if (localStreamingUpdate) {
      revert();
    }
  }

  function handleRedo() {
    dispatch(setStreamingUpdate(localStreamingUpdate));
  }

  const onAcceptChanges = () => {
    setGenerationState("generating");
    dispatch(setEditorMode(EditorMode.edit));
    setCanvasState("build-assistant");

    // Get streaming update ID before applying changes
    const streamingUpdateId = selectStreamingUpdateId(store.getState());

    // Log analytics event for approved AI action
    if (streamingUpdateId) {
      logEvent("ai.action.approved", {
        streamingUpdateId,
        model: isFeatureEnabled("ai-claude")
          ? "claude-3-7-sonnet-20250219"
          : "gpt-4o",
        triggeringFeature: "build-assistant",
      });
    }

    dispatch(setStreamingUpdate(localStreamingUpdate));
    applyChanges();
    dispatch(setStreamingUpdate(null));
  };

  const onDiscardChanges = () => {
    setGenerationState("generating");
    dispatch(setEditorMode(EditorMode.edit));
    setCanvasState("build-assistant");

    // Get streaming update ID before discarding changes
    const streamingUpdateId = selectStreamingUpdateId(store.getState());

    // Log analytics event for rejected AI action
    if (streamingUpdateId) {
      logEvent("ai.action.rejected", {
        streamingUpdateId,
        model: isFeatureEnabled("ai-claude")
          ? "claude-3-7-sonnet-20250219"
          : "gpt-4o",
        triggeringFeature: "build-assistant",
      });
    }

    discardChanges();
  };

  useReploHotkeys({
    enter: handleBuild,
    escape: handleAbort,
  });

  const AIPrompt = isGenerating ? (
    <div className="flex items-center gap-2 text-foreground">
      <img
        src="/images/templates/ai-loader.svg"
        alt="AI is thinking"
        className="w-6 h-6 animate-spin"
      />
      <span className="typ-body-base">Applying changes...</span>
      <div className="ml-auto">
        <ReploButton variant="secondary" onClick={handleAbort}>
          <div className="flex items-center gap-1">
            Stop <span className="text-[10px] font-mono">esc</span>
          </div>
        </ReploButton>
      </div>
    </div>
  ) : (
    <>
      <div
        className={twMerge(
          "flex justify-between items-center gap-1",
          draftComponents.length > 0 && "mb-3 items-start",
        )}
      >
        <div className="flex items-center gap-1 flex-wrap">
          {draftComponents.length > 0 ? (
            draftComponents.map((component) => {
              const activeCanvas = selectActiveCanvas(store.getState());
              const componentPosition = getComponentPositioning(
                editorCanvasToMediaSize[activeCanvas].mediaSize,
                component ?? undefined,
              );
              const componentHasActions = Boolean(
                component?.props?.onClick?.length ||
                  component?.props?.onHover?.length,
              );
              const componentAlignment = getComponentAlignment(
                editorCanvasToMediaSize[activeCanvas].mediaSize,
                component ?? undefined,
              );
              const rootComponent = selectRootComponent(store.getState());
              const isPageRoot = component.id === rootComponent?.id;

              const { icon } = getIconAndMessage(
                isPageRoot,
                component.props.direction,
                component.type,
                component.props.iconName,
                componentPosition,
                componentHasActions,
                component.name ?? "",
                componentAlignment,
                null,
              );

              const componentData = selectComponentData(
                store.getState(),
                component.id,
              );
              const label = componentData?.label;

              return (
                <Chip
                  key={component.id}
                  size="sm"
                  active={false}
                  UNSAFE_className="hover:bg-transparent cursor-default"
                >
                  <div className="flex items-center gap-1">
                    <div className="text-muted">{icon}</div>
                    <span className="text-sm truncate max-w-[320px]">
                      {label || component.name || component.type}
                    </span>
                  </div>
                </Chip>
              );
            })
          ) : (
            <>
              <MousePointerClick size={16} />
              <div className="text-sm font-semibold ml-1">
                First, select something to edit
              </div>
            </>
          )}
        </div>

        <button
          onClick={onClose}
          className="p-1 rounded-full hover:bg-hover transition-colors ml-auto"
          aria-label="Close Build Assistant"
        >
          <ChevronDown size={16} />
        </button>
      </div>

      {draftComponents.length > 0 && (
        <BuildAssistantPrompt
          prompt={prompt}
          setPrompt={setPrompt}
          placeholderText="Ask for any edits or customizations"
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          onBuild={handleBuild}
          showBrandContextPreview={false}
          showFontSample={false}
          fontSampleTriggerClassName="border-0 p-0"
          colorOverlapSize="large"
          selectProductClassName="max-w-[250px] min-w-[150px]"
          selectProductTriggerClassName="h-[24px] p-1 border-dashed"
          selectProductSelectionIndicatorClassName="h-4 w-4"
          showSendButton={true}
          textareaClassName="h-[104px]"
          isDisabled={draftComponents.length === 0}
        />
      )}
    </>
  );

  return (
    <Popover
      isOpen={isOpen}
      side="top"
      shouldPreventDefaultOnInteractOutside={false}
      variant="unstyled"
      content={
        <div className="p-3 bg-white rounded-lg shadow-dropdown w-[420px] border-0.5 border-border relative -translate-x-[115px] -translate-y-[6px]">
          {generationState === "finished" && (
            <PromptFeedback
              onAccept={onAcceptChanges}
              onDiscard={onDiscardChanges}
              canvasState={canvasState}
              handleUndo={handleUndo}
              handleRedo={handleRedo}
              setCanvasState={setCanvasState}
            />
          )}
          {generationState !== "finished" && AIPrompt}
        </div>
      }
    >
      {children}
    </Popover>
  );
};
