import React from "react";

import { Skeleton } from "@replo/design-system/components/skeleton/Skeleton";

const TextSavedStyleSkeleton = () => {
  return (
    <>
      <Skeleton className="w-full h-12 rounded-md" />
      {Array.from({ length: 4 }, (_, i) => (
        <TextSavedStyleRowSkeleton key={i} />
      ))}
    </>
  );
};

const TextSavedStyleRowSkeleton = () => {
  return (
    <div className="flex rounded w-full p-1 gap-2">
      <Skeleton className="w-8 h-8 rounded-md" />
      <div className="flex flex-col flex-grow min-w-0">
        <Skeleton className="w-14 h-4 rounded" />
        <Skeleton className="w-28 h-3 rounded mt-1" />
      </div>
    </div>
  );
};

export default TextSavedStyleSkeleton;
