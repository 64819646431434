import type { FormKey } from "@editor/contexts/ElementEditorErrorContext";
import type {
  ReploElement,
  ReploElementMetadata,
  ReploElementType,
  ReploPartialElement,
} from "schemas/generated/element";

import { docs } from "@editor/utils/docs";

import { format } from "date-fns";
import { exhaustiveSwitch, isEmpty } from "replo-utils/lib/misc";
import { slugify } from "replo-utils/lib/string";

import validatePageModalPath from "./validatePageModalPath";

export const MAX_SECTION_NAME_LENGTH = 25;

export const INVALID_ELEMENT_NAME_CHARACTERS_REGEX =
  /[^\p{L}\p{M}\p{N}\p{P}\p{Z} -~]+/gu;

export const REPLO_ELEMENT_DEFAULT_FOLDER_NAME = "REPLO_WITHOUT_FOLDER";

const shouldValidateShopifyPagePath = (type: ReploElement["type"]) => {
  return exhaustiveSwitch({ type })({
    page: true,
    shopifyArticle: true,
    shopifyProductTemplate: false,
    shopifySection: false,
  });
};

export type ElementRequestType =
  | "new"
  | "update"
  | "duplicate"
  | "duplicate.support";

export type PageLayoutType =
  | "no-header-footer"
  | "no-footer"
  | "no-header"
  | "default";

export type ElementErrorType =
  | "invalidCustomHtml"
  | "invalidCustomCss"
  | "loadingMissingData"
  | "errorLoadingData"
  | "storeHasNoBlogs"
  | "pathAlreadyExists"
  | "isInvalidPathName"
  | "isInvalidSectionName"
  | "pathIsEmpty"
  | "nameIsEmpty";

type ReploEditableElement =
  | ReploElement
  | ReploPartialElement
  | ReploElementMetadata;

export type ReploEditableElementWithId = ReploEditableElement & {
  id: ReploElement["id"];
};

export function sanitizeElementName(name: string, type: ReploElementType) {
  switch (type) {
    case "shopifySection":
      return name.length > MAX_SECTION_NAME_LENGTH
        ? name.slice(0, MAX_SECTION_NAME_LENGTH)
        : name;
    default:
      return name;
  }
}

export function isElementPartial(
  value: ReploPartialElement | ReploElement | null,
): value is ReploPartialElement {
  if (!value) {
    return false;
  }
  return !("id" in value);
}

export function getPageUrl({
  storeUrl,
  shopifyPagePath,
}: {
  storeUrl: string | undefined;
  shopifyPagePath: string | undefined;
}) {
  return shopifyPagePath && storeUrl
    ? `https://${storeUrl}/pages/${shopifyPagePath}`
    : undefined;
}

export function elementFormHasErrors(
  errorMapping: Record<FormKey, ElementErrorType[] | null>,
): boolean {
  return Object.values(errorMapping).some((errors) => !isEmpty(errors));
}

/**
 * Takes the name of an element and returns a name suffixed by the current datetime
 * to avoid duplicate names. We assume timestamp + hash generates a unique name
 */
export function getDuplicatedElementName({
  name,
  now,
  elementType,
  isSupportCopy,
}: {
  name: string;
  now: Date;
  elementType: ReploElementType;
  isSupportCopy: boolean;
}) {
  if (!name) {
    return "Copy";
  }
  if (elementType === "shopifySection") {
    return isSupportCopy ? `(Support Copy) ${name}` : `(Copy) ${name}`;
  }
  const timestamp = format(now, "yyyy-MM-dd hh:mm:ssaaa");
  return `${name} (Copied ${timestamp})`;
}

export function getDuplicateElementPath({
  name,
  now,
  path,
  isSupportCopy,
}: {
  name: string;
  now: Date;
  path?: string;
  isSupportCopy?: boolean;
}) {
  if (!path) {
    return slugify(name);
  }
  const timestamp = format(now, "yyyy-MM-dd-hh-mm-ss-aaa");
  if (isSupportCopy) {
    return `support-copy-${path}-${timestamp}`;
  }
  return `${path}-${timestamp}`;
}

type ElementErrorMessages = {
  [K in ElementErrorType]: {
    [key in ReploElementType]?: {
      title: string;
      description: string;
      link?: {
        text: string;
        href: string;
      };
    } | null;
  };
};

export const errorMessages: ElementErrorMessages = {
  // NOTE (Matt 2025-02-26): These are kept empty because error messaging for these errors
  // is handled by the code editor component.
  invalidCustomHtml: {},
  invalidCustomCss: {},
  pathAlreadyExists: {
    shopifyArticle: {
      title: "Blog post path already exists.",
      description:
        "The blog post you are trying to create uses a path that already exists for another blog post. Please choose a different path.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.pages,
      },
    },
    page: {
      title: "Page path already exists.",
      description:
        "The page you are trying to create already exists in Replo or elsewhere on your Shopify store. Please choose a different page path.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.pages,
      },
    },
    shopifySection: {
      title: "Section name already exists.",
      description:
        "The section you are trying to create already exists for this Shopify theme. Please choose a different name.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.shopifySections,
      },
    },
  },
  isInvalidPathName: {
    shopifyArticle: {
      title: "Article path is not valid.",
      description: "Blank spaces or special characters are not allowed.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.pages,
      },
    },
    page: {
      title: "Page path is not valid.",
      description: "Blank spaces or special characters are not allowed.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.pages,
      },
    },
  },
  pathIsEmpty: {
    shopifyArticle: {
      title: "Post path must be set.",
      description: "Please set the blog post URL path.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.pages,
      },
    },
    page: {
      title: "Page path must be set.",
      description: "Please set the page URL path.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.pages,
      },
    },
  },
  nameIsEmpty: {
    shopifyProductTemplate: {
      title: "Product template name must be set.",
      description: "Please set the product template name.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.productTemplates,
      },
    },
    shopifyArticle: {
      title: "Blog post name must be set.",
      description: "Please set the blog post name.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.pages,
      },
    },
    page: {
      title: "Page name must be set.",
      description: "Please set the page name.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.pages,
      },
    },
    shopifySection: {
      title: "Section name must be set.",
      description: "Please set the section name.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.shopifySections,
      },
    },
  },
  isInvalidSectionName: {
    shopifySection: {
      title: "Section name is invalid.",
      description:
        "Special characters are not allowed in Shopify section names.",
      link: {
        text: "Learn more",
        href: docs.editorContentTypes.shopifySections,
      },
    },
  },
  errorLoadingData: {
    shopifyArticle: {
      title: "Error loading blog posts.",
      description:
        "There was an error loading blog posts from your store. Please try again later.",
    },
  },
  loadingMissingData: {
    shopifyArticle: {
      title: "Busy loading blog posts",
      description:
        "We are loading blog posts from your store. This may take a few seconds.",
    },
  },
  storeHasNoBlogs: {
    shopifyArticle: {
      title: "No blogs found.",
      description:
        "You need to have at least one blog in your Shopify store to create a blog post.",
    },
  },
};

/**
 * This function validates an element.
 */
export function validateElement(
  element: ReploPartialElement,
  shopifyArticleData: {
    storeHasBlogs: boolean | undefined;
    isErrorFetchingData: boolean;
  },
) {
  const titleErrors: ElementErrorType[] = [];
  const pathErrors: ElementErrorType[] = [];

  // NOTE (Matt 2023-08-16): If the Shopify Section Name includes any unicode characters
  // it will throw an error (422) when hit publish. We have safeguards elsewhere in the code
  // to prevent this from happening, but this check here will allow us to inform the user
  // more clearly why the Section is not publishing.
  if (element.type === "shopifySection" && element.name.length > 0) {
    const invalidSymbolsInSectionName = /[\u0300-\u036F]/g.test(element.name);
    if (invalidSymbolsInSectionName) {
      titleErrors.push("isInvalidSectionName");
    }
  }

  if (element.name.length === 0) {
    titleErrors.push("nameIsEmpty");
  }

  if (shouldValidateShopifyPagePath(element.type)) {
    if (!element?.shopifyPagePath) {
      pathErrors.push("pathIsEmpty");
    }
    if (validatePageModalPath(element.shopifyPagePath ?? "")) {
      pathErrors.push("isInvalidPathName");
    }
    if (element.type === "shopifyArticle") {
      if (shopifyArticleData.storeHasBlogs === undefined) {
        pathErrors.push("loadingMissingData");
      } else if (!shopifyArticleData.storeHasBlogs) {
        pathErrors.push("storeHasNoBlogs");
      } else if (shopifyArticleData.isErrorFetchingData) {
        pathErrors.push("errorLoadingData");
      }
    }
  }

  return {
    isValid: titleErrors.length === 0 && pathErrors.length === 0,
    errors: { title: titleErrors, path: pathErrors },
  };
}

export const canRenameElement = (
  element: ReploElementMetadata | ReploPartialElement,
) => {
  if (element.type === "shopifySection" && element.publishedAt) {
    return false;
  }
  return true;
};
