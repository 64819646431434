// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import * as React from "react";

import { PartnershipAgreementForm } from "@components/dashboard/partner/forms/PartnershipAgreementForm";
import { useCurrentWorkspace } from "@editor/contexts/WorkspaceDashboardContext";

export const Hero = () => {
  const workspace = useCurrentWorkspace();
  return (
    <div className="max-w-custom mx-auto my-9 flex flex-col-reverse justify-center lg:flex-row">
      <div className="flex flex-col gap-y-6 px-5 lg:flex">
        <div className="flex gap-3">
          {tiers.map((tier) => {
            return (
              <Diamond
                primaryColor={tier.primaryColor}
                secondaryColor={tier.secondaryColor}
                key={tier.name}
              />
            );
          })}
        </div>
        <p className="text-4xl font-semibold md:text-5xl">
          Refer. Get Paid. Get Perks.
        </p>
        <p className="text-lg font-normal leading-7 text-black">
          Start referring and start earning with the new Replo Partner today.
          Earn up to 50% referral commission for up to 12 months when a new
          customer uses your link to sign up and purchase their first Replo
          subscription.
        </p>
        {!workspace?.isOnboarded && <PartnershipAgreementForm />}
      </div>
      <div className="flex w-full justify-center lg:w-3/5 lg:px-5">
        <iframe
          id="player"
          width="709"
          height="400"
          src="https://www.youtube.com/embed/NTUaUmqS6sk"
        ></iframe>
      </div>
    </div>
  );
};

export const Diamond: React.FC<{
  primaryColor: string;
  secondaryColor: string;
  size?: string;
}> = ({ primaryColor, secondaryColor, size = "35" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17095_29389)">
        <rect
          width="24.4199"
          height="24.4199"
          transform="translate(0 17.2676) rotate(-45)"
          fill={secondaryColor}
        />
        <path
          d="M17.2674 -0.00936313L34.5445 17.2677L17.3285 17.2677L17.2674 -0.00936313Z"
          fill={primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_17095_29389">
          <rect
            width="24.4199"
            height="24.4199"
            fill="white"
            transform="translate(0 17.2676) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const tiers = [
  {
    name: "Affiliate",
    primaryColor: "#bfdbfe",
    secondaryColor: "#2563eb",
    referralBonus: "20%",
  },
  {
    name: "Silver",
    primaryColor: "#e2e8f0",
    secondaryColor: "#94a3b8",
    referralBonus: "30%",
  },
  {
    name: "Gold",
    primaryColor: "#fde68a",
    secondaryColor: "#fbbf24",
    referralBonus: "40%",
  },
  {
    name: "Platinum",
    primaryColor: "#374151",
    secondaryColor: "#000000",
    referralBonus: "50%",
  },
];
