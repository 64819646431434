import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import { TEXT_STYLE_DEFAULTS } from "@common/constants/textStyles";
import PreviewTextShopStyle from "@editor/components/designLibrary/ShopStylePreviewText";
import useShopStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";

const ShopStylesFontSample: React.FC = () => {
  const { textShopStyles: textSavedStyles } = useShopStyles();

  const headingPriority = React.useMemo(
    () => ["h1", "h2", "h3", "h4", "h5", "h6"],
    [],
  );

  const highestHeadingStyle = React.useMemo(() => {
    const defaultStyle = TEXT_STYLE_DEFAULTS.h4;

    if (!textSavedStyles || textSavedStyles.length === 0) {
      return defaultStyle;
    }

    for (const tag of headingPriority) {
      const foundStyle = textSavedStyles.find(
        (style) =>
          style.type === "text" &&
          style.attributes?.type === "text" &&
          style.attributes?.htmlTag === tag,
      );

      if (foundStyle?.attributes) {
        return foundStyle.attributes as SavedStyleTextAttributes;
      }
    }

    return defaultStyle;
  }, [textSavedStyles, headingPriority]);

  return (
    <PreviewTextShopStyle
      attributes={highestHeadingStyle}
      text="Aa"
      className="text-[20px] leading-[24px]"
    />
  );
};

export default ShopStylesFontSample;
