import type { Component } from "schemas/component";
import type {
  Dependency,
  MetaFieldValuesMapping,
  ReploElement,
  ReploElementType,
} from "schemas/generated/element";
import type { ZodSchema, ZodTypeDef } from "zod";

import { isObjectLike } from "replo-utils/lib/type-check";
import {
  reploComponentSchema,
  reploComponentSchemaBase,
} from "schemas/component";
import { shopifyMetafieldNamespacedKeySchema } from "schemas/shopifyMetafield";
import { nullishToOptional } from "schemas/utils";
import { z } from "zod";

const reploElementVersionKindSchema = z.enum([
  "revert",
  "publish",
  "unpublish",
  "automatic",
  "automaticElementOutdated",
  "current",
]);

export const reploElementVersionFilter = z
  .enum(["all", "revert", "unpublish", "publish", "user"])
  .describe("ReploElementVersionFilter");

export type ReploElementVersionKind =
  | "current"
  | "revert"
  | "publish"
  | "unpublish"
  | "automatic"
  | "automaticElementOutdated";

export const ReploElementVersionKinds = {
  current: "current",
  revert: "revert",
  publish: "publish",
  unpublish: "unpublish",
  automatic: "automatic",
  automaticElementOutdated: "automaticElementOutdated",
} satisfies Record<ReploElementVersionKind, ReploElementVersionKind>;

export const metafieldTypes = [
  "boolean",
  "collection_reference",
  "color",
  "date",
  "date_time",
  "dimension",
  "file_reference",
  "json",
  "money",
  "multi_line_text_field",
  "number_decimal",
  "number_integer",
  "page_reference",
  "product_reference",
  "rating",
  "single_line_text_field",
  "url",
  "variant_reference",
  "volume",
  "weight",
  "rich_text_field",
] as const;

export const reploGenericMetafieldSchema = z
  .object({
    shopifyId: z.string().optional(),
    type: z.string(),
    namespace: z.string(),
    value: z.union([z.string(), z.number(), z.boolean()]),
    key: z.string(),
  })
  .describe("ReploGenericMetafield");

export const reploGenericExistingMetafieldSchema = reploGenericMetafieldSchema
  .required({ shopifyId: true })
  .describe("ReploExistingGenericMetafield");

export const dataTableSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    singularItemName: z.string().nullable(),
    pluralItemName: z.string().nullable(),
    data: z.object({
      schema: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          type: z.enum([
            "text",
            "color",
            "image",
            "imageArray",
            "product",
            "plainText",
          ]),
        }),
      ),
      rows: z.array(z.record(z.unknown())),
    }),
  })
  .describe("DataTable");

export const reploElementTypeSchema = z
  .enum(["page", "shopifyProductTemplate", "shopifyArticle", "shopifySection"])
  .describe("ReploElementType");

export const mainThemeLastPublishedContentSchema = z
  .object({
    outputHtml: z.string(),
    chunks: z.array(z.string()),
    runtimeVersion: nullishToOptional(z.string()),
    styles: nullishToOptional(z.string()),
  })
  .describe("MainThemeLastPublishedContent");

/**
 * NOTE (Gabe 2023-08-10): We accept null values because that's what Django
 * passes, but we convert them to undefined to be consistent with our
 * Replo Element type.
 */

export const reploElementSchema = z
  .object({
    id: z.string(),
    version: z.number(),
    projectId: z.string().uuid(),
    name: z.string(),
    type: reploElementTypeSchema,
    shopifyPageId: nullishToOptional(z.string()),
    hideDefaultHeader: z.boolean(),
    hideDefaultFooter: z.boolean(),
    hideShopifyAnnouncementBar: z
      .boolean()
      // TODO (Gabe 2023-10-09): For some reason the POST endpoint is being hit
      // with this value undefined. For now we'll allow this and default to false.
      .optional()
      .describe("override:boolean")
      .transform((val) => val ?? false),
    customHeadContent: nullishToOptional(z.string()),
    customCss: nullishToOptional(z.string()),
    isHomepage: z.boolean(),
    shopifyPagePath: z.string(),
    shopifyTemplateSuffix: nullishToOptional(z.string()),
    shopifyBlogId: nullishToOptional(z.string()),
    publishedAt: nullishToOptional(z.string()),
    templateShopifyProductIds: nullishToOptional(z.array(z.string())),
    productTemplateSlug: nullishToOptional(z.string()),
    component: reploComponentSchema as z.ZodType<Component>,
    createdAt: z.string(),
    updatedAt: z.string(),
    archivedAt: nullishToOptional(z.string()),
    isPublished: nullishToOptional(z.boolean()),
    lastPublishedComponent: nullishToOptional(
      reploComponentSchemaBase.partial() as z.ZodType<Partial<Component>>,
    ),
    htmlString: nullishToOptional(z.string()),
    // TODO (Gabe 2023-09-18): For some reason, using nullishToUndefined on the
    // object loses the type information from the nullishToUndefined properties
    // within, so we use the same pattern but not the helper.
    mainThemeLastPublishedContent: mainThemeLastPublishedContentSchema
      .nullish()
      .transform((val) => val ?? undefined)
      .describe("override?:MainThemeLastPublishedContent | undefined"),
    shopifyMetafields: z.array(reploGenericMetafieldSchema),
    shopifyArticleImage: z.object({
      src: nullishToOptional(z.string()),
      alt: nullishToOptional(z.string()),
    }),
    runtimeVersion: nullishToOptional(z.string()),
    useSectionSettings: nullishToOptional(z.boolean()),
    slug: nullishToOptional(z.string()),
    includeProductSchema: nullishToOptional(z.boolean()),
    folderId: z.string().nullable(),
  })
  .describe("ReploElement");

export const reploElementVersionRevision = z
  .object({
    id: z.string(),
    elementId: z.string().optional(),
    kind: reploElementVersionKindSchema,
    title: z.string(),
    createdAt: z.string(),
    revertedTo: z.string().optional(),
    createdBy: z
      .object({
        id: z.string(),
        email: z.string(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
        name: z.string().nullish(),
      })
      .optional(),
    // Note (Noah, 2024-05-09): Old revisions might have components which are technically invalid,
    // since we might have updated the schema and the component might not match the new schema. Not
    // sure this is always the case, but we should look into this
    component: z.unknown().optional(),
  })
  .describe("ReploElementVersionRevision");

export const reploElementForExperiments = reploElementSchema
  .pick({
    id: true,
    name: true,
    shopifyPagePath: true,
    projectId: true,
  })
  .extend({
    shopifyUrl: z.string(),
    hasWebPixelId: z.boolean(),
  })
  .describe("ReploElementForExperiments");

export const reploSimpleElementSchema = reploElementSchema
  .pick({
    id: true,
    version: true,
    name: true,
    type: true,
    shopifyPageId: true,
    projectId: true,
    hideDefaultHeader: true,
    hideDefaultFooter: true,
    isHomepage: true,
    publishedAt: true,
    shopifyPagePath: true,
    shopifyTemplateSuffix: true,
    shopifyBlogId: true,
    templateShopifyProductIds: true,
    productTemplateSlug: true,
    isPublished: true,
    createdAt: true,
    updatedAt: true,
    shopifyArticleImage: true,
    shopifyMetafields: true,
    folderId: true,
    archivedAt: true,
    customCss: true,
  })
  .describe("ReploSimpleElement");

export const reploDraftElementSchema = reploElementSchema.omit({
  htmlString: true,
  lastPublishedComponent: true,
  mainThemeLastPublishedContent: true,
});

export type ReploDraftElement = Omit<
  ReploElement,
  "htmlString" | "lastPublishedComponent" | "mainThemeLastPublishedContent"
>;

export const reploPartialElementSchema = reploElementSchema
  .pick({
    projectId: true,
    name: true,
    type: true,
    shopifyPageId: true,
    shopifyTemplateSuffix: true,
    shopifyBlogId: true,
    publishedAt: true,
    templateShopifyProductIds: true,
    isPublished: true,
  })
  .merge(
    reploElementSchema
      .pick({
        hideDefaultHeader: true,
        hideDefaultFooter: true,
        hideShopifyAnnouncementBar: true,
        isHomepage: true,
        shopifyPagePath: true,
        component: true,
        shopifyMetafields: true,
        shopifyArticleImage: true,
        useSectionSettings: true,
        slug: true,
        includeProductSchema: true,
        folderId: true,
        customCss: true,
      })
      .partial(),
  )
  .describe("ReploPartialElement");

export const productIdSchema = z
  .union([z.string(), z.number()])
  .nullable()
  .describe("ProductId");

export const metafieldsDependencySchema = z
  .object({
    type: z.literal("metafields"),
    entityType: z.enum(["product", "collection", "variant"]),
    productId: productIdSchema.optional(),
    variantId: z.string().optional(),
    productHandle: z.string(),
    metafieldKeys: z.array(
      reploGenericMetafieldSchema.pick({
        key: true,
        namespace: true,
        type: true,
      }),
    ),
  })
  .describe("MetafieldsDependency");

export const productsDependencySchema = z
  .object({
    type: z.literal("products"),
    productIds: z.array(productIdSchema),
  })
  .describe("ProductsDependency");

export const dataTablesDependencySchema = z
  .object({
    type: z.literal("dataTable"),
    dataTableId: z.string(),
  })
  .describe("DataTablesDependency");

export const dependencySchema = z
  .discriminatedUnion("type", [
    metafieldsDependencySchema,
    productsDependencySchema,
    dataTablesDependencySchema,
  ])
  .describe("Dependency");

const storeVariantSchema = z.object({
  id: z.number(),
  sku: z.string().nullable(),
  title: z.string(),
  option1: z.string(),
  option2: z.string().nullish(),
  option3: z.string().nullish(),
  available: z.boolean(),
  name: z.string(),
  price: z.string(),
  compare_at_price: z.string().nullable(),
  featured_image: z.object({ src: z.string() }).nullish(),
});

const storeSellingPlanOptionDefinitionSchema = z.object({
  name: z.string(),
  position: z.number(),
  /**
   * NOTE Ben 2024-02-19: (For USE-747) As far as I can tell from
   * https://shopify.dev/docs/api/admin-graphql/2024-01/objects/SellingPlanGroup, the options field
   * in graphql is supposed to be a list of non-null strings, but it clearly is not in some cases.
   * We're allowing null here because we don't assume it's a string in the runtime, and this is
   * the least invasive change at the moment.
   */
  values: z.array(z.string().nullable()),
});

const storeSellingPlanOptionSchema = z.object({
  name: z.string().optional(),
  position: z.number(),
  value: z.string(),
});

const storeSellingPlanPriceAdjustmentSchema = z.object({
  value: z.number(),
  value_type: z.enum(["percentage", "fixed_amount", "price"]),
});

const storeSellingPlanSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  options: z.array(storeSellingPlanOptionSchema),
  price_adjustments: z.array(storeSellingPlanPriceAdjustmentSchema),
});

const storeSellingPlanGroupSchema = z.object({
  id: z.string(),
  app_id: z.string().nullable(),
  options: z.array(storeSellingPlanOptionDefinitionSchema),
  selling_plans: z.array(storeSellingPlanSchema),
});

const metafieldTypeSchema = z.enum(metafieldTypes);
export const metafieldValuesMappingSchema = z
  .record(
    z.record(
      z.object({
        type: metafieldTypeSchema,
        value: z.union([z.string(), z.number()]),
      }),
    ),
  )
  .describe("MetaFieldValuesMapping");

export const productStatus = ["ACTIVE", "DRAFT", "ARCHIVED"] as const;

export const storeProductSchema = z
  .object({
    title: z.string(),
    images: z.array(z.string()),
    id: z.union([z.string(), z.number()]),
    type: z.string(),
    variants: z.array(storeVariantSchema),
    options: z.array(z.string()),
    featured_image: z.string().nullable(),
    description: z.string(),
    handle: z.string(),
    status: z.enum(productStatus),
    // Note (Noah, 2022-08-19, REPL-3674): Apparently, selling plan groups can be undefined
    // in liquid objects
    selling_plan_groups: z.array(storeSellingPlanGroupSchema).optional(),
  })
  .describe("StoreProduct");

export const reploElementProductSchema = z
  .unknown()
  .describe("ReploElementProduct");

export const reploProductMetafieldValue = z
  .unknown()
  .describe("ReploProductMetafieldValue");

export const previewElementPropsSchema = z
  .object({
    projectId: z.string().uuid(),
    elementId: z.string().uuid(),
    previewElementVersion: z.number(),
    dependencies: z.record(z.array(dependencySchema)),
    elementProducts: z.array(z.unknown()),
    variantMetafieldValues: z.record(z.unknown()),
    productMetafieldValues: z.record(z.unknown()),
    okendoNamespace: z.enum(["okendoReviews", "okeWidgetApi"]).nullish(),
    storeUrl: z.string(),
    dataTables: z.array(dataTableSchema),
    currencyCode: z.string(),
    activeLanguage: z.string(),
    activeShopifyUrlRoot: z.string(),
    moneyFormat: z.string().nullable(),
    dataEmDisableOnImages: z.boolean().default(false),
  })
  .describe("PreviewElementProps");

export const publishElementBodySchema = z
  .object({
    elementId: z.string().uuid(),
    theme_id: z.string().nullable(),
    products: z.array(storeProductSchema),
    dataEmDisableOnImages: z.boolean().default(false),
    dependencies: z.record(z.array(dependencySchema)),
    okendoNamespace: z.enum(["okendoReviews", "okeWidgetApi"]).nullish(),
    useShopifyDebugTools: z.boolean().default(false).nullish(),
    allReferencedMetafields: z
      .object({
        product: z.array(shopifyMetafieldNamespacedKeySchema),
        variant: z.array(shopifyMetafieldNamespacedKeySchema),
      })
      .optional(),
    productMetafieldValues: z.record(metafieldValuesMappingSchema),
    variantMetafieldValues: z.record(metafieldValuesMappingSchema),
  })
  .describe("PublishElementBody");

export const publishElementReturnSchema = reploElementSchema.omit({
  customHeadContent: true,
  lastPublishedComponent: true,
  mainThemeLastPublishedContent: true,
  runtimeVersion: true,
  slug: true,
});

export const componentTemplatePreviewPropsSchema = z
  .object({
    projectId: z.string().uuid(),
    dependencies: z.record(z.array(dependencySchema)),
    elementProducts: z.array(z.unknown()),
    variantMetafieldValues: z.record(z.unknown()),
    productMetafieldValues: z.record(z.unknown()),
    storeUrl: z.string(),
    currencyCode: z.string(),
    activeLanguage: z.string(),
    activeShopifyUrlRoot: z.string(),
    moneyFormat: z.string().nullable(),
  })
  .describe("ComponentTemplatePreviewProps");

export type ShopifySectionSchema = {
  blocks?: {
    type: "@app";
  }[];
  settings: (
    | {
        id: string;
        type: "richtext" | "image_picker" | "url";
        label: string;
        value?: string;
        default?: string;
        info?: string;
      }
    | {
        type: "header";
        content?: string;
        info?: string;
      }
  )[];
};

export type ComponentTemplatePreviewProps = {
  projectId: string;
  dependencies: {
    [x: string]: Dependency[];
  };
  elementProducts: unknown[];
  variantMetafieldValues: {
    [x: string]: MetaFieldValuesMapping;
  };
  productMetafieldValues: {
    [x: string]: MetaFieldValuesMapping;
  };
  storeUrl: string;
  currencyCode: string;
  activeLanguage: string;
  activeShopifyUrlRoot: string;
  moneyFormat: string | null;
};

export function isReploElementType(value: unknown): value is ReploElementType {
  return reploElementTypeSchema.options.includes(value as ReploElementType);
}

export function isPageOrShopifyArticleType(
  value: unknown,
): value is "page" | "shopifyArticle" {
  const pageOrBlogArticleType = reploElementTypeSchema.extract([
    "page",
    "shopifyArticle",
  ]);
  return pageOrBlogArticleType.options.includes(
    value as "page" | "shopifyArticle",
  );
}

export const updateElementSource = z
  .enum(["pageSettings", "component"])
  .describe("UpdateElementSource");

// TODO (Fran 2024-09-10): Remove this in a couple of days after merge PR #10019.
// This function will avoid an error in the schema check when user that are not refresh the page
// use the old body with storeId on Replo Element's requests.
export const preprocessReploElementStoreId = <
  Output,
  Def extends ZodTypeDef,
  Input,
>(
  schema: ZodSchema<Output, Def, Input>,
) => {
  return z.preprocess((value) => {
    if (isObjectLike(value) && "storeId" in value) {
      const projectId = value.storeId;
      delete value.storeId;
      return {
        ...value,
        projectId,
      };
    }

    return value;
  }, schema);
};

export const reploElementComponentSchema = reploElementSchema
  .pick({
    id: true,
    component: true,
    version: true,
  })
  .describe("ReploElementComponent");

export const reploElementMetadataSchema = reploElementSchema
  .pick({
    id: true,
    name: true,
    type: true,
    publishedAt: true,
    isHomepage: true,
    createdAt: true,
    updatedAt: true,
    isPublished: true,
    projectId: true,
    folderId: true,
    customCss: true,
    shopifyPagePath: true,
    shopifyMetafields: true,
    shopifyBlogId: true,
    hideShopifyAnnouncementBar: true,
    includeProductSchema: true,
    hideDefaultHeader: true,
    hideDefaultFooter: true,
    templateShopifyProductIds: true,
    useSectionSettings: true,
    version: true,
  })
  .describe("ReploElementMetadata");
