import * as React from "react";

import DashboardLayout from "@components/layouts/DashboardLayout";
import { useCurrentWorkspaceContext } from "@editor/contexts/WorkspaceDashboardContext";
import useConnectShopifyIntegrationNavigator from "@editor/hooks/useConnectShopifyIntegrationNavigator";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { Outlet, useNavigate } from "react-router-dom";

import WorkspaceProvider from "../WorkspaceProvider";
import { useDefaultWorkspaceId } from "./hooks/useDefaultWorkspaceId";
import ProjectsTable from "./projects/ProjectsTable";

const DashboardHomeProjectsTable: React.FC<{
  onNewProjectClick: () => void;
}> = ({ onNewProjectClick }) => {
  const {
    workspace,
    workspaceId,
    isLoading: isLoadingWorkspace,
  } = useCurrentWorkspaceContext();

  const { data, isLoading } = trpc.project.listWithStats.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );

  return (
    <ProjectsTable
      title={!isLoading ? `${workspace?.name} Projects` : null}
      subtitle={`These are the projects within ${workspace?.name}`}
      projects={data?.projects}
      isLoading={isLoading || isLoadingWorkspace}
      buttonLabel="New Replo Project"
      onButtonClick={onNewProjectClick}
    />
  );
};

const DashboardHome: React.FC<React.PropsWithChildren> = ({ children }) => {
  useConnectShopifyIntegrationNavigator();
  const navigate = useNavigate();
  const workspaceId = useDefaultWorkspaceId();

  return (
    <WorkspaceProvider workspaceId={workspaceId ?? undefined}>
      <DashboardLayout>
        <div className="bg-white no-scrollbar col-span-9 mt-4 flex flex-grow flex-col items-start gap-y-2 overflow-scroll">
          <DashboardHomeProjectsTable
            onNewProjectClick={() => navigate("../project/new")}
          />
        </div>
        {children}
        <Outlet />
      </DashboardLayout>
    </WorkspaceProvider>
  );
};

export default DashboardHome;
