// NOTE (Matt 2024-11-17): In a pre-no-mirror world, the componentNode we're getting the boundingClientRect for
// exists inside of an iframe, so the value of getBindingClientRect is relative to the specific canvas it is
// rendered in. now that the iframe has been removed, we need to relalculate the boundingClientRect relative
// to the frame it resides in. We need the canvasScale because the frame has been transformed by the canvasScale
export default function getCanvasRelativeBoundingClientRect(
  componentNode: HTMLElement | Element,
  canvasScale?: number,
) {
  const componentRects = componentNode.getBoundingClientRect();
  const frame = componentNode.closest("[data-canvas-id]");
  if (!frame) {
    return componentRects;
  }
  const frameRects = frame.getBoundingClientRect();
  const { width, height } = componentRects;
  // NOTE (Matt 2024-11-17): In addition to adjusting relative to the frame of the page, we also have to
  // consider that the entire element exists within our transformed, scaled container wrapped around the canvas.
  // this means that the rendered size & position of the component is different than the calculated value
  // of getBoundingClientRect. For context, you can see the transformed scale that we're counteracting here
  // in useUpdateFramePositionStyles.
  const scale = 1 / (canvasScale ?? window.alchemyEditor?.canvasScale ?? 1);
  const adjustedHeight = height * scale;
  const adjustedTop = (componentRects.top - frameRects.top) * scale;
  let adjustedWidth = width * scale;
  let adjustedLeft = (componentRects.left - frameRects.left) * scale;
  // NOTE (Matt 2025-01-06): This next bit ensures the bouning box of a component stays within
  // the targetFrame. This is necessary because now that the frame is a div rather than an iframe,
  // it is possible for a component's boundingClientRect to be larger than the containing frame.
  if (width > frameRects.width) {
    adjustedWidth = frameRects.width * scale;
    adjustedLeft = 0;
  }
  return {
    width: adjustedWidth,
    height: adjustedHeight,
    left: adjustedLeft,
    top: adjustedTop,
    bottom: adjustedTop + adjustedHeight,
    right: adjustedLeft + adjustedWidth,
  };
}
