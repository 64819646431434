import React from "react";

import { Indicator, Item, Root } from "@radix-ui/react-radio-group";
import twMerge from "@replo/design-system/utils/twMerge";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import Description from "../label/Description";
import Label from "../label/Label";

type RadioButtonGroupProps = {
  size: "base" | "sm";
  disabled: boolean;
  options: RadioButtonProps[];
  onChange?: (e: string | undefined) => void;
  selectedValue?: string;
  defaultValueIndex?: number;
  layoutClassName?: string;
  variant?: "default" | "card";
  UNSAFE_className?: string;
};

type RadioButtonProps = {
  disabled?: boolean;
  value: string;
  label: string;
  description?: string;
  onChange?: (e: string | undefined) => void;
  selectedValue?: string;
};

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  size,
  disabled = false,
  options = [],
  onChange,
  selectedValue,
  defaultValueIndex,
  layoutClassName,
  UNSAFE_className,
  variant = "default",
}) => {
  const defaultValue = defaultValueIndex
    ? options[defaultValueIndex]?.value
    : options[0]?.value;
  const [selectedRadioValue, setSelectedRadioValue] = useControllableState(
    selectedValue,
    defaultValue,
    onChange,
  );

  return (
    <Root
      className={twMerge(
        "flex flex-col gap-3",
        layoutClassName,
        UNSAFE_className,
      )}
      value={selectedRadioValue}
      onValueChange={setSelectedRadioValue}
      disabled={disabled}
    >
      {options.map((radioButtonOption) => {
        const optionDisabled = radioButtonOption.disabled ?? disabled;
        return (
          <div
            key={radioButtonOption.value}
            className={twMerge(
              "flex font-normal items-start gap-2 cursor-pointer",
              variant === "card" &&
                "rounded-lg border border-borderhover:bg-hover p-3",
              variant === "card" &&
                selectedRadioValue === radioButtonOption.value &&
                "border-primary bg-selectable-selected text-primary hover:bg-selectable-selected-hover",
              optionDisabled && "opacity-50 cursor-not-allowed",
            )}
            onClick={() => {
              if (!optionDisabled) {
                setSelectedRadioValue(radioButtonOption.value);
              }
            }}
          >
            <Item
              className={twMerge(
                "bg-white w-4 h-4 rounded-full border border-border shrink-0 relative",
                size === "sm" && "mt-[1px]",
                size === "base" && "mt-[3px]",
                optionDisabled && "cursor-not-allowed",
              )}
              value={radioButtonOption.value}
            >
              <Indicator className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 after:content-[''] after:block after:w-[9.14px] after:h-[9.14px] after:rounded-full after:bg-primary" />
            </Item>
            {(radioButtonOption.label || radioButtonOption.description) && (
              <div className="flex-1 min-w-0 gap-1">
                <Label
                  UNSAFE_className={twMerge(
                    optionDisabled && "cursor-not-allowed",
                  )}
                  label={radioButtonOption.label}
                  size={size}
                  breakWords
                  pointer
                />
                <Description
                  description={radioButtonOption.description}
                  size={size}
                  type="muted"
                  breakWords
                />
              </div>
            )}
          </div>
        );
      })}
    </Root>
  );
};

export { RadioButtonGroup };
