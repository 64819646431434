import * as React from "react";

export interface ClipperRefs {
  before: HTMLDivElement | null;
  after: HTMLDivElement | null;
}

interface InternalContextValue {
  orientation: Orientation;
  isDisabled: boolean;
  position: number;
  transition: string | undefined;
  handleRef: React.RefObject<HTMLButtonElement>;
  clipperRefs: React.MutableRefObject<ClipperRefs>;
  setHandleElement: React.RefCallback<HTMLButtonElement>;
  isDragging: boolean;
  positionWithPadding: number;
}

/** @internal */
export const CompareSliderClipperContext = (() => {
  const CompareSliderClipperContext = React.createContext<null | {
    position: "before" | "after";
  }>(null);
  CompareSliderClipperContext.displayName = "CompareSliderClipperContext";
  return CompareSliderClipperContext;
})();

/** @internal */
export function useCompareSliderClipperContext() {
  const ctx = React.useContext(CompareSliderClipperContext);
  if (!ctx) {
    throw new Error(
      "Cannot use `CompareSliderClipperContext` outside of a `CompareSliderClipper` component.",
    );
  }
  return ctx;
}

/** @internal */
export const CompareSliderContext = (() => {
  const CompareSliderContext = React.createContext<null | InternalContextValue>(
    null,
  );
  CompareSliderContext.displayName = "CompareSliderContext";
  return CompareSliderContext;
})();

/** @internal */
export function useInternalContext() {
  const ctx = React.useContext(CompareSliderContext);
  if (!ctx) {
    throw new Error(
      "Cannot use `CompareSliderContext` outside of a `CompareSlider` component.",
    );
  }
  return ctx;
}

export interface CompareSliderContextValue {
  orientation: Orientation;
  isDisabled: boolean;
  position: number;
  handleRef: React.RefObject<HTMLButtonElement>;
  clipperRefs: React.MutableRefObject<ClipperRefs>;
  isDragging: boolean;
}

export type Orientation = "horizontal" | "vertical";
