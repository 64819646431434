import type { EditorPropsRecord } from "replo-runtime/shared/utils/editorProps";
import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "..";
import type { AlchemyActionType } from "../../../shared/enums";

import { convertToLegacyProps } from "../../../shared/utils/props";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _items: {
      type: "dynamicItems",
      name: "Dynamic Items",
      description: "If set, the list can use each item for display.",
      defaultValue: null,
    },
    _accessibilitySelection: {
      type: "boolean",
      name: "Accessibility Selection",
      description:
        "If set, the component will behave like a radio group, including all necessary accessibility controls.",
      defaultValue: true,
    },
  };
}

function getEditorProps(): EditorPropsRecord {
  return {};
}

const styleElements = {
  root: {
    defaultStyles: ({ component }) => ({
      cursor: component.props._accessibilitySelection ? "pointer" : "default",
    }),
  },
} as const satisfies StyleElements;

const actionTypes = [
  "setSelectedListItem",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    editorProps: getEditorProps(),
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
    allowChildrenReorder: true,
    variantTriggers: ["state.selectionList.isItemSelected"],
    actionTypes,
  },
} satisfies ComponentConfig;

export default config;
