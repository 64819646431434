import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { sanitizePageUrlPath } from "@editor/utils/analytics";

import useBasicAnalyticsRead from "@/features/analytics/useBasicAnalyticsRead";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import { BsWindow } from "react-icons/bs";
import { ConditionOperatorEnum } from "schemas/generated/analyticsRead";

import { DEFAULT_FILTERS } from "../constants";
import { useAnalyticsQueryContext } from "../contexts/AnalyticsQueryContext";

const AnalyticsHostPagesCombobox: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { query, dispatchAnalyticsQuery } = useAnalyticsQueryContext();

  // NOTE (Max, 2024-12-15): pageUrlPath is just a filter, so we find it
  // in the context's filters. The only time we use ConditionOperatorEnum.EQUALS
  // is when on the deep dive page, so that's the one we're looking for.
  const pageUrlPath = React.useMemo(
    () =>
      query.filters.urlPath.find(
        (filter) => filter.operator === ConditionOperatorEnum.EQUALS,
      )?.value[0] ?? "",
    [query.filters.urlPath],
  );

  const handlePageUrlPathChange = (value: string) => {
    dispatchAnalyticsQuery({
      type: "filters.replaceUrlPath",
      payload: value,
    });
  };

  const [searchTerm, setSearchTerm] = React.useState("");

  const { mainRangeResults, isLoading } = useBasicAnalyticsRead({
    workspaceId: workspaceId ?? null,
    type: "withUrlSyncQuery",
    queryConfig: {
      useChartInterval: false,
      queryOverrides: {
        // NOTE (Max, 2024-12-15): Requirement from product team to only show top 10 results
        // by unique_sessions in this combobox
        limit: 10,
        sortMetric: "unique_sessions" as AnalyticsReadQuery["sortMetric"],
        order: "DESC" as AnalyticsReadQuery["order"],
        /**
         * NOTE (Max, 2024-12-15): As this Combobox is shown in the Deep Dive page, the urlPath
         * filters will lead to results for only that 1 urlPath. Given that we want to show
         * all the urlPaths in the Combobox options, we're overriding the context's filters so that
         * only urlPaths containing the searchTerm are returned.
         */
        filters: {
          ...query.filters,
          urlPath: [
            ...DEFAULT_FILTERS.urlPath,
            { operator: ConditionOperatorEnum.CONTAINS, value: searchTerm },
          ],
        },
      },
    },
  });

  const { value: inputValue, onChange: onInputChange } = useOverridableInput({
    value: searchTerm,
    onValueChange: (value: string) => {
      setSearchTerm(value);
    },
  });

  const pageOptions = React.useMemo(() => {
    return mainRangeResults.map((result) => ({
      value: result.urlPath,
      label: sanitizePageUrlPath(result.urlPath),
    }));
  }, [mainRangeResults]);

  return (
    <Combobox
      options={pageOptions}
      value={sanitizePageUrlPath(pageUrlPath)}
      onChange={handlePageUrlPathChange}
      input={inputValue}
      onInputChange={(value: string) => {
        onInputChange({
          target: { value },
        } as React.ChangeEvent<HTMLInputElement>);
      }}
      areOptionsSearchable
      loader={
        isLoading ? (
          <Spinner size={28} variant="primary" layoutClassName="mx-auto" />
        ) : null
      }
      trigger={
        <Combobox.SelectionButton
          startEnhancer={<BsWindow className="h-4 w-4" />}
          title={sanitizePageUrlPath(pageUrlPath) ?? "Select a page..."}
          isPlaceholder={!pageUrlPath}
        />
      }
    />
  );
};

export default AnalyticsHostPagesCombobox;
