import type { Component } from "schemas/component";

import isString from "lodash-es/isString";
import { actionTypeToRenderData } from "replo-runtime/store/utils/action";

/**
 * Return whether this component has a redirect action in its onClick prop
 * (an action which may be rendered as a link). Useful for detecting whether
 * we can render buttons as links, since we want to avoid nested <a> tags due
 * to browsers rendering them weirdly.
 */
export const componentHasRedirectAction = (component: Component) => {
  return (component.props.onClick ?? []).some(
    (action) => actionTypeToRenderData[action.type]?.isRedirectToUrl,
  );
};

/**
 * Return the component's redirect action (if it has one), otherwise undefined.
 */
export const getComponentRedirectAction = (component: Component) => {
  return (component.props.onClick ?? []).find(
    (action) => actionTypeToRenderData[action.type]?.isRedirectToUrl,
  );
};

export function hasAnchorTagInTextProp(component: Component) {
  return (
    // Note (Chance 2023-08-18, USE-350) While `component.props.text` should
    // always be a string if it's defined, there seem to be cases where a weakly
    // typed value makes its way through here, resulting in an uncaught error
    // which will show a render error to the user. We want to guard against that
    // with a runtime type check.
    isString(component.props.text) && component.props.text.includes("</a>")
  );
}
