import * as React from "react";

import ShopifyIntegrationCard from "@editor/components/dashboard/integrations/ShopifyIntegrationCard";
import { ConnectShopifyIntegrationCta } from "@editor/components/editor/page/ConnectShopifyCallout";
import { useCurrentWorkspaceId } from "@editor/contexts/WorkspaceDashboardContext";
import { trpc } from "@editor/utils/trpc";

import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BsArrowLeft, BsArrowUpRight } from "react-icons/bs";
import { generatePath, useNavigate } from "react-router-dom";

const ShopifyIntegrationSettings = () => {
  const workspaceId = useCurrentWorkspaceId();
  const navigate = useNavigate();
  const { data: integrationsData } =
    trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );

  if (workspaceId && integrationsData) {
    const { integrations } = integrationsData;
    return (
      <div className="flex flex-col px-6 w-full max-w-screen-xl">
        <button
          className="flex items-center gap-1 font-medium text-sm text-blue-600 mb-3"
          onClick={() =>
            navigate(
              generatePath("/workspace/:workspaceId/integrations", {
                workspaceId,
              }),
            )
          }
        >
          <BsArrowLeft size={18} />
          Back to Integrations
        </button>
        <div className="flex items-center mb-3">
          <img
            src="/images/integration-logos/shopify.png"
            className="w-9 h-9 mr-2"
          />
          <h2 className="text-3xl font-bold mr-auto">Shopify</h2>
          <ConnectShopifyIntegrationCta
            cookieValue={{ type: "integrationHub", workspaceId }}
          />
        </div>
        <a
          className="flex items-center gap-1 font-medium text-sm text-blue-600 py-2 mr-auto"
          href="https://support.replo.app/articles/1713040192-integrating-replo-with-your-shopify-store"
        >
          Learn More
          <BsArrowUpRight size={18} />
        </a>
        <div className="my-8 w-[600px]">
          <h3 className="text-base font-semibold text-slate-600 mb-4">
            Added Integrations
          </h3>
          {integrations.length > 0 ? (
            <div>
              {integrations.map((integration, index) => (
                <ShopifyIntegrationCard
                  key={integration.id}
                  number={index + 1}
                  integration={integration}
                />
              ))}
            </div>
          ) : (
            <p className="bg-slate-50 p-4 rounded-lg inline-block text-base medium">
              To add a Shopify integration, select the button in the upper right
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default ShopifyIntegrationSettings;
