import * as React from "react";

import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useModal } from "@editor/hooks/useModal";

import "@/features/canvas/canvas-reducer";

import { useGlobalEditorActions } from "@editor/hooks/useGlobalEditorActions";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  selectEditorMode,
  selectInternalDebugModeOn,
  setDebugPanelVisibility,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

const GlobalHotkeysListener: React.FC = () => {
  const dispatch = useEditorDispatch();
  const store = useEditorStore();
  const setDraftElement = useSetDraftElement();
  const modal = useModal();
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);
  const globalEditorActions = useGlobalEditorActions();

  const { paste, copy } = globalEditorActions;
  React.useEffect(() => {
    window.addEventListener("paste", paste);
    window.addEventListener("copy", copy);
    return () => {
      window.removeEventListener("paste", paste);
      window.removeEventListener("copy", copy);
    };
  }, [paste, copy]);

  // NOTE (Sebas, 2024-05-15): For some reason when you click on a modal for the
  // first time and you press the hotkey, the hotkey is not triggered. This is a
  // workaround to make sure the modal is deleted when the hotkey is pressed.
  // TODO (Chance 2024-06-12): Debug this issue. We may need to resolve it
  // without depending on the iframe. With multi-canvas enabled a modal may be
  // visible in each frame. Currently we use the desktop frame so behavior
  // should be consistent without the multi-canvas feature.
  const modalMountingPoint = window.document.querySelector(
    "#alchemy-modal-mount-point",
  );
  useReploHotkeys({ delete: globalEditorActions.delete }, modalMountingPoint);

  // Note (Evan, 2024-07-16): We should only allow undo/redo when no global
  // modals are open.
  const shouldAllowUndoRedo = Object.values(modal.modals).length === 0;

  const handleDebug = React.useCallback(() => {
    dispatch(setDebugPanelVisibility(!isDebugPanelVisible));
  }, [dispatch, isDebugPanelVisible]);

  const { togglePreviewMode } = globalEditorActions;
  const handleDeselectCurrentComponent = React.useCallback(() => {
    const areModalsOpen = selectAreModalsOpen(store.getState());
    const editorMode = selectEditorMode(store.getState());
    // Note (Evan, 2024-07-19): Don't do anything in AI generation mode
    if (editorMode === EditorMode.aiGeneration) {
      return;
    }
    // if it's preview, get out of preview instead
    if (editorMode === EditorMode.preview) {
      togglePreviewMode();
    } else {
      // NOTE (Sebas, 2024-06-17): If there are modals open, we should not
      // deselect the current component, as it will close the modals and
      // deselect the component at the same time looking weird.
      if (!areModalsOpen) {
        setDraftElement({ componentIds: [] });
      }
    }
  }, [setDraftElement, store, togglePreviewMode]);

  useReploHotkeys({
    delete: globalEditorActions.delete,
    grabCanvas: [
      globalEditorActions.grabCanvas,
      {
        filter: (event: KeyboardEvent) => !event.repeat,
        keyup: true,
      },
    ],
    undo: [
      globalEditorActions.undo,
      {
        filter: () => shouldAllowUndoRedo,
      },
    ],
    redo: [
      globalEditorActions.redo,
      {
        filter: () => shouldAllowUndoRedo,
      },
    ],
    duplicate: globalEditorActions.duplicate,
    debug: handleDebug,
    groupIntoContainer: globalEditorActions.groupIntoContainer,
    // Note (Fran 2022-09-07): To add a new hotkey we need to create a second
    // one with the same function.
    groupIntoContainerTwo: globalEditorActions.groupIntoContainer,
    zoomIn: globalEditorActions.zoomIn,
    saveComponentTemplate: globalEditorActions.saveComponentTemplate,
    openHotkeysModal: () =>
      modal.openModal({
        type: "hotkeysModal",
      }),
    mockSave: globalEditorActions.mockSave,
    zoomOut: globalEditorActions.zoomOut,
    resetZoom: globalEditorActions.resetZoom,
    deselectCurrentComponent: handleDeselectCurrentComponent,
    preventSelectingAllTextsOnEditor: (e) => {
      e.stopPropagation();
    },
    togglePreviewMode: globalEditorActions.togglePreviewMode,
    copyStyles: globalEditorActions.copyStyles,
    pasteStyles: globalEditorActions.pasteStyles,
    toggleAIMenu: globalEditorActions.toggleAIMenu,
    setDesignPanel: globalEditorActions.setDesignPanel,
    setConfigPanel: globalEditorActions.setConfigPanel,
    setInteractionsPanel: globalEditorActions.setInteractionsPanel,
    setLayersPanel: globalEditorActions.setLayersPanel,
    setElementsPanel: globalEditorActions.setElementsPanel,
    setComponentsPanel: globalEditorActions.setComponentsPanel,
    setSavedStylesPanel: globalEditorActions.setSavedStylesPanel,
    toggleCodeEditor: globalEditorActions.toggleCodeEditor,
    toggleVersionHistory: globalEditorActions.toggleVersionHistory,
    openProjectSettings: globalEditorActions.openProjectSettings,
    openPageSettings: globalEditorActions.openPageSettings,
    setAssetsPanel: globalEditorActions.setAssetsPanel,
    moveUpInTheTree: globalEditorActions.moveUpInTheTree,
    moveDownInTheTree: globalEditorActions.moveDownInTheTree,
    exportToSection: globalEditorActions.exportToSection,
    setWidthToFillAvailable: globalEditorActions.setWidthToFillAvailable,
    setWidthToWrapContent: globalEditorActions.setWidthToWrapContent,
    setHeightToFillAvailable: globalEditorActions.setHeightToFillAvailable,
    setHeightToWrapContent: globalEditorActions.setHeightToWrapContent,
    alignLeft: globalEditorActions.alignLeft,
    alignRight: globalEditorActions.alignRight,
    alignTop: globalEditorActions.alignTop,
    alignBottom: globalEditorActions.alignBottom,
    alignHorizontalCenter: globalEditorActions.alignHorizontalCenter,
    alignVerticalCenter: globalEditorActions.alignVerticalCenter,
  });

  return null;
};

export default GlobalHotkeysListener;
