import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useGetWidgetYotpo } from "../../hooks/useGetWidgetYotpo";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";
import { SharedYotpoReviews } from "../SharedYotpoReviews";

const YotpoProductRating: React.FC<RenderComponentProps> = (props) => {
  const { component, context, componentAttributes } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  const yotpo = useGetWidgetYotpo(
    "ReviewsStarRatingsWidget",
    component.id,
    globalWindow,
  );
  const product = useProductFromProps(component.props, context);

  if (isEditorApp) {
    return (
      <div {...componentAttributes}>
        <RenderComponentPlaceholder
          title="Yotpo Product Rating"
          type="reviewStars"
        />
      </div>
    );
  }

  if (!product) {
    return <div {...componentAttributes}></div>;
  }

  return (
    <SharedYotpoReviews
      attributes={componentAttributes}
      component={component}
      context={context}
      htmlContent={
        yotpo?.widget
          ? `<div
          class="yotpo-widget-instance"
          data-yotpo-instance-id="${yotpo.widget.instanceId}"
          data-yotpo-product-id="${product.id}"></div>`
          : `<div class="yotpo bottomLine" data-product-id="${product.id}"></div>`
      }
    />
  );
};

export default YotpoProductRating;
