import type { Context } from "replo-runtime/store/ReploVariable";
import type { Component } from "schemas/component";
import type { ItemsConfig } from "schemas/dynamicData";

import * as React from "react";

import isObject from "lodash-es/isObject";
import { filterNulls } from "replo-utils/lib/array";
import { isEmpty } from "replo-utils/lib/misc";
import { ItemsConfigType } from "schemas/dynamicData";

import { isCompletelyHiddenComponent } from "../../store/utils/isCompletelyHiddenComponent";
import { getItemObjectsForRender } from "../../store/utils/items";
import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../runtime-context";
import { mergeContext } from "./context";

/**
 * Array of Replo components which should be displayed as children of another possibly-dynamic
 * Replo component. This is used in dynamic components like Carousel, where either the
 * children are static from the component's `children` prop, or they repeat one or more
 * of the children N times, for each dyamic data item (e.g. for each image in a product
 * collection).
 */
export type RenderChildren = {
  /**
   * The component that should be rendered as a the child
   */
  component: Component;

  /**
   * Context which should be merged into the child's context (e.g. so the current item
   * can be passed to the child image in a carousel)
   */
  context: Context;

  /**
   * The dynamic item which is associated with this child. If the children are static,
   * this will be null. Useful for matching up with the "selected item" of a carousel
   * to figure out whether to auto-scroll to it when the selected item changes.
   */
  item: unknown | null;
}[];

type UseRenderChildrenOptions = {
  context: Context;
  itemsConfig?: ItemsConfig | null;
  currentItemId?: string;
  alwaysPreferChildComponent?: boolean;
};

export function useRenderChildren(
  component: Component | null,
  options: UseRenderChildrenOptions,
): RenderChildren {
  const { context, itemsConfig } = options;
  const emptyChildren = React.useMemo(() => [], []);
  const staticChildren = React.useMemo(() => {
    return getStaticChildren(component, context);
  }, [component, context]);

  const dynamicChildren = useDynamicChildren(component, {
    ...options,
    staticChildren,
  });

  if (!component) {
    return emptyChildren;
  }

  // Note (Fran, 2022-08-10): If the dynamic data doesn't have elements, we
  // need to fallback to the static children, otherwise the component will
  // render incorrectly.
  // https://linear.app/replo/issue/REPL-3467/setting-carousel-to-product-images-makes-it-auto-width
  const noDynamicChildren = dynamicChildren.length === 0;

  if (!itemsConfig || noDynamicChildren) {
    return staticChildren;
  }

  // TODO (Noah, 2022-07-22, REPL-3075): If the type of the items is inline but there
  // are no items, treat it as if there were no items at all. This happens when the user
  // adds items, then removes all of them. This can be removed once we don't support
  // adding "specific items" anymore.
  if (
    itemsConfig.type === ItemsConfigType.inline &&
    itemsConfig.valueType === "string" &&
    itemsConfig.values?.length === 0
  ) {
    return staticChildren;
  }

  return dynamicChildren;
}

function useDynamicChildren(
  component: Component | null,
  options: UseRenderChildrenOptions & { staticChildren: RenderChildren },
): RenderChildren {
  const {
    context,
    itemsConfig,
    currentItemId,
    alwaysPreferChildComponent,
    staticChildren,
  } = options;
  const {
    fakeProducts,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const isShopifyProductsLoading =
    useRuntimeContext(RuntimeHooksContext).useIsShopifyProductsLoading();
  const dataTableMapping =
    useRuntimeContext(RuntimeHooksContext).useDataTableMapping();
  const emptyChildren = React.useMemo(() => [], []);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);

  const items = React.useMemo(() => {
    const firstChildComponent = component?.children?.[0];

    if (!firstChildComponent || !itemsConfig) {
      return emptyChildren;
    }

    const itemsObjects = getItemObjectsForRender(
      itemsConfig,
      dataTableMapping,
      context,
      {
        products,
        currencyCode,
        moneyFormat,
        fakeProducts,
        language,
        templateProduct,
        isEditor: isEditorApp,
        isShopifyProductsLoading,
      },
    );

    // If its a data table and there are no items, fallback to static children
    if (
      itemsConfig.type === ItemsConfigType.dataTable &&
      isEmpty(itemsObjects)
    ) {
      return staticChildren;
    }

    // NOTE (Fran 2024-10-29): If the itemsObjects is not an array, we return an empty array.
    if (!itemsObjects || !Array.isArray(itemsObjects)) {
      return [];
    }

    return itemsObjects.map((item, index) => ({
      component: alwaysPreferChildComponent
        ? component.children?.[index] ?? firstChildComponent
        : firstChildComponent,
      context: mergeContext(context, {
        attributes: {
          _currentItem: isObject(item)
            ? { ...item, index: String(index + 1) }
            : item,
        },
        attributeKeyToComponentId: {
          _currentItem: currentItemId,
        },
      }),
      item,
    }));
  }, [
    component,
    itemsConfig,
    context,
    currentItemId,
    alwaysPreferChildComponent,
    dataTableMapping,
    products,
    fakeProducts,
    currencyCode,
    moneyFormat,
    language,
    templateProduct,
    emptyChildren,
    staticChildren,
    isEditorApp,
    isShopifyProductsLoading,
  ]);

  return items;
}

function getStaticChildren(
  component: Component | null | undefined,
  context: Context,
): RenderChildren {
  const children = filterNulls(
    component?.children?.map((child) => {
      if (!child) {
        return null;
      }

      // Note (Evan, 2024-11-19, USE-1448): This is the same logic as in ReploComponentSafe - we can only
      // definitively say a component is completely hidden if it is hidden on all breakpoints AND
      // there are no states (we don't check visibility in all possible states).
      const selfOrParentHasVariants =
        context.selfOrParentHasVariants || (child.variants?.length ?? 0) > 0;

      if (isCompletelyHiddenComponent(child) && !selfOrParentHasVariants) {
        return null;
      }

      return {
        component: child,
        context,
        item: null,
      };
    }),
  );

  return children;
}
