import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import React from "react";

import Selectable from "@editor/components/common/designSystem/Selectable";

import ControlGroup from "../../extras/ControlGroup";

interface TagSelectorProps {
  currentTag: RichTextEditorTag | ReploMixedStyleValue | null;
  onChangeTag: (tag: RichTextEditorTag) => void;
}

const TagSelector: React.FC<TagSelectorProps> = ({
  currentTag,
  onChangeTag,
}) => {
  return (
    <ControlGroup label="Tag">
      <Selectable
        layoutClassName="w-full"
        placeholder="Select tag"
        value={currentTag}
        options={tagOptions}
        onSelect={onChangeTag}
      />
    </ControlGroup>
  );
};

const tagOptions: {
  label: string;
  value: RichTextEditorTag;
}[] = [
  {
    label: "Paragraph",
    value: "P",
  },
  {
    label: "Heading 1",
    value: "1",
  },
  {
    label: "Heading 2",
    value: "2",
  },
  {
    label: "Heading 3",
    value: "3",
  },
  {
    label: "Heading 4",
    value: "4",
  },
  {
    label: "Heading 5",
    value: "5",
  },
  {
    label: "Heading 6",
    value: "6",
  },
];

export default TagSelector;
