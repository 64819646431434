import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/props";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _option: {
      name: "Option",
      type: "productOptions",
      defaultValue: null,
    },
    _hideDefaultArrowDisplay: {
      name: "Hide Default Arrow Display",
      type: "boolean",
      defaultValue: false,
      description:
        "If set, the default arrow which indicates the component is a dropdown will be hidden.",
    },
    _defaultText: {
      name: "Default Text",
      type: "string",
      defaultValue: null,
      description:
        "If set, this text will be displayed when the user has never selected an option.",
    },
    _swatches: {
      type: "swatches",
      name: "Swatches",
      description: "",
      defaultValue: null,
    },
    _showOptionsNotSoldTogether: {
      name: "Show Options Not Sold Together",
      type: "boolean",
      description:
        "Show all options, even if they are not sold together with the other selected options",
      defaultValue: false,
    },
  };
}

const styleElements = {
  root: {
    defaultStyles: {
      cursor: "pointer",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderLeftWidth: 0,
    },
    overrideStyles({ component }) {
      const hideDefaultArrowDisplay = component.props._hideDefaultArrowDisplay;
      return hideDefaultArrowDisplay ? { appearance: "none" } : {};
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: true,
    canvasIndicatorDragDirections: [],
    ancestorAllow: {
      ancestorTypes: ["product"],
      message:
        "Option Dropdown components may only be placed inside a Product box.",
    },
    ancestorDisallowList: [
      {
        ancestorTypes: ["marquee"],
        message: "Option Dropdowns cannot be nested inside tickers.",
      },
    ],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
