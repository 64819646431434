import React from "react";

interface DetailsContainerProps {
  title: string;
  isRequired?: boolean;
  description?: string;
  headerComponent?: React.ReactNode;
}

export const DetailsContainer: React.FC<
  React.PropsWithChildren<DetailsContainerProps>
> = ({ title, description, children, isRequired = false, headerComponent }) => {
  return (
    <div className="rounded-md flex flex-col gap-2 text-default w-full">
      <div className=" flex flex-col gap-1">
        <div className="font-semibold flex flex-row gap-2 justify-between">
          <div className="flex gap-1">
            <span>
              {title}&nbsp;
              {isRequired && <span className="text-danger">*</span>}
            </span>
          </div>
          <div className="flex flex-1 justify-end">{headerComponent}</div>
        </div>
        {description && <span className="text-subtle">{description}</span>}
      </div>
      {children}
    </div>
  );
};
