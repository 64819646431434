import type { StyleElements } from "replo-runtime/shared/utils/styles";
import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/props";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _knoCommerceApiKey: {
      type: "string",
      name: "KnoCommerce API Key",
      description:
        "Found in KnoCommerce dashboard in the 'Custom' Integration in 'Settings' -> 'Integration'",
      defaultValue: null,
    },
    _knoCommerceAccountId: {
      type: "string",
      name: "KnoCommerce Account Id",
      description: "Found in KnoCommerce dashboard in 'Settings' -> 'Account'",
      defaultValue: null,
    },
    _surveyId: {
      type: "string",
      name: "Survey Id",
      description:
        "Found in KnoCommerce dashboard. Will default to most recently published survey when left blank.",
      defaultValue: null,
    },
  };
}

const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
