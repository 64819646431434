import type { InternalQuery } from "@/features/analytics/query";
import type { ChartConfig } from "@replo/design-system/components/shadcn/core/chart";
import type {
  AnalyticsReadQuery,
  AnalyticsReadQueryFilters,
  MetricName,
} from "schemas/generated/analyticsRead";

import { ConditionOperatorEnum, PageTypeEnum } from "schemas/analyticsRead";

export const RESULTS_PER_PAGE = 25;

const DEFAULT_SORT_METRIC: MetricName = "unique_sessions";
const DEFAULT_QUERY_RESULT_LIMIT = RESULTS_PER_PAGE;
const DEFAULT_QUERY_RESULT_OFFSET = 0;
const DEFAULT_ORDER: AnalyticsReadQuery["order"] = "DESC";

const DEFAULT_METRICS: AnalyticsReadQuery["metrics"] = [
  "conversions",
  "views",
  "conversion_rates",
  "unique_sessions",
  "revenue",
  "revenue_per_session",
  "average_order_value",
];

export const DEFAULT_FILTERS: AnalyticsReadQueryFilters = {
  urlPath: [
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/checkouts/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/orders/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/tools/recurring/portal/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/account/",
    },
  ],
  pageType: PageTypeEnum.ENTRY_PAGES,
  urlParams: {},
};

export const DEFAULT_QUERY: InternalQuery = {
  order: DEFAULT_ORDER,
  sortMetric: DEFAULT_SORT_METRIC,
  metrics: DEFAULT_METRICS,
  offset: DEFAULT_QUERY_RESULT_OFFSET,
  limit: DEFAULT_QUERY_RESULT_LIMIT,
};

export const OVERVIEW_TABLE_METRICS: {
  key: MetricName;
  label: string;
}[] = [
  { key: "unique_sessions", label: "Sessions" },
  { key: "conversions", label: "Conversions" },
  { key: "revenue", label: "Revenue" },
  { key: "conversion_rates", label: "Conversion Rate" },
  { key: "average_order_value", label: "AOV" },
  { key: "revenue_per_session", label: "Revenue per Session" },
];

export type TableMetric = MetricName;

export const METRICS_REQUIRING_DECIMALS: TableMetric[] = [
  "revenue",
  "revenue_per_session",
  "conversion_rates",
  "average_order_value",
];
export const METRICS_REQUIRING_PERCENTAGE: TableMetric[] = ["conversion_rates"];
export const METRICS_REQUIRING_CURRENCY: TableMetric[] = [
  "revenue",
  "revenue_per_session",
  "average_order_value",
];

export const DEFAULT_CURRENCY = "USD";

export const METRIC_SORT_TOOLTIP_TEXT: Record<
  AnalyticsReadQuery["sortMetric"],
  string
> = {
  conversion_rates: "Sort by Conversion Rate",
  average_order_value: "Sort by AOV",
  revenue_per_session: "Sort by Revenue/Session",
  unique_sessions: "Sort by Unique Sessions",
  conversions: "Sort by Conversions",
  revenue: "Sort by Revenue",
  views: "Sort by Views",
};

export const DETAILS_PAGE_CARD_METRICS: {
  label: string;
  key: MetricName;
}[] = [
  { label: "Conversion Rate", key: "conversion_rates" },
  { label: "Revenue/Session", key: "revenue_per_session" },
  { label: "Average Order Value", key: "average_order_value" },
  { label: "Total Sessions", key: "unique_sessions" },
  { label: "Total Conversions", key: "conversions" },
  { label: "Total Revenue", key: "revenue" },
];

export const DEFAULT_ANALYTICS_CHART_CONFIG: ChartConfig = {
  lines: {
    previousPeriod: {
      label: "Previous Period",
      color: "blue-300",
      dataKey: "previousPeriod",
      type: "linear",
      strokeWidth: 2,
    },
    thisPeriod: {
      label: "This Period",
      color: "blue-600",
      dataKey: "thisPeriod",
      type: "linear",
      strokeWidth: 2,
    },
  },
  xAxis: {
    dataKey: "x",
  },
  tooltipKeyFormatter: (value: string) => {
    return `${value.slice(0, 16).replace("T", " ")}`;
  },
};
