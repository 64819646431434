import type { ComboboxProps } from "@replo/design-system/components/combobox/Combobox";
import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type { DateAfter, DateRange } from "react-day-picker";
import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "replo-utils/analytics";

import * as React from "react";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { TZDate } from "@date-fns/tz";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import { DatePickerWithRange } from "@replo/design-system/components/shadcn/core/date-range-picker";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsCalendar3 } from "react-icons/bs";
import {
  calculateCompareRangePredefined,
  calculateCustomRange,
  calculateQueryRangesForRelativeTimeFrame,
  calculateRangesForCustomTimeFrame,
} from "replo-utils/analytics";

type AnalyticsTimeRangeComboboxProps<
  T extends RelativeTimeFrame | CompareRangeHandleChange,
> = Pick<ComboboxProps, "open" | "onOpenChange" | "size"> & {
  handleRangeChange: (timeFrame: T) => void;
  isCompare: boolean;
  placeholderPrefix?: string;
  options: (ComboboxOption & { displayValue: string })[];
};

type CompareRangeHandleChange = ComparisonTimeFrame | DateRange;
type MainRangeHandleChange = RelativeTimeFrame | DateRange;

const rangeCustomDatePicker = (
  startDateTime: number,
  endDateTime: number,
  handleRangeChange: (dateRange: DateRange) => void,
  setIsRangePickerOpen: (isOpen: boolean) => void,
  timeZone: string,
  size?: ComboboxProps["size"],
): React.ReactNode => {
  return (
    <DatePickerWithRange
      initialDateRange={{
        from: new TZDate(startDateTime, timeZone),
        to: new TZDate(endDateTime, timeZone),
      }}
      disabledDates={{ after: new TZDate(new Date(), timeZone) } as DateAfter}
      updateAction={(dateRange) => {
        handleRangeChange({
          from: dateRange.from
            ? new TZDate(dateRange.from, timeZone)
            : undefined,
          to: dateRange.to ? new TZDate(dateRange.to, timeZone) : undefined,
        });
        setIsRangePickerOpen(false);
      }}
      popoverTriggerComponent={() => (
        <span
          className={twMerge(
            "font-medium cursor-pointer flex items-center text-blue-600",
            size === "sm" ? "typ-body-small" : "typ-body-base",
          )}
        >
          <BsCalendar3 size={size === "sm" ? 12 : 14} className="mr-1.5" />
          Custom date range
        </span>
      )}
      closeAction={() => setIsRangePickerOpen(false)}
      rangeExtendsOnSelect={false}
    />
  );
};

export const AnalyticsTimeRangeCombobox = <
  T extends MainRangeHandleChange | CompareRangeHandleChange,
>({
  handleRangeChange,
  isCompare,
  size = "sm",
  ...props
}: AnalyticsTimeRangeComboboxProps<T>) => {
  const { query, timeZone } = useAnalyticsQueryContext();

  let startDatetime;
  let endDatetime;
  let selectedValue;
  const selectedTimePeriod = query.selectedTimePeriod;
  const selectedComparePeriod = query.selectedComparePeriod;
  const { updatedRanges, selectedTimeFrame } =
    selectedTimePeriod.type === "custom"
      ? calculateRangesForCustomTimeFrame({
          start: selectedTimePeriod.value.from,
          end: selectedTimePeriod.value.to,
          timeZone,
        })
      : calculateQueryRangesForRelativeTimeFrame({
          timeFrame: selectedTimePeriod.value as RelativeTimeFrame,
          timeZone,
        });

  if (!isCompare) {
    selectedValue = selectedTimeFrame;
    startDatetime = updatedRanges.mainRange.startDatetime;
    endDatetime = updatedRanges.mainRange.endDatetime;
  } else {
    const { range: compareAtRange, selectedPeriod } =
      selectedComparePeriod.type === "custom"
        ? calculateCustomRange({
            start: selectedComparePeriod.value.from,
            end: selectedComparePeriod.value.to,
            timeZone,
          })
        : calculateCompareRangePredefined(
            selectedComparePeriod.value as ComparisonTimeFrame,
            updatedRanges.mainRange,
          );

    selectedValue = selectedPeriod;
    startDatetime = compareAtRange.startDatetime;
    endDatetime = compareAtRange.endDatetime;
  }

  const [isRangePickerOpen, setIsRangePickerOpen] = React.useState(false);

  const footerContent = (
    <>
      {rangeCustomDatePicker(
        startDatetime ?? 0,
        endDatetime ?? 0,
        (dateRange) => handleRangeChange(dateRange as T),
        setIsRangePickerOpen,
        timeZone,
        size,
      )}
    </>
  );

  const selectedOption = props.options.find(
    (option) => option.value === selectedValue,
  );
  return (
    <Combobox
      {...props}
      value={selectedValue}
      size={size}
      onChange={(value) => {
        handleRangeChange(value as T);
      }}
      open={isRangePickerOpen}
      onOpenChange={(isOpen) => {
        setIsRangePickerOpen(isOpen);
        props.onOpenChange?.(isOpen);
      }}
      trigger={
        <Combobox.SelectionButton
          title={
            selectedOption?.displayValue ??
            `${props.placeholderPrefix ? `${props.placeholderPrefix} ` : ""}${selectedValue}`
          }
          startEnhancer={<BsCalendar3 className="h-4 w-4" />}
        />
      }
      footer={
        footerContent && (
          <Combobox.Footer size="sm">{footerContent}</Combobox.Footer>
        )
      }
    />
  );
};
