import type { ComponentTemplate } from "@editor/types/component-template";

export const button: ComponentTemplate = {
  id: "button",
  scope: "left-bar",
  type: "component",

  storeId: null,
  name: "Basic/Button",
  leftBarCategory: { main: "basic", subCategory: "core" },
  preview: "basic/button.svg",
  template: {
    id: "e0dffb31-40e9-4584-816a-5dc08c4cec8b",
    type: "button",
    props: {
      style: {
        color: "#FFFFFF",
        display: "flex",
        paddingTop: "20px",
        paddingLeft: "32px",
        paddingRight: "32px",
        paddingBottom: "20px",
        backgroundColor: "#000000FF",
        alignSelf: "auto",
        width: "auto",
      },
    },
    children: [
      {
        id: "036e5d7c-6d05-447d-a233-d5f6bee3cc1e",
        type: "text",
        props: {
          text: "<p>This is a button</p>",
          style: {
            letterSpacing: "2px",
            color: "#FFFFFF",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "20px",
            flexGrow: 1,
          },
        },
      },
    ],
  },
};
