import type { ComponentTemplate } from "@editor/types/component-template";

import { addDays, addHours, addMinutes, addSeconds } from "date-fns";

export const countdownTimer: ComponentTemplate = {
  id: "$uid",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Component/Countdown Timer",
  leftBarCategory: { main: "interactive", subCategory: "countdown" },
  preview: "component/countdown-timer.svg",
  transforms: [
    (component) => {
      // Create a date which is sufficiently in the future but not too much,
      // in order to populate the countdown timer with data
      const targetDate = addSeconds(
        addMinutes(addHours(addDays(new Date(), 10), 5), 30),
        30,
      );
      component.props._endTime = targetDate.toISOString();
      return component;
    },
  ],
  template: {
    id: "6ba2243c-416d-4f3f-adf8-ca8ef557e116",
    name: "Countdown Timer",
    type: "countdownTimer",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        __flexGap: "42px",
        alignSelf: "stretch",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
      },
      _endTime: "2022-06-25T20:07:00.000Z",
    },
    children: [
      {
        id: "93af8b34-1cbc-41c9-9ba7-5028c1bb53b3",
        type: "container",
        props: {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            flexGrow: "unset",
            alignSelf: "stretch",
            __flexGap: "8px",
          },
        },
        children: [
          {
            id: "94005dd3-c6cd-41d9-a9cf-322f5ec9b65c",
            type: "text",
            props: {
              text: "{{attributes.daysUntilEnd}}",
              style: {
                fontSize: "52px",
                lineHeight: "54px",
                fontWeight: "700",
                fontFamily: null,
              },
            },
          },
          {
            id: "c3ef0972-6079-4074-9b05-80f38594e52f",
            type: "text",
            props: {
              text: "<p>DAYS</p>",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "700",
              },
            },
          },
        ],
        name: "Container 1",
      },
      {
        id: "715f83ff-df2b-4e90-b739-2e39e56292ed",
        type: "text",
        props: {
          text: "<p>:</p>",
          style: { fontSize: "53px", lineHeight: "55px" },
        },
      },
      {
        id: "78124858-674a-4c63-a1c7-bec4ace32fa9",
        type: "container",
        props: {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            flexGrow: "unset",
            alignSelf: "stretch",
            __flexGap: "8px",
          },
        },
        children: [
          {
            id: "f3a1f0da-dbb6-43fc-86f5-9a0a0e3828e6",
            type: "text",
            props: {
              text: "{{attributes.hoursUntilEnd}}",
              style: {
                fontSize: "52px",
                lineHeight: "54px",
                fontWeight: "700",
                fontFamily: null,
              },
            },
          },
          {
            id: "182546a4-0fed-44e9-801d-cab97ce5b63f",
            type: "text",
            props: {
              text: "<p>HOURS</p>",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "700",
              },
            },
          },
        ],
        name: "Container 1",
      },
      {
        id: "59ff221c-fd0f-4e72-bf2e-c8d6104ad30a",
        type: "text",
        props: {
          text: "<p>:</p>",
          style: { fontSize: "53px", lineHeight: "55px" },
        },
      },
      {
        id: "66c1ad2d-0d35-404b-9972-80ab91484307",
        type: "container",
        props: {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            flexGrow: "unset",
            alignSelf: "stretch",
            __flexGap: "8px",
          },
        },
        children: [
          {
            id: "34dc04b8-3ff7-41e5-93fb-ab8a3b550aef",
            type: "text",
            props: {
              text: "{{attributes.minutesUntilEnd}}",
              style: {
                fontSize: "52px",
                lineHeight: "54px",
                fontWeight: "700",
                fontFamily: null,
              },
            },
          },
          {
            id: "9cfc5b98-7f97-4dfe-ba62-a0d2f11d6a2f",
            type: "text",
            props: {
              text: "<p>MINUTES</p>",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "700",
              },
            },
          },
        ],
        name: "Container 1",
      },
      {
        id: "87f74446-fe31-4d9a-8bca-08a56465c67c",
        type: "text",
        props: {
          text: "<p>:</p>",
          style: { fontSize: "53px", lineHeight: "55px" },
        },
      },
      {
        id: "08960c6b-948e-4cd2-9c60-acf2fb27c86a",
        type: "container",
        props: {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            flexGrow: "unset",
            alignSelf: "stretch",
            __flexGap: "8px",
          },
        },
        children: [
          {
            id: "9b056334-4e4e-4d08-a11f-8bf39eed4519",
            type: "text",
            props: {
              text: "{{attributes.secondsUntilEnd}}",
              style: {
                fontSize: "52px",
                lineHeight: "54px",
                fontWeight: "700",
                fontFamily: null,
              },
            },
          },
          {
            id: "5c32f080-6bbb-4afe-963b-a8495d11becd",
            type: "text",
            props: {
              text: "<p>SECONDS</p>",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "700",
              },
            },
          },
        ],
        name: "Container 1",
      },
    ],
  },
};
