import type { FlowStepDataValueOf } from "schemas/flow";

import * as React from "react";

import { FlowProgress } from "@components/flows/components/FlowProgress";
import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import usePendingShopifyIntegrationCookie from "@editor/hooks/usePendingShopifyIntegrationCookie";
import { ReploLogoBlack } from "@svg/replo-logo-black";

import twMerge from "@replo/design-system/utils/twMerge";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { useEffectEvent } from "replo-utils/react/use-effect-event";

type OnboardingStepLayoutProps = {
  rightPanelContent: React.ReactNode;
};

const OnboardingStepLayout: React.FC<
  React.PropsWithChildren<OnboardingStepLayoutProps>
> = ({ rightPanelContent, children }) => {
  const analytics = useLogAnalytics();
  const { currentStep } = useReploFlowsStepContext();
  const { currentInstance } = useGetCurrentFlow();

  const { pendingShopifyIntegrationCookie } =
    usePendingShopifyIntegrationCookie();

  const onBeforeUnload = useEffectEvent(() => {
    if (currentStep && currentInstance) {
      const isUserAbandoningFlow = exhaustiveSwitch(currentStep)({
        "onboarding.user.full-name": true,
        "onboarding.user.import-from-url": true,
        "onboarding.user.who-are-you-building-pages-for": true,
        "onboarding.user.workspace-name": true,
        "onboarding.user.what-do-you-want-to-do-in-replo": true,
        "onboarding.user.who-are-you-building-pages-for.revenue": true,
        "onboarding.user.which-best-describes-your-industry": true,
        "onboarding.user.brand-details": true,
        "onboarding.user.saved-styles": true,
        "onboarding.user.do-you-want-to-start-from-template": true,
        // Note (Noah, 2025-03-04): If there's a cookie, that means that the user is going to shopify,
        // not abandoning the flow
        "onboarding.user.connect-shopify": !Boolean(
          pendingShopifyIntegrationCookie,
        ),
        "tour.editor.generic": true,
      });
      if (isUserAbandoningFlow) {
        analytics("onboarding.flow.abandoned", {
          lastStepCompleted: currentStep?.id,
          stepsData: currentInstance?.stepResults || {},
          whatToDo:
            (
              currentInstance?.stepResults?.[
                "onboarding.user.what-do-you-want-to-do-in-replo"
              ]
                ?.data as FlowStepDataValueOf<"onboarding.user.what-do-you-want-to-do-in-replo">
            )?.whatToDo ?? [],
        });
      }
    }
  });

  React.useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [onBeforeUnload]);

  return (
    <div className="bg-gray-100 w-screen h-screen grid grid-cols-12 grid-rows-1">
      <div className="col-span-12 lg:col-span-5 xl:col-span-4 bg-white flex flex-col justify-between overflow-auto no-scrollbar h-full">
        <div className="flex flex-1 flex-col gap-12 px-14 pt-14">
          <ReploLogoBlack />
          {children}
        </div>
        <div className="py-6 px-14">
          <FlowProgress entityType="user" />
        </div>
      </div>
      <div className="col-span-12 lg:col-span-7 xl:col-span-8 overflow-hidden">
        {rightPanelContent}
      </div>
    </div>
  );
};

export const OnboardingStepForm: React.FC<
  React.PropsWithChildren<{
    onSubmit: (data: React.FormEvent<HTMLFormElement>) => void;
    className?: string;
  }>
> = ({ children, onSubmit, className }) => {
  return (
    <form
      className={twMerge("flex flex-col gap-10 flex-1", className)}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};

export default OnboardingStepLayout;
