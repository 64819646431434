import type { useErrorToast } from "@editor/hooks/useErrorToast";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { Component } from "schemas/component";
import type { DesignLibrarySavedStyleMetadata } from "schemas/generated/designLibrary";
import type { ReploElement } from "schemas/generated/element";

import { prepareComponentTemplate } from "@editor/components/editor/defaultComponentTemplates";
import { image } from "@editor/components/editor/templates/image";
import { text } from "@editor/components/editor/templates/text";
import { setStorageItem } from "@editor/hooks/useLocalStorage";

import { isFirefox, isSafari } from "@replo/design-system/utils/platform";
import cloneDeep from "lodash-es/cloneDeep";
import svgToMiniDataURI from "mini-svg-data-uri";
import { isNullish } from "replo-utils/lib/misc";

const ReploComponentClipboardKey = "alchemy-component-clipboard";

export type ReploClipboardText = { type: "text"; component: Component };
export type ReploClipboardImage = { type: "image"; component: Component };
export type ReploClipboardMultiple = {
  type: "multipleComponents";
  components: Component[];
  designLibraryMetadata: DesignLibrarySavedStyleMetadata | null;
};
export type ReploClipboardSingle = {
  type: "singleComponent";
  component: Component;
  designLibraryMetadata: DesignLibrarySavedStyleMetadata | null;
};

export type ReploClipboardStyles = {
  type: "copyPasteStyles";
  styles: string;
};

export type ReploClipboardFigmaStyles = {
  type: "pasteCSSStyles";
  rawCss: string;
};

// NOTE/TODO (Jose, 2024-06-07): Create in a utils package so it can be shared with the plugin
export interface PluginImageData {
  id: string;
  dataURL: string;
  name: string;
}

type ExportWarnings = {
  multipleFillsNodes: { name: string }[];
  maskNodes: { name: string }[];
};

export type ReploClipboardFigmaPluginv2 = {
  type: "figmaPluginExportv2";
  reploComponent: Component;
  reploComponentIdToImageData: Record<string, string>;
  figmaId?: string | null;
  exportWarnings?: ExportWarnings;
};

export type ReploClipboard =
  | ReploClipboardSingle
  | ReploClipboardMultiple
  | ReploClipboardText
  | ReploClipboardImage
  | ReploClipboardStyles
  | ReploClipboardFigmaStyles
  | ReploClipboardFigmaPluginv2;

const textHtmlType = "text/html";
const textPlainType = "text/plain";

const getValueFromClipboardItems = (data: ClipboardItems) => {
  if (data.length === 0) {
    return null;
  }
  if (data[0]!.types.includes(textPlainType)) {
    return data[0]!.getType(textPlainType).then((blob) => blob.text());
  }
  if (data[0]!.types.includes(textHtmlType)) {
    return data[0]!.getType(textHtmlType).then(async (blob) => {
      const htmlText = await blob.text();
      return parseClipboardDataFromHtml(htmlText);
    });
  }
  return null;
};

const parseClipboardDataFromHtml = (htmlText: string) => {
  // Note: as we are passing data via text/html reploClipboard we are doing this b/c
  // we don't want to expose our json structure to the world, now we have to decode and use it!
  const parser = new DOMParser();
  let clipboardText = htmlText;
  const doc = parser.parseFromString(htmlText, textHtmlType);
  const divElement = doc.querySelector("div");
  if (!divElement) {
    return clipboardText;
  }
  // Note (Noah, 2024-11-03): Default to alchemyClipboard for compatibility, confirm with e.g.
  // Daydreamers who has copy-to-replo that they're using reploClipboard
  const encodedData =
    divElement.dataset?.["reploClipboard"] ??
    divElement.dataset?.["alchemyClipboard"];
  if (encodedData) {
    clipboardText = unescape(encodedData);
  }
  return clipboardText;
};

const getDataFromDataTransfer = (data: DataTransfer) => {
  const types = Array.from(data.types).map((type) => ({
    type,
    data: data.getData(type),
  }));

  if (data.types.includes(textPlainType)) {
    return types.find((type) => type.type === textPlainType)!.data;
  } else if (data.types.includes(textHtmlType)) {
    const htmlText = types.find((type) => type.type === textHtmlType)!.data;
    return parseClipboardDataFromHtml(htmlText);
  }
  return null;
};

const getLocalStorageClipboardData = () => {
  return localStorage.getItem(ReploComponentClipboardKey);
};

const readFromClipboard = async (
  errorToast: ReturnType<typeof useErrorToast>,
  clipBoard?: DataTransfer,
) => {
  if (clipBoard) {
    return getDataFromDataTransfer(clipBoard);
  }
  const doesSupportClipboardRead = Boolean(navigator.clipboard.read);
  if (!doesSupportClipboardRead) {
    return getLocalStorageClipboardData();
  }

  if (!isFirefox() && !isSafari()) {
    try {
      const clipboardReadPermission = await navigator?.permissions
        // @ts-ignore
        ?.query({ name: "clipboard-read" });
      const hasReadPermission = ["granted", "prompt"].includes(
        clipboardReadPermission.state,
      );

      if (!hasReadPermission) {
        return errorToast(
          "Missing Permissions",
          "Replo requires permission to read from the clipboard. You can grant this permission in your browser's settings.",
          "error.clipboard.read",
          {
            clipboardReadPermission,
          },
        );
      }

      const clipboardData = await navigator.clipboard.read();
      return (
        (await getValueFromClipboardItems(clipboardData)) ||
        getLocalStorageClipboardData()
      );
    } catch {
      return getLocalStorageClipboardData();
    }
  }

  return getLocalStorageClipboardData();
};

const getHtmlFromJsonString = (text: string) => {
  // Note: Ovishek (2022-04-08) escape/unescape is needed to encode the html tags and quotation marks
  // we are not using encode/decode URI functions b/c text can already have encoded stuff that we don't want
  // to expose during decoding process. For more context, https://linear.app/replo/issue/REPL-1567
  return `<div data-alchemy-clipboard="${escape(text)}"></div>`;
};

const writeToClipboard = async (
  text: string,
  errorToast: ReturnType<typeof useErrorToast>,
  clipBoard?: DataTransfer,
) => {
  if (clipBoard) {
    clipBoard.setData(textHtmlType, getHtmlFromJsonString(text));
    return;
  }

  const doesSupportClipboardWrite = Boolean(navigator.clipboard.write);
  setStorageItem(ReploComponentClipboardKey, text);
  if (!doesSupportClipboardWrite) {
    return;
  }

  if (!isFirefox() && !isSafari()) {
    void navigator?.permissions
      // @ts-ignore
      ?.query({ name: "clipboard-write" })
      .then((result) => {
        if (result.state == "granted" || result.state == "prompt") {
          // Note: saving data to clipboard via text/html not to expose when user paste in other platforms
          const blob = new Blob([getHtmlFromJsonString(text)], {
            type: textHtmlType,
          });
          const data = [new ClipboardItem({ [textHtmlType]: blob })];
          navigator.clipboard.write(data).then(
            () => {
              return;
            },
            (error) => {
              console.warn("Unable to write to clipboard. :-(", error);
            },
          );
        } else {
          errorToast(
            "Missing Permissions",
            "Replo requires permission to write to the clipboard. You can grant this permission in your browser's settings. For further assistance, please contact support@replo.app.",
            "error.clipboard.write",
            {
              permissions: navigator?.permissions,
            },
          );
        }
      });
  }
};

const getFormattedImageSrc = (html: string | null) => {
  if (!html) {
    return null;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const img = doc.querySelector("img");
  return img?.src ?? null;
};

const checkForSvgOrImg = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const svgExists = doc.querySelector("svg") !== null;
  const imgExists = doc.querySelector("img") !== null;

  return {
    containsSVG: svgExists,
    containsImg: imgExists,
  };
};
const isValidImageUrl = (url: string) => {
  try {
    new URL(url);
    const imageRegex = /\.(jpeg|jpg|png|webp)(\?.*)?$/i;
    return imageRegex.test(url);
  } catch {
    return false;
  }
};

export const getComponentClipboard = async (
  clipBoard: DataTransfer | undefined,
  element: ReploElement,
  getAttribute: GetAttributeFunction,
  componentDataMapping: ComponentDataMapping,
  productResolutionDependencies: ProductResolutionDependencies,
  errorToast: ReturnType<typeof useErrorToast>,
): Promise<ReploClipboard | null> => {
  const content = await readFromClipboard(errorToast, clipBoard);

  if (!content) {
    return null;
  }

  let parsedData = null;
  try {
    parsedData = JSON.parse(content);
  } catch {}

  // Note: Ovishek (2022-03-12) if parsed parsedData is null that mean's it's not a component we copied
  // so we assume it's a text
  if (isNullish(parsedData)) {
    const { containsSVG, containsImg } = checkForSvgOrImg(content);
    const isValidUrl = isValidImageUrl(content);
    if (
      containsSVG ||
      containsImg ||
      content.startsWith("data:image/svg") ||
      isValidUrl
    ) {
      // Note (Sebas, 2022-10-19) I'm using lodash because JSON.stringify
      // removes functions
      let src: string | null = content;
      const imageTemplate: ComponentTemplate = cloneDeep(image);
      const isHtml = containsSVG || containsImg;

      if (isHtml) {
        src = containsSVG
          ? svgToMiniDataURI(content)
          : getFormattedImageSrc(content);
      }

      if (imageTemplate.template && src) {
        imageTemplate.template.props.src = src;
      }

      const newComponent = prepareComponentTemplate(
        imageTemplate,
        null,
        element,
        {
          getAttribute,
          productResolutionDependencies,
          componentDataMapping,
          context: null,
        },
      );

      return {
        type: "image",
        component: newComponent,
      };
    }

    // Note (Sebas, 2023-03-31): This is to check if the pasted element
    // contains CSS properties. If it does, we are inferring that the
    // user is pasting styles from Figma.
    const cssPropertiesRegex = /\b[A-Za-z-]+:\s*[\d\s#(),A-Za-z-]+;/g;
    const containsCssProperties = cssPropertiesRegex.test(content);
    // Note (Sebas, 2023-03-31): This is to check if the pasted element
    // contains CSS border property. This is because the regex
    // above is not perfect and it's matching css properties checking for ;
    // at the end of the line. For some reason when you copy a border style
    // from Figma, it doesn't have a ; at the end of the line.
    const cssBorderRegex =
      /border(?:-(?:top|right|bottom|left))?:\s*\d+px\s+(?:solid|dashed|dotted|double|groove|ridge|inset|outset)\s+#[\dA-Fa-f]{6};\s*/;
    const containsCssBorderProperty = cssBorderRegex.test(content);

    if (containsCssProperties || containsCssBorderProperty) {
      return {
        type: "pasteCSSStyles",
        rawCss: content,
      };
    }

    const textTemplate: ComponentTemplate = JSON.parse(JSON.stringify(text));
    if (textTemplate.template) {
      textTemplate.template.props.text = `<p>${content}</p>`;
    }
    const newComponent = prepareComponentTemplate(textTemplate, null, element, {
      getAttribute,
      productResolutionDependencies,
      componentDataMapping,
      context: null,
    });

    return {
      type: "text",
      component: newComponent,
    };
  }

  if (
    ![
      "multipleComponents",
      "singleComponent",
      "copyPasteStyles",
      "figmaPluginExportv2",
    ].includes(parsedData?.type)
  ) {
    return null;
  }

  return parsedData;
};

export const setComponentClipboard = (
  data: ReploClipboard,
  errorToast: ReturnType<typeof useErrorToast>,
  clipBoard?: DataTransfer,
) => {
  void writeToClipboard(JSON.stringify(data), errorToast, clipBoard);
};
