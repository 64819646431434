import React, { useEffect, useState } from "react";

import { Root } from "@radix-ui/react-toggle-group";
import { animated, useSpring } from "@react-spring/web";
import twMerge from "@replo/design-system/utils/twMerge";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import { Toggle } from "./Toggle";

export type ToggleOption = {
  tooltipContent?: React.ReactNode;
  value: string;
  label: React.ReactNode;
  disabled?: boolean;
};

export interface ToggleGroupProps {
  size: "base" | "sm";
  deselectable?: boolean;
  disabled?: boolean;
  selectedValue?: string | null;
  onChange?: (selected: string) => void;
  options: ToggleOption[];
  layoutClassName?: string;
  UNSAFE_className?: string;
}

export const ToggleGroup: React.FC<ToggleGroupProps> = ({
  size,
  disabled = false,
  selectedValue,
  onChange,
  options = [],
  layoutClassName,
  UNSAFE_className,
  deselectable = false,
}) => {
  const [selected, setSelected] = useControllableState(
    selectedValue ?? undefined,
    "",
    onChange,
  );
  const [initialized, setInitialized] = useState(false);

  const numItems = options.length;

  const selectedIndex = options.findIndex(
    (option) => option.value === selected,
  );

  const [animatedStyles, api] = useSpring(() => ({
    left: `${(selectedIndex / numItems) * 100 + 50 / numItems}%`,
    top: "50%",
    width: "0%",
    height: "0%",
    transform: "translate(-50%, -50%)",
    config: { tension: 180, friction: 22, mass: 1.2 },
  }));

  useEffect(() => {
    if (selectedIndex !== -1 && numItems > 0) {
      if (!initialized) {
        void api.start({
          left: `${(selectedIndex / numItems) * 100 + 50 / numItems}%`,
          top: "50%",
          width: "0%",
          height: "0%",
          transform: "translate(-50%, -50%)",
          config: { tension: 180, friction: 22, mass: 1.2 },
          immediate: true,
        });
        setInitialized(true);
      } else {
        void api.start({
          left: `${(selectedIndex / numItems) * 100 + 50 / numItems}%`,
          width: `${100 / numItems}%`,
          height: "100%",
        });
      }
    }
  }, [selectedIndex, api, numItems, initialized]);
  return (
    <Root
      type="single"
      className={twMerge(
        "relative flex bg-light-surface rounded-[4px] items-center w-full overflow-visible",
        size === "sm" && "h-6",
        size === "base" && "h-8",
        disabled && "opacity-50 cursor-not-allowed",
        layoutClassName,
        UNSAFE_className,
      )}
      value={selected}
      onValueChange={(value) => {
        if (value || deselectable) {
          setSelected(value);
        }
      }}
      disabled={disabled}
    >
      {selectedIndex !== -1 && numItems > 0 && (
        <animated.div
          className="absolute top-0 bottom-0 bg-white hover:bg-white border-[.5px] border-border rounded-[4px]"
          style={animatedStyles}
        />
      )}
      {options.map((toggle, index) => {
        const isActive = selected === toggle.value;

        return (
          <div
            key={toggle.value}
            className="relative h-full flex-1 min-w-0 overflow-hidden"
          >
            {index > 0 &&
              !isActive &&
              options[index - 1]?.value !== selected && (
                <div className="w-[1px] h-4 bg-border absolute top-1/2 transform -translate-y-1/2" />
              )}
            <Toggle
              {...toggle}
              disabled={!disabled && toggle.disabled}
              size={size}
              isActive={isActive}
              className={twMerge(
                index === 0 && "rounded-r-none",
                index === options.length - 1 && "rounded-l-none",
                index !== 0 && index !== options.length - 1 && "rounded-none",
                isActive && "rounded-[4px]",
                "hover:rounded-[4px]",
              )}
            />
          </div>
        );
      })}
    </Root>
  );
};
