import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "replo-runtime/shared/runtime-context";

import RenderComponentPlaceholder from "../RenderComponentPlaceholder";

const RawHTMLContent: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
}) => {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);

  if (isEditorApp) {
    return (
      <div {...componentAttributes}>
        <RenderComponentPlaceholder type="html" title="Custom HTML" />
      </div>
    );
  }

  return component.props._htmlContent ? (
    <div
      {...componentAttributes}
      dangerouslySetInnerHTML={{ __html: component.props._htmlContent }}
    />
  ) : (
    <div {...componentAttributes}>
      <RenderComponentPlaceholder
        title={
          "Once you set this component's HTML content, the code will be embedded here."
        }
      />
    </div>
  );
};

export default RawHTMLContent;
