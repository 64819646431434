import type { RelativeRoutingType, To } from "react-router-dom";
import type { SpinnerVariants } from "../spinner/Spinner";
import type { ButtonSize, ButtonVariant } from "./button-shared";

import React from "react";

import { Spinner } from "@replo/design-system/components/spinner/Spinner";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { Link } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type TertiaryButtonProps = {
  variant: "tertiary";
  hasHoverHighlight?: boolean;
};

type OtherButtonProps = {
  variant: Exclude<ButtonVariant, "tertiary">;
};

type ButtonVariantProps = TertiaryButtonProps | OtherButtonProps;

interface ButtonPropsBase
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "className"> {
  children?: React.ReactNode;
  size?: ButtonSize;
  isLoading?: boolean;
  layoutClassName?: string;
  UNSAFE_className?: string;
  id?: string;
  // Patrick (2025-01-23): Added this React.ReactElement | false here to accept react elements only (so we can clone the className), but also allow
  // syntax like arrowRight && <BsArrowRight />, so that we don't have to explicitly pass through undefined
  startEnhancer?: React.ReactElement | false;
  endEnhancer?: React.ReactElement | false;
  tooltipText?: React.ReactNode;
  to?: To;
  target?: string;
  rel?: string;
  collisionPadding?: number;
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
  spinnerSize?: number;
  hasMinDimensions?: boolean;
  UNSAFE_contentClassName?: string;
  layoutChildContainerClassName?: string;
  isActive?: boolean;
  titleAlignment?: "start" | "center";
}
export type ButtonProps = ButtonPropsBase & ButtonVariantProps;

const TOOLTIP_SIDE_OFFSET = 8;

const Button = React.forwardRef<
  Omit<HTMLButtonElement | HTMLAnchorElement, "className">,
  ButtonProps
>((_props, ref) => {
  const {
    hasMinDimensions = true,
    children,
    variant,
    size = "sm",
    isLoading = false,
    startEnhancer,
    layoutChildContainerClassName,
    endEnhancer,
    tooltipText,
    to,
    target,
    rel,
    UNSAFE_contentClassName,
    collisionPadding = 0,
    replace,
    state,
    preventScrollReset,
    relative,
    spinnerSize,
    disabled,
    layoutClassName,
    UNSAFE_className,
    onClick,
    isActive,
    titleAlignment = "center",
    ...props
  } = _props;
  const hasHoverHighlight = exhaustiveSwitch(
    _props,
    "variant",
  )({
    tertiary: (props) => props.hasHoverHighlight,
    danger: () => false,
    primary: () => false,
    secondary: () => false,
    dangerLink: () => false,
    link: () => false,
    secondaryDanger: () => false,
    inherit: () => false,
  });

  const baseClass = twMerge(
    "group flex flex-shrink-0 items-center justify-center font-medium cursor-pointer transition duration-300",
    "rounded font-sans whitespace-nowrap",

    // Special styles
    disabled && "opacity-50 cursor-not-allowed",

    // Size related styles
    size === "sm" && "p-small h-small typ-button-small",
    size === "base" &&
      "p-base h-base typ-button-base max-sm:p-large max-sm:h-large max-sm:typ-button-large",

    // Loading styles
    isLoading && variant === "tertiary" && "bg-light-surface",
    isLoading && variant === "link" && "bg-info-soft",
    isLoading && variant === "dangerLink" && "bg-danger-soft",

    // Min Dimension styles
    hasMinDimensions && size === "sm" && "min-w-[64px]",
    hasMinDimensions && size === "base" && "min-w-[80px] max-sm:min-w-[92px]",

    // Variant styles
    variantClassNames[variant],

    // isActive styles
    isActive && isActiveVariantMap[variant],

    // Icon button styles
    hasHoverHighlight && !disabled && "hover:bg-light-surface",
    isActive && hasHoverHighlight && !disabled && "bg-light-surface",

    layoutClassName,
    UNSAFE_className,
  );

  const baseEnhancerClass = twMerge(
    size === "sm" && "typ-button-small",
    size === "base" && "typ-button-base max-sm:typ-button-large",
  );

  const content = (
    <div
      className={twMerge("flex items-center flex-1", UNSAFE_contentClassName)}
    >
      {/* Patrick (2025-01-23):
          We're cloning the startEnhancer here in order to pass the baseEnhancerClass to it as a default.
          We force the enhancer to be a reactElement.

          If this gets inefficient in the future, we can memoize the startEnhancer
        */}
      {startEnhancer &&
        React.cloneElement(startEnhancer, {
          className: twMerge(baseEnhancerClass, startEnhancer.props.className),
        })}
      <div
        className={twMerge(
          "relative flex items-center justify-center flex-1",
          layoutChildContainerClassName,
        )}
      >
        {isLoading && (
          <Spinner
            UNSAFE_className="absolute"
            size={spinnerSize ? spinnerSize : 16}
            variant={spinnerVariantMap[variant]}
          />
        )}
        {children && (
          <div
            className={twMerge(
              "flex-1 flex",
              titleAlignment === "start" && "justify-start",
              titleAlignment === "center" && "justify-center",
              isLoading && "invisible",
              startEnhancer && "ml-1",
              endEnhancer && "mr-1",
            )}
          >
            {children}
          </div>
        )}
      </div>
      {/* Patrick (2025-01-23):
          We're cloning the startEnhancer here in order to pass the baseEnhancerClass to it as a default.
          We force the enhancer to be a reactElement.

          If this gets inefficient in the future, we can memoize the endEnhancer
        */}
      {endEnhancer &&
        React.cloneElement(endEnhancer, {
          className: twMerge(baseEnhancerClass, endEnhancer.props.className),
        })}
    </div>
  );

  const sharedProps = {
    className: baseClass,
    style: props.style,
    disabled,
    "aria-disabled": disabled || undefined,
    onClick,
  };

  if (to) {
    const linkProps = {
      to,
      target,
      rel: rel || (target === "_blank" ? "noreferrer" : undefined),
      replace,
      state,
      preventScrollReset,
      relative,
      // Patrick (12-19-2024): The reason we have this onClick here is because we can't disable a <Link> so if it's disabled we need the onClick to not work
      onClick: (event: any) => {
        if (disabled) {
          event.preventDefault();
        } else if (onClick) {
          onClick(event);
        }
      },
    };
    return (
      <Tooltip
        content={tooltipText}
        collisionPadding={collisionPadding}
        triggerAsChild
        sideOffset={TOOLTIP_SIDE_OFFSET}
      >
        <Link
          {...sharedProps}
          {...linkProps}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          {content}
        </Link>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      content={tooltipText}
      collisionPadding={collisionPadding}
      triggerAsChild
      sideOffset={TOOLTIP_SIDE_OFFSET}
    >
      <button
        {...sharedProps}
        {...props}
        onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
        type={props.type || "button"}
        ref={ref as React.Ref<HTMLButtonElement>}
      >
        {content}
      </button>
    </Tooltip>
  );
});

const variantClassNames: Record<ButtonVariant, string> = {
  primary: "bg-primary hover:bg-primary-hover text-white",
  secondary: "bg-light-surface hover:bg-light-surface-hover",
  // Craig (2025-02-27): Removes highlight, padding, and dimensions from the button and adds underline
  // based on designs
  tertiary: "bg-transparent hover:underline p-0 h-[unset] w-[unset]",
  // Craig (2025-02-27): Removes highlight, padding, and dimensions from the button and adds underline
  // based on designs
  link: "bg-transparent text-primary hover:underline p-0 h-[unset] w-[unset]",
  danger: "bg-danger hover:bg-danger-hover text-white",
  // Craig (2025-02-27): Removes highlight, padding, and dimensions from the button and adds underline
  // based on designs
  dangerLink:
    "bg-transparent hover:underline text-danger p-0 h-[unset] w-[unset]",
  secondaryDanger: "text-danger bg-red-100 hover:bg-gray-200",
  inherit: "bg-inherit text-inherit m-0 p-0",
};

const isActiveVariantMap: Record<ButtonVariant, string> = {
  primary: "bg-primary-hover",
  secondary: "bg-light-surface-hover",
  tertiary: "underline",
  link: "underline",
  danger: "bg-danger-hover",
  dangerLink: "underline",
  secondaryDanger: "bg-danger-hover",
  inherit: "",
};

const spinnerVariantMap: Record<ButtonVariant, SpinnerVariants> = {
  primary: "white",
  secondary: "secondary",
  tertiary: "secondary",
  danger: "danger",
  link: "primary",
  dangerLink: "danger",
  secondaryDanger: "danger",
  inherit: "primary",
};

Button.displayName = "Button";

export default Button;
