import type { RemoveShopifyIntegrationModalProps } from "../AppModalTypes";

import * as React from "react";

import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import toast from "@replo/design-system/components/alert/Toast";
import Button from "@replo/design-system/components/button/Button";
import { Modal } from "@replo/design-system/components/modal/Modal";

export const RemoveShopifyIntegrationModal: React.FC<
  RemoveShopifyIntegrationModalProps
> = ({ projectId, integrationId }) => {
  const { closeModal } = useModal();
  const { mutate: disconnectIntegration, isPending: isLoading } =
    trpc.integration.disconnectIntegrationFromProject.useMutation({
      onSuccess: () => {
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        void trpcUtils.project.findByUserId.invalidate();
        void trpcUtils.project.listWithStats.invalidate();
        void trpcUtils.project.get.invalidate(projectId);
        toast({
          header: "Shopify Integration removed from Project",
        });
      },
      onSettled: () => {
        closeModal({ type: "removeShopifyIntegrationModal" });
      },
    });

  const handleConfirm = () => {
    disconnectIntegration({ projectId, integrationId });
  };

  return (
    <Modal
      title="Remove Shopify Integration"
      isOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          closeModal({ type: "removeShopifyIntegrationModal" });
        }
      }}
      size="base"
      footer={
        <div className="flex flex-row gap-2">
          <Button
            variant="secondary"
            size="base"
            onClick={() =>
              closeModal({ type: "removeShopifyIntegrationModal" })
            }
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            size="base"
            type="submit"
            isLoading={isLoading}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      }
    >
      <p className="typ-body-base text-default">
        Removing the Shopify integration from this project will disconnect all
        dynamic data, and you won't be able to publish new changes until you
        install the Shopify integration again.
      </p>
    </Modal>
  );
};
