import type { ComponentTemplate } from "@editor/types/component-template";
import type { ComponentTemplateIndustryId } from "schemas/componentTemplates";
import type { ResponsiveToggleAllowedDevices } from "../common/designSystem/ResponsiveToggle";

import React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import { getComponentTemplateIndustryName } from "replo-runtime/shared/componentTemplates";

import { ComponentTemplateSnapshot } from "../component-template/ComponentTemplateSnapshot";
import CopyTemplateLinkButton from "./CopyTemplateLinkButton";

interface TemplateCardProps {
  template: ComponentTemplate;
  setSubpageTemplate: (template: ComponentTemplate | null) => void;
  previewPlatform: ResponsiveToggleAllowedDevices;
}

const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  setSubpageTemplate,
  previewPlatform,
}) => {
  const analytics = useLogAnalytics();

  const handleClick = () => {
    analytics("editor.marketplace.details", {
      type: "componentTemplate",
      componentTemplateId: template.id,
      componentTemplateName: template.name,
    });

    setSubpageTemplate(template);
  };

  const industryName = template.industryId
    ? getComponentTemplateIndustryName(
        template.industryId as ComponentTemplateIndustryId,
      )
    : null;

  return (
    <button
      className="rounded-xl overflow-hidden p-3 flex flex-col gap-2 hover:bg-white group"
      onClick={handleClick}
      data-testid="template-card"
    >
      <div
        className={twMerge(
          "relative rounded-lg overflow-hidden border-0.5 border-border",
          previewPlatform === "desktop" ? "aspect-square" : "aspect-[9/14]",
        )}
      >
        <ComponentTemplateSnapshot
          componentTemplate={template}
          previewPlatform={previewPlatform}
        />

        <div className="absolute inset-0 bg-gradient-to-t to-black/20 from-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none"></div>

        <div className="absolute top-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <CopyTemplateLinkButton template={template} />
        </div>
      </div>

      <div className="relative flex justify-between">
        <div className="flex flex-col gap-1 items-start max-w-[60%] overflow-hidden">
          <Tooltip triggerAsChild content={template.name} delay={300}>
            <div className="typ-label-base truncate w-full">
              {template.name}
            </div>
          </Tooltip>
          <div className="typ-body-small text-muted min-h-4">
            {industryName}
          </div>
        </div>
        {template.logoUrl && (
          <img
            src={template.logoUrl}
            alt={`${template.name} logo`}
            className="max-h-full max-w-[40%] w-auto object-contain absolute right-0 top-0 bottom-0 my-auto"
          />
        )}
      </div>
    </button>
  );
};

export default TemplateCard;
