import type { ElementErrorType } from "@editor/utils/element";

import * as React from "react";

export type FormKey = "title" | "path" | "customHtml" | "customCss";

const ElementEditorErrorContext = React.createContext<{
  errorMapping: Record<FormKey, ElementErrorType[] | null>;
  setErrors: (key: FormKey, errors: ElementErrorType[] | null) => void;
  clearErrors: (
    key: FormKey,
    errorsToRemove: ElementErrorType[] | "allErrorsFromAllFormKeys",
  ) => void;
}>({
  errorMapping: {} as Record<FormKey, ElementErrorType[] | null>,
  setErrors: () => null,
  clearErrors: () => null,
});

export function useElementEditorErrorContext() {
  const ctx = React.useContext(ElementEditorErrorContext);
  if (!ctx) {
    throw new Error(
      "useElementEditorErrorContext must be used within a ElementEditorErrorContextProvider",
    );
  }
  return ctx;
}

const ElementEditorErrorContextProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [errorMapping, setErrorMapping] = React.useState<
    Record<FormKey, ElementErrorType[] | null>
  >({} as Record<FormKey, ElementErrorType[] | null>);

  const setErrors = React.useCallback(
    (key: FormKey, errors: ElementErrorType[] | null) => {
      setErrorMapping((mapping) => {
        const uniqueErrors = new Set([
          ...(mapping[key] ?? []),
          ...(errors ?? []),
        ]);
        return {
          ...mapping,
          [key]: Array.from(uniqueErrors),
        };
      });
    },
    [],
  );

  const clearErrors = React.useCallback(
    (
      key: FormKey,
      errorsToRemove: ElementErrorType[] | "allErrorsFromAllFormKeys",
    ) => {
      if (errorsToRemove === "allErrorsFromAllFormKeys") {
        return setErrorMapping(
          {} as Record<FormKey, ElementErrorType[] | null>,
        );
      }
      setErrorMapping((mapping) => ({
        ...mapping,
        [key]:
          mapping[key]?.filter((error) => !errorsToRemove.includes(error)) ??
          null,
      }));
    },
    [],
  );

  return (
    <ElementEditorErrorContext.Provider
      value={{ errorMapping, setErrors, clearErrors }}
    >
      {children}
    </ElementEditorErrorContext.Provider>
  );
};

export const withElementEditorErrorContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> => {
  const Component = (props: P) => (
    <ElementEditorErrorContextProvider>
      <WrappedComponent {...props} />
    </ElementEditorErrorContextProvider>
  );
  Component.displayName =
    WrappedComponent.displayName ?? "ElementEditorErrorContext";
  return Component;
};
