import type { ComponentTemplate } from "@editor/types/component-template";

import React, { useState } from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { getConfig } from "@/config";
import { toast } from "@replo/design-system/components/alert/Toast";
import IconButton from "@replo/design-system/components/button/IconButton";
import twMerge from "@replo/design-system/utils/twMerge";
import { Check, Link } from "lucide-react";

interface CopyTemplateLinkButtonProps {
  template: ComponentTemplate;
}

const CopyTemplateLinkButton = ({ template }: CopyTemplateLinkButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const analytics = useLogAnalytics();

  const handleCopyTemplateLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCopied(true);
    const url = getConfig("dashboardUrl") ?? "https://dashboard.replo.app/";
    const baseUrl = `${url}/home/template/${template.id}`;
    void navigator.clipboard.writeText(baseUrl);

    analytics("editor.componentTemplate.share", {
      shareUrl: baseUrl,
      componentTemplateId: template.id,
      componentTemplateName: template.name,
      from: "componentTemplateCard",
    });

    toast({
      header: "Template Link Copied",
    });

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <IconButton
      variant="secondary"
      tooltipText="Copy URL"
      size="base"
      onClick={handleCopyTemplateLink}
      icon={
        isCopied ? (
          <Check size={20} className="text-white" />
        ) : (
          <Link size={20} className="text-muted" />
        )
      }
      UNSAFE_className={twMerge(isCopied && "bg-blue-600 hover:bg-blue-600")}
    />
  );
};

export default CopyTemplateLinkButton;
