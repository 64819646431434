import * as React from "react";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import twMerge from "@replo/design-system/utils/twMerge";
import { PageTypeEnum } from "schemas/analyticsRead";

const PageTypeWithDescription: React.FC<{
  title: string;
  description: string;
  isSelected: boolean;
  className?: string;
}> = ({ title, description, isSelected, className }) => {
  return (
    <div
      className={twMerge(
        className,
        "flex flex-col rounded p-1.5 w-full",
        isSelected && "bg-selectable-selected",
        !isSelected && "hover:bg-selectable-hover",
      )}
    >
      <p className="typ-header-small text-default">{title}</p>
      <p className="typ-body-small text-muted">{description}</p>
    </div>
  );
};

const PageTypeSelect: React.FC = () => {
  const { query, dispatchAnalyticsQuery } = useAnalyticsQueryContext();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenChange = () => {
    setIsOpen(!isOpen);
  };

  const options = [
    {
      displayValue: "All Viewed Pages",
      label: (
        <PageTypeWithDescription
          title="All Viewed Pages"
          description="Data for any page visited within the session"
          isSelected={query.filters?.pageType === PageTypeEnum.ALL_PAGES}
        />
      ),
      value: PageTypeEnum.ALL_PAGES,
      isDisabled: false,
    },
    {
      displayValue: "Entry Pages",
      label: (
        <PageTypeWithDescription
          title="Entry Pages"
          description="Shows metrics for pages that began the session"
          isSelected={query.filters?.pageType === PageTypeEnum.ENTRY_PAGES}
        />
      ),
      value: PageTypeEnum.ENTRY_PAGES,
      isDisabled: false,
    },
  ];
  return (
    <Combobox.Root
      options={options}
      open={isOpen}
      onOpenChange={handleOpenChange}
      value={query.filters?.pageType ?? PageTypeEnum.ALL_PAGES}
      onChange={(value) => {
        dispatchAnalyticsQuery({
          type: "filters.pageType",
          payload: value as PageTypeEnum,
        });
      }}
    >
      <Combobox.Trigger>
        <Combobox.SelectionButton
          size="base"
          title={
            options.find((option) => option.value === query.filters?.pageType)
              ?.displayValue ?? ""
          }
          isPlaceholder={!query.filters?.pageType}
        />
      </Combobox.Trigger>
      <Combobox.Popover side="bottom" align="end" layoutClassName="w-[300px]">
        <Combobox.Content size="lg" />
      </Combobox.Popover>
    </Combobox.Root>
  );
};

export default PageTypeSelect;
