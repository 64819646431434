import { dequal as dequalLite } from "dequal/lite";
import isNumber from "lodash-es/isNumber";
import isObject from "lodash-es/isObject";
import isString from "lodash-es/isString";

interface ForEachPrimitiveValueArgs {
  target: Record<string, any> | unknown[] | null;
  // Note (Chance 2023-07-14) Consider improving types and update `any`s ... but
  // maybe this one isn't worth it since it'll be ridiculously overloaded
  fn: (value: unknown, key: string, newTarget: Record<string, any>) => void;
  valueConversionFunction?: (
    transformValue: Record<string, any> | unknown[],
  ) => Record<string, any> | unknown[];
}
/** There's probably a bug somewhere here since it assumes target is initially an object */
export function forEachPrimitiveValue({
  target,
  fn,
  valueConversionFunction,
}: ForEachPrimitiveValueArgs) {
  if (!target) {
    return;
  }

  for (const [key, value] of Object.entries(target)) {
    if (isObject(value)) {
      const newTarget = valueConversionFunction
        ? valueConversionFunction(value)
        : value;
      forEachPrimitiveValue({ target: newTarget, fn });
    }
    if (isString(value) || isNumber(value)) {
      fn(value, key, target);
    }
  }
}

// https://github.com/facebook/react/blob/main/packages/shared/shallowEqual.js
export function shallowEqual(objA: unknown, objB: unknown) {
  if (Object.is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    const currentKey = keysA[i]!;
    if (
      !Object.hasOwnProperty.call(objB, currentKey) ||
      // @ts-ignore: lost refinement of `objB`
      !Object.is(objA[currentKey], objB[currentKey])
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Internally we use `dequal` for deep equality checks. It benchmarks faster
 * than lodash and most other libraries.
 *
 * This function is an alias for `dequal`'s "lite" module. It does not check
 * equality for `Map` or `Set` values. If you need to compare those, use
 * `deepEqualHeavy`.
 *
 * @see https://github.com/lukeed/dequal#modes
 */
export const deepEqual = dequalLite;
