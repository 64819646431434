import * as React from "react";

import { formatDistanceToNow } from "date-fns";

const getTimeString = (date: Date | undefined | null) => {
  if (!date) {
    return "";
  }

  const diffInMs = Date.now() - date.getTime();
  const diffInMin = diffInMs / (1000 * 60);

  return diffInMin < 1
    ? "just now"
    : formatDistanceToNow(date, {
        addSuffix: true,
      }).replace("about", "");
};

export const useEditedTimeString = (
  lastEditedAt: Date | undefined | null,
  createdAt: Date | undefined,
) => {
  const [editedTimeString, setEditedTimeString] = React.useState(() => {
    const date = lastEditedAt ?? createdAt;
    return getTimeString(date);
  });

  React.useEffect(() => {
    const updateTime = () => {
      const date = lastEditedAt ?? createdAt;
      setEditedTimeString(getTimeString(date));
    };

    updateTime();
    const interval = setInterval(updateTime, 60_000);

    return () => clearInterval(interval);
  }, [lastEditedAt, createdAt]);

  return editedTimeString;
};
