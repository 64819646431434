import type { MenuItem } from "@replo/design-system/components/menu/Menu";
import type { ReploSimpleProject } from "schemas/generated/project";

import * as React from "react";

import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useModal } from "@editor/hooks/useModal";

import IconButton from "@replo/design-system/components/button/IconButton";
import { Menu, MenuTrigger } from "@replo/design-system/components/menu/Menu";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { filterNulls } from "replo-utils/lib/array";

type ProjectMenuProps = {
  project: ReploSimpleProject;
  isVertical?: boolean;
  onRenameOpen: () => void;
  allowsRenaming: boolean;
};

const ProjectMenu: React.FC<ProjectMenuProps> = ({
  project,
  isVertical = false,
  onRenameOpen,
  allowsRenaming,
}) => {
  const { openModal } = useModal();
  const isWorkspaceOwner = useIsWorkspaceOwner(project?.ownerWorkspace?.id);
  const shopifyData = project.integrations?.shopify?.store;

  const selectableItems: MenuItem[] = isWorkspaceOwner
    ? filterNulls([
        allowsRenaming
          ? {
              type: "leaf",
              id: "renameProject",
              variant: "default",
              size: "sm",
              title: "Rename",
              onSelect: onRenameOpen,
            }
          : null,
        {
          type: "leaf",
          id: "moveProject",
          variant: "default",
          size: "sm",
          title: "Move",
          onSelect: () => {
            if (project.ownerWorkspace?.id) {
              openModal({
                type: "moveProjectModal",
                props: {
                  projectId: project.id,
                  oldWorkspaceId: project?.ownerWorkspace?.id,
                  shopifyUrl: project.integrations?.shopify?.store.shopifyUrl,
                },
              });
            }
          },
        },
        shopifyData?.integrationId
          ? {
              type: "leaf",
              id: "removeShopifyIntegration",
              variant: "default",
              size: "sm",
              title: "Remove Shopify",
              onSelect: () =>
                openModal({
                  type: "removeShopifyIntegrationModal",
                  props: {
                    projectId: project.id,
                    integrationId: shopifyData.integrationId!,
                  },
                }),
            }
          : null,
        {
          type: "leaf",
          id: "deleteProject",
          variant: "default",
          size: "sm",
          title: "Delete",
          onSelect: () =>
            openModal({
              type: "deleteProjectModal",
              props: {
                projectId: project.id,
                from: "list",
              },
            }),
        },
      ])
    : filterNulls([
        allowsRenaming
          ? {
              type: "leaf",
              id: "renameProject",
              variant: "default",
              size: "sm",
              title: "Rename",
              onSelect: onRenameOpen,
            }
          : null,
      ]);

  if (selectableItems.length === 0) {
    return null;
  }

  return (
    <Menu
      align="end"
      size="sm"
      items={selectableItems}
      trigger={
        <MenuTrigger tooltipText="More options" asChild>
          <IconButton
            variant="tertiary"
            size="sm"
            icon={
              isVertical ? (
                <BsThreeDotsVertical size={16} className="text-subtle" />
              ) : (
                <BsThreeDots size={16} className="text-subtle" />
              )
            }
            aria-hidden
          >
            <span className="sr-only">More options</span>
          </IconButton>
        </MenuTrigger>
      }
    />
  );
};

export default ProjectMenu;
