import type { SetCandidateNodePayload } from "@editor/reducers/candidate-reducer";
import type { CanvasOffset } from "@providers/DragAndDropProvider";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import * as React from "react";

import { HEADER_HEIGHT } from "@components/editor/constants";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import {
  selectCandidateNode,
  selectComponentIdToDrag,
  selectComponentNodeToDrag,
  selectLastCandidateComponentId,
  selectLastCandidateRepeatedIndex,
  setCandidateNode as setCandidateNodeAction,
} from "@editor/reducers/candidate-reducer";
import {
  selectDraftComponentIds,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftRepeatedIndex,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { closestElementToPoint } from "@editor/utils/dom";
import useDragAndDrop from "@providers/DragAndDropProvider";

import { OFFSET_LIMIT, REPLO_COMPONENT_SELECTOR } from "./canvas-constants";
import {
  selectCanvasDeltaXY,
  selectCanvasInteractionMode,
  selectCanvasScale,
  selectVisibleCanvases,
} from "./canvas-reducer";

function firstReploElementFromPoint(x: number, y: number): HTMLElement | null {
  // NOTE (Matt 2024-12-02): The reason we do this instead of using
  // elementFromPoint is because the BoundingBoxes are now in the same
  // rendering context as the replo components, so elementFromPoint would
  // just always spit back a BoundingBox.
  const elements: HTMLElement[] = window.document.elementsFromPoint(
    x,
    y,
  ) as HTMLElement[];
  return elements.find((element) => element.dataset.rid)!;
}

export const useSetCandidateNodeFromPoint = () => {
  const { currentDragType } = useCurrentDragType();
  const { setOffset } = useDragAndDrop();
  const dispatch = useEditorDispatch();
  const store = useEditorStore();

  const setCandidateNode = React.useCallback(
    (
      newCandidateNode: HTMLElement | null,
      candidateCanvas: EditorCanvas | null,
    ) => {
      const lastCandidateComponentId = selectLastCandidateComponentId(
        store.getState(),
      );
      const lastCandidateRepeatedIndex = selectLastCandidateRepeatedIndex(
        store.getState(),
      );
      const { deltaX, deltaY } = selectCanvasDeltaXY(store.getState());
      const canvasInteractionMode = selectCanvasInteractionMode(
        store.getState(),
      );
      const isDragging = canvasInteractionMode === "dragging-components";
      const canvasScale = selectCanvasScale(store.getState());
      if (newCandidateNode === null) {
        const payload: SetCandidateNodePayload = {
          candidateNode: null,
          lastCandidateComponentId: null,
          lastCandidateRepeatedIndex: null,
          candidateCanvas: null,
        };
        if (!isDragging) {
          payload.componentIdToDrag = null;
          payload.componentNodeToDrag = null;
        }
        dispatch(setCandidateNodeAction(payload));
      } else {
        const componentId = newCandidateNode?.dataset.rid ?? null;
        const repeatedIndex = newCandidateNode?.getAttribute(
          "data-replo-repeated-index",
        );

        // Only update the candidate node if it's different from the last one.
        if (
          componentId === lastCandidateComponentId &&
          repeatedIndex === lastCandidateRepeatedIndex
        ) {
          return;
        }

        setOffset?.((offset: CanvasOffset | null) => ({
          x: deltaX,
          y: deltaY + HEADER_HEIGHT,
          scale: canvasScale,
          canvasHeight: offset?.canvasHeight || 0,
          canvasWidth: offset?.canvasHeight || 0,
        }));

        const payload: SetCandidateNodePayload = {
          candidateNode: newCandidateNode,
          candidateCanvas,
          lastCandidateComponentId: componentId,
          lastCandidateRepeatedIndex: repeatedIndex,
        };
        if (!isDragging) {
          payload.componentIdToDrag = componentId;
          payload.componentNodeToDrag = newCandidateNode;
        }
        dispatch(setCandidateNodeAction(payload));
      }
    },
    [dispatch, setOffset, store],
  );

  const setCandidateNodeFromPoint = React.useCallback(
    (clientX: number, clientY: number) => {
      const canvasInteractionMode = selectCanvasInteractionMode(
        store.getState(),
      );
      const visibleCanvases = selectVisibleCanvases(store.getState());
      const isDragging = canvasInteractionMode === "dragging-components";
      const targetIframe = closestElementToPoint(
        Array.from(document.querySelectorAll("[data-canvas-id]")).filter(
          (iframe) => {
            // Note (Noah, 2024-07-08): We only want to consider iframes that apply
            // to currently visible canvases. This is because even if a canvas is
            // hidden, its iframe might still exist.
            return Object.keys(visibleCanvases).includes(
              (iframe as HTMLElement).dataset.canvasId as EditorCanvas,
            );
          },
        ) as HTMLElement[],
        clientX,
        clientY,
      );
      if (!targetIframe) {
        return;
      }

      let frameNodeAtPoint = firstReploElementFromPoint(clientX, clientY);

      if (frameNodeAtPoint?.closest(".ignore-frame-cursor")) {
        return;
      }

      const candidateCanvas = targetIframe.dataset.canvasId;

      // Note (Noah, 2023-10-01): Whether the user is dragging something
      // (a component, template, etc). Used since we calculate the target
      // element slightly differently, to make it easier to drag things
      // in certain situations like dragging components onto the very edges
      // of nested containers, etc
      const isDraggingSomething = isDragging || Boolean(currentDragType);

      // NOTE (Matt 2025-01-27): If the user is dragging something, we allow a margin around the canvas
      // for the user to drag an element to (ie if you drag a component <100px above the top of the canvas, we'll drop
      // that component at the top of the canvas). This next bit allows us to detect and reassign frameNodeAtPoint
      // when this is happening.
      if (!frameNodeAtPoint && isDraggingSomething) {
        // NOTE (Matt 2025-01-27): If there is no `frameNodeAtPoint` and we are dragging something, check if
        // the user is within the offset limit in any direction. If so, reassign the X/Y arg of firstReploElementFromPoint
        // as the corresponding boundary of the canvas.
        const frameBounds = targetIframe.getBoundingClientRect();
        let targetY = clientY;
        let targetX = clientX;
        if (
          clientY < frameBounds.top &&
          clientY > frameBounds.top - OFFSET_LIMIT
        ) {
          targetY = frameBounds.top;
        }
        if (
          clientY > frameBounds.bottom &&
          clientY < frameBounds.bottom + OFFSET_LIMIT
        ) {
          targetY = frameBounds.bottom;
        }
        if (
          clientX < frameBounds.left &&
          clientX > frameBounds.left - OFFSET_LIMIT
        ) {
          targetX = frameBounds.left;
        }
        if (
          clientX > frameBounds.right &&
          clientX < frameBounds.right + OFFSET_LIMIT
        ) {
          targetX = frameBounds.right;
        }
        if (targetX !== clientX || targetY !== clientY) {
          frameNodeAtPoint = firstReploElementFromPoint(targetX, targetY);
        }
      }
      let closestComponent: HTMLElement | null = null;
      if (frameNodeAtPoint && currentDragType === "section") {
        // Note (Ben O., 2025-03-04): Sections can only be dropped above or below other sections so
        // we only allow direct children of the draftElement component to be used as the candidate node.
        const canvasElement = document.querySelector(
          `[data-canvas-id="${candidateCanvas}"]`,
        ) as HTMLElement;
        if (!canvasElement) {
          return null;
        }

        const draftElement =
          selectDraftElement_warningThisWillRerenderOnEveryUpdate(
            store.getState(),
          );
        if (!draftElement) {
          return null;
        }

        closestComponent =
          canvasElement.querySelector(
            `[data-rid="${draftElement.component.id}"]`,
          ) ?? null;
      } else {
        closestComponent = frameNodeAtPoint?.closest(
          REPLO_COMPONENT_SELECTOR,
        ) as HTMLElement | null;
      }
      setCandidateNode(
        closestComponent,
        candidateCanvas as EditorCanvas | null,
      );
      return closestComponent;
    },
    [currentDragType, setCandidateNode, store],
  );
  return {
    setCandidateNodeFromPoint,
    setCandidateNode,
  };
};

export function useCandidateNode() {
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const draftComponentRepeatedIndex = useEditorSelector(
    selectDraftRepeatedIndex,
  );
  const candidateNode = useEditorSelector(selectCandidateNode);
  const componentIdToDrag = useEditorSelector(selectComponentIdToDrag);
  const componentNodeToDrag = useEditorSelector(selectComponentNodeToDrag);
  const lastCandidateRepeatedIndex = useEditorSelector(
    selectLastCandidateRepeatedIndex,
  );

  const { setCandidateNodeFromPoint } = useSetCandidateNodeFromPoint();

  // Note (Noah, 2023-06-18): Show the candidate box for non-draft components if
  // the user hovers over them. This box allows the user to start a drag of a
  // non-draft component. We don't show this box for draft components because
  // <DraftBox /> renders one internally.
  // NOTE (Martin 2024-11-25): Since we are using draft components for multi-selection
  // now, we only want to apply conditional logic to show candidate boxes if there's
  // a single component being selected.
  let isCandidateBoxVisible = true;
  if (draftComponentIds.length === 1) {
    isCandidateBoxVisible = componentIdToDrag
      ? !draftComponentIds.includes(componentIdToDrag)
      : false;
  }

  const shouldShowCandidateBox =
    (componentNodeToDrag && isCandidateBoxVisible) ||
    (lastCandidateRepeatedIndex &&
      draftComponentRepeatedIndex &&
      lastCandidateRepeatedIndex !== draftComponentRepeatedIndex);

  return {
    candidateNode,
    setCandidateNodeFromPoint,
    shouldShowCandidateBox,
  };
}
