import type { ComponentTemplate } from "@editor/types/component-template";

export const postscriptSignupForm: ComponentTemplate = {
  id: "1e149e2b-2076-443d-ba2a-1e584a850f02",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Postscript Signup Form",
  leftBarCategory: { main: "productIntegrations", subCategory: "other" },
  preview: "integrations/postscript-signup-form.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "postscriptSignupForm",
    props: {},
  },
};
