import * as React from "react";
import { forwardRef } from "react";

import DynamicData from "@svg/dynamic-data";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";

type DynamicDataButtonProps = {
  // NOTE (Jackson, 2025-03-13): Making this optional for now since we don't need it
  // for the new dynamic data UI but still want this UI element
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tooltipText?: string;
} & React.ComponentPropsWithoutRef<"button">;

const DynamicDataButton = forwardRef<HTMLButtonElement, DynamicDataButtonProps>(
  ({ onClick, tooltipText = "Add Dynamic Data", ...props }, ref) => {
    return (
      <Tooltip content={tooltipText} triggerAsChild>
        <button
          // Note (Noah, 2023-07-19, USE-306): Always use type="button", otherwise
          // if this button's inside a form, clicking on it will submit the form
          // (this is the default behavior in html if you don't specify `type`)
          {...props}
          ref={ref}
          type="button"
          aria-label={tooltipText}
          className="flex h-6 w-6 cursor-pointer items-center justify-center gap-1 rounded bg-subtle shrink-0"
          onClick={onClick ?? undefined}
        >
          <DynamicData />
        </button>
      </Tooltip>
    );
  },
);

DynamicDataButton.displayName = "DynamicDataButton";

export default DynamicDataButton;
