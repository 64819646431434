import type { ReploPartialElement } from "schemas/generated/element";

import * as React from "react";

export const ElementEditorDataContext = React.createContext<{
  element: ReploPartialElement;
  onChangeElement: (element: React.SetStateAction<ReploPartialElement>) => void;
}>({
  element: {
    name: "",
    projectId: "",
    type: "page",
  },
  onChangeElement: () => null,
});

export function useElementEditorDataContext() {
  const ctx = React.useContext(ElementEditorDataContext);
  if (!ctx) {
    throw new Error(
      "useElementEditorDataContext must be used within a ElementEditorDataContextProvider",
    );
  }
  return ctx;
}
