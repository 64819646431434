import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const JUNIP_LIQUID_SOURCE = `
  <span
    class="junip-product-review"
    data-product-id="{{ product.id }}">
  </span>
`;

const JunipReviews: React.FC<RenderComponentProps> = (props) => {
  const product = useProductFromProps(props.component.props, props.context);

  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: JUNIP_LIQUID_SOURCE,
      context: props.context,
    },
  );

  return (
    <SharedShopifyLiquid
      componentAttributes={props.componentAttributes}
      component={props.component}
      componentId={props.component.id}
      liquidSource={liquidSourceWithProductAssignment}
      title="Junip Product Reviews"
      placeholderType="reviewFull"
      repeatedIndexPath={props.context.repeatedIndexPath}
      isLiquidSupported
    />
  );
};

export default JunipReviews;
