import type { ComponentTemplate } from "@editor/types/component-template";

import { getCanBeAddedFunction } from "@editor/utils/template";

export const optionSelect: ComponentTemplate = {
  id: "e4f8c985-0556-4a47-9524-8cc6810652d8",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Option List",
  leftBarCategory: { main: "product", subCategory: "options" },
  tags: ["product", "product option list"],
  preview: "product/list-basic.svg",
  template: {
    id: "bbd81ca8-e2ae-4406-b95e-8c36f4e76611",
    name: "Option List",
    type: "optionSelect",
    props: {
      style: { display: "flex", __flexGap: "12px", flexDirection: "row" },
    },
    children: [
      {
        id: "ffbe05b8-acfa-4102-9862-ea61086003a4",
        name: "Container",
        type: "container",
        props: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingTop: "4px",
            paddingLeft: "8px",
            paddingRight: "8px",
            flexDirection: "column",
            paddingBottom: "4px",
            justifyContent: "center",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            __animateVariantTransitions: true,
          },
        },
        children: [
          {
            id: "ffe0b750-afed-41e5-bcac-5d495e3b1345",
            type: "text",
            props: {
              text: "<p>{{attributes._currentOptionValue}}</p>",
              style: { fontSize: "16px", fontWeight: 300, lineHeight: "24px" },
            },
          },
        ],
        variants: [
          {
            id: "9fde2ab3-6846-4490-956f-888a586568da",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "2602d1dd-a8eb-443e-86a4-0f4890fd8aa4",
            name: "Selected",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                  field: "state.product.selectedOptionValues",
                },
              ],
            },
          },
        ],
        variantOverrides: {
          "2602d1dd-a8eb-443e-86a4-0f4890fd8aa4": {
            componentOverrides: {
              "ffbe05b8-acfa-4102-9862-ea61086003a4": {
                props: {
                  style: {
                    backgroundColor: "#000000",
                  },
                },
              },
              "ffe0b750-afed-41e5-bcac-5d495e3b1345": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};

export const optionSelectDropdown: ComponentTemplate = {
  id: "d41688c8-b7cf-4f37-9755-83a68aa5798a",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Option Dropdown",
  leftBarCategory: { main: "product", subCategory: "options" },
  tags: ["product", "product option dropdown"],
  preview: "product/option-dropdown.svg",
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Option Dropdowns can only be added inside Product boxes.",
  ),
  template: {
    id: "a9a99f91-e42a-4db9-aae3-192bc74896ce",
    name: "Option Dropdown",
    type: "container",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        __flexGap: "24px",
        alignItems: "center",
        justifyContent: "flex-start",
        alignSelf: "auto",
      },
    },
    children: [
      {
        id: "8b5df21a-b6a0-45a7-b17a-29639e764e35",
        name: "Dropdown",
        type: "optionSelectDropdown",
        props: {
          style: {
            zIndex: "2",
            fontSize: "16px",
            fontWeight: 700,
            paddingTop: "12px",
            borderColor: "rgb(0, 0, 0)",
            borderWidth: "1px",
            paddingLeft: "12px",
            paddingRight: "24px",
            paddingBottom: "12px",
            borderTopStyle: "solid",
            borderLeftStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          _hideDefaultArrowDisplay: true,
        },
      },
      {
        id: "489e66c4-647c-4044-9923-ed32173a19a2",
        type: "icon",
        props: {
          style: {
            top: "50%",
            right: "2px",
            width: "24px",
            height: "24px",
            zIndex: 1,
            position: "absolute",
            __transform: {
              skewX: "0deg",
              skewY: "0deg",
              scaleX: "100%",
              scaleY: "100%",
              scaleZ: "100%",
              rotateX: "0deg",
              rotateY: "0deg",
              rotateZ: "0deg",
              translateX: "0px",
              translateY: "-50%",
              translateZ: "0px",
            },
          },
          iconName: "chevron-down",
        },
      },
    ],
  },
};

export const optionSelectWithRadio: ComponentTemplate = {
  id: "d41688c8-b7cf-4f37-9755-83a68aa5798h",
  storeId: null,

  scope: "left-bar",
  type: "component",
  name: "Product/Option List with Radio",
  leftBarCategory: { main: "product", subCategory: "options" },
  tags: ["product", "product option radio", "radio"],
  preview: "product/list-radio.svg",
  template: {
    id: "2e326a78-9bb3-4e4f-8549-a175ba43fc25",
    name: "Option List with Radio",
    type: "optionSelect",
    props: {
      style: {
        display: "flex",
        __flexGap: "15px",
        alignSelf: "stretch",
        flexDirection: "column",
      },
    },
    children: [
      {
        id: "c3a90fdc-f098-4151-a85d-afbf30ec6ac9",
        name: "Option Container",
        type: "container",
        props: {
          style: {
            cursor: "pointer",
            display: "flex",
            __flexGap: "15px",
            alignItems: "center",
            paddingTop: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            justifyContent: "flex-start",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            __animateVariantTransitions: true,
          },
          onClick: [],
        },
        children: [
          {
            id: "9b89ae93-bae4-4335-a1f5-66dd687b5d88",
            name: "Option Radio",
            type: "container",
            props: {
              style: {
                width: "22px",
                height: "22px",
                __width: "22px",
                display: "flex",
                __height: "22px",
                alignItems: "center",
                borderTopColor: "#000000",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
                justifyContent: "center",
                borderLeftColor: "#000000",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
                borderRightColor: "#000000",
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderBottomColor: "#000000",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderTopLeftRadius: "50%",
                borderTopRightRadius: "50%",
                borderBottomLeftRadius: "50%",
                borderBottomRightRadius: "50%",
              },
              _accessibilityRole: "radio",
              _accessibilityChecked: false,
            },
            children: [
              {
                id: "d19add49-9a7f-44c5-90bd-ffb319619a4b",
                name: "Option Radio Indicator",
                type: "container",
                props: {
                  style: {
                    width: "10px",
                    height: "10px",
                    __width: "10px",
                    display: "flex",
                    opacity: "0%",
                    __height: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#000000FF",
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                  },
                },
                children: [],
              },
            ],
            variants: [
              {
                id: "7df1d806-7b31-4fcf-bb13-8813682566c2",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "9685a630-b7bf-4bbd-ba70-4ddda107cff7",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "e4a4b396-827e-4cc7-a4dd-cef3bcd9a212",
                      field: "state.product.selectedOptionValues",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "9685a630-b7bf-4bbd-ba70-4ddda107cff7": {
                componentOverrides: {
                  "9b89ae93-bae4-4335-a1f5-66dd687b5d88": {
                    props: {
                      style: {
                        width: "20px",
                        height: "20px",
                        __width: "20px",
                        display: "flex",
                        __height: "20px",
                        flexGrow: "unset",
                        alignSelf: "auto",
                        marginTop: "0px",
                        borderColor: null,
                        borderStyle: null,
                        borderWidth: null,
                        flexDirection: "column",
                        borderTopColor: "#FFFFFFFF",
                        borderTopStyle: "solid",
                        borderTopWidth: "1px",
                        borderLeftColor: "#FFFFFFFF",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1px",
                        borderRightColor: "#FFFFFFFF",
                        borderRightStyle: "solid",
                        borderRightWidth: "1px",
                        borderBottomColor: "#FFFFFFFF",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                      },
                    },
                  },
                  "d19add49-9a7f-44c5-90bd-ffb319619a4b": {
                    props: {
                      style: {
                        width: "15px",
                        height: "15px",
                        __width: "15px",
                        opacity: "100%",
                        __height: "15px",
                        flexGrow: "unset",
                        backgroundColor: "#FFFFFFFF",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "7dae0c7e-eaeb-4fe3-ae69-629e8e5c00a0",
            name: "Option Information",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: 1,
                maxWidth: "100%",
                alignSelf: "stretch",
                alignItems: "center",
                justifyContent: "space-between",
              },
            },
            children: [
              {
                id: "15efca30-58c3-494e-9c7a-72b2bf4c4d90",
                name: "Option Title",
                type: "text",
                props: {
                  text: "{{attributes._currentOption.title}}",
                  style: {
                    fontSize: "16px",
                    fontWeight: 300,
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
        ],
        variants: [
          {
            id: "75f1783f-ba5c-4655-82df-3f84d4eff66d",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "1326b99c-225f-41d5-84c1-550b028b52d2",
            name: "Selected",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                  field: "state.product.selectedOptionValues",
                },
              ],
            },
          },
        ],
        variantOverrides: {
          "1326b99c-225f-41d5-84c1-550b028b52d2": {
            componentOverrides: {
              "0e97acae-2fcf-4dd7-b2af-d839b51c4777": {
                props: {
                  style: {
                    color: null,
                    marginLeft: "0px",
                  },
                },
              },
              "15efca30-58c3-494e-9c7a-72b2bf4c4d90": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
              "4d8e483e-45f7-49a2-b72e-364721a76549": {
                props: {
                  style: {
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    justifyContent: "center",
                    backgroundColor: "#00A872",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  },
                },
              },
              "c3a90fdc-f098-4151-a85d-afbf30ec6ac9": {
                props: {
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#000000FF",
                  },
                  onClick: [],
                },
              },
              "d8949bdb-76cd-4720-b30a-cfbf7a9df1e6": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                    fontSize: "16px",
                    lineHeight: "24px",
                  },
                },
              },
              "da175ed5-1761-414e-80e5-d877bfd6e05e": {
                props: {
                  style: {
                    color: "#FFFFFFFF",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};
