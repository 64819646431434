import type { FormKey } from "@editor/contexts/ElementEditorErrorContext";
import type { ElementErrorType } from "@editor/utils/element";
import type {
  TabsContentOption,
  TabsTriggerOption,
} from "@replo/design-system/components/tabs/Tabs";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Separator from "@editor/components/common/designSystem/Separator";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import { useShopifyBlogs } from "@editor/hooks/element";
import { useInitial } from "@editor/hooks/useInitial";
import useOnChangeMetafields from "@editor/hooks/useOnChangeMetafields";
import { selectIsShopifyIntegrationEnabled } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import validatePageModalPath from "@editor/utils/validatePageModalPath";

import { Combobox } from "@replo/design-system/components/combobox/Combobox";
import SwitchWithDescription from "@replo/design-system/components/switch/SwitchWithDescription";
import { Tabs } from "@replo/design-system/components/tabs/Tabs";
import { slugify } from "replo-utils/lib/string";

import ImageSourceSelector from "../page/element-editor/components/modifiers/ImageSourceSelector";
import ElementCustomCssEditor from "./ElementCustomCssEditor";
import { ElementEditorErrors } from "./ElementEditorErrors";
import ElementNameEditor from "./ElementNameEditor";
import SeoElementEditor from "./SeoElementEditor";
import { ShopifyThemeSettings } from "./ShopifyThemeSettings";

type ArticleElementEditorTab = "general" | "shopifyTheme" | "advanced";

const ARTICLE_ELEMENT_EDITOR_TABS: TabsTriggerOption[] = [
  { value: "general", label: "General" },
  { value: "shopifyTheme", label: "Shopify Theme" },
  { value: "advanced", label: "Advanced" },
];

const ArticleElementEditor: React.FC<{}> = () => {
  const [selectedTab, setSelectedTab] =
    React.useState<ArticleElementEditorTab>("general");

  const ARTICLE_ELEMENT_EDITOR_CONTENT: TabsContentOption[] = [
    { value: "general", tabContent: <ArticleBasicSettings /> },
    { value: "shopifyTheme", tabContent: <ShopifyThemeSettings /> },
    {
      value: "advanced",
      tabContent: <ElementCustomCssEditor />,
    },
  ];
  return (
    <div className="flex h-fit flex-col">
      <Tabs
        tabsOptions={ARTICLE_ELEMENT_EDITOR_TABS}
        contentOptions={ARTICLE_ELEMENT_EDITOR_CONTENT}
        value={selectedTab}
        onValueChange={(value) => {
          setSelectedTab(value as ArticleElementEditorTab);
        }}
        size="sm"
        defaultValue={selectedTab}
      />
    </div>
  );
};

const ArticleBasicSettings: React.FC<{}> = () => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const { errorMapping, setErrors, clearErrors } =
    useElementEditorErrorContext();

  const initialElementIsPublished = useInitial(element.isPublished);
  const { metafields, onMetafieldChange } = useOnChangeMetafields(
    element,
    onChangeElement,
  );

  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const { shopifyBlogs } = useShopifyBlogs("shopifyArticle");

  const handleNameChange = (name: string) => {
    const shopifyPagePath = !element?.shopifyPagePath
      ? slugify(name)
      : element.shopifyPagePath;
    if (errorMapping["path"]?.includes("pathAlreadyExists")) {
      clearErrors("path", ["pathAlreadyExists"]);
    }
    onChangeElement({
      ...element,
      name,
      shopifyPagePath,
    });
  };

  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const shopifyPagePath = e.target.value;

    if (shopifyPagePath.length === 0) {
      setErrors("path", ["pathIsEmpty"]);
    } else {
      clearErrors("path", ["pathIsEmpty"]);
      if (validatePageModalPath(shopifyPagePath)) {
        setErrors("path", ["isInvalidPathName"]);
      } else {
        clearErrors("path", ["isInvalidPathName"]);
      }
    }

    if (errorMapping["path"]?.includes("pathAlreadyExists")) {
      clearErrors("path", ["pathAlreadyExists"]);
    }
    if (errorMapping["path"]?.includes("pathIsEmpty")) {
      clearErrors("path", ["pathIsEmpty"]);
    }

    onChangeElement({
      ...element,
      shopifyPagePath,
    });
  };

  const handleRouteChange = (value: string) => {
    if (isShopifyIntegrationEnabled) {
      const shopifyBlogId = shopifyBlogs?.find(
        (blog) => blog.handle === value.split("/")[1],
      )?.id;

      onChangeElement({
        ...element,
        type: "shopifyArticle",
        shopifyBlogId,
      });
    }
  };

  const routes: { value: string; isSelected: boolean }[] = [];

  if (isShopifyIntegrationEnabled) {
    shopifyBlogs?.forEach((blog) =>
      routes.push({
        value: `blogs/${blog.handle}/`,
        isSelected: blog.id === element.shopifyBlogId,
      }),
    );
  }

  return (
    <div className="flex flex-col gap-y-4">
      <ElementNameEditor
        type="page"
        inputName="page-name"
        initialName={element.name}
        onChange={handleNameChange}
      />

      <LabeledControl
        label="Post Featured Image"
        id="page-data-featured-image"
        size="sm"
      >
        <ImageSourceSelector
          src={element.shopifyArticleImage?.src}
          onChangeImageSource={(value: string) => {
            onChangeElement({
              ...element,
              shopifyArticleImage: { src: value },
            });
          }}
          onRemove={() =>
            onChangeElement({
              ...element,
              shopifyArticleImage: { src: "" },
            })
          }
          allowsDynamicData={false}
          size="sm"
        />
      </LabeledControl>

      <LabeledControl
        label="URL"
        id="page-data-path"
        size="sm"
        error={
          <PathInputError
            errorMapping={errorMapping}
            elementType={element.type}
          />
        }
      >
        <div className="flex flex-row gap-2 items-center">
          <div className="flex-none">
            <Combobox.Root
              options={routes.map((route) => ({
                label: route.value,
                value: route.value,
              }))}
              value={routes.find((route) => route.isSelected)?.value}
              onChange={(value) => handleRouteChange(value)}
            >
              <Combobox.Trigger>
                <Combobox.SelectionButton
                  size="sm"
                  layoutClassName="w-24"
                  title={routes.find((route) => route.isSelected)?.value ?? ""}
                />
              </Combobox.Trigger>
              <Combobox.Popover layoutClassName="w-48">
                <Combobox.Content />
              </Combobox.Popover>
            </Combobox.Root>
          </div>
          <Input
            id="page-data-path"
            value={element.shopifyPagePath}
            placeholder="path"
            size="sm"
            onChange={handlePathChange}
          />
        </div>
      </LabeledControl>

      {initialElementIsPublished && (
        <SwitchWithDescription
          label="Is Published"
          description="Public users can see this page."
          isOn={element.isPublished}
          onChange={(isPublished) =>
            onChangeElement({ ...element, isPublished })
          }
          size="sm"
          variant="card"
        />
      )}

      <Separator />

      <SeoElementEditor
        isPageSettings={element.type === "page"}
        metafields={metafields}
        onMetafieldChange={onMetafieldChange}
      />
    </div>
  );
};

const PathInputError: React.FC<{
  errorMapping: Record<FormKey, ElementErrorType[] | null>;
  elementType: ReploElementType;
}> = ({ errorMapping, elementType }) => {
  const isError =
    errorMapping &&
    Object.keys(errorMapping).some((key) =>
      errorMapping[key as FormKey]?.some((error) =>
        [
          "pathAlreadyExists",
          "isInvalidPathName",
          "pathIsEmpty",
          "loadingMissingData",
          "errorLoadingData",
          "storeHasNoBlogs",
        ].includes(error),
      ),
    );

  if (isError) {
    const errors = Object.values(errorMapping).flat();
    const primaryErrorType = errors.find(
      (error) =>
        error?.toLowerCase().includes("path") && typeof error === "string",
    );
    const errorType = primaryErrorType ?? errors[0];
    if (errorType) {
      return (
        <ElementEditorErrors errorType={errorType} elementType={elementType} />
      );
    }
  }

  return null;
};

export default ArticleElementEditor;
