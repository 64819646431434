import type { ComboboxOption } from "@replo/design-system/components/combobox/ComboboxContext";
import type { VirtualItem } from "@tanstack/react-virtual";

import * as React from "react";

import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@replo/design-system/components/shadcn/core/command";
import twMerge from "@replo/design-system/utils/twMerge";
import { useVirtualizer } from "@tanstack/react-virtual";
import groupBy from "lodash-es/groupBy";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { useOnScreen } from "replo-utils/react/use-on-screen";

import { MenuItem } from "../../menu/MenuItem";
import Tooltip from "../../tooltip/Tooltip";
import { useCombobox } from "../hooks/useCombobox";

// NOTE (Jackson, 2024-12-03): These values are used to calculate the height of the virtualized list
const ITEM_GAP = 4;

/**
 * Get the height of a virtualized element based on its type and size
 */
const getElementHeight = (
  item: VirtualizedItem,
  size: "sm" | "base" | "lg" = "base",
  includeGap: boolean = true,
): number => {
  if (!item) {
    return 0;
  }

  if (item.type === "header") {
    return exhaustiveSwitch({ type: size })({
      sm: 32,
      base: 36,
      lg: 40,
    });
  }

  // Item height
  const baseHeight = exhaustiveSwitch({ type: size })({
    sm: 24,
    base: 28,
    lg: 44,
  });
  if (item.option.estimatedSize) {
    return item.option.estimatedSize;
  }
  return includeGap ? baseHeight + ITEM_GAP : baseHeight;
};

const useSearchableOptions = (
  options: ComboboxOption[],
  searchValue: string,
  isControlledSearch: boolean,
) => {
  return React.useMemo(() => {
    if (!searchValue || isControlledSearch) {
      return options;
    }

    return options.filter((option) => {
      if (option.excludeFromSearch) {
        return false;
      }

      let valueToCheck: string | undefined;
      if ("displayValue" in option) {
        valueToCheck = option.displayValue;
      } else {
        valueToCheck = option.label;
      }
      return valueToCheck
        .toLowerCase()
        .includes(searchValue.toLowerCase().trim());
    });
  }, [options, searchValue, isControlledSearch]);
};

type CommandItemProps = Pick<
  OptionsListProps,
  "value" | "setValue" | "setOpen" | "size"
>;

type GroupedOptions = {
  options: ComboboxOption[];
  title?: string;
}[];

const groupOptions = (options: ComboboxOption[]): GroupedOptions => {
  // NOTE (Jackson, 2024-11-27): Group options by their groupTitle. Ordering is essentially handled by
  // the order in which groupTitles are found - we could make this more explicit if needed.
  const grouped = groupBy(options, "groupTitle");

  return Object.entries(grouped).map(([title, options]) => ({
    title: title === "undefined" ? undefined : title,
    options,
  }));
};

type VirtualizedItem =
  | { type: "header"; title: string }
  | { type: "item"; option: ComboboxOption };

const flattenOptions = (groupedOptions: GroupedOptions): VirtualizedItem[] => {
  return groupedOptions.flatMap((group) => [
    ...(group.title ? [{ type: "header" as const, title: group.title }] : []),
    ...group.options.map((option) => ({ type: "item" as const, option })),
  ]);
};

const CommandOptionItem = React.forwardRef<
  HTMLDivElement,
  CommandItemProps & {
    item: VirtualizedItem;
    virtualOption: VirtualItem;
    hasHeaders: boolean;
  }
>(
  (
    { value, setValue, setOpen, item, size, virtualOption, hasHeaders },
    ref,
  ) => {
    const contentRef = React.useRef<HTMLDivElement | null>(null);
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const { isMultiselect } = useCombobox();
    React.useEffect(() => {
      const checkOverflow = () => {
        // NOTE (Patrick, 2025-01-07): This setTimeout is to allow the popover to render. Once the popover renders,
        // we can check if a menu item is overflowing or not.
        setTimeout(() => {
          if (contentRef.current) {
            const isOverflowing =
              contentRef.current.scrollWidth > contentRef.current.clientWidth;

            setIsOverflowing(isOverflowing);
          }
        }, 100);
      };

      checkOverflow();
      window.addEventListener("resize", checkOverflow); // Recheck on window resize
      return () => window.removeEventListener("resize", checkOverflow);
    }, []);

    if (item.type === "header") {
      return (
        <div
          key={`header-${virtualOption.index}`}
          style={{
            height: `${virtualOption.size}px`,
            transform: `translateY(${virtualOption.start}px)`,
          }}
          className="absolute left-0 top-0 w-full flex flex-col justify-end text-default"
        >
          <div
            className={twMerge(
              "flex items-center gap-2 py-1 px-2 font-semibold",
              size === "sm" ? "text-xs" : "text-sm",
            )}
          >
            {item.title}
          </div>
        </div>
      );
    }

    const option = item.option;

    return (
      <CommandItem
        key={option.value}
        value={option.value ?? ""}
        disabled={option.isDisabled}
        variant={size === "lg" ? "base" : size}
        ref={ref}
        /**
         * NOTE (Max, 2024-09-17): If a component is provided, it means that clicking on the option should not
         * update the value: instead, the provided component will be responsible for updating the value (e.g. with a
         * date-picker).
         */
        onSelect={(currentValue) => {
          // 1. If the clicked option is already selected, then we need to deselect it.
          // 2. Otherwise, we need to select the clicked option.
          // Side note: Value is able to be null in multiselect mode if the user wants
          // to represent "all options selected" that way, but this must be handled by the
          // consumer of the Combobox.
          if (isMultiselect) {
            const valueIndex = value.indexOf(currentValue);
            if (valueIndex === -1) {
              const newValue = [...value, currentValue];
              setValue(newValue);
            } else {
              const newValue = [...value];
              newValue.splice(valueIndex, 1);
              setValue(newValue);
            }
          } else {
            setValue(currentValue);
            if (setOpen) {
              setOpen(false);
            }
          }
        }}
        className={twMerge(
          "absolute left-0 top-0 w-full rounded",
          !hasHeaders && "px-0",
        )}
        style={{
          height: `${virtualOption.size}px`,
          transform: `translateY(${virtualOption.start}px)`,
        }}
      >
        <Tooltip
          content={option.tooltip ?? (isOverflowing ? option.label : undefined)}
          triggerAsChild
        >
          {typeof option.label === "string" ? (
            <MenuItem
              variant={isMultiselect ? "checkbox" : "default"}
              size={size === "lg" ? "base" : size}
              selected={
                isMultiselect
                  ? value?.includes(option.value)
                  : value === option.value
              }
            >
              {option.label}
            </MenuItem>
          ) : (
            option.label
          )}
        </Tooltip>
      </CommandItem>
    );
  },
);

CommandOptionItem.displayName = "CommandOptionItem";

type OptionsListProps = {
  options: ComboboxOption[];
  setValue: (value: string | string[]) => void;
  setOpen?: (value: boolean) => void;
  value: string | string[];
  areOptionsSearchable?: boolean;
  input?: string;
  setInput?: (value: string) => void;
  inputPlaceholder?: string;
  emptySearchMessage?: string;
  size: "sm" | "base" | "lg";
  onScrolledToEnd?: () => void;
  loader?: React.ReactNode | null;
  UNSAFE_className?: string;
};

const OptionsList: React.FC<OptionsListProps> = ({
  options,
  setValue,
  setOpen,
  value,
  areOptionsSearchable = false,
  input,
  emptySearchMessage = "No results",
  size,
  onScrolledToEnd,
  loader,
  UNSAFE_className,
}) => {
  const { isUsingTriggerInput } = useCombobox();

  // Create a callback ref using useOnScreen that will trigger onScrolledToEnd when visible
  const loadMoreRef = useOnScreen(
    () => {
      if (onScrolledToEnd) {
        onScrolledToEnd();
      }
    },
    {
      rootMargin: "100px",
    },
  );

  const filteredOptions = useSearchableOptions(
    options,
    input ?? "",
    /**
     * NOTE (Max, 2025-01-04): If the trigger is an input, then by definition,
     * the options are already searchable (from the ComboboxTrigger). It wouldn't
     * make sense to add another filter mechanism in the OptionsList.
     */
    !isUsingTriggerInput && !areOptionsSearchable,
  );

  const grouped = groupOptions(filteredOptions);
  const [flattenedItems] = [flattenOptions(grouped)];

  const hasHeaders = React.useMemo(
    () => flattenedItems.some((item) => item.type === "header"),
    [flattenedItems],
  );

  const parentContainerRef = React.useRef<HTMLDivElement | null>(null);

  const virtualizer = useVirtualizer({
    count: flattenedItems.length,
    getScrollElement: () => parentContainerRef.current,
    overscan: 10,
    estimateSize: (index) => {
      const item = flattenedItems[index];
      return item ? getElementHeight(item, size, true) : 0;
    },
  });

  const virtualOptions = virtualizer.getVirtualItems();

  return (
    <>
      <CommandList ref={parentContainerRef}>
        {filteredOptions.length === 0 ? (
          <CommandEmpty>{loader ? loader : emptySearchMessage}</CommandEmpty>
        ) : (
          <CommandGroup
            className={twMerge("px-2", hasHeaders ? "pb-2 pt-0" : "py-1")}
          >
            <div
              className={UNSAFE_className}
              style={{
                height: `${virtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {virtualOptions.map((virtualOption) => {
                const item = flattenedItems[virtualOption.index];
                const isLastItem = virtualOption.index === options.length - 1;

                if (!item) {
                  return null;
                }

                return (
                  <CommandOptionItem
                    value={value}
                    key={virtualOption.index}
                    setValue={setValue}
                    setOpen={setOpen}
                    item={item}
                    size={size}
                    virtualOption={virtualOption}
                    hasHeaders={hasHeaders}
                    ref={isLastItem ? loadMoreRef : null}
                  />
                );
              })}
            </div>
            {loader}
          </CommandGroup>
        )}
      </CommandList>
    </>
  );
};

export { OptionsList };
