import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button/Button";
import { useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";
import { z } from "zod";

const emailSchema = z.string().email();

type FormValues = {
  email: string;
};

const InitResetPassword: React.FC = () => {
  const { mutate: initResetPasswordApi, isPending: isLoadingInitResetPass } =
    trpc.user.initResetPassword.useMutation();
  const [resetPasswordEmail, setResetPasswordEmail] = React.useState<
    string | null
  >(null);
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      email: searchParams.get("email") ?? "",
    },
    mode: "onBlur",
  });

  const watchAllFields = useWatch({ control });

  if (resetPasswordEmail) {
    const email = resetPasswordEmail;
    return (
      <div className="text-slate-500 text-sm">
        <div>
          We sent you an email to
          <span className="pl-1 text-blue-600">{email}</span> with a secret link
          to reset your password.
        </div>
        <div className="pt-4">
          <span>
            If you do not see the email within 5 minutes, please contact us at
          </span>
          <span className="pl-1 text-blue-600">support@replo.app</span>
        </div>
      </div>
    );
  }

  const emailError = !isEmpty(watchAllFields.email)
    ? errors?.email?.message
    : undefined;

  const onSubmit = ({ email }: FormValues) => {
    setResetPasswordEmail(email);
    initResetPasswordApi(email);
  };

  return (
    <form
      className="flex-1 no-scrollbar flex flex-col gap-y-10"
      onSubmit={(values) => {
        void handleSubmit(onSubmit)(values);
      }}
    >
      <LabeledControl
        label="Email"
        className="text-default typ-body-base"
        size="sm"
        error={emailError}
      >
        <Input
          aria-invalid={emailError ? "true" : undefined}
          aria-describedby={emailError ? "error-email" : undefined}
          validityState={emailError ? "invalid" : undefined}
          autoComplete="off"
          placeholder="Your Work Email"
          {...register("email", {
            required: "Please enter a valid email address.",
            validate: (value) => {
              const parsedEmail = emailSchema.safeParse(value);
              return (
                parsedEmail.success || "Please enter a valid email address."
              );
            },
            // NOTE (Fran 2024-02-27): We should clear the errors when the user is typing
            onChange: () => clearErrors("email"),
          })}
          type="email"
          size="base"
        />
      </LabeledControl>
      <Button
        layoutClassName="w-full"
        variant="primary"
        size="base"
        type="submit"
        isLoading={isLoadingInitResetPass}
      >
        Send Reset Email
      </Button>
    </form>
  );
};

export default InitResetPassword;
