import type { ReploSimpleProjectWithStats } from "schemas/generated/project";

import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import {
  BsCart3,
  BsDistributeVertical,
  BsFileText,
  BsWindow,
} from "react-icons/bs";

type StatItemProps = {
  icon: React.ReactNode;
  count: number | undefined;
  tooltip: string;
  isLast?: boolean;
};

const StatItem: React.FC<StatItemProps> = ({
  icon,
  count,
  tooltip,
  isLast,
}) => (
  <Tooltip content={tooltip} triggerAsChild>
    <div className="flex items-center gap-1 text-slate-600 text-xs cursor-default">
      {icon}
      <span>{count ?? 0}</span>
      {!isLast && <span className="mx-0.5 text-slate-300">|</span>}
    </div>
  </Tooltip>
);

type StatSectionProps = {
  elementCounts: ReploSimpleProjectWithStats["elementCounts"];
};

const StatSection: React.FC<StatSectionProps> = ({ elementCounts }) => {
  return (
    <div className="flex flex-row gap-0.5 items-center">
      <StatItem
        icon={<BsWindow size={12} />}
        count={elementCounts?.pageCount}
        tooltip={`${elementCounts?.pageCount ?? 0} ${elementCounts?.pageCount === 1 ? "Page" : "Pages"} Published`}
      />
      <StatItem
        icon={<BsCart3 size={12} />}
        count={elementCounts?.productTemplateCount}
        tooltip={`${elementCounts?.productTemplateCount ?? 0} ${elementCounts?.productTemplateCount === 1 ? "Product Template" : "Product Templates"} Published`}
      />
      <StatItem
        icon={<BsDistributeVertical size={12} />}
        count={elementCounts?.sectionCount}
        tooltip={`${elementCounts?.sectionCount ?? 0} ${elementCounts?.sectionCount === 1 ? "Section" : "Sections"} Published`}
      />
      <StatItem
        icon={<BsFileText size={12} />}
        count={elementCounts?.blogCount}
        tooltip={`${elementCounts?.blogCount ?? 0} ${elementCounts?.blogCount === 1 ? "Blog" : "Blogs"} Published`}
        isLast
      />
    </div>
  );
};

export default StatSection;
