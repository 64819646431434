import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  ExtraContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const OkendoProductRatingSummary: React.FC<RenderComponentProps> = (props) => {
  const { okendoNamespace } = useRuntimeContext(ExtraContext);
  // Note (Fran, 2023-03-08, REPL-6564): For the Okendo Widget version that has
  // okendoReviews namespace, the liquid render is different.
  const liquidSourceWidget =
    okendoNamespace === "okendoReviews"
      ? `<div data-oke-reviews-product-listing-rating>{{ product.metafields.okendo.ProductListingSnippet }}</div>`
      : `
    <div
      data-oke-star-rating
      data-oke-reviews-product-id="shopify-{{ product.id }}">
        {{ product.metafields.okendo.StarRatingSnippet }}
    </div>`;

  const product = useProductFromProps(props.component.props, props.context);

  const liquid = wrapProductAssignmentToLiquidSource({
    product,
    liquidSource: liquidSourceWidget,
    context: props.context,
  });

  return (
    <SharedShopifyLiquid
      componentId={props.component.id}
      componentAttributes={props.componentAttributes}
      component={props.component}
      liquidSource={liquid}
      repeatedIndexPath={props.context.repeatedIndexPath}
      isLiquidSupported
      title="Okendo Product Rating"
      placeholderType="reviewStars"
    />
  );
};

export default OkendoProductRatingSummary;
