import type { AnimationField } from "replo-runtime/shared/types";
import type {
  AlchemyAnimationTriggerType,
  AlchemyAnimationType,
  Animation,
} from "schemas/animations";

import * as React from "react";

import Selectable from "@editor/components/common/designSystem/Selectable";

import { ToggleGroup } from "@replo/design-system/components/toggle/ToggleGroup";
import startCase from "lodash-es/startCase";

export const getAnimationName = (
  trigger: AlchemyAnimationTriggerType,
  type: AlchemyAnimationType,
) => {
  return `${startCase(trigger)} → ${startCase(type)}`;
};

type AnimationFieldProps = {
  animation: Partial<Animation>;
  field: AnimationField;
  onChange: (value: any) => void;
};

const animationFieldMap: { [key: string]: any } = {
  select: ({ animation, field, onChange }: AnimationFieldProps) => {
    return (
      <Selectable
        key={field.name}
        value={animation.value?.[field.id]}
        options={field.options}
        placeholder={field.placeholder}
        onSelect={onChange}
      />
    );
  },
  toggle: ({ animation, field, onChange }: AnimationFieldProps) => {
    return (
      <ToggleGroup
        size="sm"
        key={field.name}
        selectedValue={animation.value?.[field.id]}
        options={field.options}
        onChange={(value) => {
          onChange(value as string);
        }}
      />
    );
  },
};

export const getAnimationField = (props: AnimationFieldProps) => {
  return animationFieldMap[props.field.type](props);
};
