import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

export const convertHtmlTagToReadableString = (
  tag: SavedStyleTextAttributes["htmlTag"],
) => {
  return exhaustiveSwitch({ type: tag })({
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    h4: "Heading 4",
    h5: "Heading 5",
    h6: "Heading 6",
    p: "Paragraph",
  });
};
