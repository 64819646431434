import * as React from "react";

import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";

import Button from "@replo/design-system/components/button/Button";
import Description from "@replo/design-system/components/label/Description";
import Label from "@replo/design-system/components/label/Label";
import twMerge from "@replo/design-system/utils/twMerge";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type BaseLabelProps = {
  label?: string | React.ReactNode;
  id?: string;
  error?: React.ReactNode | null;
  className?: string;
  containerClassName?: string;
  size?: "base" | "sm";
  description?: string;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
};

type DefaultLabelProps = {
  variant?: "default";
  labelActionText?: string | React.ReactNode;
  labelAction?: () => void;
};

type InlineLabelProps = {
  variant: "inline";
};

type LabeledControlProps = BaseLabelProps &
  (DefaultLabelProps | InlineLabelProps);

type VariantProps = {
  labelActionText?: string | React.ReactNode;
  labelAction?: () => void;
};
const LabeledControl: React.FC<
  React.PropsWithChildren<LabeledControlProps>
> = ({
  id,
  label,
  children,
  error,
  containerClassName,
  className = "",
  onClick,
  size = "base",
  description,
  variant = "default",
  ...rest
}) => {
  const variantProps = exhaustiveSwitch(
    { ...rest, variant },
    "variant",
  )<VariantProps>({
    default: (props) => ({
      labelActionText: props.labelActionText,
      labelAction: props.labelAction,
    }),
    inline: () => ({}),
  });
  const { labelActionText, labelAction } = variantProps || {};

  const labelClassName = twMerge(
    "font-medium leading-5 text-default",
    Boolean(onClick) && "cursor-pointer",
    size === "sm" && "text-xs",
    size === "base" && "text-sm",
    className,
  );

  return (
    <div
      className={twMerge(
        "flex flex-col gap-1.5",
        containerClassName,
        variant === "inline" && "flex-row",
      )}
    >
      <div className="flex justify-between gap-1">
        <Label
          size={size}
          htmlFor={id}
          className={labelClassName}
          onClick={onClick}
          label={label}
        />
        {labelActionText && (
          <Button onClick={labelAction} variant="link" size={size}>
            {labelActionText}
          </Button>
        )}
      </div>
      {children}
      {error && <ErrorMessage error={error} />}
      {description && !error && (
        <Description description={description} size={size} />
      )}
    </div>
  );
};

export default LabeledControl;
